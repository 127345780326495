import { Button, Dropdown, DropdownProps, Form } from "antd";
import React, { ReactNode, useState } from "react";
import { Dropdown_Datagrid_template } from "./DropdownDatagrid";
import { DownOutlined } from "@ant-design/icons";
import { RefreshButtonTemplate } from "./RefreshButtonTemplate";

interface props {
  columns: any;
  disabled: boolean;
  gridData: any;
  defaultValue: any;
  label?: string;
  dropdownBtnStyles?: any;
  dropStyles?: any;
  cusBtnStyle?: boolean;
  onRowClick?: (e: any) => void;
  handleRefresh?: () => void;
  labelCol?: number;
  ref?: any;
  isHide?: boolean;
  className?: string;
  buttonClass?: string;
  extraWidget?: any | ReactNode;
  keyExpr?:string;
}

export const DropdownGrid_template = ({
  columns,
  disabled = true,
  isHide,
  gridData,
  cusBtnStyle = false,
  defaultValue,
  onRowClick,
  handleRefresh,
  label,
  labelCol,
  dropdownBtnStyles,
  dropStyles,
  className,
  buttonClass,
  extraWidget,
  keyExpr,
}: props) => {
  const [trigger, setTrigger] = useState<"click" | "contextMenu" | "hover">(
    "click"
  );
  const [open, setOpen] = useState(false);

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const handleGridClick = (e: any) => {
    onRowClick && onRowClick(e);
    // Close the dropdown when a row is clicked
    setOpen(false);
  };

  return (
    <Form.Item
      label={<p className="text-xs">{label}</p>}
      labelCol={{ span: labelCol && labelCol }}
      className="w-full"
      style={dropStyles && dropStyles}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .ant-btn.ant-btn-sm {
              padding: 0,
            }
            `,
        }}
      />

      <div className="w-full flex flex-row items-center">
        <Dropdown
          open={open}
          onOpenChange={handleOpenChange}
          arrow={true}
          trigger={[trigger]}
          className={`h-[23px] ${className} p-0 `}
          disabled={disabled}
          dropdownRender={() => (
            <div style={{}}>
              <Dropdown_Datagrid_template
                keyExpr={keyExpr}
                gridheight={200}
                gridwidth={500}
                className={"w-full"}
                columns={columns}
                data={gridData}
                disableGroupPanel
                disablePaging
                disableSearch
                onRowClick={handleGridClick}
              />
            </div>
          )}
        >
          <a onClick={(e) => e.preventDefault()}
            className={buttonClass}
            >
            <Button
              disabled={disabled}
              style={
                cusBtnStyle
                  ? { ...dropdownBtnStyles }
                  : { width: "calc(100% - 23px)" }
              }
              className="p-0"
            >
              <div className="flex justify-between w-full px-[5px]">
                <aside className="w-[95%] flex">
                  <label className="text-ellipsis overflow-hidden ">
                    {defaultValue ?? ""}
                  </label>
                </aside>
                <aside className="w-[10%] flex ">
                  <label className=" ">
                    <DownOutlined size={2} style={{ color: "lightgrey", }} className="ml-2.5" />
                  </label>
                </aside>
              </div>
            </Button>
          </a>
        </Dropdown>

        <div className="w-full flex ml-1">
          {!isHide && (
            <RefreshButtonTemplate
              handleRefresh={() => {
                handleRefresh && handleRefresh();
              }}
            />
          )}

          {extraWidget && extraWidget}
        </div>
      </div>
    </Form.Item>
  );
};
