/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useRef, useState } from "react";
import { format } from "date-fns";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import refresh from "../../../../../../../assets/refresh.png";

import {
  Cancel,
  OpenButton,
  Schedule_Alert_Button,
} from "../../../../../../accessories/buttons";
import { req } from "../data/mini_datagrid_cols";
import useFetch from "../../../../../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { Checkbox } from "antd";
import { Tooltip } from "devextreme-react";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { SMSAlerts2 } from "../../../../../../accessories/custom-toolbars/widgets/entriesModal/SMSAlerts2";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  setState?: (key: string, value: any) => void;
  opened?: () => void;
  selectedRecord?: any;
  openSelectedTask?: any;
  selectedTask_update?: any;
  openCancel?: () => void;
  validCancel?: () => void;
}
interface stateTypes {
  cancellationReason: string;
  workShop: any;
  date: string;
}
export const Maintenance_Activities_Details_Form = ({
  toggler,
  datgridCol,
  customHeightHook,
  setState,
  opened,
  openCancel,
  selectedRecord,
  openSelectedTask,
  selectedTask_update,
  validCancel,
}: props) => {

  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [wrap, setWrap] = useState<boolean>(false);

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [states, setStates] = useState<stateTypes>({
    cancellationReason: "",
    date: "",
    workShop: { id: 0, name: "" },
  });
console.log("Task States", states)
  const formCode = useSelector((state: RootState) => state.general.formCode);
  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };


  const [employeeId, userId, userInfo] = useAccountInfo();
  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [posting, updating] = useCrudFunc();
  // states handler
  //  const updateState = (key: keyof stateTypes, value: any) => {
  //   setStates(prevState => ({ ...prevState, [key]: value }));
  // };


  const [refreshing, setRefreshing] = useState(false);

  const selectGrid_update: any = useCallback<any>((e: any) => {

    if (setState !== undefined) {
      setState("selectedTask_update", [e]);
      setState("openSelectedTask", [e]);
      updateState("workShop", { id: e?.wsdIDpk, name: e?.wsdName });

    } else {
      // do something which will come later
    }

  }, []);

  const [Alldata, Allerror, Allloading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityRequestsAll?cancelledStatus=${isCancel}`,
    refreshing
  );
  const [alertws_data, alertws_error, alertws_loading] = useFetch(
    "AsmTmsAlertRequester/GetLueWorkshops"
  );

  if (alertws_data) {

  } else {

  }


  /**************************************************************/
  // SMS ALERT MODAL
  /*************************************************************** */
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again",
        title: "No Internet Connection!",
      });
    }
  };

  const openAlert = () => {
    if (selectedTask_update === undefined) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Record Selected", title: "No Record!" });
    } else {
      setOpenAlertModal(true);
    }
  };

  /**************************************************************** */
  //      Response MODAL
  /**************************************************************** */
  const AlertSentModalData = {
    message: "Transport Alert Sent",
    title: "SMS ALERT SENT",
    okText: "Ok",
  };
  const AlertSaveModalData = {
    message: "Are you sure you want to send this alert?",
    title: "SEND SMS ALERT?",
    okText: "Yes, send",
    cancelText: "No, Cancel",
  };

  const CancellationModalData = {
    message: "Please enter the reason for Cancelling this request",
    title: "Cancellation Reason",
    okText: "Ok",
    cancelText: "No, Cancel",
  };
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState<any>(AlertSentModalData);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);

  /**************************************************************** */
  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    const formattedDate = format(date, "EEE, dd MMM yyyy");

    return `${formattedDate}`;
  };
  const currentDate = formatDate(new Date());
  //SMS message
  let message = selectedTask_update
    ? `Hi, ${selectedTask_update?.driverFname}, please present your vehicle with Reg No ${selectedTask_update?.vdtRegNo} 
at the ${states?.workShop.name} workshop on ${states?.date ?? currentDate}.
Thank you.
Sent from innoX`
    : "No record selected";

  let rec = {
    mob: selectedTask_update?.requestedByMobNox,
    recordID: selectedTask_update?.mrqIDpk,
    empID: selectedTask_update?.driverEmpID,
    driverMob: selectedTask_update?.driverMobNox,
  };

  let alertMessage = selectedTask_update
    ? `Your Vehicle Maintenance request with Request No, ${selectedTask_update?.vdtRegNo}, has been scheduled for ${currentDate} 
  The workshop is ${states.workShop.name}.`
    : "";
  //send alert endpoint
  const sendAlert = async () => {
    checkConnection();
    try {
      setBusyLoader("Sending SMS...");
      await posting (`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: rec.recordID === rec.empID ? rec.recordID : rec.empID && rec.empID,
        altMessage: alertMessage,
        altTakeAction: true,
        altFormCode: "",
      },`Alert sent to ${selectedTask_update?.requestedByEmpName === selectedTask_update?.driverEmpName ? selectedTask_update?.requestedByEmpName :selectedTask_update?.driverEmpName || selectedTask_update?.requestedByEmpName } on ${currentDate}`);
      await posting (`SMSAlert/SaveSMS`, {
        imsMessage: message,
        imsRecipientEmpIdfk:rec.recordID === rec.empID ? rec.recordID : rec.empID || rec.recordID ,
        imsSender: userInfo.empName,
        imsRecordIDfk: rec.recordID,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsSmsServiceProvider: "Hubtel",
        imsEmpIDfk: rec.empID,
        imsInitiatorEmpIDfk: userId,
        imsCreatedBy: userId,
      },`SMS sent to ${selectedTask_update?.driverEmpName === selectedTask_update?.requestedByEmpName ? selectedTask_update?.requestedByEmpName :selectedTask_update?.driverEmpName || selectedTask_update?.requestedByEmpName } on ${currentDate}`);
      setRefreshing(!refreshing);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Transport SMS alert sent successfully!",
        title: "Alert Sent!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to send alert!",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  //cancel message
  let cancelMessage = `Hello ${selectedTask_update?.driverFname}, please be informed that the maintenance request with ID ${selectedTask_update?.mrqIDpk} has been cancelled by ${userInfo.titShtName} ${userInfo.empFirstName} ${userInfo.empLastName}`;


  //cancel endpoint
  const cancelFunc = async () => {
    try {
      if (
        states.cancellationReason === "" ||
        states.cancellationReason === undefined
      ) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please enter the reason for Cancelling this request",
          title: "Cancellation Reason",
        });
        setOpenModal(true);
      }

      setBusyLoader("Cancelling Request...");
      await updating(
        `AsmTmsEntMaintenanceActivity/UpdateAsmTmsMaintenanceActivityUpdateCancel?MrqIDpk=${selectedTask_update?.mrqIDpk
        }&Text=${states.cancellationReason ?? ""}`,
        {
          MrqIDpk: selectedTask_update?.mrqIDpk ?? 0,
          Text: states.cancellationReason ?? "",
        },
        `Request with ID ${selectedTask_update?.mrqIDpk} has been cancelled by ${userInfo.titShtName} ${userInfo.empFirstName} ${userInfo.empLastName} on ${currentDate} due to ${states.cancellationReason}`
      );
      checkConnection();
      await posting (`SMSAlert/SaveSMS`, {
        imsMessage: cancelMessage,
        imsRecipientEmpIdfk:rec.recordID === rec.empID ? rec.recordID : rec.empID || rec.recordID,
        imsSender: userInfo.empName,
        imsRecordIDfk: rec.recordID,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsEmpIDfk: rec.empID,
        imsInitiatorEmpIDfk: userId,
        imsCreatedBy: userId,
      },
    ``);
    setRefreshing(!refreshing); 
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Record cancelled Successfully!",
        title: "Cancelled!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please enter a cancellation reason..or try again else contact the admin for assistance!",
        title: "Failed to Cancel!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  //undo cancelling
  const undoCancel = async () => {
    try {
      setBusyLoader("Restoring Request...");
      await updating(
        `AsmTmsVehicleMaintenanceRequest/RestoreCanceledMaintenanceRequest`,
        {
          mrqIdpk: selectedTask_update?.mrqIDpk,
          mrqReviewReport: selectedTask_update?.mrqReviewReport,
        },
        `Request with ID ${selectedTask_update?.mrqIDpk} has been restored by ${userInfo.titShtName} ${userInfo.empFirstName} ${userInfo.empLastName} on ${currentDate} `
      );
      setRefreshing(!refreshing);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Request Restored Successfully!",
        title: "Resquest Restored!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Failed to UNDO cancel.Please try again or contact administrator for assistance!",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };
  //open button
  const openAccessPriv = () => {
    if (savePriv === true) {
      opened?.();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //schedule & alert button
  const scheduleAccessPriv = () => {
    if (savePriv === true) {
      openAlert();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  const cancel = () => {
    if (selectedTask_update === undefined) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Record Selected", title: "No Record!" });
    } else if (selectedTask_update?.mrqCancelled === true) {
      setIcon("question");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "This request has already been Cancelled. Do you want to UNDO the Cancellation?",
        title: "UNDO Cancellation?",
        okText: "Yes, Undo",
      });
    } else {
      setModalData(CancellationModalData);
      setOpenModal(true);
      setIcon("warning");
    }
  };
  //cancel button
  const cancelAccessPriv = () => {
    if (savePriv === true) {
      cancel();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  const callSaveAlert = async () => {
    setIcon("question");
    setOpenModal(true);
    setModalData(AlertSaveModalData);
  };
  const sendMail = () => {
    callSaveAlert();
    setOpenAlertModal(false);
  };
  const handleRefresh = () => {
    setRefreshing(!refreshing);
  };

  return (
    <div>
      <SMSAlerts2
        open={openAlertModal}
        cancelHandler={() => {
          setOpenAlertModal(false);
        }}
        placeHolder={selectedTask_update?.wsdName}
        okText={""}
        title={"Transport Alerts"}
        idexpr={"wsdIDpk"}
        message={message}
        dataexp={"wsdName"}
        options={alertws_data}
        selectedValue={(e) => {
          updateState("workShop", { id: e["wsdIDpk"], name: e["wsdName"] });
        }}
        selectedDate={states.date}
        handleDate={(e: any) => {
          updateState("date", dayjs(e));
        }}
        useCallFunc
        setCallbackValue={(e) => { }}
        sendMail={sendMail}
        updateStatesx={updateState}
      />


      <ModalTemplate
        icon={icon}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === AlertSaveModalData.title
            ? sendAlert()
            : setOpenModal(false);
          modalData.title === CancellationModalData.title
            ? cancelFunc()
            : setOpenModal(false);
          modalData.title === "UNDO Cancellation?"
            ? undoCancel()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "question" ? false : true}
        withInput2={
          modalData.title === CancellationModalData.title ? true : false
        }
        inputOnchange={(e) => {
          updateState("cancellationReason", e);
        }}
      />



      <div
        style={{ height: "" }}
        className="flex justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
      >
        {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
        {/* <p className="w-full py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">

        </p> */}
        <ul className="flex space-y-0.5 items-center justify-end w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
          <li id="canc" className="px-4 items-center justify-center ">
            <Checkbox
              style={{ borderColor: borderTheme }}
              className="border-[1px] rounded px-1"
              onChange={(e) => {
                setIsCancel(e.target.checked);
              }}
              value={isCancel}
            />
            <Tooltip
              target="#canc"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Cancelled status</p>
            </Tooltip>
          </li>

          <li id="rowsH" className="px-4 items-center justify-center ">
            <Checkbox
              style={{ borderColor: borderTheme }}
              className="border-[1px] rounded px-1"
              onClick={() => {
                setWrap(!wrap);
              }}
            />
            <Tooltip
              target="#rowsH"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>toggle Update rows auto height</p>
            </Tooltip>
          </li>
          <li
            onClick={handleRefresh}
            style={{ borderColor: borderTheme, cursor: "pointer" }}
            className="border-[1px] rounded p-1 mx-1 items-center justify-center "
          >
            <img className="w-4" src={refresh} />
          </li>
        </ul>
      </div>

      {/* Datagrid table */}
      <div
        style={{ width: "100%", marginBottom: "2px", borderColor: borderTheme }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Datagrid_template
          gridheight={380}
          columns={req}
          data={Alldata}
          wordWrap={wrap}
          gridwidth={datgridCol}
          onRowClick={selectGrid_update}
          disablePaging={true}
          disableGroupPanel={true}
        />

      </div>

      <div className="w-full flex px-3 flex-row items-start justify-end bg-slate-100">
        <Cancel
          handleCallbackCancel={cancelAccessPriv}
          buttonID="macancel"
          isTooltip
          tooltipMsg="Cancel"
        />
        <Schedule_Alert_Button
          isTooltip
          tooltipMsg="Schedule Alerts"
          handleAlerts={scheduleAccessPriv}
        />
        <OpenButton
          isTooltip
          tooltipMsg="Open Job Card"
          handleOpen={openAccessPriv}
        />

      </div>



    </div>
  );
};

