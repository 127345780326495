/* eslint-disable react/jsx-pascal-case */
import React, { useState, useCallback, useEffect } from "react";

import { DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

import save from "../../../../../../../assets/floppy-disk.png";
import reset from "../../../../../../../assets/reset.png";

import {
  SaveButton,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { RootState } from "../../../../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  formModalStates,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  setGeneralValue,
  settask_footer_table_selected,
} from "../../../../../../../features/generalSlice";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { PostDataFunc } from "../../../../../../../functions/post";
import { GetDataFunc } from "../../../../../../../functions/get";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import dayjs from "dayjs";
import { InputsTemplate } from "../../../../../../../templates/input";
import { DateTemplate } from "../../../../../../../templates/date";
import { FormModal } from "../../../../../../accessories/formModal";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import {
  FetchArrivalCondition,
  FetchMaintainance_Type,
  FetchRegistration_No,
  FetchReportingDriver,
  FetchRequest,
} from "../../../../../../../features/apiCalls/maintenanceactApi/maintenancesActivities";
interface props {
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  openedTask?: any;
  setrefreshx?: (e: any) => void;
  refreshx?: boolean;
  selectedRecord?: any;
  selectedTask_updatex?: any;
  startDate?: any;
  LookUps?: (e: any) => void;
  takeJobUpdateId?: any;
}

interface stateTypes {
  // block ?: string;
  vehNo?: any;
  dept?: any;
  section?: any;
  dateIn?: string;
  dateOut?: string;
  maint_type?: any;
  maint_req?: any;
  ac?: any;
  rd?: any;
  job?: string;
  odometerIn: number;
  odometerOut: number;
  debit3?: number;
  fuelIn?: string;
  fuelOut?: string;
  remarks?: string;
  deft?: any;
  workshop?: { id: number; name: string };
  saveOdoModals: boolean;
  isOpenDropdown1: boolean;
  isOpenDropdown2: boolean;
  isOpenDropdown3: boolean;
  isOpenDropdown4: boolean;
  isOpenDropdown5: boolean;
  isOpenDropdown6: boolean;
  isOpenDropdown7: boolean;
  isLoading: boolean;
}

export const Maintenance_Activities_tab2_Details = ({
  toggler,
  datgridCol,
  customHeightHook,
  openedTask,
  setrefreshx,
  refreshx,
  selectedRecord,
  startDate,
  selectedTask_updatex,
  takeJobUpdateId,
  LookUps,
}: props) => {
  const [selectedTask_update, setSelectedTask_update] = useState();
  const [fuelLevels, setFuelLevel] = useState<any>([]);
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [posting, updating] = useCrudFunc();

  const update_state_change = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );

  const [states, setStates] = useState<stateTypes>({
    vehNo: { id: 0, name: "" },
    dateIn: "",
    dateOut: "",
    maint_type: { id: 0, name: "" },
    maint_req: { id: 0, name: "" },
    ac: { id: 0, name: "" },
    rd: "",
    job: "",
    odometerIn: 0,
    odometerOut: 0,
    debit3: 0,
    fuelIn: "",
    fuelOut: "",
    remarks: "",
    dept: { id: 0, name: "" },
    section: { id: 0, name: "" },
    deft: { id: 0, name: "" },
    workshop: { id: 0, name: "" },
    saveOdoModals: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdown3: false,
    isOpenDropdown4: false,
    isOpenDropdown5: false,
    isOpenDropdown6: false,
    isOpenDropdown7: false,
    isLoading: false,
  });

  const dispatch = useDispatch();

  // states handler
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  console.log("states", states);
  // Endpoints

  const [mainT_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueMaintenanceTypes`
  );
  const [cat_data] = useFetch(
    `AsmRstMaintenanceActivity/GetLueMaintenanceCategories`
  );
  const [cond_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueArrivalConditions`
  );
  const [req_data] = useFetch(
    `AsmRstMaintenanceActivity/GetLueMaintenanceRequestedBy`
  );
  console.log(req_data);
  const [rdriver_data] = useFetch(
    "AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueReportingDrivers"
  );
  const [curVehAssID] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetCurrentVehicleAssignmentID?regNo=${openedTask[0]?.vdtRegNo}` //states.vehNo?.name
  );
  const [jobNums] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetJobNumbers?WorkShopIdx=${openedTask[0]?.mrqWorkshopIDfk}` //states.workshop?.id
  );
  console.log("Assignment ID", curVehAssID);
  // Endpoint
  const [vehNo_data] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos"
  );
  const [dept_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueVehicleDept?vehicleId=${openedTask[0]?.vdtIDpk}&startDate=${startDate} `, //states?.vehNo.id
    refreshx
  );
  const [sections_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueVehicleSections?vehicleId=${openedTask[0]?.vdtIDpk}&startDate=${startDate}` //states?.vehNo.id
  );
  console.log("Dept", dept_data);
  console.log("Section", sections_data);
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const populateFields = (clearFields?: boolean) => {
    if (clearFields === true) {
      dispatch(settask_footer_table_selected([]));
    }

    const gridState = [
      {
        key: "vehNo",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: openedTask[0]?.vdtIDpk ?? 0,
              name: openedTask[0]?.vdtRegNo ?? "",
            },
      },
      {
        key: "deft",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: 0,
              name:
                openedTask[0]?.mrqDefectRequest ??
                selectedRecord?.mtaJobSummary,
            },
      },
      {
        key: "dept",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: openedTask[0]?.requestedByDptIDpk ?? selectedRecord?.dptIDpk,
              name:
                openedTask[0]?.requestedByDptName ?? selectedRecord?.dptName,
            },
      },
      {
        key: "section",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: openedTask[0]?.requestedBySxnID ?? selectedRecord?.sxnIDpk,
              name:
                openedTask[0]?.requestedBySxnName ?? selectedRecord?.sxnName,
            },
      },
      {
        key: "rd",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: openedTask[0]?.driverEmpID ?? selectedRecord?.reqDriverEmpID,
              name:
                openedTask[0]?.driverEmpName ??
                selectedRecord?.reqDriverEmpName,
            },
      },
      {
        key: "maint_type",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id:
                openedTask[0]?.mtpIDpk ??
                selectedRecord?.mtaMaintenanceTypeIdfk,
              name: openedTask[0]?.mtpName ?? selectedRecord?.mtpName,
            },
      },
      {
        key: "ac",
        value: clearFields
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord?.mtaArrivalCondition },
      },
      {
        key: "odometerIn",
        value: clearFields
          ? 0
          : openedTask
          ? openedTask[0]?.mtaSpeedoIn
          : selectedRecord?.mtaSpeedoIn || 0,
      },
      {
        key: "odometerOut",
        value: clearFields
          ? 0
          : openedTask
          ? openedTask[0]?.mtaSpeedoOut
          : selectedRecord?.mtaSpeedoOut || 0,
      },
      {
        key: "debit3",
        value: clearFields
          ? 0
          : openedTask
          ? openedTask[0]?.mtaSpeedoDiff
          : selectedRecord?.mtaSpeedoDiff || 0,
      },
      {
        key: "fuelOut",
        value: clearFields
          ? ""
          : openedTask
          ? openedTask[0]?.mtaFuelLevelOutIDfk
          : selectedRecord?.mtaFuelLevelOutIDfk || 0,
      },
      {
        key: "fuelIn",
        value: clearFields
          ? ""
          : openedTask
          ? openedTask[0]?.mtaFuelLevelInIDfk
          : selectedRecord?.mtaFuelLevelInIDfk || "",
      },
      {
        key: "remarks",
        value: clearFields
          ? ""
          : openedTask[0]?.mrqRmks ?? selectedRecord?.mtaRmks,
      },
      {
        key: "dateIn",
        value: clearFields
          ? ""
          : openedTask[0]?.mrqDate ?? selectedRecord?.mtaDateIn,
      },
      {
        key: "dateOut",
        value: clearFields
          ? ""
          : openedTask[0]?.mrqCreationDate ?? selectedRecord?.mtaDateOut,
      },
    ];

    for (let instance of gridState) setState(instance.key, instance.value);
  };

  const FuelLevels = async () => {
    const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetAllFuelLevels`);

    setFuelLevel(res.data.slice(0, 5));
  };
  const defaultFuelLevel = [
    { id: 1, value: 1, name: "Empty" },
    { id: 2, value: 2, name: "1/2" },
    { id: 3, value: 3, name: "1/4" },
    { id: 4, value: 4, name: "3/4" },
  ];

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const [maintenanceLookup, maintenanceLookupError, maintenanceLookupLoading] =
    useFetch(
      `AsmTmsEntMaintenanceActivity/GetMaintenanceActivityLookups?GetMaintenanceActivityJobUpdateId=${
        takeJobUpdateId ?? 0
      }&LueVehicleDeptId=${states?.dept?.id}&LueVehicleDeptStartDate=${
        states.dateIn
      }&LueVehicleSectionsStartDate=${states.dateIn}&LueVehicleSectionsId=${
        states.section.id
      }&pagenumber=1&pagesize=20`
    );
  console.log(maintenanceLookup);
  useEffect(() => {
    LookUps && LookUps(maintenanceLookup);
  }, [maintenanceLookup]);

  const vehReg: selectsTypes[] = [
    {
      id: 1,
      label: "Vehicle Reg. No",
      optionsData:
        states?.vehNo?.name.length > 0
          ? vehNo_data
          : maintenanceLookup?.getEntAsmTmsLueVehicleNos,
      idExpr: "vdtIdpk",
      dataExpr: "vdtRegNo",
      stateName: "vehNo",
      defaultValue: states.vehNo?.name,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      style: "",
      handleFocus: () => {},
      singleEndpoint: FetchRegistration_No,
    },
  ];

  const selects: selectsTypes[] = [
    {
      id: 1,
      label: "Maint. Type",
      optionsData:
        states?.maint_type?.name.length > 0
          ? mainT_data
          : maintenanceLookup?.getEntAsmTmsLueMaintenanceTypes,
      idExpr: "mtpIdpk",
      dataExpr: "mtpName",
      stateName: "maint_type",
      defaultValue: states.maint_type?.name,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: "isOpenDropdown2",
      style: "",
      handleFocus: () => {},
      singleEndpoint: FetchMaintainance_Type,
    },
    {
      id: 2,
      label: "Request",
      // dataExpr: "mrqDefectRequest",
      idExpr: "empId",
      dataExpr: "empNameStaffNo",
      optionsData: req_data,
      stateName: "deft",
      defaultValue: states?.deft.name,
      isOpenDropdown: states.isOpenDropdown3,
      isOpenDropdownKey: "isOpenDropdown3",
      style: "",
      handleFocus: () => {},
      singleEndpoint: FetchRequest,
    },
    {
      id: 3,
      label: "Arrival Condition",
      optionsData:
        states?.ac?.name.length > 0
          ? cond_data
          : maintenanceLookup?.getEntAsmTmsLueArrivalConditions,
      dataExpr: "mtaArrivalCondition",
      stateName: "ac",
      defaultValue: states.ac.name,
      isOpenDropdown: states.isOpenDropdown4,
      isOpenDropdownKey: "isOpenDropdown4",
      style: "",
      handleFocus: () => {},
      singleEndpoint: FetchArrivalCondition,
    },
    {
      id: 4,
      label: "Reporting Driver",
      optionsData:
        states?.rd.length > 0
          ? rdriver_data
          : maintenanceLookup?.getEntAsmTmsLueReportingDrivers,
      idExpr: "empIDpk",
      dataExpr: "empNameStaffNo",
      stateName: "rd",
      defaultValue: states.rd?.name,
      isOpenDropdown: states.isOpenDropdown5,
      isOpenDropdownKey: "isOpenDropdown5",
      style: "",
      handleFocus: () => {},
      singleEndpoint: FetchReportingDriver,
    },
  ];

  const dateFormat = "DD MMM YYYY HH: mm";

  //  Current date
  const editedDate = dayjs().format();
  //

  // Modal dialog data states

  const [refreshValue, setRefreshValue] = useState<boolean>(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const validateField = (condition, message, title) => {
    if (condition) {
      setshowModalDisableButton(true);
      setModalData({ message, title, okText: "Ok" });
      setOpenModal(true);
      setIcon("warning");
      return false;
    }
    return true;
  };

  const validateNumberField = (field, fieldName) => {
    if (isNaN(Number(field?.toString()))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: `Invalid Input for ${fieldName}!`,
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
      return false;
    }
    return true;
  };

  const validateSave = async () => {
    setIcon("warning");

    if (
      !validateField(
        states.vehNo?.id === 0 || states.vehNo === undefined,
        "Please select data entry for Vehicle Reg.No",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.dateIn === "" ||
          dayjs(states.dateIn).isAfter(editedDate) ||
          states.dateOut === editedDate ||
          states.dateOut === undefined,
        "Please enter data for DateIn",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.dateOut === "" ||
          dayjs(states.dateIn).isAfter(editedDate) ||
          states.dateOut === editedDate ||
          states.dateOut === undefined,
        "Please check calendar for DateOut, DateOut cannot be greater or equal to today",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.deft?.name === undefined ||
          states.deft.name === "" ||
          states.deft === undefined,
        "Please enter data for Request",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.maint_type?.name === undefined ||
          states.maint_type.name === "" ||
          states.maint_type === undefined,
        "Please enter data for maintenance type",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.ac?.name === "" ||
          states.ac.name === undefined ||
          states.ac === undefined,
        "Please enter data for arrival condition",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states?.dept?.name === "" || states?.dept?.name === undefined,
        "Department data required!",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states?.rd?.id === 0 || states.rd.id === undefined,
        "Please enter data for reporting driver",
        "Field Required!"
      )
    )
      return;
    if (!validateNumberField(states.odometerIn, "Odometer In")) return;
    if (!validateNumberField(states.odometerOut, "Odometer Out")) return;
    if (
      !validateField(
        states.odometerIn == 0 ||
          states.odometerIn == states.odometerOut ||
          states.odometerIn > states.odometerOut ||
          states.odometerIn === undefined ||
          states.odometerOut === undefined,
        "Please enter data for odometer, odometer in cannot be greater or equal to odometer out",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.fuelIn === undefined || states.fuelIn === "",
        "Please enter data for fuel level in",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.fuelOut === undefined || states.fuelOut === "",
        "Please enter data for fuel level Out",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        curVehAssID[0]?.vehAssignIDx === 0 ||
          curVehAssID[0]?.vehAssignIDx === null ||
          curVehAssID[0]?.vehAssignIDx === undefined,
        "This vehicle has not yet been assigned. Please assign the vehicle and proceed",
        "Required!"
      )
    )
      return;

    setshowModalDisableButton(false);
    setOpenModal(true);
    setModalData({
      message: "Are you sure you want to save this record",
      title: "Save record?",
    });
    setIcon("question");
  };
  const validateUpdate = async () => {
    setIcon("warning");

    if (
      !validateField(
        states.vehNo?.id === 0 || states.vehNo === undefined,
        "Please select data entry for Vehicle Reg.No",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.dateIn === "" ||
          dayjs(states.dateIn).isAfter(editedDate) ||
          states.dateOut === editedDate ||
          states.dateOut === undefined,
        "Please enter data for DateIn",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.dateOut === "" ||
          dayjs(states.dateIn).isAfter(editedDate) ||
          states.dateOut === editedDate ||
          states.dateOut === undefined,
        "Please check calendar for DateOut, DateOut cannot be greater or equal to today",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.deft?.name === undefined ||
          states.deft.name === "" ||
          states.deft === undefined,
        "Please enter data for Request",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.maint_type?.name === undefined ||
          states.maint_type.name === "" ||
          states.maint_type === undefined,
        "Please enter data for maintenance type",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.ac?.name === "" ||
          states.ac.name === undefined ||
          states.ac === undefined,
        "Please enter data for arrival condition",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states?.dept?.name === "" || states?.dept?.name === undefined,
        "Department data required!",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states?.rd?.id === 0 || states.rd.id === undefined,
        "Please enter data for reporting driver",
        "Field Required!"
      )
    )
      return;
    if (!validateNumberField(states.odometerIn, "Odometer In")) return;
    if (!validateNumberField(states.odometerOut, "Odometer Out")) return;
    if (
      !validateField(
        states.odometerIn === 0 ||
          states.odometerOut === 0 ||
          states.odometerIn == states.odometerOut ||
          states.odometerIn > states.odometerOut ||
          states.odometerIn === undefined ||
          states.odometerOut === undefined,
        "Please enter data for odometer, odometer in cannot be greater or equal to odometer out",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.fuelIn === undefined || states.fuelIn === "",
        "Please enter data for fuel level in",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        states.fuelOut === undefined || states.fuelOut === "",
        "Please enter data for fuel level Out",
        "Field Required!"
      )
    )
      return;
    if (
      !validateField(
        curVehAssID[0]?.vehAssignIDx === 0 ||
          curVehAssID[0]?.vehAssignIDx === null ||
          curVehAssID[0]?.vehAssignIDx === undefined,
        "This vehicle has not yet been assigned. Please assign the vehicle and proceed",
        "Required!"
      )
    )
      return;

    setshowModalDisableButton(false);
    setOpenModal(true);
    setModalData({
      message: "Are you sure you want to update this record",
      title: "Update record?",
    });
    setIcon("question");
  };

  //save button
  const saveAccessPriv = () => {
    if (savePriv === true) {
      validateSave();
      setOpenModal(true);
    } else if (
      curVehAssID[0]?.vehAssignIDx === 0 ||
      curVehAssID[0]?.vehAssignIDx === null ||
      curVehAssID[0]?.vehAssignIDx === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "This vehicle has not yet been assigned. Please assign the vehicle and proceed",
        title: "Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //update button
  const updateAccessPriv: () => void = () => {
    if (savePriv === true) {
      validateUpdate();
      setOpenModal(true);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  // Post DATA
  const postData = async () => {
    try {
      setBusyLoader("Saving Record.....");
      const res = await posting(
        `AsmTmsEntMaintenanceActivity/CreateEntMaintenanceActivity`,
        {
          mtaServiceRequestIdfk:
            openedTask.length != 0 ? openedTask[0]?.mrqIDpk : 0,
          mtaVehicleAssignmentIdfk:
            curVehAssID.length != 0 ? curVehAssID[0]?.vehAssignIDx : 0,
          mtaDateIn: states !== undefined ? dayjs(states?.dateIn).format() : 0,
          mtaDateOut:
            states !== undefined ? dayjs(states?.dateOut).format() : 0,
          mtaJobNo: states.job,
          mtaMaintenanceTypeIdfk:
            states !== undefined ? states.maint_type.id : 0,
          mtaArrivalCondition: states !== undefined ? states?.ac.name : "",
          mtaJobSummary: states !== undefined ? states?.deft.name : "",
          mtaSpeedoIn: states !== undefined ? states?.odometerIn : 0,
          mtaSpeedoOut: states !== undefined ? states?.odometerOut : 0,
          mtaFuelLevelInIdfk: states !== undefined ? states?.fuelIn : 0,
          mtaFuelLevelOutIdfk: states !== undefined ? states?.fuelOut : 0,
          mtaDeptIdfk: states !== undefined ? states?.dept.id : 0, //states.dept

          mtaSectionIdfk: states?.section.id,
          mtaRmks: states !== undefined ? states?.remarks : "",
          mtaCreatedBy: employeeId,
        },
        `Created the Asset Mgr-Trans-Maintenance Activity with RegNo ${states.vehNo.name} and Job No ${states.job} on ${states.dateIn} - ${states.dateOut} with ${states.maint_type.name} maintenance type with the Report Driver being ${states.rd.name} at the ${states.dept.name} Department and ${states.section.name} section successfully`
      );
      const id = res.data.id;
      const requesterMessge = `Hello ${openedTask[0]?.requestedByFname}, please be informed that the Job Card number${states.job} has been opened for your vehicle maintenance request with ID ${id}`;
      const alertMessg = `Job Number ${states.job} has been opened for your vehicle maintenance request with ID ${id}`;
      await posting(
        `SMSAlert/SaveSMS`,
        {
          imsMessage: requesterMessge,
          imsRecipientEmpIdfk: openedTask[0]?.requestedByEmpID,
          imsSender: userInfo.empName,
          imsRecordIdfk: id,
          imsMessageType: "string",
          imsMessageClass: "string",
          imsInitiatorEmpIdfk: userId,
          imsCreatedBy: userId,
        },
        ""
      );
      await posting(
        `USCXGenAlerts/SaveAlert`,
        {
          altMessage: alertMessg,
          altTakeAction: true,
          recipientEmpID: openedTask[0]?.requestedByEmpID,
        },
        ``
      );

      setRefreshValue(!refreshValue);
      setrefreshx && setrefreshx(!refreshValue);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setBusyLoader("");
      //
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Failed to Save Record!..Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    try {
      setBusyLoader("Updating Record.....");
      await updating(
        `AsmTmsEntMaintenanceActivity/UpdateEntMaintenanceActivity`,
        {
          mtaIdpk: selectedRecord?.mtaIDpk,
          mtaServiceRequestIdfk:
            openedTask.length !== 0 ? openedTask[0].mrqIDpk : 0,
          mtaVehicleAssignmentIdfk:
            curVehAssID.length !== 0 ? curVehAssID[0].vehAssignIDx : 0,
          mtaDateIn: states !== undefined ? dayjs(states.dateIn).format() : 0,
          mtaDateOut: states !== undefined ? dayjs(states.dateOut).format() : 0,
          mtaJobNo: states.job,
          mtaMaintenanceTypeIdfk:
            states !== undefined ? states.maint_type.id : 0,
          mtaArrivalCondition: states !== undefined ? states.ac.name : "",
          mtaJobSummary: states !== undefined ? states.deft.name : "",
          mtaSpeedoIn: states !== undefined ? states.odometerIn : 0,
          mtaSpeedoOut: states !== undefined ? states.odometerOut : 0,
          mtaFuelLevelInIdfk: states !== undefined ? states.fuelIn : 0,
          mtaFuelLevelOutIdfk: states !== undefined ? states.fuelOut : 0,
          mtaDeptIdfk: states !== undefined ? states.dept.id : 0, //states.dept
          mtaSectionIdfk: states.section.id,
          mtaRmks: states !== undefined ? states.remarks : "",
          mtaEditedBy: employeeId,
        },
        `Closed the Asset Mgr-Trans-Maintenance Activities form  with RegNo ${states.vehNo} and Job No ${states.job} on ${states.dateIn} - ${states.dateOut} with ${states.maint_type} maintenance type with the Report Driver being ${states.rd} at the ${states.dept} Department and ${states.section} section successfully`
      );
      setRefreshValue(!refreshValue);
      setrefreshx && setrefreshx(!refreshValue);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry updated successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setBusyLoader("");
      //
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Failed to update Record!..Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  if (openedTask !== undefined) {
    //
  }
  useEffect(() => {
    populateFields(false);
    setState("workshop", {
      id: openedTask[0]?.wsdIDpk ?? selectedRecord?.wsdIDpk,
      name: openedTask[0]?.wsdName ?? selectedRecord?.wsdName,
    });
    // populateFields(false);
  }, [openedTask, selectedRecord]);
  useEffect(() => {
    populateFields(false);
    FuelLevels();
  }, [openedTask]);

  useEffect(() => {
    // setState("dept", {
    //   id: dept_data[0]?.dptIdpk ?? selectedRecord?.dptIDpk,
    //   name: dept_data[0]?.dptShtName ?? selectedRecord?.dptShtName,
    // });
    // setState("section", {
    //   id: sections_data[0]?.sxnShtIdpk ?? selectedRecord?.sxnIDpk,
    //   name: sections_data[0]?.sxnShtName ?? selectedRecord?.sxnShtName,
    // });
    setState("job", jobNums?.txtJobNo ?? selectedRecord?.mtaJobNo);
  }, [dept_data, sections_data, jobNums, selectedRecord]);

  //Access Priviledge

  const [privType] = useState<string>("save");
  const [savePriv] = useFormPriviledge(privType);

  const dates: any[] = [
    { id: 0, label: "Arrival Time ", stateName: "", defaultValue: "" },
    { id: 1, label: "Departure Time ", stateName: "", defaultValue: "" },
  ];

  const inputs: any[] = [
    {
      id: 0,
      label: "Odometer In ",
      stateName: "",
      defaultValue: "",
      style: "",
      meterholder: "",
    },
    {
      id: 1,
      label: "Odometer Out ",
      stateName: "",
      defaultValue: "",
      style: "",
      meterholder: "0km",
    },
  ];

  const SaveForm = () => (
    <Form
      className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
      name="basic"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 10 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      size="small"
      layout="horizontal"
    >
      <div className="w-full h-full ">
        <SelectsTemplate
          label="Reg. No"
          selectStyle={{ width: 212 }}
          disabled={false}
          options={vehNo_data}
          useCallFunc
          idexpr={"vdtIDpk"}
          dataexp={"vdtRegNo"}
          placeHolder={states.vehNo?.name}
          selectedValue={(e) => {
            setState("vehNo", {
              id: e["vdtIDpk"],
              name: e["vdtRegNo"],
            });
          }}
        />

        {dates.map(({ id, label, stateName, defaultValue, style }) => {
          return (
            <>
              <DateTemplate key={id + "da"} label={label} disabled={false} />
            </>
          );
        })}

        {inputs.map(
          ({ id, label, stateName, defaultValue, style, meterholder }) => {
            return (
              <>
                <InputsTemplate
                  useCallbackFunc
                  span
                  disabledStatus={false}
                  labelColPan={5}
                  numberOnly={true}
                  key={id}
                  label={label}
                  defaultValue={defaultValue}
                  inputStyle={{ width: 300 }}
                  additionalWidget={() => (
                    <p className="flex w-full bg-red-500">{meterholder}</p>
                  )}
                />
              </>
            );
          }
        )}

        <Form.Item
          style={{ width: "calc(100%)" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          label={
            <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
              {"Fuel Guage In"}
            </p>
          }
        >
          <div className="w-full flex flex-wrap justify-evenly items-center border-[1px] mb-0.5 rounded-md">
            <Radio.Group
              value={states.fuelIn}
              className=" gap-[6rem] w-full grid grid-cols-8 px-[5px] items-center  "
              onChange={(e) => {
                setState("fuelIn", e.target.value);
              }}
            >
              {!fuelLevels
                ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                    return (
                      <Radio key={index} value={fuelLevel.value}>
                        {fuelLevel.name}
                      </Radio>
                    );
                  })
                : fuelLevels?.map((fuelLevel: any, index: number) => {
                    return (
                      <Radio key={fuelLevel.flvIdpk} value={fuelLevel.flvIdpk}>
                        {fuelLevel.flvName}
                      </Radio>
                    );
                  })}
            </Radio.Group>
          </div>
        </Form.Item>
        <Form.Item
          style={{ width: "calc(100%)" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          label={
            <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
              {"Fuel Guage Out"}
            </p>
          }
        >
          <div className="w-full flex flex-wrap justify-evenly border-[1px] mb-0.5 rounded-md">
            <Radio.Group
              value={states.fuelOut}
              className=" gap-[6rem] w-full grid grid-cols-8 px-[5px] items-center "
              onChange={(e) => {
                setState("fuelOut", e.target.value);
              }}
            >
              {!fuelLevels
                ? defaultFuelLevel
                    .slice(0, 4)
                    ?.map((fuelLevel: any, index: number) => {
                      return (
                        <Radio key={index} value={fuelLevel.value}>
                          {fuelLevel.name}
                        </Radio>
                      );
                    })
                : fuelLevels
                    .slice(0, 4)
                    ?.map((fuelLevel: any, index: number) => {
                      return (
                        <Radio
                          key={fuelLevel.flvIdpk}
                          value={fuelLevel.flvIdpk}
                        >
                          {fuelLevel.flvName}
                        </Radio>
                      );
                    })}
            </Radio.Group>
          </div>
        </Form.Item>

        <div className="w-full flex justify-end py-0.5">
          <UpdateButton />
        </div>
      </div>
    </Form>
  );

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Transport - Update Odometer Readings",
      module: <SaveForm />,
    },
  ];

  return (
    <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 ">
      <ModalTemplate
        icon={icon}
        cancelText={modalData?.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData?.title === "Save record?"
            ? postData()
            : modalData?.title === "Update record?"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData?.message}
        okText={modalData?.okText}
        title={modalData?.title}
        disableCancel={icon === "question" ? false : true}
      />

      <FormModal
        height={1000}
        width={"30%"}
        openState={states.saveOdoModals}
        onCancel={() => {
          setState("saveOdoModals", false);
        }}
        child={formModal[0].module}
        title={formModal[0].label}
      />

      <div className="px-2 h-full">
        <Form
          className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-0.5  "
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="w-full h-full">
            {/* Block */}

            {vehReg.map(
              ({
                id,
                label,
                idExpr,
                dataExpr,
                optionsData,
                stateName,
                defaultValue,
                isOpenDropdown,
                isOpenDropdownKey,
                singleEndpoint,
              }) => {
                return (
                  <>
                    <InputSelect_template
                      key={id}
                      placeHolder={defaultValue}
                      handleRefresh={() => {
                        setState(stateName!, {
                          id: 0,
                          name: "",
                        });
                      }}
                      label={label}
                      disabledCustomWidth={true}
                      cusWidth={"197px"}
                      disabled={form_disable}
                      options={optionsData}
                      useCallFunc
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      isLoading={states.isLoading}
                      isOpenDropdown={isOpenDropdown}
                      handleSearch={ 
                        debounce((e) => {
                          setState(stateName!, {
                            id: e[idExpr!],
                            name: e[dataExpr!],
                          });
                          setState(isOpenDropdownKey, true);
                          setState("isLoading", true);
                          // setState('isLoading',true);
                          dispatch(singleEndpoint(e));
                        }, 500) 
                      }
                      handleFocus={() => {
                        setState(isOpenDropdownKey, !isOpenDropdown);
                      }}
                      selectedValue={(e) => {
                        setState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                        setState(isOpenDropdownKey, false);
                      }}
                    />
                    {/* <SelectsTemplate
                      cusWidth={"197px"}
                      disabledCustomWidth={true}
                      label={label}
                      options={optionsData}
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      placeHolder={defaultValue}
                      useCallFunc
                      disabled={form_disable}
                      selectedValue={(e) => {
                        setState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                      }}
                      handleRefresh={() => {
                        setState(stateName!, {
                          id: 0,
                          name: "",
                        });
                      }}
                    /> */}
                  </>
                );
              }
            )}

            <Form.Item
              style={{ width: "calc(50%)" }}
              className=" "
              labelCol={{ span: 8 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Date In"}
                </p>
              }
            >
              <div className="w-full  flex">
                <DatePicker
                  allowClear={false}
                  format={dateFormat}
                  // showTime={{ defaultValue: dayjs("00:00:00", "HH:mm") }}
                  className="w-full"
                  onChange={(date, dateString) => {
                    setState("dateIn", dateString);
                  }}
                  value={dayjs(states.dateIn)}
                  disabled={form_disable}
                />
              </div>
            </Form.Item>

            {/* Date */}
            <Form className="w-full flex ">
              <Form.Item
                style={{ width: "calc(50%)" }}
                className=""
                labelCol={{ span: 8 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {" Date Out"}
                  </p>
                }
              >
                <div className="w-full flex">
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    className="w-full"
                    onChange={(date, dateString) => {
                      setState("dateOut", dateString);
                    }}
                    value={dayjs(states.dateOut)}
                    disabled={form_disable}
                  />
                </div>
              </Form.Item>

              <Form.Item
                style={{ width: "calc(50%)" }}
                className=""
                wrapperCol={{ span: 15 }}
                labelCol={{ span: 7 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Job No"}
                  </p>
                }
              >
                <div className="w-full flex">
                  <Input
                    value={states.job}
                    disabled={true}
                    className="w-full"
                  />
                </div>
              </Form.Item>
            </Form>

            {/* Selects */}

            <>
              {selects.map(
                ({
                  id,
                  label,
                  idExpr,
                  dataExpr,
                  optionsData,
                  stateName,
                  defaultValue,
                  isOpenDropdown,
                  isOpenDropdownKey,
                  singleEndpoint,
                }) => {
                  return (
                    <>
                      <InputSelect_template
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={defaultValue}
                        labelCol={4}
                        label={label}
                        options={optionsData}
                        idexpr={idExpr}
                        dataexp={dataExpr}
                        handleSearch={ 
                          debounce((e) => {
                            setState(stateName!, e);
                            dispatch(singleEndpoint(e));
                            // setState('isLoading',true);
                            setState(isOpenDropdownKey, true);
                          }, 500) 
                        }
                        isLoading={states.isLoading}
                        isOpenDropdown={isOpenDropdown}
                        handleRefresh={() => {
                          setState(stateName!, {
                            id: 0,
                            name: "",
                          });
                        }}
                        handleFocus={() => {
                          setState(isOpenDropdownKey!, !isOpenDropdown);
                        }}
                        selectedValue={(e) => {
                          setState(stateName!, {
                            id: e[idExpr!],
                            name: e[dataExpr!],
                          });
                          setState(isOpenDropdownKey!, false);
                        }}
                      />
                      {/* <SelectsTemplate
                        labelCol={4}
                        label={label}
                        options={optionsData}
                        idexpr={idExpr}
                        dataexp={dataExpr}
                        placeHolder={defaultValue}
                        useCallFunc
                        disabled={form_disable}
                        selectedValue={(e) => {
                          setState(stateName!, {
                            id: e[idExpr!],
                            name: e[dataExpr!],
                          });
                        }}
                      /> */}
                    </>
                  );
                }
              )}
            </>

            {/* Debit Dept/ Section */}
            <Form className="w-full flex ">
              <Form.Item
                style={{ width: "calc(51%)" }}
                className=""
                labelCol={{ span: 8 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Debit Dept/Section"}
                  </p>
                }
              >
                <div className="w-full flex">
                  <Select
                    placeholder="Dept"
                    value={states.dept.name}
                    onChange={(value: any) => {
                      setState("dept", {
                        id: JSON.parse(value).dptIdpk,
                        name: JSON.parse(value).dptShtName,
                      });
                    }}
                    disabled={true}
                  >
                    {dept_data?.map((dp: any, index: number) => {
                      return (
                        <Select.Option key={index} value={JSON.stringify(dp)}>
                          {dp["dptShtName"]}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  {/* <InputSelect_template
                    placeHolder={states.dept.name}
                    useCallFunc
                    idexpr="dptIdpk"
                    dataexp="dptShtName"
                    options={dept_data}
                    selectedValue={(value) => {
                      console.log('!@#$',value)
                      setState("dept", {
                        id: JSON.parse(value).dptIdpk,
                        name: JSON.parse(value).dptShtName,
                      });
                      setState('isOpenDropdown6', false);
                    }}
                  /> */}
                </div>
              </Form.Item>

              <Form.Item style={{ width: "calc(49%)" }} className="ml-3">
                <div className="w-full flex">
                  <Select
                    value={states.section.name}
                    placeholder="Section"
                    onChange={(value: any) => {
                      setState("section", {
                        id: JSON.parse(value).sxnIdpk,
                        name: JSON.parse(value).sxnShtName,
                      });
                    }}
                    disabled={true}
                  >
                    {sections_data?.map((sxn: any, index: number) => {
                      return (
                        <Select.Option key={index} value={JSON.stringify(sxn)}>
                          {sxn["sxnShtName"]}
                        </Select.Option>
                      );
                    })}
                  </Select>

                  <span
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 28,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span>
                </div>
              </Form.Item>
            </Form>

            {/* JOB */}
            <Form className="w-full  flex">
              <Form.Item
                style={{ width: "calc(100%)" }}
                className=" "
                labelCol={{ span: 4 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Odometer In/Out"}
                  </p>
                }
              >
                <div className="w-full  flex">
                  <div style={{ width: "calc(72%)" }} className="mr-2">
                    <Input
                      value={states.odometerIn}
                      disabled={form_disable}
                      placeholder=" Input odometer in"
                      className="w-full  "
                      onChange={(e) => {
                        setState("odometerIn", e.target.value);
                        setState("odometerOut", e.target.value);
                      }}
                    />
                  </div>

                  <div style={{ width: "calc(65%)" }} className="mr-2">
                    <Input
                      disabled={form_disable}
                      value={states.odometerOut}
                      placeholder="Input odometer out"
                      className="w-full "
                      onChange={(e: any) => {
                        setState("odometerOut", e.target.value);
                        setState("debit3", e.target.value - states.odometerIn);
                      }}
                    />
                  </div>

                  <div style={{ width: "calc(35%)" }} className=" flex mb-0 ">
                    <Input
                      disabled={form_disable}
                      style={{ height: "21px" }}
                      className="w-full"
                      placeholder="Odometer In"
                      value={(states.debit3 || 0) + " km"}
                    />
                    {/* <button
                      onClick={(e) => {
                        setState("saveOdoModals", true);
                        e.preventDefault();
                      }}
                      // disabled={false}
                      style={{ height: 22.5, borderWidth: 1, width: 30.9 }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base  w-5 h-5 px-1 flex flex-row items-center justify-end  ml-0.5  border-gray-300 rounded hover:border-blue-300 cursor-pointer"
                    >
                      <img className="" src={save} />
                    </button> */}
                  </div>
                  {/* <div style={{width:'calc(10%)'}} className=''>
                                           
                                        </div> */}
                </div>
              </Form.Item>
            </Form>
            <Form.Item
              style={{ width: "calc(100%)" }}
              labelCol={{ span: 4 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Fuel Guage In"}
                </p>
              }
            >
              <div className="w-full flex  border-[1px] mb-0.5 rounded-md h-[50px] ">
                <Radio.Group
                  value={states.fuelIn}
                  disabled={form_disable}
                  className=" w-full   flex-wrap flex justify-center  "
                  onChange={(e) => {
                    setState("fuelIn", e.target.value);
                  }}
                >
                  {!fuelLevels
                    ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                        return (
                          <Radio
                            key={index}
                            value={fuelLevel.value}
                            className=" p-3"
                          >
                            {fuelLevel.name}
                          </Radio>
                        );
                      })
                    : fuelLevels?.map((fuelLevel: any, index: number) => {
                        return (
                          <Radio
                            key={fuelLevel.flvIdpk}
                            value={fuelLevel.flvIdpk}
                            className=" p-3"
                          >
                            {fuelLevel.flvName}
                          </Radio>
                        );
                      })}
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item
              style={{ width: "calc(100%)" }}
              labelCol={{ span: 4 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Fuel Guage Out"}
                </p>
              }
            >
              <div className="w-full flex justify-center  border-[2px] mb-0.5 rounded-md h-[50px]">
                <Radio.Group
                  disabled={form_disable}
                  value={states.fuelOut}
                  className=" w-full p-[2px]  flex-wrap flex justify-center  "
                  onChange={(e) => {
                    setState("fuelOut", e.target.value);
                  }}
                >
                  {!fuelLevels
                    ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                        return (
                          <Radio
                            key={index}
                            value={fuelLevel.value}
                            className=" p-3"
                          >
                            {fuelLevel.name}
                          </Radio>
                        );
                      })
                    : fuelLevels?.map((fuelLevel: any, index: number) => {
                        return (
                          <Radio
                            key={fuelLevel.flvIdpk}
                            value={fuelLevel.flvIdpk}
                            className=" p-3"
                          >
                            {fuelLevel.flvName}
                          </Radio>
                        );
                      })}
                </Radio.Group>
              </div>
            </Form.Item>

            {/* Remarks */}
            <Form.Item
              style={{ width: "calc(100%)" }}
              labelCol={{ span: 4 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Remarks"}
                </p>
              }
            >
              <div className="w-full flex ">
                <div style={{ width: "calc(100%)" }} className="h-[70px]">
                  <TextArea
                    value={states.remarks}
                    disabled={form_disable}
                    style={{ resize: "none", height: "65px", padding: "4px" }}
                    onChange={(e) => {
                      setState("remarks", e.target.value);
                    }}
                  />
                </div>
              </div>
            </Form.Item>

            <div className="w-full flex  items-center justify-between mb-0.5 ">
              <ul className="w-full flex items-center justify-end">
                {update_state_change ? (
                  <UpdateButton
                    handleUpdate={() => {
                      updateAccessPriv();
                    }}
                  />
                ) : (
                  <SaveButton
                    disableButton={form_disable}
                    handleSave={() => {
                      saveAccessPriv();
                    }}
                  />
                )}
              </ul>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
