import dayjs from "dayjs";

export const elogbook_columns = (showDate: boolean) => [
  {
    id: 1,
    caption: "LOG ID",
    dataField: "lbkIDpk",
    // dataType: "number",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "EVENT DATE",
    dataField: "lbkLogTime",
    dataType: "date",
    format:  "dd MMM yyyy", 
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "TIME",
    dataField: "lbkLogTime", 
    dataType: "datetime",
    format: "HH:mm:ss",
    width: 60,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "TYPE",
    dataField: "evtShtName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "LOG",
    dataField: "lbkEvent",
    dataType: "string",
    width: 500,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "RPTN.STA",
    dataField: "reportingStationName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "CORRESPONDING",
    dataField: "correspondentName2",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "LOGGED BY",
    dataField: "loggedByName2",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 9,
    caption: "HOME STATION",
    dataField: "homeStationName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "COMM?",
    dataField: "lbkComment",
    dataType: "boolean",
    width: 60,
    fixed: true,
    visible: true,
  },
  {
    id: 11,
    caption: "FLAG?",
    dataField: "lbkFlagged",
    dataType: "boolean",
    width: 60,
    fixed: true,
    visible: true,
  },
  {
    id: 12,
    caption: "LOG DATE",
    dataField: "lbkLogDate",
    dataType: "date",
    format:  "dd MMM yyyy", 
    width: 200,
    fixed: true,
    visible: showDate,
  },
  {
    id: 13,
    caption: "SYNCED?",
    dataField: "lbkSynced",
    dataType: "boolean",
    width: 60,
    fixed: true,
    visible: true,
  },
  {
    id: 13,
    caption: "PREVIEWED?",
    dataField: "lbkPreviewed",
    dataType: "boolean",
    width: 60,
    fixed: true,
    visible: true,
  },
];

export const elogbook_columns2 = [
  // Station
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "ACTIVE",
    dataField: "stnActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "OPS?",
    dataField: "stnOperational",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "REMARKS",
    dataField: "",
    dataType: "string",
    width: 400,
    fixed: false,
    visible: true,
  },
];

export const elogbook_columns3 = [
  // Staff List
  {
    id: 1,
    caption: "STAFF NAME",
    dataField: "empName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Remarks",
    dataField: "",
    dataType: "string",
    width: 400,
    fixed: false,
    visible: true,
  },
];

export const elogbook_columns4 = [
  // Staff Shift Members
  {
    id: 1,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "STAFF NAME",
    dataField: "empName", //missen in api data
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "LOC",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "INTERCOM",
    dataField: "",
    dataType: "string",
    width: 400,
    fixed: false,
    visible: true,
  },
];
export const elogbook_columns5 = [
  {
    id: 1,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "STAFF Name",
    dataField: "empName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "Substation",
    dataField: "stnName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "Shift LDR?",
    dataField: "pvaShiftLeader",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "LOG?",
    dataField: "pvaLog",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "View?",
    dataField: "pvaView",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "Home?",
    dataField: "pvaHome",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "DEF.Home?",
    dataField: "pvaDefaultHome",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 9,
    caption: "IRSC",
    dataField: "pvaInitiateRemoteShiftChange",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "TOS",
    dataField: "pvaTakeOverShift",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 11,
    caption: "Flag?",
    dataField: "pvaFlag",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 12,
    caption: "Active",
    dataField: "pvaActive",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 13,
    caption: "SHIFT MEM.",
    dataField: "pvaShiftMember",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "MS?",
    dataField: "stnMasterStation",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "NCC?",
    dataField: "stnNationalControlCenter",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "Operational?",
    dataField: "stnOperational",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "DATE ASSIGNED",
    dataField: "",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "DATE UNASSIGNED",
    dataField: "",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "OPERATOR LOC",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "REMARKS",
    dataField: "pvaRmks",
    dataType: "string",
    width: 400,
    fixed: false,
    visible: true,
  },
];
