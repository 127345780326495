import { datagridColumnTypes } from "../../../../../../../human-resource/setups/data/types/selectsTypes";

export const TROUBLEREPORT_COLUMNS = [
  {
    id: 0,
    caption: "ID",
    dataField: "tadIDpk",
    allignment: "left",
    fixed: false,
    width: 100,
    dataType: "string",
    visible: false,
  },
  {
    id: 1,
    caption: "NAME",
    dataField: "tadName",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 300,
  },
  {
    id: 2,
    caption: "SERIAL NO",
    dataField: "tadSerialNo",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "EQP. CODE/REG No",
    dataField: "tadCode",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "ORDER",
    dataField: "tadOrder",
    allignment: "left",
    // dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "ACTIVE",
    dataField: "tadActive",
    allignment: "left",
    dataType: "boolean",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "MANUFACTURER",
    dataField: "mftName",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 100,
  },

  {
    id: 7,
    caption: "ASSET TYPE",
    dataField: "tatName",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 70,
  },

  {
    id: 8,
    caption: "ASSET CLASS",
    dataField: "tacName",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 70,
  },
  {
    id: 9,
    caption: "MANUF. DATE",
    dataField: "tadManufactureDate",
    allignment: "left",
    dataType: "datetime",
    fixed: false,
    width: 70,
  },
  {
    id: 10,
    caption: "INSTALLATION DATE",
    dataField: "tadInstallationDate",
    allignment: "left",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "END OF LIFE DATE",
    dataField: "tadEndOfLifeDate",
    allignment: "left",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "PURCHASE PRICE",
    dataField: "tadPurchaseCost",
    allignment: "left",
    // dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 13,
    caption: "OPERATIONAL COST",
    dataField: "tadOperationalCost",
    allignment: "left",
    // dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 14,
    caption: "DEPRECIATION",
    dataField: "tadDepreciation",
    allignment: "left",
    // dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 15,
    caption: "LOC. COORDINATES",
    dataField: "tadLocationCoordinates",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 16,
    caption: "REMARKS",
    dataField: "tadRmks",
    allignment: "left",
    dataType: "string",
    fixed: false,
    width: 100,
  },
];
