import React from 'react'
import calendar from '../../../assets/calendar_32x32.png'
import today from '../../../assets/today_16x16.png'
import dayview from '../../../assets/adateoccurring_16x16.png'
import month from '../../../assets/month-calendar.png'
import reload from '../../../assets/reload.png'
import forward from '../../../assets/shape.png'
import backward from '../../../assets/shape (1).png'
import ZoomIn from '../../../assets/zoom-in.png'
import ZoomOut from '../../../assets/zoom-out.png'
import timeline from '../../../assets/timelineview_32x32.png'


export const  CalendarManagementToolbar = () => {
  return (
    <div className='border-[1px] rounded-md'>
        <div className="w-full flex ">
          <div className='border-1 h-full '>
           <div className='flex'>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
                <p className='text-[10px] font-medium text-center'>New Appointment</p>
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={reload} />
                </div>
               <p className='text-[10px] font-medium text-center'>New Recurring Appointment </p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
            </div>
            <p className= "border-t-[1px] text-center">Appointment</p>
            </div>
           
            <div className='border-[1px]'>
            <div className='flex'>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={forward} />
                </div>
               <p  className='text-[10px] font-medium text-center'>Forward</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={backward} />
                </div>
               <p className='text-[10px] font-medium text-center'>Backward </p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
           
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={today} />
                </div>
               <p className='text-[10px] font-medium text-center'>Go to 
                Today</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center"
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={ZoomIn} />
                </div>
               <p className='text-[10px] font-medium text-center'>Zoom In</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={ZoomOut} />
               </div>
               <p className='text-[10px] font-medium text-center'>Zoom Out</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
            </div>
            <p className='border-t-[1px] text-center'> Navigate</p>
            </div>

            <div className='border-[1px]'>
              <div className='flex'>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center"
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={dayview} />
                </div>
               <p className='text-[10px] font-medium text-center'>Day View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
            <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center"
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Work Week View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Full Week View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={month} />
                </div>
               <p className='text-[10px] font-medium text-center'>Month View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={timeline} />
                </div>
               <p className='text-[10px] font-medium text-center'>Timeline View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Gantt View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Agenda View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Year View</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
            </div>
            <p className="border-t-[1px] text-center">Arrange</p>
            </div>

            <div className='border-[1px]'>
              <div className='flex'>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Group by None</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px] justify-center" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Group by Date</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
        <li
              id=""
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex justify-center "
            >
              <span className=" dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <div className='flex justify-center'>
                <img className="pr-1 w-[30px]" src={calendar} />
                </div>
               <p className='text-[10px] font-medium text-center'>Group by Resource</p> 
              </span>
              {/* <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip> */}
            </li>
            </div>
            <p className='border-t-[1px] text-center'>Group By</p>
            </div>
        </div>
    </div>
  )
}
