/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect, useRef, useCallback } from "react";
import { Form } from "antd";
import {
  Cancel,
  SaveButton,
  NewButton,
  UpdateButton,
  Recommend,
} from "../../../../../../accessories/buttons";
import {
  inputsTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import dayjs, { Dayjs } from "dayjs";
import { DateTemplate } from "../../../../../../../templates/date";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { useDispatch, useSelector } from "react-redux";
import { task_footer_table_selected } from "../../../../../../../features/forms/entries/safetyManager/ppeRequest";
import { RootState } from "../../../../../../../app/store";
import { ModalTemplate } from "../../../../../../../templates/modal";
import useFetch from "../../../../../../../hooks/useFetch";
import { Tooltip } from "devextreme-react";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { recomBy_cols, reqBy_cols, reqWind_cols } from "../data/datagrid_cols";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { task_footer_update } from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { InputsTemplate } from "../../../../../../../templates/input";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { Fetch_VehicleRegNo } from "../../../../../../../features/apiCalls/FuelTopupRequest";
import React from "react";
import { useAxiosService } from "../../../../../../../general/requestConfig";

export interface LookupResponseType {
  requestWindows: RequestWindow[];
  requestTypes: RequestType[];
  requestedBys: EdBy[];
  recommendedBys: EdBy[];
  getVehicles: GetVehicle[];
}

export interface GetVehicle {
  vdtIDpk: number;
  vdtRegNo: string;
  vhcShtName: null;
  vmkShtName: null;
  mftShtName: string;
  mdlShtName: null;
  ftpShtName: string;
  vdtSeatingCapacity: number;
  vdtTankCapacity: number;
  vdtSupplyDate: Date;
  vdtManufactureDate: Date;
  vstName: string;
  vdtActive: boolean;
  dptShtName: string;
  sxnShtName: string;
  untShtName: string;
  driverEmpName5: string;
  driverPlc: null | string;
  ownerEmpName5: string;
  ownerPlc: string;
}

export interface EdBy {
  empIDpk: number;
  empStaffNo: string;
  empName: null | string;
  dptShtName: string;
  sxnShtName: string;
  locShtName: string;
  jbtShtName: string;
}

export interface RequestType {
  fttIDpk: number;
  fttName: string;
}

export interface RequestWindow {
  ftwIDpk: number;
  ftwName: string;
  ftwStartDate: Date;
  ftwEndDate: Date;
}

interface stateTypes {
  rw?: { id: number; name: string } | any;
  rt?: { id: number; name: string } | any;
  date: string;
  recom?: { id: number; name: string } | any;
  reqby?: { id: number; name: string } | any;
  vehreg?: { id: number; name: string } | any;
  quan1?: number;
  quan2?: number;
  quan3?: number;
  purpose?: string;
  leftRemarks?: string;
  qtyAprv?: string;
  qtyTopup?: string;
  debit?: { id: number; name: string } | any;
  cost?: { id: number; name: string } | any;
  rightRemarks?: string;
  cancelRemarks?: string;
  requestWindowRefresh: boolean;
  requestType: boolean;
  requestedBy: boolean;
  recomendedBy: boolean;
  vehicleReg: boolean;
  isOpenDropdown1: boolean;
  isOpenDropdown2: boolean;
  isOpenDropdownKey1: string;
  isOpenDropdownKey2: string;
}

interface props {
  selectedRecordx?: any;
  setSelectedRecordx?: (e: any) => void;
  setRefreshx?: (e: any) => void;
  statesx?: any;
}

export const Topup_Requisition_Details_Form = ({
  selectedRecordx,
  setSelectedRecordx,
  statesx,
  setRefreshx,
}: props) => {
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);
  const axiosGo = useAxiosService();

  const dispatch = useDispatch();
  const dropdownRef = useRef<any>(null);
  const dropdownRef2 = useRef<any>(null);
  const dropdownRef3 = useRef<any>(null);

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const formCode = useSelector((state: RootState) => state.user?.formCode);
  const update_state_change = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const Fetch_VehicleRegNo_Data = useSelector(
    (state: RootState) => state.FuelTopupRequestSlice.vehicle_regno
  );
  const editedDate = dayjs().format();

  const [states, setStates] = useState<stateTypes>({
    rw: { id: 0, name: "" },
    rt: { id: 0, name: "" },
    date: editedDate,
    recom: { id: 0, name: "" },
    reqby: { id: 0, name: "" },
    vehreg: { id: 0, name: "" },
    quan1: 0,
    quan2: 0,
    quan3: 0,
    purpose: "",
    leftRemarks: "",
    cancelRemarks: "",
    requestWindowRefresh: false,
    requestType: false,
    requestedBy: false,
    recomendedBy: false,
    vehicleReg: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdownKey1: "isOpenDropdown1",
    isOpenDropdownKey2: "isOpenDropdown2",
  });

  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [refreshValue, setRefreshValue] = useState<boolean>(false);

  const [reqWindData] = useFetch(
    `FuelTopUpRequest/GetRequestWindows`,
    states.requestWindowRefresh
  );
  const [reqTypeData] = useFetch(
    `FuelTopUpRequest/GetRequestTypes`,
    states.requestType
  );
  const [recomData] = useFetch(
    `FuelTopUpRequest/GetRecommendBy`,
    states.recomendedBy
  );
  const [vehData] = useFetch(
    `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos`,
    states.vehicleReg
  );
  const [reqByData] = useFetch(
    `FuelTopUpRequest/GetRequestByAll`,
    states.requestedBy
  );
  const [Lookup, setLookup] = React.useState<LookupResponseType>();
  const [loading, setLoading] = React.useState<boolean>();

  React.useEffect(() => {
    (() => {
      setLoading(true);
      axiosGo({
        url: `FuelTopUpRequest/GetEntryLookups`,
        params: { pagenumber: 1, pagesize: 20 },
        method: "GET",
      }).then((res) => {
        setLookup(res);
      });
      setLoading(false);
    })();
  }, []);
  const selects1: selectsTypes[] = [
    {
      id: 0,
      label: "Request Window",
      optionsData: reqWindData,
      dataExpr: "ftwName",
      idExpr: "ftwIDpk",
      stateName: "rw",
      refreshStateName: "requestWindowRefresh",
      defaultValue: states.rw?.name,
      style: "209px",
      refresh: states.requestWindowRefresh,
    },
    {
      id: 1,
      label: "Request Type",
      optionsData: reqTypeData,
      dataExpr: "fttName",
      idExpr: "fttIDpk",
      stateName: "rt",
      refreshStateName: "requestType",
      defaultValue: states.rt?.name,
      style: "",
      refresh: states.requestType,
    },
  ];

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Requested On",
      stateName: "date",
      defaultValue: states.date,
      style: "210px",
    },
  ];

  const selects2: selectsTypes[] = [
    {
      id: 0,
      label: "Requested By",
      optionsData: reqByData,
      dataExpr: "empName",
      idExpr: "empIDpk",
      stateName: "reqby",
      refreshStateName: "requestedBy",
      defaultValue: states.reqby?.name,
      style: "209px",
      refresh: states.requestedBy,
    },
    {
      id: 1,
      label: "Recommended By",
      optionsData: recomData,
      dataExpr: "empName",
      idExpr: "empIDpk",
      stateName: "recom",
      refreshStateName: "recomendedBy",
      defaultValue: states.recom?.name,
      style: "209px",
      refresh: states.recomendedBy,
    },
    {
      id: 2,
      label: "Vehicle Reg.No",
      optionsData:
        states.vehreg?.length > 0
          ? Fetch_VehicleRegNo_Data
          : Lookup?.getVehicles,
      dataExpr: "vdtRegNo",
      idExpr: "vdtIdpk",
      stateName: "vehreg",
      refreshStateName: "vehicleReg",
      defaultValue: states.vehreg?.name,
      singleEndpoint: Fetch_VehicleRegNo,
      style: "",
      refresh: states.vehicleReg,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: states.isOpenDropdownKey1,
    },
  ];

  const textAreas: inputsTypes[] = [
    {
      id: 0,
      label: "Purpose",
      stateName: "purpose",
      defaultValue: states?.purpose,
      style: 40,
    },
    {
      id: 1,
      label: "Remarks",
      stateName: "leftRemarks",
      defaultValue: states?.leftRemarks,
      style: 40,
    },
  ];

  const CancellationModalData = {
    message: "Please enter the reason for Cancelling this request",
    title: "Cancellation Reason",
    okText: "Ok",
    cancelText: "No, Cancel",
  };

  const RecommendModalData = {
    message: "Please are you certain to Recommend this record",
    title: "Recommendation",
    okText: "Ok",
    cancelText: "No, Cancel",
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [Posting, Updating, Patching] = useCrudFunc();
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const populateFields = async (clear: boolean) => {
    if (clear === true) {
      dispatch(task_footer_table_selected([]));
      setSelectedRecordx!({});
    }

    const gridState = [
      {
        key: "rw",
        value: clear
          ? { id: 0, name: "" }
          : { id: selectedRecordx?.ftwIDpk, name: selectedRecordx?.ftwName },
      },
      {
        key: "rt",
        value: clear
          ? { id: 0, name: "" }
          : { id: selectedRecordx?.fttIDpk, name: selectedRecordx?.fttName },
      },
      {
        key: "date",
        value: clear ? editedDate : selectedRecordx?.ftrCreationDate,
      },
      {
        key: "recom",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecordx?.recommendedByEmpID,
              name: selectedRecordx?.recommendedByEmpName5,
            },
      },
      {
        key: "reqby",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecordx?.requesterEmpID,
              name: selectedRecordx?.requesterEmpName5,
            },
      },
      {
        key: "vehreg",
        value: clear
          ? { id: 0, name: "" }
          : { id: selectedRecordx?.vdtIDpk, name: selectedRecordx?.vdtRegNo },
      },
      {
        key: "quan1",
        value: clear ? 0 : selectedRecordx?.ftrQuantityRequested,
      },
      { key: "quan2", value: clear ? 0 : selectedRecordx?.ftrQuantityApproved },
      { key: "quan3", value: clear ? 0 : selectedRecordx?.ftrQuantityToppedUp },
      { key: "purpose", value: clear ? "" : selectedRecordx?.ftrPurpose },
      { key: "leftRemarks", value: clear ? "" : selectedRecordx?.ftrRmks },
    ];

    for (let instance of gridState) updateState(instance.key, instance.value);
  };

  const validationForms = async () => {
    setIcon("warning");
    if (states.rw?.id === 0 || states.rw?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Request Window",
        title: " Field Required! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.rt?.id === 0 || states.rt?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Request Type",
        title: " Field Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.reqby?.id === 0 || states.reqby?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Requested By",
        title: " Field Required! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.recom?.id === 0 || states.recom?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Recommended By",
        title: "Field Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehreg?.id === 0 || states.vehreg?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Vehicle Reg.No",
        title: "Field Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.quan1 ||
      states.quan1 === undefined ||
      states.quan1 < 0 ||
      isNaN(Number(states.quan1))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.quan1 < 0
            ? "Please, quantity requested cannot be negative"
            : isNaN(Number(states.quan1))
            ? "Please, enter a valid number for quantity requested"
            : "Please, enter data for quantity requested",
        title: "Field Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const newAccesspriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      populateFields(true);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const saveAccesspriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(task_footer_update(false));
      setModalData(saveModalData);
      validationForms();
      setOpenModal(true);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const updateAccesspriv = () => {
    setPrivType("update");
    if (updatePriv === true && selectedRecordx?.ftrRecommended === false) {
      dispatch(task_footer_update(true));
      setModalData(updateModalData);
      validationForms();
      setOpenModal(true);
    } else if (selectedRecordx?.ftrRecommended === true) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        title: "Access Denied!",
        message:
          "Please user cannot update a record that has already been recommended!",
      });
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const cancelAceessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      setModalData(CancellationModalData);
      setOpenModal(true);
      setIcon("warning");
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const recommendAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setIcon("question");
      setModalData(RecommendModalData);
      setOpenModal(true);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const message = `Recommended Fuel Top-Up Request with ID of ${selectedRecordx?.ftrIDpk} has been recommended.`;

  const message2 = `Hello ${selectedRecordx?.recommendedByFirstName}, Fuel Top-Up Request from ${userInfo.empName} ${userInfo.empStaffno} is pending your recommendation 
  Thanks.
  Sent from InnoX`;

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please your computer is not connected to the Internet. Please check your Internet connection and try again",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  let mobNox = "";
  const sendSms = async () => {
    try {
      checkConnection();
      if (selectedRecordx?.requesterEmpID) {
        mobNox = selectedRecordx?.requesterMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: message2,
        imsSender: userInfo.empName,
        imsRecordIdfk: selectedRecordx.vdtIdpk,
        imsFormCode: formCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: selectedRecordx.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: selectedRecordx.requesterEmpID,
        altFormCode: formCode,
        altMessage: message,
        altTakeAction: true,
      });
      // setIcon("success");
      // setOpenModal(true);
      // setshowModalDisableButton(true);
      // setModalData({
      //   message: "SMS Sent Successfully",
      //   title: "Success",
      //   okText: "Ok",
      // });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to send Alert!.. Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const sendSmsCan = async () => {
    try {
      checkConnection();
      if (selectedRecordx?.requesterEmpID) {
        mobNox = selectedRecordx?.requesterMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: message2,
        imsSender: userInfo.empName,
        imsRecordIdfk: selectedRecordx.vdtIdpk,
        imsFormCode: formCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: selectedRecordx.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: selectedRecordx.requesterEmpID,
        altFormCode: formCode,
        altMessage: message,
        altTakeAction: true,
      });
      // setIcon("success");
      // setOpenModal(true);
      // setshowModalDisableButton(true);
      // setModalData({
      //   message: "SMS Sent Successfully",
      //   title: "Success",
      //   okText: "Ok",
      // });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const smsAlert = async () => {
    try {
      checkConnection();
      if (selectedRecordx?.requesterEmpID) {
        mobNox = selectedRecordx?.requesterMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }
      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: selectedRecordx.requesterEmpID,
        altFormCode: formCode,
        altMessage: message,
        altTakeAction: true,
      });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const [ID, setID] = useState<number>(0);
  const postData = async () => {
    setBusyLoader("Saving Record...");
    try {
      const res = await Posting(
        `FuelTopUpRequest/CreateFuelTopUpRequest`,
        {
          ftrRequestWindowIDfk: states.rw?.id,
          ftrRequesterEmpIDfk: states.reqby?.id,
          ftrRecommededByEmpIDfk: states.recom?.id,
          ftrQuantityRequested: states.quan1,
          ftrVehicleDetailIDfk: states.vehreg?.id,
          ftrRequestTypeIDfk: states.rt?.id,
          ftrPurpose: states.purpose,
          ftrRmks: states.leftRemarks,
          ftrCreatedBy: employeeId,
        },
        `Created the Asset Manager-Trans-Fuel Top-Up Request for record with Request Window of ${states.rw?.name}, Request Type of ${states.rt?.name}, Request Date of ${states.date}, Requested By of ${states.reqby?.name},
      Recommended By of ${states.recom?.name}, Vehicle Reg.No of ${states.vehreg?.name}, Quantity of ${states.quan1}, Purpose of ${states.purpose}, Remarks of ${states.leftRemarks}`
      );
      const id = () => {
        setID(res.data.id);
      };
      sendSms();
      setRefreshValue(!refreshValue);
      setRefreshx && setRefreshx(!refreshValue);
      setIcon("success");
      setOpenModal(true);

      setshowModalDisableButton(true);
      setModalData({
        message: "Data entry saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
    } catch (e: any) {
      console.log(e, "tttt");
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: e.response
          ? e.response.data.errors.fuelTopUpRequestDto
          : "Server Error",
        title: e.response ? e.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    setBusyLoader("Updating Record...");
    try {
      const res = await Updating(
        `FuelTopUpRequest/UpdateFuelTopUpRequests?Id=${selectedRecordx?.ftrIDpk}`,
        {
          ftrRequestWindowIDfk: states.rw?.id,
          ftrRequesterEmpIDfk: states.reqby?.id,
          ftrRecommededByEmpIDfk: states.recom?.id,
          ftrQuantityRequested: states.quan1,
          ftrVehicleDetailIDfk: states.vehreg?.id,
          ftrRequestTypeIDfk: states.rt?.id,
          ftrPurpose: states.purpose,
          ftrRmks: states.leftRemarks,
          ftrEditedBy: employeeId,
          ftrEditedDate: editedDate,
        },
        `Edited the Asset Manager-Trans-Fuel Top-Up Request with ${selectedRecordx?.ftrIDpk}, Request Window of ${states.rw?.name}, Request Type of ${states.rt?.name}, Request Date of ${states.date}, Requested By of ${states.reqby?.name},
        Recommended By of ${states.recom?.name}, Vehicle Reg.No of ${states.vehreg?.name}, Quantity of ${states.quan1}, Purpose of ${states.purpose}, Remarks of ${states.leftRemarks}`
      );

      setRefreshValue(!refreshValue);
      setRefreshx && setRefreshx(!refreshValue);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Data entry saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: e.response
          ? e.response.data.errors.fuelTopUpRequestDto
          : "Server Error",
        title: e.response ? e.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const cancellation = async () => {
    try {
      await Patching(
        `FuelTopUpRequest/CancelFuelTopUpRequest?EmpId=${employeeId}&ftrIdpk=${selectedRecordx?.ftrIDpk}`,
        {},
        `Cancelled Transport-Fuel Top-Up Request for record with ID - ${selectedRecordx?.ftrIDpk}`
      );
      sendSmsCan();
      setRefreshValue(!refreshValue);
      setRefreshx && setRefreshx(!refreshValue);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry Cancelled successfully!",
        title: "Data Updated!",
        okText: "Ok",
      });
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const Recommendation = async () => {
    try {
      await Patching(
        `FuelTopUpRequest/RecommendFuelTopUpRequest?Id=${selectedRecordx?.ftrIDpk}`,
        {
          ftrRecommended: true,
          ftrCancelled: false,
          ftrRecommendedOn: editedDate,
          ftrEditedBy: employeeId,
          ftrEditedDate: editedDate,
        },
        `Recommended Fuel Top-Up Request for ${selectedRecordx?.recommendedByEmpName5}  ${selectedRecordx?.recommendedByStaffNo} ${selectedRecordx?.recommendedByDeptShtName} for request with ID ${selectedRecordx?.ftrIDpk}`
      );
      smsAlert();
      setRefreshValue(!refreshValue);
      setRefreshx && setRefreshx(!refreshValue);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record Recommended successfully!",
        title: "Data Updated!",
        okText: "Ok",
      });
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const [onCancel_btn, setonCancel_btn] = useState<boolean>(true);

  useEffect(() => {
    populateFields(false);
  }, [selectedRecordx]);

  return (
    <div className=" dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-bas">
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={icon === "warning" || icon === "success" ? true : false}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? postData()
            : modalData === updateModalData
            ? updateData()
            : modalData.title === CancellationModalData.title
            ? cancellation()
            : modalData.title === RecommendModalData.title
            ? Recommendation()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        withInput2={
          modalData.title === CancellationModalData.title ? true : false
        }
        inputOnchange={(e) => {
          updateState("cancelRemarks", e);
        }}
      />
      <Form
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base rounded py-1.5 px-2 "
        name="basic"
        labelCol={{
          span:
            userInfo.empIsDirector === true || userInfo.empIsManager === true
              ? 5
              : 2,
        }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
        style={{ height: "100px" }}
      >
        <>
          {selects1?.map(
            ({
              id,
              label,
              optionsData,
              idExpr,
              dataExpr,
              defaultValue,
              stateName,
              style,
              refreshStateName,
              refresh,
            }) => {
              return (
                <>
                  {id === 0 ? (
                    <DropdownGrid_template
                      label={label}
                      ref={dropdownRef}
                      cusBtnStyle={true}
                      dropdownBtnStyles={{
                        width: 207,
                        maxWidth: 210,
                        overflow: "hidden",
                      }}
                      labelCol={
                        userInfo.empIsDirector === true ||
                        userInfo.empIsManager === true
                          ? 5
                          : 2
                      }
                      columns={reqWind_cols}
                      disabled={form_disable}
                      gridData={optionsData}
                      defaultValue={defaultValue}
                      onRowClick={(e) => {
                        // alert("Drop");
                        updateState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                      }}
                    />
                  ) : (
                    <SelectsTemplate
                      selectStyle={{ marginTop: 3, width: 207 }}
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      label={label}
                      // wrapperCol={9}

                      useCallFunc={true}
                      options={optionsData}
                      placeHolder={defaultValue}
                      selectedValue={(e) => {
                        updateState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                      }}
                      handleClear={() => {
                        updateState(stateName!, { id: 0, name: "" });
                      }}
                      handleRefresh={() => {
                        updateState(refreshStateName!, !states.requestType);
                      }}
                    />
                  )}
                </>
              );
            }
          )}
        </>

        <>
          {dates?.map(({ id, label, defaultValue, stateName, style }) => {
            return (
              <DateTemplate
                style={{ marginTop: 0.5 }}
                label={label}
                changeDate={(e: Dayjs) => {
                  updateState("date", dayjs(e).format());
                }}
                width={207}
                selectedDate={dayjs(defaultValue)}
                disabled={form_disable ? true : false}
                disabledDate={(current: Dayjs) => {
                  return current && current > dayjs().endOf("day");
                }}
              />
            );
          })}
        </>

        <>
          {selects2?.map(
            ({
              id,
              label,
              optionsData,
              idExpr,
              dataExpr,
              defaultValue,
              stateName,
              style,
              singleEndpoint,
              refreshStateName,
              isOpenDropdown,
              isOpenDropdownKey,
              refresh,
            }) => {
              return (
                <div className={`mb-0.5 `}>
                  {id === 0 || id === 1 ? (
                    <DropdownGrid_template
                      label={label}
                      ref={id === 0 ? dropdownRef2 : dropdownRef3}
                      cusBtnStyle={true}
                      dropdownBtnStyles={{
                        width: 207,
                        maxWidth: 210,
                        overflow: "hidden",
                      }}
                      labelCol={
                        userInfo.empIsDirector === true ||
                        userInfo.empIsManager === true
                          ? 5
                          : 2
                      }
                      columns={
                        label === "Requested By" ? reqBy_cols : recomBy_cols
                      }
                      disabled={
                        update_state_change === true && label === "Requested By"
                          ? true
                          : form_disable
                      }
                      gridData={optionsData}
                      defaultValue={defaultValue}
                      onRowClick={(e) => {
                        updateState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                      }}
                      handleRefresh={() => {
                        updateState(stateName!, { id: 0, name: "" });
                        updateState(refreshStateName!, !refresh);
                      }}
                    />
                  ) : (
                    <InputSelect_template
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      label={label}
                      wrapperCol={9}
                      isOpenDropdown={isOpenDropdown}
                      handleFocus={(e: any) => {
                        updateState(isOpenDropdownKey, !isOpenDropdown);
                      }}
                      selectStyle={{
                        width: 207,
                        // maxWidth: 210,
                        // overflow: "hidden",'
                        height: 23,
                        marginBottom: -2,
                      }}
                      useCallFunc={true}
                      handleSearch={debounce((e) => {
                        updateState(stateName!, e);
                        // UpdateState("isLoading", true);
                        dispatch(
                          singleEndpoint({
                            search: e,
                            EmpID: states.reqby.id,
                          })
                        );
                        updateState(isOpenDropdownKey, true);
                      }, 500)}
                      options={optionsData}
                      placeHolder={defaultValue}
                      selectedValue={(e) => {
                        updateState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                        updateState(isOpenDropdownKey, false);
                      }}
                      handleClear={() => {
                        updateState(stateName!, { id: 0, name: "" });
                      }}
                      handleRefresh={() => {
                        updateState(refreshStateName!, !refresh);
                      }}
                    />
                  )}
                </div>
              );
            }
          )}
        </>

        <InputsTemplate
          inputStyle={{ width: 68, marginRight: 2, marginTop: -2 }}
          widthChange={true}
          // numberOnly={true}
          label={"Quantity (ltrs)"}
          labelColPan={
            userInfo.empIsDirector === true || userInfo.empIsManager === true
              ? 5
              : 2
          }
          span
          defaultValue={states.quan1}
          useCallbackFunc
          disabledStatus={form_disable ? true : false}
          orderOnchange={(e: any) => {
            updateState("quan1", e);
          }}
          additionalWidget={
            <div className="flex">
              <InputsTemplate
                inputStyle={{
                  width: 68,
                  backgroundColor: "#fff59d",
                  marginRight: 2,
                  marginBottom: 1,
                }}
                widthChange={true}
                // numberOnly={true}
                span
                defaultValue={states.quan2}
                useCallbackFunc
                readOnly={true}
                disabledStatus={false}
                orderOnchange={(e: any) => {
                  updateState("quan2", e);
                }}
              />
              <InputsTemplate
                inputStyle={{
                  width: 68,
                  backgroundColor: "#fff59d",
                  marginRight: 2,
                }}
                widthChange={true}
                // numberOnly={true}
                span
                defaultValue={states.quan3}
                useCallbackFunc
                readOnly={true}
                disabledStatus={false}
                orderOnchange={(e: any) => {
                  updateState("quan3", e);
                }}
              />
            </div>
          }
        />

        {/* <Input
                  style={{ width: "67px" }}
                  value={states.quan1}
                  onChange={(e) => {
                    updateState("quan1", e.target.value);
                  }}
                  disabled={form_disable ? true : false}
                  className="mr-1 "
                /> */}
        {/* <Input
                  style={{ width: "67px", backgroundColor: "#fff59d" }}
                  readOnly={true}
                  value={states.quan2}
                  onChange={(e) => {
                    updateState("quan2", e.target.value);
                  }}
                  className=" mr-1"
                /> */}
        {/* <Input
                  style={{ width: "67px", backgroundColor: "#fff59d" }}
                  readOnly={true}
                  value={states.quan3}
                  onChange={(e) => {
                    updateState("quan2", e.target.value);
                  }}
                  className=" mr-1"
                /> */}

        {/* <Topup_Requisition_textarea /> */}
        <>
          {textAreas?.map(({ id, label, defaultValue, stateName, style }) => {
            return label === "Remarks" ? (
              <div className="-mt-1 ">
                <TextareaTemplate
                  label={label}
                  labelCol={
                    userInfo.empIsDirector === true ||
                    userInfo.empIsManager === true
                      ? 5
                      : 2
                  }
                  readonly={form_disable ? true : false}
                  useCallbackFunc
                  setCallbackValue={(e) => {
                    updateState(stateName!, e);
                  }}
                  defaultValue={defaultValue?.toString()}
                  height={48}
                  width={"100%"}
                />
              </div>
            ) : (
              <TextareaTemplate
                label={label}
                labelCol={
                  userInfo.empIsDirector === true ||
                  userInfo.empIsManager === true
                    ? 5
                    : 2
                }
                readonly={form_disable ? true : false}
                useCallbackFunc
                setCallbackValue={(e) => {
                  updateState(stateName!, e);
                }}
                defaultValue={defaultValue?.toString()}
                height={45}
                width={"100%"}
              />
            );
          })}
        </>

        <div className="w-full flex flex-col">
          <Form.Item
            labelCol={{
              span:
                userInfo.empIsDirector === true ||
                userInfo.empIsManager === true
                  ? 5
                  : 2,
            }}
            label={<p className="hidden">hidden</p>}
            colon={false}
          >
            <ul
              style={{
                width:
                  userInfo.empIsDirector || userInfo.empIsManager
                    ? "100%"
                    : `calc(100%)`,
              }}
              className="w-full flex justify-between  "
            >
              <li id="cancelx" className="  flex items-center flex-row">
                <Cancel
                  handleCallbackCancel={() => {
                    cancelAceessPriv();
                  }}
                />
                <Tooltip
                  target="#cancelx"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p>Click to cancel unapproved requests</p>
                </Tooltip>
              </li>
              <li>
                {userInfo.empName === selectedRecordx?.recommendedByEmpName && (
                  <Recommend handleRecommend={recommendAccessPriv} />
                )}
              </li>
              <ul className="flex justify-end  ">
                {form_disable === false && update_state_change === false ? (
                  <SaveButton
                    title={"Submit"}
                    handleSave={() => {
                      saveAccesspriv();
                    }}
                  />
                ) : update_state_change === true ? (
                  <UpdateButton
                    handleUpdate={() => {
                      updateAccesspriv();
                    }}
                  />
                ) : (
                  <SaveButton title={"Submit"} />
                )}

                {form_disable === true ? (
                  <NewButton
                    useCallbackFunc={true}
                    new_button_toggler={() => {
                      newAccesspriv();
                    }}
                  />
                ) : update_state_change === true ? (
                  <Cancel
                    useCallbackFunc={true}
                    cancel_button_toggler={async () => {
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: true })
                      );
                      dispatch(task_footer_update(false));
                      setonCancel_btn(true);
                    }}
                  />
                ) : !(update_state_change && form_disable) ? (
                  <Cancel
                    useCallbackFunc={true}
                    cancel_button_toggler={async () => {
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: true })
                      );
                      dispatch(task_footer_update(false));
                      setonCancel_btn(true);
                    }}
                  />
                ) : (
                  <NewButton
                    useCallbackFunc={true}
                    new_button_toggler={() => {
                      newAccesspriv();
                    }}
                  />
                )}
              </ul>
            </ul>
          </Form.Item>
        </div>
      </Form>
      {/* </div> */}
    </div>
  );
};
