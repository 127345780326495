import { createSlice, PayloadAction } from "@reduxjs/toolkit"


interface ScheduleSlice{
    New_cancel_Button: boolean,
    Edit_Cancel_Button:boolean,
    Save_Update_Button: boolean,
    Bulk_Cancel_Button: boolean,
    Disabled_form:boolean,
}

const MaintenanceSchedule_Initial : ScheduleSlice ={
    New_cancel_Button: true,
    Edit_Cancel_Button: true,
    Bulk_Cancel_Button: true,
    Save_Update_Button: true,
    Disabled_form: true
}


const MaintenanceScheduleSlice = createSlice({
    name:"Maintenance Schedule",
    initialState: MaintenanceSchedule_Initial,
    reducers:{
        setNew_Cancel_Button:(state, action:PayloadAction<boolean>)=>{
            state.New_cancel_Button = action.payload
        },
        setEdit_Cancel_Button:(state, action:PayloadAction<boolean>)=>{
            state.Edit_Cancel_Button = action.payload
        },
        setBulk_Cancel_Button:(state , action:PayloadAction<boolean>)=>{
            state.Bulk_Cancel_Button = action.payload
        },
        setSave_Update_Button:(state, action:PayloadAction<boolean>)=>{
            state.Save_Update_Button = action.payload
        },
        setDisabled_Form:(state, action:PayloadAction<boolean>)=>{
state.Disabled_form = action.payload
        }
    }
})


export const{
    setNew_Cancel_Button,
    setEdit_Cancel_Button,
    setBulk_Cancel_Button,
    setSave_Update_Button,
    setDisabled_Form,
} = MaintenanceScheduleSlice.actions

export default MaintenanceScheduleSlice.reducer;