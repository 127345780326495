/* eslint-disable react/jsx-pascal-case */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { RootState } from "../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import useFetch from "../../../../../../../hooks/useFetch";
import { navbar_height, toolbar_height } from "../../../../../../accessories/component_infos";
import CustomLoader from "../../../../../../accessories/custom-loader";
import { HrSetupsCustomToolbar } from "../../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { searchTextTypes, searchCriteriaTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { assetsSetups_maintenancelocation_columns } from "./data/maintenanceLocationsData";
import {  TRPLocationsForm } from "./widgets/maintenceLocationForm";
import { location_columns } from "../location/data/trouble_location_data";

export const TRPLocations = forwardRef(
  ({ a }: any, ref) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );
    const [selectedData, setSelectedData] = useState({});
    const [refresh, setRefresh] = useState<boolean>(false);
    const [active, setActive] = useState<{
      temp: boolean | string;
      status: boolean | string;
    }>({
      temp: "",
      status: "",
    });
    const [searchText, setSearchText] = useState<searchTextTypes>({
      temp: "",
      text: "",
    });
    const [searchIndex, setSearchIndex] = useState<searchCriteriaTypes>({
      index: 0,
      temp: 0,
    });

    const [data, error, loading] = useFetch(
    `TroubleLocations/GetAllTroubleLocations?search=${searchText.text}&active=${active.status}`,
    refresh,
    "tlcIDpk", false, "powerapp"
    );

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));

    const renderCount = useRef(0);
    const dispatch = useDispatch();

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
    const formModeSearchParam = "form-and-table"; //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;

    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height, set_form_height] = useState<number>(270);

    // Page resize height responsiveness
    useEffect(() => {
      settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
    }, []);

    // handle interface switch
    const toggle_staff_form = (): void => {
      const view = searchParams.get("view");

      view === null || view === formModeSearchParam
        ? navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: datagridOnlySearchParam,
            }).toString(),
          })
        : navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: formModeSearchParam,
            }).toString(),
          });
    };

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

    return (
      <div  className="w-full h-[600px]">
        {loading && !renderCount?.current && <CustomLoader />}

        <>
          <div className="px-2 ">
            <HrSetupsCustomToolbar
              outerSelected={selectedData}
              setOuterSelected={setSelectedData}
              searchCriteria={false}
              searchCriteriaData={[
                { id: 0, value: "Maintenance Loc" },
                { id: 1, value: "Area" },
                { id: 2, value: "Location" },
                { id: 3, value: "Contact Person" },
                { id: 4, value: "Mob/Tel No/INTERCOM" },
                { id: 5, value: "eMail" },
              ]}
              searchCriteriaOnchange={(index, criteria) => {
                setSearchIndex({ temp: index, index: 0 });
              }}
              withDates={false}
              toggler={toggle_staff_form}
              handleRefresh={() => setRefresh(!refresh)}
              checkOnChange={(value) => {
                setActive({ temp: value, status: active.status }); //store locally
                dispatch(
                  setGeneralValue({ expr: "activeStatus", value: value })
                ); //push value to redux
              }}
              searchTextOnchange={(newText) => {
                setSearchText({ temp: newText, text: searchText.text });
              }}
              handleFind={() => {
                setSearchIndex({
                  temp: searchIndex.temp,
                  index: searchIndex.temp,
                });
                setSearchText({ text: searchText.temp, temp: searchText.temp });
                setActive({ temp: active.temp, status: active.temp });
              }}
            />
          </div>

          {/* form section */}
          {searchParams.get("view") !== datagridOnlySearchParam && (
            <section
              // style={{ height: form_height }}
              className="form border-b-4"
            >
              <TRPLocationsForm
                selectedData={selectedData}
                // refreshGrid={()=>{}}
                refreshGrid={() => {
                  setRefresh(!refresh);
                }}
              />
            </section>
          )}
          {/* datagrid section */}
          <section className="mt-1 px-2">
            <Datagrid_template
              onRowClick={(selected) => {
                setSelectedData(selected);
                console.log(selected);
                
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: true })
                );
                dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              }}
              rowDoubleClicked={(selected) => {
                //disable Update Mode
                dispatch(setGeneralValue({ expr: "updateMode", value: true }));

                //disable forms
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: false })
                );
              }}
              gridheight={
                400
              }
              data={data}
              columns={location_columns}
            />
          </section>
        </>
      </div>
    );
  }
);
