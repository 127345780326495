/* eslint-disable react/jsx-pascal-case */
import React from "react";
import TabsPanel_template from "../../../../../../templates/tabsPanel_template";
import Attachments from "./Tabcontent/Attachments";
import Comments from "./Tabcontent/Comments";
import { ModalTemplate } from "../../../../../../templates/modal";
import ImageAttachment from "./Tabcontent/Image";

interface IProps {
  mainGridDataLength: number;
  mainGridSelectedRecord: any;
}

export function Others({ mainGridDataLength, mainGridSelectedRecord }: IProps) {
  const [messageModal, setMessageModal] = React.useState<{
    icon: "warning" | "question" | "success";
    title: string;
    message: string;
    openModal: boolean;
    hideCancel: boolean;
    okText: string;
    cancelText: string;
    okHandler: () => void;
    cancelHandler: () => void;
  }>({
    icon: "warning",
    title: "",
    message: "",
    openModal: false,
    hideCancel: false,
    okText: "",
    cancelText: "",
    okHandler: () => {
      setMessageModal((p) => ({ ...p, openModal: false }));
    },
    cancelHandler: () => {
      setMessageModal((p) => ({ ...p, openModal: false }));
    },
  });

  const [tabSummaryCount, setTabSummaryCount] = React.useState<any>({
    Comments: 0,
    Attachments: 0,
  });

  const updateCount = (type: string, count: number) => {
    setTabSummaryCount((p) => ({ ...p, [type]: count }));
  }

  const tabs: { id: number, title: string, contents: any }[] = [
    {
      id: 0, title: `Comments (${tabSummaryCount.Comments})`, contents: <Comments setTabSummaryCount={updateCount}
        mainGridDataLength={mainGridDataLength} msgModal={messageModal} setMsgModal={setMessageModal} mainGridSelectedRecord={mainGridSelectedRecord} />
    },
    {
      id: 1, title: `Attachments (${tabSummaryCount.Attachments})`, contents: <Attachments setTabSummaryCount={updateCount}
        mainGridSelectedRecord={mainGridSelectedRecord} msgModal={messageModal} setMsgModal={setMessageModal} />
    },

  ]
  return (
    <div>
      <ModalTemplate
        icon={messageModal.icon}
        title={messageModal.title}
        message={messageModal.message}
        open={messageModal.openModal}
        okText={messageModal.okText}
        cancelText={messageModal.cancelText}
        disableCancel={messageModal.hideCancel}
        okHandler={messageModal.okHandler}
        cancelHandler={messageModal.cancelHandler}
      />
      <TabsPanel_template tabs={tabs} />
    </div>
  );
}
