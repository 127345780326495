import React from 'react'
import Datagrid_template from "../../../../templates/Datagrid";
import newIcon from "../../../../assets/new.png";
import refresh from "../../../../assets/refresh.png";
import save from "../../../../assets/save2.png";
import assignees from "../../../../assets/group-teams.png";
import cog from "../../../../assets/userCog.png";
import close from "../../../../assets/close.png";
import { Checkbox, Input, Select } from 'antd';
import { SelectsTemplate } from '../../../../templates/select';
import { InputsTemplate } from '../../../../templates/input';
import { share_cols } from '../../../Assets-Manager/Transport/Entries/ActivitiesCapture/data/datagrid_cols';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import search from 'antd/es/transfer/search';
import { SaveButton } from '../../../accessories/buttons';
import { lower_cols, top_cols } from '../../../Assets-Manager/Transport/Entries/Maintenance/Maintenance_Activities/data/datagrid_cols';
import { IconsContainer } from '../../elogger/StationReadings/_widget/stationReadingsToolbar';
export const Share_case = () => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <div className="w-full ">
      <ul className="w-full flex justify-evenly items-center">
        <li className="justify-center items-center px-1">
          <p>Find:</p>
        </li>
        <li className="justify-center items-center">
          <Select
          defaultValue={"Staff"}
            // options={criteria}
            className="w-[75px] mt-0.5"
            size="small"
            // onChange={handleSelectChange}
          />
        </li>
        <li className="justify-center items-center">
          <Input
            // value={search}
            // onChange={(e: any) => {
            //   setSearch(e.target.value);
            // }}
            className="w-[85px]"
            size="small"
          />
        </li>
        <li
          onClick={() => {
            // addSharedEmp();
          }}
          style={{ borderColor: borderTheme }}
          className="justify-center items-center border-[1px] cursor-pointer rounded py-0.5 px-0.5"
        >
          <img src={newIcon} />
        </li>
        <li
          onClick={() => {
            // setRefreshBtn(!refreshBtn);
          }}
          style={{ borderColor: borderTheme }}
          className="justify-center items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5"
        >
          <img className="w-4" src={refresh} />
        </li>
      </ul>
      <ul className="w-[150px] flex items-center justify-between ">
        <li className="  border-[1px] rounded px-1 ml-[10px]">
          <Checkbox
            title="Tick to select all"
            // onChange={(e) => selectAllOwn(e.target.checked)}
          />
        </li>
        <li className=" border-[1px] rounded px-1">
          <Checkbox
            title="Tick to select all"
            // onChange={(e) => selectAllRead(e.target.checked)}
          />
        </li>
        <li className=" border-[1px] rounded px-1 mr-[10px]">
          <Checkbox
            title="Tick to select all"
            // onChange={(e) => selectAllPrint(e.target.checked)}
          />
        </li>
      </ul>
      <div className="pt-[1px] h-[40%]">
        <Datagrid_template
          gridheight={300}
          columns={top_cols}
          data={[]}
          deselectFirstRow
          allowColumnEditing={true}
          disableGroupPanel
          disableSearch
          disablePaging
          style={{ maxWidth: "400px" }}
          noColumn={150}
          onRowClick={(e) => {
            // setSelectedRecord(e);
          }}
        />

        <Datagrid_template
          gridheight={395}
          columns={lower_cols}
          allowColumnEditing={true}
          data={[]}
          disableGroupPanel
          deselectFirstRow
          // onRowPrepared={rowColoring}
          rowAlternationEnabled={false}
          disableSearch
          disablePaging
          style={{ maxWidth: "400px" }}
          noColumn={150}
          onRowClick={(e) => {
            // setSharedData(e);
          }}
          rowDoubleClicked={(e) => {
            // removeDblClicked(e);
          }}
        />
       
      </div>
      <ul className=" w-full flex items-center ml-2 gap-2">
         
          <li
            onClick={() => {
              // setRefreshBtn(!refreshBtn);
            }}
            style={{ borderColor: borderTheme }}
            className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5"
          >
            <img className="w-5" src={save} />
          </li>
          <li
            onClick={() => {
              // setRefreshBtn(!refreshBtn);
            }}
            style={{ borderColor: borderTheme }}
            className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5"
          >
            <img className="w-5" src={assignees} />
          </li>
          <li
            onClick={() => {
              // setRefreshBtn(!refreshBtn);
            }}
            style={{ borderColor: borderTheme }}
            className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5"
          >
            <img className="w-5" src={cog} />
          </li>
          <li
            onClick={() => {
              // setRefreshBtn(!refreshBtn);
            }}
            style={{ borderColor: borderTheme }}
            className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5"
          >
            <img className="w-5" src={refresh} />
          </li>
         <div className='w-[45%] flex justify-end'>
         <li
            onClick={() => {
              // setRefreshBtn(!refreshBtn);
            }}
            style={{ borderColor: borderTheme }}
            className=" items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5 flex justify-end"
          >
            <img className="w-5" src={close} />
          </li>
         </div>
         
         
         
        </ul>
    </div>
  );
};


