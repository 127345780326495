import { Data } from "@syncfusion/ej2-react-grids";
import React from "react";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { Form } from "antd";
import { IconsContainer } from "../../../../elogger/StationReadings/_widget/stationReadingsToolbar";
import addNew from "../../../../../../assets/addNew.png";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../../templates/input";
import print from "../../../../../../assets/printer.png";
import refresh from "../../../../../../assets/refresh.png";
const Share_columns = [
  {
    id: 1,
    caption: "Rdr",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Name",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Dept",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Section",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
];
const bottom_columns = [
  {
    id: 1,
    caption: "Rdr",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Name",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Dept",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Section",
    dataField: "exnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
];

function Share() {
  return (
    <main className="w-full h-full">
      <div className="flex w-full pl-2 justify-between">
        <CheckboxTemlate withBorders />
        <aside className="flex items-center w-auto">
          <div className="w-full">
            <InputsTemplate
              label="Find"
              inputStyle={{ width: 120 , height: 21}}
              span={true}
            />
          </div>
          <div className="w-8">
            <IconsContainer url={addNew} />
          </div>
        </aside>
      </div>

      <Datagrid_template
        columns={Share_columns}
        data={[]}
        disablePaging
        disableSearch
        disableGroupPanel
        gridheight={330}
      />
      <div>
        <Datagrid_template
          columns={bottom_columns}
          data={[]}
          disablePaging
          disableSearch
          disableGroupPanel
          // gridheight={window.innerHeight - 500}
          gridheight={330}
        />
      </div>
      <div className="flex ml-5 ">
   <IconsContainer url={print} />
   <aside className="w-[30px] ml-5"> 
    <IconsContainer url={refresh} />
   </aside>
  
   </div>
    </main>
  );
}

export default Share;
