import React, { useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import {
  AlertSMS,
  AppraiseSettings,
  TaskSettings,
} from "./widgets/customToolbarModals";
import question from "../../../assets/question.png";
import edit from "../../../assets/edit.png";
import close from "../../../assets/close.png";
import statusLoader from "../../../assets/statusloader.gif";
import newfile from "../../../assets/new.png";
import refresh from "../../../assets/refresh.png";
import printer from "../../../assets/printer.png";
import share from "../../../assets/share.png";
import data_sharing from "../../../assets/data_sharing.png";
import alter from "../../../assets/alter.png";
import { Checkbox, DatePicker, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../features/generalSlice";
import {
  task_form_disable,
  task_footer_update,
} from "../../../features/Task&Todo/Task/TaskDetails";
import { ModalTemplate } from "../../../templates/modal";
import { saveModalData } from "../component_infos";
import { FaBinoculars } from "react-icons/fa";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import dayjs from "dayjs";

interface props {
  toggler?: () => void;
  startDate: string;
  endDate: string;
  searchCriteria: any;
  setSearchCriteria: (e: any) => void;
  handleFind: () => void;
  handleSearchChange: (e: any) => void;
  handleRefresh: () => void;
  handleActive: (e: any) => void;
  setStartDate: (e: any) => void;
  setEndDate: (e: any) => void;
  setShowAllGrids: (e: any) => void;
  deleteDocument: () => void;
  handleAutoShare: () => void;
  handleAllShare: () => void;
}

export const TransDoc_toolbar = ({
  toggler,
  startDate,
  endDate,
  searchCriteria,
  setSearchCriteria,
  handleFind,
  handleSearchChange,
  handleActive,
  handleRefresh,
  setStartDate,
  setEndDate,
  setShowAllGrids,
  deleteDocument,
  handleAutoShare,
  handleAllShare,
}: props) => {
  const dateFormat = " DD MMM YYYY";
  const dispatch = useDispatch();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [active, setActive] = useState<any>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);

  const handleChange = (value: number) => {
    setSearchCriteria(value);
  };

  const [searchValue, setSearchValue] = useState<string>("");
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [indeterminate, setIndeterminate] = useState<any>(true);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setActive(true);
      handleActive(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setActive(false);
      handleActive(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setActive("");
      handleActive("");
    }
  };
  // from redux
  const disablex = useSelector(
    (state: RootState) => state.general.TransformDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.TransupdateMode
  );

  const selectedRecord = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );

  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      handleNew();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setOpenModal(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      handleUpdate();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(task_footer_update(false));
      dispatch(setGeneralValue({ expr: "TransupdateMode", value: false }));
      dispatch(task_form_disable(true));
      setOpenModal(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  const findAccessPriv = async () => {
    setPrivType("read");
    if (readPriv === true) {
      handleFind();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setOpenModal(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  const printAccessPriv = () => {
    setPrivType("read");
    if (readPriv === true) {
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setOpenModal(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  // relative to the new button
  const handleNew = () => {
    dispatch(setGeneralValue({ expr: "TransformDisabled", value: false }));

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));
    dispatch(setGeneralValue({ expr: "TransupdateMode", value: false }));

    //clear data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const cancel_button_toggler = () => {
    dispatch(setGeneralValue({ expr: "TransformDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "TransupdateMode", value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showCancel, setshowCancel] = useState(false);

  //handle edit
  const handleUpdate = () => {
    if (Object.keys(selectedRecord).length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No record selected",
        title: "Invalid record",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "TransupdateMode", value: true }));
      dispatch(task_footer_update(true));
      dispatch(task_form_disable(false));
    }
  };

  return (
    <div
      style={{
        borderColor: borderTheme,
        borderBottomWidth: "1px",
        width: "4000px",
      }}
      className=" h-full flex justify-between border-gray-200 mb-1 mx-1"
    >
      <div className="">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={!showCancel}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {/* new and cancel section */}
          {disablex || updateMode ? (
            <li
              id="newRecord"
              onClick={() => saveAccessPriv()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => cancel_button_toggler()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                updateAccessPriv();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => cancel_button_toggler()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          <li
            id="switch"
            onClick={toggler}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span
              onClick={() => setShowAllGrids((current: boolean) => !current)}
              className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
            >
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Switch interface / table view</p>
            </Tooltip>
          </li>

          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="delete"
            onClick={() => deleteDocument()}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                src={close}
              />{" "}
            </span>
            <Tooltip
              target="#delete"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Delete Document</p>
            </Tooltip>
          </li>

          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="data"
            onClick={() => {
              handleAutoShare && handleAutoShare();
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                src={data_sharing}
              />{" "}
            </span>
            <Tooltip
              target="#data"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Auto Share</p>
            </Tooltip>
          </li>

          <li
            onClick={() => {
              handleAllShare && handleAllShare();
            }}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="share"
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                src={share}
              />{" "}
            </span>
            <Tooltip
              target="#share"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Share</p>
            </Tooltip>
          </li>

          <li
            id="help"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                src={question}
              />{" "}
            </span>
            <Tooltip
              target="#help"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Help Center</p>
            </Tooltip>
          </li>

          {/* Differents starts here */}

          <li className="items-right ml-14 ">
            <label className="text-xs px-1 ">Search Criteria </label>
            <Select
              defaultValue={searchCriteria}
              style={{ width: 150 }}
              value={searchCriteria}
              onChange={handleChange}
              options={data}
              size="small"
              dropdownStyle={{ minWidth: "250px" }}
            />
          </li>

          <li className="px-1 flex items-center ml-10 ">
            <label className="flex text-xs px-2">Search Text</label>

            <Input
              name="search"
              id="search"
              size="small"
              className="h-6"
              style={{ width: "150px" }}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
                handleSearchChange(e.target.value);
              }}
              value={searchValue}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              size="small"
              onChange={(e: any) => {
                setStartDate((prev: any) => ({ ...prev, text: dayjs(e) }));
              }}
              value={dayjs(startDate) ?? dayjs()}
              allowClear={false}
              placeholder="Start Date"
              style={{ width: 125 }}
              format={dateFormat}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              onChange={(e: any) => {
                setEndDate((prev: any) => ({ ...prev, text: dayjs(e) }));
              }}
              size="small"
              value={dayjs(endDate) ?? dayjs()}
              allowClear={false}
              placeholder="End Date"
              style={{ width: 125 }}
              format={dateFormat}
            />
          </li>
          <li className="px-0.5 mr-1">
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
          </li>

          <li
            onClick={async () => {
              setSearchValue("");
              handleRefresh();
            }}
            style={{ borderColor: borderTheme }}
            className="hover:cursor-pointer border-[1px] p-1 mr-1 border-slate-200 text-xs  text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                alt=""
                className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={refresh}
              />{" "}
            </span>
          </li>
          <li
            id="find"
            onClick={() => {
              findAccessPriv();
            }}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <FaBinoculars size={15.5} color="#007AFF" />{" "}
            </span>
            <Tooltip
              target="#find"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Find</p>
            </Tooltip>
          </li>
          {/* <li
            id="print"
            onClick={() => printAccessPriv()}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />{" "}
            </span>
            <Tooltip
              target="#print"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Print / Export</p>
            </Tooltip>
          </li> */}

          <li className="hover:cursor-pointer ml-5  px-2  flex items-center">
            {disablex && (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}

            {!disablex && (
              <div className="  flex flex-row items-center">
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                {!updateMode ? (
                  <p className=" italic text-gray-600">New Record</p>
                ) : (
                  <p className=" italic text-gray-600">Editing</p>
                )}
              </div>
            )}
          </li>
        </ul>
      </div>

      <div className="">
        <ul className=" "></ul>

        <TaskSettings
          SettingsisActive={SettingsisActive}
          SettingssetIsActive={SettingssetIsActive}
        />
        <AlertSMS
          AlertSMSisActive={AlertSMSisActive}
          AlertSMSsetIsActive={AlertSMSsetIsActive}
        />
        <AppraiseSettings
          AppraiseisActive={AppraiseisActive}
          AppraisesetIsActive={AppraisesetIsActive}
        />
      </div>
    </div>
  );
};

const data = [
  { label: "Title", value: 0 },
  { label: "Description", value: 1 },
  { label: "Document Type", value: 2 },
];
