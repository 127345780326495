import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import reset from '../../../../../assets/reset.png';
import TextArea from "antd/es/input/TextArea";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../../../app/store";
import { setGeneralValue } from "../../../../features/generalSlice";
import { PostDataFunc } from "../../../../functions/post";
import { UpdateDataFunc } from "../../../../functions/update";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useValidateExist } from "../../../../hooks/useValidateExists";
import { BottomBtns } from "../../../../templates/butttons";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { DateTemplate } from "../../../../templates/date";
import { InputsTemplate } from "../../../../templates/input";
import { ModalTemplate } from "../../../../templates/modal";
import { TextareaTemplate } from "../../../../templates/textarea";
import { saveModalData, updateModalData } from "../../../accessories/component_infos";
import SetupsDatalistTemplat from "../../../human-resource/setups/data/setups_datalist_template";
import { modalPropTypes, validateInstanceTypes, selectsTypes, modalTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import InputSelect_template from "../../../../templates/InputSelect";
import useFetch from "../../../../hooks/useFetch";
import { useCrudFunc } from "../../../../functions/crud";

interface props{
    busyloader:(text?:string)=>void,
    refreshGrid:()=>void
    clearSelected:()=>void
    selectedField:any
    data:any
}
interface stateTypes{
    name:string,
    substation: string,
    shortName:string,
    minVoltage:string,
    maxVoltage:string,
    order:number,
    opened:boolean,
    active:boolean,
    remarks:string

    selectedRecordId:number,
    confirmModal:modalPropTypes
    warningModal:modalPropTypes
    successModal:modalPropTypes
}

export const Bus_form = forwardRef(({busyloader,clearSelected,refreshGrid,data,selectedField}:props, ref) => {
    const validateExist = useValidateExist();
    const [employeeId, userId] = useAccountInfo();
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass);
    
    const postUrl:string = 'Periods/CreatePeriod';
    const updateUrl:string = 'Periods/UpdatePeriod';

    useEffect(()=>{
        populateStatesFromGrid(false);
    },[selectedField])

    const dispatch = useDispatch();

    const[states,setStates]=useState<stateTypes>({
        name:'',
        substation: '',
        shortName:'',
        minVoltage:'',
        maxVoltage: '',
        order:0,
        opened:false,
        active:false,
        remarks:'',

        selectedRecordId:0,

        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
    })

    const{name,shortName,minVoltage,maxVoltage,substation , opened,selectedRecordId,order,active,remarks,confirmModal,warningModal,successModal}=states;

    const updateState=(stateName:string,value:string|modalPropTypes|boolean|number):void=>{ //update single state
        setStates((prev)=>({...prev,[stateName]:value}));
    }

    const disableConfirmModal=()=>{
        updateState('confirmModal',{
            state:false,
            title:'',
            message:'',
            action:'post'
        })
    }

    const enableSuccessModal=(titleActionText:string,messageActionText:string)=>{
        updateState('successModal',{
            state:true,
            title:`${titleActionText} Successful`,
            message:`Record ${messageActionText} successfully`,
        })
    }

    //validate fields
    const validateFields=():boolean=>{
        const validationInstances:validateInstanceTypes[]=[
            {state:substation,baseValue:'',modalProps:{state:true,title:'Enter substation',message:'Please select a substation'}},
            {state:name,baseValue:'',modalProps:{state:true,title:'Enter name',message:'Please enter a name'}},
            {state:shortName,baseValue:'',modalProps:{state:true,title:'Enter shortname',message:'Please enter a short name'}},
            {state:minVoltage,baseValue:'',modalProps:{state:true,title:'Enter Min. Voltage',message:'Please enter min. voltage'}},
            {state:maxVoltage,baseValue:'',modalProps:{state:true,title:'Enter Max. Voltage',message:'Please enter a max. voltage'}},
            // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
        ]

        for(let instance of validationInstances) if (instance.state===instance.baseValue) {
            const{modalProps}=instance;
            updateState('warningModal',{
                state:modalProps.state,
                title:modalProps.title,
                message:modalProps.message
            })
            return false;
        }
        return true;
    }

    const [input, setInputs] = useState<any>({
        input0: "",
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: 0,
        input6: "",
        input7: "",
        input8: "",
      });

      let setInputValue = (inputKey: string, value: string | number) => {
        setInputs((prevState: any) => ({ ...prevState, [inputKey]: value }));
      };

    const disableFields=()=>{
        //disable Update Mode
        dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));

        //disable forms
        dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
    }

    const populateStatesFromGrid=(clearFields?:boolean)=>{
        const run=()=>{
            console.log(selectedField);
            setSubStationName(clearFields?'':selectedField?.stnName)

            const populateInstances=[
                {state:'substation',value:clearFields?'':(selectedField?.bdtStationIDfk === undefined? '': selectedField?.bdtStationIDfk)},
                {state:'name',value:clearFields?'':selectedField?.bdtName},
                {state:'shortName',value:clearFields?'':selectedField?.bdtShtName},
                {state:'maxVoltage',value:clearFields?'':selectedField?.bdtMaximumVoltage},
                {state:'minVoltage',value:clearFields?'':selectedField?.bdtMinimumVoltage},
                {state:'order',value:clearFields?0:selectedField?.bdtOrder},
                {state:'opened',value:clearFields?false:selectedField?.bdtOpened},
                {state:'active',value:clearFields?true:selectedField?.bdtActive},
                {state:'remarks',value:clearFields?'':selectedField?.bdtRmks},
                {state:'selectedRecordId',value:clearFields?0:selectedField?.bdtIDpk},
            ]
    
            for(let instance of populateInstances) updateState(instance.state,instance.value);
        }
        Object.keys(selectedField).length !== 0 && run();
    }

    //post data
    const [posting , updating, patching, fetching]=useCrudFunc("powerapp");

    //create
    const postData = async (action:'validate'|'post') => {
        if (action==='validate') {
            // validate fieldds
            validateFields() &&
            updateState('confirmModal',{
                state:true,
                title:'Save Record',
                message:'Are you sure you want to save this record?',
                action:'post'
            })
            return;
        }

        //post
        disableConfirmModal();
        busyloader(saveModalData.progress)
      //post request here
    //   setModal("state", false);
      // refreshGrid();
      // setBusyLoader(saveModalData.progress);
      try {

        const checkResponse =   await fetching (`BusDetails/check_before_insert?bdtName=${states.name}&bdtShtName=${states.shortName}&bdtStationIDfk=${states.substation}`,`checked Bus Detail`);
     if(checkResponse.status=== 200){
   const postResponse =   await posting (`BusDetails/Create`, {
            "bdtStationIDfk": states.substation,
            "bdtName": states.name,
            "bdtShtName": states.shortName,
            "bdtMinimumVoltage": parseInt(states.minVoltage),
            "bdtMaximumVoltage": parseInt( states.maxVoltage),
            "bdtActive": states.active,
            "bdtRmks": states.remarks,
            "formCode": "string",
            "formAction": "string"
        },`Created Bus Detail`);
        refreshGrid();
          postResponse.status===201  && enableSuccessModal('Save','saved')
        disableFields();
        refreshGrid();
        clearSelected()
        populateStatesFromGrid(false);
}
        
    } catch (error) {
        console.log('stst',error);
        if(error.response.data.code === 409){
            updateState('warningModal',{
                state: true,
                title: saveModalData.error.title,
                message: error.response.data.message
            })
        }else{
        
        updateState('warningModal',{
            state: true,
            title: saveModalData.error.title,
            message: saveModalData.error.message
        })
    }
    } finally{
        busyloader('');
    }
    return;
    };

    useImperativeHandle(ref, () => ({
       
        topNavNew: () => {
          // editAccessPriv();
          populateStatesFromGrid(true)
       
        },
      }));
  


    //update data
    const update=async(action:'validate'|'update')=>{
        console.log(states);
        
        if (action==='validate') {
            // validate fieldds
            validateFields() && 
            updateState('confirmModal',{
                state:true,
                title:'Update Record',
                message:'Are you sure you want to update this record?',
                action:'update'
            })
            return;
        }

        //update
        disableConfirmModal();
        busyloader(updateModalData.progress);

        try {
            const updateResponse =   await updating (`BusDetails/update_bus_details/${states.selectedRecordId}`, {
                "bdtIDpk": states.selectedRecordId,
                "BdtStationIDfk": states.substation,
                "BdtName": states.name,
                "BdtShtName": states.shortName,
                "BdtMinimumVoltage": parseInt(states.minVoltage),
                "BdtMaximumVoltage": parseInt( states.maxVoltage),
                "BdtActive": states.active,
                "BdtRmks": states.remarks,
                "formCode": "string",
                "formAction": "string"
            },`Updated Bus Detail`);
            refreshGrid();
            enableSuccessModal('Update','updated')
            disableFields();
            refreshGrid();
            clearSelected();
            populateStatesFromGrid(false);

        } catch (error) {
            console.log(error);

            updateState('warningModal',{
                state: true,
                title: updateModalData.error.title,
                message: updateModalData.error.message
            })
        } finally{
            busyloader('');
        }
        return;
    }

    
   

    const [refresh, setrefresh] = useState<boolean>(false);

    const [subStationName, setSubStationName] = useState<string>('');
   

     //gridview data
     const [substations, substationsError, substationsLoading] = useFetch(`BusDetails/stations`, refresh, 'stnIDpk', false, "powerapp");
     const [busNames,busNamesError, busNamesLoading] = useFetch(`BusDetails/bus_details_names`, refresh, 'bdtName', false, "powerapp");
     const [busShortNames,busShortNamesError, busShortNamesLoading] = useFetch(`BusDetails/bus_details_name_shortname`, refresh, 'bdtShtName', false, "powerapp");
     const datalists: selectsTypes[] = [
        // {id: 0 , label : 'Substation' ,optionsData :  [
        //     {
        //       "stnIDpk": 7,
        //       "stnName": "Diaba",
        //       "locName": "",
        //       "areName": ""
        //     },
        //     {
        //       "stnIDpk": 4,
        //       "stnName": "Elog Book",
        //       "locName": "",
        //       "areName": ""
        //     }], stateName: 'substation', idExpr: 'stnIDpk', dataExpr:'stnName',defaultValue:substation},
        {id: 0 , label : 'Name' ,optionsData : busNames, stateName: 'name', idExpr: 'prdIdpk', dataExpr:'bdtName',defaultValue:name},
        {id: 1 , label : 'Short Name' ,optionsData : busShortNames, stateName: 'shortName', idExpr: 'prdIdpk', dataExpr:'bdtShtName',defaultValue:shortName},
    ]


    // modal instances
    const modals:modalTypes[] = [
        //validation
        {disableCancel : true , icon : 'warning' ,open : warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : warningModal.message, okText : 'Ok', title : warningModal.title,cancelText : 'No, cancel'},
        
        //confirmation
        {disableCancel : false , icon : 'question' ,open : confirmModal.state, okHandler : confirmModal.action==='update'?()=>update('update'):()=>postData('post'), cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

        //success
        {disableCancel : true , icon : 'success' ,open : successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : successModal.message, okText : 'Ok', title : successModal.title,cancelText : 'No, cancel'},
    ]

    return (
        <>
        {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                </div>
            )
        })}


        <div className="px-2">
            {/* FORM HEADER */}
            <p id={"form_header"} style={{borderColor: borderTheme}} className='pt-1 px-2 border-[1px] border-b-0 font-semibold w-full bg-slate-100'>{'Bus Details'}</p>
            <Form
                style={{borderColor: borderTheme}}
                className={`w-full ${borderTheme ? 'border-[1px]' : 'border-2'} border-slate-100 rounded flex justify-between py-1 px-2 `}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 19 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size='small'
            >
                <section  className="" style={{width: '500px'}} >
                <InputSelect_template
                placeHolder={subStationName}
                    useCallFunc
                    options={substations}
                    selectStyle={{ height: 24 }}
                    idexpr={'stnIDpk'}
                    dataexp={'stnName'!}
                    key={'stnIDpk'}
                    label={'Substation'}
                    span={false}
                    selectedValue={(value: any) => {
                        console.log(value)
                        updateState(
                          "substation",
                          value.stnIDpk
                        );
                        setSubStationName(value.stnName)
                      }}

                      
                  />
                    {/* datalists */}
                    {datalists.map((datalist, index)=>{
                        return(
                            <div key={index} className = {datalist.id === 1 ? 'mb-1' : ' mb-1'}>
                                <SetupsDatalistTemplat
                                    datalistStyle={{height:24}} 
                                    useCallbackFunc
                                    selectedData={datalist.defaultValue}
                                    setCallbackValue={(value)=>{
                                        updateState(datalist.stateName!,value)

                                        datalist.stateName === 'name' && updateState('shortName', value);
                                    }}
                                    listid={datalist.label}
                                    idExpr={datalist.idExpr}
                                    nameExpr={datalist.dataExpr}
                                    label = {datalist.label} 
                                    options = {datalist.optionsData}
                                    handleRefresh={()=>{
                                        updateState('name', "");
                                        updateState('shortName', "");
                                      }}
                                />
                            </div>
                        )
                    })}
            <div className=" mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              useCallbackFunc
              inlinePlaceholder={[states.minVoltage, states.maxVoltage]}
              inlineInputsOnchange={(key, value) => updateState(key, value)}
              keyValues={["minVoltage", "maxVoltage"]}
              label="Min. Voltage (kV)"
              label2="Max. Voltage (kV)"
              widths={{ form1: "35%", form2: "62.9%" }}
            />
          </div>
                    

                    {/* checkbox and butons */}
                    <div className = ''>
                        <BottomBtns
                        addActiveBorders
                        defaultValue={active}
                        onNewClick={()=>{
                            populateStatesFromGrid(true)
                            clearSelected();
                            updateState('remarks','');
                        }}
                        saveHandler={()=>postData('validate')}
                        updateHandler={()=>update('validate')}
                        useCallbackFunc
                        checkboxChange={(value)=>updateState('active',value)}
                        span
                        />
                    </div>
                </section>

                {/* remarks */}
                <section className=" " style={{width : 'calc(100% - 550px)'}}>
                    <TextareaTemplate 
                        useCallbackFunc
                        setCallbackValue={(value)=>{
                            updateState('remarks',value);
                        }}
                        height = {170} 
                        defaultValue={remarks}
                        label = {'Remarks'}
                    />
                </section>
            </Form>
        </div>
        </>
    );  
})
 