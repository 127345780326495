export const safety_setups_eventTypesColumns = [
    //  {id : 0, caption : 'ID',dataField : 'evtIDpk',dataType : 'number', width : 80, fixed : true, visible : false},
    {id : 0, caption : 'NAME', dataField : 'evtName',dataType : 'string', width : 200, fixed : true , visible : true},
    {id : 1, caption : 'SHORT NAME', dataField : 'evtShtName',dataType : 'string', width : 200, fixed : true , visible : true},
    {id : 3, caption : 'ACTIVE?', dataField : 'evtActive',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 2, caption : 'ORDER', dataField : 'evtOrder',dataType : '', width : 90, fixed : true , visible : true},
    {id : 4, caption : 'SC?', dataField : 'evtShiftChange',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 5, caption : 'MN CONDITIONS?', dataField : 'evtMidnightCondition',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 6, caption : 'SILB?', dataField : 'evtShowInLogBook',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 7, caption : 'STO?', dataField : 'evtShiftTakeOver',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 8, caption : 'REMARKS',dataField : 'evtRmks',dataType : 'string', width : 4000, fixed : false , visible : true}
]