import { Form, Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { DateBox } from "devextreme-react";
import { ApplyValueMode, EditorStyle, LabelMode } from "devextreme/common";
import { Format } from "devextreme/localization";
import { DatePickerType, DateType } from "devextreme/ui/date_box";
import { memo } from "react";

type Props = {
    label?: string;
    acceptCustomValue?: boolean,
    width?: string | number | "auto" | "inherit",
    height?: string | number | "inherit",
    adaptivityEnabled?: boolean, //Specifies whether or not adaptive UI component rendering is enabled on a small screen.
    applyButtonText?: string,
    applyValueMode?: ApplyValueMode, //Specifies the way an end user applies the selected value.
    cancelButtonText?: string,
    dateSerializationFormat?: string, //Specifies the date-time value serialization format.
    disabled?: boolean, //Specifies whether the UI component responds to user interaction. 
    displayFormat?: Format, //Specifies the date display format. Ignored if the pickerType property is "native"
    focusStateEnabled?: boolean, //Specifies whether the UI component can be focused using keyboard navigation.
    hint?: string, //Specifies text for a hint that appears when a user pauses on the UI component.
    labelMode?: LabelMode,
    pickerType?: DatePickerType,
    placeholder?: string,
    readOnly?: boolean, //Specifies whether the editor is read-only.
    showAnalogClock?: boolean, //Specifies whether to show the analog clock in the value picker. Applies only if type is "datetime" and pickerType is "calendar".
    showClearButton?: boolean, //Specifies whether to display the Clear button in the UI component.
    stylingMode?: EditorStyle, //Specifies how the UI component's text field is styled.
    tabIndex?: number,
    type?: DateType,
    useMaskBehavior?: boolean, //A format used to display date/time information. 
    value?: Date | number | string,
    todayButtonText?: string,
    labelCol?: number,
    wrapperCol?: number,
    toolTip?: string;
    toolTipPlacement?: TooltipPlacement,
    ref?: any,
    onChange?: (v: any) => void, //A function that is executed when the UI component loses focus after the text field's content was changed using the keyboard.
    onValueChanged?: (v: any) => void,
}

const DateTemp = (
    {
        type = "datetime",
        acceptCustomValue = true,
        adaptivityEnabled = false,
        applyValueMode = "useButtons",
        showAnalogClock = true,
        pickerType = "calendar",
        showClearButton = false,
        stylingMode = "outlined",
        useMaskBehavior = true,
        displayFormat = "dd MMM yyyy HH:mm:ss",
        dateSerializationFormat = "dd MMM yyyy HH:mm:ss",
        height = "inherit",
        width = "auto",
        applyButtonText = "OK",
        cancelButtonText = "Cancel",
        focusStateEnabled = true,
        disabled = false,
        labelMode = "hidden",
        todayButtonText = "Today",
        ref,
        ...props
    }: Props ) => {

    return <Form.Item label={props?.label} labelCol={{ span: props?.labelCol }} wrapperCol={{ span: props.wrapperCol }}>
        <Tooltip title={props?.toolTip} placement={props?.toolTipPlacement} color="white" overlayInnerStyle={{ color: "black" }}>
            <DateBox ref={ref}
                acceptCustomValue={acceptCustomValue}
                height={height}
                width={width}
                adaptivityEnabled={adaptivityEnabled}
                applyButtonText={applyButtonText}
                applyValueMode={applyValueMode}
                cancelButtonText={cancelButtonText}
                dateSerializationFormat={dateSerializationFormat}
                disabled={disabled}
                displayFormat={displayFormat}
                focusStateEnabled={focusStateEnabled}
                hint={props?.hint}
                labelMode={labelMode}
                pickerType={pickerType}
                placeholder={props?.placeholder}
                readOnly={props?.readOnly}
                showAnalogClock={showAnalogClock}
                showClearButton={showClearButton}
                stylingMode={stylingMode}
                tabIndex={props?.tabIndex ?? 0}
                type={type}
                useMaskBehavior={useMaskBehavior}
                value={props?.value}
                onChange={props?.onChange}
                onValueChanged={({ value }) => props?.onValueChanged && props?.onValueChanged(value)}
                showDropDownButton={true}
                todayButtonText={todayButtonText}
                calendarOptions={{}}
            />
        </Tooltip>
    </Form.Item>
}

export default memo(DateTemp);