/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  checkboxTypes,
  searchTextTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../../../templates/input";
import { FindButton } from "../../../../../../accessories/buttons";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { RefreshButtonTemplate } from "../../../../../../../templates/RefreshButtonTemplate";
import { formPropsConst } from "../../../../../../accessories/constants";
import { Form } from "antd";
import { AutoShare_cols1, AutoShare_cols2 } from "../data/datagrid_cols";
import { SelectsTemplate } from "../../../../../../../templates/select";
import useFetch from "../../../../../../../hooks/useFetch";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { UpdateDataFunc } from "../../../../../../../functions/update";

type stateTypes = {
  ownCheck: boolean;
  readCheck: boolean;
  printCheck: boolean;
};

export const AutoShare = () => {
  const [states, setStates] = useState<stateTypes>({
    ownCheck: false,
    readCheck: false,
    printCheck: false,
  });

  const { ownCheck, readCheck, printCheck } = states;

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const checkboxes: checkboxTypes[] = [
    {
      id: 0,
      label: "",
      nameExpr: "",
      idExpr: "",
      stateName: "dasOwner",
      defaultValue: ownCheck,
    },
    {
      id: 1,
      label: "",
      nameExpr: "",
      idExpr: "",
      stateName: "dasReader",
      defaultValue: readCheck,
    },
    {
      id: 2,
      label: "",
      nameExpr: "",
      idExpr: "",
      stateName: "dasPrint",
      defaultValue: printCheck,
    },
  ];
  const [source, setSource] = useState({ id: 0, name: "" });
  const sourceRef = useRef<string>("");
  const [dblClickedRecord, setDblClickedRecord] = useState<any>({});
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [empId, userId] = useAccountInfo();
  const [empsRefresh, setEmpsRefresh] = useState(false);
  const [sharedRefresh, setSharedRefresh] = useState(false);
  //ModalData
  const [icon, setIcon] = useState<"warning" | "success" | "question">(
    "warning"
  );
  const [disableCancel, setDisableCancel] = useState(true);
  const [okText, setOkText] = useState("Ok");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [cancelText, setCancelText] = useState("No, Cancel");

  const sourceList = [
    { id: 1, name: "Mail Tracker" },
    { id: 2, name: "gDocs" },
    { id: 3, name: "Finance-Voucher Docs" },
    { id: 4, name: "Finance-Bank Payments Docs" },
    { id: 5, name: "Finance-PO Docs" },
    { id: 6, name: "Project Docs" },
    { id: 7, name: "Legal Docs" },
    { id: 8, name: "HR-Employee's Docs" },
    { id: 9, name: "Task Manager" },
    { id: 10, name: "Transport Document" },
  ];

  const [empsData, setEmpsData] = useState([]);
  const [Emps, EmpsError, EmpsLoading] = useFetch(
    `GenDocAutoShare/GetEmpsDetails?empId=${empId}&dataSource=${source.name}&searchText=${searchText.text}`,
    empsRefresh
  );
  const [shared, sharedError, sharedLoading] = useFetch(
    `GenDocAutoShare/GetSharedDocs?dasEmpIDfk=${empId}`,
    sharedRefresh
  );

  const addedCols = {
    dasOwner: ownCheck,
    dasReader: readCheck,
    dasPrint: printCheck,
  };
  const processEmps = () => {
    Emps.forEach((obj: any) => Object.assign(obj, addedCols));
    setEmpsData(Emps);
  };

  const selectAllOwn = useCallback((checked: boolean) => {
    setEmpsData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, dasOwner: checked }))
    );
  }, []);
  const selectAllRead = useCallback((checked: boolean) => {
    setEmpsData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, dasRead: checked }))
    );
  }, []);
  const selectAllPrint = useCallback((checked: boolean) => {
    setEmpsData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, dasPrint: checked }))
    );
  }, []);

  const postData = async (item: any) => {
    if (Object.keys(item).length !== 0) {
      if (sourceRef.current === "") {
        setIcon("warning");
        setMessage("Please select a source");
        setTitle("Validation Error");
        setDisableCancel(true);
        setOpen(true);
      } else if (
        item.dasOwner === true ||
        item.dasReader === true ||
        item.dasPrint === true
      ) {
        try {
          const res = await PostDataFunc ("GenDocAutoShare/CreateEmpAutoShare", {
            dasOwner: item.dasOwner,
            dasReader: item.dasReader,
            dasPrint: item.dasPrint,
            dasSource: sourceRef.current,
            dasShareWithEmpIDfk: "" + item.empIDpk,
          });
          if (res.data.isSuccess === true) {
            setEmpsRefresh((prev) => !prev);
            setSharedRefresh((prev) => !prev);
            setIcon("success");
            setMessage("Privilege has been added to the person successfully.");
            setTitle("Success");
            setDisableCancel(true);
            setOpen(true);
          } else {
            throw Error("Something went wrong. Try again later.");
          }
        } catch (e: any) {
          setIcon("warning");
          setMessage(e.message);
          setTitle("Unexpected Error!");
          setDisableCancel(true);
          setOpen(true);
        }
      } else {
        setIcon("warning");
        setMessage(
          "Please assign at least one privilege to the person you want to add"
        );
        setTitle("Validation Error");
        setDisableCancel(true);
        setOpen(true);
      }
    }
  };

  const confirmDelete = useCallback(() => {
    setIcon("question");
    setMessage(
      "Are you sure you want to remove the selected person from your Automatic Shared List?"
    );
    setTitle("Confirm Removal");
    setDisableCancel(false);
    setOpen(true);
  }, []);
  const deleteData = async () => {
    try {
      const res = await UpdateDataFunc(
        `GenDocAutoShare/RemoveEmpAutoShare?DasEmpIDfk=${dblClickedRecord.empIDpk}`,
        {}
      );
      if (res.data.isSuccess === true) {
        setEmpsRefresh((prev) => !prev);
        setSharedRefresh((prev) => !prev);
        setIcon("success");
        setMessage("Privilege has been added to the person successfully.");
        setTitle("Success");
        setDisableCancel(true);
        setOpen(true);
      } else {
        throw Error("Something went wrong. Try again later.");
      }
    } catch (e: any) {
      setIcon("warning");
      setMessage(e.message);
      setTitle("Unexpected Error!");
      setDisableCancel(true);
      setOpen(true);
    }
  };

  const selectAllRecord = useCallback((type: string, check: boolean) => {
    switch (type) {
      case "dasOwner":
        updateStates("ownCheck", check);
        selectAllOwn(check);
        break;
      case "dasReader":
        updateStates("readCheck", check);
        selectAllRead(check);
        break;
      case "dasPrint":
        updateStates("printCheck", check);
        selectAllPrint(check);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    processEmps();
  }, [processEmps, shared]);

  return (
    <div className="w-full h-full p-1">
      <ModalTemplate
        icon={icon}
        disableCancel={disableCancel}
        cancelText={cancelText}
        open={open}
        okHandler={() => {
          if (title === "Confirm Removal") {
            deleteData();
          }
          setOpen(false);
        }}
        cancelHandler={() => {
          setOpen(false);
        }}
        message={message}
        okText={okText}
        title={title}
      />
      <Form {...formPropsConst}>
        <div className="w-full flex  justify-evenly items-center">
          {checkboxes.map(({ stateName, defaultValue }) => {
            return (
              <div className="px-1">
                <CheckboxTemlate
                  customDisabled={false}
                  defaultValue={defaultValue}
                  useCallbackFunc
                  withBorders={true}
                  setCheckboxOnchange={(e: boolean, index: any) => {
                    selectAllRecord(stateName!, e);
                  }}
                />
              </div>
            );
          })}

          <div className="w-full">
            <Form.Item label={<p className="text-xs">{"Source"}</p>}>
              <SelectsTemplate
                selectStyle={{ width: "150px" }}
                options={sourceList}
                dataexp="name"
                idexpr="id"
                selectedValue={(e) => {
                  setSource(e);
                  sourceRef.current = e.name;
                }}
                useCallFunc
                placeHolder={source.name}
                disabled={false}
                span={true}
              />
            </Form.Item>
          </div>

          <div className="w-full flex space-x-1 items-center">
            <InputsTemplate
              disabledStatus={false}
              span={true}
              useCallbackFunc
              defaultValue={searchText.temp}
              label="Search"
              orderOnchange={(e: any) => {
                setSearchText((prev) => ({ ...prev, temp: e }));
              }}
            />
            <FindButton
              handleOnClick={() =>
                setSearchText((prev) => ({ ...prev, text: prev.temp }))
              }
              findSize={16}
              findHeight={4}
            />
          </div>
        </div>
      </Form>

      {/* 1st Datagrid */}
      <div className="">
        <Datagrid_template
          allowColumnEditing
          gridheight={250}
          columns={AutoShare_cols1}
          data={empsData}
          disableGroupPanel
          disablePaging
          disableSearch
          rowDoubleClicked={(e: any) => {
            postData(e);
          }}
        />
      </div>
      {/* 2nd Datagrid */}
      <div className="">
        <li className="w-full py-1 flex justify-between">
          <p className="text-xs text-blue-500">
            Assign at least one privilege and double click to add to your auto
            share list{" "}
          </p>
          <RefreshButtonTemplate
            handleRefresh={() => {
              setEmpsRefresh((prev) => !prev);
            }}
          />
        </li>
        <Datagrid_template
          gridheight={350}
          columns={AutoShare_cols2}
          data={shared}
          disableGroupPanel
          rowDoubleClicked={(e) => {
            setDblClickedRecord(e);
            confirmDelete();
          }}
        />
      </div>
    </div>
  );
};
