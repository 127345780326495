import {
  Checkbox,
  DatePicker,
  DatePickerProps,
  Form,
  Select,
  Upload,
} from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import TextArea from "antd/es/input/TextArea";
import { DataGrid, DropDownBox } from "devextreme-react";
import { Column, Selection } from "devextreme-react/data-grid";
import reset from "../../../../assets/reset.png";
import { useState, useEffect, useRef } from "react";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import dayjs from "dayjs";
import { station_cols, transformers_TH } from "../data/transformers-data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import image_loader from "../../../../assets/image_loader.gif";
import image_upload from "../../../../assets/image_upload.png";
import { RootState } from "../../../../app/store";
import {
  setGeneralValue,
  settask_footer_table_refresh,
  settask_footer_update,
  settask_form_disable,
} from "../../../../features/generalSlice";
import {
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import {
  SaveButton,
  UpdateButton,
  NewButton,
  Cancel,
} from "../../../accessories/buttons";
import {
  navbar_height,
  toolbar_height,
  saveModalData,
  api_url,
  updateModalData,
  api_base_url,
  currentDate,
} from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import { ImageUpload } from "./imageUpload";
import InputSelect_template from "../../../../templates/InputSelect";
import { GetDataFunc } from "../../../../functions/get";
import { DropdownGrid_template } from "../../../../templates/DropdownGrid";
import { Dropdown_Datagrid_template } from "../../../../templates/DropdownDatagrid";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { RefreshButtonTemplate } from "../../../../templates/RefreshButtonTemplate";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { useToken } from "../../../../hooks/useToken";
import { UpdateDataFunc } from "../../../../functions/update";
import { useCrudFunc } from "../../../../functions/crud";

interface stateTypes {
  transformerId?: { name: string };
  serialNoState?: { name: string };
  station?: { id: number; name: string };
  standardState?: { name: string };
  coolingTypeState?: { name: string };
  tapChanger?: { name: string };
  correctionSymbolState?: { name: string };
  manufacturerState?: { id: number; name: string };
  supplierState?: { id: number; name: string };
  statusState?: { id: number; name: string };
  statusCheck?: boolean;
  locationState?: { id: number; name: string };
  installation?: any;
  commissioning?: any;
  decommissioning?: any;
  manDate?: any;
  supplyDate?: any;
  verified?: boolean;
  active?: boolean;
  readings?: boolean;
}
interface props {
  otherDeatailsStates?: any;
  selectedRecord?: any;
  populateOD?: (clearFields?: boolean) => void;
  updateOtherDt?: (key: string, value: any) => void;
  refreshStates?: () => void;
}

const BaseDetailsTab = ({
  otherDeatailsStates,
  selectedRecord,
  populateOD,
  updateOtherDt,
  refreshStates,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  console.log("selectedRecord", selectedRecord);
  const sp = "\u00a0";
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const TransformerClear = useSelector(
    (state: RootState) => state.general.TransformerClear
  );
  const saveTransformer = useSelector(
    (state: RootState) => state.general.saveTransformer
  );
  const updateTransformer = useSelector(
    (state: RootState) => state.general.updateTransformer
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const [busyLoader, setBusyLoader] = useBusyLoader();
  //varying form height
  const form_height = 490;
  const [empID, userID, userInfo] = useAccountInfo();
  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form
  //************************************************************************************************* */
  //EndPoints

  const token = useToken();
  const [Posting , Updating] = useCrudFunc("powerapp")
 

  const [transformerID] = useFetch(
    `TransformerDetails/PopCmbTransformerIDs`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [serialNo] = useFetch(
    `TransformerDetails/PopCmbSerialNos`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [standard] = useFetch(
    `TransformerDetails/PopCmbStandards`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [station] = useFetch(
    `TransformerDetails/PopLueStation`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [coolingType] = useFetch(
    `TransformerDetails/PopCmbCoolingType`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [tapchangertype] = useFetch(
    `TransformerDetails/PopCmbTapChangerTypes`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [correctionSymbol] = useFetch(
    `TransformerDetails/PopCmbConnectionSymbols`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [manufacturer] = useFetch(
    `TransformerDetails/PopCmbManufacturers`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [supplier] = useFetch(
    `TransformerDetails/PopCmbSuppliers`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [status] = useFetch(
    `TransformerDetails/PopCmbStatus`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [equipmentLocation] = useFetch(
    `TransformerDetails/PopLueLocations`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm";

  const dispatch = useDispatch();

  const editedDate = dayjs().format();

  const [states, setStates] = useState<stateTypes>({
    transformerId: { name: "" },
    serialNoState: { name: "" },
    station: { id: 0, name: "" },
    standardState: { name: "" },
    coolingTypeState: { name: "" },
    tapChanger: { name: "" },
    correctionSymbolState: { name: "" },
    manufacturerState: { id: 0, name: "" },
    supplierState: { id: 0, name: "" },
    statusState: { id: 0, name: "" },
    statusCheck: false,
    locationState: { id: 0, name: "" },
    installation: currentDate,
    commissioning: currentDate,
    decommissioning: currentDate,
    manDate: currentDate,
    supplyDate: currentDate,
    verified: false,
    active: true,
    readings: true,
  });

  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  console.log("states", states);
  // Data from redux
  const update_state_change = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
 

  const populateFields = (clearFields?: boolean) => {
    // console.log(selectedData);

    const states = [
      {
        key: "transformerId",
        value: clearFields
          ? { name: "" }
          : {
              name: selectedRecord?.tfdTransformerID,
            },
      },
      {
        key: "serialNoState",
        value: clearFields
          ? { name: "" }
          : {
              name: selectedRecord?.tfdSerialNo,
            },
      },
      {
        key: "station",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.tfdStationIDfk,
              name: selectedRecord?.stnName,
            },
      },
      {
        key: "standardState",
        value: clearFields
          ? { name: "" }
          : { name: selectedRecord?.tfdStandard },
      },
      {
        key: "coolingTypeState",
        value: clearFields
          ? { name: "" }
          : {
              name: selectedRecord?.tfdCoolingType,
            },
      },
      {
        key: "tapChanger",
        value: clearFields
          ? { name: "" }
          : {
              name: selectedRecord?.tfdTapChangerType,
            },
      },
      {
        key: "correctionSymbolState",
        value: clearFields
          ? { name: "" }
          : {
              name: selectedRecord?.tfdConnectionSymbol,
            },
      },
      {
        key: "manufacturerState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.tfdManufacturerIDfk,
              name: selectedRecord?.mftName,
            },
      },
      {
        key: "supplierState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.tfdSupplierIDfk,
              name: selectedRecord?.splName,
            },
      },
      {
        key: "statusState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.estIDpk,
              name: selectedRecord?.estName,
            },
      },
      {
        key: "locationState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.talIDpk,
              name: selectedRecord?.talName,
            },
      },
      {
        key: "installation",
        value: clearFields
          ? currentDate
          : selectedRecord?.tfdInstallationStartDate,
      },
      {
        key: "commissioning",
        value: clearFields ? currentDate : selectedRecord?.tfdCommissionDate,
      },
      {
        key: "decommissioning",
        value: clearFields ? currentDate : selectedRecord?.tfdDecommissionDate,
      },
      {
        key: "manDate",
        value: clearFields ? currentDate : selectedRecord?.tfdManufactureDate,
      },
      {
        key: "supplyDate",
        value: clearFields ? currentDate : selectedRecord?.tfdSupplyDate,
      },
      {
        key: "verified",
        value: clearFields ? false : selectedRecord?.tfdVerified,
      },
      { key: "active", value: clearFields ? true : selectedRecord?.tfdActive },
      {
        key: "readings",
        value: clearFields ? true : selectedRecord?.tfdShowInStationReadings,
      },
      {
        key: "statusCheck",
        value: clearFields ? false : selectedRecord?.estFitStatus,
      },
    ];

    //set states
    for (let instance of states) updateState(instance.key, instance.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedRecord]);

  const resetFunction = () => {
    dispatch(setGeneralValue({ expr: "TransformerClear", value: true }));
    updateState("transformerId", { id: 0, name: "" });
    updateState("serialNoState", { id: 0, name: "" });
    updateState("station", { id: 0, name: "" });
    updateState("standardState", { id: 0, name: "" });
    updateState("coolingTypeState", { id: 0, name: "" });
    updateState("tapChanger", { id: 0, name: "" });
    updateState("correctionSymbolState", { id: 0, name: "" });
    updateState("manufacturerState", { id: 0, name: "" });
    updateState("supplierState", { id: 0, name: "" });
    updateState("statusState", { id: 0, name: "" });
    updateState("locationState", { id: 0, name: "" });
    updateState("installation", currentDate);
    updateState("commissioning", currentDate);
    updateState("decommissioning", currentDate);
    updateState("supplyDate", currentDate);
    updateState("manDate", currentDate);
    updateState("verified", false);
    updateState("active", true);
    updateState("readings", true);
    updateState("statusCheck", false);
    populateOD(true);
  };

  useEffect(() => {
    if (TransformerClear === true) {
      resetFunction();
    }
  }, [TransformerClear]);

  // Endpoints comsuption

  // Modal dialig states
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const clearFeids = () => {
    dispatch(task_footer_table_selected([[]]));
  };

 
  const validateFields = (fields) => {
    for (const field of fields) {
      if (!field.value || field.value === "") {
        setshowModalDisableButton(true);
        setModalData({
          message: `Please enter data for ${field.name}`,
          title: "Required Field",
          okText: "Ok",
        });
        setOpenModal(true);
        setIcon("warning");
        return false;
      }
    }
    return true;
  };

  const validationForms = async () => {
    const basicFields = [
      { value: states.transformerId?.name, name: "Transformer ID" },
      { value: states.serialNoState.name, name: "Serial No" },
      { value: states.station.name, name: "Station" },
      { value: states.standardState.name, name: "Standard" },
      { value: states.coolingTypeState.name, name: "Cooling Type" },
      { value: states.tapChanger.name, name: "Tap Changer Type" },
      { value: states.correctionSymbolState.name, name: "Correction Symbol" },
      { value: states.manufacturerState?.name, name: "Manufacturer" },
      { value: states.supplierState?.name, name: "Supplier" },
      { value: states.statusState?.name, name: "Status" },
      { value: states.locationState?.name, name: "Equipment Location" },
      { value: states.commissioning, name: "Commissioning Date" },
      { value: states.decommissioning, name: "Decommissioning Date" },
      { value: states.installation, name: "Installation Date" },
      { value: states.manDate, name: "Manufacturer Date" },
      { value: states.supplyDate, name: "Supply Date" },
    ];

    const otherDetailsFields = [
      { value: otherDeatailsStates.tappedWinding, name: "Tapped Winding" },
      { value: otherDeatailsStates.tapChangeRange, name: "Tap Change Range" },
      { value: otherDeatailsStates.tapChangeStep, name: "Tap Change Step" },
      { value: otherDeatailsStates.deg, name: "Deg" },
      { value: otherDeatailsStates.normalTap, name: "Normal Tap" },
      { value: otherDeatailsStates.ratedOutput, name: "Rated Output" },
      { value: otherDeatailsStates.ratedVoltageI, name: "Rated Voltage I" },
      { value: otherDeatailsStates.ratedVoltageII, name: "Rated Voltage II" },
      { value: otherDeatailsStates.ratedVoltageIII, name: "Rated Voltage III" },
      { value: otherDeatailsStates.ratedPowerI, name: "Rated Power I" },
      { value: otherDeatailsStates.ratedPowerII, name: "Rated Power II" },
      { value: otherDeatailsStates.ratedPowerIII, name: "Rated Power III" },
      { value: otherDeatailsStates.zhBase, name: "ZH Base" },
      { value: otherDeatailsStates.zxBase, name: "ZX Base" },
      { value: otherDeatailsStates.zyBase, name: "ZY Base" },
      { value: otherDeatailsStates.resistanceBase, name: "Resistance Base" },
    ];

    const basicValid = validateFields(basicFields);
    if (!basicValid) return;

    const otherDetailsValid = validateFields(otherDetailsFields);
    if (!otherDetailsValid) return;

    // If both validations pass
    setshowModalDisableButton(false);
    setIcon("question");
    setOpenModal(true);
  };
  const dropdownRef = useRef<any>(null);

  const close = () => {
    dropdownRef.current?.instance.close();
  };

 

  const postData = async () => {
    try {
      setBusyLoader("Saving record...");
      const res = await Posting(
        `TransformerDetails/CreateTransformerDetails`,
        {
          tfdSerialNo: states.serialNoState.name,
          tfdTransformerID: states.transformerId.name,
          tfdStationIDfk: states.station.id,
          tfdLocationIDfk: states.locationState.id,
          tfdStandard: states.standardState.name,
          tfdCoolingType: states.coolingTypeState.name,
          tfdTapChangerType: states.tapChanger.name,
          tfdConnectionSymbol: states.correctionSymbolState.name,
          tfdStatusIDfk: states.statusState.id,
          tfdTappedWinding: otherDeatailsStates.tappedWinding,
          tfdTapChangerRange: otherDeatailsStates.tapChangeRange,
          tfdTapChangerStep: otherDeatailsStates.tapChangeStep,
          tfdPercentImpedanceAt75Deg: otherDeatailsStates.deg,
          tfdNominalTap: otherDeatailsStates.normalTap,
          tfdRatedOutput: otherDeatailsStates.ratedOutput,
          tfdRatedVoltageI: otherDeatailsStates.ratedVoltageI,
          tfdRatedVoltageII: otherDeatailsStates.ratedVoltageII,
          tfdRatedVoltageIII: otherDeatailsStates.ratedVoltageIII,
          tfdRatedPowerI: otherDeatailsStates.ratedPowerI,
          tfdRatedPowerII: otherDeatailsStates.ratedPowerII,
          tfdRatedPowerIII: otherDeatailsStates.ratedPowerIII,
          tfdZhAt100MVABaseAt75Deg: otherDeatailsStates.zhBase,
          tfdZxAt100MVABaseAt75Deg: otherDeatailsStates.zxBase,
          tfdZyAt100MVABaseAt75Deg: otherDeatailsStates.zyBase,
          tfdResistanceAt100MVABaseAt75Deg: otherDeatailsStates.resistanceBase,
          tfdManufacturerIDfk: states.manufacturerState.id,
          tfdSupplierIDfk: states.supplierState.id,
          tfdInstallationStartDate: states.installation,
          tfdCommissionDate: states.commissioning,
          tfdDecommissionDate: states.decommissioning,
          tfdManufactureDate: states.manDate,
          tfdSupplyDate: states.supplyDate,
          tfdVerified: states.verified,
          tfdShowInStationReadings: states.readings,
          tfdActive: states.active,
          tfdRmks: "",
          tfdCreatedBy: userID,
          formAction: "Created Transformer Details successfully",
        },
      );

      // console.log(res)
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record saved successfully!",
        title: "Record saved!",
        okText: "Ok",
      });
      refreshStates();
      setBusyLoader("");
    } catch (err: any) {
      console.log("error", err);

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    try {
      setBusyLoader("Updating record...");
      const res = await Updating(
        `TransformerDetails/UpdateTransformerDetails`,
        {
          tfdIDpk: selectedRecord?.tfdIDpk,
          tfdSerialNo: states.serialNoState.name,
          tfdTransformerID: states.transformerId.name,
          tfdStationIDfk: states.station.id,
          tfdLocationIDfk: states.locationState.id,
          tfdStandard: states.standardState.name,
          tfdCoolingType: states.coolingTypeState.name,
          tfdTapChangerType: states.tapChanger.name,
          tfdConnectionSymbol: states.correctionSymbolState.name,
          tfdStatusIDfk: states.statusState.id,
          tfdTappedWinding: otherDeatailsStates.tappedWinding,
          tfdTapChangerRange: otherDeatailsStates.tapChangeRange,
          tfdTapChangerStep: otherDeatailsStates.tapChangeStep,
          tfdPercentImpedanceAt75Deg: otherDeatailsStates.deg,
          tfdNominalTap: otherDeatailsStates.normalTap,
          tfdRatedOutput: otherDeatailsStates.ratedOutput,
          tfdRatedVoltageI: otherDeatailsStates.ratedVoltageI,
          tfdRatedVoltageII: otherDeatailsStates.ratedVoltageII,
          tfdRatedVoltageIII: otherDeatailsStates.ratedVoltageIII,
          tfdRatedPowerI: otherDeatailsStates.ratedPowerI,
          tfdRatedPowerII: otherDeatailsStates.ratedPowerII,
          tfdRatedPowerIII: otherDeatailsStates.ratedPowerIII,
          tfdZhAt100MVABaseAt75Deg: otherDeatailsStates.zhBase,
          tfdZxAt100MVABaseAt75Deg: otherDeatailsStates.zxBase,
          tfdZyAt100MVABaseAt75Deg: otherDeatailsStates.zyBase,
          tfdResistanceAt100MVABaseAt75Deg: otherDeatailsStates.resistanceBase,
          tfdManufacturerIDfk: states.manufacturerState.id,
          tfdSupplierIDfk: states.supplierState.id,
          tfdInstallationStartDate: states.installation,
          tfdCommissionDate: states.commissioning,
          tfdDecommissionDate: states.decommissioning,
          tfdManufactureDate: states.manDate,
          tfdSupplyDate: states.supplyDate,
          tfdVerified: states.verified,
          tfdShowInStationReadings: states.readings,
          tfdActive: states.active,
          tfdRmks: "",
          tfdCreatedBy: userID,
          formAction: `Updated Transformer Details with ID number${selectedRecord?.tfdIDpk} successfully` ,
        },
      );
      console.log("resTrans");
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record updated successfully!",
        title: "Record updated!",
        okText: "Ok",
      });
      refreshStates();
      setBusyLoader("");
      setOpenModal(false);
    } catch (error: any) {
      console.log(error);
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  useEffect(() => {
    if (saveTransformer === true) {
      postData();
    } else if (updateTransformer === true) {
      updateData();
    }
  }, [saveTransformer, updateTransformer]);

  // Image Upload

  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageCon, setImageCon] = useState<any>([]);

  console.log(imageCon);

  // useEffect(() => {
  //    console.log(`inside ${toggled}`)

  //  console.log(station_data)

  // }, [toggled])

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        disableCancel={icon === "question" ? false : true}
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? postData()
            : modalData === updateModalData
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {
        <div className="  rounded-md">
          <style
            dangerouslySetInnerHTML={{
              __html: `
                    .dx-dropdowneditor-icon {
                        margin-top: 5px;
                    }
                    
                    .dx-dropdowneditor-icon::before {
                        content: "\f001";
                        position: absolute;
                        display: block;
                        width: 18px;
                        top: 50%;
                        margin-top: -5px;
                        left: 50%;
                        margin-left: -9px;
                    }
                    .dx-popup-wrapper {
                        width: 400px;
  

                    }
                    

                    .dx-overlay-content.dx-popup-normal.dx-resizable.dx-popup-flex-height{
                        width: 700px;
                        min-width: 700px;
                        
                    }

                    

                    
                    `,
            }}
          />

          {/* The form */}
          <div className=" flex ">
            <Form
              {...formPropsConst}
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 10 }}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
              disabled={form_disable}
            >
              {/* <Form.Item label={<p className='text-xs'>{'Start Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} format={dateFormat} style={{ display: 'inline-block', width: 'calc(65%)' }} />


               </Form.Item>

               <Form.Item label={<p className='text-xs'>{'End Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} style={{ display: 'inline-block', width: 'calc(65%)' }} format={dateFormat} />


               </Form.Item> */}
              <div>
                <InputSelect_template
                  label="Transformer ID"
                  options={transformerID}
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("transformerId", { name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.transformerId?.name}
                  disabled={disable}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Serial No"
                  options={serialNo}
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("serialNoState", { name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.serialNoState?.name}
                  disabled={disable}
                />
              </div>

              {/* <Form.Item
                label={<p className="text-xs">{"Station"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "} */}
              <div className="flex flex-row items-center w-full">
                <DropdownGrid_template
                  columns={transformers_TH}
                  gridData={station}
                  defaultValue={states.station?.name}
                  className="w-[950%] mb-1"
                  label="Station"
                  labelCol={10}
                  cusBtnStyle
                  disabled={disable}
                  dropdownBtnStyles={{ width: "100%" }}
                  onRowClick={(e: any) => {
                    updateState("station", {
                      id: e.stnIDpk!,
                      name: e.stnName!,
                    });
                  }}
                />
                {/* <DropDownBox
                      hoverStateEnabled={true}
                      ref={dropdownRef}
                      // value={this.state.gridBoxValue}
                      style={{
                        width: "100%",
                        height: "23px",
                        textAlign: "center",
                      }}
                      valueExpr="ID"
                      deferRendering={false}
                      showClearButton={true}
                      openOnFieldClick={true}
                      value={states.station?.name}
                      placeholder={states.station?.name}
                      // contentRender={() =>
                      //    dataGridRender(
                      //       station_data,
                      //       station_cols, (e: any) => {
                      //          // setstationState([e])
                      //          console.log([e])
                      //          updateState('station', { id: e.stnIdpk!, name: e.stnName! })
                      //       }
                      //    )
                      // }
                    />

                    <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 23.9,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
              </div>
              {/* </Form.Item>
              </Form.Item> */}

              <div>
                <InputSelect_template
                  label="Standard"
                  options={standard}
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("standardState", { name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.standardState?.name}
                  disabled={disable}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Cooling Type"
                  options={coolingType}
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("coolingTypeState", { name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.coolingTypeState?.name}
                  disabled={disable}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Tap changer Type"
                  options={tapchangertype.map((v, i) => ({
                    id: i,
                    name: v.name,
                  }))}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("tapChanger", { name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.tapChanger?.name}
                  disabled={disable}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Correction Symbol"
                  options={correctionSymbol}
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("correctionSymbolState", {
                      name: e.name,
                    });
                    console.log("emmm", e);
                  }}
                  placeHolder={states.correctionSymbolState?.name}
                  disabled={disable}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Manufacturer"
                  options={manufacturer}
                  idexpr="objId"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("manufacturerState", {
                      id: e.objId,
                      name: e.name,
                    });
                    console.log("e", e);
                  }}
                  placeHolder={states.manufacturerState?.name}
                  disabled={disable}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Supplier"
                  options={supplier}
                  idexpr="objId"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("supplierState", { id: e.objId, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.supplierState?.name}
                  disabled={disable}
                />
              </div>
              <Form.Item
                label={<p className="text-xs">{"Status"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Select
                      className=" mr-0 mb-0.5 py-0 overflow-hidden"
                      placeholder=""
                      onChange={(value: any) => {
                        updateState("statusState", {
                          id: JSON.parse(value)["objId"],
                          name: JSON.parse(value)["name"],
                        });
                      }}
                      value={states.statusState?.name}
                      style={{ width: "85%" }}
                      disabled={disable}
                    >
                      {status?.map((s: any, index: number) => {
                        return (
                          <Option key={index} value={JSON.stringify(s)}>
                            {s.name}
                          </Option>
                        );
                      })}
                    </Select>
                    <span className="h-full w-4 ml-1 flex justify-center hover:cursor-pointer hover">
                      {/* <Checkbox
                        disabled={disable}
                        className=""
                        checked={states.statusCheck}
                        onChange={(e) => {
                          updateState("statusState", e);
                        }}
                      ></Checkbox> */}
                      <CheckboxTemlate
                        setCheckboxOnchange={(e) => {
                          updateState("statusCheck", e);
                          console.log("box", e);
                        }}
                        defaultValue={states.statusCheck ?? false}
                        customDisabled={disable}
                      />
                    </span>

                    <span
                      onClick={() => {
                        updateState("statusState", "");
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 24.2,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span>
                  </div>
                </Form.Item>
              </Form.Item>
              {/* <div className="flex w-full bg-red-300">
                <InputSelect_template
                  label="Status"
                  options={status}
                labelCol={24}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e:any)=>{
                     updateState('statusState', {id: e.id, name: e.name})
                     console.log("e",e)
                  }}
                  placeHolder={states.statusState?.name}
                  span
                  selectStyle={{width:150}}
                />
                <CheckboxTemlate/>
                <RefreshButtonTemplate handleRefresh={function (): void {
                          throw new Error("Function not implemented.");
                       } }/>
              </div> */}

              <div>
                <InputSelect_template
                  label="Equipment Location"
                  options={equipmentLocation}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("locationState", { id: e.objId, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.locationState?.name}
                  disabled={disable}
                />
              </div>
            </Form>

            <Form
              {...formPropsConst}
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 11 }}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Installation Start Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState(
                          "installation",
                          dayjs(date).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={states.installation}
                      className="w-full"
                      format={dateFormat}
                      showTime={false}
                      disabled={disable}
                      value={dayjs(states.installation)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Commissioning Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState(
                          "commissioning",
                          dayjs(date).format("YYYY-MM-DD")
                        );
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      disabled={disable}
                      defaultValue={states.commissioning}
                      value={dayjs(states.commissioning)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Decommissioning Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState(
                          "decommissioning",
                          dayjs(date).format("YYYY-MM-DD")
                        );
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={states.decommissioning}
                      disabled={disable}
                      value={dayjs(states.decommissioning)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Manufacture Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState(
                          "manDate",
                          dayjs(date).format("YYYY-MM-DD")
                        );
                      }}
                      value={dayjs(states.manDate)}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={states.manDate}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Supply Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState(
                          "supplyDate",
                          dayjs(date).format("YYYY-MM-DD")
                        );
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={states.supplyDate}
                      disabled={disable}
                      value={dayjs(states.supplyDate)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Verified?"}</p>}
                style={{ marginBottom: 0 }}
              >
                <div className="flex justify-between ">
                  <Form.Item name="year" style={{ display: "inline-block" }}>
                    <div>
                      <Checkbox
                        checked={states.verified}
                        onChange={(e: any) => {
                          updateState("verified", e.target.checked);
                        }}
                        disabled={disable}
                      ></Checkbox>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Active?"}</p>}
                    name="month"
                    style={{ display: "inline-block" }}
                  >
                    <div>
                      <Checkbox
                        checked={states.active ?? true}
                        onChange={(e: any) => {
                          updateState("active", e.target.checked);
                        }}
                        disabled={disable}
                      ></Checkbox>
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", marginBottom: "2px" }}
                label={<p className="text-xs">{"Show In Station Readings?"}</p>}
                className="w-full mb-1"
              >
                <div className=" items-end">
                  <div className="w-full flex  flex-row justify-between  ">
                    <div>
                      <Checkbox
                        checked={states.readings ?? true}
                        onChange={(e: any) => {
                          updateState("readings", e.target.checked);
                        }}
                        disabled={disable}
                      />
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="flex  justify-end mt-20 mr-4">
                {update_state_change === false ? (
                  <SaveButton
                    disableButton={form_disable}
                    handleSave={() => {
                      setModalData(saveModalData);
                      setOpenModal(true);
                      validationForms();
                    }}
                  />
                ) : (
                  <UpdateButton
                    handleUpdate={() => {
                      setModalData(updateModalData);
                      setOpenModal(true);
                      validationForms();
                    }}
                  />
                )}
                {/* {
                  form_disable === false && update_state_change === false ? (
                    <SaveButton
                      handleSave={() => {
                        setModalData(saveModalData);
                        setOpenModal(true);
                        validation();
                      }}
                    />
                  ) : update_state_change === true ? (
                    <UpdateButton
                      handleUpdate={() => {
                        setModalData(updateModalData);
                        setOpenModal(true);
                        validationForms();
                      }}
                    /> */}

                {/* // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}} */}

                {form_disable ? (
                  <NewButton
                    new_button_toggler={() => {
                      // populateFields(true);
                      resetFunction();
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: false })
                      );
                      dispatch(task_form_disable(false));
                      // populateOD();
                    }}
                  />
                ) : (
                  <Cancel
                    cancel_button_toggler={() => {
                      setshowCancel(false);
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: true })
                      );
                      dispatch(task_form_disable(true));
                      dispatch(settask_form_disable(true));
                      dispatch(task_footer_update(false));
                      dispatch(settask_footer_update(false));

                      dispatch(
                        setGeneralValue({ expr: "updateMode", value: false })
                      );
                    }}
                  />
                )}
                {/* {form_disable === true ? (
                  <NewButton
                    new_button_toggler={() => {
                      clearFeids();
                      populateFields(true);
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: false })
                      );
                    }}
                  />
                ) : update_state_change === true ? (
                  <Cancel
                    cancel_button_toggler={() => {
                      setshowCancel(false);
                    }}
                  />)} */}

                {/* //  ) : !(update_state_change && form_disable) ? (
               //    <Cancel cancel_button_toggler={() => {}} />
               //  ) : (
               //    <NewButton */}
                {/* //      new_button_toggler={() => { */}
                {/* //        clearFeids();
               //        populateFields(true);
               //        dispatch(
               //          setGeneralValue({ expr: "formDisabled", value: false })
               //        );
               //      }}
               //    /> */}
              </div>
            </Form>
          </div>
        </div>
      }
    </div>
  );
};

export default BaseDetailsTab;
