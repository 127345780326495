/* eslint-disable react/jsx-pascal-case */
import { Dropdown, Form, Input, Tooltip } from "antd";
import { forwardRef, memo, useCallback, useState } from "react";
import DataGrid, { Column, HeaderFilter, LoadPanel, Paging, Scrolling } from "devextreme-react/cjs/data-grid";
import { datagridColumnTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { DownOutlined } from "@ant-design/icons";
import reset from "../../../../assets/refresh-small.png";
import { TooltipPlacement } from "antd/es/tooltip";
import { debounce } from "lodash";
import { useNonInitialEffect } from "../util/util";


type Props = {
    columns?: any;
    disabled?: boolean;
    dataSource?: any[];
    defaultValue?: any;
    label?: string;
    labelCol?: number;
    wrapperCol?: number;
    ref?: any;
    className?: string;
    buttonClass?: string;
    keyExpr?: string;
    displayExpr: string;
    height?: string | number;
    gridHeight?: string | number;
    gridwidth?: string | number;
    acceptCustomValue?: boolean;
    toolTip?: string;
    toolTipPlacement?: TooltipPlacement,
    placeHolder?: string;
    onSelect?: (e: any) => void; //onRowClicked
    onSearch?: (v: string) => Promise<any[]>;
    onRefresh?: () => void;
}

const GridDropdownTemp = forwardRef((
    {
        columns = [],
        label,
        className,
        buttonClass,
        keyExpr,
        displayExpr,
        dataSource,
        gridHeight = 200,
        gridwidth = 500,
        height = 26,
        labelCol,
        wrapperCol,
        acceptCustomValue = false,
        disabled = false,
        toolTip,
        toolTipPlacement = "leftBottom",
        placeHolder,
        defaultValue,
        onSelect,
        onSearch,
        onRefresh
    }: Props,
    ref: any) => {

    const [selectedRecord, setSelectedRecord] = useState("");
    const [filteredList, setFilteredList] = useState(dataSource);
    const [open, setOpen] = useState(false);

    const debouncedOnSearch = debounce((e: string) => handleSearch(e), 100);

    const handleSearch = async (value: string) => {
        if (value.trim() === "") {
            setFilteredList(dataSource);
            return;
        }

        try {
            let filter = dataSource.filter((param: any) => param[displayExpr].toLowerCase().includes(value?.toLowerCase()));
            if (filter?.length === 0) {
                filter = await onSearch(value) ?? [];
            }
            setFilteredList(filter);
        } catch (e) {
            setFilteredList([]);
        }
    };

    const handleOnSelect = (opt: any) => {
        displayExpr && setSelectedRecord(opt[displayExpr]);
        onSelect && onSelect(opt);
        setOpen(false);
    }

    const handleOnRefresh = () => {
        setSelectedRecord("");
        setFilteredList(dataSource);
        onRefresh && onRefresh();
    }

    const handleOnChange = (value: string) => { 
        setSelectedRecord(value);
        debouncedOnSearch(value);
    }

    useNonInitialEffect(() => {
        setFilteredList(dataSource);
    }, [dataSource]);

    useNonInitialEffect(() => {
        setSelectedRecord(defaultValue);
    }, [defaultValue]);

    return (
        <Form.Item label={label} labelCol={{ span: labelCol }} wrapperCol={{ span: wrapperCol }}>
            <div className="flex w-full cursor-pointer">
                <Dropdown
                    open={open}
                    onOpenChange={(open: boolean) => { setOpen(open); }}
                    trigger={["click"]}
                    className={`h-[12px] ${className} p-0 cursor-pointer `}
                    disabled={disabled}
                    dropdownRender={() => (
                        <div className="w-fit h-auto mt-3">
                            <MiniGrid columns={columns} data={filteredList} height={gridHeight} keyExpr={keyExpr} onSelect={handleOnSelect} width={gridwidth} />
                        </div>
                    )}
                >
                    <Tooltip title={toolTip} placement={toolTipPlacement} color="white" overlayInnerStyle={{ color: "black" }}>
                        <div className="w-full" onClick={() => setOpen(!open)}>
                            <Input
                                style={{ height: height }}
                                ref={ref}
                                className={`cursor-pointer ${buttonClass}`}
                                readOnly={!acceptCustomValue}
                                placeholder={placeHolder}
                                suffix={<DownOutlined className="text-gray-300" />}
                                value={selectedRecord}
                                onChange={async (e) => handleOnChange(e.target.value)}
                                disabled={disabled}
                            />
                        </div>
                    </Tooltip>
                </Dropdown >
                <div>
                    <span onClick={disabled ? () => { } : handleOnRefresh}
                        style={{ height: 24.5, borderWidth: 1, width: 24 }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded "
                    >
                        <img className={`size-full flex p-0.5 items-center justify-center ${disabled ? " bg-gray-200" : "bg-muted hover:bg-blue-50"}`} src={reset} alt="reset" />
                    </span>
                </div>
            </div>
        </Form.Item>

    );
});

export default memo(GridDropdownTemp);


type MiniGridProps = {
    columns: any;
    data: any[];
    height: string | number;
    width?: string | number;
    keyExpr: string;
    onSelect: (e: any) => void;
}

const MiniGrid = memo(({ columns, data, height, width, keyExpr, onSelect }: MiniGridProps) => {

    const dateFormat = "EEE, dd MMM yyyy HH:mm";

    return (
        <div className="w-full h-full max-h-full mt-1 max-w-none flex bottom-0  inset-0">
            {/* custom style for search section */}
            <style
                dangerouslySetInnerHTML={{ __html: `.dx-datagrid-header-panel .dx-toolbar {  margin-bottom: 1.5px;}`, }}
            />
            <DataGrid
                keyExpr={keyExpr}
                dataSource={data}
                showBorders={true}
                columnWidth={30}
                repaintChangesOnly={true}
                rowAlternationEnabled={true}
                focusedRowEnabled={true}
                hoverStateEnabled={true}
                activeStateEnabled={true}
                onFocusedRowChanged={(e) => { }}
                showRowLines={true}
                showColumnLines={true}
                allowColumnResizing={true}
                columnResizingMode="widget"
                height={height}
                width={width ?? "100%"}
                onRowClick={(e) => {
                    onSelect && onSelect(e.data ?? {});
                }}
            >
                <HeaderFilter visible={true} allowSearch={true} />
                <LoadPanel enabled={true} />

                {columns.map(
                    ({
                        id,
                        dataField,
                        caption,
                        allowEditing,
                        dataType,
                        width,
                        fixed,
                        visible,
                        format,
                    }: datagridColumnTypes) => {
                        return (
                            <Column
                                key={id}
                                dataField={dataField}
                                caption={caption}
                                dataType={dataType}
                                width={width}
                                alignment={"left"}
                                fixed={fixed}
                                allowFiltering={caption === "No" ? false : true}
                                allowEditing={allowEditing}
                                visible={visible}
                                format={
                                    format ??
                                    (dataType === "datetime"
                                        ? dateFormat
                                        : dataType === "time"
                                            ? dateFormat
                                            : dataType)
                                }
                            />
                        );
                    }
                )}
                <Scrolling
                    useNative={false}
                    scrollByContent={true}
                    scrollByThumb={true}
                    showScrollbar="always"
                />
                <Paging defaultPageSize={0} />
            </DataGrid>
        </div>
    );
})
