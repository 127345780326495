import React, { useState } from "react";

import Taskdetailsform from "./widget/Taskdetailsform";
import Datagrid_template from "../../../../templates/Datagrid";
import Taskupdate from "./widget/Taskupdate";
import { Others } from "./widget/Otherside/Other";
import { TaskMgmttoolbar } from "./widget/taskMgmttoolbar";
import { HSeperator, VSeperator } from "../../elogger/widgets/utils";
import { TaskManagement_columns } from "./widget/Otherside/col";

export default function TaskManagement() {
  const [showTaskDetails, setShowTaskDetails] = useState(true);
  const [showGrid, setShowGrid] = useState(true);
  const [showOthers, setShowOthers] = useState(true);
  return (
    <div style={{width:"calc(100%)"}} className="h-full ">
      <header className="text-md font-medium border-b-1 rounded-t-md py-1 pl-2">
        <TaskMgmttoolbar
          startDate={""}
          endDate={""}
          searchCriteria={undefined}
          setSearchCriteria={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          handleFind={function (): void {
            throw new Error("Function not implemented.");
          }}
          handleSearchChange={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          handleRefresh={function (): void {
            throw new Error("Function not implemented.");
          }}
          handleActive={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          setStartDate={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          setEndDate={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          setShowAllGrids={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          deleteDocument={function (): void {
            throw new Error("Function not implemented.");
          }}
          handleAutoShare={function (): void {
            throw new Error("Function not implemented.");
          }}
          handleAllShare={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </header>
      <main style={{width:"calc(100%-710px)"}} className="flex  items-center h-full">
        <section className=" h-full w-full">
          {showGrid && (
            <section className="flex">
              <aside className=" w-full h-full">
                <Taskdetailsform />
              </aside>
              <VSeperator
                height={100}
                onClick={() => setShowTaskDetails(!showTaskDetails)}
                status={showTaskDetails}
              />
              {showTaskDetails && (
                <aside className=" w-full h-full">
                  <Taskupdate />
                </aside>
              )}
            </section>
          )}
          <HSeperator
            onClick={() => setShowGrid(!showGrid)}
            status={showGrid}
          />
          <section className="w-full h-full">
            <Datagrid_template
              gridheight={!showGrid ? 720 : 380}
              columns={TaskManagement_columns}
              data={[]}
              // gridheight={showGrid ? window.innerHeight - heights_out - form_height : window.innerHeight - heights_out}
              className={"w-full"}
            />
          </section>
        </section>
        <VSeperator
          onClick={() => setShowOthers(!showOthers)}
          status={showOthers}
        />
        {showOthers && (
          <section  className="w-full h-full">
            <Others />
          </section>
        )}
      </main>
    </div>
  );
}
// style={{width:"calc(100%-80%)"}}