import { Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Item } from "devextreme-react/accordion";
import { TabPanel } from "devextreme-react";
import BaseDetailsTab from "./baseDetails";
import OtherDetails from "./other-details";
import GPSDetailsTab from "./gps-details";
import OtherParameters from "./other-parameters";
import { transformers_TH } from "../../Transformers/data/transformers-data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  task_form_disable,
  task_footer_table_selected,
  task_footer_update,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import {
  navbar_height,
  toolbar_height,
  api_url,
  api_base_url,
  currentDate,
} from "../../../accessories/component_infos";
import { ImageUpload } from "./imageUpload";
import Datagrid_template from "../../../../templates/Datagrid";
import { GetDataFunc } from "../../../../functions/get";
import { string } from "zod";
import { update } from "lodash";
import useFetch from "../../../../hooks/useFetch";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import { setGeneralValue } from "../../../../features/generalSlice";

const { Option } = Select;

interface props {
  toggled?: boolean;
  SearchTerm?: string;
  SearchCriteria?: number;
  CheckState?: any;
}
interface stateTypes {
  tappedWinding: string;
  tapChangeRange: string;
  tapChangeStep: string;
  deg: string;
  normalTap: string;
  ratedOutput: string;
  ratedVoltageI: string;
  ratedVoltageII: string;
  ratedVoltageIII: string;
  ratedPowerI: string;
  ratedPowerII: string;
  ratedPowerIII: string;
  zhBase: string;
  zxBase: string;
  zyBase: string;
  resistanceBase: string;
  selectedRecord?: any;
}

const TransformerDetails = ({ toggled ,SearchTerm,SearchCriteria,CheckState }: props) => {
  const [states, setStates] = useState<stateTypes>({
    tappedWinding: "",
    tapChangeRange: "",
    tapChangeStep: "",
    deg: "",
    normalTap: "",
    ratedOutput: "",
    ratedVoltageI: "",
    ratedVoltageII: "",
    ratedVoltageIII: "",
    ratedPowerI: "",
    ratedPowerII: "",
    ratedPowerIII: "",
    zhBase: "",
    zxBase: "",
    zyBase: "",
    resistanceBase: "",
  });
  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  console.log("Parentstates", states);

  const showFields = (clearFields?: boolean) => {
    // console.log(selectedData);

    const states = [
      {
        key: "tappedWinding",
        value: clearFields ? "" : selectedRecord?.tfdTappedWinding,
      },
      {
        key: "tapChangeRange",
        value: clearFields ? "" : selectedRecord?.tfdTapChangerRange,
      },
      {
        key: "tapChangeStep",
        value: clearFields ? "" : selectedRecord?.tfdTapChangerStep,
      },
      {
        key: "deg",
        value: clearFields ? "" : selectedRecord?.tfdPercentImpedanceAt75Deg,
      },
      {
        key: "normalTap",
        value: clearFields ? "" : selectedRecord?.tfdNominalTap,
      },
      {
        key: "ratedOutput",
        value: clearFields ? "" : selectedRecord?.tfdRatedOutput,
      },
      {
        key: "ratedVoltageI",
        value: clearFields ? "" : selectedRecord?.tfdRatedVoltageI,
      },
      {
        key: "ratedVoltageII",
        value: clearFields ? "" : selectedRecord?.tfdRatedVoltageII,
      },
      {
        key: "ratedVoltageIII",
        value: clearFields ? "" : selectedRecord?.tfdRatedVoltageIII,
      },
      {
        key: "ratedPowerI",
        value: clearFields ? "" : selectedRecord?.tfdRatedPowerI,
      },
      {
        key: "ratedPowerII",
        value: clearFields ? "" : selectedRecord?.tfdRatedPowerII,
      },
      {
        key: "ratedPowerIII",
        value: clearFields ? "" : selectedRecord?.tfdRatedPowerIII,
      },
      {
        key: "zhBase",
        value: clearFields ? "" : selectedRecord?.tfdZhAt100MVABaseAt75Deg,
      },
      {
        key: "zxBase",
        value: clearFields ? "" : selectedRecord?.tfdZxAt100MVABaseAt75Deg,
      },
      {
        key: "zyBase",
        value: clearFields ? "" : selectedRecord?.tfdZyAt100MVABaseAt75Deg,
      },
      {
        key: "resistanceBase",
        value: clearFields
          ? ""
          : selectedRecord?.tfdResistanceAt100MVABaseAt75Deg,
      },
    ];

    //set states
    for (let instance of states) updateState(instance.key, instance.value);
  };

  useEffect(() => {
    showFields(false);
  }, [selectedRecord]);
  const [refresh, setRefresh] = useState(false);

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const toggle = useSelector((state: RootState) => state.general.switch);
  console.log("toggle", toggle);
  const [tabs, setTabs] = useState<String[]>([
    "Base Details",
    "Other Details",
    "GPS Details",
    "Other Parameters",
  ]);
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const [DataGd, DataGd_error, DataGd_loading] = useFetch(
    `TransformerDetails/GetAllTransformerDetails?PageNumber=1&PageSize=20&SearchCriteria=${SearchCriteria}&SortColumn=3&SearchTerm=${SearchTerm}&CheckState=${CheckState}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );

  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const dispatch = useDispatch();

  //varying form height
  const form_height = 490;

  const refreshDB = useSelector((state: RootState) => state.general.refresh);

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form
  const [currentIndex, setCurrentIndex] = useState(0);
  const [DBdata, setDBdata] = useState<any>([]);

  const [showTable, setShowTable] = useState(true);

  // const dataGridData = GetDataFunc(`${api_base_url}/TransformerDetails/GetAllTransformerDetails`)

  // onSelect

  // const selectRecord = useCallback((e: any) => {
  //   e.component.byKey(e.currentSelectedRowKeys[0]).done((e: any) => {
  //     setSelectedRecord(e);
  //     //disable forms
  //     dispatch(task_form_disable(true));
  //   });
  // }, []);

  // // post selected record to redux
  // dispatch(task_footer_table_selected([selectedRecord]));

  // const allowUpdate = (e: any) => {
  //   //enable forms
  //   dispatch(task_form_disable(false));

  //   //enable Update Mode
  //   dispatch(task_footer_update(true));
  //   console.log(e);
  // };

  //   function onSelectionChanged(args:any) {
  //    if(args){
  //        console.log(args.value)
  //        setCurrentIndex(args.value)
  //    }
  //      }

  //   Endpoint Data

  const GetDBdata = async () => {
    const res = await axios.get(
      `${api_url}/api/AssetTamEntTransformerDetail/GetAssetTamLueTransformerDetails`,
      {
        headers: {
          "Content-Type": ["application/json", "text/plain", "charset=uft-8"],
        },
      }
    );
    console.log(res.data.data);
    setDBdata(res.data.data);
  };

  useEffect(() => {
    GetDBdata();
    // console.log(`inside ${toggled}`)
    console.log("hey terere ");
  }, [refreshDB]);

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
        }}
      />

      {toggled && (
        <div className="border-b-4 pb-1 flex ">
          <div className="w-[60%]">
            <TabPanel className="w-full h-full flex">
              <Item title={"Base Details"}>
                <BaseDetailsTab
                  otherDeatailsStates={states}
                  updateOtherDt={updateState}
                  selectedRecord={selectedRecord}
                  populateOD={showFields}
                  refreshStates={() => setRefresh(!refresh)}
                />
              </Item>

              <Item title={"Other Details"}>
                <OtherDetails
                  states={states}
                  updateState={updateState}
                  selectedRecord={selectedRecord}
                />
              </Item>

              <Item title={"GPS Details"}>
                <GPSDetailsTab />
              </Item>

              <Item title={"Other Parameters"}>
                <OtherParameters  selectedRecord={selectedRecord}/>
              </Item>
            </TabPanel>
          </div>
          <div className="w-[40%] ">
            <ImageUpload />
          </div>
        </div>
      )}

      <div className="pt-0.5">
        <Datagrid_template
          gridheight={toggle ? 750 : 400}
          showColumnHeaders={true}
          onRowClick={(e) => {
            setSelectedRecord(e);
            console.log("e", e);
            dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
            dispatch(setGeneralValue({ expr: "TranSelectedRecord", value: e }));
            dispatch(task_footer_update(false));
            dispatch(
              setGeneralValue({ expr: "TransformerClear", value: false })
            );
          }}
          rowDoubleClicked={() => {
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            dispatch(task_form_disable(false));

            //enable Update Mode
            dispatch(task_footer_update(true));
          }}
          columns={transformers_TH}
          data={DataGd}
          disablePaging
          disableGroupPanel
        />
        <div className="text-xs text-indigo-500 p-1">Done</div>
      </div>
    </div>
  );
};

export default TransformerDetails;
