/* eslint-disable no-restricted-globals */
import { TextArea } from "devextreme-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useResizeMode from "../../../../hooks/useResizeMode";

interface options {
  pageResized: Function;
}
const StickyNotes = forwardRef(({ pageResized }: options, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  // const [viewHeight, setViewHeight] = useState(
  //   window.innerHeight - 58.05 - 32 - 40 - 32 - news_flash_height
  // );
  let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth === screen.width && window.innerHeight === screen.height);

  let  normalPageSize =  initialSize - 234;
  let  onF11PageSize = resizeOnf11 - 234;
  
  // useImperativeHandle(ref, () => ({
  //   test: () => {
  //     setViewHeight(
  //       window.innerHeight - 58.05 - 32 - 40 - 32 - news_flash_height
  //     );
  //   },
  // }));

  return (
    <div className="mt-2 mb-5 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
      <TextArea
        height={window.innerWidth === screen.width && window.innerHeight === screen.height ? normalPageSize:onF11PageSize }
        defaultValue={
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae libero consectetur recusandae, eligendi culpa nulla ea nobis dolorem laborum. Officiis illo minus saepe dolorem quo voluptate deleniti ut quisquam non."
        }
      />
    </div>
  );
});

export default StickyNotes;
