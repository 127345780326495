
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from "react";
import {
  checkboxTypes,
  inputsTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { Form, Image } from "antd";
import { SelectsTemplate } from "../../../../../../../templates/select";

import { InputsTemplate } from "../../../../../../../templates/input";
import { DateTemplate } from "../../../../../../../templates/date";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import { AppDispatch, RootState } from "../../../../../../../app/store";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import useFetch from "../../../../../../../hooks/useFetch";
import { colorsJson } from "../data/ColourJson";
import invalidImage from "../../../../../../../assets/none.jpg";

import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  setGeneralValue,
  setRegNo,
} from "../../../../../../../features/generalSlice";
import { Root } from "react-dom/client";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { Fields, InputTypes } from "../data/AllFieldOBj";
import { FormModal } from "../../../../../../accessories/formModal";
import { VehicleCategory } from "./VehicleCategory";
import { SettingsTemplate } from "../../../../../../../templates/SettingIcon";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import dayjs from "dayjs";
import { FieldValidation } from "../data/validationfunc";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { currentDate } from "../../../../../../accessories/component_infos";
import { getRegNo } from "../data/logics";
import {
  fetchChassisNo,
  fetchCountry,
  fetchEngineMake,
  fetchEngineType,
  fetchFuelType,
  fetchManufacturer,
  fetchPowerTrain,
  fetchRegNo,
  fetchSpeedUnit,
  fetchSupplier,
  fetchTransmissionType,
  fetchVehicleClass,
  fetchVehicleMake,
  fetchVehicleModel,
} from "../../../../../../../features/apiCalls/VehiicleDetailsApi/apiSlice";
import { debounce } from "lodash";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";

export interface V_FieldTypes {
  vdtIdpk?: number;
  regNo?: string;
  vehClass?: { id: number; value: string } | string | any;
  manufacturer?: { id: number; value: string } | string | any;
  engineType?: { id: number; value: string } | string | any;
  powerTrain?: { id: number; value: string } | string | any;
  speedUnit?: { id: number; value: string } | string | any;
  vehModel?: { id: number; value: string } | string | any;
  supplier?: { id: number; value: string } | string | any;
  engineMake?: { id: number; value: string } | string | any;
  chassisNo?: string;
  vehMake?: { id: number; value: string } | string | any;
  country?: { id: number; value: string } | string | any;
  fuelType?: { id: number; value: string } | string | any;
  transType?: { id: number; value: string } | string | any;
  status?: { id: number; value: string } | string | any;
  currency?: { id: number; value: string } | string | any;

  len?: number;
  width?: number;
  height?: number;
  noCylinder?: any;
  horsePower?: any;
  netWeight?: number;
  grossWeight?: any;
  manuDate?: string;

  noOfAxles?: any;
  noOfWheels?: any;
  tankCap?: number;
  price?: string;
  supplyDate?: string;
  maxSpeed?: number;

  cubicCapacity?: any;
  seatingCap?: any;
  engineNo?: string;
  tyreSizeF?: any;
  tyreSizeM?: any;
  tyreSizeR?: any;
  other?: string;
  regDate?: string;
  colour?: string;
  general?: boolean;
  bus?: boolean;
  specialised?: boolean;
  motorBike?: boolean;
  equipment?: boolean;
  lightDuty?: boolean;
  heavyDuty?: boolean;
  vehicle?: boolean;
  active?: boolean;
  remarks?: string;
  isOpenDropdown?: boolean;
  isOpenDropdown1?: boolean;
  isOpenDropdown2?: boolean;
  isOpenDropdown3?: boolean;
  isOpenDropdown4?: boolean;
  isOpenDropdown5?: boolean;
  isOpenDropdown6?: boolean;
  isOpenDropdown7?: boolean;
  isOpenDropdown8?: boolean;
  isOpenDropdown9?: boolean;
  isOpenDropdown10?: boolean;
  isOpenDropdown11?: boolean;
  isOpenDropdown12?: boolean;
  isOpenDropdown13?: boolean;
  isLoading?: boolean;
}
interface ModalDisplay {
  icon: "warning" | "question" | "success";
  title: string;
  message: string;
  okText: string;
  cancelText?: string;
  openState: boolean;
  disableCancel: boolean;
  okHandler: () => void;
  cancelHandler: () => void;
}
interface props {
  selectedRecord?: any;
  setSelectedrecord?: (e: any) => void;
  searchCriteria?: any;
  searchTextValue?: any;
  date?: any;
  refresh?: boolean;
  setRefresh?: (e: any) => void;
}
const VehDetails_Form = ({
  selectedRecord,
  setSelectedrecord,
  searchCriteria,
  searchTextValue,
  date,
  refresh,
  setRefresh,
}: props) => {
  // state to show Image
  const [showImage, setShowImage] = useState(false);

  // state to  for  Modal openState
  const [openVehCat, setOpenVehCat] = useState(false);

  // Form Fields states
  const [states, setStates] = useState<V_FieldTypes>({
    vdtIdpk: 0,
    regNo: " ",
    vehClass: { id: 0, value: "" },
    manufacturer: { id: 0, value: "" },
    engineType: { id: 0, value: "" },
    powerTrain: { id: 0, value: "" },
    len: 0,
    width: 0,
    height: 0,
    noCylinder: 0,
    horsePower: "",
    netWeight: 0,
    grossWeight: 0,
    manuDate: currentDate,
    chassisNo: "",
    vehMake: { id: 0, value: "" },
    country: { id: 0, value: "" },
    fuelType: { id: 0, value: "" },
    transType: { id: 0, value: "" },
    status: { id: 0, value: "" },
    noOfAxles: 0,
    noOfWheels: 0,
    tankCap: 0,
    price: "",
    currency: { id: 0, value: "" },
    supplyDate: currentDate,
    maxSpeed: 0,
    speedUnit: { id: 0, value: "" },
    vehModel: { id: 0, value: "" },
    supplier: { id: 0, value: "" },
    engineMake: { id: 0, value: "" },
    cubicCapacity: 0,
    seatingCap: 0,
    engineNo: "",
    tyreSizeF: "",
    tyreSizeM: "",
    tyreSizeR: "",
    other: "",
    regDate: currentDate,
    colour: "",
    general: false,
    bus: false,
    specialised: false,
    motorBike: false,
    equipment: false,
    lightDuty: false,
    heavyDuty: false,
    vehicle: false,
    active: false,
    remarks: "",
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdown3: false,
    isOpenDropdown4: false,
    isOpenDropdown5: false,
    isOpenDropdown6: false,
    isOpenDropdown7: false,
    isOpenDropdown8: false,
    isOpenDropdown9: false,
    isOpenDropdown10: false,
    isOpenDropdown11: false,
    isOpenDropdown12: false,
    isOpenDropdown13: false,
    isLoading: false,
  });

  // EndPoint Refresh States
  //   const Refresh = {
  //     RegNumber:false,
  //     vehicleClass:false,
  //     engineType:false,
  //     powerTrain:false,
  //     fuelType:false,
  //     transmissionType:false,
  //     manufacturer:false,
  //     country:false,
  //     engineMake:false,
  //     supplier:false,
  //     speedUnit:false,
  //     currency:false,
  //     chassisNo:false,
  //     status:false,
  //     images:false,
  //     vehicleMake:false,
  //     vehicleModel:false,
  //   }
  // //EndPoints
  // const [regNumber_data, regNumber_error, regNumber_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetRegistrationAssetTamVehicleDetails`,
  //   states.regNo
  // );

  // const [vehicleClass_data, vehicleClass_error, vehicleClass_loading] =
  //   useFetch(
  //     `AssetTamVehicleDetail/GetPopVehicleClassAssetTamVehicleDetails?vhcActive=true`,
  //      states.vehClass.value
  //   );
  // console.log("vehicleClass_data:", vehicleClass_data);
  // const [engineType_data, engineType_error, engineType_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopCmbEngineTypesyAssetTamVehicleDetails?egtActive=true`,
  //   states.engineType.value
  // );
  // console.log("engineType_data: ", engineType_data);
  // const [powerTrain_data, powerTrain_error, powerTrain_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopPowerTrainAssetTamVehicleDetails?pwtActive=true`,
  //     states.powerTrain.value
  // );
  // const [fuelType_data, fuelType_error, fuelType_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetCmbFuelTypesAssetTamVehicleDetails?ftpActive=true`,
  //   states.fuelType.value
  // );
  // console.log("fuelType_data :", fuelType_data);
  // const [
  //   transmissionType_data,
  //   transmissionType_error,
  //   transmissionType_loading,
  // ] = useFetch(
  //   `AssetTamVehicleDetail/GetTransmissionTypeAssetTamVehicleDetails?trtActive=true`,
  //   states.transType.value
  // );
  // const [manufacturer_data, manufacturer_error, manufacturer_loading] =
  //   useFetch(
  //     `AssetTamVehicleDetail/GetPopManufactureAllActiveAssetTamVehicleDetails`,
  //       states.manufacturer.value
  //   );
  // const [country_data, country_error, country_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopCmbCountryAssetTamVehicleDetails?ctyActive=true`,
  //   states.country.value
  // );
  // const [engineMake_data, engineMake_error, engineMake_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetCmbEngineMakeAssetTamVehicleDetails?egmActive=true`,
  //   states.engineMake.value
  // );
  // const [supplier_data, supplier_error, supplier_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopCmbSuppliersAssetTamVehicleDetails?splActive=true`,
  //   states.supplier.value
  // );
  // const [speedUnit_data, speedUnit_error, speedUnit_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopSpeedoUnitsAssetTamVehicleDetails?spuActive=true`,
  //      states.speedUnit.value
  // );
  // console.log("speedUnit_data: ", speedUnit_data);
  // const [currency_data, currency_error, currency_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopLuePriceCurrencyAssetTamVehicleDetails?curActive=true`,
  //   states.currency.value
  // );
  // //   const [grid_data, grid_error, grid_loading] = useFetch(
  // //     `AssetTamVehicleDetail/GetAllAssetTamVehicleDetails`
  // // Refresh.
  // //   );
  // const [chassisNo_data, chassisNo_error, chassisNo_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopChassisNoAssetTamVehicleDetails`,
  //   states.chassisNo
  // );
  // console.log("chassisNo_data: ", chassisNo_data);
  // const [status_data, status_error, status_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopCmbVehicleStatusAssetTamVehicleDetails?vstActive=true`,
  //   states.status.value
  // );
  // console.log("status_data:", status_data);
  // const [images_data, images_error, images_loading] = useFetch(''
  //   // `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${states.vdtIdpk}&vimActive=true`
  //   // Refresh.images
  // );

  // const [vehicleMake_data, vehicleMake_error, vehicleMake_loading] = useFetch(
  //   `AssetTamVehicleDetail/GetPopCmbVehicleMakeAssetTamVehicleDetails?vhcname=${states.vehClass?.id}&vmkActive=true`,
  //   states.engineMake.value
  // );
  // const [vehicleModel_data, vehicleModel_error, vehicleModel_loading] =
  //   useFetch(
  //     `AssetTamVehicleDetail/GetPopCmbVehicleModelAssetTamVehicleDetails?vhcmake=${states.vehMake?.id}&mdlActive=true`,
  //       states.vehModel.value
  //   );

  // logicsVD
  // console.log("vehicleModel_data: ", vehicleModel_data);
  const regNumber_data = useSelector(
    (state: RootState) => state.apiCalls.regNodata
  );
  const vehicleClass_data = useSelector(
    (state: RootState) => state.apiCalls.vehClassdata
  );
  const engineType_data = useSelector(
    (state: RootState) => state.apiCalls.engineTypedata
  );
  const powerTrain_data = useSelector(
    (state: RootState) => state.apiCalls.powerTraindata
  );
  const fuelType_data = useSelector(
    (state: RootState) => state.apiCalls.fuelTypedata
  );
  const transmissionType_data = useSelector(
    (state: RootState) => state.apiCalls.transmissionTypedata
  );
  const manufacturer_data = useSelector(
    (state: RootState) => state.apiCalls.manufacturerdata
  );
  const country_data = useSelector(
    (state: RootState) => state.apiCalls.countrydata
  );
  const engineMake_data = useSelector(
    (state: RootState) => state.apiCalls.engineMakedata
  );
  const supplier_data = useSelector(
    (state: RootState) => state.apiCalls.supplierdata
  );
  const speedUnit_data = useSelector(
    (state: RootState) => state.apiCalls.speedUnitdata
  );
  const currency_data = useSelector(
    (state: RootState) => state.apiCalls.currencydata
  );
  const chassisNo_data = useSelector(
    (state: RootState) => state.apiCalls.chassisdata
  );
  const status_data = useSelector(
    (state: RootState) => state.apiCalls.statusdata
  );
  const images_data = useSelector(
    (state: RootState) => state.apiCalls.imagesdata
  );
  const vehicleMake_data = useSelector(
    (state: RootState) => state.apiCalls.vehMakedata
  );
  const vehicleModel_data = useSelector(
    (state: RootState) => state.apiCalls.vehicleModeldata
  );

  console.log("Rxchange", {
    regNumber_data,
    vehicleClass_data,
    manufacturer_data,
  });

  //http://localhost:5000/api/AssetTamVehicleDetail/GetEntryLookUps/lookups?=&vhcname=&vhcmake=&vstActive=true&egtActive=&pwtActive=&ftpActive=&trtActive=&ctyActive=&spuActive=&egmActive=&splActive=&curActive=&mdlActive=&=&vmkActive=&vhcActive=&pagesize=5&pagenumber=1&index=0
  // Aggregated api
  const [lookups, lookupsErr, lookupsLoading] = useFetch(
    `AssetTamVehicleDetail/GetEntryLookUps/lookups?vstActive=${true}&pagenumber=1&vhcnameID=1&vhcmakeID=1&egtActive=${true}&pwtActive=${true}&ftpActive=${true}&trtActive=${true}&ctyActive=${true}&spuActive=${true}&egmActive=${true}&splActive=${true}&curActive=${true}&mdlActive=${true}&vhcActive=${true}&pagesize=20&search=a`
  );

  console.log("LOOKUPS FOR AGRREGATION", lookups);

  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  // Redux
  const form_disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  //  state for  modal display
  const [modalData, setModalData] = useState<ModalDisplay>({
    icon: "warning",
    title: "Access Denied",
    message: "No Access Priviledge",
    okText: "Ok",
    cancelText: "cancel",
    openState: false,
    disableCancel: true,
    okHandler: () => {
      setModalData({ ...modalData, ["openState"]: false });
    },
    cancelHandler: () => {
      setModalData({ ...modalData, ["openState"]: false });
    },
  });
  // Form Fields
  const selects1: selectsTypes[] = [
    {
      id: 0,
      label: "Registration Number",
      idExpr: "vdtIdpk",
      dataExpr: "vdtRegNo",
      optionsData:
        states.regNo?.length > 0 ? regNumber_data : lookups.registrationAsset,
      stateName: "regNo",
      defaultValue: states.regNo,
      isOpenDropdown: states.isOpenDropdown13,
      isOpenDropdownKey: "isOpenDropdown13",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchRegNo,
    },
    {
      id: 1,
      label: "Vehicle Class",
      idExpr: "vhcIDpk",
      dataExpr: "vhcName",
      optionsData:
        states.vehClass?.length > 0
          ? vehicleClass_data
          : lookups.vehicleClassAsset,
      stateName: "vehClass",
      defaultValue: states.vehClass?.value,
      isOpenDropdown: states.isOpenDropdown,
      isOpenDropdownKey: "isOpenDropdown",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchVehicleClass,
    },
    {
      id: 2,
      label: "Manufacturer",
      idExpr: "mftIDpk",
      dataExpr: "mftName",
      optionsData:
        states.manufacturer?.length > 0
          ? manufacturer_data
          : lookups.manufactureAllActiveAsset,
      stateName: "manufacturer",
      defaultValue: states.manufacturer?.value,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchManufacturer,
    },
    {
      id: 3,
      label: "Engine Type",
      idExpr: "egtIDpk",
      dataExpr: "egtName",
      optionsData:
        states.engineType?.length > 0
          ? engineType_data
          : lookups.engineTypesAsset,
      stateName: "engineType",
      defaultValue: states.engineType?.value,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: "isOpenDropdown2",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchEngineType,
    },
    {
      id: 4,
      label: "Power Train",
      idExpr: "pwtIDpk",
      dataExpr: "pwtName",
      optionsData:
        states.powerTrain?.length > 0
          ? powerTrain_data
          : lookups.powerTrainAsset,
      stateName: "powerTrain",
      defaultValue: states.powerTrain?.value,
      isOpenDropdown: states.isOpenDropdown3,
      isOpenDropdownKey: "isOpenDropdown3",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchPowerTrain,
    },
  ];
  const selects2: selectsTypes[] = [
    {
      id: 0,
      label: "Chassis No",
      idExpr: "vdtIdpk",
      dataExpr: "vdtChassisNo",
      optionsData:
        states.chassisNo?.length > 0 ? chassisNo_data : lookups.chassisNoAsset,
      stateName: "chassisNo",
      defaultValue: states.chassisNo,
      isOpenDropdown: states.isOpenDropdown4,
      isOpenDropdownKey: "isOpenDropdown4",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchChassisNo,
    },
    {
      id: 1,
      label: "Vehicle Make",
      idExpr: "vmkIdpk",
      dataExpr: "vmkName",
      optionsData:
        states.vehMake?.length > 0
          ? vehicleMake_data
          : lookups.vehicleMakeAsset,
      stateName: "vehMake",
      defaultValue: states.vehMake?.value,
      isOpenDropdown: states.isOpenDropdown5,
      isOpenDropdownKey: "isOpenDropdown5",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchVehicleMake,
    },
    {
      id: 2,
      label: " Country",
      idExpr: "ctyIDpk",
      dataExpr: "ctyName",
      optionsData:
        states.country?.length > 0 ? country_data : lookups.countryAsset,
      stateName: "country",
      defaultValue: states.country?.value,
      isOpenDropdown: states.isOpenDropdown6,
      isOpenDropdownKey: "isOpenDropdown6",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchCountry,
    },
    {
      id: 3,
      label: "Fuel Type",
      idExpr: "ftpIDpk",
      dataExpr: "ftpName",
      optionsData:
        states.fuelType?.length > 0 ? fuelType_data : lookups.fuelTypesAsset,
      stateName: "fuelType",
      defaultValue: states.fuelType?.value,
      isOpenDropdown: states.isOpenDropdown7,
      isOpenDropdownKey: "isOpenDropdown7",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchFuelType,
    },
    {
      id: 4,
      label: "Transm. Type",
      idExpr: "trtIDpk",
      dataExpr: "trtName",
      optionsData:
        states.transType?.length > 0
          ? transmissionType_data
          : lookups.transmissionTypeAsset,
      stateName: "transType",
      defaultValue: states.transType?.value,
      isOpenDropdown: states.isOpenDropdown8,
      isOpenDropdownKey: "isOpenDropdown8",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchTransmissionType,
    },
  ];

  const selects3: selectsTypes[] = [
    {
      id: 0,
      label: "Max. Speedo",
      idExpr: "spuIDpk",
      dataExpr: "spuName",
      optionsData:
        states.speedUnit?.length > 0
          ? speedUnit_data
          : lookups.speedoUnitsAsset,
      stateName: "maxSpeed",
      defaultValue: states.speedUnit?.value,
      isOpenDropdown: states.isOpenDropdown9,
      isOpenDropdownKey: "isOpenDropdown9",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchSpeedUnit,
    },
    {
      id: 1,
      label: "Vehicle Model",
      idExpr: "mdlIdpk",
      dataExpr: "mdlName",
      optionsData:
        states.vehModel?.length > 0
          ? vehicleModel_data
          : lookups.vehicleModelAsset,
      stateName: "vehModel",
      defaultValue: states.vehModel?.value,
      isOpenDropdown: states.isOpenDropdown10,
      isOpenDropdownKey: "isOpenDropdown10",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchVehicleModel,
    },
    {
      id: 2,
      label: " Supplier",
      idExpr: "splIDpk",
      dataExpr: "splName",
      optionsData:
        states.supplier?.length > 0 ? supplier_data : lookups.suppliersAsset,
      stateName: "supplier",
      defaultValue: states.supplier?.value,
      isOpenDropdown: states.isOpenDropdown11,
      isOpenDropdownKey: "isOpenDropdown11",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchSupplier,
    },
    {
      id: 3,
      label: "Engine Make",
      idExpr: "egmIDpk",
      dataExpr: "egmName",
      optionsData:
        states.engineMake?.length > 0
          ? engineMake_data
          : lookups.engineMakeAsset,
      stateName: "engineMake",
      defaultValue: states.engineMake?.value,
      isOpenDropdown: states.isOpenDropdown12,
      isOpenDropdownKey: "isOpenDropdown12",
      style: "",
      handleFocus: () => { },
      singleEndpoint: fetchEngineMake,
    },
  ];

  const Len_Width_and_Height_Field: inputsTypes[] = [
    { id: 0, label: "", stateName: "len", defaultValue: states.len, style: "" },
    {
      id: 1,
      label: "",
      stateName: "width",
      defaultValue: states.width,
      style: 2,
    },
    {
      id: 2,
      label: "",
      stateName: "height",
      defaultValue: states.height,
      style: 2,
    },
  ];

  const TyreR_TyreM_TyreF_Field: inputsTypes[] = [
    {
      id: 0,
      label: "",
      stateName: "tyreSizeF",
      defaultValue: states.tyreSizeF,
      style: "",
    },
    {
      id: 1,
      label: "",
      stateName: "tyreSizeM",
      defaultValue: states.tyreSizeM,
      style: 2,
    },
    {
      id: 2,
      label: "",
      stateName: "tyreSizeR",
      defaultValue: states.tyreSizeR,
      style: 2,
    },
  ];

  // noCylinder: "",
  //   horsePower: "",

  const Number_of_cylinder_and_horsePower: inputsTypes[] = [
    {
      id: 0,
      label: "",
      stateName: "noCylinder",
      defaultValue: states.noCylinder,
      style: "",
    },

    {
      id: 1,
      label: "",
      stateName: "horsePower",
      defaultValue: states.horsePower,
      style: 2,
    },
  ];

  const Weight_N_G: inputsTypes[] = [
    {
      id: 0,
      label: "",
      stateName: "netWeight",
      defaultValue: states.netWeight,
      style: "",
    },
    {
      id: 1,
      label: "",
      stateName: "grossWeight",
      defaultValue: states.grossWeight,
      style: 2,
    },
  ];

  const No_axels_and_wheels: inputsTypes[] = [
    {
      id: 0,
      label: "",
      stateName: "noOfAxles",
      defaultValue: states.noOfAxles,
      style: "",
    },
    {
      id: 1,
      label: "",
      stateName: "noOfWheels",
      defaultValue: states.noOfWheels,
      style: 2,
    },
  ];

  const cubicCap_seatCap_EngineNo: inputsTypes[] = [
    {
      id: 0,
      label: "Cubic Cap.",
      stateName: "cubicCapacity",
      defaultValue: states.cubicCapacity,
      style: "",
    },
    {
      id: 1,
      label: "Seating Cap.",
      stateName: "seatingCap",
      defaultValue: states.seatingCap,
      style: "",
    },
    {
      id: 2,
      label: "Engine No.",
      stateName: "engineNo",
      defaultValue: states.engineNo,
      style: "",
    },
  ];

  const makeThree3: inputsTypes[] = [
    { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
    { id: 1, label: "", stateName: "", defaultValue: "", style: 2 },
    { id: 2, label: "", stateName: "", defaultValue: "", style: 2 },
  ];

  const checkboxes: checkboxTypes[] = [
    {
      id: 0,
      label: "Vehicle?",
      stateName: "vehicle",
      defaultValue: states.vehicle,
    },
    {
      id: 1,
      label: "Gen Vehicle?",
      stateName: "general",
      defaultValue: states.general,
    },
    { id: 2, label: "Bus?", stateName: "bus", defaultValue: states.bus },
    {
      id: 3,
      label: "Light Duty?",
      stateName: "lightDuty",
      defaultValue: states.lightDuty,
    },
    {
      id: 4,
      label: "Heavy Duty?",
      stateName: "heavyDuty",
      defaultValue: states.heavyDuty,
    },
    {
      id: 5,
      label: "Specialized?",
      stateName: "specialised",
      defaultValue: states.specialised,
    },
    {
      id: 6,
      label: "Moto Bike?",
      stateName: "motorBike",
      defaultValue: states.motorBike,
    },
    {
      id: 7,
      label: "Equipment?",
      stateName: "equipment",
      defaultValue: states.equipment,
    },
  ];

  const images: { id: number; image: any; alt: string }[] = [
    { id: 0, image: "", alt: "Front View" },
    { id: 1, image: "", alt: "Side View" },
    { id: 2, image: "", alt: "Back View" },
  ];

  const dispatch: AppDispatch = useDispatch();
  // user Account
  const [userId, employID, userObj] = useAccountInfo();
  // priviledge state
  const [privType, setPrivType] = useState<string>("save");
  // User Priviledges on save , read and update
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  // Function to update the state
  let UpdateState = (key: any, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  // function to Update the state with a selected record
  const UpdateState_onSingle_and_Double_Click = (e: any) => {
    console.log("% update state ", e);
    const state = [
      { key: "vdtIdpk", name: e?.vdtIDpk },
      { key: "regNo", name: e?.vdtRegNo },
      { key: "chassisNo", name: e?.vdtChassisNo },
      {
        key: "vehClass",
        name: { id: e?.vhcIDpk, value: e?.vhcName },
      },
      { key: "manufacturer", name: { id: e?.mftIDpk, value: e?.mftName } },
      { key: "engineType", name: { id: e?.egtIDpk, value: e?.egtName } },
      { key: "powerTrain", name: { id: e?.pwtIDpk, value: e?.pwtName } },
      { key: "len", name: e?.vdtLength },
      { key: "width", name: e?.vdtWidth },
      { key: "height", name: e?.vdtHeight },
      { key: "noCylinder", name: e?.vdtNoOfCylinders },
      { key: "horsePower", name: e?.vdtHorsePower },
      { key: "netWeight", name: e?.vdtNetWeight },
      { key: "grossWeight", name: e?.vdtGrossWeight },
      { key: "manuDate", name: e?.vdtManufactureDate },
      { key: "vehMake", name: { id: e?.vmkIDpk, value: e?.vmkName } },
      { key: "country", name: { id: e?.ctyIDpk, value: e?.ctyName } },
      { key: "fuelType", name: { id: e?.vdtFuelTypeIDfk, value: e?.ftpName } },
      { key: "transType", name: { id: e?.trtIDpk, value: e?.trtName } },
      { key: "status", name: { id: e?.vdtStatusIDfk, value: e?.vstName } },
      { key: "noOfAxles", name: e?.vdtNoOfAxles },
      { key: "noOfWheels", name: e?.vdtNoOfWheels },
      { key: "tankCap", name: e?.vdtTankCapacity },
      { key: "price", name: e?.vdtPrice },
      {
        key: "currency",
        name: { id: e?.vdtPriceCurrencyIDfk, value: e?.curSymbol },
      },
      { key: "supplyDate", name: e?.vdtSupplyDate },
      { key: "vehModel", name: { id: e?.vdtModelIDfk, value: e?.mdlName } },
      { key: "supplier", name: { id: e?.vdtSupplierIDfk, value: e?.splName } },
      { key: "engineMake", name: { id: e?.egmIDpk, value: e?.egmName } },
      { key: "cubicCapacity", name: e?.vdtCubicCapacity },
      { key: "seatingCap", name: e?.vdtSeatingCapacity },
      { key: "engineNo", name: e?.vdtEngineNo },
      { key: "tyreSizeF", name: e?.vdtTyreSizeFront },
      { key: "tyreSizeM", name: e?.vdtTyreSizeMiddle },
      { key: "tyreSizeR", name: e?.vdtTyreSizeRear },
      { key: "maxSpeed", name: e?.vdtSpeedometerLimit },
      { key: "colour", name: e?.vdtColour },
      { key: "general", name: e?.vdtGeneral },
      { key: "bus", name: e?.vdtBus },
      { key: "specialised", name: e?.vdtSpecialised },
      { key: "motorBike", name: e?.vdtMotorBike },
      { key: "equipment", name: e?.vdtEquipment },
      { key: "lightDuty", name: e?.vdtLightDuty },
      { key: "heavyDuty", name: e?.vdtHeavyDuty },
      { key: "vehicle", name: e?.vdtVehicle },
      { key: "active", name: e?.vdtActive },
      { key: "remarks", name: e?.vdtRmks },
      //   {key:'other',name:e?. },
      { key: "regDate", name: e?.vdtDvlaRegDate },
    ];

    for (let instance of state) {
      UpdateState(instance.key, instance.name);
    }
  };

  const ResetFields = () => {
    setStates({
      vdtIdpk: 0,
      regNo: " ",
      vehClass: { id: 0, value: "" },
      manufacturer: { id: 0, value: "" },
      engineType: { id: 0, value: "" },
      powerTrain: { id: 0, value: "" },
      len: 0,
      width: 0,
      height: 0,
      noCylinder: "",
      horsePower: "",
      netWeight: 0,
      grossWeight: "",
      manuDate: currentDate,
      chassisNo: "",
      vehMake: { id: 0, value: "" },
      country: { id: 0, value: "" },
      fuelType: { id: 0, value: "" },
      transType: { id: 0, value: "" },
      status: { id: 0, value: "" },
      noOfAxles: 0,
      noOfWheels: 0,
      tankCap: 0,
      price: "",
      currency: { id: 0, value: "" },
      supplyDate: currentDate,
      maxSpeed: 0,
      speedUnit: { id: 0, value: "" },
      vehModel: { id: 0, value: "" },
      supplier: { id: 0, value: "" },
      engineMake: { id: 0, value: "" },
      cubicCapacity: 0,
      seatingCap: 0,
      engineNo: "",
      tyreSizeF: "",
      tyreSizeM: "",
      tyreSizeR: "",
      other: "",
      regDate: currentDate,
      colour: "",
      specialised: false,
      motorBike: false,
      equipment: false,
      lightDuty: false,
      heavyDuty: false,
      vehicle: false,
      active: false,
      remarks: "",
    });
  };
  const [busyLoader, setBusyLoader] = useBusyLoader();
  // function to save data
  const SaveData = async () => {
    setBusyLoader("posting data...");
    await PostDataFunc(
      `AssetTamVehicleDetail/CreateAssetTamVehicleDetails`,
      {
        vdtRegNo: states.regNo,
        vdtChassisNo: states.chassisNo,
        vdtVehicleClassIdfk: states.vehClass?.id,
        vdtEngineNo: states.engineNo,
        vdtModelIdfk: states.vehModel?.id,
        vdtManufacturerIdfk: states.manufacturer?.id,
        vdtCountryOfOriginIdfk: states.country?.id,
        vdtSupplierIdfk: states.supplier?.id,
        vdtManufactureDate: states.manuDate,
        vdtSupplyDate: states.supplyDate,
        vdtDvlaRegDate: states.regDate,
        vdtEngineTypeIdfk: states.engineType?.id,
        vdtFuelTypeIdfk: states.fuelType?.id,
        vdtCubicCapacity: states.cubicCapacity,
        vdtTankCapacity: states.tankCap,
        vdtPowerTrainIdfk: states.powerTrain?.id,
        vdtTransmissionTypeIdfk: states.transType?.id,
        vdtEngineMakeIdfk: states.engineMake?.id,
        vdtNoOfCylinders: states.noCylinder,
        vdtSpeedometerLimit: states.maxSpeed,
        vdtSpeedometerUnitIdfk: states.speedUnit?.id,
        vdtColour: states.colour,
        vdtStatusIdfk: states.status?.id,
        vdtSeatingCapacity: states.seatingCap,
        vdtLength: states.len,
        vdtWidth: states.width,
        vdtHeight: states.height,
        vdtNoOfAxles: states.noOfAxles,
        vdtNoOfWheels: states.noOfWheels,
        vdtTyreSizeFront: states.tyreSizeF,
        vdtTyreSizeMiddle: states.tyreSizeM,
        vdtTyreSizeRear: states.tyreSizeR,
        vdtNetWeight: states.netWeight,
        vdtGrossWeight: states.grossWeight,
        vdtHorsePower: states.horsePower,
        vdtPrice: states.price,
        vdtPriceCurrencyIdfk: states.currency?.id,
        vdtGeneral: states.general,
        vdtBus: states.bus,
        vdtSpecialised: states.specialised,
        vdtMotorBike: states.motorBike,
        vdtEquipment: states.equipment,
        vdtLightDuty: states.lightDuty,
        vdtHeavyDuty: states.heavyDuty,
        vdtVehicle: states.vehicle,
        vdtActive: states.active,
        vdtRmks: states.remarks,
        vdtCreatedBy: 1,
      },
      `Created the Asset Mgr-Trans-Vehicle Details with 
        Registration No.:${states.regNo}, chassis No:${states.chassisNo}, engine No:${states.engineNo},
        manufacturer:${states.manufacturer.value}, engine Type: ${states.engineType.value}, power Train:${states.powerTrain.value},
        length:${states.len}, width:${states.width}, height:${states.height}, No. of cylinder:${states.noCylinder},
        horse Power:${states.horsePower}, net weight:${states.netWeight}, gross weight:${states.grossWeight},
        manufacturing Date:${states.manuDate}, vehicle Make:${states.vehMake.value}, country:${states.country.value}, fuel Type:${states.fuelType.value},
        Trans type:${states.transType.value}, status:${states.status.value}, No. of Axles:${states.noOfAxles}, No. of Wheels:${states.noOfWheels},
        Tank Capacity:${states.tankCap}, Price:${states.price}, currency:${states.currency.value}, supply Date:${states.supplyDate},
        maxSpeed:${states.maxSpeed}, Speed unit:${states.speedUnit.value}, vehicle model:${states.vehModel.value}, supplier:${states.supplier.value},
        engine Make:${states.engineMake.value}, cubic Capacity:${states.cubicCapacity} seating capacity:${states.seatingCap},
        tyreSizeF:${states.tyreSizeF}, tyreSizeM:${states.tyreSizeM}, tyreSizeR:${states.tyreSizeR}, other:${states.other}, registration Date:${states.regDate},   
        colour:${states.colour} successfully`
    )
      .then((response) => {
        setModalData({
          icon: "success",
          title: "Record Saved",
          message: "Record Saved Successfully",
          okText: "Ok",
          cancelText: "cancel",
          openState: true,
          disableCancel: true,
          okHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
          cancelHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
        });
        setBusyLoader("");
        setRefresh(!refresh)!;
      })
      .catch((err) => {
        setModalData({
          icon: "warning",
          title: "Record Not saved",
          message: "Please Contact Your Systems Administrator",
          okText: "Ok",
          cancelText: "cancel",
          openState: true,
          disableCancel: true,
          okHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
          cancelHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
        });
        setBusyLoader("");
      });
  };
  //function to update data
  const UpdateData = async () => {
    setBusyLoader("updating data...");
    await UpdateDataFunc(
      `AssetTamVehicleDetail/UpdateAssetTamVehicleDetails/${states.vdtIdpk}`,
      {
        vdtRegNo: states.regNo,
        vdtChassisNo: states.chassisNo,
        vdtVehicleClassIdfk: states.vehClass?.id,
        vdtEngineNo: states.engineNo,
        vdtModelIdfk: states.vehModel?.id,
        vdtManufacturerIdfk: states.manufacturer?.id,
        vdtCountryOfOriginIdfk: states.country?.id,
        vdtSupplierIdfk: states.supplier?.id,
        vdtManufactureDate: states.manuDate,
        vdtSupplyDate: states.supplyDate,
        vdtDvlaRegDate: states.regDate,
        vdtEngineTypeIdfk: states.engineType?.id,
        vdtFuelTypeIdfk: states.fuelType?.id,
        vdtCubicCapacity: states.cubicCapacity,
        vdtTankCapacity: states.tankCap,
        vdtPowerTrainIdfk: states.powerTrain?.id,
        vdtTransmissionTypeIdfk: states.transType?.id,
        vdtEngineMakeIdfk: states.engineMake?.id,
        vdtNoOfCylinders: states.noCylinder,
        vdtSpeedometerLimit: states.maxSpeed,
        vdtSpeedometerUnitIdfk: states.speedUnit?.id,
        vdtColour: states.colour,
        vdtStatusIdfk: states.status?.id,
        vdtSeatingCapacity: states.seatingCap,
        vdtLength: states.len,
        vdtWidth: states.width,
        vdtHeight: states.height,
        vdtNoOfAxles: states.noOfAxles,
        vdtNoOfWheels: states.noOfWheels,
        vdtTyreSizeFront: states.tyreSizeF,
        vdtTyreSizeMiddle: states.tyreSizeM,
        vdtTyreSizeRear: states.tyreSizeR,
        vdtNetWeight: states.netWeight,
        vdtGrossWeight: states.grossWeight,
        vdtHorsePower: states.horsePower,
        vdtPrice: states.price,
        vdtPriceCurrencyIdfk: states.currency?.id,
        vdtGeneral: states.general,
        vdtBus: states.bus,
        vdtSpecialised: states.specialised,
        vdtMotorBike: states.motorBike,
        vdtEquipment: states.equipment,
        vdtLightDuty: states.lightDuty,
        vdtHeavyDuty: states.heavyDuty,
        vdtVehicle: states.vehicle,
        vdtActive: states.active,
        vdtRmks: states.remarks,
        vdtEditedBy: 1,
      },
      `Edited the Asset Mgr-Trans-Vehicle Detail with ID:${states.vdtIdpk}, Registration No.:${states.regNo}, chassis No:${states.chassisNo}, engine No:${states.engineNo},
      manufacturer:${states.manufacturer.value}, engine Type: ${states.engineType.value}, power Train:${states.powerTrain.value},
      length:${states.len}, width:${states.width}, height:${states.height}, No. of cylinder:${states.noCylinder},
      horse Power:${states.horsePower}, net weight:${states.netWeight}, gross weight:${states.grossWeight},
      manufacturing Date:${states.manuDate}, vehicle Make:${states.vehMake.value}, country:${states.country.value}, fuel Type:${states.fuelType.value},
      Trans type:${states.transType.value}, status:${states.status.value}, No. of Axles:${states.noOfAxles}, No. of Wheels:${states.noOfWheels},
      Tank Capacity:${states.tankCap}, Price:${states.price}, currency:${states.currency.value}, supply Date:${states.supplyDate},
      maxSpeed:${states.maxSpeed}, Speed unit:${states.speedUnit.value}, vehicle model:${states.vehModel.value}, supplier:${states.supplier},
      engine Make:${states.engineMake.value}, cubic Capacity:${states.cubicCapacity} seating capacity:${states.seatingCap},
      tyreSizeF:${states.tyreSizeF}, tyreSizeM:${states.tyreSizeM}, tyreSizeR:${states.tyreSizeR}, other:${states.other}, registration Date:${states.regDate},   
      colour:${states.colour}  successfully`
    )
      .then((response) => {
        setModalData({
          icon: "success",
          title: "Updated",
          message: "Record Updated Successfully",
          okText: "Ok",
          cancelText: "cancel",
          openState: true,
          disableCancel: true,
          okHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
          cancelHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
        });
        setBusyLoader("");
        setRefresh(!refresh)!;
      })
      .catch((err) => {
        setModalData({
          icon: "warning",
          title: "Record Not Updated",
          message: "Please Contact Your Systems Administrator",
          okText: "Ok",
          cancelText: "cancel",
          openState: true,
          disableCancel: true,
          okHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
          cancelHandler: () => {
            setModalData({ ...modalData, ["openState"]: false });
          },
        });
        setBusyLoader("");
      });
  };
  // function for New button on the toolbar
  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      ResetFields();
      setSelectedrecord && setSelectedrecord({});
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied!",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  };
  //  function to cancel form
  const Cancel_Button = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };
  // function to check priv for Edit button on the toolbar
  const AccessPriv_on_UpdateButton = () => {
    setPrivType("update");
    if (updatePriv === true) {
      FieldValidation({
        method: "Update",
        states: states,
        modal_Data: modalData,
        set_ModalData: setModalData,
        UpdateData,
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied! !",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  };
  // function to check priviledge for save button on the toolbar
  const PrivAccess_on_SaveButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      FieldValidation({
        method: "Save",
        states: states,
        modal_Data: modalData,
        set_ModalData: setModalData,
        SaveData,
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied! !",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  };

  console.log("state: ", states);

  console.log("@#$:", states, "\n", "$#@:", selectedRecord);
  // Update state on single and double click
  useEffect(() => {
    console.log("%% in useeffect", selectedRecord);
    UpdateState_onSingle_and_Double_Click(selectedRecord);
  }, [selectedRecord]);

  return (
    <>
      <ModalTemplate
        icon_width={50}
        icon={modalData.icon}
        cancelText={modalData.cancelText}
        disableCancel={modalData.disableCancel}
        cancelHandler={modalData.cancelHandler}
        open={modalData.openState}
        okHandler={modalData.okHandler}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        width={
          modalData.title === "Manu. Date" ||
            modalData.title === "Supply Date" ||
            modalData.title === "Reg. Date"
            ? 500
            : 350
        }
      />
      <FormModal
        openState={openVehCat}
        child={
          <VehicleCategory
            searchCriteria={searchCriteria}
            searchTextValue={searchTextValue}
            date={date}
          />
        }
        title={"Transport-Categorise Vehicles"}
        onCancel={() => {
          setOpenVehCat(false);
        }}
      />
      <div className="w-[1500px] h-full py-0.5">
        <Form
          // onSubmitCapture={handleSubmit(Submit)}
          name="complex-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          size={"small"}
          className=""
          layout="horizontal"
        // onSubmitCapture={handleSubmit(submit)}
        // style={{ width: "25%" }}
        >
          <section className="w-full h-full">
            <section className="w-full h-full flex justify-evenly">
              {/* first layer */}
              <aside className="w-1/4">
                {selects1.map(
                  ({
                    id,
                    label,
                    optionsData,
                    idExpr,
                    dataExpr,
                    stateName,
                    defaultValue,
                    style,
                    isOpenDropdown,
                    isOpenDropdownKey,
                    handleFocus,
                    singleEndpoint,
                  }) => {
                    return (
                      <>
                        {id === 0 ? (
                          // <Controller
                          //   name={registerName}
                          //   control={control}
                          //   render={({ field }) => (
                          <InputSelect_template
                            key={id}
                            placeHolder={defaultValue}
                            handleRefresh={() => { }}
                            label={label}
                            options={optionsData}
                            // disabled={form_disable}
                            // disabled={DisableForm}
                            useCallFunc
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            isLoading={states.isLoading}
                            isOpenDropdown={isOpenDropdown}
                            handleSearch={
                              debounce((e) => {
                                UpdateState(stateName!, e);
                                // UpdateState("isLoading", true);
                                dispatch(singleEndpoint(e));

                                UpdateState(isOpenDropdownKey, true);
                              }, 500)
                            }
                            // handleSearch={useCallback((e: any) => {
                            //   // UpdateState(stateName!, e);

                            //   // dispatch(
                            //   //   setGeneralValue({ expr: "rxregNo", value:  getRegNo() })
                            //   // );
                            //  dispatch(fetchRegNo())
                            //   console.log("searching", e)

                            // })}
                            handleFocus={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                            }}
                            selectedValue={(e: any) => {
                              UpdateState(stateName!, e?.vdtRegNo);
                              UpdateState(isOpenDropdownKey, false);
                            }}
                          // field={field}
                          // registerName={registerName}
                          // errors={errors}
                          />
                        ) : (
                          <InputSelect_template
                            key={id}
                            placeHolder={defaultValue}
                            handleRefresh={() => { }}
                            label={label}
                            options={optionsData}
                            // disabled={form_disable}
                            // disabled={DisableForm}
                            useCallFunc={true}
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            isOpenDropdown={isOpenDropdown}
                            isLoading={states.isLoading}
                            handleFocus={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                            }}
                            handleSearch={
                              debounce((e: string) => {
                                UpdateState(stateName!, e);

                                dispatch(singleEndpoint(e));

                                UpdateState(isOpenDropdownKey, true);
                              }, 500) // Adjust the debounce delay as needed
                            }
                            selectedValue={(e: any) => {
                              UpdateState(isOpenDropdownKey, false);
                              UpdateState(stateName!, {
                                id: e?.[idExpr!],
                                value: e?.[dataExpr!],
                              });
                            }}
                          // register={register}
                          // registerName={registerName}
                          />
                        )}
                      </>
                    );
                  }
                )}

                <SelectsTemplate
                  // onScroll={(e:any)=>{console.log(e);}}

                  placeHolder={states.colour}
                  label={"Colour"}
                  options={colorsJson}
                  useCallFunc={true}
                  idexpr={"id"}
                  dataexp={"value"}
                  span={true}
                  fullWidth={true}
                  extraWidget={
                    <div className=" ">
                      <InputsTemplate
                        useCallbackFunc
                        span
                        inputStyle={{
                          width: 24,
                          marginLeft: 3,
                          marginBottom: 4,
                          backgroundColor: `${states.colour}`,
                        }}
                      />
                    </div>
                  }
                  selectedValue={(e: any) => {
                    UpdateState("colour", e.value);
                  }}
                />

                <Form.Item
                  label={<p className="text-xs">{"Len./Wdt./Hgt."}</p>}
                  labelCol={{ span: 8 }}
                >
                  <div className="w-full flex justify-between ">
                    {Len_Width_and_Height_Field.map(
                      ({ id, label, defaultValue, stateName, style }) => {
                        return (
                          <InputsTemplate
                            key={id}
                            useCallbackFunc
                            defaultValue={defaultValue}
                            span
                            // numberOnly={true}
                            readOnly={false}
                            inputStyle={{ width: 80, marginLeft: style }}
                            orderOnchange={(e) => {
                              UpdateState(stateName, Number(e));
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"No of Cyl/Hp"}</p>}
                  labelCol={{ span: 8 }}
                >
                  <div className="w-full flex justify-between ">
                    {Number_of_cylinder_and_horsePower.map(
                      ({ id, label, defaultValue, stateName, style }) => {
                        return id === 0 ? (
                          <InputsTemplate
                            key={id}
                            useCallbackFunc
                            // placeholder={defaultValue}
                            defaultValue={defaultValue}
                            span
                            // numberOnly={true}
                            readOnly={false}
                            inputStyle={{ width: 121, marginLeft: style }}
                            orderOnchange={(e) => {
                              UpdateState(stateName, Number(e));
                            }}
                          />
                        ) : (
                          <InputsTemplate
                            key={id}
                            useCallbackFunc
                            // placeholder={defaultValue}
                            defaultValue={defaultValue}
                            span
                            // numberOnly={true}
                            readOnly={false}
                            inputStyle={{ width: 121, marginLeft: style }}
                            orderOnchange={(e) => {
                              UpdateState(stateName, e);
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Weight(N/G)"}</p>}
                  labelCol={{ span: 8 }}
                >
                  <div className="w-full flex justify-between mb-0.5 ">
                    {Weight_N_G.map(
                      ({ id, label, defaultValue, stateName, style }) => {
                        return (
                          <InputsTemplate
                            key={id}
                            useCallbackFunc
                            span
                            // placeholder={defaultValue}
                            defaultValue={defaultValue}
                            // numberOnly={true}
                            readOnly={false}
                            inputStyle={{ width: 121, marginLeft: style }}
                            orderOnchange={(e) => {
                              UpdateState(stateName, Number(e));
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                </Form.Item>

                <DateTemplate
                  dbFormat
                  label={"Manuf. Date"}
                  width={122}
                  selectedDate={dayjs(states.manuDate)}
                  changeDate={(e) => {
                    UpdateState("manuDate", e);
                  }}
                />
              </aside>
              {/* second layer */}
              <aside className="w-1/4">
                {selects2.map(
                  ({
                    id,
                    label,
                    optionsData,
                    idExpr,
                    dataExpr,
                    stateName,
                    defaultValue,
                    style,
                    isOpenDropdown,
                    isOpenDropdownKey,
                    singleEndpoint,
                  }) => {
                    return (
                      <>
                        {id === 0 ? (
                          <InputSelect_template
                            key={id}
                            placeHolder={defaultValue}
                            handleRefresh={() => { }}
                            label={label}
                            options={optionsData}
                            // disabled={form_disable}
                            // disabled={DisableForm}
                            useCallFunc={true}
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            isLoading={states.isLoading}
                            isOpenDropdown={isOpenDropdown}
                            handleSearch={
                              debounce((e) => {
                                UpdateState(stateName!, e);
                                // UpdateState("isLoading", true);
                                dispatch(singleEndpoint(e));

                                UpdateState(isOpenDropdownKey, !isOpenDropdown);
                              }, 500)
                            }
                            handleFocus={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                            }}
                            selectedValue={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                              UpdateState(stateName!, e?.vdtChassisNo);
                              dispatch(
                                setGeneralValue({
                                  expr: stateName!,
                                  value: e?.vdtChassisNo,
                                })
                              );
                            }}
                          />
                        ) : (
                          <InputSelect_template
                            key={id}
                            placeHolder={defaultValue}
                            handleRefresh={() => { }}
                            label={label}
                            options={optionsData}
                            // disabled={form_disable}
                            // disabled={DisableForm}
                            useCallFunc={true}
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            isLoading={states.isLoading}
                            isOpenDropdown={isOpenDropdown}
                            handleSearch={
                              debounce((e) => {
                                UpdateState(stateName!, e);
                                // UpdateState("isLoading", true);
                                dispatch(singleEndpoint(e));

                                UpdateState(isOpenDropdownKey, !isOpenDropdown);
                              }, 500)
                            }
                            handleFocus={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                            }}
                            selectedValue={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                              dispatch(
                                setGeneralValue({
                                  expr: stateName!,
                                  value: {
                                    id: e?.[idExpr!],
                                    value: e?.[dataExpr!],
                                  },
                                })
                              );
                              UpdateState(stateName!, {
                                id: e?.[idExpr!],
                                value: e?.[dataExpr!],
                              });
                            }}
                          />
                        )}
                      </>
                    );
                  }
                )}

                <InputSelect_template
                  placeHolder={states.status}
                  label={"Status"}
                  options={status_data}
                  useCallFunc={true}
                  idexpr={"vstIdpk"}
                  dataexp={"vstName"}
                  span={true}
                  fullWidth={true}
                  extraWidget={
                    <div className=" ">
                      <CheckboxTemlate
                        borders={true}
                        withBorders={true}
                        style={{
                          width: 24,
                          height: 22.5,
                          marginBottom: 3,
                          marginLeft: 2,
                        }}
                        defaultValue={states.active}
                        setCheckboxOnchange={(e) => {
                          UpdateState("active", e);
                        }}
                      />
                    </div>
                  }
                  selectedValue={(e) => {
                    UpdateState("status", { id: e.vstIdpk, value: e.vstName });
                    dispatch(
                      setGeneralValue({
                        expr: "status",
                        value: { id: e.vstIdpk, value: e.vstName },
                      })
                    );
                  }}
                />

                <Form.Item
                  label={<p className="text-xs">{"No of Axles/Wheels"}</p>}
                  labelCol={{ span: 8 }}
                >
                  <div className="w-full flex justify-between ">
                    {No_axels_and_wheels.map(
                      ({ id, label, defaultValue, stateName, style }) => {
                        return (
                          <InputsTemplate
                            key={id}
                            useCallbackFunc
                            span
                            // numberOnly={true}
                            readOnly={false}
                            defaultValue={defaultValue}
                            inputStyle={{ width: 121, marginLeft: style }}
                            orderOnchange={(e) => {
                              UpdateState(stateName, Number(e));
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                </Form.Item>

                <InputsTemplate
                  useCallbackFunc
                  labelColPan={8}
                  label={"Tank Cap(Ltrs)"}
                  // numberOnly={true}
                  // placeholder={states.tankCap}
                  // numberOnly={true}
                  placeholder={states.tankCap}
                  span
                  inputStyle={{ width: "100%" }}
                  defaultValue={states.tankCap}
                  orderOnchange={(e) => {
                    UpdateState("tankCap", Number(e));
                  }}
                />
                <InputsTemplate
                  useCallbackFunc
                  labelColPan={8}
                  label={"Price"}
                  // inputType="text"
                  // numberOnly={true}
                  placeholder={states.price}
                  span
                  inputStyle={{ width: 121, MarginRight: 3, height: 23 }}
                  defaultValue={states.price || " "}
                  orderOnchange={(e) => {
                    UpdateState("price", Number(e));
                  }}
                  additionalWidget={
                    <>
                      <InputSelect_template
                        useCallFunc
                        selectStyle={{ width: 121, marginLeft: 3 }}
                        placeHolder={states.currency}
                        // options={currency_data}
                        idexpr="curIDpk"
                        dataexp="curSymbol"
                        selectedValue={(e) => {
                          dispatch(
                            setGeneralValue({
                              expr: "currency",
                              value: {
                                id: e.curIDpk,
                                value: e.curSymbol,
                              },
                            })
                          );
                          UpdateState("currency", {
                            id: e.curIDpk,
                            value: e.curSymbol,
                          });
                        }}
                      />
                    </>
                  }
                />

                <DateTemplate
                  dbFormat
                  label={"Supply Date"}
                  width={110}
                  selectedDate={dayjs(states.supplyDate)}
                  // datePlaceholder={dayjs().format("DD MMM YYYY")}
                  changeDate={(e) => {
                    UpdateState("supplyDate", e);
                  }}
                />
              </aside>

              {/* third layer */}
              <aside className="w-1/4">
                {selects3.map(
                  ({
                    id,
                    label,
                    optionsData,
                    idExpr,
                    dataExpr,
                    stateName,
                    defaultValue,
                    style,
                  }) => {
                    return (
                      <>
                        {id === 0 ? (
                          <InputsTemplate
                            key={id}
                            labelColPan={8}
                            useCallbackFunc
                            label={label}
                            // numberOnly={true}
                            span
                            inputStyle={{ width: 121, MarginRight: 3 }}
                            orderOnchange={(e) => {
                              console.log("input ---> e: ", e);
                              UpdateState("maxSpeed", e);
                            }}
                            additionalWidget={
                              <>
                                <InputSelect_template
                                  placeHolder={defaultValue}
                                  options={optionsData}
                                  idexpr={idExpr}
                                  dataexp={dataExpr}
                                  useCallFunc
                                  selectedValue={(e: any) => {
                                    dispatch(
                                      setGeneralValue({
                                        expr: "speedUnit",
                                        value: {
                                          id: e?.spuIDpk,
                                          value: e?.spuName,
                                        },
                                      })
                                    );
                                    UpdateState("speedUnit", {
                                      id: e?.spuIDpk,
                                      value: e?.spuName,
                                    });
                                  }}
                                  selectStyle={{ width: 121, marginLeft: 3 }}
                                />
                              </>
                            }
                          />
                        ) : (
                          <InputSelect_template
                            key={id}
                            placeHolder={defaultValue}
                            handleRefresh={() => { }}
                            label={label}
                            options={optionsData}
                            // disabled={form_disable}
                            // disabled={DisableForm}
                            useCallFunc={true}
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            selectedValue={(e: any) => {
                              dispatch(
                                setGeneralValue({
                                  expr: stateName!,
                                  value: {
                                    id: e?.[idExpr!],
                                    value: e?.[dataExpr!],
                                  },
                                })
                              );
                              UpdateState(stateName, {
                                id: e?.[idExpr!],
                                value: e?.[dataExpr!],
                              });
                            }}
                          />
                        )}
                      </>
                    );
                  }
                )}

                {cubicCap_seatCap_EngineNo.map(
                  ({ id, label, defaultValue, stateName, style }) => {
                    return id === 0 || id === 1 ? (
                      <InputsTemplate
                        key={id}
                        labelColPan={8}
                        label={label}
                        defaultValue={defaultValue}
                        span
                        inputStyle={{ width: "100%" }}
                        // placeholder={defaultValue}
                        useCallbackFunc
                        orderOnchange={(e) => {
                          UpdateState(stateName, Number(e));
                        }}
                      />
                    ) : (
                      <InputsTemplate
                        key={id}
                        labelColPan={8}
                        label={label}
                        defaultValue={defaultValue}
                        span
                        inputStyle={{ width: "100%" }}
                        // placeholder={defaultValue}
                        useCallbackFunc
                        orderOnchange={(e) => {
                          UpdateState(stateName, e);
                        }}
                      />
                    );
                  }
                )}

                <Form.Item
                  label={<p className="text-xs">{"Tyre Size(F/M/R)"}</p>}
                  labelCol={{ span: 8 }}
                >
                  <div className="w-full flex justify-between mb-0.3 ">
                    {TyreR_TyreM_TyreF_Field.map(
                      ({ id, label, defaultValue, stateName, style }) => {
                        return (
                          <InputsTemplate
                            key={id}
                            useCallbackFunc
                            span
                            // numberOnly={true}
                            // placeholder={defaultValue}
                            defaultValue={defaultValue}
                            readOnly={false}
                            inputStyle={{ width: 80, marginLeft: style }}
                            orderOnchange={(e) => {
                              UpdateState(stateName, e);
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                </Form.Item>

                <InputsTemplate
                  labelColPan={8}
                  useCallbackFunc
                  label={"other"}
                  // numberOnly={true}
                  placeholder={states.other}
                  span
                  inputStyle={{
                    width: 121,
                    MarginRight: 3,
                    height: 23,
                    marginBottom: 0.5,
                  }}
                  orderOnchange={(e) => {
                    console.log("other", e);
                    UpdateState("other", e);
                  }}
                  additionalWidget={
                    <>
                      <InputSelect_template
                        useCallFunc
                        selectStyle={{ width: 121, marginLeft: 3 }}
                        placeHolder={states.currency}
                        // options={currency_data}
                        idexpr="curIDpk"
                        dataexp="curSymbol"
                        selectedValue={(e) => {
                          dispatch(
                            setGeneralValue({
                              expr: "currency",
                              value: {
                                id: e.curIDpk,
                                value: e.curSymbol,
                              },
                            })
                          );
                          UpdateState("currency", {
                            id: e.curIDpk,
                            value: e.curSymbol,
                          });
                        }}
                      />
                    </>
                  }
                />

                <DateTemplate
                  dbFormat
                  label={"Reg. Date"}
                  width={110}
                  selectedDate={dayjs(states.regDate)}
                  // datePlaceholder={dayjs().format("DD MMM YYYY")}
                  changeDate={(e) => {
                    UpdateState("regDate", e);
                  }}
                />
              </aside>

              {/* Fourth layer */}
              <aside className="w-1/4 ">
                <div className="w-full flex-col justify-center items-center  px-5">
                  {images.map(({ id, image, alt }) => {
                    return (
                      <div
                        key={id}
                        className="w-full justify-center items-center"
                      >
                        <Image
                          style={{ borderColor: borderTheme }}
                          width={240}
                          height={90}
                          className={`text-xs border-[1px]`}
                          src={
                            !showImage
                              ? invalidImage
                              : `data:image/png;base64, ${images_data[id]?.vimImage}`
                          }
                          alt={alt}
                          onError={(e) => {
                            e.currentTarget.src = invalidImage;
                          }}
                        />
                      </div>
                    );
                  })}

                  <div className="flex py-0.5 space-x-2">
                    <CheckboxTemlate
                      customDisabled={false}
                      setCheckboxOnchange={(e) => {
                        setShowImage(e);
                      }}
                      useCallbackFunc
                      withBorders={true}
                    />
                    <p className="">Show Images Auto</p>
                  </div>
                </div>
              </aside>
            </section>

            {/* Checkboxes and remarks */}
            <section className="w-full">
              {/* <Form.Item label={<p className='text-xs'>{"Veh. Category"}</p>} labelCol={{ span: 3 }} className="w-full flex py-0.5"> */}
              <div className="w-[76%]  flex items-center justify-evenly py-0.5">
                {checkboxes.map(({ id, label, defaultValue, stateName }) => {
                  return (
                    <>
                      {id == 0 ? (
                        <Form.Item
                          label={
                            <p className="w-full text-xs">{"Veh. Category"}</p>
                          }
                          labelCol={{ span: 14 }}
                          key={id}
                        >
                          <div className="w-full flex  items-center ">
                            <CheckboxTemlate
                              useCallbackFunc
                              defaultValue={defaultValue}
                              withBorders={true}
                              setCheckboxOnchange={(e) => {
                                UpdateState(stateName, e);
                              }}
                            />
                            <p className="px-1 text-xs">{label}</p>
                          </div>
                        </Form.Item>
                      ) : (
                        <div className="flex items-center " key={id}>
                          <CheckboxTemlate
                            useCallbackFunc
                            defaultValue={defaultValue}
                            withBorders={true}
                            setCheckboxOnchange={(e) => {
                              UpdateState(stateName, e);
                            }}
                          />
                          <p className="px-1 text-xs">{label}</p>
                        </div>
                      )}
                    </>
                  );
                })}

                <SettingsTemplate
                  handleClick={() => {
                    setOpenVehCat(true);
                  }}
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 24,
                    borderColor: borderTheme,
                  }}
                />
              </div>
              {/* </Form.Item> */}

              {/* Textarea */}

              <section className="w-3/4  space-x-2 flex py-0.5">
                <div className="w-full">
                  <TextareaTemplate
                    label={"Remarks"}
                    height={70}
                    labelCol={2}
                    MarginLeft={40}
                    placeholder={states.remarks}
                    defaultValue={states.remarks}
                    useCallbackFunc
                    setCallbackValue={(e) => {
                      UpdateState("remarks", e);
                    }}
                  />
                </div>

                <div className="space-y-0.5">
                  {form_disabled ? (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        AccessPriv_on_NewButton();
                      }}
                    />
                  ) : (
                    <Cancel
                      useCallbackFunc={true}
                      cancel_button_toggler={() => {
                        Cancel_Button();
                      }}
                    />
                  )}

                  {updateMode === false ? (
                    <SaveButton
                      disableButton={form_disabled}
                      handleSave={() => {
                        PrivAccess_on_SaveButton();
                      }}
                    />
                  ) : (
                    <UpdateButton
                      handleUpdate={() => {
                        AccessPriv_on_UpdateButton();
                      }}
                    />
                  )}
                </div>
              </section>
            </section>
          </section>
        </Form>
      </div>
    </>
  );
};

export default VehDetails_Form;
