import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import { RcFile } from "antd/es/upload";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import TextArea from "antd/es/input/TextArea";
import { useCrudFunc } from "../../../../functions/crud";
import { ModalTemplate } from "../../../../templates/modal";
import { saveModalData } from "../../../accessories/component_infos";
import useFetch from "../../../../hooks/useFetch";
import { set } from "lodash";

interface Props {
  statesx?: any;
  onRecordClick?: (recordId: number) => void;
  imageDescriptions?: any;
  setImageDescriptions?: any;
}

interface ImageData {
  imageData: string;
  index: number;
  description?: string;
}

export const ImageUpload = ({ statesx, onRecordClick, imageDescriptions, setImageDescriptions }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [imageCon, setImageCon] = useState<{ [key: number]: string }>({});
  // const [imageDescriptions, setImageDescriptions] = useState("");
  const [fileName, setFileName] = useState<string>("");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [refresh, setRefresh] = useState<boolean>(false);

  const [Posting] = useCrudFunc("powerapp");

  const [images, error, isLoading] = useFetch(
    `LineDetails/images/${statesx?.ldtIDpk}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );

  const saveAccessPriv = () => {
    setIcon("question");
    setModalData({
      message: "Are you sure you want to save the image?",
      title: "Save Image",
      okText: "Yes",
      cancelText: "No",
    });
    setOpenModal(true);
  };

  useEffect(() => {
    if (statesx?.ldtIDpk) {
      setRefresh((prev) => !prev);
    }
  }, [statesx?.ldtIDpk]);

  useEffect(() => {
    if (images && Array.isArray(images)) {
      const newImageCon: { [key: number]: string } = {};
      const newImageDescriptions: { [key: number]: string } = {};

      images.forEach((image: ImageData) => {
        if (image.index && image.imageData) {
          newImageCon[image.index] = image.imageData;
          if (image.description) {
            newImageDescriptions[image.index] = image.description;
          }
        }
      });

      setImageCon(newImageCon);
      // setImageDescriptions(newImageDescriptions);
    }
  }, [images]);

  useEffect(() => {
    // Set activeTab to the first available image index when images are loaded
    if (Object.keys(imageCon).length > 0) {
      setActiveTab(Number(Object.keys(imageCon)[0]));
    }
  }, [imageCon]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const browseImage = async (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    try {
      const base64 = await getBase64(info.file.originFileObj as RcFile);
      setLoading(false);
      setImageCon((prev) => ({
        ...prev,
        [activeTab]: base64,
      }));
      setFileName(info.file.name);
    } catch (error) {
      console.error("Error converting image to base64", error);
      setLoading(false);
    }
  };

  const saveImage = async () => {
    if (!imageCon[activeTab]) {
      console.error("No image to save for the current tab");
      return;
    }

    const payload = {
      file: imageCon[activeTab],
      LdtIDpk: statesx?.ldtIDpk,
      imageIndex: activeTab,
      description: imageDescriptions[activeTab] || "",
    };

    try {
      const res = await Posting(`LineDetails/SaveLineImage`, payload);
      console.log("Image saved successfully", res);
      setIcon("success");
      setModalData({
        message: "Image saved successfully",
        title: "Image Saved",
        okText: "Ok",
      });
      setOpenModal(true);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error saving image", error);
      setIcon("warning");
      setModalData({
        message: "Error saving image",
        title: "UnexpError",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save Image" ? saveImage() : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "success" || icon === "warning"}
      />
      <div className="border-t-2 pb-2 w-full">
        <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
          Images
        </div>

        <div className="px-2 pb-2 flex items-end h-full w-full">
          <div
            style={{
              backgroundImage: imageCon[activeTab]
                ? `url(${imageCon[activeTab]})`
                : "none",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            className="w-full h-60 border flex justify-center items-center"
          >
            {/* {!imageCon[activeTab] && (
              <Upload
                name="File uploaded"
                listType="picture"
                className="avatar-uploader justify-center items-center"
                showUploadList={false}
                onChange={browseImage}
              >
                <div>Click or drag file to this area to upload</div>
              </Upload>
            )} */}
          </div>
        </div>

        <div className="flex mx-2 mb-0.5">
          <TextArea
            style={{
              resize: "none",
              maxHeight: "12%",
              minHeight: "10%",
              width: "88%",
            }}
            className="py-1 overflow-y-scroll resize-none"
            rows={1}
            value={imageDescriptions}
            onChange={(e) => setImageDescriptions(e.target.value)}
          />
          <div className="h-full">
            <button
              onClick={saveAccessPriv}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1 h-full mr-1 px-2 border-2 border-slate-200 rounded"
            >
              <img className="pr-1" src={save} alt="Save" />
            </button>
          </div>
          <div>
            <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center px-2 border-2 border-slate-200 rounded">
              <Upload showUploadList={false} onChange={browseImage}>
                <img className="pr-1" src={newIcon} alt="New" />
              </Upload>
            </button>
          </div>
        </div>

        <div className="flex ml-2">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((tab) => (
            <div
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`border-r p-0.5 text-xs px-2 cursor-pointer ${
                activeTab === tab ? "bg-gray-300" : ""
              } ${imageCon[tab] ? "font-bold" : ""}`}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
