import { Select, Form, Input, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// import {
//   SaveButton,
//   NewButton,
//   Cancel,
//   UpdateButton,
// } from "../../../../../accessories/buttons";
// import {
//   navbar_height,
//   toolbar_height,
//   useModalData,
// } from "../../../../../accessories/component_infos";
// import dayjs, { Dayjs } from "dayjs";
// import { meter_assignment_TH } from "../data/meter-assignment-details-data";
// import { ModalTemplate } from "../../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../../../../../../app/store";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
// import { formPropsConst } from "../../../../../accessories/constants";
// import MeterAssignmentTable from "../../../../../../templates/Datagrid";
// import { setGeneralValue } from "../../../../../../features/generalSlice";
// import { useQueryFetch } from "../../../../../../functions/crud";
// import { DropdownGrid_template } from "../../../../../../templates/DropdownGrid";
// import { datagridColumnTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
// import { DateTemplate } from "../../../../../../templates/date";
// import { useAxiosService } from "../../../../../../general/requestConfig";
import { inRange, isUndefined } from "lodash";
import { DropdownTable } from "./DropdownGrid";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../../features/generalSlice";
import { useQueryFetch } from "../../../../../../../../functions/crud";
import { useAxiosService } from "../../../../../../../../general/requestConfig";
import useResizeMode from "../../../../../../../../hooks/useResizeMode";
import { DateTemplate } from "../../../../../../../../templates/date";
import { DropdownGrid_template } from "../../../../../../../../templates/DropdownGrid";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import {
  SaveButton,
  UpdateButton,
  NewButton,
  Cancel,
} from "../../../../../../../accessories/buttons";
import {
  useModalData,
  navbar_height,
  toolbar_height,
  readableDateFormat,
} from "../../../../../../../accessories/component_infos";
import { formPropsConst } from "../../../../../../../accessories/constants";
import { datagridColumnTypes } from "../../../../../../../human-resource/setups/data/types/selectsTypes";
import { meter_assignment_TH } from "../data/columns";
import { DataTable } from "../../../../../../../abstracts/data-table";
import { TROUBLEREPORT_COLUMNS } from "../data/tr-alertdetails-columns";
import { InputsTemplate } from "../../../../../../../../templates/input";
import { RefreshButton } from "../../../../../../../Safety/elogger/StationReadings/_widget/utils";
import { AssetDetail } from ".";
import { record } from "zod";
import { CheckboxTemlate } from "../../../../../../../../templates/checkbox";
// import useResizeMode from "../../../../../../hooks/useResizeMode";

const { Option } = Select;

interface props {
  toggled?: boolean;
  troubleReportData?: any;
  setRefreshstate?: React.Dispatch<React.SetStateAction<boolean>>;
  setRecord?: React.Dispatch<React.SetStateAction<any>>;
  setSwitch_change?: React.Dispatch<React.SetStateAction<boolean>>;
  switch_change?: boolean;
}

// Step 0 Define an interface for the states
interface stateTypes {
  startDate?: any;
  endDate?: any;
  subst?: { id: number; name: string };
  location?: { id: number; name: string };
  customer?: { id: number; name: string };
  feeder?: { id: number; name: string };
  meter?: { id: number; name: string };
  reportType?: { id: number; name: string };
  customerType?: { id: number; name: string };
  tscCurrency?: { id: number; name: string };
  rlCurrency?: { id: number; name: string };
  active?: boolean;
  MKA?: number;
  MKAr?: number;
  rlRate?: number;
  tsRate?: number;
  pilRate?: number;
  alRate?: number;
  genPlant?: { id: number; name: string };
  plNo?: { id: number; name: string };
  tom?: boolean;
  stservice?: boolean;
  remarks?: string;
}

const AssetDetailsForm = forwardRef(
  (
    {
      toggled,
      setRefreshstate,
      troubleReportData,
      setRecord,
      setSwitch_change,
      switch_change,
    }: props,
    ref
  ) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );

    //varying form height
    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
    const formModeSearchParam = "form-and-table"; //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const query = useAxiosService("powerapp");
    const [modalData, setModalData] = useModalData();
    const [formData, setFormData] = useState<
      Partial<CreateAssetDetails> | undefined
    >({
      tadActive: true,
      tadPurchaseCost: 0,
      tadOperationalCost: 0,
      tadDepreciation: 0,
      tadManufactureDate: dayjs().format(readableDateFormat),
      tadInstallationDate: dayjs().format(readableDateFormat),
      tadEndOfLifeDate: dayjs().format(readableDateFormat),
    });

    const [formheight, set_form_height] = useState<number>(0);

    const [initialH, fullH] = useResizeMode(toggled);
    const formRef = useRef<any>(null);
    var pageHeight =
      initialH - (navbar_height + news_flash_height + formheight + 20);
    var fullPage = fullH - (navbar_height + news_flash_height + 40);

    useEffect(() => {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      set_form_height(formRef.current?.clientHeight + 30);
    }, []);

    //miscellaneous heights
    const miscHeight = 7;
    const [refreshFeeder, setRefreshFeeder] = useState<boolean>(false);

    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    // Page resize height responsiveness
    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
      },
    }));

    // handle interface switch
    const toggle_staff_form = (): void => {
      const view = searchParams.get("view");

      view === null || view === formModeSearchParam
        ? navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: datagridOnlySearchParam,
            }).toString(),
          })
        : navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: formModeSearchParam,
            }).toString(),
          });
    };

    //Table Height
    // const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(
      window.innerHeight - heights_out + miscHeight
    ); //height without navbar and toolbar

    //Table Height
    const [table_form_Height, settable_form_Height] = useState(
      window.innerHeight - heights_out
    ); //height without navbar and toolbar and form

    const dateFormat = "YYYY-MM-DD";
    const displayFormat = "DD MMM YYYY";

    const form_disable = useSelector(
      (state: RootState) => state.general.formDisabled
    );
    const clearForm = useSelector(
      (state: RootState) => state.general.clearForm
    );

    const update_state_change = useSelector(
      (state: RootState) => state.general.updateMode
    );

    const { data: hasSavePriviledge } = useQueryFetch<
      ResponseType<{ message: string; data: string; code: number }>
    >({
      app: "powerapp",
      url: `MeterAssignments/CheckMeterAssignmentSavePrivilege`,
      revalidateAfter: 10 * 60 * 1000,
    });
    const { data: hasEditPriviledge } = useQueryFetch<
      ResponseType<{ message: string; data: string; code: number }>
    >({
      app: "powerapp",
      url: `MeterAssignments/CheckMeterAssignmentEditPrivilege`,
      revalidateAfter: 10 * 60 * 1000,
    });

    const { data: GetTroubleAssetDetailAssetTypes } = useQueryFetch<
      ResponseType<{ objId: number; objName: string }>
    >({
      app: "powerapp",
      url: `TroubleAssetDetails/GetTroubleAssetDetailAssetTypes`,
      params: {
        mode: true,
        tatIDpk: undefined,
      },
      // revalidateAfter: 2 * 60 * 1000,
    });
    const { data: GetTroubleAssetDetailAssetClasses } = useQueryFetch<
      ResponseType<Location>
    >({
      app: "powerapp",
      url: `TroubleAssetDetails/GetTroubleAssetDetailAssetClasses`,
      params: {
        mode: undefined,
        tacIDpk: 2,
        tadAssetTypeIDfk: 2,
      },
      // revalidateAfter: 2 * 60 * 1000,
    });
    const { data: GetTroubleAssetDetailNames } = useQueryFetch<
      ResponseType<{ tadIDpk: number; tadName: string }>
    >({
      app: "powerapp",
      url: `TroubleAssetDetails/GetTroubleAssetDetailNames`,
      refresh: refreshFeeder,
      revalidateAfter: 1 * 60 * 1000,
    });
    const { data: GetTroubleAssetDetailManufacturers } = useQueryFetch<
      ResponseType<{ objId: number; objName: string }>
    >({
      app: "powerapp",
      url: `TroubleAssetDetails/GetTroubleAssetDetailManufacturers`,
      params: {
        mode: true,
        mftIDpk: 2,
      },
      // revalidateAfter: 2 * 60 * 1000,
    });

    //2 To set ALL states
    const setStates = (key: keyof CreateAssetDetails, value: any) => {
      setFormData((state) => ({ ...state, [key]: value }));
    };

    function isFieldValid(): boolean {
      // validate inputs on after the other
      // const diffStartDate = dayjs(formData?.startDate);
      // const diffEndDate = dayjs(formData?.endDate);
      if (!formData?.tadAssetTypeIDfk) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Asset Type is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please select a valid Asset Type",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadAssetClassIDfk) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Asset Class is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please select a valid Asset Class",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadName) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Name is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please input a valid Name",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadSerialNo) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Serial No is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please input a valid Serial No",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadCode) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Asset Code is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please input a valid Asset Code",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadManufacturerIDfk) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Manufacturer is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please select a valid Manufacturer",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadManufactureDate) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Manufacture Date is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please select a valid Manufacture Date",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadInstallationDate) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Installation Date is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please select a valid Installation Date",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadEndOfLifeDate) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `End of Life Date is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please select a valid End of Life Date",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
        return false;
      }
      if (!formData?.tadOrder) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Order is required`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please input a valid Order",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));
      }
      if (isNaN(Number(formData?.tadOrder))) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `Order must be a number`,
          cancelText: "No Cancel",
          okText: "Okay",
          message: "Please input a valid Order",
          disableCancel: true,
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler() {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
        }));

        return false;
      }
      return true;
    }

    //  Footer Grid
    const dispatch = useDispatch();

    const [selectedRecord, setSelectedRecord] = useState<
      AssetDetail | undefined
    >(undefined);

    const selectRecord = useCallback((record: AssetDetail) => {
      setRecord(record);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      dispatch(setGeneralValue({ expr: "clearForm", value: false }));

      setFormData({
        tadName: record?.tadName,
        tadSerialNo: record?.tadSerialNo,
        tadCode: record?.tadCode,
        tadLocationCoordinates: record?.tadLocationCoordinates,
        tadAssetTypeIDfk: record?.tatIDpk,
        tadAssetClassIDfk: record?.tacIDpk,
        tadManufacturerIDfk: record?.mftIDpk,
        tadManufactureDate: record?.tadManufactureDate,
        tadInstallationDate: record?.tadInstallationDate,
        tadEndOfLifeDate: record?.tadEndOfLifeDate,
        tadPurchaseCost: record?.tadPurchaseCost,
        tadOperationalCost: record?.tadOperationalCost,
        tadDepreciation: record?.tadDepreciation,
        tadOrder: record?.tadOrder,
        tadActive: record?.tadActive,
        tadRmks: record?.tadRmks,

        // For viewing
        tatName: record?.tatName,
        mftName: record?.mftName,
        tadIDpk: record?.tadIDpk,
        mftIDpk: record?.mftIDpk,
        tacIDpk: record?.tacIDpk,
        tacName: record?.tacName,
        tatIDpk: record?.tatIDpk,
      });
      setSelectedRecord(record);
    }, []);

    const SaveRecord = () => {
      query({
        url: `TroubleAssetDetails/CreateTroubleAssetDetail`,
        method: "POST",
        data: {
          ...formData,
          formCode: `omgTroubleReports`,
          formAction: `Created Trouble Asset Detail with  Name: ${formData?.tadName}, Serial No: ${formData?.tadSerialNo}, Code: ${formData?.tadCode}, 
          Asset Type: ${formData?.tatName}, Asset Class: ${formData?.tacName}, Manufacturer: ${formData?.mftName}, Manufacture Date: ${formData?.tadManufactureDate}, Installation Date: ${formData?.tadInstallationDate},`,
        },
      }).then((res: ResponseType<any>) => {
        if (!inRange(res?.code, 199, 300)) {
          setModalData({
            state: true,
            title: "Save Failed",
            message: res?.message,
            icon: "warning",
            okText: "Okay",
            cancelText: "No",
            disableCancel: true,
            okHandler: async () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
          });
        } else {
          setModalData({
            state: true,
            title: "Save Successfully",
            message: res?.message,
            icon: "success",
            okText: "Okay",
            cancelText: "No",
            disableCancel: true,
            okHandler: async () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
          });
          setRefreshstate((state) => !state);
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          dispatch(setGeneralValue({ expr: "clearForm", value: false }));
        }
      });
    };
    const UpdateRecord = () => {
      query({
        url: `TroubleAssetDetails/UpdateTroubleAssetDetail/${selectedRecord?.tadIDpk}`,
        method: "PUT",
        data: {
          ...formData,
          formCode: `omgTroubleReports`,
          formAction: `Update Trouble Asset Detail with ID: ${formData?.tadIDpk}, Name: ${formData?.tadName}, Serial No: ${formData?.tadSerialNo}, Code: ${formData?.tadCode}, 
          Asset Type: ${formData?.tatName}, Asset Class: ${formData?.tacName}, Manufacturer: ${formData?.mftName}, Manufacture Date: ${formData?.tadManufactureDate}, Installation Date: ${formData?.tadInstallationDate},`,
        },
      }).then((res: ResponseType<any>) => {
        if (!inRange(res?.code, 199, 300)) {
          setModalData({
            state: true,
            title: "Update Failed",
            message: res?.message,
            icon: "warning",
            okText: "Okay",
            cancelText: "No",
            disableCancel: true,
            okHandler: async () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
          });
        } else {
          setModalData({
            state: true,
            title: "Update Successfully",
            message: res?.message,
            icon: "success",
            okText: "Okay",
            cancelText: "No",
            disableCancel: true,
            okHandler: async () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {
              setModalData((prev) => ({
                ...prev,
                state: false,
              }));
            },
          });
          setRefreshstate((state) => !state);
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          dispatch(setGeneralValue({ expr: "clearForm", value: false }));
        }
      });
    };

    useEffect(() => {
      if (clearForm) {
        setSelectedRecord(undefined);
        setFormData({
          tadActive: true,
          tadPurchaseCost: 0,
          tadOperationalCost: 0,
          tadDepreciation: 0,
          tadOrder: 1,
          tadManufactureDate: dayjs().format(readableDateFormat),
          tadInstallationDate: dayjs().format(readableDateFormat),
          tadEndOfLifeDate: dayjs().format(readableDateFormat),
        });
      }
    }, [clearForm]);

    return (
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />

        {toggled && (
          <div className="w-full border rounded-md" ref={formRef}>
            <ModalTemplate
              width={450}
              icon={modalData.icon}
              disableCancel={modalData.disableCancel}
              cancelText={modalData.cancelText}
              open={modalData.state}
              okHandler={modalData.okHandler}
              cancelHandler={modalData.cancelHandler}
              message={modalData.message}
              okText={modalData.okText}
              title={modalData.title}
            />

            <div className="text-xs bg-slate-100 rounded-t-md py-1 px-2">
              Asset Details
            </div>
            {/* The form */}
            <div className="py-2 flex flex-1 w-full space-x-6">
              <Form
                {...formPropsConst}
                wrapperCol={{ span: 18 }}
                className="ml-2 "
                style={{ width: "35%" }}
                disabled={form_disable}
              >
                <Form.Item style={{ marginBottom: 2 }}>
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(102%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DropdownGrid_template
                        keyExpr={"objId"}
                        labelCol={8}
                        label="Asset Type"
                        cusBtnStyle={true}
                        dropdownBtnStyles={{
                          width: 319,
                          overflow: "hidden",
                        }}
                        gridData={GetTroubleAssetDetailAssetTypes?.data}
                        columns={
                          [
                            {
                              dataField: "objId",
                              dataType: "string",
                              fixed: true,
                              allignment: "left",
                              caption: "ID",
                              visible: false,
                              width: 100,
                              id: 1,
                            },
                            {
                              dataField: "objName",
                              dataType: "string",
                              fixed: true,
                              allignment: "left",
                              caption: "Select Asset Type",
                              visible: true,
                              width: 100,
                              id: 2,
                            },
                          ] as datagridColumnTypes[]
                        }
                        defaultValue={formData?.tatName}
                        disabled={form_disable}
                        onRowClick={(record) => {
                          setStates("tadAssetTypeIDfk", record.objId);
                          setStates("tatName", record.objName);
                        }}
                        handleRefresh={() => {
                          !form_disable &&
                            setFormData((state) => ({
                              ...state,
                              tatName: undefined,
                              tatIDpk: undefined,
                            }));
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  // label={<p className="text-xs">{"Location"}</p>}
                  style={{ marginBottom: 2 }}
                >
                  <Form.Item
                    name="receivedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(102%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DropdownGrid_template
                        keyExpr={"objId"}
                        labelCol={8}
                        label="Asset Class"
                        cusBtnStyle={true}
                        dropdownBtnStyles={{
                          width: 319,
                          overflow: "hidden",
                        }}
                        gridData={GetTroubleAssetDetailAssetClasses?.data}
                        columns={
                          [
                            {
                              dataField: "objId",
                              dataType: "string",
                              fixed: true,
                              allignment: "left",
                              caption: "ID",
                              visible: false,
                              width: 100,
                              id: 1,
                            },
                            {
                              dataField: "objName",
                              dataType: "string",
                              fixed: true,
                              allignment: "left",
                              caption: "Select Asset Class",
                              visible: true,
                              width: 100,
                              id: 2,
                            },
                          ] as datagridColumnTypes[]
                        }
                        defaultValue={formData?.tacName}
                        disabled={form_disable}
                        onRowClick={(record) => {
                          setStates("tadAssetClassIDfk", record.objId);
                          setStates("tacName", record.objName);
                        }}
                        handleRefresh={() => {
                          !form_disable &&
                            setFormData((state) => ({
                              ...state,
                              tacName: undefined,
                              tacIDpk: undefined,
                            }));
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"Name"}</p>}
                  style={{ marginBottom: 2 }}
                >
                  <Form.Item
                    name="requestWindow"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(102%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <div className="pl-0.5 space-x-1 flex flex-row items-center">
                        <Input
                          value={formData?.tadName}
                          onChange={(e) => {
                            setStates("tadName", e.target.value);
                          }}
                          style={{
                            marginBottom: 0,
                            height: "25px",
                            width: "319px",
                          }}
                        />
                        <RefreshButton
                          onClick={() => {
                            !form_disable && setStates("tadName", undefined);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"Serial No"}</p>}
                  style={{ marginBottom: 2 }}
                >
                  <Form.Item
                    name="requestWindow"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(102%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <div className="pl-0.5 space-x-1 flex flex-row items-center">
                        <Input
                          value={formData?.tadSerialNo}
                          onChange={(e) => {
                            setStates("tadSerialNo", e.target.value);
                          }}
                          style={{
                            marginBottom: 0,
                            height: "25px",
                            width: "319px",
                          }}
                        />
                        <RefreshButton
                          onClick={() => {
                            !form_disable &&
                              setStates("tadSerialNo", undefined);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Code/Reg. No"}</p>}
                  style={{ marginBottom: 2 }}
                >
                  <Form.Item
                    name="requestWindow"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(102%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <div className="pl-0.5 space-x-1 flex flex-row items-center">
                        <Input
                          value={formData?.tadCode}
                          onChange={(e) => {
                            setStates("tadCode", e.target.value);
                          }}
                          style={{
                            marginBottom: 0,
                            height: "25px",
                            width: "319px",
                          }}
                        />
                        <RefreshButton
                          onClick={() => {
                            !form_disable && setStates("tadCode", undefined);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 2 }}>
                  <Form.Item
                    name="receivedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(102%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DropdownGrid_template
                        keyExpr={"objId"}
                        labelCol={8}
                        label="Manufacturer"
                        cusBtnStyle={true}
                        dropdownBtnStyles={{
                          width: 319,
                          overflow: "hidden",
                        }}
                        gridData={GetTroubleAssetDetailManufacturers?.data}
                        columns={
                          [
                            {
                              dataField: "objId",
                              dataType: "string",
                              fixed: true,
                              allignment: "left",
                              caption: "ID",
                              visible: false,
                              width: 100,
                              id: 1,
                            },
                            {
                              dataField: "objName",
                              dataType: "string",
                              fixed: true,
                              allignment: "left",
                              caption: "Select Manufacturer",
                              visible: true,
                              width: 100,
                              id: 2,
                            },
                          ] as datagridColumnTypes[]
                        }
                        defaultValue={formData?.mftName}
                        disabled={form_disable}
                        onRowClick={(record) => {
                          setStates("tadManufacturerIDfk", record.objId);
                          setStates("mftName", record.objName);
                        }}
                        handleRefresh={() => {
                          !form_disable &&
                            setFormData((state) => ({
                              ...state,
                              mftIDpk: undefined,
                              mftName: undefined,
                            }));
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"Manufacture Date"}</p>}
                  style={{ marginBottom: 0, paddingLeft: 4 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(50%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DateTemplate
                        showTime
                        style={{ width: "220px" }}
                        format={displayFormat}
                        disabled={form_disable}
                        datePlaceholder=""
                        selectedDate={
                          formData?.tadManufactureDate === undefined
                            ? undefined
                            : dayjs(formData?.tadManufactureDate)
                        }
                        changeDate={(e: Dayjs) => {
                          setStates("tadManufactureDate", dayjs(e));
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"Installation Date"}</p>}
                  style={{ marginBottom: 0, paddingLeft: 4 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(50%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DateTemplate
                        showTime
                        style={{ width: "220px" }}
                        format={displayFormat}
                        disabled={form_disable}
                        datePlaceholder=""
                        selectedDate={
                          formData?.tadInstallationDate === undefined
                            ? undefined
                            : dayjs(formData?.tadInstallationDate)
                        }
                        changeDate={(e: Dayjs) => {
                          setStates("tadInstallationDate", dayjs(e));
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"End of Life Date"}</p>}
                  style={{ marginBottom: 0, paddingLeft: 4 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(50%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DateTemplate
                        showTime
                        style={{ width: "220px" }}
                        format={displayFormat}
                        disabled={form_disable}
                        datePlaceholder=""
                        selectedDate={
                          formData?.tadEndOfLifeDate === undefined
                            ? undefined
                            : dayjs(formData?.tadEndOfLifeDate)
                        }
                        changeDate={(e: Dayjs) => {
                          setStates("tadEndOfLifeDate", dayjs(e));
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"Order"}</p>}
                  style={{ marginBottom: 4 }}
                >
                  <Form.Item
                    name="receivedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(50%)" }}
                  >
                    {" "}
                    <div className="px-0.5 ml-[1px] flex flex-row items-center">
                      <Form.Item
                        rules={[{ required: false }]}
                        style={{ width: "220px" }}
                      >
                        <style
                          dangerouslySetInnerHTML={{
                            __html: `

            ant-picker-ok{
                background-color: green;
            }
            .ant-btn css-dev-only-do-not-override-1ij74fp ant-btn-primary ant-btn-sm{
                background-color: green;
            }
            :where(.css-dev-only-do-not-override-1ij74fp).ant-btn-primary {
                color: #fff;
                background-color: #1677ff;
                box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
            }


            `,
                          }}
                        />
                        <Input
                          value={formData?.tadOrder}
                          onChange={(e) => {
                            setStates("tadOrder", e.target.value);
                          }}
                          style={{
                            marginBottom: 0,
                            height: "25px",
                            width: "220px",
                          }}
                        />
                        <span className="flex flex-row items-center"></span>
                      </Form.Item>
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex items-center mt-6",
                    // marginBottom: "2px",
                    width: "calc(102%)",
                  }}
                  label={<p className="text-xs">{"Active?"}</p>}
                  // className="mt-2 "
                >
                  <div className="flex justify-between" style={{ width: 336 }}>
                    <CheckboxTemlate
                      // checked={formData?.tadActive}
                      // onChange={(e) => {
                      //   setStates("tadActive", e.target.checked);
                      // }}
                      defaultValue={formData?.tadActive}
                      setCheckboxOnchange={(e) => {
                        setStates("tadActive", e);
                      }}
                      withBorders
                      useCallbackFunc
                    />
                    <ul className="flex mr-4">
                      {
                        form_disable === false &&
                        update_state_change === false ? (
                          <SaveButton
                            handleSave={() => {
                              if (isFieldValid()) {
                                setModalData((state) => ({
                                  ...state,
                                  state: true,
                                  title: `Save Equipment Details`,
                                  message: `Are you sure you want to save this Equipment Detail?`,
                                  disableCancel: false,
                                  okText: "Yes, save",
                                  cancelText: `No, cancel`,
                                  icon: "question",
                                  cancelHandler: () => {
                                    setModalData((state) => ({
                                      ...state,
                                      state: false,
                                    }));
                                  },
                                  okHandler: () => {
                                    SaveRecord();
                                  },
                                }));
                              }
                            }}
                          />
                        ) : update_state_change === true ? (
                          <UpdateButton
                            handleUpdate={() => {
                              if (isFieldValid()) {
                                setModalData((state) => ({
                                  ...state,
                                  state: true,
                                  title: `Update Equipment Details`,
                                  message: `Are you sure you want to update this Equipment Detail?`,
                                  disableCancel: false,
                                  okText: "Yes, update",
                                  cancelText: `No, cancel`,
                                  icon: "question",
                                  cancelHandler: () => {
                                    setModalData((state) => ({
                                      ...state,
                                      state: false,
                                    }));
                                  },
                                  okHandler: () => {
                                    UpdateRecord();
                                  },
                                }));
                              }
                            }}
                          />
                        ) : (
                          <SaveButton />
                        )

                        // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                      }

                      {form_disable === true ? (
                        <NewButton
                          useCallbackFunc
                          new_button_toggler={() => {
                            dispatch(
                              setGeneralValue({
                                expr: "formDisabled",
                                value: false,
                              })
                            );
                            dispatch(
                              setGeneralValue({
                                expr: "updateMode",
                                value: false,
                              })
                            );
                            setSelectedRecord(undefined);
                            setRecord(undefined);
                            setFormData({
                              tadActive: true,
                              tadPurchaseCost: 0,
                              tadOperationalCost: 0,
                              tadDepreciation: 0,
                              tadManufactureDate:
                                dayjs().format(readableDateFormat),
                              tadInstallationDate:
                                dayjs().format(readableDateFormat),
                              tadEndOfLifeDate:
                                dayjs().format(readableDateFormat),
                              tadOrder: 1,
                            });
                          }}
                        />
                      ) : update_state_change === true ? (
                        <Cancel
                          handleCallbackCancel={() => {
                            // setshowCancel(false);
                          }}
                        />
                      ) : !(update_state_change && form_disable) ? (
                        <Cancel
                          handleCallbackCancel={() => {
                            ("");
                          }}
                        />
                      ) : (
                        <NewButton
                          useCallbackFunc
                          new_button_toggler={() => {
                            setSelectedRecord(undefined);
                            setRecord(undefined);
                            setFormData({
                              tadActive: true,
                              tadPurchaseCost: 0,
                              tadOperationalCost: 0,
                              tadDepreciation: 0,
                              tadManufactureDate:
                                dayjs().format(readableDateFormat),
                              tadInstallationDate:
                                dayjs().format(readableDateFormat),
                              tadEndOfLifeDate:
                                dayjs().format(readableDateFormat),
                              tadOrder: 1,
                            });
                          }}
                        />
                      )}
                    </ul>
                  </div>
                </Form.Item>
              </Form>

              <div className="flex flex-col w-[60%] pr-6">
                <Form
                  {...formPropsConst}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 20 }}
                  className="ml-2 "
                  style={{ width: "60%" }}
                  disabled={form_disable}
                >
                  <Form.Item
                    label={<p className="text-xs">{"Purchase Cost"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      rules={[{ required: false }]}
                      style={{
                        display: "inline-block",
                        width: 300,
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={formData?.tadPurchaseCost}
                          onChange={(e) => {
                            setStates("tadPurchaseCost", e.target.value);
                          }}
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Operational Cost"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      rules={[{ required: false }]}
                      style={{
                        display: "inline-block",
                        width: 300,
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={formData?.tadOperationalCost}
                          onChange={(e) => {
                            setStates("tadOperationalCost", e.target.value);
                          }}
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Depreciation"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{
                        display: "inline-block",
                        width: 300,
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={formData?.tadDepreciation}
                          onChange={(e) => {
                            setStates("tadDepreciation", e.target.value);
                          }}
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Location Coordinates"}</p>}
                    style={{ marginBottom: 2 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{
                        display: "inline-block",
                      }}
                    >
                      {" "}
                      <div className="space-x-1 flex flex-row items-center">
                        <Input
                          value={formData?.tadLocationCoordinates}
                          onChange={(e) => {
                            setStates("tadLocationCoordinates", e.target.value);
                          }}
                          style={{
                            marginBottom: 0,
                            height: "25px",
                            width: "300px",
                          }}
                        />
                        <RefreshButton
                          onClick={() => {
                            !form_disable &&
                              setStates("tadLocationCoordinates", undefined);
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </Form>
                <Form
                  //  {...formPropsConst}
                  labelCol={{ span: 5 }}
                  //  wrapperCol={{ span: 20 }}
                  // className="ml-[12px]"
                  style={{ flex: 1 }}
                  disabled={form_disable}
                >
                  <Form.Item
                    label={<p className="text-xs">{"Remarks"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        marginBottom: "1px",
                        width: "100%",
                        height: "100%",
                        flex: 1,
                      }}
                      className=" mb-1 mr-2 "
                    >
                      <TextArea
                        style={{
                          resize: "none",
                          maxHeight: "100%",
                          minHeight: "100%",
                          height: 200,
                          width: "",
                          marginLeft: "1.5px",
                        }}
                        className="overflow-y-scroll resize-none"
                        value={formData?.tadRmks}
                        rows={3}
                        onChange={(e) => {
                          setStates("tadRmks", e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        )}

        <div className="pt-0.5">
          <DataTable
            disablePaging
            dataId="tadIDpk"
            rowAlternationEnabled={false}
            data={troubleReportData}
            rowDoubleClicked={(record) => {
              setRecord(record);
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              dispatch(setGeneralValue({ expr: "updateMode", value: true }));
              setSwitch_change && setSwitch_change(true);
              dispatch(setGeneralValue({ expr: "clearForm", value: false }));
            }}
            onRowClick={selectRecord}
            columns={TROUBLEREPORT_COLUMNS}
            gridheight={toggled ? pageHeight : fullPage}
          />
        </div>
      </div>
    );
  }
);

export default AssetDetailsForm;

interface ResponseType<TData> {
  message?: string;
  data: TData[];
  code: number;
}

interface CreateAssetDetails extends Partial<AssetDetail> {
  tadName: string;
  tadSerialNo: string;
  tadCode: string;
  tadLocationCoordinates: string;
  tadAssetTypeIDfk: number;
  tadAssetClassIDfk: number;
  tadManufacturerIDfk: number;
  tadManufactureDate: string; // ISO 8601 format
  tadInstallationDate: string; // ISO 8601 format
  tadEndOfLifeDate: string; // ISO 8601 format
  tadPurchaseCost: number;
  tadOperationalCost: number;
  tadDepreciation: number;
  tadOrder: number;
  tadActive: boolean;
  tadRmks: string;
  formCode?: string;
  formAction?: string;
}
