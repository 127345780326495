import { Checkbox, DatePicker, Input, Select } from "antd";
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import alter from "../../../assets/alter.png";
import close from "../../../assets/close.png";
import edit from "../../../assets/edit.png";
import email from "../../../assets/email.png";
import newfile from "../../../assets/new.png";
import printer from "../../../assets/printer.png";
import question from "../../../assets/question.png";
import refresh from "../../../assets/refresh.png";
import statusLoader from "../../../assets/statusloader.gif";
import {
  task_footer_update,
  task_form_disable,
} from "../../../features/Task&Todo/Task/TaskDetails";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../../features/generalSlice";

import dayjs from "dayjs";
import { FaBinoculars } from "react-icons/fa";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
interface props {
  toggler?: () => void;
  startDate?: string;
  endDate?: string;
  useCallBackFunc?: boolean;

  handleSave?: () => void; //save handler
  handleNew?: () => void; //new handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle prints functionality
  // handleActive?: (e: string) => void; // handle active functionality

  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  disableNew_edit?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  searchCriteria?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
  setVisible?: (e: boolean) => void;
  handleSMS?: () => void
}

export const Fuel_topuptoolbar = ({
  toggler,
  startDate,
  endDate,

  setOuterSelected,
  searchCriteriaOnchange,

  searchCriteriaData = [],

  searchCriteria,
  handleNew: newButtonClick,
  handleRefresh,
  handlePrint,
  outerSelected,
  disableNew_edit,
  useCallBackFunc,
  withDates = true,
  searchTextOnchange,
  handleSave,
  handleUpdate: handleEdit,
  // handleActive,
  startDateChanged,
  endDateChanged,
  handleFind,
  checkOnChange,
  setVisible,
  handleSMS,
  
}: props) => {
  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";
  const dispatch = useDispatch();

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<{
    title: string;
    message: string;
  }>({ title: "", message: "" });

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(
    privType,
   
  );

  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  const [searchTextValue, setSearchTextValue] = useState("");

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
      // handleActive && handleActive('true');
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
      // handleActive && handleActive('false');
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
      // handleActive && handleActive('indeterminate');
    }
  };

  // from redux
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const newAccessPriv = () => {
    if (savePriv === true) {
      handleNew();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  const editAccessPriv = () => {
    if (updatePriv === true) {
      handleUpdate();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  const smsAccessPriv = () => {
    if (readPriv === true) {
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  const findAccessPriv = () => {
    if (readPriv === true) {
      if (handleFind) {
        handleFind();
      } else {
      }
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  const exportAccessPriv = () => {
    if (readPriv === true) {
      handlePrint && handlePrint();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  // relative to the new button
  const handleNew = () => {
    setVisible && setVisible(true);
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    setOuterSelected && setOuterSelected({});

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    //clear data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    // dispatch(update_route_state(false))
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // clear fields 
    dispatch(setGeneralValue({ expr: "topUpClear", value: false }));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };

  const setModal = () => {
    setWarningModal(true);
    setWarningModalData({
      title: "Select record",
      message: "Please select a record to edit",
    });
  };
  const OnModalOk = () => {
    setWarningModal(false);
  };
  const OnModalCancel = () => {
    setWarningModal(false);
  };

  //handle edit
  const handleUpdate = () => {
    setVisible && setVisible(true);
  
    if (!outerSelected || Object.keys(outerSelected).length < 1) {
      setModal();
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(task_form_disable(true));
      return;
    }
  
    const isManagerOrDirector = userInfo.empIsDirector || userInfo.empIsManager;
  
    if (!isManagerOrDirector) { //user
      if (outerSelected.ftrRecommended) {
        setWarningModal(true);
        setWarningModalData({
          title: "Access Denied!",
          message: "User can't update recommended records! 1",
        });
      } else {
        dispatch(task_footer_update(true));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      }
      return;
    }
  
    if (isManagerOrDirector) {
      if (outerSelected.ftrRecommended && outerSelected.ftrApproved) {
        dispatch(task_form_disable(true));
        setWarningModal(true);
        setWarningModalData({
          title: "Access Denied!",
          message: "Can't approve already approved records! 1",
        });
      } else if (outerSelected.ftrRecommended) {
        dispatch(task_form_disable(false));
      } else {
        dispatch(task_footer_update(true));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      }
      return;
    }
  
    // If no conditions met, enable forms and update mode
    dispatch(task_form_disable(false));
    dispatch(task_footer_update(true));
  };

  return (
    <>
      <ModalTemplate
        icon={"warning"}
        cancelHandler={OnModalCancel}
        disableCancel={true}
        open={warningModal}
        okHandler={OnModalOk}
        message={warningModalData.message}
        okText={"Ok"}
        title={warningModalData.title}
      />
      <div
        style={{ width: "4000px", borderColor: borderTheme }}
        className=" h-full flex justify-between border-[1px] rounded border-gray-200 my-0.5 mx-2 px-0.5 rounded"
      >
        <div className="flex justify-center">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            {/* new and cancel section */}
            {disable || updateMode ? (
              <li
                id="newRecord"
                onClick={() => newAccessPriv()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">New Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
            {!updateMode ? (
              <li
                id="editRecordst"
                onClick={editAccessPriv}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecordst"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}

            <li
              id="switch"
              onClick={toggler}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="h-2">Switch interface / table view</p>
              </Tooltip>
            </li>

            <li
              id="email"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              onClick={()=>{handleSMS && handleSMS()}}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={email}
                />
              </span>
              <Tooltip
                target="#email"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="h-2">Send SMS alerts</p>
              </Tooltip>
            </li>

            {/* <li><FaUserCog size={size}/></li> */}

            <li
              id="help"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target="#help"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Help Center</p>
              </Tooltip>
            </li>

            {/* Differents starts here */}

            <li className="flex items-center ml-4 ">
              <label className="flex text-xs px-2">Search Criteria</label>
              <Select
                value={criteriaDataLocal}
                dropdownMatchSelectWidth={false}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{
                  width: `${sideAlert === true ? 20 : 110}`,
                  height: "25px",
                }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
             
            </li>

                    <li className=" flex items-center ml-4 ">
                        <label className='flex w-[87px] px-2'>Search Text</label>
                        <Input 
                        onChange={(e:any)=>{
                            const searchText:string = e.target.value;
                            searchTextOnchange && searchTextOnchange(searchText);
                            setSearchTextValue(e.target.value)
                        }}
                        value={searchTextValue}
                        name="search" 
                        id="search" 
                        size="small" 
                        allowClear={true}  
                        style={{ width:130, height : '24px'}} />
                    </li>
                    <li className="mt-0.5 ml-1">
                        <DatePicker allowClear={false} size="small" placeholder="Start Date" value={dayjs(startDate)??dayjs()} onChange={startDateChanged} style={{ width: 125 }} format={dateFormat} />
                    </li>
                    <li className="px-0.5 ml-0.5 mt-0.5">
                        <DatePicker allowClear={false} size="small" placeholder="End Date" value={dayjs(endDate)??dayjs()} onChange={endDateChanged} style={{ width: 125 }} format={dateFormat} />
                    </li>
                    
                    
                    <li className="px-0.5 mr-1">
                        <Checkbox
                            style={{borderColor:borderTheme}} className="border-[1px] rounded px-1"
                            checked={checkedBox}
                            indeterminate={indeterminate}
                            onChange={(checkValue: any) => { 
                                onChange(checkValue);
                            }}
                        />
                        <Tooltip
                            target="#status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Show Status</p>
                        </Tooltip>
                    </li>

            <li
              onClick={() => {
                handleRefresh && handleRefresh();
                setSearchTextValue("");

                setCheckedBox(false);
                setIndeterminate(true);
                setCheckValue("");
                setCriterialDataLocal({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.value ?? '' });
              }}
              style={{ borderColor: borderTheme }}
              className="border-[1px] hover:cursor-pointer mr-1 border-slate-200 text-xs p-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={refresh}
                />{" "}
              </span>
            </li>
            <li
              id="find"
              onClick={() => {
                findAccessPriv();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <FaBinoculars size={15.5} color="#007AFF" />{" "}
              </span>
              <Tooltip
                target="#find"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Find</p>
              </Tooltip>
            </li>
            <li
              id="print"
              onClick={() => exportAccessPriv()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />{" "}
              </span>
              <Tooltip
                target="#print"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Print / Export</p>
              </Tooltip>
            </li>

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {disable && (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}

              {!disable && (
                <div className="  flex flex-row items-center">
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  {!updateMode ? (
                    <p className=" italic text-gray-600">New Record</p>
                  ) : (
                    <p className=" italic text-gray-600">Editing</p>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
