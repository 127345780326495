/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-pascal-case */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { Form, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";

import settings from "../../../../../../assets/setting.png";
import blueStar from "../../../../../../assets/blue-star.png";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  settask_footer_table_selected,
  setGeneralValue,
} from "../../../../../../features/generalSlice";
import { useCrudFunc } from "../../../../../../functions/crud";
import { GetDataFunc } from "../../../../../../functions/get";
import {
  useAccountInfo,
  useCurrentSession,
} from "../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { DateTemplate } from "../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../templates/input";
import { ModalTemplate } from "../../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../../templates/select";
import {
  UpdateButton,
  SaveButton,
  NewButton,
  Cancel,
  CustomizableButton,
  RateButton,
} from "../../../../../accessories/buttons";
import {
  currentDate,
  dateFormat,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import { FormModal } from "../../../../../accessories/formModal";
import {
  selectsTypes,
  formModalStates,
  validateInstanceTypes,
  modalTypes,
  modalPropTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { DropdownGrid_template } from "../../../../../../templates/DropdownGrid";
import { staff_name_TH } from "../../../../../human-resource/entries/StaffNames/data/staff-name-data";
import { Trouble_reports_reported_by } from "../data/troubleDataSample";
import RatingForm from "./rating_form";
import ClassifyForm from "./classify_form";
import { useTroubleReportRepo } from "../repo";

interface props {
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  openedTask?: any;
  setrefreshx?: (e: any) => void;
  clearRecord?: () => void;
  refreshx?: boolean;
  selectedRecord?: any;
  selectedTask_updatex?: any;
  startDate?: any;
}

interface stateTypes {
  // block ?: string;
  troubleReportId?: string;
  troubleDate?: string;
  completionDate?: string;
  equipment?: string;
  troubleRemarks?: string;
  remarks?: string;
  satisfactionRemarks?: string;
  reportedBy?: { id: string; name: string };
  troubleType?: { id: string; name: string };
  troubleClass?: { id: string; name: string };
  equipmentType?: { id: string; name: string };
  troubleLocation?: { id: string; name: string };
  priority?: { id: string; name: string };
  troubleSubClass?: { id: string; name: string };
  troubleCategory?: { id: string; name: string };
  busyLoader: string;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
}

export const MainTroubleWidget = forwardRef(
  (
    {
      toggler,
      datgridCol,
      customHeightHook,
      openedTask,
      setrefreshx,
      refreshx,
      selectedRecord,
      startDate,
      selectedTask_updatex,
      clearRecord,
    }: props,
    ref
  ) => {
    const [selectedTask_update, setSelectedTask_update] = useState();
    const [fuelLevels, setFuelLevel] = useState<any>([]);
    const [employeeId, userId, userInfo] = useAccountInfo();
    const [posting, updating] = useCrudFunc();

    const update_state_change = useSelector(
      (state: RootState) => state.addTask.taskFooterUpdate
    );
    const { empIDpk: userID } = useCurrentSession();
    const { selectedReport } = useTroubleReportRepo((state) => state);
    const [states, setStates] = useState<stateTypes>({
      troubleReportId: "",
      troubleDate: "",
      completionDate: "",
      equipment: "",
      remarks: "",
      troubleRemarks: "",
      satisfactionRemarks: "",
      reportedBy: { id: "", name: "" },
      troubleType: { id: "", name: "" },
      troubleClass: { id: "", name: "" },
      equipmentType: { id: "", name: "" },
      troubleLocation: { id: "", name: "" },
      priority: { id: "", name: "" },
      troubleSubClass: { id: "", name: "" },
      troubleCategory: { id: "", name: "" },
      busyLoader: "",
      confirmModal: { state: false, title: "", message: "", action: "post" },
      warningModal: { state: false, title: "", message: "" },
      successModal: { state: false, title: "", message: "" },
    });

    const clearAllFields = () => {
      console.log("clear all fields");
      setStates({
        troubleReportId: "",
        troubleDate: currentDate,
        completionDate: currentDate,
        equipment: "",
        remarks: "",
        troubleRemarks: "",
        satisfactionRemarks: "",
        reportedBy: { id: "", name: "" },
        troubleType: { id: "", name: "" },
        troubleClass: { id: "", name: "" },
        equipmentType: { id: "", name: "" },
        troubleLocation: { id: "", name: "" },
        priority: { id: "", name: "" },
        troubleSubClass: { id: "", name: "" },
        troubleCategory: { id: "", name: "" },
        busyLoader: "",
        confirmModal: { state: false, title: "", message: "", action: "post" },
        warningModal: { state: false, title: "", message: "" },
        successModal: { state: false, title: "", message: "" },
      });
    };

    const dispatch = useDispatch();

    // states handler
    const setState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };
    console.log("states", states);
    // Endpoints

    const form_disable = useSelector(
      (state: RootState) => state.general.formDisabled
    );

    const populateStatesFromGrid = (clearFields?: boolean) => {
      setStates({
        troubleReportId: selectedRecord?.trpIDpk,
        troubleDate: selectedRecord?.trpReportDate ?? currentDate,
        completionDate: selectedRecord?.trpDueDate ?? currentDate,
        equipment: selectedRecord?.trpEquipment,
        remarks: selectedRecord?.trpRmks,
        troubleRemarks: selectedRecord?.trpReport,
        satisfactionRemarks: selectedRecord?.trpSatisfactionRmks,
        reportedBy: {
          id: selectedRecord?.reportedByEmpID,
          name: selectedRecord?.reportedByName,
        },
        troubleType: {
          id: selectedRecord?.ttpIDpk,
          name: selectedRecord?.ttpName,
        },
        troubleClass: {
          id: selectedRecord?.tclIDpk,
          name: selectedRecord?.tclName,
        },
        equipmentType: {
          id: selectedRecord?.tatIDpk,
          name: selectedRecord?.tatName,
        },
        troubleLocation: {
          id: selectedRecord?.tlcIDpk,
          name: selectedRecord?.tlcName,
        },
        priority: {
          id: selectedRecord?.tptIDpk,
          name: selectedRecord?.tptName,
        },
        troubleSubClass: {
          id: selectedRecord?.tscIDpk,
          name: selectedRecord?.tscName,
        },
        troubleCategory: {
          id: selectedRecord?.tctIDpk,
          name: selectedRecord?.tctName,
        },
        busyLoader: "",
        confirmModal: { state: false, title: "", message: "", action: "post" },
        warningModal: { state: false, title: "", message: "" },
        successModal: { state: false, title: "", message: "" },
      });
    };

    useEffect(() => {
      populateStatesFromGrid(false);
    }, [selectedRecord]);

    useImperativeHandle(ref, () => ({
      topNavNew: () => {
        // editAccessPriv();
        clearAllFields();
      },
    }));

    const [refresh, setrefresh] = useState<boolean>(false);
    const [troubleReports, troubleReportsError, troubleReportsLoading] =
      useFetch(
        `TroubleReports/PopLueReporters`,
        refresh,
        "empIDpk",
        false,
        "powerapp"
      );

    const [troubleTypes, troubleTypesError, troubleTypesLoading] = useFetch(
      `TroubleReports/PopLueTroubleTypes`,
      refresh,
      "ttpIDpk",
      false,
      "powerapp"
    );
    const [troubleClasses, troubleClassesError, troubleClassesLoading] =
      useFetch(
        `TroubleReports/PopLueTroubleClasses/${states.troubleType.id}`,
        refresh,
        "tclIDpk",
        false,
        "powerapp"
      );
    const [
      troubleSubClasses,
      troubleSubClassesError,
      troubleSubClassesLoading,
    ] = useFetch(
      `TroubleReports/PopLueTroubleSubclasses/${states.troubleClass.id}`,
      refresh,
      "tclIDpk",
      false,
      "powerapp"
    );
    const [troubleLocation, troubleLocationError, troubleLocationLoading] =
      useFetch(
        `TroubleReports/PopLueTroubleLocations`,
        refresh,
        "tlcIDpk",
        false,
        "powerapp"
      );
    const [
      troubleCategories,
      troubleCategoriesError,
      troubleCategoriesLoading,
    ] = useFetch(
      `TroubleReports/PopLueTroubleCategories`,
      refresh,
      "tctIDpk",
      false,
      "powerapp"
    );

    const [
      troublePriorities,
      troublePrioritiesError,
      troublePrioritiesLoading,
    ] = useFetch(
      `TroubleReports/PopLueTroublePriorities`,
      refresh,
      "tptIDpk",
      false,
      "powerapp"
    );
    const [
      troubleEquipmentTypes,
      troubleEquipmentTypesError,
      troubleEquipmentTypesLoading,
    ] = useFetch(
      `TroubleReports/PopLueAssetTypes`,
      refresh,
      "tatIDpk",
      false,
      "powerapp"
    );

    //validate fields
    const validateFields = (): boolean => {
      const validationInstances: validateInstanceTypes[] = [
        {
          state: states.troubleDate,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Trouble Date",
            message: "Please select trouble date",
          },
        },
        {
          state: states.completionDate,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Completion Date",
            message: "Please select completion date",
          },
        },
        {
          state: states.reportedBy.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Reported By",
            message: "Please select reported by",
          },
        },
        {
          state: states.troubleLocation.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Location",
            message: "Please select location",
          },
        },
        {
          state: states.troubleType.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Trouble Type",
            message: "Please select trouble type",
          },
        },
        {
          state: states.priority.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Priority",
            message: "Please select priority",
          },
        },
        {
          state: states.troubleClass.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Trouble Class",
            message: "Please select trouble class",
          },
        },
        {
          state: states.troubleSubClass.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Trouble Sub Class",
            message: "Please select trouble sub class",
          },
        },
        {
          state: states.equipmentType.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Equipment Type",
            message: "Please select equipment type",
          },
        },
        {
          state: states.troubleCategory.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Category",
            message: "Please select category",
          },
        },
        {
          state: states.equipment,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Equipment",
            message: "Please select equipment",
          },
        },
        // {state:perspective.value,baseValue:'',modalProps:{state:true,title:'Select perspective',message:'Please select perspective'}},
        // {state:measure.value,baseValue:'',modalProps:{state:true,title:'Select measure',message:'Please select measure'}},
        // {state:target.value,baseValue:'',modalProps:{state:true,title:'Select perspective',message:'Please select target'}},
        // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
      ];

      for (let instance of validationInstances)
        if (instance.state === instance.baseValue) {
          const { modalProps } = instance;
          updateState("warningModal", {
            state: modalProps.state,
            title: modalProps.title,
            message: modalProps.message,
          });
          return false;
        }
      return true;
    };

    //Access Priviledge

    const [privType] = useState<string>("save");
    const [savePriv] = useFormPriviledge(privType);
    const [PostDataFunc, UpdateDataFunc] = useCrudFunc("powerapp");

    //post api
    const postData = async (action: "confirm" | "post") => {
      console.log("post data called");
      if (action === "confirm") {
        updateState("confirmModal", {
          state: true,
          title: "Save Record",
          message: "Are you sure you want to save this record?",
          func() {
            console.log("post data event called");
            postData("post");
          },
        });
        return;
      }

      updateState("confirmModal", {
        state: false,
        title: "Save Record?",
        message: "Are you sure you want to save this record?",
      });

      try {
        // busyloader(saveModalData.progress);

        const res = await PostDataFunc(
          `TroubleReports/SaveTroubleReport`,
          {
            trpReportedByEmpIDfk: states.reportedBy.id,
            trpEquipmentTypeIDfk: states.equipmentType.id,
            trpEquipment: states.equipment,
            trpReport: states.troubleRemarks,
            trpReportDate: states.troubleDate,
            trpDueDate: states.completionDate,
            trpCategoryIDfk: states.troubleCategory.id,
            trpPriorityIDfk: states.priority.id,
            trpTroubleTypeIDfk: states.troubleType.id,
            trpClassIDfk: states.troubleClass.id,
            trpSubclassIDfk: states.troubleSubClass.id,
            trpLocationIDfk: states.troubleLocation.id,
            trpRmks: states.remarks,
            // "formCode": "omgTroubleReports",
            // "formAction": `Created Trouble report by emp id ${states.reportedBy.id} successfully`,
          },
          `Created Trouble report by emp id ${states.reportedBy.id} successfully`,
          "omgTroubleReports"
        );

        // disableFields();
        // refreshGrid();
        // clearSelected();
        // //Clear fields when done.
        // populateStatesFromGrid(true);
        updateState("successModal", {
          state: true,
          title: "Record Saved",
          message: "Trouble Report added successfully",
        });
        setrefreshx("");
      } catch (error) {
        console.log(error);

        updateState("warningModal", {
          state: true,
          title: saveModalData.error.title,
          message: saveModalData.error.message,
        });
      } finally {
        // busyloader('');
      }
      return;
    };

    //update data
    const update = async (action: "validate" | "update") => {
      const fetchData = async () => {
        // disableConfirmModal();
        // busyloader(updateModalData.progress)

        try {
          const updateResponse = await UpdateDataFunc(
            `TroubleReports/UpdateTroubleReport`,
            {
              trpIDpk: states.troubleReportId,
              trpReportedByEmpIDfk: states.reportedBy.id,
              trpEquipmentTypeIDfk: states.equipmentType.id,
              trpEquipment: states.equipment,
              trpReport: states.troubleRemarks,
              trpReportDate: states.troubleDate,
              trpDueDate: states.completionDate,
              trpCategoryIDfk: states.troubleCategory.id,
              trpPriorityIDfk: states.priority.id,
              trpTroubleTypeIDfk: states.troubleType.id,
              trpClassIDfk: states.troubleClass.id,
              trpSubclassIDfk: states.troubleSubClass.id,
              trpLocationIDfk: states.troubleLocation.id,
              trpRmks: states.remarks,
              formCode: "omgTroubleReports",
              formAction: `Updated Trouble report with id ${states.troubleReportId} successfully`,
            },
            `Created Trouble report by emp id ${states.reportedBy.id} successfully`,
            "omgTroubleReports"
          );

          updateState("successModal", {
            state: true,
            title: "Record Updated",
            message: "Trouble Report updated successfully",
          });
          // enableSuccessModal('Update','updated')
          // disableFields();
          // refreshGrid();
          // clearSelected();
          setrefreshx("");
          //Maintain updated fields
          // populateStatesFromGrid(false);
        } catch (error) {
          console.log(error);

          updateState("warningModal", {
            state: true,
            title: updateModalData.error.title,
            message: updateModalData.error.message,
          });
        } finally {
          // busyloader('');
        }
      };

      if (action === "validate") {
        // validate fieldds
        validateFields() &&
          updateState("confirmModal", {
            state: true,
            title: "Update Record",
            message: "Are you sure you want to update this record?",
            action: "update",
            func() {
              update("update");
            },
          });
        return;
      } else {
        fetchData();
      }
      return;
    };

    //update button
    const updateAccessPriv: () => void = () => {};

    const updateState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };

    const dates: any[] = [
      { id: 0, label: "Arrival Time ", stateName: "", defaultValue: "" },
      { id: 1, label: "Departure Time ", stateName: "", defaultValue: "" },
    ];

    const inputs: any[] = [
      {
        id: 0,
        label: "Odometer In ",
        stateName: "",
        defaultValue: "",
        style: "",
        meterholder: "",
      },
      {
        id: 1,
        label: "Odometer Out ",
        stateName: "",
        defaultValue: "",
        style: "",
        meterholder: "0km",
      },
    ];

    // modal instances
    const modals: modalTypes[] = [
      //validation
      {
        disableCancel: true,
        icon: "warning",
        open: states.warningModal.state,
        okHandler: () =>
          updateState("warningModal", { state: false, title: "", message: "" }),
        cancelHandler: () =>
          updateState("warningModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.warningModal.message,
        okText: "Ok",
        title: states.warningModal.title,
        cancelText: "No, cancel",
      },

      //confirmation
      {
        disableCancel: false,
        icon: "question",
        open: states.confirmModal.state,
        okHandler: states.confirmModal.func,
        cancelHandler: () =>
          updateState("confirmModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.confirmModal.message,
        okText:
          states.confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
        title: states.confirmModal.title,
        cancelText: "No, cancel",
      },

      //success
      {
        disableCancel: true,
        icon: "success",
        open: states.successModal.state,
        okHandler: () =>
          updateState("successModal", { state: false, title: "", message: "" }),
        cancelHandler: () =>
          updateState("successModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.successModal.message,
        okText: "Ok",
        title: states.successModal.title,
        cancelText: "No, cancel",
      },
    ];

    const [showRatingForm, setShowRatingForm] = useState(false);
    const [showClassifyForm, setShowClassifyForm] = useState(false);
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );

    return (
      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 ">
        {modals.map(
          (
            {
              disableCancel,
              icon,
              okHandler,
              cancelHandler,
              open,
              cancelText,
              okText,
              message,
              title,
            }: modalTypes,
            index
          ) => {
            return (
              <div key={index}>
                <ModalTemplate
                  icon={icon && icon}
                  disableCancel={disableCancel}
                  cancelText={cancelText && cancelText}
                  open={open}
                  okHandler={okHandler}
                  cancelHandler={cancelHandler}
                  message={message}
                  okText={okText}
                  title={title}
                />
              </div>
            );
          }
        )}
        <ModalTemplate
          disableCancel
          width={"25%"}
          customComponent={
            <ClassifyForm
              handleOnCancel={() => {
                setShowClassifyForm(false);
              }}
              selectedRecord={selectedRecord}
              handleRefresh={() => {
                setrefreshx("");
              }}
            />
          }
          title={"Classify"}
          message={""}
          cancelHandler={() => setShowClassifyForm(false)}
          open={showClassifyForm}
          okHandler={() => {}}
        />

        <ModalTemplate
          disableCancel
          width={"25%"}
          customComponent={
            <RatingForm
              selectedRecord={selectedRecord}
              handleOnCancel={() => {
                setShowRatingForm(false);
                setrefreshx("");
              }}
            />
          }
          title={"Rating"}
          message={""}
          cancelHandler={() => setShowRatingForm(false)}
          open={showRatingForm}
          okHandler={() => {}}
        />

        {/* <FormModal
        height={1000}
        width={"30%"}
        openState={states.saveOdoModals}
        onCancel={() => {
          setState("saveOdoModals", false);
        }}
        child={formModal[0].module}
        title={formModal[0].label}
      /> */}
        <div
          style={{ borderColor: borderTheme }}
          className="m-1 border rounded "
        >
          <div
            style={{ height: "" }}
            className="flex items-center  justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
          >
            {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
            <p className=" py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
              Trouble Report Details
            </p>
            <div className="flex flex-1 items-center justify-end w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
              <span
                onClick={() => {
                  setShowClassifyForm(true);
                  // setData('');
                  // handleRefresh && handleRefresh();
                }}
                // style={{height:23.5, borderWidth:1,width:25}}
                className="mx-2 flex justify-center hover:cursor-pointer border-gray-300 rounded text-blue-500"
              >
                <img
                  className=" hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={settings}
                  alt="reset"
                />
                Classify
              </span>
            </div>
          </div>
          <div className="px-2 h-full">
            <Form
              className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-0.5  "
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className="w-full h-full">
                {/* Block */}

                <Form className="w-full flex ">
                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    labelCol={{ span: 8 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {" Trouble Date"}
                      </p>
                    }
                  >
                    <DateTemplate
                      showTime={true}
                      format={dateFormat}
                      disabled={form_disable}
                      selectedDate={
                        states.troubleDate === ""
                          ? ""
                          : dayjs(states.troubleDate)
                      }
                      changeDate={(e) => {
                        updateState("troubleDate", e);
                      }}
                      span={false}
                      width={"calc(100%)"}
                      // width={ "calc(175px)"}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    wrapperCol={{ span: 15 }}
                    labelCol={{ span: 7 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Completion Date"}
                      </p>
                    }
                  >
                    <DateTemplate
                      showTime={true}
                      format={dateFormat}
                      disabled={form_disable}
                      selectedDate={
                        states.completionDate === ""
                          ? ""
                          : dayjs(states.completionDate)
                      }
                      changeDate={(e) => {
                        updateState("completionDate"!, e);
                      }}
                      span
                      width={"calc(100%)"}
                    />
                  </Form.Item>
                </Form>

                {/* Date */}
                <Form className="w-full flex ">
                  {/* <Form.Item
                style={{ width: "calc(50%)" }}
                className=""
                labelCol={{ span: 8 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {" Reported By"}
                  </p>
                }
              >
                <div className="w-full flex">
                <SelectsTemplate
                      // cusWidth={"calc(100%)"}
                      style={{width: "calc(100%)"}}      
                     
                      disabledCustomWidth={true}
                      label={''}
                      // style={{width: "200px"}}
                      options={troubleReports}
                      idexpr={'empIDpk'}
                      dataexp={'empStaffNo'}
                      useCallFunc
                      disabled={form_disable}
                      placeHolder={states.reportedBy.name}
                      selectedValue={(e) => {
                        updateState('reportedBy', {
                          id: e['empIDpk'],
                          name: e['empStaffNo'],
                        });
                      }}
                      handleClear={() => {
                        updateState('reportedBy', { id: 0, name: "" });
                      }}
                    />
                </div>
              </Form.Item> */}
                  <DropdownGrid_template
                    label={"Reported By"}
                    cusBtnStyle={true}
                    dropStyles={{ width: "calc(50%)" }}
                    dropdownBtnStyles={{ width: "100%" }}
                    className=""
                    labelCol={8}
                    columns={Trouble_reports_reported_by}
                    disabled={form_disable ? true : false}
                    gridData={troubleReports}
                    defaultValue={states.reportedBy.name}
                    handleRefresh={() => {
                      updateState("reportedBy", { id: 0, name: "" });
                      // setState(stateName!, { id: 0, name: "" });
                      // setState(refreshStateName!, !refresh);
                    }}
                    buttonClass="w-[3000px]"
                    onRowClick={(e) => {
                      console.log(e);
                      updateState("reportedBy", {
                        id: e["empIDpk"],
                        name: e["empName"],
                      });
                      close();
                      // setState(stateName!, {
                      //   id: e[idExpr!],
                      //   name: e[dataExpr!],
                      // });
                      // close();
                    }}
                  />

                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    // wrapperCol={{ span: 15 }}
                    labelCol={{ span: 7 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Trouble Location"}
                      </p>
                    }
                  >
                    <div className="w-full flex">
                      <SelectsTemplate
                        // cusWidth={"197px"}
                        style={{ width: "calc(100%)" }}
                        disabledCustomWidth={true}
                        label={""}
                        options={troubleLocation}
                        idexpr={"tlcIDpk"}
                        dataexp={"tlcName"}
                        placeHolder={states.troubleLocation.name}
                        selectedValue={(e) => {
                          updateState("troubleLocation", {
                            id: e["tlcIDpk"],
                            name: e["tlcName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("troubleLocation", { id: 0, name: "" });
                        }}
                        useCallFunc
                        disabled={form_disable}
                      />
                    </div>
                  </Form.Item>
                </Form>

                <Form className="w-full flex ">
                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    labelCol={{ span: 8 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Trouble Type"}
                      </p>
                    }
                  >
                    <div className=" flex">
                      <SelectsTemplate
                        style={{ width: "calc(100%)" }}
                        // cusWidth={"200px"}
                        //                  style={{width: "200px"}}
                        disabledCustomWidth={false}
                        label={""}
                        options={troubleTypes}
                        idexpr={"ttpIDpk"}
                        dataexp={"ttpName"}
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={states.troubleType.name}
                        selectedValue={(e) => {
                          updateState("troubleType", {
                            id: e["ttpIDpk"],
                            name: e["ttpName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("troubleType", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>

                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    // wrapperCol={{ span: 15 }}
                    labelCol={{ span: 7 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Priority"}
                      </p>
                    }
                  >
                    <div className="w-full flex">
                      <SelectsTemplate
                        // cusWidth={"197px"}
                        style={{ width: "calc(100%)" }}
                        disabledCustomWidth={true}
                        label={""}
                        options={troublePriorities}
                        idexpr={"tptIDpk"}
                        dataexp={"tptName"}
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={states.priority.name}
                        selectedValue={(e) => {
                          updateState("priority", {
                            id: e["tptIDpk"],
                            name: e["tptName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("priority", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form>

                <Form className="w-full flex ">
                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    labelCol={{ span: 8 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {" Trouble Class"}
                      </p>
                    }
                  >
                    <div className="w-full flex">
                      <SelectsTemplate
                        style={{ width: "calc(100%)" }}
                        // cusWidth={"197px"}
                        //                  style={{width: "200px"}}
                        disabledCustomWidth={true}
                        label={""}
                        options={troubleClasses}
                        idexpr={"tclIDpk"}
                        dataexp={"tclName"}
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={states.troubleClass.name}
                        selectedValue={(e) => {
                          updateState("troubleClass", {
                            id: e["tclIDpk"],
                            name: e["tclName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("troubleClass", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>

                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    // wrapperCol={{ span: 16 }}
                    labelCol={{ span: 7 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Trouble Subclass"}
                      </p>
                    }
                  >
                    <div className="w-full flex">
                      <SelectsTemplate
                        // cusWidth={"197px"}
                        style={{ width: "calc(100%)" }}
                        disabledCustomWidth={true}
                        label={""}
                        options={troubleSubClasses}
                        idexpr={"tscIDpk"}
                        dataexp={"tscName"}
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={states.troubleSubClass.name}
                        selectedValue={(e) => {
                          updateState("troubleSubClass", {
                            id: e["tscIDpk"],
                            name: e["tscName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("troubleSubClass", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form>
                <Form className="w-full flex ">
                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    labelCol={{ span: 8 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Equipment Type"}
                      </p>
                    }
                  >
                    <div className="w-full flex">
                      <SelectsTemplate
                        style={{ width: "calc(100%)" }}
                        // cusWidth={"197px"}
                        //                  style={{width: "200px"}}
                        disabledCustomWidth={true}
                        label={""}
                        options={troubleEquipmentTypes}
                        idexpr={"tatIDpk"}
                        dataexp={"tatName"}
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={states.equipmentType.name}
                        selectedValue={(e) => {
                          updateState("equipmentType", {
                            id: e["tatIDpk"],
                            name: e["tatName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("equipmentType", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>

                  <Form.Item
                    style={{ width: "calc(50%)" }}
                    className=""
                    // wrapperCol={{ span: 15 }}
                    labelCol={{ span: 7 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Trouble Category"}
                      </p>
                    }
                  >
                    <div className="w-full flex">
                      <SelectsTemplate
                        // cusWidth={"197px"}
                        style={{ width: "calc(100%)" }}
                        disabledCustomWidth={true}
                        label={""}
                        options={troubleCategories}
                        idexpr={"tctIDpk"}
                        dataexp={"tctName"}
                        useCallFunc
                        disabled={form_disable}
                        placeHolder={states.troubleCategory.name}
                        selectedValue={(e) => {
                          updateState("troubleCategory", {
                            id: e["tctIDpk"],
                            name: e["tctName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("troubleCategory", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form>

                {/* Selects */}
                <InputsTemplate
                  // style={{ width: "98%",}}
                  // parentClassName="w-[400px]"

                  labelColPan={4}
                  wrapperCol={8}
                  span={false}
                  label={"Equipment"}
                  defaultValue={states.equipment}
                  useCallbackFunc
                  orderOnchange={(e: any) => {
                    updateState("equipment", e);
                  }}
                  disabledStatus={form_disable}
                  handleRefresh={() => {
                    updateState("equipment", "");
                  }}
                />

                {/* Remarks */}
                <Form.Item
                  style={{ width: "calc(100%) " }}
                  labelCol={{ span: 4 }}
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {"Trouble Description"}
                    </p>
                  }
                >
                  <div className="w-full flex ">
                    <div style={{ width: "calc(100%)" }} className="h-[70px]">
                      <TextArea
                        value={states.troubleRemarks}
                        disabled={form_disable}
                        style={{
                          resize: "none",
                          height: "65px",
                          padding: "4px",
                        }}
                        onChange={(e) => {
                          setState("troubleRemarks", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
                {/* Remarks */}
                <Form.Item
                  style={{ width: "calc(100%)" }}
                  labelCol={{ span: 4 }}
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {"Remarks"}
                    </p>
                  }
                >
                  <div className="w-full flex ">
                    <div style={{ width: "calc(100%)" }} className="h-[70px]">
                      <TextArea
                        value={states.remarks}
                        disabled={form_disable}
                        style={{
                          resize: "none",
                          height: "65px",
                          padding: "4px",
                        }}
                        onChange={(e) => {
                          setState("remarks", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
                {/* Remarks */}
                <Form.Item
                  style={{ width: "calc(100%)" }}
                  labelCol={{ span: 4 }}
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {"Satisfaction Remarks"}
                    </p>
                  }
                >
                  <div className="w-full flex ">
                    <div style={{ width: "calc(100%)" }} className="h-[110px]">
                      <TextArea
                        value={states.satisfactionRemarks}
                        disabled={form_disable}
                        style={{
                          resize: "none",
                          height: "105px",
                          padding: "4px",
                        }}
                        onChange={(e) => {
                          setState("satisfactionRemarks", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  colon={false}
                  style={{ width: "calc(100%)" }}
                  labelCol={{ span: 4 }}
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {/* {"Satisfaction Remarks"} */}
                    </p>
                  }
                >
                  <div className="w-full flex  items-center justify-between  mb-0.5 ">
                    <RateButton
                      disableButton={selectedRecord?.trpIDpk === undefined}
                      useCallbackFunc
                      handleSave={() => {
                        if (selectedReport?.reportedByEmpID !== userID) {
                          updateState("warningModal", {
                            state: true,
                            title: "Invalid Action",
                            message:
                              "You did not log this Trouble Report. You cannot Rate it.",
                            func() {
                              setStates((state) => ({
                                ...state,
                                warningModal: {
                                  ...state.warningModal,
                                  state: false,
                                },
                              }));
                            },
                          });
                          return;
                        }
                        setShowRatingForm(true);
                      }}
                    />
                    <ul className=" flex items-center gap-x-1 justify-start">
                      {update_state_change ? (
                        <UpdateButton
                          handleUpdate={() => {
                            validateFields() && update("validate");
                          }}
                        />
                      ) : (
                        <SaveButton
                          title="Submit"
                          disableButton={form_disable}
                          handleSave={() => {
                            validateFields() && postData("confirm");
                          }}
                        />
                      )}

                      {form_disable ? (
                        <NewButton
                          useCallbackFunc={true}
                          new_button_toggler={() => {
                            dispatch(
                              setGeneralValue({
                                expr: "formDisabled",
                                value: false,
                              })
                            );
                            dispatch(
                              setGeneralValue({
                                expr: "updateMode",
                                value: false,
                              })
                            );

                            clearRecord();
                            clearAllFields();
                          }}
                        />
                      ) : (
                        <Cancel cancel_button_toggler={() => {}} />
                      )}
                    </ul>
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
);
