import React from "react";
import { EntryCustomToolbar } from "../../accessories/custom-toolbars/entryCustomtoolbar";
import { Pc2 } from "./pc2";
import Datagrid_template from "../../../templates/Datagrid";

export const Protection_guarantee_pc2 = () => {
  return (
    <div>
      <div>
        <EntryCustomToolbar withDates={false} />
      </div>
    <div>
        <Pc2/>
    </div>
    <div>
        {/* DataGrid Template */}
        <Datagrid_template gridheight={238} columns={[]} data={[]} disableSearch/>
    </div>
    </div>
  );
};
