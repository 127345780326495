import { Tooltip } from "devextreme-react";
// import { CheckboxTemlate } from "../../../../../templates/checkbox";
// import { DateTemplate } from "../../../../../templates/date";
// import { InputsTemplate } from "../../../../../templates/input";
// import { IconsContainer } from "./stationReadingsToolbar";
// import close from "../../../../../assets/close.png";
// import print from "../../../../../assets/printer.png";
// import flip from "../../../../../assets/switch-chart.png";
import reset from "../../../../../../assets/reset.png";

import flip from "../../../../../../assets/alter.png";
import edit from "../../../../../../assets/edit.png";
import close from "../../../../../../assets/close.png";
import chart from "../../../../../../assets/bar-chart.png";
import newImg from "../../../../../../assets/new.png";
import { FaBinoculars } from "react-icons/fa";
// import { useGraphSwitcher, useSwitcher } from "../db/useSwitcher";
import { useDispatch } from "react-redux";
// import { setGeneralValue } from "../../../../../features/generalSlice";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { IconsContainer } from "../../_widget/stationReadingsToolbar";
import { InputsTemplate } from "../../../../../../templates/input";
import { DateTemplate } from "../../../../../../templates/date";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import { currentDate } from "../../../../../accessories/component_infos";
import { DatePicker } from "antd";
import { RefreshButton } from "../../_widget/utils";
import InputSelect_template from "../../../../../../templates/InputSelect";

type Props = {
  showEditIcon?: boolean;
  searchTextOnchange?: (value: string) => void;
  checkOnChange?: (value: any) => void;
  handleFind?: () => void;
  runNew?: () => void;
  runUpdate?: () => void;
  handleClose?: () => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  startDate?: any;
  endDate?: any;
  searchValue?: string;
  handleRefresh?: () => void;
  handleSwitcher?: () => void;
  handleSwitcher1?: any;
  stationData?: any[];
};

export default function LineReadingsToolbar({
  showEditIcon = true,
  searchTextOnchange,
  checkOnChange,
  handleFind,
  startDateChanged,
  endDateChanged,
  handleClose,
  runNew,
  runUpdate,
  startDate,
  endDate,
  handleRefresh,
  searchValue,
  handleSwitcher,
  handleSwitcher1,
  stationData,
}: Props) {
  const { setSwitchGraph } = useGraphSwitcher();
  const dispatch = useDispatch();

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState("");
  const [selectKey, setSelectKey] = useState(0);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
      checkOnChange(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
      checkOnChange(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
      checkOnChange("");
    }
  };

  return (
    <div className="border-2 border-muted w-full h-auto rounded-md">
      <section className="flex items-center w-full h-full space-x-1 px-1">
        <aside className="flex space-x-0.5">
          <IconsContainer
            onClick={() => {
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              runNew();
              // handleSwitcher1 && handleSwitcher1();
            }}
            url={newImg}
            name="New"
            // tooltip="Add a new record"
          />

          {showEditIcon && (
            <span onClick={()=> {
              runUpdate();
              // handleSwitcher1 && handleSwitcher1();
            }}>
              <IconsContainer
                url={edit}
                name="Edit"
                // tooltip="Update the selected record"
              />
            </span>
          )}
          <span onClick={handleClose}>
            <IconsContainer
              url={close}
              name="Active Status"
              tooltip="Set record status to active or inactive"
              id="closeLineReadings"
            />
          </span>

          <span onClick={handleSwitcher}>
            <IconsContainer
              url={flip}
              name="Switch"
              // id="flipDG"
            
              
              tooltip="Switch between the table and the form view"
              // id="switchLineReadings"
            />
          </span>
          <aside onClick={setSwitchGraph}>
            <IconsContainer
              url={chart}
              name="Charts"
              tooltip="View chart representation of the data"
            />
          </aside>
        </aside>
        <aside className="flex flex-1 space-x-1 items-center">
          <section className="flex-1">
          <InputSelect_template
              key={selectKey}
              label="Search Station"
              options={stationData}
              idexpr="stnIDpk"
              dataexp="stnName"
              span={true}
              disabled={false}
              selectedValue={(value) => {
                searchTextOnchange(value.stnName);
              }}
              handleSearch={(value) => {
                searchTextOnchange(value);
              }}
              selectStyle={{ height: "25px" }}
              handleClear={() => {
                searchTextOnchange("");
              }}
            />
          </section>
        </aside>
        <aside className="flex space-x-1 items-center mr-2">
          {/* <DateTemplate
            disabled={false}
            changeDate={(date: Dayjs) =>
              startDateChanged!(dayjs(date).format())
            }
            datePlaceholder="Start Date"
            selectedDate={currentDate}
                  /> */}
          <DatePicker
            clearIcon={<span style={{ display: "none" }}></span>}
            onChange={(e) => {
              //   startDateChanged!(e);
              startDateChanged!(dayjs(e).format(`YYYY-MM-DD`));
            }}
            value={dayjs(startDate)}
            size="small"
            placeholder="Start Date"
            style={{ width: 125 }}
            format={"DD MMM YYYY"}
          />
          <DatePicker
            clearIcon={<span style={{ display: "none" }}></span>}
            onChange={(e) => {
              //   endDateChanged!(e);
              endDateChanged!(dayjs(e).format(`YYYY-MM-DD`));
            }}
            value={dayjs(endDate)}
            size="small"
            placeholder="End Date"
            style={{ width: 125 }}
            format={"DD MMM YYYY"}
          />
          {/* <DateTemplate
            disabled={false}
            changeDate={(date: Dayjs) => endDateChanged!(dayjs(date).format())}
            // selectedDate={dayjs().format()}
            // selectedDate={currentDate}
            datePlaceholder="End Date"
          /> */}
          <li
            id="checkactive"
            style={{}}
            className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center "
          >
            <CheckboxTemlate
              defaultValue={checkedBox}
              indeterminate={indeterminate}
              withBorders
              customDisabled={false}
              useCallbackFunc
              setCheckboxOnchange={(e: boolean, index: any) => {
                
                onChange(e);
              }}
            />

            <Tooltip
              target="#checkactive"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Switch Status</p>
            </Tooltip>
          </li>
          {/* <li
            id="refreshGrid"
            style={{}}
            // className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center "
            className="p-1 border-[1px] rounded-sm "
          >
            <FaBinoculars size={15.5} color="#007AFF" />
            <Tooltip
              target="#refreshGrid"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Find record</p>
              <p>Search record in database</p>
            </Tooltip>
          </li> */}
          <div
            id="searchGrid"
            onClick={() => {
              handleFind();
            }}
            className="p-1 border-[1px] rounded-sm "
          >
            <FaBinoculars size={15.5} color="#007AFF" />
            <Tooltip
              target="#searchGrid"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Find record</p>
            </Tooltip>
          </div>
          <span
            id="refreshGrid"
            onClick={() => {
              handleRefresh();
              setCheckValue("");
              setCheckedBox(false);
              setIndeterminate(true);
              setSearchText("");
              setSelectKey(prevKey => prevKey + 1);
            }}
            style={{
              height: 24,
              marginBottom: 0,
              borderWidth: 1,
              width: 24,
            }}
            className="flex justify-center hover:cursor-pointer border-gray-300 rounded "
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
              src={reset}
              alt="reset"
            />
            <Tooltip
              target="#refreshGrid"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Refresh table</p>
            </Tooltip>
          </span>

          {/* <IconsContainer
            url={print}
            name="Midnight Conditions"
            tooltip="Click to initiate the capturing of midnight conditions for the station"
          /> */}
        </aside>
      </section>
    </div>
  );
}
