import save from "../../assets/floppy-disk.png";
import email_send from "../../assets/email_send.png";
import star from "../../assets/blue-star.png";
import newIcon from "../../assets/new.png";
import checkmark from "../../assets/check-mark.png";
import black_checkmark from "../../assets/black_checkmark.png";
import edit from "../../assets/edit.png";
import close from "../../assets/close.png";
import mail_out from "../../assets/mail_out.png";
import search1 from "../../assets/search1.png";
import nextbutton from "../../assets/nextbutton.png";
import password from "../../assets/password.png";
import dollar_tag from "../../assets/dollar_tag.png";
import windows from "../../assets/windows.png";
import fill from "../../assets/fill.png";
import openfolder from "../../assets/openfolder.png";
import submit from "../../assets/submitB.png";
import correct from "../../assets/correct.png";
import React from "react";
import { AppraiseSettings } from "./custom-toolbars/widgets/customToolbarModals";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../features/generalSlice";
import {
  task_footer_update,
  task_form_disable,
} from "../../features/Task&Todo/Task/TaskDetails";

import { ModalTemplate } from "../../templates/modal";
import { Tooltip } from "devextreme-react";
import { FaBinoculars } from "react-icons/fa";

interface props {
  handleSave?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  new_button_toggler?: () => void;
  cancel_button_toggler?: () => void;
  handleOpen?: () => void;
  handleSubmit?: () => void;
  handleMail?: () => void;
  handleAlerts?: () => void;
  handleSignOff?: () => void;
  handleAnalysis?: () => void;
  handleApprove?: () => void;
  handleDisapprove?: () => void;
  handleRecommend?: () => void;
  handleAuth?: () => void;
  handleUpdate?: () => void;
  handleCallbackCancel?: () => void;
  handleLock?: () => void;
  handleOnClick?: () => void;
  handleSearchedButtonClick?: () => void;
  handleCustomizableClick?: () => void;
  classname?: any;
  classname2?: any;
  customizableBtnImage?: any;
  customizableBtnImageClassname?: any;
  disabled_status?: boolean;
  useCallbackFunc?: boolean;
  disableButton?: boolean;
  height?: any;
  heightChange?: boolean;
  text?: string;
  isText?: boolean;
  title?: string;
  label?: string;
  isTooltip?: boolean;
  tooltipMsg?: string;
  buttonID?: string;
  borderTheme?: any;
  newButtonId?: string;
  findSize?: number;
  findHeight?: number;
  className?: string;
  iconClassName?: string;
}

// Analyze all
export const AnalyzeAllButton = ({ handleAnalysis, label, classname }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  return (
    <>
      <button
        id={label}
        style={{ borderColor: borderTheme }}
        onClick={(e: any) => {
          handleAnalysis && handleAnalysis();
          e.preventDefault();
        }}
        className={`${classname}}dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200  rounded`}
      >
        <img alt="" className="pr-1" src={windows} />
        Analyze All
      </button>
    </>
  );
};

// Dollar_tag

export const AnalyzeButton = ({ handleAnalysis, label, classname, classname2 }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        id={label}
        style={{ borderColor: borderTheme }}

        onClick={(e) => {
          handleAnalysis && handleAnalysis();
          e.preventDefault();
        }}
        className={`${classname} dark:bg-slate-900 dark:text-darkModeSkin-base flex  items-center justify-center   border-2 border-slate-200 rounded`}
      >
        <img alt="" className={classname2 ? classname2 : "w-[24px]"} src={dollar_tag} />
      </button>
    </>
  );
};

export const SendEmailButton = ({ handleMail, disableButton }: props) => {
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        onClick={() => {
          handleMail && handleMail();
        }}
        disabled={disableButton}
        style={
          disabled === false || disableButton === false
            ? { opacity: 1, borderColor: borderTheme }
            : { opacity: 0.5, borderColor: borderTheme }
        }
        className="flex h-8 hover:border-blue-200 flex-row items-center justify-center ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1" src={email_send} />
        Send
      </button>
    </>
  );
};

// form save button

export const SaveButton = ({
  handleSave,
  disableButton = undefined,
  useCallbackFunc,
  title,
  className,
  iconClassName,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const borderBg = useSelector((state: RootState) => state.general.cssBorderBg);
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  return (
    <>
      <button
        style={
          disableButton === undefined
            ? disabled === false
              ? {
                opacity: 1,
                borderColor: borderTheme,
                backgroundColor: borderBg,
              }
              : {
                opacity: 0.5,
                borderColor: borderTheme,
                backgroundColor: borderBg,
              }
            : disableButton === false
              ? {
                opacity: 1,
                borderColor: borderTheme,
                backgroundColor: borderBg,
              }
              : {
                opacity: 0.5,
                borderColor: borderTheme,
                backgroundColor: borderBg,
              }
        }
        disabled={disableButton ?? disabled}
        onClick={(e) => {
          handleSave && handleSave(e);
          e.preventDefault();
        }}
        className={
          className
            ? className
            : `flex h-8 hover:border-blue-200 flex-row items-center justify-center px-2 py-2 border-2 mr-1 'border-slate-200' rounded dark:bg-slate-900 dark:text-darkModeSkin-base`
        }
      >
        <img alt="" className={iconClassName ?? "pr-1"} src={save} />
        {title ? title : "Save"}
      </button>
    </>
  );
};

export const RateButton = ({
  handleSave,
  disableButton = undefined,
  useCallbackFunc,
  title,
  className,
  iconClassName,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const borderBg = useSelector((state: RootState) => state.general.cssBorderBg);
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  return (
    <>
      <button
       
        disabled={disableButton ?? disabled}
        onClick={(e) => {
          handleSave && handleSave(e);
          // e.preventDefault();
        }}
        className={
          className
            ? className
            : `flex h-8 hover:border-blue-200 flex-row items-center justify-center mr-1  px-2 py-2 border-2 'border-slate-200' rounded dark:bg-slate-900 dark:text-darkModeSkin-base pr-4`
        }
      >
        <img alt="" className={iconClassName ?? "pr-1"} src={star} />
        {title ? title : "Rate"}
      </button>
    </>
  );
};



export const UpdateButton = ({ handleUpdate, disableButton }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={
          !disableButton
            ? { opacity: 1, borderColor: borderTheme }
            : { opacity: 0.5, borderColor: borderTheme }
        }
        disabled={disableButton}
        onClick={handleUpdate && handleUpdate}
        className="hover:cursor-pointer h-8 dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
      >
        <img alt="" className="pr-1  " src={save} />
        Update
      </button>
    </>
  );
};

export const Schedule_Alert_Button = ({
  handleAlerts,
  buttonID = "alert",
  isTooltip,
  tooltipMsg,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        id={`${buttonID}`}
        style={{ borderColor: borderTheme }}
        className=" hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mx-1 px-2 py-1 border-2 border-slate-200 hover:border-blue-300 rounded"
        onClick={() => {
          handleAlerts!();
        }}
      >
        <img alt="" className="pr-1" src={mail_out} />
        Schedule & Alert
      </button>

      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};

// form new button
export const NewButton = ({
  title,
  new_button_toggler,
  useCallbackFunc,
  newButtonId,
  disableButton,
}: props) => {
  const dispatch = useDispatch();
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const borderBg = useSelector((state: RootState) => state.general.cssBorderBg);
  const form_access_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  // dispatch(settask_form_disable(false));
  const handleNew = () => {
    form_access_disable || form_access_disable === undefined
      ? dispatch(setGeneralValue({ expr: "TransformDisabled", value: true }))
      : dispatch(setGeneralValue({ expr: "TransformDisabled", value: false }));
    form_access_disable || form_access_disable === undefined
      ? dispatch(task_form_disable(true))
      : dispatch(task_form_disable(false));
    form_access_disable || form_access_disable === undefined
      ? dispatch(settask_form_disable(true))
      : dispatch(settask_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    //clear data on redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: {} }));
  };
  return (
    <>
      <button
        id={newButtonId ?? "newButton"}
        disabled={disableButton ?? false}
        onClick={() => {
          new_button_toggler && new_button_toggler();

          !useCallbackFunc && handleNew();
        }}
        className={`h-8 flex hover:border-blue-200 flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base`}
        style={{
          borderColor: borderTheme ?? "#778899",
          backgroundColor: borderBg,
        }}
      >
        <img alt="" className="pr-1" src={newIcon} />
        <p className=" inline">{title ?? "New"}</p>
      </button>
    </>
  );
};

export const OpenButton = ({
  handleOpen,
  buttonID = "open",
  isTooltip,
  tooltipMsg,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        id={`${buttonID}`}
        style={{ borderColor: borderTheme }}
        onClick={handleOpen}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center px-2 py-1 border-2 border-slate-200 hover:border-blue-300 rounded"
      >
        <img alt="" className="pr-1" src={black_checkmark} />
        Open
      </button>
      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};

// search button

export const SearchButton = ({
  isText = false,
  text = "Search",
  isTooltip,
  tooltipMsg,
  buttonID = "search",
  handleOnClick,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        onClick={handleOnClick}
        id={buttonID}
        style={{ borderColor: borderTheme }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1 " src={search1} />
        {isText && "Search"}
      </button>
      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};
export const FindButton = ({
  isText = false,
  text = "Find",
  isTooltip,
  tooltipMsg,
  buttonID = "Find",
  handleOnClick,
  findSize,
  findHeight,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        onClick={handleOnClick}
        id={buttonID}
        style={{ borderColor: borderTheme }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded"
      >
        <FaBinoculars
          height={findHeight}
          size={findSize ?? 18}
          color="#007AFF"
        />
        {isText && "Find"}
      </button>
      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};
export const BrowseButton = ({
  isText = false,
  text = "Browse",
  isTooltip,
  tooltipMsg,
  buttonID = "Browse",
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        id={buttonID}
        style={{ borderColor: borderTheme }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1" src={openfolder} />

        {isText && "Browse"}
      </button>
      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};

export const RecallSubmissionButton = () => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-5 py-4 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1" src={nextbutton} />
        Recall Submission
      </button>
    </>
  );
};

export const ForwardAssignmentButton = () => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-5 py-4 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1" src={nextbutton} />
        Forward Assignments
      </button>
    </>
  );
};

export const LockButton = ({ handleLock }: any) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        onClick={handleLock}
        style={{ borderColor: borderTheme }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 mt-1 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1" src={password} />
        Lock
      </button>
    </>
  );
};

export const LockSubmissionButton = ({ handleLock }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={handleLock}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-5 py-4 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1" src={password} />
        Lock Submission
      </button>
    </>
  );
};

interface props {
  text?: string;
  AppraiseisActive?: boolean;
  AppraisesetIsActive?: any;
}

// Appraise button
export const AppraiseButton = ({
  AppraiseisActive,
  AppraisesetIsActive,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={() => {
          AppraisesetIsActive(!AppraiseisActive);
        }}
        className="flex h-8 flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={checkmark} />
        Appraise
      </button>

      <AppraiseSettings
        AppraiseisActive={AppraiseisActive}
        AppraisesetIsActive={AppraisesetIsActive}
      />
    </>
  );
};

export const ApproveButton = ({
  text,
  handleApprove,
  disableButton = false,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        onClick={handleApprove && handleApprove}
        style={
          disableButton === false
            ? { opacity: 1, borderColor: borderTheme }
            : { opacity: 0.5, borderColor: borderTheme }
        }
        disabled={disableButton}
        className="flex h-8 flex-row items-center justify-center px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={checkmark} />
        {text ?? "Approve"}
      </button>
    </>
  );
};

export const DisapproveButton = ({ handleDisapprove }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={handleDisapprove && handleDisapprove}
        className="flex flex-row items-center justify-center h-8 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={close} />
        Disapprove
      </button>
    </>
  );
};

export const AuthButton = ({ text, handleAuth }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={handleAuth && handleAuth}
        className="flex h-8 flex-row items-center justify-center px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={save} />
        {text ?? "Authorise"}
      </button>
    </>
  );
};

export const FillButton = ({ }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={() => { }}
        className="flex flex-row items-center justify-center h-8 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={fill} />
        Fill
      </button>
    </>
  );
};

export const AcknowledgeButton = ({ }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={() => { }}
        className="flex h-8 flex-row items-center justify-center px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={correct} />
        Acknowledge
      </button>
    </>
  );
};

export const RejectButton = ({ }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={() => { }}
        className="flex flex-row items-center justify-center h-8 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={close} />
        Reject
      </button>
    </>
  );
};

//  Submit
export const SubmitButton = ({
  handleSubmit,
  height,
  heightChange = false,
  disableButton,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        disabled={disableButton}
        onClick={handleSubmit && handleSubmit}
        className={`h-${!heightChange ? 8 : height
          } flex flex-row items-center justify-center  ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base`}
      >
        <img alt="" className="pr-1 " src={submit} />
        Submit
      </button>
    </>
  );
};

// Dispense

export const DispenseButton = ({
  handleSubmit,
  height,
  heightChange = false,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={() => {
          handleSubmit && handleSubmit();
          // AppraisesetIsActive(!AppraiseisActive);
        }}
        className={`h-${!heightChange ? 9 : height
          } flex flex-row items-center justify-center  ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base`}
      >
        <img alt="" className="pr-1 " src={submit} />
        Dispense
      </button>
    </>
  );
};

// Sign off button

export const SignOff = ({ handleSignOff }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={handleSignOff}
        className="flex flex-row items-center justify-center mt-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={checkmark} />
        Sign Off
      </button>
    </>
  );
};

export const MiniCancel = ({ handleOnClick }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  return (
    <>
      <li
        onClick={handleOnClick}
        id="newRecord"
        style={{ borderWidth: "1px", borderColor: borderTheme }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img alt="" className="pr-1 " src={close} />
          Cancel{" "}
        </span>
        <Tooltip
          target="#newRecord"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className="">Cancel</p>
        </Tooltip>
      </li>
    </>
  );
};

export const Cancel = ({
  useCallbackFunc,
  handleCallbackCancel,
  cancel_button_toggler,
  text = "Cancel",
  isTooltip,
  tooltipMsg,
  buttonID = "cancel",
  disableButton = false,
}: props) => {
  const dispatch = useDispatch();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "TransformDisabled", value: true }));

    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    // dispatch(update_route_state(false))
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    dispatch(setGeneralValue({ expr: "TransupdateMode", value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    handleCallbackCancel && handleCallbackCancel();
  };
  return (
    <>
      <button
        id={`${buttonID}`}
        disabled={disableButton}
        onClick={() =>
          useCallbackFunc === true
            ? cancel_button_toggler && cancel_button_toggler()
            : handleCancel()
        }
        className=" h-8 flex hover:border-blue-200 flex-row items-center justify-center ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
        style={
          disableButton === false
            ? { opacity: 1, borderColor: borderTheme }
            : { opacity: 0.5, borderColor: borderTheme }
        }
      >
        <img alt="" className="pr-1 " src={close} />
        {text}
      </button>

      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};

export const Cancel_Rmks = () => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={() => { }}
        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
      >
        <img alt="" className="pr-1 " src={close} />
        Cancel
      </button>

      <ModalTemplate
        title={"Cancellation Reason"}
        okText={""}
        message={""}
        open={false}
        okHandler={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    </>
  );
};

export const Recommend = ({
  handleRecommend,
  disableButton = false,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        disabled={disableButton}
        onClick={handleRecommend}
        style={
          disableButton === false
            ? { opacity: 1, borderColor: borderTheme }
            : { opacity: 0.5, borderColor: borderTheme }
        }
        className="flex flex-row items-center h-8 justify-center  px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <img alt="" className="pr-1 " src={checkmark} />
        Recommend
      </button>
    </>
  );
};

export const EditButton: React.FC<{ onClick?: () => void }> = (
  { onClick },
  { disableButton }: props
) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        style={{ borderColor: borderTheme }}
        onClick={onClick}
        disabled={disableButton}
        className="dark:bg-slate-900 h-8 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded"
      >
        <img alt="" className="pr-1" src={edit} />
        Edit
      </button>
    </>
  );
};

// customizable button
export const CustomizableButton = ({
  isText = false,
  text = "Search",
  isTooltip,
  tooltipMsg,
  buttonID = "search",
  classname,
  handleCustomizableClick,
  customizableBtnImage,
  customizableBtnImageClassname,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <>
      <button
        id={buttonID}
        onClick={handleCustomizableClick}
        style={{ borderColor: borderTheme }}
        className={`${classname} border-[1px] border-slate-200 rounded`}
      >
        <img
          alt=""
          className={customizableBtnImageClassname}
          src={customizableBtnImage}
        />
        {isText && text}
      </button>
      {isTooltip && (
        <Tooltip
          target={`#${buttonID}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p>{`${tooltipMsg}`}</p>
        </Tooltip>
      )}
    </>
  );
};
