import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import TransformerDetails from "./transformer-details";
import { TransformersToolbar } from "./transformers-toolbar";
import { searchCriteriaTypes, searchTextTypes, statusTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";

export const Transformers = () => {
  const taskDetailsHeight = "309px";

  const [tasks_update_state, setTask_update_state] = useState<boolean>(true);
  const [task_datagrid_height, set_task_datagrid_height] = useState(
    `calc(100% - ${taskDetailsHeight})`
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const current_page_route = "/tasks-todos";
  const view = searchParams.get("view");
  const taskDataGrid = "task-data-grid-extended";
  const [showTable, setShowTable] = useState<boolean>(true);

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);
  const initialStart = dayjs()
    .subtract(5, "year")
    .startOf("year")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });
  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };
  const searchCriteriaData = [
    {
        id: 1,
        label: 'ID',
       
      },
      {
        id: 2,
        label: 'Serial Number',
       
      },
      {
        id: 3,
        label: 'Station',
       
      },
      {
        id: 4,
        label: 'Manufacturer',
       
      },
      {
        id: 5,
        label: 'Standard',
       
      },
      {
        id: 6,
        label: 'Cooling Type',
       
      },
      {
        id: 7,
        label: 'Tap changing Gear Type',
       
      },
      {
        id: 8,
        label: 'Connection Symbol',
       
      },
      {
        id: 9,
        label: 'status',
       
      },
      {
        id: 10,
        label: 'Tapped Wing (KV)',
       
      },
      {
        id: 11,
        label: 'Steps',
       
      },
      {
        id: 12,
        label: '% Impedance @ 75deg cel',
       
      },
      {
        id: 13,
        label: 'Rated Output',
       
      },
      {
        id: 14,
        label: 'Norminal Tap',
       
      },
      {
        id: 15,
        label: 'Rated Voltage I',
       
      },
      {
        id: 16,
        label: 'Rated Voltage II',
       
      },
      {
        id: 17,
        label: 'Rated Voltage III',
       
      },
      {
        id: 18,
        label: 'Rated Power I',
       
      },
      {
        id: 19,
        label: 'Rated Power II',
       
      },
      {
        id: 20,
        label: 'Rated Power III',
       
      },
      {
        id: 21,
        label: 'Construction Start Date',
       
      },
      {
        id: 22,
        label: 'Commissioning Date',
       
      },
      {
        id: 23,
        label: 'Decommissioning Date',
       
      },
      {
        id: 24,
        label: 'Manufacture Date',
       
      },
      {
        id: 25,
        label: 'Supply Date',
       
      },
]
  return (
    <>
      <div className="">
        <TransformersToolbar 
         startDate={startDate.temp}
         endDate={endDate.temp}
         startDateChanged={(date) =>
           setStartDate({
             temp: dayjs(date).format(genFormat),
             text: startDate.text,
           })
         }
         endDateChanged={(date) =>
           setEndDate({
             temp: dayjs(date).format(genFormat),
             text: endDate.text,
           })
         }
         searchTextOnchange={(text) =>
           setSearchText({ temp: text, text: searchText.text })
         }
         checkOnChange={(check) =>
           setStatus({ temp: check, status: status.status })
         }
         handleFind={() => {
           setStartDate({ text: startDate.temp, temp: startDate.temp });
           setEndDate({ text: endDate.temp, temp: endDate.temp });
           setSearchText({ temp: searchText.temp, text: searchText.temp });
           setStatus({ temp: status.temp, status: status.temp });
           setSearchCriteria({
             temp: searchCriteria.temp,
             index: searchCriteria.temp,
           });
         }}
         searchCriteriaOnchange={(newIndex, criteria) => {
           setSearchCriteria({
             temp: newIndex,
             index: searchCriteria.index,
           });
         }}
         searchCriteriaData={searchCriteriaData}
        
        toggler={switch_toggle}
        toggled={showTable}
        />
        {/* <Staff_customtoolbar /> */}
      </div>
      <div className=" m-1  border rounded-md">
        <p className=" px-2 w-full bg-slate-100">{"Transformer Details"}</p>
        <div className=" mb-0.5  ">
          <TransformerDetails toggled={showTable} SearchCriteria={searchCriteria.index} CheckState={status.status} SearchTerm={searchText.text}/>
        </div>
      </div>
    </>
  );
};
