/* eslint-disable react/jsx-pascal-case */
import { Select, Form, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import reset from "../../../../../../../assets/reset.png";
import checkmark from "../../../../../../../assets/check-mark.png";

import cancelCircle from "../../../../../../../assets/cancel_circle.png";
import checkedCircle from "../../../../../../../assets/checked_circle.png";
import editFile from "../../../../../../../assets/edit-file.png";
import newIcon from "../../../../../../../assets/new.png";
import {
  NewButton,
  Cancel,
  UpdateButton,
  SubmitButton,
  CustomizableButton,
  Recommend,
} from "../../../../../../accessories/buttons";

import {
  currentDate,
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import { vehicle_request_TH } from "../data/vehicle-requests-data";
import useFetch from "../../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../../templates/modal";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  settask_footer_table_selected,
  setGeneralValue,
} from "../../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { Tooltip } from "devextreme-react";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";

import { GetDataFunc } from "../../../../../../../functions/get";
import { EnvironmentOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../../../accessories/formModal";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { formPropsConst } from "../../../../../../accessories/constants";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import {
  datesTypes,
  formModalStates,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { DateTemplate } from "../../../../../../../templates/date";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import React from "react";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { debounce, update } from "lodash";
import { useCrudFunc } from "../../../../../../../functions/crud";
import ConfirmationModal from "../../../../../../../templates/ConfirmModal";
import {
  FetchFrom_country,
  FetchFrom_location,
  FetchFrom_region,
  FetchRecommendedBy,
  FetchRequestArea,
  FetchRequestBy,
  FetchRequestType,
  FetchTo_country,
  FetchTo_location,
  FetchTo_region,
} from "../../../../../../../features/apiCalls/vehicleRequestApi/RequisitionDetails";
import {
  FetchDriver_No1,
  FetchDriver_No2,
  FetchRequestClass,
  FetchVehicle_No1,
  FetchVehicle_No2,
} from "../../../../../../../features/apiCalls/vehicleRequestApi/RequistionApproval";
import { name } from "mustache";

interface props {
  toggled?: boolean;
  Datax: any;
  setRefreshx?: () => void;
  AllState?: any;
  setAllState?: (value: any) => void;
  setOuterSelectedRecord?: (e: any) => void;
  setVisible?: () => void;
  heightChange?: boolean;
  height?: any;
}

interface stateTypes {
  cancellationReason: string;
  refresh: boolean;
  inputModal?: any;
  openFormModal: boolean;
  remarks: string;
  recommendRemarks: string;
  debitCategory: { name: string; id: number };
  costCenter: { name: string; id: number };
}

const VehicleRequestsDetails = ({
  toggled,
  Datax,
  setRefreshx,
  AllState,
  setAllState,
  setOuterSelectedRecord,
  setVisible,
  heightChange = false,
  height,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [empID, userId, userInfo] = useAccountInfo();
  const updateState = (stateName: string, value: any) => {
    if (stateName === "confirmState") {
      setAllState!((currentState: any) => ({
        ...currentState,
        confirmState: value,
      }));
    } else {
      setAllState!((currentState: any) => ({
        ...currentState,
        [stateName]: value,
      }));
    }
  };

  const [selectedRecord, setSelectedRecord] = useState<any>({});
  // const [drivers, setDrivers] = useState<any>();
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [states, setStates] = useState<stateTypes>({
    cancellationReason: "",
    openFormModal: false,
    inputModal: {
      state: false,
      title: "",
      message: "",
      event: () => {},
    },
    refresh: false,
    remarks: "",
    recommendRemarks: "",
    debitCategory: { name: "", id: 0 },
    costCenter: { name: "", id: 0 },
  });

  const inUpdateStates = (key: string, val: any) => {
    setStates((prev: any) => ({ ...prev, [key]: val }));
  };

  const dispatch = useDispatch();
  // redux
  const form_disable = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const form_disable2 = useSelector(
    (state: RootState) => state.general.formDisabled2
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [posting, updating] = useCrudFunc();

  const [areas, areasErr, areasLoading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestAreas",
    AllState.refresh
  );

  const [requestBy, requestByErr, requestByLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestedByAll`,
    AllState.refresh
  );

  const [requestTypes, requestTypesErr, requestTypesLoading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestTypes",
    AllState.refresh
  );

  const [countryFrom, countryFromErr, countryFromLoading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestFromCountryGetAll",
    AllState.refresh
  );

  const [countryTo, countryToErr, countryToLoading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestToCountryGetAll",
    AllState.refresh
  );

  const [recommended, recommendedErr, recommendedLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestRecommendByGetAll?VaaRequestAreaIDfk=${AllState?.requestArea.id}`,
    AllState.refresh
  );

  const [requestClasses, requestClassesErr, requestClassesLoading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestClasses",
    AllState.refresh
  );

  const [vehicleNo, vehicleNoErr, vehicleNoloading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestVehiclesRegNo",
    AllState.refresh
  );

  const [defaultGridData, error, loading] = useFetch(
    "AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequests?startDate=2019-01-16&endDate=2022-01-16&EmpIDpk=379&VdtRegNo=2",
    AllState.refresh
  );

  //New Implementation

  const [fromRegion, fromRegionErr, fromRegionLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestFromRegionGetAll?ctyIDpk=${AllState?.selectedFromCountry.id}`,
    AllState.refresh
  );

  const [toRegion, toRegionErr, toRegionLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestToRegionGetAll?CtyIDpk=${AllState?.selectedToCountry.id}`,
    AllState.refresh
  );

  const [fromLocation, fromLocationErr, fromLocationLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestFromTownGetAll?ctyRgnIDpk=${AllState.selectedFromCountry.id}&FromRgnID=${AllState.selectedFromRegion.id}`,
    AllState.refresh
  );

  const [toLocation, toLocationErr, toLocationLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestToTownGetAll?rgnCtyIDpk=${AllState.selectedToCountry.id}&toRgnID=${AllState.selectedToRegion.id}`,
    AllState.refresh
  );
  const [drivers, driversErr, driversLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestDrivers?vaaRequestAreaIDfk=${selectedRecord?.vraIdpk}&vrqAreadIDfk=${selectedRecord?.vraIdpk}`
  );
  const [costCenterData] = useFetch(
    "AsmTmsRecommendVehicleRequest/GetAllCosts"
  );

  const validate = (method: string) => {
    if (AllState.requestArea.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select Request Area",
        title: "Required field",
      });
      return;
    }

    if (AllState.requestType.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select Request Type",
        title: "Required field",
      });
      return;
    }

    if (AllState.requestBy.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select Request By",
        title: "Required field",
      });
      return;
    }

    if (dayjs(AllState.departureTime).isAfter(dayjs(AllState.returnTime))) {
      updateState("modalState", {
        state: true,
        message: "Departure Time cannot be greater than Arrival Time ",
        title: "Time validation!",
      });
      return;
    }

    if (AllState.recommendedBy.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select Recommended By",
        title: "Required field",
      });
      return;
    }

    if (AllState.selectedFromCountry.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select From - Country",
        title: "Required field",
      });
      return;
    }

    if (AllState.selectedToCountry.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select To - Country",
        title: "Required field",
      });
      return;
    }

    if (AllState.selectedFromRegion.id === "") {
      updateState("modalState", {
        state: true,
        message: "Please select From - Region",
        title: "Required field",
      });
      return;
    }

    if (AllState.selectedToRegion.id === "") {
      updateState("modalState", {
        state: true,
        message: "Please select To - Region",
        title: "Required field",
      });
      return;
    }

    if (AllState.selectedFromLocation.id === "") {
      updateState("modalState", {
        state: true,
        message: "Please select From - Location",
        title: "Required field",
      });
      return;
    }

    if (AllState.selectedToLocation.id === "") {
      updateState("modalState", {
        state: true,
        message: "Please select To - Location",
        title: "Required field",
      });
      return;
    }

    if (AllState.isDriver === false && AllState.isVehicle === false) {
      updateState("modalState", {
        state: true,
        message:
          "Please select the service required. Either a vehicle or driver must be selected",
        title: "Required field",
      });
      return;
    }

    if (AllState.numOfEmployees === 0) {
      updateState("modalState", {
        state: true,
        message: "Please enter the number of staff",
        title: "Required field",
      });
      return;
    }

    if (AllState.purpose === "") {
      updateState("modalState", {
        state: true,
        message: "Please enter the the purpose of the trip",
        title: "Required field",
      });
      return;
    }

    if (method === "post") {
      updateState("confirmState", {
        state: true,
        title: "Confirm",
        message: "Are you sure you want to insert record?",
        event: () => {
          postData();
          updateState("confirmState", { state: false });
        },
      });
    } else {
      updateState("confirmState", {
        state: true,
        title: "Confirm",
        okText: "Yes",
        message: "Are you sure you want to update record?",
        event: () => {
          updateData();
          updateState("confirmState", { state: false });
        },
      });
    }
  };

  const postData = async () => {
    await posting(
      `AsmTmsVehicleRequest/CreateAsmTmsVehicleRequest`,
      {
        vrqRequesterEmpIdfk: AllState.requestBy.id,
        vrqRecommendedByEmpIdfk: AllState.recommendedBy.id,
        vrqDepartureTime: AllState.departureTime,
        vrqEstimatedReturnTime: AllState.returnTime,
        vrqActualReturnTime: AllState.returnTime,
        vrqCategoryIdfk: AllState.requestType.id,
        vrqAreaIdfk: AllState.requestArea.id,
        vrqFromRegionIdfk: AllState.selectedFromRegion.id,
        vrqToRegionIdfk: AllState.selectedToRegion.id,
        vrqFromTown: AllState.selectedFromLocation.name,
        vrqToTown: AllState.selectedToLocation.name,
        vrqRoute: AllState.route,
        vrqNoOnTrip: AllState.numOfEmployees,
        vrqPurpose: AllState.purpose,
        vrqRequestVehicle: AllState.isVehicle,
        vrqRequestDriver: AllState.isDriver,
        vrqRmks: AllState.remark,
        vrqCreatedBy: `${empID}`,
      },
      `Created the Asset Manager-Trans-Vehicle Request with Request Area - ${AllState.requestArea.name}, Request Type - ${AllState.requestType.name}, Request By - ${AllState.requestBy.name}, Recommended By - ${AllState.recommendedBy.name}, Departure Time - ${AllState.departureTime}, Return Time - ${AllState.returnTime}, From Location - ${AllState.selectedFromLocation.name}, To Location - ${AllState.selectedToLocation.name}, Route - ${AllState.route}, Number of Employees - ${AllState.numOfEmployees}, Purpose - ${AllState.purpose}, Request Vehicle - ${AllState.isVehicle}, Request Driver - ${AllState.isDriver}`
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Record submitted successfully",
        });
        updateState("refresh", !AllState.refresh);
        setRefreshx && setRefreshx();
      })
      .catch((err) => {
        updateState("modalState", {
          state: true,
          title: "Sorry !",
          message:
            "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
        });
      });
  };

  const updateData = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });

    await updating(
      `AsmTmsVehicleRequest/UpdateAsmTmsVehicleRequest/${AllState.selectedItemId}`,
      {
        vrqRequesterEmpIdfk: AllState.requestBy.id,
        vrqRecommendedByEmpIdfk: AllState.recommendedBy.id,
        vrqDepartureTime: AllState.departureTime,
        vrqEstimatedReturnTime: AllState.returnTime,
        vrqActualReturnTime: AllState.returnTime,
        vrqAreaIdfk: AllState.requestArea.id,
        vrqFromRegionIdfk: AllState.selectedFromRegion.id,
        vrqToRegionIdfk: AllState.selectedToRegion.id,
        vrqFromTown: AllState.selectedFromLocation.name,
        vrqToTown: AllState.selectedToLocation.name,
        vrqRoute: AllState.route,
        vrqNoOnTrip: AllState.numOfEmployees,
        vrqPurpose: AllState.purpose,
        vrqRequestVehicle: AllState.isVehicle,
        vrqRequestDriver: AllState.isDriver,
        vrqRmks: AllState.remark,
        vrqEditedBy: `${empID}`,
        vrqEditedDate: currentDate,
      },
      `Edited the Asset Manager-Trans-Vehicle Request with Request Area - ${AllState.requestArea.name}, Request Type - ${AllState.requestType.name}, Request By - ${AllState.requestBy.name}, Recommended By - ${AllState.recommendedBy.name}, Departure Time - ${AllState.departureTime}, Return Time - ${AllState.returnTime}, From Location - ${AllState.selectedFromLocation.name}, To Location - ${AllState.selectedToLocation.name}, Route - ${AllState.route}, Number of Employees - ${AllState.numOfEmployees}, Purpose - ${AllState.purpose}, Request Vehicle - ${AllState.isVehicle}, Request Driver - ${AllState.isDriver}`
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Record updated successfully",
        });
        updateState("refresh", !AllState.refresh);
        setRefreshx && setRefreshx();
      })
      .catch((err) => {
        updateState("modalState", {
          state: true,
          title: "Required field",
          message:
            "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
        });
      });
  };

  // const fetchDriverData = async () => {
  //   const driversData = await GetDataFunc(
  //     `AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestDrivers?vaaRequestAreaIDfk=${selectedRecord?.vraIdpk}&vrqAreadIDfk=${selectedRecord?.vraIdpk}`
  //   );

  //   setDrivers(driversData.data);
  // };

  const Row_Double_Click = (data: any) => {
    setSelectedRecord(data);
    updateState("selectedItemId", data?.vrqIdpk);
    updateState("active", data?.vrqActive);
    updateState("isDriver", data?.vrqRequestDriver);
    updateState("isVehicle", data?.vrqRequestVehicle);
    updateState("purpose", data?.vrqPurpose);
    updateState("remark", data?.vrqRmks);
    updateState("route", data?.vrqRoute);
    updateState("departureTime", data?.vrqDepartureTime);
    updateState("returnTime", data?.vrqActualReturnTime);
    updateState("numOfEmployees", data?.vrqNoOnTrip);
    updateState("selectedRequestClass", {
      name: data?.vrcName,
      id: data?.vrcIdpk,
    });
    updateState("selectedVehicleno1", {
      name: data?.vdtRegNo,
      id: data?.vdtIdpk,
    });
    updateState("selectedDriverNo1", {
      name: data?.empName,
      id: data?.empIdpk,
    });
    updateState("selectedRequestType", {
      name: data?.vctName,
      id: data?.vctIdpk,
    });
    updateState("debitAmount", data?.vrqDebitAmount);
    updateState("debitNotes", data?.vrqDebitNotes);
    updateState("remarks2", data?.vrqApprovalRmks);
    updateState("assignRemarks", data?.vrqAssignmentRmks);
    updateState("selectedFromLocation", {
      id: data?.vrqIdpk,
      name: data?.vrqFromTown,
    });
    updateState("selectedToLocation", {
      id: data?.vrqIdpk,
      name: data?.vrqToTown,
    });
    updateState("selectedFromCountry", {
      id: data?.fromRgnCtyId,
      name: data?.fromRgnCtyName,
    });
    updateState("selectedToCountry", {
      id: data?.toRgnCtyId,
      name: data?.toRgnCtyName,
    });
    updateState("selectedFromRegion", {
      id: 0,
      name: data?.fromRgnName,
    });
    updateState("selectedToRegion", {
      id: 0,
      name: data?.toRgnName,
    });
    updateState("requestBy", {
      id: data?.requesterEmpId,
      name: data?.requesterEmpName,
    });
    updateState("recommendedBy", {
      id: data?.recommendedByEmpId,
      name: data?.recommendedByEmpName,
    });
    updateState("requestType", {
      id: data?.vctIdpk,
      name: data?.vctName,
    });

    for (var a = 0; a < countryFrom.length; a++) {
      if (countryFrom[a]["fromCtyName"] === data?.fromRgnCtyName) {
        updateState("selectedFromCountry", {
          id: countryFrom[a]["fromCtyID"],
          name: countryFrom[a]["fromCtyName"],
        });
      }
    }

    for (var b = 0; b < countryTo.length; b++) {
      if (countryTo[b]["toCtyName"] === data?.toRgnCtyName) {
        updateState("selectedToCountry", {
          id: countryTo[b]["toCtyID"],
          name: countryTo[b]["toCtyName"],
        });
      }
    }
  };

  const rowClicked = (data: any) => {
    // fetchDriverData();

    updateState("selectedItemId", data?.vrqIdpk);
    updateState("active", data?.vrqActive);
    updateState("isDriver", data?.vrqRequestDriver);
    updateState("isVehicle", data?.vrqRequestVehicle);
    updateState("purpose", data?.vrqPurpose);
    updateState("remark", data?.vrqRmks);
    updateState("route", data?.vrqRoute);
    updateState("departureTime", data?.vrqDepartureTime);
    updateState("returnTime", data?.vrqActualReturnTime);
    updateState("numOfEmployees", data?.vrqNoOnTrip);
    updateState("selectedRequestClass", {
      name: data?.vrcName,
      id: data?.vrcIdpk,
    });
    updateState("selectedVehicleno1", {
      name: data?.vdtRegNo,
      id: data?.vdtIdpk,
    });
    updateState("selectedDriverNo1", {
      name: data?.empName,
      id: data?.empIdpk,
    });
    updateState("selectedRequestType", {
      name: data?.vctName,
      id: data?.vctIdpk,
    });
    updateState("debitAmount", data?.vrqDebitAmount);
    updateState("debitNotes", data?.vrqDebitNotes);
    updateState("remarks2", data?.vrqApprovalRmks);
    updateState("assignRemarks", data?.vrqAssignmentRmks);
    updateState("selectedFromLocation", {
      id: data?.vrqIdpk,
      name: data?.vrqFromTown,
    });
    updateState("selectedToLocation", {
      id: data?.vrqIdpk,
      name: data?.vrqToTown,
    });
    updateState("selectedFromCountry", {
      id: data?.fromRgnCtyId,
      name: data?.fromRgnCtyName,
    });
    updateState("selectedToCountry", {
      id: data?.toRgnCtyId,
      name: data?.toRgnCtyName,
    });
    updateState("selectedFromRegion", {
      id: 0,
      name: data?.fromRgnName,
    });
    updateState("selectedToRegion", {
      id: 0,
      name: data?.toRgnName,
    });
    updateState("requestBy", {
      id: data?.requesterEmpId,
      name: data?.requesterEmpName,
    });
    updateState("recommendedBy", {
      id: data?.recommendedByEmpId,
      name: data?.recommendedByEmpName,
    });
    updateState("requestType", {
      id: data?.vctIdpk,
      name: data?.vctName,
    });
    updateState("requestArea", {
      id: data?.vraIdpk,
      name: data?.vraName,
    });

    for (var a = 0; a < countryFrom.length; a++) {
      if (countryFrom[a]["fromCtyName"] === data?.fromRgnCtyName) {
        updateState("selectedFromCountry", {
          id: countryFrom[a]["fromCtyID"],
          name: countryFrom[a]["fromCtyName"],
        });
      }
    }

    for (var a = 0; a < countryTo.length; a++) {
      if (countryTo[a]["toCtyName"] === data?.toRgnCtyName) {
        updateState("selectedToCountry", {
          id: countryTo[a]["toCtyID"],
          name: countryTo[a]["toCtyName"],
        });
      }
    }
  };

  const checkConnection = () => {
    if (!navigator.onLine) {
      updateState("modalState", {
        state: true,
        title: "No Internet Connection",
        message: "Please check your internet connection",
      });
    }
  };

  const resetFields = () => {
    updateState("active", true);
    updateState("purpose", "");
    updateState("remark", "");
    updateState("route", "");
    updateState("numOfEmployees", "");
    updateState("departureTime", currentDate);
    updateState("returnTime", currentDate);
    updateState("isDriver", false);
    updateState("isVehicle", false);
    dispatch(settask_footer_table_selected([]));

    updateState("selectedFromLocation", {
      id: "",
      name: "",
    });

    updateState("selectedToLocation", {
      id: "",
      name: "",
    });

    updateState("requestArea", {
      id: 0,
      name: "",
    });

    updateState("requestBy", {
      id: 0,
      name: "",
    });

    updateState("requestType", {
      id: 0,
      name: "",
    });

    updateState("recommendedBy", {
      id: 0,
      name: "",
    });

    updateState("selectedFromCountry", {
      id: 0,
      name: "",
    });

    updateState("selectedToCountry", {
      id: 0,
      name: "",
    });
    updateState("selectedFromRegion", {
      name: "",
      id: "",
    });
    updateState("selectedToRegion", {
      name: "",
      id: "",
    });
  };

  const resetFields2 = () => {
    updateState("assignRemarks", "");
    updateState("remarks2", "");
    updateState("debitNotes", "");
    updateState("debitAmount", 1);
    updateState("reminderDate", currentDate);
    dispatch(settask_footer_table_selected([]));
    updateState("selectedRequestClass", {
      id: 0,
      name: "",
    });

    updateState("selectedVehicleno1", {
      id: 0,
      name: "",
    });

    updateState("selectedVehicleno2", {
      id: 0,
      name: "",
    });

    updateState("selectedDriverNo1", {
      id: 0,
      name: "",
    });

    updateState("selectedDriverNo2", {
      id: 0,
      name: "",
    });

    updateState("selectedRequestType", {
      id: 0,
      name: "",
    });
  };

  const [resizeMode, initialresizeMode] = useResizeMode(!!toggled);

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  const form_height = 490;

  const pageHeight = initialresizeMode - (navbar_height + form_height + 70);
  const tableHeight = resizeMode - (heights_out + 20);

  const dateFormat = " DD MMM YYYY HH: mm:ss";

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "formDisabled2", value: true }));
  }, [toggled]);

  const validateApprove = async () => {
    let isValid = true;

    if (!selectedRecord.vrqRecommended) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Request not recommended",
        message:
          "The request has not been recommended yet. Only recommended requests can be approved",
      });
    }
    if (!selectedRecord.vrqVehicleApproved && !selectedRecord.vrqApproved) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Access Denied",
        message:
          "The request have not yet been assigned. You cannot approve it",
      });
    }
    if (AllState.selectedRequestClass.id <= 0) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Request Class is required",
      });
    }
    if (
      AllState.selectedVehicleno1.id <= 0 &&
      selectedRecord.vrqRequestVehicle
    ) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Vehicle is required",
      });
    }
    // if (AllState.selectedDriverNo1.id <= 0 && selectedRecord.vrqRequestDriver) {
    //   updateState("modalState", {
    //     state: true,
    //     title: "Validation Error",
    //     message: "Driver is required",
    //   });
    // }
    if (AllState.selectedRequestType.id <= 0) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Request Type is required",
      });
    }
    if (AllState.debitAmount.id === "") {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Debit Amount is required",
      });
    }
    if (AllState.debitNotes.trim() === "") {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Debit Notes is required",
      });
    }

    if (isValid) {
      if (selectedRecord?.vrqVehicleApproved && !selectedRecord?.vrqApproved) {
        updateState("confirmState", {
          state: true,
          okText: "Yes",
          title: "Approve Request?",
          message: "Are you sure you want to approve this request?",
          event: async () => {
            await ApproveReq();
            updateState("confirmState", {
              state: false,
              okText: "",
              title: "",
              message: "",
              event: () => {},
            });
          },
        });
      }
    }
  };

  const validateAssign = async () => {
    let isValid = true;

    if (!selectedRecord.vrqRecommended) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Request not recommended",
        message:
          "The request has not been recommended yet. You cannot assign it",
      });
    }
    if (selectedRecord.vrqVehicleApproved === true) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Access Denied",
        message: "The request has already been assigned",
      });
    }
    if (AllState.selectedRequestClass.id <= 0) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Request Class is required",
      });
    }
    if (
      AllState.selectedVehicleno1.id <= 0 &&
      selectedRecord.vrqRequestVehicle
    ) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Vehicle is required",
      });
    }
    if (AllState.selectedRequestType.id <= 0) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Request Type is required",
      });
    }
    if (AllState.debitAmount.id === "") {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error",
        message: "Debit Amount is required",
      });
    }

    if (isValid) {
      if (!selectedRecord?.vrqVehicleApproved && !selectedRecord?.vrqApproved) {
        const confirmed = await new Promise((resolve) => {
          updateState("confirmState", {
            state: true,
            okText: "Yes",
            title: "Assign Request?",
            message: "Are you sure you want to assign this request?",
            event: () => {
              resolve(true);
            },
          });
        });

        if (confirmed) {
          await AssignReq();
          updateState("confirmState", {
            state: false,
            okText: "",
            title: "",
            message: "",
            event: () => {},
          });
        }
      }
    }
  };

  const ApproveReq = async () => {
    await updating(
      `AsmTmsVehicleRequest/ApproveVehicleRequest?vrqIDpk=${selectedRecord.vrqIdpk}`,
      {
        vrqApprovalRmks: AllState.remarks2,
        vrqApprovedOn: currentDate,
        vrqEditedDate: currentDate,
      },
      `Successfully approved the Asset Manager-Trans-Vehicle Request with Request Area - ${selectedRecord.vraName}, Request Type - ${selectedRecord.vctName}, Request By - ${selectedRecord.requesterEmpName}, Recommended By - ${selectedRecord.recommendedByEmpName}, Departure Time - ${selectedRecord.vrqDepartureTime}, Return Time - ${selectedRecord.vrqActualReturnTime}, From Location - ${selectedRecord.vrqFromTown}, To Location - ${selectedRecord.vrqToTown}, Route - ${selectedRecord.vrqRoute}, Number of Employees - ${selectedRecord.vrqNoOnTrip}, Purpose - ${selectedRecord.vrqPurpose}, Request Vehicle - ${selectedRecord.vrqRequestVehicle}, Request Driver - ${selectedRecord.vrqRequestDriver}`
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request approved successfully",
        });
        setRefreshx && setRefreshx();
        updateState("refresh", !states.refresh);
        setBusyLoader("");
      })
      .catch((err) => {
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const AssignReq = async () => {
    await updating(
      `AsmTmsVehicleRequest/AssignVehicleRequest?vrqIDpk=${selectedRecord.vrqIdpk}`,
      {
        vrqCategoryIDfk: AllState.selectedRequestType.id,
        vrqRequestClassIDfk: AllState.selectedRequestClass.id,
        vrqApprovedVehicleIDfk: AllState.selectedVehicleno1.id,
        vrqDebitNotes: AllState.debitNotes,
        vrqDebitAmount: AllState.debitAmount,
        vrqDriverApproved: true,
        vrqVehicleApproved: !selectedRecord.vrqVehicleApproved,
        vrqDriverEmpIDfk: AllState.selectedDriverNo1.id,
        vrqAssignmentRmks: AllState.remarks2,
        vrqAssignedOn: currentDate,
        vrqEditedDate: currentDate,
      },
      `Successfully assigned the Asset Manager-Trans-Vehicle Request with Request Area - ${selectedRecord.vraName}, Request Type - ${selectedRecord.vctName}, Request By - ${selectedRecord.requesterEmpName}, Recommended By - ${selectedRecord.recommendedByEmpName}, Departure Time - ${selectedRecord.vrqDepartureTime}, Return Time - ${selectedRecord.vrqActualReturnTime}, From Location - ${selectedRecord.vrqFromTown}, To Location - ${selectedRecord.vrqToTown}, Route - ${selectedRecord.vrqRoute}, Number of Employees - ${selectedRecord.vrqNoOnTrip}, Purpose - ${selectedRecord.vrqPurpose}, Request Vehicle - ${selectedRecord.vrqRequestVehicle}, Request Driver - ${selectedRecord.vrqRequestDriver}`
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request assigned successfully",
        });
        setRefreshx && setRefreshx();
        updateState("refresh", !states.refresh);
        setBusyLoader("");
      })
      .catch((err) => {
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(task_form_disable(false));
      dispatch(task_footer_update(false));
      updateState("showCancelButton", true);
      resetFields();
    } else {
      dispatch(task_form_disable(true));
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  const AccessPriv_on_NewButton2 = () => {
    setPrivType("save");
    if (savePriv === true) {
      if (!selectedRecord.vrqRecommended) {
        updateState("modalState", {
          state: true,
          title: "Request not recommended",
          message: "The request has not been recommended yet",
        });
      } else if (selectedRecord.vrqApproved) {
        updateState("modalState", {
          state: true,
          title: "Request already Approved",
          message: "The request has already been approved",
        });
      } else {
        setAutoShowAssDetails(true);
        resetFields2();
        dispatch(
          setGeneralValue({
            expr: "formDisabled2",
            value: false,
          })
        );
      }
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
      dispatch(
        setGeneralValue({
          expr: "formDisabled2",
          value: true,
        })
      );
    }
  };
  const AccessPriv_on_SubmitButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      validate("post");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  const AccessPriv_on_UpdateButton = () => {
    setPrivType("update");
    if (updatePriv === true) {
      validate("put");

      dispatch(task_form_disable(false));
      dispatch(task_footer_update(true));
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const validateRecommend = async () => {
    let isValid = true;

    if (selectedRecord?.vrqRecommended === true) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Request already recommended",
        message: "The request has already been recommended",
      });
    }
    if (states.debitCategory.name === "") {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Debit category required!",
        message: "Please select the Debit Category",
      });
    }
    if (states.costCenter.name === "") {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Cost Center required!",
        message: "Please select the Cost Center",
      });
    }

    if (isValid) {
      if (selectedRecord?.vrqRecommended === false) {
        updateState("confirmState", {
          state: true,
          okText: "Yes",
          title: "Recommend Request?",
          message: "Are you sure you want to recommend this request?",
          event: async () => {
            await recommendRequest();
            updateState("confirmState", {
              state: false,
              okText: "",
              title: "",
              message: "",
              event: () => {},
            });
          },
        });
      }
    }
  };

  const validateCancelRec = async () => {
    let isValid = true;

    if (
      selectedRecord?.vrqCancelled === true &&
      selectedRecord?.vrqRecommended === false
    ) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Request already cancelled",
        message: "The request has already been cancelled",
      });
    }

    if (
      selectedRecord?.vrqRecommended === false &&
      selectedRecord?.vrqCancelled === false
    ) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Request not recommended!",
        message:
          "The request has not yet been recommended, hence you cannot perform this action",
      });
    }

    if (isValid) {
      if (
        selectedRecord?.vrqRecommended === true &&
        selectedRecord?.vrqCancelled === false
      ) {
        updateState("confirmState", {
          state: true,
          okText: "Yes",
          title: "Cancel Recommendation?",
          message:
            "Are you sure you want to cancel the recommendation of this request?",
          event: async () => {
            await cancelRecommend();
            updateState("confirmState", {
              state: false,
              okText: "",
              title: "",
              message: "",
              event: () => {},
            });
          },
        });
      }
    }
  };

  const validateUndoRec = async () => {
    let isValid = true;

    if (selectedRecord?.vrqCancelled === false) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Validation Error!",
        message:
          "The request has not yet been cancelled, you cannot undo cancel",
      });
    }

    if (selectedRecord.vrqDepartureTime < currentDate) {
      isValid = false;
      updateState("modalState", {
        state: true,
        title: "Invalid Action",
        message:
          "The Departure time has already passed. You cannot activate an expired request. Please alert the requester to initiate a new request",
      });
    }

    if (isValid) {
      if (
        selectedRecord?.vrqRecommended === false &&
        selectedRecord?.vrqCancelled === true
      ) {
        updateState("confirmState", {
          state: true,
          okText: "Yes",
          title: "Undo Cancel?",
          message:
            "Are you sure you want to undo cancellation of this request?",
          event: async () => {
            await undoCancel();
            updateState("confirmState", {
              state: false,
              okText: "",
              title: "",
              message: "",
              event: () => {},
            });
          },
        });
      }
    }
  };

  // Alerts
  const senRecommendSms = async (message) => {
    try {
      checkConnection();
      await posting(
        `SMSAlert/SaveSMS`,
        {
          imsMessage: message,
          imsRecipientEmpIdfk: empID,
          imsSender: userInfo.empName,
          imsRecordIdfk: selectedRecord?.vrqIdpk,
          imsFormCode: "asmTmsVehicleRequest",
          imsMessageType: "string",
          imsMessageClass: "string",
          imsInitiatorEmpIdfk: empID,
          imsCreatedBy: userId,
        },
        `Sent Vehicle Request SMS to Approver ${selectedRecord?.empName} (${selectedRecord?.requesterStaffNo}) for Request with ID - ${selectedRecord?.vrqIdpk}`
      );
    } catch (e) {
      updateState("modalState", {
        state: true,
        title: "Error",
        message: "An error occurred while sending the SMS",
      });
    }
  };

  // Recommend Endpoints
  const recommendRequest = async () => {
    senRecommendSms(
      `Hello ${userInfo.empName}, a personal vehicle request from ${selectedRecord?.requesterEmpName} with ID ${selectedRecord?.vrqIdpk} has been recommended, pending your approval and assignment`
    );
    await PatchDataFunc(`AsmTmsRecommendVehicleRequest/UpdateRecommend`, {
      vrqApprovalRmks: "string",
      vrqDebitCategory: states.debitCategory.name,
      vrqRecommendedRoute: AllState.route,
      vrqCostCenter: states.costCenter.name,
      vrqRecommended: !selectedRecord.vrqRecommended,
      vrqCancelled: selectedRecord.vrqCancelled,
      vrqCostCenterIdfk: 1,
      vrqCancelledOn: dayjs().format(),
      vrqRecommededOn: dayjs().format(),
      vrqIdpk: selectedRecord.vrqIdpk,
      vrqRecommendedByEmpIdfk: empID,
      editedDate: dayjs().format(),
      formCode: "string",
      action: "string",
    })
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request recommended successfully",
        });
        setRefreshx && setRefreshx();
        updateState("refresh", !states.refresh);
        setBusyLoader("");
      })
      .catch((err) => {
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const undoCancel = async () => {
    senRecommendSms(`Hello ${selectedRecord?.requesterEmpName}, your request with ID ${selectedRecord?.vrqIdpk} that was cancelled by ${userInfo.empName} has been restored.
    Sent from Innox`);
    await PatchDataFunc(`AsmTmsRecommendVehicleRequest/UndoRequest`, {
      vrqReactivationRmks: "string",
      reqRequestID: selectedRecord.vrqIdpk,
      formCode: "string",
      action: "string",
    })
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request undone successfully",
        });
        setRefreshx && setRefreshx();
        updateState("refresh", !states.refresh);
        setBusyLoader("");
      })
      .catch((err) => {
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const cancelRecommend = async () => {
    senRecommendSms(`Hello ${selectedRecord?.requesterEmpName}, your request with ID ${selectedRecord?.vrqIdpk} has been cancelled by ${userInfo.empName}.
    Sent from Innox`);
    await PatchDataFunc(`AsmTmsRecommendVehicleRequest/DisApproveRecommend`, {
      vrqCancellationRmks: "string",
      vrqRecommended: !selectedRecord.vrqRecommended,
      vrqCancelled: !selectedRecord.vrqCancelled,
      vrqCancelledByEmpIdfk: empID,
      vrqCancelledOn: dayjs().format(),
      vrqEditedDate: dayjs().format(),
      vrqIdpk: selectedRecord.vrqIdpk,
      formCode: "string",
      action: "string",
    })
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request recommendation cancelled successfully",
        });
        setRefreshx && setRefreshx();
        updateState("refresh", !states.refresh);
        setBusyLoader("");
      })
      .catch((err) => {
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const cancelRequest = async () => {
    await updating(
      `AsmTmsVehicleRequest/CancelVehicleRequest?vrqIDpk=${selectedRecord?.vrqIdpk}`,
      {
        vrqCancellationRmks: states.cancellationReason,
        formCode: "string",
        formAction: "string",
      },
      `Successfully cancelled the Asset Manager-Trans-Vehicle Request with Request Area - ${selectedRecord.vraName}, Request Type - ${selectedRecord.vctName}, Request By - ${selectedRecord.requesterEmpName}, Recommended By - ${selectedRecord.recommendedByEmpName}, Departure Time - ${selectedRecord.vrqDepartureTime}, Return Time - ${selectedRecord.vrqActualReturnTime}, From Location - ${selectedRecord.vrqFromTown}, To Location - ${selectedRecord.vrqToTown}, Route - ${selectedRecord.vrqRoute}, Number of Employees - ${selectedRecord.vrqNoOnTrip}, Purpose - ${selectedRecord.vrqPurpose}, Request Vehicle - ${selectedRecord.vrqRequestVehicle}, Request Driver - ${selectedRecord.vrqRequestDriver}`
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request disapproved successfully",
        });
        setRefreshx && setRefreshx();
        updateState("refresh", !states.refresh);
        resetFields();
        setBusyLoader("");
      })
      .catch((err) => {
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const validateCancel = () => {
    if (
      selectedRecord?.vrqCancelled === false &&
      selectedRecord?.vrqApproved === false
    ) {
      updateState("modalState", {
        state: true,
        message:
          "The request has not yet been approved, you cannot disapprove it",
        title: "Disapproval Failed!",
      });
    } else if (selectedRecord?.vrqCancelled === false) {
      updateState("confirmState", {
        state: true,
        title: "Disapprove Request?",
        message:
          "Are you sure you want to continue and disapprove this request?",
        event: () => {
          cancelRequest();
          updateState("cancellationReason", "");
        },
      });
    }
  };

  const validateCancelBtn = () => {
    if (selectedRecord?.vrqCancelled === true) {
      updateState("modalState", {
        state: true,
        message: "This request has already been disapproved",
        title: "Disapproval Failed!",
      });
    } else {
      updateState("inputModal", { state: true });
    }
  };

  const cancelAccessPriv = () => {
    validateCancel();
    if (savePriv === true) {
      if (selectedRecord === undefined) {
        updateState("modalState", {
          state: true,
          message: "Please select a vehicle Request to disapprove",
          title: "Required",
        });
      } else {
        validateCancelBtn();
      }
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const AccessPriv_on_EditButton2 = () => {
    setPrivType("update");
    if (updatePriv === true) {
      if (Object.keys(selectedRecord).length < 1) {
        updateState("modalState", {
          state: true,
          title: "No record selected",
          message: "Please select a record first",
        });
      } else {
        dispatch(
          setGeneralValue({
            expr: "formDisabled2",
            value: false,
          })
        );
      }
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const AccessPriv_on_Approve_Assign_Button = () => {
    setPrivType("update");
    if (readPriv === true) {
      validateApprove();
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message:
          "The data is in read mode. Please click on the Approval New button and try again.",
      });
    }
  };
  const AccessPriv_on_DataGriddoubleClick = (selectedData: any) => {
    setPrivType("update");
    setVisible && setVisible();
    if (updatePriv === true) {
      //enable form
      if (selectedData?.vrqRecommended && !selectedData?.vrqApproved) {
        updateState("modalState", {
          state: true,
          title: "You Can't Edit this Record",
          message: "This Record has been Recommended",
        });
      } else {
        Row_Double_Click(selectedData);
        dispatch(task_form_disable(false));

        dispatch(task_footer_update(true));
        dispatch(settask_footer_table_selected([selectedData]));
      }
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
      dispatch(task_form_disable(true));

      dispatch(task_footer_update(false));
    }
  };
  const [seeMap, setSeeMap] = useState<boolean>(false);
  const [autoShowAssDetails, setAutoShowAssDetails] = useState(false);

  const dates: datesTypes[] = [
    {
      id: 0,
      label: "Departure Time",
      stateName: "departureTime",
      defaultValue:
        AllState.departureTime === undefined
          ? currentDate
          : dayjs(`${AllState.departureTime}`),
      style: "",
    },
    {
      id: 1,
      label: "Est. Return Time",
      stateName: "returnTime",
      defaultValue:
        AllState.returnTime === undefined
          ? currentDate
          : dayjs(`${AllState.returnTime}`),
      style: "",
    },
  ];

  const debitOps = [
    {
      id: 0,
      name: "Section",
    },
    {
      id: 1,
      name: "Staff",
    },
  ];

  // Recommend Button
  const RecommendForm = () => (
    <Form
      className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
      name="basic"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 10 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      size="small"
      layout="horizontal"
    >
      <section className="w-full h-full">
        <SelectsTemplate
          idexpr={"id"}
          dataexp={"name"}
          label={"Debit Category"}
          useCallFunc={true}
          placeHolder={states.debitCategory.name}
          selectStyle={{ width: "40%" }}
          options={debitOps}
          selectedValue={(value) => {
            inUpdateStates("debitCategory", { id: value.id, name: value.name });
          }}
          disabled={false}
        />

        <SelectsTemplate
          idexpr={"cctIDpk"}
          dataexp={"cctShtName"}
          label={"Cost Center"}
          useCallFunc={true}
          placeHolder={states.costCenter.name}
          selectStyle={{ width: "100%" }}
          options={costCenterData}
          selectedValue={(value) => {
            inUpdateStates("costCenter", {
              id: value.cctIDpk,
              name: value.cctShtName,
            });
          }}
          disabled={false}
        />

        <InputSelect_template
          label={"Route"}
          placeHolder={AllState.route}
          useCallFunc
          disabled={true}
        />

        <TextareaTemplate
          height={100}
          label={"Remarks"}
          defaultValue={AllState.recommendRemarks}
          labelCol={5}
          setCallbackValue={() => {}}
          useCallbackFunc
          disabled={false}
        />

        <section className="w-full flex justify-between ">
          <aside className="w-[42%] flex  justify-end ">
            <CustomizableButton
              isText
              text={"Undo Cancel"}
              classname={" flex p-1 justify-center items-center"}
              customizableBtnImage={checkmark}
              handleCustomizableClick={validateUndoRec}
            />
          </aside>

          <aside className="w-[58%] flex justify-end">
            <Recommend handleRecommend={validateRecommend} />
            <Cancel handleCallbackCancel={validateCancelRec} />
          </aside>
        </section>
      </section>
    </Form>
  );

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Recommend Vehicle Request",
      module: <RecommendForm />,
    },
    {
      id: 1,
      label: "From-Location && To-Location ",
      module: null,
    },
  ];

  const [
    vehicleRequestLookUp,
    vehicleRequestLookupError,
    vehicleRequestLookupLoading,
  ] = useFetch(
    `AsmTmsVehicleRequest/VehicleRequestLookUp/VehicleRequest?pagesize=1&pagenumber=20`
  );

  console.log("vehicleRequestLookUp:", vehicleRequestLookUp);
  console.log("!@#", AllState);
  return (
    <>
      {error && <WarningAlert />}

      <FormModal
        title={formModal[0].label}
        openState={states.openFormModal}
        width={"30%"}
        child={formModal[0].module}
        onCancel={() => {
          inUpdateStates("openFormModal", false);
        }}
      />

      <ModalTemplate
        disableCancel={true}
        title={AllState.modalState.title}
        okText={""}
        message={AllState.modalState.message}
        open={AllState.modalState.state}
        okHandler={() => {
          updateState("modalState", { state: false });
        }}
        cancelHandler={() => {
          updateState("modalState", { state: false });
        }}
      />
      <ModalTemplate
        icon="success"
        disableCancel={true}
        title={AllState.successModal.title}
        okText={""}
        message={AllState.successModal.message}
        open={AllState.successModal.state}
        okHandler={() => {
          updateState("successModal", { state: false });
        }}
        cancelHandler={() => {
          updateState("successModal", { state: false });
        }}
      />

      <ConfirmationModal
        title={AllState.confirmState.title}
        okText={AllState.confirmState.okText}
        message={AllState.confirmState.message}
        open={AllState.confirmState.state}
        onConfirm={AllState.confirmState.event}
        onCancel={() => {
          updateState("confirmState", { state: false });
        }}
      />

      <ModalTemplate
        message="Please enter the reason for disapproving this request"
        icon="warning"
        title="Cancellation Reason"
        open={states.inputModal.state}
        okText={"OK"}
        cancelText={"Cancel"}
        okHandler={() => {
          updateState("inputModal", { state: false });
          updateState("cancellationReason", "");
          cancelAccessPriv();
        }}
        cancelHandler={() => {
          updateState("inputModal", { state: false });
          updateState("cancellationReason", "");
        }}
        withTextarea={true}
        textareaOnchange={(e: any) => {
          updateState("cancellationReason", e);
        }}
        textareaHeight={100}
      />
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />

        {toggled && (
          <div className="flex">
            <div
              style={{ borderColor: borderTheme }}
              className={`${
                selectedRecord !== undefined &&
                selectedRecord.length !== 0 &&
                selectedRecord?.vrqVehicleApproved
                  ? "w-[1500px]  border rounded-md  "
                  : "w-full border rounded-md "
              }`}
            >
              <p
                id={"form_header"}
                style={{ borderColor: borderTheme }}
                className="py-1  pl-4 w-full  border-b-[1px] border-l-1  border-t-0 border-l-0 border-r-0 bg-slate-100 rounded-tl font-medium"
              >
                Requisition Details
              </p>

              {/* The form */}
              <div className="p-2 flex ">
                <Form
                  name="complex-form"
                  labelCol={{
                    span:
                      selectedRecord?.vrqVehicleApproved === true ||
                      selectedRecord === true
                        ? 8
                        : 8,
                  }}
                  // labelCol={{ span:  selectedRecord !== undefined &&
                  //   selectedRecord.length !== 0 &&
                  //   selectedRecord?.vrqVehicleApproved ? 2: 8 }}

                  size={"small"}
                  className=""
                  style={
                    selectedRecord !== undefined &&
                    selectedRecord.length !== 0 &&
                    selectedRecord?.vrqVehicleApproved
                      ? { width: "80%" }
                      : { width: "80%" }
                  }
                >
                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs w-[200px] ">
                        {"Request Area"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState.requestArea.name}
                      handleRefresh={() => {
                        updateState("requestArea", "");
                      }}
                      options={
                        AllState?.requestArea?.name.length > 0
                          ? areas
                          : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestAreas
                      }
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true}
                      cusWidth={"243px"}
                      idexpr={"vraIdpk"}
                      dataexp={"vraName"}
                      isLoading={AllState?.isLoading}
                      isOpenDropdown={AllState?.isOpenDropdown1}
                      selectedValue={(e: any) => {
                        updateState("requestArea", {
                          id: e.vraIdpk,
                          name: e.vraName,
                        });
                        updateState("isOpenDropdown1", false);
                      }}
                      handleFocus={(e: any) => {
                        updateState(
                          "isOpenDropdown1",
                          !AllState?.isOpenDropdown1
                        );
                      }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("requestArea", {
                            ...AllState?.requestArea,
                            name: e,
                          });
                          updateState("isOpenDropdown1", true);
                          dispatch(FetchRequestArea(e) as unknown as any);
                        }, 500) 
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Request By"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState.requestBy.name}
                      handleRefresh={() => {
                        updateState("requestType", "");
                      }}
                      options={
                        AllState?.requestBy?.name.length > 0
                          ? requestBy
                          : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestRequestedBy
                      }
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true}
                      cusWidth={"243px"}
                      idexpr={"empIdpk"}
                      dataexp={"empName"}
                      isLoading={AllState?.isLoading}
                      isOpenDropdown={AllState?.isOpenDropdown2}
                      selectedValue={(e: any) => {
                        updateState("isOpenDropdown2", false);
                        updateState("requestBy", {
                          id: e.empIdpk,
                          name: e.empName,
                        });
                      }}
                      selectStyle={{ overflow: "hidden" }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("requestArea", {
                            ...AllState?.requestBy,
                            name: e,
                          });
                          updateState("isOpenDropdown2", true);
                          dispatch(FetchRequestBy(e) as unknown as any);
                        }, 500) 
                      }
                      handleFocus={() => {
                        updateState(
                          "isOpenDropdown2",
                          !AllState?.isOpenDropdown2
                        );
                      }}
                    />
                  </Form.Item>

                  {dates.map(
                    ({ id, label, defaultValue, stateName, style }) => {
                      return (
                        <DateTemplate
                          label={label}
                          selectedDate={defaultValue}
                          changeDate={(e) => {
                            updateState(stateName!, e);
                          }}
                          disabled={form_disable}
                          width={242}
                          dbFormat={true}
                        />
                      );
                    }
                  )}

                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Request Type"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState.requestType.name}
                      handleRefresh={() => {
                        updateState("requestType", "");
                      }}
                      options={
                        AllState?.requestTypes?.name.length > 0
                          ? requestTypes
                          : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestTypes
                      }
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true}
                      cusWidth={"243px"}
                      idexpr={"vctIdpk"}
                      dataexp={"vctName"}
                      isLoading={AllState?.isLoading}
                      isOpenDropdown={AllState?.isOpenDropdown3}
                      handleFocus={() => {
                        updateState(
                          "isOpenDropdown3",
                          !AllState?.isOpenDropdown3
                        );
                      }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("requestType", {
                            ...AllState?.requestType,
                            name: e,
                          });
                          updateState("isOpenDropdown3", true);
                          dispatch(FetchRequestType(e) as unknown as any);
                        }, 500) 
                      }
                      selectedValue={(e: any) => {
                        updateState("isOpenDropdown3", false);
                        updateState("requestType", {
                          id: e.vctIdpk,
                          name: e.vctName,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Recommended By"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState?.recommendedBy?.name}
                      handleRefresh={() => {
                        updateState("recommendedBy", { id: 0, name: "" });
                      }}
                      options={recommended} // isn't this in the lookup why don't I see a condition here
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true} 
                      cusWidth={"243px"}
                      idexpr={"empIdpk"}
                      dataexp={"empName"}
                      isLoading={AllState?.isLoading}
                      isOpenDropdown={AllState?.isOpenDropdown4}
                      selectedValue={(e: any) => {
                        updateState("recommendedBy", {
                          id: e.empIdpk,
                          name: e.empName,
                        });
                        updateState("isOpenDropdown4", false);
                      }}
                      selectStyle={{ overflow: "hidden" }}
                      handleFocus={() => {
                        updateState(
                          "isOpenDropdown4",
                          !AllState?.isOpenDropdown4
                        );
                      }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("recommendedBy", {
                            ...AllState?.recommendedBy,
                            name: e,
                          });
                          // UpdateState("isLoading", true);
                          dispatch(
                            FetchRecommendedBy({
                              search: e,
                              id: AllState?.requestArea.id,
                            }) as unknown as any
                          );
                          updateState("isOpenDropdown4", true);
                        }, 500) 
                      }
                    />
                  </Form.Item>

                  <div className="my-[1px] ">
                    <Form.Item
                      label={<p className="text-xs">{"Needs"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <div className="flex gap-1">
                        <Form.Item
                          name="year"
                          className=" my-0.5"
                          style={{
                            display: "inline-block",
                            width: "120px",
                          }}
                        >
                          <div
                            style={{
                              borderWidth: "1px",
                              borderColor: borderTheme,
                            }}
                            className=" border-stone-300 rounded px-0.5"
                          >
                            <Tooltip
                              target="#vehicleCheck"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p>Tick this if you will need a vehicle</p>
                            </Tooltip>
                            <Checkbox
                              disabled={form_disable}
                              id="vehicleCheck"
                              checked={AllState.isVehicle}
                              onChange={(e: any) => {
                                updateState("isVehicle", e.target.checked);
                              }}
                            >
                              Vehicle
                            </Checkbox>
                          </div>
                        </Form.Item>
                        <Form.Item
                          labelCol={{ span: 10 }}
                          name="month"
                          // className="px-5"
                          style={{
                            display: "inline-block",
                            width: "120px",
                            // marginLeft: "2%",
                          }}
                        >
                          <div
                            style={{
                              borderWidth: "1px",
                              // width: "80%",
                              borderColor: borderTheme,
                            }}
                            className=" border-stone-300 rounded px-0.5 "
                          >
                            <Tooltip
                              target="#driverCheck"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p>Tick this if you will need a driver</p>
                            </Tooltip>
                            <Checkbox
                              disabled={form_disable}
                              id="driverCheck"
                              checked={AllState.isDriver}
                              onChange={(e: any) => {
                                updateState("isDriver", e.target.checked);
                              }}
                            >
                              Driver
                            </Checkbox>
                          </div>
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </div>

                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"From - Country"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState?.selectedFromCountry?.name}
                      handleRefresh={() => {
                        updateState("selectedFromCountry", "");
                      }}
                      options={
                        AllState?.selectedFromCountry?.name.length > 0
                          ? countryFrom
                          : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestFromCountry
                      }
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true}
                      cusWidth={"243px"}
                      idexpr={"fromCtyID"}
                      dataexp={"fromCtyName"}
                      isLoading={AllState.isLoading}
                      isOpenDropdown={AllState.isOpenDropdown5}
                      handleFocus={() => {
                        updateState(
                          "isOpenDropdown5",
                          !AllState?.isOpenDropdown5
                        );
                      }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("selectedFromCountry", {
                            ...AllState?.selectedFromCountry,
                            name: e,
                          });
                          // UpdateState("isLoading", true);
                          dispatch(FetchFrom_country(e) as unknown as any);

                          updateState("isOpenDropdown5", true);
                        }, 500) 
                      }
                      selectedValue={(e: any) => {
                        updateState("isOpenDropdown5", false);
                        updateState("selectedFromCountry", {
                          name: e.fromCtyName,
                          id: e.fromCtyID,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"From - Region"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState.selectedFromCountry.name}
                      handleRefresh={() => {
                        updateState("selectedFromRegion", "");
                      }}
                      options={fromRegion}
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true}
                      cusWidth={"243px"}
                      idexpr={"fromCtyID"}
                      dataexp={"fromRgnName"}
                      isLoading={AllState?.isLoading}
                      isOpenDropdown={AllState?.isOpenDropdown6}
                      handleFocus={() => {
                        updateState(
                          "isOpenDropdown6",
                          !AllState.isOpenDropdown6
                        );
                      }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("selectedFromRegion", {
                            ...AllState?.selectedFromCountry,
                            name: e,
                          });
                          // UpdateState("isLoading", true);
                          dispatch(
                            FetchFrom_region({
                              search: e,
                              id: AllState?.selectedFromCountry.id,
                            }) as unknown as any
                          );

                          updateState("isOpenDropdown6", true);
                        }, 500) 
                      }
                      selectedValue={(e: any) => {
                        updateState("isOpenDropdown6", false);
                        updateState("selectedFromRegion", {
                          name: e.fromRgnName,
                          id: e.fromRgnID,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"From - Location"}
                      </p>
                    }
                  >
                    <InputSelect_template
                      placeHolder={AllState?.selectedFromLocation?.name}
                      handleRefresh={() => {
                        updateState("selectedFromLocation", "");
                      }}
                      options={fromLocation}
                      disabled={form_disable}
                      useCallFunc={true}
                      disabledCustomWidth={true}
                      cusWidth={"243px"}
                      idexpr={"vrqIDpk"}
                      dataexp={"vrqFromTown"}
                      selectStyle={{ overflow: "hidden" }}
                      isLoading={AllState.isLoading}
                      isOpenDropdown={AllState.isOpenDropdown7}
                      handleFocus={() => {
                        updateState(
                          "isOpenDropdown7",
                          !AllState.isOpenDropdown7
                        );
                      }}
                      handleSearch={ 
                        debounce((e) => {
                          updateState("selectedFromLocation", {
                            ...AllState?.selectedFromLocation,
                            name: e,
                          });
                          // UpdateState("isLoading", true);
                          dispatch(
                            FetchFrom_location({
                              search: e,
                              countryID:
                                AllState?.AllState.selectedFromCountry.id,
                              regionID: AllState.selectedFromRegion.id,
                            }) as unknown as any
                          );
                          updateState("isOpenDropdown7", true);
                        }, 500) 
                      }
                      selectedValue={(e: any) => {
                        updateState("isOpenDropdown7", false);
                        updateState("selectedFromLocation", {
                          name: e.vrqFromTown,
                          id: e.vrqIDpk,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Route"}</p>}
                    style={{ marginTop: 3 }}
                  >
                    <Form.Item
                      style={
                        selectedRecord !== undefined &&
                        selectedRecord.length !== 0 &&
                        selectedRecord?.vrqVehicleApproved
                          ? {
                              display: "inline-block",
                              marginBottom: "0px",
                              width: "calc(275%)",
                            }
                          : {
                              display: "inline-block",
                              marginBottom: "0px",
                              width: "calc(275%)",
                            }
                      }
                      className=" mb-1 mr-2 "
                    >
                      <TextArea
                        readOnly={form_disable}
                        value={AllState.route}
                        onChange={(e: any) => {
                          updateState("route", e.target.value);
                        }}
                        style={{
                          resize: "none",
                          maxHeight: "30px",
                          minHeight: "30px",
                          height: "30px",
                          width: "100%",
                        }}
                        className="o  overflow-y-scroll resize-none"
                        rows={3}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Purpose"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      style={
                        selectedRecord !== undefined &&
                        selectedRecord.length !== 0 &&
                        selectedRecord?.vrqVehicleApproved
                          ? {
                              display: "inline-block",
                              marginBottom: "0px",
                              width: "calc(275%)",
                            }
                          : {
                              display: "inline-block",
                              marginBottom: "0px",
                              width: "calc(275%)",
                            }
                      }
                      className=" mb-1 mr-2 "
                    >
                      <TextArea
                        readOnly={form_disable}
                        value={AllState.purpose}
                        onChange={(e: any) => {
                          updateState("purpose", e.target.value);
                        }}
                        style={{
                          resize: "none",
                          maxHeight: "30px",
                          minHeight: "30px",
                          height: "30px",
                          width: "100%",
                        }}
                        className="o  overflow-y-scroll resize-none"
                        rows={3}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Remarks"}</p>}
                    style={{ marginBottom: 0, marginTop: "1px" }}
                  >
                    <Form.Item
                      style={
                        selectedRecord !== undefined &&
                        selectedRecord.length !== 0 &&
                        selectedRecord?.vrqVehicleApproved
                          ? {
                              display: "inline-block",
                              marginBottom: "0px",
                              width: "calc(275%)",
                            }
                          : {
                              display: "inline-block",
                              marginBottom: "0px",
                              width: "calc(275%)",
                            }
                      }
                      className=" mb-1 mr-2 "
                    >
                      <TextArea
                        readOnly={form_disable}
                        value={AllState.remark}
                        onChange={(e: any) => {
                          updateState("remark", e.target.value);
                        }}
                        style={{
                          resize: "none",
                          maxHeight: "40px",
                          minHeight: "40px",
                          height: "40px",
                          width: "",
                        }}
                        className="o  overflow-y-scroll resize-none"
                        rows={3}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    style={{ display: "inline-block", marginBottom: "2px" }}
                    label={<p className="text-xs">{"Active"}</p>}
                    className="w-full mb-1"
                  >
                    <div className="items-end">
                      <div
                        className={`${
                          selectedRecord !== undefined &&
                          selectedRecord.length !== 0 &&
                          selectedRecord?.vrqVehicleApproved
                            ? ""
                            : ""
                        } `}
                        style={
                          selectedRecord !== undefined &&
                          selectedRecord.length !== 0 &&
                          selectedRecord?.vrqVehicleApproved
                            ? { marginBottom: "1px", width: "calc(275%)" }
                            : { marginBottom: "1px", width: "calc(275%)" }
                        }
                      >
                        <div className="flex-1 flex justify-between">
                          <div className="flex items-center">
                            <li
                              style={{ borderColor: borderTheme }}
                              id="status"
                              className="hover:cursor-pointer mr-1 border-slate-200 text-xs text-gray-600 rounded flex items-center"
                            >
                              <Checkbox
                                disabled={form_disable}
                                className="border-[1px] rounded px-1"
                                checked={AllState.active}
                                onChange={(e: any) => {
                                  updateState("active", e.target.checked);
                                }}
                              />
                            </li>
                            {selectedRecord?.recommendedByEmpName ===
                              userInfo.empName && (
                              <CustomizableButton
                                isText
                                text={"Recommend"}
                                classname={`h-${
                                  !heightChange ? 8 : height
                                } flex flex-row items-center justify-center ml-6 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base`}
                                customizableBtnImage={checkedCircle}
                                customizableBtnImageClassname={"pr-1"}
                                handleCustomizableClick={() => {
                                  inUpdateStates("openFormModal", true);
                                }}
                              />
                            )}
                          </div>

                          <div className="flex items-center space-x-2">
                            {updateMode === true ? (
                              <UpdateButton
                                handleUpdate={() => {
                                  AccessPriv_on_UpdateButton();
                                }}
                              />
                            ) : (
                              <SubmitButton
                                heightChange
                                disableButton={form_disable}
                                height={8}
                                handleSubmit={() => {
                                  if (!form_disable) {
                                    AccessPriv_on_SubmitButton();
                                  }
                                }}
                              />
                            )}
                            {form_disable === false ? (
                              <Cancel
                                cancel_button_toggler={() => {
                                  dispatch(
                                    setGeneralValue({
                                      expr: "formDisabled",
                                      value: true,
                                    })
                                  );
                                  dispatch(task_form_disable(true));
                                  dispatch(task_footer_update(false));
                                  resetFields();
                                }}
                              />
                            ) : (
                              <NewButton
                                heightChange
                                useCallbackFunc={true}
                                height={0}
                                new_button_toggler={() => {
                                  AccessPriv_on_NewButton();
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </Form>

                <Form
                  {...formPropsConst}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 20 }}
                  className=" "
                  style={{ width: "100%" }}
                >
                  <div className="invisible">
                    <Form.Item
                      label={<p className="text-xs">{"Chainage"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                          marginBottom: "1px",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DateTemplate
                            datePlaceholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>
                  </div>
                  <div className="invisible ">
                    <Form.Item
                      label={<p className="text-xs">{"Chainage"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DateTemplate
                            datePlaceholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>
                  </div>

                  <div className="" style={{ width: "50%" }}>
                    <Form.Item
                      label={
                        <p id="duration" className="text-xs text-blue-600">
                          {"*Duration"}
                        </p>
                      }
                      style={{ marginBottom: 0 }}
                    >
                      <Tooltip
                        target="#duration"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Trip duration</p>
                      </Tooltip>
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center text-blue-600">
                          <p>
                            {Math.floor(
                              dayjs(AllState.returnTime).diff(
                                dayjs(AllState.departureTime),
                                "day",
                                true
                              )
                            )}{" "}
                            day(s)
                          </p>
                        </div>
                      </Form.Item>
                    </Form.Item>
                  </div>
                  <div className="invisible">
                    <Form.Item
                      label={<p className="text-xs">{"Chainage"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DateTemplate
                            datePlaceholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>
                  </div>
                  <div className="invisible">
                    <Form.Item
                      label={<p className="text-xs">{"Chainage"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DateTemplate
                            datePlaceholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>
                  </div>
                  {/* <div className="pr-0.5 ml-[50%] bg-purple-400">
                    <Tag
                      icon={<EnvironmentOutlined />}
                      color="purple"
                      className="cursor-pointer"
                      onClick={() => {
                        setSeeMap(!seeMap);
                      }}
                    >
                      See Location on Map{" "}
                    </Tag>
                  </div> */}
                  <div
                    className="mt-3 ml-1"
                    // style={
                    //   selectedRecord !== undefined &&
                    //     selectedRecord.length !== 0 &&
                    //     selectedRecord?.vrqVehicleApproved
                    //     ? { width: "100%" }
                    //     : { width: "100%" }
                    // }
                  >
                    <InputsTemplate
                      label={"No of Staff (exd Driv)"}
                      labelColPan={8}
                      disabledStatus={form_disable}
                      numberOnly={true}
                      useCallbackFunc
                      orderOnchange={(e: any) => {
                        updateState("numOfEmployees", e);
                      }}
                      span
                    />

                    <Form.Item
                      labelCol={{ span: 8 }}
                      label={
                        <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                          {"To - Country"}
                        </p>
                      }
                    >
                      <InputSelect_template
                        placeHolder={AllState.selectedToCountry.name}
                        handleRefresh={() => {
                          updateState("selectedToCountry", "");
                        }}
                        options={
                          AllState.selectedToCountry?.name.length > 0
                            ? countryTo
                            : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestToCountry
                        }
                        disabled={form_disable}
                        useCallFunc={true}
                        disabledCustomWidth={true}
                        cusWidth={"213px"}
                        idexpr={"toCtyID"}
                        dataexp={"toCtyName"}
                        isLoading={AllState.isLoading}
                        isOpenDropdown={AllState.isOpenDropdown8}
                        handleFocus={() => {
                          updateState(
                            "isOpenDropdown8",
                            !AllState?.isOpenDropdown8
                          );
                        }}
                        handleSearch={ 
                          debounce((e) => {
                            updateState("selectedToCountry", {
                              ...AllState?.selectedToCountry,
                              name: e,
                            });
                            // UpdateState("isLoading", true);
                            dispatch(FetchTo_country(e) as unknown as any);
                            updateState("isOpenDropdown8", true);
                          }, 500) 
                        }
                        selectedValue={(e: any) => {
                          updateState("isOpenDropdown8", false);
                          updateState("selectedToCountry", {
                            name: e.toCtyName,
                            id: e.toCtyID,
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 8 }}
                      label={
                        <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                          {"To - Region"}
                        </p>
                      }
                    >
                      <InputSelect_template
                        placeHolder={AllState.selectedToRegion.name}
                        handleRefresh={() => {
                          updateState("selectedToRegion", "");
                        }}
                        options={toRegion}
                        selectStyle={{ overflow: "hidden" }}
                        disabled={form_disable}
                        useCallFunc={true}
                        disabledCustomWidth={true}
                        cusWidth={"213px"} //TODO: width changes when sidebar is out typeof: bug
                        idexpr={"toRgnID"}
                        dataexp={"toRgnName"}
                        isLoading={AllState?.isLoading}
                        isOpenDropdown={AllState?.isOpenDropdown9}
                        selectedValue={(e: any) => {
                          updateState("isOpenDropdown9", false);
                          updateState("selectedToRegion", {
                            name: e.toRgnName,
                            id: e.toRgnID,
                          });
                        }}
                        handleFocus={() => {
                          updateState(
                            "isOpenDropdown9",
                            !AllState?.isOpenDropdown9
                          );
                        }}
                        handleSearch={
                          debounce((e) => {
                            updateState("selectedToRegion", {
                              ...AllState?.selectedToRegion,
                              name: e,
                            });
                            // UpdateState("isLoading", true);
                            dispatch(
                              FetchTo_region({
                                search: e,
                                id: AllState?.selectedToCountry.id,
                              }) as unknown as any
                            );
                            updateState("isOpenDropdown8", true);
                          }, 500)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 8 }}
                      label={
                        <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs ">
                          {"To - Location"}
                        </p>
                      }
                    >
                      <InputSelect_template
                        placeHolder={AllState.selectedToLocation.name}
                        handleRefresh={() => {
                          updateState("selectedToLocation", "");
                        }}
                        options={toLocation}
                        disabled={form_disable}
                        useCallFunc={true}
                        disabledCustomWidth={true}
                        cusWidth={"213px"}
                        idexpr={"vrqIDpk"}
                        dataexp={"vrqToTown"}
                        isLoading={AllState?.Loading}
                        isOpenDropdown={AllState?.isOpenDropdown10}
                        handleFocus={() => {
                          updateState(
                            "isOpenDropdown10",
                            !AllState?.isOpenDropdown10
                          );
                        }}
                        handleSearch={
                          debounce((e) => {
                            updateState("selectedToLocation", {
                              ...AllState?.selectedToLocation,
                              name: e,
                            });
                            // UpdateState("isLoading", true);
                            dispatch(
                              FetchTo_location({
                                search: e,
                                countryID: AllState.selectedToCountry.id,
                                regionID: AllState.selectedToRegion.id,
                              }) as unknown as any
                            );

                            updateState("isOpenDropdown10", true);
                          }, 500)
                        }
                        selectedValue={(e: any) => {
                          updateState("isOpenDropdown10", false);
                          updateState("selectedToLocation", {
                            name: e.vrqToTown,
                            id: e.vrqIDpk,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>

            {selectedRecord !== undefined &&
              Object.keys(selectedRecord).length !== 0 &&
              selectedRecord?.vrqRecommended && (
                <div className="w-full ml-3 border rounded-md  border-b-[1px] border-l-[1px] border-t-[1px] border-r-[1px]">
                  <div className="text-xs bg-slate-100 rounded-t-md "></div>
                  <div className=" flex justify-between  h-[24px] pl-4 w-full bg-slate-100 rounded-tl ">
                    <div className="w-1/2 flex item-center">
                      <p className="font-medium mt-0.5">
                        Requisition Approvals
                      </p>
                    </div>
                    <div className="flex w-1/2 h-7 items-center space-x-1 justify-end px-1  border-b-[1px] border-l-[1px] border-t-[1px]  border-r-[1px] ">
                      <CheckboxTemlate
                        withBorders
                        customDisabled={false}
                        defaultValue={autoShowAssDetails}
                        setCheckboxOnchange={(val) => {
                          setAutoShowAssDetails(val);
                        }}
                        useCallbackFunc={true}
                      />
                      <p className="flex items-center">
                        Auto Show Assignment Details
                      </p>
                    </div>
                  </div>
                  <div className="py-2 flex ">
                    <Form
                      name="complex-form"
                      labelCol={{ span: 8 }}
                      size={"small"}
                      className="ml- "
                      style={{ width: "46%" }}
                    >
                      <Form.Item
                        label={
                          <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs ">
                            {"Request Class"}
                          </p>
                        }
                      >
                        <div className="flex flex-row items-center w-full">
                          <InputSelect_template
                            placeHolder={
                              autoShowAssDetails === false
                                ? " "
                                : AllState.selectedRequestClass.name
                            }
                            disabled={form_disable2}
                            idexpr="vrcIdpk"
                            dataexp="vrcName"
                            useCallFunc
                            handleRefresh={() => {
                              updateState("selectedRequestClass", "");
                            }}
                            selectStyle={{ width: 200 }}
                            useCusWidth
                            // cusWidth={100}
                            // disabledCustomWidth
                            options={requestClasses}
                            isLoading={AllState?.isLoading}
                            isOpenDropdown={AllState?.isOpenDropdown11}
                            handleSearch={
                              // useCallback(
                              debounce((e) => {
                                updateState("selectedRequestClass", {
                                  ...AllState?.selectedRequestClass,
                                  name: e,
                                });
                                // UpdateState("isLoading", true);
                                updateState("isOpenDropdown11", true);
                                dispatch(
                                  FetchRequestClass(e) as unknown as any
                                );
                              }, 500) // Adjust the debounce delay as needed
                              // []
                              // )
                            }
                            handleFocus={() => {
                              updateState(
                                "isOpenDropdown11",
                                !AllState?.isOpenDropdown11
                              );
                            }}
                            selectedValue={(e) => {
                              updateState("isOpenDropdown11", false);
                              updateState("selectedRequestClass", {
                                name: JSON.parse(e).vrcName,
                                id: JSON.parse(e).vrcIdpk,
                              });
                            }}
                          />
                          {/* <Select
                            value={
                              autoShowAssDetails === false
                                ? " "
                                : AllState.selectedRequestClass.name
                            }
                            key={AllState.selectedRequestClass.id}
                            disabled={form_disable2}
                            onChange={(e: any) => {
                              updateState("selectedRequestClass", {
                                name: JSON.parse(e).vrcName,
                                id: JSON.parse(e).vrcIdpk,
                              });
                            }}
                          >
                            {requestClasses?.map(
                              (option: any, index: number) => {
                                return (
                                  <option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["vrcName"!]}`}
                                  </option>
                                );
                              }
                            )}
                          </Select> */}

                          {/* {
                            <span
                              style={{
                                borderColor: borderTheme,
                                height: 22.5,
                                marginBottom: 2,
                                borderWidth: 1,
                                width: 24.9,
                              }}
                              className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                              onClick={() => {
                                updateState("selectedRequestClass", "");
                              }}
                            >
                              <img
                                className="w-full h-full p-0.5 flex items-center justify-center"
                                src={reset}
                                alt="reset"
                              />
                            </span>
                          } */}
                        </div>
                      </Form.Item>

                      <Form.Item
                        label={
                          <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                            {"Vehicle No 1"}
                          </p>
                        }
                      >
                        <div className="flex flex-row items-center">
                          <InputSelect_template
                            placeHolder={
                              autoShowAssDetails === false
                                ? " "
                                : AllState?.selectedVehicleno1?.name
                            }
                            disabled={form_disable2}
                            idexpr="vdtIdpk"
                            dataexp="vdtRegNo"
                            useCallFunc
                            options={
                              AllState?.selectedVehicleno1?.name.length > 0
                                ? vehicleNo
                                : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestVehicles
                            }
                            handleRefresh={() => {
                              updateState("selectedVehicleno1", "");
                            }}
                            selectStyle={{ width: 200 }}
                            isLoading={AllState?.isLoading}
                            isOpenDropdown={AllState?.isOpenDropdown12}
                            handleSearch={
                              // useCallback(
                              debounce((e) => {
                                updateState("selectedVehicleno1", {
                                  ...AllState?.selectedVehicleno1,
                                  name: e,
                                });
                                //     // UpdateState("isLoading", true);
                                dispatch(FetchVehicle_No1(e) as unknown as any);
                                updateState("isOpenDropdown12", true);
                              }, 500) // Adjust the debounce delay as needed
                              // []
                              // )
                            }
                            handleFocus={() => {
                              updateState(
                                "isOpenDropdown12",
                                !AllState?.isOpenDropdown12
                              );
                            }}
                            selectedValue={(e) => {
                              updateState("isOpenDropdown12", false);
                              updateState("selectedVehicleno1", {
                                name: JSON.parse(e).vdtRegNo,
                                id: JSON.parse(e).vdtIdpk,
                              });
                            }}
                          />
                          {/* <Select
                            value={
                              !autoShowAssDetails
                                ? " "
                                : AllState.selectedVehicleno1.name
                            }
                            key={AllState.selectedVehicleno1.name}
                            disabled={form_disable2}
                            onChange={(e: any) => {
                              updateState("selectedVehicleno1", {
                                name: JSON.parse(e).vdtRegNo,
                                id: JSON.parse(e).vdtIdpk,
                              });
                            }}
                          >
                            {vehicleNo?.map((option: any, index: number) => {
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(option)}
                                >
                                  {`${option["vdtRegNo"!]}`}
                                </option>
                              );
                            })}
                          </Select> */}

                          {/* {
                            <span
                              style={{
                                borderColor: borderTheme,
                                height: 22.5,
                                marginBottom: 2,
                                borderWidth: 1,
                                width: 24.9,
                              }}
                              className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                              onClick={() => {
                                updateState("selectedVehicleno1", "");
                              }}
                            >
                              <img
                                className="w-full h-full p-0.5 flex items-center justify-center"
                                src={reset}
                                alt="reset"
                              />
                            </span>
                          }*/}
                        </div>
                      </Form.Item>

                      <Form.Item
                        label={
                          <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                            {"Driver 1"}
                          </p>
                        }
                      >
                        <div className="flex flex-row items-center">
                          <InputSelect_template
                            placeHolder={
                              autoShowAssDetails === false
                                ? " "
                                : AllState?.selectedDriverNo1?.name
                            }
                            disabled={form_disable2}
                            idexpr="empIdpk"
                            dataexp="empName"
                            useCallFunc
                            options={drivers}
                            selectStyle={{ width: 200 }}
                            isLoading={AllState?.isLoading}
                            isOpenDropdown={AllState?.isOpenDropdown13}
                            handleRefresh={() => {
                              updateState("selectedDriverNo1", "");
                            }}
                            handleFocus={() => {
                              updateState(
                                "isOpenDropdown13",
                                !AllState?.isOpenDropdown13
                              );
                            }}
                            handleSearch={
                              // useCallback(
                              debounce((e) => {
                                updateState("selectedDriverNo1", {
                                  ...AllState.selectedDriverNo1,
                                  name: e,
                                });
                                // UpdateState("isLoading", true);
                                dispatch(FetchDriver_No1(e) as unknown as any);

                                updateState("isOpenDropdown13", true);
                              }, 500) // Adjust the debounce delay as needed
                              // []
                              // )
                            }
                            selectedValue={(e) => {
                              updateState("isOpenDropdown13", false);
                              updateState("selectedDriverNo1", {
                                name: JSON.parse(e).empName,
                                id: JSON.parse(e).empIdpk,
                              });
                            }}
                          />
                          {/* <Select
                            value={
                              !autoShowAssDetails
                                ? " "
                                : AllState.selectedDriverNo1.name
                            }
                            key={AllState.selectedDriverNo1.name}
                            disabled={form_disable2}
                            onChange={(e: any) => {
                              updateState("selectedDriverNo1", {
                                name: JSON.parse(e).empName,
                                id: JSON.parse(e).empIdpk,
                              });
                            }}
                          >
                            {drivers?.map((option: any, index: number) => {
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(option)}
                                >
                                  {`${option["empName"!]}`}
                                </option>
                              );
                            })}
                          </Select> */}

                          {/* {
                            <span
                              style={{
                                borderColor: borderTheme,
                                height: 22.5,
                                marginBottom: 2,
                                borderWidth: 1,
                                width: 24.9,
                              }}
                              className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                              onClick={() => {
                                updateState("selectedDriverNo1", "");
                              }}
                            >
                              <img
                                className="w-full h-full p-0.5 flex items-center justify-center"
                                src={reset}
                                alt="reset"
                              />
                            </span>
                          } */}
                        </div>
                      </Form.Item>

                      <Form.Item
                        label={
                          <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                            {"Request Type"}
                          </p>
                        }
                      >
                        <div className="flex flex-row items-center">
                          <InputSelect_template
                            placeHolder={
                              autoShowAssDetails === false
                                ? " "
                                : AllState?.selectedRequestType?.name
                            }
                            disabled={form_disable2}
                            idexpr="vctIdpk"
                            dataexp="vctName"
                            useCallFunc
                            options={
                              AllState?.selectedRequestType?.name.length > 0
                                ? requestTypes
                                : vehicleRequestLookUp?.getAllAsmTmsVehicleApproveRequestTypes
                            }
                            selectStyle={{ width: 200 }}
                            isOpenDropdown={AllState?.isOpenDropdown14}
                            isLoading={AllState?.isLoading}
                            handleRefresh={() => {
                              updateState("selectedRequestType", "");
                            }}
                            handleFocus={() => {
                              updateState(
                                "isOpenDropdown14",
                                !AllState?.isOpenDropdown14
                              );
                            }}
                            handleSearch={
                              // useCallback(
                              debounce((e) => {
                                updateState("selectedRequestType", e);
                                // UpdateState("isLoading", true);
                                dispatch(FetchRequestType(e) as unknown as any);
                                updateState("isOpenDropdown14", true);
                              }, 500) // Adjust the debounce delay as needed
                              // []
                              // )
                            }
                            selectedValue={(e) => {
                              updateState("isOpenDropdown14", false);
                              updateState("selectedRequestType", {
                                name: JSON.parse(e).vctName,
                                id: JSON.parse(e).vctIdpk,
                              });
                            }}
                          />
                          {/* <Select
                            value={
                              !autoShowAssDetails
                                ? " "
                                : AllState.selectedRequestType.name
                            }
                            key={AllState.selectedRequestType.id}
                            disabled={form_disable2}
                            onChange={(e: any) => {
                              updateState("selectedRequestType", {
                                name: JSON.parse(e).vctName,
                                id: JSON.parse(e).vctIdpk,
                              });
                            }}
                          >
                            {requestTypes?.map((option: any, index: number) => {
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(option)}
                                >
                                  {`${option["vctName"!]}`}
                                </option>
                              );
                            })}
                          </Select> */}

                          {/* {
                            <span
                              style={{
                                borderColor: borderTheme,
                                height: 22.5,
                                marginBottom: 2,
                                borderWidth: 1,
                                width: 24.9,
                              }}
                              className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                              onClick={() => {
                                updateState("selectedRequestType", "");
                              }}
                            >
                              <img
                                className="w-full h-full p-0.5 flex items-center justify-center"
                                src={reset}
                                alt="reset"
                              />
                            </span>
                          } */}
                        </div>
                      </Form.Item>

                      <InputsTemplate
                        readOnly={form_disable2}
                        disabledStatus={form_disable2}
                        placeholder={AllState.debitAmount}
                        inputType="number"
                        label="Debit Amount"
                        defaultValue={
                          !autoShowAssDetails ? " " : AllState.debitAmount
                        }
                        orderOnchange={(val) => {
                          updateState("debitAmount", val);
                        }}
                      />

                      <div
                        style={{
                          width: "203%",
                          marginLeft: "12%",
                          paddingTop: "1%",
                        }}
                      >
                        <TextareaTemplate
                          label={"Debit Note"}
                          height={74}
                          readonly={form_disable2}
                          disabled={form_disable2}
                          useCallbackFunc
                          setCallbackValue={(e: any) => {
                            updateState("debitNotes", e);
                          }}
                          defaultValue={
                            !autoShowAssDetails ? " " : AllState.debitNotes
                          }
                        />
                      </div>
                      <div style={{ width: "211%", marginLeft: "4%" }}>
                        <TextareaTemplate
                          label={"Assign. Remarks"}
                          height={75}
                          disabled={form_disable2}
                          readonly={form_disable2}
                          useCallbackFunc
                          setCallbackValue={(e: any) => {
                            updateState("assignRemarks", e);
                          }}
                          defaultValue={
                            !autoShowAssDetails ? " " : AllState.assignRemarks
                          }
                        />
                      </div>

                      <div style={{ width: "214%", marginLeft: "3.2px" }}>
                        <TextareaTemplate
                          label={"Approval Remarks"}
                          height={75}
                          disabled={form_disable2}
                          readonly={form_disable2}
                          useCallbackFunc
                          setCallbackValue={(e: any) => {
                            updateState("remarks2", e);
                          }}
                          defaultValue={
                            !autoShowAssDetails ? " " : AllState.remarks2
                          }
                        />
                      </div>

                      <div className=" items-end">
                        <div
                          className=" flex flex-row justify-between"
                          style={{
                            marginBottom: "1px",
                            width: "calc(182%)",
                            marginLeft: "33%",
                          }}
                        >
                          <div className="flex flex-row justify-between">
                            <button
                              onClick={(e) => {
                                cancelAccessPriv();
                              }}
                              className="hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                            >
                              <img alt="" className="pr-1" src={cancelCircle} />
                              Disapprove
                            </button>

                            {selectedRecord?.vrqVehicleApproved &&
                            !selectedRecord?.vrqApproved ? (
                              <button
                                onClick={(e) => {
                                  AccessPriv_on_Approve_Assign_Button();
                                }}
                                className=" h-8 hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                              >
                                <img
                                  alt=""
                                  className="pr-1"
                                  src={checkedCircle}
                                />
                                Approve
                              </button>
                            ) : !selectedRecord?.vrqVehicleApproved &&
                              !selectedRecord?.vrqApproved ? (
                              <button
                                onClick={(e) => {
                                  validateAssign();
                                }}
                                className=" h-8 hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                              >
                                <img
                                  alt=""
                                  className="pr-1"
                                  src={checkedCircle}
                                />
                                Assign
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          <ul className="flex ml-5">
                            <div>
                              <button
                                onClick={(e) => {
                                  AccessPriv_on_EditButton2();
                                }}
                                disabled={false}
                                className="hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                              >
                                <img alt="" className="pr-1" src={editFile} />
                                Edit
                              </button>
                            </div>

                            {form_disable2 === true ? (
                              <button
                                onClick={() => {
                                  AccessPriv_on_NewButton2();
                                }}
                                className=" h-8 flex hover:border-blue-200 flex-row items-center justify-center ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                              >
                                <img alt="" className="pr-1" src={newIcon} />
                                New
                              </button>
                            ) : (
                              <Cancel
                                useCallbackFunc
                                cancel_button_toggler={() => {
                                  dispatch(
                                    setGeneralValue({
                                      expr: "formDisabled2",
                                      value: true,
                                    })
                                  );
                                }}
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                    </Form>

                    <Form
                      name="complex-form"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 20 }}
                      size={"small"}
                      className="ml-2 "
                      style={{ width: "25%" }}
                    >
                      <div className="mt-8" style={{ width: "200%" }}>
                        <Form.Item
                          label={
                            <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                              {"Veh No 2"}
                            </p>
                          }
                        >
                          <div className="flex flex-row items-center">
                            <InputSelect_template
                              placeHolder={
                                autoShowAssDetails === false
                                  ? " "
                                  : AllState.selectedVehicleno2.name
                              }
                              disabled={form_disable2}
                              idexpr="vdtIdpk"
                              dataexp="vdtRegNo"
                              useCallFunc
                              options={
                                AllState?.selectedVehicleno2?.name.length > 0
                                  ? vehicleNo
                                  : vehicleRequestLookUp?.getAllAsmTmsVehicleRequestVehicles
                              }
                              selectStyle={{ width: 200 }}
                              isLoading={AllState?.isLoading}
                              isOpenDropdown={AllState?.isOpenDropdown15}
                              handleRefresh={() => {
                                updateState("selectedVehicleno2", "");
                              }}
                              handleFocus={() => {
                                updateState(
                                  "isOpenDropdown15",
                                  !AllState?.isOpenDropdown15
                                );
                              }}
                              handleSearch={
                                // useCallback(
                                debounce((e) => {
                                  updateState("selectedVehicleno2", e);
                                  // UpdateState("isLoading", true);
                                  dispatch(
                                    FetchVehicle_No2(e) as unknown as any
                                  );

                                  updateState("isOpenDropdown15", true);
                                }, 500) // Adjust the debounce delay as needed
                                // []
                                // )
                              }
                              selectedValue={(e) => {
                                updateState("isOpenDropdown14", false);
                                updateState("selectedVehicleno2", {
                                  name: JSON.parse(e).vdtRegNo,
                                  id: JSON.parse(e).vdtIdpk,
                                });
                              }}
                            />
                            {/* <Select
                              value={AllState.selectedVehicleno2.name}
                              key={AllState.selectedVehicleno2.name}
                              disabled={form_disable2}
                              onChange={(e: any) => {
                                updateState("selectedVehicleno2", {
                                  name: JSON.parse(e).vdtRegNo,
                                  id: JSON.parse(e).vdtIdpk,
                                });
                              }}
                            >
                              {vehicleNo?.map((option: any, index: number) => {
                                return (
                                  <option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["vdtRegNo"!]}`}
                                  </option>
                                );
                              })}
                            </Select> */}
                            {/* {
                              <span
                                style={{
                                  borderColor: borderTheme,
                                  height: 22.5,
                                  marginBottom: 2,
                                  borderWidth: 1,
                                  width: 24.9,
                                }}
                                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                                onClick={() => {
                                  updateState("selectedVehicleno2", "");
                                }}
                              >
                                <img
                                  className="w-full h-full p-0.5 flex items-center justify-center"
                                  src={reset}
                                  alt="reset"
                                />
                              </span>
                            } */}
                          </div>
                        </Form.Item>

                        <Form.Item
                          label={
                            <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                              {"Driver"}
                            </p>
                          }
                        >
                          <div className="flex flex-row items-center">
                            <InputSelect_template
                              placeHolder={
                                autoShowAssDetails === false
                                  ? " "
                                  : AllState.selectedVehicleno2.name
                              }
                              disabled={form_disable2}
                              idexpr="empIdpk"
                              dataexp="empName"
                              useCallFunc
                              options={drivers}
                              selectStyle={{ width: 200 }}
                              isLoading={AllState?.isLoading}
                              isOpenDropdown={AllState?.isOpenDropdown16}
                              handleFocus={() => {
                                updateState(
                                  "isOpenDropdown16",
                                  !AllState?.isOpenDropdown16
                                );
                              }}
                              handleRefresh={() => {
                                updateState("selectedDriverNo2", "");
                              }}
                              handleSearch={
                                // useCallback(
                                debounce((e) => {
                                  updateState("selectedVehicleno2", {
                                    ...AllState.selectedVehicleno2,
                                    name: e,
                                  });
                                  // UpdateState("isLoading", true);
                                  dispatch(
                                    FetchDriver_No2(e) as unknown as any
                                  );

                                  updateState(
                                    "isOpenDropdown16",
                                    true
                                  );
                                }, 500) // Adjust the debounce delay as needed
                                // []
                                // )
                              }
                              selectedValue={(e) => {
                                updateState(
                                  "isOpenDropdown16",
                                  false
                                );
                                updateState("selectedVehicleno2", {
                                  name: JSON.parse(e).empName,
                                  id: JSON.parse(e).empIdpk,
                                });
                              }}
                            />
                            {/* <Select
                              value={AllState.selectedDriverNo2.name}
                              key={AllState.selectedDriverNo2.name}
                              disabled={form_disable2}
                              onChange={(e: any) => {
                                updateState("selectedDriverNo2", {
                                  name: JSON.parse(e).empName,
                                  id: JSON.parse(e).empIdpk,
                                });
                              }}
                            >
                              {drivers?.map((option: any, index: number) => {
                                return (
                                  <option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["empName"!]}`}
                                  </option>
                                );
                              })}
                            </Select> */}

                            {
                              <span
                                style={{
                                  borderColor: borderTheme,
                                  height: 22.5,
                                  marginBottom: 2,
                                  borderWidth: 1,
                                  width: 24.9,
                                }}
                                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                                onClick={() => {
                                  updateState("selectedDriverNo2", "");
                                }}
                              >
                                <img
                                  className="w-full h-full p-0.5 flex items-center justify-center"
                                  src={reset}
                                  alt="reset"
                                />
                              </span>
                            }
                          </div>
                        </Form.Item>
                        <Form.Item
                          label={
                            <p className="text-xs">{"Remind Return After"}</p>
                          }
                          style={{ marginBottom: 0 }}
                        >
                          <div className="flex flex-row items-center">
                            <Form.Item
                              name="receivedBy"
                              rules={[{ required: true }]}
                              style={{
                                display: "inline-block",
                                width: "calc(90%)",
                              }}
                            >
                              {" "}
                              <div className="flex flex-row items-center">
                                <DateTemplate
                                  selectedDate={
                                    AllState.reminderDate === undefined
                                      ? currentDate
                                      : dayjs(`${AllState.reminderDate}`)
                                  }
                                  key={
                                    AllState.reminderDate === undefined
                                      ? ""
                                      : AllState.reminderDate
                                  }
                                  disabled={form_disable2}
                                  changeDate={(e) => {
                                    updateState("reminderDate", e);
                                  }}
                                  showTime={true}
                                  style={{ width: "100%" }}
                                  format={dateFormat}
                                />
                              </div>
                            </Form.Item>
                          </div>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
          </div>
        )}

        <div className="pt-0.5">
          <Datagrid_template
            data={Datax}
            gridheight={toggled === true ? pageHeight : tableHeight}
            columns={vehicle_request_TH}
            onRowClick={(selectedData: any) => {
              setSelectedRecord(selectedData);
              rowClicked(selectedData);
              setOuterSelectedRecord && setOuterSelectedRecord(selectedData);
              dispatch(task_form_disable(true));
              dispatch(task_footer_update(false));
              dispatch(settask_footer_table_selected([selectedData]));
            }}
            rowDoubleClicked={(selectedData: any) => {
              AccessPriv_on_DataGriddoubleClick(selectedData);
              setOuterSelectedRecord && setOuterSelectedRecord(selectedData);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleRequestsDetails;
