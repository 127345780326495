export const line_cols =[
    { id: 1, caption: "NOM.", dataField: 'ldtNomenclature', dataType: 'string', fixed: true, width: 80 },
    { id: 2, caption: "FROM", dataField: 'sourceStationName', dataType: 'string', fixed: true, width: 80 },
    { id: 3, caption: "TO", dataField: 'destStationName', dataType: 'string', fixed: true, width: 80 },
    { id: 4, caption: "ACTIVE?", dataField: 'ldtActive', dataType: 'boolean', fixed: true, width: 80 },
    { id: 5, caption: "LENGTH", dataField: 'ldtLength', dataType: '', fixed: false, width: 80 },
    { id: 6, caption: "OPERATING VOLTAGE", dataField: 'ldtOperatingVoltage', dataType: '', fixed: false, width: 80 },
    { id: 7, caption: "CONST. DATE", dataField: 'ldtConstructionDate', dataType: 'datetime', fixed: false, width: 80 },
    { id: 8, caption: "BUNDLED?", dataField: 'ldtBundled', dataType: 'boolean', fixed: false, width: 80 },
    { id: 9, caption: "CONDUCT. TYPE", dataField: 'ldtConductorTypeIDfk', dataType: '', fixed: false, width: 80 },
    { id: 10, caption: "VERIFIED?", dataField: 'ldtVerified', dataType: 'boolean', fixed: false, width: 80 },
    { id: 11, caption: "CONDUCT. CODE NAME", dataField: 'ldtConductorCodeNameIDfk', dataType: 'string', fixed: false, width: 80 },
    { id: 12, caption: "CSA", dataField: 'ldtConductorCrossSectionalArea', dataType: '', fixed: false, width: 80 },
    { id: 13, caption: "MANUFACTURER", dataField: 'mftName', dataType: 'string', fixed: false, width: 80 },
    { id: 14, caption: "COMMISSION DATE", dataField: 'ldtCommissionDate', dataType: 'datetime', fixed: false, width: 80 },
    { id: 15, caption: "DECOMMISSION DATE", dataField: 'ldtDecommissionDate', dataType: 'datetime', fixed: false, width: 80 },
    { id: 16, caption: "+ve SEQ RES/km", dataField: 'ldtPositiveSeqResistancePerKm', dataType: '', fixed: false, width: 80 },
    { id: 17, caption: "+ve SEQ REACT./km", dataField: 'ldtPositiveSeqReactancePerKm', dataType: '', fixed: false, width: 80 },
    { id: 18, caption: "SUSCEPTANCE/km", dataField: 'ldtSusceptancePerKm', dataType: '', fixed: false, width: 80 },
    { id: 19, caption: "RES. on 100MVA BASE", dataField: 'ldtResistanceOn100MvaBase', dataType: '', fixed: false, width: 80 },
    { id: 20, caption: "REACT. on 100MVA BASE", dataField: 'ldtReactanceOn100MvaBase', dataType: '', fixed: false, width: 80 },
    { id: 21, caption: "SUSCEPT. on 100MVA BASE", dataField: 'ldtSusceptanceOn100MvaBase', dataType: '', fixed: false, width: 80 },
    { id: 22, caption: "ZERO SEQ RESIST.", dataField: 'ldtZeroSeqResistance', dataType: '', fixed: false, width: 80 },
    { id: 23, caption: "ZERO SEQ REACT.", dataField: 'ldtZeroSeqReactance', dataType: '', fixed: false, width: 80 },
    { id: 24, caption: "ZERO SEQ SUSCEPT.", dataField: 'ldtZeroSeqSusceptance', dataType: '', fixed: false, width: 80 },
    { id: 25, caption: "ZERO SEQ. REST. ON 100MVA BASE", dataField: 'ldtZeroSeqResistanceOn100MvaBase', dataType: '', fixed: false, width: 80 },
    { id: 26, caption: "ZERO SEQ. REACT. ON 100MVA BASE", dataField: 'ldtZeroSeqReactanceOn100MvaBase', dataType: '', fixed: false, width: 80 },
    { id: 27, caption: "ZERO SEQ. SUSCEPT. ON 100MVA BASE", dataField: '', dataType: '', fixed: false, width: 80 },
    { id: 28, caption: "THERMAL LIMIT", dataField: 'ldtThermalLimit', dataType: '', fixed: false, width: 80 },
    { id: 29, caption: "SIL", dataField: 'ldtSurgeImpedanceLoading', dataType: '', fixed: false, width: 80 },
    { id: 30, caption: "START LATITUDE", dataField: 'ldtStartLatitude', dataType: '', fixed: false, width: 80 },
    { id: 31, caption: "END LATITUDE", dataField: 'ldtEndLatitude', dataType: '', fixed: false, width: 80 },
    { id: 32, caption: "START LONGITUDE", dataField: 'ldtStartLongitude', dataType: '', fixed: false, width: 80 },
    { id: 33, caption: "END LONGITUDE", dataField: 'ldtEndLongitude', dataType: '', fixed: false, width: 80 },
    { id: 34, caption: "REMARKS", dataField: 'ldtRmks', dataType: 'string', fixed: false, width: 400 },
]