/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";

import { Input, Select } from "antd";
import { mini_datagrid_cols } from "../data/datagrid_cols";
import search from "../../../../../../../assets/search.png";
import plus_add from "../../../../../../../assets/plus_add.png";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { task_footer_table_selected } from "../../../../../../../features/forms/entries/safetyManager/ppeRequest";
import Datagrid_template from "../../../../../../../templates/Datagrid";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  data?: any;
  dataVA?: any;
  setRefreshCallback?: (e: any) => void;
  statesx?: any;
  updateStatesx?: (key: string, value: any) => void;
  vehicleCriteria?: any;
  handleSearch?: () => void;
}

export const Maintenance_Schedule_Table_Form = ({
  toggler,
  datgridCol,
  customHeightHook,
  data,
  dataVA,
  setRefreshCallback,
  statesx,
  updateStatesx,
  vehicleCriteria,
  handleSearch,
}: props) => {
  const dispatch = useDispatch();

  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const [addedSelected, setAddSelected] = useState<any>([]);

  const addTask = async () => {
    if (addedSelected.length < 1) {
      setAddSelected(selectedRecord);
      updateStatesx && updateStatesx("addedSelectedRecord", selectedRecord);
    } else {
      //loop through selected records
      for (var a = 0; a < selectedRecord.length; a++) {
        //loop through added records
        var testBool: boolean = false;
        for (var b = 0; b < addedSelected.length; b++) {
          //if current record is found
          if (selectedRecord[a] === addedSelected[b]) {
            testBool = true;
          } else {
            //do nothing
          }
        }
        //selected record already exists in addedselected
        if (testBool === true) {
        } else {
          //add record to added selected
          setAddSelected([...addedSelected, selectedRecord[a]]);

          dispatch(
            setGeneralValue({
              expr: "rightVehTable",
              value: [...addedSelected, selectedRecord[a]],
            })
          ); //

          updateStatesx &&
            updateStatesx("addedSelectedRecord", [
              ...addedSelected,
              selectedRecord[a],
            ]);
        }
      }
    }
  };

  const [refresh, setRefresh] = useState<boolean>(false);

  return (
    <div>
      <div
        style={{ height: "" }}
        className="flex w-full h-full  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
      >
        <li className=" list-none justify-center items-center mt-[4px] ">
          <Select
            value={statesx?.rightVeh}
            placeholder={statesx?.rightVeh}
            size="small"
            className="w-28"
            onChange={(e) => {
              updateStatesx!("rightVeh", e);
              setRefreshCallback !== undefined && setRefreshCallback(!refresh);
            }}
          >
            {vehicleCriteria?.map(
              ({ id, label }: { id: number; label: string }) => {
                return (
                  <Select.Option id={id} value={label}>
                    {label}
                  </Select.Option>
                );
              }
            )}
          </Select>
        </li>
        <li
          style={{ height: "24px" }}
          className="px-2 list-none justify-center items-center mt-[4px] "
        >
          <Input
            className="h-[96%]"
            size="small"
            value={statesx && statesx.miniVDSearch.temp}
            onChange={(e: any) => {
              updateStatesx &&
                updateStatesx("miniVDSearch", {
                  temp: e.target.value,
                  text: statesx.miniVDSearch?.text,
                });
            }}
          />
        </li>
        <ul className=" mb-0.5 flex items-center justify-start w-full  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
          <li className="mr-10 justify-center items-center">
            <button
              onClick={() => {
                handleSearch && handleSearch();
              }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-0.5 border-2 border-slate-200 rounded hover:border-blue-300"
            >
              <img alt="" src={search} />
            </button>
          </li>
          <li>
            {" "}
            <button
              onClick={() => {
                addTask();
              }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-0.5 border-2 border-slate-200 rounded hover:border-blue-300"
            >
              <img alt="" src={plus_add} />
            </button>
          </li>
        </ul>
      </div>

      {/* 1ST Datagrid table */}
      <div
        style={{ width: "100%", marginBottom: "5px" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Datagrid_template
          gridheight={170}
          columns={mini_datagrid_cols}
          data={statesx?.rightVeh === "Vehicle Details" ? data : dataVA}
          disableGroupPanel
          disablePaging
          disableSearch
          selectedItemsChange={(e) => {
            setSelectedRecord(e.selectedRowsData);
          }}
          onRowClick={async (e) => {
            if (selectedRecord.length === 0) {
              setSelectedRecord([e]);
            } else {
              if (!selectedRecord.includes(e)) {
                setSelectedRecord([...selectedRecord, e]);
                dispatch(task_footer_table_selected([selectedRecord]));
              }
            }
          }}
        />
      </div>

      {/* 2nd Datagrid table */}
      <div
        style={{ width: "100%", marginBottom: "5px" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base "
      >
        <Datagrid_template
          gridheight={135}
          columns={mini_datagrid_cols}
          data={addedSelected}
          disableGroupPanel
          disablePaging
          disableSearch
          rowDoubleClicked={(e) => {
            const arr = addedSelected.filter(
              (item: any) => item.vdtIDpk !== e["vdtIDpk"]
            );
            setAddSelected(arr);
          }} // will work on it tommoroow
        />
      </div>
    </div>
  );
};
