import { Select } from "antd";
import {
  Column,
  DataGrid,
  GroupPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { CheckBox } from "devextreme-react/check-box";
import switchChart from "../../../../../../assets/switch-chart.png";

import { Item } from "devextreme-react/accordion";
import { TabPanel } from "devextreme-react";
// import {
//   api_url,
//   navbar_height,

//   toolbar_height,
// } from "../../../../../accessories/component_infos";
// import { station_data } from "../../Stations/data/stations-data";
import BasicLineDetails from "./basic-line-details";
import GPSLineDetailsTab from "./gps-line-details";
import SecondLineDetailsTab from "./second-line-details";
import OtherLineDetailsTab from "./other-line-details";
import { lineData_TH } from "../data/line-details-data";
// import useFetch from "../../../../../../hooks/useFetch";

// import {
//   task_footer_update,
//   task_form_disable,
// } from "../../../../../../features/Task&Todo/Task/TaskDetails";
// import { task_footer_table_selected } from "../../../../../../features/forms/entries/safetyManager/ppeRequest";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { RootState } from "../../../../../../app/store";
import axios from "axios";
import {
  api_url,
  navbar_height,
  saveModalData,
  toolbar_height,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { setGeneralValue } from "../../../../features/generalSlice";
import {
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import Datagrid_template from "../../../../templates/Datagrid";
import useFetch from "../../../../hooks/useFetch";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";
import { ImageUpload } from "./imageUpload";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../templates/modal";
import { UpdateStates } from "../../../../functions/updateStates";
// import { setGeneralValue } from "../../../../../../features/generalSlice";

const { Option } = Select;

interface props {
  toggled?: boolean;
  searchText?: any;
  activeStatus?: any;
  searchCriteria?: any;
  selectedRecord?: any;
  setState?: (key: string, value: any) => void;
  setRefresh?: (e: boolean) => void;
  refresh?: boolean;
  setShowTable?: (e: boolean) => void;
  // paging?: { pageNumber: number; pageSize: number };
}

const LineMainDetails = ({
  toggled,
  searchText,
  activeStatus,
  searchCriteria,
  selectedRecord,
  // paging,
  setState,
  setRefresh,
  refresh,
  setShowTable,
}: props) => {
  const [tabs, setTabs] = useState<String[]>([
    "Basic Line Details",
    "Second Line Details",
    "GPS Details",
    "Other Line Details",
  ]);
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const dateFormat = "EEE, dd MMM yyyy HH:mm";

  const height = window.innerHeight - (navbar_height + news_flash_height + 43); //height without navbar
  const [formheight, set_form_height] = useState<number>(490);
  const formRef = useRef<any>(null);

  const [pageHeight, setPageHeight] = useState(
    window.innerHeight - (navbar_height + news_flash_height + formheight -50)
  );
  const [fullPage, setfullPage] = useState(
    window.innerHeight - (navbar_height + news_flash_height + 100)
  );

  // const [showTable, setShowTable] = useState(true);

  const dispatch = useDispatch();
  // const refresh = useSelector((state: RootState) => state.general.refresh);

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);

  // const [states, setStates] = useState<any>({
  //   selectedRecord: {},
  // });

  // const setState = (key: string, value: any) => {
  //   setStates((prev: any) => ({ ...prev, [key]: value }));
  // };
  // const selectRecord = useCallback((e: any) => {
  //   dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

  //   setSelectedRecord(e.data);
  //   console.log(e);
  //   dispatch(task_footer_table_selected([e.data]));

  //   dispatch(task_footer_update(false));
  //   //disable forms
  //   // dispatch(task_form_disable(true));
  // }, []);

  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 20,
  });

  const allowUpdate = (e: any) => {
    //enable forms
    dispatch(task_form_disable(false));
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    //enable Update Mode
    dispatch(task_footer_update(true));

    dispatch(task_footer_table_selected([e.data]));
    console.log(e.data);
  };

  const [DBdata, DBdata_error, DBdata_loading] = useFetch(
    `LineDetails/line_details?${
      searchText ? `SearchTerm=${searchText}&` : ""
    }CheckState=${activeStatus}&PageNumber=${1}&PageSize=${paging.pageSize}&SearchCriteria=${searchCriteria}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );
  console.log(DBdata, "DBdata");

  // const [DBdata, setDBdata] = useState<any>([])

  // const DB_Fetch =  async()=>{
  //     const res = await axios.get(`${api_url}/api/AsmTamEntLineDetails/GetAllAsmTamLineDetails`,{
  //       headers :{
  //         'Content-Type': ['application/json', 'text/plain', 'charset=utf-8']
  //       }
  //     })

  //     setDBdata(res.data);
  // }

  // if (DBdata) {
  //   // console.log(DBdata);
  // } else {
  //   console.log("DBdata_error");
  // }

  useEffect(() => {
    // DB_Fetch()
    console.log(`inside ${toggled}`);
    // set_form_height((formRef.current?.clientHeight)+ 80)
    console.log(formRef);
  }, [toggled]);

  const displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  const allowedPageSizes = ["all", 5, 10, 15];

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [imageDescriptions, setImageDescriptions] = useState("");

  const doubleClickAccessPriv = (e: any) => {
    if (updatePriv === true) {
      setShowTable && setShowTable(true);
      setState("selectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
        }}
      />

      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
           setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel ={icon === "success" || icon === "warning" ? true : false}
      />

      {toggled && (
        <div ref={formRef} className="border-b-4 pb-1 flex">
          <div className="w-[60%]">
            <TabPanel className="w-full h-full flex">
              <Item title={"Basic Line Details"}>
                <BasicLineDetails
                  statesx={selectedRecord}
                  updateStatesx={setState}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  imageDescriptions={imageDescriptions}
                />
              </Item>

              <Item title={"Second Line Details"}>
                <SecondLineDetailsTab statesx={selectedRecord} 
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </Item>

              <Item title={"GPS Details"}>
                <GPSLineDetailsTab statesx={selectedRecord}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </Item>

              <Item title={"Other Line Details"}>
                <OtherLineDetailsTab statesx={selectedRecord}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </Item>
            </TabPanel>
          </div>
          <div className="w-[40%] ">
            <ImageUpload  statesx={selectedRecord} imageDescriptions={imageDescriptions} setImageDescriptions={setImageDescriptions}/>
          </div>
        </div>
      )}

      <div className="pt-0.5">
        <Datagrid_template
          gridheight={toggled === true ? pageHeight : fullPage}
          columns={lineData_TH}
          data={DBdata}
          onRowClick={(e: any) => {
            setState("selectedRecord", e);
            dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

            dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          }}
          rowDoubleClicked={(e: any) => {
            // setState("selectedRecord", e);
            doubleClickAccessPriv(e);
          }}
          handlePageNumber={(e: any) => {
            UpdateStates("pageNumber", e, setPaging);
          }}
          handlePageSize={(e: any) => {
            UpdateStates("pageSize", e, setPaging);
          }}
          pageSize={paging.pageSize}
          pageNumber={paging.pageNumber}
        />
        {/* dataSource={DBdata}
          showBorders={true}
          columnWidth={150}
          showRowLines={true}
          columnResizingMode={"widget"}
          showColumnLines={true}
          //Remember to change
          height={toggled === true ? pageHeight : fullPage}
          onRowClick={selectRecord}
          onRowDblClick={allowUpdate}
        > */}
        {/* <SearchPanel visible={true} width={200} />
          <GroupPanel visible={true} /> */}

        {/* STAFF DETAILS COLUMNS */}
        {/* FIXED COLUMNS */}

        {/* {lineData_TH.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                dataType={data.dataType && data.dataType}
                alignment={data.alignment}
                fixed={data.fixed}
                format={data.dataType === "datetime" && dateFormat}
                width={data.width === null ? 150 : data.width}
              />
            );
          })} */}

        {/* SCROLLBAR  */}

        {/* <Scrolling
           columnRenderingMode="finite" 
           />
          <Selection mode="single" />
          <Paging defaultPageSize={0} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={displayModes}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
        /> */}

        {/* </Datagrid_template> */}
        {/* <div className="text-xs text-indigo-500 p-1">Done</div> */}
      </div>
    </div>
  );
};

export default LineMainDetails;
