import 'devextreme/dist/css/dx.light.css';
import { useState } from "react";

import { Checkbox, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { Tooltip } from "devextreme-react";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import alter from '../../../assets/alter.png';
import close from '../../../assets/close.png';
import edit from '../../../assets/edit.png';
import email from '../../../assets/email.png';
import newfile from '../../../assets/new.png';
import printer from '../../../assets/printer.png';
import question from '../../../assets/question.png';
import refresh from '../../../assets/refresh.png';
import send from '../../../assets/send.png';
import settings from '../../../assets/setting.png';
import share from '../../../assets/share.png';
import { customtoolbarEndDate, customtoolbarStartDate, customtoolbar_Active_Status, customtoolbar_Complete_Status, customtoolbar_default_endDate, customtoolbar_default_startDate } from "../../../features/Task&Todo/Task/Checkstate";
import { task_footer_update, task_form_disable } from "../../../features/Task&Todo/Task/TaskDetails";
import { setGeneralValue, settask_footer_update, settask_form_disable } from "../../../features/generalSlice";
import { AlertSMS, AppraiseSettings, CustomSwitcher, DeleteTask, TaskSettings } from "./widgets/customToolbarModals";

interface props {
    toggler?: () => void

}



export const CustomToolbar = ({ toggler }: props) => {

    const { Search } = Input;

    const dateFormat = " DD MMM YYYY";

    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState<boolean>(false)
    const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false)
    const [AlertSMSisActive, setAlertSMSsetIsActive] = useState<boolean>(false)
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    const size: number = 16;

    const [searchValue, setSearchValue] = useState<string>();


    const disable = useSelector((state: RootState) => state.general.formDisabled);
    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const selectedRecord = useSelector((state: RootState) => state.addTask.selectedRecord)
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)


    const handleNew = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        dispatch(task_form_disable(false));
        dispatch(settask_form_disable(false));


        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))




        dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    };

    const handleCancel = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(task_form_disable(true));
        dispatch(settask_form_disable(true))

        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))




        dispatch(setGeneralValue({ expr: 'updateMode', value: false }));



    };



    const handleUpdate = () => {
        if (!selectedRecord) {

            dispatch(task_form_disable(true));
        }
        else {

            dispatch(task_form_disable(false));


            dispatch(task_footer_update(true))
        }
    }


    const handleChange = (value: string) => {
    };


    const [indeterminate, setIndeterminate] = useState<any>(true);
    const [checkedBox, setCheckedBox] = useState<boolean>(false);

    const onChange = () => {

        if (indeterminate === true) {
            setCheckedBox(false);

            setIndeterminate(null);

        } else if (indeterminate === null) {
            setCheckedBox(true);
            setIndeterminate(false);



        } else {
            setCheckedBox(false);
            setIndeterminate(true);
        }

    };



    dispatch(customtoolbar_Complete_Status(indeterminate))


    const defaultEndDate = dayjs().add(6, 'M')
    const defaultStartDate = defaultEndDate.subtract(1, 'y')





    const [startDate, setStartDate] = useState<any>(defaultStartDate.format())
    const [endDate, setEndDate] = useState<any>(defaultEndDate.format())






    dispatch(customtoolbar_default_startDate(defaultStartDate.format()))
    dispatch(customtoolbar_default_endDate(defaultEndDate.format()))
    dispatch(customtoolbarStartDate(startDate))
    dispatch(customtoolbarEndDate(endDate))



    const [indeterminate2, setIndeterminate2] = useState<boolean>(false);
    const [checkedBox2, setCheckedBox2] = useState<any>(true);

    const onChange2 = () => {

        if (checkedBox2 === true) {
            setCheckedBox2(false);

            setIndeterminate2(false);

        } else if (checkedBox2 === false) {
            setCheckedBox2(null);
            setIndeterminate2(true);
        } else {
            setCheckedBox2(true);
            setIndeterminate2(false);

        }

    };

    dispatch(customtoolbar_Active_Status(checkedBox2))


    return (
        <div style={{ borderColor: borderTheme }} className=" h-full  flex border-[1px] rounded border-gray-200 m-1 ">
            <div className="flex justify-center">
                <ul className="flex justify-evenly w-full items-center h-full py-0.5 px-2">
                    {/* new and cancel section */}
                    {(disablex || updateMode) ?
                        <li id="newRecord" onClick={() => handleNew()} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={newfile} />New </span>
                            <Tooltip
                                target="#newRecord"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">New Record</p>
                            </Tooltip>
                        </li>
                        :
                        <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={close} />Cancel </span>
                            <Tooltip
                                target="#close-cancel"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Cancel</p>
                            </Tooltip>
                        </li>
                    }
                    {!updateMode ?
                        <li id="editRecordst" onClick={() => { handleUpdate() }} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} />Edit </span>
                            <Tooltip
                                target="#editRecordst"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Edit Record</p>
                            </Tooltip>
                        </li>
                        :
                        <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={close} />Cancel </span>
                            <Tooltip
                                target="#close-cancel"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Cancel</p>
                            </Tooltip>
                        </li>
                    }

                    <li id="switch" onClick={toggler} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100" src={alter} />Switch </span>
                        <Tooltip
                            target="#switch"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="h-2">Switch interface / table view</p>
                        </Tooltip>
                    </li>

                    <li className="flex items-center rounded mr-1" >

                        <CustomSwitcher />

                    </li>

                    <li style={{ borderWidth: '1px', borderColor: borderTheme }} id="sms" onClick={() => { setAlertSMSsetIsActive(!AlertSMSisActive) }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className='flex dark:bg-slate-900 dark:text-white duration-100'> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={email} /> </span>
                        <Tooltip
                            target="#sms"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="h-2">Alert By SMS</p>
                        </Tooltip>

                    </li>
                    {/* <li><FaUserCog size={size}/></li> */}
                    <li style={{ borderWidth: '1px', borderColor: borderTheme }} id='settings' onClick={() => { SettingssetIsActive(!SettingsisActive) }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-white duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={settings} /> </span>
                        <Tooltip
                            target="#settings"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Task Settings</p>
                        </Tooltip>
                    </li>

                    <li style={{ borderWidth: '1px', borderColor: borderTheme }} id="delete" onClick={() => { setIsActive(!isActive) }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img alt="" className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={close} /> </span>
                        <Tooltip
                            target="#delete"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Delete Task</p>
                        </Tooltip>
                    </li>

                    <li style={{ borderWidth: '1px', borderColor: borderTheme }} id="share" className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img alt="" className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={share} /> </span>
                        <Tooltip
                            target="#share"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Share</p>
                        </Tooltip>
                    </li>

                    <li style={{ borderWidth: '1px', borderColor: borderTheme }} id="help" className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img alt="" className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={question} /> </span>
                        <Tooltip
                            target="#help"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Help Center</p>
                        </Tooltip>
                    </li>


                    <li className="px-0.5">
                        <Checkbox className="border-[1px] rounded px-1" />
                        <Tooltip
                            target="#status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">show or hide deleted tasks</p>
                        </Tooltip>

                    </li>
                    <li className="px-0.5">
                        <Checkbox className="border-[1px] rounded px-1" />
                        <Tooltip
                            target="#status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">single or multipleline rows</p>
                        </Tooltip>

                    </li>

                    {/* Differences starts here */}

                    <li className="items-center ml-9 ">
                        <label className='text-xs px-1 '>Search Criteria </label>
                        <Select
                            defaultValue="Task"

                            style={{ width: 80 }}
                            onChange={handleChange}
                            options={data}
                            size="small"
                            placeholder="Task"
                            dropdownStyle={{ minWidth: '250px' }}
                        />

                    </li>



                    <li className="px-1">
                        <Input size="small" placeholder="" className="h-6" style={{ width: 180 }} onChange={(e: any) => { setSearchValue(e.target.value) }} value={searchValue} />
                    </li>
                    <li className="px-0.5">
                        <DatePicker allowClear={false} onChange={(date, dateString) => { setStartDate(dayjs(date?.format())) }} defaultValue={dayjs(`${startDate}`)} size="small" placeholder="Start Date" style={{ width: 125 }} format={dateFormat} />
                    </li>
                    <li className="px-0.5">
                        <DatePicker allowClear={false} onChange={(date, dateString) => { dayjs(date?.format()) }} defaultValue={dayjs(`${endDate}`)} size="small" placeholder="End Date" style={{ width: 125 }} format={dateFormat} />
                    </li>
                    <li id="complete_status" className="flex px-0.5 mr-1 items-center">
                        <label className='text-xs px-0.5 '>Done?</label>
                        <Checkbox
                            onChange={() => { onChange() }}
                            indeterminate={indeterminate}
                            checked={checkedBox}

                        />
                        <Tooltip
                            target="#complete_status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Complete status</p>
                        </Tooltip>
                    </li>

                    <li id="active_status" className="flex px-0.5 mr-1 items-center">

                        <Checkbox
                            onChange={() => onChange2()}
                            indeterminate={indeterminate2}
                            checked={checkedBox2}

                        />
                        <Tooltip
                            target="#active_status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Active status</p>
                        </Tooltip>
                    </li>

                    <li onClick={() => { setSearchValue('') }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center">
                        <span> <img alt="" className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100" src={refresh} /> </span>
                    </li>
                    <li id="find" style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 px-2 py-1 border-slate-200 text-xs   text-gray-600 rounded flex items-center">
                        <span> <FaBinoculars size={15.5} color="#007AFF" /> </span>
                        <Tooltip
                            target="#find"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Find</p>
                        </Tooltip>
                    </li>
                    <li id="print" style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 px-2 py-1 border-slate-200 text-xs   text-gray-600 rounded flex items-center">
                        <span> <img alt="" className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={printer} /> </span>
                        <Tooltip
                            target="#print"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Print / Export</p>
                        </Tooltip>
                    </li>

                </ul>

            </div>

            <div className="flex items-center">
                <ul className="flex items-center">


                </ul>

                <DeleteTask isActive={isActive} setIsActive={setIsActive} />
                <TaskSettings SettingsisActive={SettingsisActive} SettingssetIsActive={SettingssetIsActive} />
                <AlertSMS
                    open={AlertSMSisActive}
                    cancelHandler={() => { setAlertSMSsetIsActive(false) }}
                    Message={Message} title={"Task - Alert Assignees"}
                    iscardTitle={true} cardName={"Alert SMS"}
                    footerButton={true}
                    footerButtonName={'send'}
                    footerButtonImg={send}
                    footerButtonClick={() => { setAlertSMSsetIsActive(false) }}


                />
                <AppraiseSettings AppraiseisActive={AppraiseisActive} AppraisesetIsActive={AppraisesetIsActive} />
            </div>
        </div>
    )
};









const Message = [

    <div className="justify-center items-center">
        <p>Hello Kofi, you have a Task Assignment: </p>
        <p>Title - Sharing Project plan with Justice</p>
        <p>Assigned By: Mr. Kofi Albrotsi (10010)</p>
        <p>Starts On: Tue, 08 Nov 2022 08:00</p>
        <p>Due On:   Wed, 09 Nov 2022 02:00</p>
        <p>Thank you</p>
        <p>Sent from MeshApps</p>
    </div>
]


const data = [
    { id: 0, label: "Task", },
    { id: 1, label: "Progress Activity", },
    { id: 2, label: "Priority", },
    { id: 3, label: "Status", },
    { id: 4, label: "Perf. Objective", },
    { id: 5, label: "Perf. Initiative", },
    { id: 6, label: "Assigned By", },
    { id: 7, label: "Assigned To", },
    { id: 8, label: "Done By", },

];