import { Checkbox, DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../../../app/store";
import alter from "../../../assets/alter.png";
import clearAll from "../../../assets/clear-all.png";
import close from "../../../assets/close.png";
import edit from "../../../assets/edit.png";
import help from "../../../assets/help.png";
import newfile from "../../../assets/new.png";
import printer from "../../../assets/printer.png";
import reset from "../../../assets/refresh-small.png";
import statusLoader from "../../../assets/statusloader.gif";
import { setGeneralValue } from "../../../features/generalSlice";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
import { Popup_Tips } from "../../Assets-Manager/Transport/Entries/Vehicles/Vehicle-Requests/widgets/widgets/popUpTips";
import { FormModal } from "../formModal";
interface props {
  useCallBackFunc?: boolean;
  toggler?: () => void; 
  handleDelete?: () => void; 
  handleSave?: () => void; 
  handleNew?: () => void; 
  handleUpdate?: () => void; 
  searchTextOnchange?: (value: string) => void; 
  startDateChanged?: (value: any) => void; 
  endDateChanged?: (value: any) => void; 
  checkOnChange?: (value: any) => void; 
  handleFind?: () => void; 
  handleRefresh?: () => void; 
  handlePrint?: () => void; 
  handleToggle?: () => void;
  handleToggleAll?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void; 
  disableNew_edit?: boolean;
  withDates?: boolean; 
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
  additionalComponentsAfter?: any;
  setVisible?: () => void;
  visible?: boolean;
  searchParams_change?: string | boolean | any | undefined;
  setSearchParams_pathname?: string;
  isSearchText?: boolean;
  startDate?: any;
  endDate?: any;
  setIsActive?: (e: boolean) => void;
  setShowGrid?: () => void;
  resetFields?: () => void;
  
  setSwitchToggle?: (e: boolean) => void;
}

export const EntryCustomToolbar = ({
  setVisible,
  visible,
  searchParams_change,
  setSearchParams_pathname,
  toggler,
  handleToggle,
  handleToggleAll,
  setOuterSelected,
  searchCriteriaOnchange,
  checkBoxAfterHelp = false,
  searchCriteriaData = [],
  assignmentsForm,
  checkBoxAfterHelpOnchange,
  searchCriteria,
  handleNew: newButtonClick,
  handleRefresh,
  handlePrint,
  outerSelected,
  disableNew_edit,
  useCallBackFunc,
  withDates = true,
  handleDelete,
  searchTextOnchange,
  handleSave,
  handleUpdate,
  startDateChanged,
  endDateChanged,
  handleFind,
  checkOnChange,
  additionalComponentsAfter,
  isSearchText = true,
  endDate,
  startDate,
  setIsActive,
  setShowGrid,
  resetFields,
  setSwitchToggle
}: props) => {
  const dispatch = useDispatch();
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); 
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });
  const selected = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); 
  const [searchTextValue, setSearchTextValue] = useState("");

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );
 

  const onChange = () => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };
  const reduxDispatchs = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: false })); 
    dispatch(setGeneralValue({ expr: "updateMode", value: false })); 

    
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const setModal = () => {
    setWarningModal(true);
    setWarningModalData({
      title: "Select record",
      message: "Please select a record to edit",
    });
  };
  const [helpCenter, setHelpCenter] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();


  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(
    privType
  );
  const AccessPriv_on_FindButton = () => {
    if (savePriv === true) {
      handleFind && handleFind();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      handlePrint && handlePrint();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const AccessPriv_on_NewButtton = () => {
    setPrivType("save");
    if (savePriv === true || true) {
      setVisible && setVisible();
      navigate({
        pathname: setSearchParams_pathname,
        search: createSearchParams({
          view: searchParams_change,
        }).toString(),
      });
      setSwitchToggle && setSwitchToggle(true);
      reduxDispatchs(); 
      resetFields && resetFields()
      setOuterSelected && setOuterSelected({})
      
      newButtonClick && newButtonClick();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const AccessPriv_on_EditButtton = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setShowGrid && setShowGrid()
      handleEdit();
      handleUpdate && handleUpdate()
    }else{
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  }
  

  const view = searchParams.get("view");
  
  const handleNew = () => {
    AccessPriv_on_NewButtton();
  };
  
  const OnModalOk = () => {
    setWarningModal(false);
  };
  const OnModalCancel = () => {
    setWarningModal(false);
  };

  
  
  const handleEdit = () => {

    navigate({
      pathname: setSearchParams_pathname,
      search: createSearchParams({
        view: searchParams_change,
      }).toString(),
    });
    if (outerSelected && !useCallBackFunc) {
      if (Object.keys(outerSelected).length < 1) {
        setModal();
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } else {
        
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      }

      return;
    } else if (outerSelected && useCallBackFunc) {
      
      return;
    }

    if (outerSelected && Object.keys(outerSelected).length < 1) {
      setWarningModal(true);
      setWarningModalData({
        title: "Select record",
        message: "Please select a record to edit",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
      
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

      
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    }
  };
  
  
  
  
  

  
  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  return (
    <>
     <FormModal
          title={"Popup Tips"}
          child={<Popup_Tips/>}
          width={'500px'}
          openState={helpCenter}
          onCancel={()=>{setHelpCenter(!helpCenter)}}
        />
    <div
      style={{ height: 32, width: "4000px", borderColor: borderTheme }}
      className="px-0.5 mt-0.5 rounded border-[1px]  w-full h-full flex  border-gray-200 mb-1"
    >
        {
        <FormModal
          title={"Popup Tips"}
          child={<Popup_Tips />}
          
          width={"500px"}
          openState={helpCenter}
          onCancel={() => {
            setHelpCenter(!helpCenter);
          }}
        />
      }
      {/* ------------------------- for print -------------------------- */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
                        .ant-btn.ant-btn-sm {
                            background-color: #fff;
                            padding: 5px 6px;
                            justify-content: center;
                        }

                        :where(.css-dev-only-do-not-override-1n7nwfa).ant-btn.ant-btn-sm{
                            height: 26px;
                        }
                    `,
        }}
      />
      {/* -------------------------------------------------------------------------- */}
      <ModalTemplate
        icon={"warning"}
        cancelHandler={OnModalCancel}
        disableCancel={true}
        open={warningModal}
        okHandler={OnModalOk}
        message={warningModalData.message}
        okText={"Ok"}
        title={warningModalData.title}
      />
      <ul className="flex items-center h-full py-0.5">
        {/* new and cancel section */}
        {!disableNew_edit && (
          <span className="flex items-center h-full ">
            {disabled || updateMode ? (
              <li
                id="newRecord"
                onClick={() => handleNew()}
                style={{
                  borderWidth: "1px",
                  borderColor: borderTheme,
                  width: "60px",
                }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">New Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}

            {!updateMode ? (
              <li
                id="editRecordst"
                
                onClick={() => {
                  AccessPriv_on_EditButtton();
                }}
                style={{
                  borderWidth: "1px",
                  borderColor: borderTheme,
                  width: "60px",
                }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                  alt=""
                    className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecordst"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
          </span>
        )}

        <li
          id="switchInterface"
          onClick={toggler}
          style={{
            borderWidth: "1px",
            borderColor: borderTheme,
            width: "70px",
          }}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img
            alt=""
              className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
              src={alter}
            />
            Switch
          </span>
          <Tooltip
            target="#switchInterface"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Switch interface / table view</p>
          </Tooltip>
        </li>

        <li
          id="helpst"
          style={{
            borderWidth: "1px",
            borderColor: borderTheme,
            width: "60px",
          }}
          onClick={()=>{setHelpCenter(true)}}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
            {" "}
            <img
            alt=""
              className="dark:bg-slate-900 dark:text-gray-100 duration-100 mr-1"
              src={help}
            />
            Help{" "}
          </span>
          <Tooltip
            target="#helpst"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Help</p>
          </Tooltip>
        </li>
        {additionalComponentsAfter && additionalComponentsAfter}

        {assignmentsForm && (
          <>
            <li
              id="clear-cancel"
              onClick={() => handleToggle && handleToggle()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                alt=""
                  style={{ paddingTop: 1, paddingBottom: 1 }}
                  className=" w-10/12 py-0.5 h-10/12"
                  src={close}
                />
              </span>
              <Tooltip
                target="#clear-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Toggle active status of selected assignment</p>
              </Tooltip>
            </li>

            <li
              id="clear-cancel-all"
              onClick={() => handleToggleAll && handleToggleAll()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="" src={clearAll} />
              </span>
              <Tooltip
                target="#clear-cancel-all"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Toggle active status of all assignments</p>
              </Tooltip>
            </li>
          </>
        )}

        {checkBoxAfterHelp && (
          <li
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 5,
              paddingRight: 5,
            }}
            id="loadAll"
            className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center"
          >
            <Checkbox
              onChange={(checkValue) => {
                checkBoxAfterHelpOnchange &&
                  checkBoxAfterHelpOnchange(checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#loadAll"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Load all forms</p>
            </Tooltip>
          </li>
        )}
      </ul>

      <ul
        style={
          !disableNew_edit
            ? { width: "calc(100% - 340px)" }
            : { width: "calc(100% - 240px)" }
        }
        className="flex  w-full mr-4"
      >
        <section
          
          className="flex justify-start items-center"
        >
          {(searchCriteria || assignmentsForm) && (
            <li className="px-1 flex items-center flex-row ">
              <label style={{ width: "87px" }} className=" text-xs">
                Search Criteria
              </label>
              <Select
                value={criteriaDataLocal}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); 
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); 
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{
                  width: `${sideAlert === true ? 75 : "145px"}`, 
                  height: "25px",
                }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </li>
          )}


          <li className=" ml-4 px-1 flex items-center flex-row  ">
            <label className="w-[90px] flex  text-xs">Search Text</label>
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchTextValue(searchText);
              }}
              value={searchTextValue}
              name="search"
              id="search"
              size="small"
              allowClear={true}
              style={{
                width: `${sideAlert === true ? 75 : 130}`,
                height: "25px",
              }}
            />
          </li>

          {withDates && (
            <ul className=" flex flex-row items-center mt-0.5">
              <li className="px-0.5">
                <DatePicker
                  clearIcon={<span style={{ display: "none" }}></span>}
                  onChange={(e) => {
                    startDateChanged!(e);
                  }}
                  value={dayjs(startDate)}
                  size="small"
                  placeholder="Start Date"
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                />
              </li>
              <li className="px-0.5">
                <DatePicker
                  clearIcon={<span style={{ display: "none" }}></span>}
                  onChange={(e) => {
                    endDateChanged!(e);
                  }}
                  value={dayjs(endDate)}
                  size="small"
                  placeholder="End Date"
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                />
              </li>
            </ul>
          )}
          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="status"
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={checkedBox}
              value={checkValue}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange();
                setIsActive!(checkValue.target.checked);
                

              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li>
          <li
            onClick={() => {
              AccessPriv_on_FindButton();
            }}
            id="findst"
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "25px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li>

          {/* refresh */}
          <li
            onClick={() => {
              handleRefresh && handleRefresh();

           

              
              setSearchTextValue("");
              setCheckedBox(false);
              setIndeterminate(true);
              setCheckValue("");
              setCriterialDataLocal({
                id: searchCriteriaData![0]?.id ?? 0,
                value: searchCriteriaData![0]?.value ?? "",
              });
            }}
            id="refreshst"
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "25px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
               alt=""
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          {/* <li
            onClick={() => {
              AccessPriv_on_PrintButton();
            }}
            id="printst"
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "25px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1 text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
              alt=""
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li> */}
          {/* Exports */}

        </section>

        {/* Form status label */}
        {!disableNew_edit && (
          <section
            
            className="flex pl-10 h-full flex-row items-center"
          >
            {/* done state */}
            {disabled && <p className=" text-gray-600">Done.</p>}

            {/* new record or editing state */}
            {!disabled && (
              <div className="gif flex flex-row items-center">
                <img className="w-11 h-7" src={statusLoader} alt="gif" />
                {!updateMode ? (
                  <p className=" text-gray-600">New record...</p>
                ) : (
                  <p className=" text-gray-600">Editing...</p>
                )}
              </div>
            )}
          </section>
        )}
      </ul>
    </div>
    </>
  );
}