export const vehicleList_data = [
  {
    id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    width: 140,
  },
  {
    id: 1,
    caption: "START DATE",
    dataField: "vasStartDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 120,
    format: "dd MMM yyyy",
  },
  {
    id: 2,
    caption: "END DATE",
    dataField: "vasEndDate",
    alignment: "left",
    fixed: true,
    dataType: "datetime",
    width: 100,
    format: "dd MMM yyyy",
  },
  {
    id: 3,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100,
  },
  {
    id: 4,

    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "CLASS",
    dataField: "vhcName",
    alignment: "left",
    fixed: false,

    width: 100,
  },
  {
    id: 6,
    caption: "MAKE",
    dataField: "vmkName",
    alignment: "left",
    fixed: false,
    width: 100,
    visible: false,
  },
  {
    id: 7,
    caption: "MODEL",
    dataField: "mdlName",
    alignment: "left",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "ACTIVE?",
    dataField: "vdtActive",
    alignment: "left",

    datatype: "boolean",

    fixed: false,
    width: 100,
  },
  {
    id: 9,
    caption: "FIT?",
    dataField: "vstFitStatus",
    alignment: "left",

    datatype: "boolean",

    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "DEL?",
    dataField: "vasDeleted",
    alignment: "left",

    datatype: "boolean",

    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "VEH.STATUS",
    dataField: "vstName",
    alignment: "left",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "WORKSHOP",
    dataField: "wsdName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 13,
    caption: "DRIVER",
    dataField: "driverEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 14,
    caption: "APPROVING OFFICER",
    dataField: "ownerEmpName",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 15,
    caption: "ADMINISTRATOR",
    dataField: "adminEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 16,
    caption: "VEHICLE CONTROLLER",
    dataField: "controllerEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 17,
    caption: "MANAGER",
    dataField: "mgrEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 18,
    caption: "DIRECTOR",
    dataField: "dirEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 19,
    caption: "TRANS. UNIT",
    dataField: "truName",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 20,
    caption: "LOC.",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 21,
    caption: "UNIT",
    dataField: "untShtName",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 22,
    caption: "CHASSIS No",
    dataField: "vdtChassisNo",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 23,
    caption: "MANUF. DATE",
    dataField: "vdtManufactureDate",
    alignment: "left",
    dataType: "date",
    fixed: false,
    width: 140,
  },
  {
    id: 24,
    caption: "SUPPLY DATE",
    dataField: "vdtSupplyDate",
    alignment: "left",
    dataType: "date",
    fixed: false,
    width: 140,
  },
  {
    id: 25,
    caption: "ENGINE TYPE",
    dataField: "egtName",
    alignment: "left",
    fixed: false,
    width: 140,
  },
  {
    id: 26,
    caption: "REMARKS",
    dataField: "vasRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
  {
    id: 27,
    caption: "ID",
    dataField: "vasIdpk ",
    alignment: "left",
    fixed: false,
    width: 60,
    visible: false,
  },
];
