import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../app/store";
import { useEffect } from "react";
import { userModel } from "../components/human-resource/setups/data/types/selectsTypes";

export const useAccountInfo = (): [number, number, userModel] => {
  const navigate = useNavigate();
  const userID = useSelector(
    (state: RootState) => state.user?.user?.userModel?.usaIDpk
  ); //user ID
  const employeeID = useSelector(
    (state: RootState) => state?.user.user?.userModel?.empIDpk
  ); //emp id
  const userModel = useSelector(
    (state: RootState) => state?.user.user?.userModel
  ); //emp id

  const user: userModel = userModel;

  useEffect(() => {
    if (!userID || !employeeID) {
      navigate("/");
    }
  }, []);

  return [employeeID, userID, user];
};

export const useCurrentSession = () => {
  const user = useSelector(
    (state: RootState) => state?.user?.user?.userModel as UserModel
  );
  return user;
};

export interface UserModel {
  usaIDpk: number;
  empIDpk: number;
  usaUsername: string;
  usaSuperuser: boolean;
  usaAllowSendingOfSMS: boolean;
  dptName: string;
  dptShtName: string;
  dptIDpk: number;
  sxnIDpk: number;
  untIDpk: number;
  sxnName: string;
  sxnShtName: string;
  untName: string;
  untShtName: string;
  empIsOrgAdmin: boolean;
  empIsDeptadmin: boolean;
  empIsSectionAdmin: boolean;
  empIsUnitAdmin: boolean;
  usaSeeSalaries: boolean;
  usaEditIntercom: boolean;
  empFirstName: string;
  empLastName: string;
  empDoB: Date;
  empStaffno: string;
  empMobNox: string;
  empDirTelNo: string;
  usaSeeAllForms: boolean;
  empIntercom: string;
  empTelNo: string;
  empMobNo: string;
  empShowBirthdayInNewsFlash: boolean;
  empShowWorkAnniversaryInNewsFlash: boolean;
  usaSeeAuditTrail: boolean;
  empName14: string;
  empName15: string;
  empName12: string;
  empName: string;
  empName1: string;
  empName2: string;
  usaChangePassword: boolean;
  usaactive: boolean;
  apmCode: string;
  ascName: string;
  locName: string;
  empOfficialEmail: string;
  gndName: string;
  empIsDirector: boolean;
  empIsManager: boolean;
  empIsSupervisor: boolean;
  empIsCeo: boolean;
  titShtName: string;
  titName: string;
  usaStartDate: Date;
  usaEndDate: Date;
  usaSeeExecDashboard: boolean;
  empName16: string;
  usaShowTipOfDayOnStartup: boolean;
  usaShowRightNavigatorOnStartup: boolean;
}
