import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import {
  api_base_url,
  api_base_url2,
  api_url,
} from "../components/accessories/component_infos";
import { requestConfig, useAxiosService } from "../general/requestConfig";
import { useEffect, useId, useState } from "react";
import { useQuery } from "@tanstack/react-query";

// Custom hook for crud Func
export const useCrudFunc = (baseUrl?: "innox" | "powerapp" | "legal") => {
  let FormCode = useSelector((state: RootState) => state.user?.formCode);

  var api = api_url;

  switch (baseUrl) {
    case "powerapp":
      api = api_base_url;
      break;
    case "legal":
      api = api_base_url2;
      break;
    default:
      api = api_url;
  }

  const postData = async (
    url: string,
    payload: any,
    FormAction?: string,
    customFormCode?: string,
    cusHeader?: any
  ) => {
    if (!FormCode) FormCode = "0000-debug";
    const response = await axios.post(
      `${api}/${url}`,

      {
        ...payload,
        formCode: customFormCode ?? FormCode,
        formAction: FormAction,
      },
      requestConfig(null, cusHeader)
    );
    return response;
  };

  const updateData = async (
    url: string,
    payload: any,
    FormAction?: string,
    customFormCode?: string,
    cusHeader?: any
  ) => {
    if (!FormCode) FormCode = "0000-debug";
    const response = await axios.put(
      `${api}/${url}`,
      {
        ...payload,
        formCode: customFormCode ?? FormCode,
        formAction: FormAction,
      },
      requestConfig(null, cusHeader)
    );
    return response;
  };

  // const DeleteData = async (url: string, payload:any,  FormAction?: string, cusHeader?:any) => {
  //     const response = await axios.delete(
  //         `${api}/${url}`,
  //         { ...payload, FormCode: FormCode, FormAction: FormAction },
  //         requestConfig()

  //     );
  //     return response;
  // };

  const patchData = async (url: string, payload: any, FormAction?: string) => {
    const response = await axios.patch(
      `${api}/${url}`,
      { ...payload, FormCode, FormAction },
      requestConfig()
    );
    return response;
  };
  const fetchData = async (url: string) => {
    const response = await axios.get(`${api}/${url}`, requestConfig());
    return response;
  };

  return [postData, updateData, patchData, fetchData];
};

type QueryFetcherConfig = {
  app: "innox" | "powerapp";
  url: string;
  params?: Record<string, string | number | boolean>;
  /**
   * @params refresh
   * If `true`, the query will be re-fetched whenever the `refresh` value changes.
   * If `false`, the query will not be re-fetched whenever the `refresh` value changes.
   */
  refresh?: boolean;
  /**
   * @params revalidateAfter
   * If set, the query will automatically re-fetch every `revalidateAfter` milliseconds.
   */
  revalidateAfter?: number; // Add an optional interval parameter
};
/**
 * Simple hook for fetching data from the server with revalidation and refresh options
 * @param config for fetching data from the server @type QueryFetcherConfig
 * @returns {loading, data, error} @type {boolean, any, any}
 */
export const useQueryFetch = <TData = any, TError = any>(
  config: QueryFetcherConfig
) => {
  if (!config.url) {
    throw new Error("url is required in the config object");
  }
  const query = useAxiosService(config.app);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TData | undefined>();
  const [error, setError] = useState<TError | undefined>();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await query({
          url: config.url,
          method: "GET",
          params: config?.params ?? undefined,
        });
        setData(res as TData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Fetch data immediately on mount

    if (config?.revalidateAfter) {
      interval = setInterval(fetchData, config?.revalidateAfter);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.refresh, config?.revalidateAfter, config.url]);

  return { loading, data, error };
};

type ExtendQueryFetcherConfig = Omit<QueryFetcherConfig, "refresh"> & {
  staleTime?: number;
  queryKey?: string;
  gcTime?: number;
};

export const useQueryFetcherX = <TData = unknown>(
  config: ExtendQueryFetcherConfig,
  params: Record<string, any>
) => {
  const query = useAxiosService<TData>(config.app);

  return useQuery({
    queryKey: [config?.queryKey, params],
    queryFn: async () => {
      const res = await query({
        url: config.url,
        method: "GET",
        params,
      });
      return res as TData;
    },
    refetchInterval: config?.revalidateAfter,
    staleTime: config?.staleTime,
    gcTime: config?.gcTime,
  });
};