import axios from "axios"
import { api_base_url, api_url } from "../components/accessories/component_infos";
import { useToken } from "antd/es/theme/internal";

import { userDataCrypt } from "./userDataEncrypt";
import { requestConfig } from "../general/requestConfig";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";


export const DeleteDataFunc=async(  url:string, queryParams?: Record<string, string | number|boolean>, baseUrl?: "innox"|"powerapp")=>{
    const queryWithFormCode = {
        ...queryParams,        
           
    };

    // Construct the query string from queryWithFormCode
    const queryString = queryParams ? '?' + new URLSearchParams(queryWithFormCode as Record<string, string>).toString() : '';
    var api = api_url;

    switch (baseUrl) {
        case "powerapp":
            api = api_base_url
            break;
        default:
                api = api_url
    }

    if (!url) {
        // If the URL is not provided, return an empty data object
        return { data: [] };
    }
    
    try{
        const deleteResponse=await axios.delete(
            `${api}/${url}${queryString}`,
            requestConfig()
            
        )
        
        return deleteResponse;
    }catch(err:any){

    }
}

//action message: Deleted image for vehicle with Reg No " & rowx("vdtRegNo"