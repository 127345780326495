import React from "react";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import InputSelect_template from "../../../../templates/InputSelect";
import { TextareaTemplate } from "../../../../templates/textarea";
import Datagrid_template from "../../../../templates/Datagrid";
import form from "antd/es/form";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { InputsTemplate } from "../../../../templates/input";
import { NewButton, SaveButton } from "../../../accessories/buttons";

const category_columns = [
    {
      id: 1,
      caption: "NAME",
      dataField: "exnName",
      dataType: "string",
      width: 100,
      fixed: false,
      visible: true,
    },
    {
      id: 2,
      caption: "SHORT NAME",
      dataField: "exnName",
      dataType: "string",
      width: 100,
      fixed: false,
      visible: true,
    },
    {
      id: 3,
      caption: "ORDER",
      dataField: "exnName",
      dataType: "string",
      width: 120,
      fixed: false,
      visible: true,
    },
    {
      id: 4,
      caption: "ACTIVE?",
      dataField: "exnName",
      dataType: "string",
      width: 130,
      fixed: false,
      visible: true,
    },
    {
      id: 5,
      caption: "REMARRKS",
      dataField: "exnName",
      dataType: "string",
      width: 60,
      fixed: false,
      visible: true,
    },
  ];
const Categorydetails = () => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  return (
    <div className="px-2">
      <HrSetupsCustomToolbar />
      <div className="w-full h-full">
        <div className="flex flex-col w-full h-full">
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="pt-1 px-2 border-[1px] rounded-t-md border-t-1 font-medium w-full bg-slate-100"
          >
            {"Category Details"}
          </p>
          <section className="flex w-full h-full py-4">
            <aside className=" w-[40%]">
              <Form
                className="w-full border-slate-100 rounded-b-md border-t-0 flex justify-between py-1 px-2 "
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
              >
                <div className="w-full">
                  <InputSelect_template label="Name" />
                  <InputSelect_template label="Short Name" /> 
                  <InputsTemplate  label="Order"/>
                  <aside className="flex  mx-5 mt-3">
                <div className="w-full mr-20">
                  <CheckboxTemlate labelStyle={""} label="Active:    " />
                </div>
                <div className="w-full flex space-x-2 justify-end">
                  <NewButton />
                  <SaveButton />
                </div>
              </aside>
                </div>
              </Form>
            </aside>
            <aside className="flex w-[60%]">
                <p className="mr-2" >Remarks: </p>
              <TextareaTemplate height={160} parentClassName="flex-1" />
            </aside>
          </section>
        </div>
        <div>
          <Datagrid_template
            gridheight={720}
            columns={category_columns}
            data={[]}
            // gridheight={showGrid ? window.innerHeight - heights_out - form_height : window.innerHeight - heights_out}
            className={"w-full"}
          />
        </div>
      </div>
    </div>
  );
};

export default Categorydetails;
