/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import close from "../../../assets/close.png";
import eLogBook from "../../../assets/eLogBook.png";
import help from "../../../assets/help.png";
import reset from "../../../assets/refresh-small.png";
import midnight from "../../../assets/night-mode.png";
import template from "../../../assets/open-folder.png";
import takeOver from "../../../assets/changeUserBlack241.png";
import shiftChange from "../../../assets/remoteDesktopBlue241.png";
import shiftMembers from "../../../assets/userCog.png";
import config from "../../../assets/updatetable_16x161.png";
import file from "../../../assets/groupfieldcollection_16x161.png";
import performance from "../../../assets/performance.small.png";
import redflag from "../../../assets/red-flag.png";
import docs from "../../../assets/google-docs.png";
import arrowUpDown from "../../../assets/mobile-data.png";
import print from "../../../assets/printer.png";
import loader from "../../../assets/statusloader.gif";
import swituser from "../../../assets/switUserBlue241.png";
import alter from "../../../assets/alter.png";
import { CheckboxTemlate } from "../../../templates/checkbox";
import { IconsContainer } from "../../Safety/elogger/StationReadings/_widget/stationReadingsToolbar";
import { InputsTemplate } from "../../../templates/input";
import InputSelect_template from "../../../templates/InputSelect";
import { DateTemplate } from "../../../templates/date";
import { FaBinoculars } from "react-icons/fa";
import { RefreshButton } from "../../Safety/elogger/StationReadings/_widget/utils";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import axios from "axios";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
import { api_base_url } from "../component_infos";
import { useBusyLoader } from "../../../hooks/useBusyLoader";
import CustomLoader from "../custom-loader";
import dayjs from "dayjs";
import { Checkbox } from "antd";
import { ModalTemplate } from "../../../templates/modal";
import { userDataCrypt } from "../../../functions/userDataEncrypt";
// import { Fetch_Data } from "../../../functions/Me";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDataFunc } from "../../../functions/get";
import { RootState } from "../../../app/store";
import { handleElogbookSearch, resetSearchParams, setSearchParamOnChange } from "../../../features/apiCalls/elogbook";
import { useCrudFunc } from "../../../functions/crud";
import { Fetch_Data } from "../../../functions/Dry/Me";
interface props {
  midnightConditionsClick: () => void;
  updateShiftMembersClick: () => void;
  updateTemplatesClick: () => void;
  remoteShiftChangeClick: () => void;
  shiftTakeoverClick: () => void;
  eventTypesClick: () => void;
  stationDetailsClick: () => void;
  shiftchangeClick: () => void;
  updateStationShiftMembers: () => void;
  switchForm: () => void;
  setDataToDatagrid?: (e: any) => void;
  setElogData: (e: any) => void;
  selectedData?: any;
  elogData?: any[];
  onSpellCheckerClick?: () => void;
  onShowLogDateClick?: () => void;
  onMultilineRowsClick?: () => void;
  onShowVerticalLinesClick?: () => void;
  spellChecker?: boolean;
  showLogDate?: boolean;
  multilineRows?: boolean;
  showVerticalLines?: boolean;
  handleDeleteLogClicked?: () => void;
  handleSyncLogClicked?: () => void;
  handleSyncStatusClicked?: () => void;
}

export const EloggerCustomToolbar = ({
  midnightConditionsClick,
  updateShiftMembersClick,
  updateTemplatesClick,
  remoteShiftChangeClick,
  shiftTakeoverClick,
  eventTypesClick,
  stationDetailsClick,
  shiftchangeClick,
  updateStationShiftMembers,
  switchForm,
  setDataToDatagrid,
  setElogData,
  selectedData,
  elogData,
  onSpellCheckerClick,
  onShowLogDateClick,
  onMultilineRowsClick,
  onShowVerticalLinesClick,
  spellChecker,
  showLogDate,
  multilineRows,
  showVerticalLines,
  handleDeleteLogClicked,
  handleSyncLogClicked,
  handleSyncStatusClicked
}: props) => {
  const [employeeID, userID, user] = useAccountInfo();
  const checkboxes = [
    // {
    //   id: "spell-checker",
    //   name: "Spell Checker",
    //   description:
    //     "Tick to enable the spell checking feature for your event. You can turn it off by unchecking it.",
    //   onclick: onSpellCheckerClick,
    //   defaultChecked: spellChecker,
    // },
    {
      id: "Show-log-date",
      name: "Show log date",
      description:
        "Click to show or hide the date the event was saved to the database.",
      onclick: onShowLogDateClick,
      defaultChecked: showLogDate,
    },
    {
      id: "Multiline-rows",
      name: "Multiline Rows",
      description: "Click to expand or collapse multiple rows in the table.",
      onclick: onMultilineRowsClick,
      defaultChecked: multilineRows,
    },
    {
      id: "Show-vertical-lines",
      name: "Show Vertical Lines",
      description: "Tick to show vertical lines in the table.",
      onclick: onShowVerticalLinesClick,
      defaultChecked: showVerticalLines,
    },
  ];

  const ShowCheckPrivWarningModal = (data: any) => {
    if (searchParamsOnChange?.cmbSrchViewStation?.initialValue === "All Stations") {
      if (
        searchParamsOnChange?.startDate?.initialStartDate <
        data?.data?.eupStartDate ||
        searchParamsOnChange?.endDate?.initialEndDate > data?.data?.eupEndDate
      ) {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "question",
          title: "Invalid date range, adjust?",
          message: data?.message,
          okText: "yes",
          cancelText: "no",
          openModal: true,
          okHandler: () => {
            setStartDateAndEndDateFromCheckPriv_AllStations(data);
          },
          cancelHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
      }
    } else {
      if (
        searchParamsOnChange?.startDate?.initialStartDate <
        data?.data?.eupStartDate ||
        searchParamsOnChange?.endDate?.initialEndDate > data?.data?.eupEndDate
      ) {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "question",
          title: "Invalid date range, adjust?",
          message: data?.message,
          okText: "yes",
          cancelText: "no",
          openModal: true,
          okHandler: () => {
            setStartDateAndEndDateFromCheckPriv_OtherStations(data);
          },
          cancelHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
      }
    }
  };

  const [value, setValue] = useState<boolean | string>("");
  const [B_value, setB_value] = useState<boolean | string>("");
  const [checkCount, setCheckCount] = useState<number>(0);
  const [isHandleFindClicked, setIsHandleFindClicked] =
    useState<boolean>(false);
  const [isHandleRefreshClicked, setIsHandleResfreshClicked] =
    useState<boolean>(false);
  const [messageModal, setMessageModal] = useState<{
    icon: "warning" | "question" | "success";
    title: string;
    message: string;
    openModal: boolean;
    hideCancel: boolean;
    okText: string;
    cancelText: string;
    okHandler: () => void;
    cancelHandler: () => void;
  }>({
    icon: "warning",
    title: "",
    message: "",
    openModal: false,
    hideCancel: true,
    okText: "",
    cancelText: "",
    okHandler: () => { },
    cancelHandler: () => { },
  });

  const dispatch = useDispatch();

  const searchParamsOnChange = useSelector(
    (state: RootState) => state?.elogBook
  );
  const cmbSrchViewStationsList = useSelector((state: RootState) => state.general.cmbSrchViewStationsList) ?? [];

  const [posting, updating] = useCrudFunc("powerapp");

  const check_PriviledgeBeforeAndAfterSearch = async () => {
    // alert('inside the check priviledge function')
    const { isError, data } = await Fetch_Data({
      route: `${api_base_url}/ELogBook/CheckPriviledge?stationName=${searchParamsOnChange?.cmbSrchViewStation?.value
        }&startDate=${dayjs(searchParamsOnChange?.startDate?.startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        )}&endDate=${dayjs(searchParamsOnChange?.endDate?.endDate).format(
          "YYYY-MM-DD HH:mm:ss"
        )}`,
      // setState: setCheckPrivData,
    });
    if (!isError) {
      // alert('no error')
      FetchAllLogs();
    } else {
      // alert('error')
      ShowCheckPrivWarningModal(data);
      setElogData([]);
    }
  };
  const eventType = useFetch("ELogBook/PopCmbEventTypes", "", "", false, "powerapp")[0];
  const cmbSrchDispatchers = useFetch(`ELogBook/PopCmbSrchDispatchers`, "", "", false, "powerapp")[0];
  const reportingStations = useFetch("ELogBook/PopLueReportingStations", "", "", false, "powerapp")[0];
  const correspondent = useFetch("ELogBook/PopCmbCorrespondents", "", "", false, "powerapp")[0];
  const setStartDateAndEndDateFromCheckPriv_AllStations = (data: any) => {
    if (
      searchParamsOnChange.startDate.initialStartDate <
      dayjs(data?.data?.eupStartDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      dispatch(
        setSearchParamOnChange({
          key: "startDate",
          value: {
            ...searchParamsOnChange.startDate,
            initialStartDate: dayjs(data?.data?.eupStartDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          },
        })
      );
    }
    if (
      searchParamsOnChange.endDate.initialEndDate >
      dayjs(data?.data?.eupEndDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      dispatch(
        setSearchParamOnChange({
          key: "endDate",
          value: {
            ...searchParamsOnChange.endDate,
            initialEndDate: dayjs(data?.data?.eupEndDate).format("YYYY-MM-DD HH:mm:ss"),
          },
        })
      );
      dispatch(
        setSearchParamOnChange({
          key: "startDate",
          value: {
            ...searchParamsOnChange.startDate,
            initialStartDate: dayjs(data?.data?.eupEndDate)
              .subtract(7, "days")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        })
      );
    }
    setMessageModal((prev: any) => ({
      ...prev,
      openModal: false,
    }));
  };
  const setStartDateAndEndDateFromCheckPriv_OtherStations = (data: any) => {
    if (
      searchParamsOnChange.startDate.initialStartDate <
      dayjs(data?.data?.pvaStartDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      dispatch(
        setSearchParamOnChange({
          key: "startDate",
          value: {
            ...searchParamsOnChange.startDate,
            initialStartDate: dayjs(data?.data?.pvaStartDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          },
        })
      );
    }
    if (
      searchParamsOnChange.endDate.initialEndDate >
      dayjs(data?.data?.pvaEndDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      dispatch(
        setSearchParamOnChange({
          key: "endDate",
          value: {
            ...searchParamsOnChange.endDate,
            initialEndDate: dayjs(data?.data?.pvaEndDate).format("YYYY-MM-DD HH:mm:ss"),
          },
        })
      );
      dispatch(
        setSearchParamOnChange({
          key: "startDate",
          value: {
            ...searchParamsOnChange.startDate,
            initialStartDate: dayjs(data?.data?.pvaEndDate)
              .subtract(7, "days")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        })
      );
    }
    setMessageModal((prev: any) => ({
      ...prev,
      openModal: false,
    }));
  };
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const inputTemps = [
    {
      id: 0,
      idExpr: "id",
      dataExpr: "name",
      option: cmbSrchViewStationsList,
      // option: searchParamsOnChange.cmbSrchViewStationsList,
      toolTipId: `one`,
      toolTip: "Home station - the station where the log was captured",
      stateName: "cmbSrchViewStation",
      placeholder: searchParamsOnChange.cmbSrchViewStation.initialValue,
    },
    {
      id: 1,
      idExpr: "evtidpk",
      dataExpr: "evtName",
      option: eventType,
      toolTipId: `two`,
      toolTip: "Event type",
      stateName: "eventType",
      placeholder: searchParamsOnChange.eventType.initialValue,
    },
    {
      id: 2,
      idExpr: "stnIDpk",
      dataExpr: "stnName",
      option: reportingStations,
      toolTipId: "three",
      toolTip: "Report station - the station which the log is written about",
      stateName: "logStationName",
      placeholder: searchParamsOnChange.logStationName.initialValue,
    },
    {
      id: 3,
      idExpr: "name",
      dataExpr: "name",
      option: cmbSrchDispatchers,
      toolTipId: `four`,
      toolTip: "Operator - the person who logged the event",
      stateName: "cmbSrchDispatcher",
      placeholder: searchParamsOnChange.cmbSrchDispatcher.initialValue,
    },
    {
      id: 4,
      idExpr: "crdIDpk",
      dataExpr: "empName1",
      option: correspondent,
      toolTipId: `five`,
      toolTip: "Correspondent - the person who relayed the information",
      stateName: "correspondentName",
      placeholder: searchParamsOnChange.correspondentName.initialValue,
    },
  ];

  const flagLogValidation = async () => {
    if (elogData.length === 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: "There is no log to flag",
        openModal: true,
        okText: "Ok",
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    }

    if (Object.keys(selectedData).length === 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: "There is no event log to flag",
        openModal: true,
        okText: "Ok",
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    }

    const flagResult = await getFlagPrivilege();

    if (flagResult === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access Denied",
        message: `You do not have the privileges to flag logs For ${selectedData?.homeStationName} station`,
        openModal: true,
        okText: "Ok",
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
      return;
    }

    if (selectedData.lbkFlagged === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Flag?",
        message: "Are you sure you want To flag the selected Event log",
        openModal: true,
        hideCancel: false,
        okText: "Yes",
        cancelText: "No, cancel",
        okHandler: async () => { await flagOrUnflagLog(); },
        cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Delete Flag?",
        message: "Are you sure you want to delete the flag on the selected event log?",
        openModal: true,
        hideCancel: false,
        okText: "Yes",
        cancelText: "No, cancel",
        okHandler: async () => { await flagOrUnflagLog("d"); },
        cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    }

  };

  const flagOrUnflagLog = async (m: 'i' | "d" = "i") => {
    try {
      await updating("ELogBook/FlagOrUnflagLog", { lbkIDpk: selectedData?.lbkIDpk, lbkFlag: selectedData.lbkFlagged }, "Flagged log with id " + selectedData?.lbkIDpk);
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "success",
        title: m === "i" ? "Flagged successful" : "Unflagged successful",
        message: m === "i" ? "Event log flagged successfully" : "Event log unflagged successfully",
        openModal: true,
        okText: "Ok",
        hideCancel: true,
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
      dispatch(setSearchParamOnChange({ key: "refresh", value: !searchParamsOnChange.refresh }));
    } catch (error) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Flag Error",
        message: "An error occurred while trying to flag the selected log",
        openModal: true,
        okText: "Ok",
        hideCancel: true,
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    }
  }

  const bookMarkValidations = async () => {

    if (elogData.length === 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: "There is no log to bookmark",
        openModal: true,
        okText: "Ok",
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    }

    if (Object.keys(selectedData).length === 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: "There Is no Event log To Bookmark",
        openModal: true,
        okText: "Ok",
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    }
    const bookmarkResult = await getBookmarks();
    if (bookmarkResult && bookmarkResult?.lbmIDpk !== 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Bookmark?",
        message: "Are you sure you want To bookmark the selected Event log?",
        openModal: true,
        hideCancel: false,
        okText: "Yes",
        cancelText: "No, cancel",
        okHandler: async () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          if (bookmarkResult?.lbmActive === true) {
            setMessageModal((prev: any) => ({
              ...prev,
              icon: "question",
              title: "Delete Bookmark?",
              message: "The selected Event log has been bookmarked. Do you want To Delete the bookmark?",
              openModal: true,
              hideCancel: false,
              okText: "Yes",
              cancelText: "No, cancel",
              okHandler: async () => {
                setMessageModal((prev: any) => ({ ...prev, openModal: false }));
                await deleteOrActivateBookmark(bookmarkResult?.lbmIDpk, !!bookmarkResult?.lbmActive);
              },
              cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
            }));
          } else {
            setMessageModal((prev: any) => ({
              ...prev,
              icon: "question",
              title: "Activate Bookmark?",
              message: "Are you sure you want To Activate the bookmark On the selected Event log?",
              openModal: true,
              hideCancel: false,
              okText: "Yes",
              cancelText: "No, cancel",
              okHandler: async () => { await deleteOrActivateBookmark(bookmarkResult?.lbmIDpk, !!bookmarkResult?.lbmActive) },
              cancelHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
            }));
          }
        },
        cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Bookmark?",
        message: "Are you sure you want To bookmark the selected Event log?",
        openModal: true,
        hideCancel: false,
        okText: "Yes",
        cancelText: "No, cancel",
        okHandler: async () => { await saveBookmark(); },
        cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }))
    }


  };

  const deleteOrActivateBookmark = async (lbmIDpk: number, lbmActive: boolean) => {
    try {
      const res = await updating("ELogBook/DeleteOrActivateBookmark", { lbmIDpk: lbmIDpk, lbmActive: lbmActive, }, "Updated bookmark status with id " + lbmIDpk);
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "success",
        title: "Bookmarked successful",
        message: res.data.message,
        openModal: true,
        okText: "Ok",
        hideCancel: true,
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
      dispatch(setSearchParamOnChange({ key: "refresh", value: !searchParamsOnChange.refresh }));
    } catch (error) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: " Bookmark Error",
        message: "An error occurred while trying to bookmark the selected log",
        openModal: true,
        okText: "Ok",
        hideCancel: true,
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    }
  }

  const saveBookmark = async () => {
    try {
      await posting("ELogBook/CreateBookmark", { lbmIDpk: selectedData?.lbkIDpk, homeStationId: selectedData.lbkHomeStationIDfk }, "Saved bookmark for id " + selectedData?.lbkIDpk);
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "success",
        title: "Bookmarked successful",
        message: "Log bookmarked successfully",
        openModal: true,
        okText: "Ok",
        hideCancel: true,
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    } catch (error) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: " Bookmark Error",
        message: "An error occurred while trying to bookmark the selected log",
        openModal: true,
        okText: "Ok",
        hideCancel: true,
        okHandler: async () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })) },
      }));
    }
  }

  const icons = [
    [
      {
        id: "Midnight-conditions",
        name: "Midnight Conditions",
        tooltip:
          "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => {
          midnightConditionsClick && midnightConditionsClick();
        },
        src: midnight,
      },
      {
        id: "Shift-Change",
        name: "Shift Change",
        tooltip:
          "Click to initiate the changing and handing over of the current shift",
        onClick: shiftchangeClick,
        src: swituser,
      },
      {
        id: "Update-templates",
        name: "Update Templates",
        tooltip: "Click to launch the templates update interface",
        onClick: updateTemplatesClick,
        src: template,
      },
      {
        id: "remote-shift",
        name: "Remote Shift Change",
        tooltip:
          "Click to launch the form to either Initiate or Accept a remote shift change",
        onClick: remoteShiftChangeClick,
        src: shiftChange,
      },
      {
        id: "shift-takeover",
        name: "Shift Take Over",
        tooltip:
          "Click to launch the form to take over the current shift. You should have Shift Take Over priviledge before trying this.",
        onClick: shiftTakeoverClick,
        src: takeOver,
      },
      {
        id: "shift-members",
        name: "Update Current Shift Members",
        tooltip:
          "Click to launch the form to use to update the current shift members",
        onClick: updateShiftMembersClick,
        src: shiftMembers,
      },
      // {
      //   id: "folder-sync",
      //   name: "Remote Shift Change",
      //   tooltip:
      //     "Click to launch the form to use to update current shift members",
      //   onClick: () => {},
      //   src: file,
      // },
      {
        id: "config",
        name: "Update Station Shift Members",
        tooltip:
          "Click to launch the form to use to update current shift members",
        onClick: updateStationShiftMembers,
        src: config,
      },
      {
        id: "event-types",
        name: "Event Types",
        tooltip:
          "Click to launch the form to use to update current shift members",
        onClick: eventTypesClick,
        src: file,
      },
    ],
    [
      {
        id: "bookmark",
        name: "Bookmark Log",
        tooltip:
          "Click to bookmark the selected log. Bookmarked logs are visible to the individual who bookmarked it",
        onClick: bookMarkValidations,
        src: docs,
      },
      {
        id: "flag",
        name: "Flag Log",
        tooltip:
          "Click to flag the selected log. Flagged logs are visible to all individuals who have the privilege to view the log. It is coloured yellow.",
        onClick: flagLogValidation,
        src: redflag,
      },
      {
        id: "station-readings",
        name: "Station Details",
        tooltip:
          "Click to launch the form to use to capture a number of parameter readings recorded at the substation.",
        onClick: stationDetailsClick,
        src: performance,
      },
    ],
    [
      {
        id: "arrow-up-down",
        name: "Sync Logs",
        tooltip: "Click to setup synchronisation  of local logs",
        onClick: handleSyncLogClicked,
        src: arrowUpDown,
      },
      {
        id: "refresh",
        name: "Refresh",
        tooltip: "View Sync Progress/Click to view the progress of stations that are syncing logs locally",
        onClick: handleSyncStatusClicked,
        src: reset,
      },
    ],
    [
      {
        id: "close",
        name: "Delete Log",
        tooltip: "Delete log/Click to delete the selected log. The selected log must be logged by you and should be in your current shift.",
        onClick: handleDeleteLogClicked,
        src: close,
      },
      { id: "help", name: "Help", tooltip: "", onClick: () => { }, src: help },
    ],
  ];

  const [FetchingAllLogPending, setFetchingAllLogPending] = useState(false);

  const FetchAllLogs = async () => {
    let item = sessionStorage.getItem("client");
    let encItem = userDataCrypt("decrypt", item);
    let parsedItem = JSON.parse(encItem);
    setFetchingAllLogPending(true);
    await axios
      .get(
        `${api_base_url}/ELogBook/getAllLogBook?StationName=${searchParamsOnChange.cmbSrchViewStation.value
        }&CheckState=${searchParamsOnChange.checkActive.value}&PageNumber=${searchParamsOnChange.pageNumber
        }&PageSize=${searchParamsOnChange.pageSize}&LbkEvent=${searchParamsOnChange.lbkEvent.value
        }&EventType=${searchParamsOnChange.eventType.value
        }&CorrespondentName2=${searchParamsOnChange.correspondentName.value
        }&LbkStartDate=${dayjs(searchParamsOnChange.startDate.startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        )}&LbkEndDate=${dayjs(searchParamsOnChange.endDate.endDate).format(
          "YYYY-MM-DD HH:mm:ss"
        )}&LogStationName=${searchParamsOnChange.logStationName.value
        }&Operator=${searchParamsOnChange.cmbSrchDispatcher.value}&BookMarkStatus=${searchParamsOnChange.bookMark.value
        }`,
        {
          headers: {
            "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${parsedItem?.token}`,
          },
        }
      )
      .then((res) => {
        setFetchingAllLogPending(false);
        setElogData(res?.data?.data);
      })
      .catch((err) => {
        setFetchingAllLogPending(false);
        setElogData([]);
      });
  };

  const getBookmarks = async () => {
    const res: any = await GetDataFunc("ELogBook/GetBookmarks", { lbkIDpk: selectedData?.lbkIDpk }, "powerapp");
    if (res?.status === 200) {
      return res?.data?.data;
    } else {
      return false;
    }
  }
  const getFlagPrivilege = async (): Promise<boolean> => {
    const { data: r } = await GetDataFunc("ELogBook/GetFlagPrivilege", { homeStationId: selectedData.lbkHomeStationIDfk }, "powerapp");
    return r.data.pvaFlag
  }

  useEffect(() => {
    check_PriviledgeBeforeAndAfterSearch();
  }, [isHandleFindClicked, isHandleRefreshClicked, searchParamsOnChange.refresh, searchParamsOnChange.pageNumber, searchParamsOnChange.pageSize]);

  const handleChange = () => {
    if (value === "" && checkCount === 0) {
      setCheckCount((prev) => (prev += 1));
      setValue(true);
      dispatch(
        setSearchParamOnChange({
          key: "checkActive",
          value: { ...searchParamsOnChange.checkActive, initialValue: true },
        })
      );
    } else if (value === true && checkCount === 1) {
      setCheckCount((prev) => (prev += 1));
      setValue(false);
      dispatch(
        setSearchParamOnChange({
          key: "checkActive",
          value: { ...searchParamsOnChange.checkActive, initialValue: false },
        })
      );
    } else {
      setValue("");
      dispatch(
        setSearchParamOnChange({
          key: "checkActive",
          value: { ...searchParamsOnChange.checkActive, initialValue: "" },
        })
      );
      setCheckCount(0);
    }
  };
  const handleBookMarkCheckBox = () => {
    if (B_value === "") {
      setB_value(true);
      dispatch(
        setSearchParamOnChange({
          key: "bookMark",
          value: { ...searchParamsOnChange.bookMark, initialValue: true },
        })
      );
    } else if (B_value === true) {
      setB_value(false);
      dispatch(
        setSearchParamOnChange({
          key: "bookMark",
          value: { ...searchParamsOnChange.bookMark, initialValue: false },
        })
      );
    } else {
      setB_value("");
      dispatch(
        setSearchParamOnChange({
          key: "bookMark",
          value: { ...searchParamsOnChange.bookMark, initialValue: "" },
        })
      );
    }
  };
  const handleRefresh = () => {
    dispatch(resetSearchParams());
    setIsHandleResfreshClicked(!isHandleRefreshClicked);
  };
  const handleFind = () => {
    setIsHandleFindClicked(!isHandleFindClicked);
    dispatch(handleElogbookSearch());
  };

  return (
    <>
      <ModalTemplate
        icon={messageModal.icon}
        title={messageModal.title}
        message={messageModal.message}
        open={messageModal.openModal}
        okText={messageModal.okText}
        cancelText={messageModal.cancelText}
        disableCancel={messageModal.hideCancel}
        okHandler={messageModal.okHandler}
        cancelHandler={messageModal.cancelHandler}
      />
      {FetchingAllLogPending && <CustomLoader text="Fetching records..." />}
      <main className="mb-0.5">
        <section className="w-full ml-1 flex items-center space-x-2 border-[1px] px-1">
          <aside>
            <p className="truncate">{"Search-->"}</p>
          </aside>
          <aside id="six" className="w-[150px]">
            <InputsTemplate
              span={true}
              defaultValue={searchParamsOnChange?.lbkEvent?.initialValue}
              disabledStatus={false}
              inputStyle={{ height: "24px", marginBottom: 3 }}
              useCallbackFunc
              orderOnchange={(e: any) => {
                dispatch(
                  setSearchParamOnChange({
                    key: "lbkEvent",
                    value: {
                      ...searchParamsOnChange.lbkEvent,
                      initialValue: e,
                    },
                  })
                );
              }}
            />
            <Tooltip
              target={`#six`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="text font-bold">
                {"Text to search in the event body"}
              </p>
            </Tooltip>
          </aside>

          {inputTemps.map((inputTemp) => (
            <aside
              className="w-[150px] bg-transparent"
              key={inputTemp.toolTipId}
              id={inputTemp.toolTipId}
            >
              <InputSelect_template
                toolTipId={inputTemp.toolTipId}
                selectStyle={{ height: "25px" }}
                disabled={false}
                placeHolder={inputTemp.placeholder}
                options={inputTemp.option}
                span={true}
                idexpr={inputTemp.idExpr}
                dataexp={inputTemp.dataExpr}
                settingsIcon={false}
                settingsIconBefore={false}
                disabledCustomWidth={true}
                useCallFunc
                selectedValue={(e: any) => {
                  dispatch(
                    setSearchParamOnChange({
                      key: inputTemp.stateName,
                      value: {
                        ...searchParamsOnChange[inputTemp.stateName],
                        initialValue: e[inputTemp.dataExpr],
                      },
                    })
                  );
                }}
              />
              <Tooltip
                target={`#${inputTemp.toolTipId}`}
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="text-black font-bold">{inputTemp.toolTip}</p>
              </Tooltip>
            </aside>
          ))}
          <aside id="startDatex">
            <DateTemplate
              selectedDate={dayjs(
                searchParamsOnChange?.startDate?.initialStartDate
              )}
              showTime={true}
              changeDate={(e: any) => {
                dispatch(
                  setSearchParamOnChange({
                    key: "startDate",
                    value: {
                      ...searchParamsOnChange.startDate,
                      initialStartDate: dayjs(e).format("YYYY-MM-DD HH:mm:ss"),
                    },
                  })
                );
              }}
              format={"DD MMM YYYY HH:mm:ss"}
              disabled={false}
              showTimeWithSeconds={true}
            />
            <Tooltip
              target={`#startDatex`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="text-black font-bold">Event search start date</p>
            </Tooltip>
          </aside>
          <aside id="endDatex">
            <DateTemplate
              selectedDate={dayjs(
                searchParamsOnChange?.endDate?.initialEndDate
              )}
              showTime={true}
              changeDate={(e: any) => {
                dispatch(
                  setSearchParamOnChange({
                    key: "endDate",
                    value: {
                      ...searchParamsOnChange.endDate,
                      initialEndDate: dayjs(e).format("YYYY-MM-DD HH:mm:ss"),
                    },
                  })
                );
              }}
              format={"DD MMM YYYY HH:mm:ss"}
              disabled={false}
              showTimeWithSeconds={true}
            />
            <Tooltip
              target={`#endDatex`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="text-black font-bold">Event search end date</p>
            </Tooltip>
          </aside>
          <aside id="nine">
            <Checkbox
              checked={searchParamsOnChange?.checkActive?.initialValue === true}
              indeterminate={
                searchParamsOnChange?.checkActive?.initialValue === ""
              }
              onChange={(e: any) => {
                handleChange();
                // onChange();
              }}
            />
            <Tooltip
              target={`#nine`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="text-black font-bold">{`Active Status of log`}</p>
            </Tooltip>
          </aside>
          <aside id="bookMark">
            <Checkbox
              checked={searchParamsOnChange?.bookMark.initialValue === true}
              indeterminate={searchParamsOnChange?.bookMark.initialValue === ""}
              onChange={(e: any) => {
                handleBookMarkCheckBox();
              }}
            />
            <Tooltip
              target={`#bookMark`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="text-black font-bold">{`Bookmark status`}</p>
            </Tooltip>
          </aside>
          <div className="p-1 border-[1px] rounded-sm">
            <FaBinoculars
              className="cursor-pointer"
              onClick={() => {
                handleFind();
              }}
              size={15.5}
              color="#007AFF"
            />
          </div>
          <div className="">
            <RefreshButton
              onClick={() => {
                handleRefresh();
              }}
            />
          </div>
          {/* <IconsContainer
          url={print}
          name="Midnight Conditions"
          tooltip="Click to initiate the capturing of midnight conditions for the station"
        /> */}

          <img
            className=" hover:bg-blue-50  flex p-0.5 items-center justify-center border-[1px] rounded"
            src={print}
            alt="print"
          />
          <aside className=" w-[30px]">
            <img src={loader} alt="eLog Book" />
          </aside>
        </section>

        <section className="ml-1 flex items-center border-[1px] px-1 w-full h-full">
          <aside className="h-4 w-[100px] border-[1px] -mt-1 mx-1">
            <img src={eLogBook} alt="eLog Book" />
          </aside>
          <aside>
            <p className="truncate">{"Actions-->"}</p>
          </aside>

          {/* <aside className="w-[45px]"> */}
          <aside className="w-[100px] flex space-x-2 border-[1px] rounded-md justify-center items-center m-1 p-0.5 cursor-pointer" onClick={() => switchForm && switchForm()}>
            <img src={alter} sizes="32" />
            <p>Switch</p>
            <Tooltip
              target={`#alter`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">{"Switch Interface"}</p>
              <p>{"Click to switch between read and log mode interface"}</p>
            </Tooltip>
          </aside>
          <aside className=" flex">
            {checkboxes.map((checkbox, index) => (
              <li
                key={index}
                id={checkbox.id}
                style={{}}
                className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center"
              >
                <CheckboxTemlate
                  withBorders
                  customDisabled={false}
                  indeterminate={false}
                  defaultValue={checkbox.defaultChecked}
                  useCallbackFunc
                  setCheckboxOnchange={(e: any) => checkbox.onclick()}
                />

                <Tooltip
                  target={`#${checkbox.id}`}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className=" text-left font-semibold">{checkbox.name}</p>
                  <p>{checkbox.description}</p>
                </Tooltip>
              </li>
            ))}
          </aside>

          <aside className="flex w-[300px]">
            {icons[0].map((icon, index) => (
              <IconsContainer url={icon.src} {...icon} />
            ))}
          </aside>

          <aside className="flex mx-5 w-[160px]">
            {icons[1].map((icon, index) => (
              <IconsContainer url={icon.src} {...icon} />
            ))}
          </aside>

          <aside
            style={{ width: "calc(100% - 500px)" }}
            className="flex justify-between  w-fit"
          >
            <div className="flex w-40">
              {icons[2].map((icon, index) => (
                <IconsContainer url={icon.src} {...icon} />
              ))}
            </div>

            <div className=" flex">
              {icons[3].map((icon, index) => (
                <IconsContainer url={icon.src} {...icon} />
              ))}
            </div>
          </aside>
        </section>
      </main>
    </>
  );
};
