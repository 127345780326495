/* eslint-disable react/jsx-pascal-case */
import { Switcher } from "../../_widget/switcher";
import { RefreshButton } from "../../_widget/utils";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { SaveButton, UpdateButton } from "../../../../../accessories/buttons";
import { DateTemplate } from "../../../../../../templates/date";
import MiniToolbar from "../../_widget/minitollbar";
import { InputsTemplate } from "../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { ReadingsChart } from "../../_widget/readingschart";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import {
    feederColumns,
  lineColumns,
  lineData,
  stationEntries,
  stationEntriesColumns,
} from "../BusVoltage/_data/stationentriescolumns";
import useFetch from "../../../../../../hooks/useFetch";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useCrudFunc } from "../../../../../../functions/crud";
import { ModalTemplate } from "../../../../../../templates/modal";
import { saveModalData } from "../../../../../accessories/component_infos";
import { title } from "process";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import LineReadingsToolbar from "../LineReadings/lineReadingToolbar";

interface FeederReadingsTypes {
  voltage: any;
  current: any;
  activeInput: any;
  reactiveInput: any;
  refresh: boolean;
  selectedDate?: Dayjs;
  active: boolean;
  remarks: string;
  stationSelected: any;
  lineSelected: any;
  selectedRecord: any;
  stRefresh: boolean;
}

export default function FeederReadings() {
  const [states, setStates] = useState<FeederReadingsTypes>({
    refresh: false,
    selectedDate: dayjs(),
    voltage: 0,
    current: 0,
    activeInput: 0,
    active: false,
    reactiveInput: 0,
    remarks: "",
    stationSelected: {},
    lineSelected: {},
    selectedRecord: {},
    stRefresh: false,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  console.log(states, "states");

  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 1,
    index: 1,
  });

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(1, "month")
    .startOf("year")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  const [refresh, setRefresh] = useState<boolean>(false);

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const [searchValue, setSearchValue] = useState("");

  const { status } = useGraphSwitcher();
  const { status: switchStatus, setWitch, setWitchFalse } = useSwitcher();
  const dispatch = useDispatch();
  const recordSwitch = useRef<boolean>();

  useEffect(() => {
    recordSwitch.current = switchStatus;
  }, [switchStatus]);

  //LineReadings/grid?StartDate=2020-01-01&EndDate=2024-01-01&EmpId=1&SearchTerm=a&CheckState=true&PageNumber=1&PageSize=20

  // const [lineData] = useFetch(
  //   `LineReadings/grid?StartDate=${startDate.text}&EndDate=${
  //     endDate.text
  //   }&EmpId=${1}&SearchTerm=${searchText.text}&CheckState=${
  //     activeStatus.status
  //   }&PageNumber=1&PageSize=20`,
  //   refresh,
  //   undefined,
  //   undefined,
  //   "powerapp"
  // );

  const [station_data] = useFetch(
    `LineReadings/line-stations`,
    states.stRefresh,
    undefined,
    undefined,
    "powerapp"
  );

  //LineReadings/line-details?stnIDfk=1
  const [lineDetails] = useFetch(
    `LineReadings/line-details?stnIDfk=${states.stationSelected.stnIDpk}`,
    states.refresh,
    undefined,
    undefined,
    "powerapp"
  );

    const [refreshFeeder, setrefreshFeeder] = useState<boolean>(false);

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)
    
    // const [searchText, setSearchText] = useState('');
    // const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' })

    const [feedData] = useFetch(
        `EntFeederReadings/grid`,
        refresh,
        undefined,
        undefined,
        "powerapp", true,
        {
          ...(startDate.text !== '' &&  {  "StartDate":startDate.text}),
          ...(endDate.text !== '' &&  { "EndDate": endDate.text}),
          ...(searchText.text !== '' &&  { "SearchText": searchText.text}),
        ...( { "ActiveStatus": activeStatus.status}),
        ...(searchCriteria.index.toString() !== '0' &&  { "SearchCriteria": searchCriteria.index}),
        ... { "EmpId": '1'},
        ... { "PageNumber": `${pageNumber}`},
        ...(  { "PageSize": pageSize})
         
     }
      );

  const [feederData,feederDataError, feederDataLoading] = useFetch(`EntFeederReadings/feeder-details?stnIDfk=${states.stationSelected.stnIDpk}`, refreshFeeder, 'fdrIDpk', false, "powerapp", false, {
    ...(startDate.text !== '' &&  {  "StartDate":startDate.text}),
    ...(endDate.text !== '' &&  { "EndDate": endDate.text}),
    ...(searchText.text !== '' &&  { "SearchText": searchText.text})
});

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const clearFields = () => {
    setStates({
      refresh: false,
      selectedDate: dayjs(),
      voltage: 0,
      current: 0,
      activeInput: 0,
      active: false,
      reactiveInput: 0,
      remarks: "",
      stationSelected: states.stationSelected,
      lineSelected: states.lineSelected,
      selectedRecord: {},
      stRefresh: false,
    });
  };

  const populateFields = (data: any) => {
    setStates(prevState => ({
      ...prevState,
      selectedDate: data?.frdDate ? dayjs(data.frdDate) : dayjs(),
      voltage: data?.frdVoltage ?? 0,
      current: data?.frdCurrent ?? 0,
      activeInput: data?.frdActivePower ?? 0,
      reactiveInput: data?.frdReactivePower ?? 0,
      remarks: data?.frdRmks ?? "",
      selectedRecord: data ?? {},
    }));
  };

  const validation = () => {
    if (
      states.stationSelected.stnIDpk === undefined ||
      states.stationSelected.stnIDpk === null ||
      states.stationSelected.stnIDpk === 0
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please select a station",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else if (
      states.lineSelected.fdrIDpk === undefined ||
      states.lineSelected.fdrIDpk === null ||
      states.lineSelected.fdrIDpk === 0
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please select a feeder",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else if (
      states.selectedDate === undefined ||
      states.selectedDate === null
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please select a date",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else if (
      states.voltage === undefined ||
      states.voltage === null ||
      states.voltage === "" 
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter a voltage",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else if (
      states.current === undefined ||
      states.current === null ||
      states.current === "" 
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter a current",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else if (
      states.activeInput === undefined ||
      states.activeInput === null ||
      states.activeInput === ""
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter active power",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else if (
      states.reactiveInput === undefined ||
      states.reactiveInput === null ||
      states.reactiveInput === ""
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter reactive power",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } else {
      setIcon("question");
    setModalData({
      title: "Save Record",
      message: "Are you sure you want to save this record?",
      okText: "Ok",
      cancelText: "Cancel",
    });
    setOpenModal(true);
      return true;
    }
  };

  const saveAccespriv =async () => {
    validation();
  // if(res){
  //   setIcon("question");
  //   setModalData({
  //     title: "Save Record",
  //     message: "Are you sure you want to save this record?",
  //     okText: "Ok",
  //     cancelText: "Cancel",
  //   });
  //   setOpenModal(true);
  // }
  };
  const updateAccespriv = () => {
    validation();
    setIcon("question");
    setModalData({
      title: "Update Record",
      message: "Are you sure you want to update this record?",
      okText: "Ok",
      cancelText: "Cancel",
    });
    setOpenModal(true);
  };

  const [Posting, Updating, Patching] = useCrudFunc("powerapp");

  const postData = async () => {
    try {

        // const postResponse =   await posting (`EntFeederReadings
            
        //     //             `, {
        //     //             "frdStationIDfk": states.station.id,
        //     //             "frdFeederIDfk": states.feeder.id,
        //     //             "frdTime": states.feederTime,
        //     //             "frdVoltage": states.voltage,
        //     //             "frdCurrent": states.current,
        //     //             "frdActivePower": states.activePower,
        //     //             "frdReactivePower": states.reactivePower,
        //     //             "frdActive": states.active,
        //     //             "frdRmks": states.remarks,
        //     //             "formCode": "string",
        //     //             "formAction": "string"
        //     //         },`Created Feeder Reading`);
      const res = await Posting(`EntFeederReadings`, {
        "frdStationIDfk":states.stationSelected.stnIDpk,
                        "frdFeederIDfk": states.lineSelected.fdrIDpk,
                        "frdTime": states.selectedDate,
                        "frdVoltage": states.voltage,
                        "frdCurrent": states.current,
                        "frdActivePower": states.activeInput,
                        "frdReactivePower": states.reactiveInput,
                        "frdActive": states.active,
                        "frdRmks": states.remarks,
        // lrdStationIDfk: states.stationSelected.stnIDpk,
        // lrdLineIDfk: states.lineSelected.ldtIDpk,
        // lrdTime: states.selectedDate,
        // lrdActivePower: states.activeInput,
        // lrdReactivePower: states.reactiveInput,
        // lrdCurrent: states.current,
        // lrdVoltage: states.voltage,
        // lrdRmks: states.remarks,
      });
      setIcon("success");
      setModalData({
        title: "Record Saved",
        message: "Record saved successfully",
        okText: "Ok",
      });
      setOpenModal(true);
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (error) {
      setIcon("warning");
      setModalData({
        title: "Unexpected Error!",
        message: "Record not saved",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const updateData = async () => {
    try {
      const res = await Updating(
        `EntFeederReadings`,
        {
          frdIDpk: states.selectedRecord.frdIDpk,
          // frdStationIdfk: states.stationSelected.stnIDpk,
          // frdLineIdfk: states.lineSelected.ldtIDpk,
          frdTime: states.selectedDate,
          frdActivePower: states.activeInput,
          frdReactivePower: states.reactiveInput,
          frdCurrent: states.current,
          frdVoltage: states.voltage,
          frdActive: states.active,
          frdRmks: states.remarks,
        }
      );
      setIcon("success");
      setModalData({
        title: "Record Updated",
        message: "Record updated successfully",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setOpenModal(true);
      setRefresh(!refresh);
    } catch (error) {
      setIcon("warning");
      setModalData({
        title: "Unexpected Error!",
        message: "Record failed to update",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const deactivateRecord = async () => {
    try {
      const res = await Patching(
        `EntFeederReadings/UpdateActiveStatusFeederReadings/${states.selectedRecord.frdIDpk}?frdActive=${states.selectedRecord.frdActive? 'false':'true'        }`,
        {}
        // {
        //   frdIDpk: states.selectedRecord.frdIDpk,
        
        // }
      );
      setIcon("success");
      setModalData({
        title: "Record Deactivated",
        message: "Record deactivated successfully",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setOpenModal(true);
      setRefresh(!refresh);
    } catch (error) {
      setIcon("warning");
      setModalData({
        title: "Unexpected Error!",
        message:error.data.message,
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  useEffect(() => {
    // if (Object.keys(states.selectedRecord).length > 0) {
      populateFields(states.selectedRecord);
    // }
  }, [states.selectedRecord]);

  return (
    <section className="w-full h-full">
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save Record"
            ? postData()
            : modalData.title === "Update Record"
            ? updateData()
            : modalData.title === "Deactivate Record"?
            deactivateRecord():
            
            setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "success" || icon === "warning" ? true : false}
      />
      <Switcher alternate={""}>
        {/* top section */}
        <section className="flex space-x-3 w-full border-2 p-2">
          {/* left section */}
          <aside className="w-[22%]">
            <aside className="flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400">
              <p className="text-sm font-medium">STATION</p>
              <RefreshButton
                onClick={() => setState("stRefresh", !states.stRefresh)}
              />
            </aside>
            <aside>
              <Datagrid_template
                gridheight={240}
                showColumnHeaders={false}
                columns={[
                  {
                    id: 1,
                    caption: "STATION",
                    dataField: "stnName",
                  },
                ]}
                data={station_data}
                onRowClick={(e) => {
                  setState("stationSelected", e);
                }}
                disablePaging
                disableSearch
                disableGroupPanel
              />
            </aside>
          </aside>
          {/* right section */}
          <aside className="w-[78%] items-right overflow-hidden">
            <section className="flex items-start ml-[160px]">
              <p className="text-[12px] font-medium mr-2">Feeder:</p>
              <Datagrid_template
                gridheight={100}
                showColumnHeaders={true}
                columns={feederColumns}
                data={feederData}
                onRowClick={(e) => {
                  setState("lineSelected", e);
                }}
                disablePaging
                disableSearch
                disableGroupPanel
              />
            </section>
            <section className="w-full h-full mt-1">
              <section className="flex items-start justify-between">
                <aside className="flex items-start w-full ml-[160px] mb-0.5">
                  <p className="mr-[9px] mt-[2px]">Time : </p>
                  <DateTemplate
                    disabled={form_disable}
                    style={{ width: 230, height: 32 }}
                    showTime
                    // format=" MMM DD, YYYY HH:mm"
                    selectedDate={dayjs(states.selectedDate) ?? dayjs()}
                    changeDate={(e: Dayjs) => {
                      setState("selectedDate", dayjs(e).format());
                    }}
                    className="w-full"
                  />
                </aside>
                <aside>
                  <RefreshButton
                    onClick={() => {
                      clearFields();
                      // setState("selectedRecord", {});
                    }}
                  />
                </aside>
              </section>
              <section className="flex space-x-2 items-center mb-1">
                <aside className="space-y-0.5 ">
                  <section className="flex space-x-1">
                    <aside className="ml-14">
                      <InputsTemplate
                        disabledStatus={form_disable}
                        span
                        useCallbackFunc
                        inputStyle={{ width: 114 }}
                        label="Voltage (kv) & Current (A)"
                        labelColPan={3}
                        defaultValue={states.voltage ?? 0}
                        orderOnchange={(e) => {
                          setState("voltage", e);
                        }}
                      />
                    </aside>
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      inputStyle={{ width: 114 }}
                      defaultValue={states.current ?? 0}
                      orderOnchange={(e) => {
                        setState("current", e);
                      }}
                    />
                  </section>
                  <section className="flex space-x-1 ml-0.5">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      span
                      useCallbackFunc
                      inputStyle={{ width: 114 }}
                      label="Active (kW) & Reactive Power (kVAr)"
                      labelColPan={12}
                      defaultValue={states.activeInput ?? 0}
                      orderOnchange={(e) => {
                        setState("activeInput", e);
                      }}
                    />
                    <InputsTemplate
                      disabledStatus={form_disable}
                      span
                      inputStyle={{ width: 114 }}
                      useCallbackFunc
                      defaultValue={states.reactiveInput ?? 0}
                      orderOnchange={(e) => {
                        setState("reactiveInput", e);
                      }}
                    />

                    {!update_state_change ? (
                      <SaveButton
                        disableButton={form_disable}
                        handleSave={() => {
                          saveAccespriv();
                        }}
                      />
                    ) : (
                      <UpdateButton
                        disableButton={form_disable}
                        handleUpdate={updateAccespriv}
                      />
                    )}
                    {/* <SaveButton disableButton={false} handleSave={() => {}} />
                    <UpdateButton disableButton={false} handleUpdate={() => {}} /> */}
                  </section>
                </aside>
              </section>
              {/* <section className="h-full mt-0.5  "> */}
              {/* <p className="mr-[6px]">Remarks:</p> */}
              <Form.Item
                className="mt-0.5  ml-[148px]"
                label={<p className="text-[12px]">Remarks</p>}
              >
                <div className="w-full pr-2">
                  <TextareaTemplate
                    height={75}
                    useCallbackFunc
                    defaultValue={states.remarks ?? ""}
                    disabled={form_disable}
                    readonly={form_disable}
                    setCallbackValue={(e) => {
                      setState("remarks", e);
                    }}
                  />
                </div>
              </Form.Item>

              {/* <TextareaTemplate
                  height={75}
                  //   labelCol={7}
                  label={"Remarks"}
                  useCallbackFunc
                  defaultValue={states.remarks ?? ""}
                  disabled={false}
                  setCallbackValue={(e) => {
                    setState("remarks", e);
                  }}
                /> */}
              {/* </section> */}
            </section>
          </aside>
        </section>
        <div className="h-1 w-full bg-sky-200" />
      </Switcher>
      {/* botom section */}
      <section className="flex flex-col">
        <LineReadingsToolbar
        startDate={startDate.temp}
        endDate={endDate.temp}
          startDateChanged={(date) => {
            setStartDate({
              temp: dayjs(date).format(genFormat),
              text: startDate.text,
            });
          }}
          endDateChanged={(date) => {
            setEndDate({
              temp: dayjs(date).format(genFormat),
              text: endDate.text,
            });
          }}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, text: searchText.temp });
            setActiveStatus({
              temp: activeStatus.temp,
              status: activeStatus.temp,
            });
            setStartDate({ text: startDate.temp, temp: startDate.temp });
            setEndDate({ text: endDate.temp, temp: endDate.temp });
            setRefresh(current => !current)
          }}
          checkOnChange={(check) => {
             setActiveStatus({ temp: check, status: activeStatus.status });
          }}
          searchTextOnchange={(searchValue) => {
            setSearchText({ temp: searchValue, text: searchText.text });
          }}
          runNew={() => {
            clearFields();
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            setWitchFalse();
          }}
          runUpdate={() => {
            if (Object.keys(states.selectedRecord).length === 0) {
              setIcon("warning");
              setModalData({
                title: "Select Record",
                message: "Please select a record to edit",
                okText: "Ok",
              });
              setOpenModal(true);
              return;
            }
            populateFields(states.selectedRecord);
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            dispatch(setGeneralValue({ expr: "updateMode", value: true }));
            setWitchFalse();
          }}
          handleClose={() => {
            setIcon("question");
            setModalData({
              title: "Deactivate Record",
              message: "Are you sure you want to deactivate this record?",
              okText: "Ok",
              cancelText: "Cancel",
            });
            setOpenModal(true);
            // dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
            // dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          }}
          handleSwitcher={setWitch}
          handleRefresh={() => {
            setRefresh(!refresh);
            setStartDate({ text: startDate.temp, temp: startDate.temp });
            setEndDate({ text: endDate.temp, temp: endDate.temp });
            setSearchText({ temp: "", text: "" });
            setActiveStatus({ temp: "", status: "" });
            setSearchValue("");
          }}
          searchValue={searchText.temp}
           stationData={station_data}
        />
        <section className="flex-grow">
          <section className="w-full h-full">
            {status ? (
              <ReadingsChart />
            ) : (
              <Datagrid_template
              handlePageSize={(size)=>{
                setPageSize(size)
                  setRefresh(current => !current)
            }}
                gridheight={
                  !switchStatus?
                  380: 700}
                showColumnHeaders={true}
                columns={feederColumns}
                onRowClick={(e) => {
                  setState("selectedRecord", e);
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: false })
                  );
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );
                }}
                rowDoubleClicked={(e) => {
                  setState("selectedRecord", e);
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: true })
                  );
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: false })
                  );
                  setWitchFalse();
                }}
                handlePageNumber={(e: any) => {
                  setPageNumber(e)
                  setRefresh(current => !current)
            }}
               
                data={feedData}
                // disablePaging
                // disableGroupPanel
              />
            )}
          </section>
        </section>
      </section>
    </section>
  );
}






// /* eslint-disable react/jsx-pascal-case */
// import { Switcher } from "../../_widget/switcher";
// import { RefreshButton } from "../../_widget/utils";
// import Datagrid_template from "../../../../../../templates/Datagrid";
// import { SaveButton } from "../../../../../accessories/buttons";
// import { DateTemplate } from "../../../../../../templates/date";
// import { feeder, feederColumns, stationEntries, stationEntriesColumns } from "../BusVoltage/_data/stationentriescolumns";
// import MiniToolbar from "../../_widget/minitollbar";
// import { InputsTemplate } from "../../../../../../templates/input";
// import { TextareaTemplate } from "../../../../../../templates/textarea";
// import { useGraphSwitcher } from "../../db/useSwitcher";
// import { ReadingsChart } from "../../_widget/readingschart";
// import { modalPropTypes, modalTypes, searchTextTypes, validateInstanceTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
// import React, { useEffect, useState } from "react";
// import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
// import { useCrudFunc } from "../../../../../../functions/crud";
// import { saveModalData } from "../../../../../accessories/component_infos";
// import { ModalTemplate } from "../../../../../../templates/modal";
// import { useDispatch } from "react-redux";
// import useFetch from "../../../../../../hooks/useFetch";
// import dayjs from "dayjs";


// interface stateTypes{
//     feederTime:string,
//     voltage:string,
//     current:string,
//     activePower:string,
//     reactivePower:string,
//     station: any,
//     feeder:any,
    
//     active:boolean,
//     remarks:string,
    
  
//     selectedStationId:0,
//         selectedFeederId:0,
//     confirmModal:modalPropTypes
//     warningModal:modalPropTypes
//     successModal:modalPropTypes
//   }

// export default function FeederReadings() {
//     const { status } = useGraphSwitcher();
//     const [refresh, setrefresh] = useState<boolean>(false);
//     const [refreshFeeder, setrefreshFeeder] = useState<boolean>(false);
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     // const [searchText, setSearchText] = useState('');
//     const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' })
   

//     const updateState = (key: string, value: any) => {
//         setStates((prev: any) => ({ ...prev, [key]: value }));
//       };

//       const disableConfirmModal=()=>{
//         updateState('confirmModal',{
//             state:false,
//             title:'',
//             message:'',
//             action:'post'
//         })
//     }


    
//    //post data
//    const [posting , updating]=useCrudFunc("powerapp");

//    const [allowUpdate,setAllowUpdate]=useState(false);

//    const [busyLoader, setBusyLoader] = useBusyLoader();

//     const[states,setStates]=useState<stateTypes>({
//         feederTime:'',
//         voltage:'',
//         activePower:'',
//         current:'',
//         reactivePower:'',
//         station: {name:'', id:""},
//         feeder:{name:'', id:''},
       
//         active:false,
//         remarks:'',
    
//         selectedStationId:0,
//         selectedFeederId:0,
    
//         confirmModal:{state:false,title:'',message:'',action:'post'},
//         warningModal:{state:false,title:'',message:''},
//         successModal:{state:false,title:'',message:''}
//     })

//     const postData = async (action:'validate'|'post') => {
//         if (action==='validate') {
//             // validate fieldds
//             validateFields() &&
//             updateState('confirmModal',{
//                 state:true,
//                 title:'Save Record',
//                 message:'Are you sure you want to save this record?',
//                 action:'post'
//             })
//             return;
//         }
 
//         //post
//         disableConfirmModal();
//         setBusyLoader(saveModalData.progress)
//       //post request here
//     //   setModal("state", false);
//       // refreshGrid();
//       // setBusyLoader(saveModalData.progress);
//       try {
//         const postResponse =   await posting (`EntFeederReadings
            
//             `, {
//             "frdStationIDfk": states.station.id,
//             "frdFeederIDfk": states.feeder.id,
//             "frdTime": states.feederTime,
//             "frdVoltage": states.voltage,
//             "frdCurrent": states.current,
//             "frdActivePower": states.activePower,
//             "frdReactivePower": states.reactivePower,
//             "frdActive": states.active,
//             "frdRmks": states.remarks,
//             "formCode": "string",
//             "formAction": "string"
//         },`Created Feeder Reading`);
//         setrefresh(!refresh);
//         // setRefresh(!refresh);
//         // setsuccesModalState({
//         //   state: true,
//         //   message: "Record saved successfully",
//         //   title: "Save Successful",
//         // });
//         // setBusyLoader("");
//         // populateFromGrid(false);
//         setrefresh(!refresh);
       
//         setStates({
//             feederTime:'',
//             voltage:'',
//             activePower:'',
//             current:'',
//             reactivePower:'',
//             station: {name:'', id:""},
//             feeder:{name:'', id:''},
           
//             active:false,
//             remarks:'',
        
//             selectedStationId:0,
//             selectedFeederId:0,
        
//             confirmModal:{state:false,title:'',message:'',action:'post'},
//             warningModal:{state:false,title:'',message:''},
//             successModal:{state:false,title:'',message:''}
//         })
//      updateState('successModal',{
//        state:true,
//        title:`Save Successful`,
//        message:`Record saved successfully`,
//    })
      
//        //  populateStatesFromGrid(false);
//     } catch (error) {
//         console.log(error);
        
//         updateState('warningModal',{
//             state: true,
//             title: saveModalData.error.title,
//             message: saveModalData.error.message
//         })
//     } finally{
//         setBusyLoader('');
//     }
   
//     return;
//     };

//      //validate fields
//    const validateFields=():boolean=>{
//     const validationInstances:validateInstanceTypes[]=[
//       {state:states.station.id,baseValue:'',modalProps:{state:true,title:'Select Station',message:'Please select a station'}},
//       {state:states.feeder.id ,baseValue:'',modalProps:{state:true,title:'Select Feeder',message:'Please select a feeder'}},
        
      
//       {state:states.feederTime,baseValue:'',modalProps:{state:true,title:'Enter Time',message:'Please select a time'}},
//         {state:states.voltage,baseValue:'',modalProps:{state:true,title:'Enter Voltage',message:'Please enter voltage'}},
//         {state:states.current,baseValue:'',modalProps:{state:true,title:'Enter Current',message:'Please enter a current'}},
//         {state:states.activePower,baseValue:'',modalProps:{state:true,title:'Enter Active Power',message:'Please enter active power'}},
//         {state:states.reactivePower,baseValue:'',modalProps:{state:true,title:'Enter Reactive Power',message:'Please enter reactive power'}},
//         // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
//     ]

//     for(let instance of validationInstances) if (instance.state===instance.baseValue) {
//         const{modalProps}=instance;
//         updateState('warningModal',{
//             state:modalProps.state,
//             title:modalProps.title,
//             message:modalProps.message
//         })
//         return false;
//     }
//     return true;
// }

// const updateData = async (action:'validate'|'update') => {
//     if (action==='validate') {
//         // validate fieldds
//         validateFields() &&
//         updateState('confirmModal',{
//             state:true,
//             title:'Update Record',
//             message:'Are you sure you want to update this record?',
//             action:'update'
//         })
//         return;
//     }

//     //post
//     disableConfirmModal();
//     setBusyLoader(saveModalData.progress)
//   //post request here
// //   setModal("state", false);
//   // refreshGrid();
//   // setBusyLoader(saveModalData.progress);
//   try {
//     const postResponse =   await updating (`EquipmentOutage/UpdateEquipmentOutage`, {
//         "frdStationIDfk": 0,
//         "frdFeederIDfk": 0,
//         "frdTime": "2024-08-13T10:43:59.779Z",
//         "frdVoltage": 0,
//         "frdCurrent": 0,
//         "frdActivePower": 0,
//         "frdReactivePower": 0,
//         "frdActive": true,
//         "frdRmks": "string",
//      "eotCreatedBy": 0,
//         "formCode": "string",
//         "formAction": "string"
//     },`Created Bus Detail`);
//     // setrefresh(!refresh);
//     // setRefresh(!refresh);
//     // setsuccesModalState({
//     //   state: true,
//     //   message: "Record saved successfully",
//     //   title: "Save Successful",
//     // });
//     // setBusyLoader("");
//     // populateFromGrid(false);
     
//     setrefresh(!refresh);
//     setAllowUpdate(false);
//     setStates({
//         feederTime:'',
//         voltage:'',
//         activePower:'',
//         current:'',
//         reactivePower:'',
//         station: {name:'', id:""},
//         feeder:{name:'', id:''},
       
//         active:false,
//         remarks:'',
    
//         selectedStationId:0,
//         selectedFeederId:0,
    
//         confirmModal:{state:false,title:'',message:'',action:'post'},
//         warningModal:{state:false,title:'',message:''},
//         successModal:{state:false,title:'',message:''}
//     })
//  updateState('successModal',{
//   state:true,
//   title:`Update Successful`,
//   message:`Record updated successfully`,
// })
//    //  populateStatesFromGrid(false);
// } catch (error) {
//     console.log(error);
    
//     updateState('warningModal',{
//         state: true,
//         title: saveModalData.error.title,
//         message: saveModalData.error.message
//     })
// } finally{
//     setBusyLoader('');
// }
// return}

//   // modal instances
//   const modals:modalTypes[] = [
//     //validation
//     {disableCancel : true , icon : 'warning' ,open : states.warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : states.warningModal.message, okText : 'Ok', title : states.warningModal.title,cancelText : 'No, cancel'},
    
//     //confirmation
//     {disableCancel : false , icon : 'question' ,open : states.confirmModal.state, okHandler : states.confirmModal.action==='update'?()=>updateData('update'):()=>postData('post'), cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : states.confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

//     //success
//     {disableCancel : true , icon : 'success' ,open : states.successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : states.successModal.message, okText : 'Ok', title : states.successModal.title,cancelText : 'No, cancel'},
// ]

// const dispatch = useDispatch();


// const [stationData,stationDataError, stationDataLoading] = useFetch(`EntFeederReadings/stations`, refresh, 'stnIDpk', false, "powerapp");
// const [feederData,feederDataError, feederDataLoading] = useFetch(`EntFeederReadings/feeder-details?stnIDfk=${states.station.id}`, refreshFeeder, 'fdrIDpk', false, "powerapp", false, {
//     ...(startDate !== '' &&  {  "StartDate":startDate}),
//     ...(endDate !== '' &&  { "EndDate": endDate}),
//     ...(searchText.text !== '' &&  { "SearchText": searchText.text})
// });

  
    

//     return (
//         <>
//           {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
//             return(
//                 <div key = {index}>
//                     <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
//                 </div>
//             )
//         })}

        
//         <section className="w-full h-full" >

//             <Switcher alternate={''} >
//                 {/* top section */}
//                 <section className='flex space-x-3 w-full border-2 p-2'>
//                     {/* left section */}
//                     <aside className='w-[22%]'>
//                         <aside className='flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400'>
//                             <p className='text-sm font-medium'>STATION</p>
//                             <RefreshButton />
//                         </aside>
//                         <aside>
//                             <Datagrid_template
//                                 gridheight={240}
//                                 showColumnHeaders={false}
//                                 columns={[{
//                                     id: 1,
//                                     caption: "STATION",
//                                     dataField: "stnName",
//                                 },]}
//                                 data={stationData}
//                                 onRowClick={(e) => {
//                                   console.log(e);
//                                   updateState('station', {
//                                     id: e['stnIDpk'],
//                                     name: e['stnName'!],
//                                   });
//                             }}
//                                 disablePaging
//                                 disableSearch
//                                 disableGroupPanel
//                             />
//                         </aside>
//                     </aside>
//                     {/* right section */}
//                     <aside className='w-[78%] items-right overflow-hidden'>
//                         <section className='flex items-start ml-[160px]'>
//                             <p className='text-[12px] font-medium mr-2'>Feeder:</p>
//                             <Datagrid_template
//                                 gridheight={100}
//                                 showColumnHeaders={true}
//                                 columns={feederColumns}
//                                 data={feederData}
//                                 disablePaging
//                                 disableSearch
//                                 disableGroupPanel
//                                 onRowClick={(e) => {
//                                     console.log(e);
//                                     updateState('feeder', {
//                                       id: e['fdrIDpk'],
//                                       name: e['fdrName'!],
//                                     });
//                               }}
//                             />
//                         </section>
//                         <section className='w-full h-full mt-1'>
//                             <section className="flex items-start justify-between">
//                                 <aside className="flex items-start w-full ml-[168px] mb-0.5">
//                                     <p className="mr-[6px]">Time:</p>
//                                     <DateTemplate disabled={false} style={{ width: 230, height: 32 }} className="w-full"
//                                      selectedDate={states.feederTime===''?dayjs():dayjs( states.feederTime)}
//                                      changeDate={(e) => {
//                                        updateState('feederTime', e);
                                       
//                                      }}
//                                     />
//                                 </aside>
//                                 <aside>
//                                     <RefreshButton onClick={()=>{
//                                         setrefreshFeeder(!refreshFeeder)
//                                     }} />
//                                 </aside>
//                             </section>
//                             <section className="flex space-x-2 items-center">
//                                 <aside className="space-y-0.5 ">
//                                     <section className="flex space-x-1">
//                                         <aside className="ml-14">
//                                             <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Voltage (kv) & Current (A)" labelColPan={3}
//                                              useCallbackFunc
//                                              orderOnchange={(e)=>{
//                                                updateState('voltage'!, e)
//                                              }}
//                                              defaultValue={states.voltage}
//                                             />
//                                         </aside>
//                                         <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }}
                                        
//                                         useCallbackFunc
//                                         orderOnchange={(e)=>{
//                                           updateState('current'!, e)
//                                         }}
//                                         defaultValue={states.current} />
//                                     </section>
//                                     <section className="flex space-x-1 ml-0.5">
//                                         <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Active (kW) & Reactive Power (kVAr)" labelColPan={12} 
//                                          useCallbackFunc
//                                          orderOnchange={(e)=>{
//                                            updateState('activePower', e)
//                                          }}
//                                          defaultValue={states.activePower}
//                                         />
//                                         <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }}
//                                          useCallbackFunc
//                                          orderOnchange={(e)=>{
//                                            updateState('reactivePower', e)
//                                          }}
//                                          defaultValue={states.reactivePower}
//                                         />
//                                         <SaveButton  
//                                          disableButton={false}
//                                          useCallbackFunc={true}
//                                          handleSave={()=>{
//                                              postData("validate") 
//                                             //  updateData("validate");
//                                              ;
//                                          }}
//                                         />
//                                     </section>
//                                 </aside>
//                             </section>
//                             <section className="h-full mt-0.5  ml-[205px]">
//                                 <TextareaTemplate height={75}
//                                  defaultValue={states.remarks}
//                                  useCallbackFunc
//                                 setCallbackValue={(e) => {
//                                   updateState('remarks', e);
//                                  }}
//                                 />
//                             </section>
//                         </section>
//                     </aside>
//                 </section>
//                 <div className='h-1 w-full bg-sky-200' />
//             </Switcher>
//             {/* botom section */}
//             <section className='flex flex-col'>
//                 <MiniToolbar showEditIcon={true}
//                 runNew={()=>{}}
//             runCancel={()=>{}}
//             runUpdate={()=>{}}
//             searchTextOnchange={(val)=>{
//                 setSearchText({ temp: val, text: searchText.temp });
                
//             }}
//             handleFind={()=>{
//                 setSearchText({ temp: searchText.temp, text: searchText.temp });
//                 // setStatus({ temp: status.temp, status: status.temp })
//                 setrefreshFeeder(current => !current)
           
//             }}
            
//                 />
//                 <section className='flex-grow'>
//                     <section className='w-full h-full'>
//                         {
//                             status ? <ReadingsChart /> :
//                                 <Datagrid_template
//                                     gridheight={415}
//                                     showColumnHeaders={true}
//                                     columns={feederColumns}
//                                     data={feederData}
//                                     disablePaging
//                                     disableGroupPanel
//                                 />}
//                     </section>
//                 </section>
//             </section>
//         </section>
//         </>
//     );
// }