import { Checkbox, DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import alter from "../../../assets/alter.png";
import excel from "../../../assets/excel.png";
import printer from "../../../assets/printer.png";
import question from "../../../assets/question.png";
import refill from "../../../assets/refill.png";
import refresh from "../../../assets/refresh.png";
import statusLoader from "../../../assets/statusloader.gif";
import switch_chart from "../../../assets/switch-chart.png";
import tag from "../../../assets/tag.png";
import update from "../../../assets/update.png";
import { setGeneralValue, setShowChart } from "../../../features/generalSlice";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
import { saveModalData } from "../component_infos";
import { SMSAlerts } from "./widgets/entriesModal/SMSAlerts";
interface props {
  toggler?: () => void;
  importExcel?: () => void;
  handleChange?: (value: string) => void;
  handleChart?: () => void;
  UrlDatax?: any;
  handleFind?: () => void;
  handleDriver_Code?: (e: boolean) => void;
  handleMonthCol?: (e: boolean) => void;
  updateCanData?: () => void;
  updateRefillData?: () => void;
}

interface stateTypes {
  searchCriteria?: { id: number; name: string };
  searchText1?: string;
  searchText2?: any;
  activeStatus?: boolean | string;
  staffStatus?: boolean;
  startDate?: string;
  endDate?: string;
  showChartToogle?: boolean;
  commentMessage?: string;
  commentDate?: string;
}

export const Fuel_transactionuploadtoolbar = ({
  toggler,
  handleChange,
  importExcel,
  handleChart,
  UrlDatax,
  handleFind,
  handleDriver_Code,
  handleMonthCol,
  updateCanData,
  updateRefillData,
}: props) => {
  const { Search } = Input;

  const dateFormat = " YYYY-MMM-DD HH:mm:ss";
  const today = dayjs().format("YYYY-MM-DDTHH:mm:ss");
  const initialStart = dayjs()
    .subtract(6, "year")
    .startOf("year")
    .format("YYYY-MM-DDTHH:mm:ss");
  const dispatch = useDispatch();

  const [states, setStates] = useState<stateTypes>({
    searchCriteria: { id: 0, name: "Reg No" },
    searchText1: "",
    searchText2: {},
    activeStatus: "",
    staffStatus: false,
    startDate: initialStart,
    endDate: today,
    showChartToogle: false,
    commentMessage: "",
    commentDate: dayjs().format(),
  });

  const [triggerFindData, setTriggerFindData] = useState(false);

  console.log(states);

  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>("");

  const onActiveChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
      setState("activeStatus", true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
      setState("activeStatus", false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
      setState("activeStatus", "");
    }
  };

  const [LoadFind, setLoadFind] = useState<boolean>(false);

  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const handleChanges = (value: any) => {
    let conv = JSON.parse(value);

    setState("searchCriteria", { id: conv.id, name: conv.label });
  };
  const [refreshtoggle, setRefreshtoggle] = useState<boolean>(false);

  const findData = () => {
    setLoadFind(!LoadFind);
    dispatch(setGeneralValue({ expr: "LoadFind", value: LoadFind }));
    let searchUrl = `FuelTransactionsCardDataUpload/GetAllVehicleTransactionUpload?EndDate=${
      states.endDate
    }&StartDate=${states.startDate}&SearchText1=${
      states.searchText1
    }&CriteriaIndex=${states.searchCriteria.id}&Active=${
      states.activeStatus
    }&PageSize=${20}&PageNumber=${1}`;
    if (
      ["Quantity", "Amount", "Balance", "Mileage"].includes(
        states.searchCriteria?.name
      )
    ) {
      searchUrl += `&SearchText2=${states.searchText2}`;
    }

    handleChange!(searchUrl);

    switch (states.searchCriteria?.name) {
      case "Reg No":
      case "Card No":
      case "Driver Code":
      case "Product":
        handleChange!(
          `FuelTransactionsCardDataUpload/GetAllVehicleTransactionUpload?EndDate=${
            states.endDate
          }&StartDate=${states.startDate}&SearchText1=${
            states.searchText1
          }&CriteriaIndex=${states.searchCriteria.id}&Active=${
            states.activeStatus
          }&PageSize=${20}&PageNumber=${1}`
        );
        break;
      case "Quantity":
      case "Amount":
      case "Balance":
      case "Mileage":
        handleChange!(
          `FuelTransactionsCardDataUpload/GetAllVehicleTransactionUpload?EndDate=${
            states.endDate
          }&StartDate=${states.startDate}&SearchText1=${
            states.searchText1
          }&SearchText2=${states.searchText2}&CriteriaIndex=${
            states.searchCriteria.id
          }&Active=${states.activeStatus}&PageSize=${20}&PageNumber=${1}`
        );
        break;
      default:
        handleChange!(``);
        break;
    }
  };

  const refreshGrid = () => {
    setStates((prevStates) => ({
      ...prevStates,
      searchText1: "",
      searchText2: "",
      searchCriteria: { id: 0, name: "Reg No" },
      activeStatus: "",
    }));
    setTriggerFindData(false);
    setTimeout(() => setTriggerFindData(true), 0);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showCancel, setshowCancel] = useState(false);

  const excelAccessPriv = () => {
    if (savePriv === true) {
      importExcel?.();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const findAccessPriv = () => {
    if (readPriv === true) {
      findData();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const exportAccessPriv = () => {
    if (readPriv === true) {
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const validation = () => {
    if (dayjs(states.commentDate).isAfter(dayjs(states.endDate))) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        title: "Invalid Date!",
        message: "Comment date cannot be a future date",
      });
      return false;
    } else if (states.commentMessage === "") {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        title: "Invalid Input!",
        message: "Message cannot be empty",
      });
      return false;
    } else {
    }
  };

  useEffect(() => {
    setTriggerFindData(true); // Trigger data load on initial render
  }, []);

  useEffect(() => {
    if (triggerFindData) {
      findData();
      setTriggerFindData(false); // Reset the trigger
    }
  }, [triggerFindData, states]);
  return (
    <>
      <div>
        <div>
          <SMSAlerts
            open={openModal2}
            cancelHandler={() => {
              setOpenModal2(false);
            }}
            okText="Ok"
            title={"Tag Comment"}
            useCallFunc
            isWorkShop={false}
            setCallbackValue={(value: any) => {
              setState("commentMessage", value);
            }}
            disabledTextArea={false}
            sendMail={() => {
              validation();
            }}
            defaultDate={states.commentDate}
            setDateState={(date: any) => {
              setState("commentDate", date);
            }}
            meslabel="Comment"
          />
        </div>
      </div>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={!showCancel}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{ width: "4000px", borderColor: borderTheme }}
        className=" h-full border-[1px] rounded flex  border-gray-200 mt-0.5 mb-1 mx-1"
      >
        <div className=" px-1  flex justify-center">
          <ul className=" flex items-center h-full py-0.5">
            <li
              id="excel"
              onClick={excelAccessPriv}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={excel}
                />
              </span>
              <Tooltip
                target="#excel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Excel</p>
              </Tooltip>
            </li>

            <li
              id="switch"
              onClick={toggler}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch interface / table view</p>
              </Tooltip>
            </li>

            <li id="driver" className="px-0.5 mr-1">
              <Checkbox
                onChange={(e) => {
                  handleDriver_Code && handleDriver_Code(e.target.checked);
                }}
                style={{ borderColor: borderTheme }}
                className="border-[1px] rounded px-1"
              />
              <Tooltip
                target="#driver"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Show Driver Code column</p>
              </Tooltip>
            </li>

            <li id="month" className="px-0.5 mr-1">
              <Checkbox
                onChange={(e) => {
                  handleMonthCol && handleMonthCol(e.target.checked);
                }}
                style={{ borderColor: borderTheme }}
                className="border-[1px] rounded px-1"
              />
              <Tooltip
                target="#month"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Show month column</p>
              </Tooltip>
            </li>

            <li
              id="update"
              onClick={updateCanData}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={update}
                />
              </span>
              <Tooltip
                target="#update"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">
                  Update cancelled transactions and Delete duplicate
                  transactions
                </p>
              </Tooltip>
            </li>

            <li
              id="refill"
              onClick={updateRefillData}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={refill}
                />
              </span>
              <Tooltip
                target="#refill"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Update Refill Intervals</p>
              </Tooltip>
            </li>

            <li
              id="switch_chart"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              onClick={() => {
                setState("showChartToogle", !states.showChartToogle);
                dispatch(setShowChart(states.showChartToogle));
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={switch_chart}
                />
              </span>
              <Tooltip
                target="#switch_chart"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch between charts and table views</p>
              </Tooltip>
            </li>
            <li
              id="tag"
              onClick={() => setOpenModal2(true)}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={tag}
                />
              </span>
              <Tooltip
                target="#tag"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Tag for investigative comments</p>
              </Tooltip>
            </li>

            <li
              id="help"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target="#help"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Help Center</p>
              </Tooltip>
            </li>

            {/* Differents starts here */}

            <li className="flex items-center ml-2 ">
              <label className="flex text-xs px-2">Search Criteria</label>
              <Select
                style={{ width: `${sideAlert === true ? 20 : 100}` }}
                onChange={handleChanges}
                value={states.searchCriteria?.name}
                size="small"
                dropdownStyle={{ minWidth: "250px" }}
              >
                {data?.map((option: any, index: number) => {
                  return (
                    <Select.Option key={index} value={JSON.stringify(option)}>
                      {option.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </li>

            <li className="px-2 flex items-center ml-3 ">
              <label className="flex text-xs px-2">Search Text</label>
              <Input
                name="search"
                id="search"
                size="small"
                style={{ width: "130px" }}
                className="h-6"
                onChange={(e: any) => {
                  setState("searchText1", e.target.value);
                }}
                value={states.searchText1}
              />
            </li>

            <li className="px-0.5">
              <DatePicker
                allowClear={false}
                size="small"
                style={{ width: 125 }}
                format={dateFormat}
                onChange={(date: any, dateString: any) => {
                  setState("startDate", dateString);
                }}
                value={dayjs(states.startDate)}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                allowClear={false}
                size="small"
                style={{ width: 125 }}
                format={dateFormat}
                onChange={(date: any, dateString: any) => {
                  setState("endDate", dateString);
                }}
                value={dayjs(states.endDate)}
              />
            </li>
            <li className="px-0.5 mr-1">
              <Checkbox
                style={{ borderColor: borderTheme }}
                className="border-[1px] rounded px-1"
                onChange={(e) => {
                  onActiveChange(e.target.checked);
                }}
                checked={checkedBox}
                indeterminate={indeterminate}
              />
              <Tooltip
                target="#active_status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Active status</p>
              </Tooltip>
            </li>

            <li
              onClick={refreshGrid}
              style={{ borderColor: borderTheme }}
              className="border-[1px] hover:cursor-pointer mr-1 p-1 border-slate-200 text-xs text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  alt=""
                  className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={refresh}
                />{" "}
              </span>
            </li>
            <li
              id="find"
              onClick={() => {
                findAccessPriv();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <FaBinoculars size={15.5} color="#007AFF" />{" "}
              </span>
              <Tooltip
                target="#find"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Find</p>
              </Tooltip>
            </li>
            {/* <li id="print" style={{ borderWidth: '1px',borderColor:borderTheme }} onClick={exportAccessPriv} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                        <span> <img alt="" className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={printer} /> </span>
                        <Tooltip
                            target="#print"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p>Print / Export</p>
                        </Tooltip>
                    </li> */}

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {disablex && (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}

              {!disablex && (
                <div className="  flex flex-row items-center">
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  {!updateMode ? (
                    <p className=" italic text-gray-600">New Record</p>
                  ) : (
                    <p className=" italic text-gray-600">Editing</p>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const data = [
  { id: 0, label: "Reg No", value: "Reg No" },
  { id: 1, label: "Card No", value: "Card No" },
  { id: 2, label: "Driver Code", value: "Driver Code" },
  { id: 3, label: "Product", value: "Product" },
  { id: 4, label: "Quantity", value: "Quantity" },
  { id: 5, label: "Amount", value: "Amount" },
  { id: 6, label: "Balance", value: "Balance" },
  { id: 7, label: "Mileage", value: "Mileage" },
  { id: 8, label: "Receipt No", value: "Receipt No" },
  { id: 9, label: "Station No", value: "Station No" },
  { id: 10, label: "Place", value: "Place" },
  { id: 11, label: "Refill interval", value: "Refill interval" },
  { id: 12, label: "Tagged", value: "Tagged" },
];
