import { Form, Radio } from "antd";
import React from "react";
import { DateTemplate } from "../../../templates/date";
import InputSelect_template from "../../../templates/InputSelect";
import { TextareaTemplate } from "../../../templates/textarea";
import Datagrid_template from "../../../templates/Datagrid";
import { NewButton, SaveButton } from "../../accessories/buttons";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

export const Oto_details = () => {
    const borderTheme = useSelector(
        (state: RootState) => state.general.cssBorderClass
      );
  return (
    <div className="w-full h-full ">
      <div>
        <p className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
          {"OTO Details"}
        </p>
      </div>
      <div className=" border-b-1 justify-center bg-orange-100  py-1 pl-2 flex gap-2">
        <p className="font-medium">NOTE:</p>
        <p className="font-medium text-wrap">
          - Orders to operate must be performed in the number of sequence listed
          <br></br> - A group of the operators listed under the number may be
          performed in any sequence
          <br></br> - Apparatus must be in the position stated in the
          "OPERATION" column
        </p>
      </div>
      <section className="w-full flex ">
        <aside className="w-[40%]">
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 mr-3"
            name="basic"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div className="w-full">
              <DateTemplate label="Prepared On" />
            </div>
            <div className="w-full">
              <DateTemplate label="Checked On" />
            </div>
            <div className="w-full">
              <DateTemplate label="Ordered On" />
            </div>
            <div className="w-full">
              <DateTemplate label="Completed On" />
            </div>
            <div className="w-full">
              <DateTemplate label="Mimic Board Changed On" />
            </div>
          </Form>
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  mr-3"
            name="basic"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div>
              <InputSelect_template label="Station" />
            </div>
            <div>
              <InputSelect_template label="Applications" />
            </div>
            <div>
              <InputSelect_template label="Prepared By" />
            </div>
            <div>
              <InputSelect_template label="Checked By" />
            </div>
            <div>
              <InputSelect_template label="Ordered By" />
            </div>
            <div>
              <InputSelect_template label="Completed By" />
            </div>
            <div>
              <InputSelect_template label="Pin/Mimic Board Changed By" />
            </div>
          </Form>
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded w-full"
            name="basic"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div className="flex  w-full py-[2px]  ">
              <Form.Item
                label={<p className="text-xs">{"Purpose"}</p>}
                style={{ marginBottom: 0 }}
                // labelCol={{span:18}}
                className=" w-full mr-2"
              >
                <div style={{borderColor:borderTheme}} className="w-[115%] border">
                  <Radio.Group>
                    <div className=" flex space-x-20 w-full ml-1">
                      <Radio value="Isolation">Isolation</Radio>
                      <Radio value="Preparation">Preparation</Radio>
                    </div>
                  </Radio.Group>
                </div>
              </Form.Item>
            </div>

            <div className="mr-2">
              <TextareaTemplate
                height={50}
                label={"Purpose Of Order"}
                labelCol={9}
              />
            </div>
            <div className="flex py-2 gap-2 justify-end mr-2">
              {/* {!updateMode ? ( */}
              <div>
                <SaveButton
                  //   disableButton={disabled}
                  handleSave={() => {}}
                />
              </div>
              {/* ) : ( */}
              {/* <div>
                    <UpdateButton
                      disableButton={disabled}
                      handleUpdate={updateFunction}
                    />
                  </div> */}
              {/* )} */}
              {/* {disabled ? ( */}
              <div>
                <NewButton new_button_toggler={() => {}} />
              </div>
              {/* ) : ( */}
              {/* <div>
                    <Cancel
                      cancel_button_toggler={() => {
                        dispatch(
                          setGeneralValue({
                            expr: "clearF",
                            value: false,
                          })
                        );
                      }}
                    />
                  </div>
                )} */}
            </div>
          </Form>
        </aside>
        <aside className="w-[60%] ">
          <div className="w-[100%]">
            <Datagrid_template
              gridheight={435}
              columns={[]}
              data={[]}
              disableGroupPanel
              disablePaging
              disableSearch
            />
          </div>
        </aside>
      </section>
    </div>
  );
};
