import React from "react";
import { DateTemplate } from "../../../../../templates/date";
import { DropdownBox_template } from "../../../../../templates/DropdownBox";
import InputSelect_template from "../../../../../templates/InputSelect";
import { Form } from "antd";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { AppraiseButton, NewButton, SaveButton } from "../../../../accessories/buttons";

const Taskdetailsform = () => {
  return (
    <div className="w-full h-full border-1">
      <header className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
        Task Details
      </header>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 flex "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <div className="w-full">
          <DateTemplate showTime disabled={false} label="Start Date" />
        </div>

        <div className="w-full">
          <DateTemplate showTime disabled={false} label="Due Date" />
        </div>

        {/* <InputSelect_template
        label="Assigned By"
        //   selectStyle={{ height: 25, width: 320 }}
          disabled={false}
        /> */}
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <InputSelect_template
          label="Assigned By"
          //   selectStyle={{ height: 25, width: 320 }}
          disabled={false}
        />
        <InputSelect_template
          //   selectStyle={{ height: 25, width: 320 }}
          disabled={false}
          label="Assigned To"
        />
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded flex "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 4 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <div className="w-full">
          <InputSelect_template disabled={false} label="Status" />
        </div>

        <div className="w-full">
          <InputSelect_template disabled={false} label="Priority" />
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded flex "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <div className="w-full">
          <InputSelect_template
            disabled={false}
            label="Criteria/Objective"
            span={true}
            wrapperCol={12}
         
          />
        </div>

        <div className="w-full">
          <InputSelect_template disabled={false} wrapperCol={25} labelCol={2} />
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded "
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <div>
          <InputSelect_template disabled={false} label="Initiative" />
        </div>
        <div>
          <InputSelect_template disabled={false} label="Title" />
        </div>
        <div>
          <TextareaTemplate
            disabled={false}
            label="Description"
            height={60}
            labelCol={4}
          />
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex  rounded "
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <div className="w-full">
          <CheckboxTemlate label={"Scoring"} withBorders labelCol={12} />
        </div>
        <div className="w-full  flex justify-center">
            <AppraiseButton />
        </div>
        <div className="w-full flex  justify-end gap-2" >
            <NewButton/>
            <SaveButton/>
        </div>
      </Form>

      {/* <div className="flex items-center space-x-2  ">
        <InputSelect_template
          selectStyle={{ height: 25, width: 320 }}
          disabled={false}
        />
      </div>
      <section className="space-x-3 p-1 w-full overflow-x-clip">
        <aside className="flex items-center space-x-2 ml-8">
          <label>Status:</label>
          <InputSelect_template
            selectStyle={{ height: 25, width: 100 }}
            disabled={false}
          />
        </aside>
        <aside className="flex items-center ">
          <label>Priority</label>
          <InputSelect_template
            selectStyle={{ height: 25, width: 100 }}
            disabled={false}
          />
        </aside>
      </section> */}
    </div>
  );
};

export default Taskdetailsform;
