/* eslint-disable jsx-a11y/alt-text */
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import swap from "../../../../../../assets/swap.png";

import edit from "../../../../../../../../assets/edit.png";
import newfile from "../../../../../../../../assets/new.png";
import printer from "../../../../../../../../assets/printer.png";
import reset from "../../../../../../../../assets/refresh-small.png";
import alter from "../../../../../../../../assets/alter.png";
import close from "../../../../../../../../assets/close.png";

import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import { FaBinoculars } from "react-icons/fa";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import cx from "classix";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../../features/generalSlice";
import { CheckboxTemlate } from "../../../../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../../../../templates/date";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { saveModalData } from "../../../../../../../accessories/component_infos";
import {
  DeleteTask,
  TaskSettings,
  AlertSMS,
  AppraiseSettings,
} from "../../../../../../../accessories/custom-toolbars/widgets/customToolbarModals";

interface props {
  toggler?: () => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  startDate?: string;
  endDate?: string;
  searchCriteriaData?: { id: number; value: string }[];
  onNewClick?: () => void;
  switch_change?: any;
  searchValue?: string;
}

export const AssetDetailsToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  setOuterSelected,
  searchValue,
  outerSelected,
  searchCriteriaOnchange,
  startDateChanged,
  endDateChanged,
  searchTextOnchange,
  handleSave,
  useCallBackFunc,
  withDates,
  toggleNew,
  toggleUpdate,
  handleEdit,
  searchCriteriaData,
  startDate,
  endDate,
  onNewClick,
  switch_change,
}: props) => {
  const { Option } = Select;

  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";

  const [switchiconMsg, setSwitchiconMsg] = useState<string>("Personal");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const size: number = 16;
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [Active, setActive] = useState(false);
  const [refreshx, setRefreshx] = useState(false);
  const dispatch = useDispatch();

  const disablex = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const alertsOpened = useSelector(
    (state: RootState) => state.general.SideAlert
  );
  // const updateMode = useSelector(
  //   (state: RootState) => state.addTask.taskFooterUpdate
  // );
  const drawerOpened = useSelector(
    (state: RootState) => state.general.drawerOpened
  );

  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );

  // relative to the new button
  const handleNew = () => {
    switch_change && switch_change(true);
    onNewClick && onNewClick();
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    setOuterSelected && setOuterSelected({});

    //clear data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };
  //handle edit
  const handleUpdate = () => {
    switch_change && switch_change(true);
    if (!outerSelected || Object.keys(outerSelected).length < 1) {
      // dispatch(task_form_disable(true));

      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "Please select a record to update",
        title: "Action Required",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      //enable forms
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    }
  };
  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use

  const [searchTextValue, setSearchTextValue] = useState("");

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 1,
    value: searchCriteriaData![0]?.value ?? "",
  });
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  return (
    <>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={!showCancel}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{ borderBottomWidth: "1px" }}
        className="w-full h-full flex  justify-between border-gray-200 mb-1 px-1"
      >
        <div
          className={cx(
            !alertsOpened && !drawerOpened && "w-[25%]",
            alertsOpened && !drawerOpened && "w-[30%]",
            !alertsOpened && drawerOpened && "w-[30%]"
            // alertsOpened && drawerOpened && "w-[35%] bg-fuchsia-100"
          )}
        >
          <ul className="flex w-full items-center h-full py-0.5">
            {!disablex && !updateMode ? (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-2 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="newRecord"
                onClick={() => handleNew()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-2 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">New Record</p>
                </Tooltip>
              </li>
            )}
            {!updateMode ? (
              <li
                id="editRecordst"
                onClick={handleUpdate}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-2 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecordst"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-2 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}

            <li
              id="switch"
              onClick={toggler}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch{" "}
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="h-2">Switch interface / table view</p>
              </Tooltip>
            </li>

            {/* <li className="px-1 flex items-center flex-row ml-4 space-x-1">
              <label style={{ width: "80px" }} className=" text-xs">
                {`Search Criteria: `}
              </label>
              <Select
                value={criteriaDataLocal}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  });
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    );
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{
                  width: `${sideAlert === true ? 80 : "150px"}`,
                  height: "25px",
                }}
              >
                {searchCriteriaData?.map(({ id, value }) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: id, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </li> */}
          </ul>
        </div>
        <div className="flex flex-1 items-center">
          <li className=" ml-4 px-1 flex items-center flex-row flex-1 ">
            <label className="w-[90px] flex  text-xs">Search Text</label>
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
              }}
              value={searchValue}
              name="search"
              id="search"
              size="small"
              style={{
                width: `${sideAlert === true ? "100%" : "100%"}`,
                height: "24px",
              }}
            />
          </li>
        </div>

        <div className="flex w-[32%]">
          <ul className="flex items-center">
            {/* <li className="px-0.5">
              <DateTemplate
                selectedDate={isEmpty(startDate) ? undefined : dayjs(startDate)}
                datePlaceholder="Start Date"
                style={{ width: 125 }}
                format={dateFormat}
                disabled={false}
                dbFormat
                changeDate={(date: any) => {
                  startDateChanged && startDateChanged(date);
                }}
              />
            </li>
            <li className="px-0.5">
              <DateTemplate
                selectedDate={isEmpty(endDate) ? undefined : dayjs(endDate)}
                datePlaceholder="End Date"
                style={{ width: 125 }}
                format={dateFormat}
                disabled={false}
                changeDate={(date: any) => {
                  endDateChanged && endDateChanged(date);
                }}
                dbFormat
              />
            </li> */}
            <li
              id="status"
              className="hover:cursor-pointer mr-1  text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <CheckboxTemlate
                useCallbackFunc
                defaultValue={checkedBox}
                // checked={checkedBox}
                indeterminate={indeterminate}
                // borders
                setCheckboxOnchange={(value) => {
                  onChange(value);
                }}
                // onChange={(checkValue: any) => {
                // }}
                customDisabled={false}
                withBorders={true}
              />
              <Tooltip
                target="#status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Show Status</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                if (handleFind) {
                  handleFind();
                } else {
                }
              }}
              id="findst"
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                <FaBinoculars size={15.5} color="#007AFF" />
              </span>
              <Tooltip
                target="#findst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Find</p>
              </Tooltip>
            </li>

            {/* refresh */}
            <li
              onClick={() => {
                if (handleRefresh) {
                  handleRefresh();
                }
                setSearchTextValue("");
                setCheckedBox(false);
                setIndeterminate(true);
                setCheckValue("");
                setCriterialDataLocal({
                  id: searchCriteriaData![0]?.id ?? 0,
                  value: searchCriteriaData![0]?.value ?? "",
                });
              }}
              id="refreshst"
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={reset}
                />
              </span>
              <Tooltip
                target="#refreshst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Refresh</p>
              </Tooltip>
            </li>

            <li
              onClick={() => handlePrint && handlePrint()}
              id="printst"
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />
              </span>
              <Tooltip
                target="#printst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Print/Export</p>
              </Tooltip>
            </li>
          </ul>
          <DeleteTask isActive={isActive} setIsActive={setIsActive} />
          <TaskSettings
            SettingsisActive={SettingsisActive}
            SettingssetIsActive={SettingssetIsActive}
          />
          <AlertSMS
            AlertSMSisActive={AlertSMSisActive}
            AlertSMSsetIsActive={AlertSMSsetIsActive}
          />
          <AppraiseSettings
            AppraiseisActive={AppraiseisActive}
            AppraisesetIsActive={AppraisesetIsActive}
          />
        </div>
      </div>
    </>
  );
};

const data = [
  {
    label: "Group",
  },
  {
    label: "Staff Name",
  },
  {
    label: "Job Title",
  },
  {
    label: "Department",
  },
  {
    label: "Section",
  },
  {
    label: "Unit",
  },
  {
    label: "Location",
  },
];
