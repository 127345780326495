import React, { useCallback, useEffect, useRef, useState } from "react";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import VehDetails_Form from "./widgets/vehDetails_form";
import Datagrid_template from "../../../../../../templates/Datagrid";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import {
  currentDate,
  navbar_height,
  startingDate,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { vehicleDetails_cols } from "./data/datagridCols";
import useFetch from "../../../../../../hooks/useFetch";
import dayjs from "dayjs";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../../templates/modal";
// ----------------Eagle Mentality🦅 -------------------------------------------------------------------------------
import {
  setDisableForm,
  setEdit_Cancel_Button,
  setNew_Cancel_Button,
  setSave_Update_Button,
} from "../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleDetailsSlice";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
// import { VehicleForm } from "./VehicleDetails/DetailsForm";
// ---------------------------------------------------------------------------------------------------------------------------
interface ModalDisplay {
  icon: "warning" | "question" | "success";
  title: string;
  message: string;
  okText: string;
  cancelText?: string;
  openState: boolean;
  disableCancel: boolean;
  okHandler: () => void;
  cancelHandler: () => void;
}
const VehicleDetails2 = () => {
  const formRef = useRef<any>(null);

  // Redux
  const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  // ---------------------------- Eagle Mentality🦅   -------------------------------------
  // states from redux store
  let NewAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.New_Cancel_Button
  );
  let EditAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.Edit_Cancel_Button
  );
  // dispatch redux functions
  const Vdispatch = useDispatch();
  // -----------------------------------------------------------------------------

  const dispatch = useDispatch();
  // user Account
  const [userId, employID, userObj] = useAccountInfo();
  // priviledge state
  const [privType, setPrivType] = useState<string>("save");
  // User Priviledges on save , read and update
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  // Data for Search Criteria
  const CriteriaData: { id: number; value: string }[] = [
    {
      id: 0,
      value: "Registration No",
    },
    {
      id: 1,
      value: "Chasis No",
    },
    {
      id: 2,
      value: "Vehicle Class",
    },
    {
      id: 3,
      value: "Vehicle Make",
    },
    {
      id: 4,
      value: "Vehicle Model",
    },
    {
      id: 5,
      value: "Manufacturer",
    },
    {
      id: 6,
      value: "Supplier",
    },
    {
      id: 7,
      value: "Manufacture Date",
    },
    {
      id: 8,
      value: "Supply Date",
    },
  ];
  // Toolbar States
  const [states, setStates] = useState<any>({
    searchTextValue: { temp: "", index: "" },
    searchCriteria: { temp: 0, index: 0 },
    dates: { startDate: startingDate, endDate: currentDate },
    active: { temp: "", status: " " },
  });
  // state Destructuring
  const {
    searchTextValue,
    dates: { startDate, endDate },
    active,
    searchCriteria,
  } = states;

  // Refresh  DataSource
  const [refresh, setRefresh] = useState(false);

  //state to toggle  form + datagrid and datagrid only
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  // DataGrid Resizing
  const [initialHeight, finalHeight] = useResizeMode(switch_change);

  //Table Height
  const heights_out = navbar_height + news_flash_height + toolbar_height + 510;

  let pageHeight = initialHeight - heights_out + 45; // form + datagrid

  let fullPage = finalHeight - (navbar_height + news_flash_height + 67); // datagrid

  //  state for  modal display
  let [modalData, setModalData] = useState<ModalDisplay>({
    icon: "warning",
    title: "Access Denied",
    message: "No Access Priviledge",
    okText: "Ok",
    cancelText: "cancel",
    openState: false,
    disableCancel: true,
    okHandler: () => {
      setModalData({ ...modalData, ["openState"]: false });
    },
    cancelHandler: () => {
      setModalData({ ...modalData, ["openState"]: false });
    },
  });

  const [busyLoader, setBusyLoader] = useBusyLoader();

  // state to hold selectd data on row click or double click
  const [selectedRecord, setSelectedRecord] = useState({});

  // function to  set values to search criteria, search text, Start Date and End Date
  const updateStates = useCallback((key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  }, []);

  // function to find or search data from toolbar
  const Find_Button_on_ToolBar = useCallback(() => {
    setPrivType("read");
    if (savePriv === true) {
      setRefresh(!refresh);
      updateStates("searchTextValue", {
        temp: searchTextValue.temp,
        index: searchTextValue.temp,
      });
      updateStates("searchCriteria", {
        temp: searchCriteria.temp,
        index: searchCriteria.temp,
      });
      updateStates("dates", {
        startDate: startDate,
        endDate: endDate,
      });
      updateStates("active", { temp: active.temp, status: active.temp });
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied!",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  }, [searchTextValue, startDate, endDate, active, searchCriteria]);
  // function to refresh from toolbar
  const Toolbar_Refresh_Button = useCallback(() => {
    setRefresh(!refresh);
    updateStates("searchTextValue", { temp: "", index: "" });
    updateStates("searchCriteria", { temp: 0, index: 0 });
    updateStates("dates", { startDate: startingDate, endDate: currentDate });
    updateStates("active", { temp: "", status: "" });
  }, []);
  // function to change criteria from toolbar
  const CriteriaOnChange = (index: any) => {
    updateStates("searchCriteria", {
      temp: index,
      index: searchCriteria.index,
    });
  };
  // function to change search text from toolbar
  const SearchTextOnchange = (text: any) => {
    updateStates("searchTextValue", {
      temp: text,
      index: searchTextValue.index,
    });
  };
  // function to change start date from toolbar
  const StartDateOnChange = (date: any) => {
    updateStates("dates", {
      startDate: dayjs(date).format("YYYY-MM-DD"),
      endDate: endDate,
    });
  };
  // function to change end date from toolbar
  const EndDateOnChange = (date: any) => {
    updateStates("dates", {
      startDate: startDate,
      endDate: dayjs(date).format("YYYY-MM-DD"),
    });
  };
  // function to change  active status from toolbar
  const ActiveStateOnchange = (activeState: any) => {
    updateStates("active", { temp: activeState, status: active.status });
  };

  // function to print from toolbar
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      return;
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied!",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  };

  // function to check Priviledge Access on double click
  const Priv_Access_on_DoubleClick = (data: any) => {
    setPrivType("read");
    if (updatePriv === true) {
      console.log('%% accesspriv reco', data)
      setSelectedRecord(data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied!",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  };
  // function  for single click on datagrid
  const singleClick = (data: any) => {
    setPrivType("read");
    if (updatePriv === true) {
      setSelectedRecord(data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } else {
      setModalData({
        icon: "warning",
        title: "Access Denied!",
        message: "No Access Priviledge",
        okText: "Ok",
        cancelText: "cancel",
        openState: true,
        disableCancel: true,
        okHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
        cancelHandler: () => {
          setModalData({ ...modalData, ["openState"]: false });
        },
      });
    }
  };
  // Data
  const [DBdata, DBerr, DBLoading] = useFetch(
    `AssetTamVehicleDetail/GetAllAssetTamVehicleDetails?criteria=${searchCriteria.index}&startDate=${startDate}&endDate=${endDate}&active=${active.status}&search=${searchTextValue.index}&pageSize=${20}&pageNumber=${1}`,
    refresh,
    "vdtIDpk"
  );
   
useEffect(()=>{
  if(DBLoading){
    setBusyLoader('Fetching Data...')
  }else{
    setBusyLoader('')
  }
},[DBLoading])


  return (
    <>
      {/* <ModalTemplate
        icon_width={50}
        icon={modalData.icon}
        cancelText={modalData.cancelText}
        disableCancel={modalData.disableCancel}
        cancelHandler={modalData.cancelHandler}
        open={modalData.openState}
        okHandler={modalData.okHandler}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      /> */}
      <div className="w-full h-full px-2">
        {/* Toolabar */}
        <div className="w-full">
          <HrSetupsCustomToolbar
            searchCriteriaOnchange={CriteriaOnChange}
            searchTextOnchange={SearchTextOnchange}
            checkOnChange={ActiveStateOnchange}
            startDateChanged={StartDateOnChange}
            endDateChanged={EndDateOnChange}
            handleFind={Find_Button_on_ToolBar}
            handleRefresh={Toolbar_Refresh_Button}
            handlePrint={AccessPriv_on_PrintButton}
            outerSelected={selectedRecord}
            setOuterSelected={setSelectedRecord}
            toggler={(e)=>{setSwitch_change(e)}}
            Switch={switch_change}
            searchCriteriaData={CriteriaData}
            searchCriteria={true}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        {/* Form body */}
        <div className="w-full border-[1px] rounded "
         style={{ borderColor: borderTheme}}>
          
          <p id={"form_header"} className="w-full font-medium border-b-[1px] pl-4">
            {"Vehicle Details"}
          </p>

          {switch_change && (
            <div ref={formRef} className="w-full h-full ">
              <VehDetails_Form
                selectedRecord={selectedRecord}
                searchTextValue={searchTextValue}
                searchCriteria={searchCriteria}
                date={{startDate,endDate}}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </div>
          )}
        </div>

        <div className="w-full h-full">
          <Datagrid_template
            gridheight={switch_change ? pageHeight : fullPage}
            columns={vehicleDetails_cols}
            // data={DBdata}
            data={DBdata}
            onRowClick={(e) => {
              console.log('e: ',e)
              singleClick(e);
            }}
            rowDoubleClicked={(e) => {
              Priv_Access_on_DoubleClick(e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleDetails2;
