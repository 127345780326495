import { TaskTab } from "../components/Task&Todos/taskTabs";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Page } from "./types";

import VehiclesEntriesScreen from "../components/Assets-Manager/Transport/Entries/Vehicles/vehicles-entries";
import { Assets_setups_vehicleClass } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-classes/vehicleClass";
import { Assets_setups_vehicleMake } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-makes/vehicleMake";
import { Assets_setups_vehicleModel } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-models/vehicleModel";
import { Assets_setups_manufacturer } from "../components/Assets-Manager/Transport/setups/vehicles/manufacturers/manufacturers";
import { Assets_setups_suppliers } from "../components/Assets-Manager/Transport/setups/vehicles/suppliers/suppliers";
import { Assets_setups_engineType } from "../components/Assets-Manager/Transport/setups/vehicles/engine-types/engineTypes";
import { Assets_setups_powerTrains } from "../components/Assets-Manager/Transport/setups/vehicles/power-trains/powerTrains";
import { Assets_setups_docTypes } from "../components/Assets-Manager/Transport/setups/general/document-types/docTypes";
import { Assets_setups_accessories } from "../components/Assets-Manager/Transport/setups/general/accessories/accessories";
import { Assets_setups_accessoryValues } from "../components/Assets-Manager/Transport/setups/general/accessory-values/accessoryValues";
import { Assets_setups_products } from "../components/Assets-Manager/Transport/setups/general/products/products";
import { Assets_setups_transunits } from "../components/Assets-Manager/Transport/setups/general/transport-units/transportUnits";
import { Page404 } from "../pages/Page404/Page404";
import { Assets_setups_VehicleRA } from "../components/Assets-Manager/Transport/setups/general/vehicle-request-areas/vehicleRequestAreas";
import { Assets_setups_VehicleRC } from "../components/Assets-Manager/Transport/setups/general/vehicle-request-categories/vehicleRC";
import { Assets_setups_DPHCategiries } from "../components/Assets-Manager/Transport/setups/general/drivers-permit-holders-categories/DPHCategories";
import { Assets_Setups_InsuranceCompanies } from "../components/Assets-Manager/Transport/setups/general/insurance-companies/insuranceCompanies";

import { Assets_Setups_ProductPrices } from "../components/Assets-Manager/Transport/setups/general/product-prices/productPrices";
import { Assets_setups_fuelTypes } from "../components/Assets-Manager/Transport/setups/fuel/fuel-types/fuelTypes";
import { Assets_setups_fuelLevels } from "../components/Assets-Manager/Transport/setups/fuel/fuel-levels/fuelLevels";
import { Assets_setups_fuelUnits } from "../components/Assets-Manager/Transport/setups/fuel/fuel-units/fuelUnits";
import { Assets_setups_fuelPurchaseModes } from "../components/Assets-Manager/Transport/setups/fuel/fuel-purchase-models/fuelPurchaseModes";

import { Assets_setups_ServiceProviders } from "../components/Assets-Manager/Transport/setups/maintenance/service-provider-details/serviceProviders";
import { Assets_setups_serviceItems } from "../components/Assets-Manager/Transport/setups/maintenance/service-items/serviceItems";
import { Assets_setups_maintenanceTypes } from "../components/Assets-Manager/Transport/setups/maintenance/maintenance-types/maintenanceTypes";
import { Assets_setups_maintenancelocations } from "../components/Assets-Manager/Transport/setups/maintenance/maintenance-locations/maintenanceLocations";
import { Assets_setups_transmissionType } from "../components/Assets-Manager/Transport/setups/vehicles/transmission-types/transmissionTypes";
import { Assets_setups_vehicleStatuses } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-statuses/vehicleStatus";
import { Assets_setups_engineMakes } from "../components/Assets-Manager/Transport/setups/vehicles/engine-makes/engineMakes";
import { Assets_setups_cubicCapabilities } from "../components/Assets-Manager/Transport/setups/vehicles/cubic-capabilities/cubicCapabilities";
import { Assets_setups_vehicleAccessories } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-accessories/vehicleAccessories";

import { Transaction_Upload } from "../components/Assets-Manager/Transport/Entries/Fuel/Transaction_uploads/Transaction_uploads";

import { Assets_setups_ServiceStations } from "../components/Assets-Manager/Transport/setups/maintenance/service-stations/serviceStations";
import { AssetsEntries_drivingTests } from "../components/Assets-Manager/Transport/Entries/Exams/Driving-tests/drivingTests";

import PriviledgesEntriesScreen from "../components/Assets-Manager/Transport/Entries/Priviledges-Assignments/priviledges-entries";

import { Topup_Request } from "../components/Assets-Manager/Transport/Entries/Fuel/Topup_Req/Top_up_request";
import { Fuel_Backlog } from "../components/Assets-Manager/Transport/Entries/Fuel/BacklogFuelEntries/Backlog_Fuel_Entries";
import { Return_Request } from "../components/Assets-Manager/Transport/Entries/Fuel/Return_req/Returns_Req";
import MaintenanceEntriesScreen from "../components/Assets-Manager/Transport/Entries/Maintenance/Maintenance-Requests/maintenance-entries";
import { Maintenance_Activities } from "../components/Assets-Manager/Transport/Entries/Maintenance/Maintenance_Activities/Maintenance_activities";
import { Maintenance_Schedule } from "../components/Assets-Manager/Transport/Entries/Maintenance/Maintenance_Schedules/Maintenance_schedules";
import { Assets_setups_vehicleClassAssignments } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-class-assignments/vehicleClassAssignments";

import LockScreen from "../pages/Login/lockScreen";

import VehicleRequestsSecurity from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Requests-Security/widgets/vehicle-request-security";
import SmsAlertsTransport from "../components/Assets-Manager/Transport/Entries/Maintenance/Sms-Alerts/widgets/smsAlerts";

import { Fuel_Transactions } from "../components/Assets-Manager/Transport/Entries/Fuel/Transaction/Transactions";

import { Fuel_Documents } from "../components/Assets-Manager/Transport/Entries/Fuel/Document/TransportDocument";

import VehicleDetails from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Details/widgets/vehicleDetails";
import VehicleAssignments from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Assignments/widgets/vehicle-assignments";
import VehicleLogBook from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Log-Book/widgets/vehicleLogBook";
import VehicleSimCard from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-SIM-Card-Assignments/widgets/vehicleSimCard";

import { CardDetails } from "../components/Assets-Manager/Transport/setups/general/card-details/cardDetails";
import { DriverCodeAssignments } from "../components/Assets-Manager/Transport/setups/general/drivers-code-assignments/driversCodeAssignments";
import { TMSApproversVehicleRequestPriveledges } from "../components/Assets-Manager/Transport/setups/vehicles/vehicle-request-priveledges/vehicleRequestPriveledges";
import { DriversPermitHoldersAssignments } from "../components/Assets-Manager/Transport/Entries/drivers&permit-holders-assignments/driverspermitholders";
import { SmsAlertVehicleUsers } from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-SmsAlertVehicleUsers/SmsAlertVehicleUsers";
import VehicleDataUpload from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Data-Upload/widgets/vehicleDataUpload";
import VehicleCard from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Fuel-Card-Asssignments/widgets/vehicleCard";

import VehicleImages from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Images/widgets/vehicleImages";
import { DrivingTestRegistration } from "../components/Assets-Manager/Transport/setups/general/exams-registration/examsRegistration";
import { Assets_setups_fuelDAAssignments } from "../components/Assets-Manager/Transport/Entries/Priviledges-Assignments/fuel-dump-attendant-assignments/fdaAssignment";
import { TmsWorkshopAttendantAssignments } from "../components/Assets-Manager/Transport/Entries/Priviledges-Assignments/workshop-attendants-assignments/workshopAttendantAssignments";
import VehicleRequests from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Requests/widgets/vehicleRequests";
import { AuthorizeFuelRequestDetails } from "../components/Assets-Manager/Transport/Entries/authorize-fuel-requests/authorizeFuelRequests";
import { BulkProductPrices } from "../components/Assets-Manager/Transport/setups/general/bulk-product-prices/bulkProductPrices";

import { Workshop_details_setupform } from "../components/Assets-Manager/Transport/setups/general/workshop-details/workshopDetails";
import { Dump_Dips } from "../components/Assets-Manager/Transport/Entries/Fuel/Dump_Dips/Dump_Dips";
import { Fuel_Dump_Attendants } from "../components/Assets-Manager/Transport/Entries/Priviledges-Assignments/FuelDumps/FuelDump";
import { TMSExamNames } from "../components/Assets-Manager/Transport/setups/general/exams-names/examNames";
import { TMSExamsQuestions } from "../components/Assets-Manager/Transport/setups/general/exams-questions/examsQuestions";
import { SyncFusion } from "../pages/trials/syncFusion/syncFusion";
import VehicleConditions from "../components/Assets-Manager/Transport/Entries/Vehicles/Vehicle-Condition-Returns/widgets/vehicleConditions";
import MaintenanceRequests from "../components/Assets-Manager/Transport/Entries/Maintenance/Maintenance-Requests/widgets/maintenanceRequests";
import { Elogger } from "../components/Assets-Manager/Transport/Entries/general/e-logger/elogger";
import { Alert_Drivers } from "../components/Assets-Manager/Transport/Entries/AlertDrivers/AlertDetails";
import { Assets_setups_Maintenance_I_A_M } from "../components/Assets-Manager/Transport/setups/maintenance/maintenance-items&ckecklist/maintenance";
import { ActivitiesCapture } from "../components/Assets-Manager/Transport/Entries/ActivitiesCapture/activities_capture";
import VehicleDetails2 from "../components/Assets-Manager/Transport/Entries/Vehicles/vehicleDetails/vehDetails";
import Access_Privilees_Assignement from "../components/system-administration/access_privileges_assignments/apa";
import ApplicationForm from "../components/system-administration/applicationForms/applicationForm";
import ApplicationMod from "../components/system-administration/applicationModules/applicationMod";
import SMSLogs from "../components/system-administration/SMSLogs/smsLogs";
import { AuditTrail } from "../components/system-administration/auditTrail/auditTrail";
import UserAdministration from "../components/system-administration/user-administration/userAdministration";
import ResetPassword from "../components/system-administration/resetPassword/resetPassword";
import TagComments from "../components/Assets-Manager/Transport/Entries/Fuel/TagComments/tagComments";
import StaffDetails from "../components/human-resource/entries/staffDetails/staffDetails";
import UpdateStaffOrg from "../components/human-resource/entries/UpdateStaffOrg.tsx/updateStaffOrg";
import StaffNames from "../components/human-resource/entries/StaffNames/widgets/staff-names";
import StaffClassifications from "../components/human-resource/entries/staffClassifications/widgets/staff-classifications";
import { Passport_Picture } from "../components/human-resource/entries/passportPictures/passportPictures";
import { Hr_Setups_Units } from "../components/human-resource/setups/units/units";
import { Hr_Setups_Branch } from "../components/human-resource/setups/branch/branch";
import { LeaveRequests } from "../components/human-resource/entries/self-service/leave-requests/leaveRequests";
import StaffDependant from "../components/human-resource/entries/staffDependantDetails/widgets/staffDependant";
import { Hr_Setups_Areas } from "../components/human-resource/setups/Areas/areas";
import { Hr_Setups_AppraisalGroups } from "../components/human-resource/setups/appraisal-groups/appraisal-group";
import { Hr_Assignment_Categories } from "../components/human-resource/setups/assignment categories/assignmentCategories";
import { Hr_Setups_AssignmentStatus } from "../components/human-resource/setups/assignment-status/assignmentStatus";
import { Hr_Setups_CostCenters } from "../components/human-resource/setups/cost-centers/costCenters";
import { Hr_Setups_Countries } from "../components/human-resource/setups/countries/countries";
import { Hr_Setups_Departments } from "../components/human-resource/setups/departments/departments";
import { Hr_Setups_Districts } from "../components/human-resource/setups/districts/districts";
import { Hr_Setups_EmployeeCategories } from "../components/human-resource/setups/employee-categories/employee-categories";
import { Hr_Setups_EmployeeGroup } from "../components/human-resource/setups/employee-groups/employeeGroups";
import { Hr_Setups_EmployeeTypes } from "../components/human-resource/setups/employee-types/employeeTypes";
import { Hr_Setups_Genders } from "../components/human-resource/setups/genders/gender";
import { Hr_Setups_Grade } from "../components/human-resource/setups/grades/grade";
import { Hr_Setups_JobTitles } from "../components/human-resource/setups/job-titles/jobTitles";
import { Hr_Setups_Locations } from "../components/human-resource/setups/location/location";
import { Hr_Setups_MaritalStatus } from "../components/human-resource/setups/marital-status/maritalStatus";
import { Hr_Setups_OfficeLocations } from "../components/human-resource/setups/office-locations/officeLocation";
import { Hr_Setups_PersonTypes } from "../components/human-resource/setups/person-types/personTypes";
import { Hr_Setups_Regions } from "../components/human-resource/setups/regions/region";
import { Hr_Setups_RoomNos } from "../components/human-resource/setups/room-nos/roomNos";
import { Hr_Setups_Sections } from "../components/human-resource/setups/sections/sections";
import { Hr_Setups_Steps } from "../components/human-resource/setups/steps/steps";
import { Hr_Setups_Titles } from "../components/human-resource/setups/titles/titles";
import { Hr_Setups_Towns } from "../components/human-resource/setups/towns/towns";
import StaffBeneficiaryDetails from "../components/human-resource/entries/staffBeneficiary/widgets/staffBeneficiary-details";
import TelephoneDir from "../components/TelephoneDirectories/TelephoneDir";
import SysSettings from "../components/system-administration/sysSettings/sysSettings";
import GenSettings from "../components/system-administration/sysSettings/genSettings";
import TmsSettings from "../components/system-administration/sysSettings/tmsSettings";
import SmsSettings from "../components/system-administration/sysSettings/smsSettings";
import CoreHR_Settings from "../components/system-administration/sysSettings/coreHR";
import SMSAlertInt from "../components/system-administration/sysSettings/SMSAlertInt";
import Maint_Sch_Freq from "../components/system-administration/sysSettings/Maint_Sch_Freq";
import { CoreValues } from "../components/Performance/setups/core-values/coreValues";
import { PerformanceSetupsCorporateMeasures } from "../components/Performance/setups/corporate-measure-values/corporateMeasures";
import { Perf_setups_deptInitiatives } from "../components/Performance/setups/dept-initiative-values/deptInitiativeValue";
import { Perf_setups_measures } from "../components/Performance/setups/measures/measures";
import { PerformanceSetupsMonitoringModules } from "../components/Performance/setups/monitoring-modules/monitoringModules";
import { PerformanceSetupsPeriods } from "../components/Performance/setups/periods/periods";
import { PerformanceSetupsRatings } from "../components/Performance/setups/ratings/ratings";
import { Perf_setups_secInitiatives } from "../components/Performance/setups/section-initiatives/sectionInitiatives";
import { PerformanceStaffInitiatives } from "../components/Performance/setups/staff-initiatives/staffInitiatives";
import { Perf_setups_Target } from "../components/Performance/setups/targets/targets";
import { Perf_setups_unitInitiatives } from "../components/Performance/setups/unit-initiatives/unitInitiatives";
import { Perf_setups_unitOfMeasure } from "../components/Performance/setups/unit-of-measure/unitOfMeasure";
import { PerformanceWeights } from "../components/Performance/setups/weights/weights";

import { CI } from "../components/Performance/Entries/CI/Cooperate_initiative";
import { CIA } from "../components/Performance/Entries/CIA/CIA_Details";
import { DIA } from "../components/Performance/Entries/DIA/DIA_Details";
import { Lock_assignments } from "../components/Performance/Entries/LockAssignments/LockSubmission";
import { Measure } from "../components/Performance/Entries/Measures&Target/Measures/Measure_Details";
import { Target } from "../components/Performance/Entries/Measures&Target/Targets/Target_Details";
import { Measures_Targets_Tabs } from "../components/Performance/Entries/Measures&Targets/Measures_Targets";
import { Mission_Vision } from "../components/Performance/Entries/Mission&Vision/Mission_Vision";
import { Perspectives } from "../components/Performance/Entries/Perspective/Perspective_Details";
import StaffAssignmentAppraisal from "../components/Performance/Entries/SAA/staffAssignmentAppraisal";
import { SecIA } from "../components/Performance/Entries/Section Initiative Assignment/SecIA_Details";
import { SignOff_Assignments } from "../components/Performance/Entries/SignOff Assignements/SignOff";
import { SIA } from "../components/Performance/Entries/Staff Initiative Assignment/SIA";
import { Objective_Tabs } from "../components/Performance/Entries/Strategic Objectives/Tabs_Objectives";
import { Theme_Tabs } from "../components/Performance/Entries/Strategic Themes/Tabs_Themes";
import { UIA } from "../components/Performance/Entries/UIA/UIA_Details";
import { SafetySetupEventTypes } from "../components/Assets-Manager/Transport/setups/general/event-types/eventTypes";
// import { Safety_Elogger } from "../components/Safety/elogger/elogbook";
import { SationReadings } from "../components/Safety/elogger/StationReadings/stationreadings";
import { OutageEquipmentType } from "../components/Safety/elogger/StationReadings/outageEquipmentType";
import { Documents_Details } from "../components/Safety/elogger/StationReadings/Documents";
import { BusDetails } from "../components/Safety/elogger/busDetails/busDetails";
import { Transformers } from "../components/Safety/Transformers/widgets/transformers";
import { Safety_Elogger } from "../components/Safety/elogger/elogbook";
import { Case_management } from "../components/Safety/Case Management/case_management";
import TaskManagement from "../components/Safety/LegalMgmt/TaskManagement/TaskManagement";
import LineDetails from "../components/Safety/Line-Details/widgets/line-details";
import { Hr_Setups_CaseClasses } from "../components/human-resource/setups/employee-categories copy 2/case-classes";
import { Hr_Setups_CaseStatus } from "../components/human-resource/setups/employee-categories copy 3/case-status";
import { Hr_Setups_CaseImpacts } from "../components/human-resource/setups/employee-categories copy 4/case-impacts";
import { Hr_Setups_CaseCategories } from "../components/human-resource/setups/employee-categories copy/case-categories";
import JudgementCatalogs from "../components/Safety/elogger/StationReadings/JudgementCatalogs";
import LawCatalogs from "../components/Safety/elogger/StationReadings/LawCatalogs";
// import { TroubleReports } from "../components/Assets-Manager/Transport/Entries/troubleReport/troubleReport";

import Categorydetails from "../components/Safety/elogger/StationReadings/Categorydetails";
import { Calendarmanagement } from "../components/Safety/calendermanagement/calendarmanagement";
import { OutageEquipmentTypes } from "../components/Safety/elogger/StationReadings/equipmentOutageTypes";
import { Protection_guarantee_pc2 } from "../components/Protection Guarantee (PC2)/widget/protection_guarantee_pc2";
import { Order_to_operate_main } from "../components/OTO/widget/order_to_operate_main";
import ProtectionGuarantee1 from "../components/Protection Guarantee (PC1)";
import { JobMain } from "../components/Job Hazard Analysis/widget/jobAnalysisMain";
import TroubleReportPriviledgesAssignment from "../components/TR-Priviledges Assignment";
import { ElogBookMainPriv } from "../components/Assets-Manager/Transport/Entries/Priviledges-Assignments/ElogBookPriv/elogBookMainPriv";
import Assets_power_setups_accuracyClass from "../components/Assets-Manager/Transport/setups/general/accuracy-classes/accuracyClasses";
import Manufacturers from "../components/Assets-Manager/Transport/setups/general/Manufacturers-Details/widgets/manufacturers";
import { OutageMainPriv } from "../components/Assets-Manager/Transport/Entries/Priviledges-Assignments/Outage-Priviledge/outageMainPriv";
import { LoadProfile1Parent } from "../components/Load Profile 1/widget/loadProfile1Parent";
import { TroubleCategories } from "../components/Assets-Manager/Transport/Entries/troubleReport/widgets/setup/trouble-category/Trouble-categories";
import TroubleClasses from "../components/Assets-Manager/Transport/Entries/troubleReport/widgets/setups/trouble-classes";
import TroubleEquipment$AssetDetails from "../components/Assets-Manager/Transport/Entries/troubleReport/widgets/setups/trouble-equipment-details";
import TroubleReportWrapper from "../components/Assets-Manager/Transport/Entries/troubleReport/provider";
import { StationDetails } from "../components/Safety/elogger/StationDetails/StationDetails";

export const pages: Page[] = [
  // HOME
  { path: "/welcome", exact: true, component: Dashboard, selected: "Welcome" },
  //LOGIN
  {
    path: "/locked",
    exact: true,
    component: LockScreen,
    selected: "Lock Screen",
  },
  //404
  { path: "*", exact: true, component: Page404, selected: "Page 404" },

  //SYSTEM ADMIN
  {
    path: "/system-administration/user-administration",
    exact: true,
    component: UserAdministration,
    selected: "User Administration",
  },
  {
    path: "/system-administration/reset-password",
    exact: true,
    component: ResetPassword,
    selected: "Reset Password",
  },
  {
    path: "/system-administration/sysSettings",
    exact: true,
    component: SysSettings,
    selected: "System Settings",
  },
  {
    path: "/system-administration/genSettings",
    exact: true,
    component: GenSettings,
    selected: "General Settings",
  },
  {
    path: "/system-administration/TmsSettings",
    exact: true,
    component: TmsSettings,
    selected: "TMS Settings",
  },
  {
    path: "/system-administration/SmsSettings",
    exact: true,
    component: SmsSettings,
    selected: "SMS Settings",
  },
  {
    path: "/system-administration/CoreHRSettings",
    exact: true,
    component: CoreHR_Settings,
    selected: "Core HR Settings",
  },
  {
    path: "/system-administration/SMSIntSettings",
    exact: true,
    component: SMSAlertInt,
    selected: "SMS Alert Interval Settings",
  },
  {
    path: "/system-administration/MaintSettings",
    exact: true,
    component: Maint_Sch_Freq,
    selected: "Maint. Schedules Frequency Settings",
  },
  {
    path: "/system-administration/sms-logs",
    exact: true,
    component: SMSLogs,
    selected: "Instant Messages",
  },
  {
    path: "/system-administration/application-form",
    exact: true,
    component: ApplicationForm,
    selected: "Application Forms",
  },
  {
    path: "/system-administration/application-modules",
    exact: true,
    component: ApplicationMod,
    selected: "Application Modules",
  },
  {
    path: "/system-administration/audit-trail",
    exact: true,
    component: AuditTrail,
    selected: "Audit-Trail",
  },

  {
    path: "/system-administration/access-privileges-assignments",
    exact: true,
    component: Access_Privilees_Assignement,
    selected: "Sys Admin - Users",
  },

  //SYNC FUSION TRIAL
  {
    path: "/sync-fusion",
    exact: true,
    component: SyncFusion,
    selected: "Sync Fusion",
  },

  // HR
  // entries
  {
    path: "/human-resource/entries/staff-management/staff-details",
    exact: true,
    component: StaffDetails,
    selected: "HR - Staff Details",
  },
  {
    path: "/human-resource/entries/staff-management/update-staff-org-units",
    exact: true,
    component: UpdateStaffOrg,
    selected: "HR - Update Staff Org Units",
  },
  {
    path: "/human-resource/entries/staff-management/staff-names",
    exact: true,
    component: StaffNames,
    selected: "HR - Staff Names",
  },
  {
    path: "/human-resource/entries/staff-management/staff-classifications",
    exact: true,
    component: StaffClassifications,
    selected: "HR - Staff Classifications",
  },

  {
    path: "/human-resource/entries/staff-management/passport-picture",
    exact: true,
    component: Passport_Picture,
    selected: "HR - Passport Picture",
  },

  {
    path: "/human-resource/entries/staff-management/staff-beneficiaries",
    exact: true,
    component: StaffBeneficiaryDetails,
    selected: "HR - Staff Beneficiaries",
  },
  {
    path: "/human-resource/entries/staff-management/dependant-details",
    exact: true,
    component: StaffDependant,
    selected: "HR - Dependant Details",
  },

  // Hr/entries/self service
  {
    path: "/human-resource/entries/self-service/leave-requests",
    exact: true,
    component: LeaveRequests,
    selected: "HR - Leave Requests",
  },

  //setups
  {
    path: "/human-resource/setups/hr-operations/locations",
    exact: true,
    component: Hr_Setups_Locations,
    selected: "HR - Locations",
  },
  {
    path: "/human-resource/setups/hr-operations/unit",
    exact: true,
    component: Hr_Setups_Units,
    selected: "HR - Units",
  },
  {
    path: "/human-resource/setups/hr-operations/branches",
    exact: true,
    component: Hr_Setups_Branch,
    selected: "HR - Branches",
  },
  {
    path: "/human-resource/setups/hr-operations/department",
    exact: true,
    component: Hr_Setups_Departments,
    selected: "HR - Departments",
  },
  {
    path: "/human-resource/setups/hr-operations/sections",
    exact: true,
    component: Hr_Setups_Sections,
    selected: "HR - Sections",
  },
  {
    path: "/human-resource/setups/hr-operations/areas",
    exact: true,
    component: Hr_Setups_Areas,
    selected: "HR - Areas",
  },
  {
    path: "/human-resource/setups/hr-operations/titles",
    exact: true,
    component: Hr_Setups_Titles,
    selected: "HR - Titles",
  },
  {
    path: "/human-resource/setups/hr-operations/marital-status",
    exact: true,
    component: Hr_Setups_MaritalStatus,
    selected: "HR - Marital Status",
  },
  {
    path: "/human-resource/setups/hr-operations/employee-types",
    exact: true,
    component: Hr_Setups_EmployeeTypes,
    selected: "HR - Employee Types",
  },
  {
    path: "/human-resource/setups/hr-operations/employee-groups",
    exact: true,
    component: Hr_Setups_EmployeeGroup,
    selected: "HR - Employee Groups",
  },
  {
    path: "/human-resource/setups/hr-operations/person-types",
    exact: true,
    component: Hr_Setups_PersonTypes,
    selected: "HR - Person Types",
  },
  {
    path: "/human-resource/setups/hr-operations/assignment-statuses",
    exact: true,
    component: Hr_Setups_AssignmentStatus,
    selected: "HR - Assignment Status",
  },
  {
    path: "/human-resource/setups/hr-operations/office-locations",
    exact: true,
    component: Hr_Setups_OfficeLocations,
    selected: "HR - Office Locations",
  },
  {
    path: "/human-resource/setups/hr-operations/grades",
    exact: true,
    component: Hr_Setups_Grade,
    selected: "HR - Grades",
  },
  {
    path: "/human-resource/setups/hr-operations/job-titles",
    exact: true,
    component: Hr_Setups_JobTitles,
    selected: "HR - Job Titles",
  },
  {
    path: "/human-resource/setups/hr-operations/steps",
    exact: true,
    component: Hr_Setups_Steps,
    selected: "HR - Steps",
  },
  {
    path: "/human-resource/setups/hr-operations/room-nos",
    exact: true,
    component: Hr_Setups_RoomNos,
    selected: "HR - Room Nos",
  },
  {
    path: "/human-resource/setups/hr-operations/appraisal-groups",
    exact: true,
    component: Hr_Setups_AppraisalGroups,
    selected: "HR - Appraisal Groups",
  },
  {
    path: "/human-resource/setups/hr-operations/genders",
    exact: true,
    component: Hr_Setups_Genders,
    selected: "HR - Genders",
  },
  {
    path: "/human-resource/setups/hr-operations/employee-categories",
    exact: true,
    component: Hr_Setups_EmployeeCategories,
    selected: "HR - Employee Categories",
  },
  {
    path: "/human-resource/setups/hr-operations/case-categories",
    exact: true,
    component: Hr_Setups_CaseCategories,
    selected: "HR - Case Categories",
  },
  {
    path: "/human-resource/setups/hr-operations/case-classes",
    exact: true,
    component: Hr_Setups_CaseClasses,
    selected: "HR - Case Classes",
  },
  {
    path: "/human-resource/setups/hr-operations/case-impact",
    exact: true,
    component: Hr_Setups_CaseImpacts,
    selected: "HR - Case Impacts",
  },
  {
    path: "/human-resource/setups/hr-operations/case-status",
    exact: true,
    component: Hr_Setups_CaseStatus,
    selected: "HR - Case Status",
  },
  {
    path: "/human-resource/setups/hr-operations/countries",
    exact: true,
    component: Hr_Setups_Countries,
    selected: "HR - Countries",
  },
  {
    path: "/human-resource/setups/hr-operations/regions",
    exact: true,
    component: Hr_Setups_Regions,
    selected: "HR - Regions",
  },
  {
    path: "/human-resource/setups/hr-operations/districts",
    exact: true,
    component: Hr_Setups_Districts,
    selected: "HR - Districts",
  },
  {
    path: "/human-resource/setups/hr-operations/towns",
    exact: true,
    component: Hr_Setups_Towns,
    selected: "HR - Towns",
  },
  {
    path: "/human-resource/setups/hr-operations/cost-centers",
    exact: true,
    component: Hr_Setups_CostCenters,
    selected: "HR - Cost Centers",
  },
  {
    path: "/human-resource/setups/hr-operations/assignment-categories",
    exact: true,
    component: Hr_Assignment_Categories,
    selected: "HR - Assignment Categories",
  },

  //Entries/Maintenance
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-activities",
    exact: true,
    component: Maintenance_Activities,
    selected: "Asset Mgr-TMS-Maintenance Activities",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-schedule",
    exact: true,
    component: Maintenance_Schedule,
    selected: "Asset Mgr-TMS-Maintenance Schedule",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-requests",
    exact: true,
    component: MaintenanceRequests,
    selected: "Asset Mgr-TMS-TMS-Maintenance Requests",
  },
  // { path: '/assets-manager/transport/entries/maintenance/maintenance-schedule-alert',exact: true, component: Schedule_Alert, selected: 'Asset Mgr-TMS-Schedule_Alert'},
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-schedule-sms-alerts",
    exact: true,
    component: SmsAlertsTransport,
    selected: "Asset Mgr-Transport-Maintenance Schedule SMS Alerts",
  },

  //entries/vehicles
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-assignments",
    exact: true,
    component: VehicleAssignments,
    selected: "TMS Vehicle Assignments",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-sim-card-assignments",
    exact: true,
    component: VehicleSimCard,
    selected: "Transport-Vehicle-SIM Card Assignment",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-requests",
    exact: true,
    component: VehicleRequests,
    selected: "Asset Mgr-TMS-Vehicle Requests",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-condition-returns",
    exact: true,
    component: VehicleConditions,
    selected: "Asset Mgr-Transport-Vehicle Condition Returns",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-details",
    exact: true,
    component: VehicleDetails2,
    selected: "Asset Mgr-TMS-Vehicle Details",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-images",
    exact: true,
    component: VehicleImages,
    selected: "Asset Mgr-TMS-Vehicle Images",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-log-book",
    exact: true,
    component: VehicleLogBook,
    selected: "Asset Mgr-TMS-Vehicle Log Book",
  },

  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-fuel-card-assignments",
    exact: true,
    component: VehicleCard,
    selected: "Asset Mgr-TMS-Vehicle Card",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-data-upload",
    exact: true,
    component: VehicleDataUpload,
    selected: "Asset Mgr-Transport-Vehicle Data Upload",
  },
  // '/assets-manager/transport/entries/vehicles/vehicle-requests-security'
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-requests-security",
    exact: true,
    component: VehicleRequestsSecurity,
    selected: "Transport - Vehicle Requests",
  },

  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-smsAlertVehicleUsers",
    exact: true,
    component: SmsAlertVehicleUsers,
    selected: "Asset Mgr-TMS-SMS Alert Vehicle Users",
  },
  {
    path: "/assets-manager/transport/entries/priviledges/drivers-code-assignments",
    exact: true,
    component: DriverCodeAssignments,
    selected: "Asset Mgr-TMS-Drivers Code Assignments",
  },
  {
    path: "/assets-manager/transport/entries/priviledges/drivers&permit-holders-assignments",
    exact: true,
    component: DriversPermitHoldersAssignments,
    selected: "Asset Mgr-TMS-Drivers & Permit Holders",
  },
  {
    path: "/assets-manager/transport/entries/priviledges/workshop-attendant-assignments",
    exact: true,
    component: TmsWorkshopAttendantAssignments,
    selected: "Asset Mgr-TMS-Workshop Attendant Assignments",
  },

  //--ENTRIES/PRIVILEDGES
  {
    path: "/assets-manager/transport/entries/priviledges/vehicle-requests",
    exact: true,
    component: PriviledgesEntriesScreen,
    selected: "Asset Mgr-TMS-Vehicle Requests Priviledges",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/fuel-dump-attendants",
    exact: true,
    component: Assets_setups_fuelDAAssignments,
    selected: "Asset Mgr-TMS-Fuel Dump Attendants",
  },

  //entries/exams
  {
    path: "/powerapps/elogbook/entries/elogbook",
    exact: true,
    component: Safety_Elogger,
    selected: "eLog Book - Log Book",
  },
  {
    path: "/powerapps/elogbook/setups/stationdetails",
    exact: true,
    component: StationDetails,
    selected: "eLog Book - Station Details",
  },
  {
    path: "/assets-manager/transport/entries/general/bus-details",
    exact: true,
    component: BusDetails,
    selected: "Asset Mgr-TMS-Bus Details",
  },
  {
    path: "/assets-manager/transport/entries/general/trouble-report",
    exact: true,
    // component: TroubleReports,
    component: TroubleReportWrapper,
    selected: "Asset Mgr-TMS Trouble Report",
  },

  //entries/general
  {
    path: "/assets-manager/transport/entries/exams/driving-tests",
    exact: true,
    component: AssetsEntries_drivingTests,
    selected: "Asset Mgr-TMS-Driving Tests",
  },
  {
    path: "/assets-manager/transport/entries/general/quipment-outage-types",
    exact: true,
    component: OutageEquipmentTypes,
    selected: "Asset Mgr-TMS-Equipment Outage Types",
  },
  {
    path: "/assets-manager/transport/entries/general/protection-guarantee-pc2",
    exact: true,
    component: Protection_guarantee_pc2,
    selected: "Protection Guarantee (PC2)",
  },
  {
    path: "/assets-manager/transport/entries/general/protection-guarantee-pc1",
    exact: true,
    component: ProtectionGuarantee1,
    selected: "Protection Guarantee (PC1)",
  },
  {
    path: "/assets-manager/transport/entries/general/tr-priveledges-assignment",
    exact: true,
    component: TroubleReportPriviledgesAssignment,
    selected: "Trouble Report Priveledges Assignment",
  },
  {
    path: "/assets-manager/transport/entries/general/job-hazard-analysis",
    exact: true,
    component: JobMain,
    selected: "Job Hazard Analysis",
  },

  //setups
  //---vehicles---
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-classes",
    exact: true,
    component: Assets_setups_vehicleClass,
    selected: "Asset Mgr-TMS-Vehicle Classes",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-make",
    exact: true,
    component: Assets_setups_vehicleMake,
    selected: "Asset Mgr-TMS-Vehicle Make",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-models",
    exact: true,
    component: Assets_setups_vehicleModel,
    selected: "Asset Mgr-TMS-Vehicle Models",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/manufacturers",
    exact: true,
    component: Assets_setups_manufacturer,
    selected: "Asset Mgr-TMS-Manufacturers",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/suppliers",
    exact: true,
    component: Assets_setups_suppliers,
    selected: "Asset Mgr-TMS-Suppliers",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/engine-types",
    exact: true,
    component: Assets_setups_engineType,
    selected: "Asset Mgr-TMS-Engine Types",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/power-trains",
    exact: true,
    component: Assets_setups_powerTrains,
    selected: "Asset Mgr-TMS-Power Trains",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/transmission-types",
    exact: true,
    component: Assets_setups_transmissionType,
    selected: "Asset Mgr-TMS-Transmission Types",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-statuses",
    exact: true,
    component: Assets_setups_vehicleStatuses,
    selected: "Asset Mgr-TMS-Vehicle Statuses",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/engine-makes",
    exact: true,
    component: Assets_setups_engineMakes,
    selected: "Asset Mgr-TMS-Engine Makes",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/cubic-capacity",
    exact: true,
    component: Assets_setups_cubicCapabilities,
    selected: "Asset Mgr-TMS-Cubic Capacity",
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-accessories",
    exact: true,
    component: Assets_setups_vehicleAccessories,
    selected: "Asset Mgr-TMS-Vehicle Accessories",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle&vehicle-class-assignments",
    exact: true,
    component: Assets_setups_vehicleClassAssignments,
    selected: "Asset Mgr-TMS-Vehicle CLass Assignment",
  },

  //---general---
  {
    path: "/assets-manager/transport/setups/general/document-types",
    exact: true,
    component: Assets_setups_docTypes,
    selected: "Asset Mgr-TMS-Document Types",
  },
  {
    path: "/assets-manager/transport/setups/general/accessories",
    exact: true,
    component: Assets_setups_accessories,
    selected: "Asset Mgr-TMS-Accessories",
  },
  {
    path: "/assets-manager/transport/setups/general/accessory-values",
    exact: true,
    component: Assets_setups_accessoryValues,
    selected: "Asset Mgr-TMS-Accessory Values",
  },
  {
    path: "/assets-manager/transport/setups/general/products",
    exact: true,
    component: Assets_setups_products,
    selected: "Asset Mgr-TMS-Products",
  },
  {
    path: "/assets-manager/transport/setups/general/trouble-categories",
    exact: true,
    component: TroubleCategories,
    selected: "Asset Mgr-TMS-Trouble Categories",
  },
  {
    path: "/assets-manager/transport/setups/general/transport-units",
    exact: true,
    component: Assets_setups_transunits,
    selected: "Asset Mgr-TMS-Transport Units",
  },
  {
    path: "/assets-manager/transport/setups/general/trouble-classes",
    exact: true,
    component: TroubleClasses,
    selected: "Trouble Rpts-Trouble Classes",
  },
  {
    path: "/assets-manager/transport/setups/general/equipment-details",
    exact: true,
    component: TroubleEquipment$AssetDetails,
    selected: "Trouble Rpts-Equipment or Asset Details",
  },
  // { path: "/assets-manager/transport/setups/general/log-regions",exact: true, component: Assets_setups_logRegions, selected: 'Asset Mgr-TMS-Regions'},
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-areas",
    exact: true,
    component: Assets_setups_VehicleRA,
    selected: "Asset Mgr-TMS-Vehicle Request Areas",
  },
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-categories",
    exact: true,
    component: Assets_setups_VehicleRC,
    selected: "Asset Mgr-TMS-Vehicle Request Categories",
  },
  {
    path: "/assets-manager/transport/setups/general/Drivers-permit-holders-categories",
    exact: true,
    component: Assets_setups_DPHCategiries,
    selected: "Asset Mgr-TMS-Drivers Permit Holders Categories",
  },
  {
    path: "/assets-manager/transport/setups/general/insurance-companies",
    exact: true,
    component: Assets_Setups_InsuranceCompanies,
    selected: "Asset Mgr-TMS-Insurance Companies",
  },
  {
    path: "/assets-manager/transport/setups/general/product-prices",
    exact: true,
    component: Assets_Setups_ProductPrices,
    selected: "Asset Mgr-TMS-Product Prices",
  },
  {
    path: "/assets-manager/transport/setups/general/card-details",
    exact: true,
    component: CardDetails,
    selected: "Asset Mgr-TMS-Card Details",
  },
  {
    path: "/assets-manager/transport/setups/general/driving-test-registrations",
    exact: true,
    component: DrivingTestRegistration,
    selected: "Asset Mgr-TMS-Driving Test Registrations",
  },
  {
    path: "/assets-manager/transport/setups/general/bulk-product-prices",
    exact: true,
    component: BulkProductPrices,
    selected: "Asset Mgr-TMS-Bulk Product Prices",
  },
  {
    path: "/assets-manager/transport/setups/general/workshop-details",
    exact: true,
    component: Workshop_details_setupform,
    selected: "Asset Mgr-TMS-Workshop Details",
  },
  {
    path: "/powerapps/elogbook/setups/eventtypes",
    exact: true,
    component: SafetySetupEventTypes,
    selected: "eLog Book - Event Types",
  },

  {
    path: "/assets-manager/transport/entries/general/station-readings",
    exact: true,
    component: SationReadings,
    selected: "eLog Book Station Readings",
  },
  {
    path: "/assets-manager/power-apps/elog/entries/general/load-profile-1",
    exact: true,
    component: LoadProfile1Parent,
    selected: "Load Profile One",
  },
  {
    path: "/assets-manager/transport/entries/general/outage-equipment-types",
    exact: true,
    component: OutageEquipmentType,
    selected: "eLog Book Outage Equipment Types",
  },
  {
    path: "/assets-manager/transport/entries/general/Documents",
    exact: true,
    component: Documents_Details,
    selected: "eLog Book Outage Equipment Types",
  },
  {
    path: "/assets-manager/transport/entries/general/law-catalogs",
    exact: true,
    component: LawCatalogs,
    selected: "Law catalogs",
  },
  {
    path: "/assets-manager/transport/entries/general/judgement-catalogs",
    exact: true,
    component: JudgementCatalogs,
    selected: "Judgement catalogs",
  },
  {
    path: "/assets-manager/transport/entries/general/transformer-details",
    exact: true,
    component: Transformers,
    selected: "Transformer Details",
  },
  {
    path: "/assets-manager/transport/entries/general/case-management",
    exact: true,
    component: Case_management,
    selected: "Case Management",
  },
  {
    path: "/assets-manager/transport/entries/general/task-management",
    exact: true,
    component: TaskManagement,
    selected: "Task Management",
  },
  {
    path: "/assets-manager/transport/entries/general/line-details",
    exact: true,
    component: LineDetails,
    selected: "Line Details",
  },
  {
    path: "/assets-manager/transport/entries/general/category-details",
    exact: true,
    component: Categorydetails,
    selected: "Category Details",
  },
  {
    path: "/assets-manager/transport/entries/general/calendar-management",
    exact: true,
    component: Calendarmanagement,
    selected: "Calendar Managment",
  },
  {
    path: "/assets-manager/transport/entries/general/order-to-operate",
    exact: true,
    component: Order_to_operate_main,
    selected: "Order To Operate",
  },

  //---fuel----
  {
    path: "/assets-manager/transport/setups/fuel/fuel-types",
    exact: true,
    component: Assets_setups_fuelTypes,
    selected: "Asset Mgr-TMS-Fuel Types",
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-levels",
    exact: true,
    component: Assets_setups_fuelLevels,
    selected: "Asset Mgr-Fuel Levels",
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-units",
    exact: true,
    component: Assets_setups_fuelUnits,
    selected: "Asset Mgr-TMS-Fuel Units",
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-purchase-modes",
    exact: true,
    component: Assets_setups_fuelPurchaseModes,
    selected: "Asset Mgr-TMS-Fuel Purchase Modes",
  },
  // ---maintenance---
  {
    path: "/assets-manager/transport/setups/maintenance/service-stations",
    exact: true,
    component: Assets_setups_ServiceStations,
    selected: "Asset Mgr-TMS-Service Stations",
  },
  {
    path: "/assets-manager/transport/setups/maintenance/service-providers",
    exact: true,
    component: Assets_setups_ServiceProviders,
    selected: "Asset Mgr-TMS-Service Providers",
  },
  {
    path: "/assets-manager/transport/setups/maintenance/service-items",
    exact: true,
    component: Assets_setups_serviceItems,
    selected: "Asset Mgr-TMS-Service Items",
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-types",
    exact: true,
    component: Assets_setups_maintenanceTypes,
    selected: "Asset Mgr-TMS-Maintenance Types",
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-items&checklist",
    exact: true,
    component: Assets_setups_Maintenance_I_A_M,
    selected: "Asset Mgr-TMS-Maintenance Items & Checklist",
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    exact: true,
    component: Assets_setups_maintenancelocations,
    selected: "Asset Mgr-TMS-Maintenance Locations",
  },

  //TASKS-TODOS
  {
    path: "/tasks",
    exact: true,
    component: TaskTab,
    selected: "Tasks & Todos",
  },
  //TEL DIR
  {
    path: "/tel-directory",
    exact: true,
    component: TelephoneDir,
    selected: "Tel. Directory",
  },

  //Assets Manager
  //Transport Fuel Entries
  {
    path: "/assets-manager/transport/entries/fuel/fuel-dump-dips",
    exact: true,
    component: Dump_Dips,
    selected: "Asset Mgr-TMS-Fuel Dump Dips",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-backlog-entries",
    exact: true,
    component: Fuel_Backlog,
    selected: "Asset Mgr-TMS-Fuel Backlog",
  },

  //#transport

  // Alert Drivers
  {
    path: "/assets-manager/transport/entries/transport-alerts-drivers-permit-holders",
    exact: true,
    component: Alert_Drivers,
    selected: "Asset Mgr-TMS-Alert Drivers",
  },
  {
    path: "/assets-manager/transport/entries/activities-capture",
    exact: true,
    component: ActivitiesCapture,
    selected: "Asset Mgr-TMS-Activities Capture",
  },

  //Entries/Fuel
  {
    path: "/assets-manager/transport/entries/fuel/return_request",
    exact: true,
    component: Return_Request,
    selected: "Asset Mgr-TMS-Return Request",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-transaction-uploads",
    exact: true,
    component: Transaction_Upload,
    selected: "Asset Mgr-TMS-Transactions Upload",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-topup-request",
    exact: true,
    component: Topup_Request,
    selected: "Asset Mgr-TMS-Topup Request",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-dump-dips",
    exact: true,
    component: Dump_Dips,
    selected: "Asset Mgr-TMS-Dump Dips",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-backlog-entries",
    exact: true,
    component: Fuel_Backlog,
    selected: "Asset Mgr-TMS-Fuel Backlog",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-transactions",
    exact: true,
    component: Fuel_Transactions,
    selected: "Asset Mgr-TMS-Fuel Transactions",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-documents",
    exact: true,
    component: Fuel_Documents,
    selected: "Asset Mgr-TMS-Fuel Documents",
  },
  {
    path: "/assets-manager/transport/entries/fuel/tag-comments",
    exact: true,
    component: TagComments,
    selected: "Asset Mgr-TMS-Fuel Tag Comments",
  },

  //Entries/Maintenance
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-activities",
    exact: true,
    component: Maintenance_Activities,
    selected: "Asset Mgr-TMS-Maintenance-Activities",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-schedule",
    exact: true,
    component: Maintenance_Schedule,
    selected: "Asset Mgr-TMS- Maintenance Schedule",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-requests",
    exact: true,
    component: MaintenanceEntriesScreen,
    selected: "Asset Mgr-TMS-Maintenance Requests",
  },
  // {
  //   path: "/assets-manager/transport/entries/maintenance/maintenance-schedule-alert",
  //   exact: true,
  //   component: Schedule_Alert,
  //   selected: "Schedule_Alert",
  // },
  {
    path: "/assets-manager/transport/entries/Maintenance/sms-alerts",
    exact: true,
    component: SmsAlertsTransport,
  },

  //entries/vehicles
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-assignments",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "TMS Vehicle Assignments",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-sim-card-assignments",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Transport-Vehicle-SIM Card Assignment",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-requests",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "TMS-Vehicle Requests",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-condition-returns",
    exact: true,
    component: VehicleConditions,
    selected: "Asset Mgr-Transport-Vehicle Condition Returns",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-details",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Asset Mgr-Transport-Vehicle Details",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-images",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Asset Mgr-Transport-Vehicle Images",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-log-book",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Transport - Vehicle Log Book",
  },
  //Entries/Fuel
  {
    path: "/assets-manager/transport/entries/fuel/return_request",
    exact: true,
    component: Return_Request,
    selected: "Asset Mgr-TMS- Return Request",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-transaction-uploads",
    exact: true,
    component: Transaction_Upload,
    selected: "Asset Mgr-TMS-Transactions Upload",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-topup-request",
    exact: true,
    component: Topup_Request,
    selected: "Asset Mgr-TMS-Topup-Request",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-backlog-entries",
    exact: true,
    component: Fuel_Backlog,
    selected: "Asset Mgr-TMS-Fuel Backlog",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-transactions",
    exact: true,
    component: Fuel_Transactions,
    selected: "Asset Mgr-TMS-Transactions",
  },
  {
    path: "/assets-manager/transport/entries/fuel/fuel-documents",
    exact: true,
    component: Fuel_Documents,
    selected: "Asset Mgr-TMS-Fuel Documents",
  },
  {
    path: "/assets-manager/transport/entries/fuel/authorise-fuel-requests",
    exact: true,
    component: AuthorizeFuelRequestDetails,
    selected: "Asset Mgr-TMS-AuthorizeFuelRequestDetails",
  },

  //Entries/Maintenance
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-activities",
    exact: true,
    component: Maintenance_Activities,
    selected: "Asset Mgr-TMS-Maintenance-Activities",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-schedule",
    exact: true,
    component: Maintenance_Schedule,
    selected: "Asset Mgr-TMS-Maintenance-Schedule",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-requests",
    exact: true,
    component: MaintenanceEntriesScreen,
    selected: "TMS-Maintenance Requests",
  },
  {
    path: "/assets-manager/transport/entries/Maintenance/sms-alerts",
    exact: true,
    component: SmsAlertsTransport,
    selected: "Asset Mgr-TMS-SMS-Alert",
  },

  //entries/vehicles
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-assignments",
    exact: true,
    component: VehicleAssignments,
    selected: "TMS Vehicle Assignments",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-sim-card-assignments",
    exact: true,
    component: VehicleSimCard,
    selected: "Transport-Vehicle-SIM Card Assignment",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-requests",
    exact: true,
    component: VehicleRequests,
    selected: "Asset Mgr-TMS-Vehicle Requests",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-condition-returns",
    exact: true,
    component: VehicleConditions,
    selected: "Asset Mgr-Transport-Vehicle Condition Returns",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-details",
    exact: true,
    component: VehicleDetails,
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-images",
    exact: true,
    component: VehicleImages,
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-log-book",
    exact: true,
    component: VehicleLogBook,
  },

  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-fuel-card-assignments",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Asset Mgr-Transport-Vehicle-Card Assignment",
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-data-upload",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Asset Mgr-Transport-Vehicle Data Upload",
  },
  // '/assets-manager/transport/entries/vehicles/vehicle-requests-security'
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-requests-security",
    exact: true,
    component: VehiclesEntriesScreen,
    selected: "Transport - Vehicle Requests",
  },

  //entries/maintenance
  //--ENTRIES/MAINTENANCE
  {
    path: "/assets-manager/transport/entries/maintenance/maintenance-requests",
    exact: true,
    component: MaintenanceEntriesScreen,
    selected: "TMS-Maintenance Requests",
  },

  //--ENTRIES/PRIVILEDGES
  {
    path: "/assets-manager/transport/entries/priviledges/vehicle-request-priveledges",
    exact: true,
    component: TMSApproversVehicleRequestPriveledges,
    selected: "Asset Mgr-TMS-Vehicle Request Priviledges",
  },

  {
    path: "/assets-manager/transport/entries/priviledges/vehicle-requests",
    exact: true,
    component: PriviledgesEntriesScreen,
    selected: "TMS-Vehicle Requests Priviledges",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/fuel-dump-attendants",
    exact: true,
    component: Fuel_Dump_Attendants,
  },

  //entries/exams
  {
    path: "/assets-manager/transport/entries/exams/driving-tests",
    exact: true,
    component: AssetsEntries_drivingTests,
  },

  //setups
  //---vehicles---
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-classes",
    exact: true,
    component: Assets_setups_vehicleClass,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-make",
    exact: true,
    component: Assets_setups_vehicleMake,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-models",
    exact: true,
    component: Assets_setups_vehicleModel,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/manufacturers",
    exact: true,
    component: Assets_setups_manufacturer,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/suppliers",
    exact: true,
    component: Assets_setups_suppliers,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/engine-types",
    exact: true,
    component: Assets_setups_engineType,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/power-trains",
    exact: true,
    component: Assets_setups_powerTrains,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/transmission-types",
    exact: true,
    component: Assets_setups_transmissionType,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-statuses",
    exact: true,
    component: Assets_setups_vehicleStatuses,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/engine-makes",
    exact: true,
    component: Assets_setups_engineMakes,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/cubic-capacity",
    exact: true,
    component: Assets_setups_cubicCapabilities,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-accessories",
    exact: true,
    component: Assets_setups_vehicleAccessories,
  },
  // {
  //   path: "/assets-manager/transport/setups/vehicles/vehicle&vehicle-class-assignments",
  //   exact: true,
  //   component: Assets_setups_vehicleClassAssignments,
  // },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-fuel-card-assignments",
    exact: true,
    component: VehicleCard,
  },
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-data-upload",
    exact: true,
    component: VehicleDataUpload,
    selected: "Asset Mgr-Transport-Vehicle Data Upload",
  },
  // '/assets-manager/transport/entries/vehicles/vehicle-requests-security'
  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-requests-security",
    exact: true,
    component: VehicleRequestsSecurity,
    selected: "Transport - Vehicle Requests",
  },

  {
    path: "/assets-manager/transport/entries/vehicles/vehicle-smsAlertVehicleUsers",
    exact: true,
    component: SmsAlertVehicleUsers,
  },
  {
    path: "/assets-manager/transport/entries/priviledges/drivers-code-assignments",
    exact: true,
    component: DriverCodeAssignments,
  },
  {
    path: "/assets-manager/transport/entries/priviledges/drivers&permit-holders-assignments",
    exact: true,
    component: DriversPermitHoldersAssignments,
  },
  {
    path: "/assets-manager/transport/entries/priviledges/workshop-attendant-assignments",
    exact: true,
    component: TmsWorkshopAttendantAssignments,
  },
  {
    path: "/powerapps/elogbook/entries/privilegesassignments",
    exact: true,
    component: ElogBookMainPriv,
    selected: "eLog Book - Privilege Assignments",
  },
  {
    path: "/assets-manager/transport/entries/priviledges/outage-privilege-assignments",
    exact: true,
    component: OutageMainPriv,
    selected: "Outage Mgr Privilege Assignments",
  },

  //--ENTRIES/PRIVILEDGES
  {
    path: "/assets-manager/transport/entries/priviledges/vehicle-requests",
    exact: true,
    component: PriviledgesEntriesScreen,
    selected: "TMS-Vehicle Requests Priviledges",
  },
  {
    path: "/assets-manager/transport/entries/maintenance/fuel-dump-attendants",
    exact: true,
    component: Assets_setups_fuelDAAssignments,
  },

  //entries/exams
  {
    path: "/assets-manager/transport/entries/exams/driving-tests",
    exact: true,
    component: AssetsEntries_drivingTests,
  },

  //setups
  //---vehicles---
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-classes",
    exact: true,
    component: Assets_setups_vehicleClass,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-make",
    exact: true,
    component: Assets_setups_vehicleMake,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-models",
    exact: true,
    component: Assets_setups_vehicleModel,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/manufacturers",
    exact: true,
    component: Assets_setups_manufacturer,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/suppliers",
    exact: true,
    component: Assets_setups_suppliers,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/engine-types",
    exact: true,
    component: Assets_setups_engineType,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/power-trains",
    exact: true,
    component: Assets_setups_powerTrains,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/transmission-types",
    exact: true,
    component: Assets_setups_transmissionType,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-statuses",
    exact: true,
    component: Assets_setups_vehicleStatuses,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/engine-makes",
    exact: true,
    component: Assets_setups_engineMakes,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/cubic-capacity",
    exact: true,
    component: Assets_setups_cubicCapabilities,
  },
  {
    path: "/assets-manager/transport/setups/vehicles/vehicle-accessories",
    exact: true,
    component: Assets_setups_vehicleAccessories,
  },
  //---general---
  {
    path: "/assets-manager/transport/setups/general/document-types",
    exact: true,
    component: Assets_setups_docTypes,
  },
  {
    path: "/assets-manager/transport/setups/general/accessories",
    exact: true,
    component: Assets_setups_accessories,
  },
  {
    path: "/assets-manager/transport/setups/general/accessory-values",
    exact: true,
    component: Assets_setups_accessoryValues,
  },
  {
    path: "/assets-manager/transport/setups/general/products",
    exact: true,
    component: Assets_setups_products,
  },
  {
    path: "/assets-manager/transport/setups/general/transport-units",
    exact: true,
    component: Assets_setups_transunits,
  },
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-areas",
    exact: true,
    component: Assets_setups_VehicleRA,
  },
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-categories",
    exact: true,
    component: Assets_setups_VehicleRC,
  },
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-categories",
    exact: true,
    component: Assets_setups_VehicleRC,
  },
  {
    path: "/assets-manager/transport/setups/general/Drivers-permit-holders-categories",
    exact: true,
    component: Assets_setups_DPHCategiries,
  },
  {
    path: "/assets-manager/transport/setups/general/insurance-companies",
    exact: true,
    component: Assets_Setups_InsuranceCompanies,
  },
  {
    path: "/assets-manager/transport/setups/general/product-prices",
    exact: true,
    component: Assets_Setups_ProductPrices,
  },
  {
    path: "/assets-manager/power-apps/elog/setups/accuracy-classes",
    exact: true,
    component: Assets_power_setups_accuracyClass,
    selected: "Asset Mgr-TAM-Accuracy Class",
  },
  {
    path: "/assets-manager/power-apps/elog/setups/manufacturer",
    exact: true,
    component: Manufacturers,
    selected: "Asset Mgr-TAM-Manufacturer",
  },

  //---fuel---
  {
    path: "/assets-manager/transport/setups/fuel/fuel-types",
    exact: true,
    component: Assets_setups_fuelTypes,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-levels",
    exact: true,
    component: Assets_setups_fuelLevels,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-units",
    exact: true,
    component: Assets_setups_fuelUnits,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-purchase-modes",
    exact: true,
    component: Assets_setups_fuelPurchaseModes,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-dump-attendant-assignments",
    exact: true,
    component: Assets_setups_fuelDAAssignments,
  },
  // ---maintenance---
  {
    path: "/assets-manager/transport/setups/maintenance/service-stations",
    exact: true,
    component: Assets_setups_ServiceStations,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/service-providers",
    exact: true,
    component: Assets_setups_ServiceProviders,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/service-items",
    exact: true,
    component: Assets_setups_serviceItems,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-types",
    exact: true,
    component: Assets_setups_maintenanceTypes,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-items&checklist",
    exact: true,
    component: Assets_setups_Maintenance_I_A_M,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    exact: true,
    component: Assets_setups_maintenancelocations,
  },
  //---general---
  {
    path: "/assets-manager/transport/setups/general/document-types",
    exact: true,
    component: Assets_setups_docTypes,
  },
  {
    path: "/assets-manager/transport/setups/general/accessories",
    exact: true,
    component: Assets_setups_accessories,
  },
  {
    path: "/assets-manager/transport/setups/general/accessory-values",
    exact: true,
    component: Assets_setups_accessoryValues,
  },
  {
    path: "/assets-manager/transport/setups/general/products",
    exact: true,
    component: Assets_setups_products,
  },
  {
    path: "/assets-manager/transport/setups/general/transport-units",
    exact: true,
    component: Assets_setups_transunits,
  },
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-areas",
    exact: true,
    component: Assets_setups_VehicleRA,
  },
  {
    path: "/assets-manager/transport/setups/general/vehicle-request-categories",
    exact: true,
    component: Assets_setups_VehicleRC,
  },
  {
    path: "/assets-manager/transport/setups/general/Drivers-permit-holders-categories",
    exact: true,
    component: Assets_setups_DPHCategiries,
  },
  {
    path: "/assets-manager/transport/setups/general/insurance-companies",
    exact: true,
    component: Assets_Setups_InsuranceCompanies,
  },
  {
    path: "/assets-manager/transport/setups/general/product-prices",
    exact: true,
    component: Assets_Setups_ProductPrices,
  },

  {
    path: "/assets-manager/transport/setups/general/card-details",
    exact: true,
    component: CardDetails,
  },
  {
    path: "/assets-manager/transport/setups/general/driving-test-registrations",
    exact: true,
    component: DrivingTestRegistration,
  },
  {
    path: "/assets-manager/transport/setups/general/bulk-product-prices",
    exact: true,
    component: BulkProductPrices,
    selected: "Bulk Product Prices",
  },
  {
    path: "/assets-manager/transport/setups/general/exams-names",
    exact: true,
    component: TMSExamNames,
    selected: "Exams Names",
  },
  {
    path: "/assets-manager/transport/setups/general/exams-questions",
    exact: true,
    component: TMSExamsQuestions,
    selected: "Exams Questions",
  },

  //---fuel---
  {
    path: "/assets-manager/transport/setups/fuel/fuel-types",
    exact: true,
    component: Assets_setups_fuelTypes,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-levels",
    exact: true,
    component: Assets_setups_fuelLevels,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-units",
    exact: true,
    component: Assets_setups_fuelUnits,
  },
  {
    path: "/assets-manager/transport/setups/fuel/fuel-purchase-modes",
    exact: true,
    component: Assets_setups_fuelPurchaseModes,
  },
  // ---maintenance---
  {
    path: "/assets-manager/transport/setups/maintenance/service-stations",
    exact: true,
    component: Assets_setups_ServiceStations,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/service-providers",
    exact: true,
    component: Assets_setups_ServiceProviders,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/service-items",
    exact: true,
    component: Assets_setups_serviceItems,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-types",
    exact: true,
    component: Assets_setups_maintenanceTypes,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-items&checklist",
    exact: true,
    component: Assets_setups_Maintenance_I_A_M,
  },
  {
    path: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    exact: true,
    component: Assets_setups_maintenancelocations,
  },

  //PERFORMANCE MANAGER
  //setups
  {
    path: "/performance-manager/setups/corporate-measures",
    exact: true,
    component: PerformanceSetupsCorporateMeasures,
    selected: "Pfm-Coorporate Measures",
  },
  {
    path: "/performance-manager/setups/department-initiatives",
    exact: true,
    component: Perf_setups_deptInitiatives,
    selected: "Pfm-Department Initiatives",
  },
  {
    path: "/performance-manager/setups/measures",
    exact: true,
    component: Perf_setups_measures,
    selected: "Pfm-Measures",
  },
  {
    path: "/performance-manager/setups/periods",
    exact: true,
    component: PerformanceSetupsPeriods,
    selected: "Pfm-Periods",
  },
  {
    path: "/performance-manager/setups/section-initiatives",
    exact: true,
    component: Perf_setups_secInitiatives,
    selected: "Pfm-Section Initiatives",
  },
  {
    path: "/performance-manager/setups/ratings",
    exact: true,
    component: PerformanceSetupsRatings,
    selected: "Pfm-Ratings",
  },
  {
    path: "/performance-manager/setups/units-of-measure",
    exact: true,
    component: Perf_setups_unitOfMeasure,
    selected: "Pfm-Units of Measures",
  },
  {
    path: "/performance-manager/setups/unit-initiatives",
    exact: true,
    component: Perf_setups_unitInitiatives,
    selected: "Pfm-Unit Initiatives",
  },
  {
    path: "/performance-manager/setups/targets",
    exact: true,
    component: Perf_setups_Target,
    selected: "Pfm-Targets",
  },
  {
    path: "/performance-manager/setups/results-monitoring-modules",
    exact: true,
    component: PerformanceSetupsMonitoringModules,
    selected: "Pfm-Results Monitoring Modules",
  },
  {
    path: "/performance-manager/setups/staff-initiatives",
    exact: true,
    component: PerformanceStaffInitiatives,
    selected: "Pfm-Staff Initiatives",
  },
  {
    path: "/performance-manager/setups/core-values",
    exact: true,
    component: CoreValues,
    selected: "Pfm-Core Values",
  },
  {
    path: "/performance-manager/setups/weights",
    exact: true,
    component: PerformanceWeights,
    selected: "Pfm-Weights",
  },

  //  PERFORMANCE
  // Entries

  {
    path: "/performance-manager/entries/staff-initiative-assignments",
    exact: true,
    component: SIA,
    selected: "Pfm-SIA",
  },
  {
    path: "/performance-manager/entries/signoff-assignments",
    exact: true,
    component: SignOff_Assignments,
    selected: "Pfm-Sign Off Assignments",
  },
  {
    path: "/performance-manager/entries/lock-assignments",
    exact: true,
    component: Lock_assignments,
    selected: "Pfm-Lock Assignments",
  },
  {
    path: "/performance-manager/entries/corperative-initiative-assignments",
    exact: true,
    component: CIA,
    selected: "Pfm-CIA",
  },
  {
    path: "/performance-manager/entries/corperate-initiatives",
    exact: true,
    component: CI,
    selected: "Pfm-CI",
  },
  {
    path: "/performance-manager/entries/department-initiative-assignments",
    exact: true,
    component: DIA,
    selected: "Pfm-DIA",
  },
  {
    path: "/performance-manager/entries/section-initiative-assignments",
    exact: true,
    component: SecIA,
    selected: "Pfm-SecIA",
  },
  {
    path: "/performance-manager/entries/unit-initiative-assignments",
    exact: true,
    component: UIA,
    selected: "Pfm-UIA",
  },
  {
    path: "/performance-manager/entries/mission-vision",
    exact: true,
    component: Mission_Vision,
    selected: "Pfm-Mission Vision",
  },
  {
    path: "/performance-manager/entries/perspectives",
    exact: true,
    component: Perspectives,
    selected: "Pfm-Perspectives",
  },
  {
    path: "/performance-manager/entries/strategic-themes",
    exact: true,
    component: Theme_Tabs,
    selected: "Pfm-Strategic Themes",
  },
  {
    path: "/performance-manager/entries/strategic-objectives",
    exact: true,
    component: Objective_Tabs,
    selected: "Pfm-Strategic Objectives",
  },
  {
    path: "/performance-manager/entries/measure",
    exact: true,
    component: Measure,
    selected: "Pfm-Measure",
  },
  {
    path: "/performance-manager/entries/target",
    exact: true,
    component: Target,
    selected: "Pfm-Target",
  },
  {
    path: "/performance-manager/entries/measures-targets",
    exact: true,
    component: Measures_Targets_Tabs,
    selected: "Pfm-Measures & Targets",
  },
  {
    path: "/performance-manager/entries/staff-assignments-appraisal",
    exact: true,
    component: StaffAssignmentAppraisal,
    selected: "Pfm - Initiative Appraisal",
  },
];
