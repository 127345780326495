import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DataGrid } from "devextreme-react";
import {
  SearchPanel,
  GroupPanel,
  Pager,
  Column,
  Scrolling,
} from "devextreme-react/data-grid";
import reset from "../../../../../../assets/reset.png";
import { question_table_data, questions_TH } from "../data/questions-data";
// import { EditButton, NewButton, SaveButton } from "../../../../../accessories/buttons";
// import { smallAttendanceData, smallAttendaceTable_TH, mediumlAttendaceTable_TH } from "../../../../../Safety/Entries/SafetyMeetingAttendance/data/meeting-attendance-data";
import { useState, useEffect } from "react";
// import { api_url, navbar_height,  toolbar_height } from "../../../../../accessories/component_infos";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import axios from "axios";
import { useSelector } from "react-redux";
// import { RootState } from "../../../../../../app/store";
// import { settask_footer_table_refresh } from "../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  api_url,
  navbar_height,
  saveModalData,
  toolbar_height,
  updateModalData,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { settask_footer_table_refresh } from "../../../../features/generalSlice";
import { InputsTemplate } from "../../../../templates/input";
import { TextareaTemplate } from "../../../../templates/textarea";
import { useCrudFunc } from "../../../../functions/crud";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../templates/modal";
import { title } from "process";
import { ok } from "assert";
import { UpdateButton } from "../../../accessories/buttons";
interface props {
  toggled?: boolean;
  statesx?: any;
  setRefresh?: (e: boolean) => void;
  refresh?: boolean;
}

const GPSLineDetailsTab = ({
  toggled,
  statesx,
  refresh,
  setRefresh,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm";
  const dispatch = useDispatch();
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [states, setStates] = useState({
    startLtt: "",
    endLtt: "",
    startLgt: "",
    endLgt: "",
    rmks: "",
    refresh: false,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);

  const editedDate = dayjs().format();

  const populateFields = (clear?: boolean) => {
    const gridState = [
      {
        key: "startLtt",
        value: clear ? states.startLtt : statesx.ldtStartLatitude,
      },
      {
        key: "endLtt",
        value: clear ? states.endLtt : statesx.ldtEndLatitude,
      },
      {
        key: "startLgt",
        value: clear ? states.startLgt : statesx.ldtStartLongitude,
      },
      {
        key: "endLgt",
        value: clear ? states.endLgt : statesx.ldtEndLongitude,
      },
      // {
      //   key: "rmks",
      //   value: clear ? states.rmks : statesx.ldtRmks,
      // },
    ];

    for (let instance of gridState) {
      console.log(
        `Setting state for ${instance.key} with value ${instance.value}`
      );
      setState(instance.key, instance.value);
    }
  };

  let FormCode = useSelector((state: RootState) => state.user?.formCode);

  const ValidateForm = () => {
    setIcon("warning");
    if (states.startLtt === "" || !states.startLtt) {
      setModalData({
        title: "Invalid field",
        message: "Start Latitude is required",
        okText: "Ok",
        cancelText: "",
      });
      setOpenModal(true);
      return false;
    } else if (states.endLtt === "" || !states.endLtt) {
      setModalData({
        title: "Invalid field",
        message: "End Latitude is required",
        okText: "Ok",
        cancelText: "",
      });
      setOpenModal(true);
      return false;
    } else if (states.startLgt === "" || !states.startLgt) {
      setModalData({
        title: "Invalid field",
        message: "Start Longitude is required",
        okText: "Ok",
        cancelText: "",
      });
      setOpenModal(true);
      return false;
    } else if (states.endLgt === "" || !states.endLgt) {
      setModalData({
        title: "Invalid field",
        message: "End Longitude is required",
        okText: "Ok",
        cancelText: "",
      });
      setOpenModal(true);
      return false;
    } else {
      setIcon("question");
      setModalData({
        title: "Update Record",
        message: "Are you sure you want to update this record?",
        okText: "Yes",
        cancelText: "No",
      });
      setOpenModal(true);
      return true;
    }
  };

  const [Posting, Updating, Patching] = useCrudFunc("powerapp");

  const updateData = async () => {
    try {
      const res = await Updating(`LineDetails/gps/${FormCode}/test`, {
        ldtStartLatitude: states.startLtt,
        ldtEndLatitude: states.endLtt,
        ldtStartLongitude: states.startLgt,
        ldtEndLongitude: states.endLgt,
        ldtRmks: states.rmks,
        ldtEditedBy: userId,
        ldtEditedDate: editedDate,
        ldtIDpk: statesx?.ldtIDpk,
      }).then((response) => {
        console.log(response);
        setOpenModal(true);
        setIcon("success");
        setModalData({
          title: "Record Updated",
          message: "Record updated successfully",
          okText: "Ok",
          cancelText: "",
        });
        // dispatch(settask_footer_table_refresh(setRefresh(!refresh)))
        setRefresh && setRefresh(!refresh);
      });

      // setOpenModal(false)
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
   populateFields(false);
    console.log(`inside ${toggled}`);
  }, [toggled, statesx]);

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Update Record"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "success" || icon === "warning" ? true : false}
      />
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              name="complex-form"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 20 }}
              size={"small"}
              className="ml-2 my-2 "
              style={{ width: "100%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Start & End Latitude"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(50%)" }}
                  labelCol={{ span: 4 }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={form_disable}
                      style={{ width: "100%" }}
                      defaultValue={states.startLtt}
                      orderOnchange={(e) => {
                        setState("startLtt", e);
                      }}
                      //  onChange={(e: any) => {
                      //    setStartLtt(e.target.value);
                      //  }}
                      //  style={{ marginBottom: 0 }}
                      //  className=""
                      placeholder=""
                    />

                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={form_disable}
                      style={{ width: "100%", marginLeft: "5px" }}
                      defaultValue={states.endLtt}
                      orderOnchange={(e) => {
                        setState("endLtt", e);
                      }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Start & End Longitude"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(50%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={form_disable}
                      style={{ width: "100%" }}
                      defaultValue={states.startLgt}
                      orderOnchange={(e) => {
                        setState("startLgt", e);
                      }}
                      placeholder=""
                    />

                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={form_disable}
                      style={{ width: "100%", marginLeft: "5px" }}
                      defaultValue={states.endLgt}
                      orderOnchange={(e) => {
                        setState("endLgt", e);
                      }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Remarks"}</p>}
                style={{
                  marginBottom: 0,
                  marginTop: "2px",
                  width: "100%",
                }}
              >
                <div className="flex flex-col w-[95%]">
                  <TextareaTemplate
                    setCallbackValue={(e) => {
                      setState("rmks", e);
                    }}
                    useCallbackFunc
                    height={200}
                    width="100%"
                    defaultValue={states.rmks}
                    readonly={false}
                    disabled={form_disable}
                  />
                  <div className="flex justify-end mt-4">
                    <UpdateButton
                      handleUpdate={() => {
                        ValidateForm();
                      }}
                      disableButton={form_disable}
                    />
                  </div>
                </div>
              </Form.Item>
            </Form>

            {/* <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 ml-2 "
              style={{ width: "45%" }}
            >
              <div className=" border-t-2 pb-2 ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Images
                </div>

                <div className="px-2 pb-2 flex items-end    h-64 w-full ">
                  <div className=" w-full h-60  border flex justify-center items-center">
                    No Image data
                  </div>
                </div>
                <div className="flex mx-2 mb-0.5">
                  {" "}
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "88%",
                    }}
                    className="o  py-1  overflow-y-scroll resize-none"
                    rows={1}
                  />
                  <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  mr-1 px-2  border-2 border-slate-200 rounded">
                    <img className="pr-1" src={save} />
                  </button>
                  <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded">
                    <img className="pr-1" src={newIcon} />
                  </button>
                </div>
                <div className="flex">
                  <div
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "100%",
                    }}
                    className="o mx-2  border  flex "
                  >
                    <div className=" border-r p-0.5 text-xs px-2">1</div>
                    <div className=" border-r p-0.5 text-xs px-2">2</div>
                    <div className=" border-r p-0.5 text-xs px-2">3</div>
                    <div className=" border-r p-0.5 text-xs px-2">4</div>
                    <div className=" border-r p-0.5 text-xs px-2">5</div>
                    <div className=" border-r p-0.5 text-xs px-2">6</div>
                    <div className=" border-r p-0.5 text-xs px-2">7</div>
                    <div className=" border-r p-0.5 text-xs px-2">8</div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
        </div>
      }
    </div>
  );
};

export default GPSLineDetailsTab;
