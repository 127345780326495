import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import DataGrid, {
  Column,
  DataGridTypes,
  Editing,
  GroupPanel,
  HeaderFilter,
  LoadPanel,
  Pager,
  Paging,
  RemoteOperations,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { datagridColumnTypes } from "../components/human-resource/setups/data/types/selectsTypes";
import reset from "../assets/reset.png";
import settings from "../assets/setting.png";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setGeneralValue } from "../features/generalSlice";
import { useDispatch } from "react-redux";
import { ModalTemplate } from "./modal";
import { useFormPriviledge } from "../hooks/useFormPriviledge";
import {
  CellClickEvent,
  ContentReadyEvent,
  InitializedEvent,
} from "devextreme/ui/data_grid";

interface props {
  delectRecords?:boolean;
  className?: any;
  gridheight: number;
  gridwidth?: number | string;
  keyExpr?: any;
  columns: any;
  data: any;
  loading?: boolean;
  selected?: any;
  rowDoubleClicked?: (selectedField: any) => void;
  onRowClick?: (selectedField: any) => void;
  selectedRowIndex?: (index: number) => void;
  selectedItemsChange?: (selectedItems: any) => void;
  handleRefresh?: () => void; // handle refresh of the grids
  handlePageSize?: (e: number) => void;
  handlePageNumber?: (e: number) => void;
  pageSize?: number;
  pageNumber?: number;
  disableSearch?: boolean;
  disableGroupPanel?: boolean;
  allowExporting?: boolean;
  style?: any;
  disablePaging?: boolean;
  selectionMode?: "single" | "multiple" | "none"; //"single" | "multiple" | "none" | "row" | "column";
  showSelectionCheckBox?: "always";
  searchWidth?: number;
  allowColumnEditing?: boolean;
  format?: string;
  saveEditedCell?: (newData: any, value: any, currentRowData: any) => void;
  DataGridRef?: any;
  onRowPrepared?: (e: any) => void;
  onToolbarPreparing?: (e: any) => void;
  onContentReady?: (e: ContentReadyEvent<any, any>) => void;
  onRowUpdated?: (e: any) => void;
  deselectFirstRow?: boolean;
  resetbtn?: boolean;
  settingsbtn?: boolean;
  extraBtn?: boolean;
  extraWidget?: any;
  ref?: any;

  settingsOnClick?: () => void;
  noColumn?: number;
  cellColoringFxn?: (e: any) => void;
  summary?: any;
  isAddBtn?: boolean;
  isShowCol?: boolean;
  visibleCol?: { id: number; caption: string; action: boolean }[];
  editorOptions?: any; // adding other components in the datagrid
  editorItems?: any[]; // which captions to apply it to
  wordWrap?: boolean;
  showColumnHeaders?: boolean;
  dataId?: string;
  rowAlternationEnabled?: boolean;
  onCustomSelectionChanged?: (e: any) => void;

  editingOptions?: any;
  onCellClicked?: (e: CellClickEvent) => void;
  onDatagridInitialized?: (e: InitializedEvent<any, any>) => void;

  columnCellRender?: (e: {}, value: string) => ReactNode;

  dataRowRender?: (e: DataGridTypes.RowTemplateData) => React.ReactNode;
  addCustomColumn?: boolean;
  secondExtraColumn?: boolean;
  customColumnCaption?: string;
  onRowUpdating?: (e: any) => void;
  showBorders?: boolean;
  columnAutoWidth?: boolean;
}

const Datagrid_template = ({
  onRowClick,
  dataId,
  gridheight,
  gridwidth,
  style,
  searchWidth,
  selectionMode = "single",
  showSelectionCheckBox = "always",
  disableGroupPanel,
  disablePaging,
  disableSearch,
  columns,
  keyExpr,
  data,
  selectedRowIndex,
  allowColumnEditing,
  rowDoubleClicked,
  onRowPrepared,
  onToolbarPreparing,
  selectedItemsChange,
  onContentReady,
  DataGridRef,
  deselectFirstRow,
  onRowUpdated,
  resetbtn = false,
  settingsbtn = false,
  extraWidget,
  ref,
  extraBtn = false,
  className,
  settingsOnClick,
  noColumn,
  cellColoringFxn,
  summary,
  handleRefresh,
  isAddBtn = false,
  visibleCol,
  wordWrap = false,
  showColumnHeaders = true,
  rowAlternationEnabled = true,

  editingOptions,
  onCellClicked,
  onDatagridInitialized,

  columnCellRender,
  dataRowRender,
  handlePageNumber,
  handlePageSize,
  pageNumber,
  pageSize,
  addCustomColumn,

  secondExtraColumn,
  customColumnCaption,
  onRowUpdating,
  showBorders = true,
  columnAutoWidth = false,
  delectRecords,
}: props) => {
  const dataGridRef = useRef<any>();

  const dispatch = useDispatch();

  const [savePriv, readPriv, updatePriv] = useFormPriviledge("read");

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const rxReadAaccessPriv: boolean = useSelector(
    (state: RootState) => state.general.DatagridReadPriv
  );
  const formCode: string = useSelector(
    (state: RootState) => state.user.formCode
  );
  const [modalState, setModalState] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({ state: false, title: "", message: "" });

  const displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];

  const allowedPageSizes: any[] = [20, 50, 125, 200, 500, 1000];

  const dateFormat = "EEE, dd MMM yyyy HH:mm";

  const readAccessPriv = () => {
    if (readPriv === true) {
      dispatch(setGeneralValue({ expr: "DatagridReadPriv", value: true }));
    } else {
      dispatch(setGeneralValue({ expr: "DatagridReadPriv", value: false }));
      setModalState({
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  const booleanCols = [
    "ACTIVE",
    "ACTIVE?",
    "ORDER",
    "FIT?",
    "APRVD?",
    "LCKD?",
    "KEY IN?",
    "KEY OUT?",
    "SUBM",
    "SUBM?",
    "ACK.?",
    "ACKN?",
    "AUTH",
    "CAN?",
    "VEH?",
    "GEN. VEH?",
    "IN?",
    "OUT?",
    "DEL",
    "RECOM.?",
    "DRIV",
    "ASSIGNED?",
    "ASSND?",
    "ASSIGNED",
    "BUS?",
    "EQUIP?",
    "CARD?",
    "NON CARD?",
    "BOARD?",
    "BIKE?",
    "BUS?",
    "SPEC?",
    "STATUS",
    "STATUS?",
    "MANAGE?",
    "VIEW?",
    "AUTHORIZE?",
    "VRFD?",
    "REC.",
    "CANCEL REQ?",
    "SCHED ALERTS?",
    "OPEN JOB CARDS?",
    "MNG JOB DETAILS?",
    "MNG MTRLS?",
    "MNG WORK ORDERS?",
    "MNG INVOICES",
    "MNG UPDATES",
    "APRV RQSTS?",
    "SEE STATS?",
    "GET SMS?",
    "PM?",
    "RW?",
    "INS?",
    "PD?",
    "PAO?",
    "PA?",
    "PVC?",
    "P Dir?",
    "PERM.HOLD?",
    "STAFF?",
    "SMS SENT?",
  ];

  const identifierCols = [
    "REG. No",
    "REG NO",
    "VEH. REG NO",
    "VEH. REG No",
    "LOC",
    "LOC.",
    "LOCATION",
    "DEPARTMENT",
    "SECTION",
    "SEC",
    "DEPT",
    "UNIT",
    "MOB. No",
    "RESCHEDULE PLAN",
    "JOB TITLE",
    "RQST NO",
    "PERIOD",
  ];

  const numsCols = [
    "HORSE POW",
    "CUBIC CAP.",
    "LENGTH",
    "WIDTH",
    "HEIGHT",
    "MAX SPEEDO.",
    "MIN SPEEDO",
    "TANK CAPACITY",
    "TANK CAP.",
    "PRICE CURR",
    "CUR",
    "CURR",
    "STAFF NO",
    "Staff No",
    "BATCH No",
    "ID",
    "FREQ. (Days)",
    "DAYS DONE",
    "FREQ. (km)",
    "NO",
  ];

  useEffect(() => {
    readAccessPriv();
  }, [formCode]);

  // Datagrid Props
  const gridContent = useCallback((e: ContentReadyEvent<any, any>) => {
    const dataGridInstance = dataGridRef.current?.instance;
    !deselectFirstRow && dataGridInstance?.selectRowsByIndexes([0]);
    onContentReady && onContentReady(e);
  }, []);
  const deselectAllSelectedRecords = () => {
    const gridInstance = dataGridRef.current?.instance;
    if (gridInstance) {
      gridInstance.deselectAll(); // Refresh the grid
    }
  };
  const onRowpreparedContent = useCallback((e: any) => {
    onRowPrepared && onRowPrepared(e);
  }, []);

  const selectionChangeContent = (selected: any) => {
    !deselectFirstRow && onRowClick && onRowClick(selected.selectedRowsData[0]);
    selectionMode === "multiple" &&
      selectedItemsChange!(selected?.selectedRowsData);
  };

  const onRowClickContent = useCallback((e: any) => {
    selectedRowIndex && selectedRowIndex(e.rowIndex);
    onRowClick && onRowClick(e.data ?? {});
  }, []);

  const onRowDblClickContent = useCallback((e: any) => {
    rowDoubleClicked && rowDoubleClicked(e.data ?? []);
  }, []);

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Handle "Select All" checkbox click
  const handleSelectAllClick = (inputEvent: any, cellRenderEvent: any) => {
    const isChecked = inputEvent.target.checked;
    setSelectAllChecked(isChecked);

    // Manually trigger the select/deselect action for all rows in DataGrid
    const gridInstance = cellRenderEvent.component;

    if (isChecked) {
      gridInstance.selectAll();
    } else {
      gridInstance.deselectAll();
    }
  };
  const handleCellRender = (cellRenderEvnt: any) => (
    <div
      style={{
        paddingTop: "7.5%",
        paddingLeft: "14%",
        color: "black",
        display: "flex",
        gap: "5px",
      }}
    >
      <input
        type="checkbox"
        checked={selectAllChecked}
        onChange={(inptEvent) => {
          handleSelectAllClick(inptEvent, cellRenderEvnt);
        }}
      />
      <span>{customColumnCaption}</span>
    </div>
  );

  useMemo(()=>{
    deselectAllSelectedRecords();
  },[delectRecords])

  return (
    <>
      <ModalTemplate
        disableCancel={true}
        title={modalState.title}
        message={modalState.message}
        open={modalState.state}
        okHandler={() => {
          setModalState({ ...modalState, state: false });
        }}
      />
      <div className="w-full h-full max-h-full mt-1 max-w-none flex bottom-0  inset-0">
        {/* custom style for search section */}
        <style
          dangerouslySetInnerHTML={{
            __html: `
          
            .dx-datagrid-header-panel .dx-toolbar {
                margin-bottom: 1.5px;
            }`,
          }}
        />

        <DataGrid
          className={className}
          keyExpr={dataId || "id"}
          style={style}
          ref={dataGridRef}
          dataSource={rxReadAaccessPriv ? data : []}
          showBorders={true}
          columnWidth={noColumn ? noColumn : 30}
          onRowUpdated={onRowUpdated}
          repaintChangesOnly={true}
          onCellPrepared={cellColoringFxn}
          rowAlternationEnabled={rowAlternationEnabled}
          focusedRowEnabled={true}
          wordWrapEnabled={wordWrap}
          hoverStateEnabled={true}
          activeStateEnabled={true}
          onToolbarPreparing={onToolbarPreparing}
          onFocusedRowChanged={(e) => {}}
          onContentReady={gridContent}
          showRowLines={showBorders}
          showColumnLines={showBorders}
          showColumnHeaders={showColumnHeaders}
          onRowPrepared={onRowpreparedContent}
          onSelectionChanged={selectionChangeContent}
          allowColumnResizing={true}
          columnResizingMode="widget"
          height={gridheight}
          width={gridwidth ?? "100%"}
          onRowClick={onRowClickContent}
          onRowDblClick={onRowDblClickContent}
          onCellClick={onCellClicked}
          onInitialized={onDatagridInitialized}
          dataRowRender={dataRowRender}
          onRowUpdating={onRowUpdating}
          columnAutoWidth={columnAutoWidth}
        >
          <HeaderFilter visible={true} allowSearch={true} />
          {!disableSearch && (
            <SearchPanel
              highlightSearchText={true}
              width={searchWidth ? searchWidth : 300}
              visible={true}
              searchVisibleColumnsOnly={true}
            />
          )}
          {!disableGroupPanel && <GroupPanel visible={true} />}
          <Selection
            mode={selectionMode}
            showCheckBoxesMode={showSelectionCheckBox}
            // deferred={selectionMode === "multiple" ? true : false}
          />
          <LoadPanel enabled={true} />
          {allowColumnEditing && (
            <Editing
              mode={"cell"}
              allowUpdating={true}
              allowAdding={isAddBtn}
            />
          )}
          {addCustomColumn && (
            <Column
              type="selection"
              width={80}
              headerCellRender={(cellRenderEvnt: any) => (
                <div
                  className="flex justify-center items-center"
                  style={{
                    paddingTop: "7.5%",
                    color: "black",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={(inptEvent) => {
                      handleSelectAllClick(inptEvent, cellRenderEvnt);
                    }}
                  />
                  <span>{customColumnCaption}</span>
                </div>
              )}
            />
          )}
          {secondExtraColumn && (
            <Column
              type="selection"
              width={80}
              headerCellRender={(cellRenderEvnt: any) => (
                <div
                  className="flex justify-center items-center"
                  style={{
                    paddingTop: "7.5%",
                    color: "black",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={(inptEvent) => {
                      handleSelectAllClick(inptEvent, cellRenderEvnt);
                    }}
                  />
                  <span>{customColumnCaption}</span>
                </div>
              )}
            />
          )}
          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}
          {columns.map(
            ({
              id,
              type,
              dataField,
              caption,
              allowEditing,
              dataType,
              width,
              fixed,
              visible,
              format,
              lookup,
              setCellValue,
              cellTemplate,
              showEditorAlways,
              calculateCellValue,
            }: datagridColumnTypes) => {
              return (
                <Column
                  key={id}
                  showEditorAlways={showEditorAlways}
                  cellTemplate={cellTemplate}
                  // caption === "PICTURE" && columnCellRender
                  cellRender={
                    caption === "PICTURE" //TODO: replace with prop to accept key value
                      ? ({ data, value }) => {
                          return (
                            columnCellRender && columnCellRender(data, value)
                          );
                        }
                      : undefined
                    // ({ data, value }) => {
                    //   return columnCellRender && columnCellRender(data, value);

                    // }
                  }
                  // headerCellRender={(e)=>{
                  //   type&&handleCellRender(e)
                  // }}
                  type={type}
                  setCellValue={setCellValue}
                  lookup={lookup}
                  calculateCellValue={calculateCellValue}
                  dataField={dataField}
                  caption={caption}
                  dataType={dataType}
                  width={
                    width === null || undefined
                      ? 150
                      : numsCols.some((cols) => caption?.includes(cols))
                      ? 75
                      : identifierCols.some((cols) => caption?.includes(cols))
                      ? 100
                      : booleanCols.some((cols) => caption?.includes(cols))
                      ? 80
                      : width
                  }
                  alignment={"left"}
                  fixed={fixed}
                  renderAsync={true} // loads simple columns first before complex columns
                  allowFiltering={caption === "No" ? false : true}
                  allowEditing={allowEditing}
                  visible={
                    visibleCol?.find((cols) => {
                      return cols.caption === caption && cols.action === true;
                    })
                      ? true
                      : visible
                  }
                  format={
                    format ??
                    (dataType === "datetime"
                      ? dateFormat
                      : dataType === "time"
                      ? dateFormat
                      : dataType)
                  }
                />
              );
            }
          )}

          <Scrolling
            useNative={false}
            scrollByContent={true}
            scrollByThumb={true}
            showScrollbar="always"
            mode={"infinite"}
            rowRenderingMode={"virtual"}
            columnRenderingMode={"virtual"}
            preloadEnabled={true}
          />

          {!disablePaging && (
            <Paging
              defaultPageSize={0}
              pageSize={pageSize}
              pageIndex={0}
              onPageSizeChange={handlePageSize}
              onPageIndexChange={handlePageNumber}
            />
          )}
          {!disablePaging && (
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"compact"}
              showInfo={true}
              showNavigationButtons={true}
              showPageSizeSelector={true}
            />
          )}
          <RemoteOperations
            filtering={true}
            paging={true}
            sorting={true}
            summary={true}
            grouping={true}
            groupPaging={true}
          />

          {summary}
        </DataGrid>
        {/* extra widgets */}

        {extraBtn && (
          <div>
            {resetbtn && (
              <span
                onClick={() => {
                  handleRefresh && handleRefresh();
                }}
                style={{
                  height: 22.5,
                  marginBottom: 2,
                  borderWidth: 1,
                  width: 24,
                  borderColor: borderTheme,
                }}
                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
              >
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={reset}
                  alt="reset"
                />
              </span>
            )}

            {settingsbtn && (
              <span
                onClick={() => {
                  settingsOnClick!();
                }}
                style={{
                  height: 22.5,
                  marginBottom: 2,
                  borderWidth: 1,
                  width: 24,
                  borderColor: borderTheme,
                }}
                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded bg-black"
              >
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={settings}
                  alt="reset"
                />
              </span>
            )}

            {extraWidget}
          </div>
        )}
      </div>
    </>
  );
};
export default React.memo(Datagrid_template);
