/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useResizeMode from '../../../../hooks/useResizeMode'
import Datagrid_template from "../../../../templates/Datagrid"
import { right_cols1 } from '../data/datagrid_cols'
import { InputsTemplate } from '../../../../templates/input'
import { SelectsTemplate } from '../../../../templates/select'
import { selectsTypes } from '../../../human-resource/setups/data/types/selectsTypes'
import { CustomizableButton, FindButton } from '../../../accessories/buttons'
import { Form } from 'antd'
import save from "../../../../assets/floppy-disk.png"
import useFetch from '../../../../hooks/useFetch'
import { GetAllForms, GetFormAccessPrivileges } from '../../../../interfaces'
import { GetDataFunc } from '../../../../functions/get'
import dxDataGrid, { CellClickEvent } from 'devextreme/ui/data_grid'
import entityMapper from '../../../../entity'
import { v4 as uuidv4 } from 'uuid'
import { PostDataFunc  } from '../../../../functions/post'
import { useModalData } from '../../../accessories/component_infos'
import { ModalTemplate } from '../../../../templates/modal'


interface PageProps {
    userID?: number
}

export const Apa_right1 = ({ userID }: PageProps) => {
    const [initial, final] = useResizeMode(window.innerHeight)
    const gridheight = initial - 265

    const [states, setStates] = useState<any>({
        id: uuidv4(),
        module: "none",
        type: "none",
        form: "none"
    })
    const [tempState, setTempState] = useState<any>({
        id: uuidv4(),
        module: "",
        type: "",
        form: ""
    })


    const updateStates = useCallback((key: string, value: any) => {
        setTempState((prev: any) => ({ ...prev, [key]: value }))
    }, [])

    // API Integration
    // const [formData, formsError, formLoading]: [GetAllForms[], any, any] = useFetch(`SysAdminFormAccessPrivileges/GetAllForms?CmbModule=${states.module}&CmbType=${states.type}&Search=${states.form}`)
    const [formData, formsError, formLoading]: [GetAllForms[], any, any] = useFetch(`SysAdminFormAccessPrivileges/GetAllForms?CmbModule=${states.module}&CmbType=${states.type}&Search=${states.form}`, !!userID)
    const [formModules, moduleError, isFormLoading] = useFetch(`SysAdminFormAccessPrivileges/GetFormModules`)
    const [formTypes, typeError, isFormTypeLoading] = useFetch(`SysAdminFormAccessPrivileges/GetFormTypes`)

    const [gridData, setGridData] = useState<GetFormAccessPrivileges[]>();
    const [modal, setModal] = useModalData();


    useEffect(() => {

        const fetchData = async () => {

            let data = await generateColumnsData(formData) as GetFormAccessPrivileges[]
            setGridData(data)

        }

        fetchData()

    }, [formData]);

    let dataGridRef = useRef<dxDataGrid>(null);


    const selects: selectsTypes[] = [
        { id: 0, label: "Module", idExpr: "apmShtName", dataExpr: "apmShtName", optionsData: formModules, stateName: "module", defaultValue: states.module, style: "" },
        { id: 1, label: "Type", idExpr: "afmType", dataExpr: "afmType", optionsData: formTypes, stateName: "type", defaultValue: states.type, style: "" },
    ]

    const mapper = new entityMapper();


    const generateColumnsData = async (forms: GetAllForms[]) => {

        return Promise.all(forms.map(async (form: GetAllForms) => {

            let getFormID = form.afmIDpk;

            let fetchFormData;
            let formWithUserPrivs = {} as GetFormAccessPrivileges;

            if (getFormID !== undefined) {

                fetchFormData = await GetDataFunc(`SysAdminFormAccessPrivileges/GetFormAccessPrivileges`,
                    {
                        FormID: getFormID,
                        UserID: userID
                    }
                )

                if ((fetchFormData?.data && fetchFormData?.data.length > 0)) {

                    let data = fetchFormData.data[0] as GetFormAccessPrivileges


                    formWithUserPrivs = {

                        ...form,
                        "apvInsert": data.apvInsert,
                        "apvUpdate": data.apvUpdate,
                        "apvRead": data.apvRead,
                        "apvPrint": data.apvPrint,
                        "apvcreationdate": data.apvcreationdate,

                    }


                } else if (fetchFormData.data && fetchFormData.data.length === 0) {

                    formWithUserPrivs = {

                        ...form,
                        "apvInsert": false,
                        "apvUpdate": false,
                        "apvRead": false,
                        "apvPrint": false,
                        "apvcreationdate": null,
                    }

                }

                return formWithUserPrivs
            }

            return {
                ...form,
                "apvInsert": false,
                "apvUpdate": false,
                "apvRead": false,
                "apvPrint": false,
                "apvcreationdate": null,
            }


        }))


    }

    const onCellClickedFunc = (e: CellClickEvent<GetFormAccessPrivileges>) => {



        if (e.columnIndex === 2) {


            let getUpdateState = e.data.apvUpdate
            let getReadState = e.data.apvRead


            if (getUpdateState === true || getReadState !== true) {
                e.component.cellValue(e.row.rowIndex, "apvRead", true)
            }

        }

        if (e.columnIndex === 3) {

            let getUpdateState = e.data.apvUpdate


            if (getUpdateState === true) {
                e.component.cellValue(e.row.rowIndex, "apvRead", true)
            }
        }
    }


    const saveAccessPriveleges = async () => {


        let data = dataGridRef.current.getDataSource().items()

        if (data.length !== 0) {

            const submittables = mapper.toCreateGenAccessPrivPOST(data, userID)

            for await (const chunk of submittables) {
                await PostDataFunc (`SysAdminFormAccessPrivileges/CreateGenAccessPrivileges`, chunk)

            }

        }


    }

    const awakeSaveModal_AccessPriv_POST = () => {
        setModal({
            state: true,
            title: "Save Access Priveleges",
            message: "Are you sure you want to update access priveleges for the selected User?",
            icon: "question",
            okText: "Yes",
            cancelText: "No",
            disableCancel: false,
            okHandler: () => {
                setModal(prev => ({
                    ...prev,
                    state: false,

                }))
                saveAccessPriveleges()
            },
            cancelHandler: () => {
                setModal(prev => ({
                    ...prev,
                    state: false,

                }))
            }
        })
    }
    const awakeActivateModal_AccessPriv_POST = () => {

        setModal({
            state: true,
            title: "Disable Acc?",
            message: `The account for ${undefined} is Enabled. Are you sure you want to Disable the account`,
            icon: "question",
            okText: "Yes",
            cancelText: "No",
            disableCancel: false,
            okHandler: () => {
                setModal(prev => ({
                    ...prev,
                    state: false,

                }))
                saveAccessPriveleges()
            },
            cancelHandler: () => {
                setModal(prev => ({
                    ...prev,
                    state: false,

                }))
            }
        })
    }

    return (
        <div className="w-full h-full">
            {/*  */}

            {/* Grid */}
            <ModalTemplate
                width={400}
                icon={modal.icon}
                disableCancel={modal.disableCancel}
                cancelText={modal.cancelText}
                open={modal.state}
                okHandler={modal.okHandler}
                cancelHandler={modal.cancelHandler}
                message={modal.message}
                okText={modal.okText}
                title={modal.title}
            />

            <section className="w-full  px-1">
                <Datagrid_template
                    ref={dataGridRef}
                    gridheight={gridheight}
                    columns={right_cols1}
                    data={gridData}
                    disablePaging
                    allowColumnEditing

                    onCellClicked={onCellClickedFunc}
                    onDatagridInitialized={(e) => {
                        dataGridRef.current = e.component
                    }}

                />
                {/* Forms */}
                <div className="w-full flex ">
                    <Form
                        className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 '
                        name="basic"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size='small'
                        layout='horizontal'
                        disabled={false}
                    >

                        <section className='w-full flex items-center '>
                            <div className='w-[45%] flex '>
                                {selects.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                    return (
                                        <SelectsTemplate
                                            key={id}
                                            useCallFunc={true}
                                            label={label}
                                            options={optionsData}
                                            disabled={false}

                                            // idexpr={idExpr}
                                            dataexp={dataExpr}
                                            selectedValue={(e: any) => {
                                                // console.log("selected value: ", e[dataExpr!])
                                                updateStates(stateName!, e[dataExpr!])
                                            }}

                                            span
                                            selectStyle={{ width: 150 }}
                                        />
                                    )
                                })}
                            </div>

                            <div className="w-[30%] flex items-center space-x-1">
                                <InputsTemplate
                                    useCallbackFunc
                                    label={"Form"}
                                    placeholder={'Search'}
                                    defaultValue={""}
                                    disabledStatus={false}
                                    // inputStyle={{ width: 240 }}
                                    orderOnchange={(e: string) => {
                                        updateStates("form"!, e)
                                    }}


                                />

                                <FindButton
                                    handleOnClick={async () => {
                                        setStates({
                                            id: uuidv4(),
                                            module: tempState?.module,
                                            type: tempState?.type,
                                            form: tempState?.form
                                        })

                                    }}
                                />
                            </div>


                            <div onClick={awakeSaveModal_AccessPriv_POST} className='w-[25%] flex justify-end'>
                                <CustomizableButton isText text={"Save Privilege"} classname="flex px-0.5 py-0.5" customizableBtnImage={save} customizableBtnImageClassname={"px-1"} />
                            </div>
                        </section>


                    </Form>

                </div>
            </section>

        </div>
    )
}
