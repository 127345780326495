export const TaskManagement_columns = [
    {
        id:0,
        caption : "ID",
        dataField: "tupDate",
        dataType: "string",
        width: 100,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    },
    {
        id:1,
        caption : "START DATE",
        dataField: "tupDate",
        dataType: "string",
        width: 100,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    },
    {
        id:2,
        caption : "DUE DATE",
        dataField: "tupDate",
        dataType: "string",
        width: 80,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    },
    {
        id:3,
        caption : "TASK",
        dataField: "tupDate",
        dataType: "string",
        width: 100,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    },
    
    {
        id:4,
        caption : "PROGRESS %",
        dataField: "tupDate",
        dataType: "string",
        width: 100,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    },
    {
        id:5,
        caption : "APPRAISED %",
        dataField: "tupDate",
        dataType: "string",
        width: 100,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:6,
        caption : "SCORING",
        dataField: "tupDate",
        dataType: "string",
        width: 80,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:7,
        caption : "ACTIV",
        dataField: "tupDate",
        dataType: "string",
        width: 80,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:8,
        caption : "STATUS",
        dataField: "tupDate",
        dataType: "string",
        width: 80,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:9,
        caption : "PRIORITY",
        dataField: "tupDate",
        dataType: "string",
        width: 80,
        alignment: 'left',
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:10,
        caption : "PROGRESS ACTIVITY",
        dataField: "tupDate",
        dataType: "string",
        alignment: 'left',
        width: 150,
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:11,
        caption : "ASSIGNED BY",
        dataField: "tupDate",
        dataType: "string",
        alignment: 'left',
        width: 150,
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, {
        id:12,
        caption : "ASSIGNED TO",
        dataField: "tupDate",
        dataType: "string",
        alignment: 'left',
        width: 150,
        fixed: false,
        visible: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    }, 
]
