import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../features/generalSlice";
import { GetDataFunc } from "../functions/get";
import { log } from "console";

const useFetch = (
  route: string,
  refresh?: string | number | boolean,
  sortKey?: string,
  
  sortKeyIsDate?: boolean,
  baseUrl?: "innox" | "powerapp",
  fromModal?: boolean,
  params?: any,
): [any, any, boolean] => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>("");
  const dispatch = useDispatch();

  useEffect(() => {
    !route ? setData([]) : fetchData();
  }, [route, refresh, fromModal]);

  // Function to generate a random ID
  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomText = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomText += characters[randomIndex];
    }

    return randomText;
  }

  const fetchData = async () => {
    dispatch(setGeneralValue({ expr: "formData", value: [] })); //clear form data from redux general store
    try {
      setLoading(true); // initially set loading state
      // get data
      const res = await GetDataFunc(`${route}`, params??'', baseUrl);
     

      if (route.includes('TroubleReports')) {
        if(route.includes('GetShareWith')){
          const newArray = res.data.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        } else if(route.includes('PopLueStatus')) {
          const newArray = res.data.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        }
        else if(route.includes('PopLueUpdIssueImpacts')) {
          console.log('gingdssddo',res.data.data )
          
          const newArray = res.data.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        }
        else if(route.includes('PopLueDoneBy')) {
           const newArray = res.data.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        }
        else if(route.includes('GetAllTroubleReport')) {
          const newArray = res.data.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        }
       
         else {
         
         
          const newArray = res?.data?.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        }
        // set the new array
      } else {
        //   // this creates a key value pair to all the objects in it just so i get a unique key across all datasets
        if (Array.isArray(res?.data?.data?.data)) {
          const newArray = res?.data?.data?.data?.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          // set the new array
          setData(newArray);
          return;
        } else if (!Array.isArray(res?.data)) {
          // if its an object of an array
          if (res.data.value) {
            let newArray = res?.data?.value.map((obj: any) => ({
              ...obj,
              id: generateRandomId(),
            }));
            setData(newArray);
          } else {
            if (res?.data?.data) {
              if (res?.data?.data?.colors) {
                let newArray = res?.data?.data?.colors;
                setData(newArray);
              } else {
                let newArray = res?.data?.data?.map((obj: any) => ({
                  ...obj,
                  id: generateRandomId(),
                }));
                setData(newArray);
              }
            } else {
              let newArray = res?.data?.map((obj: any) => ({
                ...obj,
                id: generateRandomId(),
              }));
              setData(newArray);
              // setData(res.data);
            }
          }
        } else {
          const newArray = res?.data?.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          setData(newArray);
        }
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false); //loading state to false
    }
  };

  return [data, error, loading];
};
export default useFetch;
