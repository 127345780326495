export const grid_cols = [
    { id: 1, caption: "JHA No", dataField: '', dataType: 'string', fixed: true, width: 80 },
    { id: 2, caption: "REQUEST DATE", dataField: '', dataType: 'string', fixed: true, width: 120 },
    { id: 3, caption: "REVIEW DATE", dataField: '', dataType: 'string', fixed: true, width: 120 },
    { id: 4, caption: "SIGN-OFF DATE", dataField: '', dataType: 'string', fixed: true, width: 120 },
    { id: 5, caption: "LOCATION", dataField: '', dataType: 'string', fixed: true, width: 120 },
    { id: 6, caption: "REVIEWED BY", dataField: '', dataType: 'string', fixed: true, width: 120 },
    { id: 7, caption: "ACTIVE?", dataField: '', dataType: 'string', fixed: true, width: 120 },
    { id: 8, caption: "SUP/GROUP LEADER", dataField: '', dataType: 'string', fixed: false, width: 200 },
    { id: 9, caption: "JOB DESCRIPTION", dataField: '', dataType: 'string', fixed: false, width: 200 },
    { id: 10, caption: "JOB DETAILS", dataField: '', dataType: 'string', fixed: false, width: 200 },
    { id: 11, caption: "INSTRUCTIONS FOR EMPS", dataField: '', dataType: 'string', fixed: false, width: 200 },
    { id: 12, caption: "CREW MEMBERS", dataField: '', dataType: 'string', fixed: false, width: 200 },
    { id: 13, caption: "REMARKS", dataField: '', dataType: 'string', fixed: false, width: 1000 },
];

export const job_grid =[
    { id: 1, caption: "Details", dataField: 'sstName', dataType: 'string', fixed: true, width: 80 },
    { id: 2, caption: "Hazard", dataField: 'twnName', dataType: 'string', fixed: true, width: 80 },
    { id: 2, caption: "Preventive Measures", dataField: 'twnName', dataType: 'string', fixed: true, width: 80 },
]


