import { useEffect, useRef, useState } from "react";

import DataGrid, {
  Column,
  Editing,
  GroupPanel,
  HeaderFilter,
  LoadPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { datagridColumnTypes } from "../components/human-resource/setups/data/types/selectsTypes";
import reset from "../assets/reset.png";
import settings from "../assets/setting.png";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useFormPriviledge } from "../hooks/useFormPriviledge";
import { setGeneralValue } from "../features/generalSlice";
import { useDispatch } from "react-redux";
import { ModalTemplate } from "./modal";

interface props {
  className?: any;
  gridheight: number;
  gridwidth?: number | string;
  columns: any;
  data: any;
  loading?: boolean;
  selected?: any;
  rowDoubleClicked?: (selectedField: any) => void;
  onRowClick?: (selectedField: any) => void;
  selectedRowIndex?: (index: number) => void;
  selectedItemsChange?: (selectedItems: any) => void;
  handleRefresh?: () => void; // handle refresh of the grids
  disableSearch?: boolean;
  disableGroupPanel?: boolean;
  style?: any;
  disablePaging?: boolean;
  selectionMode?: "single" | "multiple";
  showSelectionCheckBox?: "always";
  searchWidth?: number;
  allowColumnEditing?: boolean;
  format?: string;
  saveEditedCell?: (newData: any, value: any, currentRowData: any) => void;
  dataGridRef?: any;
  onRowPrepared?: (e: any) => void;
  onToolbarPreparing?: (e: any) => void;
  onContentReady?: (e: any) => void;
  onRowUpdated?: (e: any) => void;
  deselectFirstRow?: boolean;
  resetbtn?: boolean;
  settingsbtn?: boolean;
  extraBtn?: boolean;
  extraWidget?: any;
  settingsOnClick?: () => void;
  noColumn?: number;
  cellColoringFxn?: (e: any) => void;
  summary?: any;
  isAddBtn?: boolean;
  keyExpr?:string;
}

export const Dropdown_Datagrid_template = ({
  onRowClick,
  gridheight,
  gridwidth,
  style,
  searchWidth,
  selectionMode = "single",
  showSelectionCheckBox = "always",
  disableGroupPanel,
  disablePaging,
  disableSearch,
  columns,
  data,
  selectedRowIndex,
  allowColumnEditing,
  rowDoubleClicked,
  onRowPrepared,
  onToolbarPreparing,
  selectedItemsChange,
  onContentReady,
  dataGridRef: gridRef,
  deselectFirstRow,
  onRowUpdated,
  resetbtn = false,
  settingsbtn = false,
  extraWidget,
  extraBtn = false,
  className,
  settingsOnClick,
  noColumn,
  cellColoringFxn,
  summary,
  handleRefresh,
  isAddBtn = false,
  keyExpr
}: props) => {
  const dataGridRef = useRef<any>();

  const dispatch = useDispatch();
  const [savePriv, readPriv, updatePriv] = useFormPriviledge("read");

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const rxReadAaccessPriv: boolean = useSelector(
    (state: RootState) => state.general.DatagridReadPriv
  );
  const formCode: string = useSelector(
    (state: RootState) => state.general.formCode
  );
  const [modalState, setModalState] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({ state: false, title: "", message: "" });

  const displayModes: any[] = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];


  const allowedPageSizes: any[] = ["all", 15, 50, 125, 200, 500, 1000];

  const dateFormat = "EEE, dd MMM yyyy HH:mm";
  // read Privilege checker
  const readAccessPriv = () => {
    if (readPriv === true) {
      dispatch(setGeneralValue({ expr: "DatagridReadPriv", value: true }));
    } else {
      dispatch(setGeneralValue({ expr: "DatagridReadPriv", value: false }));
      setModalState({
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const booleanCols = [
    "ACTIVE",
    "ACTIVE?",
    "ORDER",
    "FIT?",
    "APRVD?",
    "LCKD?",
    "KEY IN?",
    "KEY OUT?",
    "SUBM",
    "SUBM?",
    "ACK.?",
    "ACKN?",
    "AUTH",
    "CAN?",
    "VEH?",
    "GEN. VEH?",
    "IN?",
    "OUT?",
    "DEL",
    "RECOM.?",
    "DRIV",
    "ASSIGNED?",
    "ASSND?",
    "ASSIGNED",
    "BUS?",
    "EQUIP?",
    "CARD?",
    "NON CARD?",
    "BOARD?",
    "BIKE?",
    "BUS?",
    "SPEC?",
    "STATUS",
    "STATUS?",
    "MANAGE?",
    "VIEW?",
    "AUTHORIZE?",
    "VRFD?",
    "REC.",
    "CANCEL REQ?",
    "SCHED ALERTS?",
    "OPEN JOB CARDS?",
    "MNG JOB DETAILS?",
    "MNG MTRLS?",
    "MNG WORK ORDERS?",
    "MNG INVOICES",
    "MNG UPDATES",
    "APRV RQSTS?",
    "SEE STATS?",
    "GET SMS?",
    "PM?",
    "RW?",
    "INS?",
    "PD?",
    "PAO?",
    "PA?",
    "PVC?",
    "P Dir?",
    "PERM.HOLD?",
    "STAFF?",
    "SMS SENT?",
  ];

  const identifierCols = [
    "REG. No",
    "REG NO",
    "VEH. REG NO",
    "VEH. REG No",
    "LOC",
    "LOC.",
    "LOCATION",
    "DEPARTMENT",
    "SECTION",
    "SEC",
    "DEPT",
    "UNIT",
    "MOB. No",
    "RESCHEDULE PLAN",
    "JOB TITLE",
    "RQST NO",
    "PERIOD",
  ];

  const numsCols = [
    "HORSE POW",
    "CUBIC CAP.",
    "LENGTH",
    "WIDTH",
    "HEIGHT",
    "MAX SPEEDO.",
    "MIN SPEEDO",
    "TANK CAPACITY",
    "TANK CAP.",
    "PRICE CURR",
    "CUR",
    "CURR",
    "STAFF NO",
    "Staff No",
    "BATCH No",
    "ID",
    "FREQ. (Days)",
    "DAYS DONE",
    "FREQ. (km)",
    "NO",
  ];

  useEffect(() => {
    readAccessPriv();
  }, [formCode]);
const key =  keyExpr ?? 'id'
  return (
    <>
      <ModalTemplate
        disableCancel={true}
        title={modalState.title}
        message={modalState.message}
        open={modalState.state}
        okHandler={() => {
          setModalState({ ...modalState, state: false });
        }}
      />
      <div className="w-full h-full max-h-full mt-1 max-w-none flex bottom-0  inset-0">
        {/* custom style for search section */}
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .dx-datagrid-header-panel .dx-toolbar {
                margin-bottom: 1.5px;
            }`,
          }}
        />
        <DataGrid
          className={className}
          keyExpr={key}
          style={style}
          ref={dataGridRef}
          dataSource={rxReadAaccessPriv ? data : []}
          showBorders={true}
          columnWidth={noColumn ? noColumn : 30}
          onRowUpdated={onRowUpdated}
          repaintChangesOnly={true}
          onCellPrepared={cellColoringFxn}
          rowAlternationEnabled={true}
          focusedRowEnabled={true}
          hoverStateEnabled={true}
          activeStateEnabled={true}
          onToolbarPreparing={onToolbarPreparing}
          onFocusedRowChanged={(e) => { }}
          onContentReady={(e: any) => {
            onContentReady && onContentReady(e);
          }}
          showRowLines={true}
          showColumnLines={true}
          onRowPrepared={(e) => {
            onRowPrepared && onRowPrepared(e);
          }}
          onSelectionChanged={(selected: any) => {
            !deselectFirstRow &&
              onRowClick &&
              onRowClick(selected.selectedRowsData[0]);
            selectionMode === "multiple" &&
              selectedItemsChange!(selected?.selectedRowsData);
          }}
          allowColumnResizing={true}
          columnResizingMode="widget"
          height={gridheight}
          width={gridwidth ?? "100%"}
          onRowClick={(e) => {
            selectedRowIndex && selectedRowIndex(e.rowIndex);

            onRowClick && onRowClick(e.data ?? {});
          }}
          onRowDblClick={(e) =>
            rowDoubleClicked && rowDoubleClicked(e.data ?? [])
          }
        >
          <HeaderFilter visible={true} allowSearch={true} />
          {!disableSearch && (
            <SearchPanel
              width={searchWidth ? searchWidth : 300}
              visible={true}
            />
          )}
          {!disableGroupPanel && <GroupPanel visible={true} />}
          <Selection
            mode={selectionMode}
            showCheckBoxesMode={showSelectionCheckBox}
          />
          <LoadPanel enabled={true} />
          {allowColumnEditing && (
            <Editing
              mode={"cell"}
              allowUpdating={true}
              allowAdding={isAddBtn}
            />
          )}

          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}
          {columns.map(
            ({
              id,
              dataField,
              caption,
              allowEditing,
              dataType,
              width,
              fixed,
              visible,
              format,
            }: datagridColumnTypes) => {
              return (
                <Column
                  key={id}
                  dataField={dataField}
                  caption={caption}
                  dataType={dataType}
                  width={
                    width === null
                      ? 150
                      : numsCols.some((cols) => caption.includes(cols))
                        ? 75
                        : identifierCols.some((cols) => caption.includes(cols))
                          ? 100
                          : booleanCols.some((cols) => caption.includes(cols))
                            ? 80
                            : width
                  }
                  alignment={"left"}
                  fixed={fixed}
                  allowFiltering={caption === "No" ? false : true}
                  allowEditing={allowEditing}
                  visible={visible}
                  format={
                    format ??
                    (dataType === "datetime"
                      ? dateFormat
                      : dataType === "time"
                        ? dateFormat
                        : dataType)
                  }
                />
              );
            }
          )}
          <Scrolling
            useNative={false}
            scrollByContent={true}
            scrollByThumb={true}
            showScrollbar="always"
          />

          <Paging defaultPageSize={0} />
          {!disablePaging && (
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"compact"}
              showInfo={true}
              showNavigationButtons={true}
              showPageSizeSelector={true}
            />
          )}
          {summary}
        </DataGrid>
        {/* extra widgets */}

        {extraBtn && (
          <div>
            {resetbtn && (
              <span
                onClick={() => {
                  handleRefresh && handleRefresh();
                }}
                style={{
                  height: 22.5,
                  marginBottom: 2,
                  borderWidth: 1,
                  width: 24,
                  borderColor: borderTheme,
                }}
                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
              >
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={reset}
                  alt="reset"
                />
              </span>
            )}

            {settingsbtn && (
              <span
                onClick={() => {
                  settingsOnClick!();
                }}
                style={{
                  height: 22.5,
                  marginBottom: 2,
                  borderWidth: 1,
                  width: 24,
                  borderColor: borderTheme,
                }}
                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded bg-black"
              >
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={settings}
                  alt="reset"
                />
              </span>
            )}

            {extraWidget}
          </div>
        )}
      </div>
    </>
  );
};
