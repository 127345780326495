/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import settings from "../../../../assets/refresh.png";
import { SaveButton } from "../../../accessories/buttons";
import { DateTemplate } from "../../../../templates/date";
import { TextareaTemplate } from "../../../../templates/textarea";
import {
  currentDate,
  saveModalData,
} from "../../../accessories/component_infos";
import dayjs, { Dayjs } from "dayjs";
import { useCrudFunc } from "../../../../functions/crud";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { ModalTemplate } from "../../../../templates/modal";
import { RootState } from "../../../../app/store";
import { useAxiosService } from "../../../../general/requestConfig";
import React from "react";
import { setSearchParamOnChange } from "../../../../features/apiCalls/elogbook";

interface props {
  open: boolean;
  handleCancel: () => void;
  resetKey?: () => void;
}
interface stateTypes {
  date: any;
  takeoverShift: boolean;
  summary: string;
}

export const Takeover_Shift_Members_Modal = ({ open, handleCancel, resetKey }: props) => {
  const [states, setStates] = useState<stateTypes>({
    date: dayjs(currentDate).format("ddd,DD MMM YYYY 23:59:00"),
    takeoverShift: false,
    summary: "",
  });
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const homeStationx = useSelector(
    (state: RootState) => state.general.homeStation
  );
  const tState =
    useSelector((state: any) => state.elogBook) ?? {};
  const summaryRef = useRef<any>(null);

  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  //hooks
  const Posting = useCrudFunc("powerapp")[0];
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [refresh, setRefresh] = useState<boolean>(false);
  const rxCssBorderClass = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  //endPoints

  const [takeOver_operators, setTakeOver_operators] = useState([]);
  const query = useAxiosService("powerapp");
  useEffect(() => {
    query({
      method: "GET",
      url: `ShiftTakeOver/operators?stationId=${homeStationx}`,
    }).then((res) => {
      setTakeOver_operators(typeof (res) === "string" ? [] : res);
    }).catch((e) => {
      setTakeOver_operators([]);
    });
  }, [homeStationx, refresh]);

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  //selected members
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const SelectMember = useCallback(
    (name: string, value: boolean) => {
      value
        ? setSelectedMembers((record) => [...record, name])
        : setSelectedMembers((record) =>
          record.filter((item) => item !== name)
        );
    },
    [selectedMembers]
  );
  //post endpoint 
  const shift_takeover = async () => {
    try {
      setBusyLoader("Taking Over Shift....");
      const membersString = selectedMembers.join(", ");
      await Posting(
        `ShiftTakeOver/shift_takeover`,
        {
          lbkLogDate: dayjs(states.date).format("YYYY-MM-DDTHH:mm:ss"),
          lbkHomeStationIDfk: homeStationx,
          membersOnDuty: membersString,
          lbkEvent: states.summary,
        },
      );
      setModalData({
        title: "Success",
        message: "Shift Taken Over Successfully",
      });
      setOpenModal(true);
      setIcon("success");
      setBusyLoader("");
      setStates({
        date: dayjs(currentDate).format("ddd,DD MMM YYYY 23:59:00"),
        takeoverShift: false,
        summary: "",
      });
      dispatch(setSearchParamOnChange({ key: "refresh", value: { ...tState, refresh: !tState.refresh, } }));
    } catch (e: any) {
      setModalData({
        title: "Error",
        message: e?.response?.data?.message ?? e?.response?.data?.errors[0],
      });
      setIcon("warning");
      setOpenModal(true);
      setBusyLoader("");
      setSelectedMembers([]);
    }
  };

  //validate fields
  const validate = () => {
    if (states.date === "" || states.date === undefined) {
      setModalData({
        title: "Required",
        message: "Please select a date",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (states.summary === "" || states.summary === undefined) {
      setModalData({
        title: "Required",
        message: "Please enter your Shift Take Over summary",
      });
      setIcon("warning");
      setOpenModal(true);
      if (summaryRef?.current) {
        summaryRef.current.focus();
      }
    } else {
      setModalData({
        title: "Take Over Shift",
        message: "This action will log the current shift member(s) out And allow you To take over the shift.\n\nNote that the current shift holder will be alerted of this activity.\n\n Are you sure you want To proceed And Take Over the Shift?",
        okText: "Yes",
      });
      setIcon("question");
      setOpenModal(true);
    }
  };

  const handleTakeover = async () => {
    if (dayjs(states?.date).isAfter(dayjs())) {
      setModalData({
        title: "Invalid Date",
        message: "The Date and Time you entered is greater than the current date and time . Please check your date and try again.",
        okayText: "Okay",
      });
      setIcon("warning");
      setOpenModal(true);
      return;
    }
    if ((await validateLastLogDate()) === true) {
      setModalData({
        title: "Invalid Date",
        message: "The date you entered is earlier than the last log captured for the station. Please check your date and try again.",
        okText: "Yes",
      });
      setIcon("warning");
      setOpenModal(true);
      return;
    }
    validate();
  };

  const validateLastLogDate = async (): Promise<boolean> => {
    try {
      return await query({
        method: "GET",
        url: `ShiftTakeOver/validateLastLogTime?lbkHomeStationIDfk=${homeStationx}&logDate=${dayjs(states.date).format("YYYY-MM-DDTHH:mm:ss")}`,
      }).then((res) => {
        console.log(res.data);
        return res.data;
      });
    } catch (e: any) {
      return true;
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        width={700}
        footer=""
        centered
        style={{
          border: "1px solid rgb(59 130 246)",
          borderRadius: 9,
          paddingBottom: 0,
        }}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            eLog Book - Shift Takeover
          </div>
        }
        open={open}
        onOk={() => { }}
        transitionName=""
        onCancel={() => {
          handleCancel();
          setStates({
            date: dayjs(currentDate).format("ddd,DD MMM YYYY 23:59:00"),
            takeoverShift: false,
            summary: "",
          });
          setSelectedMembers([]);
        }}
      >
        <ModalTemplate
          width={modalData.title === "Take Over Shift" ? 460 : undefined}
          icon={icon}
          cancelText={modalData.cancelText}
          cancelHandler={() => {
            setOpenModal(false);
            setSelectedMembers([]);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === "Take Over Shift"
              ? shift_takeover()
              : setOpenModal(false);
            setSelectedMembers([]);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
          disableCancel={icon === "question" ? false : true}
        />

        <>
          <main className="">
            {/* right form */}
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 19 }}
              className=" "
              size="small"
            >
              <div className=" flex justify-between">
                <div className=" ml-[4px] mb-0.5">
                  <DateTemplate
                    disabled={false}
                    showTime
                    selectedDate={dayjs(states.date)}
                    changeDate={(date: Dayjs) => {
                      setState("date", dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
                    }}
                    labelCol={10}
                    width={300}
                    label="Date & Time"
                    showTimeWithSeconds={true}
                  />
                </div>

                <span
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 24,
                  }}
                  className="ml-3 flex justify-center hover:cursor-pointer rounded "
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center rounded-lg justify-center"
                    src={settings}
                    alt="reset"
                  />
                </span>
              </div>

              <Form.Item
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"TakeOver Shift Members"}
                  </p>
                }
                className=" "
              >
                <div style={{ borderColor: rxCssBorderClass }} className="overflow-y-scroll h-[180px] px-2 border border-slate-300">
                  <div className="  py-2 rounded-b-md ">
                    {takeOver_operators && takeOver_operators?.map((operator, index) => (
                      <div className="flex mb-1.5" key={index}>
                        <span className="mr-2">
                          <CheckboxTemlate
                            useCallbackFunc
                            customDisabled={false}
                            // defaultValue={}
                            setCheckboxOnchange={(e: any) => {
                              SelectMember(operator, e);
                            }}
                            withBorders
                          />
                        </span>
                        <span>
                          <p>{operator}</p>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Item>

              <div className=" ml-[15px] mt-[5.5px]">
                <TextareaTemplate
                  ref={summaryRef}
                  disabled={false}
                  label={"Takeover Event/Summary"}
                  useCallbackFunc
                  defaultValue={states.summary}
                  setCallbackValue={(e: string) => {
                    setState("summary", e);
                  }}
                  height={200}
                />
              </div>

              <div className=" mr-[-4px] flex flex-row justify-end">
                <SaveButton
                  title={"Take Over"}
                  disableButton={false}
                  disabled_status={false}
                  handleSave={async () => {
                    await handleTakeover();
                  }}
                />
              </div>
            </Form>
            <div className="text-blue-600">
              Note that the name of the Operator who is logged in currently will
              not show up here,<br></br>
              but will be captured as the one taking over the shift together
              with those selected.
            </div>
          </main>
        </>
      </Modal>
    </>
  );
};
