import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../../features/generalSlice";
import { useCrudFunc } from "../../../../../../../../functions/crud";
import { useAccountInfo } from "../../../../../../../../hooks/useAccountInfo";
import useFetch from "../../../../../../../../hooks/useFetch";
import { BottomBtns } from "../../../../../../../../templates/butttons";
import { InputsTemplate } from "../../../../../../../../templates/input";
import InputSelect_template from "../../../../../../../../templates/InputSelect";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../../../templates/textarea";
import { saveModalData, updateModalData } from "../../../../../../../accessories/component_infos";
import { inputsTypes } from "../../../../../../../human-resource/setups/data/types/selectsTypes";
import SetupsDatalistTemplat from "../../../../../../../human-resource/setups/data/setups_datalist_template";

interface stateTypes {
  maintenanceLocation: { id: number; name: string };
  selectedArea?: { id: number; name: string };
  selectedLocation?: { id: number; name: string };
  selectedTown?: { id: number; name: string };
  shortname: { id: number; name: string };
  contact: string;
  intercom: string;
  email: string;
  order: number;
  active: boolean;
  modalState?: any;
  confirmState?: any;
  successModal?: any;
  refresh: boolean;
  remarks?: string;
  selectedItemId: number;
}
interface props {
  selectedData: any;
  refreshGrid: () => void;
}

export const TRPLocationsForm = ({
  selectedData,
  refreshGrid,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const dispatch = useDispatch();

  const [states, setStates] = useState<stateTypes>({
    maintenanceLocation: { id: 0, name: "" },
    selectedArea: { id: 0, name: "" },
    selectedLocation: { id: 0, name: "" },
    selectedTown: { id: 0, name: "" },
    shortname: { id: 0, name: "" },
    contact: "",
    intercom: "",
    email: "",
    order: 0,
    active: false,

    refresh: false,
    remarks: "",
    selectedItemId: 0,
  });

  const updateState = (stateName: string, value: any) => {
    setStates((currentState: any) => ({ ...currentState, [stateName]: value }));
  };

  const inputs: inputsTypes[] = [
    // {
    //   id: 0,
    //   label: "Contact Persons",
    //   stateName: "contact",
    //   defaultValue: states.contact,
    // },
    // {
    //   id: 1,
    //   label: "Mob/Tel NOs/INTERCOM",
    //   stateName: "intercom",
    //   defaultValue: states.intercom,
    // },
    // { id: 2, label: "Email", stateName: "email", defaultValue: states.email },
    { id: 3, label: "Order", stateName: "order", defaultValue: states.order },
  ];

  // Dropdown data

  const [locations, locations_err, locations_loading] = useFetch(
    `AsmTmsStpMaintenanceLocation/GetMaintenanceLocations`
  );
  const [main_locations, main_locations_err, main_locations_loading] = useFetch(
    `TroubleLocations/GetAllTroubleLocationNames`, true,
    "tlcIDpk", false, "powerapp"
  );
  const [area, area_err, area_loading] = useFetch(
    "AsmTmsStpMaintenanceLocation/GetAreas"
  );
  const [towns, towns_err, towns_loading] = useFetch(
    "AsmTmsStpMaintenanceLocation/GetTowns"
  );
  const [area_locations, area_locations_loading, area_locations_error] =
    useFetch(
      `AsmTmsStpMaintenanceLocation/GetLocations?area=${states.selectedArea?.name}`
    );

  const [posting, updating] = useCrudFunc("powerapp");
  const [empId, userId, userObj] = useAccountInfo();

  const populateFields = () => {
    const gridState = [
      { key: "selectedItemId", value: selectedData.tlcIDpk },
      { key: "maintenanceLocation", value: {id:0, name:selectedData.tlcName} },
      { key: "shortname", value: {id:0, name: selectedData.tlcShtName} },
      { key: "contact", value: selectedData.mlcMainContacts },
      { key: "remarks", value: selectedData.tlcRmks },
      { key: "intercom", value: selectedData.mlcTelNos },
      { key: "email", value: selectedData.mlcEmails },
      { key: "order", value: selectedData.tlcOrder },
      { key: "active", value: selectedData.tlcActive },
      {
        key: "selectedArea",
        value: {
          id: selectedData.areIDpk,
          name: selectedData.areName,
        },
      },
      {
        key: "selectedLocation",
        value: {
          id: selectedData.locIDpk,
          name: selectedData.locName,
        },
      },
      {
        key: "selectedTown",
        value: {
          id: selectedData.twnIDpk,
          name: selectedData.twnName,
        },
      },
    ];

    for (let i of gridState) updateState(i.key, i.value);
    // updateState("selectedItemId", selectedData.mlcIdpk);
    // updateState("maintenanceLocation", selectedData.mlcName);
    // updateState("shortname", selectedData.mlcShtName);
    // updateState("contact", selectedData.mlcMainContacts);
    // updateState("remarks", selectedData.mlcRmks);
    // updateState("intercom", selectedData.mlcTelNos);
    // updateState("email", selectedData.mlcEmails);
    // updateState("order", selectedData.mlcOrder);
    // updateState("active", selectedData.mlcActive);

    // updateState("selectedArea", {
    //   id: selectedData.areIDpk,
    //   name: selectedData.areName,
    // });

    // updateState("selectedLocation", {
    //   id: selectedData.locIDpk,
    //   name: selectedData.locName,
    // });

    // updateState("selectedTown", {
    //   id: selectedData.twnIDpk,
    //   name: selectedData.twnName,
    // });
  };

  useEffect(() => {
    populateFields();
  }, [selectedData]);

  const showWarningDialog = async (message: string) => {
    updateState("modalState", {
      state: true,
      message: message,
      title: "Required field",
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);

  const validationForms = () => {
    setIcon("warning");
    if (states.maintenanceLocation.name === "") {
      setModalData({
        message: "Please enter Maint. Location, validation failed",
        title: " Field Required! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.shortname.name === "") {
      // setshowModalDisableButton(true);
      setModalData({
        message: "Please enter shortname, validation failed",
        title: " Field Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setOpenModal(true);
    }
  };

  const resetFields = () => {
    const gridState = [
      { key: "maintenanceLocation", value: { id: 0, name: "" }},
      { key: "shortname", value: {id:0, name:""} },
      // { key: "selectedArea", value: { id: 0, name: "" } },
      // { key: "selectedLocation", value: { id: 0, name: "" } },
      // { key: "selectedTown", value: { id: 0, name: "" } },
      // { key: "contact", value: "" },
      // { key: "remarks", value: "" },
      // { key: "intercom", value: "" },
      // { key: "email", value: "" },
      { key: "order", value: 0 },
      { key: "active", value: true },
    ];

    for (let i of gridState) updateState(i.key, i.value);
    // updateState("maintenanceLocation", "");
    // updateState("shortname", "");
    // updateState("selectedArea", { id: 0, name: "" });
    // updateState("selectedLocation", { id: 0, name: "" });
    // updateState("selectedTown", { id: 0, name: "" });
    // updateState("contact", "");
    // updateState("remarks", "");
    // updateState("intercom", "");
    // updateState("email", "");
    // updateState("order", 0);
    // updateState("active", true);
  };

  const save = async () => {

    try {
    

    await posting(
      `TroubleLocations/CreateTroubleLocation`,
      {
        tlcName: states.maintenanceLocation.name,
        tlcShtName: states.shortname.name,
       
        mlcActive: states.active,
        mlcOrder: states.order,
        mlcRmks: states.remarks,
        formCode: "string",
        formAction: "string",
      },
      `Created Truoble Location Details successfully with trouble location - ${states.maintenanceLocation.name}, shortname - ${states.shortname.name}, town - ${states.selectedTown?.name}, contact persons - ${states.contact}, intercom - ${states.intercom}, email - ${states.email}, order - ${states.order}, remarks - ${states.remarks}`
    )
      .then((res) => {
        resetFields();
        refreshGrid();
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(true);
        setModalData({
          message: "Data entry saved successfully!",
          title: "Data Saved!",
          okText: "Ok",
        });
      })
      .catch((err) => {
        if (err.response) {
          // Check for validation error
          if (err.response.status === 400) {
            showWarningDialog(err.response.data.errors[0]);
          } else {
            setIcon("warning");
            setModalData({
              message: "Please enter Mn failed",
              title: " Field Required! ",
              okText: "Ok",
            });
           
            showWarningDialog(
              "Unexpected error occured. Please contact your system administrator"
            );
          }
        } else {
          setIcon("warning");
          setModalData({
            message: "Please enter Mo failed",
            title: " Field Required! ",
            okText: "Ok",
          });
          showWarningDialog(
            "Unexpected error occured. Please contact your system administrator"
          );
        }
      });
        
    } catch (error) {
      setIcon("warning");
      setModalData({
        message: "Please  Mn failed",
        title: " Field Required! ",
        okText: "Ok",
      });
    }
  };

  const update = async () => {
    await updating(
      `TroubleLocations/UpdateTroubleLocation/${states.selectedItemId}`,
      {
        tlcIdpk: states.selectedItemId,
        tlcName: states.maintenanceLocation.name,
        tlcShtName: states.shortname.name,
       
        tlcOrder: states.order,
       tlcActive: states.active,
        tlcRmks: states.remarks,
        formCode: "string",
        formAction: "string",
      },
      `Updated Trouble Location Details successfully with trouble location - ${states.maintenanceLocation.name}, shortname - ${states.shortname.name}, town - ${states.selectedTown?.name}, contact persons - ${states.contact}, intercom - ${states.intercom}, email - ${states.email}, order - ${states.order}, remarks - ${states.remarks}`
    )
      .then((res) => {
        resetFields();
        refreshGrid();
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Data entry updated successfully!",
          title: "Data Updated!",
          okText: "Ok",
        });
      })
      .catch((err) => {
        if (err.response) {
          // Check for validation error
          if (err.response.status === 400) {
            showWarningDialog(err.response.data.errors[0]);
          } else {
            showWarningDialog(
              "Unexpected error occured. Please contact your system administrator"
            );
          }
        } else {
          showWarningDialog(
            "Unexpected error occured. Please contact your system administrator"
          );
        }
      });
  };

  return (
    <>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? save()
            : modalData === updateModalData
            ? update()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {
        <div className="px-2">
          {/* FORM HEADER */}
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="form_head pt-1 border-[1px] border-b-0 px-2 w-full bg-slate-100 font-semibold"
          >
            {"Location Details"}
          </p>
          <Form
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] border-slate-100 rounded flex justify-between py-1 px-2 "
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 19 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
            <section className="" style={{ width: "550px" }}>
              <div className="mb-1">
              <SetupsDatalistTemplat
                    selectedData={states.maintenanceLocation?.name}
                    listid={"Name"}
                    nameExpr={"tlcName"}
                    label={"Name"}
                    refresh={true}
                    datalistStyle={{ height: 24 }}
                    span={true}
                    options={main_locations}
                    useCallbackFunc
                    setCallbackValue={(value) => {
                      updateState("maintenanceLocation", {id: 0, name:value});
                      updateState("shortname", {id: 0, name:value});
                    }}
                  />
                
              </div>

              <div className=" mb-1">
              <SetupsDatalistTemplat
                    selectedData={states.shortname?.name}
                    listid={"Short Name"}
                    nameExpr={"tlcShtName"}
                    label={"shortname"}
                    refresh={true}
                    datalistStyle={{ height: 24 }}
                    span={true}
                    options={main_locations}
                    useCallbackFunc
                    setCallbackValue={(value) => {
                      updateState("shortname", {id: 0, name:value});
                    }}
                  />
              
              </div>

             

              {inputs.map(({ id, label, stateName, defaultValue }) => (
                <div className=" mb-1">
                  <InputsTemplate
                    inputStyle={{ height: 24, width: "100%" }}
                    span
                    key={id}
                    numberOnly={label === "Order" && true}
                    labelColPan={7}
                    useCallbackFunc
                    defaultValue={defaultValue}
                    label={label}
                    orderOnchange={(e) => {
                      updateState(stateName!, e);
                    }}
                  />
                </div>
              ))}

              <div className="">
                <BottomBtns
                  addActiveBorders={true}
                  checkboxChange={(e) => {
                    updateState("active", e);
                  }}
                  defaultValue={states.active}
                  onNewClick={() => {
                    resetFields();
                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: false })
                    );
                  }}
                  useCallbackFunc={true}
                  saveHandler={() => {
                    setModalData(saveModalData);
                    setOpenModal(true);
                    validationForms();
                  }}
                  updateHandler={() => {
                    setModalData(updateModalData);
                    setOpenModal(true);
                    validationForms();
                  }}
                  span
                />
              </div>
            </section>

            <section className=" " style={{ width: "calc(100% - 550px)" }}>
              <TextareaTemplate
                useCallbackFunc
                defaultValue={states.remarks}
                setCallbackValue={(value: string) => {
                  updateState("remarks", value);
                }}
                MarginLeft={40}
                height={120}
                label={"Remarks"}
              />
            </section>
          </Form>
        </div>
      }
    </>
  );
};
