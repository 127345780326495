import { Form, Input } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import dropdown from '../../../../assets/dropdown.png'
import reset from '../../../../assets/reset.png'
import { setValue } from "../../../../features/forms/tier1/createSlice";
import { setTier2Value } from "../../../../features/forms/tier2/tier2Slice";
import { setTier2CheckboxValue } from "../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setTier3Value } from "../../../../features/forms/tier3/tier3Slice";
import { setTier4Value } from "../../../../features/forms/tier4/tier4Slice";
import { useReduxValues } from "../../../../hooks/useReduxValues";
import React from "react";
interface props {
    datalistStyle?: any
    index?: number
    nameExpr?: any;
    idExpr?: any;
    options?: any
    defaultValue?: string | number;
    classname?: string
    label?: string
    listid?: string
    diffExprs?: { idExpr: string, dataExpr: string }
    refresh?: boolean
    span?: boolean
    inputType?: 'number' | 'text'
    style?: any
    selectedData?: any
    useCallbackFunc?: boolean
    outerDisable?: boolean
    customDataSet?: { data: any[], idExpr: string, nameExpr: string } //custom data set for datalist instances
    setCallbackValue?: (value: string | number, index: number) => void,
    handleClear?: () => void
    handleRefresh?: () => void
}
const SetupsDatalistTemplat = ({ options, datalistStyle, inputType = 'text', selectedData, customDataSet, useCallbackFunc, classname, defaultValue, setCallbackValue, index, label, listid, refresh = true, span = true, style, nameExpr, handleRefresh, outerDisable = undefined, idExpr, handleClear: handleClear }: props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<any>('');

    // get form tier
    const tier = useSelector((state: RootState) => state.general.tier);
    const updateMode = useSelector(
        (state: RootState) => state.general.updateMode
    );
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass);

    const { name, shortName, checkOnclick, checkTrigger, checkUrl } = useReduxValues(tier)

    const disabled = useSelector((state: RootState) => state.general.formDisabled);

    //short name auto fill
    
    const formTableData = useSelector((state: RootState) => state.general.formData);
    
    // get disabled mode
    const selectedFieldData = useSelector((state: RootState) => state.general.selectedFieldData);

    //Populating field on update 
    // const tier1Data = useSelector((state:RootState)=>state.tier1Add.selectedFieldData);

    //Code to fix error on clicking order fields
    const inputField = document.getElementById('ice-cream-choice');

    inputField?.addEventListener('mouseenter', function () {
        this.focus();
    });

    //Disable focus on mouse out. 🎉
    inputField?.addEventListener('mouseout', function () {
        this.blur();
    });

    
    //handle onchange redux posting
    const passFormData = useCallback((data: string | number, action?: string) => {
        tier === '1' ?
            dispatch(setValue({ expr: listid, value: data }))
            : tier === 'tier2checkbox' ?
            dispatch(setTier2CheckboxValue({ expr: listid, value: data }))
                : tier === '2' ?
                    dispatch(setTier2Value({ expr: listid, value: data }))
                    : tier === '3' ?
                        dispatch(setTier3Value({ expr: listid, value: data }))
                        : tier === '4' ?
                            dispatch(setTier4Value({ expr: listid, value: data }))
                            :
                            console.log('No Tier set');
                        }, [tier, dispatch, listid])
                        
                        useEffect(() => {
                            if (label === 'Short Name' && !updateMode && !disabled) {
                                setData(name)
                                passFormData(name)
                            }
                        }, [name,updateMode, disabled, passFormData, label])
                        useEffect(() => {
                            if (useCallbackFunc) {
                                setData(selectedData)
                                setCallbackValue && setCallbackValue(selectedData!, index!);
                            }
                            else {
                                setData(selectedFieldData[nameExpr!]); //set selected field data from general slice
                                passFormData(selectedFieldData[nameExpr!])
                            }
                        }, [selectedFieldData, selectedData,index, nameExpr, setCallbackValue, useCallbackFunc, passFormData]);
    const memoizedOptions = useMemo(() => {

        return !options ? formTableData?.map((option: any, index: number) => {
            const key = index
            const value = option[nameExpr!]
            return (
                <div key={key}>
                    <option value={value} />
                </div>
            )
        })
            :
            options?.map((option: any, index: number) => {
                const key = index
                const value = option[nameExpr!]
                return (
                    <div key={key}>
                        <option value={value} />
                    </div>
                )
            })
    }, [formTableData, nameExpr,options])


    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

        setData(inputType === 'text' ? e.target.value : Number(e.target.value))
        console.log("aaahahah: ", e)
        useCallbackFunc ?
            setCallbackValue && setCallbackValue(inputType === 'text' ? e.target.value : Number(e.target.value), index!)
            :
            passFormData(inputType === 'text' ? e.target.value : Number(e.target.value))
    
    }, [index, inputType, passFormData, setCallbackValue, useCallbackFunc])

return (
    <>
        <Form.Item
            style={style}
            label={label && <p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{label}</p>}
            className={'mb-0'}
            name={label}
        >
            <div className="flex flex-row items-center">
                <Input
                    className={classname}
                    style={!span ? { width: 'calc(100% - 28px)', ...datalistStyle } : { ...datalistStyle }}
                    type={inputType}
                    onPasteCapture={() => {}}
                    value={data}
                    disabled={outerDisable !== undefined ? outerDisable : disabled}
                    onChange={handleChange}
                    // placeholder = '&#xf0d7;'
                    list={listid}
                    // onMouseLeave={() => setMiniDropdown(true)}
                    // onMouseEnter={() => setMiniDropdown(false)}
                    id="ice-cream-choice"
                    name={listid}
                />
                <datalist id={listid}>
                    {memoizedOptions}
                </datalist>

                {(label !== 'Short Name' && refresh) &&
                    <span
                        onClick={() => {
                            if(!disabled) {
                            handleClear && handleClear()
                            handleRefresh && handleRefresh()
                            setData('');
                            }
                        }}
                        style={{ height: 22.5, marginBottom: 1, borderWidth: 1, width: 25.5, borderColor: borderTheme }}
                        className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                    </span>
                }

                {!span &&
                    <span
                        onClick={() => {
                            if(!disabled) { 
                                handleClear && handleClear()
                                setData('');
                            }
                        }}
                        style={{ height: 22.5, marginBottom: 1, width: 24, borderColor: borderTheme }}
                        className=' flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                        {/* <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" /> */}
                    </span>
                }
            </div>
        </Form.Item>
    </>
);
}

export default SetupsDatalistTemplat;