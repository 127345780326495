/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useFetch from "../../../../hooks/useFetch";
import {
  api_base_url,
  navbar_height,
  saveModalData,
  toolbar_height,
} from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import Datagrid_template from "../../../../templates/Datagrid";
import { other_details_TH } from "../data/other-details-data";
import InputSelect_template from "../../../../templates/InputSelect";
import { TextareaTemplate } from "../../../../templates/textarea";
import newBtn from "../../../../assets/new.png";
import close from "../../../../assets/close.png";
import refreshBtn from "../../../../assets/refresh-small.png";
import axios from "axios";
import { ModalTemplate } from "../../../../templates/modal";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import useToken from "antd/es/theme/useToken";
import { useCrudFunc } from "../../../../functions/crud";
interface props {
  toggled?: boolean;
  selectedRecord?: any;
}
interface states {
  parameter: { id: number; name: string };
  value: { id: number; name: string };
  remarks: string;
}
const OtherParameters = ({ toggled, selectedRecord }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const resetParams = useSelector(
    (state: RootState) => state.general.resetParams
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const [Posting, Updating] = useCrudFunc("powerapp");
  const [states, setStates] = useState<states>({
    parameter: { id: 0, name: "" },
    value: { id: 0, name: "" },
    remarks: "",
  });
  console.log("params", states);
  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  const [userID, empID, userInfo] = useAccountInfo();
  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refreshState, setRefreshState] = useState<boolean>(false);
  const [paramsGrid, setParamsGrid] = useState<any>([]);
  console.log("setParamsGrid", paramsGrid);
  const [otherParamsData] = useFetch(
    `TransformerDetails/PopCmbOtherParameters`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [mainGrid] = useFetch(
    `TransformerDetails/PopGdcOtherTransformerParameters?olpLineDetailsIDfk=${selectedRecord?.tfdIDpk}`,
    refreshState,
    undefined,
    undefined,
    "powerapp"
  );
  const [valueData] = useFetch(
    `TransformerDetails/PopCmbParameterValues`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [parameterCheck] = useFetch(
    `TransformerDetails/CheckIfParameterExists?opmName=${states.parameter.name}&OlpParameterIdfk=${selectedRecord?.tfdIDpk}`,
    selectedRecord,
    undefined,
    undefined,
    "powerapp"
  );
  console.log("parameterCheck", parameterCheck);
  const validateParams = () => {
    if (parameterCheck.length > 0) {
      setIcon("question");
      setOpenModal(true);
      setModalData({
        message:
          "This Parameter has already been added to the features of this Line. Do you want to UPDATE its Value?",
        title: "Parameter exists!",
        okText: "Yes, update",
      });
    } else if (
      states.parameter.name === "" ||
      states.parameter.name === undefined
    ) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Please select a parameter",
        title: "Field required!",
        okText: "Ok",
      });
    } else if (states.value.name === "" || states.value.name === undefined) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Please select a value",
        title: "Field required!",
        okText: "Ok",
      });
    } else {
      setIcon("question");
      setOpenModal(true);
      setModalData({
        message: "Are you sure you want to add this parameter",
        title: "Add parameter?",
        okText: "Add",
      });
    }
  };
  const validate_delete = () => {
    if (paramsGrid === undefined || paramsGrid === "") {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "No record selected, please select a record to delete",
        title: "No record selected",
        okText: "Add",
      });
    } else {
      setIcon("question");
      setOpenModal(true);
      setModalData({
        message: "Are you sure you want delete this record?",
        title: "Delete record?",
        okText: "Delete",
      });
    }
  };
  const postData = async () => {
    try {
      const res = await Posting(`TransformerDetails/CreateAccessories`, {
        olpLineDetailsIdfk: selectedRecord?.tfdIDpk,
        olpParameterIdfk: states.parameter.id,
        olpValue: states.value.name,
        olpActive: true,
        olpRmks: states.remarks,
        olpCreatedBy: userInfo.empName,
        formAction: `Created Other Parameter with ID ${selectedRecord?.tfdIDpk} successfully`,
      });

      // console.log(res)
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "Record saved successfully!",
        title: "Parameter added!",
        okText: "Ok",
      });
      setRefreshState(!refreshState);
      // refreshStates();
    } catch (err: any) {
      console.log("error", err);

      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const updateData = async () => {
    try {
      const res = await Updating(`TransformerDetails/UpdateAccessories`, {
        olpEditedBy: userID,
        olpIdpk: states.parameter.id,
        olpValue: states.value.name,
        olpRmks: states.remarks,
        formAction: `Updated Other Parameter with ID ${selectedRecord?.tfdIDpk}'s value to ${states.value.name} successfully`,
      });

      // console.log(res)
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "Record updated successfully!",
        title: "Value updated!",
        okText: "Ok",
      });
      setRefreshState(!refreshState);
      // refreshStates();
    } catch (err: any) {
      console.log("error", err);

      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const deleteData = async () => {
    try {
      const res = await Updating(`TransformerDetails/DeleteAccessories`, {
        olpIDpk: paramsGrid?.olpIDpk,
        formAction: `Updated Other Parameter with ID ${selectedRecord?.tfdIDpk}'s value to ${states.value.name} successfully`,
      });

      // console.log(res)
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "Record deleted successfully!",
        title: "Record deleted!",
        okText: "Ok",
      });
      setRefreshState(!refreshState);
      // refreshStates();
    } catch (err: any) {
      console.log("error", err);

      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const addParameter = () => {
    validateParams();
  };

  const resetFields = () => {
    updateState("parameter", { id: 0, name: "" });
    updateState("value", { id: 0, name: "" });
    updateState("remarks", "");
  };
  useEffect(() => {
    if (resetParams === true) {
      resetFields;
    }
  }, [resetParams]);

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        disableCancel={icon === "question" ? false : true}
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Parameter exists!"
            ? updateData()
            : modalData.title === "Add parameter?"
            ? postData()
            : modalData.title === "Delete record?"
            ? deleteData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              {...formPropsConst}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 24 }}
              className=" border-l-2 border-r-2 "
              style={{ width: "100%" }}
            >
              <div className=" border-t-2  ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Other Parameter
                </div>
                {/* <Form labelCol={{span:4}}
                layout="horizontal"
                > */}
                {!disable && (
                  <div className=" mr-[2px]">
                    <div>
                      <InputSelect_template
                        options={otherParamsData}
                        dataexp={"name"}
                        idexpr={"objId"}
                        placeHolder={states.parameter}
                        selectedValue={(e: any) => {
                          updateState("parameter", {
                            id: e.objId,
                            name: e.name,
                          });
                          console.log("e", e);
                        }}
                        label="Parameter"
                      />
                    </div>
                    <div className="">
                      <InputSelect_template
                        dataexp={"name"}
                        idexpr={"objId"}
                        options={valueData}
                        placeHolder={states.value}
                        selectedValue={(e: any) => {
                          updateState("value", { id: e.objId, name: e.name });
                          console.log("e", e);
                        }}
                        label="Value"
                      />
                    </div>
                    <div className="flex w-full gap-[2px]">
                      <div className="w-full ml-[7px]">
                        <TextareaTemplate
                          label="Remarks"
                          height={50}
                          labelCol={3}
                          setCallbackValue={(e: string) => {
                            updateState("remark", e);
                          }}
                        />
                      </div>

                      <li
                        onClick={() => {
                          addParameter();
                          // setRefreshBtn(!refreshBtn);
                        }}
                        className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5  "
                      >
                        <img className="w-[25px] mt-3" src={newBtn} />
                      </li>
                    </div>
                    {/* Refresh && Close Btn */}
                    <div className="flex w-full justify-end mt-1">
                      <li
                        onClick={() => {
                          setRefreshState(!refreshState);
                          // setRefreshBtn(!refreshBtn);
                        }}
                        className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5 mr-1  "
                      >
                        <img className="" src={refreshBtn} />
                      </li>
                      <li
                        onClick={() => {
                          validate_delete();
                          // setRefreshBtn(!refreshBtn);
                        }}
                        className=" border-[1px] rounded cursor-pointer py-0.5 px-0.5 mr-1  "
                      >
                        <img className="" src={close} />
                      </li>
                    </div>
                  </div>
                )}
                {/* </Form> */}
                <Datagrid_template
                  keyExpr="olpIDpk"
                  gridheight={250}
                  showColumnHeaders={true}
                  columns={other_details_TH}
                  data={mainGrid}
                  onRowClick={(e: any) => {
                    setParamsGrid(e);
                  }}
                  disablePaging
                  disableSearch
                  disableGroupPanel
                />
              </div>
            </Form>
          </div>
        </div>
      }
    </div>
  );
};

export default OtherParameters;
