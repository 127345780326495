import { Tooltip } from "devextreme-react";
import { generateUUID } from "../../../../../../../Safety/elogger/widgets/utils";
import { ReactNode } from "react";

type IconButtonProps = {
  handleOnclick?: () => void;
  id?: string;
  imgSrc: string;
  tooltip?: ReactNode;
  hideOnOutsideClick?: boolean;
  label?: ReactNode;
  disabled?: boolean;
};
export default function IconButton(props: IconButtonProps) {
  const fakeID = generateUUID();
  return (
    <button
      disabled={props.disabled ?? !!!props.handleOnclick}
      id={props.id ?? fakeID}
      onClick={props.handleOnclick}
      style={{ borderWidth: "1px" }}
      className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
    >
      <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
        {" "}
        {props.imgSrc && <img
          className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100 self-center"
          src={props.imgSrc}
        />}
        {props.label && props.label}
      </span>
      <Tooltip
        target={`#${props.id ?? fakeID}`}
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        hideOnOutsideClick={props.hideOnOutsideClick ?? true}
      >
        {props.tooltip}
      </Tooltip>
    </button>
  );
}
