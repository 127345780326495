import { Form, Checkbox, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { Cancel, UpdateButton } from "../../../../../accessories/buttons";
import { updateModalData } from "../../../../../accessories/component_infos";
import {
  modalTypes,
  validateInstanceTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useCrudFunc } from "../../../../../../functions/crud";
interface statesTypes {
  rating?: number;
  remarks?: any;
  satisfied?: boolean;
  confirmModal: any;
  warningModal: any;
  successModal: any;
}

interface props {
  selectedRecord?: any;
  handleOnCancel: () => void;
}

export default function RatingForm({ selectedRecord, handleOnCancel }: props) {
  const [states, setStates] = useState<statesTypes>({
    rating: 0,
    remarks: "",
    satisfied: false,
    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
  });
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc("powerapp");
  //update data
  const update = async (action: "validate" | "update") => {
    const fetchData = async () => {
      // disableConfirmModal();
      // busyloader(updateModalData.progress)
      updateState("confirmModal", {
        state: false,
        title: "string",
        message: "string",
      });
      try {
        const updateResponse = await UpdateDataFunc(
          `TroubleReports/Rate`,
          {
            trpAcceptanceRmks: states.remarks,
            trpRating: states.rating,
            trpReporterSatisfied: states.satisfied,
            trpIDpk: selectedRecord?.trpIDpk,
            formCode: "omgTroubleReports",
            formAction: `Rated Trouble report with id ${selectedRecord?.id} successfully`,
          },
          `Rated Trouble report with id ${selectedRecord?.id} successfully`
        );
        handleOnCancel();

        updateState("successModal", {
          state: true,
          title: "Record Updated",
          message: "Trouble Report updated successfully",
        });
        // enableSuccessModal('Update','updated')
        // disableFields();
        // refreshGrid();
        // clearSelected();
        // setrefreshx('');
        //Maintain updated fields
        // populateStatesFromGrid(false);
      } catch (error) {
        console.log(error);

        updateState("warningModal", {
          state: true,
          title: updateModalData.error.title,
          message: updateModalData.error.message,
        });
      } finally {
        // busyloader('');
      }
    };

    if (action === "validate") {
      // validate fieldds
      validateFields() &&
        updateState("confirmModal", {
          state: true,
          title: "Rate Record",
          message: "Are you sure you want to rate this record?",
          action: "update",
          func() {
            update("update");
          },
        });
      return;
    } else {
      fetchData();
    }
    return;
  };

  const validateFields = (): boolean => {
    const validationInstances: validateInstanceTypes[] = [
      {
        state: states.remarks,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Rating Remarks",
          message: "Please enter remarks",
        },
      },
      // {state:states.rating,baseValue:'',modalProps:{state:true,title:'Select Completion Date',message:'Please select completion date'}},
    ];

    for (let instance of validationInstances)
      if (instance.state === instance.baseValue) {
        const { modalProps } = instance;
        updateState("warningModal", {
          state: modalProps.state,
          title: modalProps.title,
          message: modalProps.message,
        });
        return false;
      }
    return true;
  };

  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: states.warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.warningModal.message,
      okText: "Ok",
      title: states.warningModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: states.confirmModal.state,
      okHandler: states.confirmModal.func,
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText:
        states.confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    //success
    {
      disableCancel: true,
      icon: "success",
      open: states.successModal.state,
      okHandler: () =>
        updateState("successModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.successModal.message,
      okText: "Ok",
      title: states.successModal.title,
      cancelText: "No, cancel",
    },
  ];

  return (
    <div>
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}
      <Form
        name="complex-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        size={"small"}
        className="w-full"
      >
        <div className="mt-[22px]">
          <div>
            <Form.Item
              label={<p className="text-xs">{"Acceptance Remarks"}</p>}
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                style={{
                  display: "inline-block",
                  marginBottom: "1px",
                  width: "calc(100%)",
                }}
                className=" mb-1 mr-2 "
              >
                <TextArea
                  style={{
                    resize: "none",
                    maxHeight: "70px",
                    minHeight: "70px",
                    height: "70px",
                    width: "",
                  }}
                  className="o  overflow-y-scroll resize-none"
                  rows={3}
                  value={states.remarks}
                  onChange={(e) => {
                    updateState("remarks", e.target.value);
                  }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              style={{ display: "inline-block", marginBottom: "10px" }}
              label={<p className="text-xs">{"User Rating"}</p>}
              className="w-full mb-1"
            >
              <div className=" items-end border  px-[4px] rounded pt-[4px]">
                <div
                  className=" flex  flex-row justify-between"
                  style={{ marginBottom: "1px", width: "calc(100%)" }}
                >
                  <div className="text-xs ">
                    <span style={{ borderColor: borderTheme }} className="">
                      <Rate
                        className="m-0 p-0"
                        tooltips={["desc"]}
                        value={states.rating}
                        onChange={(e: number) => {
                          updateState("rating", e);
                        }}
                        count={10}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              style={{ display: "inline-block", marginBottom: "10px" }}
              label={<p className="text-xs">{"Satisfied with Solution"}</p>}
              className="w-full mb-1"
            >
              <div className=" items-end">
                <div
                  className=" flex  flex-row justify-between"
                  style={{ marginBottom: "1px", width: "calc(100%)" }}
                >
                  <div
                    style={{
                      borderWidth: "1px",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderColor: borderTheme,
                    }}
                    className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center h-[24px]"
                  >
                    <Checkbox
                      checked={states.satisfied}
                      defaultChecked={states.satisfied}
                      onChange={(e) => {
                        updateState("satisfied", e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              style={{ display: "inline-block", marginBottom: "10px" }}
              label={<p className="text-xs">{}</p>}
              className="w-full mb-1"
              colon={false}
            >
              <div className="flex justify-end items-end">
                <UpdateButton
                  useCallbackFunc
                  handleUpdate={() => {
                    validateFields() && update("validate");
                  }}
                />
                <Cancel />
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
