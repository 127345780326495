/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { RootState } from '../../../../../../app/store';
import { setGeneralValue } from '../../../../../../features/generalSlice';
import { useBusyLoader } from '../../../../../../hooks/useBusyLoader';
import { navbar_height, saveModalData, toolbar_height } from '../../../../../accessories/component_infos';
import { searchTextTypes, statusTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import useFetch from '../../../../../../hooks/useFetch';
import { WarningAlert } from '../../../../../accessories/warningAlert';
import { HrSetupsCustomToolbar } from '../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { EventTypes_form } from './widgets/eventTypeForm';
import { safety_setups_eventTypesColumns } from './data/eventTypesData';
import Datagrid_template from '../../../../../../templates/Datagrid';
import { ModalTemplate } from '../../../../../../templates/modal';
import { useHeightResizer } from '../../../../../Safety/elogger/util/iHooks';



export const SafetySetupEventTypes = forwardRef(({ a }: any, ref) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    const dispatch = useDispatch();
    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));


    const allowUpdate = () => {
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    }

    const [SelectedFielld, setSelectedFielld] = useState({});
    const [busyLoader, setBusyLoader] = useBusyLoader();

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;

    //varying form height 

    const screenHeight = useHeightResizer();

    const childRef = useRef<any>();

    // handle interface switch
    const toggle_staff_form = (): void => {
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ?
            navigate({
                pathname: setupPageRoute,
                search: createSearchParams({
                    view: datagridOnlySearchParam,
                }).toString(),
            })
            :
            navigate({
                pathname: setupPageRoute,
                search: createSearchParams({
                    view: formModeSearchParam,
                }).toString(),
            });
    }
    const [refresh, setRefresh] = useState(false)

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar


    const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' })
    const [status, setStatus] = useState<statusTypes>({ temp: '', status: '' })
    const [pageSize, setPageSize] = useState(20)
    //fetch data
    const [data, error, loading] = useFetch(`EventTypeContoller/GetEventTypes`, refresh, 'evtIDpk', false, "powerapp", false, {
        ...(searchText.text !== '' && { "SearchTerm": searchText.text }),
        ...(status.status !== '' && { "CheckState": status.status }),
        ...({ "PageSize": pageSize })

    });

    useEffect(() => {
        loading ? setBusyLoader('.') : setBusyLoader('');
    }, [loading])


    const dataGridRowClick = (selectedFieldData: any) => {
        setSelectedFielld(selectedFieldData);
        dispatch(setGeneralValue({ expr: 'selectedFieldData', value: selectedFieldData }))

        //disable forms
        dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
        dispatch(setGeneralValue({ expr: 'updateMode', value: false }));
    }

    const [validationModalState, setvalidationModalState] = useState<any>({
        state: false,
        title: '',
        message: '',
    });
    const setValidationModal = (state: string, value: string | boolean) => {
        setvalidationModalState((warningState: any) => ({ ...warningState, [state]: value }));
    }


    return (
        <>
            {error && (
                <WarningAlert />
            )}
            <ModalTemplate
                disableCancel={true}
                open={validationModalState.state}
                okHandler={() => setValidationModal("state", false)}
                cancelHandler={() => setValidationModal("state", false)}
                message={validationModalState.message}
                okText={"Ok"}
                title={validationModalState.title}
            />
            <div style={{ height: pageHeight }} className="w-full">
                <div className="px-2">
                    <HrSetupsCustomToolbar
                        handleNew={() => {
                            childRef.current.clear();

                            setSelectedFielld({})
                        }}
                        handleUpdate={() => {
                            // if(SelectedFielld['evtIDpk'] === undefined ||SelectedFielld['evtName'] === undefined){
                            //     setvalidationModalState({
                            //         state: true,
                            //         title: "Error",
                            //         message: "Please select a record",
                            //       });
                            // }
                        }
                        }
                        outerSelected={SelectedFielld}
                        searchTextOnchange={(newText) => {
                            setSearchText({ temp: newText, text: searchText.text })
                        }}
                        checkOnChange={(newStatus) => {
                            setStatus({ temp: newStatus, status: status.status })
                        }}
                        handleFind={() => {
                            setSearchText({ temp: searchText.temp, text: searchText.temp });
                            setStatus({ temp: status.temp, status: status.temp })
                            setRefresh(current => !current)
                        }}
                        handleRefresh={() => {
                            setSearchText({ temp: "", text: "" });
                            setStatus({ temp: "", status: "" })

                            setRefresh(current => !current)
                        }}
                        withDates={false}
                        toggler={toggle_staff_form} />
                </div>

                {/* form section */}
                {
                    <section style={{
                        height: (searchParams.get('view') !== datagridOnlySearchParam) ? 220 : 0,
                        display: (searchParams.get('view') !== datagridOnlySearchParam) ? "block" : "none"
                    }} className="form border-b-4">
                        <EventTypes_form
                            ref={childRef}
                            refreshGrid={() => setRefresh(current => !current)}
                            selectedItemData={SelectedFielld}
                            resetData={() => {
                                setSelectedFielld({})

                            }}
                            setBusyLoader={setBusyLoader}
                        />
                    </section>}

                {/* datagrid section */}
                <section className="mt-1 px-2">
                    <Datagrid_template
                        rowDoubleClicked={() => {
                            try {

                                navigate({
                                    pathname: setupPageRoute,
                                    search: createSearchParams({
                                        view: formModeSearchParam,
                                    }).toString(),
                                });

                            } catch (error) {
                                console.log(error)
                            }
                            allowUpdate()


                        }}
                        handlePageSize={(size) => {
                            setPageSize(size)
                            setRefresh(current => !current)
                        }}
                        pageNumber={pageSize}
                        pageSize={pageSize}
                        handlePageNumber={() => { }}
                        onRowClick={dataGridRowClick}
                        gridheight={searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ? screenHeight - (220 + miscHeight) : screenHeight - miscHeight}
                        data={data}
                        columns={safety_setups_eventTypesColumns} />
                </section>
            </div>
        </>
    );
})