import {
  Checkbox,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Radio,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { DataGrid } from "devextreme-react";
import {
  SearchPanel,
  GroupPanel,
  Pager,
  Column,
  Scrolling,
} from "devextreme-react/data-grid";
import reset from "../../../../assets/reset.png";
import { question_table_data, questions_TH } from "../data/questions-data";
// import {
//   Cancel,
//   NewButton,
//   SaveButton,
//   UpdateButton,
// } from "../../../../../accessories/buttons";
// import {
//   smallAttendanceData,
//   smallAttendaceTable_TH,
//   mediumlAttendaceTable_TH,
// } from "../../../../../Safety/Entries/SafetyMeetingAttendance/data/meeting-attendance-data";
import { useState, useEffect, useRef } from "react";
// import {
//   api_url,
//   navbar_height,

//   saveModalData,
//   toolbar_height,
//   updateModalData,
// } from "../../../../../accessories/component_infos";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
// import useFetch from "../../../../../../hooks/useFetch";
import axios from "axios";
import { RootState } from "../../../../app/store";
import {
  api_url,
  navbar_height,
  saveModalData,
  toolbar_height,
  updateModalData,
} from "../../../accessories/component_infos";
import useFetch from "../../../../hooks/useFetch";
import {
  setGeneralValue,
  settask_footer_table_refresh,
} from "../../../../features/generalSlice";
import { ModalTemplate } from "../../../../templates/modal";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../accessories/buttons";
import { DateTemplate } from "../../../../templates/date";
import { SelectsTemplate } from "../../../../templates/select";
import { InputsTemplate } from "../../../../templates/input";
import { DropdownGrid_template } from "../../../../templates/DropdownGrid";
import { sourceStation_cols } from "../data/source-station-data";
import InputSelect_template from "../../../../templates/InputSelect";
import { useCrudFunc } from "../../../../functions/crud";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { backgroundColor } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";
import { debounce } from "lodash";
import { GetDataFunc } from "../../../../functions/get";
// import { ModalTemplate } from "../../../../../../templates/modal";
// import { RootState } from "../../../../../../app/store";
// import { settask_footer_table_refresh } from "../../../../../../features/generalSlice";
// import { task_footer_update, task_form_disable } from "../../../../../../features/Task&Todo/Task/TaskDetails";
interface props {
  toggled?: boolean;
  statesx?: any;
  updateStatesx?: (key: string, value: any) => void;
  setRefresh?: (e: boolean) => void;
  refresh?: boolean;
  imageDescriptions?: any;
}

interface stateTypes {
  nomenclature?: { id: number; name: string };
  source?: { id: number; name: string };
  dstation?: { id: number; name: string };
  condtype?: { id: number; name: string };
  condcode?: { id: number; name: string };
  manufacturer?: { id: number; name: string };
  length?: number;
  voltage?: any;
  secArea?: any;
  construction?: Dayjs;
  commissioning?: Dayjs;
  decommissioning?: Dayjs;
  manDate?: Dayjs;
  supply?: Dayjs;
  bundled?: boolean;
  plant?: boolean;
  verified?: boolean;
  active?: boolean;
  sisr?: boolean;
  isOpenDropdown: boolean;
  isLoading: boolean;
  // refresh: boolean;
}

const BasicLineDetailsTab = ({
  toggled,
  statesx,
  updateStatesx,
  refresh,
  setRefresh,
  imageDescriptions,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm";

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);

  const dispatch = useDispatch();
  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );
  console.log(statesx, "selectedRecord");
  const refs = useRef<any>(null);

  const [states, setStates] = useState<stateTypes>({
    nomenclature: { id: 0, name: "" },
    source: { id: 0, name: "" },
    dstation: { id: 0, name: "" },
    condtype: { id: 0, name: "" },
    condcode: { id: 0, name: "" },
    manufacturer: { id: 0, name: "" },
    length: 0,
    voltage: 0,
    secArea: 0,
    construction: dayjs(),
    commissioning: dayjs(),
    decommissioning: dayjs(),
    manDate: dayjs(),
    supply: dayjs(),
    bundled: false,
    plant: false,
    verified: false,
    active: false,
    sisr: false,
    isOpenDropdown: false,
    isLoading: false,
  });

  console.log(states, "states");

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const close = async () => {
    refs.current!.instance.close();
  };

  // Endpoints

  const [nom_data, nom_error, nom_loading] = useFetch(
    `LineDetails/nomenclatures`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [ccn_data, ccn_error, ccn_loading] = useFetch(
    `LineDetails/conductor_code_names`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [ss_data, ss_error, ss_loading] = useFetch(
    `LineDetails/stations`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  // const [ds_data, ds_error, ds_loading] = useFetch(
  //   "api/AsmTamEntLineDetails/GetAsmTamLineDetailLueDestinationStation"
  // );
  const [ct_data, ct_error, ct_loading] = useFetch(
    `LineDetails/conductor_types`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [m_data, m_error, m_loading] = useFetch(
    `LineDetails/manufacturers/true`,
    true,
    undefined,
    undefined,
    "powerapp"
  );

  console.log(nom_data, "nom_data");

  // Modal dialig states
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [refreshstate, setRefreshstate] = useState(false);
  // const [refresh, setRefresh] = useState(false);

  // Input/Select Onchange
  const [nomState, setNomState] = useState<string>("");
  const [ssState, setssState] = useState<number>(0);
  const [dsState, setdsState] = useState<string>("");
  const [ctState, setctState] = useState<number>(0);
  const [ccnState, setccnState] = useState<number>(0);
  const [mState, setmState] = useState<number>(0);
  const [length, setLength] = useState<number>(0);
  const [ov, setOv] = useState<number>(0);
  const [secA, setSecA] = useState<string>("");

  // Dates
  const [ConstructionDate, setConstructionDate] = useState<string>("");
  const [CommissioningDate, setCommissioningDate] = useState<string>("");
  const [DecommissioningDate, setDecommissioningDate] = useState<string>("");
  const [ManufactureDate, setManufactureDate] = useState<string>("");
  const [SupplyDate, setSupplyDate] = useState<string>("");

  const ConstructionDateOnChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    console.log("task start date " + dateString);

    setConstructionDate(dateString as string);
  };
  const CommissioningDateOnChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    console.log("task start date " + dateString);
    setCommissioningDate(dateString as string);
  };
  const DecommissioningDateOnChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    console.log("task start date " + dateString);
    setDecommissioningDate(dateString as string);
  };
  const ManufactureDateOnChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    console.log("task start date " + dateString);
    setManufactureDate(dateString as string);
  };
  const SupplyDateOnChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    console.log("task start date " + dateString);
    setSupplyDate(dateString as string);
  };

  const edited_date = dayjs().format();

  //  Booleans
  const [Verified, setVerified] = useState<boolean>(false);
  const [Active, setActive] = useState<boolean>(false);
  const [bundled, setBundled] = useState<boolean>(false);
  const [plantLine, setPlantLine] = useState<boolean>(false);
  const [sisr, setSisr] = useState<boolean>(false);

  // const newAccesspriv = () => {
  //   dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
  // };

  const populateFields = (clear?: boolean) => {
    if (clear === true) {
    }
    const gridState = [
      {
        key: "nomenclature",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx?.ldtNomenclature,
              name: statesx?.ldtNomenclature,
            },
      },
      {
        key: "source",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx?.ldtSourceStationIDfk,
              name: statesx?.sourceStationName,
            }, //need to change this
      },
      {
        key: "dstation",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx?.ldtDestinationStationIDfk,
              name: statesx?.destStationName,
            },
      },
      {
        key: "condtype",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx?.ldtConductorTypeIDfk,
              name: statesx?.cdtName,
            },
      },
      {
        key: "manufacturer",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx?.mftIDpk,
              name: statesx?.mftName,
            },
      },
      {
        key: "condcode",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx?.ldtConductorCodeNameIDfk,
              name: statesx?.ccnName,
            },
      },
      {
        key: "length",
        value: clear ? 0 : statesx?.ldtLength,
      },
      {
        key: "voltage",
        value: clear ? 0 : statesx?.ldtOperatingVoltage,
      },
      {
        key: "secArea",
        value: clear ? 0 : statesx?.ldtConductorCrossSectionalArea,
      },
      {
        key: "construction",
        value: clear ? dayjs() : statesx?.ldtConstructionDate,
      },
      {
        key: "commissioning",
        value: clear ? dayjs() : statesx?.ldtCommissionDate,
      },
      {
        key: "decommissioning",
        value: clear ? dayjs() : statesx?.ldtDecommissionDate,
      },
      {
        key: "manDate",
        value: clear ? dayjs() : statesx?.ldtManufactureDate,
      },
      { key: "supply", value: clear ? dayjs() : statesx?.ldtSupplyDate },
      {
        key: "bundled",
        value: clear ? false : statesx?.ldtBundled,
      },
      {
        key: "plant",
        value: clear ? false : statesx?.ldtPlantLine,
      },
      { key: "verified", value: clear ? false : statesx?.ldtVerified },
      { key: "active", value: clear ? false : statesx?.ldtActive },
      {
        key: "sisr",
        value: clear ? false : statesx?.ldtShowInStationReadings,
      },
    ];

    for (let instance of gridState) setState(instance.key, instance.value);
  };

  const validationForms = () => {
    setIcon("warning");
    const isUpdating = update_state_change;

    const validateDate = (
      dateField: string,
      fieldName: string,
      statesxField: string
    ) => {
      const currentDate = states[dateField];
      const today = dayjs();

      if (isUpdating) {
        const originalDate = dayjs(statesx?.[statesxField]);
        if (dayjs(currentDate).isBefore(originalDate)) {
          setshowModalDisableButton(true);
          setModalData({
            message: `${fieldName} cannot be before the original date (${originalDate.format(
              dateFormat
            )})`,
            title: "Invalid Entry!",
            okText: "Ok",
          });
          setOpenModal(true);
          return false;
        }
      } else {
        if (dayjs(currentDate).isBefore(dayjs(), "day")) {
          setshowModalDisableButton(true);
          setModalData({
            message: `${fieldName} cannot be in the past for new records`,
            title: "Invalid Entry!",
            okText: "Ok",
          });
          setOpenModal(true);
          return false;
        }
      }
      return true;
    };

    if (states.nomenclature.name === "") {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Nomenclature",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.source.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for source station",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.dstation.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for destination station",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.condtype.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for conductor type",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.condcode.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for conductor code name",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.manufacturer.id == 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for manufacturer",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.length === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for length",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !validateDate("construction", "Construction date", "ldtConstructionDate")
    ) {
      return;
    } else if (
      !validateDate("commissioning", "Commissioning date", "ldtCommissionDate")
    ) {
      return;
    } else if (
      !validateDate(
        "decommissioning",
        "Decommissioning date",
        "ldtDecommissionDate"
      )
    ) {
      return;
    } else if (
      !validateDate("manDate", "Manufacture date", "ldtManufactureDate")
    ) {
      return;
    } else if (!validateDate("supply", "Supply date", "ldtSupplyDate")) {
      return;
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      // updateStatesx("selectedRecord", {});
      populateFields(true);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setIcon("question");
      setModalData({
        title: "Save Record",
        message: "Are you sure you want to save this record?",
        okText: "Yes",
        cancelText: "No",
      });
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  let FormCode = useSelector((state: RootState) => state.user?.formCode);

  const [Posting, Updating, Patching] = useCrudFunc("powerapp");


  // Post
  

  const postData = async () => {
    const otherLineExistData: any = await GetDataFunc(
      `LineDetails/check_nomenclature_exist/${states.nomenclature.name}`,
      undefined,
      "powerapp"
    );
    const otherLineExist = otherLineExistData.data.data;
    if (otherLineExist === true){
      try {
        const res = await Posting(
          `LineDetails/line_details`,
          {
            ldtName: "stringg",
            ldtNomenclature: states.nomenclature.name,
            ldtSourceStationIdfk: states.source.id,
            ldtDestinationStationIdfk: states.dstation.id,
            ldtLength: states.length,
            ldtConstructionDate: states.construction,
            ldtCommissionDate: states.commissioning,
            ldtDecommissionDate: states.decommissioning,
            ldtOperatingVoltage: states.voltage,
            ldtConductorTypeIdfk: states.condtype.id,
            ldtConductorCodeNameIdfk: states.condcode.id,
            ldtManufacturerIdfk: states.manufacturer.id,
            ldtConductorCrossSectionalArea: states.secArea,
            ldtManufactureDate: states.manDate,
            ldtSupplyDate: states.supply,
            ldtAssigned: true,
            ldtBundled: states.bundled || false,
            ldtPlantLine: states.plant || false,
            ldtVerified: states.verified || false,
            ldtShowInStationReadings: states.sisr || false,
            ldtActive: states.active || false,
            ldtRmks: imageDescriptions,
            ldtCreatedBy: userId,
          },
          `test`
        );
        setIcon("success");
        setOpenModal(true);
        console.log(res);
        // setRefreshValue(!refreshValue);
        setRefresh && setRefresh(!refresh);
  
        setshowModalDisableButton(false);
        setModalData({
          message: "Record saved successfully!",
          title: "Record Saved",
          okText: "Ok",
        });
      } catch (err: any) {
        console.log(err);
        setIcon("warning");
        setOpenModal(true);
        setModalData({
          message: err?.response?.data.message,
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    }else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        title: "Record already exists",
        message: "The name or shortname you entered exists. Please enter a new name and try again.",
        okText: "Ok",
      });
    }
  };

  // Update

  const updateData = async () => {
    try {
      const res = await Updating(`LineDetails/line_details/${FormCode}/test`, {
        ldtName: "string",
        ldtNomenclature: states.nomenclature.name,
        ldtSourceStationIdfk: states.source.id,
        ldtDestinationStationIdfk: states.dstation.id,
        ldtLength: states.length,
        ldtConstructionDate: states.construction,
        ldtCommissionDate: states.commissioning,
        ldtDecommissionDate: states.decommissioning,
        ldtOperatingVoltage: states.voltage,
        ldtConductorTypeIdfk: states.condtype.id,
        ldtConductorCodeNameIdfk: states.condcode.id,
        ldtManufacturerIdfk: states.manufacturer.id,
        ldtConductorCrossSectionalArea: states.secArea,
        ldtManufactureDate: states.manDate,
        ldtSupplyDate: states.supply,
        ldtAssigned: true,
        ldtBundled: states.bundled || false,
        ldtPlantLine: states.plant || false,
        ldtVerified: states.verified || false,
        ldtShowInStationReadings: states.sisr || false,
        ldtActive: states.active || false,
        ldtRmks: imageDescriptions,
        ldtCreatedBy: userId,
        ldtIdpk: statesx !== undefined ? statesx.ldtIDpk : 0,
        ldtEditedBy: 1,
        ldtEditedDate: dayjs(`${edited_date}`).format(),
      });
      setIcon("success");
      setOpenModal(true);
      console.log(res);

      // dispatch(settask_footer_table_refresh(setRefresh(!refresh)))
      setRefreshValue(!refreshValue);
      setRefresh && setRefresh(!refresh);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record updated successfully",
        title: "Record Updated",
        okText: "Ok",
      });

      // setOpenModal(false)
    } catch (e) {
      console.log(e);
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: e?.response?.data.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const [nomenclature, setNomenclature] = useState<any>("");
  const [sourceStation, setSourceStation] = useState<any>("");
  const [destinationStation, setDestinationStation] = useState<any>("");
  const [conductorType, setConductorType] = useState<any>("");
  const [conductorCodeName, setConductorCodeName] = useState<any>("");
  const [manufacture, setManufacture] = useState<any>("");

  const [operatingVoltage, setOperatingVoltage] = useState<any>("");
  const [secArea, SetSecArea] = useState<any>("");

  useEffect(() => {
    populateFields(false);
  }, [toggled, statesx]);

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );

  useEffect(() => {
    if (!disablex) {
      dispatch(task_form_disable(false));

      //  this destroys the update mode state
      dispatch(task_footer_update(false));
    } else {
    }
    if (updateMode) {
    }
  }, [disablex, updateMode]);

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save Record"
            ? postData()
            : modalData.title === "Update Record"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "success" || icon === "warning" ? true : false}
      />
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              name="complex-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              size={"small"}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
              disabled={form_disable}
            >
              {/* <Form.Item label={<p className='text-xs'>{'Start Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} format={dateFormat} style={{ display: 'inline-block', width: 'calc(65%)' }} />


               </Form.Item>

               <Form.Item label={<p className='text-xs'>{'End Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} style={{ display: 'inline-block', width: 'calc(65%)' }} format={dateFormat} />


               </Form.Item> */}
              <Form.Item
                label={<p className="text-xs">{"Nomenclature"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                  colon={false}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      disabled={form_disable}
                      useCallFunc
                      idexpr={"ldtIDpk"}
                      dataexp={"ldtNomenclature"}
                      options={nom_data}
                      placeHolder={states.nomenclature?.name}
                      selectedValue={(e) => {
                        const newValue =
                          typeof e === "string" ? e : e["ldtNomenclature"];
                        setState("isOpenDropdown", false);
                        setState("nomenclature", { id: 0, name: newValue });
                        setNomenclature(newValue);
                      }}
                      handleClear={() => {
                        setState("nomenclature", { id: 0, name: "" });
                      }}
                      handleFocus={() => {
                        setState("isOpenDropdown", !states.isOpenDropdown);
                      }}
                      handleSearch={debounce((e) => {
                        setState("nomenclature", { id: 0, name: e });
                        console.log(e, "nomenclature");
                        setState("isOpenDropdown", true);
                      }, 300)}
                      isOpenDropdown={states.isOpenDropdown}
                      isLoading={states.isLoading}
                    />
                    {/* 
<InputSelect_template
                            key={id}
                            placeHolder={defaultValue}
                            handleRefresh={() => {}}
                            label={label}
                            options={optionsData}
                            // disabled={form_disable}
                            // disabled={DisableForm}
                            useCallFunc
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            isLoading={states.isLoading}
                            isOpenDropdown={isOpenDropdown}
                            handleSearch={
                              debounce((e) => {
                                UpdateState(stateName!, e);
                                // UpdateState("isLoading", true);
                                dispatch(singleEndpoint(e));

                                UpdateState(isOpenDropdownKey, true);
                              }, 3000) // Adjust the debounce delay as needed
                            }
                            // handleSearch={useCallback((e: any) => {
                            //   // UpdateState(stateName!, e);

                            //   // dispatch(
                            //   //   setGeneralValue({ expr: "rxregNo", value:  getRegNo() })
                            //   // );
                            //  dispatch(fetchRegNo())
                            //   console.log("searching", e)

                            // })}
                            handleFocus={(e: any) => {
                              UpdateState(isOpenDropdownKey, !isOpenDropdown);
                            }}
                            selectedValue={(e: any) => {
                              UpdateState(stateName!, e?.vdtRegNo);
                              UpdateState(isOpenDropdownKey, false);
                            }}
                            // field={field}
                            // registerName={registerName} */}
                    {/* // errors= */}
                    {/* {nom_data?.map((nd: any, index: number) => {
                        return (
                          <Option key={index} value={nd.ldtName}>
                            {nd.ldtName}
                          </Option>
                        );
                      })}
                    </SelectsTemplate> */}

                    {/* <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 0.6,
                        borderWidth: 1,
                        width: 25.2,
                      }}
                      className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
                  </div>
                </Form.Item>
              </Form.Item>
              {/* <Form.Item
                label={<p className="text-xs">{"Source Station"}</p>}
                style={{ marginBottom: 0 }}
                colon={false}
              > */}
              {/* <Form.Item
                  label={<p className="text-xs">{"Source Station"}</p>}
                  name="receivedBy"
                  // rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                > */}{" "}
              <div className="flex flex-row items-center">
                <DropdownGrid_template
                  dropdownBtnStyles={{ width: "100%" }}
                  cusBtnStyle
                  disabled={form_disable}
                  columns={sourceStation_cols}
                  className="w-[4000px] mb-1"
                  labelCol={8}
                  label={"Source Station"}
                  gridData={ss_data}
                  defaultValue={states.source?.name}
                  handleRefresh={() => {
                    setState("source", { id: 0, name: "" });
                    // setState(refreshStateName!, !refresh);
                  }}
                  onRowClick={(e) => {
                    setState("source", {
                      id: e["stnIDpk"],
                      name: e["stnName"],
                    });
                    close();
                  }}
                  // key={
                  //   selectedRecord[0] === undefined
                  //     ? ""
                  //     : selectedRecord[0]?.ldtSourceStationIdfk
                  // }
                  // defaultValue={
                  //   selectedRecord[0] === undefined
                  //     ? ""
                  //     : selectedRecord[0]?.ldtSourceStationIdfk
                  // }
                  // className="W w-full mr-0 mb-0.5 py-0"
                  // placeholder=""
                  // onChange={(e: any) => {
                  //   setssState(e);
                  // }}
                />
                {/* {ss_data?.map((sd: any, index: number) => {
                        return (
                          <Option key={index} value={sd.stnIdpk}>
                            {sd.stnName}
                          </Option>
                        );
                      })} */}
                {/* </SelectsTemplate> */}

                {/* <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 0.6,
                        borderWidth: 1,
                        width: 25.2,
                      }}
                      className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
              </div>
              {/* </Form.Item> */}
              {/* </Form.Item> */}
              {/* <Form.Item
                label={<p className="text-xs">{"Destination Station"}</p>}
                style={{ marginBottom: 0 }}
              > */}
              {/* <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                > */}{" "}
              <div className="flex flex-row items-center">
                <DropdownGrid_template
                  dropdownBtnStyles={{
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                  disabled={form_disable}
                  columns={sourceStation_cols}
                  label={"Destination Station"}
                  labelCol={8}
                  cusBtnStyle={true}
                  className="w-[4000px] mb-1"
                  gridData={ss_data}
                  defaultValue={states.dstation?.name}
                  handleRefresh={() => {
                    setState("dstation", { id: 0, name: "" });
                    // setState(refreshStateName!, !refresh);
                  }}
                  onRowClick={(e) => {
                    setState("dstation", {
                      id: e["stnIDpk"],
                      name: e["stnName"],
                    });
                    close();
                  }}
                  // className="W w-full mr-0 mb-0.5 py-0"
                  // placeholder=""
                  // onChange = {(e:any)=>{setdsState(e)}}
                />
                {/* {ds_data?.map((dsd: any, index: number) => {
                        return (
                          <Option key={index} value={dsd.stnIdpk}>
                            {dsd.stnName}
                          </Option>
                        );
                      })} */}
                {/* </SelectsTemplate> */}

                {/* <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 0.6,
                        borderWidth: 1,
                        width: 25.2,
                      }}
                      className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
              </div>
              {/* </Form.Item>
              </Form.Item> */}
              <Form.Item
                label={<p className="text-xs">{"Conductor Type"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      disabled={form_disable}
                      useCallFunc
                      idexpr={"cdtIDpk"}
                      dataexp={"cdtName"}
                      options={ct_data}
                      placeHolder={states.condtype?.name}
                      selectedValue={(e) => {
                        setState("condtype", {
                          id: e["cdtIDpk"],
                          name: e["cdtName"],
                        });
                      }}
                      handleClear={() => {
                        setState("condtype", { id: 0, name: "" });
                      }}
                      // key={
                      //   selectedRecord[0] === undefined
                      //     ? ""
                      //     : selectedRecord[0]?.ldtConductorTypeIdfk
                      // }
                      // defaultValue={
                      //   selectedRecord[0] === undefined
                      //     ? ""
                      //     : selectedRecord[0]?.ldtConductorTypeIdfk
                      // }
                      // className="W w-full mr-0 mb-0.5 py-0"
                      // placeholder=""
                      // onChange={(e: any) => {
                      //   setctState(e);
                      // }}
                    />
                    {/* {ct_data?.map((ctd: any, index: number) => {
                        return (
                          <Option key={index} value={ctd.cdtIdpk}>
                            {ctd.cdtName}
                          </Option>
                        );
                      })}
                    </Select> */}

                    {/* <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 0.6,
                        borderWidth: 1,
                        width: 25.2,
                      }}
                      className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Conductor Code Name"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      disabled={form_disable}
                      useCallFunc
                      idexpr={"ccnIDpk"}
                      dataexp={"ccnName"}
                      options={ccn_data}
                      placeHolder={states.condcode?.name}
                      selectedValue={(e) => {
                        setState("condcode", {
                          id: e["ccnIDpk"],
                          name: e["ccnName"],
                        });
                      }}
                      handleClear={() => {
                        setState("condcode", { id: 0, name: "" });
                      }}
                      // key={
                      //   selectedRecord[0] === undefined
                      //     ? ""
                      //     : selectedRecord[0]?.ldtConductorCodeNameIdfk
                      // }
                      // defaultValue={
                      //   selectedRecord[0] === undefined
                      //     ? ""
                      //     : selectedRecord[0]?.ldtConductorCodeNameIdfk
                      // }
                      // className="W w-full mr-0 mb-0.5 py-0"
                      // placeholder=""
                      // onChange={(e: any) => {
                      //   setccnState(e);
                      // }}
                    />
                    {/* {ccn_data?.map((cnnd: any, index: number) => {
                        return (
                          <Option key={index} value={cnnd.ccnIdpk}>
                            {cnnd.ccnName}
                          </Option>
                        );
                      })}
                    </Select>

                    <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 0.6,
                        borderWidth: 1,
                        width: 25.2,
                      }}
                      className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Manufacturer"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      disabled={form_disable}
                      useCallFunc
                      idexpr={"mftIDpk"}
                      dataexp={"mftName"}
                      options={m_data}
                      placeHolder={states.manufacturer?.name}
                      selectedValue={(e) => {
                        setState("manufacturer", {
                          id: e["mftIDpk"],
                          name: e["mftName"],
                        });
                      }}
                      handleClear={() => {
                        setState("manufacturer", { id: 0, name: "" });
                      }}
                      // key={
                      //   selectedRecord[0] === undefined
                      //     ? ""
                      //     : selectedRecord[0]?.ldtManufacturerIdfk
                      // }
                      // defaultValue={
                      //   selectedRecord[0] === undefined
                      //     ? ""
                      //     : selectedRecord[0]?.ldtManufacturerIdfk
                      // }
                      // className="W w-full mr-0 mb-0.5 py-0"
                      // placeholder=""
                      // onChange={(e: any) => {
                      //   setmState(e);
                      // }}
                    />
                    {/* {m_data?.map((md: any, index: number) => {
                        return <Option value={md.mftIdpk}>{md.mftName}</Option>;
                      })}
                    </Select>

                    <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 0.6,
                        borderWidth: 1,
                        width: 25.2,
                      }}
                      className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
                  </div>
                </Form.Item>
              </Form.Item>
              <div className="invisible">
                <Form.Item
                  label={<p className="text-xs">{"Correction Symbol"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="receivedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <Select
                        className="W w-full mr-0 mb-0.5 py-0"
                        placeholder=""
                      >
                        <Option value="male">{"Control Room"}</Option>
                      </Select>

                      <span className="h-full w-4 ml-1 flex justify-center hover:cursor-pointer hover">
                        <img
                          className="w-full h-full flex items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>
              </div>
              <Form.Item
                label={<p className="text-xs">{"Length (km)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      defaultValue={states.length}
                      orderOnchange={(e) => {
                        setState("length", e);
                      }}
                      style={{ width: "100%" }}
                      span
                      // onChange={(e: any) => setLength(e.target.value)}
                      // style={{ marginBottom: 0 }}
                      // className=""
                      // placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Operating Voltage (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      defaultValue={states.voltage}
                      orderOnchange={(e) => {
                        setState("voltage", e);
                      }}
                      style={{ width: "100%" }}
                      span
                      // onChange={(e: any) => {
                      //   setOv(e.target.value);
                      // }}
                      // style={{ marginBottom: 0 }}
                      // className=""
                      // placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Sec. Area (sqmm)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      defaultValue={states.secArea}
                      orderOnchange={(e) => {
                        setState("secArea", e);
                      }}
                      style={{ width: "100%" }}
                      span
                      // onChange={(e: any) => setSecA(e.target.value)}
                      // style={{ marginBottom: 0 }}
                      // className=""
                      // placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>

            <Form
              name="complex-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              size={"small"}
              className="ml-2 my-2"
              style={{ width: "45%" }}
            >
              {/* <Form.Item label={<p className='text-xs'>{'Start Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} format={dateFormat} style={{ display: 'inline-block', width: 'calc(65%)' }} />


               </Form.Item>

               <Form.Item label={<p className='text-xs'>{'End Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} style={{ display: 'inline-block', width: 'calc(65%)' }} format={dateFormat} />


               </Form.Item> */}

              <Form.Item
                label={<p className="text-xs">{"Construction Start Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DateTemplate
                      disabled={form_disable}
                      selectedDate={dayjs(states.construction) ?? dayjs()}
                      changeDate={(date: Dayjs) => {
                        setState("construction", dayjs(date).format());
                      }}
                      // placeholder=""
                      // showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="w-full"
                      format={dateFormat}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Commissioning Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DateTemplate
                      disabled={form_disable}
                      selectedDate={dayjs(states.commissioning) ?? dayjs()}
                      changeDate={(date: Dayjs) => {
                        setState("commissioning", dayjs(date).format());
                      }}
                      // placeholder=""
                      // showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="w-full"
                      format={dateFormat}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Decommissioning Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DateTemplate
                      disabled={form_disable}
                      selectedDate={dayjs(states.decommissioning) ?? dayjs()}
                      changeDate={(date: Dayjs) => {
                        setState("decommissioning", dayjs(date).format());
                      }}
                      // placeholder=""
                      // showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="w-full"
                      format={dateFormat}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Manufacture Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DateTemplate
                      disabled={form_disable}
                      selectedDate={dayjs(states.manDate) ?? dayjs()}
                      changeDate={(date: Dayjs) => {
                        setState("manDate", dayjs(date).format());
                      }}
                      className="w-full"
                      format={dateFormat}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Supply Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DateTemplate
                      disabled={form_disable}
                      selectedDate={dayjs(states.supply) ?? dayjs()}
                      changeDate={(date: Dayjs) => {
                        setState("supply", dayjs(date).format());
                      }}
                      className="w-full"
                      format={dateFormat}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Bundled?"}</p>}
                style={{ marginBottom: 0 }}
              >
                <div className="flex justify-between ">
                  <Form.Item name="year" style={{ display: "inline-block" }}>
                    <div>
                      <CheckboxTemlate
                        defaultValue={states.bundled}
                        setCheckboxOnchange={(val) => {
                          setState("bundled", val);
                        }}
                        useCallbackFunc={true}
                        customDisabled={form_disable}
                        // withBorders={true}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Active?"}</p>}
                    name="month"
                    style={{ display: "inline-block" }}
                  >
                    <div>
                      <CheckboxTemlate
                        defaultValue={states.active}
                        setCheckboxOnchange={(val) => {
                          setState("active", val);
                        }}
                        useCallbackFunc={true}
                        customDisabled={form_disable}
                        // withBorders={true}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Plant Line?"}</p>}
                style={{ marginBottom: 0 }}
              >
                <div className="flex justify-between ">
                  <Form.Item name="year" style={{ display: "inline-block" }}>
                    <div>
                      <CheckboxTemlate
                        defaultValue={states.plant}
                        setCheckboxOnchange={(val) => {
                          setState("plant", val);
                        }}
                        useCallbackFunc={true}
                        customDisabled={form_disable}
                        // withBorders={true}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"SISR?"}</p>}
                    name="month"
                    style={{ display: "inline-block" }}
                  >
                    <div>
                      <CheckboxTemlate
                        defaultValue={states.sisr}
                        setCheckboxOnchange={(val) => {
                          setState("sisr", val);
                        }}
                        useCallbackFunc={true}
                        customDisabled={form_disable}
                        // withBorders={true}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", marginBottom: "2px" }}
                label={<p className="text-xs">{"Verified?"}</p>}
                className="w-full mb-1"
              >
                <div className=" items-end">
                  <div className="w-full flex  flex-row justify-between  ">
                    <div>
                      <CheckboxTemlate
                        defaultValue={states.verified}
                        setCheckboxOnchange={(val) => {
                          setState("verified", val);
                        }}
                        useCallbackFunc={true}
                        customDisabled={form_disable}
                        // withBorders={true}
                      />
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="flex  justify-end mt-20">
                {form_disable === false && update_state_change === false ? (
                  <SaveButton
                    handleSave={() => {
                      saveAccessPriv();
                    }}
                  />
                ) : update_state_change === true ? (
                  <UpdateButton
                    handleUpdate={() => {
                      setModalData({
                        message: "Are you sure you want to update this record?",
                        title: "Update Record",
                        okText: "Yes",
                        cancelText: "No",
                      });
                      setOpenModal(true);
                      validationForms();
                      dispatch(
                        setGeneralValue({ expr: "updateMode", value: true })
                      );
                      // updateAccesspriv();
                    }}
                  />
                ) : (
                  <SaveButton />
                )}

                {form_disable === true ? (
                  <NewButton
                    useCallbackFunc={true}
                    new_button_toggler={() => {
                      newAccessPriv();
                    }}
                  />
                ) : update_state_change === true ? (
                  <Cancel
                    // useCallbackFunc={true}
                    cancel_button_toggler={() => {}}
                  />
                ) : !(update_state_change && form_disable) ? (
                  <Cancel
                    // useCallbackFunc={true}
                    cancel_button_toggler={() => {}}
                  />
                ) : (
                  <NewButton
                    useCallbackFunc={true}
                    new_button_toggler={() => {
                      newAccessPriv();
                    }}
                  />
                )}
              </div>
            </Form>

            {/* <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 ml-2 "
              style={{ width: "45%" }}
            >
              <div className=" border-t-2 pb-2 ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Images
                </div>

                <div className="px-2 pb-2 flex items-end    h-64 w-full ">
                  <div className=" w-full h-60  border flex justify-center items-center">
                    No Image data
                  </div>
                </div>
                <div className="flex mx-2 mb-0.5">
                  {" "}
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "88%",
                    }}
                    className="o  py-1  overflow-y-scroll resize-none"
                    rows={1}
                  />
                  <button
                    title="save"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  mr-1 px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="save" className="pr-1" src={save} />
                  </button>
                  <button
                    title="newIcon"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="newIcon" className="pr-1" src={newIcon} />
                  </button>
                </div>
                <div className="flex">
                  <div
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "100%",
                    }}
                    className="o mx-2  border  flex "
                  >
                    <div className=" border-r p-0.5 text-xs px-2">1</div>
                    <div className=" border-r p-0.5 text-xs px-2">2</div>
                    <div className=" border-r p-0.5 text-xs px-2">3</div>
                    <div className=" border-r p-0.5 text-xs px-2">4</div>
                    <div className=" border-r p-0.5 text-xs px-2">5</div>
                    <div className=" border-r p-0.5 text-xs px-2">6</div>
                    <div className=" border-r p-0.5 text-xs px-2">7</div>
                    <div className=" border-r p-0.5 text-xs px-2">8</div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
        </div>
      }
    </div>
  );
};

export default BasicLineDetailsTab;
