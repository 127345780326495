/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { IconsContainer } from "../../../../StationReadings/_widget/stationReadingsToolbar";
import savesmall from "../../../../../../../assets/save-small.png";
import New161 from "../../../../../../../assets/New161.png";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { HSeperator } from "../../../utils";
import { navbar_height, toolbar_height, tab_bar_height } from "../../../../../../accessories/component_infos";
import useFetch from "../../../../../../../hooks/useFetch";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { GetDataFunc } from "../../../../../../../functions/get";
import { useDispatch, useSelector } from "react-redux";
import { setSearchParamOnChange } from "../../../../../../../features/apiCalls/elogbook";
import CustomLoader from "../../../../../../accessories/custom-loader";

const Comments_columns = [
  { id: 1, caption: 'DATE', dataField: 'lbcCreationDate', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 2, caption: 'NAME', dataField: 'empName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 3, caption: 'COMMENT', dataField: 'lbcComment', dataType: 'string', width: 100, fixed: false, visible: true },
];

interface IProps {
  mainGridDataLength: number;
  msgModal: any;
  setMsgModal: (v: any) => void;
  mainGridSelectedRecord: any;
  setTabSummaryCount: any;
}

interface IState {
  comment: { value: string, status: boolean };
  iomdComment: "i" | "";
  btnCmtSaveStatus: boolean;
  info: {
    lblCmtEmpName: string, lblCmtJobTitle: string, lblCmtDept: string,
    lblCmtLoc: string, lblCmtSection: string, lblCmtPlc: string
  };
  selectedComment: any;
}

export default function Comments({ mainGridDataLength, msgModal, setMsgModal, mainGridSelectedRecord, setTabSummaryCount }: IProps) {
  const [posting, updating] = useCrudFunc("powerapp");
  const dispatch = useDispatch();
  const tState = useSelector((state: any) => state.elogBook) ?? {};
  const [showGrid, setShowGrid] = React.useState(true);
  const [loading, setLoading] = useState({ status: false, msg: "" });
  const [state, setState] = React.useState<IState>({
    comment: { value: "", status: false },
    iomdComment: "",
    btnCmtSaveStatus: false,
    info: {
      lblCmtEmpName: "", lblCmtJobTitle: "", lblCmtDept: "",
      lblCmtLoc: "", lblCmtSection: "", lblCmtPlc: ""
    },
    selectedComment: {},
  });
  const [commentList, setCommentList] = React.useState<any[]>([]);
  const updateState = (key: string, value: any) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const heights_out = navbar_height + toolbar_height + tab_bar_height + 140;
  const form_height = 178;

  const [refresh, setRefresh] = React.useState(false);

  // const comments = useFetch(`ELogBook/GetComments?lbkIDpk=${mainGridSelectedRecord?.lbkIDpk}`, refresh, "", false, "powerapp")[0];

  const handleNewClicked = () => {
    updateState("btnCmtSaveStatus", true); // enable save button
    updateState("comment", { ...state.comment, value: "", status: true }); // enable text area
    updateState("iomdComment", 'i');
  }

  const checkIfCommentIsExist = async () => {
    const { data: r } = await GetDataFunc("ELogBook/CheckIfCommentExists",
      { lbkIDpk: mainGridSelectedRecord?.lbkIDpk, comment: state.comment.value }, "powerapp");
    return r?.data?.code === 200 ? r?.data?.objId : 0;
  }

  const handleSaveValidation = async () => {
    setLoading((prev) => ({ ...prev, status: true, msg: "Loading..." }));
    if (mainGridDataLength === 0) {
      setMsgModal({
        icon: "warning",
        title: "Invalid Action",
        message: "There is no log item to comment on",
        openModal: true,
        hideCancel: true,
        okText: "OK",
        cancelText: "",
        okHandler: () => {
          setMsgModal({ ...msgModal, openModal: false, hideCancel: true, });
        },
      });
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
      return;
    }

    if (state.iomdComment !== "i") {
      setMsgModal({
        icon: "warning",
        title: "Invalid Action",
        message: "Please click on the new button to initiate the new comment entry and try again",
        openModal: true,
        hideCancel: true,
        okText: "OK",
        cancelText: "",
        okHandler: () => {
          setMsgModal({ ...msgModal, openModal: false, hideCancel: true, });
        },
      });
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
      return;
    }

    if (state.comment.value === "") {
      setMsgModal({
        icon: "warning",
        title: "Invalid Action",
        message: "Please enter the comment and try again",
        openModal: true,
        hideCancel: true,
        okText: "OK",
        cancelText: "",
        okHandler: () => {
          setMsgModal({ ...msgModal, openModal: false, });
        },
      });
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
      return;
    }

    try {
      if ((await checkIfCommentIsExist()) === 0) {
        setMsgModal({
          icon: "question",
          title: "Save?",
          message: "Are you sure you want to save this comment?",
          openModal: true,
          hideCancel: false,
          okText: "Yes",
          cancelText: "No",
          okHandler: async () => {
            setMsgModal({ ...msgModal, openModal: false });
            await handleSave();
          },
          cancelHandler: () => {
            setMsgModal({ ...msgModal, openModal: false });
          },
        });
      } else {
        setMsgModal({
          icon: "warning",
          title: "Comment Exists",
          message: "You have entered this comment already. Please check the entries and try again",
          openModal: true,
          hideCancel: true,
          okText: "OK",
          cancelText: "",
          okHandler: () => setMsgModal({ ...msgModal, openModal: false }),
        });
      }
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
    } catch (error) {
      setMsgModal({
        icon: "warning",
        title: "Comment Exists",
        message: "You have entered this comment already. Please check the entries and try again",
        openModal: true,
        hideCancel: true,
        okText: "OK",
        cancelText: "",
        okHandler: () => setMsgModal({ ...msgModal, openModal: false }),
      });
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
    }
  }

  const handleSave = async () => {
    setLoading((prev) => ({ ...prev, status: true, msg: "Saving comment" }));
    try {
      const { data: r } = await posting("ELogBook/SaveComment",
        {
          lbkIDpk: mainGridSelectedRecord?.lbkIDpk,
          comment: state.comment.value,
          stationId: mainGridSelectedRecord?.lbkHomeStationIDfk
        },
      );
      if (r.code === 201) {
        setMsgModal({
          icon: "success",
          title: "Success",
          message: "Comment saved successfully",
          openModal: true,
          hideCancel: true,
          okText: "OK",
          cancelText: "",
          okHandler: () => {
            setMsgModal({ ...msgModal, openModal: false });
          },
        });
        dispatch(setSearchParamOnChange({ key: "refresh", value: { ...tState, refresh: !tState.refresh, } })); // refresh the main grid
        updateState("btnCmtSaveStatus", false); // disable save button
        updateState("comment", { ...state.comment, value: "", status: false }); // disable text area
        updateState("iomdComment", '');
        setRefresh(!refresh);
        setLoading((prev) => ({ ...prev, status: false, msg: "" }));
      } else {
        setMsgModal({
          icon: "warning",
          title: "Error",
          message: "An error occured. Please contact your Systems Administrator for assistance",
          openModal: true,
          hideCancel: true,
          okText: "OK",
          cancelText: "",
          okHandler: () => {
            setMsgModal({ ...msgModal, openModal: false });
          },
        });
        setLoading((prev) => ({ ...prev, status: false, msg: "" }));
      }
    } catch (e) {
      setMsgModal({
        icon: "warning",
        title: "Error",
        message: "An error occured. Please contact your Systems Administrator for assistance",
        openModal: true,
        hideCancel: true,
        okText: "OK",
        cancelText: "",
        okHandler: () => {
          setMsgModal({ ...msgModal, openModal: false });
        },
      });
    } 
    setLoading((prev) => ({ ...prev, status: false, msg: "" }));
  }

  const displayComment = (comment: any) => {
    updateState("comment", { ...state.comment, value: comment?.lbcComment ?? "" });
    updateState("info", {
      ...state.info, lblCmtEmpName: comment?.empName ?? "", lblCmtJobTitle: comment?.jbtShtName ?? "",
      lblCmtDept: comment?.dptShtName ?? "", lblCmtLoc: comment?.locShtName ?? "", lblCmtSection: comment?.sxnShtName ?? "", lblCmtPlc: comment?.empIntercom ?? ""
    });
  }

  const GetComments = async () => {
    const { data: r } = await GetDataFunc("ELogBook/GetComments", { lbkIDpk: mainGridSelectedRecord?.lbkIDpk }, "powerapp");
    setCommentList(r.data.map((c: any, _: number) => ({ ...c, id: _ })));
    updateState("comment", { ...state.comment, value: "" });
    setTabSummaryCount("Comments", r.data.length);
    updateState("info", { ...state.info, lblCmtEmpName: "", lblCmtJobTitle: "", lblCmtDept: "", lblCmtLoc: "", lblCmtSection: "", lblCmtPlc: "" });
  }

  useEffect(() => {
    GetComments();
  }, [mainGridSelectedRecord]);

  return (
    <main>
      {
        showGrid && (
          <section className="w-full h-auto">
            {loading.status && <CustomLoader text={`${loading.msg}...`} />}
            <div className=" px-2">
              <TextareaTemplate disabled={!state.comment.status} height={100} defaultValue={state.comment.value} placeholder=""
                setContent={(v: string) => { updateState("comment", { ...state.comment, value: v, status: true }) }} />
            </div>
            <div className=" ">
              <aside className=" flex justify-between items-center">
                <div className="ml-2 ">
                  <p className="truncate w-44">{state.info.lblCmtEmpName !== "" ? state.info.lblCmtEmpName : "*"}</p>
                  <p className="truncate w-44">{state.info.lblCmtJobTitle !== "" ? state.info.lblCmtJobTitle : "*"}</p>
                </div>
                <div className={`${state.btnCmtSaveStatus ? "cursor-pointer bg-white" : " cursor-not-allowed bg-gray-300 w-fit"}`}>
                  <IconsContainer url={savesmall} onClick={state.btnCmtSaveStatus ? handleSaveValidation : undefined} />
                </div>
              </aside>
              <aside className=" flex justify-between items-center ">
                <div className="ml-2">
                  <p className="truncate w-12">{state.info.lblCmtDept !== "" ? state.info.lblCmtDept : "*"}</p>
                  <p className="truncate w-12">{state.info.lblCmtLoc !== "" ? state.info.lblCmtLoc : "*"}</p>
                </div>
                <aside>
                  <p className="truncate w-12">{state.info.lblCmtSection !== "" ? state.info.lblCmtSection : "*"}</p>
                  <p className="truncate w-12">{state.info.lblCmtPlc !== "" ? state.info.lblCmtPlc : "*"}</p>
                </aside>
                <IconsContainer url={New161} onClick={handleNewClicked} />
              </aside>
            </div>
          </section>
        )
      }
      <HSeperator onClick={() => setShowGrid(!showGrid)} status={showGrid} />
      <Datagrid_template
        deselectFirstRow={true}
        columns={Comments_columns}
        data={commentList}
        disablePaging
        gridheight={showGrid ? window.innerHeight - heights_out - form_height : window.innerHeight - heights_out}
        // gridheight={showGrid ? heights_out : 448}
        disableSearch
        onRowClick={(row: any) => {
          updateState("selectedComment", row);
          displayComment(row);
        }}
      />
    </main>
  );
}


