/* eslint-disable react/jsx-pascal-case */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  navbar_height,
  toolbar_height,
} from "../../../accessories/component_infos";
import Datagrid_template from "../../../../templates/Datagrid";
import useFetch from "../../../../hooks/useFetch";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import dayjs from "dayjs";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useDispatch } from "react-redux";
import { FormModal } from "../../../accessories/formModal";
import { StationDetailsForm } from "./widgets/StationDetailsForm";
import { Passport_Picture } from "../../../human-resource/entries/passportPictures/passportPictures";
import { searchTextTypes, statusTypes, formModalStates } from "../../../human-resource/setups/data/types/selectsTypes";
import { station_details_Columns } from "./data/StationDatagridData";
import { setGeneralValue } from "../../../../features/generalSlice";
import { Tooltip } from "devextreme-react";

import printer from "../../../../assets/printer.png";
import { ModalTemplate } from "../../../../templates/modal";
import { useHeightResizer } from "../util/iHooks";

export const StationDetails = forwardRef(({ a }: any, ref) => {
  const dispatch = useDispatch();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const screenHeight = useHeightResizer();

  const miscHeight = 4; 
  //varying form height
  const form_height = 405;
  // on page resize height responsiveness
  useImperativeHandle(ref, () => ({
    test: () => { },
  }));


  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const setBusyLoader = useBusyLoader()[1];
  const [refresh, setRefresh] = useState(false);
  const [showForm, setShowForm] = useState(true);

  //new states
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(14, "year")
    .startOf("year")
    .format(genFormat);

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [criteria, setCriteria] = useState<searchTextTypes>({
    text: "0",
    temp: "0",
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });
  const [selectedData, setSelectedData] = useState<any>({});
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState(20)
  const [stationData, stationError, stationLoading] = useFetch(
    `StationDetails/station_details`,
    refresh, 'stnIDpk', false, "powerapp", false, {
    ...(searchText.text !== '' && { "SearchTerm": searchText.text }),
    ...(status.status !== '' && { "CheckState": status.status }),
    ...(criteria.text !== '0' && { "SearchCriteria": criteria.text }),
    ...({ "PageSize": pageSize })

  }
  );

  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const childRef = useRef<any>();

  useEffect(() => {
    stationLoading ? setBusyLoader(".") : setBusyLoader("");
  }, [stationLoading]);

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Passport Picture",
      module: <Passport_Picture state_change={false} />,
    },
  ];

  const [validationModalState, setvalidationModalState] = useState<any>({
    state: false,
    title: '',
    message: '',
  });
  const setValidationModal = (state: string, value: string | boolean) => {
    setvalidationModalState((warningState: any) => ({ ...warningState, [state]: value }));
  }



  return (
    <>
      {/* error handler */}
      {/* {staffError && <WarningAlert />} */}
      <ModalTemplate
        disableCancel={true}
        open={validationModalState.state}
        okHandler={() => setValidationModal("state", false)}
        cancelHandler={() => setValidationModal("state", false)}
        message={validationModalState.message}
        okText={"Ok"}
        title={validationModalState.title}
      />
      <FormModal
        height={400}
        width={"85%"}
        openState={openFormModal}
        onCancel={() => {
          setOpenFormModal(false);
        }}
        child={formModal[0].module}
        title={formModal[0].label}
      />

      <div style={{ height: pageHeight }} className="w-full px-2">
        {/*staff toolbar */}
        <div className="">
          <HrSetupsCustomToolbar
            withDates={false}
            searchCriteria={true}
            searchCriteriaData={[
              { id: 0, value: "Station" },
              { id: 1, value: "Location" },
              { id: 2, value: "Contact Person" }


            ]}
            outerSelected={selectedData}

            searchCriteriaOnchange={(val) => {
              setCriteria({ temp: `${val}`, text: criteria.text })

            }}
            handleRefresh={() => {
              setSearchText({ temp: "", text: "" })
              setStatus({ temp: "", status: "" })
              setCriteria({ temp: "0", text: "0" })
              setRefresh(current => !current)
            }}

            startDate={startDate.temp}
            endDate={endDate.temp}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            toggler={() => {
              setShowForm(!showForm)
            }}
            handleToggle={() => {
            }}
            handleToggleAll={() => {
            }}
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            handleFind={() => {

              setSearchText({ temp: searchText.temp, text: searchText.temp })
              setStatus({ temp: status.temp, status: status.temp })
              setCriteria({ temp: criteria.temp, text: criteria.temp })
              setRefresh(current => !current)
            }}
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            handleUpdate={() => {
              setShowForm(true)
              if (childRef.current) {
                childRef.current.doubleClick();

              }
              if (selectedData['stnIDpk'] === undefined) {
                // setvalidationModalState({
                //     state: true,
                //     title: "Error",
                //     message: "Please select a record",
                //   });
              }

            }}
            handleCancelButton={() => {
              if (childRef.current) {
                childRef.current.topNavCancel();
              }
            }}
            handleNew={() => {
              setSelectedData({});

              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              setShowForm(true);
              if (childRef.current) {
                childRef.current.topNavNew();
              }


            }}

            lastadditionalWidget={<li
              onClick={() => { }}
              id="printst"
              style={{ borderWidth: "1px", borderColor: colorTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />
              </span>
              <Tooltip
                target="#printst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Print/Export</p>
              </Tooltip>
            </li>}


          />
        </div>

        {/* form */}
        {(
          <div
            style={{
              height: showForm ? form_height : 0,
              display: showForm ? "block" : "none"

            }}
            className="form dark:text-darkModeSkin-base"
          >
            <StationDetailsForm
              clearSelected={() => setSelectedData({})}
              selectedField={selectedData}
              refreshGrid={() => setRefresh(current => !current)
              }
              ref={childRef}
              refreshState={() => setRefresh(current => !current)
              }
              setBusyLoader={setBusyLoader}
            />
          </div>
        )}

        {/* datagrid */}
        <div className="dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
          <Datagrid_template
            handlePageSize={(size) => {
              setPageSize(size)
              setRefresh(current => !current)
            }}
            columns={station_details_Columns}
            data={stationData}
            gridheight={showForm ? screenHeight - (form_height + miscHeight + 3) : screenHeight - (miscHeight + 3)}
            onRowClick={(selected) => {
              if (childRef.current) {
                childRef.current.singleClick(selected);
              }
              setSelectedData(selected);
              //disable Update Mode
              dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

              //disable forms
              dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
            }}
            rowDoubleClicked={() => {
              setShowForm(true)
              if (childRef.current) {
                childRef.current.doubleClick();
              }
              //disable Update Mode
              dispatch(setGeneralValue({ expr: 'updateMode', value: true }));

              //disable forms
              dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
            }} 
            pageNumber={pageSize}
            pageSize={pageSize}
            handlePageNumber={() => { }}
          />
        </div>
      </div>
    </>
  );
});  