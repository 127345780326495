/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { RefreshButton } from "../../../../StationReadings/_widget/utils";
import close from "../../../../../../../assets/close.png";
import { IconsContainer } from "../../../../StationReadings/_widget/stationReadingsToolbar";
import print from "../../../../../../../assets/printer.png";
import addNew from "../../../../../../../assets/addNew.png";
import { useCrudFunc } from "../../../../../../../functions/crud";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { navbar_height, tab_bar_height, toolbar_height } from "../../../../../../accessories/component_infos";
import { GetDataFunc } from "../../../../../../../functions/get";
import CustomLoader from "../../../../../../accessories/custom-loader";

const Attachments_columns = [
  { id: 1, caption: 'colFileName', dataField: 'atdFileName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 2, caption: 'colFormat', dataField: 'atdFormat', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 3, caption: 'colAttachPath', dataField: 'colAttachPath', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 4, caption: 'colAttachID', dataField: 'atdIDpk', dataType: 'string', width: 100, fixed: false, visible: true },
];

interface IProps {
  mainGridSelectedRecord: any;
  msgModal: any;
  setMsgModal: any;
  setTabSummaryCount: any;
}

export default function Attachments({ mainGridSelectedRecord, msgModal, setMsgModal, setTabSummaryCount }: IProps) {

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [post, update] = useCrudFunc("powerapp");
  const [attachements, setAttachements] = React.useState<any[]>([]);
  const [selectedDoc, setSelectedDoc] = React.useState<any>();
  const [loading, setLoading] = useState({ status: false, msg: "" });
  const count = React.useRef(0);

  const heights_out = navbar_height + toolbar_height + tab_bar_height + 165;

  const handleAddDocument = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const base64 = await fileToBase64(file);
      if (!checkIfAttachmentIsAlreadySelected(file.name)) {
        setAttachements((p) => [...p, { atdFileName: file.name, atdFormat: file.type.split("/")[1], colAttachPath: "", atdIDpk: "", base64: base64.split(",")[1] }]);
        count.current += 1;
        // setTabSummaryCount("Attachments", count.current);
      } else {
        setMsgModal({
          ...msgModal,
          icon: "warning",
          title: "Warning",
          message: "This document is already selected",
          openModal: true,
          hideCancel: true,
          okText: "Ok",
          okHandler: () => {
            setMsgModal((p: any) => ({ ...p, openModal: false }));
          },
        });
      }
    }
  }

  const checkIfAttachmentIsAlreadySelected = (fileName: string) => {
    return attachements.findIndex((doc) => doc.atdFileName === fileName) > -1;
  }

  const GetAttachments = async () => {
    const { data: r } = await GetDataFunc("ELogBook/GetAttachments", { twdIdpk: mainGridSelectedRecord.lbkIDpk }, "powerapp");
    if (r.code === 200) {
      setAttachements(r.data);
      count.current += (r.data?.length ?? 0);
    }
  }

  const handleSaveDocs = async () => {
    setLoading((prev) => ({ ...prev, status: true, msg: "Loading..." }));
    if (attachements.length > 0) {
      setMsgModal({
        ...msgModal,
        icon: "question",
        title: "Save Attachment",
        message: "Are you sure you want save the document list?",
        openModal: true,
        hideCancel: false,
        okText: "Yes",
        cancelText: "No, Cancel",
        okHandler: async () => {
          attachements.forEach(async (doc, i) => {
            setLoading((prev) => ({ ...prev, status: true, msg: "Saving document..." }));
            if (doc.atdIDpk === "") {
              const atdIDpk = await saveDoc(doc);
              attachements[i].atdIDpk = atdIDpk;
              setAttachements([...attachements]);
            }
          });
          setMsgModal({
            ...msgModal,
            icon: "success",
            title: "Success",
            message: "Documents saved successfully",
            openModal: true,
            hideCancel: true,
            okText: "Ok",
            okHandler: () => {
              setMsgModal((p: any) => ({ ...p, openModal: false }));
            },
          });
          await GetAttachments();
          setLoading((prev) => ({ ...prev, status: false, msg: "" }));
        },
        cancelHandler: () => {
          setMsgModal((p: any) => ({ ...p, openModal: false }));
        }
      });
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
    } else {
      setMsgModal({
        ...msgModal,
        icon: "warning",
        title: "Attach Document",
        message: "There is no attachment to save.",
        openModal: true,
        hideCancel: true,
        okText: "Ok",
        okHandler: () => {
          setMsgModal((p: any) => ({ ...p, openModal: false }));
        },
      });
    }
  }

  const saveDoc = async ({ base64, atdFileName, atdFormat }) => {
    try {
      const { data: r } = await post("ELogBook/addAttachedDocs",
        {
          atdMailIDfk: mainGridSelectedRecord.lbkIDpk,
          atdDoc: base64,
          atdFileName: atdFileName,
          atdFormat: atdFormat,
        });
      if (r.code === 201) {
        return `${r.data.atdIDpk}`;
      }
      return "";
    } catch (error) {
      setMsgModal({
        icon: "warning",
        title: "Error",
        message: "An error occured whilst saving the document",
        openModal: true,
        hideCancel: true,
        okText: "Ok",
        okHandler: () => {
          setMsgModal((p: any) => ({ ...p, openModal: false }));
        },
      });
    }
  }

  const refreshDocs = () => {
    setMsgModal({
      ...msgModal,
      icon: "question",
      title: "Refresh?",
      message: "This action will clear all unsaved attachments. Are you sure you want to continue and refresh the files list?",
      openModal: true,
      hideCancel: false,
      okText: "Yes",
      cancelText: "No, Cancel",
      okHandler: async () => {
        setLoading((prev) => ({ ...prev, status: true, msg: "Refreshing document..." }));
        await GetAttachments();
        setMsgModal((p: any) => ({ ...p, openModal: false }));
        setLoading((prev) => ({ ...prev, status: false, msg: "" }));
      },
      cancelHandler: () => {
        setMsgModal((p: any) => ({ ...p, openModal: false }));
      }
    });
  }

  const handleDeleteDoc = async () => {
    setLoading((prev) => ({ ...prev, status: true, msg: "Loading..." }));
    if (selectedDoc?.atdIDpk !== "") {
      setMsgModal({
        ...msgModal,
        icon: "question",
        title: "Delete Attachment",
        message: "Are you sure you want to delete this attachment?",
        openModal: true,
        hideCancel: false,
        okText: "Yes",
        cancelText: "No, Cancel",
        okHandler: async () => {
          setLoading((prev) => ({ ...prev, status: true, msg: "Deleting attachment..." }));
          await deleteDoc(selectedDoc);
          await GetAttachments();
          setMsgModal({
            ...msgModal,
            icon: "success",
            title: "Success",
            message: "Document deleted successfully",
            openModal: true,
            hideCancel: true,
            okText: "Ok",
            okHandler: () => {
              setMsgModal((p: any) => ({ ...p, openModal: false }));
            },
          });
          setMsgModal((p: any) => ({ ...p, openModal: false }));
          setLoading((prev) => ({ ...prev, status: false, msg: "" }));
        },
        cancelHandler: () => {
          setMsgModal((p: any) => ({ ...p, openModal: false }));
        }
      });
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
    } else {
      // setAttachements((p) => p.filter((d) => d.atdIDpk !== selectedDoc.atdIDpk));
      await GetAttachments();
      setLoading((prev) => ({ ...prev, status: false, msg: "" }));
    }
  }

  const deleteDoc = async (doc: any) => {
    try {
      await update("ELogBook/DeleteDocument", { atdIDpk: doc.atdIDpk }, `Delete document ${doc.atdFileName}`);
      setMsgModal({
        icon: "success",
        title: "Success",
        message: "File deleted successfully",
        openModal: true,
        hideCancel: true,
        okText: "Ok",
        okHandler: () => {
          setMsgModal((p: any) => ({ ...p, openModal: false }));
        },
      });
    } catch (error) {
      setMsgModal({
        icon: "warning",
        title: "Error",
        message: "An error occured whilst deleting the document",
        openModal: true,
        hideCancel: true,
        okText: "Ok",
        okHandler: () => {
          setMsgModal((p: any) => ({ ...p, openModal: false }));
        },
      });
    }
  }

  useEffect(() => {
    GetAttachments();
  }, [mainGridSelectedRecord]);

  const handleCellRender = (e: any) => {
    if (e.column.dataField === "atdFileName") {
      e.cellElement.style.backgroundColor = "#F0E68C";
    }
  };

  return (
    <main className="flex flex-col h-[69vh] w-full ">
      {loading.status && <CustomLoader text={`${loading.msg}...`} />}
      <section className=" flex-1 border-[1px] h-full w-full">
        <div className="w-full h-auto border-[1px] flex justify-around items-center">
          <p className="">File Name</p>
          <aside className="flex">
            <span className="w-8  ">
              <IconsContainer url={addNew} onClick={handleAddDocument} />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </span>
            <IconsContainer url={print} onClick={handleSaveDocs} />
          </aside>
          <aside className="flex space-x-2 items-center">
            <RefreshButton onClick={refreshDocs} />
            <IconsContainer url={close} onClick={handleDeleteDoc} />
          </aside>
        </div>
        <Datagrid_template
          dataId="atdFileName"
          deselectFirstRow={true}
          columns={Attachments_columns}
          rowAlternationEnabled={false}
          data={attachements}
          disablePaging
          disableGroupPanel={true}
          showColumnHeaders={false}
          gridheight={window.innerHeight - heights_out}
          disableSearch={true}
          onRowClick={(row: any) => {
            setSelectedDoc(row);
          }}
          cellColoringFxn={handleCellRender}
        />
      </section>
    </main>
  );
}
