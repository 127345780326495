/* eslint-disable react/jsx-pascal-case */
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { Form, Image, DatePicker } from "antd";
import reset from "../../../../../../../assets/reset.png";

import Checkbox from "antd/es/checkbox/Checkbox";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
// import { SelectsTemplate } from "../../../../../../../templates/select";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { InputsTemplate } from "../../../../../../../templates/input";
import dayjs, { Dayjs } from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { GetDataFunc } from "../../../../../../../functions/get";
import {
  dispensedBy_table,
  vehicle_capture_reg_no,
} from "../data/vehicle_reg_data";
import invalidImage from "../../../../../../../assets/none.jpg";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { DateTemplate } from "../../../../../../../templates/date";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import {
  Fetch_CostCenter,
  Fetch_Department,
  Fetch_Product,
  Fetch_Sections,
  Fetch_ServiceProvider,
  Fetch_ServiceStation,
  Fetch_UnitOfMeasure,
} from "../../../../../../../features/apiCalls/BacklogFuelEntries";
import React from "react";
import { useAxiosService } from "../../../../../../../general/requestConfig";

interface prop {
  refreshState?: () => void;
  setOuterSelect?: any;
  setSelected_Record?: (val: any) => void;
  a?: any;
}
export interface LookupResponseType {
  serviceProviders: ServiceProvider[];
  serviceStations: ServiceStation[];
  products: any[];
  unitsOfMeasure: any[];
  dispensedBy: EdBy[];
  recievedBy: EdBy[];
  authorisedBy: EdBy[];
  department: Department[];
  sections: Section[];
  costCenters: CostCenter[];
}

export interface EdBy {
  empIdpk: number;
  empStaffNo: string;
  empName: string;
  dptShtName: string;
  sxnShtName: string;
  locShtName: string;
  jbtShtName: string;
}
export interface CostCenter {
  fcctIdpk: number;
  fcctName: string;
  fcctCode: string;
}

export interface Department {
  dptIdpk: number;
  dptShtName: string;
}

export interface Section {
  sxnIdpk: number;
  sxnShtName: string;
}

export interface ServiceProvider {
  spdIDpk: number;
  spdName: string;
}

export interface ServiceStation {
  sstIdpk: number;
  sstName: string;
  twnName: string;
}

export const Fuel_Backlog_Details_Form = forwardRef(
  ({ refreshState }: prop, ref) => {
    const options = [];
    for (let i = 1; i < 36; i++) {
      options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
      });
    }

    const dateFormat = " DD MMM YYYY HH: mm:ss";
    const [ShowImages, setShowImages] = useState<any>(false);

    const [formData, setFormData] = useState<any>({
      selectedId: 0,
      requestDate: "",
      vehicleRegNo: { name: "", id: "" },
      serviceProvider: { name: "", id: "" },
      serviceStation: { name: "", id: "" },
      loadImage: { name: "", id: "" },
      product: { name: "", id: "" },
      quantityRequested: 0,
      quantityAuthorized: 0,
      quantityDispensed: 0,
      uom: { name: "", id: "" },
      unitPrice: 0,
      dumpAmount: 0,
      receiptNumber: "",
      dispensedBy: { name: "", id: "" },
      authorisedBy: { name: "", id: "" },
      receivedBy: { name: "", id: "" },
      department: { name: "", id: "" },
      section: { name: "", id: "" },
      costCenter: { name: "", id: "" },
      previousOdomReading: "",
      currentOdomReading: "",
      active: false,
      updateMode: false,
      disabled: true,
      loadImageActive: false,
      remarks: "",
      refresh: false,

      /**
       * By Icanhazip
       */
      isOpenDropdown1: false,
      isOpenDropdown2: false,
      isOpenDropdown3: false,
      isOpenDropdown4: false,
      isOpenDropdown5: false,
      isOpenDropdown6: false,
      isOpenDropdown7: false,

      serviceProviderRefresh: false,
      serviceStationRefresh: false,
      productRefresh: false,
      uomRefresh: false,
      departmentRefresh: false,
      sectionRefresh: false,
      costCenterRefresh: false,

      isOpenDropdownKey1: "isOpenDropdown1",
      isOpenDropdownKey2: "isOpenDropdown2",
      isOpenDropdownKey3: "isOpenDropdown3",
      isOpenDropdownKey4: "isOpenDropdown4",
      isOpenDropdownKey5: "isOpenDropdown5",
      isOpenDropdownKey6: "isOpenDropdown6",
      isOpenDropdownKey7: "isOpenDropdown7",
    });
    const ServiceProviderData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.service_provider
    );
    const ServiceStationData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.service_station
    );
    const ProductData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.product
    );
    const UnitOfMeasureData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.unit_of_measure
    );
    const DepartmentData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.department
    );
    const SectionData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.sections
    );
    const CostCenterData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.cost_centers
    );

    const setState = (key: string, value: any) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
    };
    const [posting, updating] = useCrudFunc();
    const axiosGo = useAxiosService();
    const [lookup_data, setLookup] = React.useState<LookupResponseType>();
    const [loading, setLoading] = React.useState<boolean>();
    const [Submittable, setSubmittable] = React.useState<{
      serviceProvider: number;
      serviceStation: number;
      product: number;
    }>({
      serviceProvider: 1,
      serviceStation: 1,
      product: 1,
    });

    console.log("SUBMIT STATES: ", Submittable);

    React.useEffect(() => {
      (() => {
        setLoading(true);
        axiosGo({
          url: `AsmTmsCaptureOldFuelRecords/GetLookUps`,
          params: {
            VasStartDate: "2001-02-02",
            SpdIDpk: 1,
            SpdIDProd: 1,
            DptIDSection: 1,
            DptIDCost: 1,
            SxnIDCost: 1,
            pagesize: 20,
            pagenumber: 1,
          },
          method: "GET",
        }).then((res) => {
          setLookup(res);
        });
        setLoading(false);
      })();
    }, []);
    const [regNoData] = useFetch(
      "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos"
    );
    // const [serviceProviderData, serviceProviderError, serviceProviderLoading] =
    //   useFetch("AsmTmsCaptureOldFuelRecords/GetAllServiceProviders");
    // const [serviceStationsData, setServiceStations] = useState<any>([]);
    // // products data only work with provider data of id 1.
    // const [productsData] = useFetch(
    //   // `AsmTmsCaptureOldFuelRecords/GetAllProducts?SpdIDpk=${formData.serviceProvider.id}`
    //   `FuelManagement/dumpAllProducts`
    // );
    const [loadImg] = useFetch(
      `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${formData.selectedId}&vimActive=true`
    );
    const [recipientsData] = useFetch(
      "AsmTmsCaptureOldFuelRecords/GetAllRecipients"
    );
    const [authorisersData] = useFetch(
      "AsmTmsCaptureOldFuelRecords/GetAllAuthorisers"
    );
    const [dispensorsData] = useFetch(
      "AsmTmsCaptureOldFuelRecords/GetAllDispensors"
    );

    const [unitPrice] = useFetch(
      `AsmTmsCaptureOldFuelRecords/GetUnitPrice?SpdIDpk=${formData.serviceProvider.id}&PprIDpK=${formData.product.id}`
    );
    let Unit_Price_Value = unitPrice?.map(({ pprUnitPrice, funIDpk }: any) => ({
      pprUnitPrice,
      funIDpk,
    }));

    // const [departmentData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllDepartments"
    // );
    // const [sectionData, setSectionData] = useState<any>([]);
    // const [costCenterData, setCostCenterData] = useState<any>([]);
    // const [uomData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllFuelUnitsOfMeasure"
    // );

    useImperativeHandle(ref, () => ({
      singleClick: (e: any) => {
        if (e !== undefined) {
          dispatch(setGeneralValue({ expr: "selectedFieldData", value: e }));
          dispatch(task_footer_table_selected(e));

          setFormData({
            ...formData,
            selectedId: e.fmgIDpk,
            requestDate: e.fmgDate,
            vehicleRegNo: { name: e.vdtRegNo, id: e.vdtIDpk },
            serviceProvider: { name: e.spdName, id: e.spdIDpk },
            serviceStation: { name: e.sstName, id: e.sstIDpk },
            product: { name: e.prdName, id: e.prdIDpk },
            quantityRequested: e.fmgQuantityRequested,
            quantityAuthorized: e.fmgQuantityAuthorised,
            quantityDispensed: e.fmgQuantityDispensed,
            uom: { name: "", id: "" },
            unitPrice: e.unitPrice,
            dumpAmount: "",
            receiptNumber: e.fmgReceiptNo,
            dispensedBy: { name: e.dispenserEmpName, id: e.dispenserEmpID },
            authorisedBy: { name: e.authoriserEmpName, id: e.authoriserEmpID },
            receivedBy: { name: e.driverName, id: e.driverEmpID },
            department: { name: e.vehAssignDeptName, id: e.vehAssignDeptID },
            section: { name: e.vehAssignSxnName, id: e.vehAssignSxnID },
            costCenter: { name: e.cctName, id: e.fmgCostCenterIDfk },
            previousOdomReading: e.fmgPreviousOdometerReading,
            currentOdomReading: e.fmgCurrentOdometerReading,
            active: e.fmgActive,
            remarks: e.fmgRmks,
            updateMode: false,
            disabled: true,
          });
        }
      },
      doubleClick: () => {
        editAccessPriv();
      },
    }));

    const fetchData = async (route: string, type: string) => {
      try {
        // get data
        const res = await GetDataFunc(`${route}`);
        // type === "serviceStations"
        //   ? setServiceStations(res.data.reverse().slice(0, 100))
        //   : type === "sections"
        //   ? setSectionData(res.data.reverse().slice(0, 100))
        //   : setCostCenterData(res.data.reverse().slice(0, 100));
      } catch (error: any) {
      } finally {
      }
    };

    const [modalInfo, setModalInfo] = useState<any>({
      icon: "question",
      data: saveModalData,
      disableCancel: false,
      openModal: false,
    });

    const clearFields = () => {
      setFormData({
        ...formData,
        selectedId: "",
        requestDate: "",
        vehicleRegNo: { name: "", id: "" },
        serviceProvider: { name: "", id: "" },
        serviceStation: { name: "", id: "" },
        product: { name: "", id: "" },
        quantityRequested: "",
        quantityAuthorized: "",
        quantityDispensed: "",
        uom: { name: "", id: "" },
        unitPrice: "",
        dumpAmount: "",
        receiptNumber: "",
        dispensedBy: { name: "", id: "" },
        authorisedBy: { name: "", id: "" },
        receivedBy: { name: "", id: "" },
        department: { name: "", id: "" },
        section: { name: "", id: "" },
        costCenter: { name: "", id: "" },
        previousOdomReading: "",
        currentOdomReading: "",
        active: false,
        updateMode: false,
        disabled: false,
        loadImage: false,
        remarks: "",
      });
    };

    const updateState = (stateName: string, value: any) => {
      setFormData((currentState: any) => ({
        ...currentState,
        [stateName]: value,
      }));
    };

    const disablex = useSelector(
      (state: RootState) => state.general.formDisabled
    );
    const updateMode = useSelector(
      (state: RootState) => state.general.updateMode
    );
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const dispatch = useDispatch();

    const validationForms = (method: "post" | "update") => {
      if (formData.vehicleRegNo.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select vehicle registration number",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (
        Unit_Price_Value[0]?.pprUnitPrice === "" ||
        Unit_Price_Value[0]?.pprUnitPrice === undefined
      ) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please make sure Unit Price is given",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.serviceProvider.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select service Provider Name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.serviceStation.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select vehicle service Station ",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.product.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select product name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.uom === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide Quantity UoM",
          },
          disableCancel: true,
          openModal: true,
        });

        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide Amount",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.dispensedBy.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Dispensed By",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.authorisedBy.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Authorised By name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.receivedBy.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select received by name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (
        formData.department.id === "" ||
        formData.department.id === 0
      ) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select a department",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.previousOdomReading === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide Pre. Odom Reading ",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.currentOdomReading === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide current Odom Reading",
          },
          disableCancel: true,
          openModal: true,
        });
      } else {
        if (method === "post") {
          setModalInfo({
            icon: "question",
            data: saveModalData,
            disableCancel: false,
            openModal: true,
          });
        } else {
          setModalInfo({
            icon: "question",
            data: updateModalData,
            disableCancel: false,
            openModal: true,
          });
        }
      }
    };

    const saveData = async () => {
      await posting(
        `AsmTmsCaptureOldFuelRecords/CreateAsmTmsCaptureOldFuelRecord`,
        {
          fmgDate: formData.requestDate,
          fmgDateAuthorised: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgDateDispensed: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgVehicleDetailIDfk: formData.vehicleRegNo.id,
          fmgDriverIDfk: formData.receivedBy.id,
          fmgServiceProviderIDfk: formData.serviceProvider.id,
          fmgServiceStationIDfk: formData.serviceStation.id,
          fmgProductIDfk: formData.product.id,
          fmgAuthoriserEmpIDfk: formData.authorisedBy.id,
          fmgDispenserEmpIDfk: formData.dispensedBy.id,
          fmgFuelUnitIDfk: Unit_Price_Value[0]?.funIDpk,
          fmgAmount: Number(formData.dumpAmount),
          fmgQuantityRequested: formData.quantityRequested,
          fmgQuantityAuthorised: formData.quantityAuthorized,
          fmgQuantityDispensed: formData.quantityDispensed,
          fmgCurrentOdometerReading: formData.currentOdomReading,
          fmgPreviousOdometerReading: formData.previousOdomReading,
          fmgReceiptNo: formData.receiptNumber,
          fmgVehicleAssignmentIDfk: formData.department.id,
          fmgAuthorised: true,
          fmgRmks: formData.remarks,
          formCode: "string",
        },
        `Created the backlog details successfully`
      )
        .then((response) => {
          setModalInfo({
            icon: "success",
            data: {
              title: "Success",
              message: "Record saved successfully",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
          refreshState && refreshState();
        })
        .catch((err) => {
          setModalInfo({
            icon: "error",
            data: {
              title: "Unexpected error!",
              message:
                "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
        });
    };

    const updateData = async () => {
      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => {},
      });

      await updating(
        `AsmTmsCaptureOldFuelRecords/UpdateAsmTmsCaptureOldFuelRecords/${formData.selectedId}`,
        {
          fmgDate: formData.requestDate,
          fmgDateAuthorised: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgDateDispensed: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgVehicleDetailIDfk: formData.vehicleRegNo.id,
          fmgDriverIDfk: formData.receivedBy.id,
          fmgServiceProviderIDfk: formData.serviceProvider.id,
          fmgServiceStationIDfk: formData.serviceStation.id,
          fmgProductIDfk: formData.product.id,
          fmgAuthoriserEmpIDfk: formData.authorisedBy.id,
          fmgDispenserEmpIDfk: formData.dispensedBy.id,
          fmgPurchaseModeIDfk: 2,
          fmgCardIDfk: 1,
          fmgFuelUnitIDfk: Unit_Price_Value[0]?.funIDpk,
          fmgAmount: Number(formData.dumpAmount),
          fmgBalance: 0,
          fmgQuantityRequested: formData.quantityRequested,
          fmgQuantityAuthorised: formData.quantityAuthorized,
          fmgQuantityDispensed: formData.quantityDispensed,
          fmgCurrentOdometerReading: formData.currentOdomReading,
          fmgPreviousOdometerReading: formData.previousOdomReading,
          fmgReceiptNo: formData.receiptNumber,
          fmgVehicleAssignmentIDfk: formData.department.id,
          fmgAuthorised: true,
          fmgRmks: formData.remarks,
          formCode: "string",
        },
        `Updated backlog details successfully`
      )
        .then((response) => {
          setModalInfo({
            icon: "success",
            data: {
              title: "Success",
              message: "Record updated successfully",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
          refreshState && refreshState();
        })
        .catch((err) => {
          setModalInfo({
            icon: "error",
            data: {
              title: "Unexpected error!",
              message:
                "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
        });
    };

    const handleInputChange = (field: String, value: any) => {
      // Update the common value and propagate it to other fields
      setFormData({
        ...formData,

        quantityAuthorized: value,
        quantityRequested: value,
        quantityDispensed: value,
      });
    };

    const unitInputChange = (field: string, value: any) => {
      setFormData({
        ...formData,
        [field]: value,
      });
    };

    const [privtype, setPrivType] = useState<string>("save");
    const [savePriv] = useFormPriviledge(privtype);

    const newAccesPriv = () => {
      setPrivType("save");
      if (savePriv === true) {
        setFormData({ ...formData, disabled: false });
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        clearFields();
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const updateAccessPriv = () => {
      setPrivType("post");
      if (savePriv === true) {
        validationForms("update");
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const saveAccessPriv = () => {
      setPrivType("save");

      if (savePriv === true) {
        validationForms("post");
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const editAccessPriv = () => {
      setPrivType("update");

      if (savePriv === true) {
        dispatch(task_footer_update(true));
        setFormData({
          ...formData,
          updateMode: true,
          disabled: false,
        });
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    useEffect(() => {
      if (!disablex) {
        dispatch(task_footer_table_selected({}));

        dispatch(task_footer_update(false));
        setFormData({ ...formData, disabled: false });
        clearFields();
        dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
        dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode
      } else {
        dispatch(task_form_disable(true));
        dispatch(task_footer_update(false));
        setFormData({
          ...formData,
          updateMode: false,
          disabled: true,
        });
      }
      if (updateMode) {
        dispatch(task_footer_update(true));
        setFormData({
          ...formData,
          updateMode: true,
          disabled: false,
        });
      }
    }, [disablex, updateMode]);

    console.log("TESTING DATA AGAIN", lookup_data);

    return (
      <div
        style={{ width: "100%", height: "100%", borderColor: borderTheme }}
        className="  border-[1px] border-t-0 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  pr-2"
      >
        <ModalTemplate
          disableCancel={modalInfo.disableCancel}
          icon={modalInfo.icon}
          title={modalInfo.data.title}
          okText={modalInfo.data.okText}
          message={modalInfo.data.message}
          cancelText={modalInfo.data.cancelText}
          open={modalInfo.openModal}
          okHandler={() => {
            modalInfo.data.title === "Update Record?"
              ? updateData()
              : modalInfo.data.title === "Save Record?"
              ? saveData()
              : setModalInfo({ ...modalInfo, openModal: false });
          }}
          cancelHandler={() => {
            setModalInfo({ ...modalInfo, openModal: false });
          }}
        />{" "}
        <div
          style={{ maxHeight: "160px", minHeight: "160px", height: "160px" }}
          className="items-start justify-start "
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 pl-2 "
            name="basic"
            labelCol={{ span: 6 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div style={{ width: "1400px" }} className=" flex ">
              <div style={{ width: "600px" }} className="">
                {" "}
                <Form.Item
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {"Request Date"}
                    </p>
                  }
                  labelCol={{ span: 7 }}
                >
                  <div className="w-full flex flex-row justify-between  ">
                    <DateTemplate
                      format={dateFormat}
                      disabled={formData.disabled}
                      selectedDate={
                        formData.requestDate
                          ? dayjs(formData.requestDate).isValid()
                            ? dayjs(formData.requestDate)
                            : dayjs()
                          : dayjs()
                      }
                      changeDate={(e: Dayjs) => {
                        setFormData({ ...formData, requestDate: e });
                      }}
                    />

                    <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover"></span>
                  </div>
                </Form.Item>
                <Form.Item style={{ width: "100%", marginBottom: 2.5 }}>
                  <Form.Item
                    name="issueBy"
                    style={{
                      display: "inline-block",
                      marginLeft: "82px",
                      width: "100%",
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <div className="">
                        <DropdownGrid_template
                          labelCol={25}
                          label={"Vehicle Reg No"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 348 }}
                          isHide={true}
                          defaultValue={formData.vehicleRegNo?.name}
                          disabled={formData.disabled}
                          columns={vehicle_capture_reg_no}
                          gridData={regNoData}
                          onRowClick={(e: any) => {
                            updateState("vehicleRegNo", {
                              id: e.vdtIdpk,
                              name: e.vdtRegNo,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            vehicleRegNo: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>
                <InputSelect_template
                  useCallFunc
                  label="Service Provider"
                  isOpenDropdown={formData.isOpenDropdown1}
                  handleFocus={(e: any) => {
                    updateState(`isOpenDropdown1`, !formData.isOpenDropdown1);
                  }}
                  style={{ width: "83.5%", marginLeft: "8.3%" }}
                  options={
                    formData.serviceProvider?.length > 0
                      ? ServiceProviderData
                      : lookup_data?.serviceProviders
                  }
                  handleRefresh={() => {
                    updateState("serviceProvider"!, "");
                    updateState(
                      "serviceProviderRefresh",
                      !formData.serviceProviderRefresh
                    );
                  }}
                  handleSearch={useCallback(
                    debounce((e) => {
                      setState(`serviceProvider`!, e);
                      setState("isLoading", true);
                      dispatch(Fetch_ServiceProvider(e) as unknown as any);

                      setState(`isOpenDropdown1`, !formData.isOpenDropdown1);
                    }, 500), // Adjust the debounce delay as needed
                    []
                  )}
                  dataexp="spdName"
                  idexpr="spdIDpk"
                  selectedValue={(e: any) => {
                    console.log("INFORMATION: ", e);
                    setSubmittable((state) => ({
                      ...state,
                      serviceProvider: e.spdIDpk,
                    }));
                    setState("serviceProvider", {
                      name: e.spdName,
                      id: e.spdIDpk,
                    });
                  }}
                  placeHolder={formData.serviceProvider.name}
                  disabled={formData.disabled}
                />
                <InputSelect_template
                  label="Service Station"
                  style={{ width: "83.5%", marginLeft: "8.3%" }}
                  isOpenDropdown={formData.isOpenDropdown2}
                  handleFocus={(e: any) => {
                    setState(`isOpenDropdown2`, !formData.isOpenDropdown2);
                  }}
                  options={
                    formData.serviceStation?.length > 0
                      ? ServiceStationData
                      : lookup_data?.serviceStations
                  }
                  dataexp="sstName"
                  idexpr="sstIdpk"
                  selectedValue={(e: any) => {
                    setSubmittable((state) => ({
                      ...state,
                      serviceStation: e.sstIdpk,
                    }));
                    setState("serviceStation", {
                      name: e.sstName,
                      id: e.sstIdpk,
                    });
                  }}
                  handleRefresh={() => {
                    setState("serviceStation"!, "");
                    setState(
                      "serviceStationRefresh",
                      !formData.serviceStationRefresh
                    );
                  }}
                  handleSearch={useCallback(
                    debounce((e: string) => {
                      setState(`serviceStation`!, e);
                      console.log("FORM STATE: ", formData);
                      console.log("SUBMITTABLE: ", Submittable);
                      setState("isLoading", true);
                      dispatch(
                        Fetch_ServiceStation({
                          search: e,
                          SpdIDpk: Submittable.serviceProvider,
                        }) as unknown as any
                      );

                      updateState(`isOpenDropdown2`, !formData.isOpenDropdown2);
                    }, 500), // Adjust the debounce delay as needed
                    []
                  )}
                  placeHolder={formData.serviceStation.name}
                  useCallFunc={true}
                  disabled={formData.disabled}
                />
                <InputSelect_template
                  label="Product"
                  isOpenDropdown={formData.isOpenDropdown3}
                  handleFocus={(e: any) => {
                    updateState(`isOpenDropdown3`, !formData.isOpenDropdown3);
                  }}
                  style={{ width: "83.5%", marginLeft: "8.3%" }}
                  options={
                    formData.product?.length > 0
                      ? ProductData
                      : lookup_data?.products
                  }
                  handleSearch={useCallback(
                    debounce((e: string) => {
                      updateState(`product`!, e);
                      updateState("isLoading", true);
                      dispatch(
                        Fetch_Product({
                          search: e,
                          SpdIDProd: formData.serviceStation.id,
                        }) as unknown as any
                      );

                      updateState(`isOpenDropdown3`, !formData.isOpenDropdown3);
                    }, 500), // Adjust the debounce delay as needed
                    []
                  )}
                  dataexp="prdName"
                  idexpr="prdIdpk"
                  selectedValue={(e) => {
                    updateState("product", { name: e.prdName, id: e.prdIdpk });
                  }}
                  placeHolder={formData.product.name}
                  useCallFunc={true}
                  disabled={formData.disabled}
                />
                <ul className=" flex flex-col">
                  <div className="  w-full  justify-between ">
                    <li
                      style={{
                        width: "calc(600px - 20px)",
                        marginLeft: "84px",
                      }}
                      className=" flex items-center flex-row "
                    >
                      <p className=" mr-2 text-xs">Quantity/UoM : </p>
                      <div className="flex justify-evenly h-5">
                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          span
                          defaultValue={formData.quantityRequested}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            handleInputChange("quantityRequested", e);
                          }}
                        />

                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          span
                          defaultValue={formData.quantityAuthorized}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            handleInputChange("quantityAuthorized", e);
                          }}
                        />

                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          span
                          defaultValue={formData.quantityDispensed}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            handleInputChange("quantityDispensed", e);
                          }}
                        />

                        <InputSelect_template
                          style={{ width: "149px" }}
                          options={
                            formData.uom?.length > 0
                              ? UnitOfMeasureData
                              : lookup_data?.unitsOfMeasure
                          }
                          handleFocus={(e: any) => {
                            updateState(
                              `isOpenDropdown4`,
                              !formData.isOpenDropdown4
                            );
                          }}
                          handleRefresh={() => {
                            updateState("uom"!, "");
                            updateState("uomRefresh", !formData.uomRefresh);
                          }}
                          handleSearch={useCallback(
                            debounce((e) => {
                              updateState(`uom`!, e);
                              updateState("isLoading", true);
                              dispatch(
                                Fetch_UnitOfMeasure(e) as unknown as any
                              );

                              updateState(
                                `isOpenDropdown4`,
                                !formData.isOpenDropdown4
                              );
                            }, 500), // Adjust the debounce delay as needed
                            []
                          )}
                          dataexp="funShtName"
                          idexpr="funIdpk"
                          selectedValue={(e: any) => {
                            updateState("uom", {
                              name: e.funShtName,
                              id: e.funIdpk,
                            });
                          }}
                          placeHolder={formData.uom.name}
                          useCallFunc={true}
                          disabled={formData.disabled}
                        />
                      </div>
                    </li>

                    <li
                      style={{
                        width: "calc(600px - 20px)",
                        marginLeft: "34px",
                        marginTop: "5px",
                      }}
                      className="flex items-center flex-row "
                    >
                      <p className="mr-2 text-xs ">
                        Unit Price/Total Amount :{" "}
                      </p>
                      <div className="flex justify-evenly mt-0.5 ">
                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          // readOnly={true}
                          span
                          defaultValue={Unit_Price_Value[0]?.pprUnitPrice}
                          useCallbackFunc
                          orderOnchange={(e: any) => {
                            unitInputChange("unitPrice", e);
                          }}
                        />
                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          readOnly={true}
                          span
                          defaultValue={
                            formData.unitPrice * formData.quantityRequested
                          }
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              dumpAmount: e.target.value,
                            });
                          }}
                        />

                        <InputsTemplate
                          inputStyle={{ width: 197, height: 24, marginTop: 1 }}
                          parentClassName="mr-1"
                          span
                          defaultValue={formData.receiptNumber}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              receiptNumber: e,
                            });
                          }}
                        />
                      </div>
                    </li>
                  </div>
                </ul>
                {/* Remarks */}
              </div>

              <div style={{ width: "600px" }} className="">
                <Form.Item
                  style={{
                    width: "100%",
                    marginLeft: "85px",
                    marginBottom: 3,
                  }}
                >
                  <Form.Item
                    name="DispensedBy"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <div className="flex flex-row items-center  ">
                      <div className="">
                        <DropdownGrid_template
                          labelCol={25}
                          label={"Dispensed By"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 273 }}
                          isHide={true}
                          defaultValue={formData.dispensedBy.name}
                          disabled={formData.disabled}
                          columns={dispensedBy_table}
                          gridData={dispensorsData}
                          onRowClick={(e: any) => {
                            updateState("dispensedBy", {
                              id: e.empIdpk,
                              name: e.empName,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            dispensedBy: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 1,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                    marginLeft: "82px",
                    marginBottom: 2,
                  }}
                >
                  <Form.Item
                    name="DispensedBy"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <div className="flex flex-row items-center">
                      <div className="">
                        <DropdownGrid_template
                          labelCol={25}
                          label={"Authorised By"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 273 }}
                          isHide={true}
                          defaultValue={formData.authorisedBy.name}
                          disabled={formData.disabled}
                          columns={dispensedBy_table}
                          gridData={authorisersData}
                          onRowClick={(e: any) => {
                            updateState("authorisedBy", {
                              id: e.empIdpk,
                              name: e.empName,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            authorisedBy: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                    marginLeft: "85px",
                    marginBottom: 2.5,
                  }}
                >
                  <Form.Item
                    name="DispensedBy"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <div className="flex flex-row items-center">
                      <div className="ml-[8px]">
                        <DropdownGrid_template
                          labelCol={25}
                          label={"Received By"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 273 }}
                          isHide={true}
                          defaultValue={formData.receivedBy.name}
                          disabled={formData.disabled}
                          columns={dispensedBy_table}
                          gridData={recipientsData}
                          onRowClick={(e: any) => {
                            updateState("receivedBy", {
                              id: e.empIdpk,
                              name: e.empName,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            receivedBy: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <ul className=" flex flex-col ">
                  <div className="  w-full  justify-between mb-0.5">
                    <li className=" flex items-center flex-row w-[472px] ">
                      <Form.Item
                        className="w-full"
                        labelCol={{ span: 8 }}
                        label={
                          <p className=" text-xs ">{`Dept/Section/Cost Center`}</p>
                        }
                      >
                        <div
                          style={{ width: "100%" }}
                          className="flex space-x-1"
                        >
                          <div className="w-full flex pl-3">
                            <InputSelect_template
                              isOpenDropdown={formData.isOpenDropdown5}
                              style={{ width: "100%" }}
                              options={
                                formData.department?.length > 0
                                  ? DepartmentData
                                  : lookup_data?.department
                              }
                              handleFocus={(e: any) => {
                                updateState(
                                  `isOpenDropdown5`,
                                  !formData.isOpenDropdown5
                                );
                              }}
                              handleSearch={useCallback(
                                debounce((e) => {
                                  updateState(`department`!, e);
                                  updateState("isLoading", true);
                                  dispatch(
                                    Fetch_Department(e) as unknown as any
                                  );

                                  updateState(
                                    `isOpenDropdown5`,
                                    !formData.isOpenDropdown5
                                  );
                                }, 500), // Adjust the debounce delay as needed
                                []
                              )}
                              dataexp="dptShtName"
                              idexpr="dptIdpk"
                              disabledCustomWidth={true}
                              cusWidth={`100%`}
                              selectedValue={(e) => {
                                updateState("department", {
                                  name:
                                    e.dptShtName.length > 6
                                      ? `${e.dptShtName.slice(0, 6)}...`
                                      : e.dptShtName,
                                  id: e.dptIdpk,
                                });
                                fetchData(
                                  `AsmTmsCaptureOldFuelRecords/GetAllSections?DptIDpk=${formData.department.id}`,
                                  "sections"
                                );
                              }}
                              useCallFunc={true}
                              disabled={formData.disabled}
                              placeHolder={
                                formData.department.name?.length > 6
                                  ? `${formData.department.name.slice(0, 6)}...`
                                  : formData.department.name
                              }
                              span={true}
                            />
                          </div>
                          <InputSelect_template
                            isOpenDropdown={formData.isOpenDropdown6}
                            style={{ width: "100%", marginRight: "2px" }}
                            options={
                              formData.section?.length > 0
                                ? SectionData
                                : lookup_data?.sections
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                `isOpenDropdown6`,
                                !formData.isOpenDropdown6
                              );
                            }}
                            handleSearch={useCallback(
                              debounce((e) => {
                                updateState(`section`!, e);
                                updateState("isLoading", true);
                                dispatch(
                                  Fetch_Sections({
                                    search: e,
                                    DptIDSection: formData.department.id,
                                  }) as unknown as any
                                );

                                updateState(
                                  `isOpenDropdown6`,
                                  !formData.isOpenDropdown6
                                );
                              }, 500), // Adjust the debounce delay as needed
                              []
                            )}
                            dataexp="sxnShtName"
                            fullWidth={true}
                            span={true}
                            disabledCustomWidth={true}
                            cusWidth={`100%`}
                            idexpr="sxnIdpk"
                            selectedValue={(e) => {
                              fetchData(
                                `AsmTmsCaptureOldFuelRecords/GetAllCostCenters?DptIDpk=${formData.department.id}&SxnIDpk=${formData.section.id}`,
                                "costCenters"
                              );
                              updateState("section", {
                                name:
                                  e.sxnShtName.length > 5
                                    ? `${e.sxnShtName.slice(0, 5)}...`
                                    : e.sxnShtName,
                                id: e.sxnIdpk,
                              });
                            }}
                            useCallFunc={true}
                            disabled={formData.disabled}
                            placeHolder={
                              formData.section.name?.length > 5
                                ? `${formData.section.name.slice(0, 5)}...`
                                : formData.section.name
                            }
                          />
                          <InputSelect_template
                            style={{ width: "100%", marginRight: "2px" }}
                            isOpenDropdown={formData.isOpenDropdown7}
                            options={
                              formData.costCenter?.length > 0
                                ? CostCenterData
                                : lookup_data?.costCenters
                            }
                            dataexp="fcctName"
                            idexpr="fcctIdpk"
                            selectedValue={(e) => {
                              updateState("costCenter", {
                                name:
                                  e.fcctName.length > 5
                                    ? `${e.fcctName.slice(0, 5)}...`
                                    : e.fcctName,
                                id: e.fcctIdpk,
                              });
                            }}
                            placeHolder={
                              formData.costCenter.name?.length > 5
                                ? `${formData.costCenter.name.slice(0, 5)}...`
                                : formData.costCenter.name
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                `isOpenDropdown7`,
                                !formData.isOpenDropdown7
                              );
                            }}
                            handleSearch={useCallback(
                              debounce((e) => {
                                updateState(`costCenter`!, e);
                                updateState("isLoading", true);
                                dispatch(
                                  Fetch_CostCenter({
                                    DptIDCost: formData.department.id,
                                    search: e,
                                    SxnIDCost: formData.costCenter.id,
                                  }) as unknown as any
                                );

                                updateState(
                                  `isOpenDropdown7`,
                                  !formData.isOpenDropdown7
                                );
                              }, 500), // Adjust the debounce delay as needed
                              []
                            )}
                            useCallFunc={true}
                            disabled={formData.disabled}
                          />
                        </div>
                      </Form.Item>
                    </li>
                    <Form.Item
                      label={
                        <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                          {"Prev. Odom Reading"}
                        </p>
                      }
                      labelCol={{ span: 7 }}
                      // wrapperCol={{ span: 15 }}
                    >
                      <div className="flex flex-row justify-between">
                        <InputsTemplate
                          inputStyle={{ width: "73.5%", marginBottom: 0.5 }}
                          parentClassName="w-full"
                          span
                          defaultValue={formData.previousOdomReading}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              previousOdomReading: e,
                            });
                          }}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      label={
                        <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                          {"Current Odom Reading"}
                        </p>
                      }
                      labelCol={{ span: 7 }}
                      // wrapperCol={{ span: 15 }}
                    >
                      <div className="flex flex-row justify-between ">
                        <InputsTemplate
                          inputStyle={{ width: "73.5%" }}
                          parentClassName="w-full"
                          span
                          defaultValue={formData.currentOdomReading}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              currentOdomReading: e,
                            });
                          }}
                        />
                      </div>
                    </Form.Item>
                    {/* <Fuel_Backlog_Right_Input /> */}
                    <li
                      style={{ width: "calc(600px - 20px)" }}
                      className=" flex items-center flex-row mt-0.5"
                    >
                      <div className="w-full flex">
                        <CheckboxTemlate
                          style={{ borderColor: borderTheme, width: "100%" }}
                          label="Active? "
                          withBorders={true}
                          customDisabled={formData.disabled}
                          defaultValue={formData.active}
                          labelCol={7}
                          useCallbackFunc
                          setCheckboxOnchange={(e) => {
                            setFormData({
                              ...formData,
                              active: e,
                            });
                          }}
                          extraWidget={
                            <span className="flex w-full px-2 items-center">
                              <p className="text-blue-700 text-xs flex">*</p>
                            </span>
                          }
                        />
                      </div>
                    </li>
                  </div>
                </ul>
              </div>

              <div className=" w-full ">
                <div
                  style={{
                    border: "1px solid #ccc",
                    width: "160px",
                    borderColor: borderTheme,
                  }}
                  className="p-1 flex items-center justify-center"
                >
                  <Image
                    onError={(e) => {
                      e.currentTarget.src = invalidImage;
                    }}
                    src={
                      !ShowImages
                        ? invalidImage
                        : `data:image/png;base64, ${loadImg[0]?.vimImage}`
                    }
                    alt="No Image data"
                    className="w-full text-xs "
                  />
                </div>
                <li className="w-full flex flex-row items-center mt-1">
                  <Checkbox
                    style={{ borderColor: borderTheme }}
                    className=" py-0.5 px-1 rounded border-[1px]"
                    checked={formData.loadImageActive}
                    value={formData.loadImageActive}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        loadImageActive: e.target.checked,
                        //
                      });
                      setShowImages(e);
                    }}
                  />
                  <p
                    style={{ maxWidth: "500px" }}
                    className="w-full text-xs ml-1"
                  >
                    Load Vehicle Image{" "}
                  </p>
                </li>
              </div>
            </div>

            <div className="w-full mt-0.5 flex flex-col ">
              <Form.Item
                // className="ml-16"
                style={{ marginLeft: "4.5rem" }}
              >
                <>
                  <TextareaTemplate
                    // readonly={formData.disabled}
                    disabled={formData.disabled}
                    height={70}
                    label={"Remarks"}
                    MarginLeft={45}
                    useCallbackFunc={true}
                    defaultValue={formData.remarks}
                    setCallbackValue={(val) => {
                      updateState("remarks", val);
                    }}
                  />
                </>
              </Form.Item>

              <div style={{ width: "555px" }} className=" flex justify-end">
                <ul className="flex justify-end pr-1  ">
                  {formData.updateMode ? (
                    <UpdateButton
                      disableButton={formData.disabled}
                      useCallbackFunc={true}
                      handleUpdate={() => {
                        updateAccessPriv();
                      }}
                    />
                  ) : (
                    <SaveButton
                      disableButton={formData.disabled}
                      useCallbackFunc={true}
                      handleSave={() => {
                        //  save()
                        saveAccessPriv();
                      }}
                    />
                  )}
                  {formData.disabled ? (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        newAccesPriv();
                      }}
                    />
                  ) : (
                    <Cancel
                      useCallbackFunc
                      cancel_button_toggler={() => {
                        setFormData({
                          ...formData,
                          updateMode: false,
                          disabled: true,
                        });
                        dispatch(task_form_disable(true));
                        dispatch(
                          setGeneralValue({ expr: "formDisabled", value: true })
                        );
                        dispatch(task_footer_update(false));
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
);
