/* eslint-disable react/jsx-pascal-case */
import { DatePicker, Form, Input, Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import invalidImage from "../../../../../../../assets/none.jpg";
import useFetch from "../../../../../../../hooks/useFetch";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  navbar_height,
  saveModalData,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import { vehicleSimCard_TH } from "../../Vehicle-Requests/data/vehicle-requests-data";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  task_footer_table_selected,
  task_form_disable,
  task_footer_update,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { colorsJson } from "../../../../../../accessories/color-names";

import search1 from "../../../../../../../assets/search1.png";
import setting from "../../../../../../../assets/setting.png";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { Tooltip } from "devextreme-react";
import { FormModal } from "../../../../../../accessories/formModal";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";

//------------------------eagleMentality🦅------------------------------------
import {
  setNew_Cancel_Button,
  setEdit_Cancel_Button,
  setSave_Update_Button,
  setDisableForm,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleDetailsSlice";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { formPropsConst } from "../../../../../../accessories/constants";
import { InputsTemplate } from "../../../../../../../templates/input";
import { inputsTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
//-----------------------------------------------------------------------------

interface props {
  toggled?: boolean;
  mainDataGrid?: any;
  refreshGrid?: () => any;
  states?: any;
  setState?: (e: any, val: any) => void;
  selectedRecordx?: any;
  setSelectedRecordx?: (e: any) => void;
  setVisible?: () => void;
  showImage?: boolean;
  setShowImages?: (e: boolean) => void;
}

const VehicleDetsDetails = ({
  toggled,
  mainDataGrid,
  refreshGrid,
  states,
  setState,
  setSelectedRecordx,
  setVisible,
  showImage,
  setShowImages,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const dispatch = useDispatch();

  const vehDClear = useSelector((state: RootState) => state.general.vehDClear);

  //---------------------eagleMentality🦅--------------------------------------------
  let NewAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.New_Cancel_Button
  );
  let EditAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.Edit_Cancel_Button
  );
  let SaveAndUpdateButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.save_update_Button
  );
  let DisableForm = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.disableForm
  );
  const Vdispatch = useDispatch();
  //---------------------------------------------------------------------------------------

  let [resizeMode, initialResizeMode] = useResizeMode(!toggled);

  const heights_out = navbar_height + news_flash_height + toolbar_height + 510;

  //Table Height
  let pageHeight = resizeMode - heights_out + 60; // form + datagrid

  let fullPage = initialResizeMode - (navbar_height + news_flash_height + 40); // datagrid
  //height without navbar and toolbar and form

  const dateFormat = " DD MMM YYYY";

  const [refresh, setRefresh] = useState<boolean>(true);
  const [active, setActive] = useState<any>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [Icon, setIcon] = useState<"warning" | "success" | "question">(
    "warning"
  );

  const [regNumber_data, regNumber_error, regNumber_loading] = useFetch(
    `AssetTamVehicleDetail/GetRegistrationAssetTamVehicleDetails`,
    refresh
  );
  const [vehicleClass_data, vehicleClass_error, vehicleClass_loading] =
    useFetch(
      `AssetTamVehicleDetail/GetPopVehicleClassAssetTamVehicleDetails?vhcActive=${active}`,
      refresh
    );
  const [engineType_data, engineType_error, engineType_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopCmbEngineTypesyAssetTamVehicleDetails?egtActive=${active}`,
    refresh
  );
  const [powerTrain_data, powerTrain_error, powerTrain_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopPowerTrainAssetTamVehicleDetails?pwtActive=${active}`,
    refresh
  );
  const [fuelType_data, fuelType_error, fuelType_loading] = useFetch(
    `AssetTamVehicleDetail/GetCmbFuelTypesAssetTamVehicleDetails?ftpActive=${active}`,
    refresh
  );
  const [
    transmissionType_data,
    transmissionType_error,
    transmissionType_loading,
  ] = useFetch(
    `AssetTamVehicleDetail/GetTransmissionTypeAssetTamVehicleDetails?trtActive=${active}`,
    refresh
  );
  const [manufacturer_data, manufacturer_error, manufacturer_loading] =
    useFetch(
      `AssetTamVehicleDetail/GetPopManufactureAllActiveAssetTamVehicleDetails`,
      refresh
    );
  const [country_data, country_error, country_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopCmbCountryAssetTamVehicleDetails?ctyActive=${active}`,
    refresh
  );
  const [engineMake_data, engineMake_error, engineMake_loading] = useFetch(
    `AssetTamVehicleDetail/GetCmbEngineMakeAssetTamVehicleDetails?egmActive=${active}`,
    refresh
  );
  const [supplier_data, supplier_error, supplier_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopCmbSuppliersAssetTamVehicleDetails?splActive=${active}`,
    refresh
  );
  const [speedUnit_data, speedUnit_error, speedUnit_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopSpeedoUnitsAssetTamVehicleDetails?spuActive=${active}`,
    refresh
  );
  const [currency_data, currency_error, currency_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopLuePriceCurrencyAssetTamVehicleDetails?curActive=${active}`,
    refresh
  );

  const [chassisNo_data, chassisNo_error, chassisNo_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopChassisNoAssetTamVehicleDetails`,
    refresh
  );
  const [status_data, status_error, status_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopCmbVehicleStatusAssetTamVehicleDetails?vstActive=${active}`,
    refresh
  );
  const [images_data, images_error, images_loading] = useFetch(
    `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${states.vdtIdpk}&vimActive=true`,
    refresh
  );

  const [vehicleMake_data, vehicleMake_error, vehicleMake_loading] = useFetch(
    `AssetTamVehicleDetail/GetPopCmbVehicleMakeAssetTamVehicleDetails?vhcname=${states.vehClass.id}&vmkActive=${active}`
  );
  const [vehicleModel_data, vehicleModel_error, vehicleModel_loading] =
    useFetch(
      `AssetTamVehicleDetail/GetPopCmbVehicleModelAssetTamVehicleDetails?vhcmake=${states.vehMake.id}&mdlActive=${active}`
    );

  const makeThree: inputsTypes[] = [
    { id: 0, label: "", stateName: "len", defaultValue: states.len, style: "" },
    {
      id: 1,
      label: "",
      stateName: "width",
      defaultValue: states.width,
      style: "",
    },
    {
      id: 2,
      label: "",
      stateName: "height",
      defaultValue: states.height,
      style: "",
    },
  ];

  const ResetFields = () => {
    const statesArray = [
      { key: "vdtIdpk", value: 0 },
      { key: "regNo", value: " " },
      { key: "vehClass", value: { id: 0, value: "" } },
      { key: "manufacturer", value: { id: 0, value: "" } },
      { key: "engineType", value: { id: 0, value: "" } },
      { key: "powerTrain", value: { id: 0, value: "" } },
      { key: "len", value: 0 },
      { key: "width", value: 0 },
      { key: "height", value: 0 },
      { key: "noCylinder", value: "" },
      { key: "horsePower", value: "" },
      { key: "netWeight", value: 0 },
      { key: "grossWeight", value: "" },
      { key: "manuDate", value: "" },
      { key: "chassisNo", value: "" },
      { key: "vehMake", value: { id: 0, value: "" } },
      { key: "country", value: { id: 0, value: "" } },
      { key: "fuelType", value: { id: 0, value: "" } },
      { key: "transType", value: { id: 0, value: "" } },
      { key: "status", value: { id: 0, value: "" } },
      { key: "noOfAxles", value: "" },
      { key: "noOfWheels", value: "" },
      { key: "tankCap", value: "" },
      { key: "price", value: "" },
      { key: "currency", value: { id: 0, value: "" } },
      { key: "supplyDate", value: "" },
      { key: "maxSpeed", value: 0 },
      { key: "speedUnit", value: { id: 0, value: "" } },
      { key: "vehModel", value: { id: 0, value: "" } },
      { key: "supplier", value: { id: 0, value: "" } },
      { key: "engineMake", value: { id: 0, value: "" } },
      { key: "cubicCapacity", value: "" },
      { key: "seatingCap", value: "" },
      { key: "engineNo", value: "" },
      { key: "tyreSizeF", value: "" },
      { key: "tyreSizeM", value: "" },
      { key: "tyreSizeR", value: "" },
      { key: "other", value: "" },
      { key: "regDate", value: "" },
      { key: "colour", value: "" },
      { key: "specialised", value: false },
      { key: "motorBike", value: false },
      { key: "equipment", value: false },
      { key: "lightDuty", value: false },
      { key: "heavyDuty", value: false },
      { key: "vehicle", value: false },
      { key: "active", value: false },
      { key: "remarks", value: "" },
    ];

    for (let i of statesArray) return setState && setState(i.key, i.value);
  };

  const saveData = async () => {
    await PostDataFunc (`AssetTamVehicleDetail/CreateAssetTamVehicleDetails`, {
      vdtRegNo: states.regNo,
      vdtChassisNo: states.chassisNo,
      vdtVehicleClassIdfk: states.vehClass?.id,
      vdtEngineNo: states.engineNo,
      vdtModelIdfk: states.vehModel?.id,
      vdtManufacturerIdfk: states.manufacturer?.id,
      vdtCountryOfOriginIdfk: states.country?.id,
      vdtSupplierIdfk: states.supplier?.id,
      vdtManufactureDate: states.manuDate,
      vdtSupplyDate: states.supplyDate,
      vdtDvlaRegDate: states.regDate,
      vdtEngineTypeIdfk: states.engineType?.id,
      vdtFuelTypeIdfk: states.fuelType?.id,
      vdtCubicCapacity: states.cubicCapacity,
      vdtTankCapacity: states.tankCap,
      vdtPowerTrainIdfk: states.powerTrain?.id,
      vdtTransmissionTypeIdfk: states.transType?.id,
      vdtEngineMakeIdfk: states.engineMake?.id,
      vdtNoOfCylinders: states.noCylinder,
      vdtSpeedometerLimit: states.maxSpeed,
      vdtSpeedometerUnitIdfk: states.speedUnit?.id,
      vdtColour: states.colour,
      vdtStatusIdfk: states.status?.id,
      vdtSeatingCapacity: states.seatingCap,
      vdtLength: states.len,
      vdtWidth: states.width,
      vdtHeight: states.height,
      vdtNoOfAxles: states.noOfAxles,
      vdtNoOfWheels: states.noOfWheels,
      vdtTyreSizeFront: states.tyreSizeF,
      vdtTyreSizeMiddle: states.tyreSizeM,
      vdtTyreSizeRear: states.tyreSizeR,
      vdtNetWeight: states.netWeight,
      vdtGrossWeight: states.grossWeight,
      vdtHorsePower: states.horsePower,
      vdtPrice: states.price,
      vdtPriceCurrencyIdfk: states.currency?.id,
      vdtGeneral: states.general,
      vdtBus: states.bus,
      vdtSpecialised: states.specialised,
      vdtMotorBike: states.motorBike,
      vdtEquipment: states.equipment,
      vdtLightDuty: states.lightDuty,
      vdtHeavyDuty: states.heavyDuty,
      vdtVehicle: states.vehicle,
      vdtActive: states.active,
      vdtRmks: states.remarks,
      vdtCreatedBy: 1,
    })
      .then((response) => {
        refreshGrid!();
        setRefresh(!refresh);
        setModalData({
          message: "Record Save Successfully",
          title: "Saved ",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("success");
        setOpenModal(true);
        setDisableButton(true);
      })
      .catch((err) => {
        setModalData({
          message: "Please contact the systems administrator",
          title: "Record not saved!",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("warning");
        setOpenModal(true);
        setDisableButton(true);
      });
  };

  let checkForRegNo = regNumber_data.find((reg_No: any) =>
    reg_No.vdtRegNo === states.regNo ? true : false
  );

  const validatingPost = (method: "save" | "update") => {
    setIcon("warning");

    if (states.regNo === "") {
      setModalData({
        message: "Enter a Registration Number",
        title: "Registration Number",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (checkForRegNo !== undefined) {
      setModalData({
        message: "Registration Number already exist ",
        title: " Already exist!",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (states.chassisNo === "") {
      setModalData({
        message: "Enter Chassis Number",
        title: "Chassis Number",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (states.engineNo === "") {
      setModalData({
        message: "Engine Number can not be Empty",
        title: "Engine Number ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (!states.vehModel) {
      setModalData({
        message: "Select Vehicle Model",
        title: "Vehicle Model",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (!states.manufacturer) {
      setModalData({
        message: "Select Manufacturer",
        title: "Manufacturer",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (!states.country) {
      setModalData({
        message: "Select Country",
        title: "Country",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (!states.supplier) {
      setModalData({
        message: "Select Supplier",
        title: "Supplier ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.engineType) {
      setModalData({
        message: "Select Engine Type",
        title: "Engine Type",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.fuelType) {
      setModalData({
        message: "Select Fuel Type",
        title: "Fuel Type",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.cubicCapacity?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Cubic Capacity",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.tankCap?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Tank Capacity ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.powerTrain) {
      setModalData({
        message: "Select Power Train",
        title: "Power Train",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.transType) {
      setModalData({
        message: "Select Transmission Type",
        title: "Transmission Type",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.engineMake) {
      setModalData({
        message: "Select Engine Make",
        title: " Engine Make",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.noCylinder?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Number of Cylinder ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.maxSpeed?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Max Speedo ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.speedUnit) {
      setModalData({
        message: "Select Speed Unit",
        title: "Speed Unit",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.status) {
      setModalData({ message: "Select Status", title: "Status", okText: "Ok" });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.seatingCap?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Seat Capacity ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.len?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "length ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.width?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Width ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.height?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Height ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.noOfAxles?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Number of Axles",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.noOfWheels?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Number of Wheels",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.tyreSizeF?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Front Tyre ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.tyreSizeM?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Middle Tyre",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.noCylinder?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Number of Cylinder ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.tyreSizeR?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Rear Tyre ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.netWeight?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Net Weight ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.grossWeight?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Gross Weight ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.horsePower?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: "Number of  Horse Power",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (states.price?.toString() === "") {
      setModalData({
        message: "Input Field can not be Empty",
        title: " Price ",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    }
    if (!states.currency) {
      setModalData({
        message: "Select Currency",
        title: "Currency",
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else if (method == "save") {
      setIcon("question");
      setModalData({
        message: "Are you sure you want to save this record?",
        title: "Save Record ?",
        okText: "Yes",
      });
      setDisableButton(false);
      setOpenModal(true);
    } else if (method == "update") {
      setIcon("question");
      setModalData({
        message: "Are you sure you want to update this record?",
        title: "Update Record ?",
        okText: "Yes",
      });
      setDisableButton(false);
      setOpenModal(true);
    }
  };

  const UpdateData = async () => {
    await UpdateDataFunc(
      `AssetTamVehicleDetail/UpdateAssetTamVehicleDetails/${states.vdtIdpk}`,
      {
        vdtRegNo: states.regNo,
        vdtChassisNo: states.chassisNo,
        vdtVehicleClassIdfk: states.vehClass?.id,
        vdtEngineNo: states.engineNo,
        vdtModelIdfk: states.vehModel?.id,
        vdtManufacturerIdfk: states.manufacturer?.id,
        vdtCountryOfOriginIdfk: states.country?.id,
        vdtSupplierIdfk: states.supplier?.id,
        vdtManufactureDate: states.manuDate,
        vdtSupplyDate: states.supplyDate,
        vdtDvlaRegDate: states.regDate,
        vdtEngineTypeIdfk: states.engineType?.id,
        vdtFuelTypeIdfk: states.fuelType?.id,
        vdtCubicCapacity: states.cubicCapacity,
        vdtTankCapacity: states.tankCap,
        vdtPowerTrainIdfk: states.powerTrain?.id,
        vdtTransmissionTypeIdfk: states.transType?.id,
        vdtEngineMakeIdfk: states.engineMake?.id,
        vdtNoOfCylinders: states.noCylinder,
        vdtSpeedometerLimit: states.maxSpeed,
        vdtSpeedometerUnitIdfk: states.speedUnit?.id,
        vdtColour: states.colour,
        vdtStatusIdfk: states.status?.id,
        vdtSeatingCapacity: states.seatingCap,
        vdtLength: states.len,
        vdtWidth: states.width,
        vdtHeight: states.height,
        vdtNoOfAxles: states.noOfAxles,
        vdtNoOfWheels: states.noOfWheels,
        vdtTyreSizeFront: states.tyreSizeF,
        vdtTyreSizeMiddle: states.tyreSizeM,
        vdtTyreSizeRear: states.tyreSizeR,
        vdtNetWeight: states.netWeight,
        vdtGrossWeight: states.grossWeight,
        vdtHorsePower: states.horsePower,
        vdtPrice: states.price,
        vdtPriceCurrencyIdfk: states.currency?.id,
        vdtGeneral: states.general,
        vdtBus: states.bus,
        vdtSpecialised: states.specialised,
        vdtMotorBike: states.motorBike,
        vdtEquipment: states.equipment,
        vdtLightDuty: states.lightDuty,
        vdtHeavyDuty: states.heavyDuty,
        vdtVehicle: states.vehicle,
        vdtActive: states.active,
        vdtRmks: states.remarks,
        vdtEditedBy: 1,
      }
    )
      .then((response) => {
        refreshGrid!();

        setRefresh(!refresh);
        setModalData({
          message: "Record Updated Successfully",
          title: "Updated ",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("success");
        setOpenModal(true);
        setDisableButton(true);

        setRefresh(!refresh);
      })
      .catch(() => {
        setModalData({
          message: "Please contact your system administrator",
          title: "Data not saved!",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("warning");
        setOpenModal(true);
        setDisableButton(true);
      });
  };

  useEffect(() => {
    if (vehDClear) {
      ResetFields();
    }
  }, [vehDClear]);

  const [openCatVeh, setOpenCatVeh] = useState(false);
  useResizeMode(pageHeight);

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const AccessPriv_on_New_Button = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(task_footer_table_selected({}));
      //---------------eagleMentality🦅------------------
      Vdispatch(setNew_Cancel_Button(false));
      Vdispatch(setDisableForm(false));
      Vdispatch(setEdit_Cancel_Button(true));
      Vdispatch(setSave_Update_Button(true));
      //--------------------------------------------------
      setShowImages && setShowImages(false);
      ResetFields();
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied! ",
        okText: "Ok",
        cancelText: "",
      });
      setIcon("warning");
      setOpenModal(true);
      setDisableButton(true);
    }
  };
  const AccessPriv_on_save_Button = () => {
    setPrivType("save");
    if (savePriv === true) {
      validatingPost("save");
    } else {
      setModalData({
        message: "No Access Priviledge",
        title: "Access Denied! ",
        okText: "Ok",
        cancelText: "",
      });
      setIcon("warning");
      setOpenModal(true);
      setDisableButton(true);
    }
  };
  const AccessPriv_on_Update_Button = () => {
    setPrivType("update");
    if (savePriv === true) {
      validatingPost("update");
    } else {
      setModalData({
        message: "No Access Priviledge",
        title: "Access Denied! ",
        okText: "Ok",
        cancelText: "",
      });
      setIcon("warning");
      setOpenModal(true);
      setDisableButton(true);
    }
  };
  const AccessPriv_on_DataGridDoubleClick = (val: any) => {
    setPrivType("update");
    setVisible && setVisible();
    if (savePriv === true) {
      setSelectedRecordx && setSelectedRecordx(val);
      //----------- eagleMentality🦅------------------
      Vdispatch(setDisableForm(false));
      Vdispatch(setSave_Update_Button(false));
      Vdispatch(setEdit_Cancel_Button(false));
      //--------------------------------------------
    } else {
      setModalData({
        message: "No Access Priviledge",
        title: "Access Denied! ",
        okText: "Ok",
        cancelText: "",
      });
      setIcon("warning");
      setOpenModal(true);
      setDisableButton(true);
    }
  };

  return (
    <>
      <div className="">
        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  
 
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }
 
            `,
          }}
        />
        <FormModal
          openState={openCatVeh}
          child={
            <>
              <div className="flex justify-end ">
                <CheckboxTemlate withBorders={true} />
                <li
                  id="newRecord"
                  onClick={() => {}}
                  style={{ borderWidth: "1px" }}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  ml-2 text-gray-600 rounded flex items-center "
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                    {" "}
                    <img alt="" className="pr-1 " src={search1} />
                    Search{" "}
                  </span>
                </li>
              </div>
              <Datagrid_template
                gridheight={400}
                columns={vehicleSimCard_TH}
                data={mainDataGrid}
                onRowClick={(val) => {
                  setSelectedRecordx && setSelectedRecordx(val);
                }}
              />
              <Form.Item
                label={<p className="text-xs ">{"Veh. Category"}</p>}
                style={{ marginBottom: 0, marginTop: "1%" }}
              >
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginBottom: "1px",
                    width: "calc(99%)",
                  }}
                  className=" mb-1 mr-2 "
                >
                  <div className="flex justify-between mt-0.5 h-[50px] ">
                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("vehicle", value);
                        }}
                        withBorders={true}
                        defaultValue={states.vehicle}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1 "> Vehicle?</span>
                    </div>

                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("general", value);
                        }}
                        withBorders={true}
                        defaultValue={states.general}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Gen Vehicle?</span>
                    </div>
                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        customDisabled={DisableForm}
                        useCallbackFunc
                        setCheckboxOnchange={(value) => {
                          setState && setState("bus", value);
                        }}
                        withBorders={true}
                        defaultValue={states.bus}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Bus?</span>
                    </div>
                    <div className="flex justify-center ">
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("lightDuty", value);
                        }}
                        withBorders={true}
                        defaultValue={states.lightDuty}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Light Duty?</span>
                    </div>
                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("heavyDuty", value);
                        }}
                        withBorders={true}
                        defaultValue={states.heavyDuty}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Heavy Duty?</span>
                    </div>
                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("specialised", value);
                        }}
                        withBorders={true}
                        defaultValue={states.specialised}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Specialised</span>
                    </div>
                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("motorBike", value);
                        }}
                        withBorders={true}
                        defaultValue={states.motorBike}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Motor Bike?</span>
                    </div>
                    <div className="flex justify-center">
                      {" "}
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={DisableForm}
                        setCheckboxOnchange={(value) => {
                          setState && setState("equipment", value);
                        }}
                        withBorders={true}
                        defaultValue={states.equipment}
                        expr={""}
                        style={{
                          display: "inline-block",
                          width: "",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <span className="ml-1 mt-1"> Equipment?</span>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <UpdateButton
                      handleUpdate={() => {
                        AccessPriv_on_Update_Button();
                      }}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
            </>
          }
          title={"Transport-Categorise Vehicles"}
          onCancel={() => {
            setOpenCatVeh(false);
          }}
        />
        <ModalTemplate
          icon={Icon}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          disableCancel={disableButton}
          open={openModal}
          okHandler={() => {
            modalData.title === "Save Record ?"
              ? saveData()
              : modalData.title === "Update Record ?"
              ? UpdateData()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        {!toggled && (
          <div
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] border-r-1px border-b-2 rounded "
          >
            <p
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="pt-1  border-t-1 r-1 border-b-1 pr-10  bg-slate-100 font-medium"
            >
              Vehicle Details
            </p>
            {/* The form */}
            <div className="w-[1500px] ">
              <div className="py-2 flex flex-row">
                <Form
                  name="complex-form "
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 20 }}
                  size={"small"}
                  className="ml-2"
                  style={{ width: "25%" }}
                >
                  {/* Add the datalist here */}

                  <SetupsDatalistTemplat
                    datalistStyle={{ width: 222 }}
                    selectedData={states.regNo}
                    refresh={true}
                    label={"Registration Number"}
                    listid="vehReg"
                    nameExpr={"vdtRegNo"}
                    idExpr={"vdtIdpk"}
                    options={regNumber_data}
                    outerDisable={DisableForm}
                    useCallbackFunc={true}
                    setCallbackValue={(e: any) => {
                      setState && setState("regNo", e);
                    }}
                  />
                  <div style={{ height: "1px" }}></div>

                  <SelectsTemplate
                    placeHolder={states.vehClass?.value}
                    handleRefresh={() => {}}
                    label={"Vehicle Class"}
                    options={vehicleClass_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={"vhcIDpk"}
                    dataexp={"vhcName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("vehClass", {
                          id: e.vhcIDpk,
                          value: e.vhcName,
                        });
                    }}
                  />

                  <SelectsTemplate
                    placeHolder={states.manufacturer?.value}
                    handleRefresh={() => {}}
                    label={"Manufacturer"}
                    options={manufacturer_data}
                    useCallFunc={true}
                    idexpr={"mftIDpk"}
                    dataexp={"mftName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("manufacturer", {
                          id: e.mftIDpk,
                          value: e.mftName,
                        });
                    }}
                    disabled={DisableForm}
                  />

                  <SelectsTemplate
                    placeHolder={states.engineType?.value}
                    handleRefresh={() => {}}
                    label={"Engine Type"}
                    disabled={DisableForm}
                    options={engineType_data}
                    useCallFunc={true}
                    idexpr={"egtIDpk"}
                    dataexp={"egtName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("engineType", {
                          id: e.egtIDpk,
                          value: e.egtName,
                        });
                    }}
                  />

                  <SelectsTemplate
                    placeHolder={states.powerTrain?.value}
                    handleRefresh={() => {}}
                    label={"Power Train"}
                    options={powerTrain_data}
                    useCallFunc={true}
                    idexpr={"pwtIDpk"}
                    dataexp={"pwtName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("powerTrain", {
                          id: e.pwtIDpk,
                          value: e.pwtName,
                        });
                    }}
                    disabled={DisableForm}
                  />

                  <div className="block space-x-1 items-center w-full">
                    <SelectsTemplate
                      placeHolder={states.colour?.value}
                      label={"Colour"}
                      options={colorsJson}
                      useCallFunc={true}
                      idexpr={"id"}
                      dataexp={"value"}
                      span={true}
                      fullWidth={true}
                      extraWidget={
                        <div className="ml-1">
                          <Input
                            style={{
                              width: 24,
                              backgroundColor: `${states.colour}`,
                            }}
                          />
                        </div>
                      }
                      selectedValue={(e: any) => {
                        setState && setState("colour", e.value);
                      }}
                      disabled={DisableForm}
                    />
                  </div>

                  <Form.Item
                    label={<p className="text-xs">{"Len./Wdt/Hgt"}</p>}
                    style={{ marginBottom: 0, width: "calc(100%)" }}
                  >
                    {" "}
                    <aside className="flex flex-row space-x-1">
                      {makeThree.map(
                        ({ id, label, stateName, defaultValue, style }) => {
                          return (
                            <InputsTemplate
                              key={id}
                              numberOnly={true}
                              defaultValue={defaultValue}
                              disabledStatus={DisableForm}
                              inputStyle={{ width: "100%" }}
                              span={true}
                              label={label}
                              useCallbackFunc={true}
                              labelColPan={6}
                              orderOnchange={(e: any) => {
                                setState && setState(stateName!, e);
                              }}
                            />
                          );
                        }
                      )}
                    </aside>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs mb-2">{"No of Cyl/HP"}</p>}
                    style={{
                      marginBottom: 0,
                      width: "calc(100%)",
                      marginTop: "0.5%",
                    }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(65%)",
                        marginRight: "3%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          size="small"
                          allowClear
                          placeholder=""
                          value={states.noCylinder}
                          onChange={(e: any) => {
                            setState && setState("noCylinder", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(32%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          size="small"
                          allowClear
                          placeholder=""
                          value={states.horsePower}
                          onChange={(e: any) => {
                            setState && setState("horsePower", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Weight (N/G)"}</p>}
                    style={{
                      marginBottom: 0,
                      width: "calc(100%)",
                      marginTop: "0.5%",
                    }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(65%)",
                        marginRight: "3%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          type="number"
                          size="small"
                          allowClear
                          placeholder=""
                          value={states.netWeight}
                          onChange={(e: any) => {
                            setState && setState("netWeight", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(32%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          type="number"
                          size="small"
                          allowClear
                          placeholder=""
                          value={states.grossWeight}
                          onChange={(e: any) => {
                            setState && setState("grossWeight", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs bg-black">{"Manuf. Date"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "1%",
                      }}
                    >
                      {" "}
                      <div className=" flex flex-row items-center">
                        <DatePicker
                          value={
                            states.manuDate === ""
                              ? undefined
                              : dayjs(states.manuDate)
                          }
                          disabledDate={(current) =>
                            current && current.valueOf() >= Date.now()
                          }
                          disabled={DisableForm}
                          size="small"
                          placeholder=""
                          style={{ width: "64%" }}
                          format={dateFormat}
                          onChange={(stringDate: any) => {
                            setState && setState("manuDate", stringDate);
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <div className="h-5"></div>

                  <Form.Item
                    label={<p className="text-xs">{"Veh. Category"}</p>}
                    style={{ marginTop: "-5px", marginBottom: 0 }}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        marginBottom: "1px",
                        width: "calc(410%)",
                      }}
                      className=" mb-1 mr-2 "
                    >
                      <div className="flex justify-between">
                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("vehicle", value);
                            }}
                            withBorders={true}
                            defaultValue={states.vehicle}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1 "> Vehicle?</span>
                        </div>

                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("general", value);
                            }}
                            withBorders={true}
                            defaultValue={states.general}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1 "> Gen Vehicle?</span>
                        </div>
                        <div>
                          {" "}
                          <CheckboxTemlate
                            customDisabled={DisableForm}
                            useCallbackFunc
                            setCheckboxOnchange={(value) => {
                              setState && setState("bus", value);
                            }}
                            withBorders={true}
                            defaultValue={states.bus}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1"> Bus?</span>
                        </div>
                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("lightDuty", value);
                            }}
                            withBorders={true}
                            defaultValue={states.lightDuty}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1"> Light Duty?</span>
                        </div>
                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                    
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("heavyDuty", value);
                            }}
                            withBorders={true}
                            defaultValue={states.heavyDuty}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1"> Heavy Duty?</span>
                        </div>
                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("specialised", value);
                            }}
                            withBorders={true}
                            defaultValue={states.specialised}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1"> Specialised</span>
                        </div>
                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                        
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("motorBike", value);
                            }}
                            withBorders={true}
                            defaultValue={states.motorBike}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1"> Motor Bike?</span>
                        </div>
                        <div>
                          {" "}
                          <CheckboxTemlate
                            useCallbackFunc
                       
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setState && setState("equipment", value);
                            }}
                            withBorders={true}
                            defaultValue={states.equipment}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />{" "}
                          <span className="ml-1"> Equipment?</span>
                        </div>
                        <div>
                          <li
                            id="settings"
                            onClick={() => {
                              setOpenCatVeh(!openCatVeh);
                            }}
                            style={{
                              borderWidth: "1px",
                              borderColor: borderTheme,
                            }}
                            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                          >
                            <img
                              alt=""
                              className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                              src={setting}
                            />
                            <Tooltip
                              target="#towns"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Settings</p>
                            </Tooltip>
                          </li>
                        </div>
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Remarks"}</p>}
                    style={{ marginBottom: 0, marginTop: "1%" }}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        marginBottom: "1px",
                        width: "calc(410%)",
                      }}
                      className=" mb-1 mr-2"
                    >
                      <div className="flex">
                        <TextArea
                          value={states.remarks}
                          style={{
                            resize: "none",
                            maxHeight: "80px",
                            minHeight: "80px",
                            height: "80px",
                            width: "",
                          }}
                          className="o mr-2 overflow-y-scroll resize-none"
                          rows={3}
                          onChange={(e: any) => {
                            setState && setState("remarks", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                        <ul className=" my-1">
                          {NewAndCancelButton === true ? (
                            <>
                              {EditAndCancelButton === true && (
                                <NewButton
                                  useCallbackFunc={true}
                                  new_button_toggler={() => {
                                    AccessPriv_on_New_Button();
                                  }}
                                />
                              )}
                              {EditAndCancelButton === false && (
                                <Cancel
                                  useCallbackFunc={true}
                                  cancel_button_toggler={() => {
                                    // ----------eagleMentality🦅--------------
                                    Vdispatch(setNew_Cancel_Button(true));
                                    Vdispatch(setDisableForm(true));
                                    Vdispatch(setEdit_Cancel_Button(true));
                                    Vdispatch(setSave_Update_Button(true));
                                    // ----------------------------------------
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <Cancel
                              useCallbackFunc={true}
                              cancel_button_toggler={() => {
                                dispatch(task_form_disable(true));
                                //  this destroys the update mode state
                                dispatch(task_footer_update(false));
                                // ----------eagleMentality🦅--------------
                                Vdispatch(setNew_Cancel_Button(true));
                                Vdispatch(setDisableForm(true));
                                Vdispatch(setEdit_Cancel_Button(true));
                                Vdispatch(setSave_Update_Button(true));
                                // ----------------------------------------
                              }}
                            />
                          )}
                          <div className="w-97% ml-1 mt-2">
                            {SaveAndUpdateButton === true ? (
                              <SaveButton
                                disableButton={DisableForm}
                                handleSave={() => {
                                  AccessPriv_on_save_Button();
                                }}
                              />
                            ) : (
                              <UpdateButton
                                handleUpdate={() => {
                                  AccessPriv_on_Update_Button();
                                }}
                              />
                            )}
                          </div>
                        </ul>
                      </div>
                    </Form.Item>
                  </Form.Item>
                </Form>

                {/* Second */}
                <Form
                  name="complex-form "
                  labelCol={{ span: 8 }}
                  size={"small"}
                  className="ml-2 "
                  style={{ width: "25%" }}
                >
                  <SetupsDatalistTemplat
                    datalistStyle={{ width: 216 }}
                    selectedData={states.chassisNo}
                    refresh={true}
                    label={"Chassis No"}
                    options={chassisNo_data}
                    outerDisable={DisableForm}
                    useCallbackFunc={true}
                    listid="vehChassis"
                    idExpr={"vdtIdpk"}
                    nameExpr={"vdtChassisNo"}
                    setCallbackValue={(e: any) => {
                      setState && setState("chassisNo", e);
                    }}
                  />
                  <div style={{ height: "1px" }}></div>

                  <SelectsTemplate
                    placeHolder={states.vehMake}
                    handleRefresh={() => {}}
                    label={"Vehicle Make"}
                    options={vehicleMake_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={"vmkIdpk"}
                    dataexp={"vmkName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("vehMake", {
                          id: e.vmkIdpk,
                          value: e.vmkName,
                        });
                    }}
                  />

                  <SelectsTemplate
                    placeHolder={states.country?.value}
                    handleRefresh={() => {}}
                    label={"Country"}
                    options={country_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={"ctyIDpk"}
                    dataexp={"ctyName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("country", {
                          id: e.ctyIDpk,
                          value: e.ctyName,
                        });
                    }}
                  />

                  <SelectsTemplate
                    placeHolder={states.fuelType}
                    handleRefresh={() => {}}
                    label={"Fuel Type"}
                    options={fuelType_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={"ftpIDpk"}
                    dataexp={"ftpName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("fuelType", {
                          id: e.ftpIDpk,
                          value: e.ftpName,
                        });
                    }}
                  />

                  <SelectsTemplate
                    placeHolder={states.transType}
                    handleRefresh={() => {}}
                    label={"Transm. Type"}
                    options={transmissionType_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={"trtIDpk"}
                    dataexp={"trtName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("transType", {
                          id: e.trtIDpk,
                          value: e.trtName,
                        });
                    }}
                  />

                  <div className="block w-full space-x-1 items-center">
                    <SelectsTemplate
                      placeHolder={states.status}
                      handleRefresh={() => {}}
                      span={true}
                      label={"Status"}
                      options={status_data}
                      disabled={DisableForm}
                      fullWidth={true}
                      useCallFunc={true}
                      idexpr={"vstIdpk"}
                      dataexp={"vstName"}
                      selectedValue={(data: any) => {
                        setState &&
                          setState("status", {
                            id: data.vstIdpk,
                            value: data.vstName,
                          });
                      }}
                      extraWidget={
                        <div className="block ml-0.5">
                          <CheckboxTemlate
                            useCallbackFunc
                            customDisabled={DisableForm}
                            setCheckboxOnchange={(value) => {
                              setActive(value);
                            }}
                            withBorders={true}
                            expr={""}
                            style={{
                              display: "inline-block",
                              width: "",
                              marginBottom: 0,
                            }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <Form.Item
                    label={<p className="text-xs">{"No of Axles/Wheels"}</p>}
                    style={{ marginBottom: 0, width: "calc(100%)" }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(49%)",
                        marginRight: "2%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.noOfAxles}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("noOfAxles", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(49%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.noOfWheels}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("noOfWheels", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs ">{"Tank Cap (Ltrs)"}</p>}
                    style={{
                      marginBottom: 0,
                      width: "calc(100%)",
                      marginTop: "0.5%",
                    }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          type="number"
                          value={states.tankCap}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("tankCap", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Price"}</p>}
                    style={{
                      marginBottom: 0,
                      width: "calc(100%)",
                      marginTop: "0.7%",
                    }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(490%)",
                        marginRight: "2%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          type="number"
                          value={states.price}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("price", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(49%)" }}
                    >
                      <SelectsTemplate
                        style={{ width: "100%" }}
                        disabled={DisableForm}
                        selectedValue={(e: any) => {
                          setState &&
                            setState("currency", {
                              id: e.curIDpk,
                              value: e.curSymbol,
                            });
                        }}
                        dataexp={"curSymbol"}
                        idexpr={"curIDpk"}
                        options={currency_data}
                        useCallFunc={true}
                        handleRefresh={() => {}}
                        placeHolder={states.currency}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Supply Date"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "1%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          disabled={DisableForm}
                          value={
                            states.supplyDate === ""
                              ? undefined
                              : dayjs(states.supplyDate)
                          }
                          disabledDate={(current) =>
                            current && current.valueOf() >= Date.now()
                          }
                          size="small"
                          placeholder=""
                          style={{ width: "49%" }}
                          format={dateFormat}
                          onChange={(e: any, stringDate: any) => {
                            setState &&
                              setState(
                                "supplyDate",
                                dayjs(stringDate).format()
                              );
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </Form>

                {/* Third */}
                <Form
                  name="complex-form"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 5 }}
                  size={"small"}
                  className="ml-2 "
                  style={{ width: "25%" }}
                >
                  <Form.Item
                    label={<p className="text-xs">{"Max. Speedo"}</p>}
                    style={{ width: "calc(100%)", marginBottom: 0 }}
                    className=""
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(50%)", 
                        marginRight: "1.5%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center w-[150px]">
                        <Input
                          value={states.maxSpeed}
                          type="number"
                          size="small"
                          style={{ height: 24.5, width: "80%" }}
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("maxSpeed", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="speedUnit"
                      style={{ display: "inline-block", width: "calc(30%)" }}
                    >
                      <div className="w-full flex flex-row items-center ml-[85px]">
                        <SelectsTemplate
                          selectStyle={{ width: "92px" }} 
                          placeHolder={states.speedUnit!.value}
                          handleRefresh={() => {
                            setState &&
                              setState("speedUnit", { id: "", value: "" });
                          }}
                          options={speedUnit_data}
                          idexpr={"spuIDpk"}
                          dataexp={"spuName"}
                          disabled={DisableForm}
                          useCallFunc={true}
                          selectedValue={(e: any) => {
                            const data = e;
                            setState &&
                              setState("speedUnit", {
                                id: data.spuIDpk,
                                value: data.spuName,
                              });
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <SelectsTemplate
                    placeHolder={states.vehModel}
                    handleRefresh={() => {}}
                    label={"Vehicle Model"}
                    options={vehicleModel_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={"mdlIdpk"}
                    dataexp={"mdlName"}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("vehModel", {
                          id: e.mdlIdpk,
                          value: e.mdlName,
                        });
                    }}
                    selectStyle={{ marginBottom: 2 }}
                  />

                  <SelectsTemplate
                    placeHolder={states.supplier}
                    handleRefresh={() => {}}
                    label={"Supplier"}
                    idexpr={"splIDpk"}
                    dataexp={"splName"}
                    options={supplier_data}
                    disabled={DisableForm}
                    useCallFunc={true}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("supplier", {
                          id: e.splIDpk,
                          value: e.splName,
                        });
                    }}
                  />

                  <SelectsTemplate
                    placeHolder={states.engineMake}
                    handleRefresh={() => {}}
                    label={"Engine Make"}
                    options={engineMake_data}
                    idexpr={"egmIDpk"}
                    dataexp={"egmName"}
                    disabled={DisableForm}
                    useCallFunc={true}
                    selectedValue={(e: any) => {
                      setState &&
                        setState("engineMake", {
                          id: e.egmIDpk,
                          value: e.egmName,
                        });
                    }}
                  />

                  <Form.Item
                    label={<p className="text-xs">{"Cubic Cap"}</p>}
                    style={{ marginBottom: 2, width: "calc(100%)" }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          type="number"
                          value={states.cubicCapacity}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState &&
                              setState("cubicCapacity", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Seating Cap"}</p>}
                    style={{ marginBottom: 2, width: "calc(100%)" }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.seatingCap}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) =>
                            setState && setState("seatingCap", e.target.value)
                          }
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Engine No"}</p>}
                    style={{ marginBottom: 2, width: "calc(100%)" }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.engineNo}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("engineNo", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Tyre Size(F/M/R):"}</p>}
                    style={{ marginBottom: 2, width: "calc(100%)" }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(32%)",
                        marginRight: "2%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.tyreSizeF}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("tyreSizeF", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(32%)",
                        marginRight: "2%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.tyreSizeM}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("tyreSizeM", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(32%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.tyreSizeR}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("tyreSizeR", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Other Expense"}</p>}
                    style={{ marginBottom: 0, width: "calc(100%)" }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Form.Item
                      name="issuedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(49%)",
                        marginRight: "2%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={states.other}
                          size="small"
                          allowClear
                          placeholder=""
                          onChange={(e: any) => {
                            setState && setState("other", e.target.value);
                          }}
                          disabled={DisableForm}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="issuedBy"
                      style={{ display: "inline-block", width: "calc(49%)" }}
                    >
                      <SelectsTemplate
                        style={{ width: "100%" }}
                        disabled={DisableForm}
                        selectedValue={(e: any) => {
                          setState &&
                            setState("currency", {
                              id: e.curIDpk,
                              value: e.curSymbol,
                            });
                        }}
                        dataexp={"curSymbol"}
                        idexpr={"curIDpk"}
                        options={currency_data}
                        useCallFunc={true}
                        handleRefresh={() => {}}
                        placeHolder={states.currency}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Reg Date"}</p>}
                    style={{ marginBottom: 0 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "0.9%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          value={
                            states.regDate === ""
                              ? undefined
                              : dayjs(states.regDate)
                          }
                          disabledDate={(current) =>
                            current && current.valueOf() >= Date.now()
                          }
                          disabled={DisableForm}
                          size="small"
                          placeholder=""
                          style={{ width: "49%" }}
                          format={dateFormat}
                          onChange={(e: any, dataString: any) => {
                            setState &&
                              setState("regDate", dayjs(dataString).format());
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </Form>

                {/* Four */}
                <Form
                  {...formPropsConst}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 20 }}
                  className=""
                  style={{ width: "calc(100% - 80%)", marginLeft: "5%" }}
                >
                  <div className="w-full space-y-1">
                    <Image
                      style={{ borderColor: borderTheme }}
                      width={240}
                      height={115}
                      className={`text-xs border-[1px]`}
                      src={
                        !showImage
                          ? invalidImage
                          : `data:image/png;base64, ${images_data[0]?.vimImage}`
                      }
                      alt="Vehicle Image"
                      onError={(e) => {
                        e.currentTarget.src = invalidImage;
                      }}
                    />
                    <Image
                      style={{ borderColor: borderTheme }}
                      width={240}
                      height={115}
                      className={"text-xs border-[1px]"}
                      src={
                        !showImage
                          ? invalidImage
                          : `data:image/png;base64, ${images_data[1]?.vimImage}`
                      }
                      alt="Vehicle Image"
                      onError={(e) => {
                        e.currentTarget.src = invalidImage;
                      }}
                    />
                    <Image
                      style={{ borderColor: borderTheme }}
                      width={240}
                      height={115}
                      className={` text-xs  border-[1px]`}
                      src={
                        !showImage
                          ? invalidImage
                          : `data:image/png;base64, ${images_data[2]?.vimImage}`
                      }
                      alt="Vehicle Image"
                      onError={(e) => {
                        e.currentTarget.src = invalidImage;
                      }}
                    />
                  </div>

                  <div className="mt-1 flex">
                    <CheckboxTemlate
                      withBorders={true}
                      defaultValue={showImage}
                      setCheckboxOnchange={(val) => setShowImages!(val)}
                      customDisabled={false}
                      useCallbackFunc={true}
                    />
                    <span className="ml-2 flex"> Show Images Auto</span>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}

        <div className="pr-2 pt-0.5">
          <Datagrid_template
            gridheight={!toggled ? pageHeight : fullPage}
            columns={vehicleSimCard_TH}
            data={mainDataGrid}
            disableSearch={false}
            disableGroupPanel={false}
            disablePaging={false}
            onRowClick={(val) => {
              setSelectedRecordx && setSelectedRecordx(val);
              //----------- eagleMentality🦅------------------
              Vdispatch(setDisableForm(true));
              Vdispatch(setSave_Update_Button(true));
              Vdispatch(setEdit_Cancel_Button(true));
              Vdispatch(setNew_Cancel_Button(true));
              //--------------------------------------------
            }}
            rowDoubleClicked={(data) => {
              AccessPriv_on_DataGridDoubleClick(data);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleDetsDetails;
