import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../app/store";
import { useEffect } from "react";

export const useToken = (): string => {
  const token = useSelector((state: RootState) => state.user?.user?.token); //token
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  //will use this to protect routes based on tokens

  return token;
};
