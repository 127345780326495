/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { elogMainColumnsPriv } from "./data/elogBookDataPriv";
import ElogBookForm from "./widgets/elogBookFormPriv";
import useFetch from "../../../../../../hooks/useFetch";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  searchTextTypes,
  searchCriteriaTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { ModalTemplate } from "../../../../../../templates/modal";
import ElogUserSetup from "./widgets/elogUserSetup";
import ElogStationSetup from "./widgets/elogStationSetup";
import { useCrudFunc } from "../../../../../../functions/crud";

import { Tooltip } from "devextreme-react";
import cancelCalenderIcon from "../../../../../../assets/calendar_cancel.png";
import { useMessageModal } from "../../../../../../functions/Dry/Dry";
import { setGeneralValue } from "../../../../../../features/generalSlice";
export const ElogBookMainPriv = forwardRef(({ a }: any, ref) => {
  const formRef = useRef<any>();
const dispatch = useDispatch()
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const setupPageRoute = window.location.pathname;
  const datagridOnlySearchParam = "table-only";
  const formModeSearchParam = "form-and-table";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const miscHeight = 7;

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  const [messageModal, setMessageModal] = useMessageModal();
  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [form_height] = useState<number>(386);
  const [_, UpdateDataFunc] = useCrudFunc("powerapp");
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    if (view === null || view === formModeSearchParam) {
      navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: datagridOnlySearchParam,
        }).toString(),
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    } else {
      navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: formModeSearchParam,
        }).toString(),
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    }
  };
  const toggleAllAssignment = async () => {
    setMessageModal((prev) => ({
      ...prev,
      icon: "question",
      title: "Toggle all user-view assignment?",
      message: `This action is irreversible. \n Are you sure you want to toggle all substation assignment for ${
        selectedRecord?.empName
      } to ${!selectedRecord?.pvaActive}?`,
      okText: "Yes",
      cancelText: "No",
      openModal: true,
      hideCancel: false,
      okHandler: async () => {
        setMessageModal((prev) => ({ ...prev, openModal: false }));
        setBusyLoader(
          `Toggling all substation assignment for ${selectedRecord?.empName}...`
        );
        await UpdateDataFunc(
          `ElogBookPriviledgesAssignment/ActivateAllAssignment`,
          {
            pvaActive: !selectedRecord?.pvaActive,
            paaEmpIDfk: selectedRecord?.pvaEmpIDfk,
          },
          `updated with active=${selectedRecord?.pvaActive} employee Id=${selectedRecord?.pvaEmpIDfk}`
        )
          .then(() => {
            setBusyLoader("");
            setMessageModal((prev) => ({
              ...prev,
              icon: "success",
              title: "Success",
              message: `All Substation assignment for ${selectedRecord?.empName} toggled successfully`,
              okText: "Okay",
              openModal: true,
              hideCancel: true,
              okHandler: () => {
                setMessageModal((prev) => ({ ...prev, openModal: false }));
              },
            }));
            setRefresh(!refresh);
            setRefreshFromForm(!refreshFromForm);
          })
          .catch(() => {
            setBusyLoader("");
            setMessageModal((prev) => ({
              ...prev,
              icon: "warning",
              title: `Unable to toggle all substation assignment for ${selectedRecord?.empName}`,
              message: "Please contact the systems administrator",
              okText: "Okay",
              openModal: true,
              hideCancel: true,
              okHandler: () => {
                setMessageModal((prev) => ({ ...prev, openModal: false }));
              },
            }));
          });
      },
      cancelHandler: () => {
        setBusyLoader("");
        setMessageModal((prev) => ({ ...prev, openModal: false }));
      },
    }));
  };
  const toggleSingleAssignment = async () => {
    setMessageModal((prev) => ({
      ...prev,
      icon: "question",
      title: "Switch active status?",
      message: `Are you sure you want to switch the active status of ${
        selectedRecord?.empName
      } to ${!selectedRecord?.pvaActive}?`,
      okText: "Yes",
      cancelText: "No",
      openModal: true,
      hideCancel: false,
      okHandler: async () => {
        setMessageModal((prev) => ({ ...prev, openModal: false }));
        setBusyLoader("Switching Active Status...");
        await UpdateDataFunc(
          `ElogBookPriviledgesAssignment/ActivateSingleAssignment`,
          {
            pvaIDpk: selectedRecord?.pvaIDpk,
            pvaActive: !selectedRecord?.pvaActive,
          },
          `updated with Id=${
            selectedRecord?.pvaIDpk
          } and active =${!selectedRecord?.pvaActive}`
        )
          .then(() => {
            setBusyLoader("");
            setRefresh(!refresh);
            setRefreshFromForm(!refreshFromForm);
            setMessageModal((prev) => ({
              ...prev,
              icon: "success",
              title: "Success",
              message:
                "Active status for selected priviledge switched successfully",
              okText: "Okay",
              openModal: true,
              hideCancel: true,
              okHandler: () => {
                setMessageModal((prev) => ({ ...prev, openModal: false }));
              },
            }));
          })
          .catch(() => {
            setBusyLoader("");
            setMessageModal((prev) => ({
              ...prev,
              icon: "warning",
              title: "Active status for selected privileges not switched",
              message: "Please contact the systems administrator",
              okText: "Okay",
              openModal: true,
              hideCancel: true,
              okHandler: () => {
                setMessageModal((prev) => ({ ...prev, openModal: false }));
              },
            }));
          });
      },
      cancelHandler: () => {
        setBusyLoader("");
        setMessageModal((prev) => ({ ...prev, openModal: false }));
      },
    }));
  };
  const [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
  );

  //Table Height
  var pageHeight = resizeMode - (navbar_height + miscHeight + 480);
  var tableHeight = initialResizeMode - (heights_out + miscHeight);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [refreshFromForm, setRefreshFromForm] = useState(false);
  const [updateMode, setupdateMode] = useState<boolean>(false);
  const [staffDataReady, setStaffDataReady] = useState<boolean>(false);
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [
    searchParamsForMainFormGetAllEndpoint,
    setsearchParamsForMainFormGetAllEndpoint,
  ] = useState<any>({
    searchTerm: { initialValue: "", value: "" },
    searchCriteria: { initialValue: 0, value: 0 },
    checkState: { initialValue: "", value: "" },
    pageNumber: 1,
    pageSize: 20,
  });
  let { searchTerm, searchCriteria, checkState, pageNumber, pageSize } =
    searchParamsForMainFormGetAllEndpoint;

  console.log("selectedRecord", selectedRecord);

  let criteria = [
    { id: 0, value: "Substation" },
    { id: 1, value: "Operator" },
  ];

  const [userGroups, setUserGroups] = useState(false);
  const [stationGroups, setStationGroups] = useState(false);

  const [
    GetAllElogBookPivilegesAssignment,
    GetAllElogBookPrivilegesAssignmentError,
    GetAllElogBookPrivilegesAssignmentLoading,
  ] = useFetch(
    `ElogBookPriviledgesAssignment/GetAllElogBookPrivilegesAssignment?SearchTerm=${searchTerm.value}&CheckState=${checkState.value}&PageNumber=${pageNumber}&PageSize=${pageSize}&SearchCriteria=${searchCriteria.value}`,
    refresh,
    "pvaIDpk",
    false,
    "powerapp"
  );
  useEffect(() => {
    GetAllElogBookPrivilegesAssignmentLoading === true
      ? setBusyLoader("Fetching record....")
      : setBusyLoader("");
  }, [GetAllElogBookPrivilegesAssignmentLoading]);

  return (
    <>
      {/* main grid error */}
      {GetAllElogBookPrivilegesAssignmentError && <WarningAlert />}
      <ModalTemplate
        icon={messageModal.icon}
        title={messageModal.title}
        message={messageModal.message}
        okText={messageModal.okText}
        cancelText={messageModal.cancelText}
        open={messageModal.openModal}
        okHandler={messageModal.okHandler}
        cancelHandler={messageModal.cancelHandler}
        disableCancel={messageModal.hideCancel}
      />
      <ModalTemplate
        disableCancel
        width={"95%"}
        customComponent={<ElogUserSetup />}
        title={"eLog Book - User Groups"}
        message={""}
        cancelHandler={() => setUserGroups(false)}
        open={userGroups}
        okHandler={() => {}}
      />
      <ModalTemplate
        disableCancel
        width={"95%"}
        customComponent={<ElogStationSetup />}
        title={"eLog Book - Station Groups"}
        message={""}
        cancelHandler={() => setStationGroups(false)}
        open={stationGroups}
        okHandler={() => {}}
      />

      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            searchCriteriaData={criteria}
            handleRefresh={() => {
              setRefresh(!refresh);
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParamsForMainFormGetAllEndpoint.searchTerm,
                  initialValue: "",
                  value: "",
                },
                searchCriteria: {
                  ...searchParamsForMainFormGetAllEndpoint.searchCriteria,
                  initialValue: 0,
                  value: 0,
                },
                checkState: {
                  ...searchParamsForMainFormGetAllEndpoint.checkState,
                  initialValue: "",
                  value: "",
                },
                pageNumber: 1,
                pageSize: 20,
              }));
            }}
            searchTextOnchange={(text) =>
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParamsForMainFormGetAllEndpoint.searchTerm,
                  initialValue: text,
                },
              }))
            }
            searchCriteriaOnchange={(crit) =>
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                searchCriteria: {
                  ...searchParamsForMainFormGetAllEndpoint.searchCriteria,
                  initialValue: crit,
                },
              }))
            }
            checkOnChange={(check) => {
              console.log("check", check);
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                checkState: {
                  ...searchParamsForMainFormGetAllEndpoint.checkState,
                  initialValue: check,
                },
              }));
            }}
            handleFind={() => {
              setRefresh((current) => !current);
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParamsForMainFormGetAllEndpoint.searchTerm,
                  value:
                    searchParamsForMainFormGetAllEndpoint.searchTerm
                      .initialValue,
                },
                searchCriteria: {
                  ...searchParamsForMainFormGetAllEndpoint.searchCriteria,
                  value:
                    searchParamsForMainFormGetAllEndpoint.searchCriteria
                      .initialValue,
                },
                checkState: {
                  ...searchParamsForMainFormGetAllEndpoint.checkState,
                  value:
                    searchParamsForMainFormGetAllEndpoint.checkState
                      .initialValue,
                },
              }));
            }}
            handleToggle={() => formRef.current?.toggle(selectedRecord)}
            handleToggleAll={() => formRef.current?.toggleAll(selectedRecord)}
            searchCriteria
            disableNew_edit
            withDates={false}
            toggler={toggle_staff_form}
            userGroup
            stationGroup
            UserGroupModal={() => setUserGroups(true)}
            StationGroupModal={() => setStationGroups(true)}
            showPrintIcon
            Cancel_icon
            Calender_Cancel_icon
            handle_Cancel={() => {
              toggleSingleAssignment();
            }}
            handle_Calender_Cancel={() => {
              toggleAllAssignment();
            }}
          />
        </div>

        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4">
            <ElogBookForm
              // ref={formRef}
              staffDatagrid={(status: any) => setStaffDataReady(status)}
              refresh={() => {
                setRefresh(!refresh);
              }}
              updateMode={updateMode}
              refreshEverything={refreshFromForm}
            />
          </section>
        )}

        <section className=" px-2">
          <Datagrid_template
            onRowClick={(selected) => {
              setSelectedRecord(selected);
              formRef.current?.setSelectedRecord(selected);
            }}
            pageNumber={searchParamsForMainFormGetAllEndpoint.pageNumber}
            pageSize={searchParamsForMainFormGetAllEndpoint.pageSize}
            handlePageNumber={(e) => {
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                pageNumber: e,
              }));
            }}
            handlePageSize={(e) => {
              setsearchParamsForMainFormGetAllEndpoint((prev: any) => ({
                ...prev,
                pageSize: e,
              }));
            }}
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? pageHeight
                : tableHeight
            }
            data={GetAllElogBookPivilegesAssignment}
            selectionMode={"single"}
            columns={elogMainColumnsPriv}
          />
        </section>
      </div>
    </>
  );
});
