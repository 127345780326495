/* eslint-disable react/jsx-pascal-case */
import { DateTemplate } from "../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { SaveButton } from "../../../../../accessories/buttons";
import MiniToolbar from "../../_widget/minitollbar";
import { Switcher } from "../../_widget/switcher";
import { RefreshButton } from "../../_widget/utils";
import Datagrid_template from "../../../../../../templates/Datagrid";
import EquimentDataTable from "../EquipmentOutage/equipment-data-table";
import { ReadingsChart } from "../../_widget/readingschart";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import {
  transformerColumns,
  transformerData,
  transformerFormColumns,
} from "../BusVoltage/_data/stationentriescolumns";
import { useAxiosService } from "../../../../../../general/requestConfig";
import { useEffect, useRef, useState } from "react";
import EquipmentToolbar from "../EquipmentOutage/equipment-toolbar";
import {
  dbDateFormat,
  startingDate,
  useModalData,
} from "../../../../../accessories/component_infos";
import { statusTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useCrudFunc } from "../../../../../../functions/crud";
import { AxiosError } from "axios";

export default function TransformerReadings() {
  const { status } = useGraphSwitcher();
  const query = useAxiosService("powerapp");
  const dispatch = useDispatch();
  const [modal, setModal] = useModalData();
  const [posting, updating] = useCrudFunc("powerapp");

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string>(
    dayjs(startingDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"));

  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [refreshView, setRefreshView] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    number: 1,
    size: 20,
  });
  const [refreshKeys, setRefreshKeys] = useState<Record<string, boolean>>({
    station: false,
    transformer: false,
  });

  const [allowUpdate, setAllowUpdate] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled mode from general redux slice

  const { status: switchStatus, setWitch } = useSwitcher();
  const recordSwitch = useRef<boolean>();

  useEffect(() => {
    // when switch changes, store the current switch status inside `recordSwitch` ref, this is to prevent state loss when there are any re-renders
    recordSwitch.current = switchStatus;
  }, [switchStatus]);
  const [formData, setFormData] = useState({
    trdStationIDfk: undefined,
    trdTransformerIDfk: undefined,
    trdTime: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    trdCurrent: undefined,
    trdVoltage: undefined,
    trdTapChanger: undefined,
    trdActivePower: undefined,
    trdReactivePower: undefined,
    trdMinimumOilTemperature: undefined,
    trdMaximumOilTemperature: undefined,
    trdMinimumWindingTemperature1: undefined,
    trdMaximumWindingTemperature1: undefined,
    trdMinimumWindingTemperature2: undefined,
    trdMaximumWindingTemperature2: undefined,
    trdRmks: "string",
    trdCreatedBy: undefined,
    formCode: "string",
    formAction: "string",
  });

  const SaveRecord = async () => {
    try {
      const postResponse = await posting(
        `TransformerReadings/AddTransformerReadings`,
        { ...formData },
        `Created Transformer reading for ${
          formData.trdStationIDfk
        } station with transformer ID ${
          formData.trdTransformerIDfk
        }, on ${dayjs(formData.trdTime).format("YYYY-MM-DD")} at ${dayjs(
          formData.trdTime
        ).format("HH:mm:ss")} `
      );
      if (postResponse.status) {
        setModal({
          state: true,
          title: "Save Record",
          message: `Record saved successfully`,
          icon: "success",
          okText: "Okay",
          cancelText: "No, Cancel",
          disableCancel: true,
          okHandler: () => {
            setModal((prev) => ({
              ...prev,
              state: false,
            }));
          },
          cancelHandler: () => {},
        });
        setRefreshView((prev) => !prev);
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        let err = error.response?.data?.errors;
        let errMessage = (err as []).map((e: string, _i) => (
          <p className="text-rose-600 text-sm font-semibild">{`${
            _i + 1
          }. ${e}`}</p>
        ));
        if (err) {
          setModal({
            state: true,
            title: `${err.length} errors while saving`,
            message: errMessage,
            icon: "warning",
            okText: "Ok",
            cancelText: "No, Cancel",
            disableCancel: true,
            okHandler: () => {
              setModal((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {},
          });
        }
      }
    } finally {
      // setBusyLoader("");
    }

    return;
  };
  const UpdateRecord = async () => {
    try {
      const postResponse = await updating(
        `TransformerReadings/UpdateTransformerReadings`,
        { ...formData, trdIDpk: Number(rowData?.trdIDpk), trdEditedBy: 1 },
        `Created Transformer reading for ${
          formData.trdStationIDfk
        } station with transformer ID ${
          formData.trdTransformerIDfk
        }, on ${dayjs(formData.trdTime).format("YYYY-MM-DD")} at ${dayjs(
          formData.trdTime
        ).format("HH:mm:ss")} `
      );
      if (postResponse.status) {
        setModal({
          state: true,
          title: "Save Record",
          message: `Record saved successfully`,
          icon: "success",
          okText: "Okay",
          cancelText: "No, Cancel",
          disableCancel: true,
          okHandler: () => {
            setModal((prev) => ({
              ...prev,
              state: false,
            }));
          },
          cancelHandler: () => {},
        });
        setRefreshView((prev) => !prev);
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        let err = error.response?.data?.errors;
        let errMessage = (err as []).map((e: string, _i) => (
          <p className="text-rose-600 text-sm font-semibild">{`${
            _i + 1
          }. ${e}`}</p>
        ));
        if (err) {
          setModal({
            state: true,
            title: `${err.length} errors while saving`,
            message: errMessage,
            icon: "warning",
            okText: "Ok",
            cancelText: "No, Cancel",
            disableCancel: true,
            okHandler: () => {
              setModal((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {},
          });
        }
      }
    } finally {
      // setBusyLoader("");
    }

    return;
  };
  const isFieldsValid = (): boolean => {
    if (!formData.trdStationIDfk) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please select a station`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdTransformerIDfk) {
      setModal({
        state: true,
        title: "Save Record",
        message: `There is no transformer for which you can capture temperature data for`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdTime) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please select a date and time`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdVoltage) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter voltage value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdCurrent) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter current value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdActivePower) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter active power value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdReactivePower) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter reactive power value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdMinimumWindingTemperature1) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter minimum winding temperature 1 value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdMaximumWindingTemperature1) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter maximum winding temperature 1 value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdMinimumOilTemperature) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter minimum oil temperature value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdMaximumOilTemperature) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter maximum oil temperature value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }

    if (!formData.trdMinimumWindingTemperature2) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter minimum winding temperature 2 value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    if (!formData.trdMaximumWindingTemperature2) {
      setModal({
        state: true,
        title: "Save Record",
        message: `Please enter maximum winding temperature 2 value`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    return true;
  };

  const [stations, setStations] = useState<Station[]>();
  const [transformerReadings, setTransformerReadings] =
    useState<TransformerData[]>();
  const [transformerDetails, setTransformerDetails] =
    useState<TransformerDetails[]>();

  const [rowData, setRowData] = useState<TransformerData>();

  //prepare form fields to update
  useEffect(() => {
    if (allowUpdate && rowData) {
      setFormData({
        trdStationIDfk: rowData?.stnIDpk,
        trdTransformerIDfk: rowData?.tfdIDpk,
        trdTime: rowData?.trdDate ?? "",
        trdCurrent: rowData?.trdCurrent,
        trdVoltage: rowData?.trdVoltage,
        trdTapChanger: rowData?.trdTapChanger,
        trdActivePower: rowData?.trdActivePower,
        trdReactivePower: rowData?.trdReactivePower,
        trdMinimumOilTemperature: rowData?.trdMinimumOilTemperature,
        trdMaximumOilTemperature: rowData?.trdMaximumOilTemperature,
        trdMinimumWindingTemperature1: rowData?.trdMinimumWindingTemperature1,
        trdMaximumWindingTemperature1: rowData?.trdMaximumWindingTemperature1,
        trdMinimumWindingTemperature2: rowData?.trdMinimumWindingTemperature2,
        trdMaximumWindingTemperature2: rowData?.trdMaximumWindingTemperature2,
        trdRmks: rowData?.trdRmks ?? "",
        trdCreatedBy: 0,
        formCode: "string",
        formAction: "string",
      });
    }
  }, [allowUpdate, rowData]);

  // fetch stations
  useEffect(() => {
    query({
      method: "GET",
      url: "TransformerReadings/GetTrafoStations",
    }).then((res) => {
      setStations(res?.data ?? []);
    });
  }, [refreshKeys.station]);

  // fetch transformer details
  useEffect(() => {
    query({
      method: "GET",
      url: "TransformerReadings/PopGdcTransformerDetails",
      params: {
        id: formData?.trdStationIDfk,
      },
    }).then((res) => {
      setTransformerDetails(res?.data ?? []);
    });
  }, [refreshKeys.transformer, formData.trdStationIDfk]);

  // fetch transformer readings
  useEffect(() => {
    query({
      method: "GET",
      url: "TransformerReadings/PopGdcTransformerReadings",
      params: {
        startDate: startDate,
        endDate: endDate,
        searchTerm: searchValue?.length > 0 ? searchValue : undefined,
        pageNumber: pagination.number,
        pageSize: pagination.size,
        checkState: activeStatus.temp !== null ? activeStatus.temp : "",
      },
    }).then((res) => {
      setTransformerReadings(res?.data ?? []);
    });
  }, [refreshView, pagination.number, pagination.size]);

  return (
    <section className="w-full h-full">
      <ModalTemplate
        width={500}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />
      <Switcher alternate={""}>
        {/* top section */}
        <section className="flex space-x-3 w-full border-2 p-2">
          {/* left section */}
          <aside className="w-[250px]">
            <aside className="flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400">
              <p className="text-sm font-medium">STATION</p>
              <RefreshButton
                onClick={() => {
                  setRefreshKeys((state) => ({
                    ...state,
                    station: !state.station,
                  }));
                }}
              />
            </aside>
            <aside>
              <EquimentDataTable
                dataId="stnIDpk"
                deselectFirstRow
                gridheight={270}
                showColumnHeaders={false}
                columns={[
                  {
                    id: 1,
                    caption: "STATION",
                    dataField: "stnName",
                  },
                ]}
                data={stations}
                disablePaging
                disableSearch
                disableGroupPanel
                onRowClick={(record: Station) => {
                  setFormData((prev) => ({
                    ...prev,
                    trdStationIDfk: record?.stnIDpk,
                  }));
                }}
              />
            </aside>
          </aside>
          {/* right section */}
          <aside className="w-[88%] items-right overflow-hidden pl-0.5">
            <section className="flex items-start ml-[143px]">
              <p className="text-[12px] font-medium mr-2">Transformer:</p>
              <EquimentDataTable
                dataId="tfdIDpk"
                deselectFirstRow
                gridheight={100}
                showColumnHeaders={true}
                columns={transformerFormColumns}
                data={transformerDetails}
                disablePaging
                disableSearch
                disableGroupPanel
                onRowClick={(record: TransformerDetails) => {
                  setFormData((prev) => ({
                    ...prev,
                    trdTransformerIDfk: record.tfdIDpk,
                  }));
                }}
              />
            </section>
            <section className="w-full h-full mt-1">
              <section className="flex items-start justify-between">
                <aside className="flex items-center w-full ml-[180px] mb-0.5 ">
                  <p className="mr-[6px]">Time:</p>
                  <DateTemplate
                    showTime
                    style={{ width: 230, height: 32 }}
                    className="w-full"
                    selectedDate={dayjs(formData?.trdTime)}
                    changeDate={(date) => {
                      setFormData((prev) => ({
                        ...prev,
                        trdTime: dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                      }));
                    }}
                  />
                </aside>
                <aside>
                  <RefreshButton
                    onClick={() => {
                      setRefreshKeys((state) => ({
                        ...state,
                        transformer: !state.transformer,
                      }));
                    }}
                  />
                </aside>
              </section>
              <section className="flex space-x-2 items-center">
                <aside className="flex space-x-2 items-center">
                  <section className="space-y-0.5 ">
                    <section className="flex space-x-1">
                      <aside className="ml-[70px]">
                        <InputsTemplate
                          useCallbackFunc
                          // numberOnly
                          orderOnchange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              trdVoltage: Number(e),
                            }));
                          }}
                          span
                          inputStyle={{ width: 114 }}
                          label="Voltage (kv) & Current (A)"
                          labelColPan={3}
                          placeholder={formData?.trdVoltage}
                          defaultValue={formData?.trdVoltage}
                        />
                      </aside>
                      <InputsTemplate
                        useCallbackFunc
                        // numberOnly
                        span
                        inputStyle={{ width: 114 }}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdCurrent: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdCurrent}
                        defaultValue={formData?.trdCurrent}
                      />
                    </section>
                    <section className="flex space-x-1 ml-4">
                      <InputsTemplate
                        useCallbackFunc
                        // numberOnly
                        // disabledStatus={false}
                        span
                        inputStyle={{ width: 114 }}
                        label="Active (kW) & Reactive Power (kVAr)"
                        labelColPan={12}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdActivePower: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdActivePower}
                        defaultValue={formData?.trdActivePower}
                      />
                      <InputsTemplate
                        useCallbackFunc
                        // numberOnly
                        // disabledStatus={false}
                        span
                        inputStyle={{ width: 114 }}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdReactivePower: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdReactivePower}
                        defaultValue={formData?.trdReactivePower}
                      />
                    </section>
                    <section className="flex space-x-1 -ml-0.5">
                      <InputsTemplate
                        // numberOnly
                        useCallbackFunc
                        // disabledStatus={false}
                        span
                        inputStyle={{ width: 114 }}
                        label="Min & Max Winding Temperature 1 (°C)"
                        labelColPan={12}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdMinimumWindingTemperature1: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdMinimumWindingTemperature1}
                        defaultValue={formData?.trdMinimumWindingTemperature1}
                      />
                      <InputsTemplate
                        useCallbackFunc
                        // numberOnly
                        // disabledStatus={false}
                        span
                        inputStyle={{ width: 114 }}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdMaximumWindingTemperature1: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdMaximumWindingTemperature1}
                        defaultValue={formData?.trdMaximumWindingTemperature1}
                      />
                    </section>
                  </section>
                </aside>
                <aside className="flex space-x-2 items-center">
                  <section className="space-y-0.5 ">
                    <section className="flex space-x-1">
                      <aside className="ml-[70px]">
                        <InputsTemplate
                          useCallbackFunc
                          span
                          inputStyle={{ width: 114 }}
                          label="Tap Counter & Position"
                          labelColPan={3}
                          // numberOnly
                          defaultValue={formData?.trdTapChanger}
                          orderOnchange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              trdTapChanger: Number(e),
                            }));
                          }}
                        />
                      </aside>
                      <InputsTemplate
                        useCallbackFunc
                        // disabledStatus={false}
                        span
                        // numberOnly
                        inputStyle={{ width: 114 }}
                        defaultValue={formData?.trdTapChanger}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdTapChanger: Number(e),
                          }));
                        }}
                      />
                    </section>
                    <section className="flex space-x-1 ml-5">
                      <InputsTemplate
                        useCallbackFunc
                        // disabledStatus={false}
                        span
                        // numberOnly
                        inputStyle={{ width: 114 }}
                        label="Min & Max Oil Temperatures (°C)"
                        labelColPan={12}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdMinimumOilTemperature: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdMinimumOilTemperature}
                        defaultValue={formData?.trdMinimumOilTemperature}
                      />
                      <InputsTemplate
                        useCallbackFunc
                        // disabledStatus={false}
                        span
                        // numberOnly
                        inputStyle={{ width: 114 }}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdMaximumOilTemperature: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdMaximumOilTemperature}
                        defaultValue={formData?.trdMaximumOilTemperature}
                      />
                    </section>
                    <section className="flex space-x-1 ml-4">
                      <InputsTemplate
                        useCallbackFunc
                        // disabledStatus={false}
                        span
                        // numberOnly
                        inputStyle={{ width: 114 }}
                        label="Min & Max Winding Temps. 2 (°C)"
                        labelColPan={12}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdMinimumWindingTemperature2: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdMinimumWindingTemperature2}
                        defaultValue={formData?.trdMinimumWindingTemperature2}
                      />
                      <InputsTemplate
                        useCallbackFunc
                        // numberOnly
                        // disabledStatus={false}
                        span
                        inputStyle={{ width: 114 }}
                        orderOnchange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            trdMaximumWindingTemperature2: Number(e),
                          }));
                        }}
                        placeholder={formData?.trdMaximumWindingTemperature2}
                        defaultValue={formData?.trdMaximumWindingTemperature2}
                      />
                      <SaveButton
                        disableButton={disableButton}
                        useCallbackFunc={true}
                        title={allowUpdate ? "Update" : "Save"}
                        handleSave={() => {
                          const isVaid = isFieldsValid();
                          if (isVaid) {
                            setModal({
                              state: true,
                              title: !allowUpdate
                                ? `Save Record`
                                : `Update Record`,
                              message: !allowUpdate
                                ? `Are you sure you want to save this record?`
                                : `Are you sure you want to update this record?`,
                              icon: "question",
                              okText: !allowUpdate
                                ? `Yes, Save`
                                : `Yes, Update`,
                              cancelText: "No, Cancel",
                              disableCancel: false,
                              okHandler: async () => {
                                !allowUpdate
                                  ? await SaveRecord()
                                  : await UpdateRecord();
                              },
                              cancelHandler: () => {
                                setModal((prev) => ({
                                  ...prev,
                                  state: false,
                                }));
                              },
                            });
                          }
                        }}
                      />
                    </section>
                  </section>
                </aside>
              </section>
              <section className="flex h-full mt-0.5 ml-[158px]">
                <p className="text-sm mr-2">Remarks: </p>
                <aside className="w-full">
                  <TextareaTemplate
                    height={75}
                    defaultValue={formData?.trdRmks}
                    useCallbackFunc
                    setCallbackValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        trdRmks: String(e),
                      }));
                    }}
                  />
                </aside>
              </section>
            </section>
          </aside>
        </section>
        <div className="h-1 w-full bg-sky-200" />
      </Switcher>
      {/* botom section */}
      <section className="flex flex-col">
        <EquipmentToolbar
          key={Math.random() * 100}
          searchTextOnchange={(searchValue) => {
            setSearchValue(searchValue);
            // setSearchText({ temp: searchValue, text: searchText.text });
          }}
          searchValue={searchValue}
          checkOnChange={(check) => {
            alert("check is: " + check);
            setActiveStatus({ temp: check, status: activeStatus.status });
          }}
          startDateChanged={(startDateV) => {
            setStartDate(startDateV);
            // setStartDate({
            //   temp: dayjs(startDateV).format("YYYY-MM-DD"),
            //   index: startDate.index,
            // });
          }}
          endDateChanged={(endDateV) => {
            setEndDate(endDateV);
            // setEndDate({
            //   temp: dayjs(endDateV).format("YYYY-MM-DD"),
            //   index: endDate.index,
            // });
          }}
          handleFind={() => {
            setActiveStatus({
              temp: activeStatus.temp,
              status: activeStatus.temp,
            }); //active status
            setRefreshView((prev) => !prev);
          }}
          handleSwitcher={setWitch}
          runNew={() => {
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            setAllowUpdate(false);
            setDisableButton(false);
            setFormData({
              trdStationIDfk: undefined,
              trdTransformerIDfk: undefined,
              trdTime: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              trdCurrent: undefined,
              trdVoltage: undefined,
              trdTapChanger: undefined,
              trdActivePower: undefined,
              trdReactivePower: undefined,
              trdMinimumOilTemperature: undefined,
              trdMaximumOilTemperature: undefined,
              trdMinimumWindingTemperature1: undefined,
              trdMaximumWindingTemperature1: undefined,
              trdMinimumWindingTemperature2: undefined,
              trdMaximumWindingTemperature2: undefined,
              trdRmks: "",
              trdCreatedBy: undefined,
              formCode: "string",
              formAction: "string",
            });
            recordSwitch.current && setWitch();
          }}
          //   handleSwitcher={setWitch}
          handleRefresh={() => {
            setStartDate(startingDate);
            setEndDate(dayjs().format(dbDateFormat));
            setSearchValue(undefined);
            setPagination({ number: 1, size: 20 });
            setRefreshView((prev) => !prev);
          }}
          runUpdate={async () => {
            if (!rowData?.stnIDpk) {
              setModal({
                state: true,
                title: "Update Record",
                message: `Please select a record to update`,
                icon: "warning",
                okText: "Ok",
                cancelText: "No, Cancel",
                disableCancel: true,
                okHandler: () => {
                  setModal((prev) => ({
                    ...prev,
                    state: false,
                  }));
                },
                cancelHandler: () => {},
              });
              return;
            }
            //  check if user can update

            setDisableButton(false);
            setAllowUpdate(true);
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            recordSwitch.current && setWitch();
          }}
          startDate={startDate}
          endDate={endDate}
        />
        <section className="flex-grow">
          <section className="w-full h-full">
            {status ? (
              <ReadingsChart />
            ) : (
              <EquimentDataTable
                dataId="trdIDpk"
                deselectFirstRow
                gridheight={415}
                showColumnHeaders={true}
                columns={transformerColumns}
                data={transformerReadings}
                disableGroupPanel
                pageNumber={pagination.number}
                pageSize={pagination.size}
                handlePageNumber={(page) => {
                  setPagination((state) => ({ ...state, number: page }));
                }}
                handlePageSize={(size) => {
                  setPagination((state) => ({ ...state, size }));
                }}
                rowDoubleClicked={(record: TransformerData) => {
                  recordSwitch.current && setWitch();
                  setRowData(record);
                  dispatch(
                    setGeneralValue({
                      expr: "formDisabled",
                      value: false,
                    })
                  );
                  setAllowUpdate(true);
                  setDisableButton(false);
                }}
                onRowClick={(record: TransformerData) => {
                  setRowData(record);
                  dispatch(
                    setGeneralValue({
                      expr: "formDisabled",
                      value: true,
                    })
                  );
                  setDisableButton(true);
                  setAllowUpdate(false);
                  setFormData({
                    trdStationIDfk: record.stnIDpk,
                    trdTransformerIDfk: record.tfdIDpk,
                    trdTime: record.trdDate,
                    trdCurrent: record.trdCurrent,
                    trdVoltage: record.trdVoltage,
                    trdTapChanger: record.trdTapChanger,
                    trdActivePower: record.trdActivePower,
                    trdReactivePower: record.trdReactivePower,
                    trdMinimumOilTemperature: record.trdMinimumOilTemperature,
                    trdMaximumOilTemperature: record.trdMaximumOilTemperature,
                    trdMinimumWindingTemperature1:
                      record.trdMinimumWindingTemperature1,
                    trdMaximumWindingTemperature1:
                      record.trdMaximumWindingTemperature1,
                    trdMinimumWindingTemperature2:
                      record.trdMinimumWindingTemperature2,
                    trdMaximumWindingTemperature2:
                      record.trdMaximumWindingTemperature2,
                    trdRmks: record.trdRmks,
                    trdCreatedBy: 0,
                    formCode: "string",
                    formAction: "string",
                  });
                }}
              />
            )}
          </section>
        </section>
      </section>
    </section>
  );
}
type TransformerData = {
  trdIDpk: number;
  stnIDpk: number;
  tfdIDpk: number;
  stnName: string;
  tfdTransformerID: string;
  trdDate: string; // ISO date string
  trdTime: string; // ISO time string
  trdCurrent: number;
  trdVoltage: number;
  trdTapChanger: number;
  trdActivePower: number;
  trdReactivePower: number;
  trdMinimumOilTemperature: number;
  trdMaximumOilTemperature: number;
  trdMinimumWindingTemperature1: number;
  trdMaximumWindingTemperature1: number;
  trdMinimumWindingTemperature2: number;
  trdMaximumWindingTemperature2: number;
  trdActive: boolean;
  trdRmks: string;
};
type TransformerDetails = {
  tfdIDpk: number;
  tfdSerialNo: string;
  tfdTransformerID: string;
  talIDpk: number | null;
  tfdStationIDfk: number;
  tfdStandard: string;
  tfdRatedVoltageI: number;
  tfdRatedVoltageII: number;
  tfdRatedVoltageIII: number;
  tfdRatedPowerI: string;
  tfdRatedPowerII: string;
  tfdRatedPowerIII: string;
  tfdRatedOutput: string;
  tfdConnectionSymbol: string;
  tfdCoolingType: string;
  tfdTapChangerType: string;
  tfdTappedWinding: string;
  tfdTapChangerRange: string;
  tfdTapChangerStep: string;
  tfdNominalTap: string;
  tfdPercentImpedanceAt75Deg: string;
  tfdZhAt100MVABaseAt75Deg: string;
  tfdZxAt100MVABaseAt75Deg: string;
  tfdZyAt100MVABaseAt75Deg: string;
  tfdResistanceAt100MVABaseAt75Deg: string;
  tfdCommissionDate: string; // ISO date string
  tfdDecommissionDate: string; // ISO date string
  tfdManufactureDate: string; // ISO date string
  tfdInstallationStartDate: string; // ISO date string
  tfdSupplyDate: string; // ISO date string
  tfdStatusIDfk: number;
  tfdManufacturerIDfk: number;
  tfdSupplierIDfk: number;
  talName: string | null;
  tfdAssigned: boolean;
  mftName: string;
  estName: string;
  estFitStatus: boolean;
  splName: string;
  splShtName: string;
  splIDpk: number;
  mftIDpk: number;
  estIDpk: number;
  stnIDpk: number;
  stnName: string;
  stnShtName: string;
  tfdVerified: boolean;
  tfdShowInStationReadings: boolean;
  tfdActive: boolean;
  tfdRmks: string;
};
type Station = {
  stnIDpk: number;
  stnName: string;
  locName: string;
  areName: string;
};
