import axios from 'axios';
import { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exprsTypes, modalTypes, searchTextTypes, selectExprsTypes } from '../../../../../../human-resource/setups/data/types/selectsTypes';
import { setValue, tier1SaveOnclick, tier1UpdateOnclick } from '../../../../../../../features/forms/tier1/createSlice';
import { setGeneralValue } from '../../../../../../../features/generalSlice';
import { RootState } from '../../../../../../../app/store';
import { deleteModalData, saveModalData, updateModalData, validationsData } from '../../../../../../accessories/component_infos';
import { useAccountInfo } from '../../../../../../../hooks/useAccountInfo';
import useFetch from '../../../../../../../hooks/useFetch';
import CustomLoader from '../../../../../../accessories/custom-loader';
import { ModalTemplate } from '../../../../../../../templates/modal';
import Setups_Template from '../../../../../../human-resource/setups/.setups_templates/Setups_Template';
import { manufacturers_TH } from '../data/manufacturers-data';
import { useCrudFunc } from '../../../../../../../functions/crud';


 const Manufacturers = forwardRef(({a}:any, ref)=>{
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update



    const postUrl:string = 'AsmTamManufacturer/create';
    const updateUrl:string = 'AsmTamManufacturer/update';

    const saveSuccessText = {message : 'Manufacturer added Successfully',ok : 'Ok' , title : 'Save Succesful'} // successful save modal message
    const updateSuccessText = {message : 'Manufacturer updated Successfully',ok : 'Ok' , title : 'Update Successful'} // successful update modal message

    // store data expressions[unique]
    const idExpr:String = 'mftIdpk';
    const nameExpr:string = 'mftName'
    const shortnameExpr:string = 'mftShtName'
    const orderExpr: string = 'mftOrder';
    const activeExpr: string = 'mftActive';
    const remarksExpr: string = 'mftRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }
    const selectsExpr:selectExprsTypes[] = [{idExpr : 'mftIdpk', nameExpr : 'mftName',label :'Name'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'}]

    const storeExprs = () =>{
        const exprs:exprsTypes[] = [{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr},{expr: 'selectExprs' ,value : selectsExpr[0]}]
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'selectExprs' ,value : selectsExpr},{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const[busyLoader,setBusyLoader] = useState<string>('')
    const dispatch = useDispatch();

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier1Add.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier1Add.successText); //success modal text

    //--for backend errors--
    const[err,setErr] = useState<{state:boolean,title:string,message:string}>({
        state:false,
        title:'',
        message:''
    });

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier1Add.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier1Add.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier1Add.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier1Add.updateDataOnclick); //trigger update question

    //--Update data modal--
    const deleteDataInfos = deleteModalData; //update modal details[data]
    const triggerdeleteModal:boolean = useSelector((state:RootState)=>state.tier1Add.deleteDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    const [employeeId,userId]=useAccountInfo();

    // get current values from  [slice dependent on form type]
    const name = useSelector((state:RootState)=>state.tier1Add.name);
    const shortname = useSelector((state:RootState)=>state.tier1Add.shortname);
    const order = useSelector((state:RootState)=>state.tier1Add.order);
    const active = useSelector((state:RootState)=>state.tier1Add.active);
    const remarks = useSelector((state:RootState)=>state.tier1Add.remarks);
    const selectedID = useSelector((state:RootState)=>{
        return state.general.SelectedFieldID;
    });

    const [Posting, Updating, Patching] = useCrudFunc();
    //post form Data
    //validate data
    const validateDataSave = () =>{        
        dispatch(tier1SaveOnclick());
    }
    // post
    const postData = async() =>{
        dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        
        try {
            setBusyLoader('Saving record')
            const res = await Posting(`${postUrl}`,{
                mftName: name,
                mftShtName: shortname,
                mftActive: active,
                mftRmks: remarks,
                mftOrder: order,
                mftCreatedBy: `${userId}`,
            })
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }

            //disable form
            dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));

        } catch (error:any) {
            setBusyLoader('')

            //set states for db errors modal
            setErr({
                state : true,
                title : error.response ? 
                (error.response.data.message?error.response.data.message:'Server Error') 
                : 
                'Server Error',
                message: error.response ? 
                (error.response.data.errors?error.response.data?.errors[0]:'Something went wrong.Please contact system administrator')
                : 
                'Error connecting to server. Please contact system administrator'
            })
        }
    }


    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier1UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        dispatch(setValue({expr: 'updateDataOnclick' ,value : false})) //disable modal
        
        try {
            setBusyLoader('Updating record')
            const res = await Updating(`${updateUrl}/${selectedID}`,{
                mftIdpk: selectedID,
                mftName: name,
                mftShtName: shortname,
                mftActive: active,
                mftRmks: remarks,
                mftOrder: order,
                mftEditedBy: `${userId}`,
                // mftEditedDate: "2023-01-30T10:33:15.920Z",
            })
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            //disable update mode
            dispatch(setGeneralValue({expr : 'updateMode', value : false})); // dispatch fetched data to reduxstore

            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick',value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {           
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErr({
                state : true,
                title : error.response ? 
                (error.response.data.message?error.response.data.message:'Server Error') 
                : 
                'Server Error',
                message: error.response ? 
                (error.response.data.errors?error.response.data?.errors[0]:'Something went wrong.Please contact system administrator')
                : 
                'Error connecting to server. Please contact system administrator'
            })
        }
    }


    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : err.state, okHandler : ()=> setErr((prev)=>({...prev,state:false})), cancelHandler : ()=> setErr((prev)=>({...prev,state:false})), message : err.message, cancelText : '', okText : 'Ok', title : err.title},
        //success
        {disableCancel : true, icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    const [searchText, setSearchText] = useState<searchTextTypes>({temp:'', text:''});

    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const [status, setStatus] = useState<boolean | string>('');
    // data for datagrid
    const [data,error,loading] = useFetch( `AsmTamManufacturer/GetAll?searchText=${searchText.text}&isActive=${status}`, refresh,'mftIdpk');
    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }
        if (error) {
            setErr({
                state:true,
                title:'Server Error',
                message:'Error connecting to server. Please contact system administrator.'
            })
        }
    },[error,data])

    // form parameters
    const form_header = 'Manufacturer Details';

    return (
        <>

            {busyLoader !== '' && <CustomLoader text= {busyLoader}/>}
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  
                        icon= {icon && icon} 
                        disableCancel = {disableCancel} 
                        cancelText = {cancelText && cancelText} 
                        open = {open} 
                        okHandler={okHandler} 
                        cancelHandler={cancelHandler} 
                        message= {message} 
                        okText= {okText} 
                        title={title}/>
                    </div>
                )
            })}
            {(loading && <CustomLoader/>)}
                <Setups_Template 
                    searchTextChange={(newText)=> {
                        setSearchText({temp:newText, text:searchText.text})
                        // setStatus(activeStatus)
                    }}
                    handleSearch={()=> {
                        setSearchText({text: searchText.temp, temp: searchText.temp})
                        setStatus(activeStatus)
                    }}
                    createData={validateDataSave}
                    updateData = {validateDataUpdate}
                    datagridClicked={()=>{}}
                    trigger = {trigger} 
                    datagridColumns={manufacturers_TH} 
                    datagridData = {data} 
                    formHeader={ form_header}
                />
            

        </>
    );
})

export default Manufacturers;