import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import swap from "../../../../../../assets/swap.png";

import edit from "../../../../assets/edit.png";
import newfile from "../../../../assets/new.png";
import printer from "../../../../assets/printer.png";
import reset from "../../../../assets/refresh-small.png";
import alter from "../../../../assets/alter.png";
import close from "../../../../assets/close.png";

import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import { FaBinoculars } from "react-icons/fa";
// import { AlertSMS, AppraiseSettings, DeleteTask, TaskSettings } from "../../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { useDispatch, useSelector } from "react-redux";
import { saveModalData } from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../../../features/generalSlice";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import { ModalTemplate } from "../../../../templates/modal";
import {
  AlertSMS,
  AppraiseSettings,
  DeleteTask,
  TaskSettings,
} from "../../../accessories/custom-toolbars/widgets/customToolbarModals";
import dayjs from "dayjs";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { DateTemplate } from "../../../../templates/date";
// import { RootState } from "../../../../../../app/store";
// import { setGeneralValue, settask_form_disable, settask_footer_update } from "../../../../../../features/generalSlice";
// import { task_form_disable, task_footer_update } from "../../../../../../features/Task&Todo/Task/TaskDetails";
// import { saveModalData } from "../../../../../accessories/component_infos";
// import { ModalTemplate } from "../../../../../../templates/modal";

interface props {
  toggler?: () => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;

  searchCriteriaData?: { id: number; value: string }[];
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  searchCriteria?: boolean;
  startDate?: string;
  endDate?: string;
  outerSelected?: any;
  setOuterSelected?: () => void;
  setShowTable?: (value: boolean) => void;
}

export const LineDetailsToolbars = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  searchTextOnchange,
  searchCriteriaOnchange,
  startDateChanged,
  endDateChanged,
  startDate,
  endDate,
  searchCriteriaData = [],
  searchCriteria,
  outerSelected,
  setOuterSelected,
  setShowTable,
}: props) => {
  const { Option } = Select;

  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";

  const [switchiconMsg, setSwitchiconMsg] = useState<string>("Personal");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const size: number = 16;
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [Active, setActive] = useState(false);
  const [refreshx, setRefreshx] = useState(false);
  const dispatch = useDispatch();

  const disablex = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  // const selectedRecord = useSelector(
  //   (state: RootState) => state.addTask.selectedRecord
  // );
  const setModal = () => {
    setIcon("warning");
    setshowModalDisableButton(true);
    setshowCancel(false);
    setModalData({
      message: "Please select a record to edit",
      title: "Select record",
      okText: "Ok",
    });
    setOpenModal(true);
  };

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);
  // relative to the new button
  const handleNew = () => {
    setShowTable && setShowTable(true);
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    if (setOuterSelected !== undefined) {
      setOuterSelected();
    }

    //clear data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    // dispatch(update_route_state(false))
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };
  //handle edit
  const handleUpdate = () => {
    setShowTable && setShowTable(true);
    if (outerSelected) {
      if (Object.keys(outerSelected).length < 1) {
        setModal();
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));

        dispatch(task_form_disable(true));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } else {
        dispatch(task_form_disable(false));

        dispatch(task_footer_update(true));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      }
    } else {
      dispatch(task_form_disable(false));

      dispatch(task_footer_update(true));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    }
  };
  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  const [searchValue, setSearchValue] = useState<string>();

  const newAccessPriv = () => {
    if (savePriv === true) {
      handleNew();
    } else {
      setModalData(true);
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const editAccessPriv = () => {
    if (updatePriv === true) {
      handleUpdate();
    } else {
      setModalData(true);
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  return (
    <div
      style={{ borderBottomWidth: "1px" }}
      className="w-full h-full flex  justify-start border-gray-200 mb-1 ml-2"
    >
      <div className="">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={!showCancel}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {disablex || updateMode ? (
            <li
              id="newRecord"
              onClick={() => newAccessPriv()}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                editAccessPriv();
              }}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          <li
            id="switch"
            onClick={toggler}
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="h-2">Switch interface / table view</p>
            </Tooltip>
          </li>
          <li className="px-1 text-xs">Criteria</li>
          <li className="px-1">
            <Form.Item
              name="requestWindow"
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(95%)" }}
            >
              {" "}
              <div className="flex flex-row items-center">
                <Select
                  value={criteriaDataLocal}
                  dropdownMatchSelectWidth={false}
                  className=" mt-0.5"
                  onChange={(data: any) => {
                    const criteriaData = JSON.parse(data);
                    setCriterialDataLocal({
                      id: criteriaData?.index,
                      value: criteriaData?.criteria,
                    });
                    searchCriteriaOnchange &&
                      searchCriteriaOnchange(
                        criteriaData?.index,
                        criteriaData?.criteria
                      );
                  }}
                  id="search"
                  size="small"
                  showSearch={true}
                  style={{ width: 150 }}
                >
                  {searchCriteriaData?.map(({ id, value }, index) => (
                    <Select.Option
                      key={id}
                      value={JSON.stringify({ index: index, criteria: value })}
                    >
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </li>
        </ul>
      </div>

      <div className="flex ml-10">
        <ul className=" flex items-center ">
          <div>Search Text</div>
          <li className="px-1 w-[420px]">
            <Input
              size="small"
              allowClear
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              placeholder=""
              style={{ width: "100%" }}
            />
          </li>
          <li className="px-0.5">
            <DateTemplate
              // size="small"
              // placeholder="Start Date"
              disabled={false}
              style={{ width: 125 }}
              format={dateFormat}
              selectedDate={dayjs(startDate)}
              changeDate={startDateChanged}
            />
          </li>
          <li className="px-0.5">
            <DateTemplate
              // size="small"
              // placeholder="End Date"
              disabled={false}
              style={{ width: 125 }}
              format={dateFormat}
              selectedDate={dayjs(endDate)}
              changeDate={endDateChanged}
              
            />
          </li>
          <li
            id="status"
            className="hover:cursor-pointer mr-1  text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange(checkValue);
              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li>
          <li
            onClick={() => {
              if (handleFind) {
                handleFind();
              } else {
              }
            }}
            id="findst"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li>

          {/* refresh */}
          <li
            onClick={() => {
              handleRefresh && handleRefresh!();
              setSearchValue("");
              setCriterialDataLocal({id: searchCriteriaData![0]?.id, value: searchCriteriaData![0]?.value});
            }}
            id="refreshst"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          <li
            onClick={() => handlePrint && handlePrint()}
            id="printst"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li>
        </ul>

        <DeleteTask isActive={isActive} setIsActive={setIsActive} />
        <TaskSettings
          SettingsisActive={SettingsisActive}
          SettingssetIsActive={SettingssetIsActive}
        />
        <AlertSMS
          AlertSMSisActive={AlertSMSisActive}
          AlertSMSsetIsActive={AlertSMSsetIsActive}
        />
        <AppraiseSettings
          AppraiseisActive={AppraiseisActive}
          AppraisesetIsActive={AppraisesetIsActive}
        />
      </div>
    </div>
  );
};

const data = [
  {
    label: "Group",
  },
  {
    label: "Staff Name",
  },
  {
    label: "Job Title",
  },
  {
    label: "Department",
  },
  {
    label: "Section",
  },
  {
    label: "Unit",
  },
  {
    label: "Location",
  },
];

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];
