import { Form, Checkbox, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { Cancel, SaveButton, UpdateButton } from "../../../../../accessories/buttons";
import { InputsTemplate } from "../../../../../../templates/input";
import { SelectsTemplate } from "../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import useFetch from "../../../../../../hooks/useFetch";
import { modalPropTypes, modalTypes, validateInstanceTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { saveModalData, updateModalData } from "../../../../../accessories/component_infos";
import { useCrudFunc } from "../../../../../../functions/crud";
import { updateData } from "@syncfusion/ej2-react-grids";
import { ModalTemplate } from "../../../../../../templates/modal";
interface statesTypes {
  reportedBy?: { id: string; name: string };
  troubleType?: { id: string; name: string };
  troubleClass?: { id: string; name: string };
  equipmentType?: { id: string; name: string };
  troubleLocation?: { id: string; name: string };
  priority?: { id: string; name: string };
  troubleSubClass?: { id: string; name: string };
  troubleCategory?: { id: string; name: string };
  troubleImpact?: { id: string; name: string };
  busyLoader : string,
  remarks : string,
  confirmModal:modalPropTypes
  warningModal:modalPropTypes
  successModal:modalPropTypes
  }
  
  interface props {
    // this means whether a prop is passed on the component or not the component called
    // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
    // that without a prop there will be an error 
    toggler?: () => void
    selectrefreshx?: (e:any) => void
    siaId?: any
    customHeightHook?: any
    selectedRecord?:any
    handleOnCancel: ()=>void
    handleRefresh: ()=>void
   
}

export default function ClassifyForm({ selectedRecord, handleOnCancel, handleRefresh }: props,) {
    const [states, setStates] = useState<statesTypes>({
      reportedBy: { id: "", name: "" },
      troubleType: { id: "", name: "" },
      troubleClass: {id:"",name :""},
      equipmentType: { id: "", name: "" },
      troubleLocation: { id: "", name: "" },
      priority: { id: "", name: "" },
      troubleSubClass: { id: "", name: "" },
      troubleCategory: { id: "", name: "" },
      troubleImpact: { id: "", name: "" },
      remarks : '',
    
       
        busyLoader : '',
        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
      });
      const borderTheme = useSelector(
        (state: RootState) => state.general.cssBorderClass
      );
      const [refresh, setrefresh] = useState<boolean>(false);
   const [troubleReports, troubleReportsError, troubleReportsLoading] = useFetch(
    `TroubleReportClassify/PopCmbEmps`,
    refresh,
    "empIDpk",
    false,
    "powerapp"
  );

  const [troubleTypes, troubleTypesError, troubleTypesLoading] = useFetch(
    `TroubleReportClassify/PopCmbTroubleTypes`,
    refresh,
    "objId",
    false,
    "powerapp"
  );
  const [troubleClasses, troubleClassesError, troubleClassesLoading] = useFetch(
    `TroubleReportClassify/PopCmbClasses`,
    refresh,
    "tclIDpk",
    false,
    "powerapp",false, 
    {"tlcId":states.troubleLocation.id}
  );
  const [troubleSubClasses, troubleSubClassesError, troubleSubClassesLoading] = useFetch(
    `TroubleReportClassify/PopCmbSubClasses`,
    refresh,
    "tclIDpk",
    false,
    "powerapp", false, 
    {"tlcId":states.troubleLocation.id}
  );
  const [troubleLocation, troubleLocationError, troubleLocationLoading] = useFetch(
    `TroubleReportClassify/PopLueLocations`,
    refresh,
    "tlcIDpk",
    false,
    "powerapp"
  );
  const [troubleCategories, troubleCategoriesError, troubleCategoriesLoading] = useFetch(
    `TroubleReportClassify/PopCmbTroubleCats`,
    refresh,
    "tctIDpk",
    false,
    "powerapp"
  );

  const [impactData, impactError, impactLoading] = useFetch(
    `TroubleReportClassify/PopLueUpdTroubleImpacts`,
    refresh,
    "timIDpk", true, "powerapp", false,
    {"troubleTypeId": states.troubleType.id, 
      "troubleClassId": states.troubleClass.id
    }
  );
 
  const selectsData =   [
    {name:'Reported By', id:'empIDpk', value: 'empName', data:(troubleReports as []).filter((item)=>item['empName'] !== null) , stateName: 'reportedBy' },
    {name:'Location', id:'objId', value: 'name', data:troubleLocation, stateName: 'troubleLocation'}, 
    {name:'Trouble Category', id:'tctIDpk', value: 'tctName', data:troubleCategories , stateName: 'troubleCategory' },
    {name:'Trouble Type', id:'objId', value: 'name', data:troubleTypes, stateName: 'troubleType'  },
    {name:'Trouble Class', id:'objId', value: 'name', data:troubleClasses, stateName: 'troubleClass'  },
    {name:'Trouble Subclass', id:'objId', value: 'name', data: troubleSubClasses, stateName: 'troubleSubClass'  },
    {name:'Impact', id:'objId', value: 'name', data:impactData, stateName: 'troubleImpact'  },
     ]

     const updateState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };
    const validateFields=():boolean=>{
 
      const validationInstances:validateInstanceTypes[]=[
          {state:states.reportedBy.id,baseValue:'',modalProps:{state:true,title:'Select reported by',message:'Please select reported by'}},
         {state:states.troubleLocation.id,baseValue:'',modalProps:{state:true,title:'Select location',message:'Please select location'}},
          {state:states.troubleCategory.id,baseValue:'',modalProps:{state:true,title:'Select category',message:'Please select category'}},
          {state:states.troubleType.id,baseValue:'',modalProps:{state:true,title:'Select trouble type',message:'Please select trouble type'}},
          {state:states.troubleClass.id,baseValue:'',modalProps:{state:true,title:'Select trouble class',message:'Please select trouble class'}},
          {state:states.troubleSubClass.id,baseValue:'',modalProps:{state:true,title:'Select trouble subclass',message:'Please select trouble subclass'}},
          {state:states.troubleImpact.id,baseValue:'',modalProps:{state:true,title:'Select trouble impact',message:'Please select trouble impact'}},
          ]
    
      for(let instance of validationInstances) if (instance.state===instance.baseValue) {
          const{modalProps}=instance;
          updateState('warningModal',{
              state:modalProps.state,
              title:modalProps.title,
              message:modalProps.message
          })
              return false;
          }
          return true;
      }
   
      const [PostDataFunc, UpdateDataFunc] = useCrudFunc("powerapp");
  
        //update api
    const updateFormData = async(action:'confirm'|'save')=>{
      console.log('post data called')
      if (action === 'confirm') {
          updateState('confirmModal',{
              state:true,
              title:'Save Record',
              message:'Are you sure you want to save this record?',
              func() {
                  console.log('post data event called')
                  handleOnCancel()
                  updateFormData('save')
              },
              action: 'save'
          })
          return;
      }
  
      updateState('confirmModal',{
          state:false,
          title:'Save Record?',
          message:'Are you sure you want to save this record?',
      })
  
      try {
          // busyloader(saveModalData.progress);
          console.log('sele', selectedRecord)
          const res = await UpdateDataFunc (`TroubleReportClassify/UpdateTroubleReportClassifier/?usaIdpk=${selectedRecord['trpIDpk']}`,{
            "trpImpactIDfk": states.troubleImpact.id,
            "trpReportedByEmpIDfk": states.reportedBy.id,
            "trpCategoryIDfk": states.troubleCategory.id,
            "trpTroubleTypeIDfk": states.troubleType.id,
            "trpClassIDfk": states.troubleClass.id,
            "trpSubclassIDfk": states.troubleSubClass.id,
            "trpLocationIDfk": states.troubleLocation.id,
            "trpClassificationRmks": states.remarks,
            "trpIDpk": selectedRecord['trpIDpk']
          }, `Updated Trouble classify with id ${selectedRecord['trpIDpk']} successfully`, "omgTroubleReports")
  
          // disableFields();
          // refreshGrid();
          // clearSelected();
          // //Clear fields when done.
          // populateStatesFromGrid(true);
          // selectrefreshx('');
          handleRefresh();
          updateState('successModal',{
              state: true,
              title: "Record Saved",
              message: "Repairs Update added successfully"
          })
          // setStates(  {
          //   ...states,
          //   date: "",
          //   complete: 0,
          //   job: 0,
    
          //   description: "",
          //   status: { id: "", name: "" },
          //   impact: { id: "", name: "" },
          //   busyLoader : '',
          //   // confirmModal:{state:false,title:'',message:'',action:'post'},
          //   // warningModal:{state:false,title:'',message:''},
         
          // });
          // setOnChangeDoneby(null)
         
      } catch (error) {
          console.log(error);
          
          updateState('warningModal',{
              state: true,
              title: updateModalData.error.title,
              message:updateModalData.error.message
          })
      } finally{
          // busyloader('');
      }
      return;
  }
   
   // modal instances
   const modals:modalTypes[] = [
    //validation
    {disableCancel : true , icon : 'warning' ,open : states.warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : states.warningModal.message, okText : 'Ok', title : states.warningModal.title,cancelText : 'No, cancel'},
    
    //confirmation
    {disableCancel : false , icon : 'question' ,open :  states.confirmModal.state, okHandler :  states.confirmModal.func, cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : states.confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

    //success
    {disableCancel : true , icon : 'success' ,open :  states.successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : states.successModal.message, okText : 'Ok', title : states.successModal.title,cancelText : 'No, cancel'},
]

  return (
    <div>
       {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  
                    icon= {icon && icon} 
                    disableCancel = {disableCancel} 
                    cancelText = {cancelText && cancelText}
                    open = {open} 
                    okHandler={okHandler} 
                    cancelHandler={cancelHandler}
                    message= {message}
                    okText= {okText} title={title}/>
                </div>
            )
        })}
           <Form
                name="complex-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="w-full"
               
              >
        <div className="mt-[22px]">
                  <div>
                    {selectsData.map((item)=>(
 <SelectsTemplate
 // cusWidth={"197px"}
                  style={{width: "calc(100%)"}}      
 disabledCustomWidth={true}
 label={item.name}
 options={item.data}
 idexpr={item.id}
 dataexp={item.value}
 useCallFunc
 disabled={false}
 placeHolder={''}
 selectedValue={(e) => {
 
  updateState(item.stateName, {
    id: e[`${item.id}`],
    name: e[`${item.value}`],
  });
  console.log('classify', e)
  setrefresh(!refresh)
 }}
 handleClear={() => {
   
 }}
/>
                    ))}
                 

                 <TextareaTemplate
                 
                  labelCol={8}
                 label={"Remarks"}
                height={70}
                width="calc(91%)"
                useCallbackFunc
                defaultValue={states.remarks}
                setCallbackValue={(e) => {
                                   updateState('remarks', e);
                }}
                parentClassName=""
                disabled={false}
              

              />

                   
                    <Form.Item
                    colon={false}
                      style={{ display: "inline-block", marginBottom: "10px" }}
                      label={<p className="text-xs">{}</p>}
                      
                      className="w-full mb-1"
                    >
                      <div className="flex justify-end items-end  mr-[24px]">
                       <SaveButton  disableButton={false} 
                         useCallbackFunc={true} handleSave={() => {
                          console.log('validating')
                          validateFields() && updateFormData("confirm")
                          }}
                       
                       /> 
                     
                      </div>
                    </Form.Item>
                  </div>
                </div>
                </Form>
    </div>
  )
}
