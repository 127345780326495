/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "../../../../../hooks/useFetch";
import { Form } from "antd";
import { SendEmailButton } from "../../../../accessories/buttons";
import right from "../../../../../assets/right2.png";
import { ADPH_toolbar } from "../../../../accessories/custom-toolbars/alert_drivers_toolbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { left_mini_cols } from "./datagrid_cols";
import {
  navbar_height,
  startingDate,
  useModalData,
} from "../../../../accessories/component_infos";
import Datagrid_template from "../../../../../templates/Datagrid";
import { TextareaTemplate } from "../../../../../templates/textarea";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";
import useResizeMode from "../../../../../hooks/useResizeMode";
import { WarningAlert } from "../../../../accessories/warningAlert";
import useModalStore from "../../../../../hooks/zustand/modalStore";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../functions/crud";
import { ModalTemplate } from "../../../../../templates/modal";
import { useBusyLoader } from "../../../../../hooks/useBusyLoader";

interface props {
  toggler?: () => void;
  customHeightHook?: any;
}

export const Alert_Drivers = ({ toggler }: props) => {
  const [modal, setModal] = useModalData();
  const [empID, userID, userInfo] = useAccountInfo();
  const [formheight, set_form_height] = useState<number>(0);
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const formRef = useRef<any>(null);
  const pageHeight =
    window.innerHeight - (navbar_height + news_flash_height + formheight + 50);

  //    DATA FROM REDUX

  const rxfindData = useSelector(
    (state: RootState) => state.toolbarDetailsSlice.findData
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const editedDate = dayjs();

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: true,
    status: true,
  });
  const [staffStatus, setStaffStatus] = useState<statusTypes>({
    temp: false,
    status: false,
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [date, setDate] = useState<any>({
    startDate: startingDate,
    endDate: editedDate,
  });
  const [find, setFind] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [employeeId, userId] = useAccountInfo();
  const [posting, updating] = useCrudFunc();
  const [busyLoader, setBusyLoader] = useBusyLoader()
  const allowUpdate = (e: any) => {
    if (selectedRecord.length === 0) {
      setSelectedRecord([e.data]);
    } else {
      //check whether data has been added
      var check = false;
      for (var i = 0; i < forwardSelected.length; i++) {
        if (e.empIdpk === forwardSelected[i]?.empIdpk) {
          check = true;
          break;
        }
      }

      if (check === false) {
        setForwardSelected([...forwardSelected, e]);
      }
    }
  };

  const [AllD, AllDerror] = useFetch(
    `AsmTmsEntAlertDriversAndPermitHolders/GetByCriteriaAllDrivers?text=${searchText.temp}&PageNumber=${1}&PageSize=${20}`
  );
  const [AllP] = useFetch(
    `AsmTmsEntAlertDriversAndPermitHolders/GetByCriteriaAllPermitHoldersByActive?text=${searchText.text}&activeState=${activeStatus.status}&staffState=${staffStatus.status}&PageNumber=${1}&PageSize=${20}`
  );
  const [AllDP] = useFetch(
    `AsmTmsEntAlertDriversAndPermitHolders/GetByCriteriaAllDriversAndPermitHoldersByActive?text=${searchText.text}&activeState=${activeStatus.status}&staffState=${staffStatus.status}&PageSize=${20}&PageNumber=${1}`
  );
  const [AllRT] = useFetch(
    `AsmTmsEntAlertDriversAndPermitHolders/GetReturnsTimes?startdate=${date.startDate}&enddate=${date.endDate}&PageNumber=${1}&PageSize=${20}`
  );
  const [AllUnsubmittedReturn] = useFetch(
    `AsmTmsEntAlertDriversAndPermitHolders/GetAllWhoHaveNotSubmittedTheirFuelReturns?text=${searchText.text}&startdate=${date.startDate}&enddate=${date.endDate}&PageNumber=${1}&PageSize=${20}`
  );
  const [DBdata, setDBdata] = useState<any>(AllD);

  // For All Drivers

  // For the forward button press
  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const [forwardSelected, setForwardSelected] = useState<any>([]);

  const sendSMS = async () => {
    try {
      setBusyLoader("Sending SMS...")
      for (const employee of forwardSelected) {
        const res = await posting(
          `SMSAlert/SaveSMS`,
          {
            imsMessage: message,
            imsRecipientEmpIdfk: employee.empIdpk,
            imsSender: employee.empName1,
            imsRecordIdfk: employee.empIdpk,
            imsMessageType: "string",
            imsMessageClass: "string",
            imsInitiatorEmpIdfk: userID,
            imsCreatedBy: userID,
          },
          `Sent SMS to ${employee.empName1}`
        );
        if (res.status) {
          setModal({
            state: true,
            title: "SMS Report",
            message: `SMS sent successfully`,
            icon: "success",
            okText: "OK",
            cancelText: "No",
            disableCancel: true,
            okHandler: async () => {
              setModal(prev => ({
                ...prev,
                state: false,
              }))
            },
            cancelHandler: () => {
              setModal(prev => ({
                ...prev,
                state: false,

              }))
            }
          })
        }
      }
      setMessage("");
      setBusyLoader("")
    } catch (e: any) {
      console.info("error: ", e);
      setModal({
        state: true,
        title: "SMS Report",
        message: `SMS could not be sent, contact administrator`,
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: async () => {
          setModal(prev => ({
            ...prev,
            state: false,

          }))
        },
        cancelHandler: () => {
          setModal(prev => ({
            ...prev,
            state: false,

          }))
        }
      })
      setBusyLoader("")
    }
  }

  const forwardTask = async () => {
    if (forwardSelected.length === 0) {
      setForwardSelected(selectedRecord);
    } else {
      // Loop through selected records
      for (var a = 0; a < selectedRecord.length; a++) {
        var testBool: boolean = false;
        for (var b = 0; b < forwardSelected.length; b++) {
          // if current record is found
          if (selectedRecord[a] === forwardSelected[b]) {
            testBool = true;
          }
        }
        if (testBool === true) {
        } else {
          // Add selected record to forward selection
          setForwardSelected([...forwardSelected, selectedRecord[a]]);
        }
      }
    }
  };

  const removeSelected = useCallback(
    (e: any) => {
      setForwardSelected((prev: any) =>
        prev.filter((item: any) => e.empIdpk !== item.empIdpk)
      );
    },
    [forwardSelected]
  );

  useEffect(() => {
    if (searchCriteria.temp === 0) {
      setDBdata(AllD);
    } else if (searchCriteria.temp === 1) {
      setDBdata(AllP);
    } else if (searchCriteria.temp === 2) {
      setDBdata(AllDP);
    } else if (searchCriteria.temp === 3) {
      setDBdata(AllUnsubmittedReturn);
    } else if (searchCriteria.temp === 4) {
      setDBdata(AllRT);
    } else {
      setDBdata([]);
    }

    set_form_height(formRef.current?.clientHeight + 50);
  }, [find]);

  const searchCriteriaData = [
    { id: 0, value: "All Drivers" },
    { id: 1, value: "All Permit Holders" },
    { id: 2, value: "All Drivers & Permit Holders" },
    { id: 3, value: "All Unsubmitted Fuel Returns" },
    { id: 6, value: "Invalid 23:59 Returns Time" },
  ];

  // const smsHeight = window.innerHeight - 800;
  // useResizeMode(smsHeight);

  const [initial, final] = useResizeMode(window.innerHeight);
  const textheight = initial - 800;


  return (
    <>
      <ModalTemplate
        width={300}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />
      {AllDerror && <WarningAlert />}
      <div className="w-full ">
        <ADPH_toolbar
          searchTextOnchange={(searchValue) =>
            setSearchText({ temp: searchValue, text: searchText.text })
          }
          onChange={(check) =>
            setActiveStatus({ temp: check, status: activeStatus.status })
          }
          onChange2={(check2) => {
            console.log("check2", check2)
            setStaffStatus({ temp: check2, status: staffStatus.status })
            console.log("store: ", staffStatus)
          }
          }
          startDateChanged={(date) => {
            setDate((prev: any) => ({ ...prev, startDate: date }));
          }}
          endDateChanged={(date) => {
            setDate((prev: any) => ({ ...prev, endDate: date }));
          }}
          startDate={date.startDate}
          endDate={date.endDate}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
            setActiveStatus({
              temp: activeStatus.temp,
              status: activeStatus.temp,
            }); //active status
            setStaffStatus({
              temp: staffStatus.temp,
              status: staffStatus.temp,
            }); //active status
            setsearhCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
            // setrefresh(current=> !current)
            setFind(!find);
          }}
          searchCriteriaOnchange={(newIndex, criteria) => {
            setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
          }}
          searchCriteriaData={searchCriteriaData}
        />

        <div className=" w-full ">
          {/* Add PPE OPTIONS */}

          <Form
            className="w-full rounded px-2 py-3 flex justify-between  "
            name="basic"
            labelCol={{ span: 3 }}
            // wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
            ref={formRef}
          >
            <section
              style={{ width: "calc(50% - 40px)" }}
              className="w-full  bg"
            >
              {/* SELECTS */}

              {/* DATAGRID [] */}
              <Form.Item
                className="pl-2"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Recipient"}
                  </p>
                }
                name="Staff No"
                rules={[{ required: false }]}
              >
                <div className="">
                  <Datagrid_template
                    gridheight={544}
                    columns={left_mini_cols}
                    data={DBdata}
                    onRowClick={(e) => {
                      setSelectedRecord([e]);
                    }}
                    rowDoubleClicked={allowUpdate}
                    disableGroupPanel
                    disablePaging
                    disableSearch
                  />
                </div>
              </Form.Item>
            </section>

            <section
              onClick={() => forwardTask()}
              className=" border-2 mt-1 flex items-center justify-center cursor-pointer"
              style={{ width: "calc(70px)", borderColor: borderTheme }}
            >
              <img className="w-4/12 h-4/12" src={right} alt="" />
            </section>

            <section className=" mt-1 " style={{ width: "calc(50% - 40px)" }}>
              {/* DATAGRID [] */}
              <Form.Item
                className=""
                name="Staff No"
                rules={[{ required: false }]}
              >
                <div className="">
                  <Datagrid_template
                    gridheight={540}
                    columns={left_mini_cols}
                    data={forwardSelected}
                    disablePaging
                    disableGroupPanel
                    disableSearch
                    rowDoubleClicked={removeSelected}
                  />
                </div>
              </Form.Item>
            </section>

            {/* Bellow will be deleted after ediitiong  */}
          </Form>
          {/* SMS */}

          <Form
            className="w-full rounded    "
            name="basic"
            labelCol={{ span: 1 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <section
              style={{
                width: "calc(100% - 2%)",
                marginLeft: "2%",
                // height: pageHeight,
              }}
              className="h-full px-2"
            >
              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"SMS"}
                  </p>
                }
                className="mb-1 "
              >
                <TextareaTemplate
                  disabled={false}
                  useCallbackFunc
                  height={textheight}
                  readonly={false}
                  defaultValue={message}
                  setCallbackValue={(e) => {
                    setMessage(e);
                  }}
                />

                {/* Buttons */}

                <div className="w-full flex h-12 bottom-0 inset-0  flex-row items-start justify-between  ">
                  <li className=" flex justify-start items-center">{`Count ${message?.length}`}</li>

                  <ul className="flex items-start justify-start">
                    <SendEmailButton
                      disableButton={false}
                      handleMail={async () => {
                        setModal({
                          state: true,
                          title: "Save?",
                          message: `Are you sure you want to send sms`,
                          icon: "question",
                          okText: "Yes",
                          cancelText: "No",
                          disableCancel: false,
                          okHandler: async () => {
                            setModal(prev => ({
                              ...prev,
                              state: false,

                            }))
                            await sendSMS()
                          },
                          cancelHandler: () => {
                            setModal(prev => ({
                              ...prev,
                              state: false,

                            }))
                          }
                        })
                      }}

                    />
                  </ul>
                </div>
              </Form.Item>
            </section>
          </Form>
        </div>
      </div>
    </>
  );
};
