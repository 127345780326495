/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect, useCallback } from "react";
import { Form, Input, Select, Checkbox } from "antd";
import reset from "../../../../../../../assets/reset.png";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
import useFetch from "../../../../../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  dbDateFormat,
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import dayjs from "dayjs";
import { task_footer_table_selected } from "../../../../../../../features/forms/entries/safetyManager/ppeRequest";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import {
  inputsTypes,
  modalPropTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { DateTemplate } from "../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { DropDownBox } from "devextreme-react";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { workshop_cols } from "../data/datagrid_cols";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { GetDataFunc } from "../../../../../../../functions/get";
import {
  setNew_Cancel_Button,
  setEdit_Cancel_Button,
  setBulk_Cancel_Button,
  setSave_Update_Button,
  setDisabled_Form,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Maintenance/MaintenanceScheduleSlice";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { useCrudFunc } from "../../../../../../../functions/crud";
import {
  fetchMainType,
  fetchReSchPlan,
} from "../../../../../../../features/apiCalls/maintenanceScheduleApi";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import React from "react";
import { useAxiosService } from "../../../../../../../general/requestConfig";

interface gridPop {
  new_toggle?: () => void;
  cancel_toggle?: () => void;
  selectedRecordx?: any;
  statesx?: any;
  setRefreshValuex?: (e: boolean) => void;
  updateStatesx?: (key: string, value: any) => void;
  rightVDdatax?: any;
  footerDatax?: any;
  handleNew?: (value: boolean) => void;
  handleUpdate?: (value: boolean) => void;
  setOuterSelect?: (value: any) => void;
}

interface stateTypes {
  mt?: { id: number; name: string } | any;
  rp: { label: string; id: number } | any;
  ws?: { id: number; name: string } | any;
  date?: string;
  mdate?: string;
  fdur?: number;
  fd?: number;
  remarks?: string;
  sms1?: number;
  sms2?: number;
  sms3?: number;
  sms4?: number;
  driver?: boolean;
  ao?: boolean;
  admin?: boolean;
  vc?: boolean;
  man?: boolean;
  dir?: boolean;
  smsdate?: string;
  nfd?: number;
  active?: boolean;
  batchNo?: number;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
  isOpenDropdown?: boolean;
  isOpenDropdown1?: boolean;
  isOpenDropdown2?: boolean;
}
export interface LookupResponseType {
  popLueWorkshops: PopLueWorkshop[];
  popCmbMaintTypes: PopCmbMaintType[];
}

export interface PopCmbMaintType {
  mtpIdpk: number;
  mtpName: string;
  mtpShtName: string;
}

export interface PopLueWorkshop {
  wsdIdpk: number;
  wsdName: string;
  areName: string;
  locName: string;
  twnName: null | string;
  wsdContact: string;
  wsdTelNo: string;
  wsdExternal: boolean;
}
export const Maintenance_Schedule_Details_Form = ({
  new_toggle,
  cancel_toggle,
  selectedRecordx,
  statesx,
  setRefreshValuex,
  rightVDdatax,
  footerDatax,
  updateStatesx,
  handleNew,
  handleUpdate,
  setOuterSelect,
}: gridPop) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef<any>(null);

  const [refreshDB, setRefreshDB] = useState<boolean>(false);
  const axiosGo = useAxiosService("innox");

  const [states, setStates] = useState<stateTypes>({
    rp: { label: "", id: 0 },
    mt: { name: "", id: 0 },
    ws: { name: "", id: 0 },
    date: "",
    mdate: "",
    fdur: 0,
    fd: 0,
    remarks: "",
    sms1: 0,
    sms2: 0,
    sms3: 0,
    sms4: 0,
    driver: false,
    ao: false,
    admin: false,
    vc: false,
    man: false,
    dir: false,
    active: true,
    batchNo: 0,
    smsdate: "",
    nfd: 0,
    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
  });

  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const editedDate = dayjs().format();

  // Modal dialog data states

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [Posting, Updating] = useCrudFunc();
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  //........................................//
  let NewtoCancelButton = useSelector(
    (state: RootState) => state.TMSMaintenance.New_cancel_Button
  );

  let EdittoCancelButton = useSelector(
    (state: RootState) => state.TMSMaintenance.Edit_Cancel_Button
  );

  let DisabledForm = useSelector(
    (state: RootState) => state.TMSMaintenance.Disabled_form
  );

  let BullEdittoCancel = useSelector(
    (state: RootState) => state.TMSMaintenance.Bulk_Cancel_Button
  );
  const reSchPlanData = useSelector(
    (state: RootState) => state.maintSchApi.reSchPlanData
  );
  const mainTypeData = useSelector(
    (state: RootState) => state.maintSchApi.mainTypeData
  );
  const fdispatch = useDispatch();

  //..........................................//

  const populateFields = (clear?: boolean) => {
    if (clear === true) {
      dispatch(task_footer_table_selected([]));
    }

    if (selectedRecordx !== undefined) {
      const gridState = [
        {
          key: "rp",
          value: clear
            ? { id: 0, name: "" }
            : { id: 1, name: selectedRecordx.mtfReschedulePlan },
        },
        {
          key: "mt",
          value: clear
            ? { id: 0, name: "" }
            : {
                id: selectedRecordx.mtfMaintenanceTypeIdfk,
                name: selectedRecordx.mtpName,
              },
        },
        {
          key: "ws",
          value: clear
            ? { id: 0, name: "" }
            : { id: selectedRecordx.wsdIdpk, name: selectedRecordx.wsdName },
        },
        {
          key: "mdate",
          value: clear ? editedDate : selectedRecordx.mtfMaintenanceDate,
        },
        { key: "fdur", value: clear ? 0 : selectedRecordx.mtfFrequencyDays },
        { key: "fd", value: clear ? 0 : selectedRecordx.mtfFrequencyKm },
        { key: "remarks", value: clear ? "" : selectedRecordx.mtfRmks },
        {
          key: "sms1",
          value: clear ? 0 : selectedRecordx.mtfFirstSmsPromptDays,
        },
        {
          key: "sms2",
          value: clear ? 0 : selectedRecordx.mtfSecondSmsPromptDays,
        },
        {
          key: "sms3",
          value: clear ? 0 : selectedRecordx.mtfThirdSmsPromptDays,
        },
        {
          key: "sms4",
          value: clear ? 0 : selectedRecordx.mtfFourthSmsPromptDays,
        },
        {
          key: "driver",
          value: clear ? false : selectedRecordx.mtfPromptDriver,
        },
        { key: "vc", value: clear ? false : selectedRecordx.mtpRoadworthy },
        { key: "ao", value: clear ? false : selectedRecordx.mtfPromptOwner },
        { key: "admin", value: clear ? false : selectedRecordx.mtfPromptAdmin },
        { key: "man", value: clear ? false : selectedRecordx.mtfPromptManager },
        {
          key: "dir",
          value: clear ? false : selectedRecordx.mtfPromptDirector,
        },
        { key: "nfd", value: clear ? 0 : "" },
        {
          key: "date",
          value: clear ? editedDate : selectedRecordx.mtfStartDate,
        },
        {
          key: "smsdate",
          value: clear ? editedDate : selectedRecordx.mtfNextAlertDate,
        }, // will change this
        { key: "active", value: clear ? false : selectedRecordx.mtfActive },
        { key: "batchNo", value: clear ? 0 : selectedRecordx.mtsBatchNo },
      ];

      for (let instance of gridState) updateState(instance.key, instance.value);
    }
  };

  const getMaxBatchNo = async () => {
    try {
      const res = await GetDataFunc(
        `AsmTsmEntMaintenanceSchedule/GetMaxBatchNo`
      );

      updateState("batchNo", res.data.mtfBatchNo + 1);
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  useEffect(() => {
    populateFields(false);
    dispatch(setGeneralValue({ expr: "clearFields", value: populateFields }));
  }, [selectedRecordx]);

  /**
   * Lookup Response
   */
  // const [lookup_data, __dx__, __dy__]: [
  //   lookup_data: LookupResponseType,
  //   __dx__: unknown,
  //   __dy__: unknown
  // ] = useFetch(
  //   "AsmTsmEntMaintenanceSchedule/GetMaintenanceScheduleLookup?PageNumber=1&PageSize=20"
  // );

  const [lookup_data, setLookup] = React.useState<LookupResponseType>();
  const [loading, setLoading] = React.useState<boolean>();

  React.useEffect(() => {
    (() => {
      setLoading(true);
      axiosGo({
        url: `AsmTsmEntMaintenanceSchedule/GetMaintenanceScheduleLookup`,
        params: { PageNumber: 1, PageSize: 20 },
        method: "GET",
      }).then((res) => {
        setLookup(res);
      });
      setLoading(false);
    })();
  }, []);
  // const [maintTypes, maintTypesError, maintTypesLoading] = useFetch(
  //   "AsmTsmEntMaintenanceSchedule/PopCmbMaintTypes",
  //   refreshDB
  // );

  const [workshopData, workshopError, workshopLoading] = useFetch(
    "AsmTsmEntMaintenanceSchedule/PopLueWorkshops",
    refreshDB
  );

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Start Date",
      defaultValue: states.date,
      stateName: "date",
    },
    {
      id: 1,
      label: "Maintenance Date",
      defaultValue: states.mdate,
      stateName: "mdate",
    },
  ];

  const smsDate: inputsTypes[] = [
    {
      id: 0,
      label: "Next SMS Date",
      defaultValue: states.smsdate,
      stateName: "smsdate",
    },
  ];
  const inputs1: inputsTypes[] = [
    {
      id: 0,
      label: "Next Freq. Distance",
      defaultValue: states.nfd,
      stateName: "nfd",
    },
  ];

  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "Freq. Dur - (Days)",
      defaultValue: states.fdur,
      stateName: "fdur",
    },
    {
      id: 1,
      label: "Freq. Distance",
      defaultValue: states.fd,
      stateName: "fd",
    },
  ];

  const MaintenanceTypeData = useSelector(
    (state: RootState) => state.maintSchApi.mainTypeData
  );
  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Reschedule Plan",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      defaultValue: states.rp?.label,
      stateName: "rp",
      singleEndpoint: fetchReSchPlan,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      handleFocus: () => {},
    },
    {
      id: 1,
      label: "Maintenance Type",
      optionsData:
        states.mt?.name === ""
          ? lookup_data?.popCmbMaintTypes
          : MaintenanceTypeData,
      idExpr: "mtpIdpk",
      dataExpr: "mtpName",
      defaultValue: states.mt?.name,
      stateName: "mt",
      singleEndpoint: fetchMainType,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: "isOpenDropdown2",
      handleFocus: () => {},
    },
    {
      id: 2,
      label: "Workshop",
      optionsData: workshopData,
      idExpr: "wsdIdpk",
      dataExpr: "wsdName",
      defaultValue: states.ws?.name,
      stateName: "ws",
    },
  ];

  const close = () => {
    dropdownRef.current!.instance.close();
  };

  const validations = async (type: "post" | "update") => {
    setIcon("warning");
    if (states.mt?.name === "" || states.mt === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select maintenance type",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.rp?.label === "" || states.rp === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Reschedule Plan",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.ws?.name === "" || states.ws === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a workshop",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fdur === 0 || states.fdur === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Freq. Duration(Days)",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fd === 0 || states.fd === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter value for Freq. Distance ",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
      if (type === "post") {
        setModalData(saveModalData);
      } else {
        setModalData(updateModalData);
      }
    }
  };

  // Post Data

  const postData = async (action: "validate" | "post") => {
    if (
      statesx.addedSelectedRecord !== undefined ||
      statesx.addedSelectedRecord.length !== 0
    ) {
      try {
        //.......................
        fdispatch(setNew_Cancel_Button(true));
        fdispatch(setEdit_Cancel_Button(true));
        fdispatch(setBulk_Cancel_Button(true));
        fdispatch(setDisabled_Form(true));
        fdispatch(setSave_Update_Button(true));
        //.......................

        for (let veh of statesx.addedSelectedRecord) {
          await Posting(
            `AsmTsmEntMaintenanceSchedule/CreateMaintenanceSchedule`,
            {
              mtsStartDate: states.date,
              mtsVehicleDetailIdfk: veh.vdtIDpk,
              mtsWorkshopIdfk: states.ws?.id,
              mtsMaintenanceTypeIdfk: states.mt?.id,
              mtsReschedulePlan: states.rp?.label,
              mtsFrequencyKm: Number(states.fd),
              mtsMaintenanceDate: states.mdate,
              mtsFirstSmsPromptDays: states.sms1,
              mtsSecondSmsPromptDays: states.sms2,
              mtsThirdSmsPromptDays: states.sms3,
              mtsFourthSmsPromptDays: states.sms4,
              mtsPromptDriver: states.driver,
              mtsPromptOwner: states.ao,
              mtsPromptAdmin: states.admin,
              mtsPromptVehicleController: states.vc,
              mtsPromptManager: states.man,
              mtsPromptDirector: states.dir,
              mtsBatchNo: selectedRecordx.mtsBatchNo,
              mtsActive: states.active,
              mtsRmks: states.remarks,
              mtsCreatedBy: userId,
            },
            `Created MAintennce Schedule for ID ${veh.vdtIdpk}`
          );
          setRefreshDB(!refreshDB);
        }
        //.......................
        fdispatch(setNew_Cancel_Button(true));
        fdispatch(setEdit_Cancel_Button(true));
        fdispatch(setBulk_Cancel_Button(true));
        fdispatch(setDisabled_Form(true));
        fdispatch(setSave_Update_Button(true));
        //.......................
        setModalData({
          message: "Record successfully added",
          title: "Success",
          okText: "Ok",
          cancelText: "Cancel",
        });
        setIcon("success");
      } catch (err: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: err.message,
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      setModalData({
        message: "Vehicle record not selected",
        title: "Required Fields!",
        okText: "Ok",
        cancelText: "Cancel",
      });
      setIcon("warning");
      setOpenModal(true);
    }
  };

  const updateData = async () => {
    try {
      // //.......................
      if (
        BullEdittoCancel === false &&
        rightVDdatax !== undefined &&
        rightVDdatax.length > 0 &&
        footerDatax !== undefined
      ) {
        for (let ids of footerDatax) {
          if (selectedRecordx.mtsBatchNo === ids.mtsBatchNo) {
            await Updating(
              `AsmTsmEntMaintenanceSchedule/UpdateMaintenanceSchedule?Id=${ids.vdtIdpk}`,
              {
                mtfStartDate: states.date,
                mtfWorkshopIdfk: states.ws?.id,
                mtfMaintenanceTypeIdfk: states.mt?.id,
                mtfReschedulePlan: states.rp?.label,
                mtfFrequencyDays: states.fdur,
                mtfFrequencyKm: states.fd,
                mtfMaintenanceDate: states.mdate,
                mtfFirstSmsPromptDays: states.sms1,
                mtfSecondSmsPromptDays: states.sms2,
                mtfThirdSmsPromptDays: states.sms3,
                mtfFourthSmsPromptDays: states.sms4,
                mtfPromptDriver: states.driver,
                mtfPromptOwner: states.ao,
                mtfPromptAdmin: states.admin,
                mtfPromptVehicleController: states.vc,
                mtfPromptManager: states.man,
                mtfPromptDirector: states.dir,
                mtfBatchNo: selectedRecordx.mtsBatchNo,
                mtfActive: states.active,
                mtfRmks: states.remarks,
                mtfCreatedBy: userId,
              },
              `Updated Maintenance Schedule for ID ${ids.vdtIdpk} `
            );
          }
        }
        //.......................
        fdispatch(setNew_Cancel_Button(true));
        fdispatch(setEdit_Cancel_Button(true));
        fdispatch(setBulk_Cancel_Button(true));
        fdispatch(setDisabled_Form(true));
        fdispatch(setSave_Update_Button(true));
        //.......................

        setRefreshDB(!refreshDB);
        populateFields(true);
        setModalData({
          message: "Bulk record updated successfully",
          title: "Success",
          okText: "Ok",
          cancelText: "Cancel",
        });
        setIcon("success");
      } else {
        try {
          await Updating(
            `AsmTsmEntMaintenanceSchedule/UpdateMaintenanceSchedule?Id=${"1"}`,
            {
              mtfStartDate: states.date,
              mtfDeptIdfk: userInfo.empIDpk,
              mtfSectionIdfk: userInfo.sxnIDpk,
              mtfWorkshopIdfk: states.ws?.id,
              mtfMaintenanceTypeIdfk: states.mt?.id,
              mtfReschedulePlan: states.rp?.label,
              mtfFrequencyDays: states.fdur,
              mtfFrequencyKm: states.fd,
              mtfMaintenanceDate: states.mdate,
              mtfFirstSmsPromptDays: states.sms1,
              mtfSecondSmsPromptDays: states.sms2,
              mtfThirdSmsPromptDays: states.sms3,
              mtfFourthSmsPromptDays: states.sms4,
              mtfPromptDriver: states.driver,
              mtfPromptOwner: states.ao,
              mtfPromptAdmin: states.admin,
              mtfPromptVehicleController: states.vc,
              mtfPromptManager: states.man,
              mtfPromptDirector: states.dir,
              mtfBatchNo: selectedRecordx.mtsBatchNo,
              mtfActive: states.active,
              mtfRmks: states.remarks,
              mtfEditedBy: 1,
              mtfEditedDate: editedDate,
            },
            ``
          );
          //.......................
          fdispatch(setNew_Cancel_Button(true));
          fdispatch(setEdit_Cancel_Button(true));
          fdispatch(setBulk_Cancel_Button(true));
          fdispatch(setDisabled_Form(true));
          fdispatch(setSave_Update_Button(true));
          //.......................
          setRefreshDB(!refreshDB);
          setModalData({
            message: "Record updated successfully",
            title: "Success",
            okText: "Ok",
            cancelText: "Cancel",
          });
          setIcon("success");
        } catch (error: any) {
          setIcon("warning");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: error.response ? error.response.data : "Server Error",
            title: "Unexpected Error!",
            okText: "Ok",
          });
        }
      }
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: error.response ? error.response.data : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
    // }
  };
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privtype, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);

  const newAccesPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      //.......................
      fdispatch(setNew_Cancel_Button(false));
      fdispatch(setEdit_Cancel_Button(true));
      fdispatch(setBulk_Cancel_Button(true));
      fdispatch(setDisabled_Form(false));
      fdispatch(setSave_Update_Button(true));
      //.......................

      // new_toggle!();
      populateFields(true);
      getMaxBatchNo();
    } else {
      setIcon("warning");

      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege ",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };
  const EditAccesPriv = () => {
    setPrivType("update");
    if (savePriv === true) {
      //.......................
      fdispatch(setEdit_Cancel_Button(false));
      fdispatch(setBulk_Cancel_Button(true));
      fdispatch(setDisabled_Form(false));
      fdispatch(setSave_Update_Button(false));
      //.......................

      validations("update");
    } else {
      setIcon("warning");

      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege ",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  const SaveAccesPriv = () => {
    setPrivType("update");
    if (savePriv === true) {
      validations("post");
    } else {
      setIcon("warning");

      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege ",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  return (
    <div
      style={{ width: "100%", height: "100%", maxWidth: "98%" }}
      className="    dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
    >
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={showModalDisableButton}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === updateModalData.title
            ? updateData()
            : modalData.title === saveModalData.title
            ? postData("post")
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{ maxHeight: "320px", minHeight: "320px", height: "320px" }}
        className=" items-start justify-start "
      >
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 pl-2 "
          name="basic"
          labelCol={{ span: 7 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
          disabled={DisabledForm}
        >
          <div style={{ width: "800px" }} className=" flex ">
            <div style={{ width: "400px" }} className="flex flex-col gap-0.5">
              {/* <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 9 }}
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Reschedule Plan"}
                  </p>
                }
                className="ml-[-23px] mb-1 w-[93%] dark:bg-slate-900 dark:text-darkModeSkin-base"
              > */}
              {/* <div className=" flex flex-row items-center dark:bg-slate-900 dark:text-darkModeSkin-base">
                  <InputSelect_template
                    style={{ marginBottom: "0px" }}
                    disabled={DisabledForm}
                    selectStyle={{ width: "233px" }}
                    placeHolder={states.rp?.label}
                    // isOpenDropdown={isOpenDropdown}
                    options={cus_data}
                    handleFocus={(e: any) => {
                      updateState("isOpenDropdown2", true);
                    }}
                    handleSearch={useCallback(
                      debounce((e: string) => {
                        // updateState(stateName!, e);

                        // dispatch(singleEndpoint(e));

                        // updateState("isOpenDropdown2", true);
                      }, 500), // Adjust the debounce delay as needed
                      []
                    )}
                    useCallFunc */}
              {/* // value={states.rp?.label}
                    // allowClear
                    // onChange={(value: any) => { */}
              {/* //   updateState("rp", { */}
              {/* //     id: JSON.parse(value).id,
                    //     name: JSON.parse(value).label,
                    //   });
                    // }}
                  /> */}
              {/* {cus_data?.map((option: any, index: number) => {
                      return (
                        <Select.Option
                          key={index}
                          value={JSON.stringify(option)}
                        >
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
              {/* </div> */}
              {/* </Form.Item> */}

              {selects.map(
                ({
                  id,
                  label,
                  idExpr,
                  dataExpr,
                  optionsData,
                  stateName,
                  defaultValue,
                  singleEndpoint,
                  isOpenDropdown,
                  isOpenDropdownKey,
                  handleFocus,
                }) => {
                  return (
                    <>
                      <div key={id}>
                        {label === "Workshop" ? (
                          <div>
                            <DropdownGrid_template
                              labelCol={7}
                              ref={dropdownRef}
                              defaultValue={defaultValue}
                              label={label}
                              gridData={optionsData}
                              columns={workshop_cols}
                              cusBtnStyle={true}
                              dropdownBtnStyles={{ width: 233 }}
                              disabled={DisabledForm}
                              handleRefresh={() => {
                                setStates({
                                  ...states,
                                  ws: { name: "", id: 0 },
                                });
                                updateState("refresh", !refreshDB);
                              }}
                              onRowClick={(e: any) => {
                                setOuterSelect && setOuterSelect(e);
                                updateState("ws", {
                                  id: e.wsdIdpk,
                                  name: e.wsdName,
                                });
                                // close();
                              }}
                            />
                          </div>
                        ) : (
                          // </Form.Item>
                          <>
                            <InputSelect_template
                              style={{ marginBottom: "-2px" }}
                              labelCol={7}
                              wrapperCol={16}
                              label={label}
                              idexpr={idExpr}
                              options={optionsData}
                              dataexp={dataExpr}
                              disabled={DisabledForm}
                              isOpenDropdown={isOpenDropdown}
                              useCallFunc
                              handleSearch={debounce((e) => {
                                updateState(stateName!, e);
                                // UpdateState("isLoading", true);
                                dispatch(
                                  singleEndpoint({
                                    search: e,
                                    pagenumber: 1,
                                    pagesize: 20,
                                  })
                                );

                                updateState(isOpenDropdownKey, true);
                              }, 500)}
                              handleFocus={(e: any) => {
                                updateState(isOpenDropdownKey, !isOpenDropdown);
                              }}
                              selectedValue={(object) => {
                                updateState(stateName!, {
                                  id: object[idExpr!],
                                  name: object[dataExpr!],
                                });
                                updateState(isOpenDropdownKey, false);
                              }}
                              handleRefresh={() => {
                                updateState(stateName!, { id: 0, name: "" });
                              }}
                              placeHolder={defaultValue}
                              disabledCustomWidth
                              cusWidth={"233px"}
                            />
                          </>
                        )}
                      </div>
                    </>
                  );
                }
              )}

              <Form wrapperCol={{ span: 14 }} labelCol={{ span: 7 }}>
                {dates.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <div key={id}>
                      <DateTemplate
                        label={label}
                        disabled={DisabledForm}
                        changeDate={(date: any) => {
                          updateState(
                            stateName!,
                            dayjs(date).format(dbDateFormat)
                          );
                        }}
                        selectedDate={dayjs(defaultValue)}
                      />
                    </div>
                  );
                })}
              </Form>

              <Form wrapperCol={{ span: 15 }} labelCol={{ span: 7 }}>
                {inputs.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <div key={id}>
                      <InputsTemplate
                        id={label}
                        style={{ marginTop: "-2px" }}
                        inputType="number"
                        label={label}
                        disabledStatus={DisabledForm}
                        useCallbackFunc
                        orderOnchange={(data) => {
                          updateState(stateName!, data);
                        }}
                        defaultValue={defaultValue}
                        inputStyle={{ width: "233px" }}
                        span
                      />
                    </div>
                  );
                })}
              </Form>
            </div>

            <div style={{ width: "400px" }} className="">
              <ul className=" flex flex-col">
                <div className="w-full  justify-between mb-0.5">
                  <li
                    style={{ width: "calc(340px - 20px)", marginLeft: "11px" }}
                    className="  flex items-center flex-row "
                  >
                    <p className="mr-3 text-xs">SMS Freq.(Days) : </p>
                    <div className="flex justify-evenly">
                      <Input
                        value={states.sms1}
                        onChange={(e: any) => {
                          updateState("sms1", e.target.value);
                        }}
                        style={{ width: "40px" }}
                        className=" mr-2"
                      />
                      <Input
                        value={states.sms2}
                        onChange={(e: any) => {
                          updateState("sms2", e.target.value);
                        }}
                        style={{ width: "40px" }}
                        className=" mr-2"
                      />
                      <Input
                        value={states.sms3}
                        onChange={(e: any) => {
                          updateState("sms3", e.target.value);
                        }}
                        style={{ width: "40px" }}
                        className=" mr-2"
                      />
                      <Input
                        value={states.sms4}
                        onChange={(e: any) => {
                          updateState("sms4", e.target.value);
                        }}
                        style={{ width: "40px" }}
                        className=" mr-2"
                      />
                    </div>
                  </li>
                  <div
                    style={{
                      width: "calc(300px - 0)",
                      marginLeft: "4px",
                    }}
                    className="w-full flex   "
                  >
                    <p className="  text-xs mr-3">SMS Prompts To? :</p>
                    <div
                      style={{
                        width: "calc(300px - 0)",
                        marginLeft: "0px",
                      }}
                      className="flex-col mt-0.5"
                    >
                      <div className=" flex  mb-0.5">
                        <li className=" flex items-center flex-row">
                          <Checkbox
                            checked={states.driver}
                            onChange={(e) => {
                              updateState("driver", e.target.checked);
                            }}
                          />
                          <p className="text-xs mr-3 pl-1">Driver </p>
                        </li>
                        <li className=" flex items-center flex-row ml-3.5">
                          <Checkbox
                            checked={states.ao}
                            onChange={(e) => {
                              updateState("ao", e.target.checked);
                            }}
                          />
                          <p className="text-xs mr-3 pl-1">
                            Approving Officer{" "}
                          </p>
                        </li>
                      </div>
                      <div className=" flex  mb-0.5">
                        <li className=" flex items-center flex-row">
                          <Checkbox
                            checked={states.admin}
                            onChange={(e) => {
                              updateState("admin", e.target.checked);
                            }}
                          />
                          <p className="text-xs mr-3 pl-1">Admin </p>
                        </li>
                        <li className=" flex items-center flex-row ml-3">
                          <Checkbox
                            checked={states.vc}
                            onChange={(e) => {
                              updateState("vc", e.target.checked);
                            }}
                          />
                          <p className="text-xs mr-3 pl-1">
                            Vehicle Controller{" "}
                          </p>
                        </li>
                        "
                      </div>

                      <div className=" flex  mb-0.5 ">
                        <li className=" flex items-center flex-row ">
                          <Checkbox
                            checked={states.man}
                            onChange={(e) => {
                              updateState("man", e.target.checked);
                            }}
                          />
                          <p className="text-xs mr-3 pl-1">Manager </p>
                        </li>
                        <li className=" flex items-center flex-row">
                          <Checkbox
                            checked={states.dir}
                            onChange={(e) => {
                              updateState("dir", e.target.checked);
                            }}
                          />
                          <p className="text-xs mr-3 pl-1">Director </p>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div style={{ width: "calc(100%)" }} className="mt-7">
                <Form wrapperCol={{ span: 18 }} labelCol={{ span: 7 }}>
                  {smsDate.map(({ id, label, stateName, defaultValue }) => {
                    return (
                      <div key={id}>
                        <DateTemplate
                          label={label}
                          disabled={true}
                          changeDate={(date: any) => {
                            updateState(
                              stateName!,
                              dayjs(date).format(dbDateFormat)
                            );
                          }}
                          selectedDate={dayjs(defaultValue)}
                          width={"251px"}
                        />
                      </div>
                    );
                  })}
                </Form>

                <Form wrapperCol={{ span: 20 }} labelCol={{ span: 7 }}>
                  {inputs1.map(({ id, label, stateName, defaultValue }) => {
                    return (
                      <div key={id}>
                        <InputsTemplate
                          label={label}
                          useCallbackFunc
                          defaultValue={defaultValue}
                          orderOnchange={(e) => {
                            updateState(stateName!, e);
                          }}
                          disabledStatus={true}
                          inputStyle={{ width: "251px" }}
                          span
                        />
                      </div>
                    );
                  })}
                </Form>
              </div>
            </div>
          </div>

          <div className="w-full">
            {/* Remarks */}
            <Form labelCol={{ span: 11 }}>
              <div className="ml-[59px]">
                <TextareaTemplate
                  label="Remarks"
                  height={127}
                  useCallbackFunc
                  setCallbackValue={(e) => {
                    updateState("remarks", e);
                  }}
                  readonly={DisabledForm}
                  disabled={DisabledForm}
                  defaultValue={states.remarks}
                />
              </div>
            </Form>
          </div>

          <div className="w-full  flex flex-col ">
            <div
              style={{ width: "calc(100% - 52px)", marginLeft: "53px" }}
              className=" flex justify-between"
            >
              <ul className="flex justify-start">
                <li className=" flex items-center flex-row pr-2">
                  <p className="mr-2">Active ? :</p>{" "}
                  <Checkbox
                    style={{ borderColor: borderTheme }}
                    className="border-[1px] px-1 rounded"
                    checked={states.active}
                    onChange={(e) => {
                      updateState("active", e.target.checked);
                    }}
                  />
                </li>
                <li className=" flex items-center flex-row pr-2">
                  <p className="mr-3">Batch No :</p>{" "}
                  <Input
                    readOnly={true}
                    value={states.batchNo}
                    onChange={(e: any) => {
                      updateState("batchNo", e.target.value);
                    }}
                    className="w-24"
                  />
                </li>
                <li className=" flex justify-center items-center flex-row ">
                  <span
                    onClick={() => {}}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 24,
                      borderColor: borderTheme,
                    }}
                    className=" flex  justify-center items-center mt-0.5 hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span>
                </li>
              </ul>

              <ul className="flex justify-end ">
                {DisabledForm === false && NewtoCancelButton === false ? (
                  <SaveButton
                    useCallbackFunc
                    disableButton={false}
                    handleSave={() => {
                      SaveAccesPriv();
                    }}
                  />
                ) : DisabledForm === false && EdittoCancelButton === false ? (
                  <UpdateButton
                    handleUpdate={() => {
                      EditAccesPriv();
                    }}
                  />
                ) : BullEdittoCancel === false && DisabledForm === false ? (
                  <UpdateButton
                    handleUpdate={() => {
                      EditAccesPriv();
                    }}
                  />
                ) : (
                  <SaveButton disableButton={true} />
                )}

                {NewtoCancelButton ? (
                  <>
                    {EdittoCancelButton && (
                      <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                          newAccesPriv();
                        }}
                      />
                    )}
                    {EdittoCancelButton === false && (
                      <Cancel
                        useCallbackFunc={true}
                        cancel_button_toggler={() => {
                          //.......................
                          fdispatch(setNew_Cancel_Button(true));
                          fdispatch(setEdit_Cancel_Button(true));
                          fdispatch(setBulk_Cancel_Button(true));
                          fdispatch(setDisabled_Form(true));
                          fdispatch(setSave_Update_Button(true));
                          //.......................

                          // populateFields(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Cancel
                    useCallbackFunc={true}
                    cancel_button_toggler={() => {
                      //.......................
                      fdispatch(setNew_Cancel_Button(true));
                      fdispatch(setEdit_Cancel_Button(true));
                      fdispatch(setBulk_Cancel_Button(true));
                      fdispatch(setDisabled_Form(true));
                      fdispatch(setSave_Update_Button(true));

                      //.......................

                      // populateFields(true);
                      // handleN
                    }}
                  />
                )}
              </ul>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

const cus_data = [
  { id: 0, label: "None" },
  { id: 1, label: "Every 1 Week" },
  { id: 2, label: "Every 2 Weeks" },
  { id: 3, label: "Every 3 Weeks" },
  { id: 4, label: "Every 1 Month" },
  { id: 5, label: "Every 2 Months" },
  { id: 6, label: "Every 3 Months" },
  { id: 7, label: "Every 4 Months" },
  { id: 8, label: "Every 5 Months" },
  { id: 9, label: "Every 6 Months" },
  { id: 10, label: "Every 7 Months" },
  { id: 11, label: "Every 8 Months" },
  { id: 12, label: "Every 9 Months" },
  { id: 13, label: "Every 10 Months" },
  { id: 14, label: "Every 11 Months" },
  { id: 15, label: "Every 1 year" },
  { id: 16, label: "Every 2 years" },
  { id: 17, label: "Every 3 years" },
  { id: 18, label: "Every 4 years" },
  { id: 19, label: "Every 5 years" },
  { id: 20, label: "Every 10 years" },
];
