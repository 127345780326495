/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { DateTemplate } from "../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../templates/select";
import { RefreshButton } from "../../_widget/utils";
import { SaveButton } from "../../../../../accessories/buttons";
import {
  stationColumns,
  stationEntries,
  stationEntriesColumns,
  stations,
} from "./_data/stationentriescolumns";
import MiniToolbar from "../../_widget/minitollbar";
import { Switcher } from "../../_widget/switcher";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import { ReadingsChart } from "../../_widget/readingschart";
import useFetch from "../../../../../../hooks/useFetch";
import {
  currentDate,
  dbDateFormat,
  deleteModalData,
  saveModalData,
  startingDate,
} from "../../../../../accessories/component_infos";
import { UpdateStates } from "../../../../../../functions/updateStates";
import dayjs, { Dayjs } from "dayjs";
import { useCrudFunc } from "../../../../../../functions/crud";
import { GetDataFunc } from "../../../../../../functions/get";
import { DeleteDataFunc } from "../../../../../../functions/delete";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { ModalTemplate } from "../../../../../../templates/modal";

interface stateTypes {
  dateStates: string;
  timeStates: { id: number; name: string };
  selectedStation: any;
  selectedEntry: any;
  searchText: { temp: string; text: string };
  startDate: { temp: string; index: string };
  endDate: { temp: string; index: string };
  checkbox: { temp: boolean | string; status: string | boolean };
  entries: any[]
  timeRefresh : boolean
  refresh : boolean
  stationRefresh : boolean
  selectedRecord : any
  isModalOpen : boolean
  ModalStates : {message : any , icon:"success"|"question"|"warning"}
  pageSize : number
}

export default function BusVoltage() {
  const { status } = useGraphSwitcher();
  const { status:resize } = useSwitcher();
  let FormCode = useSelector((state: RootState) => state.user?.formCode);

  const [posting, updating, patching] = useCrudFunc("powerapp");

  const timeInterval: { id: number; value: string }[] = [
    { id: 0, value: "15 Minutes" },
    { id: 1, value: "30 Minutes" },
    { id: 2, value: "45 Minutes" },
    { id: 4, value: "1 Hour" },
    { id: 5, value: "2 Hours" },
    { id: 6, value: "3 Hours" },
    { id: 7, value: "4 Hours" },
    { id: 8, value: "5 Hours" },
    { id: 9, value: "6 Hours" },
    { id: 10, value: "7 Hours" },
    { id: 11, value: "8 Hours" },
    { id: 12, value: "9 Hours" },
    { id: 13, value: "10 Hours" },
    { id: 14, value: "11 Hours" },
    { id: 15, value: "12 Hours" },
   
  ];
  const [states, setStates] = useState<stateTypes>({
    dateStates: currentDate,
    timeStates: { id: 0, name: timeInterval[0].value },
    selectedStation: {},
    selectedEntry: {},
    searchText: { temp: "", text: "" },
    startDate: { temp: startingDate, index: startingDate },
    endDate: { temp: currentDate, index: currentDate },
    checkbox: { temp: "", status: "" },
    entries : [],
    timeRefresh : false,
    refresh: false,
    stationRefresh: false,
    selectedRecord: {},
    isModalOpen: false,
    ModalStates: {message: saveModalData, icon: "question"},
    pageSize : 20
  });

  const {
    timeStates,
    dateStates,
    selectedStation,
    selectedEntry,
    checkbox,
    endDate,
    searchText,
    startDate,
    entries,
    timeRefresh,
    refresh,
    selectedRecord,
    isModalOpen,
    ModalStates,
    stationRefresh,
    pageSize
  } = states;

  

  const [stationData, stationError, stationLoading] = useFetch(
    `BusVoltages/bus_stations`,
    stationRefresh,
    undefined,
    undefined,
    "powerapp"
  );
  const [
    second_bus_stationData,
    second_bus_stationError,
    second_bus_stationLoading,
  ] = useFetch(
    `BusVoltages/second_bus_readings?SearchTerm=${
      searchText.text
    }&CheckState=${checkbox.status}&PageNumber=${1}&PageSize=${pageSize}&startDate=${
      startDate.index ?? "2010-01-01"
    }&endDate=${endDate.index ?? "2022-01-01"}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );

  const [stationEntries] = useFetch(`BusVoltages/pop_gdc_bus_details/${selectedStation?.stnIDpk}`, undefined,
    undefined,
    undefined,
    "powerapp" )
  const [GenerateTime] = useFetch(`BusVoltages/GenerateTime?timeInterval=${timeStates?.name.toLowerCase()}&startTime=${dateStates}`, timeRefresh,
    undefined,
    undefined,
    "powerapp" )

  useEffect(() => {
  const mergeTimeEntries = () => {
    const mergedEntries = GenerateTime.map((time: any, index: number) => {
      // Use the index to pick the corresponding station entry
      const correspondingStation = stationEntries[index] ||""; // Handle case if there are fewer stations
      
      return {
        ...time, 
        station: stationEntries,
      };
    });

    // Update the state with the merged entries
    UpdateStates("entries", mergedEntries, setStates);
  };

  mergeTimeEntries();
}, [GenerateTime, stationEntries]); // Ensure you have both dependencies



    

  console.log("busVoltage", selectedStation);
  console.log("generateTime", GenerateTime);

  const getBdtIDpk = (stationEntries:any, bdtName: string) => {
  const foundStation = stationEntries.station.find(station => station.bdtName === bdtName);
  return foundStation ? foundStation : null;
};

const getCorrespondingValue = (entries: any, bdtName: string) => {
    return entries[bdtName] || null;
  };

  const saveBusVoltage = () => {
    
    for(let entries of selectedEntry) {
      console.log(entries)
      if(entries?.station?.length < 1){
        // alert("no data")
          UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: {title: "Field Required!", message: "Station entry not found"}, icon:"warning"}, setStates)

      }else{

        for(let station of entries.station) {
          let getBus = getBdtIDpk(entries, station.bdtName )
              console.log(getBus)
              let getValues = getCorrespondingValue(entries,getBus.bdtName)

             
              // console.log("Bus Name: " + entries[])
          if(!getBus){
            UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: {title: "Field Required!", message: "Station entry not found"}, icon:"warning"}, setStates)
            return
          }

          if(getValues <1 || getValues > 1000){
            UpdateStates("isModalOpen", true, setStates); 
            UpdateStates("ModalStates", {...ModalStates, message: {title: "Field Required!", message: "Voltage must be between 0 and 1000"}, icon:"warning"}, setStates)
            return 
          }

          // Regular expression to check if it's an actual number (integer or decimal)
const numberRegExp = /^-?\d+(\.\d+)?$/;

if (!numberRegExp.test(String(getValues))) {
  UpdateStates("isModalOpen", true, setStates); 
  UpdateStates("ModalStates", {...ModalStates, message: {title: "Field Required!", message: "Voltage must be between 0 and 1000"}, icon:"warning"}, setStates)
  return }

          const dateTime = dayjs().format('YYYY-MM-DD') + ` ${entries?.colTime}`;
  
          const actualTime = dayjs(dateTime);
        try {
          // if (getBus) {
          const res = posting(
            `BusVoltages/create_bus_reading`,
            {
              brdStationIDfk: selectedStation?.stnIDpk,
              brdBusIDfk: getBus?.bdtIDpk,
              brdTime: actualTime,//entries?.colTime,
              brdVoltage: getValues,
              brdActive: true,
              brdRmks: entries?.remarks,
            },
            `Saving record for station ${selectedStation?.stnIDpk} and bus ${getBus?.bdtIDpk}`
          );
          console.log("Response",res)
          UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: saveModalData.success, icon:"success"}, setStates)
          UpdateStates("refresh", !refresh, setStates)
        //  refresh Grid (Live reloading)
      // }else{
      //   alert("hi")
      //   UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: saveModalData.error, icon:"warning"}, setStates)
  
      // }
        } catch (err) {
          UpdateStates("isModalOpen", true, setStates); 
          UpdateStates("ModalStates", {...ModalStates, message: saveModalData.error, icon:"warning"}, setStates)
  
        }
      
    
        }
      }
    } 

    
  };

  const deleteBusVoltage = async ()=>{
      try{
        const res = await DeleteDataFunc(`BusVoltages/bus_voltage`, {brdIDpk:selectedRecord?.brdIDpk,activeStatus:false,formCode: FormCode, formAction: `Deactivation of bus${selectedRecord?.brdIDpk}`}, 'powerapp')
      console.log(res)
      UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: deleteModalData.success, icon:"success"}, setStates)
      UpdateStates("refresh", !refresh, setStates)
   
      }catch(err:any){
        UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: deleteModalData.error, icon:"warning"}, setStates)
      }
  }

  return (
    <>
    <ModalTemplate  icon={ModalStates.icon} cancelText={ModalStates.message?.cancelText}
      disableCancel={ModalStates.icon === "success" ? true : false}
      cancelHandler={() => { UpdateStates("isModalOpen", false, setStates) }} open={isModalOpen} okHandler={() => { ModalStates.message?.title === saveModalData.title ? saveBusVoltage() : ModalStates.message?.title === deleteModalData.title ? deleteBusVoltage() : UpdateStates("isModalOpen", false, setStates) }}
      message={ModalStates.message?.message} okText={ModalStates.message?.okText} title={ModalStates.message?.title} />
    
    <section className="w-full h-full">
      <Switcher alternate={""}>
        {/* top section */}
        <section className="flex space-x-10 w-full border-2 p-2">
          {/* left section */}
          <aside className="w-[22%]">
            <aside className="flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400">
              <p className="text-sm font-medium ">STATION</p>
              <RefreshButton onClick={()=>{UpdateStates("stationRefresh" , !stationRefresh, setStates)}} />
            </aside>
            <aside>
              <Datagrid_template
                gridheight={170}
                showColumnHeaders={false}
                columns={[
                  {
                    id: 1,
                    caption: "STATION",
                    dataField: "stnName",
                  },
                ]}
                data={stationData}
                disablePaging
                disableSearch
                disableGroupPanel
                onRowClick={(e) => {
                  UpdateStates("selectedStation", e, setStates);
                }}
              />
            </aside>
          </aside>
          {/* right section */}
          <aside className="w-[78%]">
            <section className="flex space-x-2 items-center ml-2">
              <DateTemplate
                disabled={false}
                label="Date"
                changeDate={(date: Dayjs) => {
                  UpdateStates("dateStates", dayjs(date).format("YYYY-MM-DD"), setStates);
                }}
                selectedDate={dayjs(dateStates)}
              />
              <SelectsTemplate
                useCallFunc
                label="Time Interval"
                selectStyle={{ height: "23px" }}
                disabled={false}
                idexpr={"id"}
                dataexp={"value"}
                options={timeInterval}
                span={true}
                useCusWidth
                cusWidth={"90px"}
                disabledCustomWidth={true}
                placeHolder={timeStates.name}
                selectedValue={(e) => {
                  console.log(`selected time`, e)
                  UpdateStates(
                    "timeStates",
                    { id: e["id"], name: e["value"] },
                    setStates
                  );
                }}
              />
              <RefreshButton onClick={()=>{UpdateStates("timeRefresh", !timeRefresh, setStates)}} />
              <SaveButton
                useCallbackFunc
                handleSave={() => {UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates",  {...ModalStates, message: saveModalData, icon:"question"}, setStates)}}
                className={`flex h-6 items-center p-2 border-[1px] rounded dark:bg-slate-900 dark:text-darkModeSkin-base text-[12px]`}
                iconClassName="h-4 w-4 mr-1"
                disableButton={false}
              />
            </section>
            <section className="flex items-start">
              <p className="text-[12px] font-medium mr-2">Entries:</p>
              <Datagrid_template
                gridheight={170}
                selectionMode="multiple"
                showSelectionCheckBox="always"
                deselectFirstRow
                showColumnHeaders={true}
                columns={stationEntriesColumns(entries)}
                allowColumnEditing
                data={entries}
                disablePaging
                disableSearch
                disableGroupPanel
                selectedItemsChange={(e) => {
                  console.log("selectedEntries", e)
                  UpdateStates("selectedEntry", e, setStates);
                }}
                
              />
            </section>
          </aside>
        </section>
        <div className="h-1 w-full bg-sky-200" />
      </Switcher>
      {/* botom section */}
      <section className="flex flex-col">
        <MiniToolbar
          showEditIcon={false}
          showAddIcon={false}
          runCancel={() => {UpdateStates("isModalOpen", true, setStates); UpdateStates("ModalStates", {...ModalStates, message: deleteModalData, icon:"question"}, setStates)}}
          searchTextOnchange={(text) => {
            UpdateStates(
              "searchText",
              { temp: text, text: searchText.text },
              setStates
            );
          }}
          checkOnChange={(check) => {
            UpdateStates(
              "checkbox",
              { temp: check, status: checkbox.status },
              setStates
            );
          }}
          startDateChanged={(date) => {
            UpdateStates(
              "startDate",
              {
                temp: dayjs(date).format(dbDateFormat),
                index: startDate.index,
              },
              setStates
            );
          }}
          endDateChanged={(date) => {
            UpdateStates(
              "endDate",
              { temp: dayjs(date).format(dbDateFormat), index: endDate.index },
              setStates
            );
          }}
          startDate={startDate.temp}
          endDate={endDate.temp}
          handleRefresh={()=>{ UpdateStates(
            "searchText",
            { temp: '', text: '' },
            setStates
          );

          UpdateStates("refresh", !refresh, setStates)
        }}
          handleFind={() => {
            UpdateStates(
              "searchText",
              { temp: searchText.temp, text: searchText.temp },
              setStates
            );
            UpdateStates(
              "checkbox",
              { temp: checkbox.temp, status: checkbox.temp },
              setStates
            );
            UpdateStates(
              "startDate",
              { temp: startDate.temp, index: startDate.temp },
              setStates
            );
            UpdateStates(
              "endDate",
              { temp: endDate.temp, index: endDate.temp },
              setStates
            );
          }}
        />
        <section className="flex-grow ">
          <section className="w-full h-full">
            {status ? (
              <ReadingsChart />
            ) : (
              <Datagrid_template
                gridheight={!resize ? 415:700}
                showColumnHeaders={true}
                
                columns={stationColumns}
                data={second_bus_stationData}
                disableGroupPanel
                onRowClick={(e)=>{
                  UpdateStates("selectedRecord", e, setStates)
                }}
                pageSize={pageSize}
                handlePageSize={(e)=>{UpdateStates("pageSize", e, setStates)}}
              />
            )}
          </section>
        </section>
      </section>
    </section>
    </>
  );
}
