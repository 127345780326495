import dayjs from "dayjs";
import { useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import VehicleSimCardDetails from "./vehicleSimCard-details";
import { VehicleSimCardToolbar } from "./vehicleSimCard-toolbar";

const VehicleSimCard = () => {
  const [showTable, setShowTable] = useState<boolean>(true)
  const [newMode, setNewMode] = useState<boolean>(false);
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const switch_toggle = () => {
    setShowTable(!showTable);

  }
  const initialUrl = `/AsmTmsVehicleSimCardAssignmentGetAll?searchCriteriaIndex=0&pagenumber=1&pagesize=20&startDate=${dayjs().subtract(10,'years').format('YYYY-MM-DD')}&endDate=${dayjs(Date.now()).format('YYYY-MM-DD')}`
  const [mainUrl, setMainUrl] = useState(initialUrl);
  const [refresh, setRefresh] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [mainDataGridData, mainDataGridError, mainDataGridLoading] = useFetch(mainUrl, refresh, 'vsaIdpk');

  return (
    <>
      <div className="px-2">
        <VehicleSimCardToolbar 
        outerSelect={selectedRecord}
        toggler={switch_toggle} 
        setOuterSelected={setSelectedRecord}
        handleNew={(value) => {
          setNewMode(value);
          setShowTable(true)
        }}

          toggleNew={newMode}
          toggleUpdate={updateMode}
          handleEdit={(value:boolean) => {
            setUpdateMode(value)
          }}
          handleFind={(val) => { setMainUrl(val) }}
          handleRefresh={() => { }}
          handlePrint={() => { }} />
      </div>

      <div className="px-2 mb-0.5  ">
        <VehicleSimCardDetails
          mainDataGrid={mainDataGridData}
          handleUpdate={(value) => {
            setUpdateMode(value);
          }}
          handleNew={(value) => {
            setNewMode(value);
          }}
          toggled={showTable}
          toggleNew={newMode}
          toggleUpdate={updateMode}
          setSelectRecodx={setSelectedRecord}
          handleRefresh={() => {
            setRefresh(!refresh);
          }}
          setShowTable={setShowTable}
        />
      </div>
    </>
  );
};

export default VehicleSimCard;
