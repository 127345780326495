/* eslint-disable react/jsx-pascal-case */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import  Datagrid_template from "../../../../../../templates/Datagrid";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { navbar_height, saveModalData } from "../../../../../accessories/component_infos";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import { searchTextTypes, searchCriteriaTypes, statusTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { RootState } from "../../../../../../app/store";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { Dump_Dips_Details_Form } from "./widgets/Fuel_dump_dips_details";
import { fuel_dump_dips_cols } from "./data/datagrid_cols";

interface props {
  state_change: boolean;
}

export const Dump_Dips = ({ state_change }: props) => {
  const dispatch = useDispatch(); //initiate dispatch
  const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
  const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass);
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true)

  const [initialH, fullH] = useResizeMode(switch_change)


  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 40);
  var pageHeight = initialH - (navbar_height + news_flash_height + formheight + 20);
  
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);

    } else {
      setSwitch_change(!switch_change);
    }
  }

  useEffect(() => {
    dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))
    dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
    set_form_height((formRef.current?.clientHeight) + 30)
  }, [])

  const [employeeId, userId, UserModel] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save")
  const [updatePriv] = useFormPriviledge(privType,)

  const [selectedData, setSeletedData] = useState<any>({});
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [refresh, setRefresh] = useState(false);

  const genFormat = 'YYYY-MM-DD';
  const today = dayjs().format(genFormat);
  const initialStart = dayjs().subtract(14, 'year').startOf('year').format(genFormat)

  const [startDate, setStartDate] = useState<searchTextTypes>({ text: initialStart, temp: initialStart });
  const [endDate, setEndDate] = useState<searchTextTypes>({ text: today, temp: today })
  const [searchText, setSearchText] = useState<searchTextTypes>({ text: '', temp: '' })
  const [status, setStatus] = useState<statusTypes>({ temp: '', status: '' });

  const [gridData, gridError, gridLoading] = useFetch(`AsmTmsEntFuelDumpDip/GetAllFuelDumpDips?employeeId=${employeeId}&startdate=${startDate.text}&enddate=${endDate.text}&isAdmin=${UserModel.empIsOrgAdmin}&active=${status.status}&search=${searchText.text}`, refresh, 'fddIdpk');
  useEffect(() => {
    gridLoading ? setBusyLoader('.') : setBusyLoader('');

  }, [gridLoading])

  // useResizeMode(pageHeight)

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
  const [showCancel] = useState(false);

  const doubleClickAccesspriv = () => {
    setPrivType('update')
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: 'updateMode', value: true }));

      dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
    } else {
      setIcon('warning')
      setOpenModal(true)
      setModalData({ title: 'Access Denied!', message: 'No Access Privilege' })
    }
  }


  return (
    <>
      {gridError && (
        <WarningAlert />
      )}

      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
        <ModalTemplate icon_width={modalData.iconSize === 'large' ? 100 : 50} icon={icon} cancelText={modalData.cancelText}
          disableCancel={!showCancel}
          cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { setOpenModal(false) }}
          message={modalData.message} okText={modalData.okText} title={modalData.title} />
        <div className=" px-2">
          <HrSetupsCustomToolbar
            startDate={startDate.temp}
            endDate={endDate.temp}
            startDateChanged={(date) => setStartDate({ temp: dayjs(date).format(genFormat), text: startDate.text })}
            endDateChanged={(date) => setEndDate({ temp: dayjs(date).format(genFormat), text: endDate.text })}
            searchTextOnchange={(text) => setSearchText({ temp: text, text: searchText.text })}
            checkOnChange={(check) => setStatus({ temp: check, status: status.status })}
            handleFind={() => {
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setStatus({ temp: status.temp, status: status.temp });
            }}
            handleNew={() => setSeletedData({})}

            outerSelected={selectedData}
            toggler={switch_toggle}
            setVisible={setSwitch_change}
            handleRefresh={() => {
              setSearchText({ temp: '', text: '' }); 
              setStatus({ temp: '', status: '' });
            }}
          />
        </div>

        {switch_change && <div ref={formRef} className="px-2">
          <p id={"form_header"} style={{ borderColor: borderTheme,}} className="pt-1 pl-4 w-90% border-[1px] rounded-t-md ">
            Fuel Dump Dip Details
          </p>
          <div
            style={{ height: "270px" }}
            className="w-full flex justify-between gap-x-4 border-[1px] border-b-0 mb-1 pb-1"
          >
            <div style={{ width: "100%", height: "250px" }} className="">
              <Dump_Dips_Details_Form
                refreshGrid={() => setRefresh(current => !current)}
                clearSelected={() => setSeletedData({})}
                selectedData={selectedData} />
            </div>
          </div>
        </div>}
        <div
          style={{ height: "" }}
          className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base"
        >
          <Datagrid_template
            onRowClick={(data) => {
              setSeletedData(data ?? {});
              dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

              dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
            }}
            rowDoubleClicked={() => {
              doubleClickAccesspriv()

            }}
            gridheight={switch_change ? pageHeight : fullPage}
            columns={fuel_dump_dips_cols}
            data={gridData}
          />
        </div>
      </div>
    </>
  );
};
