import { Form, Select } from "antd";
import {
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import reset from "../../assets/reset.png";
import settings from "../../assets/setting.png";
import { DownOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { RootState } from "../../app/store";
import { Button } from "devextreme-react/cjs/list";

interface props {
  index?: number | string;
  label?: string;
  options?: any;
  idexpr?: string;
  dataexp?: string;
  diffExprs?: { idKey: string; valueKey: string }; //use when gridview data value does match selects data keys
  span?: boolean;
  disabled?: boolean | undefined;
  style?: any;
  selectStyle?: any;
  extraWidget?: ReactNode;
  placeHolder?: any;
  selectedValue?: (object: any) => void;
  handleRefresh?: () => void; //refresh click listener
  handleClear?: () => void; // clear the actual state values
  handleSettings?: () => void; // handles settings click
  handleSearch?: (e: any) => void; // handles settings click
  handleFocus?: (e: any) => void; // handles settings click
  useCallFunc?: boolean;
  labelCol?: number;
  wrapperCol?: number;
  disabledCustomWidth?: boolean;
  cusWidth?: any;
  useCusWidth?: boolean;
  settingsIcon?: boolean;
  settingsIconBefore?: boolean;
  islabelCol?: boolean;
  isOpenDropdown?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  field?: any;
  registerName?: string;
  errors?: any;
  toolTipId?:string
}

const InputAndSelect_template = ({
  label,
  options,
  idexpr,
  fullWidth,
  dataexp,
  span,
  style,
  selectStyle,
  extraWidget,
  disabled = undefined,
  placeHolder,
  handleRefresh,
  selectedValue,
  useCallFunc,
  labelCol,
  islabelCol,
  isOpenDropdown = false,
  wrapperCol,
  disabledCustomWidth = false,
  cusWidth,
  useCusWidth = false,
  settingsIcon,
  settingsIconBefore,
  handleClear,
  handleSettings,
  handleSearch,
  handleFocus,
  isLoading = false,
  toolTipId,
}: props) => {
  const randIds = useId();
  const [searchInput, setSearchInput] = useState<any>(""); // New state for search input
  const [isTyping, setIsTyping] = useState<boolean>(false); // New state to track if the user is typing

  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled mode from general redux slice
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const handleSelectedOnChange = useCallback(
    (value: string) => {
      try {
        const selectedOptionObject = JSON.parse(value);
        if (selectedValue) {
          selectedValue(selectedOptionObject);
        }
      } catch (error) {
      } finally {
        setIsTyping(false); // Ensure isTyping is reset
        setSearchInput(value); // Set the placeholder to the selected value
      }
    },
    [searchInput]
  );

  // Correctly memoizing options for the Select component
  const memoizedOptions = useMemo(() => {
    return options?.map((option: any) => {
      const key = option[idexpr!];
      const value = JSON.stringify(option);
      const label = option[dataexp!];

      // Return the Select.Option JSX directly
      return (
        <Select.Option key={key} value={value}>
          {label}
        </Select.Option>
      );
    });
  }, [options, idexpr, dataexp]);

  const handleBlur = () => {
    if (isTyping && searchInput) {
      const generatedObject = { [idexpr!]: randIds, [dataexp!]: searchInput };
      if (selectedValue) {
        selectedValue(generatedObject);
        setSearchInput(generatedObject[dataexp!]);
      }
      setIsTyping(false);
      setSearchInput(searchInput); // Optionally reset input after handling
    }
  };

  const debouncedSearch = useCallback(
    debounce((e) => {
      setIsTyping(true);
      setSearchInput(e); // Update search input instead of items
      if (handleSearch) {
        handleSearch(e);
      }
    }, 2000), // Adjust the debounce delay as needed
    []
  );

  // this help set/populate the data when selected from the grid
  useEffect(() => {
    if (useCallFunc) {
      setSearchInput(placeHolder);
    }
  }, [placeHolder]);
  return (
    <Form.Item
      style={style}
      className=""
      label={
        label && (
          <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
            {label}
          </p>
        )
      }
      wrapperCol={{ span: wrapperCol && wrapperCol }}
    >
      <div className="flex h-full  flex-row items-center">
        <Select
          onChange={handleSelectedOnChange}
          id={toolTipId}
          onClick={handleFocus}
          value={searchInput}
          key={useCallFunc ? (placeHolder ? placeHolder : "") : searchInput}
          // className={`${errors && errors[registerName] ? 'border border-red-500':''} mb-0`}
          className={`mb-0`}
          allowClear={false}
          disabled={disabled !== undefined ? disabled : formdisabled}
          style={{
            ...selectStyle,
            maxWidth: `${
              !disabledCustomWidth
                ? "calc(200% - 28px)"
                : cusWidth || (useCusWidth === true && cusWidth)
            } `,
          }}
          showSearch={true}
          // onSearch={debouncedSearch}
          onSearch={
            (e) => {
              setIsTyping(true);
              setSearchInput(e); // Update search input instead of items
              handleSearch && handleSearch(e)
            }}
          // (e) => {
          //   setIsTyping(true);
          //   setSearchInput(e); // Update search input instead of items
          //   handleSearch && handleSearch(e)
          // }}
          onBlur={handleBlur}
          suffixIcon={<DownOutlined size={3} color={"#ccc"} />}
          // {...field}
        >
          {memoizedOptions}
        </Select>

        {settingsIconBefore && (
          <span
            onClick={() => {
              setSearchInput("");
              handleClear && handleClear();
              handleRefresh && handleRefresh();
            }}
            style={{
              height: 22.5,
              marginBottom: 2,
              borderWidth: 1,
              width: 24,
              borderColor: borderTheme,
            }}
            className="ml-0 flex justify-center hover:cursor-pointer border-gray-300 rounded"
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-1 items-center justify-center"
              src={settings}
              alt="reset"
            />
          </span>
        )}

        {!span && (
          <button
            onClick={() => {
              // setSearchInput("");
              handleClear && handleClear();
              handleRefresh && handleRefresh();
            }}
            disabled={formdisabled}
            style={{
              height: 22.5,
              marginBottom: 2,
              borderWidth: 1,
              width: 26,
              marginLeft: 3,
              borderColor: borderTheme,
            }}
            className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
              src={reset}
              alt="reset"
            />
          </button>
        )}

        {settingsIcon && (
          <span
            onClick={() => {
              handleSettings && handleSettings();
              handleRefresh && handleRefresh();
            }}
            style={{
              height: 22.5,
              marginBottom: 2,
              borderWidth: 1,
              width: 24,
              borderColor: borderTheme,
            }}
            className="ml-3 flex justify-center hover:cursor-pointer border-gray-300 rounded "
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
              src={settings}
              alt="reset"
            />
          </span>
        )}

        {fullWidth && extraWidget}
        {/* {extraWidget} */}
      </div>
    </Form.Item>
  );
};

export default InputAndSelect_template;
