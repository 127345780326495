import { Form, InputNumber, Slider } from "antd";
import React from "react";
import { DateTemplate } from "../../../../templates/date";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import InputSelect_template from "../../../../templates/InputSelect";
import { TextareaTemplate } from "../../../../templates/textarea";
import {
  EditButton,
  NewButton,
  SaveButton,
} from "../../../accessories/buttons";
import switchChart from "../../../../assets/switch-chart.png";
import lock from "../../../../assets/lock.png";
import { IconsContainer } from "../../elogger/StationReadings/_widget/stationReadingsToolbar";
import { RefreshButton } from "../../elogger/StationReadings/_widget/utils";

interface props {
  switchCase?: () => void;
}
export const Case_update = ({ switchCase }: props) => {
  return (
    <div className="w-full h-full ">
      <div className="text-md font-medium border-b-1 bg-slate-200   pl-2 flex">
        <p className="w-full mt-[4px]">{"Case Update"}</p>
        <div className="w-full flex justify-end gap-2">
          <div>
          <CheckboxTemlate customDisabled={false} withBorders />
          </div>
          <div className="mt-[2px]">
          <IconsContainer
            url={lock}
            name="lock"
            tooltip="Click to switch between the bar and line chart"
            onClick={() => {}}
          />
          </div>
          <div className="mt-[3px]">
          <RefreshButton />
          </div>
          <div className="mt-[2px]">
          <IconsContainer
            url={switchChart}
            name="Switch Chart"
            tooltip="Click to switch between the bar and line chart"
            onClick={() => {
              switchCase();
            }}
          />
          </div>
          
          
         
         
        </div>
      </div>

      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 space-y-[2px] "
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <Form.Item>
        <Form 
          labelCol={{ span: 10 }}
          > 
          <div className="flex w-full ">
            <div className="w-[30%] ">
              <DateTemplate label="Date" width={300} />
            </div>
            <div className="w-[30%] flex space-x-2 ml-4 border  ">
              <CheckboxTemlate withBorders />
              <label className="text-sm">{": Request for Info?"}</label>
            </div>
            <div className="w-[30%] flex space-x-2 border ">
              <CheckboxTemlate withBorders />
              <label className="text-sm">{": Info Recieved?"}</label>
            </div>
          </div>
          </Form>
        </Form.Item>
        <Form
          className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <Form.Item
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs ">
                {"% Complete"}
              </p>
            }
          >
            <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base ">
              <Slider
                style={{ width: "100%" }}
                className=" "
                min={0}
                max={100}
                onChange={(e) => {
                  //   setState("complete", e);
                }}
                // value={states.complete}
              />
              <InputNumber
                className="h-6 w-16 flex items-center justify-center mr-4"
                size="small"
                min={0}
                max={100}
                // value={states.complete}
                onChange={(e) => {
                  //   setState("complete", e);
                }}
              />
            </div>
          </Form.Item>
        </Form>
        <Form.Item className="mb-1">
        <Form 
          labelCol={{ span:7 }}
          > 
          <div className="flex  w-full gap-2 ml-[6px]">
            <div className="w-[40%]  ">
              <InputSelect_template
                label="Status"
                selectStyle={{ width: 150 }}
                labelCol={12}
              />
            </div>
            <div className="w-[40%] ">
              <InputSelect_template
                label="Impact"
                selectStyle={{ width: 150 }}
              />
            </div>
          </div>
          </Form>
        </Form.Item>
        <Form.Item>
          <Form labelCol={{ span: 3 }}>
            <div className="w-full  ">
              <InputSelect_template label="Tag" />
            </div>
          </Form>
          <Form 
          labelCol={{ span: 3 }}
          > 
          <div className=" ">
            <TextareaTemplate height={200} label="Update" labelCol={3} />
          </div>
          </Form>
          <Form 
          labelCol={{ span: 3 }}
          > 
          <div className="w-full">
            <InputSelect_template label="Done By" />
          </div>
          </Form>
        </Form.Item>
       
        <div className="w-full flex ">
          <div className=" gap-10 flex mt-2 w-full ml-2">
            <EditButton />
            <p className="mt-2 text-blue-600"> {"Done"}</p>
          </div>
          <div className="flex gap-4 mt-2 justify-end w-full ">
            <SaveButton />
            <NewButton />
          </div>
        </div>
      </Form>
    </div>
  );
};
