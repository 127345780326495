

export const other_details_TH = [
  {
    id: 1,
    caption: "PARAMETER",
    dataField: "opmName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "VALUE",
    dataField: "olpValue",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "REMARKS",
    dataField: "olpRmks",
    alignment: "left",
    fixed: false,
    width: 400
  },
  

]


export const question_table_data = [
  
]
