/* eslint-disable react/jsx-pascal-case */
import React, { useRef, useState } from "react";
import { RefreshButtonTemplate } from "../../../../templates/RefreshButtonTemplate";
import {
  elogbook_columns2,
  elogbook_columns3,
  elogbook_columns4,
  elogbook_columns5,
} from "../data/EloggerDatagridData";
import { Cancel, CustomizableButton } from "../../../accessories/buttons";
import CancelBlack from "../../../../assets/close_black.png";
import useResizeMode from "../../../../hooks/useResizeMode";
import {
  navbar_height,
  useModalData,
} from "../../../accessories/component_infos";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useAxiosService } from "../../../../general/requestConfig";
import EquipmentDataTable from "../StationReadings/tabContents/EquipmentOutage/equipment-data-table";
import { ModalTemplate } from "../../../../templates/modal";
import { parseLower } from "./utils";
import { inRange } from "lodash";
import { UpdateStationShiftMembersToolbar } from "./update-station-shiftmembers-toolbar";

function Update_Station_Shift_Members() {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const query = useAxiosService("powerapp");
  const [modal, setModal] = useModalData();
  let stages = ["grid-form", "only-grid", "only-form"];
  let stagesIterator = useRef(stages[Symbol.iterator]());
  const [stage, setStage] = useState<{ value: string; done: boolean }>({
    value: "only-form",
    done: false,
  });

  // useEffect(() => {
  //   const next = stagesIterator.current?.next();
  //   if (next.done) {
  //     stagesIterator.current = stages[Symbol.iterator]();
  //     return;
  //   }
  //   setStage({ value: next.value, done: next.done });
  // }, []);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [checkValue, setCheckValue] = useState<boolean | any>(undefined);
  const [staffList, setStaffList] = useState<StaffList[] | undefined>();
  const [stationShiftMembers, setStationShiftMembers] = useState<
    StationShiftMembers[] | undefined
  >();
  const [stations, setStations] = useState<Stations[]>();
  const [_recordStations, setRecordStations] = useState<Stations>();
  const [_recordShiftMembers, setRecordShiftMembers] =
    useState<StationShiftMembers>();
  const recordStations = useRef<Stations>();
  const recordShiftMembers = useRef<StationShiftMembers>();
  const [gridAllShiftMembers, setGridAllShiftMembers] =
    useState<GridPvaRecordType[]>();
  const [pagination, setPagination] = useState<Record<string, number>>({
    size: 10,
    number: 1,
  });
  const [refreshKeys, setRefreshKeys] = useState<Record<string, boolean>>({
    station: false,
    staffList: false,
    shiftMembers: false,
    pvaRecord: false,
  });

  var [datagridHeight, setDataGridHeight] = useState<number>(0);
  var [intialH, finalHeight] = useResizeMode(0);

  var initialH = intialH - datagridHeight;
  var [fullPage] = useState(
    finalHeight - (navbar_height + news_flash_height + 35)
  );

  const UpdateStaffList = (rowData?: StaffList) => {
    if (!recordStations?.current?.stnIDpk) {
      setModal((prev) => ({
        ...prev,
        message: "Please select a station",
        title: "Error",
        icon: "warning",
        state: true,
        okText: "Okay",
        cancelText: "",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
      }));
      return;
    }
    setModal({
      state: true,
      title: "Add staff to shift members",
      message: parseLower(
        "Are you sure you want to add the selected staff to the group?"
      ),
      icon: "question",
      okText: "Yes, add",
      cancelText: "No, cancel",
      disableCancel: false,
      okHandler: () => {
        query({
          url: `UpdateStationShiftMembers/double_click`,
          method: "POST",
          params: {
            empIDpk: rowData.empIDpk,
            stnIDpk: recordStations?.current?.stnIDpk,
          },
        }).then((res) => {
          if (inRange(res?.code, 200, 300)) {
            setModal((prev) => ({
              ...prev,
              message: "Staff added successfully",
              title: "Success",
              icon: "success",
              state: true,
              okText: "Ok",
              cancelText: "",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
            }));
            setStaffList(undefined);
            setRefreshKeys((state) => ({
              ...state,
              shiftMembers: !state.shiftMembers,
              staffList: !state.staffList,
            }));
          } else {
            setModal((prev) => ({
              ...prev,
              message: "An error occurred",
              title: "Error",
              icon: "warning",
              state: true,
              okText: "Okay",
              cancelText: "",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
            }));
          }
        });
        // setModal((prev) => ({
        //   ...prev,
        //   state: false,
        // }));
      },
      cancelHandler: () => {
        setModal((prev) => ({
          ...prev,
          state: false,
        }));
      },
    });
  };
  const UpdateStationShiftMembers = (rowData?: StationShiftMembers) => {
    setModal({
      state: true,
      title: "Remove staff from group?",
      message: parseLower(
        "Are you sure you want to remove the selected staff from the group?"
      ),
      icon: "question",
      okText: "Yes, remove",
      cancelText: "No, cancel",
      disableCancel: false,
      okHandler: () => {
        query({
          url: `UpdateStationShiftMembers/TeamMembers_DoubleClick`,
          method: "PUT",
          params: {
            pvaIDpk: rowData.pvaIDpk,
          },
        }).then((res) => {
          if (inRange(res?.code, 200, 300)) {
            setModal((prev) => ({
              ...prev,
              message: "Staff removed successfully",
              title: "Success",
              icon: "success",
              state: true,
              okText: "Ok",
              cancelText: "",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
            }));
            setStationShiftMembers(undefined);
            setRefreshKeys((state) => ({
              ...state,
              shiftMembers: !state.shiftMembers,
              staffList: !state.staffList,
            }));
          } else {
            setModal((prev) => ({
              ...prev,
              message: "An error occurred",
              title: "Error",
              icon: "warning",
              state: true,
              okText: "Okay",
              cancelText: "",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
            }));
          }
        });
      },
      cancelHandler: () => {
        setModal((prev) => ({
          ...prev,
          state: false,
        }));
      },
    });
  };

  const RevokeShiftMemberPriviledges = () => {
    if (!recordShiftMembers?.current?.pvaIDpk) {
      setModal((prev) => ({
        ...prev,
        message: "Please select a staff to revoke",
        title: "Error",
        icon: "warning",
        state: true,
        okText: "Okay",
        cancelText: "",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
      }));
      return;
    }

    setModal({
      state: true,
      title: "Revoke staff priviledges",
      message: parseLower(
        `Are you sure you want to revoke the "Shift Member" priviledges for ${recordShiftMembers?.current?.empName} for ${recordStations?.current?.stnName}?`
      ),
      icon: "question",
      okText: "Yes, revoke",
      cancelText: "No, cancel",
      disableCancel: false,
      okHandler: () => {
        query({
          url: `UpdateStationShiftMembers/revoke`,
          method: "PUT",
          params: {
            pvaIDpk: recordShiftMembers?.current?.pvaIDpk,
          },
        }).then((res) => {
          if (inRange(res?.code, 200, 300)) {
            setModal((prev) => ({
              ...prev,
              message: "Staff removed successfully",
              title: "Success",
              icon: "success",
              state: true,
              okText: "Okay",
              cancelText: "",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
            }));
            setRefreshKeys((state) => ({
              ...state,
              shiftMembers: !state.shiftMembers,
              staffList: !state.staffList,
            }));
          } else {
            setModal((prev) => ({
              ...prev,
              message: "An error occurred",
              title: "Error",
              icon: "warning",
              state: true,
              okText: "Okay",
              cancelText: "",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
            }));
          }
        });
      },
      cancelHandler: () => {
        setModal((prev) => ({
          ...prev,
          state: false,
        }));
      },
    });
  };
  const RevokeAllShiftMemberPriviledges = () => {
    if (!recordShiftMembers?.current?.pvaIDpk) {
      setModal((prev) => ({
        ...prev,
        message: "Please select a staff to revoke",
        title: "Error",
        icon: "warning",
        state: true,
        okText: "Okay",
        cancelText: "",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
      }));
      return;
    }

    setModal({
      state: true,
      title: "Revoke staff priviledges",
      message: parseLower(
        `Are you sure you want to revoke all the "Shift Member" priviledges for ${recordShiftMembers?.current?.empName} for ${recordStations?.current?.stnName}?`
      ),
      icon: "question",
      okText: "Yes",
      cancelText: "No",
      disableCancel: false,
      okHandler: () => {
        setModal({
          state: true,
          title: "Revoke All Priviledges",
          message: parseLower(
            `This action will revoke all the "Shift Member" priviledges for ${recordShiftMembers?.current?.empName} for ${recordStations?.current?.stnName}. Are you sure you want to proceed and remove all of the priviledges?`
          ),
          icon: "question",
          okText: "Yes, revoke",
          cancelText: "No, cancel",
          disableCancel: false,
          okHandler: () => {
            query({
              url: `UpdateStationShiftMembers/revoke_all_privilges`,
              method: "PUT",
              params: {
                pvaIDpk: recordShiftMembers?.current?.pvaIDpk,
              },
            }).then((res) => {
              if (inRange(res?.code, 200, 300)) {
                setModal((prev) => ({
                  ...prev,
                  message:
                    "Priviledges revoked successfully from the station's shift members",
                  title: "Success",
                  icon: "success",
                  state: true,
                  okText: "Okay",
                  cancelText: "",
                  disableCancel: true,
                  okHandler: () => {
                    setModal((prev) => ({
                      ...prev,
                      state: false,
                    }));
                  },
                }));
                setRefreshKeys((state) => ({
                  ...state,
                  shiftMembers: !state.shiftMembers,
                  staffList: !state.staffList,
                }));
              } else {
                setModal((prev) => ({
                  ...prev,
                  message: "An error occurred",
                  title: "Error",
                  icon: "warning",
                  state: true,
                  okText: "Okay",
                  cancelText: "",
                  disableCancel: true,
                  okHandler: () => {
                    setModal((prev) => ({
                      ...prev,
                      state: false,
                    }));
                  },
                }));
              }
            });
          },
          cancelHandler: () => {
            setModal((prev) => ({
              ...prev,
              state: false,
            }));
          },
        });
      },
      cancelHandler: () => {
        setModal((prev) => ({
          ...prev,
          state: false,
        }));
      },
    });
  };

  type Stations = {
    stnIDpk: number;
    stnName: string;
    stnOperational: string;
    stnActive: boolean;
  };

  // Fetch staff list
  React.useEffect(() => {
    // if no station id, ignore the fetch
    if (!recordStations?.current?.stnIDpk) return;
    query({
      url: `UpdateStationShiftMembers/GetStaffList`,
      method: "GET",
      params: {
        stnIDpk: recordStations?.current?.stnIDpk,
      },
    }).then((res) => {
      setStaffList(res?.data);
    });
  }, [refreshKeys.staffList, _recordStations?.stnIDpk]);

  // Fetch station shift members
  React.useEffect(() => {
    // if no station id, ignore the fetch
    if (!recordStations?.current?.stnIDpk) return;
    query({
      url: `UpdateStationShiftMembers/GetStationShiftMembers`,
      method: "GET",
      params: {
        stnIDpk: recordStations?.current?.stnIDpk,
      },
    }).then((res) => {
      setStationShiftMembers(res?.data);
    });
  }, [refreshKeys.shiftMembers, _recordStations?.stnIDpk]);

  // Fetch stations
  React.useEffect(() => {
    query({
      url: `UpdateStationShiftMembers/GetStationList`,
      method: "GET",
    }).then((res) => {
      setStations(res?.data);
    });
  }, [refreshKeys.station]);

  // datagrid, fetch all station members
  React.useEffect(() => {
    query({
      url: `UpdateStationShiftMembers/PopGdv_StatinShiftMembers`,
      method: "GET",
      params: {
        SearchTerm: searchValue,
        CheckState: checkValue,
        PageNumber: pagination.number,
        PageSize: pagination.size,
      },
    }).then((res) => {
      setGridAllShiftMembers(res?.data);
    });
  }, [refreshKeys.pvaRecord, pagination.number, pagination.size]);

  return (
    <>
      <ModalTemplate
        width={400}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />
      <div className="mx-2 w-full">
        <UpdateStationShiftMembersToolbar
          disableNew_edit={true}
          withDates={false}
          // toggler={operateDataGrid}
          handleFind={() => {
            setRefreshKeys((state) => ({
              ...state,
              pvaRecord: !state.pvaRecord,
            }));
          }}
          handleRefresh={(checkValue) => {
            setSearchValue(undefined);
            setCheckValue("");
            setRefreshKeys((state) => ({
              ...state,
              pvaRecord: !state.pvaRecord,
            }));
          }}
          searchTextOnchange={(searchValue: string) => {
            setSearchValue(searchValue);
          }}
          checkOnChange={(value: boolean | undefined) => {
            setCheckValue(value);
          }}
          toggler={() => {
            const next = stagesIterator.current?.next();
            if (next.value === undefined) {
              stagesIterator.current = stages[Symbol.iterator]();
              setStage({ value: "only-grid", done: false });
              return;
              // when done is true, next.value is also undefined, so we need to reset the iterator and also set the stage to the first value
              // stagesIterator.current = stages[Symbol.iterator]();
              // setStage({
              //   value: "grid-form",
              //   done: false,
              // });
              // return;
            }
            setStage({ value: next.value, done: next.done });
            // set datagrid height based on value
            switch (next.value) {
              case "grid-form":
                setDataGridHeight(340);
                break;
              case "only-grid":
                setDataGridHeight(fullPage);
                break;
              case "only-form":
                setDataGridHeight(0);
                break;
              default:
                setDataGridHeight(0);
                break;
            }
          }}
        />
      </div>

      <div className="grid grid-cols-3 gap-2 w-full m-1">
        {(stage?.value === "grid-form" || stage?.value === "only-form") && (
          <>
            <aside className="p-1 m-1 border border-black rounded-md">
              <div className="flex justify-between">
                <p>Station</p>
                <div>
                  <RefreshButtonTemplate
                    handleRefresh={() => {
                      setRefreshKeys((state) => ({
                        ...state,
                        station: !state.station,
                      }));
                    }}
                  />
                </div>
              </div>
              <section className="px-2 dark:bg-slate-900 dark:text-darkModeSkin-base">
                <EquipmentDataTable
                  deselectFirstRow
                  dataId="stnIDpk"
                  disablePaging
                  gridheight={initialH - 310}
                  columns={elogbook_columns2}
                  data={stations}
                  onRowClick={(record: Stations) => { 
                    recordStations.current = record;
                    setRecordStations(record);
                  }}
                />
              </section>
            </aside>
            <aside className="p-1 m-1 border border-black rounded-md">
              <div className="flex justify-between">
                <p>Staff List</p>
                <RefreshButtonTemplate
                  handleRefresh={() => {
                    setRefreshKeys((state) => ({
                      ...state,
                      staffList: !state.staffList,
                    }));
                  }}
                />
              </div>
              {/* datagrid */}
              <section>
                <EquipmentDataTable
                  deselectFirstRow
                  dataId="empIDpk"
                  disablePaging
                  gridheight={initialH - 310}
                  columns={elogbook_columns3}
                  onRowClick={(e) => { 
                  }}
                  data={staffList}
                  rowDoubleClicked={UpdateStaffList}
                />
              </section>
            </aside>
            <aside className="p-1 m-1 border border-black rounded-md">
              <div className="flex justify-between">
                <p>Staff Shift Members</p>
                <RefreshButtonTemplate
                  handleRefresh={() => {
                    setRefreshKeys((state) => ({
                      ...state,
                      shiftMembers: !state.shiftMembers,
                    }));
                  }}
                />
              </div>
              {/* datagrid */}
              <section>
                <EquipmentDataTable
                  deselectFirstRow
                  dataId="pvaIDpk"
                  disablePaging
                  gridheight={initialH - 310}
                  columns={elogbook_columns4}
                  data={stationShiftMembers}
                  onRowClick={(record: StationShiftMembers) => {
                    recordShiftMembers.current = record;
                    setRecordShiftMembers(record);
                  }}
                  rowDoubleClicked={UpdateStationShiftMembers}
                />
                <div className="flex justify-end mt-2">
                  <CustomizableButton
                    customizableBtnImage={CancelBlack}
                    customizableBtnImageClassname={"w-4 h-4 mr-2 mt-1"}
                    classname={"flex p-1 border-4 border-black rounded-md"}
                    isText
                    useCallbackFunc
                    handleCustomizableClick={RevokeShiftMemberPriviledges}
                    text="Revoke Shift Member Privileges"
                  />
                  <Cancel
                    useCallbackFunc
                    isText
                    text="Revoke All Privileges"
                    cancel_button_toggler={RevokeAllShiftMemberPriviledges}
                  />
                </div>
              </section>
            </aside>
          </>
        )}
      </div>
      <div className="flex w-full m-2">
        {stage?.value === "only-grid" && (
          <EquipmentDataTable
            deselectFirstRow
            pageSize={pagination.size}
            pageNumber={pagination.number}
            handlePageNumber={(page) => {
              setPagination((state) => ({
                ...state,
                number: page,
              }));
            }}
            handlePageSize={(size) => {
              setPagination((state) => ({
                ...state,
                size: size,
              }));
            }}
            handleRefresh={() => {
              setRefreshKeys((state) => ({
                ...state,
                pvaRecord: !state.pvaRecord,
              }));
            }}
            dataId="pvaIDpk"
            gridheight={initialH - 420}
            columns={elogbook_columns5}
            data={gridAllShiftMembers}
          />
        )}
        {stage.value === "grid-form" && (
          <EquipmentDataTable
            pageSize={pagination.size}
            pageNumber={pagination.number}
            handlePageNumber={(page) => {
              setPagination((state) => ({
                ...state,
                number: page,
              }));
            }}
            handlePageSize={(size) => {
              setPagination((state) => ({
                ...state,
                size: size,
              }));
            }}
            dataId="pvaIDpk"
            gridheight={initialH - 299}
            columns={elogbook_columns5}
            data={gridAllShiftMembers}
          />
        )}
      </div>
    </>
  );
}

export default Update_Station_Shift_Members;

export interface StaffList {
  empIDpk: number;
  empStaffNo: string;
  empName: null | string;
  empMobNo: string;
  empIntercom: string;
  locShtName: string;
  jbtShtName: string;
  dptShtName: string;
  sxnShtName: string;
  empIsStaff: boolean;
}
export interface StationShiftMembers {
  pvaIDpk: number;
  pvaEmpIDfk: number;
  stnIDpk: number;
  pvaHome: boolean;
  pvaDefaultHome: boolean;
  pvaLog: boolean;
  pvaView: boolean;
  pvaShiftLeader: boolean;
  pvaTakeOverShift: boolean;
  pvaShiftMember: boolean;
  pvaInitiateRemoteShiftChange: boolean;
  pvaFlag: boolean;
  empStaffNo: string;
  empName: string;
  locShtName: string;
  jbtShtName: string;
  dptShtName: string;
  sxnShtName: string;
  stnName: string;
  stnMasterStation: boolean;
  stnNationalControlCenter: boolean;
  stnOperational: boolean;
  pvaActive: boolean;
  pvaCreationDate: Date;
  pvaEditedDate: Date | null;
  pvaRmks: string | null;
}
export type GridPvaRecordType = {
  pvaIDpk: number;
  pvaEmpIDfk: number;
  stnIDpk: number;
  pvaHome: boolean;
  pvaDefaultHome: boolean;
  pvaLog: boolean;
  pvaView: boolean;
  pvaShiftLeader: boolean;
  pvaTakeOverShift: boolean;
  pvaShiftMember: boolean;
  pvaInitiateRemoteShiftChange: boolean;
  pvaFlag: boolean;
  empStaffNo: string;
  empName: string;
  locShtName: string;
  jbtShtName: string;
  dptShtName: string;
  sxnShtName: string;
  stnName: string;
  stnMasterStation: boolean;
  stnNationalControlCenter: boolean;
  stnOperational: boolean;
  pvaActive: boolean;
  pvaCreationDate: string; // Consider using Date if you want to handle dates
  pvaEditedDate: string; // Consider using Date if you want to handle dates
  pvaRmks: string;
};
