/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Flex, Splitter, Typography } from "antd";
// import type { SelectProps } from 'antd';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";

import { useDispatch, useSelector } from "react-redux";

import { Progress } from "antd";
import dayjs from "dayjs";
import { RootState } from "../../../../../app/store";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../features/generalSlice";
import { task_footer_update } from "../../../../../features/Task&Todo/Task/TaskDetails";
import {
  useAccountInfo,
  useCurrentSession,
} from "../../../../../hooks/useAccountInfo";
import useFetch from "../../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../hooks/useResizeMode";
import { ModalTemplate } from "../../../../../templates/modal";
import { navbar_height } from "../../../../accessories/component_infos";
import CustomLoader from "../../../../accessories/custom-loader";
import { WarningAlert } from "../../../../accessories/warningAlert";
import {
  searchTextTypes,
  statusTypes,
  searchCriteriaTypes,
} from "../../../../human-resource/setups/data/types/selectsTypes";

import { MainTroubleWidget } from "./widgets/main_widget";
import { TroubleReportSecondWidget } from "./widgets/second_widget";
import { Trouble_reports_column } from "./data/troubleDataSample";
import { RightShare } from "./widgets/share";
import { TroubleReportsToolbar } from "../../../../accessories/custom-toolbars/trouble_report_toolbar";
import { Right_CommentTroubleReport } from "./widgets/comments";
import { Right_Trouble_Attachments } from "./widgets/attachments";
import { Summary, TotalItem } from "devextreme-react/cjs/data-grid";

import { DataTable as TroubleReportsTable } from "../../../../abstracts/data-table";
import { useQueryFetch } from "../../../../../functions/crud";
import { TroubleLocationForm } from "./widgets/location/trouble_location";
import { TRPLocations } from "./widgets/maintenance-locations/maintenanceLocations";
import { AssetDetails } from "./widgets/tr-asset-details/widgets";
import { useTroubleReportRepo } from "./repo";

interface props {
  state_change?: boolean;
}

interface stateTypes {
  selectedTask_update?: any;
  openSelectedTask?: any;
  selectedRecord?: any;
  materialSelectedRecord?: any;
  JobSelectedRecord?: any;
  jobUpdateSelectedRecord?: any;
  open?: boolean;
  refresh?: boolean;
  // DBdata?: any;
  activityUpdate?: string;
  inputModal?: any;
  cancellationReason?: string;
  confirmState?: any;
}

export const TroubleReports = ({ state_change }: props) => {
  const [employeeId, userId, userInfo] = useAccountInfo();
  const { setSelectedReport } = useTroubleReportRepo((state) => state);
  //Access Priviledge
  const [states, setStates] = useState<stateTypes>({
    selectedTask_update: [],
    openSelectedTask: [],
    selectedRecord: [],
    JobSelectedRecord: [],
    materialSelectedRecord: {},
    jobUpdateSelectedRecord: [],
    open: true,
    refresh: false,
    inputModal: {
      state: false,
      title: "",
      message: "",
      event: () => {},
    },
    confirmState: {
      state: false,
      title: "",
      message: "",
      event: () => {},
    },
    cancellationReason: "",
  });
  console.log("states", states);
  const [refreshstate, setRefreshstate] = useState<boolean>(false);
  const [refreshShare, setRefreshShare] = useState<boolean>(false);
  const { empIDpk: userID } = useCurrentSession();
  // Fetching Trouble reports statistics
  const { data: GetAllTroubleStats } = useQueryFetch<
    ResponseType<CountSummary>
  >({
    url: `TroubleReports/GetAllTroubleStats`,
    app: `powerapp`,
  });
  const { data: GetAllTroublePercentageStats } = useQueryFetch<
    ResponseType<AssignmentCounts>
  >({
    url: `TroubleReports/GetAllTroublePercentageStats`,
    app: `powerapp`,
  });
  const { data: GetLastYearTroubleStats } = useQueryFetch<
    ResponseType<{ countx: number }>
  >({
    url: `TroubleReports/GetLastYearTroubleStats`,
    app: `powerapp`,
  });
  const { data: GetThisYearTroubleStats } = useQueryFetch<
    ResponseType<{ countx: number }>
  >({
    url: `TroubleReports/GetThisYearTroubleStats`,
    app: `powerapp`,
  });
  const { data: GetLastMonthTroubleStats } = useQueryFetch<
    ResponseType<{ countx: number }>
  >({
    url: `TroubleReports/GetLastMonthTroubleStats`,
    app: `powerapp`,
  });
  const { data: GetThisMonthTroubleStats } = useQueryFetch<
    ResponseType<{ countx: number }>
  >({
    url: `TroubleReports/GetThisMonthTroubleStats`,
    app: `powerapp`,
  });
  const { data: LatestPercentComplete } = useQueryFetch<
    ResponseType<{ truPercentComplete: number }>
  >({
    url: `TroubleReports/LatestPercentComplete/${states?.selectedRecord?.trpIDpk}`,
    app: `powerapp`,
  });
  // Ends here

  // Manipulate progress bars

  const getOverallPercentageComplete = useCallback(() => {
    if (GetAllTroubleStats?.data?.[0]?.countCompleted > 0) {
      return Math.floor(
        (GetAllTroubleStats?.data?.[0]?.countCompleted /
          GetAllTroubleStats?.data?.[0]?.totalCountx) *
          100
      );
    }
    return 0;
  }, [GetAllTroubleStats]);
  const getOverallPercentageClosed = useCallback(() => {
    if (GetAllTroubleStats?.data?.[0]?.countClosed > 0) {
      return Math.floor(
        (GetAllTroubleStats?.data?.[0]?.countClosed /
          GetAllTroubleStats?.data?.[0]?.totalCountx) *
          100
      );
    }
    return 0;
  }, [GetAllTroubleStats]);

  const getUserPercentageComplete = useCallback(() => {
    if (GetAllTroublePercentageStats?.data?.[0]?.countAllMyAssignments > 0) {
      return Math.floor(
        (GetAllTroublePercentageStats?.data?.[0]
          ?.countAllMyAssignmentsCompleted /
          GetAllTroublePercentageStats?.data?.[0]?.countAllMyAssignments) *
          100
      );
    }
    return 0;
  }, [GetAllTroublePercentageStats]);
  const getUserPercentageClosed = useCallback(() => {
    if (GetAllTroublePercentageStats?.data?.[0]?.countAllMyAssignments > 0) {
      return Math.floor(
        (GetAllTroublePercentageStats?.data?.[0]?.countAllMyAssignmentsClosed /
          GetAllTroublePercentageStats?.data?.[0]?.countAllMyAssignments) *
          100
      );
    }
    return 0;
  }, [GetAllTroublePercentageStats]);

  // Ends here

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);
  const [splitterHeight, setSplitterHeight] = useState<string>(`30%`);
  const [AssetDetailFormOpen, setAssetDetailFormOpen] =
    useState<boolean>(false);
  const form_disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  //Resizin
  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 20);
  var fullPage = fullH - (navbar_height + news_flash_height + 40);

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    set_form_height(formRef.current?.clientHeight + 30);
  }, []);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  // Double tap /click gesture

  const [flipSwitcher, setFlipSwitcher] = useState<boolean>(true);

  const switchRoutes = [
    {
      id: 0,
      name: "current_page_route",
      path: "/assets-manager/transport/entries/Maintenance/maintenance-activities",
    },
    { id: 1, name: "job-grid-table", path: "job-grid-table" },
    { id: 2, name: "material-grid-table", path: "material-grid-table" },
    { id: 3, name: "form-display", path: "form-display" },
    { id: 4, name: "form2-display", path: "form2-display" },
  ];

  const view = searchParams.get("view");

  const doubleClick_toggle = () => {
    if (
      flipSwitcher === false ||
      flipSwitcher === null ||
      flipSwitcher === undefined
    ) {
      // update forms view
      setFlipSwitcher(!flipSwitcher);
      navigate({
        pathname: switchRoutes[0].path,
      });
    } else {
      navigate({
        pathname: switchRoutes[0].path,
        search: createSearchParams({
          view: switchRoutes[2].path,
        }).toString(),
      });
      setFlipSwitcher(!flipSwitcher);
    }
  };

  const genFormat = "YYYY-MM-DD HH:mm:ss";

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [isProgress, setIsProgrss] = useState<boolean>(true);
  const [isMultiLine, setIsMultiLine] = useState<boolean>(false);
  const [isInformation, setInformation] = useState<boolean>(true);
  const [searchCriteria, setsearchCriteria] = useState<searchCriteriaTypes>({
    temp: 1,
    index: 1,
  });

  const defaultStartDate = dayjs().subtract(2, "M").format(genFormat);
  const defaultEndDate = dayjs().format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: defaultStartDate,
    temp: defaultStartDate,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: defaultEndDate,
    temp: defaultEndDate,
  });

  const searchCriteriaData = [
    { id: 0, value: "Trouble" },
    { id: 1, value: "Location" },
    { id: 2, value: "Equipment Type" },
    { id: 3, value: "Trouble Type" },
    { id: 4, value: "Trouble Class" },
    // { id: 5, value: "Manufacturer" },
    // { id: 6, value: "Supplier" },
    // { id: 7, value: "Manufacture Date" },
    // { id: 8, value: "Supply Date" },
  ];

  // Onchange States

  // const [DBdata, dbError, dbLoading] = useFetch(
  //   "TroubleReports/GetAllTroubleReport?startDate=2010-01-01&endDate=2026-01-01",
  //   // `TroubleReports/GetAllTroubleReport?startdate=${startDate.text
  //   // }&enddate=${endDate.text}&active=${activeStatus.status}&search=${searchText.text
  //   // }&criteriaIndex=${searchCriteria.index}`,
  //   refreshstate,
  //   "trpIDpk", true, "powerapp"
  // );

  const [DBdata, dbError, dbLoading] = useFetch(
    "TroubleReports/GetAllTroubleReport",
    // `TroubleReports/GetAllTroubleReport?startdate=${startDate.text
    // }&enddate=${endDate.text}&active=${activeStatus.status}&search=${searchText.text
    // }&criteriaIndex=${searchCriteria.index}`,
    refreshstate,
    "trpIDpk",
    true,
    "powerapp",
    false,
    {
      startDate: startDate.text,
      endDate: endDate.text,

      ...(searchText.text !== "" && { search: searchText.text }),
      ...{ criteria: searchCriteria.index },
      ...(activeStatus.status !== "" && { active: activeStatus.status }),
    }
  );

  if (DBdata) {
    console.log("bdd", DBdata);
  }
  const [shareData, shareError, shareLoading] = useFetch(
    "TroubleReports/GetShareWith",

    refreshShare,
    "trpIDpk",
    true,
    "powerapp",
    false,
    { TrpIDpk: states?.selectedRecord?.trpIDpk }
  );

  // Fxn to set states by passing parameters
  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const maintenanceRequestModal = {
    message: "Are you sure you want to open Job Card for this request",
    title: "Record Selected?",
    okText: "Yes, open",
    cancelText: "No, Cancel",
  };

  const ProgressModal = {
    message:
      "This process might take some minutes to complete. Are you sure you want to proceed and load the data?",
    title: "Proceed?",
    okText: "Yes",
    cancelText: "No",
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(maintenanceRequestModal);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [Job_data, Job_error, Job_loading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityJobDetails?mtaIDpk=${states.selectedRecord?.mtaIDpk}`,
    refreshstate
  );
  // states.selectedRecord?.mtaIDpk
  const [matData, matError, matLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityMaterials?MtaIDpk=${states.selectedRecord?.mtaIDpk}`,
    refreshstate
  );

  const [Job_updatesData, Job_updatesError, Job_updatesLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActivityJobUpdate?MtaIDpk=${states.selectedRecord?.mtaIDpk}`,
    refreshstate
  );

  const cancelRequest = async () => {
    setState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
  };
  const validateCancel = () => {
    if (states.cancellationReason === "") {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message:
          "No Reason was entered for Cancelling the request. Please try again later and enter a reason",
        title: "Required",
        okText: "Ok",
        cancel: "No cancel",
      });
      return;
    }

    setModalData({
      icon: "question",
      state: true,
      title: "Cancel Request?",
      message: "Are you sure you want to continue and Cancel this request?",
      event: () => {
        cancelRequest();
      },
    });
  };

  // When Open button is clicked
  const open = () => {
    try {
      if (states.selectedTask_update?.length !== 0) {
        setIcon("question");
        setshowModalDisableButton(false);
        setModalData({
          message: "Are you sure you want to open Job Card for this request",
          title: "Record Selected? ",
          okText: "Yes, open",
        });
        setOpenModal(true);
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      } else {
        // modal here
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Grid Record Selection Error",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Record Selection!",
        okText: "Ok",
      });
    }
  };

  const validCancel = () => {
    if (states.selectedTask_update?.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Please select a Record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
    }
  };
  const openCancel = () => {
    try {
      if (states.selectedTask_update?.length === 0) {
        // modal here
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Grid Record Selection Error",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setState("inputModal", { state: true });
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Record Selection!",
        okText: "Ok",
      });
    }
  };

  const onChangeRecord = (e: any) => {
    setState("JobSelectedRecord", e?.data);
    if (setState !== undefined) {
      setState("JobSelectedRecord", e?.data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };
  const onChangeJobRecord = (e: any) => {
    setState("jobUpdateSelectedRecord", e);
    if (setState !== undefined) {
      setState("jobUpdateSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };

  // double click feature
  const allowUpdate = (e: any) => {
    setState("JobSelectedRecord", e?.data);
    if (setState !== undefined) {
      setState("JobSelectedRecord", e?.data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };
  const allowJobUpdate = (e: any) => {
    setState("JobUpdateSelectedRecord", e);
    if (setState !== undefined) {
      setState("JobUpdateSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };
  const onChangeMatRecord = (e: any) => {
    setState("materialSelectedRecord", e);
    if (setState !== undefined) {
      setState("materialSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };

  // double click feature
  const allowMatUpdate = (e: any) => {
    setState("materialSelectedRecord", e);
    if (setState !== undefined) {
      setState("materialSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };

  const gridRef = useRef<any>(null);
  const childRef = useRef<any>();

  // ------------------------------------------------------------

  useEffect(() => {
    // DBdata_Fetch()
    set_form_height(formRef.current?.clientHeight + 25);
    dispatch(setGeneralValue({ expr: "isPrint", value: false }));

    if (isProgress) {
      set_form_height(formRef.current?.clientHeight + 80);
    }
  }, [refreshstate, isProgress]);

  //new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      doubleClick_toggle();
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      setState("jobUpdateSelectedRecord", {});
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege!",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  //handleUpdate
  const handleUpdate = () => {
    if (states.JobSelectedRecord?.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No record selected",
        title: "Invalid record",
        okText: "Ok",
      });
      setOpenModal(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
      doubleClick_toggle();
      //enable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      //enable Update Mode
      dispatch(settask_footer_update(true));
    }
  };
  //edit button
  const editAccessPriv = () => {
    if (savePriv === true) {
      handleUpdate();
    } else {
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege!",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const rightTabs: { id: number; label: string; content: any }[] = [
    {
      id: 0,
      label: "Share",
      content: (
        <RightShare
          selectedRecordData={states.selectedRecord}
          dataGridData={shareData}
          fullPage={fullPage}
          handleRefresh={() => {
            setRefreshstate(!refreshstate);
          }}
        />
      ),
    },
    {
      id: 1,
      label: "Comments",
      content: (
        <Right_CommentTroubleReport
          fullPage={fullPage}
          selectedRecord={states.selectedRecord}
          handleRefresh={() => {
            setRefreshstate(!refreshstate);
          }}
        />
      ),
    },
    {
      id: 2,
      label: "Attachments",
      content: (
        <Right_Trouble_Attachments
          fullPage={fullPage}
          selectedRecordData={states.selectedRecord}
          handleRefresh={() => {
            setRefreshstate(!refreshstate);
          }}
        />
      ),
    },
    // {
    //   id: 3,
    //   label: "Images",
    //   content: (<Right_Vehicle_Image fullPage={fullPage} />),
    // },
  ];

  const [validationModalState, setvalidationModalState] = useState<any>({
    state: false,
    title: "",
    message: "",
  });
  const setValidationModal = (state: string, value: string | boolean) => {
    setvalidationModalState((warningState: any) => ({
      ...warningState,
      [state]: value,
    }));
  };
  const reportsList = [95, 0, 4, 95, 67];
  const [getPdata, setGetPdata] = useState<boolean>(false);
  const [getTotAvg, getTotAvgError, getTotLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalAverage`
  );
  const [getTotAvgM, getTotAvgMError, getTotMLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalAverageMonth`
  );
  const [getTotAvgY, getTotAvgYError, getTotYLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalAverageYear`
  );
  const [getTotReqP, getTotReqPError, getTotReqPLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalRequestPending`
  );
  const [getTotReqD, getTotReqDError, getTotReqDLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalRequestDone`
  );
  const [getTotReq, getTotReqError, getTotReqLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalRequest`
  );
  const [getTotJobD, getTotJobDError, getTotJobDLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalCompletedJobs`
  );
  const [getTotJob, getTotJobError, getTotJobLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalNumberOfJobs`
  );

  const totalAvg =
    getTotAvg[0]?.mtaPercentComplete / getTotAvg[0]?.activityCount;
  const totAvgM =
    getTotAvgM[0]?.mtaPercentComplete / getTotAvgM[0]?.activityCount;
  const totAvgY =
    getTotAvgY[0]?.mtaPercentComplete / getTotAvgY[0]?.activityCount;

  const [qtyType, setQtyType] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("sum");
  const [qtyAuth, setQtyAuth] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("avg");
  const [qtyAuth1, setQtyAuth1] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("avg");
  const [amountType, setAmountType] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("sum");

  const progressBar = [
    {
      id: 1,
      label: "",
      value: getPdata ? totalAvg : 0,
      desc: "Total Average % Complete",
      lel: true,
      bg: "orange-200",
    },
    {
      id: 2,
      label: "",
      value: getPdata ? totAvgM : 0,
      desc: `${"Average % (" + dayjs().format("YYYY") + ")"}`,
      lel: true,
    },
    {
      id: 3,
      label: "",
      value: getPdata ? totAvgY : 0,
      desc: `${"Average % (" + dayjs().format("MMM") + ")"}`,
      lel: true,
    },
  ];

  const [showTroubleLocation, setShowTroubleLocation] = useState(false);

  return (
    <>
      {Job_error && <WarningAlert />}
      {Job_loading && <CustomLoader text="Fetching Data" />}
      <ModalTemplate
        disableCancel={true}
        open={validationModalState.state}
        okHandler={() => setValidationModal("state", false)}
        cancelHandler={() => setValidationModal("state", false)}
        message={validationModalState.message}
        okText={"Ok"}
        title={validationModalState.title}
      />
      <ModalTemplate
        disableCancel
        width={"45%"}
        customComponent={
          //  <TroubleLocationForm />
          <TRPLocations />
        }
        title={"Trouble Rpts - Trouble Locations"}
        message={""}
        cancelHandler={() => setShowTroubleLocation(false)}
        open={showTroubleLocation}
        okHandler={() => {}}
      />
      <AssetDetails
        open={AssetDetailFormOpen}
        setOpen={setAssetDetailFormOpen}
      />

      <main className="flex flex-col h-full w-full">
        <header className="flex h-[5%] ">
          <TroubleReportsToolbar
            toggleLocation={() => {
              setShowTroubleLocation(true);
            }}
            toggler={switch_toggle}
            setVisible={setSwitch_change}
            paState={states}
            pasetState={setState}
            controlAssetDetail={() => {
              setAssetDetailFormOpen(true);
            }}
            gridRefX={gridRef}
            searchTextOnchange={(searchValue) => {
              setSearchText({ temp: searchValue, text: searchText.text });
            }}
            checkOnChange={(check) => {
              setActiveStatus({ temp: check, status: activeStatus.status });
            }}
            handleNew={() => {
              console.log("nonese");
              setSwitch_change(true);
              setState("selectedRecord", {});
              if (childRef.current) {
                childRef.current.topNavNew();
              }
            }}
            handleUpdate={() => {
              console.log("rece", states.selectedRecord);
              setSwitch_change(true);

              if (states.selectedRecord["trpIDpk"] === undefined) {
                setvalidationModalState({
                  state: true,
                  title: "Error",
                  message: "Please select a record",
                });
              }
            }}
            handleFind={() => {
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });
              setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
              setActiveStatus({
                temp: activeStatus.temp,
                status: activeStatus.temp,
              }); //active status
              setsearchCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setRefreshstate(!refreshstate);
            }}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearchCriteria({
                temp: newIndex,
                index: searchCriteria.index,
              });
            }}
            handleRefresh={() => {
              setSearchText({ temp: searchText.temp, text: "" });
              setStartDate({ text: defaultStartDate, temp: defaultStartDate });
              setEndDate({ text: defaultEndDate, temp: defaultEndDate });
              setTimeout(() => {
                setRefreshstate((state) => !state);
              }, 1000);
            }}
            startDate={startDate.temp}
            endDate={endDate.temp}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            handleToggle={(e) => {
              // setIsProgrss(e);
              setIsMultiLine(e);
            }}
            searchCriteriaData={searchCriteriaData}
            selectedRecord={states.selectedRecord}
          />
        </header>
        <section className="flex flex-col h-[85%] w-full">
          <Splitter className="flex w-full flex-1" layout="horizontal">
            <Splitter.Panel collapsible>
              <Splitter layout="vertical" className="flex flex-col">
                <Splitter.Panel collapsible>
                  {switch_change && (
                    <div ref={formRef}>
                      <Splitter layout="horizontal">
                        <Splitter.Panel
                          defaultSize={`50%`}
                          min={`50%`}
                          max={`100%`}
                          collapsible
                        >
                          <MainTroubleWidget
                            ref={childRef}
                            openedTask={states.openSelectedTask}
                            setrefreshx={() => {
                              setRefreshstate(!refreshstate);
                            }}
                            refreshx={refreshstate}
                            selectedRecord={states.selectedRecord}
                            startDate={startDate.text}
                            clearRecord={() => {
                              setState("selectedRecord", {});
                            }}
                            selectedTask_updatex={states.selectedTask_update[0]}
                          />
                        </Splitter.Panel>
                        <Splitter.Panel
                          defaultSize={`40%`}
                          min={`40%`}
                          max={`60%`}
                          collapsible
                        >
                          <TroubleReportSecondWidget
                            selectedRecord={states.selectedRecord}
                            selectrefreshx={() => {
                              setRefreshstate(!refreshstate);
                            }}
                          />{" "}
                        </Splitter.Panel>
                      </Splitter>
                    </div>
                  )}
                </Splitter.Panel>
                <Splitter.Panel min={`20%`} max={`100%`}>
                  <TroubleReportsTable
                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={Trouble_reports_column}
                    data={DBdata}
                    onRowClick={(data) => {
                      setSelectedReport(data);
                      dispatch(task_footer_update(false));
                      setState("selectedRecord", data);
                      setState("openSelectedTask", [data]);
                      setRefreshShare(!refreshShare);

                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: true })
                      );
                    }}
                    wordWrap={isMultiLine}
                    rowDoubleClicked={(data) => {
                      setState("selectedRecord", data);
                      setSelectedReport(data);
                      setSwitch_change(true);
                      if (data?.reportedByEmpID !== userID) {
                        setvalidationModalState({
                          state: true,
                          title: "Invalid Action",
                          message:
                            "You did not log this Trouble Report. You cannot Edit it.",
                        });
                        return;
                      }
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: false })
                      );
                      dispatch(task_footer_update(true));
                    }}
                    handlePageNumber={() => {}}
                    handlePageSize={() => {}}
                    // summary={
                    //   <Summary>
                    //     <TotalItem
                    //       cssClass={"two"}
                    //       column="REFILL INTVL(Hrs)"
                    //       summaryType={
                    //         qtyAuth as
                    //           | "sum"
                    //           | "avg"
                    //           | "max"
                    //           | "min"
                    //           | "count"
                    //           | "custom"
                    //       }
                    //       valueFormat=""
                    //     />
                    //     <TotalItem
                    //       cssClass={"three"}
                    //       column="RATING"
                    //       summaryType={
                    //         qtyAuth1 as
                    //           | "sum"
                    //           | "avg"
                    //           | "max"
                    //           | "min"
                    //           | "count"
                    //           | "custom"
                    //       }
                    //       valueFormat=""
                    //     />

                    //     <TotalItem
                    //       cssClass={"four"}
                    //       column="% COMPLETE"
                    //       summaryType={
                    //         qtyAuth as
                    //           | "sum"
                    //           | "avg"
                    //           | "max"
                    //           | "min"
                    //           | "count"
                    //           | "custom"
                    //       }
                    //       valueFormat="currency"
                    //     />
                    //   </Summary>
                    // }
                  />
                </Splitter.Panel>
              </Splitter>
            </Splitter.Panel>
            <Splitter.Panel
              collapsible
              min={`20%`}
              defaultSize={`20%`}
              max={`50%`}
            >
              <div
                style={{
                  borderColor: borderTheme,
                }}
              >
                <div style={{ height: fullPage + 5 }} className="w-full ">
                  <TabPanel
                    showNavButtons={true}
                    swipeEnabled
                    style={{ height: fullPage + 50 }}
                    className="w-full border-[1px] "
                  >
                    {rightTabs.map(({ id, label, content }) => {
                      return (
                        <Item key={id} title={label}>
                          {content}
                        </Item>
                      );
                    })}
                    <div className="text-xs text-indigo-500 px-1">Done</div>
                  </TabPanel>
                </div>
              </div>
            </Splitter.Panel>
          </Splitter>
          <footer className="flex w-full items-center pb-4 px-4">
            <p className="w-1/5 text-sm">Done</p>
            <div className="flex flex-1 space-x-1">
              <aside className="w-1/2 flex items-center space-x-3">
                <p className="text-sm">Reports Statistics:</p>
                <section className="flex flex-col flex-1">
                  <div className="grid grid-cols-5 w-full">
                    <span className="text-sm flex-1 bg-yellow-100 border p-1">
                      {" "}
                      {/*totalCountx*/}
                      {GetAllTroubleStats?.data?.[0]?.totalCountx ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-orange-100 border p-1">
                      {/*last year endpont*/}
                      {GetLastYearTroubleStats?.data?.[0]?.countx ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-yellow-100 border p-1">
                      {/*this year endpoint*/}
                      {GetThisYearTroubleStats?.data?.[0]?.countx ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-orange-100 border p-1">
                      {/*countActive*/}
                      {GetAllTroubleStats?.data?.[0]?.countActive ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-yellow-100 border p-1">
                      {/*countCompleted*/}
                      {GetAllTroubleStats?.data?.[0]?.countCompleted ?? `*`}
                    </span>
                  </div>
                  <div className="grid grid-cols-5 w-full">
                    <span className="text-sm flex-1 bg-orange-100 border p-1">
                      {/*countDeleted*/}
                      {GetAllTroubleStats?.data?.[0]?.countDeleted ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-yellow-100 border p-1">
                      {/*last month endpoint*/}
                      {GetLastMonthTroubleStats?.data?.[0]?.countx ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-orange-100 border p-1">
                      {/*this month endpoint*/}
                      {GetThisMonthTroubleStats?.data?.[0]?.countx ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-yellow-100 border p-1">
                      {/*countFixed*/}
                      {GetAllTroubleStats?.data?.[0]?.countFixed ?? `*`}
                    </span>
                    <span className="text-sm flex-1 bg-orange-100 border p-1">
                      {/*countClosed*/}
                      {GetAllTroubleStats?.data?.[0]?.countClosed ?? `*`}
                    </span>
                  </div>{" "}
                </section>
              </aside>
              <aside className="w-1/2 flex space-x-2">
                <section className="flex w-1/2 flex-col space-y-1 ">
                  <header className="flex space-x-2 items-center">
                    <Progress
                      type="line"
                      percentPosition={{ align: "center", type: "inner" }}
                      style={{ width: "100%", height: "100%", color: `red` }}
                      showInfo
                      // steps={{ count: 100, gap: 1 }}
                      strokeColor={`#f0abfc`}
                      className="flex-1"
                      percent={getOverallPercentageComplete()}
                      status="active"
                    />
                    <Progress
                      type="line"
                      percentPosition={{ align: "center", type: "inner" }}
                      style={{ width: "100%", height: "100%", color: `red` }}
                      showInfo
                      // steps={{ count: 100, gap: 1 }}
                      strokeColor={`#f0abfc`}
                      className="flex-1"
                      percent={getOverallPercentageClosed()}
                      status="active"
                    />
                  </header>
                  <p className="text-xs text-center flex-1 bg-orange-50">{`Overall Avrg % Complete/Closed`}</p>
                </section>
                <section className="flex w-1/2 flex-col space-y-1 ">
                  <header className="flex space-x-2 items-center">
                    <Progress
                      type="line"
                      percentPosition={{ align: "center", type: "inner" }}
                      style={{ width: "100%", height: "100%", color: `red` }}
                      showInfo
                      // steps={{ count: 100, gap: 1 }}
                      strokeColor={`#f0abfc`}
                      className="flex-1"
                      percent={getUserPercentageComplete()}
                      status="active"
                    />
                    <Progress
                      type="line"
                      percentPosition={{ align: "center", type: "inner" }}
                      style={{ width: "100%", height: "100%", color: `red` }}
                      showInfo
                      // steps={{ count: 100, gap: 1 }}
                      strokeColor={`#f0abfc`}
                      className="flex-1"
                      percent={getUserPercentageClosed()}
                      status="active"
                    />
                  </header>
                  <p className="text-xs text-center flex-1 bg-yellow-50">{`Overall Avrg % Complete/Closed`}</p>
                </section>
              </aside>
            </div>
          </footer>{" "}
        </section>
      </main>
    </>
  );
};

export interface ResponseType<TData> {
  message?: string;
  data: TData[];
  code: number;
}
export interface CountSummary {
  totalCountx: number;
  countActive: number;
  countDeleted: number;
  countFixed: number;
  countClosed: number;
  countCompleted: number;
}
type AssignmentCounts = {
  countAllMyAssignments: number;
  countAllMyAssignmentsClosed: number;
  countAllMyAssignmentsCompleted: number;
};
