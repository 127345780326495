/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-pascal-case */
import { Select, DatePicker, Form, Input, Checkbox, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useRef, useState } from "react";
import update from "../../../../../../../assets/save.png";

import dayjs from "dayjs";
import close from "../../../../../../../assets/close.png";
import correct from "../../../../../../../assets/correct.png";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  reqBy_cols,
  vehicleReg_cols,
  vehicleSimCard_data,
  workshop_cols,
} from "../data/maintenance-requests-data";
import useFetch from "../../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { stat } from "fs";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { fetchMaintenanceType } from "../../../../../../../features/apiCalls/maintenanceRequestApiSlice";
const { Option } = Select;

interface props {
  toggled?: boolean;
  dataGridData?: any;
  refreshGrid?: () => void;
  startDate?: string;
  endDate?: string;
  wordWrap?: boolean;
}

interface statesTypes {
  selectedRegNo?: any;
  selectedRequest?: any;
  selectedDriver?: any;
  selectedRecommendedBy?: any;
  selectedTypes?: any;
  selectedWorkshop?: any;
  requestTime?: any;
  jobNo?: any;
  defectReport?: any;
  rating?: number;
  remarks?: any;
  active?: any;
  satisfied?: boolean;
  satisfactionRemark?: any;
  deactivateFields?: boolean;
  showUpdateButton?: boolean;
  showCancelButton?: boolean;
  modalState?: any;
  confirmState?: any;
  successModal?: any;
  inputModal?: any;
  refresh: boolean;
  selectedItemid?: number;
  selectedMaintenanceActivityId: number;
  cancellationReason?: string;
  vehicleRefresh?: boolean;
  workShopRefresh?: boolean;
  driverRefresh?: boolean;
  recommendedByRefresh?: boolean;
  requestByRefresh?: boolean;
  mainTypeRefresh?: boolean;
  selectedRecord?: any;
  isOpenDropdown?: boolean;
  isLoading?: boolean;
}

const maintenanceRequestsDetails = ({
  toggled,
  dataGridData,
  refreshGrid,
  startDate,
  endDate,
  wordWrap,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const form_disable = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const mainReqClear = useSelector(
    (state: RootState) => state.general.mainReqClear
  );
  const [busyLoader, setBusyLoader] = useBusyLoader();
  //varying form height
  const form_height = 490;
  const selectedRecordx = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );
  //formCode
  const formCode = useSelector((state: RootState) => state.general.formCode);
  //Resizing
  const [initialH, fullH] = useResizeMode(!toggled);
  const [posting, updating] = useCrudFunc();

  //Table Height
  var tableHeight = initialH - (heights_out + 20); //height without navbar and toolbar

  const refs = useRef<any>(null);
  const refs2 = useRef<any>(null);
  const refs3 = useRef<any>(null);
  const refs4 = useRef<any>(null);
  const refs5 = useRef<any>(null);

  const dateFormat = " DD MMM YYYY HH: mm:ss";
  const desc = [
    "1.0",
    "2.0",
    "3.0",
    "4.0",
    "5.0",
    "6.0",
    "7.0",
    "8.0",
    "9.0",
    "10.0",
  ];

  const [states, setState] = useState<statesTypes>({
    selectedRegNo: { id: 0, name: "" },
    selectedRequest: { id: 0, name: "" },
    selectedDriver: { id: 0, name: "" },
    selectedRecommendedBy: { id: 0, name: "", mob: "" },
    selectedTypes: { id: 0, name: "" },
    selectedWorkshop: { id: 0, name: "" },
    requestTime: dayjs(),
    rating: 3,
    defectReport: "",
    remarks: "",
    jobNo: "",
    satisfactionRemark: "",
    active: true,
    satisfied: false,
    modalState: {
      state: false,
      title: "",
      message: "",
    },
    successModal: {
      state: false,
      title: "",
      message: "",
    },
    confirmState: {
      state: false,
      title: "",
      message: "",

      event: () => {},
    },
    inputModal: {
      state: false,
      title: "",
      message: "",
      event: () => {},
    },
    refresh: false,
    deactivateFields: true,
    showUpdateButton: false,
    showCancelButton: false,
    selectedItemid: 0,
    selectedMaintenanceActivityId: 0,
    cancellationReason: "",
    workShopRefresh: false,
    driverRefresh: false,
    recommendedByRefresh: false,
    requestByRefresh: false,
    selectedRecord: {},
    isOpenDropdown: false,
    isLoading: false,
  });

  const updateState = (key: string, value: any) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  // Current logged in user
  const [empId, userId, userInfo] = useAccountInfo();

  // const [startdate, setStartDate] = useState<any>(dayjs('2022-01-01'));
  // const [enddate, setEndDate] = useState<any>(dayjs('2023-01-01'));

  // Drop downs
  const [regNo] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos",
    states.vehicleRefresh
  );
  const [requests] = useFetch(
    `AsmTmsVehicleMaintenanceRequest/GetRequestBy?empId=${empId}`,
    states.requestByRefresh
  );
  const [drivers] = useFetch(
    "AsmTmsVehicleMaintenanceRequest/GetDrivers",
    states.driverRefresh
  );
  const [recommended] = useFetch(
    "AsmTmsVehicleMaintenanceRequest/GetRecommendedBy",
    states.recommendedByRefresh
  );
  const [types] = useFetch(
    "AsmTmsVehicleMaintenanceRequest/GetMaintenanceTypes",
    states.mainTypeRefresh
  );
  const [workshop] = useFetch(
    "AsmTmsVehicleMaintenanceRequest/GetWorkshops",
    states.workShopRefresh
  );

  const [lookups] = useFetch(
    `AsmTmsVehicleMaintenanceRequest/MaintenanceRequestLookUp?PageNumber=1&PageSize=20`
  );

  const main_types = useSelector(
    (state: RootState) => state.mainType.maintenanceType
  );

  let mobileNumbers: any[] = [];
  let Fname: any[] = [];

  if (Array.isArray(recommended)) {
    recommended.map((item) => {
      let name = item.empName; //fetch name from endPoint
      let mobileNumber = item.empMobNox; //fetch mobile from endPoint

      mobileNumbers.push(mobileNumber);
      Fname.push(name);
    });
  }

  useEffect(() => {}, [toggled]);

  useEffect(() => {
    if (mainReqClear === true) {
      resetFields();
    }
  }, [mainReqClear]);

  const validate = (method: string) => {
    if (states.selectedRegNo.id === 0 || states.selectedRegNo === true) {
      //refresh states sets the states to true but default refresh states is false
      updateState("modalState", {
        state: true,
        message: "Please select Vehicle Reg.No",
        title: "Required field",
      });
      return;
    }

    if (states.selectedRequest.id === 0 || states.selectedRequest === true) {
      //
      updateState("modalState", {
        state: true,
        message: "Please select Request By",
        title: "Required field",
      });
      return;
    }

    if (states.selectedDriver.id === 0 || states.selectedDriver === true) {
      //
      updateState("modalState", {
        state: true,
        message: "Please select driver",
        title: "Required field",
      });
      return;
    }

    if (
      states.selectedRecommendedBy.id === 0 ||
      states.selectedRecommendedBy === true
    ) {
      //
      updateState("modalState", {
        state: true,
        message: "Please select Recommended By",
        title: "Required field",
      });
      return;
    }

    if (states.selectedTypes.id === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select Maintenance Type",
        title: "Required field",
      });
      return;
    }

    if (states.selectedWorkshop.id === 0) {
      updateState("modalState", {
        state: true,
        message:
          "Please select Workshop where the vehicle is going for maintenance",
        title: "Required field",
      });
      return;
    }

    if (states.defectReport === "") {
      updateState("modalState", {
        state: true,
        message: "Please select Defect Report",
        title: "Required field",
      });
      return;
    }

    if (method === "put") {
      if (states.selectedItemid === 0) {
        updateState("modalState", {
          state: true,
          message: "Please select a record to updated",
          title: "Required field",
        });
        return;
      }
    }

    if (method === "post") {
      updateState("confirmState", {
        state: true,
        title: "Confirm",
        message: "Are you sure you want to submit this Record?",
        event: () => {
          postData();
          updateState("confirmState", { state: false });
        },
      });
    } else {
      updateState("confirmState", {
        state: true,
        title: "Confirm",
        message: "Are you sure you want to update Record?",
        okText: "Update",
        event: () => {
          updateData();
          updateState("confirmState", { state: false });
        },
      });
    }
  };
  const checkConnection = () => {
    if (!navigator.onLine) {
      updateState("modalState", {
        state: true,
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again",
        title: "No Internet Connection!",
      });
    }
  };

  const rowClicked = (data: any) => {
    updateState("remarks", data?.mrqRmks);
    updateState("rating", data?.mrqRating);
    updateState("defectReport", data?.mrqDefectRequest);
    updateState("satisfactionRemark", data?.mrqReviewReport);
    updateState("satisfied", data?.mtaDone);
    updateState("jobNo", data?.mtaJobNo);
    updateState("requestTime", data?.mrqDate);
    updateState("selectedItemid", data?.mrqIDpk);
    updateState("selectedMaintenanceActivityId", data?.mtaIDpk);
    updateState("selectedRegNo", { id: data?.vdtIDpk, name: data?.vdtRegNo });
    updateState("selectedRequest", {
      id: data?.requestedByEmpID,
      name: data?.requestedByEmpName,
    });
    updateState("selectedDriver", {
      id: data?.driverEmpID,
      name: data?.driverEmpName,
    });
    updateState("selectedRecommendedBy", {
      id: data?.recommendedByEmpID,
      name: data?.recommendedByEmpName,
    });
    updateState("selectedTypes", { id: data?.mtpIDpk, name: data?.mtpName });
    updateState("selectedWorkshop", { id: data?.wsdIDpk, name: data?.wsdName });
  };

  const resetFields = () => {
    dispatch(setGeneralValue({ expr: "mainReqClear", value: true }));
    updateState("selectedRegNo", { id: 0, name: "" });
    updateState("selectedDriver", { id: userId, name: userInfo.empName });
    updateState("selectedRecommendedBy", {
      id: userId,
      name: userInfo.empName,
    });
    updateState("selectedRequest", { id: userId, name: userInfo.empName });
    updateState("selectedTypes", { id: 0, name: "" });
    updateState("selectedWorkshop", { id: 0, name: "" });
    updateState("jobNo", "");
    updateState("defectReport", "");
    updateState("satisfactionRemark", "");
    updateState("remarks", "");
    updateState("requestTime", dayjs());
    updateState("selectedItemid", 0);
    updateState("satisfied", false);
  };

  const postData = async () => {
    // updateState("confirmState", {
    //   state: false,
    //   title: "",
    //   message: "",
    //   event: () => {},
    // });
    try {
      setBusyLoader("Saving Record.....");

      const ress = await posting(
        `AsmTmsVehicleMaintenanceRequest/CreateEntVehicleMaintenanceRequest`,
        {
          mrqDate: states?.requestTime,
          mrqVehicleDetailIDfk: states?.selectedRegNo.id, //
          mrqRequestedByEmpIDfk: states?.selectedRequest.id,
          mrqDriverEmpIDfk: states?.selectedDriver.id,
          mrqRecommendedByEmpIDfk: states?.selectedRecommendedBy.id,
          mrqWorkshopIDfk: states?.selectedWorkshop.id,
          mrqDefectRequest: states?.defectReport,
          mrqMaintenanceTypeIDfk: states?.selectedTypes.id,
          mrqPm:
            states?.selectedTypes.name === "Preventive Maintenance"
              ? true
              : false, // Logic From VB
          mrqRmks: states?.remarks,
          mrqCreatedBy: empId,
        },
        `Created Trans-Maintenance Request with Vehicle Reg No- ${states.selectedRegNo.name} for ${userInfo.empFirstName} ${userInfo.empStaffno} to be Recommended by ${states.selectedRecommendedBy.name} with ${states.selectedTypes.name} Maintenance Type at the ${states.selectedWorkshop.name} Workshop on ${states.requestTime} successfully `
      );
      let id = ress.data.id;

      let messg = `Hello ${states.selectedRecommendedBy.name}, a Vehicle Maintenance Request from ${userInfo.empFirstName} ${userInfo.empLastName} (${userInfo.empStaffno})  has been submitted for your recommendation. Thanks, sent from InnoX`;

      checkConnection();
      setBusyLoader("");
      setBusyLoader(" Sending SMS....");
      await posting(
        `SMSAlert/SaveSMS`,
        {
          imsMessage: messg,
          imsRecipientEmpIdfk: states.selectedRecommendedBy.id,
          imsSender: userInfo.empName,
          imsRecordIDfk: id,
          imsMessageType: "string",
          imsMessageClass: "string",
          imsInitiatorEmpIDfk: userId,
          imsCreatedBy: userId,
        },
        `SMS sent to ${states.selectedRecommendedBy.name} for recommendation of Vehicle Maintenance Request from ${userInfo.empFirstName} ${userInfo.empLastName} (${userInfo.empStaffno})`
      );
      updateState("successModal", {
        state: true,
        title: "Success",
        message: "Record saved successfully",
      });
      refreshGrid!();
      updateState("refresh", !states.refresh);
      resetFields();
      setBusyLoader("");
    } catch (error: any) {
      console.log("errorr", error);
      updateState("modalState", {
        state: true,
        title: "Required field",
        message:
          "Failed to Save Record.. Please contact your Systems Admninistrator for assistance",
      });
      setBusyLoader("");
    }
  };
  //     .then((response) => {
  //       //
  //       updateState("successModal", {
  //         state: true,
  //         title: "Success",
  //         message: "Record saved successfully",
  //       });
  //       refreshGrid!();
  //       updateState("refresh", !states.refresh);
  //       resetFields();
  //       setBusyLoader("");
  //     })
  //     .catch((err) => {
  //       console.log("errorr",err)
  //       updateState("modalState", {
  //         state: true,
  //         title: "Required field",
  //         message:
  //           "Failed to Save Record.. Please contact your Systems Admninistrator for assistance",
  //       });
  //       setBusyLoader("");
  //     });
  // };

  const updateData = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
    setBusyLoader("Updating Record.....");

    await updating(
      `AsmTmsVehicleMaintenanceRequest/UpdateEntVehicleMaintenanceRequest`,
      {
        mrqIDpk: states?.selectedItemid,
        mrqDate: states?.requestTime,
        mrqVehicleDetailIDfk: states?.selectedRegNo.id,
        mrqRequestedByEmpIDfk: states?.selectedRequest.id,
        mrqDriverEmpIDfk: states?.selectedDriver.id,
        mrqRecommendedByEmpIDfk: states?.selectedRecommendedBy.id,
        mrqWorkshopIDfk: states?.selectedWorkshop.id,
        mrqDefectRequest: states?.defectReport,
        mrqMaintenanceTypeIDfk: states?.selectedTypes.id,
        mrqPm:
          states?.selectedTypes.name === "Preventive Maintenance"
            ? true
            : false, // From VB
        mrqRmks: states?.remarks,
        mrqCreatedBy: empId,
        mrqEditedBy: empId,
        mrqEditedDate: dayjs().toDate(),
      },
      `"Edited the Asset Manager-Trans-Maintenance Request with Vehicle Reg No- ${states.selectedRegNo} for ${userInfo.empFirstName} ${userInfo.empStaffno} to be Recommended by ${states.selectedRecommendedBy.name} with ${states.selectedTypes.name} Maintenance Type at the ${states.selectedWorkshop.name} Workshop on ${states.requestTime} successfully`
    )
      .then((response) => {
        //
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Record Updated successfully",
        });
        refreshGrid!();
        updateState("refresh", !states.refresh);
        resetFields();
        dispatch(task_form_disable(true));
        // dispatch(update_route_state(false))
        dispatch(task_footer_update(false));
        setBusyLoader("");
      })
      .catch((err) => {
        //
        updateState("modalState", {
          state: true,
          title: "Required field",
          message:
            "Failed to Update Record.. Please contact your Systems Admninistrator for assistance",
        });
        setBusyLoader("");
      });
  };
  console.log("states", states);
  // validates the request to recommend
  const validateRequestToRecommend = () => {
    // check if item has been selected
    if (states.selectedRecord === undefined) {
      updateState("modalState", {
        state: true,
        message: "Please select a Maintenance Request to Recommend",
        title: "Required field",
      });
      return;
    } else if (states.selectedRecord.mrqRecommended === true) {
      updateState("modalState", {
        state: true,
        message: "Please this request has already been recommended",
        title: "Access Denied!",
      });
    } else if (states.selectedRecord.mrqCancelled === true) {
      updateState("modalState", {
        state: true,
        message:
          "Please this request has been cancelled and cannot be recommended",
        title: "Access Denied!",
      });
    } else {
      updateState("confirmState", {
        state: true,
        title: "Confirm",
        message: "Are you sure you want to Recommend the selected request?",
        event: () => {
          recommmedRequest();
        },
      });
    }
  };

  const validateCancel = () => {
    if (states.cancellationReason === "") {
      updateState("modalState", {
        state: true,
        message:
          "No Reason was entered for Cancelling the request. Please try again later and enter a reason",
        title: "Required",
      });
      return;
    }

    updateState("confirmState", {
      state: true,
      title: "Cancel Request?",
      message: "Are you sure you want to continue and Cancel this request?",
      event: () => {
        cancelRequest();
        updateState("cancellationReason", "");
      },
    });
  };

  const validateRating = () => {
    if (states.selectedItemid === 0) {
      updateState("modalState", {
        state: true,
        message: "Please select a Maintenance Request to Rate",
        title: "Required",
      });
      return;
    }

    if (
      states.selectedMaintenanceActivityId === 0 ||
      states.selectedMaintenanceActivityId === null
    ) {
      updateState("modalState", {
        state: true,
        message: "Maintenance Request is not prepared for Rating",
        title: "Required",
      });
      return;
    }

    if (states.satisfactionRemark === "") {
      updateState("modalState", {
        state: true,
        message: "Please enter a Satisfactory remark",
        title: "Required",
      });
      return;
    }

    if (states.satisfied === null || states.satisfied === undefined) {
      updateState("modalState", {
        state: true,
        message:
          "You did not state whether you are satisfied or not. Please check or uncheck the satisfied box and try again.",
        title: "Required",
      });
      return;
    }

    updateState("confirmState", {
      state: true,
      title: "Save Remarks",
      message: "Are you sure you want to update your remarks?",
      event: () => {
        rateRequest();
      },
    });
  };

  //recommender message
  let RecMessage = `Hello ${states.selectedRecord?.recommendedByEmpName}, a new maintenance request from ${states.selectedRecord?.requestedByEmpName12} (${states.selectedRecord?.requestedByStaffNo}) for Vehicle with Reg No ${states.selectedRecord?.vdtRegNo} has been submitted. Thanks, sent from InnoX`;

  const recommmedRequest = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
    checkConnection();
    setBusyLoader("Recommending Record.....");
    await updating(
      `AsmTmsVehicleMaintenanceRequest/RecommendEntVehicleMaintenanceRequest`,
      {
        mrqIdpk: states.selectedItemid,
        empId: empId,
        mrqRecommendedByEmpIdfk: states.selectedRecommendedBy.id,
      },
      `Recommended the Asset Manager-Trans-Maintenance Request for vehicle with Reg No- ${states.selectedRecord?.vdtRegNo} from ${states.selectedRecord?.requestedByEmpName12}, ${states.selectedRecord?.mrqIDpk}successfully`
    );
    await posting(
      `SMSAlert/SaveSMS`,
      {
        imsMessage: RecMessage,
        imsRecipientEmpIdfk: states.selectedRecord?.requestedByEmpID,
        imsSender: userInfo.empName,
        imsRecordIDfk: states.selectedRecord?.mrqIDpk,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsInitiatorEmpIDfk: userId,
        imsCreatedBy: userId,
      },
      ``
    )
      // await UpDateDataFunc(``)
      .then((response) => {
        //
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Record recommended successfully.",
        });
        refreshGrid!();
        updateState("refresh", !states.refresh);
        resetFields();
        setBusyLoader("");
      })
      .catch((err) => {
        //
        // catch validation errors
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Recommend Failed",
            message: `Failed to recommend request. Please contact your Systems Admninistrator for assistance `,
          });
          setBusyLoader("");
        } else {
          // throw server error
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occured whilst executing the action. Please contact your Systems Admninistrator for assistance",
          });
          setBusyLoader("");
        }
      });
  };

  const cancelRequest = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
    setBusyLoader("Cancelling Record....");

    await updating(
      `AsmTmsVehicleMaintenanceRequest/CancelEntVehicleMaintenanceRequest`,
      {
        mrqIdpk: states.selectedRecord?.mrqIDpk,
        mrqReviewReport: states.cancellationReason,
        mrqCancelledByEmpIdfk: empId,
      },
      `Cancelled Asset Manager-Trans-Maintenance Request for record with ID - ${states.selectedRecord?.mrqIDpk} successfully`
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request Cancelled successfully",
        });
        refreshGrid!();
        updateState("refresh", !states.refresh);
        resetFields();
        setBusyLoader("");
        updateState("cancellationReason", "");
      })
      .catch((err) => {
        // catch validation errors
        if (err.response) {
          updateState("modalState", {
            state: true,
            title: "Invalid Action",
            message: `${err.response.data.errors[0]}`,
          });
        } else {
          // throw server error
          updateState("modalState", {
            state: true,
            title: "Required field",
            message:
              "An error occured whilst executing the action. Please contact your Systems Admninistrator for assistance",
          });
          setBusyLoader("");
          updateState("cancellationReason", "");
        }
      });
  };

  const rateRequest = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
    setBusyLoader("Rating Maintenance Request....");

    await updating(
      `AsmTmsVehicleMaintenanceRequest/RateEntVehicleMaintenanceRequest`,
      {
        mrqIdpk: states.selectedRecord?.mrqIDpk,
        mrqReviewReport: states.satisfactionRemark,
        mrqEditedBy: empId,
        mrqEditedDate: dayjs().toDate(),
        mrqRating: states.rating,
        mtaIdpk: states.selectedMaintenanceActivityId,
        mtaDone: states.satisfied,
      },
      ``
    )
      .then((response) => {
        updateState("successModal", {
          state: true,
          title: "Success",
          message: "Request Rated successfully",
        });
        refreshGrid!();
        updateState("refresh", !states.refresh);
        resetFields();
        setBusyLoader("");
      })
      .catch((err) => {
        updateState("modalState", {
          state: true,
          title: "Required field",
          message:
            "An error occured whilst executing the action. Please contact your Systems Admninistrator for assistance",
        });
        setBusyLoader("");
      });
  };

  //restotre cancelled
  const restoreCancelled = async () => {
    try {
      setBusyLoader("Restoring Request...");
      await updating(
        `AsmTmsVehicleMaintenanceRequest/RestoreCanceledMaintenanceRequest`,
        {
          mrqIdpk: states.selectedRecord?.mrqIDpk,
          mrqReviewReport: states.selectedRecord?.mrqReviewReport,
        },
        `Restored Asset Manager-Trans-Maintenance Request for record with ID ${states.selectedRecord?.mrqIDpk} successfully`
      );
      updateState("successModal", {
        state: true,
        title: "Success",
        message: "Request Restored successfully",
      });
      refreshGrid!();
      updateState("refresh", !states.refresh);
      resetFields();
      setBusyLoader("");
    } catch (err: any) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message:
          "Failed to Restored cancelled Request. Please try again or contact your Systems Admninistrator for assistance",
      });
      setBusyLoader("");
    }
  };
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const dispatch = useDispatch();

  const [privType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  //new button priv
  const newAccessPriv = () => {
    if (savePriv === true) {
      dispatch(task_form_disable(false));
      updateState("deactivateFields", false);
      updateState("showCancelButton", true);
      resetFields();
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  //save button
  const saveAccessPriv = () => {
    if (savePriv === true) {
      validate("post");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  //sideUpdate button
  const sideUpdateAccessPriv = () => {
    if (updatePriv === true) {
      validateRating();
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  // update button
  const updateAccessPriv = () => {
    if (updatePriv === true) {
      validate("put");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  //recommended button
  const recAccessPriv = () => {
    if (updatePriv === true) {
      validateRequestToRecommend();
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  //validate cancel
  const validateCancelBtn = () => {
    if (states.selectedRecord?.mrqCancelled === true) {
      updateState("confirmState", {
        state: true,
        message:
          "This request has already been cancelled..Do you want to restore the request?",
        title: "Restore Request?",
        event: () => {
          restoreCancelled();
          updateState("confirmState", { state: false });
        },
      });
    } else if (states.selectedRecord?.mtaOpened === true) {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message:
          "This request has been Opened.Such requests can be cancelled only by Workshop Service Personnel on the Maintenance Activities form",
      });
    } else if (
      !states.selectedRecord?.mtaOpened &&
      states.selectedRecord?.requestedByEmpID !== userInfo.empIDpk
    ) {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message:
          "You did not create this service request and so cannot cancel it",
      });
    } else {
      updateState("inputModal", { state: true });
    }
  };

  //cancel button
  const cancelAccessPriv = () => {
    if (savePriv === true) {
      if (states.selectedRecord === undefined) {
        updateState("modalState", {
          state: true,
          message: "Please select a Maintenance Request to Cancel",
          title: "Required",
        });
      } else {
        validateCancelBtn();
      }
    } else {
      // modal
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const rowColoring = (e: any) => {
    if (e.rowType === "data" && e.data.mrqCancelled === true) {
      return (e.rowElement.style.backgroundColor = "#FF7F7F");
    }
  };

  const AccessPriv_On_DoubleClick = (record: any) => {
    if (updatePriv === true) {
      if (record?.mrqRequestedByEmpIDfk !== empId) {
        updateState("modalState", {
          state: true,
          title: "Access Denied!",
          message: "You did not submit this request and so cannot edit it",
        });
      } else if (record?.mrqAcknowledged === true) {
        updateState("modalState", {
          state: true,
          title: "Access Denied!",
          message: "This request has been acknowledged and so cannot be edited",
        });
      } else if (record?.mtaOpened === true) {
        updateState("modalState", {
          state: true,
          title: "Access Denied!",
          message:
            "A Job Card has been opened for this request.It cannot be edited.",
        });
      } else {
        updateState("deactivateFields", false);
        updateState("showUpdateButton", true);
        updateState("showCancelButton", true);
        dispatch(task_form_disable(false));
        //enable Update Mode
        dispatch(task_footer_update(true));
      }
    } else {
      updateState("deactivateFields", true);
      updateState("showUpdateButton", false);
      updateState("showCancelButton", false);
      dispatch(task_form_disable(true));
      //enable Update Mode
      dispatch(task_footer_update(false));
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const singleEndPoint: any = fetchMaintenanceType;

  const selects: any[] = [
    {
      id: 0,
      label: "Vehicle Reg. No",
      idExpr: "vdtIdpk",
      dataExpr: "vdtRegNo",
      optionsData: regNo,
      stateName: "selectedRegNo",
      defaultValue: states.selectedRegNo.name,
      style: "",
      ref: refs,
      refresh: states.vehicleRefresh,
    },
    {
      id: 1,
      label: "Request By",
      idExpr: "empIDpk",
      dataExpr: "empName",
      optionsData: requests,
      stateName: "selectedRequest",
      defaultValue: states.selectedRequest.name,
      style: "",
      ref: refs2,
      refresh: states.requestByRefresh,
    },
    {
      id: 2,
      label: "Reporting Driver",
      idExpr: "empIDpk",
      dataExpr: "empName",
      optionsData: drivers,
      stateName: "selectedDriver",
      defaultValue: states.selectedDriver.name,
      style: "",
      ref: refs3,
      refresh: states.driverRefresh,
    },
    {
      id: 3,
      label: "Recommended By",
      idExpr: "empIDpk",
      dataExpr: "empName",
      optionsData: recommended,
      stateName: "selectedRecommendedBy",
      defaultValue: states.selectedRecommendedBy.name,
      style: "",
      ref: refs4,
      refresh: states.recommendedByRefresh,
    },
  ];

  const workshopSelect: any[] = [
    {
      id: 0,
      label: "Workshop",
      idExpr: "wsdIDpk",
      dataExpr: "wsdName",
      optionsData: workshop,
      stateName: "selectedWorkshop",
      defaultValue: states.selectedWorkshop.name,
      style: "",
      ref: refs5,
    },
  ];

  return (
    <>
      <ModalTemplate
        disableCancel={true}
        title={states.modalState.title}
        okText={""}
        message={states.modalState.message}
        open={states.modalState.state}
        okHandler={() => {
          updateState("modalState", { state: false });
        }}
        cancelHandler={() => {
          updateState("modalState", { state: false });
        }}
      />
      <ModalTemplate
        icon="success"
        disableCancel={true}
        title={states.successModal.title}
        okText={""}
        message={states.successModal.message}
        open={states.successModal.state}
        okHandler={() => {
          updateState("successModal", { state: false });
        }}
        cancelHandler={() => {
          updateState("successModal", { state: false });
        }}
      />

      <ModalTemplate
        icon="question"
        title={states.confirmState.title}
        okText={""}
        message={states.confirmState.message}
        open={states.confirmState.state}
        okHandler={states.confirmState.event}
        cancelHandler={() => {
          updateState("confirmState", { state: false });
        }}
      />
      <ModalTemplate
        message="Please enter the reason for Cancelling this request"
        icon="warning"
        title="Cancellation Reason"
        open={states.inputModal.state}
        okText={"OK"}
        cancelText={"Cancel"}
        okHandler={() => {
          updateState("inputModal", { state: false });
          updateState("cancellationReason", "");
          validateCancel();
        }}
        cancelHandler={() => {
          updateState("inputModal", { state: false });
          updateState("cancellationReason", "");
        }}
        withTextarea={true}
        textareaOnchange={(e: any) => {
          updateState("cancellationReason", e);
        }}
        textareaHeight={100}
        textareaDefaultValue={states.cancellationReason}
      />

      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            input[type="text"]:disabled {
               background: #fffbeb;
               height:22px
            }  

            
            
            .dx-datagrid-header-panel .dx-toolbar {
               margin-bottom: 1.5px;
         }

            .filterTodos .dx-datagrid-headers{
               margin-top: 10px;
            }

               `,
          }}
        />

        {toggled && (
          <div
            style={{ borderColor: borderTheme }}
            className="w-full border rounded-md"
          >
            <p
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="text-md font-medium border-b-1 bg-slate-100 rounded-t-md py-1 pl-2"
            >
              Maintenance Request Details
            </p>
            {/* The form */}
            <div className="py-2 flex w-[1500px] ">
              <Form
                name="complex-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className=""
                style={{ width: "25%" }}
              >
                {selects.map(
                  ({
                    id,
                    idExpr,
                    label,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                    style,
                    ref,
                    refresh,
                  }) => {
                    return (
                      <div className="w-full mb-0.5">
                        <DropdownGrid_template
                          label={label}
                          labelCol={8}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 205, overflow: "hidden" }}
                          gridData={optionsData}
                          columns={
                            label === "Vehicle Reg. No"
                              ? vehicleReg_cols
                              : label === "Request By"
                              ? reqBy_cols
                              : label === "Reporting Driver"
                              ? []
                              : []
                          }
                          defaultValue={defaultValue}
                          disabled={form_disable}
                          onRowClick={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });
                          }}
                          handleRefresh={() => {
                            updateState(stateName!, { id: 0, name: "" });
                            updateState(stateName!, !refresh);
                          }}
                        />
                      </div>
                    );
                  }
                )}

                <Form.Item
                  label={<p className="text-xs">{"Maintenance Type"}</p>}
                  style={{ marginBottom: 0.5 }}
                >
                  <Form.Item
                    name="receivedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(94%)" }}
                  >
                    <InputSelect_template
                      placeHolder={states.selectedTypes.name}
                      handleRefresh={() => {
                        updateState("selectedTypes", { name: "", id: 0 });
                      }}
                      selectStyle={{ marginRight: 1 }}
                      options={
                        states.selectedTypes?.length > 0
                          ? main_types
                          : lookups.maintenanceTypes
                      }
                      disabled={form_disable}
                      useCallFunc={true}
                      idexpr={"mtpIDpk"}
                      dataexp={"name"}
                      handleFocus={(e: any) => {
                        updateState("isOpenDropdown", !states.isOpenDropdown);
                      }}
                      selectedValue={(e: any) => {
                        updateState("isOpenDropdown", false);
                        updateState("selectedTypes", {
                          name: e.name,
                          id: e.mtpIDpk,
                        });
                      }}
                      isLoading={states.isLoading}
                      isOpenDropdown={states.isOpenDropdown}
                      handleSearch={useCallback(
                        debounce((e) => {
                          updateState("selectedTypes", e);
                          // updateState("isLoading", true);
                          dispatch(singleEndPoint(e));

                          updateState("isOpenDropdown", true);
                        }, 500), // Adjust the debounce delay as needed
                        []
                      )}
                    />
                  </Form.Item>
                </Form.Item>

                {workshopSelect.map(
                  ({
                    id,
                    idExpr,
                    label,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                    style,
                    ref,
                  }) => {
                    return (
                      <div className="w-full mb-0.5 ">
                        <DropdownGrid_template
                          label={label}
                          labelCol={8}
                          gridData={optionsData}
                          dropdownBtnStyles={{ width: 205, overflow: "hidden" }}
                          cusBtnStyle={true}
                          columns={workshop_cols}
                          defaultValue={defaultValue}
                          disabled={form_disable}
                          onRowClick={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });

                            // ref.current!.instance.close();
                          }}
                          handleRefresh={() => {
                            updateState("selectedWorkshop", {
                              name: "",
                              id: 0,
                            });
                            updateState(
                              "workShopRefresh",
                              !states.workShopRefresh
                            );
                          }}
                        />
                      </div>
                      // </Form.Item>
                    );
                  }
                )}

                <Form.Item
                  label={<p className="text-xs">{"Request Time"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <DatePicker
                    style={{
                      width: "83%",
                      display: "inline-block",
                      borderRadius: "4px",
                    }}
                    format={dateFormat}
                    allowClear={false}
                    defaultValue={
                      states.requestTime === undefined
                        ? dayjs()
                        : dayjs(`${states.requestTime}`)
                    }
                    key={
                      states.requestTime === undefined ? "" : states.requestTime
                    }
                    disabled={true}
                    onChange={(e) => {
                      updateState("requestTime", e);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Defect Report"}</p>}
                  style={{ marginBottom: 0, marginTop: "0.5%" }}
                >
                  <Form.Item
                    style={{ display: "inline-block", width: "calc(246%)" }}
                    className="  mr-2 "
                  >
                    <TextArea
                      style={{
                        resize: "none",
                        maxHeight: "67px",
                        minHeight: "67px",
                        height: "67px",
                        width: "",
                      }}
                      className="o  overflow-y-scroll resize-none"
                      rows={3}
                      value={states.defectReport}
                      readOnly={form_disable}
                      onChange={(e) => {
                        updateState("defectReport", e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={<p className="text-xs">{"Remarks"}</p>}
                  style={{ marginBottom: 0, marginTop: "1%" }}
                >
                  <Form.Item
                    style={{
                      display: "inline-block",
                      marginBottom: "1px",
                      width: "calc(530%)",
                    }}
                    className=" mb-1 mr-2 "
                  >
                    <TextArea
                      style={{
                        resize: "none",
                        maxHeight: "60px",
                        minHeight: "60px",
                        height: "60px",
                        width: "",
                      }}
                      className="o  overflow-y-scroll resize-none"
                      rows={3}
                      value={states.remarks}
                      readOnly={form_disable}
                      onChange={(e) => {
                        updateState("remarks", e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  colon={false}
                  style={{ display: "inline-block", marginBottom: "2px" }}
                  label={<p className="text-xs">{""}</p>}
                  className="w-full mb-1"
                >
                  <div className=" items-end">
                    <div
                      className=" flex  flex-row justify-between"
                      style={{ marginBottom: "1px", width: "calc(530%)" }}
                    >
                      <ul className="flex ">
                        <button
                          onClick={(e) => {
                            cancelAccessPriv();
                          }}
                          style={{ borderColor: borderTheme }}
                          className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                        >
                          <img className="pr-1" src={close} />
                          Cancel
                        </button>
                        {userInfo.empName ===
                          states?.selectedRecommendedBy.name &&
                          form_disable === true && (
                            <button
                              onClick={() => {
                                recAccessPriv();
                              }}
                              style={{ borderColor: borderTheme }}
                              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                            >
                              <img className="pr-1" src={correct} />
                              Recommend
                            </button>
                          )}
                      </ul>
                      <ul className="flex ">
                        {updateMode === true ? (
                          <UpdateButton
                            handleUpdate={() => {
                              updateAccessPriv();
                            }}
                          />
                        ) : (
                          <SaveButton
                            useCallbackFunc
                            disableButton={form_disable}
                            handleSave={() => {
                              saveAccessPriv();
                            }}
                          />
                        )}
                        {form_disable === false ? (
                          <Cancel
                            useCallbackFunc={true}
                            cancel_button_toggler={() => {
                              updateState("showUpdateButton", false);
                              updateState("showCancelButton", false);
                              dispatch(task_form_disable(true));
                              dispatch(task_footer_update(false));
                            }}
                          />
                        ) : (
                          <NewButton
                            useCallbackFunc={true}
                            new_button_toggler={() => {
                              newAccessPriv();
                            }}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </Form.Item>
              </Form>

              <Form
                name="complex-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="ml-2 "
                style={{ width: "25%" }}
              >
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>

                <div className="mt-[22px]">
                  {form_disable === true && (
                    <Form.Item
                      label={<p className="text-xs">{"Job No"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="issuedBy"
                        rules={[{ required: false }]}
                        style={{ display: "inline-block", width: "calc(93%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Input
                            style={{ marginBottom: 0 }}
                            className=""
                            value={states.jobNo}
                            disabled={true}
                            onChange={(e) => {
                              updateState("jobNo", e.target.value);
                            }}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>
                  )}
                </div>
              </Form>
              <Form
                name="complex-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className=" "
                style={{ width: "25%" }}
              >
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>

                <div className="mt-[22px]">
                  <div>
                    <Form.Item
                      label={
                        <p className="text-xs">{"Satisfaction Remarks"}</p>
                      }
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          marginBottom: "1px",
                          width: "calc(200%)",
                        }}
                        className=" mb-1 mr-2 "
                      >
                        <TextArea
                          style={{
                            resize: "none",
                            maxHeight: "70px",
                            minHeight: "70px",
                            height: "70px",
                            width: "",
                          }}
                          className="o  overflow-y-scroll resize-none"
                          rows={3}
                          value={states.satisfactionRemark}
                          readOnly={
                            states.selectedRecord?.mrqPercentComplete === 100
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            updateState("satisfactionRemark", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      style={{ display: "inline-block", marginBottom: "10px" }}
                      label={<p className="text-xs">{"Satisfied"}</p>}
                      className="w-full mb-1"
                    >
                      <div className=" items-end">
                        <div
                          className=" flex  flex-row justify-between"
                          style={{ marginBottom: "1px", width: "calc(200%)" }}
                        >
                          <div
                            style={{
                              borderWidth: "1px",
                              paddingLeft: 3,
                              paddingRight: 3,
                              borderColor: borderTheme,
                            }}
                            className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center h-[24px]"
                          >
                            <Checkbox
                              disabled={
                                states.selectedRecord?.mrqPercentComplete ===
                                100
                                  ? false
                                  : true
                              }
                              checked={states.satisfied}
                              defaultChecked={states.satisfied}
                              onChange={(e) => {
                                updateState("satisfied", e.target.checked);
                              }}
                            />
                          </div>
                          <div className="text-xs">
                            Rating:
                            <span
                              style={{ borderColor: borderTheme }}
                              className="border ml-2 p-1"
                            >
                              <Rate
                                tooltips={desc}
                                value={states.rating}
                                onChange={(e: number) => {
                                  updateState("rating", e);
                                }}
                                count={10}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </Form>

              <Form
                name="complex-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="ml-2 "
                style={{ width: "25%" }}
              >
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="invisible">
                  <Form.Item
                    label={<p className="text-xs">{"Chainage"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="receivedBy"
                      rules={[{ required: false }]}
                      style={{ display: "inline-block", width: "calc(100%)" }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "95%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </div>

                <div
                  className="mt-[22px] border flex-col justify-end items-end content-between  "
                  style={{
                    borderColor: borderTheme,
                    marginLeft: "67%",
                    width: "16%",
                    height: "27%",
                    paddingTop: "2%",
                  }}
                >
                  <div className="flex justify-center items-end">
                    <img
                      className=""
                      style={{ height: "30%", width: "30%" }}
                      src={update}
                      alt="reset"
                    />
                  </div>
                  <div
                    className=" flex justify-center text-xs hover:cursor-pointer"
                    style={{ paddingTop: "35%" }}
                    onClick={(e) => {
                      sideUpdateAccessPriv();
                    }}
                  >
                    Update
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}

        <div className="pt-0.5">
          <Datagrid_template
            data={dataGridData}
            wordWrap={wordWrap}
            gridheight={toggled === true ? tableHeight - 388 : tableHeight + 13}
            columns={vehicleSimCard_data}
            onRowPrepared={rowColoring}
            rowAlternationEnabled={false}
            onRowClick={(selectedData: any) => {
              updateState("selectedRecord", selectedData);
              dispatch(setGeneralValue({ expr: "mainReqClear", value: false }));
              updateState("deactivateFields", true);
              rowClicked(selectedData);
              updateState("showUpdateButton", false);
              updateState("showCancelButton", false);
              dispatch(task_footer_table_selected(selectedData));
              dispatch(task_form_disable(true));
              dispatch(task_footer_update(false));
            }}
            rowDoubleClicked={(selectedData: any) => {
              updateState("selectedRecord", selectedData);
              AccessPriv_On_DoubleClick(selectedData);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default maintenanceRequestsDetails;
