import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DataGrid, Tooltip } from "devextreme-react";
import {
  SearchPanel,
  GroupPanel,
  Pager,
  Column,
  Scrolling,
} from "devextreme-react/data-grid";
import reset from "../../../../assets/reset.png";
import greenAdd from "../../../../assets/green_plus.png";
import X from "../../../../assets/cancel_16x16.png";
import { question_table_data, questions_TH } from "../data/questions-data";
// import {
//   EditButton,
//   NewButton,
//   SaveButton,
// } from "../../../../../accessories/buttons";
// import {
//   smallAttendanceData,
//   smallAttendaceTable_TH,
//   mediumlAttendaceTable_TH,
//   meeting_attendance_main_data,
//   meeting_attendance_main_TH,
// } from "../../../../../Safety/Entries/SafetyMeetingAttendance/data/meeting-attendance-data";
// import { useState, useEffect } from "react";
// import {
//   navbar_height,

//   toolbar_height,
// } from "../../../../../accessories/component_infos";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import { other_details_TH } from "../data/other-details-data";
import { useSelector } from "react-redux";
import {
  navbar_height,
  saveModalData,
  toolbar_height,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
// import { RootState } from "../../../../../../app/store";
import Datagrid_template from "../../../../templates/Datagrid";
import InputSelect_template from "../../../../templates/InputSelect";
import { TextareaTemplate } from "../../../../templates/textarea";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import { useCrudFunc } from "../../../../functions/crud";
import { set } from "lodash";
import { GetDataFunc } from "../../../../functions/get";
import { RefreshButton } from "../../elogger/StationReadings/_widget/utils";
import { CustomizableButton } from "../../../accessories/buttons";
import { select } from "@syncfusion/ej2-base";
import { setGeneralValue } from "../../../../features/generalSlice";
import { useDispatch } from "react-redux";

interface props {
  toggled?: boolean;
  statesx?: any;
  setRefresh?: (e: boolean) => void;
  refresh?: boolean;
}

const OtherLineDetailsTab = ({
  toggled,
  statesx,
  refresh,
  setRefresh,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }

  interface stateTypes {
    params: any;
    value: any;
    rmks: string;
    refresh1: boolean;
    selectedRecord: any;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  useEffect(() => {
    if (statesx !== undefined && Object.keys(statesx).length === 0) {
      populateFields(true);
    } else {
      populateFields(false);
    }
    console.log(`inside ${toggled}`);
  }, [toggled, statesx]);

  const [states, setStates] = useState<stateTypes>({
    params: { id: 0, name: "" },
    value: { id: 0, name: "" },
    rmks: "",
    refresh1: false,
    selectedRecord: {},
  });

  console.log(states, "statesxx");

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  // console.log(states.selectedRecord, "ttt");

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const dispatch = useDispatch();

  const [params_data, params_loading, params_value] = useFetch(
    `LineDetails/parameter_values`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [other_params_data, other_params_loading, other_params_value] =
    useFetch(
      `LineDetails/other_parameter_values`,
      true,
      undefined,
      undefined,
      "powerapp"
    );
  const [gridData, gridData_loading, gridData_value] = useFetch(
    `LineDetails/other-line-parameters/${statesx?.ldtIDpk}`,
    states.refresh1,
    undefined,
    undefined,
    "powerapp"
  );

  console.log(params_data, "gridData");
  console.log(other_params_data, "gridData2");

  const populateFields = (clear?: boolean) => {
    const gridState = [
      {
        key: "params",
        value: clear
          ? states.params
          : {
              // id: statesx?.ldtNomenclature,
              // name: statesx?.ldtNomenclature,
            }, //wrong key
      },
      {
        key: "value",
        value: clear
          ? states.value
          : {
              // id: statesx?.ldtNomenclature,
              // name: statesx?.ldtNomenclature,
            }, //wrong key
      },
      { key: "rmks", value: clear ? states.rmks : "" }, //wrong key
    ];
    for (let instance of gridState) {
      console.log(
        `Setting state for ${instance.key} with value ${instance.value}`
      );
      setState(instance.key, instance.value);
    }
  };

  const validation = () => {
    if (states.params.id === 0 || !states.params.id) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        title: "Invalid field",
        message: "Parameter is required",
        okText: "Ok",
        cancelText: "",
      });
      return false;
    }
    if (states.value.id === 0 || !states.value.id) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        title: "Invalid field",
        message: "Value is required",
        okText: "Ok",
        cancelText: "",
      });
      return false;
    }
    return saveAccessPriv();
  };

  const { Option } = Select;

  const [Posting, Updating, Patching] = useCrudFunc("powerapp");

  const saveAccessPriv = () => {
    // validation();
    setIcon("question");
    setOpenModal(true);
    setModalData({
      title: "Add Record",
      message: "Are you sure you want to add this record?",
      okText: "Yes",
      cancelText: "No",
    });
  };

  const deleteAccessPriv = () => {
    // validation();
    setIcon("question");
    setOpenModal(true);
    setModalData({
      title: "Delete Record",
      message: "Are you sure you want to delete the accesory for the vehicle?",
      okText: "Yes",
      cancelText: "No",
    });
  };

  const addData = async () => {
    try {
      const res = await Posting(`LineDetails/other-line-parameters`, {
        olpLineDetailsIDfk: statesx?.ldtIDpk,
        olpParameterIDfk: states.params.id,
        olpValue: states.value.name,
        olpRmks: states.rmks,
        olpActive: true,
      });
      setOpenModal(true);
      setIcon("success");
      setModalData({
        title: "Success",
        message: "Record added successfully",
        okText: "Ok",
        cancelText: "",
      });
      setState("refresh1", !states.refresh1);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (error) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Record failed to add",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const updateData = async () => {
    try {
      const res = await Updating(`LineDetails/other-line-parameters`, {
        olpValue: states.value.name,
        olpRmks: states.rmks,
        olpIdpk: states.selectedRecord.olpIDpk,
      });
      setOpenModal(true);
      setIcon("success");
      setModalData({
        title: "Success",
        message: "Record added successfully",
        okText: "Ok",
        cancelText: "",
      });
      setState("refresh1", !states.refresh1);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (error) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Record failed to add",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const deactivateData = async () => {
    try {
      const res = await Patching(
        `LineDetails/DeactivateOtherLineParameters/${states.selectedRecord.olpIDpk}`,
        {}
      );
      setOpenModal(true);
      setIcon("success");
      setModalData({
        title: "Success",
        message: "Record deleted successfully",
        okText: "Ok",
        cancelText: "",
      });
      setState("refresh1", !states.refresh1);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (error) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Record failed to delete",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  // console.log(otherLineExistData, "otherLineExist");

  const handleSave = async () => {
    const otherLineExistData: any = await GetDataFunc(
      `LineDetails/check-other-line-parameter-exist?opmName=${states.params.name}&ldtIdpk=${statesx.ldtIDpk}`,
      undefined,
      "powerapp"
    );
    const otherLineExist = otherLineExistData.data.data;
    console.log(otherLineExistData, "otherLineExist");
    try {
      if (otherLineExist.exists === false) {
        await addData();
      } else if (otherLineExist.exists === true) {
        await updateData();
      }
    } catch (error) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message:
          "An error occurred while checking for existing line parameters",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Add Record"
            ? handleSave()
            : modalData.title === "Delete Record"
            ? deactivateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "success" || icon === "warning" ? true : false}
      />
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 border-r-2 "
              style={{ width: "100%" }}
            >
              <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                Other Parameters
              </div>
              <Form
                name="complex-form"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="ml-2 my-2 "
                style={{ width: "90%" }}
              >
                <Form.Item
                  label={<p className="text-xs">{"Parameter"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(44%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <InputSelect_template
                        style={{ width: "100%" }}
                        disabled={form_disable}
                        useCallFunc
                        idexpr={"opmIDpk"}
                        dataexp={"opmName"}
                        options={other_params_data}
                        placeHolder={states.params.name}
                        selectedValue={(e) => {
                          setState("params", {
                            id: e["opmIDpk"],
                            name: e["opmName"],
                          });
                        }}
                        handleClear={() => {
                          setState("params", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Value"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(44%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <InputSelect_template
                        style={{ width: "100%" }}
                        disabled={form_disable}
                        useCallFunc
                        idexpr={"acvIDpk"}
                        dataexp={"acvName"}
                        options={params_data}
                        placeHolder={states.value.name}
                        selectedValue={(e) => {
                          setState("value", {
                            id: e["acvIDpk"],
                            name: e["acvName"],
                          });
                        }}
                        handleClear={() => {
                          setState("value", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Remarks"}</p>}
                  style={{ marginBottom: 0, marginTop: "2px" }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <TextareaTemplate
                        height={45}
                        width={"500px"}
                        readonly={false}
                        disabled={form_disable}
                        useCallbackFunc
                        defaultValue={states.rmks}
                        setCallbackValue={(e) => {
                          setState("rmks", e);
                        }}
                        //  className="w-full h-full"
                      />
                      <div className="flex  justify-end mb-1 ">
                        <button
                          title="add"
                          type="button"
                          onClick={validation}
                          disabled={form_disable}
                          style={
                            !form_disable
                              ? { opacity: 1, borderColor: borderTheme }
                              : { opacity: 0.5, borderColor: borderTheme }
                          }
                          className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   px-1 ml-2 py-1 border-2 border-slate-200 rounded"
                        >
                          <img alt="add" className="" src={greenAdd} />
                        </button>
                      </div>
                    </div>
                  </Form.Item>
                </Form.Item>
              </Form>

              <div className=" border-t-2  ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2 flex justify-between items-center">
                  <span>Additional Parameter Details</span>
                  <div className="flex flex-row space-x-1">
                    <Tooltip
                      target="#refreshButton"
                      showEvent="mouseenter"
                      hideEvent="mouseleave"
                      position="bottom"
                      contentRender={() => (
                        <div>Refresh vehicle accessories </div>
                      )}
                    />
                    <li id="refreshButton">
                      <RefreshButton
                        onClick={() => {
                          setState("refresh1", !states.refresh1);
                        }}
                      />
                    </li>
                    <Tooltip
                      target="#closeButton"
                      showEvent="mouseenter"
                      hideEvent="mouseleave"
                      position="bottom"
                      contentRender={() => (
                        <div>Delete the selected accesory</div>
                      )}
                    />
                    <li id="closeButton">
                      <CustomizableButton
                        customizableBtnImage={X}
                        className="flex justify-center hover:cursor-pointer border-gray-300 rounded"
                        customizableBtnImageClassname={
                          "w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        }
                        handleCustomizableClick={() => {
                          deleteAccessPriv();
                        }}
                      />
                    </li>
                  </div>
                </div>
                <Datagrid_template
                  gridheight={210}
                  columns={other_details_TH}
                  data={gridData ?? []}
                  onRowClick={(e) => {
                    setState("selectedRecord", e);
                  }}
                />
                {/* <DataGrid
                  dataSource={meeting_attendance_main_data}
                  showBorders={true}
                  columnWidth={150}
                  showRowLines={true}
                  showColumnLines={true}
                  Remember to change
                  height={178}
                > */}
                {/* <SearchPanel visible={false} width={200} />
                  <GroupPanel visible={false} />
                  <Pager visible={false} /> */}

                {/* STAFF DETAILS COLUMNS */}
                {/* FIXED COLUMNS */}

                {/* {other_details_TH.map((data) => {
                    return (
                      <Column
                        key={data.id}
                        caption={data.caption}
                        dataField={data.dataField}
                        alignment={data.alignment}
                        fixed={data.fixed}
                        width={data.width === null ? 150 : data.width}
                      />
                    );
                  })} */}

                {/* SCROLLBAR  */}

                {/* <Scrolling
                  columnRenderingMode="finite"
                  /> */}
                {/* </DataGrid> */}
              </div>
            </Form>

            {/* <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 ml-2 "
              style={{ width: "45%" }}
            >
              <div className=" border-t-2 pb-2 ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Images
                </div>

                <div className="px-2 pb-2 flex items-end    h-64 w-full ">
                  <div className=" w-full h-60  border flex justify-center items-center">
                    No Image data
                  </div>
                </div>
                <div className="flex mx-2 mb-0.5">
                  {" "}
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "88%",
                    }}
                    className="o  py-1  overflow-y-scroll resize-none"
                    rows={1}
                  />
                  <button
                    title="save"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  mr-1 px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="save" className="pr-1" src={save} />
                  </button>
                  <button
                    title="a"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="newIcon" className="pr-1" src={newIcon} />
                  </button>
                </div>
                <div className="flex">
                  <div
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "100%",
                    }}
                    className="o mx-2  border  flex "
                  >
                    <div className=" border-r p-0.5 text-xs px-2">1</div>
                    <div className=" border-r p-0.5 text-xs px-2">2</div>
                    <div className=" border-r p-0.5 text-xs px-2">3</div>
                    <div className=" border-r p-0.5 text-xs px-2">4</div>
                    <div className=" border-r p-0.5 text-xs px-2">5</div>
                    <div className=" border-r p-0.5 text-xs px-2">6</div>
                    <div className=" border-r p-0.5 text-xs px-2">7</div>
                    <div className=" border-r p-0.5 text-xs px-2">8</div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
        </div>
      }
    </div>
  );
};

export default OtherLineDetailsTab;
