import { useRef } from "react";
import { CheckboxTemlate } from "../../../../templates/checkbox"

interface Props {
    options: any[];
    onSelectionChange?: (selected: any[]) => void;
}

export const MiniCheckBoxContainer = ({ options, onSelectionChange }: Props) => {
    const { current: selected } = useRef([]);

    const refineOperators = (operators: any[]) => {
        let init = [];
        const refine = [];
        for (let i = 0; i < operators.length; i++) {
            init.push(operators[i])
            if (init.length === 4) {
                refine.push(init);
                init = [];
            }
        }
        return refine;
    }

    const onClick = (empName1: any) => {
        selected.find(e => e === empName1) ? selected.splice(selected.indexOf(empName1), 1) :
            selected.push(empName1);
        onSelectionChange && onSelectionChange(selected);
    }

    return <section className="flex space-x-2 overflow-x-auto size-full items-center">
        {
            refineOperators(options)?.map((opt: any, i) => {
                return <section className="min-w-fit" key={i}>
                    {opt?.map(({ empName1 }, index: number) => <MiniCheckBox key={index} empName1={empName1} index={index} onClick={onClick} />)}
                </section>
            })}
    </section>
}

type MiniProps = {
    empName1: string;
    index: number;
    onClick: (empName: string) => void;
}

const MiniCheckBox = ({ empName1, index, onClick }: MiniProps) => {
    return <section
        style={{}}
        className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center space-x-2 w-full"
        key={index}
    >
        <CheckboxTemlate
            id={index}
            withBorders
            customDisabled={false}
            placeholder={empName1}
            useCallbackFunc
            handleCheck={(e) => onClick(empName1)}
        />
    </section>
}