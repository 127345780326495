import { Tooltip } from "devextreme-react";
import reset from "../../../../../../assets/reset.png";

import flip from "../../../../../../assets/alter.png";
import edit from "../../../../../../assets/edit.png";
import close from "../../../../../../assets/close.png";
import chart from "../../../../../../assets/bar-chart.png";
import newImg from "../../../../../../assets/new.png";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useState } from "react";
import dayjs from "dayjs";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { IconsContainer } from "../../_widget/stationReadingsToolbar";
import { InputsTemplate } from "../../../../../../templates/input";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { useGraphSwitcher } from "../../db/useSwitcher";
import { DatePicker } from "antd";

type Props = {
  showEditIcon?: boolean;
  searchTextOnchange?: (value: string) => void;
  checkOnChange?: (value: any) => void;
  handleFind?: () => void;
  runNew?: () => void;
  runUpdate?: () => void;
  handleClose?: () => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  startDate?: any;
  endDate?: any;
  searchValue?: string;
  handleRefresh?: () => void;
  handleSwitcher?: () => void;
};

export default function EquipmentToolbar({
  showEditIcon = true,
  searchTextOnchange,
  checkOnChange,
  handleFind,
  startDateChanged,
  endDateChanged,
  handleClose,
  runNew,
  runUpdate,
  startDate,
  endDate,
  handleRefresh,
  searchValue,
  handleSwitcher,
}: Props) {
  const { setSwitchGraph } = useGraphSwitcher();
  const dispatch = useDispatch();

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState("");

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  return (
    <div className="border-2 border-muted w-full h-auto rounded-md">
      <section className="flex items-center w-full h-full space-x-1 px-1">
        <aside className="flex space-x-0.5">
          <IconsContainer
            onClick={() => {
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              runNew();
            }}
            url={newImg}
            id={"id" + Math.ceil(Math.random() * 3600).toString()}
            name="New"
            tooltip="Add a new record"
          />

          {showEditIcon && (
            <span onClick={runUpdate}>
              <IconsContainer
                url={edit}
                id={"id" + Math.ceil(Math.random() * 3600).toString()}
                name="Edit"
                tooltip="Update the selected record"
              />
            </span>
          )}
          <span onClick={handleClose}>
            <IconsContainer
              id={"id" + Math.ceil(Math.random() * 3600).toString()}
              url={close}
              name="Deactivate"
              tooltip="Activate/De-activate the selected record"
            />
          </span>

          <span onClick={handleSwitcher}>
            <IconsContainer
              url={flip}
              id={"id" + Math.ceil(Math.random() * 3600).toString()}
              name="Switch Interface"
              tooltip="Switch between the table and the form view"
            />
          </span>
          <aside onClick={setSwitchGraph}>
            <IconsContainer
              url={chart}
              id={"id" + Math.ceil(Math.random() * 3600).toString()}
              name="Charts"
              tooltip="View chart representation of the data"
            />
          </aside>
        </aside>
        <aside className="flex flex-1 space-x-1 items-center">
          <section className="flex-1">
            <InputsTemplate
              label="Search Text"
              inputStyle={{ height: "25px" }}
              span={true}
              disabledStatus={false}
              useCallbackFunc
              defaultValue={searchValue}
              orderOnchange={(e) => {
                searchTextOnchange(String(e));
              }}
            />
          </section>
        </aside>
        <aside className="flex space-x-1 items-center mr-2">
          {/* <DateTemplate
            disabled={false}
            changeDate={(date: Dayjs) =>
              startDateChanged!(dayjs(date).format())
            }
            datePlaceholder="Start Date"
            selectedDate={currentDate}
                  /> */}
          <DatePicker
            clearIcon={<span style={{ display: "none" }}></span>}
            onChange={(e) => {
              //   startDateChanged!(e);
              startDateChanged!(dayjs(e).format(`YYYY-MM-DD`));
            }}
            value={dayjs(startDate)}
            size="small"
            placeholder="Start Date"
            style={{ width: 125 }}
            format={"DD MMM YYYY"}
          />
          <DatePicker
            clearIcon={<span style={{ display: "none" }}></span>}
            onChange={(e) => {
              //   endDateChanged!(e);
              endDateChanged!(dayjs(e).format(`YYYY-MM-DD`));
            }}
            value={dayjs(endDate)}
            size="small"
            placeholder="End Date"
            style={{ width: 125 }}
            format={"DD MMM YYYY"}
          />
          {/* <DateTemplate
            disabled={false}
            changeDate={(date: Dayjs) => endDateChanged!(dayjs(date).format())}
            // selectedDate={dayjs().format()}
            // selectedDate={currentDate}
            datePlaceholder="End Date"
          /> */}
          <li
            id="checkactive"
            style={{}}
            className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center "
          >
            <CheckboxTemlate
              defaultValue={checkedBox}
              indeterminate={indeterminate}
              withBorders
              customDisabled={false}
              useCallbackFunc
              setCheckboxOnchange={(e: boolean, index: any) => {
                checkOnChange(e);
                onChange(e);
              }}
            />

            <Tooltip
              target="#checkactive"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Active/In-active</p>
              <p>Toggle between active/inactive records</p>
            </Tooltip>
          </li>
          {/* <li
            id="refreshGrid"
            style={{}}
            // className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center "
            className="p-1 border-[1px] rounded-sm "
          >
            <FaBinoculars size={15.5} color="#007AFF" />
            <Tooltip
              target="#refreshGrid"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Find record</p>
              <p>Search record in database</p>
            </Tooltip>
          </li> */}
          <div
            id="searchGrid"
            onClick={() => {
              handleFind();
            }}
            className="p-1 border-[1px] rounded-sm "
          >
            <FaBinoculars size={15.5} color="#007AFF" />
            <Tooltip
              target="#searchGrid"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Find record</p>
              <p>Search record in database</p>
            </Tooltip>
          </div>
          <span
            id="refreshGrid"
            onClick={handleRefresh}
            style={{
              height: 24,
              marginBottom: 0,
              borderWidth: 1,
              width: 24,
            }}
            className="flex justify-center hover:cursor-pointer border-gray-300 rounded "
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
              src={reset}
              alt="reset"
            />
            <Tooltip
              target="#refreshGrid"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Refresh table</p>
              <p>Refresh table</p>
            </Tooltip>
          </span>

          {/* <IconsContainer
            url={print}
            name="Midnight Conditions"
            tooltip="Click to initiate the capturing of midnight conditions for the station"
          /> */}
        </aside>
      </section>
    </div>
  );
}
