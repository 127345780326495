/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DateTemplate } from "../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../templates/select";
import {
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../accessories/buttons";
import { InputsTemplate } from "../../../../../../templates/input";
import Datagrid_template from "../../../../../../templates/Datagrid";
import MiniToolbar from "../../_widget/minitollbar";
import { Switcher } from "../../_widget/switcher";
import { equipCols, equipData } from "./data/equipmentOutageGvData";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import { ReadingsChart } from "../../_widget/readingschart";
import useFetch from "../../../../../../hooks/useFetch";
import {
  modalPropTypes,
  modalTypes,
  searchTextTypes,
  statusTypes,
  validateInstanceTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useCrudFunc } from "../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import {
  currentDate,
  dbDateFormat,
  saveModalData,
  useModalData,
} from "../../../../../accessories/component_infos";
import { update } from "lodash";
import { ModalTemplate } from "../../../../../../templates/modal";
import dayjs from "dayjs";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { useAxiosService } from "../../../../../../general/requestConfig";
import EquipmentToolbar from "./equipment-toolbar";
import EquimentDataTable from "./equipment-data-table";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { AxiosError } from "axios";
import { parseLower } from "../../../widgets/utils";

interface stateTypes {
  startTime: string;
  endTime: string;
  station: any;
  outageType: any;
  equipmentType: any;
  equipment: any;

  active: boolean;
  remarks: string;
  details: string;

  selectedRecordId: number;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
}
export const EquipmentOutage = () => {
  const startingDate = dayjs()
    .subtract(1, "years")
    .startOf("month")
    .format(dbDateFormat);

  const { status } = useGraphSwitcher();
  const { status: switchStatus, setWitch } = useSwitcher();
  const recordSwitch = useRef<boolean>();

  useEffect(() => {
    // when switch changes, store the current switch status inside `recordSwitch` ref, this is to prevent state loss when there are any re-renders
    recordSwitch.current = switchStatus;
  }, [switchStatus]);

  const query = useAxiosService("powerapp");
  const [_, userId] = useAccountInfo();
  const [modal, setModal] = useModalData();
  const [refresh, setrefresh] = useState<boolean>(false);

  const [states, setStates] = useState<stateTypes>({
    startTime: `${dayjs().format()}`,
    endTime: `${dayjs().format()}`,
    station: { name: "", id: "" },
    outageType: { name: "", id: "" },
    equipmentType: { name: "", id: "" },
    equipment: { name: "", id: "" },
    details: "",

    active: false,
    remarks: "",

    selectedRecordId: undefined,

    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
  });

  // const[searchStates,setSearchStates]=useState({
  //   searchTerm: '',
  //   checkState: null,
  //   searchStartDate:'',
  //   searchEndDate:'',
  // })
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string>(
    dayjs(startingDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"));

  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [station, setStation] = useState<any[]>([]);
  const [outageType, setOutageType] = useState<any[]>([]);
  const [equipmentType, setEquipmentType] = useState<any[]>([]);
  const [equipment, setEquipment] = useState<any[]>([]);
  const [allEquipments, setAllEquipments] = useState<any[]>([]);
  const [refreshView, setRefreshView] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    number: 1,
    size: 20,
  });

  const [rowData, setRowData] = useState<Record<string, any>>();
  const formDateDifference = dayjs(states.endTime).diff(
    dayjs(states.startTime),
    "minutes"
  );

  useEffect(() => {
    query({
      url: `EquipmentOutage/PopLueOtgStations`,
    }).then((data) => {
      setStation(data);
    });
  }, []);

  useEffect(() => {
    query({
      url: `EquipmentOutage/PopLueOtgOutageTypes`,
    }).then((data) => {
      setOutageType(data);
    });
  }, []);
  useEffect(() => {
    query({
      url: `EquipmentOutage/PopLueOtgEquipmentTypes`,
    }).then((data) => {
      setEquipmentType(data);
    });
  }, []);

  useEffect(() => {
    if (!states.station.id || !states.equipmentType.id) return;
    query({
      url: `EquipmentOutage/PopLueOtgEquipments`,
      params: {
        stationId: states.station.id,
        EquipmentType: states.equipmentType.id,
      },
    }).then((data) => {
      setEquipment(data);
    });
  }, [states.equipmentType.id, states.station.id]);

  useEffect(() => {
    (async () => {
      query({
        url: `EquipmentOutage/PopGdcEquipmentOutages`,
        params: {
          StartDate: startDate,
          EndDate: endDate,
          CheckState: activeStatus.temp !== null ? activeStatus.temp : "",
          SearchTerm: searchValue?.length === 0 ? undefined : searchValue,
          pageNumber: pagination.number,
          pageSize: pagination.size,
        },
      }).then((res) => setAllEquipments(res?.data));
    })();
  }, [refreshView, pagination.number, pagination.size]);

  useEffect(() => {}, [switchStatus]);

  async function DeleteEquipmentOutage() {
    if (!states.selectedRecordId) {
      setModal({
        state: true,
        title: "Delete Equipment Outage",
        message: `Please select a record to delete`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return;
    }
    const canDelete = await hasEditPriviledge();
    if (!canDelete) return;
    setModal({
      state: true,
      title: !!rowData?.eotActive
        ? "Delete Equipment Outage"
        : "Reactivate Equipment Outage",
      message: !!rowData?.eotActive
        ? `Are you sure you want to delete the selected record?`
        : `Are you sure you want to REACTIVATE the selected Equipment Outage record?`,
      icon: "question",
      okText: "Yes",
      cancelText: "No",
      disableCancel: false,
      okHandler: async () => {
        query({
          url: `EquipmentOutage/DeleteEquipmentOutage`,
          method: `PUT`,
          data: {
            id: states.selectedRecordId,
            active: !!rowData?.eotActive ? false : true,
            usaIDpk: userId,
            formAction: !!rowData?.eotActive
              ? `Delete Equipment Outage with ID: ${states.selectedRecordId}, by ${userId} on ${currentDate}`
              : `Reactivate Equipment Outage with ID: ${states.selectedRecordId}, by ${userId} on ${currentDate}`,
          },
        })
          .then((res) => {
            if (!!res?.data) {
              setModal({
                state: true,
                title: !!rowData?.eotActive
                  ? "Delete Equipment Outage"
                  : "Reactivate Equipment Outage",
                message: `Record updated successfully`,
                icon: "success",
                okText: "Ok",
                cancelText: "No, Cancel",
                disableCancel: true,
                okHandler: () => {
                  setModal((prev) => ({
                    ...prev,
                    state: false,
                  }));
                },
                cancelHandler: () => {},
              });
              setRefreshView((prev) => !prev);
            } else {
              setModal({
                state: true,
                title: "Delete Equipment Outage",
                message: `An error occured while deleting the record`,
                icon: "warning",
                okText: "Ok",
                cancelText: "No, Cancel",
                disableCancel: true,
                okHandler: () => {
                  setModal((prev) => ({
                    ...prev,
                    state: false,
                  }));
                },
                cancelHandler: () => {},
              });
            }
          })
          .catch((error) => {
            setModal({
              state: true,
              title: "Delete Equipment Outage",
              message: `An error occured while deleting the record`,
              icon: "warning",
              okText: "Ok",
              cancelText: "No, Cancel",
              disableCancel: true,
              okHandler: () => {
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              },
              cancelHandler: () => {},
            });
          });
      },
      cancelHandler: () => {
        setModal((prev) => ({
          ...prev,
          state: false,
        }));
      },
    });
  }

  //validate fields
  const validateFields = (): boolean => {
    const validationInstances: validateInstanceTypes[] = [
      {
        state: states.startTime,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Start Time",
          message: "Please select start time",
        },
      },
      {
        state: states.endTime,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter End Time",
          message: "Please select end time",
        },
      },

      {
        state: states.station.id,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Station",
          message: "Please select a station",
        },
      },
      {
        state: states.outageType.id,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Outage Type",
          message: "Please select outage type",
        },
      },
      {
        state: states.equipmentType.id,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Equipment Type",
          message: "Please select equipment type",
        },
      },
      {
        state: states.equipment.id,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Equipment",
          message: "Please select equipment",
        },
      },
      // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
    ];

    for (let instance of validationInstances)
      if (instance.state === instance.baseValue) {
        const { modalProps } = instance;
        updateState("warningModal", {
          state: modalProps.state,
          title: modalProps.title,
          message: modalProps.message,
        });
        return false;
      }
    return true;
  };

  const dataFields = [
    {
      id: 0,
      label: "Start Time",
      optionsData: [],
      stateName: "startTime",
      idExpr: "",
      dataExpr: "",
      defaultValue: states?.startTime,
    },
    {
      id: 1,
      label: "End Time",
      optionsData: [],
      stateName: "endTime",
      idExpr: "",
      dataExpr: "",
      defaultValue: states?.endTime,
    },
    {
      id: 2,
      label: "Station",
      optionsData: station ?? [],
      stateName: "station",
      idExpr: "stnIDpk",
      dataExpr: "stnName",
      defaultValue: states?.station?.name,
    },
    {
      id: 3,
      label: "Outage Type",
      optionsData: outageType ?? [],
      stateName: "outageType",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.outageType?.name,
    },
    {
      id: 4,
      label: "Equipment Type",
      optionsData: equipmentType ?? [],
      stateName: "equipmentType",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.equipmentType?.name,
    },
    {
      id: 5,
      label: "Equipment",
      optionsData: equipment ?? [],
      stateName: "equipment",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.equipment?.name,
    },
  ];

  const remarkFeilds = [
    {
      id: 0,
      label: "Details",
      stateName: "details",
      defaultValue: states?.details,
    },
    {
      id: 1,
      label: "Remarks",
      stateName: "remarks",
      defaultValue: states?.remarks,
    },
  ];

  const disableConfirmModal = () => {
    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
      action: "post",
    });
  };

  const enableSuccessModal = (
    titleActionText: string,
    messageActionText: string
  ) => {
    updateState("successModal", {
      state: true,
      title: `${titleActionText} Successful`,
      message: `Record ${messageActionText} successfully`,
    });
  };

  //post data
  const [posting, updating] = useCrudFunc("powerapp");

  const [busyLoader, setBusyLoader] = useBusyLoader();

  // useEffect(() => {
  //   stationDataLoading ? setBusyLoader("") : setBusyLoader("");
  // }, [stationDataLoading]);

  //create
  const postData = async (action: "validate" | "post") => {
    if (action === "validate") {
      // validate fieldds
      validateFields() &&
        updateState("confirmModal", {
          state: true,
          title: "Save Record",
          message: "Are you sure you want to save this record?",
          action: "post",
        });
      return;
    }

    //post
    disableConfirmModal();
    setBusyLoader(saveModalData.progress);
    //post request here
    //   setModal("state", false);
    // refreshGrid();
    // setBusyLoader(saveModalData.progress);
    try {
      const postResponse = await posting(
        `EquipmentOutage/CreateEquipmentOutage`,
        {
          eotStationIDfk: states.station.id,
          eotOutageTypeIDfk: states.outageType.id,
          eotEquipmentTypeIDfk: states.equipmentType.id,
          eotEquipmentDetailIDfk: states.equipment.id,
          eotEquipment: states.equipment?.name,
          eotStartTime: states.startTime,
          eotEndTime: states.endTime,
          eotDetails: states.details,
          eotRmks: states.remarks,
          eotCreatedBy: 0,
          formCode: "string",
          formAction: "string",
        },
        `Created Bus Detail`
      );
      setRefreshView((prev) => !prev);
      setrefresh(!refresh);
      // setRefresh(!refresh);
      // setsuccesModalState({
      //   state: true,
      //   message: "Record saved successfully",
      //   title: "Save Successful",
      // });
      // setBusyLoader("");
      // populateFromGrid(false);
      setrefresh(!refresh);

      setStates({
        startTime: "",
        endTime: "",
        station: { name: "", id: "" },
        outageType: { name: "", id: "" },
        equipmentType: { name: "", id: "" },
        equipment: { name: "", id: "" },
        details: "",

        active: false,
        remarks: "",

        selectedRecordId: undefined,

        confirmModal: { state: false, title: "", message: "", action: "post" },
        warningModal: { state: false, title: "", message: "" },
        successModal: { state: false, title: "", message: "" },
      });
      updateState("successModal", {
        state: true,
        title: `Save Successful`,
        message: `Record saved successfully`,
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      //  populateStatesFromGrid(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        let err = error.response?.data?.errors;
        let errMessage = (err as []).map((e: string, _i) => (
          <p className="text-rose-600 text-sm font-semibild">{`${
            _i + 1
          }. ${e}`}</p>
        ));
        if (err) {
          setModal({
            state: true,
            title: `Encounted ${err.length} error(s)`,
            message: errMessage,
            icon: "warning",
            okText: "Ok",
            cancelText: "No, Cancel",
            disableCancel: true,
            okHandler: () => {
              setModal((prev) => ({
                ...prev,
                state: false,
              }));
            },
            cancelHandler: () => {},
          });
        }
      }
    } finally {
      setBusyLoader("");
    }

    return;
  };
  async function hasEditPriviledge(record?: number) {
    // if (!states.selectedRecordId) return;
    const res = await query({
      url: `EquipmentOutage/ConfirmCreatedBy`,
      params: {
        Id: record || rowData?.eotIDpk,
      },
    });
    if (!res?.data) {
      setModal({
        state: true,
        title: "Edit Equipment Outage",
        message:
          parseLower(res?.message) ||
          `You did not log the record. You cannot edit`,
        icon: "warning",
        okText: "Ok",
        cancelText: "No, Cancel",
        disableCancel: true,
        okHandler: () => {
          setModal((prev) => ({
            ...prev,
            state: false,
          }));
        },
        cancelHandler: () => {},
      });
      return false;
    }
    return true;
  }
  const updateData = async (action: "validate" | "update") => {
    if (action === "validate") {
      // validate fieldds
      validateFields() &&
        updateState("confirmModal", {
          state: true,
          title: "Update Record",
          message: "Are you sure you want to update this record?",
          action: "update",
        });
      return;
    }

    //post
    disableConfirmModal();
    setBusyLoader(saveModalData.progress);
    //post request here
    //   setModal("state", false);
    // refreshGrid();
    // setBusyLoader(saveModalData.progress);
    try {
      const postResponse = await updating(
        `EquipmentOutage/UpdateEquipmentOutage`,
        {
          eotIDpk: states.selectedRecordId,
          eotStationIDfk: parseInt(states.station.id),
          eotOutageTypeIDfk: parseInt(states.outageType.id),
          eotEquipmentTypeIDfk: parseInt(states.equipmentType.id),
          eotEquipmentDetailIDfk: parseInt(states.equipment.id),
          eotEquipment: states.equipment?.name,
          eotStartTime: states.startTime,
          eotEndTime: states.endTime,
          eotDetails: states.details,
          eotRmks: states.remarks,
          eotCreatedBy: 0,
          FormCode: "string",
          formAction: "string",
        },
        `Created Bus Detail`
      );
      // setrefresh(!refresh);
      // setRefresh(!refresh);
      // setsuccesModalState({
      //   state: true,
      //   message: "Record saved successfully",
      //   title: "Save Successful",
      // });
      // setBusyLoader("");
      // populateFromGrid(false);

      setrefresh(!refresh);
      setRefreshView((prev) => !prev);
      setAllowUpdate(false);
      setStates({
        startTime: "",
        endTime: "",
        station: { name: "", id: "" },
        outageType: { name: "", id: "" },
        equipmentType: { name: "", id: "" },
        equipment: { name: "", id: "" },
        details: "",

        active: false,
        remarks: "",

        selectedRecordId: undefined,

        confirmModal: { state: false, title: "", message: "", action: "post" },
        warningModal: { state: false, title: "", message: "" },
        successModal: { state: false, title: "", message: "" },
      });
      updateState("successModal", {
        state: true,
        title: `Update Successful`,
        message: `Record updated successfully`,
      });
      //  populateStatesFromGrid(false);
    } catch (error) {
      console.log(error);

      updateState("warningModal", {
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
    return;
  };

  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: states.warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.warningModal.message,
      okText: "Ok",
      title: states.warningModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: states.confirmModal.state,
      okHandler:
        states.confirmModal.action === "update"
          ? () => updateData("update")
          : () => postData("post"),
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText:
        states.confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    //success
    {
      disableCancel: true,
      icon: "success",
      open: states.successModal.state,
      okHandler: () =>
        updateState("successModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.successModal.message,
      okText: "Ok",
      title: states.successModal.title,
      cancelText: "No, cancel",
    },
  ];

  const dispatch = useDispatch();

  return (
    <>
      <ModalTemplate
        width={400}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <div className="w-full h-full p-2">
        <Switcher alternate={""}>
          <div className="flex gap-1">
            {/* leftSide */}
            <aside className="  w-[50%] mb-1 relative">
              <Form
                className=" h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded space-y-[2px] w-[350px]"
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 22 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
                layout="horizontal"
              >
                {dataFields.map(
                  ({
                    id,
                    label,
                    defaultValue,
                    optionsData,
                    idExpr,
                    dataExpr,
                    stateName,
                  }) =>
                    id === 0 || id === 1 ? (
                      <div
                        className={
                          id === 1 &&
                          "flex space-x-3 items-center pl-3.5 w-full"
                        }
                      >
                        <DateTemplate
                          showTime
                          key={id}
                          label={label}
                          width={id === 1 ? 246 : 206}
                          selectedDate={
                            defaultValue === "" ? "" : dayjs(defaultValue)
                          }
                          changeDate={(e) => {
                            updateState(stateName!, e);
                          }}
                        />
                        {id === 1 && (
                          <p className="text-xs font-bold right-2 ml-10 text-blue-400 absolute">
                            {`${formDateDifference} minutes`}
                          </p>
                        )}
                      </div>
                    ) : (
                      <Form.Item key={id} label={label} name={label}>
                        <SelectsTemplate
                          label=""
                          placeHolder={defaultValue}
                          selectedValue={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });
                          }}
                          handleClear={() => {
                            updateState(stateName!, { id: 0, name: "" });
                          }}
                          // handleRefresh={() => {
                          //   updateState(refreshStateName!, !states.requestType);
                          // }}
                          useCallFunc={true}
                          options={optionsData}
                          dataexp={dataExpr}
                          idexpr={idExpr}
                        />
                      </Form.Item>
                    )
                )}
                <div className="w-[100%] flex justify-end pr-6 ">
                  <SaveButton
                    disableButton={disableButton}
                    useCallbackFunc={true}
                    title={allowUpdate ? "Update" : "Save"}
                    handleSave={() => {
                      !allowUpdate
                        ? postData("validate")
                        : updateData("validate");
                    }}
                  />
                  {/* <UpdateButton handleUpdate={() => { 
                   updateData("validate");
                  }} /> */}

                  {/* <NewButton    useCallbackFunc={true}

                new_button_toggler={()=>{
                  dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
                  dispatch(setGeneralValue({ expr: "updateMode", value: false }));
                }}
                      /> */}
                </div>
              </Form>
            </aside>
            {/* rightSide */}
            <aside className=" w-full ">
              <Form.Item
                className="pt-2 pl-[48px] "
                label={<p className="text-[12px] ">Details:</p>}
              >
                <div className="w-full pr-2">
                  <TextareaTemplate
                    height={90}
                    defaultValue={states.details}
                    useCallbackFunc
                    setCallbackValue={(e) => {
                      updateState("details", e);
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                className="pt-2 pl-[40px]"
                label={<p className="text-[12px]">Remarks:</p>}
              >
                <div className="w-full pr-2">
                  <TextareaTemplate
                    height={90}
                    defaultValue={states.remarks}
                    useCallbackFunc
                    setCallbackValue={(e) => {
                      updateState("remarks", e);
                    }}
                  />
                </div>
              </Form.Item>
              {/* <Form
              className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded   "
              name="basic"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className="space-y-2">
                {remarkFeilds.map(({ id, label,  stateName, defaultValue,  }) => (
                  <InputsTemplate key={id} label={label} span inputStyle={{ height: "90px" }}
                  useCallbackFunc
                  orderOnchange={(e)=>{
                    updateState(stateName!, e)
                  }}
                  defaultValue={defaultValue}
                
                  />
                ))}
              </div>
            </Form> */}
            </aside>
          </div>
          {/* dataGrid section */}
          {/* <div className='h-1 w-full bg-sky-200' /> */}
        </Switcher>
        <section className="flex flex-col">
          <EquipmentToolbar
            key={Math.random() * 3600}
            runNew={() => {
              setAllowUpdate(false);
              setDisableButton(false);
              setStates({
                startTime: `${dayjs().format()}`,
                endTime: `${dayjs().format()}`,
                station: { name: "", id: "" },
                outageType: { name: "", id: "" },
                equipmentType: { name: "", id: "" },
                equipment: { name: "", id: "" },
                details: "",
                active: false,
                remarks: "",

                selectedRecordId: undefined,

                confirmModal: {
                  state: false,
                  title: "",
                  message: "",
                  action: "post",
                },
                warningModal: { state: false, title: "", message: "" },
                successModal: { state: false, title: "", message: "" },
              });
            }}
            runUpdate={async () => {
              if (
                !states.selectedRecordId ||
                !rowData?.eotIDpk ||
                states.selectedRecordId === 0
              ) {
                setModal({
                  state: true,
                  title: "Update Equipment Outage",
                  message: `Please select a record to update`,
                  icon: "warning",
                  okText: "Ok",
                  cancelText: "No, Cancel",
                  disableCancel: true,
                  okHandler: () => {
                    setModal((prev) => ({
                      ...prev,
                      state: false,
                    }));
                  },
                  cancelHandler: () => {},
                });
                return;
              }
              //  check if user can update
              const canEdit = await hasEditPriviledge(rowData?.eotIDpk);
              if (!canEdit) return;

              setDisableButton(false);
              setAllowUpdate(true);
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            }}
            handleClose={() => {
              DeleteEquipmentOutage();
              setDisableButton(true);
              setAllowUpdate(false);
            }}
            searchTextOnchange={(searchValue) => {
              setSearchValue(searchValue);
              // setSearchText({ temp: searchValue, text: searchText.text });
            }}
            searchValue={searchValue}
            checkOnChange={(check) => {
              setActiveStatus({ temp: check, status: activeStatus.status });
            }}
            startDateChanged={(startDateV) => {
              setStartDate(startDateV);
              // setStartDate({
              //   temp: dayjs(startDateV).format("YYYY-MM-DD"),
              //   index: startDate.index,
              // });
            }}
            endDateChanged={(endDateV) => {
              setEndDate(endDateV);
              // setEndDate({
              //   temp: dayjs(endDateV).format("YYYY-MM-DD"),
              //   index: endDate.index,
              // });
            }}
            handleFind={() => {
              setActiveStatus({
                temp: activeStatus.temp,
                status: activeStatus.temp,
              }); //active status
              setRefreshView((prev) => !prev);
            }}
            handleSwitcher={setWitch}
            handleRefresh={() => {
              setStartDate(startingDate);
              setEndDate(dayjs().format(dbDateFormat));
              setSearchValue(undefined);
              setPagination({ number: 1, size: 20 });
              setRefreshView((prev) => !prev);
            }}
            startDate={startDate}
            endDate={endDate}
          />
          <section className="flex-grow">
            <section className="w-full h-full">
              {status ? (
                <ReadingsChart />
              ) : (
                <EquimentDataTable
                  deselectFirstRow
                  dataId="eotIDpk"
                  handlePageNumber={(page) => {
                    setPagination((state) => ({ ...state, number: page }));
                  }}
                  handlePageSize={(size) => {
                    setPagination((state) => ({ ...state, size }));
                  }}
                  pageNumber={pagination.number}
                  pageSize={pagination.size}
                  gridheight={515}
                  showColumnHeaders={true}
                  columns={equipCols}
                  // data={equipmentOutagesData}
                  data={allEquipments ?? []}
                  disablePaging={false}
                  disableGroupPanel
                  rowDoubleClicked={(data: Record<string, any>) => {
                    recordSwitch.current && setWitch();
                    setRowData(data);
                    hasEditPriviledge(data?.eotIDpk).then((res) => {
                      if (!res) return;
                      dispatch(
                        setGeneralValue({
                          expr: "formDisabled",
                          value: false,
                        })
                      );
                      setAllowUpdate(true);
                      setDisableButton(false);
                    });
                  }}
                  onRowClick={(e) => {
                    // switchStatus && setWitch();

                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: true })
                    );
                    console.log(e);
                    setDisableButton(true);
                    setAllowUpdate(false);
                    setRowData(e);
                    setStates({
                      ...states,
                      startTime: e?.eotStartTime,
                      endTime: e?.eotEndTime,
                      station: { name: e?.stnName, id: e?.stnIDpk },
                      outageType: { name: e?.otpName, id: e?.otpIDpk },
                      equipmentType: { name: e?.oetName, id: e?.oetIDpk },
                      equipment: {
                        name: e?.eotEquipment,
                        id: e?.eotEquipmentDetailIDfk,
                      },
                      details: e?.eotDetails,

                      active: e?.eotActive,
                      remarks: e?.eotRmks,

                      selectedRecordId: e?.eotIDpk,
                    });
                  }}
                />
              )}
            </section>
          </section>
        </section>
      </div>
    </>
  );
};
