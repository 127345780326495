/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Form, Button, TimePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  AnalyzeButton,
  AnalyzeAllButton,
} from "../../../../../../accessories/buttons";
import close from "../../../../../../../assets/close.png";
import dollar_tag from "../../../../../../../assets/dollar_tag.png";
import { Fuel_transactionuploadtoolbar } from "../../../../../../accessories/custom-toolbars/fuel_transactionuploadstoolbar";
import {
  dateFormat2,
  dateFormat3,
  navbar_height,
} from "../../../../../../accessories/component_infos";
import { ContextMenu, DataGrid, Tooltip } from "devextreme-react";
import {
  Pager,
  Editing,
  Column,
  Scrolling,
  Export,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import { transaction_upload_th } from "../data/trasnsaction_upload_data";
import saveSmall from "../../../../../../../assets/save-small.png";
import excel from "../../../../../../../assets/excel.png";
import submit from "../../../../../../../assets/submit.png";
import useFetch from "../../../../../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { DateTemplate } from "../../../../../../../templates/date";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { transactionUpoad_cols } from "../data/datagrid_cols";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { Transaction_Word } from "./transaction_word";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { GetDataFunc } from "../../../../../../../functions/get";
import { DeleteDataFunc } from "../../../../../../../functions/delete";
import { InputsTemplate } from "../../../../../../../templates/input";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  state_change: boolean;
}

interface stateTypes {
  amount1: number;
  amount2: number;
  mileage1: number;
  mileage2: number;
  quantity1: number;
  quantity2: number;
  aboveTC1: number;
  aboveTC2: number;
  balance1: number;
  balance2: number;
  refill1: number;
  refill2: number;
  driverCode1: number;
  driverCode2: number;
  Ecell?: any;
  amountClick?: boolean;
  mileageClick?: boolean;
  quantityClick?: boolean;
  abtcClick?: boolean;
  balClick?: boolean;
  refilClick?: boolean;
  driverClick?: boolean;
  DupClick?: boolean;
  AllClick?: boolean;
  serviceProvider?: any;

  startDate?: string;
  endDate?: string;
  deleteOldRec?: boolean;

  toolbar_drivercode: boolean;
  toolbar_monthcol: boolean;
  excelSelectedRecord: any;
  footergridData: any;
}

export const Transaction_Upload_Details_Form = ({ state_change }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const CancelData = {
    message: "Are you sure you want to set this transaction as Cancelled",
    title: "Cancel Transaction? ",
    okText: "Yes",
    cancelText: "No",
  };
  const ActivateData = {
    message: "Are you sure you want to Activate  this transaction",
    title: "Activate Transaction? ",
    okText: "Yes",
    cancelText: "No",
  };
  const excelSaveModalData = {
    message: "Are you sure you want to set this records",
    title: "Save? ",
    okText: "Yes",
    cancelText: "No",
  };
  const updateCancelModalData = {
    message: "Are you sure you want to Update the canceled records?",
    title: "Update Cancelled Records?",
    okText: "Yes",
    cancelText: "No",
  };
  const updateRefillModalData = {
    message: "Are you sure you want to Update the Refill Interval?",
    title: "Update Refill Interval?",
    okText: "Yes",
    cancelText: "No",
  };

  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(ActivateData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [userId] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const selectRecord = (e: any) => {
    setSelectedRecord(e);
  };

  console.log("test", selectedRecord);

  const showChart = useSelector((state: RootState) => state.general.chart);
  const pickerRef = useRef<any>();
  const [isfixSheet, setIsFixSheet] = useState(false);
  const [firstTable, setFirstTable] = useState<any>([]);
  const formCode = useSelector((state: RootState) => state.general.formCode);

  const [duplicateRecords, setDuplicateRecords] = useState<any>([]);
  const [newRecords, setNewRecords] = useState<any>([]);
  const [Posting, Updating, Patching] = useCrudFunc();

  const [qtyType, setQtyType] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("sum");
  const [qtyAuth, setQtyAuth] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("avg");
  const [qtyAuth1, setQtyAuth1] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("avg");
  const [amountType, setAmountType] = useState<
    "sum" | "avg" | "max" | "min" | "count" | "custom" | undefined
  >("sum");

  function runFunctions(rowObject: any) {
    var newRec: any = [];
    var dupRec: any = [];
    for (let index = 0; index < rowObject.length; index++) {
      const element = rowObject[index];
      if (newRec.includes(element["Reg No."]) === true) {
        dupRec.push(element["Chassis No."]);
      } else {
        newRec.push(element["Reg No."]);
      }
    }

    setDuplicateRecords(dupRec);
    setNewRecords(newRec);
    setFirstTable(rowObject);
  }

  const onFileChangeHandler = (excelFile: any) => {
    if (excelFile) {
      if (!excelFile.name.match(/\.(xlsx|xls|csv|xlsm)$/)) {
        alert("Please Upload Excel File");
      } else {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(excelFile);
        fileReader.onload = (event: any) => {
          let binaryString = event.target.result;
          let workbook = XLSX.read(binaryString, { type: "binary" });
          workbook.SheetNames.forEach((sheet) => {
            let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            runFunctions(rowObject);
          });
        };
      }
    }
  };
  const [formheight, set_form_height] = useState<number>(0);
  const spreadSheetRef = useRef<any>(null);
  const formRef = useRef<any>(null);

  const [states, setStates] = useState<stateTypes>({
    amount1: 0.1,
    amount2: 0.1,
    mileage1: 0.1,
    mileage2: 0.1,
    quantity1: 0.1,
    quantity2: 0.1,
    aboveTC1: 0.1,
    aboveTC2: 0.1,
    balance1: 0.1,
    balance2: 0.1,
    refill1: 0.1,
    refill2: 0.1,
    driverCode1: 0.1,
    driverCode2: 0.1,
    Ecell: {},
    amountClick: false,
    mileageClick: false,
    quantityClick: false,
    abtcClick: false,
    balClick: false,
    refilClick: false,
    driverClick: false,
    DupClick: false,
    AllClick: false,
    serviceProvider: { spdIDpk: 0, name: "", id: "" },
    startDate: "2010-01-01",
    endDate: "2023-01-01",
    deleteOldRec: false,
    toolbar_drivercode: false,
    toolbar_monthcol: false,
    excelSelectedRecord: [],
    footergridData: [],
  });

  const { excelSelectedRecord } = states;

  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const editedDate = dayjs().format();

  const rxtoolbar = useSelector(
    (state: RootState) => state.toolbarDetailsSlice.findData
  );
  const rxLoadFind = useSelector((state: RootState) => state.general.LoadFind);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  if (rxtoolbar !== undefined) {
  } else {
  }

  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 50);
  }, []);

  var [datagridHeight, setDataGridHeight] = useState<number>(0);
  var [timesClicked, setTimesClicked] = useState(0);
  var [intialH, finalHeight] = useResizeMode(timesClicked);

  var intislH = intialH - datagridHeight;
  var [fullPage] = useState(
    finalHeight - (navbar_height + news_flash_height + 100)
  );

  const operateDataGrid = () => {
    if (timesClicked === 0) {
      setDataGridHeight(300);
      setTimesClicked(1);
    } else if (timesClicked === 1) {
      setDataGridHeight(fullPage);
      setTimesClicked(2);
    } else {
      setDataGridHeight(0);
      setTimesClicked(0);
    }
  };

  const [searchCriteriaUrlChange, setSearchCriteriaUrlChange] =
    useState<any>(``);

  const [serviceProviderData, serviceProviderError] = useFetch(
    `FuelTransactionsCardDataUpload/GetServiceProviders`
  );

  const [footerDataGridData] = useFetch(
    searchCriteriaUrlChange,
    refreshValue,
    rxLoadFind
  );
  console.log("footerDataGridData", footerDataGridData);

  const postData = async () => {
    for (let i = 0; i < excelSelectedRecord.length; i++) {
      try {
        await Posting(
          "FuelTransactionsCardDataUpload/Create",
          {
            sptServiceProviderIdfk: states.serviceProvider.spdIDpk ?? 0,
            sptCustomerNo: excelSelectedRecord[i].data.CUSTOMER_NUM ?? "",
            sptCustomer: excelSelectedRecord[i].data.CUSTOMER ?? "",
            sptDate: dayjs(excelSelectedRecord[i].data.Date).format() ?? "",
            sptTime:
              dayjs(excelSelectedRecord[i].data.TIME).format("HH:mm:ss") ?? "",
            sptDateTimex:
              dayjs(excelSelectedRecord[i].data.date).format() ?? "",
            sptDriverCode: excelSelectedRecord[i].data.DRIVER_CODE ?? "",
            sptRegNo: excelSelectedRecord[i].data.REGISTRATION_NUM ?? "",
            sptCardType: excelSelectedRecord[i].data.CARD_TYPE ?? "",
            sptCardNo: excelSelectedRecord[i].data.CARD_NUM ?? "",
            sptCardName: excelSelectedRecord[i].data.CARD_NAME ?? "",
            sptReceiptNo: excelSelectedRecord[i].data.RECEIPT_NUM ?? 0,
            sptPastMileage: excelSelectedRecord[i].data.PAST_MILEAGE ?? "",
            sptCurrentMileage:
              excelSelectedRecord[i].data.CURRENT_MILEAGE ?? "",
            sptOperationType: excelSelectedRecord[i].data.OPERATION_TYPE ?? "",
            sptProductCode: excelSelectedRecord[i].data.PRODUCT_CODE ?? "",
            sptProduct: excelSelectedRecord[i].data.PRODUCT ?? "",
            sptUnitPrice: excelSelectedRecord[i].data.UNIT_PRICE ?? "",
            sptQuantity: excelSelectedRecord[i].data.QUANTITY ?? "",
            sptAmount: excelSelectedRecord[i].data.AMOUNT ?? "",
            sptCurrencyNo: excelSelectedRecord[i].data.CURRENCY_NUM ?? "",
            sptCurrency: excelSelectedRecord[i].data.CURRENCY ?? "",
            sptBalance: excelSelectedRecord[i].data.BALANCE ?? "",
            sptStationNo: excelSelectedRecord[i].data.STATION_NUM ?? "",
            sptPlace: excelSelectedRecord[i].data.PLACE ?? "",
            sptInvoiceDate: excelSelectedRecord[i].data.INVOICE_DATE ?? "",
            sptInvoiceNo: excelSelectedRecord[i].data.INVOICE_NUM ?? "",
          },
          `Created Asset Mgr-Trans-Upload Fuel Transaction Data for Service Provider ID ${
            states.serviceProvider.spdIDpk
          }, Customer No ${
            excelSelectedRecord[i].data.CUSTOMER_NUM
          }, Customer ${excelSelectedRecord[i].data.CUSTOMER}, Date ${dayjs(
            excelSelectedRecord[i].data.Date
          ).format()}, Time ${dayjs(
            excelSelectedRecord[i].data.TIME
          ).format()}, Driver Code ${
            excelSelectedRecord[i].data.DRIVER_CODE
          }, Reg No ${
            excelSelectedRecord[i].data.REGISTRATION_NUM
          }, Card Type ${excelSelectedRecord[i].data.CARD_TYPE}, Card No ${
            excelSelectedRecord[i].data.CARD_NUM
          }, Card Name ${excelSelectedRecord[i].data.CARD_NAME}, Receipt No ${
            excelSelectedRecord[i].data.RECEIPT_NUM
          }, Past Mileage ${
            excelSelectedRecord[i].data.PAST_MILEAGE
          }, Current Mileage ${
            excelSelectedRecord[i].data.CURRENT_MILEAGE
          }, Operation Type ${
            excelSelectedRecord[i].data.OPERATION_TYPE
          }, Product Code ${
            excelSelectedRecord[i].data.PRODUCT_CODE
          }, Product ${excelSelectedRecord[i].data.PRODUCT}, Unit Price ${
            excelSelectedRecord[i].data.UNIT_PRICE
          }, Quantity ${excelSelectedRecord[i].data.QUANTITY}, Amount ${
            excelSelectedRecord[i].data.AMOUNT
          }, Currency No ${
            excelSelectedRecord[i].data.CURRENCY_NUM
          }, Currency ${excelSelectedRecord[i].data.CURRENCY}, Balance ${
            excelSelectedRecord[i].data.BALANCE
          }, Station No ${excelSelectedRecord[i].data.STATION_NUM}, Place ${
            excelSelectedRecord[i].data.PLACE
          }, Invoice Date ${
            excelSelectedRecord[i].data.INVOICE_DATE
          }, Invoice No ${
            excelSelectedRecord[i].data.INVOICE_NUM
          } successfully!`
        );

        setRefreshValue(!refreshValue);

        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Record has been saved successfully!",
          title: "Record Saved!",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response
            ? error.response.data.message
            : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    }
  };

  const updateCancelData = async () => {
    try {
      let count = 0;
      let cancel = "";

      const getAllTrans = await GetDataFunc(
        `FuelTransactionsCardDataUpload/GetAllTransactions`
      );

      for (const item of getAllTrans.data) {
        if (count === 0) {
          cancel = item.sptIDpk;
        } else {
          cancel = cancel + "," + item.sptIDpk;
        }
        count++;
        await Updating(
          `FuelTransactionsCardDataUpload/UpdateTransaction?sptIDpk=${item.sptIDpk}`,
          {},
          ""
        );
        const originalTrans = await GetDataFunc(
          `FuelTransactionsCardDataUpload/GetOrginalTransaction?sptProduct=${item.sptProduct}&sptDriverCode=${item.sptDriverCode}&sptRegNo=${item.sptRegNo}&sptCardNo=${item.sptCardNo}&sptUnitPrice=${item.sptUnitPrice}&sptQuantity=${item.sptQuantity}&sptAmount=${item.sptAmount}&sptPlace=${item.sptPlace}`
        );
        for (const content of originalTrans.data) {
          if (count === 0) {
            cancel = item.sptIDpk;
          } else {
            cancel = cancel + "," + item.sptIDpk;
          }
          count++;
          await Updating(
            `FuelTransactionsCardDataUpload/UpdateTransaction?sptIDpk=${content.sptIDpk}`,
            {},
            ""
          );
        }
      }
      await DeleteDataFunc(
        `FuelTransactionsCardDataUpload/DeleteDuplicateTransaction`
      );

      setRefreshValue(!refreshValue);

      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record has been Updated successfully!",
        title: "Record Updated!",
        okText: "Ok",
      });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: error.response? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const updateRefillData = async () => {
    try {
      await Updating(
        `FuelTransactionsCardDataUpload/UpdateRefillIntervals`,
        {},
        ""
      );
      setRefreshValue(!refreshValue);

      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record has been Updated successfully!",
        title: "Record Updated!",
        okText: "Ok",
      });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: error.response? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const colorChange = (e: any) => {
    if (states.amountClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          const amount = e.data.sptAmount;
          console.log('Checking if amount is a number:', amount); // Debugging
          if (isNaN(Number(amount))) {
            console.log('Amount is not a number, attempting to show modal'); // Debugging
            setModalData({
              message: "Amount must be a number",
              title: "Invalid Input",
              okText: "Ok",
            });
            setOpenModal(true);
          } else {
            const numericAmount = Number.parseFloat(amount);
            if (
              e.column.caption === "AMOUNT" &&
              states.amount1 <= numericAmount &&
              states.amount2 >= numericAmount
            ) {
              e.cellElement.style.backgroundColor = "rgb(250 204 21 / var(--tw-bg-opacity))";
            }
          }
        }
      }
    }

    if (states.mileageClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "MILEAGE" &&
            states.mileage1 <= parseFloat(e.data.sptMileagex) &&
            states.mileage2 >= parseFloat(e.data.sptMileagex)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }
    if (states.quantityClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "QTY" &&
            states.quantity1 <= parseFloat(e.data.sptQuantity) &&
            states.quantity2 >= parseFloat(e.data.sptQuantity)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }

    if (states.abtcClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "TANK CAP." &&
            states.aboveTC1 <= parseFloat(e.data.vdtTankCapacity) &&
            states.aboveTC2 >= parseFloat(e.data.vdtTankCapacity)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }

    if (states.balClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "BALANCE" &&
            states.balance1 <= parseFloat(e.data.sptBalance) &&
            states.balance2 >= parseFloat(e.data.sptBalance)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }
    if (states.refilClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "REFILL INTVL(Hrs)" &&
            states.refill1 == parseInt(e.data.sptRefillIntervalHours)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }

    if (states.driverClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "DRIVER CODE" &&
            states.driverCode1 <= parseFloat(e.data.sptDriverCode) &&
            states.driverCode2 >= parseFloat(e.data.sptDriverCode)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }

    // Revised logic to check for duplicates when DupClick is true
    //     if (states.DupClick) {
    //       if (
    //         footerDataGridData.length > 0 &&
    //         e.rowType === "data" &&
    //         e.column.caption === "RECEIPT No"
    //       ) {
    //         let dup = false; // Initialize dup here to ensure it's always reset when checking
    //         for (let i in footerDataGridData) {
    //           if (footerDataGridData[i].sptReceiptNo === e.data.sptReceiptNo
    // ) {
    //   console.log("eData", footerDataGridData[i].sptReceiptNo, );
    //   console.log("eDataxx", e.data.sptReceiptNo, );
    //             dup = true;
    //             e.cellElement.style.backgroundColor =
    //               "rgb(250 204 21 / var(--tw-bg-opacity))";
    //             break; // Exit the loop once a duplicate is found
    //           }
    //         }
    //       }
    //     }
    if (states.DupClick) {
      if (
        footerDataGridData.length > 0 &&
        e.rowType === "data" &&
        e.column.caption === "RECEIPT No"
      ) {
        let occurrence = 0;
        for (let i: number = 0; i < footerDataGridData.length; i++) {
          if (footerDataGridData[i].sptReceiptNo === e.data.sptReceiptNo) {
            occurrence++;
            if (occurrence >= 2) {
              e.cellElement.style.backgroundColor =
                "rgb(250 204 21 / var(--tw-bg-opacity))";
              break; // Exit the loop once a duplicate is found
            }
          }
        }
      }
    }

    if (states.AllClick) {
      if (footerDataGridData.length > 0) {
        if (e.rowType === "data") {
          if (
            e.column.caption === "DRIVER CODE" &&
            states.driverCode1 <= parseFloat(e.data.sptDriverCode) &&
            states.driverCode2 >= parseFloat(e.data.sptDriverCode)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
        if (e.rowType === "data") {
          if (
            e.column.caption === "REFILL INTVL(Hrs)" &&
            states.refill1 <= parseFloat(e.data.sptRefillIntervalHours) &&
            states.refill2 >= parseFloat(e.data.sptRefillIntervalHours)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
        if (e.rowType === "data") {
          if (
            e.column.caption === "BALANCE" &&
            states.balance1 <= parseFloat(e.data.sptBalance) &&
            states.balance2 >= parseFloat(e.data.sptBalance)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
        if (e.rowType === "data") {
          if (
            e.column.caption === "TANK CAP." &&
            states.aboveTC1 <= parseFloat(e.data.vdtTankCapacity) &&
            states.aboveTC2 >= parseFloat(e.data.vdtTankCapacity)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }

        if (e.rowType === "data") {
          if (
            e.column.caption === "QTY" &&
            states.quantity1 <= parseFloat(e.data.sptQuantity) &&
            states.quantity2 >= parseFloat(e.data.sptQuantity)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }

        if (e.rowType === "data") {
          if (
            e.column.caption === "MILEAGE" &&
            states.mileage1 <= parseFloat(e.data.sptMileagex) &&
            states.mileage2 >= parseFloat(e.data.sptMileagex)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
        if (e.rowType === "data") {
          if (
            e.column.caption === "AMOUNT" &&
            states.amount1 <= parseFloat(e.data.sptAmount) &&
            states.amount2 >= parseFloat(e.data.sptAmount)
          ) {
            e.cellElement.style.backgroundColor =
              "rgb(250 204 21 / var(--tw-bg-opacity))";
          }
        }
      }
    }
  };

  const fieldsToValidate = [
    {
      field: "spdIDpk",
      message: "Please select a service provider",
      title: "Invalid Service Provider",
    },
    {
      field: "CUSTOMER_NUM",
      message: "Customer number is empty",
      title: "Invalid Customer Number",
    },
    {
      field: "CUSTOMER",
      message: "Please Customer name is empty",
      title: "Invalid Customer Name",
    },
    { field: "date", message: "Please select Date", title: "Invalid Date" },
    { field: "TIME", message: "Please select Time ", title: "Invalid Time" },
    {
      field: "DRIVER_CODE",
      message: "Please Driver Code is empty",
      title: "Invalid Driver Code",
    },
    {
      field: "REGISTRATION_NUM",
      message: "Please Registration number is empty",
      title: "Invalid Registration Number",
    },
    {
      field: "CARD_TYPE",
      message: "Please Card Type is empty",
      title: "Invalid Card Type",
    },
    {
      field: "CARD_NUM",
      message: "Please Card Number is empty",
      title: "Invalid Card Number",
    },
    {
      field: "CARD_NAME",
      message: "Please Card Name is empty",
      title: "Invalid Card Number",
    },
    {
      field: "RECEIPT_NUM",
      message: "Please Receipt Number is empty",
      title: "Invalid Receipt Number",
    },
    {
      field: "PAST_MILEAGE",
      message: "Please Past Mileage is empty or must be a number",
      title: "Invalid Past Mileage",
    },
    {
      field: "CURRENT_MILEAGE",
      message: "Please Current Mileage is empty or must be a number",
      title: "Invalid Current Mileage",
    },
    {
      field: "OPERATION_TYPE",
      message: "Please Operation Type is empty",
      title: "Invalid Operation Type",
    },
    {
      field: "PRODUCT_CODE",
      message: "Please Product Code is empty",
      title: "Invalid Product Code",
    },
    {
      field: "PRODUCT",
      message: "Please Product is empty",
      title: "Invalid Product",
    },
    {
      field: "UNIT_PRICE",
      message: "Please Unit Price is empty or must be a number",
      title: "Invalid Unit Price",
    },
    {
      field: "QUANTITY",
      message: "Please Quantity is empty or must be a number",
      title: "Invalid Quantity",
    },
    {
      field: "AMOUNT",
      message: "Please Amount is empty or must be a number",
      title: "Invalid Amount",
    },
    {
      field: "CURRENCY_NUM",
      message: "Please Currency Number is empty",
      title: "Invalid Currency Number",
    },
    {
      field: "CURRENCY",
      message: "Please Currency Number is empty",
      title: "Invalid Currency",
    },
    {
      field: "BALANCE",
      message: "Please Balance is empty or must be a number",
      title: "Invalid Balance",
    },
    {
      field: "STATION_NUM",
      message: "Please Station Number is empty",
      title: "Invalid Station Number",
    },
    {
      field: "PLACE",
      message: "Please Place is empty",
      title: "Invalid Place",
    },
    {
      field: "INVOICE_DATE",
      message: "Please select Invoice Date",
      title: "Invalid Invoice Date",
    },
    {
      field: "INVOICE_NUM",
      message: "Please Invoice Number is empty",
      title: "Invalid Invoice Number",
    },
  ];

  const fieldValues = [
    { text: "Sum" },
    { text: "Min" },
    { text: "Max" },
    { text: "Count" },
    { text: "Avg" },
  ];

  const validateFields = () => {
    for (let i = 0; i < fieldsToValidate.length; i++) {
      const { field, message, title } = fieldsToValidate[i];
      const value =
        field === "spdIDpk"
          ? states?.serviceProvider[field]
          : excelSelectedRecord[0]?.data?.[field];
      const value1 =
        field === "PAST_MILEAGE" ||
        field === "CURRENT_MILEAGE" ||
        field === "UNIT_PRICE" ||
        field === "QUANTITY" ||
        field === "AMOUNT" ||
        field === "BALANCE"
          ? excelSelectedRecord[0]?.data?.[field]
          : false;

      if (!value || value === "") {
        setIcon("warning");
        setshowModalDisableButton(false);
        setModalData({
          message,
          title,
          okText: "Ok",
        });
        setOpenModal(true);
        break;
      }

      if (value1 === 0) {
        setIcon("warning");
        setshowModalDisableButton(false);
        setModalData({
          message,
          title,
          okText: "Ok",
        });
        setOpenModal(true);
        break;
      }
    }
  };

  const ExcelDateVal = () => {
    setIcon("warning");
    if (dayjs(states.startDate).format() > editedDate) {
      setshowModalDisableButton(false);
      setModalData({
        message: "Please the StartDate cannot be greater than today's date",
        title: "Invalid Start Date ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (dayjs(states.endDate).format() > editedDate) {
      setshowModalDisableButton(false);
      setModalData({
        message: "Please the End Date cannot be greater than today's date",
        title: "Invalid End Date ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
    }
  };

  const Cancel = async () => {
    setIcon("question");

    if (selectedRecord.sptActive === true) {
      try {
        await Updating(
          "FuelTransactionsCardDataUpload/CancelOrActivate",
          {
            sptIdpk: selectedRecord !== undefined ? selectedRecord?.sptIDpk : 0,
            sptActive: false,
            sptEditedBy: userId,
            sptEditedDate: editedDate,
          },
          `Updated the fuel transaction status to cancelled for transaction ID ${selectedRecord.sptIDpk} successfully!`
        );

        setRefreshValue(!refreshValue);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Transaction has been Cancelled successfully!",
          title: "Cancellation Successfully",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      setIcon("question");
      Activate();
    }
  };

  const Activate = async () => {
    setIcon("question");

    if (selectedRecord.sptActive === false) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Are you sure you want to Activate  this transaction ",
        title: "Activate Transaction? ",
        okText: "Ok",
      });
      setOpenModal(true);

      try {
        await Updating(
          "FuelTransactionsCardDataUpload/CancelOrActivate",
          {
            sptIdpk: selectedRecord !== undefined ? selectedRecord?.sptIDpk : 0,
            sptActive: true,
            sptEditedBy: userId,
            sptEditedDate: editedDate,
          },
          `Updated the fuel transaction status to activated for transaction ID ${selectedRecord.sptIDpk} successfully!`
        );

        setRefreshValue(!refreshValue);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Transaction has been Activated successfully!",
          title: "Activated Successfully",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      setIcon("question");
      Cancel();
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      setIcon("question");
      setModalData(excelSaveModalData);
      setOpenModal(true);
      ExcelDateVal();
      validateFields();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  const uploadAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      pickerRef!.current!.click();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  const checkAccessPriv = () => {
    setPrivType("read");
    if (readPriv === true) {
      setIsFixSheet(!isfixSheet);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  const updateCancelAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setModalData(updateCancelModalData);
      setOpenModal(true);
      setIcon("question");
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  const updateRefillAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setModalData(updateRefillModalData);
      setOpenModal(true);
      setIcon("question");
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };

  const toolbar_visibleCols: {
    id: number;
    caption: string;
    action: boolean;
  }[] = [
    { id: 0, caption: "DRIVER CODE", action: states.toolbar_drivercode },
    { id: 1, caption: "MONTH", action: states.toolbar_monthcol },
  ];

  return (
    <>
      {serviceProviderError && <WarningAlert />}
      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
        <div>
          <ModalTemplate
            icon={icon}
            cancelText={modalData.cancelText}
            disableCancel={
              icon === "warning" || icon === "success" ? true : false
            }
            cancelHandler={() => {
              setOpenModal(false);
            }}
            open={openModal}
            okHandler={() => {
              modalData.title === ActivateData.title
                ? Activate()
                : modalData.title === CancelData.title
                ? Cancel()
                : modalData.title === excelSaveModalData.title
                ? postData()
                : modalData.title === updateCancelModalData.title
                ? updateCancelData()
                : modalData.title === updateRefillModalData.title
                ? updateRefillData()
                : setOpenModal(false);
            }}
            message={modalData.message}
            okText={modalData.okText}
            title={modalData.title}
          />

          <Fuel_transactionuploadtoolbar
            toggler={() => {
              operateDataGrid();
            }}
            handleDriver_Code={(e) => {
              updateState("toolbar_drivercode", e);
            }}
            handleMonthCol={(e) => {
              updateState("toolbar_monthcol", e);
            }}
            UrlDatax={footerDataGridData}
            handleChart={() => {}}
            handleChange={async (value) => {
              setSearchCriteriaUrlChange(value);
            }}
            importExcel={() => {
              pickerRef!.current?.click();
            }}
            updateCanData={updateCancelAccessPriv}
            updateRefillData={updateRefillAccessPriv}
          />
        </div>

        {timesClicked !== 0 && (
          <>
            {" "}
            <div
              ref={spreadSheetRef}
              style={{ height: "" }}
              className=" px-2 w-full  dark:bg-slate-900 dark:text-darkModeSkin-base"
            >
              <div className="flex">
                <Form
                  size="small"
                  layout="horizontal"
                  name="transaction_spreadSheetForm"
                >
                  <div className="flex ">
                    <SelectsTemplate
                      disabled={false}
                      selectedValue={(e) => {
                        updateState("serviceProvider", e);
                      }}
                      useCallFunc
                      style={{ width: "400px" }}
                      label={"Service Provider"}
                      options={serviceProviderData}
                      idexpr={"id"}
                      dataexp={"name"}
                    />
                    <span className="ml-4"></span>
                    <DateTemplate
                      disabled={false}
                      label="Start Date"
                      changeDate={(value: any) => {
                        updateState("startDate", value);
                      }}
                      selectedDate={dayjs(states.startDate)}
                    />
                    <span className="ml-4"></span>
                    <DateTemplate
                      disabled={false}
                      label="End Date"
                      changeDate={(value: any) => {
                        updateState("endDate", value);
                      }}
                      selectedDate={dayjs(states.endDate)}
                    />
                    <span className="ml-4"></span>
                    <CheckboxTemlate
                      label="Delete Old Data?"
                      withBorders
                      useCallbackFunc
                      customDisabled={false}
                      setCheckboxOnchange={(value: boolean) => {
                        updateState("deleteOldRec", value);
                      }}
                    />
                  </div>
                </Form>
              </div>
              <input
                className="hidden"
                ref={pickerRef}
                type="file"
                onInput={(e: any) => {
                  onFileChangeHandler(e.target.files![0]);
                }}
              />

              <DataGrid
                style={{}}
                dataSource={firstTable}
                showBorders={true}
                columnWidth={150}
                showRowLines={true}
                allowColumnReordering={true}
                onRowClick={(e) => {}}
                onSaved={(e: any) => {
                  updateState("excelSelectedRecord", e.changes);
                }}
                onRowPrepared={function (e) {
                  if (isfixSheet) {
                    try {
                      if (duplicateRecords.includes(e.data["Chassis No"])) {
                        return (e.rowElement.style.backgroundColor = "#EF4444");
                      } else if (
                        e.data["Reg No"] === undefined ||
                        e.data["Reg No"] === ""
                      ) {
                        return (e.rowElement.style.backgroundColor = "#FDE047");
                      } else if (
                        e.data["Vehicle Make"] === undefined ||
                        e.data["Vehicle Make"] === ""
                      ) {
                        return (e.rowElement.style.backgroundColor = "#FDBA74");
                      }

                      return (e.rowElement.style.backgroundColor = "#FDE68A");
                    } catch (error) {

                    }
                  }
                }}
                showColumnLines={true}
                height={datagridHeight}
              >
                <Pager visible={false} />

                <Export enabled={true} />
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                />
                {transaction_upload_th.map((data) => {
                  let dataType = data.dataType as
                    | "string"
                    | "number"
                    | "boolean"
                    | "object"
                    | "date"
                    | "datetime"
                    | undefined;
                  return (
                    <Column
                      key={data.id}
                      caption={data.caption}
                      dataField={data.dataField}
                      dataType={dataType}
                      alignment={"left"}
                      fixed={data.fixed}
                      format={
                        (data.dataType === "date" && dateFormat2) ||
                        (data.dataType === "datetime" && dateFormat3)
                      }
                      width={data.width === null ? 150 : data.width}
                    />
                  );
                })}

                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </div>
            <div className=" my-1 flex justify-between px-1">
              <div>
                {" "}
                <button className="hidden dark:text-darkModeSkin-base flex-row items-center justify-center ml-1   px-2 py-0.5 border border-stone-200 rounded">
                  Duplicate Record
                </button>
              </div>

              <div className="">
                <li
                  id="newRecord"
                  style={{ borderWidth: "1px" }}
                  className="hover:cursor-pointer  mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                  onClick={() => {}}
                >
                  <span
                    onClick={() => {
                      checkAccessPriv();
                    }}
                    className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                  >
                    {" "}
                    <img className="pr-1 " src={submit} />
                    Check Column Headers
                  </span>
                </li>
              </div>
              <div className="flex">
                <li
                  id="newRecord"
                  style={{ borderWidth: "1px" }}
                  className="hover:cursor-pointer  mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                  onClick={() => {}}
                >
                  <span
                    onClick={() => {
                      uploadAccessPriv();
                    }}
                    className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                  >
                    {" "}
                    <img className="pr-1 " src={excel} />
                    Upload{" "}
                  </span>
                </li>
                <li
                  id="newRecord"
                  style={{ borderWidth: "1px" }}
                  className="hover:cursor-pointer  mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                  onClick={() => {}}
                >
                  <span
                    onClick={() => {
                      saveAccessPriv();
                    }}
                    className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                  >
                    {" "}
                    <img className="pr-1 " src={saveSmall} />
                    Save{" "}
                  </span>
                </li>
              </div>
            </div>
          </>
        )}
        {(timesClicked === 0 || timesClicked === 1) && (
          <>
            {showChart === false && (
              <>
                <div
                  ref={formRef}
                  style={{ height: "110px" }}
                  className="gap-x-4 border-b-4 mb-1 pb-1 flex items-center bg-yellow-50"
                >
                  <div
                    style={{ width: "1500px", height: "80px" }}
                    className=" px-2 flex items-center bg-yellow-50"
                  >
                    <Form
                      name="basic"
                      initialValues={{ remember: true }}
                      className="flex w-[1500px] justify-between"
                      autoComplete="off"
                      size={"middle"}
                    >
                      <Form labelCol={{ span: 6 }} className="w-full">
                        <Form.Item label="Amount" name="Amount">
                          <div className="flex  space-x-1 ">
                            <div>
                              <InputsTemplate
                                disabledStatus={false}
                                useCallbackFunc
                                // numberOnly={true}
                                inputStyle={{ width: 80 }}
                                span
                                defaultValue={states.amount1}
                                // className="w-full mr-1"
                                orderOnchange={(e: any) => {
                                  updateState("amount1", e);
                                }}
                              />
                            </div>
                            <div>
                              <InputsTemplate
                                disabledStatus={false}
                                useCallbackFunc
                                // numberOnly={true}
                                span
                                inputStyle={{ width: 80 }}
                                defaultValue={states.amount2}
                                orderOnchange={(e: any) => {
                                  updateState("amount2", e);
                                }}
                              />
                            </div>

                            <AnalyzeButton
                              classname={`mr-1 px-1 py-0.5 h-[34px]`}
                              classname2={`w-[16px]`}
                              label={"amount"}
                              handleAnalysis={() => {
                                updateState("amountClick", !states.amountClick);
                              }}
                            />
                            <Tooltip
                              target="#amount"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Analyse Amount</p>
                            </Tooltip>
                          </div>
                        </Form.Item>
                        <Form.Item label="Mileage" name="Mileage">
                          <div className="flex space-x-1 py-0.5">
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.mileage1}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("mileage1", e);
                              }}
                            />
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.mileage2}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("mileage2", e);
                              }}
                            />
                            <AnalyzeButton
                              classname={`mr-1 px-1 py-0.5 h-[34px]`}
                              classname2={`w-[16px]`}
                              label="mileage"
                              handleAnalysis={() => {
                                updateState(
                                  "mileageClick",
                                  !states.mileageClick
                                );
                              }}
                            />
                            <Tooltip
                              target="#mileage"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Analyse Mileage</p>
                            </Tooltip>
                          </div>
                        </Form.Item>
                      </Form>

                      <Form labelCol={{ span: 8 }} className="w-full">
                        <Form.Item label="Quantity" name="Quantity">
                          <div className="flex space-x-1 py-o.5">
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.quantity1}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("quantity1", e);
                              }}
                            />
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.quantity2}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("quantity2", e);
                              }}
                            />
                            <AnalyzeButton
                              classname={`mr-1 px-1 py-0.5 h-[34px]`}
                              classname2={`w-[16px]`}
                              label="quantity"
                              handleAnalysis={() => {
                                updateState(
                                  "quantityClick",
                                  !states.quantityClick
                                );
                              }}
                            />
                            <Tooltip
                              target="#quantity"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Analyse Quantity</p>
                            </Tooltip>
                          </div>
                        </Form.Item>
                        <Form.Item
                          label="Above Tank Cap."
                          name="Above Tank Cap."
                        >
                          <div className="flex space-x-1 py-0.5">
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.aboveTC1}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("aboveTC1", e);
                              }}
                            />
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.aboveTC2}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("aboveTC2", e);
                              }}
                            />
                            <AnalyzeButton
                              classname={`mr-1 px-1 py-0.5 h-[34px]`}
                              classname2={`w-[16px]`}
                              label="abtc"
                              handleAnalysis={() => {
                                updateState("abtcClick", !states.abtcClick);
                              }}
                            />

                            <Tooltip
                              target="#abtc"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">
                                Analyse Refills Above Vehicle Tank Capacity
                              </p>
                            </Tooltip>
                          </div>
                        </Form.Item>
                      </Form>

                      <Form labelCol={{ span: 7 }} className="w-full ">
                        <Form.Item label="Balance" name="Balance">
                          <div className="flex space-x-1 py-0.5">
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.balance1}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("balance1", e);
                              }}
                            />
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.balance2}
                              inputStyle={{ width: 80 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("balance2", e);
                              }}
                            />
                            <AnalyzeButton
                              classname={`mr-1 px-1 py-0.5 h-[34px]`}
                              classname2={`w-[16px]`}
                              label="bal"
                              handleAnalysis={() => {
                                updateState("balClick", !states.balClick);
                              }}
                            />
                            <Tooltip
                              target="#bal"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Analyse Balance</p>
                            </Tooltip>
                          </div>
                        </Form.Item>
                        <Form.Item
                          label="Refill Intervals"
                          name="Refill Intervals"
                        >
                          <div className="flex space-x-1">
                            <InputsTemplate
                              disabledStatus={false}
                              useCallbackFunc
                              // numberOnly={true}
                              defaultValue={states.refill1}
                              inputStyle={{ width: 163 }}
                              span
                              orderOnchange={(e: any) => {
                                updateState("refill1", e);
                              }}
                            />

                            <AnalyzeButton
                              classname={`mr-1 px-1 py-0.5 h-[34px]`}
                              classname2={`w-[16px]`}
                              label="ref"
                              handleAnalysis={() => {
                                updateState("refilClick", !states.refilClick);
                              }}
                            />
                            <Tooltip
                              target="#ref"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Analyse Refill Intervals</p>
                            </Tooltip>
                          </div>
                        </Form.Item>
                      </Form>

                      <Form labelCol={{ span: 6 }} className="w-full">
                        <Form.Item label="Driver Code" name="Driver Code">
                          <div className="w-full ">
                            <div className="flex mb-0.5 w-full space-x-1">
                              <InputsTemplate
                                disabledStatus={false}
                                useCallbackFunc
                                // numberOnly={true}
                                defaultValue={states.driverCode1}
                                inputStyle={{ width: 100 }}
                                span
                                orderOnchange={(e: any) => {
                                  updateState("driverCode1", e);
                                }}
                              />
                              <InputsTemplate
                                disabledStatus={false}
                                useCallbackFunc
                                // numberOnly={true}
                                defaultValue={states.driverCode2}
                                inputStyle={{ width: 100 }}
                                span
                                orderOnchange={(e: any) => {
                                  updateState("driverCode2", e);
                                }}
                              />
                              <AnalyzeButton
                                classname={`mr-1 px-1 py-0.5 h-[34px]`}
                                classname2={`w-[16px]`}
                                label="dc"
                                handleAnalysis={() => {
                                  updateState(
                                    "driverClick",
                                    !states.driverClick
                                  );
                                }}
                              />
                              <Tooltip
                                target="#dc"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                              >
                                <p className="">Analyse Driver Code</p>
                              </Tooltip>
                            </div>
                            <div className="w-full flex ">
                              <Button
                                onClick={() => {
                                  updateState("DupClick", !states.DupClick);
                                }}
                                style={{
                                  width: "238px",
                                  borderColor: borderTheme,
                                }}
                                className="flex justify-between"
                              >
                                <img src={dollar_tag} className="w-[10%]" />{" "}
                                <p className="flex w-[85%] justify-center items-center">
                                  Duplicate Receipt Numbers
                                </p>
                              </Button>
                            </div>
                          </div>
                        </Form.Item>
                      </Form>

                      <Form className="flex justify-end w-[500px]">
                        <Form.Item>
                          <div className="flex ">
                            <AnalyzeAllButton
                              classname={"mr-0"}
                              label="all"
                              handleAnalysis={() => {
                                updateState("AllClick", !states.AllClick);
                              }}
                            />
                            <Tooltip
                              target="#all"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">Analyse all conditions</p>
                            </Tooltip>
                          </div>

                          <button
                            onClick={(e: any) => {
                              setIcon("question");
                              setModalData(
                                selectedRecord.sptActive === true
                                  ? CancelData
                                  : ActivateData
                              );
                              setOpenModal(true);
                              e.preventDefault(e);
                            }}
                            style={{ borderColor: borderTheme }}
                            className="h[30px] flex flex-row gap-1 items-center justify-center mt-1 px-5 py-0.5 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                          >
                            <img className="pr-1 " src={close} />
                            Cancel
                          </button>
                        </Form.Item>
                      </Form>
                    </Form>
                  </div>
                </div>
              </>
            )}

            {/* Footer gridtable  */}
            <div
              style={{ height: "100%" }}
              className="px-2 w-full  dark:bg-slate-900 dark:text-darkModeSkin-base"
            >
              {showChart === true ? (
                <div style={{ height: 1000 }} className="h-full">
                  <Transaction_Word
                    windowHeight={intislH}
                    state_change={false}
                  />
                </div>
              ) : (
                <>
                  <Datagrid_template
                    gridheight={
                      timesClicked === 0
                        ? intislH - 299
                        : timesClicked === 1 || showChart
                        ? intislH - 370
                        : intislH - 299
                    }
                    visibleCol={toolbar_visibleCols}
                    columns={transactionUpoad_cols}
                    data={footerDataGridData}
                    cellColoringFxn={colorChange}
                    onRowClick={selectRecord}
                    summary={
                      <Summary>
                        <TotalItem
                          cssClass={"one"}
                          column="QTY"
                          summaryType={
                            qtyType as
                              | "sum"
                              | "avg"
                              | "max"
                              | "min"
                              | "count"
                              | "custom"
                          }
                        />

                        <TotalItem
                          cssClass={"two"}
                          column="REFILL INTVL(Hrs)"
                          summaryType={
                            qtyAuth as
                              | "sum"
                              | "avg"
                              | "max"
                              | "min"
                              | "count"
                              | "custom"
                          }
                          valueFormat=""
                        />
                        <TotalItem
                          cssClass={"three"}
                          column="REFILL INTVL(Days)"
                          summaryType={
                            qtyAuth1 as
                              | "sum"
                              | "avg"
                              | "max"
                              | "min"
                              | "count"
                              | "custom"
                          }
                          valueFormat=""
                        />

                        <TotalItem
                          cssClass={"four"}
                          column="AMOUNT"
                          summaryType={
                            amountType as
                              | "sum"
                              | "avg"
                              | "max"
                              | "min"
                              | "count"
                              | "custom"
                          }
                          valueFormat="currency"
                        />
                      </Summary>
                    }
                  />

                  <ContextMenu
                    dataSource={fieldValues}
                    // width={200}
                    target=".one"
                    onSelectedItemChange={(itemClick: any) => {
                      itemClick === "avg"
                        ? setQtyType("avg")
                        : setQtyType(itemClick["itemData"]["text"]);
                    }}
                  />

                  <ContextMenu
                    dataSource={fieldValues}
                    // width={200}
                    target=".two"
                    onSelectedItemChange={(itemClick: any) => {
                      itemClick === "avg"
                        ? setQtyAuth("avg")
                        : setQtyAuth(itemClick["itemData"]["text"]);
                    }}
                  />

                  <ContextMenu
                    dataSource={fieldValues}
                    // width={200}
                    target=".three"
                    onSelectedItemChange={(itemClick: any) => {
                      itemClick === "avg"
                        ? setQtyAuth1("avg")
                        : setQtyAuth1(itemClick["itemData"]["text"]);
                    }}
                  />

                  <ContextMenu
                    dataSource={fieldValues}
                    // width={200}
                    target=".four"
                    onSelectedItemChange={(itemClick: any) => {
                      itemClick === "avg"
                        ? setAmountType("avg")
                        : setAmountType(itemClick["itemData"]["text"]);
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
