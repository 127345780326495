/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useRef, useState } from 'react';
import newIcon from '../../../../../../assets/new.png'
import close from '../../../../../../assets/close.png';
import saveBtn from '../../../../../../assets/save2.png';
import Datagrid_template from "../../../../../../templates/Datagrid";
import { attachement_cols } from '../../../../../Task&Todos/data/datagrid_cols';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { constants } from 'http2';
import { useCrudFunc } from '../../../../../../functions/crud';
import { useAccountInfo } from '../../../../../../hooks/useAccountInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { ModalTemplate } from '../../../../../../templates/modal';
import { trouble_attachment_cols } from '../data/troubleDataSample';
import useFetch from '../../../../../../hooks/useFetch';


export const Right_Trouble_Attachments = ({ fullPage, selectedRecordData,  handleRefresh}: { fullPage?: any, selectedRecordData?:any, handleRefresh: ()=>void   }) => {
   const inputFile = useRef(null) 
const onButtonClick = () => {
  // `current` points to the mounted file input element
  inputFile.current.click();
};

// const [imagePreview, setImagePreview] = useState(null)
const [imageBase64, setImageBase64] = useState(null)
const [attachmentRemarks, setAttachmentRemarks] = useState('')
const [currentImage, setCurrentImage] = useState('')

const handleImageChange = (e) =>{
   const file = e.target.files[0];
   if(file){
      const reader = new FileReader()

      reader.onloadend = () =>{
         console.log('reader',reader.result)
         setImageBase64(reader.result)
         // setImagePreview(reader.result)
      };
      reader.readAsDataURL(file);
   }
}

// {
//    "triReportIDfk": 0,
//    "triImage": "string",
//    "triRmks": "string",
//    "formCode": "string",
//    "formAction": "string"
//  }

const [posting, updating] = useCrudFunc("powerapp");
const confirm = {
   title: "Save Share?",
   message: "Are you sure you want to save this share data?",
   okText: "Yes",
 };
 
 const [icon, setIcon] = useState<"warning" | "success" | "question">();
 const form_disable = useSelector(
   (state: RootState) => state.general.formDisabled
 );
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [refreshState, setRefreshState] = useState<boolean>(false);
 const [refreshPickedImage, setrefreshPickedImage] = useState<boolean>(false);
 const [modalData, setModalData] = useState<any>(confirm);
 const [userId,employeeId,userInfo]=useAccountInfo()

 const [imageDataList, imageDataError, imageDataLoading] = useFetch(
   "TroubleReports/GetImageDetails",
   
   refreshState,
   "triIDpk", true, "powerapp", false, {"TriReportIDpk": selectedRecordData.trpIDpk}
 );
 const [networkImageData, networkImageDataError, networkImageDataLoading] = useFetch(
   "TroubleReports/GetImage",
   
   refreshPickedImage,
   "triIDpk", true, "powerapp", false, {"TriIDpk": currentImage}
 );

 useEffect(()=>{
   if(networkImageData.length > 0){
      console.log('network image', networkImageData)
      let mimeType = 'image/jpeg';
      if(networkImageData[0]?.triImage?.startsWith('iVBORw0KG')){
         mimeType = 'image/png';
      }else if(networkImageData[0]?.triImage?.startsWith('/9j/')){
          mimeType = 'image/jpeg';
      }
      setImageBase64(`data:${mimeType};base64,${networkImageData[0]?.triImage}`)
    }
 },
 [networkImageData])
 
 
 
 const saveShare = async () => {
   try {
     await posting(`TroubleReports/SaveImage`,
      {
            "triReportIDfk": selectedRecordData.trpIDpk,
            "triImage": imageBase64.split(',')[1],
            "triRmks": attachmentRemarks,
          }
     ,`saved attachment sent by ${userInfo.empName}`, 'omgTroubleReports' );
     setIcon("success")
     // setDisableForm(true)
     // updateState("comment",'');
     setModalData({
       message: "Attachment saved successfully",
       title: "Attachment Saved!",
       okText: "Ok",
     });
     handleRefresh();
     setRefreshState(!refreshState);
     setOpenModal(true);
     // dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
   } catch (e: any) {
      console.log('image', e)
     setIcon("warning")
     setModalData({
       message: "Failed to save attachment",
       title: "Failed!",
       okText: "Ok",
     });
     setOpenModal(true);
   }
 };
 
 //save share
 const saveShareData = () => {
   setOpenModal(true);
   setModalData(confirm);
   setIcon("question");
 };
 

   return (
      <div style={{ height: fullPage }} className="w-full ">
           <ModalTemplate
        icon={icon}
        title={modalData.title}
        message={modalData.message}
        okText={modalData.okText}
        disableCancel={icon === "question" ? false : true}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save Share?"
            ? saveShare()
            : setOpenModal(false);
        }}
        cancelHandler={() => {
          setOpenModal(false);
        }}
      />
         <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleImageChange}/>
      
         <ul className="w-full  justify-evenly items-center py-2">
            <div className='mx-3'>Image</div>
            <div className='h-40 flex justify-center items-center w-[310px] border rounded m-2 mr-3'>
               {imageBase64 && <img src={imageBase64} style={{width: '100%', height: '150px', }} />}
            </div>
             {/* Remarks */}
             <div className='mx-3 mt-4'>Image Remarks</div>
              <div className="w-full flex ">
                <div style={{ width: "calc(100%)" }} className="h-[35px] m-2 mr-3">
                  <TextArea
                  //   value={states.troubleRemarks}
                  //   disabled={form_disable}
                    style={{ resize: "none", height: "35px", padding: "4px" }}
                    onChange={(e) => {
                      setAttachmentRemarks( e.target.value);
                    }}
                  />
                </div>
              </div>
           
         </ul>
         <li
            onClick={()=>{  saveShareData();}}
            className="justify-end cursor-pointer items-center flex  m-1"><img src={saveBtn} className=" w-8 p-1 border" /></li>

         <li onClick={onButtonClick} className="justify-end cursor-pointer items-center flex m-1"><img  className=' w-8 p-1 border' src={newIcon} /></li>
            <li onClick={()=>{
                  setImageBase64(null);
                  setAttachmentRemarks('');
                
            }} className="justify-end cursor-pointer items-center flex m-1 "><img className=" w-8 p-1 border" src={close} /></li>
           

         <div className="h-[90%]">
            <Datagrid_template
               gridheight={500}
               disablePaging={true}
               disableGroupPanel={true}
               disableSearch={true}
               columns={trouble_attachment_cols}
               data={imageDataList}
               onRowClick={(e) => {
                  console.log('attackkk', e)
                  setCurrentImage(e.triIDpk);
                  setAttachmentRemarks( e.triRmks);
                  setrefreshPickedImage(!refreshPickedImage);
                }}

            />


         </div>
      </div>
   )
}
