import { Form, Select } from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import reset from "../assets/reset.png";
import settings from "../assets/setting.png";
import { setTier2Value } from "../features/forms/tier2/tier2Slice";
import { setTier2CheckboxValue } from "../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setTier3Value } from "../features/forms/tier3/tier3Slice";
import { setTier4Value } from "../features/forms/tier4/tier4Slice";

interface props {
  index?: number | string;
  label?: string;
  options?: any;
  idexpr?: string;
  dataexp?: string;
  diffExprs?: { idKey: string; valueKey: string }; //use when gridview data value does match selects data keys
  span?: boolean;
  disabled?: boolean | undefined;
  style?: any;
  selectStyle?: any;
  extraWidget?: ReactNode;
  placeHolder?: any;
  selectedValue?: (object: any) => void;
  handleRefresh?: () => void; //refresh click listener
  handleClear?: () => void; // clear the actual state values
  handleSettings?: () => void; // handles settings click
  onScroll?: (e: any) => void; // handles pop scrolls 
  useCallFunc?: boolean;
  labelCol?: number;
  wrapperCol?: number;
  disabledCustomWidth?: boolean;
  cusWidth?: any;
  useCusWidth?: boolean;
  settingsIcon?: boolean;
  settingsIconBefore?: boolean;
  islabelCol?: boolean;
  fullWidth?: boolean;
  register?: any;
  registerName?: string;
  placeholder?: string;
}

export const SelectsTemplate = ({
  label,
  diffExprs,
  index,
  options,
  idexpr,
  fullWidth,
  dataexp,
  span,
  style,
  selectStyle,
  extraWidget,
  disabled = undefined,
  placeHolder,
  handleRefresh,
  selectedValue,
  onScroll,
  useCallFunc,
  wrapperCol,
  disabledCustomWidth = false,
  cusWidth,
  useCusWidth = false,
  settingsIcon,
  settingsIconBefore,
  handleClear,
  handleSettings,
  register,
  registerName,
  placeholder,
}: props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<string>();
  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled mode from general redux slice

  const tier = useSelector((state: RootState) => state.general.tier); // get form tier

  const selectedFieldData = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  useEffect(() => {
    if (useCallFunc) {
      setData(placeHolder);
    } else {
      const expr = !diffExprs?.valueKey ? dataexp! : diffExprs?.valueKey;

      setData(selectedFieldData[expr]); //set selected field data from general slice
      handleSelectOnchange(selectedFieldData, "fromUpdate");
    }
  }, [selectedFieldData, placeHolder]);

  //handle select value dispatch to specific redux slice based on form tier
  const idex = useSelector((state: RootState) => state.general.idExpr)
  const handleSelectOnchange =
    (selected: any, trigger: "fromSave" | "fromUpdate") => {
      let newNameExpr = "";
      let newIdExpr = "";

      if (trigger === "fromUpdate") {
        newNameExpr = !diffExprs?.valueKey ? dataexp! : diffExprs?.valueKey;
        newIdExpr = !diffExprs?.idKey ? idexpr! : diffExprs.idKey;
      } else {
        newNameExpr = dataexp!;
        newIdExpr = idexpr!;
      }

      if (tier == "3") {
        alert(selected[newIdExpr!])
      }

      // form type check to dispatch select value
      tier === "2"
        ? dispatch(
          setTier2Value({ expr: "selectValue", value: selected[newIdExpr!] }) //newIdExpr!
        ) // dispactch to tier2 slice
        : tier === "3"
          ? dispatch(
            setTier3Value({
              expr: `select${index}Value`,
              value: selected[newIdExpr!], //newIdExpr!
            })
          ) // dispactch to tier3 slice
          : tier === "tier2checkbox"
            ? dispatch(
              setTier2CheckboxValue({
                expr: `select${index}`,
                value: { id: selected[newIdExpr!], data: selected[newNameExpr!] },
              })
            )
            : tier === "4"
              ? dispatch(
                setTier4Value({
                  expr: `select${index}Value`,
                  value: selected[newIdExpr!],
                })
              ) // dispactch to tier4 slice
              : console.clear();
    }

  // Correctly memoizing options for the Select component
  const memoizedOptions = useMemo(() => {
    return options?.map((option: any) => {
      const key = option[idexpr!];
      const value = JSON.stringify(option);
      const label = option[dataexp!];

      // Return the Select.Option JSX directly
      return (
        <Select.Option key={key} value={value}>
          {label}
        </Select.Option>
      );
    });
  }, [options]);

  const handleSelectedOnChange = (object: any) => {

    dispatch(
      setTier2CheckboxValue({ expr: `selectedDropDown`, value: object })
    );
    const selected = JSON.parse(object); //parse data
    setData(selected[dataexp!]); //set local state with value
    useCallFunc === true //store selected value
      ? selectedValue!(selected) //to callback
      : handleSelectOnchange(selected, "fromSave"); //to redux
  }

  return (
    <Form.Item
      style={style}
      className=""
      label={
        label && (
          <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
            {label}
          </p>
        )
      }
      wrapperCol={{ span: wrapperCol && wrapperCol }}
    >
      <div className="flex h-full  flex-row items-center">
        <Select
          onPopupScroll={onScroll}
          onChange={handleSelectedOnChange}
          value={data}
          key={useCallFunc ? (placeHolder ? placeHolder : placeholder) : data}
          className={"mb-0"}
          allowClear={false}
          disabled={disabled !== undefined ? disabled : formdisabled}
          style={{
            ...selectStyle,
            maxWidth: `${!disabledCustomWidth
              ? "calc(100% - 28px)"
              : cusWidth || (useCusWidth === true && cusWidth)
              } `,
          }}
          placeholder={data ?? 'Please select an option'}
        >
          {memoizedOptions}
        </Select>

        {settingsIconBefore && (
          <span
            onClick={() => { 
              if (!formdisabled) {
                setData("");
                handleClear && handleClear();
                handleRefresh && handleRefresh();
              }
            }}
            style={{
              height: 22.5,
              marginBottom: 2,
              borderWidth: 1,
              width: 24,
              borderColor: borderTheme,
            }}
            className="ml-0 flex justify-center hover:cursor-pointer border-gray-300 rounded"
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-1 items-center justify-center"
              src={settings}
              alt="reset"
            />
          </span>
        )}

        {!span && (
          <span
            onClick={() => {
              if (!formdisabled) {
                setData("");
                handleClear && handleClear();
                handleRefresh && handleRefresh();
              }
            }}
            style={{
              height: 22.5,
              marginBottom: 2,
              borderWidth: 1,
              width: 26,
              marginLeft: 3,
              borderColor: borderTheme,
            }}
            className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
              src={reset}
              alt="reset"
            />
          </span>
        )}

        {settingsIcon && (
          <span
            onClick={() => { 
              if (!formdisabled) {
                handleSettings && handleSettings();
                handleRefresh && handleRefresh();
              }
            }}
            style={{
              height: 22.5,
              marginBottom: 2,
              borderWidth: 1,
              width: 24,
              borderColor: borderTheme,
            }}
            className="ml-3 flex justify-center hover:cursor-pointer border-gray-300 rounded "
          >
            <img
              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
              src={settings}
              alt="reset"
            />
          </span>
        )}

        {fullWidth && extraWidget}
        {/* {extraWidget} */}
      </div>
    </Form.Item>
  );
};
