/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  navbar_height,
  toolbar_height,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";

/**
 *
 * @param containerRef the ref to the container object you want to listen to it changes
 * @returns The current width of the ref instance or object
 */

export const useWidthResizer = (containerRef: any) => {
  const [width, setWidth] = useState<number | string>("100%"); // Default to 100%

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const newWidth = entries[0].contentRect.width;
        setWidth(newWidth); // Update width based on parent container
      }
    });

    const currentRef = containerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return width;
};


/**
 *
 * @param containerRef the ref to the container object you want to listen to it changes
 * @returns The current height of the ref instance or object
 */

export const useHeightResizerv2 = (containerRef: any) => {
  const [height, setHeight] = useState<number | string>("100%"); // Default to 100%

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const newHeight = entries[0].contentRect.height;
        setHeight(newHeight); // Update height based on parent container
      }
    });

    const currentRef = containerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return height;
};

/**
 *
 * @returns The current height of the ref instance or object
 */

export const useHeightResizer = (): number => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenHeight - heights_out;
};
