import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exprsTypes, modalTypes, searchTextTypes, selectExprsTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { RootState } from '../../../../../../app/store';
import { useBusyLoader } from '../../../../../../hooks/useBusyLoader';
import { setValue, tier1SaveOnclick, tier1UpdateOnclick } from '../../../../../../features/forms/tier1/createSlice';
import { setGeneralValue } from '../../../../../../features/generalSlice';
import { deleteModalData, postError, putError, saveModalData, updateModalData, validationsData } from '../../../../../accessories/component_infos';
import useFetch from '../../../../../../hooks/useFetch';
import { WarningAlert } from '../../../../../accessories/warningAlert';
import { ModalTemplate } from '../../../../../../templates/modal';
import Setups_Template from '../../../../../human-resource/setups/.setups_templates/Setups_Template';
import { assets_computers_accuracyClassesSetups_columns } from './data/accuracyClassesData';
import dayjs from 'dayjs';
import { useCrudFunc } from '../../../../../../functions/crud';
const Assets_power_setups_accuracyClass = React.forwardRef(({a}:any, ref)=>{
    const [searchText, setSearchText] = useState<searchTextTypes>({temp: '', text: ''});

    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const [status, setStatus] = useState<boolean | string>('');
    
    const fetchUrl:string = `AssetMgrTamAccuracyClass/GetAll?searchText=${searchText.text}&isActive=${status}`;
    const postUrl:string = 'AssetMgrTamAccuracyClass/create';
    const updateUrl:string = 'AssetMgrTamAccuracyClass/update';

    const saveSuccessText = {message : 'Accuracy class added Successfully',ok : 'Ok' , title : 'Save Succesful'} // successful save modal message
    const updateSuccessText = {message : 'Accuracy class updated Successfully',ok : 'Ok' , title : 'Update Successful'} // successful update modal message

    const[busyLoader,setBusyLoader] = useBusyLoader();
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    // store data expressions[unique]
    const idExpr:String = 'aclIdpk';
    const nameExpr:string = 'aclName';
    const shortnameExpr:string = 'aclShtName';
    const orderExpr: string = 'aclOrder';
    const activeExpr: string = 'aclActive';
    const remarksExpr: string = 'aclRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }
    const selectsExpr:selectExprsTypes[] = [{idExpr : 'prdIdpk', nameExpr : 'prdName',label :'Name'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'}]

    const storeExprs = () =>{
        const exprs:exprsTypes[] = [{expr:'existsUrl',value:`AssetMgrTamAccuracyClass`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr},{expr: 'selectExprs' ,value : selectsExpr[0]}]
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'selectExprs' ,value : selectsExpr},{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }


    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier1Add.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier1Add.successText); //success modal text

    //--for backend errors--
    const[err,setErr] = useState<{state:boolean,title:string,message:string}>({
        state:false,
        title:'',
        message:''
    });

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier1Add.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier1Add.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier1Add.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier1Add.updateDataOnclick); //trigger update question

    //--Update data modal--
    const deleteDataInfos = deleteModalData; //update modal details[data]
    const triggerdeleteModal:boolean = useSelector((state:RootState)=>state.tier1Add.deleteDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const name = useSelector((state:RootState)=>state.tier1Add.name);
    const shortname = useSelector((state:RootState)=>state.tier1Add.shortname);
    const order = useSelector((state:RootState)=>state.tier1Add.order);
    const active = useSelector((state:RootState)=>state.tier1Add.active);
    const remarks = useSelector((state:RootState)=>state.tier1Add.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : err.state, okHandler : ()=> setErr((prev)=>({...prev,state:false})), cancelHandler : ()=> setErr((prev)=>({...prev,state:false})), message : err.message, cancelText : '', okText : 'Ok', title : err.title},
        //success
        {disableCancel : true, icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    const [Posting, Updating, Patching] = useCrudFunc();


    //post form Data
    //validate data
    const validateDataSave = () =>{        
        dispatch(tier1SaveOnclick());
    }
    // post
    const postData = async() =>{
        dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        
        try {
            setBusyLoader('Saving record..')
            const res = await Posting(`${postUrl}`,{
                aclName: name,
                aclShtName: shortname,
                aclActive: active,
                aclOrder:order,
                aclRmks: remarks,
                aclCreatedBy: 1,
            })
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }

            //disable form
            dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));

        } catch (error:any) {
            setBusyLoader('')

            //set states for db errors modal
            setErr({
                state : true,
                title : postError.title,
                message:postError.message
            })
        }
    }


    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier1UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        dispatch(setValue({expr: 'updateDataOnclick' ,value : false})) //disable modal
        
        try {
            setBusyLoader('Updating record...')
            const res = Updating(`${updateUrl}/${selectedID}`,{
                aclIdpk: selectedID,
                aclName: name,
                aclShtName: shortname,
                aclActive: active,
                aclOrder:order,
                aclRmks: remarks,
                aclEditedBy: 1,
                aclEditedDate: dayjs(new Date()).format(),
            })
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            //disable update mode
            dispatch(setGeneralValue({expr : 'updateMode', value : false})); // dispatch fetched data to reduxstore

            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick',value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {           
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErr({
                state : true,
                title : putError.title,
                message: putError.message
            })
        }
    }

    // data for datagrid
    const [data,error,loading] = useFetch(fetchUrl, refresh,'aclIdpk');
    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }

        loading ? setBusyLoader('.') : setBusyLoader('');
    },[data,loading])

    // form parameters
    const form_header = 'Accuracy Class Details';

    return (
        <>
            {error&&(
                <WarningAlert/>
            )}

            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}

            <Setups_Template
                searchTextChange={(newText)=> {
                    setSearchText({temp:newText, text:searchText.text})
                    
                }}
                handleSearch={()=> {
                    setSearchText({text: searchText.temp, temp: searchText.temp})
                    setStatus(activeStatus)
                }}
                createData={validateDataSave}
                updateData = {validateDataUpdate} 
                datagridClicked={()=>{}}
                trigger = {trigger} 
                datagridColumns={assets_computers_accuracyClassesSetups_columns} 
                datagridData = {data} 
                formHeader={ form_header}
            />
            
        </>
    );
})

export default Assets_power_setups_accuracyClass