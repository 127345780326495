import { useCallback,useRef, useState } from "react";
import { Form, Modal } from "antd";
import { useDispatch } from "react-redux"; 
import { CheckboxTemlate } from "../../../../templates/checkbox";
import settings from "../../../../assets/refresh.png";
import { UpdateButton } from "../../../accessories/buttons";
import useFetch from "../../../../hooks/useFetch";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../templates/modal";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { useCrudFunc } from "../../../../functions/crud"; 
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

interface props {
  open: boolean;
  handleCancel: () => void;
}

export const Update_Shift_Members_Modal = ({ open, handleCancel }: props) => {
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [BusyLoader, setBusyLoader] = useBusyLoader();
  const [ModalObj, setModalObj] = useState<{
    title: string;
    icon: "warning" | "success" | "question";
    okHandler: () => void;
    okText: string;
    cancelHandler: () => void;
    cancelText: string;
    open: boolean;
    disableCancel: boolean;
    message: string;
  }>({
    title: "",
    icon: "success",
    okHandler: () => {},
    okText: "",
    cancelHandler: () => {},
    cancelText: "",
    open: false,
    disableCancel: false,
    message: "",
  });
  const [members, setMembers] = useState<{
    message: string | null | undefined;
    data: { name: string }[];
    code: number | string;
  }>();
  const [postData, updateData] = useCrudFunc("powerapp");
  const [userId, employID, userObj] = useAccountInfo();
  const homeStationId  = useSelector((state:RootState)=> state.general.homeStation)
  const [refresh, setRefresh] = useState(false);
  const [GetCurrentShiftMembers, error, loading] = useFetch(
      `UpdateCurrentShiftMembers/GetCurrentShiftMembers?empName1=${userObj?.empName1}&empStaffNo=${userObj?.empStaffno}&slbHomeStationId=${homeStationId}`,
      refresh,'',false,'powerapp'
    );
    // const [
    //   currentShiftMembersData,
    //   currentShiftMembersDataPending,
    //   currentShiftMembersDataError,
    // ] = useFetch(
    //   `UpdateCurrentShiftMembers/GetCurrentShiftMembers?empName1=kwabena&empStaffNo=2001`,
    //   "",
    //   "",
    //   false,
    //   "powerapp"
    // );
    const [selectedMembers, setSelectedMembers] = useState([
      ...GetCurrentShiftMembers,
    ]); 

  const SelectMember = useCallback(
    (name: string, value: boolean) => {
      value
        ? setSelectedMembers((record) => [...record, name])
        : setSelectedMembers((record) =>
            record.filter((item) => item !== name)
          );
    },
    [members]
  );
  const closeConfirmModalAndOpenSuccessModal = (
    code: Number,
    message: string
  ) => {
    setModalObj((state) => ({ ...state, open: false }));
    setBusyLoader("");
    setModalObj((state) => ({
      ...state,
      title: code === 200 ? "Success" : "Failed",
      icon: code === 200 ? "success" : "warning",
      open: true,
      message: message,
      okHandler: async () => {
        setModalObj((state) => ({ ...state, open: false }));
      },
      disableCancel: true,
      okText: "Ok",
      cancelText: "",
      cancelHandler: () => {},
    }));
  };
  const openWarningModal = (code: number, message: string) => {
    setBusyLoader("");
    setModalObj((state) => ({ ...state, open: false }));
    setModalObj((state) => ({
      ...state,
      title: "Warning!!",
      icon: "warning",
      open: true,
      message:
        (code === 500 && message) ??
        "Failed to update. Please contact the systems administrator",
      okHandler: async () => {
        setModalObj((state) => ({ ...state, open: false }));
      },
      disableCancel: true,
      okText: "Ok",
      cancelText: "",
      cancelHandler: () => {},
    }));
  };
  const responseFromUpdate = async () => {
    setBusyLoader("Updating current shift members");
    await updateData(
      `UpdateCurrentShiftMembers/UpdateCurrentShiftMembers?smbLogStationIDfk=${homeStationId}&smbOnDuty=${false}`, // Remember to remove hard coded value in the endpoint
      {
        shiftMembers: selectedMembers,
      }
    )
      .then((res) => {
        if (
          res.status >= 200 ||
          (res.status <= 299 && res.data?.code === 200)
        ) {
          closeConfirmModalAndOpenSuccessModal(
            res.data?.code,
            res.data?.message
          );
        }
      })
      .catch((err) => {
        openWarningModal(err.data?.code, err.data?.message);
      });
  };

  const handleUpdate = () => {
    setModalObj((state) => ({
      ...state,
      title: "Update current shift members ?",
      icon: "question",
      open: true,
      okHandler: () => {
        responseFromUpdate();
      },
      okText: "yes update",
      disableCancel: false,
      message: "Are you sure you want to update current shift members ?",
      cancelHandler: () => {
        setModalObj((state) => ({ ...state, open: false }));
      },
      cancelText: "cancel",
    }));
  };

  return (
    <>
      {/* {refresh && <CustomLoader text="Refreshing shift members..."/>} */}
      <ModalTemplate
        title={ModalObj.title}
        icon={ModalObj.icon}
        okHandler={ModalObj.okHandler}
        okText={ModalObj.okText}
        cancelHandler={ModalObj.cancelHandler}
        cancelText={ModalObj.cancelText}
        disableCancel={ModalObj.disableCancel}
        message={ModalObj.message}
        open={ModalObj.open}
      />
      <Modal
        maskClosable={false}
        width={600}
        footer=""
        centered
        style={{
          border: "1px solid rgb(59 130 246)",
          borderRadius: 9,
          paddingBottom: 0,
        }}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            eLog Book - Update Shift Members
          </div>
        }
        open={open}
        onOk={() => {}}
        transitionName=""
        onCancel={() => {
          handleCancel();
        }}
      >
        <>
          <div className=" bg-slate-200 font-semibold px-2 py-1 rounded-sm flex justify-between">
            <p>Select the Current Shift Members</p>

            <span
              onClick={() => {
                setRefresh(!refresh)
              }}
              style={{
                height: 22.5,
                marginBottom: 2,
                borderWidth: 1,
                width: 24,
              }}
              className="ml-3 flex justify-center hover:cursor-pointer rounded "
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={settings}
                onClick={()=>{
                  setRefresh(!refresh)
                }}
                alt="reset"
              />
            </span>
          </div>
          <main className="">
            {/* right form */}
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              className=" h-[300px] px-2 border border-slate-200 overflow-y-scroll"
              size="small"
            >
              <div className="  py-2 rounded-b-md ">
                {GetCurrentShiftMembers?.map(({ name }, index) => (
                  <div className="flex mb-1.5" key={index}>
                    <span className="mr-2">
                      <CheckboxTemlate
                        useCallbackFunc
                        customDisabled={false}
                        withBorders
                        setCheckboxOnchange={(checkboxValue) => {
                          SelectMember(name, checkboxValue);
                        }}
                      />
                    </span>
                    <span>
                      <p>{name}</p>
                    </span>
                  </div>
                ))}
              </div>
            </Form>

            <div className=" mt-3 flex flex-row justify-end">
              <UpdateButton
                disableButton={false}
                disabled_status={false}
                handleUpdate={handleUpdate}
              />
            </div>
          </main>
        </>
      </Modal>
    </>
  );
};
