/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { setGeneralValue } from '../../../../features/generalSlice';
import useFetch from '../../../../hooks/useFetch';
import Datagrid_template from '../../../../templates/Datagrid';
import { navbar_height, toolbar_height } from '../../../accessories/component_infos';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';

import { searchTextTypes, statusTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { RootState } from '../../../../app/store';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { PerformanceSetups_periods_columns } from '../../../Performance/setups/periods/data/periodsData';
import { Periods_form } from '../../../Performance/setups/periods/widgets/periodsForm';
import { Bus_form } from './busDetailsForm';
import { Bus_details_column } from './data/busData';
import { ModalTemplate } from '../../../../templates/modal';



export const BusDetails = forwardRef(({ a }: any, ref) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);

    // on page resize height responsiveness
    const dispatch = useDispatch();

    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showForm, setShowForm] = useState(true);
  

    //miscellaneous heights
    const miscHeight = 7;

    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height, set_form_height] = useState<number>(216);
    const pass_form_height = (height: number) => {
        set_form_height(height)
    }

    // Page resize height responsiveness
    useEffect(() => {
        settableHeight(
            window.innerHeight - (form_height+ heights_out + 3)
          );
    }, [])

    // handle interface switch
    const toggle_staff_form = (): void => {
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ?
        // settableHeight(
        //     window.innerHeight - (heights_out + miscHeight - 4)
        //   )
        settableHeight(
            window.innerHeight - (form_height+ heights_out + 3)
          )
            // navigate({
            //     pathname: setupPageRoute,
            //     search: createSearchParams({
            //         view: datagridOnlySearchParam,
            //     }).toString(),
            // })
            :

            settableHeight(
            window.innerHeight - (heights_out + miscHeight - 4)
          )
            // navigate({
            //     pathname: setupPageRoute,
            //     search: createSearchParams({
            //         view: formModeSearchParam,
            //     }).toString(),
            // });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

    const [refresh, setrefresh] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState({});
    const [busyLoader, setBusyLoader] = useBusyLoader();

    const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' })
    const [status, setStatus] = useState<statusTypes>({ temp: '', status: '' })
    const [pageSize, setPageSize] = useState(20)
    //gridview data
    const [data, error, loading] = useFetch(`BusDetails/bus_details`, refresh, 'bdtIDpk', false, "powerapp",false,  {
        ...(searchText.text !== '' &&  {  "SearchTerm":searchText.text}),
      ...(status.status !== '' &&  { "CheckState": status.status}),
      ...(  { "PageSize": pageSize})
       
   });

   const childRef = useRef<any>();
    
    
    useEffect(() => {
        loading ? setBusyLoader('') : setBusyLoader('');
    }, [loading]);

    const [validationModalState, setvalidationModalState] = useState<any>({
        state: false,
        title: '',
        message: '',
      });
      const setValidationModal = (state: string, value: string | boolean) => {
        setvalidationModalState((warningState: any) => ({ ...warningState, [state]: value }));
      }

    return (
        <>
            {error && (
                <WarningAlert />
            )}
    
    <ModalTemplate
        disableCancel={true}
        open={validationModalState.state}
        okHandler={() => setValidationModal("state", false)}
        cancelHandler={() => setValidationModal("state", false)}
        message={validationModalState.message}
        okText={"Ok"}
        title={validationModalState.title}
      />  

            <div style={{ height: pageHeight }} className="w-full">
                <div className="px-2">
                    <HrSetupsCustomToolbar
                        searchTextOnchange={(newText) => {
                            setSearchText({ temp: newText, text: searchText.text })
                        }}
                        checkOnChange={(newStatus) => {
                            setStatus({ temp: newStatus, status: status.status })
                        }}
                        handleFind={() => {
                            setSearchText({ temp: searchText.temp, text: searchText.temp });
                            setStatus({ temp: status.temp, status: status.temp })
                            setrefresh(current => !current)
                        }}
                        toggler={()=>{
                            setShowForm(!showForm)
                            if(showForm){
                            settableHeight(
                              window.innerHeight - ( heights_out + 3)
                            );
                          }else{
                            settableHeight(
                              window.innerHeight - (form_height+ heights_out + 3)
                            );
                          }
               
                        }}
                        handleUpdate={()=>{
                            setShowForm(true)
                            settableHeight(
                                window.innerHeight - (form_height+ heights_out + 3)
                              );
                        }}
                       
                        outerSelected={selectedData} 
                        handleNew={() =>{ setSelectedData({})
                        setShowForm(true)
                        settableHeight(
                            window.innerHeight - (form_height+ heights_out + 3)
                          );
                        if (childRef.current) {
                            childRef.current.topNavNew();
                          }
                    }} 
                        withDates={false}
                        handleRefresh={()=>{
                            setSearchText({ temp: "", text: "" })
                               setStatus({ temp: "", status: "" })
                               setrefresh(current => !current)
                         
                        }}
                        />
                </div>

                {/* form section */}
                
                   { <section
                    style={{ height:showForm ?  form_height: 0, 
                        display:showForm ?  "block": "none"
                        
                                       }}
                     className="form border-b-4">
                        <Bus_form
                            clearSelected={() => setSelectedData({})}
                            data={data??[]}
                            selectedField={selectedData}
                            busyloader={(text) => setBusyLoader(text ?? '')}
                            refreshGrid={() => {
                                console.log('refreshing')
                                setrefresh(!refresh)
                            }}
                            ref={childRef}
            
                        />
                    </section>}

                {/* datagrid section */}
                <section className="mt-1 px-2">

                    <Datagrid_template
                        gridheight={
                            tableHeight}
                        data={data??[]}
                        onRowClick={(selected) => {
                            setSelectedData(selected??[]);
                            //disable Update Mode
                            dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

                            //disable forms
                            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
                        }}
                        handlePageSize={(size)=>{
                            setPageSize(size)
                              setrefresh(current => !current)
                        }}
                        rowDoubleClicked={() => {
                            //disable Update Mode
                            dispatch(setGeneralValue({ expr: 'updateMode', value: true }));

                            //disable forms
                            dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
                                setShowForm(true)
                            settableHeight(
                                window.innerHeight - (form_height+ heights_out + 3)
                              );

                            // navigate({
                            //     pathname: setupPageRoute,
                            //     search: createSearchParams({
                            //         view: formModeSearchParam,
                            //     }).toString(),
                            // });
                        }}
                        columns={Bus_details_column}
                    />
                </section>
            </div>
        </>
    );
})