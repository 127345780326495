import { Button, Form, Input, Select } from "antd";
import help from "../../assets/helpothers.png";
import check from "../../assets/check-mark.png";
import {
  modalPropTypes,
  selectObjectTypes,
  selectsTypes,
} from "../../components/human-resource/setups/data/types/selectsTypes";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../features/generalSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SplashScreen } from "./splashScreen";
import dayjs, { Dayjs } from "dayjs";
import { IoClose, IoPerson } from "react-icons/io5";
import { AiFillLock } from "react-icons/ai";
import { initiateUserData } from "../../features/user";
import { ModalTemplate } from "../../templates/modal";
import ResetPasswordModal from "./widgets/resetPasswordModal";
import { HelpModal } from "./widgets/helpModal";
import {
  passwordResetRequestFunc,
  passwordResetRequestNewFunc,
  resetModalResponses,
} from "./functions/passwordResetRequest";
import RequestPasswordResetModal from "./widgets/requestPasswordResetModal";
import { userDataCrypt } from "../../functions/userDataEncrypt";
import logo from "../../assets/InnorikLogo.jpg";
import { PostDataFuncNoAuth } from "../../functions/post";
import axios from "axios";
import { api_url } from "../../components/accessories/component_infos";
import { RootState } from "../../app/store";
import { InputsTemplate } from "../../templates/input";

interface stateTypes {
  username: string;
  password: string;
  passwordBackup: string;
  server: string;
  companyName: string;
  footerLine1: string;
  footerLine2: string;
  splashScreen: boolean;
  selectOptions: selectsTypes[];
  errorDialogue: modalPropTypes;
  loginLoading: boolean;
  modalAlert: modalPropTypes;
  helpCheck: boolean;
  resetPasswordModal: boolean;
  requestPasswordResetModal: boolean;
}

interface smsDataType {
  usaIDpk: number;
  empIDpk: number;
  empMobNox: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const serverOptions = [
    { id: 0, label: "Production" },
    { id: 1, label: "Local" },
    { id: 2, label: "Cloud" },
  ];

  const token = useSelector((state: RootState) => state.user?.user?.token); //token
  const borderBgTheme = useSelector(
    (state: RootState) => state.general.cssBorderBg
  );
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    // if (!token) {
    //   navigate("/");
    //   // remove makeAlert sessionCookie
    //   sessionStorage.removeItem("client");
    //   sessionStorage.removeItem("makeAlert");
    // }

    //setting the lockStatus to false
    sessionStorage.setItem("lockStatus", "false");
    sessionStorage.setItem("tabs", JSON.stringify([]));
  }, [refresh]);

  //component states
  const [states, setState] = useState<stateTypes>({
    username: "",
    password: "",
    passwordBackup: "",
    server: serverOptions[0].label,
    companyName: "innoX",
    footerLine1: `Copyright ${dayjs().year()} Innox`,
    footerLine2: "Innorik. All rights reserved",
    selectOptions: serverOptions,
    splashScreen: true,
    errorDialogue: { state: false, title: "", message: "" },
    loginLoading: false,
    modalAlert: { state: false, title: "", message: "" },
    helpCheck: false,
    resetPasswordModal: false,
    requestPasswordResetModal: false,
  });

  const smsData = useRef<smsDataType>({
    usaIDpk: 0,
    empIDpk: 0,
    empMobNox: "",
  });
  // const {
  //   companyName,
  //   passwordBackup,
  //   username,
  //   password,
  //   resetPasswordModal,
  //   requestPasswordResetModal,
  //   helpCheck,
  //   modalAlert,
  //   errorDialogue,
  //   loginLoading,
  //   server,
  //   footerLine1,
  //   footerLine2,
  //   splashScreen,
  //   selectOptions,
  // } = states;

  //handle state update
  const updateState = (
    stateName: string,
    value:
      | string
      | boolean
      | number
      | modalPropTypes
      | selectObjectTypes
      | Dayjs
      | Object
  ) => {
    setState((currentState: any) => ({ ...currentState, [stateName]: value }));
  };

  // set update mode & disable splash
  useEffect(() => {
    // dispatch(setGeneralValue({ expr: "loginMode", value: true }));
    // dispatch(setGeneralValue({expr:'serverLogin', value: server}));
    sessionStorage.setItem("serverType", states.server);
    setTimeout(() => {
      updateState("splashScreen", false);
    }, 3250);
  }, []);

  // password expiration check
  const resetPassword = (empChangePassword: boolean): boolean => {
    updateState("loginLoading", false); // disable loader
    if (empChangePassword === undefined) {
      updateState("modalAlert", {
        state: true,
        title: "Login Failed",
        message: "Login failed. Please retry or contact system administrator.",
        icon: "warning",
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });

      //reset fields
      updateState("username", "");
      updateState("password", "");

      return true;
    }

    if (empChangePassword === true) {
      updateState("modalAlert", {
        state: true,
        icon: "question",
        title: "Reset Password",
        message: "Password expired. Do you want to reset now?",
        func() {
          updateState("modalAlert", {
            state: false,
            title: "",
            message: "",
          }); // disabe alert modal

          //enable reset modal
          updateState("resetPasswordModal", true);
        },
      });
      return true;
    }
    return false;
  };

  //validate fields
  const validate = (): boolean => {
    if (states.username.trim() === "") {
      updateState("modalAlert", {
        state: true,
        message: "Please enter a valid username",
        title: "Enter Username!",
        icon: "warning",
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });

      return false;
    } else if (states.password.trim() === "") {
      updateState("modalAlert", {
        state: true,
        message: "Please enter a valid password",
        title: "Enter Password!",
        icon: "warning",
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });

      return false;
    } else if (!states.server) {
      updateState("modalAlert", {
        state: true,
        message: "Please select server",
        title: "Select Server",
        icon: "warning",
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });

      return false;
    }
    return true;
  };
  //handle login
  const handleLogin = () => {
    dispatch(setGeneralValue({ expr: 'serverLogin', value: states.server }));
    // **************************************************************
    // for navigating into app with servertemp
    // navigate('/welcome'); //route
    // sessionStorage.setItem('makeAlert', 'true')
    // dispatch(setGeneralValue({expr:'loginMode' ,value : false})); //disable update mode
    // updateState('loginLoading',false); // disable loader
    // sessionStorage.setItem('serverType',server )
    // return;
    setRefresh(!refresh)

    sessionStorage.setItem("serverType", states.server);
    const getUser = async () => {
      //set loader
      updateState("loginLoading", true);
      try {
        //make request
        const loginResponse = await PostDataFuncNoAuth(`Accounts/Login`, {
          username: states.username,
          password: states.password,
          server: states.server.toLowerCase(),
        });

        //success
        if (loginResponse?.data) {
          // get user change password status
          const changePassword =
            loginResponse?.data?.userModel?.usaChangePassword;

          //set data to cookie
          // localStorage.setItem('client',userDataCrypt('encrypt',JSON.stringify(loginResponse?.data?.data[0]))!);
          // cookie.set('client',userDataCrypt('encrypt',JSON.stringify(loginResponse?.data?.data[0]))!);
          sessionStorage.setItem(
            "client",
            userDataCrypt("encrypt", JSON.stringify(loginResponse?.data))!
          );

          dispatch(initiateUserData()); //initiate user data get from cookie

          if (!resetPassword(changePassword)) {
            navigate("/welcome"); //route
            sessionStorage.setItem("makeAlert", "true");
            dispatch(setGeneralValue({ expr: "loginMode", value: true })); //disable update mode
            updateState("loginLoading", false); // disable loader
            // return;
          }
          // return;
        }
        //reset fields
        updateState("username", "");
        updateState("password", "");
      } catch (error: any) {
        console.log(error);

        updateState("loginLoading", false); // disable loader

        //handle error
        if (error?.response?.status === 400) {
          // updateState("password", "");
          // updateState("username", "");

          updateState("modalAlert", {
            //wrong credentials modal
            state: true,
            message: "Invalid username or password",
            title: "Wrong Credentials",
            icon: "warning",
            func() {
              updateState("modalAlert", { ...states.modalAlert, state: false });
            },
          });
          return;
        }

        updateState("modalAlert", {
          state: true,
          message:
            "Error connecting to server. Please try again or contact System Administrator",
          title: "Server Error",
          icon: "warning",
          func() {
            updateState("modalAlert", { ...states.modalAlert, state: false });
          },
        });
      }
    };

    getUser(); //avoid multiple request if data not changed
  };

  const systemSendMail = async () => {
    try {
      const res = await axios.get(
        `${api_url}/Accounts/GetAllowSendingSmsStatus`
      );
      return res.data[0]?.stlValue;
    } catch (e) {
      console.log(e);
    }
  };

  const getSmsData = async () => {
    try {
      const res = await axios.get(
        `${api_url}/Accounts/GetUserByNameOrStaffNo`,
        { params: { userNameOrstaffNo: states.username } }
      );
      console.log("getSmsData", res.data[0]);
      if (res.data.length !== 0) {
        smsData.current = res.data[0];
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
    }
  };

  const checkPasswordCap = async () => {
    try {
      const res = await axios.get(
        `${api_url}/Accounts/PasswordResetLimitCheck`,
        { params: { empIDpk: smsData.current.empIDpk } }
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const passwordResetCheck = async () => {
    //First condition for checking sms status completed
    if (!states.username || states.username.trim() === "") {
      updateState("modalAlert", {
        ...resetModalResponses[1],
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });
      return;
    }

    if ((await systemSendMail()) !== "1") {
      updateState("modalAlert", {
        ...resetModalResponses[0],
      });
      return;
    }

    if (!(await getSmsData())) {
      updateState("modalAlert", {
        ...resetModalResponses[2],
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });
    }

    if ((await systemSendMail()) !== "1") {
      updateState("modalAlert", {
        ...resetModalResponses[3],
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });
      return;
    }

    if (await checkPasswordCap()) {
      updateState("modalAlert", {
        ...resetModalResponses[4],
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });
      console.log("smsData", smsData.current);
      return;
    }

    updateState("modalAlert", {
      ...resetModalResponses[5],
      func() {
        handlePasswordResetRequest();
      },
    });
  };

  const handlePasswordResetRequest = async () => {
    const reset = await passwordResetRequestNewFunc(smsData.current);
    if (reset.status === 200) {
      updateState("modalAlert", {
        state: true,
        message: "The text message has been sent successfully",
        title: "SMS Sent",
        icon: "success",
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });

      alert(reset.data.data[0]);

      return;
    } else if (reset.status === 400) {
      updateState("modalAlert", {
        state: true,
        message:
          reset?.data?.data ??
          "Something went wrong. Please retry or contact system administrator",
        title: "Reset Failed!",
        icon: "warning",
        func() {
          updateState("modalAlert", { ...states.modalAlert, state: false });
        },
      });

      return;
    }

    updateState("modalAlert", {
      state: true,
      message:
        "Something went wrong. Please retry or contact system administrator",
      title: "Reset Failed!",
      icon: "warning",
      func() {
        updateState("modalAlert", { ...states.modalAlert, state: false });
      },
    });
  };

  return (
    <>
      {/* modal alert */}
      <ModalTemplate
        icon={states.modalAlert.icon ?? "question"}
        disableCancel={states.modalAlert.icon !== "question" ? true : false}
        cancelText={"No,cancel"}
        open={states.modalAlert.state}
        okHandler={() => states.modalAlert.func!()}
        cancelHandler={() =>
          updateState("modalAlert", {
            state: false,
            title: "",
            message: "",
          })
        }
        message={states.modalAlert.message}
        okText={states.modalAlert.icon !== "question" ? "Ok" : "Yes, Reset"}
        title={states.modalAlert.title}
      />

      {/* reset password modal */}
      <ResetPasswordModal
        oldPassword={states.passwordBackup}
        open={states.resetPasswordModal}
        handleCancel={() => {
          updateState("resetPasswordModal", false);
        }}
      />

      {/* request password reset modal */}
      <RequestPasswordResetModal
        StaffNo={states.username}
        open={states.requestPasswordResetModal}
        handleCancel={() => {
          updateState("requestPasswordResetModal", false);
        }}
      />

      {/* help modal */}
      <HelpModal
        open={states.helpCheck}
        onCancel={() => updateState("helpCheck", false)}
        onOk={() => updateState("helpCheck", false)}
      />

      {states.splashScreen ? (
        <SplashScreen />
      ) : (
        <div className="login">
          {/* header */}
          <section className="top bg-blue-400 py-8 flex items-center justify-center">
            <h1 className=" font-bold italic text-white">{states.companyName}</h1>
          </section>

          {/* error dialogue */}
          {states.errorDialogue.state && (
            <div
              style={{ top: 120 }}
              className="fixed flex justify-center items-center w-full"
            >
              <div className="flex flex-row items-center bg-red-100 py-3 px-6 rounded-3xl shadow-gray-300 shadow-2xl">
                <p className=" mr-3 text-gray-600">
                  {states.errorDialogue.message}
                </p>
                <span
                  onClick={() => {
                    updateState("errorDialogue", {
                      state: false,
                      message: "",
                      title: "",
                    });
                  }}
                  className=" text-gray-600 hover:cursor-pointer hover:text-red-500"
                >
                  <IoClose size={25} />
                </span>
              </div>
            </div>
          )}

          {/* form */}
          <section
            style={{ height: "calc(100vh - 210px)" }}
            className="controls w-full flex items-center justify-center "
          >
            <Form
              onSubmitCapture={() => validate() && handleLogin()}
              layout="vertical"
              className=" w-full space-y-3"
              form={form}
              size="middle"
              style={{ maxWidth: 275 }}
              autoComplete={"off"}
            >
              {/* logo */}
              <div className=" h-24 w-full flex items-center justify-center">
                <div className="img h-full w-24 bg-slate-100 rounded-full">
                  <img
                    className="flex justify-center items-center"
                    src={logo}
                    alt="logo here"
                  />
                </div>
              </div>

              <Form.Item>
                <InputsTemplate
                  placeholder={"Username or Staff No"}
                  defaultValue={states.username}
                  disabledStatus={false}
                  useCallbackFunc
                  span
                  beforeIcon={
                    <span className=" to-blue-800">
                      <IoPerson color="#1e40af" />
                    </span>
                  }
                  inputStyle={{
                    borderRadius: 25
                  }}

                  orderOnchange={(name: any, index: number) => {
                    states.errorDialogue.state &&
                      updateState("errorDialogue", {
                        state: false,
                        message: "",
                        title: "",
                      });
                    updateState("username", name);
                  }}
                  autoComplete={"off"}
                />

              </Form.Item>

              <Form.Item>
                  <Input.Password
                    value={states.password}
                    type={"password"}
                    onChange={(pwd) => {
                      updateState("password", pwd.target.value);
                      updateState("passwordBackup", pwd.target.value);
                    }}
                    prefix={<span className=" to-blue-800 text-yellow-700">
                      <AiFillLock color="rgb(161 98 7 / var(--tw-text-opacity))" />
                    </span>}
                    required 
                    placeholder="Enter password"

                    style={{ borderRadius: "25px", borderWidth: "1px" }}
                    className={` px-2  flex flex-row items-center border-gray-300`}
                  />
              </Form.Item>

              <Form.Item>
                <div className="my-select-container">
                  <Select
                    value={states.server}
                    onChange={(e) => {
                      updateState("server", e);
                    }}
                    placeholder={"Select server"}
                    style={{ borderRadius: "25px" }}
                    className=" mb-3"
                  >
                    {states.selectOptions.map(({ id, label }: selectsTypes) => {
                      return (
                        <Select.Option key={id} value={label}>
                          {label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>

              {/* Bottom */}
              <Form.Item className=" w-full ">
                <div className="flex flex-row items-center justify-between mt-3">
                  <p
                    onClick={() => passwordResetCheck()}
                    className=" underline text-violet-800 hover:cursor-pointer"
                  >
                    Reset password?
                  </p>

                  <span
                    onClick={() => {
                      updateState("helpCheck", true);
                    }}
                    className=" hover:cursor-pointer bg-slate-50"
                  >
                    <img className=" w-8/12 h-8/12" src={help} alt="" />
                  </span>

                  {/* Login button */}
                  <Button
                    htmlType="submit"
                    style={{ borderRadius: "25px", backgroundColor: borderBgTheme }}
                    className="text-sm px-5 font-semibold hover:text-black flex flex-row items-center justify-center bg-inherit border-2 h-10"
                  >
                    {!states.loginLoading ? (
                      <div className=" flex flex-row">
                        <img className=" mr-1" src={check} alt="" />
                        <p className=" text-black">Login</p>
                      </div>
                    ) : (
                      <>
                        <p className=" text-black">Logging in...</p>
                      </>
                    )}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </section>

          {/* footer */}
          <section className="footer py-3 fixed bottom-0 bg-blue-100 flex flex-col justify-center items-center w-full">
            <p className=" text-sm">{states.footerLine1}</p>
            <p className=" text-sm">{states.footerLine2}</p>
          </section>
        </div>
      )}
    </>
  );
};

export default Login;
