import { Button, ContextMenu, DropDownButton, Tooltip } from "devextreme-react";
import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles/navbarStyles.css";
import NavResetPasswordModal from "./widgets/navResetPasswordModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import NavUserSettings from "./widgets/navSettings";
import { ActionTypes, ProfileThemes } from "../accessories/component_infos";
import { setGeneralValue } from "../../features/generalSlice";
import { Popover } from "antd";
import { NewsAlert } from "../system-alerts/news/newsAlert";
import useFetch from "../../hooks/useFetch";
import phonebook from "../../assets/phonebook.png";
import innorikLogo from "../../assets/innorikx.png";
import { userDataCrypt } from "../../functions/userDataEncrypt";

const element = document.documentElement;

const contextMenuPosition: any = {
  my: "top right",
  at: "bottom right",
};

interface options {
  toggleRightNav: Function;
  tabs: any;
  setTabs: (e: any) => void;
  setClicked: (e: any) => void;
}

const Navbar = ({ toggleRightNav, tabs, setTabs, setClicked }: options) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user?.user?.userModel); //emp id

  const navBG = useSelector(
    (state: RootState) => state.general.settingsColors?.tnbgc
  );
  const navT = useSelector(
    (state: RootState) => state.general.settingsColors?.tntc
  );
  const refreshRx = useSelector((state: RootState) => state.general.refresh);

  const [alerts, alertsError, alertsLoading] = useFetch(
    "USCXGenAlerts/GetAllUSCXGenAlert",
    refreshRx
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  const Logout = () => {
    if (tabs.length > 0) {
      setTabs([]);
    }
    sessionStorage.removeItem("client");
    // Remove makeAlert cookie when logout is triggered
    sessionStorage.removeItem("makeAlert");
    dispatch(setGeneralValue({ expr: "loginMode", value: false }));
    window.location.href = "/";
    // setRefresh(!refresh)
  }


  const CloseAllForms = useCallback((tabs: any) => {
    if (tabs.length >= 0) {
      setTabs([]);
      dispatch(setGeneralValue({ expr: "closeAllForms", value: true }));
    }
  }, []);

  const showAlerts = useCallback(() => {
    dispatch(setGeneralValue({ expr: "newAlerts", value: true }));
  }, []);

  const [showResetModal, setShowResetModal] = useState(false); //reset passowrd modal state
  const [showUserSettingsModal, setShowUserSettingsModal] = useState(false); //user settings modal state
  const [newsFlashVal, setNewsFlashVal] = useState("Stop News Flash");
  const [hidenewsFlashVal, setHideNewsFlashVal] = useState("Hide News Flash");

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  const contextItemClick = useCallback((e: any) => {
    switch (e.itemData.text) {
      case ActionTypes.LOG_OUT:
        Logout();
        break;

      case ActionTypes.RESET_PASSWORD:
        setShowResetModal(true);
        break;

      case ActionTypes.MY_SETTINGS:
        setShowUserSettingsModal(true);
        break;
      case ActionTypes.ALERTS:
        showAlerts();
        break;
      case ActionTypes.STOP_NEWS_FLASH:
        setNewsFlashVal(ActionTypes.START_NEWS_FLASH);
        dispatch(setGeneralValue({ expr: "stopNewsFlash", value: true }));
        break;
      case ActionTypes.START_NEWS_FLASH:
        dispatch(setGeneralValue({ expr: "newsflashHeight", value: 40 }));
        setNewsFlashVal(ActionTypes.SHOW_NEWS_FLASH);
        dispatch(setGeneralValue({ expr: "stopNewsFlash", value: false }));
        break;
      case ActionTypes.HIDE_NEWS_FLASH:
        dispatch(setGeneralValue({ expr: "newsflashHeight", value: 0 }));
        setHideNewsFlashVal(ActionTypes.SHOW_NEWS_FLASH);
        break;
      case ActionTypes.SHOW_NEWS_FLASH:
        dispatch(setGeneralValue({ expr: "newsflashHeight", value: 60 }));
        setHideNewsFlashVal(ActionTypes.HIDE_NEWS_FLASH);
        break;

      case ActionTypes.THEME:
        <>
          <Popover
            placement="topLeft"
            title={"hiu"}
            content={content}
            trigger="click"
          >
            <Button>TR</Button>
          </Popover>
        </>;
        break;

      case ActionTypes.CLOSE_ALL_FORMS:
        CloseAllForms(tabs);
        break;

      default:
        break;
    }

    if (!e.itemData.items) {
      switch (e.itemData.text) {
        case ProfileThemes.LIGHT_MODE:
          element.classList.remove("dark");
          element.classList.remove("redTheme");
          // this is where I push it to redux so I can access the theme state and make the state persistent
          // using localStorage i.e localStorage.setItem('theme', 'lightMode')
          localStorage.setItem("theme", "lightMode");
          setRefresh(!refresh);
          break;

        // case ProfileThemes.DARK_MODE:
        //   element.classList.add("dark");
        //   localStorage.setItem("theme", "dark");
        //   setRefresh(!refresh);

        //   break;

        case ProfileThemes.BOOTSTRAP:
          localStorage.setItem("theme", "lightMode");
          setRefresh(!refresh);
          break;

        case ProfileThemes.BOOTSTRAP_BLUE:
          localStorage.setItem("theme", "Bootstrap Blue");
          setRefresh(!refresh);
          break;

        case ProfileThemes.BOOTSTRAP_BLACK:
          localStorage.setItem("theme", "Bootstrap Black");
          setRefresh(!refresh);
          break;

        case ProfileThemes.VISTA:
          localStorage.setItem("theme", "Vista");
          setRefresh(!refresh);
          break;

        default:
          element.classList.remove("dark");
          localStorage.setItem("theme", "lightMode");
          break;
      }

      dispatch(setGeneralValue({ expr: "themeRefresh", value: !refresh }))
    }
  }, [refresh]);

  const profileOptions = [
    { text: "Profile" },
    { text: "Reset Password" },
    { text: newsFlashVal },
    { text: hidenewsFlashVal },
    { text: "Do Not Show Right Navigator on Start" },
    { text: "Stop News Flash on Startup" },
    { text: "My Settings" },
    { text: "Alerts" },
    { text: "About" },
    { text: "Log Out" },

    {
      text: "Theme", //previous name "Application Look and Feel"
      items: [
        { text: "Light Mode" },
        // { text: "Dark Mode" },
        { text: "Bootstrap" },
        { text: "Bootstrap Black" },
        { text: "Bootstrap Blue" },
        { text: "Vista" },
      ],
    },
    { text: "---------------------------------" },
    { text: "Close All Forms" },
  ];

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [openModal, setopenModal] = useState<boolean>();

  // redux
  const rxNewsAlerts: boolean = useSelector(
    (state: RootState) => state.general.newAlerts
  );
  const userSettingsP: boolean = useSelector(
    (state: RootState) => state.general.alertsWindowOnStartup
  );

  // getSesstion cookie of Alert
  const isAlertCookie = sessionStorage.getItem("makeAlert");

  // getSession cookie of serverType
  const serverType = sessionStorage.getItem("serverType");
  // getSession cookie of lockStatus
  const lockStatus = sessionStorage.getItem("lockStatus");

  // set openModal state based on makeAlert which is a cookie
  useEffect(() => {
    switch (isAlertCookie) {
      case "true":
        setopenModal(true);
        break;
      case "false":
        setopenModal(false);
        break;
      default:
        setopenModal(true);
        break;
    }
  }, [isAlertCookie]);

  const setLockScreen = useCallback(() => {
    sessionStorage.setItem("lockStatus", "true");
    sessionStorage.setItem("client", "false");
    // when locked screen is pressed close alert if opened
    dispatch(
      setGeneralValue({
        expr: "rightNavigatorOnStartup",
        value: false,
      })
    );
    setClicked && setClicked(false);
  }, []);

  return (
    <>
      {/* Modal sub components */}
      <NavResetPasswordModal
        open={showResetModal}
        handleCancel={() => setShowResetModal(false)}
      />

      {/* Alerts */}
      {userSettingsP && (
        <NewsAlert
          open={rxNewsAlerts ?? openModal}
          cancelHandler={() => {
            dispatch(setGeneralValue({ expr: "newAlerts", value: false }));
          }}
        />
      )}

      <NavUserSettings
        open={showUserSettingsModal}
        handleCancel={() => setShowUserSettingsModal(false)}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          #navbar .dx-button {
              border-radius: 20px;

          }
          #navbar  .dx-button-mode-contained.dx-buttongroup-last-item.dx-button, #navbar  .dx-button-mode-contained.dx-buttongroup-last-item.dx-state-hover .dx-button-content, #navbar   .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button, #navbar  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-state-hover .dx-button-content {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px
          }
          
        }
          `,
        }}
      />

      <nav
        id="navbar"
        className={`  border-gray-200 bg-${navBG?.color ?? "white"
          }  border-b-gray-100 border-b-2 px-4  py-2.5  rounded dark:bg-gray-900`}
      >
        <div className="  flex flex-wrap  items-center justify-between  ">
          {/* Title  */}
          <Link
            onClick={setLockScreen}
            to={"/locked"}
            id="server"
            style={{ width: 293, borderColor: borderTheme }}
            className={`flex justify-between items-center border-[1px] rounded-full  ${serverType === "Production Server"
              ? "bg-sky-500"
              : " bg-amber-600"
              } `}
          >
            <span className="w-1/5 flex py-2 justify-center items-center rounded-l-full bg-purple-500">
              <img src={innorikLogo} className="h-6" alt="Innorik Logo" />
            </span>
            <span
              className={`w-4/5 flex justify-center items-center self-center  font-semibold whitespace-nowrap text-${navT?.color ?? "black"
                }`}
            >
              InnoX - {serverType}
            </span>
            <Tooltip
              target="#server"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <div>Server-Click to Lock App</div>
            </Tooltip>
          </Link>

          {/* Actions */}
          <div
            className=" items-center justify-between hidden  lg:flex w-auto "
            id="mobile-menu-2"
          >
            <div className="mx-0">
              <DropDownButton
                disabled={lockStatus === "true"}
                icon="fa-regular fa-square-check"
                text="Tasks"
                id="tasksAndTodos"
                className={`border-[1px] rounded-full `}
                stylingMode={"text"}
                style={{ borderColor: borderTheme, backgroundColor: "#caf0f8" }} //theme css
                dropDownOptions={{ width: 200 }}
                items={["Task Manager", "Task Assigment", "Task Updates"]}
                onItemClick={(e) => {
                  if (e.itemData == "Task Manager") {
                    navigate("/tasks");
                  } else if (e.itemData == "Task Assignment") {
                    navigate("/tasks");
                  } else if (e.itemData == "Task Updates") {
                    navigate("/tasks");
                  }
                }}
              />

              <Tooltip
                target="#tasksAndTodos"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <div>Tasks, Todos and Done Today</div>
              </Tooltip>
            </div>

            <div
              className="h-10 w-24  p-0.5 flex items-start justify-start mx-1 border-[1px] rounded-full"
              style={{ borderColor: borderTheme }}
            >
              <div className="h-8 w-full flex items-start justify-start ">
                <div className="w-full grid grid-cols-2  gap-0.5 px-2  rounded-full">
                  <div
                    id="uncompleted"
                    className="bg-yellow-50 text-red-500 text-xs text-center px-2 "
                  >
                    1
                  </div>
                  <div
                    id="tasksCount"
                    className="bg-yellow-50 text-black text-xs  text-center px-2 "
                  >
                    2
                  </div>
                  <div
                    id="completed"
                    className="bg-yellow-50 text-green-500 text-xs  text-center px-2 "
                  >
                    3
                  </div>
                  <div
                    id="notStarted"
                    className="bg-yellow-50 text-black text-xs  text-center px-2 "
                  >
                    9
                  </div>
                  <Tooltip
                    target="#uncompleted"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <div>Uncompleted Tasks Count</div>
                  </Tooltip>
                  <Tooltip
                    target="#tasksCount"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <div>Total Tasks Count</div>
                  </Tooltip>
                  <Tooltip
                    target="#completed"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <div>Total Tasks Completed</div>
                  </Tooltip>
                  <Tooltip
                    target="#notStarted"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <div>Total Tasks not Started</div>
                  </Tooltip>
                </div>
              </div>
              <div className="h-8 flex items-start justify-start mx-0"></div>
            </div>

            {/* <div className="mx-1 ">
              <Link to={"/tel-directory"}>
                <Button
                  disabled={lockStatus === "true"}
                  text="Tel. Directory"
                  stylingMode="outlined"
                  icon="fa-regular fa-address-book"
                  className={` border-[1px] rounded-full text-black}`}
                  style={{
                    borderColor: borderTheme,
                    backgroundColor: "#caf0f8",
                  }}
                  id="telDirectory"
                  render={(e) => {
                    return (
                      <ul className="w-full py-[1px] space-x-1.5 flex items-center justify-between list-none">
                        <li className="1/3">
                          <img src={phonebook} alt="phonebook" />
                        </li>
                        <li className="2/3">
                          <p className={`text-black}`}>Tel. Directory</p>
                        </li>
                      </ul>
                    );
                  }}
                />
              </Link>
              <Tooltip
                target="#telDirectory"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <div>Telephone Directory</div>
              </Tooltip>
            </div> */}
            {/* <div>
              <DropDownButton
                disabled={lockStatus === "true"}
                stylingMode="text"
                icon="help"
                text="Help Desk"
                id="helpDesk"
                style={{
                  borderColor: borderTheme,
                  backgroundColor: "#caf0f8",
                  borderRaduis: "15px",
                }}
                className="border-[1px] rounded-full"
                dropDownOptions={{ width: 200 }}
                items={["Issue Reporting", "Maintenance Requests"]}
              />
              <Tooltip
                target="#helpDesk"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <div>Help Desk</div>
              </Tooltip>
            </div> */}
          </div>

          {/* User Data */}

          <div className="flex items-center md:order-2  ">
            <img
              style={{ borderColor: borderTheme }}
              className="w-8 h-8 mr-2 border-[1px] rounded-full"
              src="https://images.pexels.com/photos/14377149/pexels-photo-14377149.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt="user-img"
            />

            <div className="userButton">
              <DropDownButton
                disabled={lockStatus === "true"}
                text={`Welcome, ${user?.empFirstName ?? "User"}`}
                id="userName"
                stylingMode="text"
                opened={false}
                noDataText={undefined}
                className="border-[1px] rounded-full "
                style={{ borderColor: borderTheme, backgroundColor: "#caf0f8" }}
              />

              <Tooltip
                target="#tasksAndTodos"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <div>
                  Your username-click to see a host of actions you can take
                </div>
              </Tooltip>
            </div>
            <ContextMenu
              showEvent="click"
              items={profileOptions}
              target="#userName"
              position={contextMenuPosition}
              onItemClick={contextItemClick}
            />
            <div className="ml-2">
              <Button
                disabled={lockStatus === "true"}
                icon="bell"
                stylingMode="outlined"
                text={"" + alerts.length}
                className="border-[1px] rounded-full"
                style={{ borderColor: borderTheme, backgroundColor: "#caf0f8" }}
                id="alerts"
                onClick={() => {
                  toggleRightNav();
                }}
              />
              <Tooltip
                target="#alerts"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <div>Alerts-Click to Show/Hide Right Navigator</div>
              </Tooltip>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
