export const outagecol = [
  {
    id: 1,
    caption: "NAME",
    dataField: "otpName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "otpShtName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "otpActive",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "AUTO?",
    dataField: "otpAuto",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "PLANNED",
    dataField: "otpPlanned",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "REMARKS",
    dataField: "otpRmks",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
 
];
