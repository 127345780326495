/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Fuel_Backlog_Details_Form } from "./widgets/Backlog_details";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  currentDate,
  startingDate,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { backlogColumns } from "./data/datagrid_cols";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { EntryCustomToolbar } from "../../../../../accessories/custom-toolbars/entryCustomtoolbar";
import dayjs from "dayjs";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { ContextMenu } from "devextreme-react";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import CustomLoader from "../../../../../accessories/custom-loader";

interface props {
  state_change: boolean;
}

export const Fuel_Backlog = forwardRef(({ state_change }: props, ref) => {




  const [outerSelect, setOuterSelect] = useState<any>({});

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const childRef = useRef<any>();
  const formRef = useRef<any>();
  const [refreshState, setRefreshState] = useState<boolean>(false);


  const [searchText, setSearchText] = useState<{ temp: string; text: string }>({
    temp: "",
    text: "",
  });
  const [startDate, setStartDate] = useState<{ temp: string; index: string }>(
    {
      temp: dayjs(startingDate).format("YYYY-MM-DD"),
      index: dayjs(startingDate).format("YYYY-MM-DD"),
    }
  );
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(currentDate).format("YYYY-MM-DD"),
    index: dayjs(currentDate).format("YYYY-MM-DD"),
  });
  const [searchCriteria, setSearchCriteria] = useState<{
    temp: number;
    index: number;
  }>({ temp: 0, index: 0 });
  const [active, setActive] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({ temp: "", status: "" });
  const [mainUrl, setMainUrl] = useState(
    `AsmTmsCaptureOldFuelRecords/GetAllCaptureOldFuelRecords/GetAllCaptureOldFuelRecords?SearchText=${searchText.temp}&SearchCriteria=${searchCriteria.temp}&StartDate=${startDate.index}&EndDate=${endDate.index}&IsActive=${active.status}`
  );
  const [oldFuelRecordData, oldFuelRecordDataError, oldFuelRecordDataLoading] =
    useFetch(mainUrl, refreshState, "fmgIDpk");
  const setupPageRoute = window.location.pathname;
  const datagridOnlySearchParam = "table-only";
  const formModeSearchParam = "form-and-table";
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialH, fullH] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
    searchParams.get("view") === null
  );
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height + 40;
  const [form_height] = useState<number>(270);


  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: datagridOnlySearchParam,
        }).toString(),
      })
      : navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: formModeSearchParam,
        }).toString(),
      });
  };
  var tableHeight = fullH - (heights_out - 40);

  const [qtyType] = useState("Count");
  const [qtyAuth] = useState("Count");
  const [amountType, setAmountType] = useState("Sum");

  const formGrid = initialH - (form_height + heights_out + 36);

  return (
    <>
      {oldFuelRecordDataLoading && <CustomLoader text={"Fetching Data"} />}
      {oldFuelRecordDataError && <WarningAlert />}
      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">

        <div className="mx-2">
          <EntryCustomToolbar
            outerSelected={outerSelect}
            setOuterSelected={setOuterSelect}
            searchCriteriaData={[
              { id: 0, value: "Registration No" },
              { id: 1, value: "Chasis No" },
              { id: 2, value: "Vehicle Type" },
              { id: 3, value: "Vehicle Make" },
              { id: 4, value: "Vehicle Model" },
              { id: 5, value: "Manufacturer" },
              { id: 6, value: "Supplier" },
              { id: 7, value: "Manufacture Date" },
              { id: 8, value: "Supply Date" },
            ]}
            handleFind={() => {
              setMainUrl(
                `AsmTmsCaptureOldFuelRecords/GetAllCaptureOldFuelRecords/GetAllCaptureOldFuelRecords?SearchText=${searchText.temp}&SearchCriteria=${searchCriteria.temp}&StartDate=${startDate.index}&EndDate=${endDate.index}&IsActive=${active.status}`
              );
            }}
            checkOnChange={(check) => {
              setActive({ temp: check, status: active.status });
            }}
            withDates={true}
            startDateChanged={(startDateV) => {
              setStartDate({
                temp: dayjs(startDateV).format("YYYY-MM-DD"),
                index: startDate.index,
              });
            }}
            endDateChanged={(endDateV) => {
              setEndDate({
                temp: dayjs(endDateV).format("YYYY-MM-DD"),
                index: endDate.index,
              });
            }}
            startDate={startDate.temp}
            endDate={endDate.temp}
            searchTextOnchange={(searchValue) => {
              setSearchText({ temp: searchValue, text: searchText.text });
            }}
            toggler={() => {
              toggle_staff_form();
            }}
            searchCriteria={true}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setSearchCriteria({
                temp: newIndex,
                index: searchCriteria.index,
              });
            }}
            handleRefresh={() => {
              setRefreshState(!refreshState);
              setSearchCriteria({
                temp: 0,
                index: 0,
              });
              setSearchText({
                temp: " ",
                text: " ",
              });
              setActive({ temp: " ", status: " " });
              setStartDate({
                temp: dayjs(startingDate).format("YYYY-MM-DD"),
                index: dayjs(startingDate).format("YYYY-MM-DD"),
              });
              setEndDate({
                temp: dayjs(currentDate).format("YYYY-MM-DD"),
                index: dayjs(currentDate).format("YYYY-MM-DD"),
              });
            }}
          />
        </div>

        {searchParams.get("view") !== datagridOnlySearchParam && (
          <div ref={formRef} className="px-2">
            <p
              id={"form_header"}
              style={{ borderColor: borderTheme, width: "100%" }}
              className="pt-1  pl-4 w-full border-[1px]  bg-slate-100 rounded-t-md"
            >
              Fuel Record Details
            </p>
            <div
              style={{ height: "305px" }}
              className="w-full  flex justify-between gap-x-4 border-b-2 mb-1 "
            >

              <div style={{ width: "100%", height: "300px" }} className="">
                <Fuel_Backlog_Details_Form
                  setOuterSelect={setOuterSelect}
                  ref={childRef}
                  refreshState={() => {
                    console.log("hello");
                    setRefreshState(!refreshState);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div
          style={{ height: "" }}
          className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base"
        >
          <Datagrid_template
            summary={
              <Summary>
                <TotalItem
                  cssClass={"one"}
                  column="QTY REQ."
                  summaryType={`${qtyType.toLowerCase() as "avg" | "count" | "custom" | "max" | "min" | "sum" | undefined}`}
                />

                <TotalItem
                  cssClass={"two"}
                  column="QTY AUTH."
                  summaryType={`${qtyAuth.toLowerCase() as "avg" | "count" | "custom" | "max" | "min" | "sum" | undefined}`}
                  valueFormat=""
                />
                <TotalItem
                  cssClass={"three"}
                  column="AMOUNT"
                  summaryType={`${amountType.toLowerCase() as "avg" | "count" | "custom" | "max" | "min" | "sum" | undefined}`}
                  valueFormat="currency"
                />
              </Summary>
            }
            rowDoubleClicked={() => {
              setSearchParams(formModeSearchParam);
              if (childRef.current) {
                childRef.current.doubleClick();
              }
            }}
            onRowClick={(e) => {
              setOuterSelect && setOuterSelect(e);
              if (childRef.current) {
                childRef.current.singleClick(e);
              }
            }}
            columns={backlogColumns}
            data={oldFuelRecordData}
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
                searchParams.get("view") === null
                ? formGrid
                : tableHeight - 5
            }
            disablePaging={false}
          />
          <ContextMenu
            dataSource={[
              { text: "Sum" },
              { text: "Min" },
              { text: "Max" },
              { text: "Count" },
              { text: "Average" },
            ]}
            // width={200}
            target=".one"
            onItemClick={(itemClick: any) => {
              itemClick === "Average"
                ? setAmountType("avg")
                : setAmountType(itemClick["itemData"]["text"]);
            }}
          />
          <ContextMenu
            dataSource={[
              { text: "Sum" },
              { text: "Min" },
              { text: "Max" },
              { text: "Count" },
              { text: "Average" },
            ]}
            // width={200}
            target=".two"
            onItemClick={(itemClick: any) => {
              itemClick === "Average"
                ? setAmountType("avg")
                : setAmountType(itemClick["itemData"]["text"]);
            }}
          />
          <ContextMenu
            dataSource={[
              { text: "Sum" },
              { text: "Min" },
              { text: "Max" },
              { text: "Count" },
              { text: "Average" },
            ]}
            // width={200}
            target=".three"
            onItemClick={(itemClick: any) => {
              itemClick === "Average"
                ? setAmountType("avg")
                : setAmountType(itemClick["itemData"]["text"]);
            }}
          />

        </div>
      </div>
    </>
  );
});
