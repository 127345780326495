/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useRef, useState } from "react";
import { ElogBookMainFormV2 } from "./widgets/ElogBookMainForm";
import Datagrid_template from "../../../templates/Datagrid";
import { elogbook_columns } from "./data/EloggerDatagridData";
import { EloggerCustomToolbar } from "../../accessories/custom-toolbars/elogger_toolbar";
import MidnightConditions from "./widgets/midnight_conditions";
import { Update_Shift_Members_Modal } from "./widgets/Update_Shift_Members";
import { Takeover_Shift_Members_Modal } from "./widgets/TakeOverShiftMembers";
import { ModalTemplate } from "../../../templates/modal";
import { StationDetails } from "./StationDetails/StationDetails";
import { SafetySetupEventTypes } from "../../Assets-Manager/Transport/setups/general/event-types/eventTypes";
import { Directories } from "./widgets/subsections/Directories/Directories";
import { Others } from "./widgets/subsections/Other/Others";
import { generateUUID, HSeperator, VSeperator } from "./widgets/utils";
import Update_Station_Shift_Members from "./widgets/Update_Station_Shift_Members";
import Remote_Shift_Change from "./widgets/Remote_Shift_Change";
import React from "react";
import { GetDataFunc } from "../../../functions/get";
import { useDispatch, useSelector } from "react-redux";
import { setSearchParamOnChange } from "../../../features/apiCalls/elogbook";
import { SyncLog } from "./widgets/SyncLog";
import SyncStatus from "./widgets/SyncStatus";
import { useHeightResizer } from "./util/iHooks";

export const Safety_Elogger = forwardRef(({ a }: any, ref) => {

  //varying form height
  let form_height = 315;

  // Modal States
  const [isUpdateTemplateBtnClicked, setIsUpdateTemplateBtnClicked] = useState(false);
  const [isRemoteShiftBtnClicked, setIsRemoteShiftBtnClicked] = useState(false);
  const [isShiftTakeOverBtnClicked, setIsShiftTakeOverBtnClicked] = useState(false);
  const [isUpdateCurrentShiftMembersClicked, setIsUpdateCurrentShiftMembersClicked] = useState(false)
  const [isEventTypeBtnClicked, setIsEventTypeBtnClicked] = useState(false);
  const [showMidnightConditions, setShowMidnightConditions] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [showRemoteShift, setShowRemoteShift] = useState(false);
  const [showShiftTakeover, setShowShiftTakeover] = useState(false);
  const [showShiftMembers, setShowShiftMembers] = useState(false);
  const [showEventTypes, setShowEventTypes] = useState(false);
  const [showStationDetails, setShowStationDetails] = useState(false);
  const [upstationShiftMembers, setUpstationShiftMembers] = useState(false);
  const [shiftchangeClick, setShiftchangeClick] = useState(false);
  const [syncLogClicked, setSyncLogClicked] = useState(false);
  const [syncStatusClicked, setSyncStatusClicked] = useState(false);

  const [showDirectories, setShowDirectories] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [showMiniForm, setshowMiniForm] = useState(false);

  const [ElogData, setElogData] = useState<any>([]);
  const [switchForm, setSwitchForm] = useState(true);
  const [switchFormDisabled, setSwitchFormDisabled] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({});
  const [resizeTextArea, setResizeTextArea] = useState(90);
  const [color, setColor] = useState<any>({ stnFlaggedRowsColour: "#F0E68C", stnEvenRowsColour: "" });
  const [showLogDate, setShowLogDate] = useState(false);
  const [showGridLines, setShowGridLines] = useState(false);
  const [multilineRows, setMultilineRows] = useState(true);
  const [spellCheck, setSpellCheck] = useState(true);
  const tState = useSelector((state: any) => state.elogBook) ?? {};
  const [averageLogTimes, setAverageLogTimes] = useState<any>({});
  const [onDeleteLogClicked, setOnDeleteLogClicked] = useState(false);
  const [onRowDoubleClick, setOnRowDoubleClick] = useState<any>(false);
  const [takeOverKey, setTakeOverKey] = useState(generateUUID());
  const dispatch = useDispatch();

  const screenHeight = useHeightResizer();
  const isMinimizeGridClicked = useRef(true);
  const isPrev400 = useRef(0);

  const handleCellRender = (e: any) => {
    if (e.rowType === "data") {
      e.rowElement.style.backgroundColor = "";
      const rowData = e.data;
      if (rowData?.lbkFlagged) {
        e.rowElement.style.backgroundColor = "#F0E68C";
        // e.rowElement.style.backgroundColor = color.stnFlaggedRowsColour;
      }
    }
  };

  const GetColor = async () => {
    const { data: r } = await GetDataFunc("ELogBook/GetColoursByStationId", { stationId: tState?.stationID ?? 0 }, "powerapp");
    // setColor({ stnFlaggedRowsColour: r.stnFlaggedRowsColour, stnEvenRowsColour: r.stnEvenRowsColour });
    if (r.code === 200) { setColor({ stnFlaggedRowsColour: "#F0E68C", stnEvenRowsColour: r.stnEvenRowsColour }); }
  };

  const GetAverageLogTimes = async () => {
    const { data: r } = await GetDataFunc("ELogBook/GetAverageLogTimes", {}, "powerapp");
    setAverageLogTimes(r.data[0]);
  };

  const getGridHeight = () => {

    if (resizeTextArea === 400) {
      return switchForm ? screenHeight - (form_height + 270) : (screenHeight - (form_height - 230))
    }

    const height = isMinimizeGridClicked.current
      ? showMiniForm
        ? screenHeight - (form_height + 160)
        : switchForm ? (screenHeight - (form_height - 30)) : screenHeight - (form_height - 230)
      : screenHeight;
    return height;
  }

  useEffect(() => {
    if (color.stnFlaggedRowsColour === "") {
      GetColor();
    }
    GetAverageLogTimes();
  }, [tState.stationID]);

  return (
    <>
      {/* Modals */}
      <MidnightConditions
        open={showTemplateModal}
        handleCancel={() => {
          setShowTemplateModal(false);
        }}
      />

      <Update_Shift_Members_Modal
        open={showShiftMembers}
        handleCancel={() => {
          setShowShiftMembers(false);
        }}
      />

      <Remote_Shift_Change
        open={showRemoteShift}
        handleCancel={() => {
          setShowRemoteShift(false);
        }}
      />

      <Takeover_Shift_Members_Modal
        key={takeOverKey}
        open={showShiftTakeover}
        handleCancel={() => {
          setShowShiftTakeover(false);
          setTakeOverKey(generateUUID());
        }}
        resetKey={() => { setTakeOverKey(generateUUID()) }}
      />

      <ModalTemplate
        disableCancel
        width={"90%"}
        customComponent={<SafetySetupEventTypes />}
        title={"eLog Book - Event Types"}
        message={""}
        cancelHandler={() => setShowEventTypes(false)}
        open={showEventTypes}
        okHandler={() => { }}
      />

      <ModalTemplate
        disableCancel
        width={"95%"}
        customComponent={<StationDetails />}
        title={"eLog Book - Station Details"}
        message={""}
        cancelHandler={() => setShowStationDetails(false)}
        open={showStationDetails}
        okHandler={() => { }}
      />
      <ModalTemplate
        disableCancel
        width={"95%"}
        customComponent={<Update_Station_Shift_Members />}
        title={"eLog Book - Update Station Shift Members"}
        message={""}
        cancelHandler={() => setUpstationShiftMembers(false)}
        open={upstationShiftMembers}
        okHandler={() => { }}
      />

      <ModalTemplate
        disableCancel
        width={"20%"}
        customComponent={<SyncLog />}
        title={"eLog Book - Sync Log"}
        message={""}
        cancelHandler={() => setSyncLogClicked(false)}
        open={syncLogClicked}
        okHandler={() => { }}
      />

      <ModalTemplate
        disableCancel
        width={"37.5%"}
        customComponent={<SyncStatus />}
        title={"eLog Book - Sync Status"}
        message={""}
        cancelHandler={() => setSyncStatusClicked(false)}
        open={syncStatusClicked}
        okHandler={() => { }}
      />

      <main className="">
        {/* e Log book custom tab */}
        <section>
          <EloggerCustomToolbar
            updateShiftMembersClick={() => setIsUpdateCurrentShiftMembersClicked(true)}
            midnightConditionsClick={() => setShowMidnightConditions(true)}
            updateTemplatesClick={() => setIsUpdateTemplateBtnClicked(true)}
            remoteShiftChangeClick={() => setIsRemoteShiftBtnClicked(true)}
            eventTypesClick={() => setIsEventTypeBtnClicked(true)}
            shiftTakeoverClick={() => setIsShiftTakeOverBtnClicked(true)}
            stationDetailsClick={() => setShowStationDetails(true)}
            shiftchangeClick={() => { setShiftchangeClick(!shiftchangeClick); }}
            updateStationShiftMembers={() => setUpstationShiftMembers(true)}
            switchForm={showMiniForm || resizeTextArea === 400 || switchFormDisabled ? () => { } : () => { setSwitchForm(!switchForm); }}
            setElogData={setElogData}
            selectedData={selectedData}
            elogData={ElogData}
            onShowLogDateClick={() => setShowLogDate(!showLogDate)}
            onShowVerticalLinesClick={() => setShowGridLines(!showGridLines)}
            onMultilineRowsClick={() => setMultilineRows(!multilineRows)}
            showLogDate={showLogDate}
            showVerticalLines={showGridLines}
            multilineRows={multilineRows}
            spellChecker={spellCheck}
            onSpellCheckerClick={() => setSpellCheck(!spellCheck)}
            handleDeleteLogClicked={() => setOnDeleteLogClicked(!onDeleteLogClicked)}
            handleSyncLogClicked={() => setSyncLogClicked(true)}
            handleSyncStatusClicked={() => setSyncStatusClicked(true)}
          />
        </section>

        {/* form */}
        <section className="flex">
          <aside className="w-[80%] flex-1">
            {/* {switchForm && ( */}
            <div className="w-full" key={"ElogBookMainFormV2"} style={{ display: switchForm ? "block" : "none" }} >
              <ElogBookMainFormV2
                setMidnightConditionsClick={() => setShowMidnightConditions(false)}
                midNightCondition={showMidnightConditions}
                isUpdateTemplateBtnClicked={isUpdateTemplateBtnClicked}
                setIsUpdateTemplateBtnClicked={setIsUpdateTemplateBtnClicked}
                isRemoteShiftBtnClicked={isRemoteShiftBtnClicked}
                setIsRemoteShiftBtnClicked={setIsRemoteShiftBtnClicked}
                setShowRemoteShift={setShowRemoteShift}
                setShowTemplateModal={setShowTemplateModal}
                isShiftTakeOverClicked={isShiftTakeOverBtnClicked}
                setIsShiftTakeOverClicked={setIsShiftTakeOverBtnClicked}
                isUpdateCurrentShiftMembersClicked={isUpdateCurrentShiftMembersClicked}
                setIsUpdateCurrentShiftMembersClicked={setIsUpdateCurrentShiftMembersClicked}
                setShowShiftMembers={setShowShiftMembers}
                setShowShiftTakeover={setShowShiftTakeover}
                showMiniForm={showMiniForm}
                shiftchangeClick={shiftchangeClick}
                updateMiniForm={setshowMiniForm}
                selectedData={selectedData}
                isEventTypeBtnClicked={isEventTypeBtnClicked}
                setIsEventTypeBtnClicked={setIsEventTypeBtnClicked}
                setShowEventTypes={setShowEventTypes}
                resizeTextArea={resizeTextArea}
                setResizeTextArea={setResizeTextArea}
                spellCheck={spellCheck}
                onDeleteLogClicked={onDeleteLogClicked}
                onRowDoubleClick={onRowDoubleClick}
                setSwitchForm={setSwitchForm}
                setSwitchFormDisabled={setSwitchFormDisabled}
              />
            </div>
            {/* )} */}
            <HSeperator
              onClick={() => {

                if (resizeTextArea === 400) {
                  isPrev400.current = resizeTextArea;
                }

                if (isMinimizeGridClicked.current) {
                  setResizeTextArea(screenHeight - 200);
                } else {
                  if (isPrev400.current !== 400) {
                    setResizeTextArea(90);
                  } else {
                    setResizeTextArea(400);
                    isPrev400.current = 0;
                  }
                }
                isMinimizeGridClicked.current = !isMinimizeGridClicked.current;

              }}
              status={!isMinimizeGridClicked.current}
            />
            {/* datagrid */}
            {isMinimizeGridClicked.current && (
              <section className="w-full h-auto flex" >
                {showDirectories && (
                  <aside className="w-[40%]">
                    {/* <aside className="w-[40%]" style={{ display: showDirectories ? "block" : "none" }}> */}
                    <Directories
                      height={
                        isMinimizeGridClicked.current
                          ? showMiniForm
                            ? screenHeight - 520
                            : switchForm ? screenHeight - 350 : screenHeight - 150
                          : screenHeight + 50
                      }
                    />
                  </aside>
                )}
                <VSeperator
                  height={100}
                  onClick={() => setShowDirectories(!showDirectories)}
                  status={!showDirectories}
                />
                <aside className="w-[59%] h-auto flex-grow">
                  <Datagrid_template
                    deselectFirstRow={true}
                    showBorders={showGridLines}
                    wordWrap={multilineRows}
                    rowAlternationEnabled={false}
                    columns={elogbook_columns(showLogDate)}
                    dataId={"lbkIDpk"}
                    data={ElogData}
                    gridheight={getGridHeight()}
                    className={"w-full"}
                    onRowClick={(data: any) => {
                      setSelectedData(data);
                    }}
                    rowDoubleClicked={(data: any) => {
                      setOnRowDoubleClick(data);
                    }}
                    onRowPrepared={handleCellRender}
                    handlePageNumber={(page) => {
                      dispatch(setSearchParamOnChange({ key: "pageNumber", value: page }));
                    }}
                    handlePageSize={(size) => {
                      dispatch(setSearchParamOnChange({ key: "pageSize", value: size }));
                    }}
                    pageNumber={tState.pageNumber}
                    pageSize={tState.pageSize}
                  />
                </aside>
              </section>
            )}
          </aside>
          <VSeperator
            height={100}
            status={showOthers}
            onClick={() => setShowOthers(!showOthers)}
          />
          {/* {showOthers && ( */}
          <aside className="w-[20%] h-auto" style={{ display: showOthers ? "block" : "none" }}>
            <Others mainGridDataLength={ElogData.length} mainGridSelectedRecord={selectedData} />
          </aside>
          {/* )} */}
        </section>
        <section className="flex justify-between items-center bg-gray-50">
          <div className="w-full flex items-center py-1">
            <p>Colour Legend- </p>
            <p className={`bg-[#F0E68C] py-0.5 px-2`}>Flagged</p>
          </div>
          <section className="w-80 py-2 font-medium flex justify-between mr-2">
            <p>{averageLogTimes?.minTimex ? `${averageLogTimes?.minTimex} mins` : "*"}</p>
            <p>{averageLogTimes?.maxTimex ? `${averageLogTimes?.maxTimex} mins` : "*"}</p>
            <p>{averageLogTimes?.averageTimeDiffx ? `${averageLogTimes?.averageTimeDiffx} mins` : "*"}</p>
          </section>
        </section>
      </main>
    </>
  );
});
