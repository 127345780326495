import { Form } from "antd";
import React from "react";
import { DateTemplate } from "../../../templates/date";
import InputSelect_template from "../../../templates/InputSelect";
import { InputsTemplate } from "../../../templates/input";
import { CheckboxTemlate } from "../../../templates/checkbox";
import { TextareaTemplate } from "../../../templates/textarea";
import Datagrid_template from "../../../templates/Datagrid";
import { IconsContainer } from "../../Safety/elogger/StationReadings/_widget/stationReadingsToolbar";
import newBtn from "../../../assets/New161.png";
import saveBtn from "../../../assets/save-small.png";

export const Pc2 = () => {
  return (
    <div className="w-full h-full">
      <div>
        <p className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
          {"Protection Guarantee Details"}
        </p>
      </div>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 mr-3"
        name="basic"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <div className="flex  w-full ">
          <div className="w-[43%]  ">
            <div className="w-full">
              <InputSelect_template label="Guarantee Type" />
            </div>
            <div className="w-full">
              <InputSelect_template label="Permit/Guarantee Issued to" />
            </div>
          </div>
          <div className="w-[33%] ">
            <div className="w-full">
              <InputSelect_template label="Issuing Station" />
            </div>
            <div className="w-full">
              <DateTemplate label="Date" />
            </div>
          </div>
          <div className="w-[35%]  ">
            <div className="w-[80%]">
              <InputsTemplate span label="Serial No" />
            </div>
            <Form
              className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 "
              name="basic"
              labelCol={{ span: 19 }}
              wrapperCol={{ span: 12 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className={"flex gap-4 w-full"}>
                <div className="w-[55%]  ">
                  <InputsTemplate
                    span
                    label="DNO. Tag No"
                    inputStyle={{ width: 40 }}
                  />
                </div>
                <div className="w-[18%]  mr-[25px]">
                  <CheckboxTemlate withBorders label="Active?" />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded mr-2 "
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 22 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <div className="w-full">
          <InputsTemplate span label="Apparatus Covered" />
        </div>
        <div className="w-full">
          <InputsTemplate span label="Guarantee Of Isolation" />
        </div>
        <div className="w-full">
          <InputsTemplate span label="Guarantee Of De-energising" />
        </div>
        <div className="w-full">
          <InputsTemplate span label="Apparatus on which Work is Authorized" />
        </div>
        <div className="w-full">
          <InputsTemplate span label="Describtion Of Work and / Test Test" />
        </div>
        <div className="w-full">
          <TextareaTemplate label="Guarantee Items" labelCol={5} height={60} />
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded mr-2  "
        name="basic"
        labelCol={{ span: 15 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <div className="flex  w-full ">
          <div className="w-[45%] ">
            <div className="w-full">
              <InputSelect_template label="Prepared Type" />
            </div>
          </div>
          <div className="w-[30%] ">
            <Form.Item className=" ">
              <div className="w-full">
                <InputSelect_template label="Checked By" />
              </div>
            </Form.Item>
          </div>
          <div className="w-[30%] ">
            <Form.Item className=" ">
              <div className="w-full">
                <InputSelect_template label="Guaranteed By" />
              </div>
            </Form.Item>
          </div>
          <div className="w-[30%] ">
            <Form
              className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
              name="basic"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className="w-full">
                <InputsTemplate span label="No Tags Placed" />
              </div>
            </Form>
          </div>
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded mr-2 "
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <div className="flex">
          <div className="flex  w-full ">
            <Form.Item
              label={<p className="text-xs">{"Table-A Station Guarantee"}</p>}
              style={{ marginBottom: 0 }}
            >
              <div className="w-[100%]">
                <Datagrid_template
                  gridheight={150}
                  columns={[]}
                  data={[]}
                  disableGroupPanel
                  disablePaging
                  disableSearch
                />
              </div>
            </Form.Item>
          </div>
          <div className="flex  w-full ">
            <Form.Item
              label={
                <p className="text-xs">
                  {"Table-B Other Permit or Authorised Personnel"}
                </p>
              }
              style={{ marginBottom: 0 }}
            >
              <div className="w-[100%]">
                <Datagrid_template
                  gridheight={150}
                  columns={[]}
                  data={[]}
                  disableGroupPanel
                  disablePaging
                  disableSearch
                />
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-1 mr-2 "
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <div className="w-full">
          <TextareaTemplate
            label="Instructions to Permit Holder"
            labelCol={5}
            height={40}
          />
        </div>
      </Form>
      <Form
        className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
        name="basic"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 22 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <section className="w-full flex">
          <aside className="w-[100%] ">
            <div className="flex  w-full gap-2">
              <div className="w-[62%]">
                <div className="w-full ml-1">
                  <InputSelect_template label="Accepted By" />
                </div>
              </div>
              <div className="w-[50%]">
                <Form.Item className="ml-[72px] ">
                  <div className="w-[98%]">
                    <InputSelect_template label="Issued By" />
                  </div>
                </Form.Item>
              </div>
              <div className="w-[30%]">
                <Form.Item className="ml-[14px] ">
                  <div className="w-full">
                    <DateTemplate label="Issued Time" />
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="flex  w-full gap-2">
              <div className="w-[62%]">
                <div className="w-full ml-1">
                  <InputSelect_template label="Surrendered By" />
                </div>
              </div>
              <div className="w-[50%]">
                <Form.Item className=" ">
                  <div className="w-full">
                    <InputSelect_template label="Surrender Accepted By" />
                  </div>
                </Form.Item>
              </div>
              <div className="w-[30%] ">
                <Form.Item className=" ">
                  <div className="w-full">
                    <DateTemplate label="Time Accepted" />
                  </div>
                </Form.Item>
              </div>
            </div>
            <Form
              className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
              name="basic"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className="flex  w-full  gap-2">
                <div className="w-[100%] ">
                  <div className="w-full ">
                    <InputsTemplate span label="Service Notes" />
                  </div>
                </div>
                <Form
                    className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded w-[33.5%]  "
                    name="basic"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 8 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size="small"
                    layout="horizontal"
                  >
                <div className="w-[100%]">
                 
                    <div className="w-[100%]">
                      <InputSelect_template label="Released for Service By" />
                    </div>
                </div>
                </Form>
              </div>
            </Form>
          </aside>
          <aside className="h-full  w-[5%] mr-1">
            <button
              style={{
                height: "40px",
                width: "100%",
              }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center w-full mb-1 ml-0.5 border-2 border-slate-200 rounded gap-2"
            >
              <img className="px-0.5" src={saveBtn} />
              <p className="text-sm">{"Save"}</p>
            </button>
            <button
              style={{
                height: "40px",
                width: "100%",
              }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center w-full mb-1 ml-0.5 border-2 border-slate-200 rounded gap-2"
            >
              <img className="px-0.5" src={newBtn} />
              <p className="text-sm">{"New"}</p>
            </button>
          </aside>
        </section>
      </Form>
    </div>
  );
};
