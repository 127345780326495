import refresh from "../../../../../../assets/refresh.png";
import edit from "../../../../../../assets/edit.png";
import close from "../../../../../../assets/close.png";
import reset from "../../../../../../assets/reset.png";
import save from "../../../../../../assets/save.png";
import save2 from "../../../../../../assets/save2.png";
import newIcon from "../../../../../../assets/new.png";
import { Mini_datagrid_template } from "../data/mini_datagrid";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { DatePicker, Form, Select, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  saveModalData,
  api_url,
  updateModalData,
} from "../../../../../accessories/component_infos";
import { inventory_checklist } from "../data/mini_datagrid_cols";
import { GetDataFunc } from "../../../../../../functions/get";
import { Data } from "@syncfusion/ej2-react-grids";
import { truncate } from "lodash";
import { UpdateStates } from "../../../../../../functions/updateStates";
interface props {
  selectedRecord?: any;
  openSelectedTask?: any;
  pmaId?: string;
  updateStates?: (key: string, val: any) => void;
  statesx?: any;
  regNo?: string;
}

interface stateTypes {
  job?: string;
  date?: string;
  description?: string;
  remarks?: string;
  inventorySelected?: any;
}

export const ActivitiesCapture_Job_Details = ({
  selectedRecord,
  openSelectedTask,
  pmaId,
  updateStates,
  statesx,
  regNo,
}: props) => {
  const dispatch = useDispatch();
  // states initialization
  const [states, setStates] = useState<stateTypes>({
    job: "",
    date: "",
    description: "",
    remarks: "",
    inventorySelected: [],
  });
console.log("REGNO", regNo);
  //  Onchange fxn
  const setState = (key: string, value: any) => {
    setStates({ ...states, [key]: value });
  };
  const [inventory_List, setInventory_List] = useState<any>([]);
  const isInventory = useSelector(
    (state: RootState) => state.general.IsinventorySelected
  );
  const inventorySelected = useSelector(
    (state: RootState) => state.general.inventorySelected
  );
  const maintenanceType = useSelector(
    (state: RootState) => state.general.maintenanceType
  );
  const RegNo = useSelector((state: RootState) => state.general.RegNo);
  // console.log("maintenanceType", maintenanceType?.name);
  // console.log("RegNo", RegNo?.name);

  const [inventoryChecklist, setInventoryChecklist] = useState([]);
  console.log("inventory Selected", states.inventorySelected);
  const [inventory_data, inventory_error, inventory_loading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsPmInventoryChecklistDetail?pidPmActivityIDx=${pmaId}`
  );
  const [model_id, model_id_error, model_id_loading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsVehicleDetail3IDs?vdtRegNo=${ RegNo?.name}`
  );
  console.log("model_id", model_id[0]?.mdlIdpk);

  const [inventory_ListData] =
    useFetch(
      `PmActivitiesCaptures/CapturesGetMtiIDpk?MaintenanceType=${
        maintenanceType?.name
      }&modelIDx=${model_id[0]?.mdlIdpk}&FormId=${0}`
    );

    const [gridStates, setgridStates] = useState<any>({
      pidBefore : false,
      pidAfter : false,
      pidRmks: "",
    });

    const appendColumns = (data:any[]) =>{
      const temp :any = [...data]

        for(let i=0; i < temp.length; i++){
          // Add new properties to each object in the array
          temp[i] = {
            ...temp[i],
            pidBefore : gridStates.pidBefore,
            pidAfter : gridStates.pidAfter,
            pidRemark:gridStates.pidRmks
          }
        }

        return temp;
    }

    console.log(gridStates)


  // console.log("inventory_List", inventory_List);
  // Mini datagrid function
  //----------------------------------------------------------------
  // const onChangeRecord: any = useCallback<any>((e: any) => {
  //   e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
  //     // setSelectedTask_update(item);
  //     if (setState !== undefined) {
  //       setState("inventorySelected", item);
  //       dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  //       dispatch(setGeneralValue({ expr: "inventorySelected", value: [item] }));
  //       updateStates && updateStates("isGridData", true);
  //     } else {
  //       console.log("Error Occured");
  //     }
  //   });
  // }, []);

  // double click feature
  // const allowUpdate = (e: any) => {
  //   if (setState !== undefined) {
  //     setState("inventorySelected", e.data);
  //     dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
  //     dispatch(settask_footer_update(true));
  //   }
  // };

  const addedCols = { pidAfter: false, pidBefore: false ,pidRemark:""};
const modInventoryList = async () => {
  // const inventory_ListData = await GetDataFunc(`PmActivitiesCaptures/CapturesGetMtiIDpk?MaintenanceType=${
  //         maintenanceType?.name
  //       }&modelIDx=${model_id[0]?.mdlIdpk}&FormId=${0}`);
  console.log('&^% inv list', inventory_ListData);
  if(inventory_ListData.length > 0 )
  setInventory_List(inventory_ListData.forEach((item:any) => Object.assign(item, addedCols)));
}
useEffect(() => {
  modInventoryList();
},[inventory_ListData]);

  return (
    <div>
      {/* Datagrid */}

      <div className="p-2">

      
        
        <Datagrid_template
          columns={inventory_checklist}
          data={
            statesx?.isGridData === true
              ? inventory_data
              : appendColumns(inventory_ListData) ?? inventory_data
          } //
          gridheight={440}
          deselectFirstRow
          selectedItemsChange={(item: any) => {
            setState("inventorySelected", item);
            // 
            UpdateStates("pidAfter", !gridStates.pidAfter, setgridStates)
            UpdateStates("pidBefore", !gridStates.pidBefore, setgridStates)
            UpdateStates("pidRmks", !gridStates.pidRmks, setgridStates)
            dispatch(
              setGeneralValue({ expr: "inventorySelected", value: item })
            );
            console.log("selectedItemsChange", item);
          }}
          allowColumnEditing={true}
          selectionMode={"multiple"}
          showSelectionCheckBox="always"
          rowAlternationEnabled={false}
          disableGroupPanel
          disablePaging
          disableSearch
        />
        {/* <Mini_datagrid_template
          columns={inventory_checklist}
          data={statesx?.isGridData === true ? inventory_data:inventory_model??inventory_model}
          gridheight={440}
          setState={setState}
          onChangeRecord={onChangeRecord}
          updateMode={allowUpdate}
        /> */}
      </div>
    </div>
  );
};
