import { Checkbox, DatePicker, Input, Select } from 'antd';
import dayjs from "dayjs";
import { Tooltip } from "devextreme-react";
import 'devextreme/dist/css/dx.light.css';
import { useEffect, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import alter from '../../../assets/alter.png';
import analysis from '../../../assets/analysis.png';
import black_checkmark from '../../../assets/black_checkmark.png';
import blue_checkmark from '../../../assets/blue_checkmark.png';
import close from '../../../assets/close.png';
import delete_file from '../../../assets/delete_file.png';
import edit from '../../../assets/edit.png';
import email from '../../../assets/email.png';
import green_checkmark from '../../../assets/green_checkmark.png';
import lock from '../../../assets/lock.png';
import lock_selected from '../../../assets/lock_selected.png';
import newfile from '../../../assets/new.png';
import printer from '../../../assets/printer.png';
import question from '../../../assets/question.png';
import refresh from '../../../assets/refresh.png';
import statusLoader from '../../../assets/statusloader.gif';
import { task_footer_update, task_form_disable } from "../../../features/Task&Todo/Task/TaskDetails";
import { setGeneralValue, settask_footer_update, settask_form_disable } from "../../../features/generalSlice";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
interface props {
    toggler?: () => void
    print_toggler?: () => void
    paState?: any
    pasetState?: (key: string, value: any) => void
    gridRefX?: any
    onExport?: (e: any) => void
    handleSave?: () => void 
    handleNew?: () => void 
    
    searchTextOnchange?: (value: string) => void 
    startDateChanged?: (value: any) => void 
    endDateChanged?: (value: any) => void 
    checkOnChange?: (value: any) => void 
    handleFind?: () => void 
    handleRefresh?: () => void 
    handlePrint?: () => void 
    handleLock?: (e: any) => void
    handleLockAll?: () => void
    handleVerify?: () => void
    handleToggleChart?: () => void
    handleAuth?: () => void
    handleDelete?: () => void
    handleUpdateReturns?: () => void
    handleSMS?: () => void
    searchCriteriaOnchange?: (index: number, criteria: string) => void
    checkBoxAfterHelpOnchange?: (checkValue: boolean) => void 
    disableNew_edit?: boolean
    withDates?: boolean 
    outerSelected?: any
    setOuterSelected?: () => void
    checkBoxAfterHelp?: boolean
    searchCriteria?: boolean
    assignmentsForm?: boolean
    searchCriteriaData?: { id: number, value: string }[],
    startDate?: string
    endDate?: string
    setSwitch_change?:(e:boolean)=>void;
}



export const Fuel_transactiontoolbar = ({
    toggler,
    print_toggler,
    paState,
    pasetState,
    gridRefX,
    onExport,
    startDate,
    endDate,
    searchCriteriaOnchange,
    searchCriteriaData = [],
    searchCriteria,
    handleNew: newButtonClick,
    handleRefresh,
    handlePrint,
    searchTextOnchange,
    handleSave,
    
    startDateChanged,
    endDateChanged,
    handleFind,
    checkOnChange,
    handleLock,
    handleLockAll,
    handleVerify,
    handleToggleChart,
    handleAuth,
    handleDelete,
    outerSelected,
    setOuterSelected,
    setSwitch_change,
    handleUpdateReturns,
    handleSMS,
}: props) => {


    const dateFormat = " DD MMM YYYY";
    const dispatch = useDispatch()

    const [searchValue, setSearchValue] = useState<string>();

    
    const disable = useSelector((state: RootState) => state.general.formDisabled);
    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.general.updateMode);
    const selectedRecord = useSelector((state: RootState) => state.safetyManagerDetails.selectedRecord)
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)
    const sideAlert = useSelector((state:RootState)=>state.general.sideAlertTest)

    const [criteriaDataLocal, setCriterialDataLocal] = useState({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.value ?? '' });

    const [indeterminate, setIndeterminate] = useState<any>(true);
    const [checkedBox, setCheckedBox] = useState<any>(false);
    const [checkValue, setCheckValue] = useState<boolean | string>(''); 
    

    const [warningModal, setWarningModal] =  useState<boolean>(false)
    const [warningModalData, setWarningModalData] = useState<{title:string, message:string}>({title:'Select Record',message:'Please select a record'})

  const [privType, setPrivType] = useState<string>("save")
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType, )

    useEffect(() => {
        dispatch(setGeneralValue({ expr: 'activeStatus', value: checkValue }))
        checkOnChange && checkOnChange(checkValue);
    }, [checkValue])

    const onChange = (value: boolean) => {
        if (indeterminate === true) {
            setCheckedBox(true);
            setIndeterminate(false);
            setCheckValue(true);
        }
        else if (!indeterminate && checkedBox) {
            setCheckedBox(false);
            setIndeterminate(false);
            setCheckValue(false)
        }
        else if (!indeterminate && !checkedBox) {
            setCheckedBox(false);
            setIndeterminate(true);
            setCheckValue('');
        }
    };

    const newAccessPriv = () => {
        if (savePriv === true) {
            handleNew()
        }else{
            setWarningModal(true)
            setWarningModalData({title:'Access Denied!',message:'No Access Privilege'})
        }
    }

    const editAccessPriv = () => {
        if (updatePriv === true) {
            handleUpdate()
    }else{
        setWarningModal(true)
        setWarningModalData({title:'Access Denied!',message:'No Access Privilege'})
    }
}

    const findAccessPriv = () => {
        if (readPriv === true) {
            if (handleFind) { 
                handleFind() 
            } else { }
        }else{
            setWarningModal(true)
            setWarningModalData({title:'Access Denied!',message:'No Access Privilege'})
        }
    }

    const printAccessPriv = () => {
        if (readPriv === true) {
            
        }else{
            setWarningModal(true)
            setWarningModalData({title:'Access Denied!',message:'No Access Privilege'})
        }
    }


    
    const handleNew = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
        setSwitch_change&&setSwitch_change(true);
        dispatch(task_form_disable(false));
        dispatch(settask_form_disable(false));

        
        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))


        

        if( setOuterSelected !== undefined){
            setOuterSelected()
         }

        
        dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    };

    const handleCancel = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(task_form_disable(true));
        dispatch(settask_form_disable(true))
        
        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))


        
        dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

        
        
    };

    const OnModalOk = () =>{
        setWarningModal(false);
    }

    const OnModalCancel = () =>{
        setWarningModal(false);
    }

    const setModal=() => {
        setWarningModal(true)
        setWarningModalData({title:'Select record',message:'Please select a record to edit'})
    }

    
    const handleUpdate = () => {
        setSwitch_change&&setSwitch_change(true)
        if (outerSelected) {
            if(Object.keys(outerSelected).length < 1){
                setModal();
                dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
                
            dispatch(task_form_disable(true));
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))


            }else{
                
            dispatch(task_form_disable(false));

            
            dispatch(task_footer_update(true))
            dispatch(setGeneralValue({ expr: 'formDisabled', value: false }))
            dispatch(setGeneralValue({ expr: 'updateMode', value: true }))
            }
            
        }
        else {
            
            dispatch(task_form_disable(false));

            
            dispatch(task_footer_update(true))
            dispatch(setGeneralValue({ expr: 'formDisabled', value: false }))
            dispatch(setGeneralValue({ expr: 'updateMode', value: true }))
        }
    }

    const noRecordSelectedModalData = {
        message: `Please select a record to perform this function`,
        title: 'No Record Selected! ',
      }

    const lockAccessPriv = () => {
        setSwitch_change&&setSwitch_change(true);
        if (Object.keys(outerSelected).length < 1){
            setWarningModal(true)
            setWarningModalData(noRecordSelectedModalData)
            dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
                
            dispatch(task_form_disable(true));
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))
        }else if(handleLock) {
            handleLock(true)
        }
    }

    const lockAllAccessPriv = () => {
        setSwitch_change&&setSwitch_change(true);
        if(Object.keys(outerSelected).length < 1){
            setWarningModal(true)
            setWarningModalData(noRecordSelectedModalData)
            dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
                
            dispatch(task_form_disable(true));
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))
        }else if (handleLockAll){
            handleLockAll()
        }
    }

    const verifyAccessPriv = () => {
        setSwitch_change&&setSwitch_change(true);
        if(Object.keys(outerSelected).length < 1){
            setWarningModal(true)
            setWarningModalData(noRecordSelectedModalData)
            dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
                
            dispatch(task_form_disable(true));
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))
        }else if (handleVerify){
            handleVerify()
        }
    }
    
    const deleteAccessPriv = () => {
        setSwitch_change&&setSwitch_change(true);
        if(Object.keys(outerSelected).length < 1){
            setWarningModal(true)
            setWarningModalData(noRecordSelectedModalData)
            dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
                
            dispatch(task_form_disable(true));
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))
        }else if (handleDelete){
            handleDelete()
        }
    }

    const authFuelRequestPriv = () => {
        setSwitch_change&&setSwitch_change(true);
        if(Object.keys(outerSelected).length < 1){
            setWarningModal(true)
            setWarningModalData(noRecordSelectedModalData)
            dispatch(setGeneralValue({ expr: 'updateMode', value: false }))
                
            dispatch(task_form_disable(true));
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }))
        }else if (handleAuth){
            handleAuth()
        }
    }


    return (
        <>
         <ModalTemplate  icon= {'warning'} cancelHandler = {OnModalCancel} disableCancel = {true}  open = {warningModal} okHandler={OnModalOk}  message= {warningModalData.message} okText= {'Ok'} title={warningModalData.title}/>
        <div style={{ width: '4000px',borderColor:borderTheme }} className=" h-full flex border-[1px] rounded justify-between border-gray-200 mb-1 mx-1">
            <div className="flex justify-center" >
                <ul className="flex justify-evenly w-full items-center h-full px-0.5">
                    {/* new and cancel section */}
                    {(disable || updateMode) ?
                        <li id="newRecord" onClick={() => newAccessPriv()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={newfile} />New </span>
                            <Tooltip
                                target="#newRecord"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">New Record</p>
                            </Tooltip>
                        </li>
                        :
                        <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={close} />Cancel </span>
                            <Tooltip
                                target="#close-cancel"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Cancel</p>
                            </Tooltip>
                        </li>
                    }
                    {!updateMode ?
                        <li id="editRecordst" onClick={() => { editAccessPriv() }} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} />Edit </span>
                            <Tooltip
                                target="#editRecordst"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Edit Record</p>
                            </Tooltip>
                        </li>
                        :
                        <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={close} />Cancel </span>
                            <Tooltip
                                target="#close-cancel"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Cancel</p>
                            </Tooltip>
                        </li>
                    }


                    <li id="switch" onClick={toggler} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100" src={alter} />Switch</span>
                        <Tooltip
                            target="#switch"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Switch interface / table view</p>
                        </Tooltip>
                    </li>
                    <li id="lock_selected" onClick={lockAccessPriv} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={lock_selected} /></span>
                        <Tooltip
                            target="#lock_selected"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Lock selected transaction</p>
                        </Tooltip>
                    </li>

                    <li id="lock" onClick={lockAllAccessPriv} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={lock} /></span>
                        <Tooltip
                            target="#lock"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Lock all transaction for selected month</p>
                        </Tooltip>
                    </li>
                    <li id="email" style={{ borderWidth: '1px',borderColor:borderTheme }} onClick={()=>{handleSMS && handleSMS()}} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={email} /></span>
                        <Tooltip
                            target="#email"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Send SMS to selected driver</p>
                        </Tooltip>
                    </li>

                    <li id="black_checkmark" onClick={verifyAccessPriv} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100" src={black_checkmark} /></span>
                        <Tooltip
                            target="#black_checkmark"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Verify</p>
                        </Tooltip>
                    </li>

                    <li id="analysis" onClick={handleToggleChart} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={analysis} /></span>
                        <Tooltip
                            target="#analysis"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Switch between charts and entries window</p>
                        </Tooltip>
                    </li>

                    <li id="green_checkmark" onClick={authFuelRequestPriv} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={green_checkmark} /></span>
                        <Tooltip
                            target="#green_checkmark"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Authorize fuel requests</p>
                        </Tooltip>
                    </li>

                    <li id="blue_checkmark" onClick={handleUpdateReturns} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={blue_checkmark} /></span>
                        <Tooltip
                            target="#blue_checkmark"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Update fuel returns report</p>
                        </Tooltip>
                    </li>

                    <li id="delete_file" onClick={deleteAccessPriv} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={delete_file} /></span>
                        <Tooltip
                            target="#delete_file"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Delete fuel requests</p>
                        </Tooltip>
                    </li>



                    <li id="help" style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img alt="" className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={question} /> </span>
                        <Tooltip
                            target="#help"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Help Center</p>
                        </Tooltip>
                    </li>


                    {/* Differents starts here */}

                    <li className="items-right ml-3 ">
                        <label className=' text-xs px-2'>Search Criteria</label>
                        <Select
                            value={criteriaDataLocal}
                            dropdownMatchSelectWidth={false}
                            className=" mt-0.5"
                            onChange={(data: any) => {
                                const criteriaData = JSON.parse(data);
                                setCriterialDataLocal({ id: criteriaData?.index, value: criteriaData?.criteria }) 
                                searchCriteriaOnchange && searchCriteriaOnchange(criteriaData?.index, criteriaData?.criteria); 
                            }}
                            id="search"
                            size="small"
                            showSearch={true}
                            style={{ width: `${sideAlert===true?20:110}`, height: '25px' }}>
                            {searchCriteriaData?.map(({ id, value }, index) =>
                                <Select.Option key={id} value={JSON.stringify({ index: index, criteria: value })}>{value}</Select.Option>
                            )}
                        </Select>

                    </li>


                    <li className="px-1 flex items-center ml-2 ">
                        <label className='w-[70px] flex text-xs'>Search Text</label>
                        <Input
                            className="w-[100px]"
                            onChange={(e: any) => {
                                const searchText: string = e.target.value;
                                searchTextOnchange && searchTextOnchange(searchText);
                                setSearchValue(e.target.value);
                            }}
                            value={searchValue}
                            name="search"
                            id="search"
                            size="small"
                            allowClear={true}
                            style={{ width:`${sideAlert===true?20:90}`, height: '24px' }} />
                    </li>
                    <li className="px-0.5">
                        <DatePicker allowClear={false} size="small" placeholder="Start Date" style={{ width: 125 }} format={'DD MMM YYYY'} value={dayjs(startDate)} onChange={startDateChanged} />
                    </li>
                    <li className="px-0.5">
                        <DatePicker allowClear={false} size="small" placeholder="End Date" style={{ width: 125 }} format={'DD MMM YYYY'} value={dayjs(endDate)} onChange={endDateChanged} />
                    </li>
                    <li style={{ borderWidth: '1px',borderColor:borderTheme }} id="status" className="hover:cursor-pointer mr-1  text-xs  px-1  text-gray-600 rounded flex items-center">
                        <Checkbox
                            checked={checkedBox}
                            indeterminate={indeterminate}
                            onChange={(checkValue: any) => {
                                onChange(checkValue);
                            }}
                        />
                        <Tooltip
                            target="#status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Show Status</p>
                        </Tooltip>
                    </li>

                    <li style={{ borderWidth: '1px',borderColor:borderTheme }}
                        onClick={() => {
                            handleRefresh && handleRefresh();
                            setSearchValue('');

                            setCheckedBox(false);
                            setIndeterminate(true);
                            setCheckValue('');
                            setCriterialDataLocal({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.value ?? '' });
                        }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs p-1 text-gray-600 rounded flex items-center">
                        <span> <img alt="" className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100" src={refresh} /> </span>
                    </li>
                    <li id="find" style={{ borderWidth: '1px',borderColor:borderTheme }} onClick={() => { findAccessPriv() }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                        <span> <FaBinoculars size={15.5} color="#007AFF" /> </span>
                        <Tooltip
                            target="#find"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Find</p>
                        </Tooltip>
                    </li>
                    {/* <li id="print" style={{ borderWidth: '1px',borderColor:borderTheme }} onClick={printAccessPriv} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                        <span> <img alt="" className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={printer} /> </span>
                        <Tooltip
                            target="#print"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Print / Export</p>
                        </Tooltip>
                    </li> */}

                    <li className="hover:cursor-pointer  px-0.5  flex items-center">
                        {disable && <p className=" italic font-semibold text-gray-600">Done.</p>}

                        {!disable && <div className="  flex flex-row items-center">
                            <img style={{ width: '31px', height: '31px' }} src={statusLoader} alt="gif" />
                            {!updateMode ?
                                <p className=" italic text-gray-600 text-xs">New Record</p>
                                :
                                <p className=" italic text-gray-600 text-xs">Editing</p>}
                        </div>}
                    </li>


                </ul>

            </div>


        </div>
        </>
    )
};

