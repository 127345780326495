export const energyReadingCol = [
  {
    id: 1,
    caption: "SUBSTATION",
    dataField: "stnName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "FEEDER",
    dataField: "fdrName",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "METER No",
    dataField: "mtrMeterNo",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "CUSTOMER",
    dataField: "cstName",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "DATE",
    dataField: "smrDate",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "smrActive",
    dataType: "boolean",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "FOR BILL?",
    dataField: "smrForBilling",
    fixed: false,
    dataType: "boolean",
    width: 100,
  },
  {
    id: 9,
    caption: "CURRENT ACTV. ENERGY (kWh)",
    dataField: "smrCurrentActiveEnergy",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "PREVIOUS ACTV ENERGY (kWh)",
    dataField: "smrPreviousActiveEnergy",
    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "ACTV ENERGY DIFF (kWh)",
    dataField: "smrActiveEnergyDifference",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "ACTV ENERGY LoR/ADJUST (kWh)",
    dataField: "smrActiveEnergyLossOfRegistration",
    fixed: false,
    width: 100,
  },
  {
    id: 13,
    caption: "ACTV ENERGY CONSUMED (kWh)",
    dataField: "smrActiveEnergyConsumed",
    fixed: false,
    width: 100,
  },
  {
    id: 14,
    caption: "CURRENT Rx ENERGY (kVArh)",
    dataField: "smrCurrentReactiveEnergy",
    fixed: false,
    width: 100,
  },
  {
    id: 15,
    caption: "PREVIOUS Rx ENERGY (kVArh)",
    dataField: "smrPreviousReactiveEnergy",
    fixed: false,
    width: 100,
  },
  {
    id: 16,
    caption: " RxTV ENERGY DIFF (kVArh)",
    dataField: "smrReactiveEnergyDifference",
    fixed: false,
    width: 100,
  },
 
  {
    id: 17,
    caption: " Rx POW LoR/ADJ (kVArh)",
    dataField: "rxPowLoRAd",
    fixed: false,
    width: 100,
  },
  {
    id: 18,
    caption: " Rx ENERGY CONSUMED (kVArh)",
    dataField: "smrReactiveEnergyConsumed",
    fixed: false,
    width: 100,
  },
  {
    id: 19,
    caption: " POWER FACTOR",
    dataField: "emaPowInfrasLevyRate",
    fixed: false,
    width: 100,
  },
  {
    id: 20,
    caption: " MAX. DEMAND",
    dataField: "smrMaximumDemand",
    fixed: false,
    width: 100,
  },
  {
    id: 21,
    caption: " REMARKS",
    dataField: "smrRmks",
    fixed: false,
    width: 300,
  },
];

 export const energyReadingData = [
    {
        id: 1,
        substation: "Substation A",
        feeder: "Feeder 1",
        meterNo: "M123456",
        time: "08:00",
        customer: "Customer 1",
        date: "2023-06-25",
        active: true,
        forBill: true,
        currentActvEnergy: 1500,
        previousActvEnergy: 1400,
        actvEnergyDiff: 100,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 95,
        currentReactvEnergy: 300,
        previousReactvEnergy: 250,
        reactvEnergyDiff: 50,
        rxTvEnergyDiff: 45,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 48,
        powerFactor: 0.95,
        maxdemand: 75,
        remarks: "Normal operation",
      },
      {
        id: 2,
        substation: "Substation B",
        feeder: "Feeder 2",
        meterNo: "M789012",
        time: "09:00",
        customer: "Customer 2",
        date: "2023-06-24",
        active: false,
        forBill: false,
        currentActvEnergy: 1600,
        previousActvEnergy: 1550,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 320,
        previousReactvEnergy: 300,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 18,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 19,
        powerFactor: 0.90,
        maxdemand: 85,
        remarks: "Maintenance scheduled",
      },
      {
        id: 3,
        substation: "Substation C",
        feeder: "Feeder 3",
        meterNo: "M345678",
        time: "10:00",
        customer: "Customer 3",
        date: "2023-06-23",
        active: true,
        forBill: true,
        currentActvEnergy: 1700,
        previousActvEnergy: 1650,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 340,
        previousReactvEnergy: 320,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 90,
        remarks: "Normal operation",
      },
      {
        id: 4,
        substation: "Substation D",
        feeder: "Feeder 4",
        meterNo: "M901234",
        time: "11:00",
        customer: "Customer 4",
        date: "2023-06-22",
        active: false,
        forBill: true,
        currentActvEnergy: 1800,
        previousActvEnergy: 1750,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 2,
        actvEnergyConsumed: 48,
        currentReactvEnergy: 360,
        previousReactvEnergy: 340,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 21,
        rxPowLoRAd: 4,
        rxEnergyConsumed: 20,
        powerFactor: 0.89,
        maxdemand: 80,
        remarks: "Issue with meter readings",
      },
      {
        id: 5,
        substation: "Substation E",
        feeder: "Feeder 5",
        meterNo: "M567890",
        time: "12:00",
        customer: "Customer 5",
        date: "2023-06-21",
        active: true,
        forBill: false,
        currentActvEnergy: 1900,
        previousActvEnergy: 1850,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 380,
        previousReactvEnergy: 360,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 21,
        powerFactor: 0.93,
        maxdemand: 95,
        remarks: "Check for voltage fluctuations",
      },
      {
        id: 6,
        substation: "Substation F",
        feeder: "Feeder 6",
        meterNo: "M135790",
        time: "13:00",
        customer: "Customer 6",
        date: "2023-06-20",
        active: false,
        forBill: true,
        currentActvEnergy: 2000,
        previousActvEnergy: 1950,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 400,
        previousReactvEnergy: 380,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 20,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 23,
        powerFactor: 0.91,
        maxdemand: 85,
        remarks: "Peak load testing",
      },
      // Additional 20 entries
      {
        id: 7,
        substation: "Substation G",
        feeder: "Feeder 7",
        meterNo: "M246802",
        time: "14:00",
        customer: "Customer 7",
        date: "2023-06-19",
        active: true,
        forBill: false,
        currentActvEnergy: 2100,
        previousActvEnergy: 2050,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 420,
        previousReactvEnergy: 400,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 18,
        powerFactor: 0.94,
        maxdemand: 90,
        remarks: "Normal operation",
      },
      {
        id: 8,
        substation: "Substation H",
        feeder: "Feeder 8",
        meterNo: "M864209",
        time: "15:00",
        customer: "Customer 8",
        date: "2023-06-18",
        active: false,
        forBill: true,
        currentActvEnergy: 2200,
        previousActvEnergy: 2150,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 440,
        previousReactvEnergy: 420,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 100,
        remarks: "Transformer issue",
      },
      {
        id: 9,
        substation: "Substation I",
        feeder: "Feeder 9",
        meterNo: "M975310",
        time: "16:00",
        customer: "Customer 9",
        date: "2023-06-17",
        active: true,
        forBill: false,
        currentActvEnergy: 2300,
        previousActvEnergy: 2250,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 460,
        previousReactvEnergy: 440,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 21,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 20,
        powerFactor: 0.90,
        maxdemand: 85,
        remarks: "Regular maintenance",
      },
      {
        id: 10,
        substation: "Substation J",
        feeder: "Feeder 10",
        meterNo: "M102938",
        time: "17:00",
        customer: "Customer 10",
        date: "2023-06-16",
        active: false,
        forBill: true,
        currentActvEnergy: 2400,
        previousActvEnergy: 2350,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 480,
        previousReactvEnergy: 460,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 21,
        powerFactor: 0.93,
        maxdemand: 95,
        remarks: "Voltage drop",
      },
      {
        id: 11,
        substation: "Substation K",
        feeder: "Feeder 11",
        meterNo: "M293847",
        time: "18:00",
        customer: "Customer 11",
        date: "2023-06-15",
        active: true,
        forBill: false,
        currentActvEnergy: 2500,
        previousActvEnergy: 2450,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 500,
        previousReactvEnergy: 480,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 100,
        remarks: "Normal operation",
      },
      {
        id: 12,
        substation: "Substation L",
        feeder: "Feeder 12",
        meterNo: "M384756",
        time: "19:00",
        customer: "Customer 12",
        date: "2023-06-14",
        active: false,
        forBill: true,
        currentActvEnergy: 2600,
        previousActvEnergy: 2550,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 520,
        previousReactvEnergy: 500,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 21,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 20,
        powerFactor: 0.90,
        maxdemand: 85,
        remarks: "Transformer upgrade",
      },
      {
        id: 13,
        substation: "Substation M",
        feeder: "Feeder 13",
        meterNo: "M475849",
        time: "20:00",
        customer: "Customer 13",
        date: "2023-06-13",
        active: true,
        forBill: false,
        currentActvEnergy: 2700,
        previousActvEnergy: 2650,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 540,
        previousReactvEnergy: 520,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 21,
        powerFactor: 0.93,
        maxdemand: 95,
        remarks: "Regular maintenance",
      },
      {
        id: 14,
        substation: "Substation N",
        feeder: "Feeder 14",
        meterNo: "M576941",
        time: "21:00",
        customer: "Customer 14",
        date: "2023-06-12",
        active: false,
        forBill: true,
        currentActvEnergy: 2800,
        previousActvEnergy: 2750,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 560,
        previousReactvEnergy: 540,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 100,
        remarks: "Voltage issue",
      },
      {
        id: 15,
        substation: "Substation O",
        feeder: "Feeder 15",
        meterNo: "M679032",
        time: "22:00",
        customer: "Customer 15",
        date: "2023-06-11",
        active: true,
        forBill: false,
        currentActvEnergy: 2900,
        previousActvEnergy: 2850,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 580,
        previousReactvEnergy: 560,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 21,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 20,
        powerFactor: 0.90,
        maxdemand: 85,
        remarks: "Transformer replacement",
      },
      {
        id: 16,
        substation: "Substation P",
        feeder: "Feeder 16",
        meterNo: "M780123",
        time: "23:00",
        customer: "Customer 16",
        date: "2023-06-10",
        active: false,
        forBill: true,
        currentActvEnergy: 3000,
        previousActvEnergy: 2950,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 600,
        previousReactvEnergy: 580,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 21,
        powerFactor: 0.93,
        maxdemand: 95,
        remarks: "Normal operation",
      },
      {
        id: 17,
        substation: "Substation Q",
        feeder: "Feeder 17",
        meterNo: "M891234",
        time: "00:00",
        customer: "Customer 17",
        date: "2023-06-09",
        active: true,
        forBill: false,
        currentActvEnergy: 3100,
        previousActvEnergy: 3050,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 620,
        previousReactvEnergy: 600,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 100,
        remarks: "Voltage regulation",
      },
      {
        id: 18,
        substation: "Substation R",
        feeder: "Feeder 18",
        meterNo: "M912345",
        time: "01:00",
        customer: "Customer 18",
        date: "2023-06-08",
        active: false,
        forBill: true,
        currentActvEnergy: 3200,
        previousActvEnergy: 3150,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 640,
        previousReactvEnergy: 620,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 21,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 20,
        powerFactor: 0.90,
        maxdemand: 85,
        remarks: "Transformer maintenance",
      },
      {
        id: 19,
        substation: "Substation S",
        feeder: "Feeder 19",
        meterNo: "M023456",
        time: "02:00",
        customer: "Customer 19",
        date: "2023-06-07",
        active: true,
        forBill: false,
        currentActvEnergy: 3300,
        previousActvEnergy: 3250,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 660,
        previousReactvEnergy: 640,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 21,
        powerFactor: 0.93,
        maxdemand: 95,
        remarks: "Peak load monitoring",
      },
      {
        id: 20,
        substation: "Substation T",
        feeder: "Feeder 20",
        meterNo: "M134567",
        time: "03:00",
        customer: "Customer 20",
        date: "2023-06-06",
        active: false,
        forBill: true,
        currentActvEnergy: 3400,
        previousActvEnergy: 3350,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 680,
        previousReactvEnergy: 660,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 100,
        remarks: "Voltage issue",
      },
      {
        id: 21,
        substation: "Substation A",
        feeder: "Feeder 1",
        meterNo: "M123456",
        time: "08:00",
        customer: "Customer 1",
        date: "2023-06-25",
        active: true,
        forBill: true,
        currentActvEnergy: 1500,
        previousActvEnergy: 1400,
        actvEnergyDiff: 100,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 95,
        currentReactvEnergy: 300,
        previousReactvEnergy: 250,
        reactvEnergyDiff: 50,
        rxTvEnergyDiff: 45,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 48,
        powerFactor: 0.95,
        maxdemand: 75,
        remarks: "Normal operation",
      },
      {
        id: 22,
        substation: "Substation B",
        feeder: "Feeder 2",
        meterNo: "M789012",
        time: "09:00",
        customer: "Customer 2",
        date: "2023-06-24",
        active: false,
        forBill: false,
        currentActvEnergy: 1600,
        previousActvEnergy: 1550,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 320,
        previousReactvEnergy: 300,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 18,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 19,
        powerFactor: 0.90,
        maxdemand: 85,
        remarks: "Maintenance scheduled",
      },
      {
        id: 23,
        substation: "Substation C",
        feeder: "Feeder 3",
        meterNo: "M345678",
        time: "10:00",
        customer: "Customer 3",
        date: "2023-06-23",
        active: true,
        forBill: true,
        currentActvEnergy: 1700,
        previousActvEnergy: 1650,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 4,
        actvEnergyConsumed: 46,
        currentReactvEnergy: 340,
        previousReactvEnergy: 320,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 22,
        rxPowLoRAd: 3,
        rxEnergyConsumed: 23,
        powerFactor: 0.92,
        maxdemand: 90,
        remarks: "Normal operation",
      },
      {
        id: 24,
        substation: "Substation D",
        feeder: "Feeder 4",
        meterNo: "M901234",
        time: "11:00",
        customer: "Customer 4",
        date: "2023-06-22",
        active: false,
        forBill: true,
        currentActvEnergy: 1800,
        previousActvEnergy: 1750,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 2,
        actvEnergyConsumed: 48,
        currentReactvEnergy: 360,
        previousReactvEnergy: 340,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 21,
        rxPowLoRAd: 4,
        rxEnergyConsumed: 20,
        powerFactor: 0.89,
        maxdemand: 80,
        remarks: "Issue with meter readings",
      },
      {
        id: 25,
        substation: "Substation E",
        feeder: "Feeder 5",
        meterNo: "M567890",
        time: "12:00",
        customer: "Customer 5",
        date: "2023-06-21",
        active: true,
        forBill: false,
        currentActvEnergy: 1900,
        previousActvEnergy: 1850,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 5,
        actvEnergyConsumed: 45,
        currentReactvEnergy: 380,
        previousReactvEnergy: 360,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 19,
        rxPowLoRAd: 2,
        rxEnergyConsumed: 21,
        powerFactor: 0.93,
        maxdemand: 95,
        remarks: "Check for voltage fluctuations",
      },
      {
        id: 26,
        substation: "Substation F",
        feeder: "Feeder 6",
        meterNo: "M135790",
        time: "13:00",
        customer: "Customer 6",
        date: "2023-06-20",
        active: false,
        forBill: true,
        currentActvEnergy: 2000,
        previousActvEnergy: 1950,
        actvEnergyDiff: 50,
        actvEnergyLoRAd: 3,
        actvEnergyConsumed: 47,
        currentReactvEnergy: 400,
        previousReactvEnergy: 380,
        reactvEnergyDiff: 20,
        rxTvEnergyDiff: 20,
        rxPowLoRAd: 1,
        rxEnergyConsumed: 23,
        powerFactor: 0.91,
        maxdemand: 85,
        remarks: "Peak load testing",
      },
]