/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import { Form, Image } from "antd"
import newIcon from '../../../../../../../../assets/new.png';
import save from '../../../../../../../../assets/save2.png';
import TextArea from "antd/es/input/TextArea";
import { images_cols } from "../../data/datagrid_cols";
import Datagrid_template from "../../../../../../../../templates/Datagrid";



export const Right_Vehicle_Image = ({ fullPage }: { fullPage?: any }) => {
    return (
        <div style={{ height: fullPage }} className="w-full">

            <div className="pt-0.5 h-[50%] w-full border-4">
                <Form
                    className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
                    name="basic"

                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size="small"
                    layout="horizontal"
                >

                    <div className='w-full h-full'>
                        <div className='w-full h-3/5 mb-0.5'>
                            <span style={{ height: '45%' }}>
                                <Image
                                    width={'100%'}
                                    height={'100%'}
                                    src="error"
                                    alt="No Image Available"

                                />
                            </span>
                        </div>

                        {/* Request */}
                        <Form.Item className=' w-full h-2/5' labelCol={{ span: 6 }} label={<p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">{'Remarks'}</p>} >


                            <div className=" w-full h-full ">
                                <TextArea style={{ resize: 'none', height: 'calc(100px)' }} className='h-full' />
                                <ul className='w-full  flex h-full ' >


                                    <div className="w-3/6 flex justify-end">
                                        <button
                                            style={{ height: '' }}
                                            className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full ml-0.5 px-3 py-1 border-2 border-slate-200 rounded"
                                        >
                                            <img style={{ width: '30%', }} className="pr-1 " src={newIcon} />

                                        </button>
                                    </div>

                                    <div className="w-3/6 flex justify-start">
                                        <button
                                            style={{ height: '' }}
                                            className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full ml-0.5 px-3 py-1 border-2 border-slate-200 rounded"
                                        >
                                            <img style={{ width: '30%', }} className="pr-1 " src={save} />

                                        </button>
                                    </div>

                                </ul>
                            </div>



                        </Form.Item>

                    </div>


                </Form>

            </div>

            <div className="pt-0.5 h-[50%]">
                <Datagrid_template
                    gridheight={500}
                    columns={images_cols}
                    data={undefined} 
                    disablePaging={true}
                    disableGroupPanel
                    disableSearch
                    />
                 


            </div>
        </div>
    )
}