import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Select, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { RootState } from "../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { HrSetupsCustomToolbar } from "../../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { InputsTemplate } from "../../../../../../../templates/input";
import { RefreshButtonTemplate } from "../../../../../../../templates/RefreshButtonTemplate";
import EquipmentDataTable from "../../../../../../Safety/elogger/StationReadings/tabContents/EquipmentOutage/equipment-data-table";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { navbar_height } from "../../../../../../accessories/component_infos";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  AllGroupListColoumn,
  AllStationGroupColoumn,
  AllStationListColoumn,
} from "../data/elogBookDataPriv";
import {
  useCrudFunc,
  useQueryFetch,
} from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import Datagrid from "../../../../../../../templates/Datagrid";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import InputAndSelect_template from "../../../../../../../functions/Dry/InputAndSelect";


export default function ElogStationSetup() {
  //   const [form_height, set_form_height] = useState<number>(0);
  const [leftSectionHeight, setLeftSectionHeight] = useState<number>();
  const [privtype, setprivtype] = useState<string>("save");

  const [messageModal, setMessageModal] = useState<{
    icon: "warning" | "question" | "success";
    openModal: boolean;
    hideCancel: boolean;
    okText: string;
    cancelText: string;
    title: string;
    message: string;
    okHandler: () => void;
    cancelHandler: () => void;
  }>({
    icon: "warning",
    title: "",
    message: "",
    okText: "",
    cancelText: "",
    openModal: false,
    okHandler: () => {},
    cancelHandler: () => {},
    hideCancel: false,
  });
  //   const formRef = useRef<any>(null);
  const leftForm = useRef<any>(null);

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  var [datagridHeight, setDataGridHeight] = useState<number>(0);
  //   var [intialH, finalHeight] = useResizeMode(0);

  // //   var initialH = intialH - datagridHeight;
  //   var [fullPage] = useState(
  //     finalHeight - (navbar_height + news_flash_height + 35)
  //   );
  const [busyloader, setBusyloader] = useBusyLoader();
  const dispatch = useDispatch();
  const [empId, userId, userInfo] = useAccountInfo();
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);

  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const selectExprs = useSelector(
    (state: RootState) => state.general.selectExprs
  );
  const selectAfterExprs = useSelector(
    (state: RootState) => state.general.selectAfterExprs
  );
  const textareaExpr = useSelector(
    (state: RootState) => state.general.inputExprs.remarksExpr
  );
  const orderListExprs = useSelector(
    (state: RootState) => state.general.checkboxExprs
  );

  const [groupDetailsFormState, setGroupDetailsFormState] = useState({
    name: { id: 0, name: "" },
    shortName: { id: 0, name: "" },
    active: false,
    remarks: "",
  });
  const handleNew = () => {
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setSelected_Group({});
      setGroupDetailsFormState((prev: any) => ({
        ...prev,
        name: { id: 0, name: "" },
        shortName: { id: 0, name: "" },
        active: true,
        remarks: "",
      }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    }
  };

  const width = 480;
  const [intialH, finalHeight] = useResizeMode(0);
  const gridHeight = intialH - 420; // Adjust this value as needed

  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 130);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 25);
  const [createGroupDetails, updateGroupDetails] = useCrudFunc("powerapp");
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };
  const [searchParam, setSearchParam] = useState({
    searchTerm: { initialValue: "", value: "" },
    checkState: { initialValue: "", value: "" },
    pageNumber: 1,
    pageSize: 50,
  });
  const { searchTerm, checkState, pageNumber, pageSize } = searchParam;
  const [refreshButtons, setRefreshButton] = useState({
    allStationGroupsRefresh: false,
    stationListRefresh: false,
    groupListRefresh: false,
  });
  const [selected_Group, setSelected_Group] = useState<any>({});
  const [selectedStationList, setSelectedStationList] = useState<any>({});
  const [selectedGroupListMember, setSelectedGroupListMember] = useState<any>(
    {}
  );
  const [addStation, setAddStation] = useState<boolean>(false);
  const [removeStation, setRemoveStation] = useState<boolean>(false);

  const [getAllStationList, getAllStationListError, getAllStationListLoading] =
    useFetch(
      `ElogBookPriviledgesAssignment/GetAllStationAsync?sgpIdpk=${selected_Group?.sgpIDpk}`,
      refreshButtons.stationListRefresh,
      "",
      false,
      "powerapp"
    );

  const [getAllGroupList, getAllGroupListError, getAllGroupListLoading] =
    useFetch(
      `ElogBookPriviledgesAssignment/PopGdcGroupStationsAsync?sgpIDpk=${selected_Group?.sgpIDpk}`,
      refreshButtons.groupListRefresh,
      "",
      false,
      "powerapp"
    );
  // ElogBookPriviledgesAssignment/GetStationGroupNameAsync
  const [
    stationGroupNameAndShortName,
    stationGroupNameAndShortNameError,
    stationGroupNameAndShortNameLoading,
  ] = useFetch(
    `ElogBookPriviledgesAssignment/GetStationGroupNameAsync`,
    true,
    "",
    false,
    "powerapp"
  );
  const { data: getAllStationGroup, loading: getAllStationGroupLoading } =
    useQueryFetch({
      app: "powerapp",
      url: `ElogBookPriviledgesAssignment/GetAllStationGroupAsync`,
      refresh: !refreshButtons.allStationGroupsRefresh,
      revalidateAfter: 0,
      params: {
        SearchTerm: searchTerm.value === "" ? undefined : searchTerm.value,
        CheckState: checkState.value,
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

  const sendAndReturnMessageForSaving = async () => {
    setBusyloader("Saving record...");
    await createGroupDetails(
      `ElogBookPriviledgesAssignment/CreateStationGroupAsync`,
      {
        sgpName: groupDetailsFormState.name.name,
        sgpShtName: groupDetailsFormState.shortName.name,
        sgpActive: groupDetailsFormState.active,
        sgpRmks: groupDetailsFormState.remarks,
      },
      `Created group details with the Name: ${groupDetailsFormState.name.name}, Short name:${groupDetailsFormState.shortName.name}, Active status:${groupDetailsFormState.active} and Remarks:${groupDetailsFormState.remarks}`
    )
      .then((res) => {
        setBusyloader("");
        setRefreshButton((prev: any) => ({
          ...prev,
          allStationGroupsRefresh: !refreshButtons.allStationGroupsRefresh,
        }));
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "success",
          title: "Success",
          message: "Record created successfully",
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      })
      .catch((err) => {
        setBusyloader("");
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: `${
            err?.response?.data?.code == 500 ? "Warning" : "Name Exists"
          }`,
          message: `${
            err?.response?.data?.code == 500
              ? "Record not saved. Please contact the systems administrator "
              : err?.response?.data?.message ||
                "The name or shortname you entered exists. Please enter a different name or shortname"
          }`,
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      });
  };

  const handleSave = async () => {
    if (savePriv === true) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Save ?",
        message: "Are you sure you want to save this record?",
        okText: "Yes",
        cancelText: "No",
        hideCancel: false,
        openModal: true,
        okHandler: async () => {
          sendAndReturnMessageForSaving();
        },
        cancelHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access denied!",
        message: "You do not have the privilege to create new records",
        okText: "okay",
        hideCancel: true,
        openModal: true,
        okHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    }
  };
  const sendAndReturnMessageForUpdating = async () => {
    setBusyloader("Updating record...");
    await updateGroupDetails(
      `ElogBookPriviledgesAssignment/UpdateStationGroupAsync`,
      {
        sgpIDpk: selected_Group?.sgpIDpk,
        sgpName: groupDetailsFormState.name.name,
        sgpShtName: groupDetailsFormState.shortName.name,
        sgpActive: groupDetailsFormState.active,
        sgpRmks: groupDetailsFormState.remarks,
      },
      `Update record with Name:${groupDetailsFormState.name}, shortName:${groupDetailsFormState.shortName}, active:${groupDetailsFormState.active}, remarks:${groupDetailsFormState.remarks}`
    )
      .then(() => {
        setBusyloader("");
        setRefreshButton((prev: any) => ({
          ...prev,
          allStationGroupsRefresh: !refreshButtons.allStationGroupsRefresh,
        }));
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "success",
          title: "Success",
          message: "Record updated successfully",
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      })
      .catch((err) => {
        setBusyloader("");
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: "Warning",
          message: `${
            err?.response?.data?.code == 500
              ? "Record not saved. Please contact the systems administrator"
              : err?.response?.data?.message
          }`,
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      });
  };
  const handleUpdate = async () => {
    if (savePriv === true) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Update ?",
        message: "Are you sure you want to update this record?",
        okText: "Yes",
        cancelText: "No",
        hideCancel: false,
        openModal: true,
        okHandler: async () => {
          sendAndReturnMessageForUpdating();
        },
        cancelHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access denied!",
        message: "You do not have the privilege to create new records",
        okText: "okay",
        hideCancel: true,
        openModal: true,
        okHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    }
  };
  useEffect(() => {
    setGroupDetailsFormState((prev: any) => ({
      ...prev,
      name: {
        ...groupDetailsFormState.name,
        id: selected_Group?.sgpIDpk ?? "",
        name: selected_Group?.sgpName ?? "",
      },
      shortName: {
        ...groupDetailsFormState.shortName,
        id: selected_Group?.sgpIDpk ?? "",
        name: selected_Group?.sgpName ?? "",
      },
      active: selected_Group?.sgpActive ?? true,
      remarks: selected_Group?.sgpRmks ?? "",
    }));
  }, [selected_Group]);

  useEffect(() => {
    if (getAllStationGroupLoading === true) {
      setBusyloader("Fetching records...");
    } else {
      setBusyloader("");
    }
  }, [getAllStationGroupLoading]);

  const addStationToGroupList = async () => {
    await updateGroupDetails(
      `ElogBookPriviledgesAssignment/AddOrUpdateStationGroupAsync`,
      {
        stnIDpk: selectedStationList?.stnIDpk,
        sgpIDpk: selected_Group?.sgpIDpk,
        // empIDpk: 0, until further notice
      },
      `added record with group id=${selected_Group?.sgpIDpk}  station id=${selectedStationList?.stnIDpk}`
    );
    setRefreshButton((prev: any) => ({
      ...prev,
      groupListRefresh: !refreshButtons.groupListRefresh,
    }));
  };

  const removeStationFromGroupList = async () => {
    setMessageModal((prev: any) => ({
      ...prev,
      icon: "question",
      title: "Remove?",
      message: "Are you sure you want to remove station from  group list?",
      okText: "Yes",
      cancelText: "No cancel",
      hideCancel: false,
      openModal: true,
      okHandler: async () => {
        await updateGroupDetails(
          `ElogBookPriviledgesAssignment/RemoveFromStationAsync`,
          {
            sgmIDpk: selectedGroupListMember?.sgmIDpk,
            // empIDpk: 0, until further notice
          },
          `removed record with group id=${selectedGroupListMember?.sgmIDpk}`
        );
        setRefreshButton((prev: any) => ({
          ...prev,
          groupListRefresh: !refreshButtons.groupListRefresh,
        }));
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setRemoveStation(false);
      },
      cancelHandler: () => {
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setRemoveStation(false);
      },
    }));
  };

  const validateBeforeSaveOrUpdate = (method: string) => {
    if (groupDetailsFormState.name.name == "") {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Warning",
        message: "Please enter group name",
        openModal: true,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    }
    if (groupDetailsFormState.shortName.name == "") {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Warning",
        message: "Please enter group short name",
        openModal: true,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    } else {
      if (method == "save") {
        return handleSave();
      } else {
        return handleUpdate();
      }
    }
  };
  // useEffect(() => {
  //   if (addStation === true) {
  //     addStationToGroupList();
  //   }
  // }, [addStation]);
  useEffect(() => {
    // if (addStation === true) {
    addStationToGroupList();
    // }
  }, [selectedStationList]);

  useEffect(() => {
    if (removeStation == true) {
      removeStationFromGroupList();
    }
  }, [removeStation]);

  const customToolTip = {
    newBtn: { id: "nwbtn22", tooltipID: "#nwbtn22" },
    editBtn: { id: "edtbtn22", tooltipID: "#edtbtn22" },
    cancelBtn: { id: "cclbtn22", tooltipID: "#cclbtn22" },
    switchBtn: { id: "stchbtn22", tooltipID: "#stchbtn22" },
    helpBtn: { id: "hlpbtn22", tooltipID: "#hlpbtn22" },
    checkBtn: { id: "chkbtn22", tooltipID: "#chkbtn22" },
    findBtn: { id: "fndbtn22", tooltipID: "#fndbtn22" },
    refreshBtn: { id: "rfshbtn22", tooltipID: "#rfshbtn22" },
    printBtn: "prbtn12",
  };

  console.log("getAllStationGroup", getAllStationGroup);
  console.log("form state", groupDetailsFormState);
  console.log("selected_Group", selected_Group);
  return (
    <>
      <ModalTemplate
        icon={messageModal.icon}
        title={messageModal.title}
        message={messageModal.message}
        okText={messageModal.okText}
        cancelText={messageModal.cancelText}
        open={messageModal.openModal}
        disableCancel={messageModal.hideCancel}
        okHandler={messageModal.okHandler}
        cancelHandler={messageModal.cancelHandler}
      />
      <div className="w-full h-full flex flex-col">
        <div className="px-2">
          <HrSetupsCustomToolbar
            toggler={switch_toggle}
            setVisible={setSwitch_change}
            handleNew={() => {
              handleNew();
            }}
            // handleUpdate={() => {}}
            handleFind={() => {
              setSearchParam((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParam.searchTerm,
                  value: searchParam.searchTerm.initialValue,
                },
                checkState: {
                  ...searchParam.checkState,
                  value: searchParam.checkState.initialValue,
                },
              }));
              setRefreshButton((prev: any) => ({
                ...prev,
                allStationGroupsRefresh:
                  !refreshButtons.allStationGroupsRefresh,
              }));
            }}
            handleRefresh={() => {
              setSearchParam((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParam.searchTerm,
                  initialValue: "",
                  value: "",
                },
                checkState: {
                  ...searchParam.checkState,
                  initialValue: "",
                  value: "",
                },
              }));
              setRefreshButton((prev: any) => ({
                ...prev,
                allStationGroupsRefresh:
                  !refreshButtons.allStationGroupsRefresh,
              }));
            }}
            checkOnChange={(e) => {
              setSearchParam((prev: any) => ({
                ...prev,
                checkState: { ...searchParam.checkState, initialValue: e },
              }));
            }}
            searchTextOnchange={(e) => {
              setSearchParam((prev: any) => ({
                ...prev,
                searchTerm: { ...searchParam.searchTerm, initialValue: e },
              }));
            }}
            outerSelected={selected_Group}
            withDates={false}
            customTooltipIds={customToolTip}
            showPrintIcon
          />
        </div>

        <div className="flex flex-col flex-grow">
          {switch_change && (
            <div ref={formRef} className="px-2 flex-shrink-0">
              <p
                id="form_header"
                style={{ borderColor: colorTheme }}
                className="pt-1 border-[1px] border-b-0 pl-2 w-full bg-slate-100 font-semibold"
              >
                Group Details
              </p>
              <Form
                className="w-full border-[1px] border-slate-100  rounded-b flex justify-between py-1 px-2"
                style={{ borderColor: colorTheme }}
                name="basic"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
              >
                <section className="" style={{ width: width }} ref={leftForm}>
                  <Form.Item
                    className="mb-2 w-full"
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        Name
                      </p>
                    }
                    name="Name"
                    rules={[{ required: false }]}
                  >
                    <InputAndSelect_template
                      idexpr="sgpIDpk"
                      dataexp="sgpName"
                      useCallFunc
                      placeHolder={groupDetailsFormState.name.name}
                      options={stationGroupNameAndShortName}
                      selectedValue={(e: any) => {
                        console.log("name", e);
                        setGroupDetailsFormState((prev: any) => ({
                          ...prev,
                          name: {
                            ...groupDetailsFormState.name,
                            id: e?.sgpIDpk,
                            name: e?.sgpName,
                          },
                          shortName: {
                            ...groupDetailsFormState.shortName,
                            id: e?.sgpIDpk,
                            name: e?.sgpShtName ?? e?.sgpName,
                          },
                        }));
                      }}
                      handleRefresh={() => {
                        form_disable === false &&
                          setGroupDetailsFormState((prev: any) => ({
                            ...prev,
                            name: {
                              ...groupDetailsFormState.name,
                              // id: stationGroupNameAndShortName[0]?.sgpIDpk,
                              id: 0,
                              // name: stationGroupNameAndShortName[0]?.sgpName,
                              name: "",
                            },
                            shortName: {
                              ...groupDetailsFormState.shortName,
                              // id: stationGroupNameAndShortName[0]?.sgpIDpk,
                              id: 0,
                              // name: stationGroupNameAndShortName[0]?.sgpShtName,
                              name: "",
                            },
                          }));
                      }}
                      selectStyle={{ width: 350 }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-2 w-full "
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        Short Name
                      </p>
                    }
                    name="ShortName"
                    rules={[{ required: false }]}
                  >
                    <InputAndSelect_template
                      span
                      useCallFunc
                      idexpr="sgpIDpk"
                      dataexp="sgpShtName"
                      placeHolder={groupDetailsFormState.shortName.name}
                      options={stationGroupNameAndShortName}
                      selectedValue={(e: any) => {
                        setGroupDetailsFormState((prev: any) => ({
                          ...prev,
                          shortName: {
                            ...groupDetailsFormState.shortName,
                            id: e?.sgpIDpk,
                            name: e?.sgpShtName,
                          },
                        }));
                      }}
                      selectStyle={{ width: 450 }}
                    />
                  </Form.Item>

                  <BottomBtns
                    outerDisabled={form_disable}
                    useCallbackFunc={false}
                    onNewClick={handleNew}
                    onCancelClick={() => {}}
                    updateHandler={() => {
                      validateBeforeSaveOrUpdate("update");
                    }}
                    saveHandler={() => {
                      validateBeforeSaveOrUpdate("save");
                    }}
                    span={true}
                    addActiveBorders={true}
                    defaultValue={groupDetailsFormState.active}
                    checkboxChange={(value) => {
                      setGroupDetailsFormState((prev: any) => ({
                        ...prev,
                        active: value,
                      }));
                    }}
                  />
                </section>

                <section style={{ width: `calc(100% - ${width}px)` }}>
                  <TextareaTemplate
                    MarginLeft={60}
                    //   dataExpr={textareaExpr}
                    defaultValue={groupDetailsFormState.remarks}
                    label="Remarks"
                    height={85}
                    setContent={(e) => {
                      setGroupDetailsFormState((prev: any) => ({
                        ...prev,
                        remarks: e,
                      }));
                    }}
                  />
                </section>
              </Form>
            </div>
          )}

          <div className="px-2">
            {" "}
            <div className="border-t-4 border-gray-300 my-2"></div>
          </div>

          {/* <div className="flex-grow overflow-auto"> */}
          <div className="w-full flex space-x-4">
            {/* Groups Section */}
            <aside className="flex-1 flex flex-col border border-gray-300 rounded-md overflow-hidden">
              <div className="flex justify-between p-2 bg-gray-100">
                <p className="font-semibold">Groups</p>
                {/* <RefreshButtonTemplate
        handleRefresh={() => {
          setRefreshButton((prev: any) => ({
            ...prev,
            allStationGroupsRefresh: !refreshButtons.allStationGroupsRefresh,
          }));
        }}
      /> */}
              </div>
              <Datagrid
                dataId={"sgpIDpk"}
                deselectFirstRow={false}
                disablePaging
                className="flex-grow flex-shrink-0 w-full"
                gridheight={switch_change === true ? gridHeight : fullPage}
                columns={AllStationGroupColoumn}
                data={getAllStationGroup?.data}
                rowDoubleClicked={(e: any) => {
                  setSwitch_change(true);
                  setSelected_Group(e);
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: false })
                  );
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: true })
                  );
                }}
                onRowClick={(e: any) => {
                  setSelected_Group(e);
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: false })
                  );
                }}
              />
            </aside>

            {/* Station List Section */}
            <aside className="flex-1 flex flex-col border border-gray-300 rounded-md overflow-hidden">
              <div className="flex justify-between p-2 bg-gray-100">
                <p className="font-semibold">Station List</p>
                {/* <RefreshButtonTemplate
        handleRefresh={() => {
          setRefreshButton((prev: any) => ({
            ...prev,
            stationListRefresh: !refreshButtons.stationListRefresh,
          }));
        }}
      /> */}
              </div>
              <Datagrid
                dataId={"stnIDpk"}
                deselectFirstRow
                disablePaging
                className="flex-grow flex-shrink-0 w-full"
                gridheight={switch_change === true ? gridHeight : fullPage}
                columns={AllStationListColoumn}
                data={getAllStationList}
                rowDoubleClicked={(e: any) => {
                  setAddStation(!addStation);
                  setSelectedStationList(e);
                  // alert(selected_Group?.sgpIDpk);
                  // await addStationToGroupList(e);
                }}
              />
            </aside>

            {/* Group List Section */}
            <aside className="flex-1 flex flex-col border border-gray-300 rounded-md overflow-hidden">
              <div className="flex justify-between p-2 bg-gray-100">
                <p className="font-semibold">Group List</p>
                {/* <RefreshButtonTemplate
        handleRefresh={() => {
          setRefreshButton((prev: any) => ({
            ...prev,
            groupListRefresh: !refreshButtons.groupListRefresh,
          }));
        }}
      /> */}
              </div>
              <Datagrid
                dataId={"sgmIDpk"}
                deselectFirstRow
                disablePaging
                className="flex-grow flex-shrink-0 w-full"
                gridheight={switch_change === true ? gridHeight : fullPage}
                columns={AllGroupListColoumn}
                data={getAllGroupList}
                rowDoubleClicked={(e: any) => {
                  // await removeStationFromGroupList(e);
                  setRemoveStation(!removeStation);
                  setSelectedGroupListMember(e);
                }}
              />
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}
