import { useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import MaintenanceRequestsDetails from "./maintenanceRequests-details";

import { MaintenanceRequestsToolbar } from "./maintenanceRequests-toolbar";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import CustomLoader from "../../../../../../accessories/custom-loader";
import dayjs from "dayjs";

const MaintenanceRequests = () => {
  const [showTable, setShowTable] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [wordWrap, setWordWrap] = useState<boolean>(false);

  const editedDate = dayjs().endOf("month").format("YYYY-MM-DD");
  const startingDate = dayjs().subtract(1, "years") .startOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState<{ temp: string; index: string }>({
    temp: startingDate,
    index: startingDate,
  });
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: editedDate,
    index: editedDate,
  });
  const [searchText, setSearchText] = useState<{ temp: string; index: string }>(
    { temp: "", index: "" }
  );
  const [activeStatus, setActiveStatus] = useState<{
    temp: string;
    index: string;
  }>({ temp: "", index: "" });

  const [mainDataGrid, mainDataGridErr, mainDataGridLoading] = useFetch(
    `AsmTmsVehicleMaintenanceRequest/GetAll?Startdate=${startDate.index}&Enddate=${endDate.index}&search=${searchText.index}&active=${activeStatus.index}`,
    refresh,
    "mrqIDpk"
  );

  const switch_toggle: (() => void) | undefined = () => {
    setShowTable(!showTable);
  };

  const [selectedData] = useState<any>({});

  return (
    <>
      {mainDataGridErr && <WarningAlert />}
      {mainDataGridLoading && <CustomLoader text="Fetching Data" />}
      <div className="mx-2">
        <MaintenanceRequestsToolbar
          toggler={switch_toggle}
          setSwitchToggle={setShowTable}
          handlewordWrap={() => {
            setWordWrap(!wordWrap);
          }}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, index: searchText.temp });
            setActiveStatus({
              temp: activeStatus.temp,
              index: activeStatus.temp,
            });
            setStartDate({ temp: startDate.temp, index: startDate.temp });
            setEndDate({ temp: endDate.temp, index: endDate.temp });
          }}
          startDateChanged={(date) => {
            setStartDate({
              temp: dayjs(date).format("YYYY-MM-DD"),
              index: startDate.index,
            });
          }}
          endDateChanged={(date) => {
            setEndDate({
              temp: dayjs(date).format("YYYY-MM-DD"),
              index: endDate.index,
            });
          }}
          searchTextOnchange={(e) => {
            setSearchText({ temp: e, index: searchText.index });
          }}
          checkOnChange={(e) => {
            setActiveStatus({ temp: e, index: activeStatus.index });
          }}
          startDate={startDate.temp}
          endDate={endDate.temp}
          outerSelected={selectedData}
          handleRefresh={() => {
            setSearchText({ temp: "", index: "" });
           setActiveStatus({ temp: "", index: "" });
            setRefresh((prev) => !prev);
          }}
        />
      </div>

      <div className="px-2 mb-0.5  ">
        <MaintenanceRequestsDetails
          toggled={showTable}
          dataGridData={mainDataGrid}
          refreshGrid={() => {
            setRefresh(!refresh);
          }}
          wordWrap={wordWrap}
        />
      </div>
    </>
  );
};

export default MaintenanceRequests;
