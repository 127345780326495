import React from "react";
import { DocRenderer } from "@cyntler/react-doc-viewer";
import { Image } from "antd";

const CustomImageRenderer: DocRenderer = ({
  mainState: { currentDocument },
}) => {
  if (!currentDocument) return null;
  return <Image src={currentDocument.fileData as string} />;
};
CustomImageRenderer.fileTypes = ["image/webp"];
CustomImageRenderer.weight = 1;
export default CustomImageRenderer;
