/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect, useCallback } from "react";
import { Form, Input, Select, Image, InputNumber } from "antd";
import reset from "../../../../../../../assets/reset.png";

import {
  SaveButton,
  NewButton,
  DispenseButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  formModalStates,
  inputsTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { InputsTemplate } from "../../../../../../../templates/input";
import { DateTemplate } from "../../../../../../../templates/date";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs, { Dayjs } from "dayjs";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import {
  authorizeBy_cols,
  ss_cols,
  vehicleF_cols,
} from "../data/datagrid_cols";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";

import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import invalidImage from "../../../../../../../assets/none.jpg";
import { FormModal } from "../../../../../../accessories/formModal";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import {
  fetchProductDump,
  fetchSPTypeDump,
} from "../../../../../../../features/apiCalls/FuelReturnRequestApi/ReturnReqDumb";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";

interface props {
  statesx?: any;
  statesxx?: any;
  updateStatesx?: (key: string, value: any) => void;
  setRefreshx?: (e: boolean) => void;
  purchaseModeChange: (e: string) => void;
}

interface stateTypes {
  purMode?: any;
  reqDate?: string;
  vehNo?: { id: number; name: string };
  sp?: any;
  ss?: { id: number; name: string };
  prod?: any;
  untP: number;
  quan1: number;
  quan2: number;
  bal?: number;
  unit: number;
  total: number;
  uom?: { id: number; name: string };
  coupon?: string;
  authBy?: { id: number; name: string };
  rec?: { id: number; name: string };
  dept?: { id: number; name: string };
  sxn?: { id: number; name: string };
  cost?: { id: number; name: string };
  prevOdom: number;
  curOdom: number;
  active?: boolean;
  remarks?: string;
  remarks2?: string;
  disp?: number;
  selectedDate1?: Dayjs;
  password?: string;

  purchaseMode: boolean;
  vehicleRegNo: boolean;
  serviceProvider: boolean;
  serviceStation: boolean;
  product: boolean;
  quantity: boolean;
  authorizedBy: boolean;
  receivedBy: boolean;
  department: boolean;
  section: boolean;
  costCenter: boolean;

  dispenseFormState: boolean;

  isOpenDropdown: boolean;
  isOpenDropdown1: boolean;
  isLoading?: boolean;
}

export const FuelDump_Transaction_Details_Form = ({
  statesx,
  updateStatesx,
  statesxx,
  setRefreshx,
  purchaseModeChange,
}: props) => {
  const { TextArea } = Input;

  const dispatch = useDispatch();

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const FormCode = useSelector((state: RootState) => state.user?.formCode);
  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const [showImages, setShowImages] = useState(false);

  const refs = useRef<any>(null);

  const editedDate = dayjs().format();

  const [states, setStates] = useState<stateTypes>({
    purMode: {
      id: statesxx !== undefined ? statesxx.purModeState?.id : 2,
      name: statesxx !== undefined ? statesxx.purModeState?.name : "Fuel Dump",
    },

    reqDate: "",
    vehNo: { id: 0, name: "" },
    sp: { id: 0, name: "" },
    ss: { id: 0, name: "" },
    prod: { id: 0, name: "" },
    untP: 0,
    quan1: 0,
    quan2: 0,
    bal: 0,
    unit: 0,
    total: 0,
    uom: { id: 0, name: "" },
    coupon: "",
    authBy: { id: 0, name: "" },
    rec: { id: userInfo.empIDpk, name: userInfo.empName },
    dept: { id: 0, name: "" },
    sxn: { id: 0, name: "" },
    cost: { id: 0, name: "" },
    prevOdom: 0,
    curOdom: 0,
    active: true,
    remarks: "",
    remarks2: "",
    disp: 0,
    selectedDate1: dayjs(),
    password: "",

    purchaseMode: false,
    vehicleRegNo: false,
    serviceProvider: false,
    serviceStation: false,
    product: false,
    quantity: false,
    authorizedBy: false,
    receivedBy: false,
    department: false,
    section: false,
    costCenter: false,

    dispenseFormState: false,

    isOpenDropdown: false,
    isOpenDropdown1: false,
    isLoading: false,
  });
  console.log("xxxxx", statesxx);

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const close = async () => {
    refs.current!.instance.close();
  };

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [pur_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLuePurchaseModes",
    states.purchaseMode
  );
  const [vehNo_data] = useFetch(
    `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos`,
    states.vehicleRegNo
  );
  const [sp_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueServiceProvides",
    states.serviceProvider
  );
  const [ss_data] = useFetch(
    `FuelManagement/dumpServiceStations?spdIDx=${states.sp?.id}`,
    states.serviceStation
  );
  const [prod_data] = useFetch(
    `FuelManagement/dumpAllProducts`,
    states.product
  );
  const [purBy_data, purBy_error, purBy_loading] = useFetch(
    `FuelManagement/dumpGetPurchasedByAll`,
    states.purchaseMode
  );
  const [uom_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueFuelUnits",
    states.quantity
  );
  const [auth_data] = useFetch(
    `FuelManagement/authorizeBy?Mode=${form_disable ? 1 : update_state_change ? 2 : 1
    }&purchaseMode=${states.purMode?.id}&provider=${states?.sp?.id}&station=${states?.ss?.id
    }`,
    states.authorizedBy
  );
  const [rec_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueReceivedBy",
    states.receivedBy
  );
  const [dept_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebits",
    states.department
  );
  const [sxn_data] = useFetch(
    `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitSections?debitDepartmentId=${states.dept?.id}`,
    states.section
  );
  const [cost_data] = useFetch(
    `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitCostCenters?debitDepartmentId=${states.dept?.id}&debitSectionId=${states.sxn?.id}`,
    states.costCenter
  );
  const [untP_data] = useFetch(
    `FuelManagement/dumpUnitPrice?spdIDx=${states !== undefined ? states.sp?.id : 0
    }&prdIDx=${states !== undefined ? states.prod?.id : 0}&sstIDx=${states !== undefined ? states.ss?.id : 0
    }`
  );
  const [image_data] = useFetch(
    `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${statesxx.selectedRecord?.vdtIdpk}&vimActive=true`
  );

  const [lookups] = useFetch(
    `FuelManagement/GetFuelDumpLookup?PageNumber=1&PageSize=20&spdIDx=${states.sp.id
    }&Mode=${form_disable ? 1 : update_state_change ? 2 : 1}&purchaseMode=${states.purMode?.name
    }&provider=${states?.sp?.id}&station=${states?.ss?.id}`
  );

  const spData = useSelector(
    (state: RootState) => state.returnDump.serviceProviderData
  );
  const prodData = useSelector(
    (state: RootState) => state.returnDump.productData
  );

  const pur: selectsTypes[] = [
    {
      id: 0,
      label: "Purchase Mode",
      optionsData:
        states.purMode.length > 0 ? pur_data : lookups.dumpGetPurchaseModes,
      idExpr: "pcmIdpk",
      dataExpr: "pcmName",
      stateName: "purMode",
      defaultValue: states.purMode?.name,
      refreshStateName: "purchaseMode",
      refresh: states.purchaseMode,
    },
  ];

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Request Date",
      stateName: "reqDate",
      defaultValue: states.reqDate,
    },
  ];

  const selects: selectsTypes[] = [
    {
      id: 1,
      label: "Vehicle Reg. No",
      optionsData: vehNo_data,
      idExpr: "vdtIdpk",
      dataExpr: "vdtRegNo",
      stateName: "vehNo",
      defaultValue: states.vehNo?.name,
      style: "80%",
      refreshStateName: "vehicleRegNo",
      refresh: states.vehicleRegNo,
    },
    {
      id: 2,
      label: "Service Provider",
      optionsData: states.sp.length > 0 ? spData : lookups.dumpServiceProviders,
      idExpr: "spdIdpk",
      dataExpr: "spdName",
      stateName: "sp",
      defaultValue: states.sp?.name,
      style: "80%",
      refreshStateName: "serviceProvider",
      refresh: states.serviceProvider,
      isOpenDropdown: states.isOpenDropdown,
      isOpenDropdownKey: "isOpenDropdown",
      singleEndpoint: fetchSPTypeDump,
    },
    {
      id: 3,
      label: "Service Station",
      optionsData: ss_data,
      idExpr: "sstIDpk",
      dataExpr: "sstName",
      stateName: "ss",
      defaultValue: states.ss?.name,
      style: "80%",
      refreshStateName: "serviceStation",
      refresh: states.serviceStation,
    },
    {
      id: 4,
      label: "Product",
      optionsData: states.prod.length > 0 ? prodData : lookups.dumpAllProducts,
      idExpr: "prdIdpk",
      dataExpr: "prdName",
      stateName: "prod",
      defaultValue: states.prod?.name,
      style: "80%",
      refreshStateName: "product",
      refresh: states.product,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      singleEndpoint: fetchProductDump,
    },
  ];

  const rightSelects: selectsTypes[] = [
    {
      id: 1,
      label: "Authorized By",
      optionsData: auth_data,
      idExpr: "empIDpk",
      dataExpr: "empName",
      stateName: "authBy",
      defaultValue: states.authBy?.name,
      refreshStateName: "authorizedBy",
      refresh: states.authorizedBy,
    },
    {
      id: 2,
      label: "Received By",
      optionsData: rec_data,
      idExpr: "empIdpk",
      dataExpr: "empName",
      stateName: "rec",
      defaultValue: states.rec?.name,
      refreshStateName: "receivedBy",
      refresh: states.receivedBy,
    },
  ];

  const Rightinputs: inputsTypes[] = [
    {
      id: 0,
      label: "Prev. Odom Reading",
      stateName: "prevOdom",
      defaultValue: Number(states.prevOdom).toFixed(2),
    },
    {
      id: 1,
      label: "Current Odom Reading",
      stateName: "curOdom",
      defaultValue: Number(states.curOdom).toFixed(2),
    },
  ];

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [Posting, Updating] = useCrudFunc();
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const populateFields = (clear?: boolean) => {
    const gridState = [
      {
        key: "purMode",
        value: clear
          ? {
            id: statesxx !== undefined ? statesxx.purModeState?.id : 2,
            name:
              statesxx !== undefined
                ? statesxx.purModeState?.name
                : "Fuel Dump",
          }
          : {
            id: statesxx?.selectedRecord?.pcmIDpk,
            name: statesxx?.selectedRecord?.pcmName,
          },
      },
      {
        key: "reqDate",
        value: clear ? editedDate : statesxx.selectedRecord?.fmgDate,
      },
      {
        key: "vehNo",
        value: clear
          ? { id: 0, name: "" }
          : {
            id: statesxx?.selectedRecord?.vdtIDpk,
            name: statesxx?.selectedRecord?.vdtRegNo,
          },
      },
      {
        key: "purBy",
        value: clear
          ? { id: 0, name: "" }
          : {
            id: statesxx?.selectedRecord?.driverEmpID,
            name: statesxx?.selectedRecord?.driverName,
          },
      },
      {
        key: "sp",
        value: clear
          ? { id: 0, name: "" }
          : {
            id: statesxx?.selectedRecord?.spdIDpk,
            name: statesxx?.selectedRecord?.spdName,
          },
      },
      {
        key: "ss",
        value: clear
          ? { id: 0, name: "" }
          : {
            id: statesxx?.selectedRecord?.sstIDpk,
            name: statesxx?.selectedRecord?.sstName,
          },
      },
      {
        key: "prod",
        value: clear
          ? { id: 0, name: "" }
          : {
            id: statesxx?.selectedRecord?.prdIDpk,
            name: statesxx?.selectedRecord?.prdName,
          },
      },
      {
        key: "coupon",
        value: clear ? "" : statesxx?.selectedRecord?.fmgReceiptNo,
      },
      {
        key: "quan1",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgQuantityRequested,
      },
      {
        key: "quan2",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgQuantityAuthorised,
      },
      {
        key: "unit",
        value: clear
          ? 0
          : statesxx?.selectedRecord?.fmgAmount /
          statesxx?.selectedRecord?.fmgQuantityRequested,
      },
      {
        key: "uom",
        value: clear
          ? ""
          : {
            id: statesxx?.selectedRecord?.funIDpk,
            name: statesxx?.selectedRecord?.funShtName,
          },
      },
      {
        key: "total",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgAmount,
      },
      { key: "bal", value: clear ? 0 : statesxx?.selectedRecord?.fmgBalance },

      {
        key: "authBy",
        value: clear
          ? ""
          : {
            id: statesxx?.selectedRecord?.authoriserEmpID,
            name: statesxx?.selectedRecord?.authoriserEmpName,
          },
      },
      {
        key: "dept",
        value: clear
          ? ""
          : {
            id: statesxx?.selectedRecord?.dptIdpk,
            name: statesxx?.selectedRecord?.dptShtName,
          },
      },
      {
        key: "sxn",
        value: clear
          ? ""
          : {
            id: statesxx?.selectedRecord?.sxnIdpk,
            name: statesxx?.selectedRecord?.sxnShtName,
          },
      },
      {
        key: "cost",
        value: clear
          ? ""
          : {
            id: statesxx?.selectedRecord?.cctIdpk,
            name: statesxx?.selectedRecord?.cctName,
          },
      },
      {
        key: "rec",
        value: clear
          ? { id: userInfo.empIDpk, name: userInfo.empName }
          : {
            id: statesxx?.selectedRecord?.driverEmpID,
            name: statesxx?.selectedRecord?.driverName,
          },
      },
      {
        key: "prevOdom",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgPreviousOdometerReading,
      },
      {
        key: "curOdom",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgCurrentOdometerReading,
      },
      {
        key: "remarks",
        value: clear ? "" : statesxx?.selectedRecord?.fmgRmks,
      },
    ];

    for (let instance of gridState) setState(instance.key, instance.value);
  };

  const validationForms = () => {
    setIcon("warning");
    if (
      statesxx.purModeState?.id === 0 ||
      statesxx.purModeState?.id === undefined ||
      statesxx.purModeState?.id === null
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Purchase Mode Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehNo?.id === 0 || states.vehNo?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Vehicle Reg.No Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.purMode?.id === 0 || states.purMode?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Purchased By Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.sp?.id === 0 || states.sp?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Service Provider Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.ss?.id === 0 || states.ss?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Service Station Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.prod?.id === 0 || states.prod?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Product Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.reqDate === "" ||
      dayjs(states.reqDate).isAfter(dayjs().endOf("day"))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please select Transaction Field, Date cannot be greater than today",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.authBy?.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Authorise By Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.rec?.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Received By Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.quan1 ||
      states.quan1 === undefined ||
      states.quan1 < 0 ||
      isNaN(Number(states.quan1))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.quan1 < 0
            ? "Please, quantity requested cannot be negative"
            : isNaN(Number(states.quan1))
              ? "Please, enter a valid number for quantity requested"
              : "Please, enter quantity requested",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.uom?.id === 0 || states.uom?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Fuel Unit of measure field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.unit ||
      states.unit === undefined ||
      states.unit < 0 ||
      isNaN(Number(states.unit))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.unit < 0
            ? "Please, unit price cannot be negative"
            : isNaN(Number(states.unit))
              ? "Please, enter a valid number for unit price"
              : "Please, enter unit price",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.authBy?.id === 0 || states.authBy?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Authorise By Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      updateStatesx!("selectedRecord", {});
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setIcon("question");
      setModalData(saveModalData);
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const updateAcccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      setIcon("question");
      setModalData(updateModalData);
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  const message = `Fuel Request from ${userInfo.empName} is pending your authorisation`;

  let mobNox = "";

  const sendSms = async () => {
    try {
      checkConnection();
      if (statesxx.selectedRecord?.driverEmpID) {
        mobNox = statesxx.selectedRecord?.dispenserMobNox
          .trim()
          .replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: message,
        imsSender: userInfo.empName,
        imsRecordIdfk: statesxx.selectedRecord.fmgIDpk,
        imsFormCode: FormCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: statesxx.selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: statesxx.selectedRecord.driverEmpID,
        altFormCode: FormCode,
        altMessage: message,
        altTakeAction: true,
      });

      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "SMS Sent Successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to send Alert!.. Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const postData = async () => {
    setBusyLoader("Saving Record...");
    try {
      const res = await Posting(
        "FuelManagement/CreateDumpFuelManagement",
        {
          fmgDate: editedDate,
          fmgVehicleDetailIDfk: states.vehNo?.id,
          fmgDriverIDfk: states.rec?.id,
          fmgDispenserEmpIDfk: 0,
          fmgServiceProviderIDfk: states.sp?.id,
          fmgServiceStationIDfk: states.ss?.id,
          fmgProductIDfk: states.prod?.id,
          fmgFuelUnitIDfk: states.uom?.id,
          fmgAuthoriserEmpIDfk: states.authBy?.id,
          fmgPurchaseModeIDfk: states.purMode?.id,
          fmgCardIDfk: 0,
          fmgAmount: Number(states.total).toFixed(2),
          fmgBalance: 0,
          fmgQuantityRequested: Number(states.quan1).toFixed(2),
          fmgReceiptNo: states.coupon,
          fmgVehicleAssignmentIDfk: 0,
          fmgAuthorised: false,
          fmgRmks: states.remarks,
          fmgCreatedBy: userId,
        },
        `Created the Asset Mgr-Transport-Fuel Transaction Log (Dump) with Purchase Mode ${states.purMode?.name
        }, Request Date ${editedDate}, Vehicle Reg No ${states.vehNo?.name
        }, Service Provider ${states.sp?.name}, Service Station ${states.ss?.name
        }, Product ${states.prod?.name}, Fuel Unit ${states.uom?.name
        }, Authorised By ${states.authBy?.name}, Unit Price ${states.total / states.quan1
        }, Total Amount ${Number(states.total).toFixed(
          2
        )}, Quantity Requested ${Number(states.quan1).toFixed(2)}, Remarks ${states.remarks
        }, Received by ${states.rec.name} successfully`
      );
      sendSms();
      setRefresh(!refresh);
      setRefreshx && setRefreshx(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response
          ? err.response.data.errors.InsertFMDto
          : "Server Error",
        title: err.response ? err.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    setBusyLoader("Updating Record...");
    try {
      await Updating(
        `FuelManagement/UpdateDumpFuelManagement?Id=${statesxx.selectedRecord?.fmgIDpk}`,
        {
          fmgDate: editedDate,
          fmgVehicleDetailIDfk: states.vehNo?.id,
          fmgDriverIDfk: states.rec?.id,
          fmgDispenserEmpIDfk: 0,
          fmgServiceProviderIDfk: states.sp?.id,
          fmgServiceStationIDfk: states.ss?.id,
          fmgProductIDfk: states.prod?.id,
          fmgAuthoriserEmpIDfk: states.authBy?.id,
          fmgPurchaseModeIDfk: states.purMode?.id,
          fmgCardIDfk: 0,
          fmgFuelUnitIDfk: states.uom?.id,
          fmgAmount: Number(states.total).toFixed(2),
          fmgBalance: 0,
          fmgQuantityRequested: Number(states.quan1).toFixed(2),
          fmgCurrentOdometerReading: Number(states.curOdom).toFixed(2),
          fmgPreviousOdometerReading: Number(states.prevOdom).toFixed(2),
          fmgReceiptNo: states.coupon,
          fmgVehicleAssignmentIDfk: 0,
          fmgAuthorised: statesxx.fmgAuthorised ? true : false,
          fmgRmks: states.remarks,
          fmgCreatedBy: userId,
        },
        `Edited the Asset Mgr-Transport-Fuel Dump Log with Purchase Mode ${states.purMode?.name
        }, Request Date ${editedDate}, Vehicle Reg No ${states.vehNo?.name
        }, Service Provider ${states.sp?.name}, Service Station ${states.ss?.name
        }, Product ${states.prod?.name}, Fuel Unit ${states.uom?.name
        }, Authorised By ${states.authBy?.name}, Unit Price ${states.total / states.quan1
        }, Total Amount ${Number(states.total).toFixed(
          2
        )}, Quantity Requested ${Number(states.quan1).toFixed(2)}, Remarks ${states.remarks
        }, Received by ${states.rec.name} successfully`
      );
      setRefresh(!refresh);
      setRefreshx && setRefreshx(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response
          ? err.response.data.errors.InsertFMDto
          : "Server Error",
        title: err.response ? err.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  useEffect(() => {
    if (
      statesxx.selectedRecord !== undefined &&
      Object.keys(statesxx.selectedRecord).length === 0
    ) {
      populateFields(true);
    } else {
      populateFields(false);
    }
  }, [statesxx?.selectedRecord]);

  interface stateTypes2 {
    dept?: { id: number; name: string };
    sxn?: { id: number; name: string };
    cost?: { id: number; name: string };
    quan1: number;
    quan2: number;
    uom1?: { id: number; name: string };
    prevOdom: number;
    curOdom: number;
    active?: boolean;
    remarks2?: string;
    disp?: number;
    selectedDate1?: Dayjs;
    password?: string;
    amount?: number;
  }

  const DispenseForm = () => {
    const [states, setStates] = useState<stateTypes2>({
      dept: { id: 0, name: "" },
      sxn: { id: 0, name: "" },
      cost: { id: 0, name: "" },
      quan1: 0,
      quan2: 0,
      uom1: { id: 0, name: "" },
      prevOdom: 0,
      curOdom: 0,
      active: true,
      remarks2: "",
      disp: 0,
      selectedDate1: dayjs(),
      password: "",
      amount: 0,
    });

    const setState = (key: string, value: any) => {
      setStates((prev) => ({ ...prev, [key]: value }));
    };

    const [dept_data] = useFetch(
      "AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebits"
    );
    const [sxn_data] = useFetch(
      `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitSections?debitDepartmentId=${states.dept?.id}`
    );
    const [cost_data] = useFetch(
      `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitCostCenters?debitDepartmentId=${states.dept?.id}&debitSectionId=${states.sxn?.id}`
    );

    const [disShowModalDisableButton, setDisShowModalDisableButton] =
      useState(false);
    const [disOpenModal, setDisOpenModal] = useState<boolean>(false);
    const [disModalData, setDisModalData] = useState<any>("");
    const [icon, setIcon] = useState<"warning" | "success" | "question">();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [Posting1, Updating1] = useCrudFunc();

    const dispenseSelects: selectsTypes[] = [
      {
        id: 0,
        label: "Debit Department",
        optionsData: dept_data,
        idExpr: "dptIdpk",
        dataExpr: "dptShtName",
        stateName: "dept",
        defaultValue: states.dept?.name,
        style: "",
      },
      {
        id: 1,
        label: "Debit Section",
        optionsData: sxn_data,
        idExpr: "sxnIdpk",
        dataExpr: "sxnShtName",
        stateName: "sxn",
        defaultValue: states.sxn?.name,
        style: "",
      },
      {
        id: 2,
        label: "Debit Cost Center",
        optionsData: cost_data,
        idExpr: "cctIdpk",
        dataExpr: "cctName",
        stateName: "cost",
        defaultValue: states.cost?.name,
        style: "",
      },
    ];
    const dispenseInputs: any[] = [
      {
        id: 0,
        stateName: "quan1",
        defaultValue: statesxx?.selectedRecord?.fmgQuantityRequested,
        style: "",
      },
      {
        id: 1,
        stateName: "quan2",
        defaultValue: statesxx?.selectedRecord?.fmgQuantityAuthorised,
        style: "",
      },
      { id: 2, stateName: "disp", defaultValue: states?.disp, style: "" },
      {
        id: 3,
        stateName: "amount",
        defaultValue:
          statesxx?.selectedRecord?.fmgQuantityAuthorised *
          (statesxx?.selectedRecord?.fmgAmount /
            statesxx?.selectedRecord?.fmgQuantityRequested),
        style: "",
      },
    ];
    const dispenseInputs2: any[] = [
      {
        id: 0,
        stateName: "prevOdom",
        defaultValue: states?.prevOdom,
        style: "",
      },
      { id: 1, stateName: "curOdom", defaultValue: states?.curOdom, style: "" },
    ];

    const checkConnection = () => {
      if (!navigator.onLine) {
        setIcon("warning");
        setDisOpenModal(true);
        setDisShowModalDisableButton(true);
        setDisModalData({
          message:
            "Your computer is not connected to the Internet. Please check your Internet connection and try again",
          title: "Connectivity Error!",
          okText: "Ok",
        });
      }
    };
    const formCode = useSelector((state: RootState) => state.general.formCode);

    const message = `Dispensed ${states.disp} ltr for the Asset Mgr-Trans-Fuel Dump Transaction Log with request ID ${statesxx.selectedRecord?.fmgIDpk}, Requested By ${statesxx.selectedRecord?.driverEmpID} successfully`;

    let mobNox = ``;

    const smsDispenseAlert = async () => {
      try {
        checkConnection();
        if (statesxx.selectedRecord?.driverEmpID) {
          mobNox = statesxx.selectedRecord?.dispenserMobNox
            .trim()
            .replace("", "");
        }
        if (mobNox.trim.length >= 10) {
          mobNox = mobNox.substring(0, 10);
        }

        await Posting(`SMSAlert/SaveSMS`, {
          imsMessage: message,
          imsSender: userInfo.empName,
          imsMessageType: "string",
          imsMessageClass: "string",
          imsRecordIdfk: statesxx.selectedRecord.fmgIDpk,
          imsFormCode: formCode,
          imsRecipientEmpIdfk: statesxx.selectedRecord.driverEmpID,
          imsInitiatorEmpIdfk: userId,
          imsCreatedBy: userId,
        });
      } catch {
        setIcon("warning");
        setDisOpenModal(true);
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Failed to send Alert!.. Please contact your administrator",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    };

    const updateDispenseData = async () => {
      try {
        await Updating(
          `FuelManagement/DispenseFuel?fmgIDpk=${statesxx.selectedRecord?.fmgIDpk}`,
          {
            fmgDateDispensed: states.selectedDate1,
            fmgDebitDeptIDfk: states.dept?.id,
            fmgDebitSectionIDfk: states.sxn?.id,
            fmgCostCenterIDfk: states.cost?.id,
            fmgQuantityDispensed: states.disp,
            fmgAmount: statesxx?.selectedRecord?.fmgAmount,
            fmgPreviousOdometerReading: states.prevOdom,
            fmgCurrentOdometerReading: states.curOdom,
            fmgDispenserRmks: states.remarks2,
            fmgEditedDate: dayjs().format(),
            password: states.password,
          },
          `Dispensed ${states.disp} ltr for the Asset Mgr-Trans-Fuel Dump Transaction Log with request ID-${statesxx.selectedRecord?.fmgIDpk},Dispensed on ${states.selectedDate1}, with Debit Department-${states.dept.name}, Debit Section -${states.sxn.name}, Debit Cost Center - ${states.cost.name}, Total Amount -${statesxx?.selectedRecord?.fmgAmount}, Previous Odom Readings - ${states.prevOdom}, Current Odom Readings - ${states.curOdom} and  Requested By - ${userInfo.empName} successfully`
        );
        smsDispenseAlert();
        setIcon("success");
        setDisOpenModal(true);
        setDisModalData({
          message: "Data entry saved successfully!",
          title: "Data Saved!",
          okText: "Ok",
        });
        setRefresh(!refresh);
        setRefreshx && setRefreshx(!refresh);
      } catch (err: any) {
        setIcon("warning");
        setDisOpenModal(true);
        setDisShowModalDisableButton(false);
        setDisModalData({
          message: err.response ? err.response.data.message : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    };

    const validation = () => {
      setIcon("warning");
      if (
        dayjs(states.selectedDate1).isBefore(
          dayjs(statesxx?.selectedRecord?.fmgDate)
        )
      ) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message:
            "Please, the dispense date cannot be less than the request date",
          title: "Invalid Date!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.dept?.id === 0 || states.dept?.id === undefined) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Please, select the debit department",
          title: "Invalid Department!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.sxn?.id === 0 || states.sxn?.id === undefined) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Please, select the section to be debited",
          title: "Invalid Section!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.cost?.id === 0 || states.cost?.id === undefined) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Please, select the cost center to be debited",
          title: "Invalid Cost Center!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.disp === 0 || states.disp === undefined) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Please, enter the quantity to be dispensed",
          title: "Invalid Quantity!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.curOdom <= states.prevOdom) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message:
            "Please, the current odometer reading cannot be less than the previous reading",
          title: "Invalid Odometer Reading!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.uom1?.id === 0 || states.uom1?.id === undefined) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Please, select the Unit of Measure",
          title: "Invalid Unit of Measure!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else if (states.password === "" || states.password === undefined) {
        setDisShowModalDisableButton(true);
        setDisModalData({
          message: "Please, enter your password",
          title: "Invalid Password!",
          okText: "Ok",
        });
        setDisOpenModal(true);
      } else {
        setDisModalData({
          title: "Update Record!",
          message: "Are you sure you want to update this record?",
          okText: "Yes",
          cancelText: "No",
        });
        setDisShowModalDisableButton(false);
        setIcon("question");
        setDisOpenModal(true);
      }
    };

    const updatedDispensePriv = () => {
      if (updatePriv === true) {
        validation();
      } else {
        setIcon("warning");
        setDisOpenModal(true);
        setDisModalData({
          title: "Access Denied!",
          message: "No Access Privilege",
        });
      }
    };
    return (
      <>
        <ModalTemplate
          icon={icon}
          open={disOpenModal}
          cancelText={modalData.cancelText}
          disableCancel={
            icon === "warning" || icon === "success" ? true : false
          }
          cancelHandler={() => {
            setDisOpenModal(false);
          }}
          okHandler={() => {
            disModalData.title === "Update Record!"
              ? updateDispenseData()
              : setDisOpenModal(false);
            setDisOpenModal(false);
          }}
          message={disModalData.message}
          okText={disModalData.okText}
          title={disModalData.title}
        />
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-1 rounded py-2 px-4"
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 10 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <section className="w-full h-full">
            <DateTemplate
              disabled={false}
              label="Date Dispensed"
              selectedDate={dayjs(states.selectedDate1)}
              changeDate={(date: Dayjs) => {
                setState("selectedDate1", date);
              }}
              span
              width={200}
              format={"ddd DD MMM YYYY HH:MM"}
              disabledDate={(current: any) =>
                current && current.isAfter(dayjs())
              }
            />

            {dispenseSelects.map(
              ({
                id,
                idExpr,
                dataExpr,
                label,
                optionsData,
                stateName,
                defaultValue,
                style,
              }) => {
                return (
                  <>
                    <SelectsTemplate
                      disabled={false}
                      label={label}
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      options={optionsData}
                      placeHolder={defaultValue}
                      useCallFunc
                      selectedValue={(e) => {
                        setState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                      }}
                      handleClear={() => {
                        setState(stateName!, { id: 0, name: "" });
                      }}
                    />
                  </>
                );
              }
            )}

            <Form.Item
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 24 }}
              label={<p>Quantity (R/A/D)</p>}
              className="w-full"
            >
              <div className="w-full flex space-x-1">
                {dispenseInputs.map(
                  ({ id, label, stateName, defaultValue, style }) => {
                    return (
                      <>
                        <InputsTemplate
                          disabledStatus={id === 2 ? false : true}
                          useCallbackFunc
                          span
                          inputStyle={{
                            width: 58,
                            backgroundColor: id === 2 ? "" : "#fff9a0",
                          }}
                          numberOnly={true}
                          defaultValue={defaultValue}
                          orderOnchange={(e) => {
                            setState(stateName!, e);
                          }}
                        />
                      </>
                    );
                  }
                )}

                <>
                  <SelectsTemplate
                    disabled={false}
                    useCallFunc
                    selectStyle={{ width: 75 }}
                    idexpr={"funIDpk"}
                    dataexp={"funShtName"}
                    options={uom_data}
                    placeHolder={states.uom1?.name}
                    selectedValue={(e) => {
                      setState("uom1", {
                        id: e["funIDpk"],
                        name: e["funShtName"],
                      });
                    }}
                    handleClear={() => {
                      setState("uom1", { id: 0, name: "" });
                    }}
                  />
                </>
              </div>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 24 }}
              label={<p>{"Odom. Readings (P/C)"}</p>}
              className="pb-[1px]"
            >
              <div className="w-full flex space-x-1 ">
                {dispenseInputs2.map(
                  ({ id, label, stateName, defaultValue, style }) => {
                    return (
                      <>
                        <InputsTemplate
                          disabledStatus={false}
                          useCallbackFunc
                          span
                          numberOnly={true}
                          defaultValue={defaultValue}
                          orderOnchange={(e) => {
                            setState(stateName!, e);
                          }}
                        />
                      </>
                    );
                  }
                )}

                <p className="w-full flex items-center text-blue-600">{`${states?.curOdom - states?.prevOdom
                  } km`}</p>
              </div>
            </Form.Item>

            <TextareaTemplate
              height={70}
              labelCol={7}
              label={"Remarks"}
              useCallbackFunc
              defaultValue={states.remarks2}
              readonly={false}
              disabled={false}
              setCallbackValue={(e) => {
                setState("remarks2", e);
              }}
            />

            <Form.Item
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 24 }}
              label={
                <p className="text-xs flex items-center">
                  {"Receipient Password"}
                </p>
              }
              className="w-full"
            >
              <div className="w-full  flex justify-between items-center">
                <InputsTemplate
                  inputType="password"
                  span
                  useCallbackFunc
                  disabledStatus={false}
                  inputStyle={{
                    width: 270,
                    height: 23,
                    backgroundColor: "#fed7aa",
                  }}
                  defaultValue={states.password}
                  orderOnchange={(e) => {
                    setState("password", e);
                  }}
                />
                <SaveButton
                  useCallbackFunc
                  disableButton={false}
                  handleSave={() => {
                    updatedDispensePriv();
                  }}
                />
              </div>
            </Form.Item>
          </section>
        </Form>
      </>
    );
  };

  const formModal: formModalStates[] = [
    { id: 0, label: "Dispense Fuel", module: <DispenseForm /> },
  ];

  return (
    <div
      style={{ borderColor: borderTheme, width: "100%", height: "100%" }}
      className=" border-[1px]  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
    >
      <>
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={
            icon === "warning" || icon === "success" ? true : false
          }
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData === saveModalData
              ? postData()
              : modalData === updateModalData
                ? updateData()
                : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <FormModal
          height={1000}
          width={"30%"}
          openState={states.dispenseFormState}
          onCancel={() => {
            setState("dispenseFormState", false);
          }}
          child={formModal[0].module}
          title={formModal[0].label}
        />

        <div
          style={{ maxHeight: "280px", minHeight: "280px", height: "280px" }}
          className=" items-start justify-start "
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-1 rounded py-2 px-4"
            name="basic"
            labelCol={{ span: 7 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div style={{ width: "100%" }} className=" flex ">
              <div style={{ width: "37%" }} className="">
                {pur.map(
                  ({
                    id,
                    label,
                    idExpr,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                    refresh,
                    refreshStateName,
                  }) => {
                    return (
                      <>
                        <SelectsTemplate
                          cusWidth={"80%"}
                          disabledCustomWidth={true}
                          label={label}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          useCallFunc
                          disabled={form_disable}
                          selectedValue={(e) => {
                            purchaseModeChange(e);
                          }}
                          handleClear={() => {
                            setState(stateName!, { id: 0, name: "" });
                            updateStatesx &&
                              updateStatesx("purModeState", {
                                id: 0,
                                name: "",
                              });
                          }}
                          handleRefresh={() => {
                            setState(refreshStateName!, !refresh);
                            updateStatesx &&
                              updateStatesx("purModeState", {
                                id: 0,
                                name: "",
                              });
                          }}
                        />
                      </>
                    );
                  }
                )}

                {dates.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <>
                      <DateTemplate
                        label={label}
                        width={"80%"}
                        changeDate={(e: Dayjs) => {
                          setState(stateName!, dayjs(e).format());
                        }}
                        selectedDate={dayjs(defaultValue)}
                        disabled={true}
                        format={"DD MMMM YYYY HH:mm"}
                      />
                    </>
                  );
                })}
                <>
                  {selects.map(
                    ({
                      id,
                      label,
                      idExpr,
                      dataExpr,
                      optionsData,
                      stateName,
                      defaultValue,
                      style,
                      refresh,
                      refreshStateName,
                      isOpenDropdown,
                      isOpenDropdownKey,
                      singleEndpoint,
                    }) => {
                      const total_selects = selects?.length;

                      return (
                        <div className={`w-full ${id === 1 ? `mb-0.5` : ``} `}>
                          {id == 1 || id == 3 ? (
                            <div className="w-full">
                              <DropdownGrid_template
                                label={label}
                                cusBtnStyle={true}
                                dropdownBtnStyles={{ width: "100%" }}
                                className="w-[400%]"
                                labelCol={7}
                                columns={id == 1 ? vehicleF_cols : ss_cols}
                                disabled={form_disable ? true : false}
                                gridData={optionsData}
                                defaultValue={defaultValue}
                                handleRefresh={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState(refreshStateName!, !refresh);
                                }}
                                onRowClick={(e) => {
                                  setState(stateName!, {
                                    id: e[idExpr!],
                                    name: e[dataExpr!],
                                  });
                                  close();
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              <InputSelect_template
                                cusWidth={"80%"}
                                disabledCustomWidth={true}
                                label={label}
                                options={optionsData}
                                idexpr={idExpr}
                                dataexp={dataExpr}
                                placeHolder={defaultValue}
                                useCallFunc
                                disabled={form_disable}
                                selectedValue={(e) => {
                                  setState(isOpenDropdownKey, false);
                                  setState(stateName!, {
                                    id: e[idExpr!],
                                    name: e[dataExpr!],
                                  });
                                  setState("untP", {
                                    id:
                                      untP_data.length != 0
                                        ? untP_data[0]?.pprIdpk
                                        : 0,
                                    name:
                                      untP_data.length != 0
                                        ? untP_data[0]?.pprUnitPrice
                                        : 0,
                                  });
                                }}
                                handleClear={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState("untP", { id: 0, name: 0 });
                                }}
                                handleRefresh={() => {
                                  setState(refreshStateName!, !refresh);
                                  setState("untP", { id: 0, name: 0 });
                                }}
                                isOpenDropdown={isOpenDropdown}
                                isLoading={states.isLoading}
                                handleFocus={(e: any) => {
                                  setState(isOpenDropdownKey, !isOpenDropdown);
                                }}
                                handleSearch={
                                  debounce((e) => {
                                    setState(stateName!, e);

                                    dispatch(singleEndpoint(e));

                                    setState(isOpenDropdownKey, true);
                                  }, 500)
                                }
                              />
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                </>

                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Quantity/UoM"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <li className="w-full flex items-center flex-row  ">
                    <div className="w-full flex ">
                      <Input
                        // type="number"
                        disabled={form_disable ? true : false}
                        style={{ width: "26%" }}
                        value={states.quan1}
                        onChange={(e: any) => {
                          setState("quan1", e?.target.value);
                          setState("total", e?.target.value * states.unit);
                        }}
                        className=" h-6"
                      />
                      <Input
                        // type="number"
                        disabled={true}
                        style={{ width: "26%" }}
                        value={states.quan2}
                        onChange={(e) => {
                          setState("quan2", e.target.value);
                        }}
                        className="ml-1 h-6 "
                      />
                      <SelectsTemplate
                        style={{ width: "42.5%" }}
                        selectStyle={{ width: "60%", marginLeft: 5 }}
                        disabledCustomWidth={true}
                        label=""
                        options={uom_data}
                        idexpr={"funIDpk"}
                        dataexp={"funShtName"}
                        placeHolder={states.uom?.name}
                        useCallFunc
                        disabled={form_disable}
                        selectedValue={(e) => {
                          setState("uom", {
                            id: e["funIDpk"],
                            name: e["funShtName"],
                          });
                        }}
                        handleClear={() => {
                          setState("uom", { id: 0, name: "" });
                        }}
                      />
                    </div>
                  </li>
                </Form.Item>

                <Form.Item
                  wrapperCol={{ span: 15 }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Unit Price/Total Amount"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <li className=" flex items-center justify-between w-[90%] gap-1">
                    <InputNumber
                      disabled={form_disable ? true : false}
                      type="number"
                      value={states.unit}
                      onChange={(e: any) => {
                        setState("unit", e?.toFixed(2));
                        setState("total", states.quan1 * e?.toFixed(2));
                      }}
                      style={{ width: "50%" }}
                      className="bg-yellow-200"
                    />

                    <InputNumber
                      disabled={true}
                      value={states.total}
                      onChange={(e) => {
                        setState("total", e?.toFixed(2));
                      }}
                      size="small"
                      style={{ width: "50%" }}
                      className="bg-yellow-200"
                    />
                  </li>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 2 }}
                  style={{
                    width: `calc(265% - 0.88%)`,
                    marginLeft: "7%",
                    marginTop: 2,
                  }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Remarks"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <div className="flex flex-row  w-full ">
                    <TextArea
                      style={{
                        resize: "none",
                        height: 40,
                        width: `calc(100%)`,
                      }}
                      className=""
                      readOnly={form_disable}
                      value={states.remarks}
                      onChange={(e) => {
                        setState("remarks", e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>

              <div style={{ width: "39%" }} className="mt-7">
                <Form.Item
                  wrapperCol={{ span: 15 }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Receipt/Coupon No"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <div className="flex">
                    <Input
                      readOnly={true}
                      disabled={true}
                      value={states.coupon}
                      onChange={(e) => {
                        setState("coupon", e.target.value);
                      }}
                      style={{ width: "91%" }}
                    />
                  </div>
                </Form.Item>

                <>
                  {rightSelects.map(
                    ({
                      id,
                      label,
                      idExpr,
                      dataExpr,
                      optionsData,
                      stateName,
                      defaultValue,
                      refreshStateName,
                      refresh,
                    }) => {
                      const total_selects = selects?.length;
                      return (
                        <>
                          {label === "Authorized By" ? (
                            <div className="w-full flex mb-0.5">
                              <DropdownGrid_template
                                label={label}
                                cusBtnStyle={true}
                                dropdownBtnStyles={{ width: "100% " }}
                                className="w-[400%]"
                                labelCol={7}
                                columns={authorizeBy_cols}
                                disabled={form_disable}
                                gridData={optionsData}
                                defaultValue={defaultValue}
                                handleRefresh={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState(refreshStateName!, !refresh);
                                }}
                                onRowClick={(e) => {
                                  if (
                                    e &&
                                    idExpr &&
                                    dataExpr &&
                                    e.hasOwnProperty(idExpr) &&
                                    e.hasOwnProperty(dataExpr)
                                  ) {
                                    setState(stateName!, {
                                      id: e[idExpr],
                                      name: e[dataExpr],
                                    });
                                    close();
                                  } else {
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <SelectsTemplate
                              cusWidth={"80%"}
                              disabledCustomWidth={true}
                              label={label}
                              options={optionsData}
                              idexpr={idExpr}
                              dataexp={dataExpr}
                              placeHolder={defaultValue}
                              useCallFunc
                              disabled={
                                label == "Received By" ? true : form_disable
                              }
                              selectedValue={(e) => {
                                setState(stateName!, {
                                  id: e[idExpr!],
                                  name: e[dataExpr!],
                                });
                              }}
                              handleClear={() => {
                                setState(stateName!, { id: 0, name: "" });
                              }}
                              handleRefresh={() => {
                                setState(refreshStateName!, !refresh);
                              }}
                            />
                          )}
                        </>
                      );
                    }
                  )}
                </>

                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Dept/Section/Cost Center"}
                    </p>
                  }
                  className="mb-0.5 w-full"
                >
                  <li className="w-full flex items-center ">
                    <div className="flex w-full ">
                      <Select
                        disabled={true}
                        style={{ width: "26.35%" }}
                        placeholder="Dept"
                        className="mr-0.5"
                        onChange={(e: any) => {
                          setState("dept", {
                            id: JSON.parse(e)["dptIdpk"],
                            name: JSON.parse(e)["dptShtName"],
                          });
                        }}
                        value={states.dept?.name}
                      >
                        {dept_data?.map((dpt: any, index: number) => {
                          return (
                            <Select.Option
                              key={index}
                              value={JSON.stringify(dpt)}
                            >
                              {dpt["dptShtName"]}
                            </Select.Option>
                          );
                        })}
                      </Select>

                      <Select
                        disabled={true}
                        style={{ width: "26.35%" }}
                        placeholder="Section"
                        className="mr-0.5"
                        onChange={(e: any) => {
                          setState("sxn", {
                            id: JSON.parse(e)["sxnIdpk"],
                            name: JSON.parse(e)["sxnShtName"],
                          });
                        }}
                        value={states.sxn?.name}
                      >
                        {sxn_data?.map((sxn: any, index: number) => {
                          return (
                            <Select.Option
                              key={index}
                              value={JSON.stringify(sxn)}
                            >
                              {sxn["sxnShtName"]}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <Select
                        disabled={true}
                        style={{ width: "26.35%" }}
                        placeholder="Cost"
                        className=""
                        onChange={(e: any) => {
                          setState("cost", {
                            id: JSON.parse(e)["cctIdpk"],
                            name: JSON.parse(e)["cctName"],
                          });
                        }}
                        value={states.cost?.name}
                      >
                        {cost_data?.map((cst: any, index: number) => {
                          return (
                            <Select.Option key={index} value={JSON.parse(cst)}>
                              {cst["cctName"]}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <span
                        onClick={() => {
                          setState("sxn", { id: 0, name: "" });
                          setState("dept", { id: 0, name: "" });
                          setState("cost", { id: 0, name: "" });
                          setState("section", !states.section);
                          setState("department", !states.department);
                          setState("costCenter", !states.costCenter);
                        }}
                        style={{
                          height: 22.5,
                          marginBottom: 2,
                          borderWidth: 1,
                          width: 23.9,
                        }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </li>
                </Form.Item>

                <>
                  {Rightinputs.map(({ id, label, stateName, defaultValue }) => {
                    return (
                      <>
                        <InputsTemplate
                          inputStyle={{ width: "80%" }}
                          widthChange={true}
                          span
                          label={label}
                          defaultValue={defaultValue}
                          useCallbackFunc
                          disabledStatus={true}
                          orderOnchange={(e) => {
                            setState(stateName!, e);
                          }}
                        />
                      </>
                    );
                  })}
                </>

                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Active? "}
                    </p>
                  }
                >
                  <div className="w-full flex justify-between items-center">
                    <CheckboxTemlate
                      defaultValue={states.active}
                      setCheckboxOnchange={(val) => {
                        setState("active", val);
                      }}
                      useCallbackFunc={true}
                      customDisabled={true}
                      withBorders={true}
                    />
                    <span className="flex w-full px-5 ">
                      <p className="text-blue-500 text-xs flex">
                        {`${states?.curOdom - states?.prevOdom} km`}
                      </p>
                    </span>
                  </div>
                </Form.Item>
              </div>

              <div style={{ width: "22%" }} className="mt-7 mx-4 ">
                <div style={{ width: "50%" }} className="p-1">
                  <Image
                    width={150}
                    height={100}
                    src={
                      !showImages
                        ? invalidImage
                        : `data:image/png;base64, ${image_data[0]?.vimImage}`
                    }
                    alt="Vehicle Image"
                    onError={(e) => {
                      e.currentTarget.src = invalidImage;
                    }}
                    className=" text-xs border-[1px] "
                  />
                  <li className=" flex flex-row mt-1 w-full ">
                    <CheckboxTemlate
                      defaultValue={showImages}
                      setCheckboxOnchange={(val) => setShowImages(val)}
                      customDisabled={false}
                      useCallbackFunc={true}
                      withBorders={true}
                    />
                    <p className="text-xs mt-0.5 ml-1 justify-center text-center items-center">
                      Load Vehicle Image
                    </p>
                  </li>
                </div>
              </div>
            </div>

            <Form.Item
              className="w-full "
              colon={false}
              labelCol={{ span: 2 }}
              label={<p className="hidden">{"Active? "}</p>}
            >
              <div
                style={{ width: `calc(100% - 2%)`, marginLeft: "2.4%" }}
                className=" flex justify-between items-center "
              >
                <ul className="flex justify-start">
                  <li>
                    {" "}
                    {(userInfo.empName ===
                      statesxx?.selectedRecord?.authorizedBy?.name ||
                      userInfo.empName ===
                      statesxx?.selectedRecord?.driverName) && (
                        <DispenseButton
                          handleSubmit={() => {
                            statesxx.selectedRecord?.fmgAuthorised
                              ? (() => {
                                setState("dispenseFormState", true);
                              })()
                              : (() => {
                                setIcon("warning");
                                setOpenModal(true);
                                setshowModalDisableButton(true);
                                setModalData({
                                  message:
                                    "Record is yet to be authorised to dispense fuel",
                                  title: "Access Denied!",
                                  okText: "Ok",
                                });
                              })();
                          }}
                        />
                      )}{" "}
                  </li>
                </ul>

                <ul
                  style={{ width: "calc(100%)" }}
                  className="flex justify-end px-1 "
                >
                  {form_disable === false && update_state_change === false ? (
                    <SaveButton
                      handleSave={() => {
                        saveAccessPriv();
                      }}
                    />
                  ) : update_state_change === true ? (
                    <UpdateButton
                      handleUpdate={() => {
                        updateAcccessPriv();
                      }}
                    />
                  ) : (
                    <SaveButton />
                  )}
                  {form_disable === true ? (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        newAccessPriv();
                      }}
                    />
                  ) : update_state_change === true ? (
                    <Cancel cancel_button_toggler={() => { }} />
                  ) : !(update_state_change && form_disable) ? (
                    <Cancel cancel_button_toggler={() => { }} />
                  ) : (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        newAccessPriv();
                      }}
                    />
                  )}
                </ul>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    </div>
  );
};
