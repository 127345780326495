import { Tooltip } from "devextreme-react";
import close from "../../../../../../assets/close.png";
import print from "../../../../../../assets/printer.png";
// import flip from "../../../../../assets/switch-chart.png";
import edit from "../../../../../../assets/edit.png";
import chart from "../../../../../../assets/bar-chart.png";
import newImg from "../../../../../../assets/new.png";
import alter from "../../../../../../assets/alter.png";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Input } from "antd";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../../templates/date";
import { IconsContainer } from "../../_widget/stationReadingsToolbar";
import { RefreshButton } from "../../_widget/utils";
import { useSwitcher, useGraphSwitcher } from "../../db/useSwitcher";
import { RootState } from "../../../../../../app/store";

type Props = {
  showEditIcon?: boolean;
  showAddIcon?: boolean;
  checkOnChange?: (value: any) => void;
  searchTextOnchange?: (value: string) => void;
  handleFind?: () => void;
  runNew?: () => void;
  runUpdate?: () => void;
  runCancel?: () => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  handleRefresh?: (value?: boolean) => void;
  startDate?: string;
  endDate?: string;
  searchValue?: string;
};

export default function EnergyMeterToolbar({
  showEditIcon = true,
  showAddIcon = true,
  checkOnChange,
  searchTextOnchange,
  handleFind,
  startDateChanged,
  endDateChanged,
  runCancel,
  runNew,
  handleRefresh,
  runUpdate,
  startDate,
  endDate,
  searchValue,
}: Props) {
  const { setWitch } = useSwitcher();
  const { setSwitchGraph } = useGraphSwitcher();
  const dispatch = useDispatch();

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState("");
  console.log("searchTextTToolbar", searchText);
  const reduxText = useSelector(
    (state: RootState) => state.general.reduxText
  );
  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
      checkOnChange(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
      checkOnChange(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
      checkOnChange("");
    }
  };

  return (
    <div className="border-2 border-muted w-full h-auto rounded-md">
      <section className="flex items-center w-full h-full space-x-1 px-1">
        <aside className="flex space-x-0.5">
          {showAddIcon && (
            <IconsContainer
              onClick={() => {
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: false })
                );
                dispatch(setGeneralValue({ expr: "updateMode", value: false }));
                runNew();
              }}
              url={newImg}
              name="New record"
              tooltip=""
            />
          )}

          {showEditIcon && (
            <IconsContainer
              onClick={() => {
                runUpdate();
              }}
              url={edit}
              name="Edit"
              tooltip={"Click to update the selected record"}
            />
          )}
          <IconsContainer
            onClick={() => {
              runCancel();
            }}
            url={close}
            name="Delete"
            tooltip="Click to delete the selected record"
          />
          <aside onClick={setWitch}>
            <IconsContainer
              url={alter}
              name="Switch"
              tooltip="Click to switch view modes"
            />
          </aside>
          <aside onClick={setSwitchGraph}>
            <IconsContainer url={chart} name="Chart" tooltip="" />
          </aside>
        </aside>
        <aside className="flex flex-1 space-x-1 items-center">
          <section className="flex-1">
            <Input
              size="small"
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchText(e.target.value);
              }}
              value={searchText}
              placeholder=""
              style={{ width: "100%" }}
            />
          </section>
        </aside>
        <aside className="flex space-x-1 items-center mr-2">
          <DateTemplate
            disabled={false}
            changeDate={(date: Dayjs) =>
              startDateChanged!(dayjs(date).format())
            }
            selectedDate={dayjs(startDate)}
          />
          <DateTemplate
            disabled={false}
            changeDate={(date: Dayjs) => endDateChanged!(dayjs(date).format())}
            selectedDate={dayjs(endDate)}
          />
          <li
            id="multiline-rows-id"
            style={{}}
            className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center "
          >
            <CheckboxTemlate
              defaultValue={checkedBox}
              indeterminate={indeterminate}
              withBorders
              customDisabled={false}
              useCallbackFunc
              setCheckboxOnchange={(e: boolean, index: any) => {
                checkOnChange(e);
                onChange(e);
              }}
            />

            <Tooltip
              target="#multiline-rows-id"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className=" text-left font-semibold">Multiline Rows</p>
              <p>Click to expand or collapse multiple rows in the table.</p>
            </Tooltip>
          </li>
          <div
            onClick={() => {
              handleFind && handleFind();
            }}
            className="p-1 border-[1px] rounded-sm cursor-pointer"
          >
            <FaBinoculars size={15.5} color="#007AFF" />
          </div>
          <RefreshButton
            onClick={() => {
              handleRefresh && handleRefresh();
              setSearchText("");
              setCheckValue("");
              setCheckedBox(false);
              setIndeterminate(true);
            }}
          />

          <IconsContainer url={print} name="Midnight Conditions" tooltip="" />
        </aside>
      </section>
    </div>
  );
}
