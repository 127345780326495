import { Form, Select } from "antd";
import React, { useState } from "react";
import { TextareaTemplate } from "../../../../../../../../templates/textarea";
import { SendEmailButton } from "../../../../../../../accessories/buttons";
import useFetch from "../../../../../../../../hooks/useFetch";
import dayjs from "dayjs";
import { RootState } from "../../../../../../../../app/store";
import { useSelector } from "react-redux";
import { PostDataFunc } from "../../../../../../../../functions/post";
import { useAccountInfo } from "../../../../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { useCrudFunc } from "../../../../../../../../functions/crud";

interface ISMSALERTFORM {
  chooseFromDropDown?: boolean;
  selectedRecordFromDatagrid?: any;
  criteria?: any;
  refresh?: boolean;
  setCriteria?: (e: any) => void;
  setRefresh?: (e: boolean) => void;
}

interface ModalData {
  state: boolean;
  icon: "warning" | "success" | "question";
  title: string;
  okText: string;
  message: string;
  okHandler: () => void;
  cancelHandler: () => void;
  disableCancel: boolean;
}

export const SendSMSAlertForm = ({
  chooseFromDropDown,
  selectedRecordFromDatagrid,
  criteria,
  setCriteria,
  setRefresh,
  refresh,
}: ISMSALERTFORM) => {
  const RecipientCategory = [
    { id: 0, value: "Selected Staff" },
    { id: 1, value: "Unsubmitted" },
    { id: 1, value: "All Active Staff" },
  ];

  let [credit_Unit, setCredit_Unit] = useState(1);
  let [credit_Unit_Count, setCredit_Unit_Count] = useState(160);
  let [total_number_of_char, setTotal_Number_Of_Char] = useState(0);
  let [char_Limit, setChar_Limit] = useState(800);
  let [remaining_Char, setRemaining_Char] = useState(char_Limit);

  const [refresh_SMS, setRefresh_SMS] = useState(true);
  const [ModalData, setModaldata] = useState<ModalData>({
    state: false,
    icon: "warning",
    title: "",
    message: "",
    okText: "",
    okHandler: () => {},
    cancelHandler: () => {},
    disableCancel: false,
  });
  const formCode = useSelector((state: RootState) => state.general.formCode);

  const [Content, setContent] = useState<string>("");

  const [ClientSMSProfile, err, loading] = useFetch(
    `SMSALert/GetNumberOfSMSLeft?CstCode=INNORIKGH01`,
    refresh_SMS
  );

  const [employeeID, userID, user] = useAccountInfo();

  const [posting, updating] = useCrudFunc();

  const PhoneNumberFilter = (str: string) => {
    let format = str
      ?.split("")
      .filter((i: string) => i !== "-")
      .join("");
    let res = "233" + format;
    return res;
  };

  function CheckLastAdminMsgSent(val: any) {
    const current = dayjs();
    const dateOnSentMessage = dayjs(val);
    return (
      current.day === dateOnSentMessage.day &&
      current.month === dateOnSentMessage.month &&
      current.year === dateOnSentMessage.year &&
      current.hour === dateOnSentMessage.hour &&
      current.minute === dateOnSentMessage.minute &&
      current.second === dateOnSentMessage.second
    );
  }

  const SendSMS = async () => {
    try {
      setModaldata({
        ...ModalData,
        state: true,
        icon: "question",
        title: "Send SMS?",
        message: "Are you sure you want to send this message ?",
        okText: "Yes",
        disableCancel: false,
        okHandler: async () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
          await posting(
            `SMSAlert/SaveSMS`,
            {
              imsMessage: Content,
              imsRecipientEmpIdfk: employeeID,
              imsSender: user.empName,
              imsRecordIdfk: 0,
              imsMessageType: "string",
              imsMessageClass: "string",
              imsInitiatorEmpIdfk: employeeID,
              imsCreatedBy: userID,
            },
            `SMS sent from ${user.empName}`
          )
            .then(() => {
              setModaldata({
                ...ModalData,
                state: true,
                icon: "success",
                title: "Success!",
                message: "SMS Sent Successfully",
                okText: "Ok",
                okHandler: () => {
                  setModaldata({
                    ...ModalData,
                    state: false,
                  });
                },
                disableCancel: true,
              });
            })
            .catch(() => {
              setModaldata({
                ...ModalData,
                icon: "warning",
                title: "SMS not sent",
                message: "Please contact your systems Administrator",
                disableCancel: true,
                okText: "Ok",
                okHandler: () => {
                  setModaldata({
                    ...ModalData,
                    state: false,
                  });
                },
              });
            });
        },
        cancelHandler: () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
        },
      });
    } catch (e) {
      setModaldata({
        ...ModalData,
        icon: "warning",
        title: "SMS not sent",
        message: "Please contact your systems Administrator",
        disableCancel: true,
        okText: "Ok",
        okHandler: () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
        },
      });
    }
  };

  return (
    <>
      <ModalTemplate
        icon={ModalData.icon}
        disableCancel={ModalData.disableCancel}
        title={ModalData.title}
        okText={ModalData.okText}
        message={ModalData.message}
        open={ModalData.state}
        okHandler={ModalData.okHandler}
        cancelHandler={ModalData.cancelHandler}
      />

      <div className="px-2">
        <Form
          className="w-full rounded flex flex-col"
          name="basic"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 25 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          <Form.Item
            className=""
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Recipient"}
              </p>
            }
            name="Staff No"
            rules={[{ required: false }]}
          >
            <div className="flex flex-row justify-between mt-1">
              {Object.keys(selectedRecordFromDatagrid).length < 1 ? (
                <Select
                  value={criteria}
                  dropdownMatchSelectWidth={false}
                  className=" mt-0.5"
                  onChange={(data: any) => {
                    const criteriaData = JSON.parse(data);
                    setCriteria!({
                      id: criteriaData?.index,
                      value: criteriaData?.criteria,
                    });
                    setRefresh!(!refresh);
                  }}
                  id="search"
                  size="small"
                  showSearch={false}
                  style={{
                    width: 210,
                    height: "25px",
                  }}
                >
                  {RecipientCategory?.map(({ id, value }: any, index: any) => (
                    <Select.Option
                      key={id}
                      value={JSON.stringify({ index: index, criteria: value })}
                    >
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <p className="font-bold text-xs text-indigo-400">
                  *Requested By :{" "}
                  <span className="text-black">
                    {selectedRecordFromDatagrid?.requesterEmpName}
                  </span>{" "}
                  * Staff No :{" "}
                  <span className="text-black">
                    {selectedRecordFromDatagrid?.requesterStaffNo}
                  </span>
                </p>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Message"}
              </p>
            }
          >
            <TextareaTemplate
              setCreditUnitCount={(val: number) => {
                let crdUntCount = 160 - (val % 160);
                let unitCount = Math.floor(val / 160) + 1;
                setCredit_Unit_Count(crdUntCount);
                setCredit_Unit(unitCount);
                setTotal_Number_Of_Char(val);
                setRemaining_Char(char_Limit - val);
              }}
              setContent={(message: string) => {
                setContent(message);
              }}
              height={160}
              disabled={false}
            />
          </Form.Item>

          <Form.Item
            label={
              <pre className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {""}
              </pre>
            }
            colon={false}
          >
            <Form.Item style={{ display: "inline-block", width: "calc(100%)" }}>
              <div className="flex justify-between ">
                {remaining_Char !== 800 ? (
                  <p className="text-indigo-400">{`${credit_Unit_Count} / ${credit_Unit} ----------> ${total_number_of_char} / ${char_Limit} / ${remaining_Char}`}</p>
                ) : (
                  <p className="text-indigo-400">0/0</p>
                )}
                <SendEmailButton
                  handleMail={() => {
                    SendSMS();
                  }}
                />
              </div>
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
