import React, { useState } from "react";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { Case_details } from "./widget/case_details";
import Datagrid_template from "../../../templates/Datagrid";
import { Case_update } from "./widget/case_update";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { HSeperator, VSeperator } from "../elogger/widgets/utils";
import { Share_case } from "./Others/share_case";
import { Comment_case } from "./Others/comment_case";
import { Item } from "devextreme-react/cjs/accordion";
import { TabPanel } from "devextreme-react";
import { CaseManagementToolbar } from "./Others/caseManagementToolbar";

export const Case_management = () => {
  const [flipSwitcher, setFlipSwitcher] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showUpdateSec, setShowUpdateSec] = useState(true);
  const [showRightPan, setShowRighPan] = useState(false);
  const [switchCase, setSwitchCase] = useState(true);
  const [switchGrid, setSwitchGrid] = useState(true);
  const view = searchParams.get("view");
  const navigate = useNavigate();
  const doubleClick_toggle = () => {
    const isFlipSwitcherInactive =
      flipSwitcher === false ||
      flipSwitcher === null ||
      flipSwitcher === undefined;

    if (isFlipSwitcherInactive) {
      // update forms view
      setFlipSwitcher(true);
      navigate({
        pathname: switchRoutes[0]?.path,
      });
    } else {
      navigate({
        pathname: switchRoutes[0]?.path,
        search: createSearchParams({
          view: switchRoutes[1]?.path,
        }).toString(),
      });
      setFlipSwitcher(false);
    }
  };
  const switchRoutes = [
    {
      id: 0,
      name: "current_page_route",
      path: "/assets-manager/transport/entries/general/case-management",
    },
    { id: 1, name: "update-grid-table", path: "update-grid-table" },
  ];
  const rightTabs: { id: number; label: string; content: any }[] = [
    { id: 0, label: "Share", content: <Share_case /> },
    {
      id: 1,
      label: "Comments",
      content: <Comment_case />,
    },
  ];
  const switchCasex = () => {
    setSwitchCase(!switchCase);
  };
  return (
    <div>
      <CaseManagementToolbar />
      <main className="flex w-full">
        <aside className="w-full">
          {switchGrid && (
            <div className="w-full flex">
              {switchCase && (
                <>
                  <div className="w-full border border-r-2">
                    <Case_details />
                  </div>
                  <VSeperator
                    height={100}
                    onClick={() => setShowUpdateSec(!showUpdateSec)}
                    status={showUpdateSec}
                  />
                </>
              )}
              {showUpdateSec && (
                <div
                  onDoubleClick={doubleClick_toggle}
                  className="w-[100%] border border-l-2 "
                >
                  {(flipSwitcher && view == null) ||
                  view === switchRoutes[0]?.path ? (
                    <Case_update switchCase={switchCasex} />
                  ) : (
                    <Datagrid_template
                      gridheight={430}
                      columns={[]}
                      data={[]}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <HSeperator
            onClick={() => setSwitchGrid(!switchGrid)}
            status={switchGrid}
          />
          <div className="w-full px-1">
            <Datagrid_template
              gridheight={!switchGrid ? 750 : 340}
              columns={[]}
              data={[]}
              disableSearch
              disablePaging
              disableGroupPanel
            />
            <div className="flex w-full mx-4 justify-evenly">
              <div>
                <p className=" text-blue-600">Done</p>
              </div>
              <div>
                <p className=" text-blue-600">* Total No of Cases: {"11"}</p>
              </div>
              <div>
                <p className=" text-blue-600">
                  *Unupdated: {"5/11"} {"(45.45%)"}
                </p>
              </div>
              <div>
                <p className=" text-blue-600">
                  EoA Overdues: {"4/11"} {"(36.36%)"}
                </p>
              </div>
              <div>
                <p className=" text-blue-600">
                  SoD Overdues: {"5/11"} {"(45.45%)"}
                </p>
              </div>
              <div
                className={`  h-[20px] w-[20%]  bg-purple-300 text-xs font-medium text-black text-center items-center p-1 leading-none  `}
                style={
                  {
                    // width: value?.toString() + "%",
                  }
                }
              >
               {"12%"} 
              </div>
            </div>
          </div>
        </aside>
        <VSeperator
          height={100}
          onClick={() => setShowRighPan(!showRightPan)}
          status={!showRightPan}
        />
        {showRightPan && (
          <aside className="w-[20%] h-full ">
            <TabPanel
              showNavButtons={true}
              swipeEnabled
              className="w-full border-[1px] "
            >
              {rightTabs.map(({ id, label, content }) => {
                return (
                  <Item key={id} title={label}>
                    {content}
                  </Item>
                );
              })}

              <div className="text-xs text-indigo-500 px-1">Done</div>
            </TabPanel>
          </aside>
        )}
      </main>
    </div>
  );
};
