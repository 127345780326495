/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"

interface IProps {
    onSelectedId: (v: string) => void;
}

export const SlbLogPreviewDialog = ({ onSelectedId }: IProps) => {
    const [selectedId, setSelectedId] = useState("");

    const handleSelectedId = (label: string) => {
        setSelectedId(label);
    }

    useEffect(() => {
        if (selectedId !== "") {
            onSelectedId(selectedId);
        }
    }, [selectedId])

    return (
        <section className="flex flex-col justify-center space-y-3 items-center">
            <aside className="flex space-x-3">
                <CButtons label="Preview Selected Log(s)" value="previewSelected" callback={handleSelectedId} selectedId={selectedId} />
                <CButtons label="Preview All Logs In Selected Shift" value="previewForSelectedShift" callback={handleSelectedId} selectedId={selectedId} />
            </aside>
            <aside className="flex space-x-3">
                <CButtons label="Cancel Preview for Selected Log(s)" value="cancelPreviewForSelected" callback={handleSelectedId} selectedId={selectedId} />
                <CButtons label="Cancel Preview for All Logs In Selected Shift" value="cancelPreviewForSelectedShift" callback={handleSelectedId} selectedId={selectedId} />
            </aside>
        </section>
    )
}

const CButtons = ({ label, callback, selectedId, value }: { label: string, selectedId: string, value: string, callback: (v: string) => void }) => {
    return (
        <section onClick={() => callback(value)} className={`h-auto px-2 py-1 border-[1px] rounded-md cursor-pointer ${selectedId === value ? "bg-yellow-300" : "bg-white"}`}>
            {label}
        </section>
    )
}