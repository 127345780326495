import { Dayjs } from "dayjs";
import React from "react";
import TabsPanel_template from "../../../templates/tabsPanel_template"; 
import { Calender } from "./tabContent/calender";


export const Calendarmanagement = () => {
  const tabs: { id: number; title: string; contents: any }[] = [
    { id: 0, title: "file", contents: <section className="w-full h-96 bg-red-500" /> },
    { id: 1, title: "Home", contents: <Calender /> },
  ];
  return (
    <>
      <div className="w-full h-full ">
        <section>
          <TabsPanel_template tabs={tabs} />
        </section>
      </div>
    </>
  );
};
