import React from "react";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { Oto_details } from "./oto_details";
import Datagrid_template from "../../../templates/Datagrid";

export const Order_to_operate_main = () => {
  return (
    <div>
      <div>
        <HrSetupsCustomToolbar />
      </div>
      <div>
        <Oto_details />
      </div>
      <div>
        <Datagrid_template gridheight={280} columns={[]} data={[]} />
      </div>
    </div>
  );
};
