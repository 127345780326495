import { Form, Checkbox, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { Cancel, SaveButton, UpdateButton } from "../../../../../accessories/buttons";
import { InputsTemplate } from "../../../../../../templates/input";
import { SelectsTemplate } from "../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { modalTypes, validateInstanceTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useCrudFunc } from "../../../../../../functions/crud";
import { updateModalData } from "../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../templates/modal";
interface statesTypes {
  remarks?: any;
  confirmModal:any,
  warningModal:any,
  successModal:any
  }
  
  interface props{
    selectedRecord?: any;
    handleOnCancel: ()=>void
    handleRefresh: ()=>void
  }
  

export default function TechnicalDescription({
 
  selectedRecord,
  handleOnCancel,
  handleRefresh,
  
}: props) {
  const [states, setStates] = useState<statesTypes>({
     remarks: "",
     confirmModal:{state:false,title:'',message:'',action:'post'},
     warningModal:{state:false,title:'',message:''},
     successModal:{state:false,title:'',message:''}
     
   });
   const borderTheme = useSelector(
     (state: RootState) => state.general.cssBorderClass
   );
   const updateState = (key: string, value: any) => {
     setStates((prev: any) => ({ ...prev, [key]: value }));
   };
   const [PostDataFunc, UpdateDataFunc] = useCrudFunc("powerapp");
   //update data
const update=async(action:'validate'|'update')=>{
const fetchData = async()=>{
   // disableConfirmModal();
   // busyloader(updateModalData.progress)
   updateState('confirmModal',{state:false,title:'string',message:'string'})

   try {
       const updateResponse = await UpdateDataFunc(
         `TroubleReports/UpdateTechnicalDescription`,
         {
           trpTechnicalDescription: states.remarks,
           trpIDpk: selectedRecord?.trpIDpk,
         }
       );

       updateState('successModal',{
         state: true,
         title: "Record Updated",
         message: "Technical Description updated successfully"
     })
     handleRefresh();
       // enableSuccessModal('Update','updated')
       // disableFields();
       // refreshGrid();
       // clearSelected();
       // setrefreshx('');
       //Maintain updated fields
       // populateStatesFromGrid(false);
   } catch (error) {
       console.log(error);

       updateState('warningModal',{
           state: true,
           title: updateModalData.error.title,
           message: updateModalData.error.message
       })
   } finally{
       // busyloader('');
   }
}

if (action==='validate') {
   // validate fieldds
   validateFields()&&
   updateState('confirmModal',{
       state:true,
       title:'Update Record',
       message:'Are you sure you want to update this record?',
       action:'update',
       func() {
        handleOnCancel();
           update('update')
       },
   })
   return;
}
else{
   fetchData();
}
return;
}

const validateFields=():boolean=>{
const validationInstances:validateInstanceTypes[]=[
   {state:states.remarks,baseValue:'',modalProps:{state:true,title:'Enter Rating Remarks',message:'Please enter remarks'}},
   // {state:states.rating,baseValue:'',modalProps:{state:true,title:'Select Completion Date',message:'Please select completion date'}},
 ]

for(let instance of validationInstances) if (instance.state===instance.baseValue) {
   const{modalProps}=instance;
   updateState('warningModal',{
       state:modalProps.state,
       title:modalProps.title,
       message:modalProps.message
   })
       return false;
   }
   return true;
}

// modal instances
const modals:modalTypes[] = [
 //validation
 {disableCancel : true , icon : 'warning' ,open : states.warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : states.warningModal.message, okText : 'Ok', title : states.warningModal.title,cancelText : 'No, cancel'},
 
 //confirmation
 {disableCancel : false , icon : 'question' ,open :  states.confirmModal.state, okHandler :  states.confirmModal.func, cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : states.confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

 //success
 {disableCancel : true , icon : 'success' ,open :  states.successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : states.successModal.message, okText : 'Ok', title : states.successModal.title,cancelText : 'No, cancel'},
]
  



  return (
    <div>
      {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  
                    icon= {icon && icon} 
                    disableCancel = {disableCancel} 
                    cancelText = {cancelText && cancelText}
                    open = {open} 
                    okHandler={okHandler} 
                    cancelHandler={cancelHandler}
                    message= {message}
                    okText= {okText} title={title}/>
                </div>
            )
        })}
           <Form
                name="complex-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="w-full"
               
              >
        <div className="mt-[22px]">
                  <div>
                  
                 

                 <TextareaTemplate
                 
                  labelCol={8}
                 label={""}
                height={100}
                useCallbackFunc
                disabled={false}
                defaultValue={states.remarks}
                setCallbackValue={(e) => { 
                  updateState("remarks", e);
                }}
                parentClassName=""


              />

                   
                    <Form.Item
                    colon={false}
                      style={{ display: "inline-block", marginBottom: "10px" }}
                      label={<p className="text-xs">{}</p>}
                      
                      className="w-full mb-1"
                    >
                      <div className="flex justify-end items-end ">
                       <UpdateButton 
                       useCallbackFunc
                       handleUpdate={() => {
                          validateFields() && update('validate')
                       }}
                       /> 
                       <Cancel useCallbackFunc cancel_button_toggler={()=>{handleOnCancel();}} /> 
                    
                      </div>
                    </Form.Item>
                  </div>
                </div>
                </Form>
    </div>
  )
}
