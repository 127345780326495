
import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Select, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { RootState } from "../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { HrSetupsCustomToolbar } from "../../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { InputsTemplate } from "../../../../../../../templates/input";
import { RefreshButtonTemplate } from "../../../../../../../templates/RefreshButtonTemplate";
import ElogUserSetupTable from "../../../../../../Safety/elogger/StationReadings/tabContents/EquipmentOutage/equipment-data-table";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { navbar_height } from "../../../../../../accessories/component_infos";
import useFetch from "../../../../../../../hooks/useFetch";
import { PageSize } from "devextreme-react/cjs/diagram";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import {
  AllStaffListColumns,
  AllUserGroupsColumns,
} from "../data/elogBookDataPriv";
import {
  useCrudFunc,
  useQueryFetch,
} from "../../../../../../../functions/crud";
import Datagrid from "../../../../../../../templates/Datagrid";
import InputAndSelect_template from "../../../../../../../functions/Dry/InputAndSelect";


export default function ElogUserSetup() {
  //   const [form_height, set_form_height] = useState<number>(0);
  const [leftSectionHeight, setLeftSectionHeight] = useState<number>();
  const [privtype, setprivtype] = useState<string>("save");
  const [messageModal, setMessageModal] = useState<{
    icon: "warning" | "question" | "success";
    openModal: boolean;
    hideCancel: boolean;
    okText: string;
    cancelText: string;
    title: string;
    message: string;
    okHandler: () => void;
    cancelHandler: () => void;
  }>({
    icon: "warning",
    title: "",
    message: "",
    okText: "",
    cancelText: "",
    openModal: false,
    okHandler: () => {},
    cancelHandler: () => {},
    hideCancel: false,
  });

  //   const formRef = useRef<any>(null);
  const leftForm = useRef<any>(null);

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  var [datagridHeight, setDataGridHeight] = useState<number>(0);
  //   var [intialH, finalHeight] = useResizeMode(0);

  // //   var initialH = intialH - datagridHeight;
  //   var [fullPage] = useState(
  //     finalHeight - (navbar_height + news_flash_height + 35)
  //   );
  const [busyloader, setBusyloader] = useBusyLoader();
  const dispatch = useDispatch();
  const [empId, userId, userInfo] = useAccountInfo();
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);

  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const selectExprs = useSelector(
    (state: RootState) => state.general.selectExprs
  );
  const selectAfterExprs = useSelector(
    (state: RootState) => state.general.selectAfterExprs
  );
  const textareaExpr = useSelector(
    (state: RootState) => state.general.inputExprs.remarksExpr
  );
  const orderListExprs = useSelector(
    (state: RootState) => state.general.checkboxExprs
  );

  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 10);
    setLeftSectionHeight(leftForm.current?.clientHeight);
  }, []);

  const [createUserGroup, updateUserGroup] = useCrudFunc("powerapp");
  const width = 450;
  const [intialH, finalHeight] = useResizeMode(0);
  const gridHeight = intialH - 320; // Adjust this value as needed

  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 130);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 25);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [selectedStaff, setSelectedStaff] = useState<any>({});
  const [selectedGroupMember, setSelectedGroupMember] = useState<any>({});
  const [addUser, setAddUser] = useState<boolean>(false);
  const [removeUser, setRemoveUser] = useState<boolean>(false);
  const [userGroupFormState, setUserGroupFormState] = useState({
    name: "",
    shortName: "",
    active: true,
    remarks: "",
  });
  const [searchParam, setSearchParam] = useState({
    searchTerm: { initialValue: "", value: "" },
    searchCriteria: { initialValue: 0, value: 0 },
    checkState: { initialValue: "", value: "" },
    pageNumber: 1,
    pageSize: 50,
  });
  const [refreshButtons, setRefreshButton] = useState({
    allUserGroupsRefresh: false,
    staffListRefresh: false,
    groupMembersRefresh: false,
  });
  const { searchTerm, searchCriteria, checkState, pageNumber, pageSize } =
    searchParam;
  const [getAllStaffList, getAllStaffListError, getAllStaffListLoading] =
    useFetch(
      `ElogBookPriviledgesAssignment/GetAllStaffListAsync?ugpIDpk=${selectedGroup?.ugpIDpk}`,
      refreshButtons.staffListRefresh,
      "",
      false,
      "powerapp"
    );
  const [
    getAllGroupMembers,
    getAllGroupMembersError,
    getAllGroupMembersListLoading,
  ] = useFetch(
    `ElogBookPriviledgesAssignment/GetAllGroupMembersAsync?ugpIDpk=${selectedGroup?.ugpIDpk}`,
    refreshButtons.groupMembersRefresh,
    "",
    false,
    "powerapp"
  );
  const [userGroupName, userGroupNameErr, userGroupNameLoading] = useFetch(
    `ElogBookPriviledgesAssignment/PopUserGroupName`,
    true,
    "",
    false,
    "powerapp"
  );

  const { data: getAllUserGroup, loading: getAllUserGroupLoading } =
    useQueryFetch({
      app: "powerapp",
      url: `ElogBookPriviledgesAssignment/GetAllUserGroupAsync`,
      refresh: refreshButtons.allUserGroupsRefresh,
      revalidateAfter: 0,
      params: {
        SearchTerm: searchTerm.value === "" ? undefined : searchTerm.value,
        CheckState: checkState.value,
        SearchCriteria: searchCriteria.value,
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });
  const handleNew = () => {
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setSelectedGroup({});
      setUserGroupFormState((prev: any) => ({
        ...prev,
        name: "",
        shortName: "",
        active: true,
        remarks: "",
      }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    }
  };
  const sendAndReturnMessageForSaving = async () => {
    setBusyloader("Saving record...");
    await createUserGroup(
      `ElogBookPriviledgesAssignment/CreateGroupAsync`,
      {
        ugpName: userGroupFormState.name,
        ugpShtName: userGroupFormState.shortName,
        ugpActive: userGroupFormState.active,
        ugpRmks: userGroupFormState.remarks,
      },
      `Created user group withe Name: ${userGroupFormState.name}, Short name:${userGroupFormState.shortName}, Active status:${userGroupFormState.active} and Remarks:${userGroupFormState.remarks}`
    )
      .then((res) => {
        setBusyloader("");
        setRefreshButton((prev: any) => ({
          ...prev,
          allUserGroupsRefresh: !refreshButtons.allUserGroupsRefresh,
        }));
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "success",
          title: "Success",
          message: `Record created successfully`,
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      })
      .catch((err) => {
        setBusyloader("");
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: `${
            err?.response?.data?.code == 500 ? "Warning" : "Name Exists"
          }`,
          message: `${
            err?.response?.data?.code == 500
              ? "Record not saved. Please contact the systems administrator "
              : err?.response?.data?.message ||'The name or shortname you entered exists. Please enter a different name or shortname'
          }`,
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      });
  };

  const handleSave = async () => {
    if (savePriv === true) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Save ?",
        message: "Are you sure you want to save this record?",
        okText: "Yes",
        cancelText: "No",
        hideCancel: false,
        openModal: true,
        okHandler: async () => {
          sendAndReturnMessageForSaving();
        },
        cancelHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access denied!",
        message: "You do not have the privilege to create new records",
        okText: "okay",
        hideCancel: true,
        openModal: true,
        okHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    }
  };

  const sendAndReturnMessageForUpdating = async () => {
    setBusyloader("Updating record...");
    await updateUserGroup(
      `ElogBookPriviledgesAssignment/UpdateGroupAsync`,
      {
        ugpIDpk: selectedGroup?.ugpIDpk,
        ugpName: userGroupFormState.name,
        ugpShtName: userGroupFormState.shortName,
        ugpActive: userGroupFormState.active,
        ugpRmks: userGroupFormState.remarks,
      },
      `Update record with Name:${userGroupFormState.name}, shortName:${userGroupFormState.shortName}, active:${userGroupFormState.active}, remarks:${userGroupFormState.remarks}`
    )
      .then(() => {
        setBusyloader("");
        setRefreshButton((prev: any) => ({
          ...prev,
          allUserGroupsRefresh: !refreshButtons.allUserGroupsRefresh,
        }));
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "success",
          title: "Success",
          message: "Record updated successfully",
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      })
      .catch((err) => {
        setBusyloader("");
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: "Warning",
          message: `${
            err?.response?.data?.code == 500
              ? "Record not saved. Please contact the systems administrator "
              : err?.response?.data?.message
          }`,
          openModal: true,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
        }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      });
  };

  const handleUpdate = async () => {
    if (savePriv === true) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Update ?",
        message: "Are you sure you want to update this record?",
        okText: "Yes",
        cancelText: "No",
        hideCancel: false,
        openModal: true,
        okHandler: async () => {
          sendAndReturnMessageForUpdating();
        },
        cancelHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access denied!",
        message: "You do not have the privilege to create new records",
        okText: "okay",
        hideCancel: true,
        openModal: true,
        okHandler: async () => {
          setMessageModal((prev: any) => ({
            ...prev,
            openModal: false,
          }));
        },
      }));
    }
  };

  const addUserToGroup = async () => {
    await updateUserGroup(
      `ElogBookPriviledgesAssignment/AddUserToGroupAsync`,
      {
        ugpIDpk: selectedGroup?.ugpIDpk,
        ugmEmpIDfk: selectedStaff?.empIDpk,
      },
      `added record with group id=${selectedStaff.ugpIDpk} employee Id=${selectedStaff?.empIDpk}`
    );
    setRefreshButton((prev: any) => ({
      ...prev,
      groupMembersRefresh: !refreshButtons.groupMembersRefresh,
    }));
    setAddUser(false);
  };
  const removeUserFromGroup = async () => {
    setMessageModal((prev: any) => ({
      ...prev,
      icon: "question",
      title: "Remove?",
      message: "Are you sure you want to remove user from  group?",
      okText: "Yes",
      cancelText: "No cancel",
      hideCancel: false,
      openModal: true,
      okHandler: async () => {
        await updateUserGroup(
          `ElogBookPriviledgesAssignment/RemoveStaffFromGroupAsync`,
          {
            ugmIDpk: selectedGroupMember?.ugmIDpk,
            ugmEmpIDfk: selectedGroupMember?.empIDpk,
          },
          `removed record with group id=${selectedGroupMember?.ugmIDpk} employee Id=${selectedGroupMember?.empIDpk}`
        );
        setRefreshButton((prev: any) => ({
          ...prev,
          groupMembersRefresh: !refreshButtons.groupMembersRefresh,
        }));
        setRemoveUser(false);
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
      },
      cancelHandler: () => {
        setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        setRemoveUser(false);
      },
    }));
  };

  useEffect(() => {
    if (addUser === true) {
      addUserToGroup();
    }
  }, [addUser]);

  useEffect(() => {
    if (removeUser) {
      removeUserFromGroup();
    }
  }, [removeUser]);

  useEffect(() => {
    setUserGroupFormState((prev: any) => ({
      ...prev,
      name: selectedGroup?.ugpName ?? "",
      shortName: selectedGroup?.ugpShtName ?? "",
      active: selectedGroup?.ugpActive ?? true,
      remarks: selectedGroup?.ugpRmks ?? "",
    }));
  }, [selectedGroup]);

  useEffect(() => {
    if (getAllUserGroupLoading === true) {
      setBusyloader("Fetching records...");
    } else {
      setBusyloader("");
    }
  }, [getAllUserGroupLoading]);
  const validateBeforeSaveOrUpdate = (method: string) => {
    if (userGroupFormState.name == "") {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Warning",
        message: "Please enter user group name",
        openModal: true,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    }
    if (userGroupFormState.shortName == "") {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Warning",
        message: "Please enter user group short name",
        openModal: true,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
      }));
      return;
    } else {
      if (method == "save") {
        return handleSave();
      } else {
        return handleUpdate();
      }
    }
  };
  const customToolTip = {
    newBtn: { id: "nwbtn", tooltipID: "#nwbtn" },
    editBtn: { id: "edtbtn", tooltipID: "#edtbtn" },
    cancelBtn: { id: "cclbtn", tooltipID: "#cclbtn" },
    switchBtn: { id: "stchbtn", tooltipID: "#stchbtn" },
    helpBtn: { id: "hlpbtn", tooltipID: "#hlpbtn" },
    checkBtn: { id: "chkbtn", tooltipID: "#chkbtn" },
    findBtn: { id: "fndbtn", tooltipID: "#fndbtn" },
    refreshBtn: { id: "rfshbtn", tooltipID: "#rfshbtn" },
    printBtn: "pritBtn3",
  };
  console.log("userGroupFormState", userGroupFormState);
  console.log("selectedGroup", selectedGroup);
  console.log("searchParam", searchParam);
  console.log("getAllStaffList", getAllStaffList);
  return (
    <>
      <ModalTemplate
        icon={messageModal.icon}
        title={messageModal.title}
        message={messageModal.message}
        okText={messageModal.okText}
        cancelText={messageModal.cancelText}
        open={messageModal.openModal}
        disableCancel={messageModal.hideCancel}
        okHandler={messageModal.okHandler}
        cancelHandler={messageModal.cancelHandler}
      />
      <div className="w-full h-full flex flex-col">
        <div className="px-2">
          <HrSetupsCustomToolbar
            toggler={switch_toggle}
            setVisible={setSwitch_change}
            handleNew={() => {
              handleNew();
            }}
            // handleUpdate={() => {}}
            handleFind={() => {
              setSearchParam((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParam.searchTerm,
                  value: searchParam.searchTerm.initialValue,
                },
                checkState: {
                  ...searchParam.checkState,
                  value: searchParam.checkState.initialValue,
                },
              }));
              setRefreshButton((prev: any) => ({
                ...prev,
                allUserGroupsRefresh: !refreshButtons.allUserGroupsRefresh,
              }));
            }}
            handleRefresh={() => {
              setSearchParam((prev: any) => ({
                ...prev,
                searchTerm: {
                  ...searchParam.searchTerm,
                  initialValue: "",
                  value: "",
                },
                checkState: {
                  ...searchParam.checkState,
                  initialValue: "",
                  value: "",
                },
              }));
              setRefreshButton((prev: any) => ({
                ...prev,
                allUserGroupsRefresh: !refreshButtons.allUserGroupsRefresh,
              }));
            }}
            checkOnChange={(e) => {
              setSearchParam((prev: any) => ({
                ...prev,
                checkState: { ...searchParam.checkState, initialValue: e },
              }));
            }}
            searchTextOnchange={(e) => {
              setSearchParam((prev: any) => ({
                ...prev,
                searchTerm: { ...searchParam.searchTerm, initialValue: e },
              }));
            }}
            outerSelected={selectedGroup}
            // selected_Record={selectedGroup}
            // setSelected_Record={() => {
            //   setSelectedGroup({});
            // }}
            withDates={false}
            customTooltipIds={customToolTip}
            showPrintIcon
          />
        </div>
        <div className="flex flex-col flex-grow">
          {switch_change && (
            <div ref={formRef} className="px-2 flex-shrink-0">
              <p
                id="form_header"
                style={{ borderColor: colorTheme }}
                className="pt-1 border-[1px] border-b-0 pl-2 w-full bg-slate-100 font-semibold"
              >
                Group Details
              </p>
              <Form
                className="w-full border-[1px] border-slate-100 rounded-b flex justify-between py-1 px-2"
                style={{ borderColor: colorTheme }}
                name="basic"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
              >
                <section className="" style={{ width: width }} ref={leftForm}>
                  <Form.Item
                    className="mb-2"
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        Name
                      </p>
                    }
                    name="Name"
                    rules={[{ required: false }]}
                  >
                    <InputAndSelect_template
                      useCallFunc
                      idexpr="ugpIDpk"
                      dataexp="ugpName"
                      placeHolder={userGroupFormState.name}
                      options={userGroupName}
                      selectedValue={(e) => {
                        console.log("name", e);
                        setUserGroupFormState((prev: any) => ({
                          ...prev,
                          name: e?.ugpName,
                          shortName: e?.ugpShtName ?? e?.ugpName,
                        }));
                      }}
                      handleRefresh={() => {
                        setUserGroupFormState((prev: any) => ({
                          ...prev,
                          // name: userGroupName[0]?.ugpName,
                          name: '',
                          // shortName: userGroupName[0]?.ugpShtName,
                          shortName:'',
                        }));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-2"
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        Short Name
                      </p>
                    }
                    name="ShortName"
                    rules={[{ required: false }]}
                  >
                    <InputAndSelect_template
                      useCallFunc
                      idexpr="ugpIDpk"
                      dataexp="ugpShtName"
                      placeHolder={userGroupFormState.shortName}
                      options={userGroupName}
                      selectedValue={(e) => {
                        setUserGroupFormState((prev: any) => ({
                          ...prev,
                          shortName: e?.ugpShtName,
                        }));
                      }}
                      span
                    />
                  </Form.Item>

                  <BottomBtns
                    outerDisabled={form_disable}
                    useCallbackFunc={false}
                    onNewClick={() => {
                      handleNew();
                    }}
                    onCancelClick={() => {}}
                    updateHandler={() => {
                      validateBeforeSaveOrUpdate("update");
                    }}
                    saveHandler={() => {
                      validateBeforeSaveOrUpdate("save");
                    }}
                    span={true}
                    addActiveBorders={true}
                    defaultValue={userGroupFormState.active}
                    checkboxChange={(value) => {
                      setUserGroupFormState((prev: any) => ({
                        ...prev,
                        active: value,
                      }));
                    }}
                  />
                </section>

                <section style={{ width: `calc(100% - ${width}px)` }}>
                  <TextareaTemplate
                    MarginLeft={60}
                    //   dataExpr={textareaExpr}
                    label="Remarks"
                    height={leftSectionHeight!}
                    defaultValue={userGroupFormState.remarks}
                    setContent={(e) => {
                      setUserGroupFormState((prev: any) => ({
                        ...prev,
                        remarks: e,
                      }));
                    }}
                  />
                </section>
              </Form>
            </div>
          )}

          <div className="px-2">
            {" "}
            <div className="border-t-4 border-gray-300 my-2"></div>
          </div>

          {/* <div className="flex-grow overflow-auto"> */}
          <div className="w-full flex space-x-4">
            {/* Group Section */}
            <aside className="flex flex-col border border-gray-300 rounded-md overflow-hidden w-1/3">
              <div className="flex bg-gray-100 justify-between p-2">
                <p className="font-semibold">Group</p>
              </div>
              <Datagrid
                dataId="ugpIDpk"
                deselectFirstRow={false}
                disablePaging
                className="flex-grow flex-shrink-0 w-full"
                gridheight={switch_change === true ? gridHeight : fullPage}
                columnAutoWidth={true}
                columns={AllUserGroupsColumns}
                data={getAllUserGroup?.data}
                rowDoubleClicked={(e: any) => {
                  setSwitch_change(true);
                  setSelectedGroup(e);
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: false })
                  );
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: true })
                  );
                }}
                onRowClick={(e: any) => {
                  setSelectedGroup(e);
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: false })
                  );
                }}
              />
            </aside>

            {/* Staff List Section */}
            <aside className="flex flex-col border border-gray-300 rounded-md overflow-hidden w-1/3">
              <div className="flex bg-gray-100 justify-between p-2">
                <p className="font-semibold">Staff List</p>
              </div>
              <Datagrid
                deselectFirstRow
                disablePaging
                className="flex-grow flex-shrink-0 w-full"
                gridheight={switch_change === true ? gridHeight : fullPage}
                columns={AllStaffListColumns}
                data={getAllStaffList}
                onRowClick={(e: any) => {}}
                rowDoubleClicked={(e: any) => {
                  setSelectedStaff(e);
                  setAddUser(!addUser);
                }}
              />
            </aside>

            {/* Group Members Section */}
            <aside className="flex flex-col border border-gray-300 rounded-md overflow-hidden w-1/3">
              <div className="flex bg-gray-100 justify-between p-2">
                <p className="font-semibold">Group Members</p>
              </div>
              <Datagrid
                deselectFirstRow
                disablePaging
                className="flex-grow flex-shrink-0 w-full"
                gridheight={switch_change === true ? gridHeight : fullPage}
                columns={AllStaffListColumns}
                data={getAllGroupMembers}
                rowDoubleClicked={(e: any) => {
                  setSelectedGroupMember(e);
                  setRemoveUser(!removeUser);
                }}
              />
            </aside>
          </div>
          {/* </div> */}
        </div>
      </div>{" "}
    </>
  );
}
