export const Outage_EquipmentColumns = [
    {
      id: 0,
      caption: "ID",
      dataField: "oetIdpk",
      dataType: "number",
      width: 80,
      fixed: true,
      visible: false,
    },
    {
      id: 1,
      caption: "NAME",
      dataField: "oetName",
      dataType: "string",
      width: 160,
      fixed: true,
      visible: true,
    },
    {
      id: 2,
      caption: "SHORT NAME",
      dataField: "oetShtName",
      dataType: "string",
      width: 160,
      fixed: true,
      visible: true,
    },
    {
      id: 3,
      caption: "ACTIVE?",
      dataField: "oetActive",
      dataType: "boolean",
      width: 90,
      fixed: true,
      visible: true,
    },
    {
      id: 4,
      caption: "TRANFO?",
      dataField: "oetTransformer",
      dataType: "boolean",
      width: 90,
      fixed: true,
      visible: true,
    },
    {
      id: 5,
      caption: "FEEDER?",
      dataField: "oetFeeder",
      dataType: "boolean",
      width: 90,
      fixed: true,
      visible: true,
    },
    {
      id: 6,
      caption: "LINE?",
      dataField: "oetLine",
      dataType: "boolean",
      width: 90,
      fixed: true,
      visible: true,
    },
    {
      id: 7,
      caption: "CAP.BANK?",
      dataField: "oetCapacitorBank",
      dataType: "boolean",
      width: 90,
      fixed: true,
      visible: true,
    },
    {
      id: 8,
      caption: "SVC?",
      dataField: "oetStaticVarCompensator",
      dataType: "boolean",
      width: 90,
      fixed: true,
      visible: true,
    },
    {
      id: 9,
      caption: "REMARKS",
      dataField: "oetRmks",
      dataType: "string",
      width: 5000,
      fixed: false,
      visible: true,
    },
  ];