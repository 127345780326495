

export const manufacturers_TH = [
  {
    id: 0,
    caption: "NAME",
    datafield: "mftName",
    // alignment: "left",
    fixed: true,
    width: 120,
    visible : true,
    dataType : 'string',
  },
  {
    id: 1,
    caption: "SHORT NAME",
    datafield: "mftShtName",
    // alignment: "left",
    fixed: true,
    width: 120,
    visible : true,
    dataType : 'string',
  },
  {
    id: 2,
    caption: "ORDER",
    datafield: "mftOrder",
    // alignment: "left",
    fixed: true,
    width: 100,
    visible : true,
    dataType : 'string',
  },
  {
    id: 3,
    caption: "ACTIVE?",
    datafield: "mftActive",
    // alignment: "left",
    fixed: true,
    width: 90,
    visible : true,
    dataType : 'boolean',
  },
  
  {
    id: 4,
    caption: "REMARKS",
    datafield: "mftRmks",
    // alignment: "left",
    fixed: false,
    width: 4000,
    visible : true,
    dataType : 'string',


  },
  

]


export const manufacturers_data = [
  
]