import { Tooltip } from "devextreme-react";
import 'devextreme/dist/css/dx.light.css';
import { useState } from "react";
import question from '../../../assets/question.png';

import { Checkbox, DatePicker, Input, Select } from 'antd';
import dayjs from "dayjs";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";


interface props {
    toggler?: () => void
    searchTextOnchange?: (value: string) => void
    startDateChanged?: (value: any) => void 
    endDateChanged?: (value: any) => void 
    checkOnChange?: (value: any) => void 
    onChange?: (value: any) => void 
    onChange2?: (value: any) => void 
    handleFind?: () => void 
    searchCriteriaOnchange?: (index: number, criteria: string) => void 

    startDate?: string
    endDate?: string
    searchCriteriaData?: { id: number, value: string }[]

}


interface stateTypes {
    
    searchTexts: string,
    activeStatus: boolean,
    staffStatus: boolean,
    
    

}



export const ADPH_toolbar = ({
    toggler,
    searchTextOnchange,
    startDateChanged, 
    endDateChanged, 
    onChange, 
    onChange2, 
    searchCriteriaOnchange,
    handleFind, 

    startDate,
    endDate,
    searchCriteriaData = [],

}: props) => {

    const { Search } = Input;

    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass);

    
    const [states, setStates] = useState<stateTypes>({
        searchTexts: "",
        activeStatus: false,
        staffStatus: false,
        
        
    })

    const { searchTexts, activeStatus, staffStatus, } = states

    const [criteriaDataLocal, setCriterialDataLocal] = useState({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.value ?? '' });


    
    const setState = (key: string, value: any) => {
        setStates({ ...states, [key]: value })
    }

    
    return (
        <div className="px-2">
            <div style={{ borderColor: borderTheme }} className="w-full flex justify-center border-[1px] px-1 rounded border-gray-200 my-1" >
                <ul className="flex  w-full items-center h-full ">
                    {/* Differents starts here */}

                    <li className="items-right  flex ">
                        <label className='flex text-xs px-2 items-center'>Search Critiria : </label>
                        <Select
                            value={criteriaDataLocal}
                            popupMatchSelectWidth={false}
                            className=" mt-0.5"
                            onChange={(data: any) => {
                                const criteriaData = JSON.parse(data);
                                setCriterialDataLocal({ id: criteriaData?.index, value: criteriaData?.criteria }) 
                                searchCriteriaOnchange && searchCriteriaOnchange(criteriaData?.index, criteriaData?.criteria); 
                            }}
                            id="search"
                            size="small"
                            showSearch={true}
                            style={{ width: 150, height: '25px' }}>
                            {searchCriteriaData?.map(({ id, value }, index) =>
                                <Select.Option key={id} value={JSON.stringify({ index: index, criteria: value })}>{value}</Select.Option>
                            )}
                        </Select>
                       
                    </li>


                    <li className="px-1 flex items-center ml-2 ">
                        <label className='flex text-xs px-2 items-center'>Search Text</label>
                        <Input
                            onChange={(e: any) => {
                                const searchText: string = e.target.value;
                                searchTextOnchange && searchTextOnchange(searchText);
                                setState('searchTexts', e.target.value)
                            }}
                            value={searchTexts}
                            name="search"
                            id="search"
                            size="small"
                            allowClear={true}
                            style={{ width: 150, height: '24px' }}
                        
                        
                        />
                    </li>
                    {/* <li style={{ borderWidth: '1px', borderColor: borderTheme }} id="active_status" className="flex px-0.5 mr-1 items-center rounded-md">
                        <label className='flex text-xs px-2 items-center'>Active status</label>
                        <Checkbox
                            
                            
                            
                            
                            
                            checked={activeStatus}
                            onChange={(checkValue: any) => {
                                const stat: boolean = checkValue.target.checked;
                                onChange && onChange(stat);

                                setState('activeStatus', checkValue.target.checked)
                            }}
                        />
                        <Tooltip
                            target="#active_status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Active status</p>
                        </Tooltip>
                    </li> */}

                    <li style={{ borderWidth: '1px', borderColor: borderTheme }} id="staff_status" className="flex px-0.5 mr-1 items-center rounded-md">
                        <label className='flex text-xs px-2 items-center'>Staff</label>
                        <Checkbox
                            
                            
                            
                            checked={staffStatus}
                            onChange={(checkValue: any) => {
                                const stat: boolean = checkValue.target.checked
                                onChange2 && onChange2(stat);

                                setState('staffStatus', checkValue.target.checked)
                            }}
                        

                        />
                        <Tooltip
                            target="#staff_status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Staff status</p>
                        </Tooltip>
                    </li>
                    <li className="px-0.5">
                        <DatePicker allowClear={false} value={dayjs(startDate) ?? dayjs()} onChange={startDateChanged} size="small" placeholder="Start Date" style={{ width: 125 }} format={"DD MMM YYYY"} />
                    </li>
                    <li className="px-0.5">
                        <DatePicker allowClear={false} value={dayjs(endDate) ?? dayjs()} onChange={endDateChanged} size="small" placeholder="End Date" style={{ width: 125 }} format={"DD MMM YYYY"} />
                    </li>



                    <li onClick={() => {
                        if (handleFind) {
                            handleFind();
                        } else {

                        }
                    }} id="find" style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                        <span> <FaBinoculars size={15.5} color="#007AFF" /> </span>
                        <Tooltip
                            target="#find"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Find</p>
                        </Tooltip>
                    </li>



                    <li id="help" style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={question} /> </span>
                        <Tooltip
                            target="#help"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Help Center</p>
                        </Tooltip>
                    </li>


                </ul>

            </div>
        </div>
    )
};


