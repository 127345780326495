

export const stationEntriesColumns = (customArr:any[]) => {
   const uniqueStations = new Map();

   customArr.forEach((item: any) => {
     item.station.forEach((station: any) => {
       if (station?.bdtName && station.bdtName.trim() !== "" && !uniqueStations.has(station.bdtIDpk)) {
         uniqueStations.set(station.bdtIDpk, station.bdtName);
       }
     });
   });
 
   const dynamicColumns = Array.from(uniqueStations).map(([bdtIDpk, bdtName], index: number) => {
     return {
       id: bdtIDpk, 
       caption: bdtName, 
       dataField: `${bdtName}`, 
       fixed: false,
       width: 100,
       allowEditing: true,
     };
   });
  return [
    {
      id: 1,
      caption: "TIME",
      dataField: "colTime",
      fixed: false,
      allowEditing: false,
      width: 70,
    },
    ...dynamicColumns, // Spread the dynamic columns
    {
      id: customArr.length + 2,
      caption: "REMARKS",
      dataField: "remarks",
      allowEditing: true,
    },
  ];
}

export const chartLabels =[
  {id: 1,caption : "Bus", dataField : 'busName', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Voltage", dataField : 'voltage', dataType : "", fixed : true,width:150},
]
  

export const stationEntries = [
  { id: 0, time: "00:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 1, time: "01:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 2, time: "02:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 3, time: "03:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 4, time: "04:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 5, time: "05:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 6, time: "06:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 7, time: "07:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 8, time: "08:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 9, time: "09:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 10, time: "10:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 11, time: "11:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 12, time: "12:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 13, time: "13:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 14, time: "14:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
];

// Station content for the BusStation tab
export const stationColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    fixed: false,
    
    width: 100,
  },
  {
    id: 2,
    caption: "BUS",
    dataField: "bdtName",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "brdDate",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "brdTime",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "brdVoltage",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "ACTIVE",
    dataField: "brdActive",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "REMARKS",
    dataField: "brdRmks",
    width: 40000
  },
];
export const transformerFormColumns: Array<Record<string, any>> = [
  {
    id: 1,
    caption: "TRANSF NOM",
    dataField: "tfdTransformerID",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SERIAL No",
    dataField: "tfdSerialNo",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "MANUFACTURER",
    dataField: "mftName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "STANDARD",
    dataField: "tfdStandard",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "NANUF. DATE",
    dataField: "tfdManufactureDate",
    dataType: "datetime",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "INSTALL DATE",
    dataField: "tfdInstallationStartDate",
    dataType: "datetime",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    // no status field in the transformer data
    id: 7,
    caption: "STATUS",
    dataField: "tfdStandard",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "FIT?",
    dataField: "estFitStatus",
    dataType: "boolean",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 9,
    caption: "VERIFIED?",
    dataField: "tfdVerified",
    dataType: "boolean",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    // no SISR field in the transformer data
    id: 10,
    caption: "SISR",
    dataField: "tfdStandard",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 11,
    caption: "ACTIVE?",
    dataField: "tfdActive",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 12,
    caption: "RATED VOLTAGE 1 (KV)",
    dataField: "tfdRatedVoltageI",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 13,
    caption: "RATED VOLTAGE 2 (KV)",
    dataField: "tfdRatedVoltageII",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 14,
    caption: "RATED VOLTAGE 3 (KV)",
    dataField: "tfdRatedVoltageIII",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 15,
    caption: "RATED POWER 1 (MVA)",
    dataField: "tfdRatedPowerI",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 16,
    caption: "RATED POWER 2 (MVA)",
    dataField: "tfdRatedPowerII",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 17,
    caption: "RATED POWER 3 (MVA)",
    dataField: "tfdRatedPowerIII",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 18,
    caption: "RATED OUTPUT",
    dataField: "tfdRatedOutput",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 19,
    caption: "CONN. SYMBOL",
    dataField: "tfdConnectionSymbol",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 20,
    caption: "COOLING TYPE",
    dataField: "tfdCoolingType",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 21,
    caption: "TAP CHANGER TYPE",
    dataField: "tfdTapChangerType",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 22,
    caption: "TAPPED WINDING",
    dataField: "tfdTappedWinding",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 23,
    caption: "TAP CHANGER RANGE",
    dataField: "tfdTapChangerRange",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 24,
    caption: "TAP CHANGER STEP",
    dataField: "tfdTapChangerStep",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 25,
    caption: "NORMAL TAP",
    dataField: "tfdNominalTap",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 26,
    caption: "% IMPED. @ 75 DEG",
    dataField: "tfdPercentImpedanceAt75Deg",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 27,
    caption: "Zh on 100 MVA BASE",
    dataField: "tfdZhAt100MVABaseAt75Deg",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 28,
    caption: "Zx on 100 MVA BASE",
    dataField: "tfdZxAt100MVABaseAt75Deg",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 29,
    caption: "Zy on 100 MVA BASE",
    dataField: "tfdZyAt100MVABaseAt75Deg",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 30,
    caption: "RESIS . on 100 MVA",
    dataField: "tfdResistanceAt100MVABaseAt75Deg",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 31,
    caption: "COMMISSION DATE",
    dataField: "tfdCommissionDate",
    dataType: "datetime",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 32,
    caption: "DECOMMISSION DATE",
    dataField: "tfdDecommissionDate",
    dataType: "datetime",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 33,
    caption: "SUPPLY DATE",
    dataField: "tfdSupplyDate",
    dataType: "datetime",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 34,
    caption: "SUPPLIER",
    dataField: "splName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    // using stnName instead of stnID
    id: 35,
    caption: "LOCATION",
    dataField: "stnName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 36,
    caption: "REMARKS",
    dataField: "tfdRmks",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
];
export const stations = [
  {
    id: 0,
    station: "Voldivost",
    bus: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    activity: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 1,
    station: "Station 1",
    bus: "Bus 1",
    date: "2021-09-02",
    time: "13:00",
    voltage: "1.00",
    activity: "1.00",
    remarks: "Remarks 1",
  },
  {
    id: 2,
    station: "Station 2",
    bus: "Bus 2",
    date: "2021-09-03",
    time: "14:00",
    voltage: "2.00",
    activity: "2.00",
    remarks: "Remarks 2",
  },
  {
    id: 3,
    station: "Station 3",
    bus: "Bus 3",
    date: "2021-09-04",
    time: "15:00",
    voltage: "3.00",
    activity: "3.00",
    remarks: "Remarks 3",
  },
  {
    id: 4,
    station: "Station 4",
    bus: "Bus 4",
    date: "2021-09-05",
    time: "16:00",
    voltage: "4.00",
    activity: "4.00",
    remarks: "Remarks 4",
  },
  {
    id: 5,
    station: "Station 5",
    bus: "Bus 5",
    date: "2021-09-06",
    time: "17:00",
    voltage: "5.00",
    activity: "5.00",
    remarks: "Remarks 5",
  },
  {
    id: 6,
    station: "Station 6",
    bus: "Bus 6",
    date: "2021-09-07",
    time: "18:00",
    voltage: "6.00",
    activity: "6.00",
    remarks: "Remarks 6",
  },
  {
    id: 7,
    station: "Station 7",
    bus: "Bus 7",
    date: "2021-09-08",
    time: "19:00",
    voltage: "7.00",
    activity: "7.00",
    remarks: "Remarks 7",
  },
  {
    id: 8,
    station: "Station 8",
    bus: "Bus 8",
    date: "2021-09-09",
    time: "20:00",
    voltage: "8.00",
    activity: "8.00",
    remarks: "Remarks 8",
  },
  {
    id: 9,
    station: "Station 9",
    bus: "Bus 9",
    date: "2021-09-10",
    time: "21:00",
    voltage: "9.00",
    activity: "9.00",
    remarks: "Remarks 9",
  },
  {
    id: 10,
    station: "Station 10",
    bus: "Bus 10",
    date: "2021-09-11",
    time: "22:00",
    voltage: "10.00",
    activity: "10.00",
    remarks: "Remarks 10",
  },
  {
    id: 11,
    station: "Station 11",
    bus: "Bus 11",
    date: "2021-09-12",
    time: "23:00",
    voltage: "11.00",
    activity: "11.00",
    remarks: "Remarks 11",
  },
];

// Station content for the BusStation tab
export const feederColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "FEEDER",
    dataField: "fdrName",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "Date",
    dataField: "frdDate",
    fixed: false,
    width: 100,
    dataType: "datetime",
   
    
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "frdTime",
    fixed: false,
    width: 100,
    dataType: "datetime",
   
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "frdVoltage",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "CURRENT",
    dataField: "frdCurrent",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "ACTIVE",
    dataField: "frdActive",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "ACTIVE POWER",
    dataField: "frdActivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 9,
    caption: "REACTIVE POWER",
    dataField: "frdReactivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "frdRmks",
  },
];

export const feeder = [
  {
    id: 0,
    station: "Voldivost",
    feeder: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 1,
    station: "Station 1",
    feeder: "Bus 1",
    date: "2021-09-02",
    time: "13:00",
    voltage: "1.00",
    current: "1.00",
    active: "1.00",
    activePower: "1.00",
    reactivePower: "1.00",
    remarks: "Remarks 1",
  },
  {
    id: 2,
    station: "Station 2",
    feeder: "Bus 2",
    date: "2021-09-03",
    time: "14:00",
    voltage: "2.00",
    current: "2.00",
    active: "2.00",
    activePower: "2.00",
    reactivePower: "2.00",
    remarks: "Remarks 2",
  },
  {
    id: 3,
    station: "Station 3",
    feeder: "Bus 3",
    date: "2021-09-04",
    time: "15:00",
    voltage: "3.00",
    current: "3.00",
    active: "3.00",
    activePower: "3.00",
    reactivePower: "3.00",
    remarks: "Remarks 3",
  },
  {
    id: 4,
    station: "Station 4",
    feeder: "Bus 4",
    date: "2021-09-05",
    time: "16:00",
    voltage: "4.00",
    current: "4.00",
    active: "4.00",
    activePower: "4.00",
    reactivePower: "4.00",
    remarks: "Remarks 4",
  },
  {
    id: 5,
    station: "Station 5",
    feeder: "Bus 5",
    date: "2021-09-06",
    time: "17:00",
    voltage: "5.00",
    current: "5.00",
    active: "5.00",
    activePower: "5.00",
    reactivePower: "5.00",
    remarks: "Remarks 5",
  },
  {
    id: 6,
    station: "Station 6",
    feeder: "Bus 6",
    date: "2021-09-07",
    time: "18:00",
    voltage: "6.00",
    current: "6.00",
    active: "6.00",
    activePower: "6.00",
    reactivePower: "6.00",
    remarks: "Remarks 6",
  },
];

export const lineColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "NOM.",
    dataField: "ldtNomenclature",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "lrdDate",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "lrdTime",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "lrdVoltage",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "CURRENT",
    dataField: "lrdCurrent",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "lrdActive",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "ACTIVE POW.",
    dataField: "lrdActivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 9,
    caption: "REACTIVE POW.",
    dataField: "lrdReactivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "SOURCE STATION",
    dataField: "sourceStationName",
    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "DEST. STATION",
    dataField: "destStationName",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "REMARKS",
    dataField: "lrdRmks",
  },
];

export const lineData = [
  {
    id: 0,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    sourceStation: "Station 1",
    destStation: "Station 2",
    remarks: "No Remarks",
  },
];

export const transformerColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "TRANSF. NOM.",
    dataField: "tfdTransformerID",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "trdDate",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "trdTime",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "trdVoltage",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "CURRENT",
    dataField: "trdCurrent",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "trdActive",
    dataType: "boolean",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "ACTIVE POW.",
    dataField: "trdActivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 9,
    caption: "REACTIVE POW.",
    dataField: "trdReactivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "TAP CHANGER",
    dataField: "trdTapChanger",
    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "MIN. OIL TEMP",
    dataField: "minOilTemp",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "MAX. OIL TEMP",
    dataField: "trdMaximumOilTemperature",
    fixed: false,
    width: 100,
  },
  {
    id: 13,
    caption: "MIN. WIND. TEMP 1",
    dataField: "trdMinimumOilTemperature",
    fixed: false,
    width: 100,
  },
  {
    id: 13,
    caption: "MAX. WIND. TEMP 1",
    dataField: "trdMaximumWindingTemperature1",
    fixed: false,
    width: 100,
  },
  {
    id: 14,
    caption: "MIN. WIND. TEMP 2",
    dataField: "trdMinimumWindingTemperature2",
    fixed: false,
    width: 100,
  },
  {
    id: 15,
    caption: "MAX. WIND. TEMP 2",
    dataField: "trdMaximumWindingTemperature2",
    fixed: false,
    width: 100,
  },
  {
    id: 16,
    caption: "REMARKS",
    dataField: "trdRmks",
  },
];

export const transformerData = [
  {
    id: 0,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 1,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 2,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 3,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 4,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
];
