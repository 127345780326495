import useToken from "antd/es/theme/useToken";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../../features/generalSlice";
import { useCrudFunc } from "../../../../../../../../functions/crud";
import { UpdateStates } from "../../../../../../../../functions/updateStates";
import { useAccountInfo } from "../../../../../../../../hooks/useAccountInfo";
import useFetch from "../../../../../../../../hooks/useFetch";
import { CheckboxTemlate } from "../../../../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../../../../templates/input";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { TextareaTemplate } from "../../../../../../../../templates/textarea";
import { SaveButton, UpdateButton, NewButton, Cancel } from "../../../../../../../accessories/buttons";
import { HrSetupsCustomToolbar } from "../../../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { Form } from "antd";
import Datagrid_template from "../../../../../../../../templates/Datagrid";
import { HSeperator } from "../../../../../../../Safety/elogger/widgets/utils";


interface states {
  name: string;
  shortName: string;
  remarks: string;
  active: boolean;
  order: number;
  selectedRecords: any;
}

export const TroubleCategories = () => {
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const clearB = useSelector((state: RootState) => state.general.clearB);
  const [refresh, setRefresh] = useState(false);
  const editedDate = dayjs().endOf("month").format("YYYY-MM-DD");
  const startingDate = dayjs()
    .subtract(1, "years")
    .startOf("month")
    .format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState<{ temp: string; index: string }>({
    temp: startingDate,
    index: startingDate,
  });
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: editedDate,
    index: editedDate,
  });
  const [searchText, setSearchText] = useState<{ temp: string; index: string }>(
    { temp: "", index: "" }
  );
  const [activeStatus, setActiveStatus] = useState<{
    temp: string;
    index: string;
  }>({ temp: "", index: "" });
  const [states, setStates] = useState<states>({
    name: "",
    shortName: "",
    remarks: "",
    active: true,
    order: 0,
    selectedRecords: [],
  });
  console.log("Parentstates", states);
  const { selectedRecords } = states;
  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const dispatch = useDispatch();
  const populateFields = (clearFields?: boolean) => {
    const gridState = [
      {
        key: "name",
        value: clearFields ? "" : selectedRecords?.bgiName,
      },
      {
        key: "shortName",
        value: clearFields ? "" : selectedRecords?.bgiShtName ?? "",
      },
      {
        key: "remarks",
        value: clearFields ? "" : selectedRecords?.bgiRmks ?? "",
      },
      {
        key: "order",
        value: clearFields ? 0 : selectedRecords?.bgiOrder ?? "",
      },
      {
        key: "active",
        value: clearFields ? true : selectedRecords?.bgiActive ?? true,
      },
    ];

    for (let instance of gridState) updateStates(instance.key, instance.value);
  };
  console.log(states);

  useEffect(() => {
    populateFields(false);
  }, [selectedRecords]);

  useEffect(() => {
    if (clearB) {
      setStates({
        name: "",
        shortName: "",
        remarks: "",
        active: true,
        order: 0,
        selectedRecords: [],
      });
    }
  }, [clearB]);
  const [checkRec] = useFetch(
    `BudgetItem/GetBudgetNameShtName`,
    refresh,
    undefined,
    undefined,
    "innox"
  );
//   const [matchingItems, setMatchingItems] = useState([]);
//   console.log("matchingItems",matchingItems)
//   useEffect(() => {
//     if (checkRec && Array.isArray(checkRec)) {
//       const matches = checkRec.filter(item => item.name === states.name);
//       setMatchingItems(matches);
//     }
//   }, [checkRec, states.name]);

  
  const [getName, setGetName] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userID, empID, userInfo] = useAccountInfo();
  const [modalData, setModalData] = useState<any>({
    title: "",
    message: "",
    okText: "",
  });
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [toggle, setToggle] = useState<boolean>(true);
  const token = useToken();
  const [Posting, Updating] = useCrudFunc("innox");
  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 15,
  });
  const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass);
  const searchTerm =
    searchText.index.length > 0 ? `SearchText=${searchText.index}` : "";
  const checkState = `isActive=${activeStatus.index}`;
  const queryParams = [searchTerm, checkState].filter(Boolean).join("&");

  const [mainGrid] = useFetch(
    `BudgetItem/GetAllBudgetItems?${queryParams}`,
    refresh,
    undefined,
    undefined,
    "innox"
  );
  const saveFunction = () => {
    if (states.name === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for Name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (states.shortName === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for short name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (states.order === 0) {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for order",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (isNaN(Number(states.order.toString()))) {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for order with a number",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else {
      setModalData({
        title: "Save record?",
        message: "Are you sure you want to save this record?",
        okText: "Save",
      });
      setIcon("question");
      setOpenModal(true);
    }
  };

  //Update FUNCTION
  const updateFunction = () => {
    if (states.name === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (states.shortName === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for short name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (isNaN(Number(states.order.toString()))) {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for order with a number",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else {
      setModalData({
        title: "Update record?",
        message: "Are you sure you want to update this record?",
        okText: "Update",
      });
      setIcon("question");
      setOpenModal(true);
    }
  };
  const postData = async () => {
    try {
      await Posting(`BudgetItem/CreateBudgetItems/create`, {
        bgiName: states.name,
        bgiShtName: states.shortName,
        bgiOrder: states.order,
        bgiActive: states.active,
        bgiRmks: states.remarks,
        bgiCreatedBy: userInfo?.empName,
        formCode:"asmTmsBudgetItems",
        formAction: `Created Budget Item with name ${states.name} successfully`,
      });
      setModalData({
        title: "Success",
        message: "Record saved successfully!",
      });
      setOpenModal(true);
      setIcon("success");
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (e: any) {
      console.log("ErrorMess", e);
      setModalData({
        title: "Error",
        message: e?.response?.data?.message,
      });
      setIcon("warning");
      setOpenModal(true);
    }
  };
  const updateData = async () => {
    try {
      await Updating(
        `BudgetItem/UpdateBudgetItems/update/${selectedRecords?.bgiIdpk}`,
        {
          bgiName: states.name,
          bgiShtName: states.shortName,
          bgiOrder: states.order,
          bgiActive: states.active,
          bgiRmks: states.remarks,
          bgiEditedBy: userID,
          formCode:"asmTmsBudgetItems",
          formAction: `Updated Budget Item with ID ${selectedRecords?.bgiIdpk} to name ${states.name} successfully`,
        }
      );
      setModalData({
        title: "Success",
        message: "Record updated successfully!",
      });
      setOpenModal(true);
      setIcon("success");
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (e: any) {
      console.log("ErrorMess", e);
      setModalData({
        title: "Error",
        message: e?.response?.data?.message,
      });
      setIcon("warning");
      setOpenModal(true);
    }
  };
   

  return (
    <div className="w-full h-full  ">
      <HrSetupsCustomToolbar
        toggler={setToggle}
        Switch={toggle}
        searchTextOnchange={(e) => {
          setSearchText({ temp: e, index: searchText.index });
        }}
        checkOnChange={(e) => {
          setActiveStatus({ temp: e, index: activeStatus.index });
        }}
        handleRefresh={() => {
          setSearchText({ temp: "", index: "" });
          setActiveStatus({ temp: "", index: "" });
        }}
        handleFind={() => {
          setSearchText({ temp: searchText.temp, index: searchText.temp });
          setActiveStatus({
            temp: activeStatus.temp,
            index: activeStatus.temp,
          });
        }}
        setVisible={setToggle}
        outerSelected={states.selectedRecords}
        selected_Record={states.selectedRecords}
        withDates={false}
      />
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save record?"
            ? postData()
            : modalData.title === "Update record?"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "question" ? false : true}
      />

      {/* <Switcher alternate={""} > */}
      <div>
        <p className="pt-1 px-2 border-[1px] rounded-t-md border-t-1 font-medium w-full bg-slate-100"
         style={{borderColor: borderTheme}}
        >
          {"Categories Details"}
        </p>
      </div>
      {toggle && (
        <div className="flex border-[1px] border-t-0 pt-1 px-2"
        style={{borderColor: borderTheme}}
        >
          <aside className=" w-[450px] ">
            <Form
              className=" dark:bg-slate-900 dark:text-darkModeSkin-base rounded py-2  "
              name="basic"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 10 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              {/* {formDetails.map(({ id, label, defaultValue, option , idexpr , dataexpr }) => ( */}
              <div className=" w-[630px]">
                <InputsTemplate
                  useCallbackFunc
                  defaultValue={states.name}
                  label={"Name"}
                  inputStyle={{ width: 272 }}
                  disabledStatus={disabled}
                  orderOnchange={(e: any) => {
                    updateStates("name", e);
                    updateStates("shortName", e);
                  }}
                  handleRefresh={() => {
                    updateStates("name", "");
                    updateStates("shortName", "")
                  }}
                />
              </div>
              <div className=" w-[630px]">
                <InputsTemplate
                  useCallbackFunc
                  span
                  defaultValue={states.shortName}
                  label={"Short Name"}
                  inputStyle={{ width: 300 }}
                  disabledStatus={disabled}
                  orderOnchange={(e: string) => {
                    updateStates("shortName", e);
                  }}
                />
              </div>
              <div className=" w-[630px]">
                <InputsTemplate
                  useCallbackFunc
                  span
                  defaultValue={states.order}
                  label={"Order"}
                  inputStyle={{ width: 300 }}
                  disabledStatus={disabled}
                  orderOnchange={(e: string) => {
                    updateStates("order", e);
                  }}
                />
              </div>
              {/* ))} */}

              <CheckboxTemlate
                defaultValue={states.active}
                label={"Active?"}
                withBorders={true}
                labelCol={7}
                customDisabled={disabled}
                setCheckboxOnchange={(e: any) => {
                  updateStates("active", e);
                }}
              />

              <div className="flex gap-2  w-[400px] justify-end">
                {!updateMode ? (
                  <div>
                    <SaveButton
                      disableButton={disabled}
                      handleSave={() => {
                        saveFunction();
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <UpdateButton
                      disableButton={disabled}
                      handleUpdate={updateFunction}
                    />
                  </div>
                )}
                {disabled ? (
                  <div>
                    <NewButton
                      new_button_toggler={() => {
                        dispatch(
                          setGeneralValue({
                            expr: "formDisabled",
                            value: false,
                          })
                        );
                        dispatch(
                          setGeneralValue({ expr: "updateMode", value: false })
                        );
                        dispatch(
                          setGeneralValue({ expr: "clearB", value: true })
                        );
                        populateFields(true)
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <Cancel
                      cancel_button_toggler={() => {
                        dispatch(
                          setGeneralValue({ expr: "clearB", value: false })
                        );
                        dispatch(
                          setGeneralValue({ expr: "formDisabled", value: true })
                        );
                        dispatch(
                          setGeneralValue({ expr: "updateMode", value: false })
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </Form>
          </aside>
          <aside className="w-full">
            <Form.Item
              className="pt-2 pl-[40px]"
              label={<p className="text-[12px]">Remarks</p>}
            >
              <div className="w-full pr-2">
                <TextareaTemplate
                  height={125}
                  useCallbackFunc
                  setCallbackValue={(e: any) => {
                    updateStates("remarks", e);
                  }}
                  defaultValue={states.remarks}
                />
              </div>
            </Form.Item>
          </aside>
        </div>
      )}
         <HSeperator
              onClick={() => {
                setToggle(!toggle );
              }}
              status={!toggle}
            />
      {/* </Switcher> */}
      <section className="flex-grow">
        <section className="w-full h-full">
          <Datagrid_template
            gridheight={toggle ? 615 : 780}
            pageSize={paging.pageSize}
            pageNumber={paging.pageNumber}
            handlePageSize={(e: number) => {
              UpdateStates("pageSize", e, setPaging);
            }}
            handlePageNumber={(e: number) => {
              UpdateStates("pageNumber", e, setPaging);
            }}
            disableGroupPanel
            showColumnHeaders={true}
            columns={[]}
            data={mainGrid}
            onRowClick={(e) => {
              updateStates("selectedRecords", e);
              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              dispatch(setGeneralValue({ expr: "clearB", value: false }));
            }}
            rowDoubleClicked={(e) => {
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              dispatch(setGeneralValue({ expr: "updateMode", value: true }));
              setToggle(true);
            }}
          />
        </section>
      </section>
    </div>
  );
};