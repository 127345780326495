import { datagridColumnTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";

export const OutageMainColumnsPriv: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "ID",
    dataField: "pvaIDpk",
    dataType: "number",
    width: 60,
    fixed: true,
    allowEditing: false,
    visible: false,
  },
  {
    id: 1,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    fixed: true,
    width: 120,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "NAME",
    dataField: "empName",
    dataType: "string",
    width: 150,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 3,
    caption: "SUBSTATION",
    dataField: "stnName",
    dataType: "string",
    width: 150,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 4,
    caption: "SHIFT LDR?",
    dataField: "pvaShiftLeader",
    dataType: "boolean",
    width: 160,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 5,
    caption: "LOG?",
    dataField: "pvaLog",
    dataType: "boolean",
    width: 80,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 6,
    caption: "VIEW?",
    dataField: "pvaView",
    dataType: "boolean",
    fixed: true,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 7,
    caption: "HOME?",
    dataField: "pvaHome",
    dataType: "boolean",
    width: 80,
    fixed: true,
    allowEditing: true,
    visible: true,
  },
  {
    id: 8,
    caption: "DEF.HOME?",
    dataField: "pvaDefaultHome",
    dataType: "boolean",
    fixed: true,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE?",
    dataField: "pvaActive",
    dataType: "boolean",
    width: 80,
    fixed: true,
    allowEditing: true,
    visible: true,
  },
  {
    id: 10,
    caption: "CSR?",
    dataField: "pvaCaptureStationReadings",
    dataType: "boolean",
    width: 80,
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 11,
    caption: "TOS?",
    dataField: "pvaTakeOverShift",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 12,
    caption: "IRSC?",
    dataField: "pvaInitiateRemoteShiftChange",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 13,
    caption: "FLAG?",
    dataField: "pvaFlag",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 14,
    caption: "SHIFT MEM.?",
    dataField: "pvaShiftMember",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 15,
    caption: "MS?",
    dataField: "stnMasterStation",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 16,
    caption: "NCC?",
    dataField: "stnNationalControlCenter",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 17,
    caption: "OP'NAL?",
    dataField: "stnOperational",
    dataType: "boolean",
    fixed: false,
    width: 80,
    allowEditing: true,
    visible: true,
  },
  {
    id: 18,
    caption: "SUP?",
    dataField: "pvaSupervisor",
    dataType: "string",
    width: 80,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 19,
    caption: "MGR?",
    dataField: "pvaManager",
    dataType: "boolean",
    width: 80,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 20,
    caption: "DIR?",
    dataField: "pvaDirector",
    dataType: "boolean",
    width: 80,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 21,
    caption: "START DATE",
    dataField: "pvaStartDate",
    dataType: "dateTime",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 22,
    caption: "END DATE",
    dataField: "pvaEndDate",
    dataType: "dateTime",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 23,
    caption: "DATE ASSIGNED",
    dataField: "pvaCreationDate",
    dataType: "dateTime",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 24,
    caption: "DATE UNASSIGNED",
    dataField: "pvaEditedDate",
    dataType: "dateTime",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 25,
    caption: "OPERATOR LOC",
    dataField: "locShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 26,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 27,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 28,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 29,
    caption: "REMARKS",
    dataField: "pvaRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
];


export const staffsColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "",
    datatype: "number",
    width: 60,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "Add?",
    dataField: "",
    datatype: "boolean",
    width: 60,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Staff?",
    dataField: "empIsStaff",
    datatype: "string",
    width: 70,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "Staff No",
    dataField: "empStaffNo",
    datatype: "string",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "Staff Name",
    dataField: "empName2",
    datatype: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "Job Title",
    dataField: "jbtShtName",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Dept",
    dataField: "dptShtName",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Section",
    dataField: "sxnShtName",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "Location",
    dataField: "locShtName",
    datatype: "string",
    width: 2000,
    fixed: false,
    visible: true,
  },
];

export const workshopsColumns = [
  {
    id: 0,
    caption: "No",
    dataField: "",
    datatype: "number",
    width: 40,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Substation",
    dataField: "",
    datatype: "string",
    width: 140,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Substation Operator?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Substation Supervisor?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Maint. Team Member?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },

  {
    id: 5,
    caption: "Maintenance Supervisor?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Request Application?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Submit Application?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "Receive Application?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "Acknowledge Application?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "Approve Application?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },

  {
    id: 11,
    caption: "Scc Operator?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "Scc Approver?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "Scc Canceller?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "Prepare Prot. Guarantee?",
    dataField: "",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "Submit Prot. Guarantee?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "Receive Prot. Guarantee?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "Acknow. Prot. Guarantee?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "Approve Prot. Guarantee?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "Surrender Guarantee?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "Prepare OTO Isol.?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "Submit OTO Isol.?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "Approve OTO Isol.?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "Prepare OTO Prep.?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "Submit OTO Prep.?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "Approve OTO Prep.?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 26,
    caption: "Prepare OUWTP?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 27,
    caption: "Submit OUWTP?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 28,
    caption: "Approve OUWTP?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 29,
    caption: "Prepare JHA?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 30,
    caption: "Submit JHA?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 31,
    caption: "Approve JHA?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 32,
    caption: "Prepare Trouble Rep.",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 33,
    caption: "Submit Trouble Rep.",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 34,
    caption: "Approve Trouble Rep.",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 35,
    caption: "Authorize Trouble Rep.",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 36,
    caption: "Manager?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 37,
    caption: "Director?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 38,
    caption: "CEO?",
    dataField: "",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 39,
    caption: "Start Date",
    dataField: "",
    datatype: "dateTime",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 40,
    caption: "End Date",
    dataField: "",
    datatype: "dateTime",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 41,
    caption: "Remarks",
    dataField: "",
    datatype: "string",
    width: 2000,
    fixed: false,
    visible: true,
  },
];
export const outageMngCols = [
  {
    id: 0,
    caption: "No",
    dataField: "pvaIDpk",
    datatype: "number",
    width: 40,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Substation",
    dataField: "stnName",
    datatype: "string",
    width: 140,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Substation Operator?",
    dataField: "pvaSystemControlOperator",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Substation Supervisor?",
    dataField: "pvaSubstationSupervisor",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Maint. Team Member?",
    dataField: "pvaMaintenanceSupervisor",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "Maintenance Supervisor?",
    dataField: "pvaMaintenanceSupervisor",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Request Application?",
    dataField: "pvaRequestProtectionGuaranteeApplications",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Submit Application?",
    dataField: "pvaSubmitProtectionGuaranteeApplications",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "Receive Application?",
    dataField: "pvaReceiveProtectionGuaranteeApplications",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "Acknowledge Application?",
    dataField: "pvaAcknowledgeProtectionGuaranteeApplications",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "Approve Application?",
    dataField: "pvaApproveProtectionGuaranteeApplications",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "Scc Operator?",
    dataField: "pvaSystemControlOperator",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "Scc Approver?",
    dataField: "pvaSystemControlApprover",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "Scc Canceller?",
    dataField: "pvaSystemControlCanceller",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "Prepare Prot. Guarantee?",
    dataField: "pvaRequestProtectionGuarantee",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "Submit Prot. Guarantee?",
    dataField: "pvaSubmitProtectionGuarantee",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "Receive Prot. Guarantee?",
    dataField: "pvaReceiveProtectionGuarantee",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "Acknow. Prot. Guarantee?",
    dataField: "pvaAcknowledgeProtectionGuarantee",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "Approve Prot. Guarantee?",
    dataField: "pvaApproveProtectionGuarantee",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "Surrender Guarantee?",
    dataField: "pvaSurrenderProtectionGuarantee",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "Prepare OTO Isol.?",
    dataField: "pvaPrepareOrderToOperateIsolation",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "Submit OTO Isol.?",
    dataField: "pvaSubmitOrderToOperateIsolation",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "Approve OTO Isol.?",
    dataField: "pvaApproveOrderToOperateIsolation",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "Prepare OTO Prep.?",
    dataField: "pvaPrepareOrderToOperatePreparation",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "Submit OTO Prep.?",
    dataField: "pvaSubmitOrderToOperatePreparation",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "Approve OTO Prep.?",
    dataField: "pvaApproveOrderToOperatePreparation",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 26,
    caption: "Prepare OUWTP?",
    dataField: "pvaPrepareOuwtp",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 27,
    caption: "Submit OUWTP?",
    dataField: "pvaSubmitOuwtp",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 28,
    caption: "Approve OUWTP?",
    dataField: "pvaApproveOuwtp",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 29,
    caption: "Prepare JHA?",
    dataField: "pvaPrepareJobHazardAnalysis",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 30,
    caption: "Submit JHA?",
    dataField: "pvaSubmitJobHazardAnalysis",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 31,
    caption: "Approve JHA?",
    dataField: "pvaApproveJobHazardAnalysis",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 32,
    caption: "Prepare Trouble Rep.",
    dataField: "pvaPrepareTroubleReport",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 33,
    caption: "Submit Trouble Rep.",
    dataField: "pvaSubmitTroubleReport",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 34,
    caption: "Approve Trouble Rep.",
    dataField: "pvaApproveTroubleReport",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 35,
    caption: "Authorize Trouble Rep.",
    dataField: "pvaAuthoriseTroubleReport",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 36,
    caption: "Manager?",
    dataField: "pvaManager",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 37,
    caption: "Director?",
    dataField: "pvaDirector",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 38,
    caption: "CEO?",
    dataField: "pvaCeo",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
];
