/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import { SelectsTemplate } from "../../../../templates/select";
import { CustomizableButton, SaveButton, } from "../../../accessories/buttons";
import { TextareaTemplate } from "../../../../templates/textarea";
import close from "../../../../assets/close.png";
import reset from "../../../../assets/reset.png";
import { useModalData } from "../../../accessories/component_infos";
import { ModalTemplate } from "../../../../templates/modal";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { AxiosConfigType, useAxiosService, } from "../../../../general/requestConfig";
import { parseLower } from "./utils";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import { DropdownGrid_template } from "../../../../templates/DropdownGrid";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { AxiosResponse } from "axios";

interface props {
  open: boolean;
  handleCancel: () => void;
}
type Station = {
  stnIDpk: number;
  stnName: string;
  stnShtName: string;
  stnCode: string;
  stnContact: string;
  stnTelNo: string;
  stnPlc: string;
  disabled: boolean;
};
type Employee = {
  empIDpk: number;
  empStaffNo: string;
  empName1: string;
  dptShtName: string;
  sxnShtName: string;
  jbtShtName: string;
  locShtName: string;
};
type ShiftMembers = {
  message: string;
  data: {
    empName1: string;
  }[];
  code: number;
};
type Type_CreateOrAccept = {
  rscInitiatorEmpIDfk: number;
  rscRemoteOperatorEmpIDfk: number;
  rscStationIDfk: number;
  rscRequestTime: string;
  rscRmks: string;
  operators: string;
};

const homeStationColumns = [
  {
    caption: "Station Name",
    dataField: "stnName",
    key: "stnName",
    fixed: true, width: 120
  },
  {
    caption: "Station Short Name",
    dataField: "stnShtName",
    key: "stnShtName",
    fixed: true, width: 120
  },
  {
    caption: "Station Code",
    dataField: "stnCode",
    key: "stnCode",
    fixed: true, width: 120
  },
  {
    caption: "Station Contact",
    dataField: "stnContact",
    key: "stnContact",
    fixed: true, width: 120
  },
  {
    caption: "Station Telephone",
    dataField: "stnTelNo",
    key: "stnTelNo",
    fixed: true, width: 120
  },
  {
    caption: "Station Place",
    dataIndex: "stnPlc",
    key: "stnPlc",
    fixed: true, width: 120
  },
];

const remoteOperatorsColumns = [
  {
    caption: "Staff No",
    dataField: "empStaffNo",
    key: "empStaffNo",
    fixed: true, width: 120
  },
  {
    caption: "Name",
    dataField: "empName1",
    key: "empName1",
    fixed: true, width: 120
  },
  {
    caption: "Dept",
    dataField: "dptShtName",
    key: "dptShtName",
    fixed: true, width: 120
  },
  {
    caption: "Section",
    dataField: "sxnShtName",
    key: "sxnShtName",
    fixed: true, width: 120
  },
  {
    caption: "Job Title",
    dataField: "jbtShtName",
    key: "jbtShtName",
    fixed: true, width: 120
  },
  {
    caption: "Location",
    dataIndex: "locShtName",
    key: "locShtName",
    fixed: true, width: 120
  },
];

const RemoteOpServices = {
  GetReportingStation: () => {
    return {
      method: "GET",
      url: "RemoteShiftChange/GetReportingStations/get_reporting_stations",
    } as AxiosConfigType;
  },
  GetRemoteOperators: (action: string, stnIDpk: number) => {
    return {
      method: "GET",
      url: "RemoteShiftChange/GetRemoteOperators",
      params: { action, stnIDpk },
    } as AxiosConfigType;
  },
  GetInitiatingOperators: (
    action: string,
    stnIDpk: number,
    remoteOperatorIDfk: number
  ) => {
    return {
      method: "GET",
      url: "RemoteShiftChange/GetInitiatingOperators",
      params: { action, stnIDpk, remoteOperatorIDfk },
    } as AxiosConfigType;
  },
  GetShiftChangeSummary: (
    initiatingOperatorID: number,
    remoteOperatorID: number
  ) => {
    return {
      method: "GET",
      url: "RemoteShiftChange/GetShiftChangeSummary/get_shift_change_summary",
      params: { initiatingOperatorID, remoteOperatorID },
    } as AxiosConfigType;
  },
  ShiftMembers: (
    initiatingOperatorID: number,
    remoteOperatorID: number,
    stnID: number
  ) => {
    return {
      method: "GET",
      url: "RemoteShiftChange/GetShiftMembers/shift_members",
      params: { initiatingOperatorID, remoteOperatorID, stnID },
    } as AxiosConfigType;
  },
  CheckIfShitExist: (remoteOperatorID: number, stnID: number) => {
    return {
      method: "GET",
      url: "RemoteShiftChange/CheckifSessionExists/initiated-session",
      params: { remoteOperatorID, stnID },
    } as AxiosConfigType;
  },
  SaveRemoteShiftChange: (data: any, action: "accept" | "request") => {
    return {
      method: "POST",
      url: "RemoteShiftChange/SaveRemoteShiftChange",
      data,
      params: { action },
    } as AxiosConfigType;
  },
  UpdateOrAcceptShiftChange: (
    data: Type_CreateOrAccept,
    action: "accept" | "request"
  ) => {
    return {
      method: "PUT",
      url: "RemoteShiftChange/updateOrAcceptRemoteShiftChange",
      data,
      params: { action },
    } as AxiosConfigType;
  },
  CancelInitiatedRemoteShiftChange: (stnIDpk: number): AxiosConfigType => {
    return {
      method: `PUT`,
      url: `RemoteShiftChange/CancelInitiatedRemoteShiftChange`,
      params: {
        stnIDpk,
      },
    };
  },
  CheckForRequestInitiatedByUser: (stnIDpk: number): AxiosConfigType => {
    return {
      method: `GET`,
      url: `RemoteShiftChange/CheckForRequestInitiatedByUser`,
      params: {
        stnIDpk,
      },
    };
  },
  CheckForRequestInitiatedForUser: (stnIDpk: number): AxiosConfigType => {
    return {
      method: `GET`,
      url: `RemoteShiftChange/CheckForRequestInitiatedForUser`,
      params: {
        stnIDpk,
      },
    };
  },
};

const Remote_Shift_Change = ({ open, handleCancel }: props) => {
  const query = useAxiosService("powerapp");
  const [disabled, setDisabled] = useState(false);
  const [modalData, setModalData] = useModalData();
  const empId = useAccountInfo()[0];
  const [formValues, setFormValues] = useState<{
    action: { id: "request" | "accept"; value: string } | undefined;
    homeStation: Station;
    initiatingOp: Employee;
    remoteOp: Employee;
    summary?: string;
    incomingShiftMember?: string[];
  }>();
  const [showIncomingShiftMembers, setShowIncomingShiftMembers] = useState(false);

  const [homeStation, setHomeStation] = useState<Station[] | undefined>();
  const [remoteOperators, setRemoteOperators] = useState<Employee[] | undefined>();
  const [initiatingOperator, setInitiatingOperator] = useState<Employee[] | undefined>();
  const [ShiftMembers, setShiftMembers] = useState<ShiftMembers["data"] | undefined>();
  const recordIncomingShiftMembers = useRef<string[]>([]);

  const searchParamsOnChange = useSelector((state: RootState) => state?.elogBook);

  //handle action change
  useEffect(() => {
    if (formValues?.action?.id === "request") {
      PopLueInitiatingOperators();
      PopLueRemoteOperators();
      setShowIncomingShiftMembers(false);
      setFormValues((state) => ({ ...state, summary: "" }));
    } else if (formValues?.action?.id === "accept") {
      PopLueInitiatingOperators();
      PopLueRemoteOperators();
      PopClbOperators();
      PopLueShiftChangeSummary();
      setShowIncomingShiftMembers(true);
    }
  }, [formValues?.action?.id]);

  const PopLueInitiatingOperators = async () => {
    await query(RemoteOpServices.GetInitiatingOperators(formValues?.action?.id, formValues?.homeStation?.stnIDpk, formValues?.remoteOp?.empIDpk))
      .then((res) => {
        const currentLoginUser = res?.data.find((emp: Employee) => emp.empIDpk === empId);
        setInitiatingOperator(res?.data);
        setFormValues((state) => ({ ...state, initiatingOp: currentLoginUser }));
      })
      .catch((err) => console.log(err));
  }

  const PopLueRemoteOperators = async () => {
    await query(RemoteOpServices.GetRemoteOperators(formValues?.action?.id, formValues?.homeStation?.stnIDpk))
      .then((res) => {
        setRemoteOperators(res?.data);
        if (formValues?.action?.id === "accept") {
          const currentLoginUser = res?.data.find((emp: Employee) => emp.empIDpk === empId);
          setFormValues((state) => ({ ...state, remoteOp: currentLoginUser }));
        } else if (formValues?.action?.id === "request") {
          setFormValues((state) => ({ ...state, remoteOp: undefined }));
        }
      })
      .catch((err) => console.log(err));
  }

  const PopClbOperators = async () => {
    await query(RemoteOpServices.ShiftMembers(formValues?.initiatingOp?.empIDpk, formValues?.remoteOp?.empIDpk, formValues?.homeStation?.stnIDpk))
      .then((res) => setShiftMembers(res?.data))
      .catch((err) => console.log(err));
  }

  const PopLueShiftChangeSummary = async () => {
    await query(RemoteOpServices.GetShiftChangeSummary(formValues?.initiatingOp?.empIDpk, formValues?.remoteOp?.empIDpk))
      .then((res) => {
        setFormValues((state) => ({ ...state, summary: res?.data?.[0]?.rscRmks, }));
        // setSummary(res?.data);
      })
      .catch((err) => console.log(err));
  }

  const PopLueReportingStations = async () => {
    query(RemoteOpServices.GetReportingStation()).then((res) => {
      setHomeStation(res?.data);
      const stationName = res?.data.find((station: Station) => station.stnIDpk === searchParamsOnChange?.homeStationID);
      setFormValues((prev) => ({
        ...prev,
        homeStation: { ...prev?.homeStation, stnIDpk: searchParamsOnChange?.homeStationID, stnName: stationName?.stnName ?? "", disabled: true },
      }));
    }).catch((err) => console.log(err));
  }

  //initialize the form
  useEffect(() => {
    PopLueReportingStations();
  }, [searchParamsOnChange?.homeStationID]);

  const fieldHasErrors = () => {
    if (!formValues?.action?.id) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: `Action is required`,
        cancelText: "No, cancel",
        okText: "Okay",
        message: "Please select an action and try again",
        disableCancel: true,
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
      }));
      return false;
    }
    if (!formValues?.homeStation?.stnIDpk) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: `Station is required`,
        cancelText: "No, cancel",
        okText: "Okay",
        message: "Please select the station and try again",
        disableCancel: true,
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
      }));
      return false;
    }
    if (!formValues?.remoteOp?.empIDpk) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: `Remote Operator is required`,
        cancelText: "No, cancel",
        okText: "Okay",
        message: "Please select the remote operator and try again",
        disableCancel: true,
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
      }));
      return false;
    }

    if (!formValues?.initiatingOp?.empIDpk) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: `Initiating Operator is required`,
        cancelText: "No, cancel",
        okText: "Okay",
        message: "Please select the initiating operator and try again",
        disableCancel: true,
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
      }));
      return false;
    }
    return true;
  };
  const findInitiatedSession = async () => {
    const res = await query(
      RemoteOpServices.CheckIfShitExist(
        formValues?.remoteOp?.empIDpk,
        formValues?.homeStation?.stnIDpk
      )
    );
    return !!res?.data;
  };
  const isAnyRequestInitiatedByUser = async () => {
    const res = await query(
      RemoteOpServices.CheckForRequestInitiatedByUser(
        formValues?.homeStation?.stnIDpk
      )
    );
    return res as { message: string; data: boolean; code: number };
  };
  const isAnyRequestInitiatedForUser = async () => {
    const res = await query(
      RemoteOpServices.CheckForRequestInitiatedForUser(
        formValues?.homeStation?.stnIDpk
      )
    );
    return res as { message: string; data: boolean; code: number };
  };

  const updateOrAcceptRemoteShiftChange = async (
    mode: "accept" | "request"
  ) => {
    const hasSession = await findInitiatedSession();
    if (hasSession || mode === "accept") {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "question",
        cancelText: "No, cancel",
        okText: mode === "request" ? "Yes, update" : "Yes, accept",
        title:
          mode === "request"
            ? `Update RSC Time?`
            : `Accept Remote Shift Change Request?`,
        message:
          mode === "request"
            ? "There is a remote shift change session for the operator and station combination already. Do you want to update the request time?"
            : `Are you sure you want to accept the remote shift change from the ${formValues.initiatingOp.empName1} ?`,
        okHandler: async () => {
          try {
            const res: AxiosResponse<any> = await query(
              RemoteOpServices.SaveRemoteShiftChange(
                {
                  rscInitiatorEmpIDfk: formValues?.initiatingOp?.empIDpk,
                  rscRemoteOperatorEmpIDfk: formValues?.remoteOp?.empIDpk,
                  rscStationIDfk: formValues?.homeStation?.stnIDpk,
                  rscRequestTime: new Date().toISOString(),
                  rscRmks: formValues?.summary ?? "",
                  operators: recordIncomingShiftMembers.current.join(", "),
                },
                formValues?.action?.id as "accept" | "request"
              )
            );
            if (res) {
              setModalData((current) => ({
                ...current,
                state: true,
                icon: "success",
                title: mode === "request" ? `Initiated` : `Accepted`,
                cancelText: "No Cancel",
                okText: "Okay",
                disableCancel: true,
                message:
                  mode === "request"
                    ? "Request details updated successfully"
                    : "Request accepted successfully",
                okHandler: () => {
                  setModalData((current) => ({
                    ...current,
                    state: false,
                  }));
                },
                cancelHandler() {
                  setModalData((current) => ({
                    ...current,
                    state: false,
                  }));
                },
              }));
              setFormValues((prev) => ({
                action: { id: undefined, value: undefined },
                homeStation: undefined,
                initiatingOp: undefined,
                remoteOp: undefined,
                summary: "",
              }));
              // reset the incoming shift members
              recordIncomingShiftMembers.current = [];
              setShiftMembers([]);
            }
          } catch (err) {
            setModalData((current) => ({
              ...current,
              state: true,
              icon: "warning",
              title: `Operation failed`,
              cancelText: "No Cancel",
              okText: "Okay",
              message: "Operation failed, please try again",
              disableCancel: true,
              okHandler: () => {
                setModalData((current) => ({
                  ...current,
                  state: false,
                }));
              },
              cancelHandler() {
                setModalData((current) => ({
                  ...current,
                  state: false,
                }));
              },
            }));
          }
        },
        cancelHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        disableCancel: false, // This should be part of the object
      }));
    } else {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "question",
        title: `Initiate Remote Shift Change?`,
        cancelText: "No",
        okText: "Yes",
        message: "Are you sure you want to initiate the remote shift change?",
        okHandler: async () => {
          try {
            const res = await query(
              RemoteOpServices.SaveRemoteShiftChange(
                {
                  rscInitiatorEmpIDfk: formValues?.initiatingOp?.empIDpk,
                  rscRemoteOperatorEmpIDfk: formValues?.remoteOp?.empIDpk,
                  rscStationIDfk: formValues?.homeStation?.stnIDpk,
                  rscRequestTime: new Date().toISOString(),
                  rscRmks: formValues?.summary ?? "",
                  operators: recordIncomingShiftMembers.current.join(", "),
                },
                formValues?.action?.id as "accept" | "request"
              )
            );
            if (res) {
              setModalData((current) => ({
                ...current,
                state: true,
                icon: "success",
                title: `Remote Shift Initiated`,
                cancelText: "No, Cancel",
                okText: "Okay",
                disableCancel: true,
                okHandler: () => {
                  setModalData((current) => ({
                    ...current,
                    state: false,
                  }));
                },
                message:
                  "Remote shift change initiation completed successfully",
                cancelHandler() {
                  setModalData((current) => ({
                    ...current,
                    state: false,
                  }));
                },
              }));
              // reset the form back to default
              setFormValues((prev) => ({
                action: { id: undefined, value: undefined },
                homeStation: undefined,
                initiatingOp: undefined,
                remoteOp: undefined,
                summary: "",
              }));
              // reset the incoming shift members
              recordIncomingShiftMembers.current = [];
              setShiftMembers([]);
              // setSummary(undefined); 
            }
          } catch (err) {
            setModalData((current) => ({
              ...current,
              state: true,
              icon: "warning",
              title: `Operation failed`,
              cancelText: "No Cancel",
              okText: "Ok",
              message: "Operation failed, please try again",
              disableCancel: true,
              okHandler: () => {
                setModalData((current) => ({
                  ...current,
                  state: false,
                }));
              },
              cancelHandler() {
                setModalData((current) => ({
                  ...current,
                  state: false,
                }));
              },
            }));
          }
        },
        cancelHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        disableCancel: false, // This should be part of the object
      }));
    }
  };
  const cancelRemoteShiftChange = async () => {
    if (!formValues?.action?.id) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: `Action is required`,
        cancelText: "No Cancel",
        okText: "Okay",
        message: "Please select an action and try again",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        disableCancel: true,
      }));
      return;
    }
    switch (formValues?.action?.id) {
      case "accept":
        const forUser = await isAnyRequestInitiatedForUser();
        if (!!forUser.data) {
          setModalData((current) => ({
            ...current,
            state: true,
            icon: "question",
            title: `Cancel Remote Shift Change`,
            cancelText: "No Cancel",
            okText: "Ok",
            message: parseLower(forUser?.message),
            // "Are you sure you want to cancel the request initiated for you?",
            okHandler: async () => {
              try {
                const res = await query(
                  RemoteOpServices.CancelInitiatedRemoteShiftChange(
                    formValues?.homeStation?.stnIDpk
                  )
                );
                if (res) {
                  setModalData((current) => ({
                    ...current,
                    state: true,
                    icon: "success",
                    title: `Remote Shift Cancelled`,
                    cancelText: "No Cancel",
                    okText: "Okay",
                    disableCancel: true,
                    okHandler: () => {
                      setModalData((current) => ({
                        ...current,
                        state: false,
                      }));
                    },
                    message:
                      res?.message ||
                      "Remote Shift Change cancellation completed successfully",
                    cancelHandler() {
                      setModalData((current) => ({
                        ...current,
                        state: false,
                      }));
                    },
                  }));
                  setFormValues((prev) => ({
                    action: { id: undefined, value: undefined },
                    homeStation: undefined,
                    initiatingOp: undefined,
                    remoteOp: undefined,
                    summary: "",
                  }));
                  // reset the incoming shift members
                  recordIncomingShiftMembers.current = [];
                  setShiftMembers([]);
                }
              } catch (err) {
                setModalData((current) => ({
                  ...current,
                  state: true,
                  icon: "warning",
                  title: `Operation failed`,
                  cancelText: "No Cancel",
                  okText: "Ok",
                  message: err.message || "Operation failed, please try again",
                  disableCancel: true,
                  okHandler: () => {
                    setModalData((current) => ({
                      ...current,
                      state: false,
                    }));
                  },
                  cancelHandler() {
                    setModalData((current) => ({
                      ...current,
                      state: false,
                    }));
                  },
                }));
              }
            },
            cancelHandler: () => {
              setModalData((current) => ({ ...current, state: false, }));
            },
            disableCancel: false, // This should be part of the object
          }));
        } else {
          setModalData((current) => ({
            ...current,
            state: true,
            icon: "warning",
            title: `No Request Found`,
            cancelText: "No, cancel",
            okText: "Okay",
            message: parseLower(forUser?.message),
            disableCancel: true,
            okHandler: () => {
              setModalData((current) => ({
                ...current,
                state: false,
              }));
            },
          }));
        }
        return;
      case "request":
        // const hasInitiated = await findInitiatedSession();
        const byUser = await isAnyRequestInitiatedByUser();

        if (!!byUser?.data) {
          setModalData((current) => ({
            ...current,
            state: true,
            icon: "question",
            title: `Cancel Request`,
            cancelText: "No",
            okText: "Yes",
            message:
              "Are you sure you want to cancel the request initiated by you?",
            okHandler: async () => {
              try {
                const res = await query(
                  RemoteOpServices.CancelInitiatedRemoteShiftChange(
                    formValues?.homeStation?.stnIDpk
                  )
                );
                if (res) {
                  setModalData((current) => ({
                    ...current,
                    state: true,
                    icon: "success",
                    title: `Remote Shift Cancelled`,
                    cancelText: "No Cancel",
                    okText: "Okay",
                    disableCancel: true,
                    okHandler: () => {
                      setModalData((current) => ({
                        ...current,
                        state: false,
                      }));
                    },
                    message: `Cancelled the Remote Shift Change request by ${formValues.initiatingOp.empName1} initiated for ${formValues.remoteOp.empName1} for the station  ${formValues.homeStation.stnName}`,
                    cancelHandler() {
                      setModalData((current) => ({
                        ...current,
                        state: false,
                      }));
                    },
                  }));
                  setFormValues((prev) => ({
                    action: { id: undefined, value: undefined },
                    homeStation: undefined,
                    initiatingOp: undefined,
                    remoteOp: undefined,
                    summary: "",
                  }));
                  // reset the incoming shift members
                  recordIncomingShiftMembers.current = [];
                  setShiftMembers([]);
                }
              } catch (err) {
                setModalData((current) => ({
                  ...current,
                  state: true,
                  icon: "warning",
                  title: `Operation failed`,
                  cancelText: "No Cancel",
                  okText: "Ok",
                  message: "Operation failed, please try again",
                  disableCancel: true,
                  okHandler: () => {
                    setModalData((current) => ({
                      ...current,
                      state: false,
                    }));
                  },
                  cancelHandler() {
                    setModalData((current) => ({
                      ...current,
                      state: false,
                    }));
                  },
                }));
              }
            },
            cancelHandler: () => {
              setModalData((current) => ({
                ...current,
                state: false,
              }));
            },
            disableCancel: false, // This should be part of the object
          }));
        } else {
          setModalData((current) => ({
            ...current,
            state: true,
            icon: "warning",
            title: `No Request Found`,
            cancelText: "No Cancel",
            okText: "Ok",
            message:
              byUser?.message === ""
                ? "There is no active remote shift change request by you currently"
                : parseLower(byUser?.message),
            disableCancel: true,
            okHandler: () => {
              setModalData((current) => ({
                ...current,
                state: false,
              }));
            },
          }));
        }
        return;
      default:
        throw new Error("Error encountered");
    }
  };

  const refineData = (data: ShiftMembers["data"]): any[][] => {
    const refined = [[]];
    let count = 0;
    data?.forEach((empName1, index: number) => {
      if (count === 4) {
        refined.push([]);
        count = 0;
      }
      refined[refined.length - 1].push(empName1?.empName1);
      count++;
    });
    return refined;
  };

  const handleCheckboxChange = useCallback(
    (member: string) => (e: any) => {
      recordIncomingShiftMembers.current = e
        ? [...recordIncomingShiftMembers.current, member]
        : recordIncomingShiftMembers.current.filter((item) => item !== member);
    },
    []
  );
  return (
    <>
      <ModalTemplate
        icon={modalData.icon}
        disableCancel={modalData.disableCancel}
        cancelText={modalData.cancelText}
        open={modalData.state}
        okHandler={modalData.okHandler}
        cancelHandler={modalData.cancelHandler}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        width={`500px`}
      />
      <Modal
        maskClosable={false}
        width={850}
        footer=""
        centered
        style={{
          border: "1px solid rgb(59 130 246)",
          borderRadius: 9,
          paddingBottom: 0,
          width: 850,
        }}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            eLog Book - Remote Shift Change
          </div>
        }
        open={open}
        onOk={() => { }}
        transitionName=""
        onCancel={() => {
          handleCancel();
        }}
      >
        <>
          <main className="">
            {/* right form */}
            <Form
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 19 }}
              className=" "
              size="small"
            >
              <div className=" flex justify-between">
                <div className=" mb-0.5 w-1/2 ">
                  {/* <div className=" w-1/2 ml-[54px] mb-0.5"> */}
                  <div className=" w-full ml-[38px] mb-0.5">
                    <SelectsTemplate
                      useCusWidth
                      useCallFunc
                      span
                      cusWidth={50}
                      disabled={false}
                      idexpr="id"
                      dataexp="value"
                      label="Action"
                      placeHolder={formValues?.action?.value}
                      selectedValue={(e) => {
                        setFormValues((prev) => ({ ...prev, action: e }));
                      }}
                      options={[
                        { id: "request", value: "Request/Initiate" },
                        { id: "accept", value: "Accept" },
                      ]}
                    />
                  </div>

                  <div className=" mb-0.5 ml-[67px] w-full">
                    <DropdownGrid_template
                      keyExpr="crdIDpk"
                      label={"Home Station"}
                      gridData={homeStation ?? []}
                      dropdownBtnStyles={{
                        width: 250,
                        overflow: "hidden",
                        height: 25,
                      }}
                      // className="pb-[28px]"
                      cusBtnStyle={true}
                      columns={homeStationColumns}
                      defaultValue={formValues?.homeStation?.stnName}
                      disabled={formValues?.homeStation?.disabled}
                      onRowClick={(e) => {
                        setFormValues((prev) => ({ ...prev, homeStation: e }));
                      }}
                      handleRefresh={() => { !formValues?.homeStation?.disabled && PopLueReportingStations(); }}
                    />

                  </div>
                  <div className=" mb-0.5 ml-[41px] w-full">
                    <DropdownGrid_template
                      keyExpr="empIDpk"
                      label={"Initiating Operator"}
                      gridData={initiatingOperator ?? []}
                      dropdownBtnStyles={{
                        width: 250,
                        overflow: "hidden",
                        height: 25,
                      }}
                      cusBtnStyle={true}
                      columns={remoteOperatorsColumns}
                      defaultValue={formValues?.initiatingOp?.empName1}
                      disabled={false}
                      onRowClick={(e) => {
                        setFormValues((state) => ({ ...state, initiatingOp: e, }));
                      }}
                      handleRefresh={async () => {
                        await PopLueInitiatingOperators();
                      }}
                    />
                  </div>
                  <div className="mb-0.5 ml-[47px] w-full">
                    <DropdownGrid_template
                      keyExpr="empIDpk"
                      label={"Remote Operator"}
                      gridData={remoteOperators ?? []}
                      dropdownBtnStyles={{
                        width: 250,
                        overflow: "hidden",
                        height: 25,
                      }}
                      cusBtnStyle={true}
                      columns={remoteOperatorsColumns}
                      defaultValue={formValues?.remoteOp?.empName1}
                      disabled={false}
                      onRowClick={(e) => {
                        setFormValues((state) => ({ ...state, remoteOp: e }));
                      }}
                      handleRefresh={async () => {
                        await PopLueRemoteOperators();
                      }}
                    />
                  </div>
                </div>

                <div className=" mb-0.5 w-1/2 ml-4 flex justify-end items-end">
                  <span
                    onClick={async () => {
                      await PopClbOperators();
                    }}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 26,
                      marginLeft: 3,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span>

                </div>
              </div>
              {
                showIncomingShiftMembers && (
                  <section className="flex mb-1">
                    <p className="text-[12px] w-[24.5%]">Incoming Shift Members:</p>
                    <section
                      className={`flex h-auto w-full bg-muted overflow-x-auto p-1 border-[1px] rounded-md space-x-10 px-2`}
                    >
                      {refineData(ShiftMembers)?.map((items, index) => (
                        <section
                          className="min-w-72 h-[10rem] space-y-1"
                          key={index}
                        >
                          {items.map((member, _) => (
                            <section
                              className="flex items-center space-x-2 w-full h-auto"
                              key={_}
                            >
                              <CheckboxTemlate
                                withBorders
                                useCallbackFunc
                                setCheckboxOnchange={handleCheckboxChange(member)}
                                customDisabled={false}
                              />
                              <span>{member}</span>
                            </section>
                          ))}
                        </section>
                      ))}
                    </section>
                  </section>)
              }


              <div className="ml-[59px] ">
                <TextareaTemplate
                  labelCol={3}
                  disabled={false}
                  label="Summary"
                  height={200}
                  useCallbackFunc
                  defaultValue={formValues?.summary}
                  setCallbackValue={(e) => {
                    setFormValues((prev) => ({ ...prev, summary: e }));
                    // setSummary([{ rscRmks: e }]);
                  }}
                />
              </div>

              {/* <div className=" ml-[106.5px]"> */}
              <div className=" ml-[145px]">
                <div className=" mt-1 flex flex-row justify-between">
                  <div className=" w-1/2 flex h-fit">
                    {/*  */}
                    <CustomizableButton
                      useCallbackFunc
                      handleCustomizableClick={async () => {
                        await cancelRemoteShiftChange();
                      }}
                      isTooltip
                      tooltipMsg={"Close"}
                      classname={"px-2 py-2"}
                      customizableBtnImage={close}
                    />
                  </div>

                  <div className=" w-1/2 flex justify-end mr-[-5px]">
                    {formValues?.action?.id === "accept" && (
                      // <ApproveButton
                      //   title='Accept'
                      //   disableButton={false}
                      //   useCallbackFunc
                      //   disabled_status={false}
                      //   handleSave={() => {
                      //     alert("Approve on steroids")
                      //     console.log("Approve on steroids")
                      //   }}
                      //   handleSubmit={updateOrAcceptRemoteShiftChange}
                      // />

                      <button
                        onClick={() => {
                          let isFieldValid = fieldHasErrors();
                          if (!isFieldValid) return;
                          updateOrAcceptRemoteShiftChange("accept");
                        }}
                        className="flex items-center space-x-3 border rounded-md px-2 py-1"
                      >
                        <CheckCircleTwoTone color="green" size={30} />
                        <span>Accept</span>
                      </button>
                    )}
                    {formValues?.action?.id === "request" && (
                      <SaveButton
                        title="Initiate"
                        disableButton={false}
                        useCallbackFunc
                        disabled_status={false}
                        handleSubmit={() => {
                          let isFieldValid = fieldHasErrors();
                          if (!isFieldValid) return;
                          updateOrAcceptRemoteShiftChange("request");
                        }}
                        handleSave={() => {
                          let isFieldValid = fieldHasErrors();
                          if (!isFieldValid) return;
                          updateOrAcceptRemoteShiftChange("request");
                        }}
                      />
                    )}
                  </div>
                </div>

                <hr className=" text-gray-200 my-2" />

                <div className="">
                  <p>
                    <span className=" text-blue-600 font-bold">*</span>
                  </p>
                </div>
              </div>
            </Form>
          </main>
        </>
      </Modal>
    </>
  );
};
export default Remote_Shift_Change;
