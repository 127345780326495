/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Select, Input, Checkbox } from "antd";
import Datagrid_template from "../../../../../../../../templates/Datagrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../app/store";
import newIcon from "../../../../../../../../assets/new.png";
import refresh from "../../../../../../../../assets/refresh.png";
import { lower_cols, top_cols } from "../../data/datagrid_cols";
import useFetch from "../../../../../../../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import { searchTextTypes } from "../../../../../../../human-resource/setups/data/types/selectsTypes";
import { Cancel, SaveButton } from "../../../../../../../accessories/buttons";
import close from "../../../../../../../../assets/close.png"


export const Right_Share = ({ fullPage }: { fullPage?: any }) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [search, setSearch] = useState("");
  const [sharedEmps, setSharedEmps] = useState([]);

  const criteria = [
    {
      id: 0,
      value: "Staff",
    },
    {
      id: 1,
      value: "Group",
    },
  ];
  const [searchCriteria, setSearchCriteria] = useState<searchTextTypes>({
    temp: "0",
    text: "0",
  });
  const [currentColumns, setCurrentColumns] = useState(top_cols);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [refreshBtn, setRefreshBtn] = useState(false);
  const[sharedData,setSharedData] = useState<any>({})

  const [shareEmps] = useFetch(
    `AsmTmsEntMaintenanceActivity/SearchStaffs?searchTeaxt=${search}`,
    refreshBtn
  );
  const selectAllOwn = (checked: boolean) => {
    setCurrentData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtOwn: checked }))
    );
  };
  const selectAllRead = (checked: boolean) => {
    setCurrentData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtRead: checked }))
    );
  };
  const selectAllPrint = (checked: boolean) => {
    setCurrentData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtPrint: checked }))
    );
  };
  const [currentData, setCurrentData] = useState(shareEmps);
  useEffect(() => {
    if (shareEmps) {
      setCurrentData(shareEmps);
    }
  }, [shareEmps]);
  const handleSelectChange = (val: string) => {
    setSearchCriteria({
      temp: val,
      text: searchCriteria.text,
    });

    switch (val) {
      case "1":
        setCurrentColumns(top_cols);
        setCurrentData(shareEmps);
        break;
      default:
        setCurrentColumns(top_cols);
        setCurrentData(shareEmps);
        break;
    }
  };
  const addedCols = {
    swtOwn: false,
    swtRead: false,
    swtPrint: false,
  };
  shareEmps.forEach((obj: any) => Object.assign(obj, addedCols));

  const addSharedEmp = () => {
    if (selectedRecord && Object.keys(selectedRecord).length != 0) {
      const alreadyExists = sharedEmps.some(
        (obj: any) => selectedRecord.empIDpk === obj.empIDpk
      );

      if (alreadyExists) {
        const updatedSharedEmps:any = sharedEmps.map((obj: any) => {
          if (obj.empIDpk === selectedRecord.empIDpk) {
            return { ...obj, ...selectedRecord };
          }
          return obj;
        });
        setSharedEmps(updatedSharedEmps);
      }

      if (!alreadyExists) {
        const record = { ...selectedRecord };
        setSharedEmps((prev: any) => [...prev, record] as typeof sharedEmps);
      }
    }
  };
console.log("selectedRecord",selectedRecord)

  const removeDblClicked = useCallback(
    (e: any) => {
      setSharedEmps((prev: any) =>
        prev.filter(
          (item: any) =>
            item.empIDpk !== e.empIDpk || item.empIdpk !== e.empIdpk
        )
      );
    },
    [setSharedEmps]
  );
console.log("sharedData",sharedData)
const rowColoring = (e: any) => {
  if (e.rowType === "data") {
    return (e.rowElement.style.backgroundColor = "#FFE4C4");
  }
};

  return (
    <div style={{ height: fullPage }} className="w-full ">
      <ul className="w-full flex justify-evenly items-center">
        <li className="justify-center items-center px-1">
          <p>Find:</p>
        </li>
        <li className="justify-center items-center">
          <Select
          defaultValue={"Staff"}
            options={criteria}
            className="w-[75px] mt-0.5"
            size="small"
            onChange={handleSelectChange}
          />
        </li>
        <li className="justify-center items-center">
          <Input
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
            className="w-[70px]"
            size="small"
          />
        </li>
        <li
          onClick={() => {
            addSharedEmp();
          }}
          style={{ borderColor: borderTheme }}
          className="justify-center items-center border-[1px] cursor-pointer rounded py-0.5 px-0.5"
        >
          <img src={newIcon} />
        </li>
        <li
          onClick={() => {
            setRefreshBtn(!refreshBtn);
          }}
          style={{ borderColor: borderTheme }}
          className="justify-center items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5"
        >
          <img className="w-4" src={refresh} />
        </li>
      </ul>
      <ul className="w-[150px] flex items-center justify-between ">
        <li className="  border-[1px] rounded px-1 ml-[10px]">
          <Checkbox
            title="Tick to select all"
            onChange={(e) => selectAllOwn(e.target.checked)}
          />
        </li>
        <li className=" border-[1px] rounded px-1">
          <Checkbox
            title="Tick to select all"
            onChange={(e) => selectAllRead(e.target.checked)}
          />
        </li>
        <li className=" border-[1px] rounded px-1 mr-[10px]">
          <Checkbox
            title="Tick to select all"
            onChange={(e) => selectAllPrint(e.target.checked)}
          />
        </li>
      </ul>
      <div className="pt-[1px] h-[40%]">
        <Datagrid_template
          gridheight={255}
          columns={currentColumns}
          data={currentData}
          deselectFirstRow
          allowColumnEditing={true}
          disableGroupPanel
          disableSearch
          disablePaging
          style={{ maxWidth: "400px" }}
          noColumn={150}
          onRowClick={(e) => {
            setSelectedRecord(e);
          }}
        />

        <Datagrid_template
          gridheight={330}
          columns={lower_cols}
          allowColumnEditing={true}
          data={sharedEmps}
          disableGroupPanel
          deselectFirstRow
          onRowPrepared={rowColoring}
          rowAlternationEnabled={false}
          disableSearch
          disablePaging
          style={{ maxWidth: "400px" }}
          noColumn={150}
          onRowClick={(e) => {
            setSharedData(e);
          }}
          rowDoubleClicked={(e) => {
            removeDblClicked(e);
          }}
        />
        <ul className=" w-full flex items-center ml-2 mt-[60px] gap-2">
          <SaveButton disableButton={sharedEmps.length === 0 ? true : false}/>
          <li
            onClick={() => {
              setRefreshBtn(!refreshBtn);
            }}
            style={{ borderColor: borderTheme }}
            className="justify-center items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5"
          >
            <img className="w-5" src={refresh} />
          </li>
          <li
            onClick={() => {
              removeDblClicked(sharedData);
            }}
            style={{ borderColor: borderTheme }}
            className="justify-center items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5"
          >
            <img className="w-5" src={close} />
          </li>
         
        </ul>
      </div>
    </div>
  );
};
