import { Button } from "devextreme-react"
import DateTemp from "../components/dateTemp"
import DropDownTemp from "../components/dropDownTemp"
import { Form } from "antd"
import { useState } from "react"
import dayjs from "dayjs"
import checked from "../../../../assets/apply_16x16.png"

export const SyncLog = () => {

    const [formState, setFormState] = useState({
        startDate: dayjs().format(),
        endDate: dayjs().format(),
        stations: [],
    });

    const up = (key: string, value: any) => {
        setFormState((state) => ({ ...state, [key]: value }));
    }

    return (
        <Form className="w-full space-y-3">
            <DateTemp label="Start Date" labelCol={5} value={dayjs(formState.startDate).format()} onValueChanged={(date) => up("startDate", dayjs(date).format())} />
            <DateTemp label="End Date" labelCol={5} value={dayjs(formState.endDate).format()} onValueChanged={(date) => up("endDate", dayjs(date).format())} />
            <DropDownTemp label="Station" showRefresh={false} labelCol={5} options={formState.stations} height={30} />
            <aside className="flex justify-end items-center"> 
                <Button text="Ok" type="normal" width={80} icon={checked} />
            </aside>
        </Form>
    )
}