/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Select, Input, Checkbox } from "antd";
import { useSelector } from "react-redux";
import close from "../../../../../../assets/close.png";
import { useState, useEffect, useCallback, useRef } from "react";
import { RootState } from "../../../../../../app/store";
import useFetch from "../../../../../../hooks/useFetch";
import { SaveButton } from "../../../../../accessories/buttons";
import { searchTextTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import {
  top_cols,
  lower_cols,
} from "../../Maintenance/Maintenance_Activities/data/datagrid_cols";
import newIcon from "../../../../../../assets/new.png";
import refresh from "../../../../../../assets/refresh.png";
import save from "../../../../../../assets/save2.png";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  share_saved_cols,
  Trouble_reports_share_column,
  Trouble_reports_share_columns,
} from "../data/troubleDataSample";
import { useCrudFunc } from "../../../../../../functions/crud";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../../templates/modal";
import EquipmentDataTable from "../../../../../Safety/elogger/StationReadings/tabContents/EquipmentOutage/equipment-data-table";

export const RightShare = ({
  fullPage,
  dataGridData,
  selectedRecordData,
  handleRefresh,
}: {
  fullPage?: any;
  dataGridData?: any;
  selectedRecordData?: any;
  handleRefresh: () => void;
}) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [search, setSearch] = useState("");
  const [sharedEmps, setSharedEmps] = useState([]);

  const criteria = [
    {
      id: 0,
      value: "Staff",
    },
    {
      id: 1,
      value: "Group",
    },
  ];
  const [searchCriteria, setSearchCriteria] = useState<searchTextTypes>({
    temp: "0",
    text: "0",
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "0",
    text: "0",
  });
  const [currentColumns, setCurrentColumns] = useState(top_cols);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [addedRecords, setAddedRecord] = useState<any>([]);
  const [refreshBtn, setRefreshBtn] = useState(false);
  const [sharedData, setSharedData] = useState<any>({});

  const [shareEmps] = useFetch(
    `TroubleReports/GetShareStaff`,
    refreshBtn,
    "trpIDpk",
    true,
    "powerapp",
    false,
    {
      search: searchText.text,
      criteria: searchCriteria.text,
    }
  );

  const selectAllOwn = (checked: boolean) => {
    setCurrentData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtOwn: checked }))
    );
  };
  const selectAllRead = (checked: boolean) => {
    setCurrentData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtRead: checked }))
    );
  };
  const selectAllPrint = (checked: boolean) => {
    setCurrentData((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtPrint: checked }))
    );
  };
  const [currentData, setCurrentData] = useState(shareEmps);
  useEffect(() => {
    if (shareEmps) {
      setCurrentData(shareEmps);
    }
  }, [shareEmps]);
  const handleSelectChange = (val: string) => {
    setSearchCriteria({
      temp: val,
      text: searchCriteria.text,
    });

    switch (val) {
      case "1":
        setCurrentColumns(top_cols);
        setCurrentData(shareEmps);
        break;
      default:
        setCurrentColumns(top_cols);
        setCurrentData(shareEmps);
        break;
    }
  };
  const addedCols = {
    swtOwn: false,
    swtRead: false,
    swtPrint: false,
  };
  shareEmps.forEach((obj: any) => Object.assign(obj, addedCols));

  const addSharedEmp = () => {
    console.log("added shared", addedRecords);
    console.log("selectedRecord", selectedRecord);
setSharedEmps([...addedRecords, ...sharedEmps])
  //   for (let index = 0; index < addedRecords.length; index++) {
  //     const selectedRe = addedRecords[index];
      
  //   console.log('selectedRe', selectedRe)

  //   if (selectedRe && Object.keys(selectedRe).length != 0) {
  //     console.log("inside selected record");
  //     console.log("inside selected record", sharedEmps);
  //     const alreadyExists = sharedEmps.some(
  //       (obj: any) => selectedRe?.empIDpk === obj.empIDpk
  //     );

  //     console.log("found some", alreadyExists);

  //     if (alreadyExists) {
  //       const updatedSharedEmps: any = sharedEmps.map((obj: any) => {
  //         if (obj.empIDpk === selectedRe?.empIDpk) {
  //           return { ...obj, ...selectedRe };
  //         }
  //         return obj;
  //       });
  //       setSharedEmps(updatedSharedEmps);
  //     }

  //     else  {
  //       const record = [selectedRe];
  //       console.log("record", record);
  //       setSharedEmps((prev: any) => [...prev, ...record] as typeof sharedEmps);
  //       console.log(" selected record", sharedEmps);
  //     }
  //   }
  // }
  };

  const removeDblClicked = useCallback(
    (e: any) => {
      setSharedEmps((prev: any) =>
        prev.filter(
          (item: any) =>
            item.empIDpk !== e.empIDpk || item.empIdpk !== e.empIdpk
        )
      );
    },
    [setSharedEmps]
  );
  console.log("sharedData", sharedData);
  const rowColoring = (e: any) => {
    console.log('coloring row', e)
   
    if (e.data?.tshIDpk !== null && e.data?.tshIDpk !== undefined) {
      return (e.rowElement.style.backgroundColor = "#5bb450");
    }else{
      if (e.rowType === "data") {
        return (e.rowElement.style.backgroundColor = "#FFE4C4");
      }
    }
  };

  const [posting, updating] = useCrudFunc("powerapp");

  const confirm = {
    title: "Save Share?",
    message: "Are you sure you want to save this share data?",
    okText: "Yes",
  };

  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(confirm);
  const [userId, employeeId, userInfo] = useAccountInfo();

  const saveShare = async () => {
    try {
      //save comment
 
    setOpenModal(true);
    setModalData(confirm);
    setIcon("question");
    }catch(e){}
  }

  const saveShareData = async () => {
    try {
      console.log("shared emps", sharedEmps);
      console.log("seselps", selectedRecordData);
      if(selectedRecordData?.trpIDpk === undefined){
        setIcon("warning");
        setModalData({
          message: "Error",
          title: "Please select a trouble report",
          okText: "Ok",
        });
       setOpenModal(true);
      }else{
      for (let index = 0; index < sharedEmps.length; index++) {
        const element = sharedEmps[index];


        await posting(
          `TroubleReports/SaveShareTroubleReport`,
          {
            tshIDpk: element.tshIDpk,
            tshReportIDfk: selectedRecordData?.trpIDpk,
            tshEmpIDfk: element.empIDpk,
            tshOwner: element.tshOwner??false,
            tshUpdate: element.tshUpdate??false,
            tshReader: element.tshReader??false,
          
          },
          `saved shareData sent by ${userInfo.empName}`,
          "omgTroubleReports"
        );
      }
      setOpenModal(false);
      setIcon("success");
      // setDisableForm(true)
      // updateState("comment",'');
      setModalData({
        message: "Share Data saved successfully",
        title: "Share Data Saved!",
        okText: "Ok",
      });
      handleRefresh();
      setRefreshBtn(!refreshBtn);
      setOpenModal(true);
      setModalData(confirm);
      setIcon("question");
    }
    } catch (e) {}
  };

  const [shareData, shareError, shareLoading] = useFetch(
    "TroubleReports/GetShareWith",

    refreshBtn,
    "trpIDpk",
    true,
    "powerapp",
    false,
    { TrpIDpk: selectedRecordData?.trpIDpk }
  );

  // const [shareData, shareError, shareLoading] = useFetch(
  //   "TroubleReports/GetShareWith",

  //   refreshBtn,
  //   "trpIDpk", true, "powerapp", false, {"TrpIDpk": selectedRecord.trpIDpk}
  // );

  useEffect(() => {
    if (shareData.length > 0) {
      setSharedEmps(shareData);
    }
  }, [shareData]);

  useEffect(() => {
      setRefreshBtn(!refreshBtn);
      console.log('selectred record changed')
    }, [ selectedRecordData]);

  return (
    <div style={{ height: fullPage }} className="w-full ">
      <ModalTemplate
        icon={icon}
        title={modalData.title}
        message={modalData.message}
        okText={modalData.okText}
        disableCancel={icon === "question" ? false : true}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save Share?" ? saveShareData() : setOpenModal(false);
        }}
        cancelHandler={() => {
          setOpenModal(false);
        }}
      />
      <ul className="w-full flex justify-evenly items-center">
        <li className="justify-center items-center px-1">
          <p>Find:</p>
        </li>
        <li className="justify-center items-center">
          <Select
            defaultValue={"Staff"}
            options={criteria}
            className="w-[75px] mt-0.5"
            size="small"
            onChange={handleSelectChange}
          />
        </li>
        <li className="justify-center items-center">
          <Input
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
            className="w-[70px]"
            size="small"
          />
        </li>
        <li
          onClick={() => {
            
            console.log('added', addedRecords)
            addSharedEmp();
          }}
          style={{ borderColor: borderTheme }}
          className="justify-center items-center border-[1px] cursor-pointer rounded py-0.5 px-0.5"
        >
          <img src={newIcon} />
        </li>
        <li
          onClick={() => {
            setSearchText({ ...searchText, text: search });
            setSearchCriteria({ ...searchCriteria, text: searchCriteria.temp });
            setRefreshBtn(!refreshBtn);
          }}
          style={{ borderColor: borderTheme }}
          className="justify-center items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5"
        >
          <img className="w-4" src={refresh} />
        </li>
      </ul>
      
      <div className="py-[1px] ">
        {/* <Datagrid_template
          gridheight={235}
          columns={Trouble_reports_share_columns}
          data={shareEmps}
          deselectFirstRow
          allowColumnEditing={true}
          // addCustomColumn={true}
          // secondExtraColumn={true}
          selectionMode={"single"}
          disableGroupPanel
          disableSearch
          disablePaging
          style={{ maxWidth: "400px" }}
          noColumn={150}
          onRowClick={(e) => {
            console.log(e)
            setSelectedRecord(e);
          }}
          selectedItemsChange={(e) => {
            console.log('kkkll',e)
            setSelectedRecord(e);
          }}
        /> */}

          <EquipmentDataTable
                  // selectionMode="multiple"
                deselectFirstRow
                disableGroupPanel
                disableSearch
                disablePaging
                data={shareEmps}
                gridheight={235}
                allowColumnEditing={true}
                columns={Trouble_reports_share_columns}
                onRowUpdated={(e)=>{
                  const recordAlreadyExists = addedRecords.some(
                    (obj: any) => obj?.id === e.data.id
                  );
                  console.log('recordAlreadyExists',recordAlreadyExists )

                  if(!recordAlreadyExists){
                    if(e.data.tshUpdate || e.data.tshReader){
//                       let tempArray = [...addedRecords];
// const index = tempArray.findIndex((element) => element.id === e.data.id);
// tempArray[index] = e.data;
                   console.log('adding records to A')
                      setAddedRecord([e.data,...addedRecords])
                     }else{
                      
                        for (let index = 0; index < addedRecords.length; index++) {
                          const element = addedRecords[index];
                          if(element.id === e.data.id){
                            var newRec = [...addedRecords];
                            var itemInd = newRec.indexOf(element.id)
                          var res =  newRec.splice(itemInd, 1)
                            setAddedRecord(res)
                          }
                          
                        }
                      
                     }
                  }else{
                    if(e.data.tshUpdate || e.data.tshReader){
                   
                      // setAddedRecord([e.data, ...addedRecords])
                     }
                  }
                   console.log('monn',e)
                   
                }}
                onRowClick={(e) => {
                  console.log(e)
                  console.log('monn',e)
                  setSelectedRecord(e);
                }}
                selectedItemsChange={(e) => {
                  console.log('kkkll',e)
                  setSelectedRecord(e);
                }}
                //   showSelectionCheckBox="always"
              />

        <Datagrid_template
          columns={share_saved_cols}
          allowColumnEditing={true}
          data={sharedEmps}
          disableGroupPanel
          deselectFirstRow
          onRowPrepared={rowColoring}
          rowAlternationEnabled={false}
          disableSearch
          disablePaging
          style={{ maxWidth: "400px" }}
          noColumn={150}
          gridheight={fullPage - 335}
          onRowClick={(e) => {
            setSharedData(e);
          }}
          rowDoubleClicked={(e) => {
            removeDblClicked(e);
          }}
        />
        <ul className=" w-full flex items-center justify-between px-2 mt-[10px] gap-2">
          <div className="flex">
            <button
              onClick={() => {
                saveShare();
              }}
              style={{ height: "" }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   mr-2 px-2 py-1 border-2 border-slate-200 rounded"
            >
              <img style={{}} className=" w-5" src={save} />
            </button>
            <button
              onClick={() => {
                setRefreshBtn(!refreshBtn);
              }}
              style={{ height: "" }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   mr-2 px-2 py-1 border-2 border-slate-200 rounded"
            >
              <img style={{}} className=" w-5" src={refresh} />
            </button>
          </div>
          <div>
            <li
              onClick={() => {
                removeDblClicked(sharedData);
              }}
              style={{ borderColor: borderTheme }}
              className="justify-center items-center border-[1px] rounded cursor-pointer py-0.5 px-0.5"
            >
              <img className="w-5" src={close} />
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};
