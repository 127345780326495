import { Form, Radio } from "antd";
import React from "react";
import { DateTemplate } from "../../../templates/date";
import InputSelect_template from "../../../templates/InputSelect";
import { TextareaTemplate } from "../../../templates/textarea";
import { SaveButton, NewButton } from "../../accessories/buttons";
import { InputsTemplate } from "../../../templates/input";
import { CheckboxTemlate } from "../../../templates/checkbox";

export const LoadProfileOne = () => {
  return (
    <div>
      <div>
        <p className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
          {"Load Profile 1 Details"}
        </p>
      </div>
      <section className="w-full flex ">
        <aside className="w-[45%]">
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 mr-3"
            name="basic"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 10 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div className="w-full ">
              <DateTemplate label="Date & Time" className=" w-[86%]" labelCol={14}/>
            </div>

            <div className="w-full ">
              <InputSelect_template label="Station" wrapperCol={10} />
            </div>
            <div className="w-full">
              <InputSelect_template label="Meter" wrapperCol={10} />
            </div>
          </Form>
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  mr-3"
            name="basic"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div>
              <InputsTemplate span label="EDIS status" />
            </div>
            <div>
              <InputsTemplate span label="Active Energy (kWh)" />
            </div>
            <div>
              <InputsTemplate span label="Reactive Energy (kvarh)" />
            </div>
            <div>
              <InputsTemplate span label="Maximum Active Power Demand (kW)" />
            </div>
            <div>
              <InputsTemplate
                span
                label="Maximum Reactive Power Demand (kvar)"
              />
            </div>
            <div>
              <InputsTemplate
                span
                label="Maxiimum Apparent Power Demand (kVA)"
              />
            </div>
            <div>
              <InputsTemplate span label="Power Factor" />
            </div>
            <div className="w-full">
              <CheckboxTemlate label="Active?" withBorders/>
            </div>
            <div className=" flex gap-2  w-full justify-end">
              <SaveButton />
              <NewButton />
              </div>
             
          </Form>
        </aside>
        <aside className="w-[55%] ">
          <div className="w-[100%]">
            <Form.Item
              // className="mt-0.5  ml-[148px]"
              label={<p className="text-[12px]">Remarks</p>}
            >
              <div className="w-full ">
                <TextareaTemplate
                  height={300}
                  useCallbackFunc
                  // defaultValue={}
                  setCallbackValue={(e) => {
                    //   setState("remarks", e);
                  }}
                />
              </div>
            </Form.Item>
          </div>
        </aside>
      </section>
    </div>
  );
};
