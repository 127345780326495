import React, { useState } from "react";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import Datagrid_template from "../../../templates/Datagrid";
import { LoadProfileOne } from "./loadProfileOne";
import { HSeperator } from "../../Safety/elogger/widgets/utils";

export const LoadProfile1Parent = () => {
  const [showGrid, setShowGrid] = useState<boolean>(true);
  return (
    <div>
      <div>
        <HrSetupsCustomToolbar searchCriteria />
      </div>
      {showGrid && (
        <div>
          <div>
            <LoadProfileOne />
          </div>
        </div>
      )}
      <HSeperator onClick={() => setShowGrid(!showGrid)} status={showGrid} />
      <div>
        {/* DataGrid Template */}
        <Datagrid_template
          gridheight={showGrid ? 438 : 740}
          columns={[]}
          data={[]}
        />
      </div>
    </div>
  );
};
