import { Item } from "devextreme-react/accordion";
import { useEffect, useState } from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { setGeneralValue } from "../../../../../../../../features/generalSlice";
import {
  useQueryFetch,
  useQueryFetcherX,
} from "../../../../../../../../functions/crud";
import {
  searchTextTypes,
  statusTypes,
  searchCriteriaTypes,
} from "../../../../../../../human-resource/setups/data/types/selectsTypes";
import AssetDetailsForm from "./form";
import { AssetDetailsToolbar } from "./toolbar";
import { Modal } from "antd";
import { ResponseType } from "../../../troubleReport";

type FormProps = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  view?: "modal" | "form";
};

export const AssetDetails = ({ open, setOpen, view = "modal" }: FormProps) => {
  const taskDetailsHeight = "309px";

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().startOf("year").month(0).date(1).format(genFormat);

  const initialStart = dayjs()
    .subtract(1, "month")
    .startOf("months")
    .format(genFormat);
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: dayjs().format(genFormat),
    temp: dayjs().format(genFormat),
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 1,
    index: 1,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const current_page_route = "/tasks-todos";
  // const view = searchParams.get("view");
  const taskDataGrid = "task-data-grid-extended";
  const [showTable, setShowTable] = useState(true);
  const [refreshstate, setRefreshstate] = useState(false);
  const [record, setRecord] = useState<any>();

  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change((state) => !state);
      // navigate(`?view=table-only`);
    } else {
      // navigate(`?view=form-and-table`);
      setSwitch_change((state) => !state);
    }
  };

  // switch button toggler
  // const switch_toggle = () => {
  //   setShowTable(!showTable);
  // };

  const { data: troubleReportData } = useQueryFetch({
    app: "powerapp",
    url: `TroubleAssetDetails/GetAllTroubleAssetDetails`,
    params: {
      // startDate: startDate?.temp,
      // endDate: endDate?.temp,
      search: searchText?.temp === "" ? undefined : searchText?.temp,
      active: status?.temp,
      // SearchCriteria: searchCriteria.index,
      // PageNumber: 1,
      // PageSize: 1000,
    },
    refresh: refreshstate,
    revalidateAfter: 0.5 * 60 * 1000,
  });

  // const { data: troubleReportData } = useQueryFetcherX<
  //   ResponseType<AssetDetail>
  // >(
  //   {
  //     queryKey: "GetAllTroubleAssetDetails",
  //     app: "powerapp",
  //     url: `TroubleAssetDetails/GetAllTroubleAssetDetails?search=${
  //       searchText?.temp === "" ? undefined : searchText?.temp
  //     }&active=${status?.temp}`,
  //     revalidateAfter: 0.5 * 60 * 1000,
  //   },
  //   { refresh: refreshstate }
  // );

  if (view === "modal") {
    return (
      <Modal
        maskClosable={false}
        width={`70%`}
        footer=""
        centered
        style={{
          border: "1px solid rgb(59 130 246)",
          borderRadius: 9,
          paddingBottom: 0,
          width: `70%`,
        }}
        title={
          <div
            style={{
              width: "70%",
              cursor: "move",
            }}
            onMouseOver={() => {
              // if (disabled) {
              //   setDisabled(false);
              // }
            }}
            onMouseOut={() => {
              // setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Trouble Rpts - Trouble Asset Details
          </div>
        }
        // open={open}
        open={open}
        onOk={() => {}}
        transitionName=""
        onCancel={() => {
          // handleCancel();
          setOpen(false);
        }}
      >
        <>
          <div className="px-2 mr-6">
            <AssetDetailsToolbar
              toggler={switch_toggle}
              switch_change={setSwitch_change}
              useCallBackFunc
              startDate={startDate?.temp}
              endDate={endDate?.temp}
              startDateChanged={(date) =>
                setStartDate({
                  temp: dayjs(date).format(genFormat),
                  text: startDate.text,
                })
              }
              onNewClick={() => {
                dispatch(setGeneralValue({ expr: "clearForm", value: true }));
                setRecord(undefined);
              }}
              endDateChanged={(date) =>
                setEndDate({
                  temp: dayjs(date).format(genFormat),
                  text: endDate.text,
                })
              }
              searchCriteriaOnchange={(newIndex, criteria) => {
                setsearhCriteria({
                  temp: newIndex,
                  index: searchCriteria.index,
                });
              }}
              searchTextOnchange={(text) =>
                setSearchText({ temp: text, text: searchText.text })
              }
              checkOnChange={(check) =>
                setStatus({ temp: check, status: status.status })
              }
              searchCriteriaData={[]}
              searchValue={searchText?.temp}
              handleFind={() => {
                setSearchText({
                  temp: searchText?.temp,
                  text: searchText?.temp,
                });
                setStatus({ temp: status?.temp, status: status?.temp });
                setStartDate({ temp: startDate?.temp, text: startDate.text });
                setEndDate({ temp: endDate?.temp, text: endDate.text });
                setsearhCriteria({
                  temp: searchCriteria.temp,
                  index: searchCriteria.temp,
                });
                setTimeout(() => {
                  setRefreshstate((state) => !state);
                }, 600);
              }}
              handleRefresh={() => {
                setSearchText({ temp: "", text: "" });
                setsearhCriteria({ temp: 1, index: 1 });

                setStatus({ temp: "", status: "" });

                setTimeout(() => {
                  setRefreshstate((state) => !state);
                }, 600);
              }}
              outerSelected={record}
              withDates={false}
            />
          </div>

          <div className="px-2 mb-0.5  ">
            <AssetDetailsForm
              toggled={switch_change}
              troubleReportData={troubleReportData?.data ?? []}
              setRefreshstate={setRefreshstate}
              setRecord={setRecord}
              setSwitch_change={setSwitch_change}
              switch_change={switch_change}
            />
          </div>
        </>
      </Modal>
    );
  }
  if (view === "form") {
    return (
      <>
        <div className="px-2 mr-6">
          <AssetDetailsToolbar
            toggler={switch_toggle}
            switch_change={setSwitch_change}
            useCallBackFunc
            startDate={startDate?.temp}
            endDate={endDate?.temp}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            onNewClick={() => {
              dispatch(setGeneralValue({ expr: "clearForm", value: true }));
              setRecord(undefined);
            }}
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
            }}
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            searchCriteriaData={[]}
            searchValue={searchText?.temp}
            handleFind={() => {
              setSearchText({ temp: searchText?.temp, text: searchText?.temp });
              setStatus({ temp: status?.temp, status: status?.temp });
              setStartDate({ temp: startDate?.temp, text: startDate.text });
              setEndDate({ temp: endDate?.temp, text: endDate.text });
              setsearhCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setTimeout(() => {
                setRefreshstate((state) => !state);
              }, 600);
            }}
            handleRefresh={() => {
              setSearchText({ temp: "", text: "" });
              setsearhCriteria({ temp: 1, index: 1 });

              setStatus({ temp: "", status: "" });

              setTimeout(() => {
                setRefreshstate((state) => !state);
              }, 600);
            }}
            outerSelected={record}
            withDates={false}
          />
        </div>

        <div className="px-2 mb-0.5  ">
          <AssetDetailsForm
            toggled={switch_change}
            troubleReportData={troubleReportData?.data ?? []}
            setRefreshstate={setRefreshstate}
            setRecord={setRecord}
            setSwitch_change={setSwitch_change}
            switch_change={switch_change}
          />
        </div>
      </>
    );
  }
};
export type AssetDetail = {
  tadIDpk: number;
  tatIDpk: number;
  tacIDpk: number;
  mftIDpk: number;
  tadName: string;
  tadSerialNo: string;
  tadCode: string;
  tatName: string;
  tacName: string;
  mftName: string;
  tadLocationCoordinates: string;
  tadManufactureDate: string; // Consider using Date type if parsing is needed
  tadInstallationDate: string; // Consider using Date type if parsing is needed
  tadEndOfLifeDate: string; // Consider using Date type if parsing is needed
  tadPurchaseCost: number;
  tadOperationalCost: number;
  tadDepreciation: number;
  tadOrder: number;
  tadActive: boolean;
  tadRmks: string;
};
