import dayjs from "dayjs";
import {
  currentDate,
  startingDate,
} from "./../../components/accessories/component_infos";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface searchParamsProp {
  cmbSrchViewStation: { initialValue: string; value: string }; 
  pageNumber: number;
  pageSize: number;
  eventType: { initialValue: string; value: string };
  lbkEvent: { initialValue: string; value: string };
  loggedByName: string;
  cmbSrchDispatcher: { initialValue: string; value: string };
  checkActive: { initialValue: string | boolean; value: string | boolean };
  bookMark: { initialValue: string | boolean; value: string | boolean };
  correspondentName: { initialValue: string; value: string };
  startDate: { initialStartDate: any; startDate: any };
  endDate: { initialEndDate: any; endDate: any };
  logStationName: {
    initialValue: string;
    value: string;
  };
  refresh: boolean;
  homeStationID: number;
}

const searchParamsOnChange: searchParamsProp = {
  cmbSrchViewStation: { initialValue: "", value: "" }, 
  pageNumber: 1,
  pageSize: 20,
  eventType: { initialValue: "", value: "" },
  lbkEvent: { initialValue: "", value: "" },
  loggedByName: "",
  cmbSrchDispatcher: { initialValue: "", value: "" },
  bookMark: { initialValue: "", value: "" },
  checkActive: { initialValue: "", value: "" },
  correspondentName: { initialValue: "", value: "" },
  startDate: { initialStartDate: startingDate, startDate: startingDate },
  endDate: { initialEndDate: dayjs(currentDate).add(1, 'day').format("YYYY-MM-DD 23:59:00"), endDate: dayjs(currentDate).add(1, 'day').format("YYYY-MM-DD 23:59:00") },
  logStationName: {
    initialValue: "",
    value: "",
  },
  refresh: false,
  homeStationID: 0,
};

const elogBookSlice = createSlice({
  name: "elogBook",
  initialState: searchParamsOnChange,
  reducers: {
    setSearchParamOnChange: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      return { ...state, [action.payload.key]: action.payload.value };
    },
    handleElogbookSearch: (state) => {
      return {
        ...state,
        cmbSrchViewStation: {
          ...state.cmbSrchViewStation,
          value: state.cmbSrchViewStation.initialValue, 
        },
        eventType: { ...state.eventType, value: state.eventType.initialValue },
        lbkEvent: { ...state.lbkEvent, value: state.lbkEvent.initialValue },
        cmbSrchDispatcher: {
          ...state.cmbSrchDispatcher,
          value: state.cmbSrchDispatcher.initialValue,
        },
        checkActive: {
          ...state.checkActive,
          value: state.checkActive.initialValue,
        },
        bookMark: { ...state.bookMark, value: state.bookMark.initialValue },
        correspondentName: {
          ...state.correspondentName,
          value: state.correspondentName.initialValue,
        },
        logStationName: {
          ...state.logStationName,
          value: state.logStationName.initialValue,
        },
        startDate: {
          ...state.startDate,
          startDate: state.startDate.initialStartDate,
        },
        endDate: { ...state.endDate, endDate: state.endDate.initialEndDate },
        refresh: !state.refresh,
      };
    },
    resetSearchParams: (state) => {
      state = searchParamsOnChange;
      return state;
    },
  },
});
export const {
  setSearchParamOnChange,
  resetSearchParams,
  handleElogbookSearch,
} = elogBookSlice.actions;
export default elogBookSlice.reducer;
