import { Progress, ProgressProps, Rate } from "antd";
import { datagridColumnTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { RefAttributes } from "react";
import React from "react";

type PartialTbRepColumns = Partial<datagridColumnTypes>;
export type OmitCellPreparedEventTypes<TData = any> = Omit<
  DataGridTypes.CellPreparedEvent<TData>,
  | "values"
  | "resized"
  | "isAltRow"
  | "isEditing"
  | "removed"
  | "summaryItems"
  | "update"
>;
export type OmitHeaderCellRenderType = Pick<
  DataGridTypes.CellPreparedEvent,
  "cellElement" | "columnIndex" | "component" | "watch" | "rowIndex" | "rowType"
> & {
  isAltRow: boolean;
};
export const Trouble_reports_column = [
  {
    id: 0,
    caption: "SER. No",
    dataField: "trpIDpk",
    dataType: "",
    width: 160,
    fixed: true,
    visible: true,
  },

  {
    id: 1,
    caption: "DATE",
    dataField: "trpReportDate",
    dataType: "datetime",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "TIME",
    dataField: "troubleTime",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "LOCATION",
    dataField: "tlcName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "CATEGORY",
    dataField: "tctName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "TROUBLE TYPE",
    dataField: "ttpName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "% COMPLETE",
    dataField: "trpPercentComplete",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
    cellRender(cell: OmitCellPreparedEventTypes) {
      return (
        <div className="flex flex-row items-center flex-1 pr-1">
          <div className="flex-1 w-full h-full">
            <Progress
              type="line"
              percentPosition={{ align: "center", type: "inner" }}
              style={{ width: "100%", height: "100%", color: `red` }}
              showInfo
              strokeColor={`#f0abfc`}
              className="flex-1"
              percent={
                cell.value
                  ? Number(cell.value)
                  : Number(cell?.data?.trpPercentComplete)
              }
              status="active"
            />
          </div>
        </div>
      );
    },
  },
  {
    id: 7,
    caption: "CLASS",
    dataField: "tclName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "SUBCLASS",
    dataField: "tscName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "TROUBLE DESCRIPTION",
    dataField: "trpReport",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "PRIORITY",
    dataField: "tptName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "EQUIP. TYPE",
    dataField: "tatName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "EQUIPMENT/ASSET(S)",
    dataField: "trpEquipment",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "RATING",
    dataField: "trpRating",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
    cellRender(cell: OmitCellPreparedEventTypes) {
      return (
        <div className="flex flex-row items-center flex-1">
          <div className="w-full h-full">
            <Rate
              allowHalf
              style={{ color: `#f0abfc` }}
              disabled
              count={5}
              tooltips={Array.from({ length: 5 }, (_, i) =>
                Number(cell.value).toFixed(1)
              )}
              defaultValue={
                cell.value
                  ? Number(Number(cell.value).toFixed(1))
                  : Number(Number(cell?.data?.trpRating).toFixed(1))
              }
              value={
                cell.value
                  ? Number(Number(cell.value).toFixed(1))
                  : Number(Number(cell?.data?.trpRating).toFixed(1))
              }
            />
          </div>
        </div>
      );
    },
  },
  {
    id: 14,
    caption: "FIXED?",
    dataField: "trpfixed",
    dataType: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "STATUS",
    dataField: "tstName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "IMPACT",
    dataField: "timName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "REPAIRS UPDATE",
    dataField: "trpReportDate",
    dataType: "datetime",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "REPORTED BY",
    dataField: "reportedByName",
    dataType: "datetime",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "FIXED ON",
    dataField: "trpCompletionDate",
    dataType: "datetime",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "SEVERITY",
    dataField: "",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "TECHNICAL DESCRIPTION",
    dataField: "trpTechnicalDescription",
    dataType: "string",
    width: 250,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "CLASSIFICATION REMARKS",
    dataField: "trpClassificationRmks",
    dataType: "string",
    width: 250,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "REQ. DEPT",
    dataField: "requestDeptName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "REQ. SECTION",
    dataField: "requestSxnName",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "UPDATED BY",
    dataField: "trpUpdatedBy",
    dataType: "string",
    width: 160,
    fixed: false,
    visible: true,
  },

  {
    id: 26,
    caption: "REMARKS",
    dataField: "trpRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
] as PartialTbRepColumns;

export const Trouble_reports_share_column = [
  {
    id: 0,
    caption: "Owner",
    dataField: "tshOwner",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 1,
    caption: "Reader",
    dataField: "tshReader",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 2,
    caption: "Sign",
    dataField: "tshSign",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 3,
    caption: "Name",
    dataField: "empName1",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: false,
  },

  {
    id: 6,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 7,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    allowEditing: true,
  },
];

export const Trouble_reports_share_columns = [
  {
    id: 20,
    caption: "Upd",
    dataField: "tshUpdate",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
    renderHeaderCellRender: (params) => {
      console.log("RECEIVED ALL PARAMS: ", params);
      return (
        <div className="flex items-center space-x-5">
         <div className="flex justify-center items-center" style={{ paddingTop: "7.5%", color: "black", display: 'flex', gap: '5px' }}>
                  <input
                    type="checkbox"
                    checked={false}
                    onChange={(inptEvent) => { }}
                  />
                  {/* <span>{customColumnCaption}</span> */}
                </div>
          {/* <RefreshButton /> */}
        </div>
      );
    },
  },
  {
    id: 21,
    caption: "Reader",
    dataField: "tshReader",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
    
    renderHeaderCellRender: (params) => {
      console.log("RECEIVED ALL PARAMS: ", params);
      return (
        <div className="flex items-center space-x-5">
         <div className="flex justify-center items-center" style={{ paddingTop: "7.5%", color: "black", display: 'flex', gap: '5px' }}>
                  <input
                    type="checkbox"
                    checked={false}
                    onChange={(inptEvent) => { }}
                  />
                  {/* <span>{customColumnCaption}</span> */}
                </div>
          {/* <RefreshButton /> */}
        </div>
      );
    },
  },
  
  {
    id: 0,
    caption: "Name",
    dataField: "empName1",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: false,
  },

  {
    id: 1,
    caption: "Job Title",
    dataField: "jbtShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: false,
  },
  {
    id: 2,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    allowEditing: false,
  },
  {
    id: 3,
    caption: "Mob No",
    dataField: "empMobNo",
    dataType: "string",
    width: 100,
    allowEditing: false,
  },
  {
    id: 4,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    allowEditing: false,
  },
  {
    id: 5,
    caption: "INTERCOM",
    dataField: "empIntercom",
    dataType: "string",
    width: 100,
    allowEditing: false,
  },
  {
    id: 6,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    allowEditing: false,
  },
];


export const Trouble_reports_reported_by = [
  {
    id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 1,
    caption: "Staff Name",
    dataField: "empName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 2,
    caption: "Department",
    dataField: "dpTshtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 0,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 0,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 0,
    caption: "Job Title",
    dataField: "jbTshtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
];

export const repairs_updates_cols = [
  // {id: 1,caption : "ID", dataField : 'boolean', dataType : "boolean", fixed : true,width:60,visible: false},
  {
    id: 2,
    caption: "DATE",
    dataField: "truDate",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 3,
    caption: "% COMPLETE",
    dataField: "truPercentComplete",
    fixed: true,
    width: 100,
    dataType: "string",
    cellRender(cell: OmitCellPreparedEventTypes) {
      return (
        <div className="flex flex-row items-center flex-1 pr-1">
          <div className="flex-1 w-full h-full">
            <Progress
              type="line"
              percentPosition={{ align: "center", type: "inner" }}
              style={{ width: "100%", height: "100%", color: `red` }}
              showInfo
              // steps={{ count: 100, gap: 1 }}
              strokeColor={`#f0abfc`}
              className="flex-1"
              percent={
                cell.value
                  ? Number(cell.value)
                  : Number(cell?.data?.truPercentComplete)
              }
              status="active"
            />
          </div>
        </div>
      );
    },
  },

  {
    id: 4,
    caption: "TASK UPDATE",
    dataField: "truUpdate",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 5,
    caption: "DONE BY",
    dataField: "truUpdatedBy",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  // {id: 6,caption : "UPDATED BY", dataField : 'truUpdatedBy', dataType : "string", fixed : true, width:100},
  {
    id: 7,
    caption: "JOB TITLE",
    dataField: "createdByJbtShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 8,
    caption: "UPDATER INTERCOM",
    dataField: "createdByMobNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 9,
    caption: "CREATED ON",
    dataField: "truCreationDate",
    dataType: "datetime",
    width: 100,
  },
  {
    id: 10,
    caption: "COMPLETION DATE",
    dataField: "truCompletionDate",
    dataType: "datetime",
    width: 100,
  },
  // {id: 11,caption : "REMARKS", dataField : 'Remarks', dataType : "string",width:4000},
];

export const Trouble_reports_comments_column = [
  {
    id: 0,
    caption: "Name",
    dataField: "empName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: false,
  },

  {
    id: 1,
    caption: "Section",
    dataField: "dptShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 2,
    caption: "Comments",
    dataField: "tcmComment",
    dataType: "string",
    width: 2000,
    allowEditing: true,
  },
];

export const share_saved_cols = [
  {
    id: 1,
    caption: "Upd",
    dataField: "tshUpdate",
    dataType: "boolean",
    width: 60,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 2,
    caption: "Rdr",
    dataField: "tshReader",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 3,
    caption: "Name",
    dataField: "empName1",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: false,
  },
  {
    id: 4,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 5,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 6,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 7,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    allowEditing: true,
  },
];

export const trouble_attachment_cols = [
  {
    id: 1,
    caption: "Date",
    dataField: "triCreationDate",
    dataType: "datetime",
    width: 60,
    fixed: false,
    allowEditing: true,
  },

  {
    id: 6,
    caption: "Remarks",
    dataField: "triRmks",
    dataType: "string",
    width: 4000,
    allowEditing: true,
  },
];
