export const reportingStation_column = [
  {
    id: 0,
    caption: "Station",
    dataField: "stnName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Code",
    dataField: "stnCode",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "SS?",
    dataField: "stnSubstation",
    dataType:"string",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "GS?",
    dataField: "stnGenStation",
    alignment: "left",
    dataType:"string",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "INTERCOM",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "Tell No",
    dataField: "stnTelNo",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 6,
    caption: "Contact Person",
    dataField: "stnContact",
    alignment: "left",
    fixed: true,
    width: null,
  },
];
export const correspondent_column = [
  {
    id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Name",
    dataField: "empName1",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "Dept",
    dataField: "stnSubstation",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "Job Tittle",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "Location",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
];
