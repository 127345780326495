import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";

import { Tooltip } from "devextreme-react";
import edit from "../../../assets/edit.png";
import lock from "../../../assets/lock.png";
import checkmark from "../../../assets/green_checkmark.png";
import question from "../../../assets/question.png";
import location from "../../../assets/location.png";
import statusLoader from "../../../assets/statusloader.gif";

import { Checkbox, DatePicker, Input, Select, Switch } from "antd";
import dayjs from "dayjs";
import "devextreme/dist/css/dx.light.css";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { RootState } from "../../../app/store";
import alter from "../../../assets/alter.png";
import close from "../../../assets/close.png";
import newfile from "../../../assets/new.png";
import printer from "../../../assets/printer.png";
import refresh from "../../../assets/refresh.png";
import screwhammer from "../../../assets/ide_16x16.png";
import {
  task_footer_update,
  task_form_disable,
} from "../../../features/Task&Todo/Task/TaskDetails";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../../features/generalSlice";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
import { IconsContainer } from "../../Safety/elogger/StationReadings/_widget/stationReadingsToolbar";
import { generateUUID } from "../../Safety/elogger/widgets/utils";
import IconButton from "../../Assets-Manager/Transport/Entries/troubleReport/widgets/tr-asset-details/widgets/IconButton";

interface props {
  toggler?: () => void;
  toggleLocation?: () => void;
  print_toggler?: () => void;
  paState?: any;
  pasetState?: (key: string, value: any) => void;
  gridRefX?: any;
  onExport?: (e: any) => void;
  handleSave?: () => void;
  handleNew?: () => void;
  handleUpdate?: () => void;
  searchTextOnchange?: (value: string) => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  checkOnChange?: (value: any) => void;
  handleFind?: () => void;
  handleRefresh?: () => void;
  handlePrint?: () => void;
  handleToggle?: (e: any) => void;
  handleToggleAll?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void;
  disableNew_edit?: boolean;
  withDates?: boolean;
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
  startDate?: string;
  endDate?: string;
  selectedRecord?: any;
  setVisible?: (e: boolean) => void;
  controlAssetDetail?: () => void;
}

export const TroubleReportsToolbar = ({
  toggler,
  toggleLocation,
  print_toggler,
  paState,
  pasetState,
  gridRefX,
  onExport,
  startDate,
  endDate,
  searchCriteriaOnchange,
  searchCriteriaData = [],
  searchCriteria,
  selectedRecord,
  handleNew: newButtonClick,
  handleRefresh,
  handlePrint,
  outerSelected,
  disableNew_edit,
  withDates = true,
  searchTextOnchange,
  handleSave,
  handleUpdate: handleEdit,
  startDateChanged,
  endDateChanged,
  handleFind,
  checkOnChange,
  handleToggle,
  setVisible,
  controlAssetDetail,
}: props) => {
  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";

  const form_disable = useSelector(
    (state: RootState) => state.general.M_Activities
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  const gridRef = useSelector((state: RootState) => state.general.gridRef);
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const [isPrint, setisPrint] = useState<boolean>(false);
  const size: number = 16;

  const handleChange = (value: string) => {};

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>();

  const disable = useSelector((state: RootState) => state.general.formDisabled);

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );

  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const updateMode2 = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const maintenanceRequestModal = {
    message: "Are you sure you want to open Job Card for this request",
    title: "Record Selected?",
    okText: "Yes, open",
    cancelText: "No, Cancel",
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(maintenanceRequestModal);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![1]?.id ?? 1,
    value: searchCriteriaData![1]?.value ?? "",
  });

  const [indeterminate, setIndeterminate] = useState<any>(true);
    const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>('');   
 const [isProgress, setIsProgress] = useState<boolean>(false)

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  const [togglerTrue, setTogglerTrue] = useState(false);

  const handleNew = () => {
    // if (paState !== undefined) {
    //   if (paState.selectedTask_update.length !== 0) {
    //     setIcon("question");
    //     setshowModalDisableButton(false);
    //     setModalData({
    //       message: "Are you sure you want to open Job Card for this request",
    //       title: "Record Selected? ",
    //       okText: "Yes, open",
    //     });
    //     setOpenModal(true);
    //     if (pasetState !== undefined) {
    //       pasetState("openSelectedTask", paState.selectedTask_update);
    //     }
    //     dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    //   } else {
    //     setIcon("warning");
    //     setshowModalDisableButton(true);
    //     setModalData({
    //       message: "Please select a Record",
    //       title: "Grid Record Selection Error",
    //       okText: "Ok",
    //     });
    //     setOpenModal(true);
    //   }

    //   setVisible && setVisible(true);
    // }

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    newButtonClick();
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));

    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  const handleUpdate = () => {
    if (selectedRecord["trpIDpk"] === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No record selected",
        title: "Invalid record",
        okText: "Ok",
      });
      setOpenModal(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
      dispatch(task_form_disable(false));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

      dispatch(task_footer_update(true));
    }
    handleEdit();
  };

  const basicStyles = `
        @media all {
          .page-break {
            display: flex;
            height: 100%;
            width: 100%
            
            
          }
        }
      
        @media print {
          html, body {
            height: 100%; 
            margin: 0 !important;
            padding: 0 !important;
            
           
          }
        }
      
        @media print {
          .page-break {
            margin-top: 1rem;
            display: block;
            break-inside: auto;
  
          }
          
        }
        
        .divider { 
           
            break-inside: auto;
        }
      
        @page {
          size: column;
          margin: 5mm;
        
          break-inside: auto;
          
          
          
        }
        @page { margin: ${0.5} ${0.5} ${0} ${0.5} !important; size: landscape; break-inside: auto;  }
      
        
      
      `;
  const PrintData = useReactToPrint({
    content: () => gridRef?.current,
    documentTitle: "Test Version",
    onAfterPrint: () => {
      dispatch(setGeneralValue({ expr: "isPrint", value: false }));
      alert("Printed Successfully jhjihbkhb");
    },
    pageStyle: basicStyles,
  });

  const newAccessPriv = () => {
    if (savePriv === true) {
      handleNew();
    } else {
      console.log(savePriv);
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const editAccessPriv = () => {
    if (updatePriv === true) {
      handleUpdate();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const findAccessPriv = () => {
    if (readPriv === true) {
      if (handleFind) {
        handleFind();
      } else {
      }
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const printAccessPriv = () => {
    if (readPriv === true) {
      PrintData();
      alert("worjign");
      dispatch(setGeneralValue({ expr: "isPrint", value: true }));
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  return (
    <div
      style={{
        borderBottomWidth: "1px",
        width: "4000px",
        borderColor: borderTheme,
      }}
      className="border-[1px] px-1 rounded h-full flex  border-gray-200 mb-1 mx-1"
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
                        .ant-btn.ant-btn-sm {
                            background-color: #fff;
                            padding: 5px 6px;
                            justify-content: center;
                        }

                        :where(.css-dev-only-do-not-override-1n7nwfa).ant-btn.ant-btn-sm{
                            height: 26px;
                        }
                    `,
        }}
      />
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        }}
        open={openModal}
        okHandler={() => {
          modalData === maintenanceRequestModal
            ? handleNew()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "warning" ? true : false}
      />
      <div className="flex justify-center">
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {/* new and cancel section */}
          {disable || updateMode || updateMode2 ? (
            <IconButton
              handleOnclick={newAccessPriv}
              imgSrc={newfile}
              tooltip="New Record"
              label="New"
            />
          ) : (
            <IconButton
              handleOnclick={handleCancel}
              imgSrc={close}
              tooltip="Cancel"
              label="Cancel"
            />
          )}
          {!(updateMode || updateMode2) ? (
            <IconButton
              handleOnclick={editAccessPriv}
              imgSrc={edit}
              tooltip="Edit Record"
              label="Edit"
            />
          ) : (
            <IconButton
              handleOnclick={handleCancel}
              imgSrc={close}
              tooltip="Cancel"
              label="Cancel"
            />
          )}
          <IconButton
            handleOnclick={toggler}
            imgSrc={alter}
            tooltip="Switch interface / table view"
            label="Switch"
          />
          <li id="show" className="px-0.5 mr-1">
            <Checkbox
              style={{ borderColor: borderTheme }}
              className="border px-1 rounded-sm"
              checked={isProgress}
              onChange={(checkValue: any) => {
                setIsProgress(checkValue.target.checked);
                handleToggle && handleToggle(checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#show"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Expand or wrap rows</p>
            </Tooltip>
          </li>

          <li id="secondCheck" className="px-0.5 mr-1">
            <Checkbox
              style={{ borderColor: borderTheme }}
              className="border px-1 rounded-sm"
              // checked={isProgress}
              onChange={(checkValue: any) => {
                // setIsProgress(checkValue.target.checked);
                // handleToggle && handleToggle(checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#secondCheck"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Toggle table lines</p>
            </Tooltip>
          </li>
          <span onClick={controlAssetDetail}>
            <IconsContainer
              noLabel
              url={screwhammer}
              id={generateUUID()}
              name="Asset or Equipment details"
              tooltip="Asset or Equipment details"
            />
          </span>

          <IconButton
            imgSrc={location}
            tooltip="Trouble locations"
            handleOnclick={toggleLocation}
          />
          <IconButton
            imgSrc={lock}
            tooltip="Lock trouble report"
            label="lock"
          />

          <IconButton
            imgSrc={checkmark}
            tooltip="Acknowledge trouble report"
            label="Ack"
          />

          <span
            id="preference"
            className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 mx-2"
          >
            <Switch
              className=" bg-gray-300 "
              onChange={(val) => {
                setTogglerTrue(val);
              }}
            />
            <Tooltip
              target="#preference"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">{`Switch between all and my issues`}</p>
            </Tooltip>
          </span>

          <li
            id="alposts"
            onClick={() => {}}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1    text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {togglerTrue ? "All Posts" : "My Posts"}
            </span>
          </li>

          {/* Differents starts here */}

          <li className="items-right ml-3 ">
            <label className=" text-xs px-2">Search Criteria</label>
            <Select
              value={criteriaDataLocal}
              dropdownMatchSelectWidth={false}
              className=" mt-0.5"
              onChange={(data: any) => {
                const criteriaData = JSON.parse(data);
                setCriterialDataLocal({
                  id: criteriaData?.index,
                  value: criteriaData?.criteria,
                });
                searchCriteriaOnchange &&
                  searchCriteriaOnchange(
                    criteriaData?.index,
                    criteriaData?.criteria
                  );
              }}
              id="search"
              size="small"
              showSearch={true}
              style={{
                width: `130px`,
                height: "25px",
              }}
            >
              {searchCriteriaData?.map(({ id, value }) => (
                <Select.Option
                  key={id}
                  value={JSON.stringify({ index: id, criteria: value })}
                >
                  {value}
                </Select.Option>
              ))}
            </Select>
          </li>

          <li className="px-1 flex items-center ml-2 ">
            <label className="flex text-xs px-2">Search Text</label>
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              name="search"
              id="search"
              size="small"
              allowClear={true}
              style={{
                width: "130px",
                height: "24px",
              }}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              showTime
              allowClear={false}
              size="small"
              placeholder="Start Date"
              style={{ width: 125 }}
              format={dateFormat}
              value={dayjs(startDate) ?? dayjs()}
              onChange={startDateChanged}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              showTime
              allowClear={false}
              size="small"
              placeholder="End Date"
              style={{ width: 125 }}
              format={dateFormat}
              value={dayjs(endDate) ?? dayjs()}
              onChange={endDateChanged}
            />
          </li>
          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="status"
            className="hover:cursor-pointer mr-1  text-xs  px-1  text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange(checkValue);
              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li>

          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            onClick={() => {
              handleRefresh && handleRefresh();
              setSearchValue("");

              setCheckedBox(false);
              setIndeterminate(true);
              setCheckValue("");
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs p-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                alt=""
                className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={refresh}
              />{" "}
            </span>
          </li>
          <li
            id="find"
            onClick={() => {
              findAccessPriv();
            }}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <FaBinoculars size={15.5} color="#007AFF" />{" "}
            </span>
            <Tooltip
              target="#find"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Find</p>
            </Tooltip>
          </li>

          <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
            {disable && (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}

            {!disable && (
              <div className="  flex flex-row items-center">
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                {!(updateMode || updateMode2) ? (
                  <p className=" italic text-gray-600">New Record</p>
                ) : (
                  <p className=" italic text-gray-600">Editing</p>
                )}
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};
