import { useCallback, useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import { VehicleConditionToolbar } from "./vehicleConditions-toolbar";
import CustomLoader from "../../../../../../accessories/custom-loader";
import { formModalStates, searchCriteriaTypes, searchTextTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { currentDate, startingDate } from "../../../../../../accessories/component_infos";
import { ReturnReport } from "./returnReport";
import { FormModal } from "../../../../../../accessories/formModal";
import VehicleConditionDetails from "./vehicleConditions-details";

type stateTypes = {
  openFormModal: boolean
}

const VehicleConditions = () => { 
  const [showTable, setShowTable] = useState(true)
  const [refresh, setRefresh] = useState<boolean>(false)

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);

  }
  const [states, setStates] = useState<stateTypes>({
    openFormModal: false
  })
  const { openFormModal } = states
  const updateStates = useCallback((key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }))
  }, [])
  const [startDate, setStartDate] = useState<string>(startingDate);
  const [endDate, setEndDate] = useState<string>(currentDate);
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [selectedTopDatax,setSelectedTopDatax]= useState<any>()
  const [getCurrentDate, setGetCurrentdate] = useState<any>()
  const [searchUrl, setSearchUrl] = useState(`AssetTamVehicleConditionReturn/PopGdvSearchAssetTamVehicleConditionReturn?PageSize=20&PageNumber=1&startDate=${startDate}&endDate=${endDate}&criteriaIndex=${searchCriteria.index}&search=${searchText.text}&active=${''}`)
  const [table_one_data, table_one_error, table_one_loading] = useFetch(searchUrl, refresh, 'vcrIdpk');

  const formModal: formModalStates[] = [
    { id: 0, label: 'Return Report', module: <ReturnReport /> },
  ]

  return (
    <div className="w-full h-full">
      <FormModal
        height={1000}
        width={"90%"}
        openState={openFormModal}
        onCancel={() => {
          updateStates("openFormModal", false);
        }}
        child={formModal[0].module}
        title={"Transport - Condition Returns Report"}
      />
      {table_one_loading && <CustomLoader text="Fetching Data" />}
      <div className="mx-2">
        <VehicleConditionToolbar
          getCurrentDate={getCurrentDate}
          toggler={switch_toggle}
          selectedRecord={selectedTopDatax}
          handleReport={() => {
            updateStates("openFormModal", true);
          }}
          handleGridRefresh={() => {
            setRefresh(!refresh);
          }}
          switchState={setShowTable}
          handleFind={(val:any) => { 
            setSearchUrl(val)
          }}
          handleRefresh={() =>{ 
            setSearchUrl(`AssetTamVehicleConditionReturn/PopGdvSearchAssetTamVehicleConditionReturn?startDate=${startDate}&endDate=${endDate}&criteriaIndex=0&search=${searchText.text}&active=${false}`)
            setRefresh(!refresh)}}
        />
      </div>
      <div className="px-2 mb-0.5">
        <VehicleConditionDetails
          setSelectedTopData={setSelectedTopDatax}
          toggled={showTable}
          table_one_data={table_one_data}
          refreshGrid={() => {
            setRefresh(!refresh);
          }}
          setCurrentDatex={setGetCurrentdate}
        />
      </div>
    </div>
  );
};

export default VehicleConditions;
