import { type ClassValue, clsx } from "clsx";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa";

export const HSeperator = ({
  status = false,
  onClick,
}: {
  status?: boolean;
  onClick?: () => void;
}) => {
  return (
    <section
      className={`w-full h-auto bg-sky-100 flex justify-center items-center text-neutral-500 cursor-pointer`}
      onClick={onClick}
    >
      {status ? (
        <FaChevronUp className="size-[10px]" />
      ) : (
        <FaChevronDown className="size-[10px]" />
      )}
    </section>
  );
};

export const VSeperator = ({
  status = false,
  height,
  onClick,
}: {
  status?: boolean;
  height?: number;
  onClick?: () => void;
}) => {
  return (
    <section
      className={`w-auto h-${
        height ? height : "full"
      } bg-sky-100 flex justify-center items-center text-neutral-500 cursor-pointer`}
      onClick={onClick}
    >
      {status ? (
        <FaChevronRight className="size-[10px]" />
      ) : (
        <FaChevronLeft className="size-[10px]" />
      )}
    </section>
  );
};

export function parseLower(str: string): string {
  if (str.length > 0 && typeof str === "string") {
    const lowercase = str.toLowerCase().trim();
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
  }
  return ""; // Add a return statement to handle the case when the condition is not met.
}
export default function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);

  return debouncedValue;
}
/**
 * Generate random string
 * @returns random uuid string
 */
export function generateUUID(): string {
  return `app${uuidv4().replaceAll(/-/g, "")}`;
}