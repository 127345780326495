/* eslint-disable react/jsx-pascal-case */

import Datagrid_template from "../../../../templates/Datagrid";
import { RefreshButton } from "../StationReadings/_widget/utils";
import laoder from "../../../../assets/statusloader.gif"
import { InputsTemplate } from "../../../../templates/input";
import { memo } from "react";

const columns = [
    { dataField: "stnShtName", caption: "Station", dataType: "string", fixed: true, width: 150 },
    { dataField: "sdtRecordsOnProductionServer", caption: "RecordOnProductionServer", dataType: "boolean", fixed: true, width: 150 },
    { dataField: "sdtRecordsOnLocalServer", caption: "RecordOnLocalServer", dataType: "boolean", fixed: false, width: 150 },
    { dataField: "sdtPercentlSynced", caption: "PercentSynced", dataType: "number", fixed: false, width: 150 },
]

function SyncStatus() {
    return (
        <section className="flex space-x-1">
            {/* <aside className="w-[62rem]"> */}
            <aside className="">
                <Datagrid_template
                    columns={columns}
                    data={[]}
                    disablePaging
                    disableGroupPanel
                    gridheight={270}
                    disableSearch
                    deselectFirstRow
                    allowColumnEditing={false}
                />
            </aside>
            <aside className="flex flex-col flex-none w-12 space-y-2 items-center bg-gray-100 mt-1 pt-0.5 rounded-sm px-0.5">
                <RefreshButton />
                <div className="size-10">
                    <img src={laoder} alt="loader" sizes="40" />
                </div>
                <InputsTemplate defaultValue={11} span={true} disabledStatus={false} />
            </aside>
        </section>
    )
}

export default memo(SyncStatus)