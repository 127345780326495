// import ProgressCell from "../widgets/barprogress"

export const Job_cols = [
    {
        id: 1,
        caption: 'JOB',
        dataField : 'mjdJob',
        dataType: 'string',
        width: null,
        fixed: true,
    },

    {
        id: 2,
        caption: 'DATE',
        dataField : 'mauDate',
        dataType: 'datetime',
        width: null,
        fixed: true,
    },
    {
        id: 3,
        caption: 'TASK UPDATE',
        dataField : 'mauActivityUpdate',
        dataType: 'string',
        width: 100,
        fixed: true,
    },
    {
        id: 4,
        caption: '%COMPLETE',
        dataField : 'mauPercentComplete',
        dataType: '',
        // format:"percent",
        // cellRender={ProgressCell},
        width: null,
        fixed: true,
    },
    {
        id: 5,
        caption: 'UPDATED BY',
        dataField : 'empName',
        dataType: 'string',
        width: null,
        fixed: true,
    },
    {
        id: 6,
        caption: 'JOB TITLE',
        dataField : 'jbtShtName',
        dataType: 'string',
        width: null,
        fixed: true,
    },
    {
        id: 7,
        caption: 'CREATED ON',
        dataField : 'mauCreationDate',
        dataType: 'datetime',
        width: 200,
        fixed: true,
    },
]

export const Job_ac = [
    {
        id: 1,
        caption: 'Job',
        dataField : 'mjdJob',
        dataType: 'string',
        width: null,
        fixed: true,
    },

    {
        id: 2,
        caption: 'Date',
        dataField : 'mjdDate',
        dataType: 'datetime',
        width: null,
        fixed: true,
    },
    {
        id: 3,
        caption: 'Description',
        dataField : 'mjdDescription',
        dataType: 'string',
        width: null,
        fixed: true,
    },
    {
        id: 4,
        caption: 'Remarks',
        dataField : 'mjdRmks',
        dataType: 'string',
        width: null,
        fixed: true,
    },
]

export const Materials_cols = [
    {
        id: 1,
        caption: 'Subm?',
        dataField : 'mamSubmitted',
        dataType: 'boolean',
        width: null,
        fixed: true,
    },

    {
        id: 2,
        caption: 'Prep?',
        dataType: 'boolean',
        dataField : 'mamPrepared',
       
        width: 60,
        fixed: true,
    },
    {
        id: 3,
        caption: 'Rec?',
        dataField : 'mamRecommended',
       dataType: 'boolean',
        width: 60,
        fixed: true,
    },
    {
        id: 4,
        caption: 'Auth?',
        dataField : 'mamAuthorised',
        dataType: 'boolean',
        width: 60,
        fixed: true,
    },
    {
        id: 5,
        caption: 'Date',
        dataField : 'mamDate',
        dataType: 'datetime',
        width: 100,
        fixed: true,
    },
    {
        id: 6,
        caption: 'Material',
        dataField : 'mtrName',
        dataType: '',
        width: null,
        fixed: false,
    },
    {
        id: 7,
        caption: 'Quantity',
        dataField : 'mamQuantity',
        dataType: '',
        width: null,
        fixed: false,
    },
    {
        id: 8,
        caption: 'Unit Price',
        dataField : 'mamUnitPrice',
        dataType: '',
        width: null,
        fixed: false,
    },
    {
        id: 9,
        caption: 'Vat',
        dataField : 'mamVatCharge',
        dataType: '',
        width: null,
        fixed: false,
    },
    {
        id: 10,
        caption: 'Total',
        dataField : 'mamTotal',
        dataType: '',
        width: null,
        fixed: false,
    },
    {
        id: 11,
        caption: 'Qty Unit',
        dataField : 'mamQuantityUnit',
        dataType: '',
        width: null,
        fixed: false,
    },
    {
        id: 12,
        caption: 'Currency',
        dataField : 'curSymbol',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 13,
        caption: 'Related Job',
        dataField : '',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 14,
        caption: 'Req',
        dataField : 'mamRequisitionNo',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 15,
        caption: 'Submitted By',
        dataField : 'submittedByEmpName5',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 16,
        caption: 'Submitted On',
        dataField : 'mamSubmittedOn',
        dataType: 'datetime',
        width: null,
        fixed: false,
    },
    {
        id: 17,
        caption: 'Prepared By',
        dataField : 'preparedByEmpName5',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 18,
        caption: 'Prepared On',
        dataField : 'mamPreparedOn',
        dataType: 'datetime',
        width: null,
        fixed: false,
    },
    {
        id: 19,
        caption: 'Recommended By',
        dataField : 'recommendedByEmpName5',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 20,
        caption: 'Recommended On',
        dataField : 'mamRecommendedOn',
        dataType: 'datetime',
        width: null,
        fixed: false,
    },
    {
        id: 21,
        caption: 'Authorised By',
        dataField : 'authorisedByEmpName5',
        dataType: 'string',
        width: null,
        fixed: false,
    },
    {
        id: 22,
        caption: 'Authorised On',
        dataField : 'mamAuthorisedOn',
        dataType: 'datetime',
        width: null,
        fixed: false,
    },
    {
        id: 23,
        caption: 'Remarks',
        dataField : 'mamRmks',
        dataType: 'string',
        width: 4000,
        fixed: false,
    },

]


export const req = [
    {   
        id:0,
            caption: "ACKN?",
            dataField:"mrqAcknowledged",
            fixed:true,
            width:80,           
            dataType:"boolean",
          },
          {
            id:1,
            caption: "SMSed?",
            dataField:"mrqSmsSent",
            dataType: "boolean",
            fixed:true,
            width:80,
            
          },
          {
            id:2,
            caption: "RQST No",
            dataField:"requestedByStaffNo",
            dataType:"string",
            fixed:false,
            width:100
            
          },
          {
            id:3,
            caption: "REQUEST DATE",
            dataField:"mrqCreationDate",
            dataType:"datetime",
            fixed:false,
            width:200
          },
          {
            id:4,
            caption: "REQ  No",
            dataField:"vdtRegNo",
            dataType:"",
            fixed:false,
            width:100
          },
          {
            id:5,
            caption: "REQUESTED BY",
            dataField:"requestedByLname",
            dataType:"string",
            fixed:false,
            width:100
          },
          {
            id:6,
            caption: "DEFECT REPORT",
            dataField:"mrqDefectRequest",
            dataType:"string",
            width:200 ,
            fixed:false,
                    
        },
          {
            id:7,
            caption: "WORKSHOP",
            dataField:"wsdName ",
            dataType:"",
            width:100,
            fixed:false,
          },
          {
            id:8,
            caption: "REPORTING DETAIL",
            dataField:"mrqReviewReport",
            dataType:"string",
            width:200,
            fixed:false,
          },
          {
            id:9,
            caption: "ACKNOWLEDGEMET",
            dataField:"COMPLETION DATE",
            dataType:"string",
            width:100,
            fixed:false,
          },
          {
            id:10,
            caption: "REJECT",
            dataField:"COMPLETION DATE",
            dataType:"string",
            width:200,
            fixed:false,
          },
          {
            id:11,
            caption: "VEH. CLASS ",
            dataField:"vhcName",
            dataType:"string",
            width:100,
            fixed:false,
          },
          {
            id:12,
            caption: "VEH. MAKE",
            dataField:"vmkName",
            dataType:"string",
            width:200,
             fixed:false,
          },
          {
            id:13,
            caption: "VEH. MODEL",
            dataField:"mdlName",
            dataType:"string",
            width:200,
            fixed:false,
          },
        
          {
            id:14,
            caption:'REMARKS',
            dataField:"mrqRmks",
            dataType:"string",
            width:400,
            fixed:false,
          }

]
