import { Badge, BadgeProps, Calendar, CalendarProps, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { CalendarManagementToolbar } from "../calendarmangtoolbar";

interface props {
  getListData?: (e: Dayjs) => { type: string; content: string }[];
  calendarStyles?: any;
  classname?: string;
  events?: any;
}

export const Calender = ({
  getListData,
  calendarStyles,
  classname,
  events,
}: props) => {
  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };
  const wrapperStyle: React.CSSProperties = {
    width: 400,
  };

  console.log(events);
  const dateCellRender = (value: Dayjs) => {
    const listData = getListData && getListData(value);
    return (
      <ul className="events w-full h-full bg-gray-500">
        {listData?.map((item: any) => (
          <li key={item.content} className="bg-green-300">
            <Badge
              status={item.type as BadgeProps["status"]}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current: Dayjs) => {
    return dateCellRender(current);
  };
  const fullMonthRender: CalendarProps<Dayjs>["monthFullCellRender"] = (
    current: Dayjs
  ) => {
    return dateCellRender(current);
  };
  const getEventMessage = (date: Dayjs) => {
    const event = events?.map((event: any) => dayjs(event).isSame(date, "day"));
    console.log(event);
    return event ? event.message : "";
  };
  const fullCellRender = (date: Dayjs) => {
    const isToday = dayjs().isSame(date, "day");
    const message = getEventMessage(date);
    return (
      <div
        className={`custom-cell h-[8rem]  ${isToday ? "bg-blue-300" : ""}`}
        style={{ padding: "5px", border: "1px solid #F0F0F0" }}
      >
        <div className="date-number font-bold">{date.date()}</div>
        <div className="custom-content py-5">{message}</div>
      </div>
    );
  };
  return (
    <>
      <div className="w-full p-2 ">
        <div>
          <CalendarManagementToolbar />
        </div>
        <div className="flex w-full">
          <aside className="w-[70%] flex-col justify-center">
            <Typography.Title
              className="absolute text-sm text-[#013D84] font-semibold pl-10 flex justify-center items-center py-5"
              style={{ color: "#013D84" }}
              level={3}
            >
              {dayjs().format("MMMM YYYY")}
            </Typography.Title>

            <Calendar
              fullscreen={true}
              mode="month"
              style={{ ...calendarStyles }}
              className={`${classname} uppercase  text-slate-300 px-5 py-1`}
              fullCellRender={fullCellRender}
            />
          </aside>

          <aside className="w-[30%] mt-10 space-y-[72px] broder border-2 ">
            <div style={wrapperStyle}>
              <Calendar fullscreen={false} onPanelChange={onPanelChange} />
            </div>
            <div style={wrapperStyle}>
              <Calendar fullscreen={false} onPanelChange={onPanelChange} />
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};
