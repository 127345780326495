import { TroubleReportContextProvider } from "./repo";
import { TroubleReports } from "./troubleReport";

export default function TroubleReportWrapper() {
  return (
    <TroubleReportContextProvider>
      <TroubleReports />
    </TroubleReportContextProvider>
  );
}
