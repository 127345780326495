import React, { useRef, useState } from "react";
import { EntryCustomToolbar } from "../../accessories/custom-toolbars/entryCustomtoolbar";
import { JobAnalysis } from "./jobAnalysis";
import Datagrid_template from "../../../templates/Datagrid";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import useResizeMode from "../../../hooks/useResizeMode";
import { navbar_height } from "../../accessories/component_infos";
import { grid_cols } from "./data/mainGridCols";

export const JobMain = () => {
  const dispatch = useDispatch();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 35);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 450);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };
  return (
    <div>
      <div>
        <EntryCustomToolbar
          toggler={switch_toggle}
          setSwitchToggle={setSwitch_change}
        />
      </div>
      <div>{switch_change && <JobAnalysis />}</div>
      <div
        style={{ height: "" }}
        className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Datagrid_template
          gridheight={switch_change === true ? pageHeight : fullPage}
          columns={grid_cols}
          data={[]}
        />
      </div>
    </div>
  );
};
