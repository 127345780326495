

export const reqWind_cols = [
  {id: 0,caption : "Request Window", dataField : 'ftwName', dataType : "string", fixed : false,width:150},
  {id: 1,caption : "StartDate", dataField : 'ftwStartDate', dataType : "datetime", fixed : false,width:150},
  {id: 2,caption : "EndDate", dataField : 'ftwEndDate', dataType : "datetime", fixed : false,width:150},
]

export const reqBy_cols = [
  {id: 0,caption : "Staff No", dataField : 'empStaffNo', dataType : "", fixed : false,width:80},
  {id: 1,caption : "Name", dataField : 'empName', dataType : "", fixed : false,width:120},
  {id: 2,caption : "Dept", dataField : 'dptShtName', dataType : "", fixed : false,width:80},
  {id: 3,caption : "Section", dataField : 'sxnShtName', dataType : "", fixed : false,width:80},
  {id: 4,caption : "Job Title", dataField : 'jbtShtName', dataType : "", fixed : false,width:90},
  {id: 5,caption : "Location", dataField : 'locShtName', dataType : "", fixed : false,width:80},
]
export const recomBy_cols = [
  {id: 0,caption : "Staff No", dataField : 'empStaffNo', dataType : "", fixed : false,width:80},
  {id: 1,caption : "Name", dataField : 'empName', dataType : "", fixed : false,width:120},
  {id: 2,caption : "Dept", dataField : 'dptShtName', dataType : "", fixed : false,width:80},
  {id: 3,caption : "Section", dataField : 'sxnShtName', dataType : "", fixed : false,width:80},
  {id: 4,caption : "Job Title", dataField : 'jbtShtName', dataType : "", fixed : false,width:90},
  {id: 5,caption : "Location", dataField : 'locShtName', dataType : "", fixed : false,width:80},
]

export const footer_datagrid_cols = [
  {id: 1,caption : "ID", dataField : 'ftrIDpk', dataType : "", fixed : true,width:80},
  {id: 2,caption : "REC.", dataField : 'ftrRecommended', dataType : "boolean", fixed : true},
  {id: 3,caption : "APRVD?", dataField : 'ftrApproved', dataType : "boolean", fixed : true},
  {id: 4,caption : "CAN.?", dataField : 'ftrCancelled', dataType : "boolean", fixed : true, width:70
},
  {id: 5,caption : "ACTIVE?", dataField : 'ftrActive', dataType : "boolean", fixed : true},
  {id: 6,caption : "SMS SENT", dataField : 'ftrSmsSent', dataType : "boolean", fixed : true, width:150},
  {id: 7,caption : "REQUESTED BY", dataField : 'requesterEmpName', dataType : "string",width:100},
  {id: 8,caption : "RECOMMENDED BY", dataField : 'recommendedByEmpName', dataType : "string",width:100},
  {id: 9,caption : "REQUESTED ON", dataField : 'ftrCreationDate', dataType : "datetime",width:100},
  {id: 10,caption : "REQUEST TYPE", dataField : 'fttName', dataType : "",width:100},
  {id: 11,caption : "REG. No", dataField : 'vdtRegNo', dataType : "",width:100},
  {id: 12,caption : "QTY REQ.", dataField : 'ftrQuantityRequested', dataType : "",width:85},
  {id: 13,caption : "QTY APPROVED", dataField : 'ftrQuantityApproved', dataType : "",width:100},
  {id: 14,caption : "QTY TOPPED UP", dataField : 'ftrQuantityToppedUp', dataType : "",width:100},
  {id: 15,caption : "PURPOSE", dataField : 'ftrPurpose', dataType : "",width:100},
  {id: 16,caption : "TOP UP TIME", dataField : 'cctName', dataType : "string",width:100},
  {id: 17,caption : "APPROVED BY", dataField : 'approverEmpName5', dataType : "",width:100},
  {id: 18,caption : "DEBIT CAT.", dataField : 'ftrDebitCategory', dataType : "",width:100},
  {id: 19,caption : "DEBIT", dataField : 'ftrCostCenter', dataType : "",width:100},
  {id: 20,caption : "APPROVAL REMARKS", dataField : 'ftrApprovalRmks', dataType : "",width:100},
  {id: 21,caption : "REQ.DEPT", dataField : 'requesterDeptName', dataType : "string",width:100},
  {id: 22,caption : "REQ. SECTION", dataField : 'recommendedBySxnShtName', dataType : "string",width:100},
  {id: 23,caption : "APPROVEC ON", dataField : 'vehAssignDeptShtName', dataType : "string",width:100},
  {id: 24,caption : "REQUESTED ON", dataField : 'ftrCreationDate', dataType : "datetime",width:100},
  {id: 25,caption : "CANCELLATION REASON", dataField : 'ftrCancellationRmks', dataType : "string",width:150},
  {id: 26,caption : "REMARKS", dataField : 'ftrRmks', dataType : "string",width:4000},

]