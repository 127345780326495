/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { InputsTemplate } from "../../../../../../../templates/input";
import { SaveButton } from "../../../../../../accessories/buttons";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  currentDate,
  postError,
  serverError,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { FaBinoculars } from "react-icons/fa";
import {
  modalPropTypes,
  searchTextTypes,
  selectObjectTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs, { Dayjs } from "dayjs";
import { staffsColumns, stationsColumns } from "../data/elogBookDataPriv";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { GetDataFunc } from "../../../../../../../functions/get";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { Switch } from "devextreme-react";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { row } from "@syncfusion/ej2-react-grids";
import dxDataGrid from "devextreme/ui/data_grid";
import { ResponseType } from "../../../troubleReport/troubleReport";

interface elogBookPriviledgeTypes {
  updateMode?: boolean;
  refreshEverything?: boolean;
  refresh?: () => void;
  staffDatagrid?: (fetchDone: boolean) => void;
}
interface stateTypes {
  remark: string;
  manager: boolean;
  view: boolean;
  authorize: boolean;
  active: boolean;
  //form state
  disableForm: boolean;
  modalState: modalPropTypes;
  confirmState: modalPropTypes;
  successModal: modalPropTypes;
  fuelDumpData: any;

  staffSearch?: searchTextTypes;
  workshopSearch?: searchTextTypes;
  selectedStaffs: any[];
  stations: any[];
  assignedWorkshops: any[];
  refreshAssignedWorkshops: boolean;
}

export const ElogBookForm = React.forwardRef(
  (
    {
      updateMode,
      refreshEverything,
      staffDatagrid,
      refresh,
    }: elogBookPriviledgeTypes,
    _ref
  ) => {
    const [Assign_And_UnAssignStation, setAssign_And_UnAssignStation] =
      useState<any[]>([]);
    const [Assign_And_UnAssignGroup, setAssign_And_UnAssignGroup] = useState<
      any[]
    >([]);
    const [userId] = useAccountInfo();
    const [busyLoader, setBusyLoader] = useBusyLoader();
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
    const dataGridRef = useRef(null);
    const secondDataGridRef = useRef<dxDataGrid>();
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const [messageModal, setMessageModal] = useState<{
      icon: "warning" | "question" | "success";
      title: string;
      message: string;
      okText: string;
      cancelText: string;
      okHandler: () => void;
      cancelHandler: () => void;
      openModal: boolean;
      hideCancel: boolean;
    }>({
      icon: "warning",
      title: "",
      message: "",
      okText: "",
      cancelText: "",
      okHandler: () => {},
      cancelHandler: () => {},
      openModal: false,
      hideCancel: false,
    });

    const [addedSelected, setAddSelected] = useState<any>({});
    // const [selectedUser, setSelectedUser] = useState<>()
    const [CreatePriviledge, UpdatePriviledge] = useCrudFunc("powerapp");
    const handleCellRender = useCallback(
      (e: DataGridTypes.RowPreparedEvent<any, any>) => {
        if (e.rowType === "data") {
          e.rowElement.style.backgroundColor = "";
          const rowData = e.data;
          // const green =
          //   rowData.pvaShiftLeader ||
          //   rowData.pvaLog ||
          //   rowData.pvaView ||
          //   rowData.pvaHome ||
          //   rowData.pvaDefaultHome ||
          //   rowData.pvaShiftMember ||
          //   rowData.pvaFlag ||
          //   rowData.pvaCaptureStationReadings ||
          //   rowData.pvaInitiateRemoteShiftChange ||
          //   rowData.pvaTakeOverShift ||
          //   rowData.pvaManager ||
          //   rowData.pvaDirector ||
          //   rowData.pvaSupervisor;
          if (rowData?.pvaIDpk) {
            e.rowElement.style.backgroundColor = "#86efac";
          } else {
            e.rowElement.style.backgroundColor = "#fcc74a";
          }
        }
      },
      []
    );
    const [checkedStaff, setCheckedStaff] = useState<any[]>([]);
    const [deselectRecordFromStaff, setDeselectRecordFromStaff] =
      useState(false);
    console.log("addedSelected", addedSelected);
    console.log("checkedStaff", checkedStaff);
    const [staffSearchParams, setStaffSearchParams] = useState({
      isStaffON: false,
      searchStaff: { initialValue: "", value: "" },
      searchGroupStation: "",
    });
    const [stationSearchParams, setStationSearchParams] = useState({
      isStationON: false,
      searchStation: { initialValue: "", value: "" },
      searchGroupStation: "",
    });

    const [assignRefresh, setAssignRefresh] = useState(false);
    const [refreshStaff, setRefreshStaff] = useState(false);

    const [getAllEmps, getAllEmpsErr, getAllEmpsLoading] = useFetch(
      `ElogBookPriviledgesAssignment/GetAllEmplyees?search=${
        staffSearchParams.isStaffON === true
          ? staffSearchParams.searchGroupStation
          : staffSearchParams.searchStaff.value
      }&staffIsOn=${staffSearchParams.isStaffON}`,
      refreshStaff,
      "empIDpk",
      false,
      "powerapp"
    );

    //------------- Get assigned and unAssigned station
    const [
      getAllAssignedContactSync,
      getAllAssignedContactSyncErr,
      getAllAssignedContactSyncLoading,
    ] = useFetch(
      `ElogBookPriviledgesAssignment/GetAssignContactsAsync?empIdpk=${
        addedSelected?.empIDpk
      }&stationName=${
        stationSearchParams.isStationON === true
          ? stationSearchParams.searchGroupStation
          : stationSearchParams.searchStation.value
      }`,
      assignRefresh,
      "pvaIDpk",
      false,
      "powerapp"
    );

    // const {
    //   data: GetAssignContactsAsync,
    //   isFetched: isGetAssignContactsAsyncFetched,
    //   refetch: refetchGetAssignContactsAsync
    // } = useFetchClient<ResponseType<StationPermissions>>({
    //   app: `powerapp`,
    //   url: `ElogBookPriviledgesAssignment/GetAssignContactsAsync`,
    //   params: {
    //     empIdpk: addedSelected?.empIDpk,
    //     stationName: stationSearchParams.isStationON === true
    //     ? stationSearchParams.searchGroupStation
    //     : stationSearchParams.searchStation.value
    //   }
    // })
    // const [
    //   getAllUnAssignedContactSync,
    //   getAllUnAssignedContactSyncErr,
    //   getAllUnAssignedContactSyncLoading,
    // ] = useFetch(
    //   `ElogBookPriviledgesAssignment/GetUnAssignStationAsync?empIdpk=${
    //     addedSelected?.empIDpk
    //   }&stationName=${
    //     stationSearchParams.isStationON === true
    //       ? stationSearchParams.searchGroupStation
    //       : stationSearchParams.searchStation.value
    //   }`,
    //   unAssignRefresh,
    //   "stnIDpk",
    //   false,
    //   "powerapp"
    // );
    // ------------------------------------------------

    const [getStationGroups, getStationGroupsErr, getStationGroupsLoading] =
      useFetch(
        `ElogBookPriviledgesAssignment/GetStationGroups`,
        staffSearchParams.isStaffON,
        "",
        false,
        "powerapp"
      );
    const [assignPrivStationGroupRefresh, setAssignPrivStationGroupRefresh] =
      useState(false);
    //---------- Get station Group ---------------
    const [
      getAllAssignedPrivByStationGroup,
      getAllAssignedPrivByStationGroupError,
      getAllAssignedPrivByStationGroupLoading,
    ] = useFetch(
      `ElogBookPriviledgesAssignment/GetAssignedPrivilegesByStationGroup?StationName=${stationSearchParams.searchStation.value}&StationGroupName=${stationSearchParams.searchGroupStation}&EmpIDpk=${addedSelected?.empIDpk}`,
      assignPrivStationGroupRefresh,
      "",
      false,
      "powerapp"
    );
    //--------------------------------------------
    const [unAssignStationGroup, setUnAssignStationGroup] = useState<any[]>([]); // Initialize as an empty array
    const [unAssignStation, setUnAssignStation] = useState<any[]>([]);
    //----------- fetch UnAssignedStation Groups ---------------
    const fetchUnAssignedStationGroups = async () => {
      // if (getAllAssignedPrivByStationGroup.length > 0) {
      let listID = "0";
      getAllAssignedPrivByStationGroup.forEach((item) => {
        listID += `,${item.stnIDpk}`;
      });
      try {
        const unAssignedStationGroup = await GetDataFunc(
          `ElogBookPriviledgesAssignment/GetUnAssignedPrivilegesByStationGroup?sgpname=${stationSearchParams.searchGroupStation}&fountStationIDs=${listID}`,
          {},
          "powerapp"
        );
        setUnAssignStationGroup(unAssignedStationGroup?.data?.data || []);
      } catch (error) {
        console.error("Error fetching unassigned station groups:", error);
      }
      // }
    };
    //------------------------------------------------------------

    const fetchUnAssignedStation = async () => {
      let listID = "0";
      getAllAssignedContactSync.forEach((item) => {
        listID += `,${item.stnIDpk}`;
      });
      try {
        const unAssignedStation = await GetDataFunc(
          `ElogBookPriviledgesAssignment/GetUnAssignStationAsync?empIdpk=${
            addedSelected?.empIDpk
          }&stationName=${
            stationSearchParams.isStationON === true
              ? stationSearchParams.searchGroupStation
              : stationSearchParams.searchStation.value
          }&fountStationIDs=${listID}`,
          {},
          "powerapp"
        );
        setUnAssignStation(unAssignedStation?.data?.data || []);
      } catch (error) {
        console.error("Error fetching unassigned station groups:", error);
      }
    };
    const [editedRows, setEditedRows] = useState<any[]>([]);
    const handleStaffOptionChange = (option: any) => {
      const isNotStaff = option?.element?.ariaLabel;
      setStaffSearchParams((prev: any) => ({
        ...prev,
        isStaffON: isNotStaff == "Staff" ? false : true,
      }));
    };

    const handleSearchStaff = (value: any) => {
      setStaffSearchParams((prev: any) => ({
        ...prev,
        searchStaff: {
          ...staffSearchParams.searchStaff,
          initialValue: value,
        },
      }));
    };
    const handleSearchGroupStation = (value: any) => {
      setStaffSearchParams((prev: any) => ({
        ...prev,
        searchGroupStation: value,
      }));
    };
    const handleStaffFind = () => {
      setStaffSearchParams((prev: any) => ({
        ...prev,
        isStaffON: staffSearchParams.isStaffON,
        searchStaff: {
          ...staffSearchParams.searchStaff,
          value: staffSearchParams.searchStaff.initialValue,
        },
      }));
    };
    const handleStationFind = () => {
      setStationSearchParams((prev: any) => ({
        ...prev,
        isStationON: stationSearchParams.isStationON,
        searchStation: {
          ...stationSearchParams.searchStation,
          value: stationSearchParams.searchStation.initialValue,
        },
      }));
    };
    const handleSearchStation = (value: any) => {
      setStationSearchParams((prev: any) => ({
        ...prev,
        searchStation: {
          ...stationSearchParams.searchStation,
          initialValue: value,
        },
      }));
    };
    const handleSearchGroupStation2 = (value: any) => {
      setStationSearchParams((prev: any) => ({
        ...prev,
        searchGroupStation: value,
      }));
    };
    const handleStationOptionchange = (option: any) => {
      const isNotStation = option?.element?.ariaLabel;
      setStationSearchParams((prev: any) => ({
        ...prev,
        isStationON: isNotStation == "Station" ? false : true,
      }));
    };
    const handleRowUpdating = useCallback((e: any) => {
      setEditedRows((prevEditedRows) => {
        let updatedRecord = { ...e.oldData, ...e.newData };

        const existingIndex = prevEditedRows.findIndex(
          (row) => row.stnIDpk === updatedRecord.stnIDpk
        );
        if (existingIndex > -1) {
          const updatedRows = [...prevEditedRows];
          updatedRows[existingIndex] = updatedRecord;
          return updatedRows;
        } else {
          return [...prevEditedRows, updatedRecord];
        }
      });
    }, []);
    console.log("editedRows", editedRows);
    console.log("checkeStaff", checkedStaff);
    function hasTrueValueInObjects(dataArray: any[]) {
      // for (let data of dataArray) {
      //   // Check the boolean properties of the current object
      //   const keys = Object.keys(data);
      //   for (let key of keys) {
      //     if (typeof data[key] === "boolean" && data[key] === true) {
      //       return true;
      //     }
      //   }
      // }

      return dataArray.filter((e: any) => {
        const keys = Object.keys(e);
        console.log("keys", keys);
        for (let key of keys) {
          console.log("value", e[key]);
          if (typeof e[key] === "boolean" && e[key] === true) {
            return true;
          }
        }
        return false;
      });

      // If no true values found in any object, return false
      // return false;
    }
    const sendAndReturnMessage = async (editedRows) => {
      setBusyLoader("Saving record...");
      try {
        for (let x = 0; x < checkedStaff.length; x++) {
          for (let i = 0; i < editedRows.length; i++) {
            await CreatePriviledge(
              `ElogBookPriviledgesAssignment/AssignPrivilege`,
              {
                pvaEmpIDfk: checkedStaff[x]?.empIDpk,
                pvaStationIDfk: editedRows[i]?.stnIDpk,
                pvaView: editedRows[i]?.pvaView ?? false,
                pvaExport: editedRows[i]?.pvaExport ?? false,
                pvaPreviewLogs: editedRows[i]?.pvaPreviewLogs ?? false,
                pvaCaptureWaterLevels:
                  editedRows[i]?.pvaCaptureWaterLevels ?? false,
                pvaViewWaterLevels: editedRows[i]?.pvaViewWaterLevels ?? false,
                pvaAnalyseWaterLevels:
                  editedRows[i]?.pvaAnalyseWaterLevels ?? false,
                pvaSeeAllLogs: editedRows[i]?.pvaSeeAllLogs ?? false,
                pvaSeeDashboard: editedRows[i]?.pvaSeeDashboard ?? false,
                pvaHome: editedRows[i]?.pvaHome ?? false,
                pvaDefaultHome: editedRows[i]?.pvaDefaultHome ?? false,
                pvaLog: editedRows[i]?.pvaLog ?? false,
                pvaShiftLeader: editedRows[i]?.pvaShiftLeader ?? false,
                pvaShiftMember: editedRows[i]?.pvaShiftMember ?? false,
                pvaTakeOverShift: editedRows[i]?.pvaTakeOverShift ?? false,
                pvaInitiateRemoteShiftChange:
                  editedRows[i]?.pvaInitiateRemoteShiftChange ?? false,
                pvaFlag: editedRows[i]?.pvaFlag ?? false,
                pvaCaptureStationReadings:
                  editedRows[i]?.pvaCaptureStationReadings ?? false,
                pvaCaptureBacklogData:
                  editedRows[i]?.pvaCaptureBacklogData ?? false,
                pvaManager: editedRows[i]?.pvaManager ?? false,
                pvaSupervisor: editedRows[i]?.pvaSupervisor ?? false,
                pvaDirector: editedRows[i]?.pvaDirector ?? false,
                pvaStartDate:
                  dayjs(editedRows[i]?.pvaStartDate).format("YYYY-MM-DD") ??
                  dayjs("01 Jan 1900 00:00").format("YYYY-MM-DD"),
                pvaEndDate:
                  dayjs(editedRows[i]?.pvaEndDate).format("YYYY-MM-DD") ??
                  dayjs("31 Dec 2100 23:59").format("YYYY-MM-DD"),
                pvaRmks: editedRows[i]?.pvaRmks ?? "",
              },
              `saved these records with employee id:${addedSelected?.empIDpk}, station id: ${editedRows[i]?.stnIDpk}, station name:${editedRows[i]?.stnName} view:${editedRows[i]?.pvaView}, shift leader:${editedRows[i]?.pvaShiftLeader}, shift member:${editedRows[i]?.pvaShiftMember} etc.`
            );
          }
        }
        fetchUnAssignedStation();
        setAssignRefresh(!assignRefresh);
        setAssignPrivStationGroupRefresh(!assignPrivStationGroupRefresh);
        fetchUnAssignedStationGroups();
        setEditedRows([]);
        refresh && refresh();
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "success",
          title: "Saved!",
          message: "Record Saved Successfully.",
          okText: "Okay",
          openModal: true,
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({
              ...prev,
              openModal: false,
            }));
          },
        }));
        setBusyLoader("");
      } catch {
        setBusyLoader("");
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: "Record not saved",
          message: "Please contact the systems administrator",
          okText: "Okay",
          openModal: true,
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({
              ...prev,
              openModal: false,
            }));
          },
        }));
      }
    };

    const handleSave = async () => {
      if (savePriv === true) {
        if (checkedStaff.length !== 0) {
          setMessageModal((prev: any) => ({
            ...prev,
            icon: "question",
            title: "Save ?",
            message: "Are you sure you want to save this record?",
            okText: "Yes",
            cancelText: "No",
            hideCancel: false,
            openModal: true,
            okHandler: async () => {
              let rows = hasTrueValueInObjects(editedRows);
              if (rows.length > 0) {
                await sendAndReturnMessage(rows);
              } else {
                setBusyLoader("Saving record...");
                fetchUnAssignedStation();
                setAssignRefresh(!assignRefresh);
                setAssignPrivStationGroupRefresh(
                  !assignPrivStationGroupRefresh
                );
                fetchUnAssignedStationGroups();
                setEditedRows([]);
                refresh && refresh();
                setMessageModal((prev: any) => ({
                  ...prev,
                  icon: "success",
                  title: "Saved!",
                  message: "Record Saved Successfully.",
                  okText: "Okay",
                  openModal: true,
                  hideCancel: true,
                  okHandler: () => {
                    setMessageModal((prev: any) => ({
                      ...prev,
                      openModal: false,
                    }));
                  },
                }));
                setBusyLoader("");
              }
            },
            cancelHandler: async () => {
              setMessageModal((prev: any) => ({
                ...prev,
                openModal: false,
              }));
            },
          }));
        } else {
          setMessageModal((prev: any) => ({
            ...prev,
            icon: "warning",
            title: "No staff selected",
            message: "No one has been selected for the priviledge assignments",
            okText: "Okay",
            openModal: true,
            hideCancel: true,
            okHandler: () => {
              setMessageModal((prev: any) => ({
                ...prev,
                openModal: false,
              }));
            },
          }));
        }
      } else {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: "Access denied!",
          message: "You do not have the privilege to create new records",
          okText: "okay",
          hideCancel: true,
          openModal: true,
          okHandler: async () => {
            setMessageModal((prev: any) => ({
              ...prev,
              openModal: false,
            }));
          },
        }));
      }
    };
    // const customColumns = [
    //   {
    //     id: 0,
    //     caption: "Shift Ldr",
    //     dataField: "pvaShiftLeader",
    //     dataType: "boolean",
    //     width: 90,
    //     fixed: false,
    //     visible: true,
    //   },
    //   {
    //     id: 1,
    //     caption: "Log",
    //     dataField: "pvaLog",
    //     dataType: "boolean",
    //     width: 90,
    //     fixed: false,
    //     visible: true,
    //   },
    //   {
    //     id: 2,
    //     caption: "View",
    //     dataField: "pvaView",
    //     dataType: "boolean",
    //     width: 90,
    //     fixed: false,
    //     visible: true,
    //   },
    // ];
    const [refreshStations, setRefreshStation] = useState(false);
    const [refreshGroup, setRefreshGroup] = useState(false);
    const [refreshUnAssignedGroup, setRefreshUnAssignedGroup] = useState(false);

    //---------------- adding properties to the assigned and unAssigned station then setting it to the setAssign_and_UnAssignStation state ;
    const Stations = () => {
      let allAssignedContactSync = getAllAssignedContactSync?.map((e) => ({
        ...e,
        pvaStartDate: dayjs(e.pvaStartDate).format("DD MMM YYYY"),
        pvaEndDate: dayjs(e.pvaEndDate).format("DD MMM YYYY"),
      }));
      let allUnAssignContactSync = unAssignStation?.map((e) => ({
        ...e,
        pvaIDpk: 0,
        pvaLog: false,
        pvaHome: false,
        pvaDefaultHome: false,
        pvaView: false,
        pvaExport: false,
        pvaPreviewLogs: false,
        pvaCaptureWaterLevels: false,
        pvaViewWaterLevels: false,
        pvaAnalyseWaterLevels: false,
        pvaSeeAllLogs: false,
        pvaSeeDashboard: false,
        pvaTakeOverShift: false,
        pvaInitiateRemoteShiftChange: false,
        pvaShiftMember: false,
        pvaFlag: false,
        pvaCaptureStationReadings: false,
        pvaCaptureBacklogData: false,
        pvaSupervisor: false,
        pvaShiftLeader: false,
        pvaManager: false,
        pvaDirector: false,
        pvaStartDate: dayjs("01 Jan 1900 00:00").format("DD MMM YYYY"),
        pvaEndDate: dayjs("31 Dec 2100 23:59").format("DD MMM YYYY"),
      }));
      setAssign_And_UnAssignStation([
        ...allAssignedContactSync,
        ...allUnAssignContactSync,
      ]);
    };
    // ----------------------------------------------
    //---------------- adding properties to the assigned and unAssigned group stations then setting it to the setAssign_And_UnAssignGroup state ;
    const GroupStation = () => {
      let allAssignPrivByStationGroup = getAllAssignedPrivByStationGroup?.map(
        (e) => ({
          ...e,
          pvaStartDate: dayjs(e.pvaStartDate).format("DD MMM YYYY"),
          pvaEndDate: dayjs("31 Dec 2100 23:59").format("DD MMM YYYY"),
        })
      );
      let allUnAssignPrivByStationGroup = unAssignStationGroup?.map((e) => ({
        ...e,
        pvaLog: false,
        pvaHome: false,
        pvaDefaultHome: false,
        pvaView: false,
        pvaExport: false,
        pvaPreviewLogs: false,
        pvaCaptureWaterLevels: false,
        pvaViewWaterLevels: false,
        pvaAnalyseWaterLevels: false,
        pvaSeeAllLogs: false,
        pvaSeeDashboard: false,
        pvaTakeOverShift: false,
        pvaInitiateRemoteShiftChange: false,
        pvaShiftMember: false,
        pvaFlag: false,
        pvaCaptureStationReadings: false,
        pvaCaptureBacklogData: false,
        pvaSupervisor: false,
        pvaShiftLeader: false,
        pvaManager: false,
        pvaDirector: false,
        pvaStartDate: dayjs("01 Jan 1900 00:00").format("DD MMM YYYY"),
        pvaEndDate: dayjs("31 Dec 2100 23:59").format("DD MMM YYYY"),
      }));
      setAssign_And_UnAssignGroup([
        ...allAssignPrivByStationGroup,
        ...allUnAssignPrivByStationGroup,
      ]);
    };

    function RefreshStation_StationGroupAndEditedRows() {
      fetchUnAssignedStation();
      setAssignRefresh(!assignRefresh);
      setAssignPrivStationGroupRefresh(!assignPrivStationGroupRefresh);
      fetchUnAssignedStationGroups();
      setEditedRows([]);
      refresh && refresh();
    }
    useMemo(() => {
      fetchUnAssignedStation();
    }, [getAllAssignedContactSync]);
    useMemo(() => {
      Stations();
    }, [getAllAssignedContactSync, unAssignStation, refreshStations]);

    useMemo(() => {
      fetchUnAssignedStationGroups();
    }, [getAllAssignedPrivByStationGroup]);
    useMemo(() => {
      GroupStation();
    }, [getAllAssignedPrivByStationGroup, unAssignStationGroup, refreshGroup]);
    useMemo(() => {
      RefreshStation_StationGroupAndEditedRows();
    }, [refreshEverything]);
    return (
      <div className="px-2 ">
        <ModalTemplate
          icon={messageModal.icon}
          title={messageModal.title}
          okText={messageModal.okText}
          message={messageModal.message}
          open={messageModal.openModal}
          okHandler={messageModal.okHandler}
          cancelHandler={messageModal.cancelHandler}
          disableCancel={messageModal.hideCancel}
        />
        <p
          id="form_header"
          style={{ borderColor: borderTheme }}
          className="py-1 px-2 w-full border-[1px] border-b-0 rounded bg-slate-100"
        >
          <span className="font-medium">
            {"Elog Book Privileges Assignment"}
          </span>
        </p>
        <Form
          style={{ borderColor: borderTheme }}
          className="w-full rounded flex justify-between px-2 py-1 border-[1px] border-t-[0px] border-slate-100"
          name="basic"
          wrapperCol={{ span: 25 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          <section style={{ width: "calc(40% - 10px" }} className="w-full  ">
            <Form.Item
              className=""
              name="Staff No"
              rules={[{ required: false }]}
            >
              <div className="">
                <div className="flex items-center justify-between mb-2 ">
                  <Switch
                    width={70}
                    switchedOnText="Group"
                    switchedOffText="Staff"
                    onValueChanged={(value: any) => {
                      handleStaffOptionChange(value);
                      setDeselectRecordFromStaff(!deselectRecordFromStaff);
                      setCheckedStaff([]);
                    }}
                  />
                  <div className="flex">
                    {staffSearchParams.isStaffON === false ? (
                      <InputsTemplate
                        useCallbackFunc
                        orderOnchange={(value) => {
                          handleSearchStaff(value);
                        }}
                        defaultValue={
                          staffSearchParams.searchStaff.initialValue
                        }
                        placeholder={"Search"}
                        disabledStatus={false}
                        span
                      />
                    ) : (
                      <InputSelect_template
                        disabledCustomWidth={false}
                        style={{ width: 200 }}
                        span
                        dataexp="name"
                        disabled={false}
                        settingsIcon={false}
                        options={getStationGroups}
                        placeHolder={staffSearchParams.searchGroupStation}
                        selectedValue={(value: any) => {
                          handleSearchGroupStation(value?.name);
                        }}
                        useCallFunc
                      />
                    )}
                    <span
                      onClick={() => {
                        handleStaffFind();
                      }}
                      style={{ borderColor: borderTheme }}
                      className="h-full ml-1 mt-0.5 flex items-center justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
                    >
                      <FaBinoculars color="#007AFF" />
                    </span>
                  </div>
                </div>

                <Datagrid_template
                  selectionMode="multiple"
                  deselectFirstRow
                  disableGroupPanel
                  disableSearch
                  disablePaging
                  DataGridRef={dataGridRef}
                  data={getAllEmps}
                  gridheight={275}
                  columns={staffsColumns}
                  onRowClick={(selected) => {
                    setAddSelected(selected);
                    setRefreshStation(!refreshStations);
                    setRefreshGroup(!refreshGroup);
                  }}
                  addCustomColumn={true}
                  customColumnCaption={"Add?"}
                  delectRecords={deselectRecordFromStaff}
                  selectedItemsChange={(selectedArr) => {
                    console.log("selectedArr", selectedArr);
                    setCheckedStaff(selectedArr);
                  }}
                  showSelectionCheckBox="always"
                />
              </div>
            </Form.Item>
          </section>

          <section className="" style={{ width: "calc(60%)" }}>
            <Form.Item
              className=""
              name="Staff No"
              rules={[{ required: false }]}
            >
              <div className="">
                <div className="flex items-center justify-between mb-2 ">
                  <Switch
                    width={70}
                    switchedOffText="Station"
                    switchedOnText="Group"
                    onValueChanged={(value: any) => {
                      handleStationOptionchange(value);
                      RefreshStation_StationGroupAndEditedRows();
                    }}
                  />
                  <div className="flex flex-row items-center">
                    {stationSearchParams.isStationON === false ? (
                      <InputsTemplate
                        useCallbackFunc
                        orderOnchange={(value) => {
                          handleSearchStation(value);
                        }}
                        defaultValue={
                          stationSearchParams.searchStation.initialValue
                        }
                        placeholder={"Search"}
                        disabledStatus={false}
                        span
                      />
                    ) : (
                      <InputSelect_template
                        disabledCustomWidth={false}
                        style={{ width: 200 }}
                        span
                        dataexp="name"
                        disabled={false}
                        settingsIcon={false}
                        options={getStationGroups}
                        placeHolder={stationSearchParams.searchGroupStation}
                        selectedValue={(value: any) => {
                          handleSearchGroupStation2(value?.name);
                          setRefreshGroup(!refreshGroup);
                        }}
                        useCallFunc
                      />
                    )}
                    <span
                      onClick={() => {
                        handleStationFind();
                      }}
                      style={{ borderColor: borderTheme }}
                      className="h-full ml-1 flex items-center justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
                    >
                      <FaBinoculars color="#007AFF" />
                    </span>
                  </div>
                  <SaveButton
                    // disableButton={stations.length > 0 ? false : true}
                    disableButton={false}
                    handleSave={() => {
                      handleSave();
                    }}
                    className="flex h-6 hover:border-blue-200 flex-row items-center justify-center  px-2 py-2 border-2 'border-slate-200' rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                    iconClassName="pr-1 w-4 h-4"
                  />
                </div>

                <Datagrid_template
                  dataId="stnIDpk"
                  selectionMode="single"
                  allowColumnEditing
                  disableGroupPanel
                  rowAlternationEnabled={false}
                  deselectFirstRow
                  selectedItemsChange={(selectedArr) => {}}
                  onRowPrepared={handleCellRender}
                  disableSearch
                  disablePaging
                  data={
                    stationSearchParams.isStationON === true
                      ? Assign_And_UnAssignGroup
                      : Assign_And_UnAssignStation
                  }
                  gridheight={275}
                  columns={stationsColumns}
                  onRowUpdating={handleRowUpdating}
                  onContentReady={(e) => {
                    secondDataGridRef.current = e.component;
                  }}
                />
                {/* <CustomDataGrid
                  dataId="stnIDpk"
                  selectionMode="single"
                  allowColumnEditing
                  disableGroupPanel
                  rowAlternationEnabled={false}
                  deselectFirstRow
                  selectedItemsChange={(selectedArr) => {}}
                  onRowPrepared={handleCellRender}
                  disableSearch
                  disablePaging
                  data={
                    stationSearchParams.isStationON === true
                      ? Assign_And_UnAssignGroup
                      : Assign_And_UnAssignStation
                  }
                  gridheight={275}
                  columns={stationsColumns}
                  onRowUpdating={handleRowUpdating}
                  //  listOfCustomColumns={customColumns}
                /> */}
              </div>
            </Form.Item>
          </section>
        </Form>
      </div>
    );
  }
);

export default ElogBookForm;

// type StationPermissions = {
//   pvaIDpk: number;
//   stnIDpk: number;
//   stnName: string;
//   pvaLog: boolean;
//   pvaHome: boolean;
//   pvaDefaultHome: boolean;
//   pvaView: boolean;
//   pvaExport: boolean;
//   pvaPreviewLogs: boolean;
//   pvaCaptureWaterLevels: boolean;
//   pvaViewWaterLevels: boolean;
//   pvaAnalyseWaterLevels: boolean;
//   pvaSeeAllLogs: boolean;
//   pvaSeeDashboard: boolean;
//   pvaTakeOverShift: boolean;
//   pvaInitiateRemoteShiftChange: boolean;
//   pvaShiftMember: boolean;
//   pvaFlag: boolean;
//   pvaCaptureStationReadings: boolean;
//   pvaCaptureBacklogData: boolean;
//   pvaSupervisor: boolean;
//   pvaShiftLeader: boolean;
//   pvaManager: boolean;
//   pvaDirector: boolean;
//   pvaStartDate: string; // ISO date string
//   pvaEndDate: string; // ISO date string
//   pvaRmks: string;
// };
