import { FaBinoculars } from "react-icons/fa";
import { InputsTemplate } from "../../templates/input";
import EquipmentDataTable from "../Safety/elogger/StationReadings/tabContents/EquipmentOutage/equipment-data-table";
import { Toolbar } from "./toolbar";
import {
  staffPriviledgesColumn,
  staffsColumns,
} from "../Assets-Manager/Transport/Entries/Priviledges-Assignments/workshop-attendants-assignments/data/workshopAttendantsData";
import { TextareaTemplate } from "../../templates/textarea";
import { SaveButton } from "../accessories/buttons";
import { datagridColumnTypes } from "../human-resource/setups/data/types/selectsTypes";
import { RefreshButton } from "../Safety/elogger/StationReadings/_widget/utils";
import useFetch from "../../hooks/useFetch";

export default function TroubleReportPriviledgesAssignment() {

  const [fetchLocation] = useFetch(`TroubleReportPrivilegeAssignment/GetLocations`,false,undefined,undefined,'powerapp', undefined, undefined)
  const [fetchType] = useFetch(`TroubleReportPrivilegeAssignment/GetTroubleTypes`,false,undefined,undefined,'powerapp', undefined, undefined)
  const [fetchClass] = useFetch(``,false,undefined,undefined,'powerapp', undefined, undefined)
  const [fetchSubstation] = useFetch(``,false,undefined,undefined,'powerapp', undefined, undefined)

  return (
    <main className="flex flex-col w-full h-full">
      <header className="p-1.5">
        <Toolbar
          searchCriteria
          searchCriteriaData={[
            { id: 0, value: "Station" },
            { id: 1, value: "Attendant" },
          ]}
        />
      </header>
      <section className="flex w-full flex-1">
        <aside className="w-1/3 flex">
          <EquipmentDataTable
            // selectionMode="multiple"
            deselectFirstRow
            disableGroupPanel
            disablePaging
            data={[]}
            gridheight={420}
            columns={staffPriviledgesColumn}
            onRowClick={(selected) => {}}
            selectedItemsChange={(selectedArr) => {
              //   updateState("selectedStaffs", selectedArr);
            }}
            // showSelectionCheckBox="always"
          />
        </aside>
        <aside className="flex flex-col space-y-1 flex-1 px-2">
          <div className="flex">
            <div className="w-1/4">
              <EquipmentDataTable
                selectionMode="multiple"
                deselectFirstRow
                disableGroupPanel
                disableSearch
                disablePaging
                data={[]}
                gridheight={309}
                columns={
                  [
                    {
                      id: 0,
                      caption: "Trouble Location",
                      dataField: "empIDpk",
                      allowFiltering: false,
                      allowSorting: false,
                      allowHiding: false,
                      dataType: "string",
                      fixed: false,
                      width: "100%",
                      renderHeaderCellRender: (params) => {
                        console.log("RECEIVED ALL PARAMS: ", params);
                        return (
                          <div className="flex items-center space-x-5">
                            <span className="text-xs flex-1">{`Trouble Location`}</span>
                            <RefreshButton />
                          </div>
                        );
                      },
                    },
                  ] as datagridColumnTypes[]
                }
                onRowClick={(selected) => {}}
                selectedItemsChange={(selectedArr) => {
                  //   updateState("selectedStaffs", selectedArr);
                }}
                showSelectionCheckBox="always"
              />
            </div>
            <div className="w-1/4">
              <EquipmentDataTable
                //   selectionMode="multiple"
                deselectFirstRow
                disableGroupPanel
                disableSearch
                disablePaging
                data={[]}
                gridheight={309}
                columns={[
                  {
                    id: 0,
                    caption: "Trouble Type",
                    dataField: "empIDpk",
                    dataType: "string",
                    fixed: true,
                    width: "100%",
                    allowFiltering: false,
                    allowSorting: false,
                    allowHiding: false,
                    renderHeaderCellRender: (params) => {
                      console.log("RECEIVED ALL PARAMS: ", params);
                      return (
                        <div className="flex items-center space-x-5">
                          <span className="text-xs flex-1">{`Trouble Type`}</span>
                          <RefreshButton />
                        </div>
                      );
                    },
                  },
                ]}
                onRowClick={(selected) => {}}
                selectedItemsChange={(selectedArr) => {
                  //   updateState("selectedStaffs", selectedArr);
                }}
                //   showSelectionCheckBox="always"
              />
            </div>
            <div className="w-1/4">
              <EquipmentDataTable
                //   selectionMode="multiple"
                deselectFirstRow
                disableGroupPanel
                disableSearch
                disablePaging
                data={[]}
                gridheight={309}
                columns={[
                  {
                    id: 0,
                    caption: "Trouble Class",
                    dataField: "empIDpk",
                    dataType: "string",
                    fixed: true,
                    width: "100%",
                    allowFiltering: false,
                    allowSorting: false,
                    allowHiding: false,
                    renderHeaderCellRender: (params) => {
                      console.log("RECEIVED ALL PARAMS: ", params);
                      return (
                        <div className="flex items-center space-x-5">
                          <span className="text-xs flex-1">{`Trouble Class`}</span>
                          <RefreshButton />
                        </div>
                      );
                    },
                  },
                ]}
                onRowClick={(selected) => {}}
                selectedItemsChange={(selectedArr) => {
                  //   updateState("selectedStaffs", selectedArr);
                }}
                //   showSelectionCheckBox="always"
              />
            </div>
            <div className="w-1/4">
              <EquipmentDataTable
                selectionMode="multiple"
                deselectFirstRow
                disableGroupPanel
                disableSearch
                disablePaging
                data={[]}
                gridheight={309}
                columns={[
                  {
                    id: 0,
                    caption: "Trouble Subclass",
                    dataField: "empIDpk",
                    dataType: "string",
                    fixed: false,
                    width: "100%",
                    allowFiltering: false,
                    allowSorting: false,
                    allowHiding: false,
                    renderHeaderCellRender: (params) => {
                      console.log("RECEIVED ALL PARAMS: ", params);
                      return (
                        <div className="flex items-center space-x-5">
                          <span className="text-xs flex-1">{`Trouble Subclass`}</span>
                          <RefreshButton />
                        </div>
                      );
                    },
                  },
                ]}
                onRowClick={(selected) => {}}
                selectedItemsChange={(selectedArr) => {
                  //   updateState("selectedStaffs", selectedArr);
                }}
                showSelectionCheckBox="always"
              />
            </div>
          </div>

          <TextareaTemplate height={70} />
          <SaveButton
            className={`flex self-end h-8 hover:border-blue-200 flex-row items-center justify-center mr-1  px-2 py-2 border-2 'border-slate-200' rounded dark:bg-slate-900 dark:text-darkModeSkin-base`}
            useCallbackFunc
            handleSave={() => {}}
          />
        </aside>
      </section>
      <footer className="flex w-full h-1/2">
        <EquipmentDataTable gridheight={0} columns={[]} data={[]} />
      </footer>
    </main>
  );
}
