import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Button } from "antd";
import { RootState } from "../../../app/store";
import { DateTemplate } from "../../../templates/date";
import InputSelect_template from "../../../templates/InputSelect";
import { TextareaTemplate } from "../../../templates/textarea";
import { CheckboxTemlate } from "../../../templates/checkbox";
import {
  CustomizableButton,
  NewButton,
  SaveButton,
} from "../../accessories/buttons";
import {
  inputsTypes,
  selectsTypes,
} from "../../human-resource/setups/data/types/selectsTypes";
import dayjs, { Dayjs } from "dayjs";
import Datagrid_template from "../../../templates/Datagrid";
import greenAdd from "../../../assets/green_plus.png";
import { DataGrid } from "devextreme-react";
import { useDispatch } from "react-redux";
import useResizeMode from "../../../hooks/useResizeMode";
import { navbar_height } from "../../accessories/component_infos";
import { job_grid } from "./data/mainGridCols";
import { InputsTemplate } from "../../../templates/input";

export const JobAnalysis = () => {
  const dispatch = useDispatch();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 35);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 25);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const editedDate = dayjs().format("YYYY-MMM-DD");

  const [states, setStates] = useState({
    requestDate: editedDate,
    reviewDate: editedDate,
    signOffDate: editedDate,
    jobLocation: { id: 0, name: "" },
    jhaNo: { id: 0, name: "" },
    reviewedBy: { id: 0, name: "" },
    supervisorGroupLeader: { id: 0, name: "" },
    jobDescription: "",
    isActive: false,
    jobDetail: { id: 0, name: "" },
    hazardIdentified: { id: 0, name: "" },
    preventiveMeasures: { id: 0, name: "" },
    instructionsForEmployees: "",
    crewMembers: "",
    remarks: "",
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Request Date",
      stateName: "requestDate",
      defaultValue: states.requestDate,
    },
    {
      id: 1,
      label: "Review  Date",
      stateName: "reviewDate",
      defaultValue: states.reviewDate,
    },
    {
      id: 2,
      label: "Sign-off Date",
      stateName: "signOffDate",
      defaultValue: states.signOffDate,
    },
  ];

  const leftSelects: selectsTypes[] = [
    {
      id: 1,
      label: "Job Location",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.jobLocation?.name,
      style: "100%",
      refreshStateName: "",
    },
    {
      id: 2,
      label: "JHA NO",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.jhaNo?.name,
      style: "100%",
      refreshStateName: "",
    },
    {
      id: 3,
      label: "Reviewed by",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.reviewedBy?.name,
      style: "30%",
      refreshStateName: "",
    },
    {
      id: 4,
      label: "Supervisor/Group Leader",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.supervisorGroupLeader?.name,
      style: "100%",
    },
  ];
  const rightSelects: selectsTypes[] = [
    {
      id: 1,
      label: "Job Detail",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.jobDetail?.name,
      style: "100%",
      refreshStateName: "",
    },
    {
      id: 2,
      label: "Hazard Identified",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.hazardIdentified?.name,
      style: "100%",
      refreshStateName: "",
    },
    {
      id: 3,
      label: "Preventive Measures",
      optionsData: [],
      idExpr: "",
      dataExpr: "",
      stateName: "",
      defaultValue: states.preventiveMeasures?.name,
      style: "30%",
      refreshStateName: "",
    },
  ];

  const textareaTemplates = [
    {
      label: "Instructions for Employees",
      height: 70,
      width: "100%",
      defaultValue: states.instructionsForEmployees,
      stateKey: "instructionsForEmployees",
    },
    {
      label: "Crew Members",
      height: 30,
      width: "100%",
      defaultValue: states.crewMembers,
      stateKey: "crewMembers",
    },
    {
      label: "Remarks",
      height: 70,
      width: "100%",
      defaultValue: states.remarks,
      stateKey: "remarks",
    },
  ];
  return (
    <div className="w-full">
      <div>
        <p className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
          Job Hazard Analysis Details
        </p>
      </div>
      <div
        className={`border-[1px] dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base items-start justify-start p-4 ${borderTheme}`}
      >
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-1 rounded py-2 px-4"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 10 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="flex">
            <div style={{ width: "38%" }} className="pr-2">
              {dates.map(({ id, label, stateName, defaultValue }) => (
                <DateTemplate
                  key={id}
                  label={label}
                  width={"80%"}
                  changeDate={(e: Dayjs) => {
                    setState(stateName!, dayjs(e).format());
                  }}
                  selectedDate={dayjs(defaultValue)}
                  disabledDate={(current: Dayjs) => {
                    return current && current > dayjs().endOf("day");
                  }}
                />
              ))}

              {leftSelects.map(
                ({
                  id,
                  label,
                  idExpr,
                  dataExpr,
                  optionsData,
                  stateName,
                  defaultValue,
                  refresh,
                  refreshStateName,
                }) => (
                  <InputSelect_template
                    key={id}
                    cusWidth={"80%"}
                    disabledCustomWidth={true}
                    label={label}
                    options={optionsData}
                    idexpr={idExpr}
                    dataexp={dataExpr}
                    placeHolder={defaultValue}
                    useCallFunc
                  />
                )
              )}

              <TextareaTemplate
                height={120}
                labelCol={8}
                width="90%"
                label={"Job Description"}
                useCallbackFunc
                defaultValue={states.jobDescription}
                readonly={false}
                disabled={false}
                setCallbackValue={(e) => {
                  setState("jobDescription", e);
                }}
              />
              <Form.Item
                label="Active?" // Label for the checkbox
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 15 }}
              >
                <div className="flex justify-between items-center ">
                  <CheckboxTemlate
                    withBorders
                    defaultValue={states.isActive}
                    setCheckboxOnchange={(value) => setState("isActive", value)}
                  />
                  <div className="flex space-x-0.5 mr-3">
                    <SaveButton />
                    <NewButton />
                  </div>
                </div>
              </Form.Item>
            </div>

            <div className="flex-1 pl-2">
              <div className="flex">
                <div className="flex-1">
                  {rightSelects.map(
                    ({
                      id,
                      label,
                      idExpr,
                      dataExpr,
                      optionsData,
                      stateName,
                      defaultValue,
                      refresh,
                      refreshStateName,
                    }) => (
                      <Form.Item
                        key={id}
                        label={label}
                        labelCol={{ span: 8 }} // Ensuring same label alignment
                        wrapperCol={{ span: 20 }} // Ensuring same input alignment
                      >
                        <InputSelect_template
                          cusWidth={"100%"}
                          disabledCustomWidth={true}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          useCallFunc
                        />
                      </Form.Item>
                    )
                  )}
                </div>
                {/* Wrap CustomizableButton in Form.Item */}
                <Form.Item
                  label="" // Empty label for alignment
                  // labelCol={{ span: 1 }} // Same as others to maintain alignment
                  // wrapperCol={{ span: 18 }} // Adjust as per your grid
                >
                  <CustomizableButton
                    classname=" text-black p-1 rounded w-[93px] h-[75px] ml-1.5 flex items-center justify-center border-[1px] border-slate-200"
                    isText={true}
                    text="Add"
                    buttonID="addButton"
                    handleCustomizableClick={() => {
                      /* Your click handler */
                    }}
                    customizableBtnImage={greenAdd}
                    customizableBtnImageClassname="w-4 h-4 mr-1"
                  />
                </Form.Item>
              </div>

              {/* Form Item wrapping TextareaTemplate */}
              <div className="">
                <Form.Item
                  label="Job Details"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Datagrid_template
                    gridheight={100}
                    columns={job_grid}
                    data={[]}
                    disableGroupPanel
                    disablePaging
                    disableSearch
                    style={{ marginBottom: "5px" }}
                  />
                </Form.Item>

                <Form.Item
                  label="Instructions for Employees"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 18 }}
                >
                  <TextareaTemplate
                    height={60}
                    parentClassName="mb-0"
                    width="100%"
                    useCallbackFunc
                    defaultValue={states.instructionsForEmployees}
                    readonly={false}
                    disabled={false}
                    setCallbackValue={(e) =>
                      setState("instructionsForEmployees", e)
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Crew Members"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 18 }}
                >
                  <InputsTemplate
                    defaultValue={states.crewMembers}
                    useCallbackFunc
                    orderOnchange={(e) => setState("crewMembers", e)}
                    inputStyle={{ marginBottom: "6px" }}
                  />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 18 }}
                >
                  <TextareaTemplate
                    height={60}
                    width="100%"
                    useCallbackFunc
                    defaultValue={states.remarks}
                    readonly={false}
                    disabled={false}
                    setCallbackValue={(e) => setState("remarks", e)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
