import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form, Select, DatePicker, InputNumber, Slider } from "antd";
import newIcon from "../../../../../../assets/new.png";
import refresh from "../../../../../../assets/refresh.png";
import settings from "../../../../../../assets/setting.png";
import menuBar from "../../../../../../assets/menu-bar.png";
import { AiOutlineSwitcher } from "react-icons/ai";
import Datagrid_template from "../../../../../../templates/Datagrid";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import useFetch from "../../../../../../hooks/useFetch";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { SelectsTemplate } from "../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import {
  Cancel,
  EditButton,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../accessories/buttons";
import {
  dateFormat,
  dateFormat2,
  readableDateFormat,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import { sia_updates_cols } from "../../../../../Performance/Entries/Staff Initiative Assignment/data/datagrid_cols";
import { useCrudFunc, useQueryFetch } from "../../../../../../functions/crud";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  modalPropTypes,
  modalTypes,
  validateInstanceTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { Tooltip } from "devextreme-react";
import ClassifyForm from "./classify_form";
import { set } from "lodash";
import TechnicalDescription from "./technical_description_form";
import { repairs_updates_cols } from "../data/troubleDataSample";
import { DataTable } from "../../../../../abstracts/data-table";
import { useQuery } from "react-query";
import { TaskUpdatesType, useTroubleReportRepo } from "../repo";
import { ResponseType } from "../troubleReport";
import { DateTemplate } from "../../../../../../templates/date";
import { useCurrentSession } from "../../../../../../hooks/useAccountInfo";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  selectrefreshx?: (e: any) => void;
  siaId?: any;
  customHeightHook?: any;
  selectedRecord?: any;
}

interface stateTypes {
  date?: string;
  complete?: number;
  job?: number;
  description?: string;
  busyLoader: string;
  status: { id: any; name: any };
  impact: { id: any; name: any };
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
}

export const TroubleReportSecondWidget = forwardRef(
  (
    { toggler, siaId, customHeightHook, selectedRecord, selectrefreshx }: props,
    ref
  ) => {
    const siaDataGrid = useSelector(
      (state: RootState) => state.general.siaDataGrid
    );
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );

    const dispatch = useDispatch();
    const [toggled, setToggled] = useState(false);
    const [refreshStatus, setrefreshStatus] = useState(false);
    const [impactStatus, setimpactStatus] = useState(false);
    const { selectedReport } = useTroubleReportRepo((state) => state);
    const { empIDpk: userID } = useCurrentSession();
    const [percentData, percentError, percentLoading] = useFetch(
      "TroubleReports/LatestPercentComplete/100",
      true,
      "trpIDpk",
      true,
      "powerapp"
    );
    const [statusData, statusError, statusLoading] = useFetch(
      `TroubleReports/PopLueStatus`,
      refreshStatus,
      "tstIDpk",
      true,
      "powerapp",
      false
      // {"tstIDpk":1}
    );
    const [impactData, impactError, impactLoading] = useFetch(
      `TroubleReports/PopLueUpdIssueImpacts/${selectedRecord?.timIDpk}/${selectedRecord?.tlcIDpk}`,
      impactStatus,
      "timIDpk",
      true,
      "powerapp",
      false
      // {"tstIDpk":1}
    );
    const [doneByData, doneByError, doneByLoading] = useFetch(
      `TroubleReports/PopLueDoneBy`,
      impactStatus,
      "timIDpk",
      true,
      "powerapp",
      false
      // {"tstIDpk":1}
    );
    const [reportDataGrid, _e, _l]: [
      reportDataGrid: ResponseType<TaskUpdatesType>,
      _e: any,
      _l: any
    ] = useFetch(
      `TroubleReports/GetAllTaskUpdates`,
      impactStatus,
      "truReportIDfk",
      true,
      "powerapp",
      false,
      { truReportIDfk: selectedRecord?.trpIDpk }
    );
    // const { data: reportDataGrid } = useQueryFetch<
    //   ResponseType<TaskUpdatesType>
    // >({
    //   app: `powerapp`,
    //   url: `TroubleReports/GetAllTaskUpdates`,
    //   params: {
    //     truReportIDfk: selectedReport?.trpIDpk,
    //   },
    //   refresh: impactStatus,
    // });
    useImperativeHandle(ref, () => ({
      topNavNew: () => {
        // editAccessPriv();
        clearAllFields();
      },
    }));

    function clearAllFields() {
      setStates({
        date: "",
        complete: 0,
        job: 0,

        description: "",
        status: { id: "", name: "" },
        impact: { id: "", name: "" },
        busyLoader: "",
        confirmModal: { state: false, title: "", message: "", action: "post" },
        warningModal: { state: false, title: "", message: "" },
        successModal: { state: false, title: "", message: "" },
      });
      setOnChangeDoneby(null);
    }

    useEffect(() => {
      console.log("reportDataGrid", reportDataGrid);
      setStates((state) => ({
        ...state,
        date: reportDataGrid?.[0]?.truDate,
        // job: reportDataGrid?.[0]?.truDate,
        impact: {
          name: reportDataGrid?.[0]?.timName,
          id: reportDataGrid?.[0]?.timIDpk,
        },
        status: {
          name: reportDataGrid?.[0]?.tstName,
          id: reportDataGrid?.[0]?.tstIDpk,
        },
        complete: reportDataGrid?.[0]?.truPercentComplete,
        description: reportDataGrid?.[0]?.truUpdate,
      }));
      setOnChangeDoneby(reportDataGrid?.[0]?.truUpdatedBy);
    }, [selectedReport, reportDataGrid]);

    useEffect(() => {
      setimpactStatus(!impactStatus);
    }, [selectedRecord]);

    const updateState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };

    const form_disable = useSelector(
      (state: RootState) => state.general.formDisabled
    );

    const [disableForm, setDisableForm] = useState(true);
    const [updateForm, setUpdateForm] = useState(false);

    const [PostDataFunc, UpdateDataFunc] = useCrudFunc("powerapp");

    //post api
    const postData = async (action: "confirm" | "post") => {
      console.log("post data called");
      if (action === "confirm") {
        updateState("confirmModal", {
          state: true,
          title: "Save Record",
          message: "Are you sure you want to save this record?",
          func() {
            console.log("post data event called");
            postData("post");
          },
        });
        return;
      }

      updateState("confirmModal", {
        state: false,
        title: "Save Record?",
        message: "Are you sure you want to save this record?",
      });

      try {
        // busyloader(saveModalData.progress);

        const res = await PostDataFunc(
          `TroubleReports/SaveTroubleReportUpdate`,
          {
            truDate: dayjs(states.date),
            truReportIDfk: selectedRecord?.trpIDpk,
            truUpdate: states.description,
            truPercentComplete: states.complete,
            truCompletionDate: selectedRecord?.trpCompletionDate,
            truSTatusIDfk: states.status.id,
            truImpactIDfk: states.impact.id,
            truUpdatedBy: onChangedoneby.toString(),
            truUpdatedByDept: "string",
            truUpdateBySection: "string",
            formCode: "omgTroubleReports",
            formAction: "string",
          }
        );

        // disableFields();
        // refreshGrid();
        // clearSelected();
        // //Clear fields when done.
        // populateStatesFromGrid(true);
        updateState("successModal", {
          state: true,
          title: "Record Updated",
          message: "Trouble Report Update added successfully",
        });
        setStates({
          ...states,
          date: "",
          complete: 0,
          job: 0,

          description: "",
          status: { id: "", name: "" },
          impact: { id: "", name: "" },
          busyLoader: "",
          // confirmModal:{state:false,title:'',message:'',action:'post'},
          // warningModal:{state:false,title:'',message:''},
        });
        setOnChangeDoneby(null);
        selectrefreshx("");
      } catch (error) {
        console.log(error);

        updateState("warningModal", {
          state: true,
          title: saveModalData.error.title,
          message: saveModalData.error.message,
        });
      } finally {
        // busyloader('');
      }
      return;
    };
    //update api
    const updateFormData = async (action: "confirm" | "update") => {
      console.log("post data called");
      if (action === "confirm") {
        updateState("confirmModal", {
          state: true,
          title: "Update Record",
          message: "Are you sure you want to update this record?",
          func() {
            console.log("post data event called");
            updateFormData("update");
          },
          action: "update",
        });
        return;
      }

      updateState("confirmModal", {
        state: false,
        title: "Update Record?",
        message: "Are you sure you want to update this record?",
      });

      try {
        // busyloader(saveModalData.progress);
        console.log(repairsUpdateRecord);
        const res = await UpdateDataFunc(
          `TroubleReports/UpdateTroubleReportUpdates`,
          {
            truIDpk: repairsUpdateRecord["truIDpk"],
            truDate: dayjs(states.date),
            // "truReportIDfk": selectedRecord?.trpIDpk,
            truUpdate: states.description,
            truPercentComplete: states.complete,
            truCompletionDate: selectedRecord?.trpCompletionDate,
            truStatusIDfk: states.status.id,
            truImpactIDfk: states.impact.id,
            truUpdatedBy: onChangedoneby.toString(),
            // "truUpdatedByDept": "string",
            // "truUpdateBySection": "string",
            // "formCode": "string",
            // "formAction": "string"
          },
          `Updated Trouble report by emp id ${repairsUpdateRecord["truIDpk"]} successfully`,
          "omgTroubleReports"
        );

        // disableFields();
        // refreshGrid();
        // clearSelected();
        // //Clear fields when done.
        // populateStatesFromGrid(true);
        selectrefreshx("");
        updateState("successModal", {
          state: true,
          title: "Record Updated",
          message: "Repairs Update added successfully",
        });
        // setStates(  {
        //   ...states,
        //   date: "",
        //   complete: 0,
        //   job: 0,

        //   description: "",
        //   status: { id: "", name: "" },
        //   impact: { id: "", name: "" },
        //   busyLoader : '',
        //   // confirmModal:{state:false,title:'',message:'',action:'post'},
        //   // warningModal:{state:false,title:'',message:''},

        // });
        setOnChangeDoneby(null);
      } catch (error) {
        console.log(error);

        updateState("warningModal", {
          state: true,
          title: updateModalData.error.title,
          message: updateModalData.error.message,
        });
      } finally {
        // busyloader('');
      }
      return;
    };

    //  const [taskUpdates, taskUpdatesError, taskUpdatesLoading] = useFetch(`api/PfmStaffInitiativesAssignments/GetTaskUpdates?SiaIDpk=10`);
    const handleref = useRef<any>(null);
    const [states, setStates] = useState<stateTypes>({
      date: "",
      complete: 0,
      job: 0,

      description: "",
      status: { id: "", name: "" },
      impact: { id: "", name: "" },
      busyLoader: "",
      confirmModal: { state: false, title: "", message: "", action: "post" },
      warningModal: { state: false, title: "", message: "" },
      successModal: { state: false, title: "", message: "" },
    });

    const setState = (key: string, value: any) => {
      setStates({ ...states, [key]: value });
    };

    //validate fields
    const validateFields = (): boolean => {
      const validationInstances: validateInstanceTypes[] = [
        {
          state: states.date,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Date",
            message: "Please select  date",
          },
        },
        {
          state: states.status.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Status",
            message: "Please select status",
          },
        },
        {
          state: states.impact.id,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Select Impact",
            message: "Please select impact",
          },
        },
      ];

      for (let instance of validationInstances)
        if (instance.state === instance.baseValue) {
          const { modalProps } = instance;
          updateState("warningModal", {
            state: modalProps.state,
            title: modalProps.title,
            message: modalProps.message,
          });
          return false;
        }
      return true;
    };

    const [repairsUpdateRecord, setRepairsUpdateRecord] = useState({});

    const [textAreaHeight, settextAreaHeight] = useState<string>("127px");
    const [onChangedoneby, setOnChangeDoneby] = useState<any>(null);

    const handleChange = (value: string[]) => {
      setOnChangeDoneby(value);
      console.log("dbye", doneByData);

      var handlerefHeight = handleref.current.clientHeight;
      var handlerefWidth = handleref.current.clientWidth / 4;

      if (handlerefHeight > 29) {
        settextAreaHeight("111px");
      } else {
        settextAreaHeight("127px");
      }

      // if(isScroll == true) {
      //     settextAreaHeight('111px')
      //     console.log(isScroll + " is  scrollable");
      //     setIsScroll(!isScroll)

      // }
      // else
      // {
      //     settextAreaHeight('127px')
      //     console.log(isScroll + " is not scrollable");

      // }
    };

    // modal instances
    const modals: modalTypes[] = [
      //validation
      {
        disableCancel: true,
        icon: "warning",
        open: states.warningModal.state,
        okHandler: () =>
          updateState("warningModal", { state: false, title: "", message: "" }),
        cancelHandler: () =>
          updateState("warningModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.warningModal.message,
        okText: "Ok",
        title: states.warningModal.title,
        cancelText: "No, cancel",
      },

      //confirmation
      {
        disableCancel: false,
        icon: "question",
        open: states.confirmModal.state,
        okHandler: states.confirmModal.func,
        cancelHandler: () =>
          updateState("confirmModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.confirmModal.message,
        okText:
          states.confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
        title: states.confirmModal.title,
        cancelText: "No, cancel",
      },

      //success
      {
        disableCancel: true,
        icon: "success",
        open: states.successModal.state,
        okHandler: () =>
          updateState("successModal", { state: false, title: "", message: "" }),
        cancelHandler: () =>
          updateState("successModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.successModal.message,
        okText: "Ok",
        title: states.successModal.title,
        cancelText: "No, cancel",
      },
    ];

    const [showClassifyForm, setShowClassifyForm] = useState(false);
    const [showTechDescForm, setShowTechDescForm] = useState(false);
    const [lineRows, setLineRows] = useState(true);
    return (
      <>
        <ModalTemplate
          disableCancel
          width={"25%"}
          customComponent={
            <ClassifyForm
              selectedRecord={selectedRecord}
              handleOnCancel={() => {
                setShowClassifyForm(false);
              }}
              handleRefresh={() => {
                selectrefreshx("");
              }}
            />
          }
          title={"Classify"}
          message={""}
          cancelHandler={() => setShowClassifyForm(false)}
          open={showClassifyForm}
          okHandler={() => {}}
        />
        <ModalTemplate
          disableCancel
          width={"25%"}
          customComponent={
            <TechnicalDescription
              selectedRecord={selectedRecord}
              handleOnCancel={() => {
                setShowTechDescForm(!showTechDescForm);
              }}
              handleRefresh={() => {
                selectrefreshx("");
              }}
            />
          }
          title={"Technical Description"}
          message={""}
          cancelHandler={() => setShowTechDescForm(false)}
          open={showTechDescForm}
          okHandler={() => {}}
        />
        {modals.map(
          (
            {
              disableCancel,
              icon,
              okHandler,
              cancelHandler,
              open,
              cancelText,
              okText,
              message,
              title,
            }: modalTypes,
            index
          ) => {
            return (
              <div key={index}>
                <ModalTemplate
                  icon={icon && icon}
                  disableCancel={disableCancel}
                  cancelText={cancelText && cancelText}
                  open={open}
                  okHandler={okHandler}
                  cancelHandler={cancelHandler}
                  message={message}
                  okText={okText}
                  title={title}
                />
              </div>
            );
          }
        )}

        <div
          style={{ borderColor: borderTheme }}
          className="m-1 border rounded "
        >
          <div
            onDoubleClick={() => {
              setToggled(!toggled);
              setRepairsUpdateRecord({});
              setUpdateForm(false);
            }}
            style={{ height: "" }}
            className="flex items-center  justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
          >
            {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
            <p className=" py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
              Repairs Update
            </p>
            <div className="flex flex-1 items-center justify-end w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
              <span
                onClick={() => {
                  setShowClassifyForm(true);
                  // setData('');
                  // handleRefresh && handleRefresh();
                }}
                // style={{height:23.5, borderWidth:1,width:25}}
                className="mx-2 flex justify-center hover:cursor-pointer border-gray-300 rounded text-blue-500"
              >
                <img
                  className=" hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={settings}
                  alt="reset"
                />
                Classify
              </span>
              <span
                onClick={() => {
                  // setData('');
                  // handleRefresh && handleRefresh();
                }}
                // style={{height:23.5, borderWidth:1,width:25}}
                className="mx-2 flex justify-center hover:cursor-pointer border-gray-300 rounded text-blue-500"
              >
                <img
                  className="w-full hover:bg-blue-50 h-[20px] flex p-0.5 items-center justify-center"
                  src={refresh}
                  alt="reset"
                />
                Refresh
              </span>
              <span
                onClick={() => {
                  setLineRows(!lineRows);
                  // setData('');
                  // handleRefresh && handleRefresh();
                }}
                // style={{height:23.5, borderWidth:1,width:25}}
                className="mx-2 flex justify-center hover:cursor-pointer border-gray-300 rounded text-blue-500"
              >
                <img
                  className=" hover:bg-blue-50 h-[20px] flex p-0.5 items-center justify-center"
                  src={menuBar}
                  alt="reset"
                />
                {lineRows ? "Single Line Rows" : "Multiple Line Rows"}
              </span>
            </div>
          </div>

          {/* Datagrid table */}
          {toggled ? (
            <div
              onDoubleClick={() => {
                setToggled(!toggled);
                setRepairsUpdateRecord({});
                setUpdateForm(false);
              }}
              style={{ width: "100%" }}
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
            >
              <DataTable
                dataId="truIDpk"
                gridheight={435}
                wordWrap={!lineRows}
                columns={repairs_updates_cols}
                data={reportDataGrid}
                disableSearch={true}
                disableGroupPanel={true}
                disablePaging={true}
                onRowClick={(data) => {
                  console.log(data);
                  setRepairsUpdateRecord(data);
                }}
                rowDoubleClicked={(data) => {
                  setToggled(false);
                  updateState("date", data.truDate);
                  updateState("complete", data.truPercentComplete);
                  updateState("status", {
                    id: data["tstIDpk"],
                    name: data["tstName"],
                  });
                  updateState("impact", {
                    id: data["timIDpk"],
                    name: data["timName"],
                  });
                  updateState("description", data.truUpdate);
                  setOnChangeDoneby(data.truUpdatedBy);
                  setDisableForm(false);
                  setUpdateForm(true);
                }}
              />
            </div>
          ) : (
            <div>
              <div
                onDoubleClick={() => {
                  setToggled(!toggled);
                  setRepairsUpdateRecord({});
                  setUpdateForm(false);
                }}
                style={{ width: "100%" }}
                className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
              >
                <Form
                  className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
                  name="basic"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  size="small"
                  layout="horizontal"
                  disabled={false}
                >
                  <Form.Item
                    wrapperCol={{ span: 15 }}
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Date"}
                      </p>
                    }
                    className="mb-1 "
                  >
                    <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ">
                      {/* <DatePicker
                        format={{ format: "EEE, dd MMM yyyy  HH:mm" }}
                        disabled={disableForm}
                        onChange={(date, dateString) => {
                          updateState("date", dateString);
                        }}
                        allowClear={false}
                        showTime={{ defaultValue: dayjs("00:00:00", "HH:mm") }}
                        value={
                          states.date === "" ? dayjs() : dayjs(states.date)
                        }
                      /> */}
                      <DateTemplate
                        showTime
                        format={readableDateFormat}
                        disabled={disableForm}
                        selectedDate={
                          states.date ? dayjs(states.date) : undefined
                        }
                        changeDate={(e: Dayjs) => {
                          updateState("date", e);
                        }}
                      />
                    </div>
                  </Form.Item>

                  <Form.Item
                    className="mb-1"
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"% Complete"}
                      </p>
                    }
                    // labelCol={{ span: 4 }}
                    // wrapperCol={{ span: 15 }}
                  >
                    <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base">
                      <Slider
                        disabled={disableForm}
                        style={{ width: "100%" }}
                        className="mr-5"
                        min={0}
                        max={100}
                        onChange={(e) => {
                          updateState("complete", e);
                        }}
                        value={states.complete}
                      />
                      <InputNumber
                        disabled={disableForm}
                        className="h-6 w-16 flex items-center justify-center"
                        size="small"
                        min={0}
                        max={100}
                        // style={{ margin: '0 16px' }}
                        value={states.complete}
                        onChange={(e) => {
                          updateState("complete", e);
                        }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Status"}
                      </p>
                    }
                    className="mb-1 "
                  >
                    <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ">
                      <SelectsTemplate
                        //  useCusWidth={true}
                        // cusWidth={'50%'}
                        disabledCustomWidth={false}
                        style={{ width: "200px" }}
                        // selectStyle={{'width': '50%'}}
                        placeHolder={states.status.name}
                        disabled={disableForm}
                        options={statusData}
                        useCallFunc
                        idexpr="tstIDpk"
                        dataexp="tstName"
                        selectedValue={(e) => {
                          console.log("sstata", e);
                          updateState("status", {
                            id: e["tstIDpk"],
                            name: e["tstName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("status", { id: "", name: "" });
                        }}
                      />
                      <SelectsTemplate
                        label="Impact"
                        // useCusWidth={true}
                        //  style={{width: "200px"}}
                        useCallFunc
                        disabledCustomWidth={false}
                        // selectStyle={{'width': '50%'}}
                        style={{ marginLeft: "20px", width: "200px" }}
                        placeHolder={states.impact.name}
                        options={impactData}
                        idexpr="timIDpk"
                        dataexp="timName"
                        disabled={disableForm}
                        selectedValue={(e) => {
                          updateState("impact", {
                            id: e["timIDpk"],
                            name: e["timName"],
                          });
                        }}
                        handleClear={() => {
                          updateState("impact", { id: "", name: "" });
                        }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block", marginBottom: "2px" }}
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Updates"}
                      </p>
                    }
                    className="w-full mb-1"
                  >
                    <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base">
                      <TextareaTemplate
                        height={275}
                        useCallbackFunc
                        disabled={disableForm}
                        defaultValue={states.description}
                        setCallbackValue={(e) => {
                          updateState("description", e);
                        }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    style={{ paddingLeft: "", display: "inline-block" }}
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Done By"}
                      </p>
                    }
                    className="mb-0.5 w-full"
                  >
                    <div
                      ref={handleref}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center "
                    >
                      <Select
                        disabled={disableForm}
                        fieldNames={{ options: "100px" }}
                        bordered={false}
                        virtual={false}
                        style={{
                          border: "1px solid #ccc",
                          maxHeight: "44px",
                          overflowY: "scroll",
                          borderRadius: "5px",
                          outline: "none",
                          borderColor: borderTheme,
                        }}
                        placeholder="Please Select"
                        allowClear
                        mode="multiple"
                        value={onChangedoneby}
                        onChange={handleChange}
                      >
                        {doneByData?.map((option: any, index: any) => {
                          return (
                            <option key={option.id} value={option.empName14}>
                              {option.name}
                            </option>
                          );
                        })}
                      </Select>
                      <span
                        onClick={() => {
                          setOnChangeDoneby(null);
                          // setData('');
                          // handleRefresh && handleRefresh();
                        }}
                        style={{
                          height: 23.5,
                          borderWidth: 1,
                          width: 25,
                          borderColor: borderTheme,
                        }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={refresh}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form>
              </div>
              <div className="w-full flex px-3 flex-row items-center justify-between py-1  bg-slate-100">
                <li
                  className=" flex justify-start items-center"
                  onClick={() => {
                    if (selectedReport?.reportedByEmpID !== userID) {
                      updateState("warningModal", {
                        state: true,
                        title: "Invalid Action",
                        message:
                          "You did not log this Trouble Report. You cannot Edit it.",
                        func() {
                          setStates((state) => ({
                            ...state,
                            warningModal: {
                              ...state.warningModal,
                              state: false,
                            },
                          }));
                        },
                      });
                      return;
                    }
                    if (
                      repairsUpdateRecord["truIDpk"] === undefined &&
                      !selectedReport?.tatIDpk
                    ) {
                      updateState("warningModal", {
                        state: true,
                        title: "Error Editing",
                        message: "Please select a record",
                      });
                    } else {
                      setToggled(true);

                      setUpdateForm(true);
                    }
                  }}
                >
                  <EditButton />
                </li>
                <li
                  onClick={() => setShowTechDescForm(!showTechDescForm)}
                  className=" flex justify-start items-center"
                >
                  <img
                    className=" hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    id="techdesc"
                    src={settings}
                    alt="reset"
                  />

                  <Tooltip
                    target="#techdesc"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Technical Description</p>
                  </Tooltip>
                </li>

                <ul className="flex items-start justify-start">
                  {updateForm ? (
                    <UpdateButton
                      disableButton={disableForm}
                      useCallbackFunc={true}
                      handleUpdate={() => {
                        validateFields() && updateFormData("confirm");
                      }}
                    />
                  ) : (
                    <SaveButton
                      disableButton={disableForm}
                      useCallbackFunc={true}
                      handleSave={() => {
                        validateFields() && postData("confirm");
                      }}
                    />
                  )}
                  {disableForm ? (
                    <NewButton
                      useCallbackFunc={true}
                      disableButton={!disableForm}
                      // disabled_status={true}
                      new_button_toggler={() => {
                        if (selectedRecord?.trpIDpk === undefined) {
                          updateState("warningModal", {
                            state: true,
                            title: "Error",
                            message:
                              "The Trouble Type, Trouble Class and Trouble Subclass cannot be empty. Please update the Trouble and try again or contact your Systems Administrator for assistance",
                          });
                        } else {
                          clearAllFields();
                          setDisableForm(false);
                        }
                        //  dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
                        //  dispatch(setGeneralValue({ expr: "updateMode", value: false }));
                      }}
                    />
                  ) : (
                    <Cancel
                      useCallbackFunc
                      cancel_button_toggler={() => {
                        setDisableForm(true);
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

const progressBar = () => {
  return (
    <div className="flex flex-col" id="avGAllTasks">
      <div className="w-full h-4  bg-gray-200 rounded-sm dark:bg-gray-700">
        <div className="relative h-full flex ">
          <div className=" absolute h-full w-6 bg-purple-300 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-sm "></div>
          <div className="relative text-xs text-black w-full text-center">
            42%
          </div>
        </div>
      </div>
    </div>
  );
};
