import { forwardRef, useEffect, useState } from "react";
import MiniToolbar from "./_widget/minitollbar";
import { Form } from "antd";
import { InputsTemplate } from "../../../../templates/input";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../accessories/buttons";
import InputSelect_template from "../../../../templates/InputSelect";
import Datagrid_template from "../../../../templates/Datagrid";
import { Switcher } from "./_widget/switcher";
import { outagecol } from "./utils/data/colums";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { TextareaTemplate } from "../../../../templates/textarea";
import { TextArea } from "devextreme-react";
import { backgroundColor } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";
import useFetch from "../../../../hooks/useFetch";
import { useToken } from "../../../../hooks/useToken";
import { api_base_url } from "../../../accessories/component_infos";
import axios from "axios";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setGeneralValue } from "../../../../features/generalSlice";
import { ModalTemplate } from "../../../../templates/modal";
import { useAxiosService } from "../../../../general/requestConfig";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import { useCrudFunc } from "../../../../functions/crud";
import EquipmentDataTable from "./tabContents/EquipmentOutage/equipment-data-table";
import { UpdateStates } from "../../../../functions/updateStates";

interface states {
  name: string;
  shortName: string;
  remarks: string;
  planned: boolean;
  auto: boolean;
  active: boolean;
  isOpenDropdown: boolean;
  selectedRecords: any;
}

export const OutageEquipmentTypes = () => {
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const clearF = useSelector((state: RootState) => state.general.clearF);
  const [refresh, setRefresh] = useState(false);
  const editedDate = dayjs().endOf("month").format("YYYY-MM-DD");
  const startingDate = dayjs()
    .subtract(1, "years")
    .startOf("month")
    .format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState<{ temp: string; index: string }>({
    temp: startingDate,
    index: startingDate,
  });
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: editedDate,
    index: editedDate,
  });
  const [searchText, setSearchText] = useState<{ temp: string; index: string }>(
    { temp: "", index: "" }
  );
  const [activeStatus, setActiveStatus] = useState<{
    temp: string;
    index: string;
  }>({ temp: "", index: "" });
  const [states, setStates] = useState<states>({
    name: "",
    shortName: "",
    remarks: "",
    planned: true,
    auto: true,
    active: true,
    isOpenDropdown: false,
    selectedRecords: [],
  });
  const formCode = useSelector((state: RootState) => state.general.formCode);
  console.log("Parentstates", states);
  const { selectedRecords } = states;
  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const dispatch = useDispatch();
  const populateFields = (clearFields?: boolean) => {
    const gridState = [
      {
        key: "name",
        value: clearFields ? "" : selectedRecords?.otpName,
      },
      {
        key: "shortName",
        value: clearFields ? "" : selectedRecords?.otpShtName ?? "",
      },
      {
        key: "remarks",
        value: clearFields ? "" : selectedRecords?.otpRmks ?? "",
      },
      {
        key: "active",
        value: clearFields ? true : selectedRecords?.otpActive ?? true,
      },
      {
        key: "planned",
        value: clearFields ? true : selectedRecords?.otpPlanned ?? true,
      },
      {
        key: "auto",
        value: clearFields ? true : selectedRecords?.otpAuto ?? true,
      },
    ];

    for (let instance of gridState) updateStates(instance.key, instance.value);
  };
  console.log(states);

  useEffect(() => {
    populateFields(false);
  }, [selectedRecords]);

  useEffect(() => {
    if (clearF === true) {
      setStates({
        name: "",
        shortName: "",
        remarks: "",
        planned: true,
        auto: true,
        active: true,
        isOpenDropdown: false,
        selectedRecords: [],
      });
    }
  }, [clearF]);
  const [getName, setGetName] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userID, empID, userInfo] = useAccountInfo();
  const [modalData, setModalData] = useState<any>({
    title: "",
    message: "",
    okText: "",
  });
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [toggle, setToggle] = useState<boolean>(true);
  const token = useToken();
  const [Posting, Updating] = useCrudFunc("powerapp");
  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 15,
  });
  const [checkRec] = useFetch(
    `OutageType/CheckIfRecordExists?name=${states.name}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );
  const searchTerm =
    searchText.index.length > 0 ? `SearchTerm=${searchText.index}` : "";
  const checkState = `CheckState=${activeStatus.index}`;
  const queryParams = [searchTerm, checkState].filter(Boolean).join("&");

  const [mainGrid] = useFetch(
    `OutageType/GetAllOutageType?${queryParams}&PageNumber=${paging.pageNumber}&PageSize=${paging.pageSize}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );

  const checkBox = [
    {
      id: 2,
      label: "Planned?",
      defaultValue: states.planned,
      stateName: "planned",
    },
    { id: 3, label: "Auto?", defaultValue: states.auto, stateName: "auto" },
    {
      id: 4,
      label: "Active?",
      defaultValue: states.active,
      stateName: "active",
    },
  ];

  //validation
  console.log("check", checkRec);
  //SAVE FUNCTION
  const saveFunction = () => {
    if (states.name === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for Name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (checkRec.length > 0) {
      setModalData({
        title: "Record Exists",
        message: "Record of such name already exists",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (states.shortName === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for short name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else {
      setModalData({
        title: "Save record?",
        message: "Are you sure you want to save this record?",
        okText: "Save",
      });
      setIcon("question");
      setOpenModal(true);
    }
  };

  //Update FUNCTION
  const updateFunction = () => {
    if (states.name === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else if (states.shortName === "") {
      setModalData({
        title: "Required field!",
        message: "Please fill in field for short name",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else {
      setModalData({
        title: "Update record?",
        message: "Are you sure you want to update this record?",
        okText: "Update",
      });
      setIcon("question");
      setOpenModal(true);
    }
  };
  const postData = async () => {
    try {
      await Posting(`OutageType/CreateOutageType`, {
        otpName: states.name,
        otpShtName: states.shortName,
        otpPlanned: states.planned,
        otpAuto: states.auto,
        otpActive: states.active,
        otpRmks: states.remarks,
        otpCreatedBy: userID,
        formAction: `Created Outage Type with name ${states.name} successfully`,
      });
      setModalData({
        title: "Success",
        message: "Record saved successfully!",
      });
      setOpenModal(true);
      setIcon("success");
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (e: any) {
      console.log("ErrorMess", e);
      setModalData({
        title: "Error",
        message: e.message,
      });
      setIcon("warning");
      setOpenModal(true);
    }
  };
  const updateData = async () => {
    try {
      await Updating(`OutageType/UpdateOutageType`, {
        otpIDpk: selectedRecords?.otpIDpk,
        otpName: states.name,
        otpShtName: states.shortName,
        otpPlanned: states.planned,
        otpAuto: states.auto,
        otpActive: states.active,
        otpRmks: states.remarks,
        otpEditedBy: userID,
        formAction: `Updated Outage Type with ID ${selectedRecords?.otpIDpk} to name ${states.name} successfully`,
      });
      setModalData({
        title: "Success",
        message: "Record updated successfully!",
      });
      setOpenModal(true);
      setIcon("success");
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (e: any) {
      console.log("ErrorMess", e);
      setModalData({
        title: "Error",
        message: e.message,
      });
      setIcon("warning");
      setOpenModal(true);
    }
  };

  return (
    <div className="w-full h-full  ">
      <HrSetupsCustomToolbar
        toggler={setToggle}
        Switch={toggle}
        searchTextOnchange={(e) => {
          setSearchText({ temp: e, index: searchText.index });
        }}
        checkOnChange={(e) => {
          setActiveStatus({ temp: e, index: activeStatus.index });
        }}
        handleRefresh={() => {
          setSearchText({ temp: "", index: "" });
          setActiveStatus({ temp: "", index: "" });
        }}
        handleFind={() => {
          setSearchText({ temp: searchText.temp, index: searchText.temp });
          setActiveStatus({
            temp: activeStatus.temp,
            index: activeStatus.temp,
          });
        }}
        setVisible={setToggle}
        outerSelected={states.selectedRecords}
        selected_Record={states.selectedRecords}
        withDates={false}
      />
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save record?"
            ? postData()
            : modalData.title === "Update record?"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "question" ? false : true}
      />

      {/* <Switcher alternate={""} > */}
      <div>
        <p className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
          {"Outage Types Details"}
        </p>
      </div>
      {toggle && (
        <div className="flex">
          <aside className=" w-[450px] ">
            <Form
              className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
              name="basic"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 10 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              {/* {formDetails.map(({ id, label, defaultValue, option , idexpr , dataexpr }) => ( */}
              <div className=" w-[630px]">
                <InputsTemplate
                  useCallbackFunc
                  defaultValue={states.name}
                  label={"Name"}
                  inputStyle={{ width: 272 }}
                  disabledStatus={disabled}
                  orderOnchange={(e: any) => {
                    updateStates("name", e);
                    updateStates("shortName", e);
                  }}
                  handleRefresh={() => {
                    updateStates("name", "");
                  }}
                />
                {/* <InputSelect_template
                  handleSearch={
                    debounce((e) => {
                      updateStates("name", { id: 0, name: e });
                      updateStates("shortName", e);
                      updateStates("isOpenDropdown", true);
                    }, 30) // Adjust the debounce delay as needed
                  }
                  // handleSearch={(e: any) => {
                  //   updateStates("name", e);
                  //   updateStates("shortName", e);
                  //   updateStates("isOpenDropdown", true);
                  //   // Adjust the debounce delay as needed
                  // }}
                  selectedValue={(e: any) => {
                    updateStates("name", { id: e.otpIDpk, name: e.otpName });
                    updateStates("shortName", e.otpShtName);
                    updateStates("isOpenDropdown", true);
                  }}
                  handleFocus={() =>
                    updateStates("isOpenDropdown", !states.isOpenDropdown)
                  }
                  isOpenDropdown={states.isOpenDropdown}
                  placeHolder={states.name.name}
                  options={getName}
                  label={"Name"}
                  selectStyle={{ width: 272 }}
                  disabled={disabled}
                  idexpr={"otpIDpk"}
                  dataexp={"otpName"}
                  useCallFunc
                /> */}
              </div>
              <div className=" w-[630px]">
                <InputsTemplate
                  useCallbackFunc
                  span
                  defaultValue={states.shortName}
                  label={"Short Name"}
                  inputStyle={{ width: 300 }}
                  disabledStatus={disabled}
                  orderOnchange={(e: string) => updateStates("shortName", e)}
                />
              </div>
              {/* ))} */}

              <div className=" flex gap-[45px]  ml-[58px] py-1  w-[500px]">
                {checkBox.map(({ id, label, defaultValue, stateName }) => (
                  <CheckboxTemlate
                    key={id}
                    defaultValue={defaultValue}
                    label={label}
                    withBorders={true}
                    labelCol={20}
                    customDisabled={disabled}
                    setCheckboxOnchange={(e: any) => {
                      updateStates(stateName!, e);
                    }}
                  />
                ))}
              </div>
              <div className="flex py-2 gap-2  w-[394px] justify-end">
                {!updateMode ? (
                  <div>
                    <SaveButton
                      disableButton={disabled}
                      handleSave={() => {
                        saveFunction();
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <UpdateButton
                      disableButton={disabled}
                      handleUpdate={updateFunction}
                    />
                  </div>
                )}
                {disabled ? (
                  <div>
                    <NewButton
                      new_button_toggler={() => {
                        dispatch(
                          setGeneralValue({
                            expr: "formDisabled",
                            value: false,
                          })
                        );
                        dispatch(
                          setGeneralValue({
                            expr: "clearF",
                            value: true,
                          })
                        );
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <Cancel
                      cancel_button_toggler={() => {
                        dispatch(
                          setGeneralValue({
                            expr: "clearF",
                            value: false,
                          })
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </Form>
          </aside>
          <aside className="w-full">
            {/* <Form.Item
              style={{
                display: "inline-block",
                marginBottom: "1px",
                // width: "100%",
                marginRight:"8px",
              }}
              label={<p className="text-xs">{"Remarks"}</p>}
              className="w-[65%]  mb-1 bg-pink-200"
            >
              <TextArea
                style={{
                    marginTop: "8px",
                  maxHeight: "70vh",
                  minHeight: "70px",
                  height: "120px",
                  width: "98%",
                }}
                className="overflow-y-scroll "
               
              />
          </Form.Item> */}
            <Form.Item
              className="pt-2 pl-[40px]"
              label={<p className="text-[12px]">Remarks</p>}
            >
              <div className="w-full pr-2">
                <TextareaTemplate
                  height={125}
                  useCallbackFunc
                  setCallbackValue={(e: any) => {
                    updateStates("remarks", e);
                  }}
                  defaultValue={states.remarks}
                />
              </div>
            </Form.Item>
          </aside>
        </div>
      )}
      {/* </Switcher> */}
      <section className="flex-grow">
        <section className="w-full h-full">
          <EquipmentDataTable
            // keyExpr="otpIDpk"
            gridheight={toggle ? 615 : 780}
            pageSize={paging.pageSize}
            pageNumber={paging.pageNumber}
            handlePageSize={(e: number) => {
              UpdateStates("pageSize", e, setPaging);
            }}
            handlePageNumber={(e: number) => {
              UpdateStates("pageNumber", e, setPaging);
            }}
            showColumnHeaders={true}
            columns={outagecol}
            data={mainGrid}
            onRowClick={(e) => {
              updateStates("selectedRecords", e);
              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              dispatch(setGeneralValue({ expr: "clearF", value: false }));
            }}
            rowDoubleClicked={(e) => {
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              dispatch(setGeneralValue({ expr: "updateMode", value: true }));
              setToggle(true);
            }}
          />
        </section>
      </section>
    </div>
  );
};
