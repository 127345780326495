import React, { useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import {
  AlertSMS,
  AppraiseSettings,
  DeleteTask,
  TaskSettings,
} from "./widgets/customToolbarModals";
import question from "../../../assets/question.png";
import edit from "../../../assets/edit.png";
import close from "../../../assets/close.png";
import statusLoader from "../../../assets/statusloader.gif";

import newfile from "../../../assets/new.png";
import refresh from "../../../assets/refresh.png";
import printer from "../../../assets/printer.png";
import alter from "../../../assets/alter.png";
import { Checkbox, DatePicker, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../features/generalSlice";
import {
  task_form_disable,
  task_footer_update,
} from "../../../features/Task&Todo/Task/TaskDetails";
import { useDispatch } from "react-redux";
import { currentDate, saveModalData, startingDate } from "../component_infos";
import { ModalTemplate } from "../../../templates/modal";
import { FaBinoculars } from "react-icons/fa";
import dayjs from "dayjs";

interface props {
  toggler?: () => void;
  startDateChanged?: () => void;
  endDateChanged?: () => void;
  startDate?: string;
  endDate?: string;
}

export const StrategicTheme_Toolbar = ({
  toggler,
  startDateChanged,
  endDateChanged,
  startDate,
  endDate,
}: props) => {
  const dispatch = useDispatch();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);

  const handleChange = (value: string) => {};

  const [searchValue, setSearchValue] = useState<string>();

  // from redux
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );

  // relative to the new button
  const handleNew = () => {
    dispatch(task_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showCancel, setshowCancel] = useState(false);

  //handle edit
  const handleUpdate = () => {
    if (Object.keys(selectedRecord).length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No record selected",
        title: "Invalid record",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      //enable Update Mode
      dispatch(task_footer_update(true));
      //enable forms
    }
  };

  return (
    <div
      style={{ width: "4000px", borderColor: borderTheme }}
      className=" h-full border-[1px] rounded px-0.5 flex justify-between border-gray-200 mb-1 mx-1"
    >
      <div className="flex justify-center">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 40}
          icon={icon}
          cancelText={modalData.cancelText}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
          disableCancel={showModalDisableButton}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {/* new and cancel section */}
          {disablex || updateMode ? (
            <li
              id="newRecord"
              onClick={() => handleNew()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                handleUpdate();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img alt="" className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          <li
            id="switch"
            onClick={toggler}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="h-2">Switch interface / table view</p>
            </Tooltip>
          </li>

          <li
            id="help"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                src={question}
              />{" "}
            </span>
            <Tooltip
              target="#help"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Help Center</p>
            </Tooltip>
          </li>

          {/* Differents starts here */}

          <li className="items-right ml-14 flex ">
            <label className="flex text-xs px-2 items-center">
              Search Critiria
            </label>
            <Select
              defaultValue="Department"
              style={{ width: 150 }}
              onChange={handleChange}
              options={data}
              size="small"
              placeholder="Department"
              dropdownStyle={{ minWidth: "250px" }}
            />
          </li>

          <li className="px-1 flex items-center ml-6 ">
            <label className="flex text-xs px-2 items-center">
              Search Text
            </label>
            <Input
              name="search"
              id="search"
              size="small"
              className="h-6"
              placeholder=""
              style={{ width: "150px" }}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              value={dayjs(startDate ?? startingDate)}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={startDateChanged}
              size="small"
              placeholder="Start Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>

          <li className="px-0.5">
            <DatePicker
              // value={dayjs(endDate) ?? currentDate}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={endDateChanged}
              size="small"
              placeholder="End Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>
          <li className="px-0.5 mr-1">
            <Checkbox
              className="border-[1px] px-1 rounded"
              style={{ borderColor: borderTheme }}
            />
          </li>

          <li
            onClick={() => {
              setSearchValue("");
            }}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                alt=""
                className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={refresh}
              />{" "}
            </span>
          </li>
          <li
            id="find"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <FaBinoculars size={15.5} color="#007AFF" />{" "}
            </span>
            <Tooltip
              target="#find"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Find</p>
            </Tooltip>
          </li>
          <li
            id="print"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />{" "}
            </span>
            <Tooltip
              target="#print"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Print / Export</p>
            </Tooltip>
          </li>

          <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
            {disablex && (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}

            {!disablex && (
              <div className="  flex flex-row items-center">
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                {!updateMode ? (
                  <p className=" italic text-gray-600">New Record</p>
                ) : (
                  <p className=" italic text-gray-600">Editing</p>
                )}
              </div>
            )}
          </li>
        </ul>
      </div>

      <div className="">
        <ul className=" "></ul>

        <DeleteTask isActive={isActive} setIsActive={setIsActive} />
        <TaskSettings
          SettingsisActive={SettingsisActive}
          SettingssetIsActive={SettingssetIsActive}
        />
        <AlertSMS
          AlertSMSisActive={AlertSMSisActive}
          AlertSMSsetIsActive={AlertSMSsetIsActive}
        />
        <AppraiseSettings
          AppraiseisActive={AppraiseisActive}
          AppraisesetIsActive={AppraisesetIsActive}
        />
      </div>
    </div>
  );
};

const data = [
  { label: "Department", value: "Department" },
  { label: "Section", value: "Section" },
  { label: "Initiative", value: "Initiative" },
  { label: "Objective", value: "Objective" },
  { label: "Theme", value: "Theme" },
  { label: "Mission/Vision", value: "Mission/Vision" },
];
