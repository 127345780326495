export const PC1_cols = [
  {
    id: 0,
    caption: "REQ.No",
    dataField: "pgaRequestNo",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 2,
    caption: "GUARANTEEE TYPE",
    dataField: "pgpName",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 3,
    caption: "SUBM?",
    dataField: "pgaSubmitted",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 4,
    caption: "RECVD?",
    dataField: "pgaReceived",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id:5,
    caption: "FWDED?",
    dataField: "pgaForwarded",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 6,
    caption: "ACKNOW?",
    dataField: "pgaAcknowledged",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 7,
    caption: "APRVD?",
    dataField: "pgaApproved",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 8,
    caption: "JHA SUBM?",
    dataField: "pgaJhaSubmitted",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 9,
    caption: "CLEARED?",
    dataField: "pgaCleared",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 10,
    caption: "SURNDD?",
    dataField: "pgaSurrendered",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 11,
    caption: "CANCLD?",
    dataField: "pgaCancelled",
    alignment: "left",
    dataType:"boolean",
    fixed: true,
    width: 50,
  },
  {
    id: 12,
    caption: "EMGNCY?",
    dataField: "pgaEmergency",
    alignment: "left",
    dataType:"boolean",
    fixed: false,
    width: 50,
  },
  {
    id: 13,
    caption: "ACTIVE?",
    dataField: "pgaActive",
    alignment: "left",
    dataType:"boolean",
    fixed: false,
    width: 50,
  },
  {
    id: 14,
    caption: "TO BE ISSUED ON",
    dataField: "pgaTobeIssuedOn",
    alignment: "left",
    dataType:"datetime",
    fixed: false,
    width: 200,
  },
  {
    id: 15,
    caption: "TO BE SURRENDERED ON",
    dataField: "pgaTobeSurrenderedOn",
    alignment: "left",
    dataType:"datetime",
    fixed: false,
    width: 200,
  },
  {
    id: 16,
    caption: "TO BE ISSUED TO",
    dataField: "pgaTobeIssuedTo",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 17,
    caption: "DESCRIPTION OF APPARATUS, WORK, TEST AND DANGERS...",
    dataField: "pgaApparatusDescription",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 18,
    caption: "WORKING ST.TIME",
    dataField: "pgaWorkingStartTime",
    alignment: "left",
    dataType:"datetime",
    fixed: false,
    width: 200,
  },
  {
    id: 19,
    caption: "WORKING END.TIME",
    dataField: "pgaWorkingEndTime",
    alignment: "left",
    dataType:"datetime",
    fixed: false,
    width: 200,
  },
  {
    id: 20,
    caption: "OUT SWITCH.ST.TIME",
    dataField: "pgaOutageSwitchingStartTime",
    alignment: "left",
    dataType:"datetime",
    fixed: false,
    width: 200,
  },
  {
    id: 21,
    caption: "OUT SWITCH.END TIME",
    dataField: "pgaOutageSwitchingEndTime",
    alignment: "left",
    dataType:"datetime",
    fixed: false,
    width: 200,
  },
  {
    id: 22,
    caption: "OUT SWITCH.TM (MINS)",
    dataField: "pgaOutageSwitchingTime",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 23,
    caption: "REST. SWITCH.ST.TIME",
    dataField: "pgaRestoreSwitchingStartTime",
    dataType:"datetime",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 24,
    caption: "REST. SWITCH.END.TIME",
    dataField: "pgaRestoreSwitchingEndTime",
    dataType:"datetime",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 25,
    caption: "REST. SWITCH.TM",
    dataField: "pgaRestoreSwitchingTime",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 26,
    caption: "TOT. SWITCH.TIME",
    dataField: "pgaSwitchingTimeAll",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 27,
    caption: "DOWN TIME",
    dataField: "pgaDownTime",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 28,
    caption: "RECEIPT DATE",
    dataField: "pgaReceivedOn",
    dataType:"datetime",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 29,
    caption: "AFFECTED EQUIPMENT",
    dataField: "pgaAffectedEquipment",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 30,
    caption: "ADDITIONAL APPARATUS",
    dataField: "pgaAdditionalApparatus",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 31,
    caption: "NOTES ON RE_OUTAGE",
    dataField: "pgaNotesOnReoutage",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 32,
    caption: "APPLICANT",
    dataField: "pgaApplicantsName",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 33,
    caption: "ADVANCED REQUEST BY",
    dataField: "pgaAdvancedRequestBy",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 34,
    caption: "SUBMITTED BY",
    dataField: "pgaSubmittedBy",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 35,
    caption: "RECIEVED BY",
    dataField: "pgaReceivedBy",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 36,
    caption: "APPROVED BY(SYS.OPR)",
    dataField: "pgaApprovedBy",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 37,
    caption: "FINAL APPROVAL (ISSUING OPR)",
    dataField: "pgaClearedBy",
    alignment: "left",
    fixed: false,
    width: 200,
  },
  {
    id: 39,
    caption: "REMARKS",
    dataField: "pgaRmks",
    alignment: "left",
    fixed: false,
    width: 200,
  },
];
