/* eslint-disable react/jsx-pascal-case */
import dayjs, { Dayjs } from "dayjs";
import { DateTemplate } from "../../templates/date";
import { InputsTemplate } from "../../templates/input";
import InputSelect_template from "../../templates/InputSelect";
import { DatePicker, Form, message } from "antd";
import { CheckboxTemlate } from "../../templates/checkbox";
import { TextareaTemplate } from "../../templates/textarea";
import {
  Cancel,
  CustomizableButton,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../accessories/buttons";
import submit from "../../assets/play_submitted.png";
import eyeopen from "../../assets/eyeopen.png";
import forward from "../../assets/up-arrow.png";
import thumbsup from "../../assets/thumbsup.png";
import approve from "../../assets/apply_16x16.png";
import checkBlue from "../../assets/check-blue.png";
import downward from "../../assets/downward.png";
import setting from "../../assets/setting.png";
import saveIcon from "../../assets/save-small.png";
import cancel from "../../assets/cancel_16x16.png";
import newbutton from "../../assets/new-small.png";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useCallback, useEffect, useState } from "react";
import { cx } from "classix";
import { currentDate } from "../accessories/component_infos";
import { Tooltip } from "devextreme-react";
import useFetch from "../../hooks/useFetch";
import { ModalTemplate } from "../../templates/modal";
import {
  formModalStates,
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../human-resource/setups/data/types/selectsTypes";
import { FormModal } from "../accessories/formModal";
import { setGeneralValue } from "../../features/generalSlice";
import { useDispatch } from "react-redux";
import { GetDataFunc } from "../../functions/get";
import { useCrudFunc } from "../../functions/crud";
import { useAccountInfo } from "../../hooks/useAccountInfo";
import React from "react";

interface props {
  selectedRecord?: any;
  setSelectedRecord?: (e: any) => void;
  refreshState?: () => void;
}
interface states {
  station: { id: number; name: string };
  protection_guarantee_type: { id: number; name: string };
  to_be_issued_on: any;
  to_be_surrendered_on: any;
  working_start_time: any;
  working_end_time: any;
  to_be_issued_to: { id: number; name: string };
  applicant_company: { id: number; name: string };
  apparatus_danger_to_user: string;
  apparatus_safe_to_user: string;
  comments: string;
  request_No: string;
  advanced_req_by: { id: number; name: string };
  applicant_name: { id: number; name: string };
  recieved_by: { id: number; name: string };
  recieved_date: any;
  applicantion_date: any;
  apparatus_outage: string;
  blank: string;
  working_time: string;
  switching_time: string;
  downTime: string;
  approved_by: { id: number; name: string };
  final_approver: { id: number; name: string };
  active: boolean;
  emergency: boolean;
  affected_equipment: string;
  notes_Re_Outage: string;
  remarks: string;
  outage_duration: any;
  outage_start_at: any;
  outage_end_at: any;
  restore_duration: any;
  restore_start_at: any;
  restore_end_at: any;
  duration1: string;
  duration2: string;
}

export default function ProtectionGuaranteeForm({
  selectedRecord,
  setSelectedRecord,
  refreshState,
}: props) {
  const drawerOpened = useSelector(
    (state: RootState) => state.general.drawerOpened
  );

  const alertsOpened = useSelector(
    (state: RootState) => state.general.SideAlert
  );
  const openRescheduleModal = useSelector(
    (state: RootState) => state.general.openRescheduleModal
  );
  const clearPCT = useSelector((state: RootState) => state.general.clearPCT);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const dateFormat = " DD MMM YYYY HH: mm";
  const dateFormat2 = "YYYY-MM-DDTHH:mm";
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const currentDate = dayjs().format(dateFormat2);
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState<any>({
    title: "",
    message: "",
    okText: "",
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [reschedule, setReschedule] = useState<boolean>(false);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [Posting, Updating, Patching] = useCrudFunc("powerapp");
  const [userID, empID, userInfo] = useAccountInfo();
  console.log("selectedRecord", selectedRecord);
  // if drawer and alerts is opened set width from 70% to 92%
  // if only drawer is opened set width from 70% to 80% plus pl-2
  // if only alerts is opened set width from 70% to 80% plus pl-3.5

  const [states, setStates] = useState<states>({
    station: { id: 0, name: "" },
    protection_guarantee_type: { id: 0, name: "" },
    to_be_issued_on: currentDate,
    to_be_surrendered_on: currentDate,
    working_start_time: currentDate,
    working_end_time: currentDate,
    to_be_issued_to: { id: 0, name: "" },
    applicant_company: { id: 0, name: "" },
    apparatus_danger_to_user: "",
    apparatus_safe_to_user: "",
    comments: "",
    request_No: "",
    advanced_req_by: { id: 0, name: "" },
    applicant_name: { id: 0, name: "" },
    recieved_by: { id: 0, name: "" },
    recieved_date: currentDate,
    applicantion_date: currentDate,
    apparatus_outage: "",
    blank: "",
    working_time: "",
    switching_time: "",
    downTime: "",
    approved_by: { id: 0, name: "" },
    final_approver: { id: 0, name: "" },
    active: true,
    emergency: false,
    affected_equipment: "",
    notes_Re_Outage: "",
    remarks: "",
    outage_duration: "",
    outage_start_at: currentDate,
    outage_end_at: currentDate,
    restore_duration: "",
    restore_start_at: currentDate,
    restore_end_at: currentDate,
    duration1: "",
    duration2: "",
  });

  const updateState = (stateName: string, value: any) => {
    setStates((currentState: any) => ({ ...currentState, [stateName]: value }));
  };
  console.log("the states:", states);
  const populateFields = (clear?: boolean) => {
    const gridState = [
      {
        key: "station",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.stnIDpk,
              name: selectedRecord?.stnName,
            },
      },
      {
        key: "protection_guarantee_type",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.pgaGuaranteeTypeIDfk,
              name: selectedRecord?.pgpName,
            },
      },
      {
        key: "to_be_issued_on",
        value: clear ? currentDate : selectedRecord?.pgaTobeIssuedOn,
      },
      {
        key: "to_be_surrendered_on",
        value: clear ? currentDate : selectedRecord?.pgaTobeSurrenderedOn,
      },
      {
        key: "working_start_time",
        value: clear ? currentDate : selectedRecord?.pgaWorkingStartTime,
      },
      {
        key: "working_end_time",
        value: clear ? currentDate : selectedRecord?.pgaWorkingEndTime,
      },
      {
        key: "to_be_issued_to",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.pgaTobeIssuedToEmpIDfk,
              name: selectedRecord?.pgaTobeIssuedTo,
            },
      },
      {
        key: "applicant_company",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: 0,
              name: selectedRecord?.pgaApplicantsOrganisation,
            },
      },
      {
        key: "apparatus_danger_to_user",
        value: clear ? "" : selectedRecord?.pgaApparatusDescription,
      },
      {
        key: "apparatus_safe_to_user",
        value: clear ? "" : selectedRecord?.pgaAdditionalApparatus,
      },
      {
        key: "request_No",
        value: clear ? "" : selectedRecord?.pgaRequestNo ?? requestNo_data,
      },
      {
        key: "advanced_req_by",
        value: clear
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord?.pgaAdvancedRequestBy },
      },
      {
        key: "applicant_name",
        value: clear
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord?.pgaApplicantsName },
      },
      {
        key: "recieved_by",
        value: clear
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord?.pgaReceivedBy },
      },
      {
        key: "recieved_date",
        value: clear ? currentDate : selectedRecord?.pgaReceivedOn,
      },
      {
        key: "applicantion_date",
        value: clear ? currentDate : selectedRecord?.pgaApplicationDate,
      },
      {
        key: "applicantion_date",
        value: clear ? currentDate : selectedRecord?.pgaApplicationDate,
      },
      {
        key: "working_time",
        value: clear ? "0 Hrs, 0 mins" : selectedRecord?.pgaWorkingTime,
      },
      {
        key: "switching_time",
        value: clear ? "" : selectedRecord?.pgaSwitchingTimeAll,
      },
      {
        key: "downTime",
        value: clear ? "" : selectedRecord?.pgaDownTime,
      },
      {
        key: "approved_by",
        value: clear
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord?.pgaApprovedBy },
      },
      {
        key: "final_approver",
        value: clear
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord?.pgaApprovedBy },
      },
      {
        key: "active",
        value: clear ? true : selectedRecord?.pgaActive,
      },
      {
        key: "emergency",
        value: clear ? false : selectedRecord?.pgaEmergency,
      },
      {
        key: "affected_equipment",
        value: clear ? "" : selectedRecord?.pgaAffectedEquipment,
      },
      {
        key: "notes_Re_Outage",
        value: clear ? "" : selectedRecord?.pgaNotesOnReoutage,
      },
      {
        key: "remarks",
        value: clear ? "" : selectedRecord?.pgaRmks,
      },
      // {
      //   key: "apparatus_outage",
      //   value: clear
      //     ? ""
      //     :selectedRecord?.pgaApplicationDate ,
      // },
      // {
      //   key: "comments",
      //   value: clear
      //     ? ""
      //     :selectedRecord?.pgaAdditionalApparatus
      // },
    ];
    for (let instance of gridState) updateState(instance.key, instance.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedRecord]);

  useEffect(() => {
    if (openRescheduleModal === true) {
      setReschedule(true);
    }
  }, [openRescheduleModal]);

  //time calculation
  useEffect(() => {
    const { working_start_time, working_end_time } = states;

    // Convert the string values back to dayjs objects for calculation
    const startTime = dayjs(working_start_time);
    const endTime = dayjs(working_end_time);

    // Make sure both times are valid dayjs objects
    if (startTime.isValid() && endTime.isValid()) {
      const durationMinutes = endTime.diff(startTime, "minute");
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      updateState("working_time", `${hours} Hrs, ${minutes} mins`);
    }
  }, [states.working_start_time, states.working_end_time]);

  useEffect(() => {
    const { to_be_issued_on, to_be_surrendered_on } = states;

    // Convert the string values back to dayjs objects for calculation
    const startTime = dayjs(to_be_issued_on);
    const endTime = dayjs(to_be_surrendered_on);

    // Make sure both times are valid dayjs objects
    if (startTime.isValid() && endTime.isValid()) {
      const durationMinutes = endTime.diff(startTime, "minute");
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      updateState("duration1", `${hours} Hrs, ${minutes} mins`);
    }
  }, [states.to_be_issued_on, states.to_be_surrendered_on]);
  ///////Outage duration calculation//////////
  useEffect(() => {
    const { outage_start_at, outage_end_at } = states;

    // Convert the string values back to dayjs objects for calculation
    const startTime = dayjs(outage_start_at);
    const endTime = dayjs(outage_end_at);

    // Make sure both times are valid dayjs objects
    if (startTime.isValid() && endTime.isValid()) {
      const durationMinutes = endTime.diff(startTime, "minute");
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      updateState("outage_duration", minutes);
    }
  }, [states.outage_start_at, states.outage_end_at]);

  useEffect(() => {
    const { outage_start_at, outage_duration } = states;

    // Convert the string value back to a dayjs object for calculation
    const startTime = dayjs(outage_start_at);

    // Make sure the start time is a valid dayjs object
    if (startTime.isValid()) {
      // Calculate the new end time based on the start time and duration
      const newEndTime = startTime.add(outage_duration, "minute").toISOString();

      updateState("outage_end_at", newEndTime);
    }
  }, [states.outage_duration]);

  ///////////Restore duration calculation//////////
  useEffect(() => {
    const { restore_start_at, restore_end_at } = states;

    // Convert the string values back to dayjs objects for calculation
    const startTime = dayjs(restore_start_at);
    const endTime = dayjs(restore_end_at);

    // Make sure both times are valid dayjs objects
    if (startTime.isValid() && endTime.isValid()) {
      const durationMinutes = endTime.diff(startTime, "minute");
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      updateState("restore_duration", minutes);
    }
  }, [states.restore_start_at, states.restore_end_at]);

  useEffect(() => {
    const { restore_start_at, restore_duration } = states;

    // Convert the string value back to a dayjs object for calculation
    const startTime = dayjs(restore_start_at);

    // Make sure the start time is a valid dayjs object
    if (startTime.isValid()) {
      // Calculate the new end time based on the start time and duration
      const newEndTime = startTime
        .add(restore_duration, "minute")
        .toISOString();

      updateState("restore_end_at", newEndTime);
    }
  }, [states.restore_duration]);

  //endpoints...
  const [station_data] = useFetch(
    `PGApplicationPC1/GetStations`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [protection_guarantee_type_data] = useFetch(
    `PGApplicationPC1/GetGuaranteeTypes`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [to_be_issued_to_data] = useFetch(
    `PGApplicationPC1/GetToBeIssuedTo?stnIDpk=${states.station.id}`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  const [applicant_company_data] = useFetch(
    `PGApplicationPC1/GetCompanies`,
    true,
    undefined,
    undefined,
    "powerapp"
  );
  // const [requestNo_data] = useFetch(
  //   `PGApplicationPC1/GenerateRequestNo?pgpIDpk=${states.protection_guarantee_type.id}&stnIDpk=${states.station.id}&dtpTobeIssueOn=${states.to_be_issued_on}`,
  //   true,
  //   undefined,
  //   undefined,
  //   "powerapp"
  // );
  const [requestNo_data, setRequestNo_data] = useState<any>([]);
  const requestNo_func = async () => {
    const requestNo_data = await GetDataFunc(
      `PGApplicationPC1/GenerateRequestNo?pgpIDpk=${states.protection_guarantee_type.id}&stnIDpk=${states.station.id}&dtpTobeIssueOn=${states.to_be_issued_on}`,
      undefined,
      "powerapp"
    );
    setRequestNo_data(requestNo_data?.data?.data);
  };
  console.log("requestNo_data", requestNo_data);
  useEffect(() => {
    requestNo_func();
  }, [states.station.id]);
  useEffect(() => {
    if (clearPCT === true) {
      populateFields(true);
    }
  }, [clearPCT]);
  const [openTimeModal, setOpenTimeModal] = useState<boolean>(false);

  //submit button functionality
  const handleSubmit = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaSubmitted === true) {
      setModalData({
        title: "Access denied",
        message: "Application has already been submitted!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Submit Application?",
        message: "Are you sure you want to Submit the selected application?",
        okText: "Submit",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const handleRecieve = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaSubmitted === false) {
      setModalData({
        title: "Access denied",
        message:
          "The application has not been submitted. You can only receive submitted applications",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaReceived === true) {
      setModalData({
        title: "Access denied",
        message: "The application has already been received!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Receive application?",
        message: "Are you sure you want to receive the selected application?",
        okText: "Recieve",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const handleForward = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaReceived === false) {
      setModalData({
        title: "Access denied",
        message:
          "The application has not been Received yet. You can only forward Received applications",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaForwarded === true) {
      setModalData({
        title: "Access denied",
        message: "The application has already been forwarded!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Forward application?",
        message: "Would you like to forward it for approval?",
        okText: "Forward",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const handleAcknowledge = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaForwarded === false) {
      setModalData({
        title: "Access denied",
        message:
          "The application has not been forwarded yet for approval. You can only acknowledge forwarded applications",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaAcknowledged === true) {
      setModalData({
        title: "Access denied",
        message: "The application has already been acknowledged!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Acknowledge application?",
        message:
          "Are you sure you want to Acknowledge the selected application?",
        okText: "Acknowledge",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const handleApproval = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaAcknowledged === false) {
      setModalData({
        title: "Access denied",
        message:
          "The application has not been acknowledged yet for approval. You can only approve acknowledged applications",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaApproved === true) {
      setModalData({
        title: "Access denied",
        message: "The application has already been approved!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Approve application?",
        message: "Are you sure you want to Approve the selected application?",
        okText: "Approve",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const handleClear = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaApproved === false) {
      setModalData({
        title: "Access denied",
        message:
          "The application has not been approved yet for approval. You can only clear approved applications",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaCleared === true) {
      setModalData({
        title: "Access denied",
        message: "The application has already been cleared!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Clear application?",
        message:
          "Are you sure you want to finally Approve the selected application?",
        okText: "Approve",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const handleSurrender = () => {
    if (selectedRecord === undefined || selectedRecord === "") {
      setModalData({
        title: "No record selected",
        message: "Please select a record from the datagrid and continue",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaCleared === false) {
      setModalData({
        title: "Access denied",
        message:
          "The application has Not been given final approval yet. You can only Surrender applications that have been given final clearance by the Operator",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (selectedRecord?.pgaSurrendered === true) {
      setModalData({
        title: "Access denied",
        message: "The application has already been surrendered!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setModalData({
        title: "Surrender application?",
        message: "Are you sure you want to Surrender the selected application?",
        okText: "Surrender",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };

  const submitApp = async () => {
    try {
      await Patching(`PGApplicationPC1/SubmitPGApplicationPC1`, {
        pgaIDpk: selectedRecord?.pgaIDpk,
        stnIDpk: selectedRecord?.stnIDpk,
        pgaCreatedBy: userID,
        pgaToBeIssuedToEmpIDfk: selectedRecord?.pgaTobeIssuedToEmpIDfk,
        formAction: `Submitted the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`,
      });
      setModalData({
        title: "Application submited",
        message: "Application submited successfully!",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };
  const recieveApp = async () => {
    try {
      await Patching(`PGApplicationPC1/ReceivePGApplicationPC1`, {
        pgaIDpk: selectedRecord?.pgaIDpk,
        stnIDpk: selectedRecord?.stnIDpk,
        formAction: `Acknowledged the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`,
      });
      setModalData({
        title: "Application recieved",
        message: "Application has been received successfully.",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };
  const forwardApp = async () => {
    try {
      await Patching(`PGApplicationPC1/ForwardPGApplicationPC1`, {
        pgaIDpk: selectedRecord?.pgaIDpk,
        stnIDpk: selectedRecord?.stnIDpk,
        formAction: `Forwarded the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`,
      });
      setModalData({
        title: "Application forwarded",
        message: "Application has been forwarded successfully.",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };
  const acknowledgeApp = async () => {
    try {
      await Updating(
        `PGApplicationPC1/AcknowledgePGApplicationPC1?StnIDpk=${
          selectedRecord?.stnIDpk
        }&PgaForwarded=${selectedRecord?.pgaForwarded}&PgaIDpk=${
          selectedRecord?.pgaIDpk
        }&formCode=""&formAction=${`Acknowledged the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`}`,
        {}
      );
      setModalData({
        title: "Application acknowledged",
        message: "Application has been acknowledged successfully.",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };
  const approveApp = async () => {
    try {
      await Updating(
        `PGApplicationPC1/ApprovePGApplicationPC1?StnIDpk=${
          selectedRecord?.stnIDpk
        }&PgaAcknowledged=${selectedRecord?.pgaAcknowledged}&PgaIDpk=${
          selectedRecord?.pgaIDpk
        }&formCode=""&formAction=${`Approved the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`}`,
        {}
      );
      setModalData({
        title: "Application approved",
        message: "Application has been approved successfully.",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };
  const clearApp = async () => {
    try {
      await Updating(
        `PGApplicationPC1/ClearPGApplicationPC1?StnIDpk=${
          selectedRecord?.stnIDpk
        }&PgaAcknowledged=${selectedRecord?.pgaAcknowledged}&PgaIDpk=${
          selectedRecord?.pgaIDpk
        }&formCode=""&formAction=${`Cleared (Finally Approved) the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`}`,
        {}
      );
      setModalData({
        title: "Application cleared",
        message: "Application has been cleared successfully.",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };
  const surrenderApp = async () => {
    try {
      await Updating(
        `PGApplicationPC1/SurrenderPGApplicationPC1?StnIDpk=${
          selectedRecord?.stnIDpk
        }&PgaCleared=${selectedRecord?.pgaCleared}&PgaIDpk=${
          selectedRecord?.pgaIDpk
        }&formCode=""&formAction=${`Surrendered the eLog Book-Protection Guarantee- ${states.protection_guarantee_type.name} for the station ${states.station.name} for the record with ID ${selectedRecord?.pgaIDpk}`}`,
        {}
      );
      setModalData({
        title: "Application Surrendered ",
        message: "Application has been surrendered successfully.",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("success");
      refreshState();
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: err?.response?.data?.message,
        title: "Unexpected error! ",
        okText: "Ok",
      });
      setOpenModal(true);
      console.log("subError", err);
    }
  };

  const handleOrderOnChange = useCallback(
    (e: any) => {
      updateState("outage_duration", e);
      console.log("outage_duration", e);
    },
    [updateState]
  );
  //****************************Time modal*************************************** */
  const TimeForm =  React.memo(() => {
    return (
    <div>
      <Form
        className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <DateTemplate
          label="To Be Issued On"
          format={dateFormat}
          disabled={true}
          showTime
          selectedDate={dayjs(states.to_be_issued_on)}
          width={200}
        />
        <DateTemplate
          label="To Be Surrendered On"
          format={dateFormat}
          disabled={true}
          showTime
          selectedDate={dayjs(states.to_be_surrendered_on)}
          width={200}
        />
        <InputsTemplate
          span
          label="Duration"
          inputStyle={{ width: 150 }}
          readOnly={true}
          placeholder={states.duration1}
        />
      </Form>
      <div className="text-center">
        <p>{"Working Times"}</p>
      </div>
      <Form
        className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <DateTemplate
          label="Starts At"
          width={200}
          showTime
          disabled={false}
          selectedDate={dayjs(states.working_start_time)}
          changeDate={(date: Dayjs) => {
            updateState("working_start_time", dayjs(date).format(dateFormat2));
          }}
        />
        <DateTemplate
          label="Ends At"
          width={200}
          disabled={false}
          showTime
          selectedDate={dayjs(states.working_end_time)}
          changeDate={(date: Dayjs) => {
            updateState("working_end_time", dayjs(date).format(dateFormat2));
          }}
        />
        <InputsTemplate
          span
          label="Duration"
          readOnly={true}
          placeholder={states.working_time}
          inputStyle={{ width: 150 }}
        />
      </Form>
      <div className="text-center">
        <p>{"Switching Times"}</p>
      </div>
      <Form
        className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <div className="w-[80%] ml-[38px]">
          <InputsTemplate
            span
            label="Outage Duration (Mins)"
            defaultValue={states.outage_duration}
            inputStyle={{ width: 150 }}
            disabledStatus={false}
            orderOnchange={handleOrderOnChange}
            useCallbackFunc
            numberOnly={true}
          />
        </div>

        <DateTemplate
          label="Starts At"
          width={200}
          showTime
          format={dateFormat}
          disabled={false}
          selectedDate={dayjs(states.outage_start_at)}
          changeDate={(date: Dayjs) => {
            updateState("outage_start_at", dayjs(date).format(dateFormat));
          }}
        />
        <DateTemplate
          label="Ends At"
          width={200}
          showTime
          format={dateFormat}
          disabled={true}
          selectedDate={dayjs(states.outage_end_at)}
          changeDate={(date: Dayjs) => {
            updateState("outage_end_at", dayjs(date).format(dateFormat));
          }}
        />
        <div className="pt-2">
          <div className="w-[80%] ml-[38px]">
            <InputsTemplate
              defaultValue={states.restore_duration}
              span
              label="Restore Duration (Mins)"
              inputStyle={{ width: 150 }}
              disabledStatus={false}
              orderOnchange={(e: any) => {
                updateState("restore_duration", e);
              }}
              useCallbackFunc
              numberOnly={true}
            />
          </div>

          <DateTemplate
            label="Starts At"
            width={200}
            disabled={false}
            showTime
            format={dateFormat}
            selectedDate={dayjs(states.restore_start_at)}
            changeDate={(date: Dayjs) => {
              updateState("restore_start_at", dayjs(date).format(dateFormat));
            }}
          />
          <DateTemplate
            label="Ends At"
            width={200}
            showTime
            format={dateFormat}
            disabled={true}
            selectedDate={dayjs(states.restore_end_at)}
            changeDate={(date: Dayjs) => {
              updateState("restore_end_at", dayjs(date).format(dateFormat));
            }}
          />
        </div>
        <Form.Item
          label={
            <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
              {"Downtime"}
            </p>
          }
          labelCol={{ span: 8 }}
          className="py-2 w-full"
        >
          <div className="w-full flex flex-row justify-between  ">
            <div className="w-full text-blue-600">
              {states.downTime === "" ? `*` : states.downTime}
            </div>
          </div>
        </Form.Item>
      </Form>
      <div className="w-full flex">
        <div className="w-full">
          <button
            id="forward"
            onClick={() => {
              handleForward();
            }}
            style={{ borderColor: borderTheme }}
            className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
          >
            <img className="pr-1" src={forward} />
            {" Forward"}
            <Tooltip
              target={`#forward`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>{`Forward application for approval`}</p>
            </Tooltip>
          </button>
        </div>
        <div className="w-full flex justify-end">
          <UpdateButton />
        </div>
      </div>
    </div>
  );
});

  //*******************************Resheduling modal******************************************************** */
  const RescheduleForm = () => (
    <div>
      <Form
        className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <InputsTemplate
          label="Request No"
          span
          defaultValue={
            states.request_No === "" ? requestNo_data : states.request_No
          }
          useCallbackFunc
          disabledStatus={true}
          orderOnchange={(e: any) => {}}
        />
        <InputSelect_template
          label="Station"
          isOpenDropdown={false}
          handleFocus={(e: any) => {}}
          options={station_data}
          dataexp="stnName"
          idexpr="stnIDpk"
          selectedValue={(e: any) => {
            updateState("station", { id: e.stnIDpk, name: e.stnName });
          }}
          handleRefresh={() => {}}
          handleSearch={() => {}}
          placeHolder={states.station.name}
          useCallFunc={true}
          disabled={false}
          selectStyle={{ width: 262 }}
        />
        <InputSelect_template
          label="Protection Guarantee Type"
          isOpenDropdown={false}
          handleFocus={(e: any) => {}}
          options={protection_guarantee_type_data}
          dataexp="pgpName"
          idexpr="pgpIDpk"
          selectedValue={(e: any) => {
            updateState("protection_guarantee_type", {
              id: e.pgpIDpk,
              name: e.pgpName,
            });
          }}
          handleRefresh={() => {}}
          handleSearch={() => {}}
          placeHolder={states.protection_guarantee_type.name}
          useCallFunc={true}
          disabled={false}
          selectStyle={{ width: 262 }}
        />{" "}
        <DateTemplate
          label="To Be Issued On"
          format={dateFormat}
          disabled={false}
          showTime
          selectedDate={dayjs(states.to_be_issued_on)}
        />
        <DateTemplate
          label="To Be Surrendered On"
          format={dateFormat}
          disabled={false}
          showTime
          selectedDate={dayjs(states.to_be_surrendered_on)}
        />
        <DateTemplate
          label="Working Start Time"
          showTime
          format={dateFormat}
          disabled={false}
          selectedDate={dayjs(states.working_start_time)}
          changeDate={(e: Dayjs) => {}}
        />
        <DateTemplate
          label="Working End Time"
          showTime
          format={dateFormat}
          disabled={false}
          selectedDate={dayjs(states.working_end_time)}
          changeDate={(date: Dayjs) => {
            updateState("working_end_time", dayjs(date).format(dateFormat));
          }}
        />
        <InputSelect_template
          label="To Be Issued To"
          isOpenDropdown={false}
          handleFocus={(e: any) => {}}
          options={to_be_issued_to_data}
          dataexp="empName"
          idexpr="pgaTobeIssuedToEmpIDfk"
          selectedValue={(e: any) => {}}
          handleRefresh={() => {}}
          handleSearch={() => {}}
          placeHolder={states.to_be_issued_to.name}
          useCallFunc={true}
          disabled={false}
          selectStyle={{ width: 262 }}
        />{" "}
      </Form>
      <div className="w-[84%] flex justify-end">
        <button
          id="reschedulex"
          onClick={() => {}}
          style={{ borderColor: borderTheme }}
          className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
        >
          <img className="pr-1" src={saveIcon} />
          {" Reschedule"}
          <Tooltip
            target={`#reschedulex`}
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p>{`Reschedule`}</p>
          </Tooltip>
        </button>
      </div>
    </div>
  );

  return (
    <main className="flex flex-col space-y-1 p-3 w-full">
      <>
        <ModalTemplate
          icon={icon}
          disableCancel={icon === "question" ? false : true}
          okText={modalData.okText}
          title={modalData.title}
          message={modalData.message}
          cancelHandler={() => setOpenModal(false)}
          open={openModal}
          okHandler={() => {
            modalData.title === "Submit Application?"
              ? submitApp()
              : modalData.title === "Receive application?"
              ? recieveApp()
              : modalData.title === "Forward application?"
              ? forwardApp()
              : modalData.title === "Acknowledge application?"
              ? acknowledgeApp()
              : modalData.title === "Approve application?"
              ? approveApp()
              : modalData.title === "Clear application?"
              ? clearApp()
              : modalData.title === "Surrender application?"
              ? surrenderApp()
              : setOpenModal(false);
          }}
        />
        <ModalTemplate
          disableCancel
          width={"30%"}
          customComponent={<TimeForm />}
          title={"Times"}
          message={""}
          cancelHandler={() => {
            setOpenTimeModal(false);
          }}
          open={openTimeModal}
          okHandler={() => {
            setOpenTimeModal(false);
            dispatch(
              setGeneralValue({ expr: "openRescheduleModal", value: false })
            );
          }}
        />
        <ModalTemplate
          disableCancel
          width={"30%"}
          customComponent={<RescheduleForm />}
          title={"Reschedule"}
          message={""}
          cancelHandler={() => {
            setReschedule(false);
            dispatch(
              setGeneralValue({ expr: "openRescheduleModal", value: false })
            );
          }}
          open={reschedule}
          okHandler={() => {
            setReschedule(false);
            dispatch(
              setGeneralValue({ expr: "openRescheduleModal", value: false })
            );
          }}
        />
      </>
      <header className="flex w-3/5 justify-between">
        {/* First segment */}
        <section className="flex flex-col space-y-2">
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
            name="basic"
            labelCol={{ span: 13 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <InputSelect_template
              label="Station"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={station_data}
              dataexp="stnName"
              idexpr="stnIDpk"
              selectedValue={(e: any) => {
                updateState("station", { id: e.stnIDpk, name: e.stnName });
              }}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.station.name}
              useCallFunc={true}
              disabled={disable}
            />{" "}
            <InputSelect_template
              label="Protection Guarantee Type"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={protection_guarantee_type_data}
              dataexp="pgpName"
              idexpr="pgpIDpk"
              selectedValue={(e: any) => {
                updateState("protection_guarantee_type", {
                  id: e.pgpIDpk,
                  name: e.pgpName,
                });
              }}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.protection_guarantee_type.name}
              useCallFunc={true}
              disabled={disable}
            />{" "}
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"To Be Issued On"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <DateTemplate
                  width={157}
                  format={dateFormat}
                  disabled={disable}
                  showTime
                  selectedDate={dayjs(states.to_be_issued_on)}
                  changeDate={(e: Dayjs) => {}}
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"To Be Surrendered On"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <DateTemplate
                  width={157}
                  format={dateFormat}
                  showTime
                  disabled={disable}
                  selectedDate={dayjs(states.to_be_surrendered_on)}
                  changeDate={(e: Dayjs) => {}}
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Working Start Time"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <DateTemplate
                  width={157}
                  showTime
                  format={dateFormat}
                  disabled={disable}
                  selectedDate={dayjs(states.working_start_time)}
                  changeDate={(date: Dayjs) => {
                    updateState(
                      "working_start_time",
                      dayjs(date).format(dateFormat2)
                    );
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Working End Time"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <DateTemplate
                  width={157}
                  showTime
                  format={dateFormat}
                  disabled={disable}
                  selectedDate={dayjs(states.working_end_time)}
                  changeDate={(date: Dayjs) => {
                    updateState(
                      "working_end_time",
                      dayjs(date).format(dateFormat2)
                    );
                  }}
                />
              </div>
            </Form.Item>
            <InputSelect_template
              label="To Be Issued To"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={to_be_issued_to_data}
              dataexp="empName"
              idexpr="pgaTobeIssuedToEmpIDfk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.to_be_issued_to.name}
              useCallFunc={true}
              disabled={disable}
            />{" "}
            <InputSelect_template
              label="Applicant's Company"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={applicant_company_data}
              dataexp="pgaApplicantsOrganisation"
              idexpr="sstIdpk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.applicant_company.name}
              useCallFunc={true}
              disabled={disable}
            />{" "}
          </Form>
        </section>
        {/* Second segment */}
        <section className="flex flex-col space-y-2">
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
            name="basic"
            labelCol={{ span: 13 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Request No."}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <InputsTemplate
                  inputStyle={{ width: 157 }}
                  parentClassName="mr-1"
                  span
                  defaultValue={
                    states.request_No === ""
                      ? requestNo_data
                      : states.request_No
                  }
                  useCallbackFunc
                  readOnly={true}
                  orderOnchange={(e: any) => {}}
                />
              </div>
            </Form.Item>
            <InputSelect_template
              label="Advanced Request By"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={[]}
              dataexp="sstName"
              idexpr="sstIdpk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.advanced_req_by.name}
              useCallFunc={true}
              disabled={disable}
            />{" "}
            <InputSelect_template
              label="Applicant's Name"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={[]}
              dataexp="sstName"
              idexpr="sstIdpk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.applicant_name.name}
              useCallFunc={true}
              disabled={true}
            />{" "}
            <InputSelect_template
              label="Received By"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={[]}
              dataexp="sstName"
              idexpr="sstIdpk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.recieved_by.name}
              useCallFunc={true}
              disabled={true}
            />{" "}
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Received Date"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <DateTemplate
                  width={157}
                  format={dateFormat}
                  disabled={true}
                  selectedDate={dayjs(states.recieved_date)}
                  changeDate={(e: Dayjs) => {}}
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Application Date"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <DateTemplate
                  width={157}
                  format={dateFormat}
                  disabled={true}
                  selectedDate={dayjs(states.applicantion_date)}
                  changeDate={(e: Dayjs) => {}}
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Aparatus Outages"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <InputsTemplate
                inputStyle={{ width: 157 }}
                parentClassName="mr-1"
                span
                defaultValue={states.apparatus_outage}
                useCallbackFunc
                orderOnchange={(e: any) => {}}
                readOnly={true}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {""}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <InputsTemplate
                inputStyle={{ width: 157 }}
                parentClassName="mr-1"
                span
                defaultValue={states.blank}
                useCallbackFunc
                orderOnchange={(e: any) => {}}
                readOnly={true}
              />
            </Form.Item>
          </Form>
        </section>
        {/* Third segment */}
        <section className="flex flex-col space-y-2">
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  "
            name="basic"
            labelCol={{ span: 13 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Working Time"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <InputsTemplate
                  inputStyle={{ width: 157, backgroundColor: "##fef9c3" }}
                  parentClassName="mr-1"
                  span
                  defaultValue={states.working_time}
                  useCallbackFunc
                  readOnly={true}
                  orderOnchange={(e: any) => {}}
                  style={{ backgroundColor: "##fef9c3" }}
                  additionalWidget={
                    <div
                      className="cursor-pointer"
                      onClick={(e: any) => {
                        setOpenTimeModal(true);
                        e.preventDefault();
                      }}
                    >
                      <img className="pr-1" src={setting} />
                    </div>
                  }
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Switching Time"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <InputsTemplate
                  inputStyle={{ width: 157, backgroundColor: "##fef9c3" }}
                  parentClassName="mr-1"
                  span
                  defaultValue={states.switching_time}
                  useCallbackFunc
                  style={{ backgroundColor: "##fef9c3" }}
                  disabledStatus={false}
                  readOnly={true}
                  orderOnchange={(e: any) => {}}
                />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Downtime"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <div className="w-full text-blue-600">
                  {states.downTime === "" ? `*` : states.downTime}
                </div>
                {/* <InputsTemplate
                  inputStyle={{ width: 157, backgroundColor: "##fef9c3" }}
                  parentClassName="mr-1"
                  span
                  defaultValue={states.downTime}
                  readOnly
                  style={{ backgroundColor: "#e7e5e4" }}
                  useCallbackFunc
                  disabledStatus={false}
                  orderOnchange={(e: any) => {}}
                /> */}
              </div>
            </Form.Item>
            <InputSelect_template
              label="Approved By (System Opr)"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={[]}
              dataexp="sstName"
              idexpr="sstIdpk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.approved_by.name}
              useCallFunc={true}
              disabled={true}
            />{" "}
            <InputSelect_template
              label="Final Approver (Issuing Opr)"
              style={{ width: 400 }}
              isOpenDropdown={false}
              handleFocus={(e: any) => {}}
              options={[]}
              dataexp="sstName"
              idexpr="sstIdpk"
              selectedValue={(e: any) => {}}
              handleRefresh={() => {}}
              handleSearch={() => {}}
              placeHolder={states.final_approver.name}
              useCallFunc={true}
              disabled={true}
            />{" "}
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Active?"}
                </p>
              }
              labelCol={{ span: 13 }}
            >
              <div className="w-full flex flex-row justify-between  ">
                <CheckboxTemlate
                  setCheckboxOnchange={(e) => {}}
                  defaultValue={states.active}
                />
                <CheckboxTemlate
                  label={`Emergency?`}
                  setCheckboxOnchange={(e) => {}}
                  defaultValue={states.emergency}
                />
              </div>
            </Form.Item>
          </Form>
        </section>
      </header>
      <section className="flex w-full flex-col">
        <div
          className={cx(
            "flex justify-between transition-all duration-500 ease-in-out",
            // alertsOpened && drawerOpened && "w-[94%]",
            !alertsOpened && drawerOpened && "w-[80%] pl-2",
            alertsOpened && !drawerOpened && "w-[80%] pl-3.5",
            !alertsOpened && !drawerOpened && "w-[70%]"
          )}
        >
          <aside className="flex flex-col w-1/2">
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
                  {
                    "Description of Aparatus, Work, tests, and any Dangers Unknown to the Issuer"
                  }
                </p>
              }
              labelCol={{ span: 8 }}
            >
              <TextareaTemplate
                // readonly={formData.disabled}
                disabled={disable}
                height={70}
                useCallbackFunc={true}
                defaultValue={states.apparatus_danger_to_user}
                setCallbackValue={(val) => {}}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
                  {"Additional Apparatus for Safe Working Space"}
                </p>
              }
              labelCol={{ span: 8 }}
            >
              <TextareaTemplate
                // readonly={formData.disabled}
                disabled={disable}
                height={70}
                useCallbackFunc={true}
                defaultValue={states.apparatus_safe_to_user}
                setCallbackValue={(val) => {}}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
                  {"Comments"}
                </p>
              }
              labelCol={{ span: 8 }}
            >
              <TextareaTemplate
                // readonly={formData.disabled}
                disabled={disable}
                height={70}
                useCallbackFunc={true}
                defaultValue={states.comments}
                setCallbackValue={(val) => {}}
              />
            </Form.Item>
          </aside>
          <aside className="flex flex-col w-1/2">
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
                  {"Affected Equipment"}
                </p>
              }
              labelCol={{ span: 8 }}
            >
              <TextareaTemplate
                // readonly={formData.disabled}
                disabled={disable}
                height={70}
                useCallbackFunc={true}
                defaultValue={states.affected_equipment}
                setCallbackValue={(val) => {}}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
                  {"Notes Re:Outage"}
                </p>
              }
              labelCol={{ span: 8 }}
            >
              <TextareaTemplate
                // readonly={formData.disabled}
                disabled={disable}
                height={70}
                useCallbackFunc={true}
                defaultValue={states.notes_Re_Outage}
                setCallbackValue={(val) => {}}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
                  {"Remarks"}
                </p>
              }
              labelCol={{ span: 8 }}
            >
              <TextareaTemplate
                // readonly={formData.disabled}
                disabled={disable}
                height={70}
                useCallbackFunc={true}
                defaultValue={states.remarks}
                setCallbackValue={(val) => {}}
              />
            </Form.Item>
          </aside>{" "}
        </div>
        {/* buttons are below */}
        <div
          className={cx(
            "flex justify-between transition-all duration-500 ease-in-out",
            // alertsOpened && drawerOpened && "w-[94%]",
            !alertsOpened && drawerOpened && "w-[80%] pl-2",
            alertsOpened && !drawerOpened && "w-[80%] pl-3.5",
            !alertsOpened && !drawerOpened && "w-[70%]"
          )}
        >
          {/* sized-box start */}
          <div
            className={cx(
              !alertsOpened && drawerOpened && "w-[210px]",
              alertsOpened && drawerOpened && `w-[210px]`,
              !alertsOpened && !drawerOpened && "w-[210px]",
              alertsOpened && !drawerOpened && "w-[200px]"
            )}
          ></div>
          {/* sized-box end */}
          <aside
            className={cx(
              "flex justify-center",
              alertsOpened && drawerOpened && "pl-1",
              alertsOpened && !drawerOpened && "pl-0"
            )}
          >
            <Form.Item
              //   label={
              //     <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
              //       {"    "}
              //     </p>
              //   }
              labelCol={{ span: 30 }}
            >
              <div className="flex items-center space-x-3 flex-1">
                {/* <CustomizableButton
                  isTooltip
                  isText
                  text="Submit"
                  buttonID="Submit"
                  tooltipMsg="Submit application to the selected station for processing"
                  classname={"flex items-center py-1.5 px-3 space-x-2"}
                  customizableBtnImage={submit}
                  handleCustomizableClick={() => {}}
                /> */}
                <button
                  id="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={submit} />
                  {" Submit"}
                  <Tooltip
                    target={`#submit`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Submit application to the selected station for processing`}</p>
                  </Tooltip>
                </button>
                <button
                  id="receive"
                  onClick={() => {
                    handleRecieve();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={eyeopen} />
                  {"Receive"}
                  <Tooltip
                    target={`#receive`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Receive application for processing`}</p>
                  </Tooltip>
                </button>
                <button
                  id="forward"
                  onClick={() => {
                    handleForward();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={forward} />
                  {" Forward"}
                  <Tooltip
                    target={`#forward`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Forward application for approval`}</p>
                  </Tooltip>
                </button>
                <button
                  id="acknowledge"
                  onClick={() => {
                    handleAcknowledge();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={thumbsup} />
                  {" Acknowledge"}
                  <Tooltip
                    target={`#acknowledge`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Acknowledge receipt of application, pending approval`}</p>
                  </Tooltip>
                </button>
                <button
                  id="approve"
                  onClick={() => {
                    handleApproval();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={approve} />
                  {"Approve (NCC)"}
                  <Tooltip
                    target={`#approve`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Approve application`}</p>
                  </Tooltip>
                </button>
                <button
                  id="clear"
                  onClick={() => {
                    handleClear();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={checkBlue} />
                  {"Clear (Opr)"}
                  <Tooltip
                    target={`#clear`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Give clearance to application for work to begin`}</p>
                  </Tooltip>
                </button>
                <button
                  id="surrender"
                  onClick={() => {
                    handleSurrender();
                  }}
                  style={{ borderColor: borderTheme }}
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                >
                  <img className="pr-1" src={downward} />
                  {"Surrender"}
                  <Tooltip
                    target={`#surrender`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p>{`Submit application to the selected station for processing`}</p>
                  </Tooltip>
                </button>
              </div>
            </Form.Item>
          </aside>
          <aside className="flex-1">
            <Form.Item
              //   label={
              //     <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs text-wrap">
              //       {"Affected Equipment"}
              //     </p>
              //   }
              className="flex justify-end"
              labelCol={{ span: 8 }}
            >
              <div className="flex  gap-2 justify-end ">
                {!updateMode ? (
                  <div>
                    <SaveButton disableButton={disable} handleSave={() => {}} />
                  </div>
                ) : (
                  <div>
                    <UpdateButton
                      disableButton={disable}
                      handleUpdate={() => {}}
                    />
                  </div>
                )}
                {disable ? (
                  <div id="newRec">
                    <NewButton
                      new_button_toggler={() => {
                        dispatch(
                          setGeneralValue({
                            expr: "formDisabled",
                            value: false,
                          })
                        );
                        populateFields(true);
                      }}
                    />
                    <Tooltip
                      target={`#newRec`}
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p>{`Create new record`}</p>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Cancel
                      cancel_button_toggler={() => {
                        dispatch(
                          setGeneralValue({ expr: "clearPCT", value: false })
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              {/* <div className="flex items-center space-x-3 flex-1">
                <CustomizableButton
                  isText
                  text="Save"
                  buttonID="save"
                  classname={"flex items-center py-1.5 px-3 space-x-2"}
                  customizableBtnImage={saveFile}
                  handleCustomizableClick={() => {}}
                />
                <CustomizableButton
                  isText
                  text="New"
                  buttonID="new"
                  classname={"flex items-center py-1.5 px-3 space-x-2"}
                  customizableBtnImage={newbutton}
                  handleCustomizableClick={() => {}}
                /> */}
              {/* </div> */}
            </Form.Item>
          </aside>{" "}
        </div>
      </section>
    </main>
  );
}
