import { Tooltip } from "devextreme-react";
import React from "react";
import newfile from "../../../../assets/new.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import edit from "../../../../assets/edit.png";
import locked from "../../../../assets/password.png";
import check from "../../../../assets/graychecked.png";
import reset from "../../../../assets/refresh-small.png";
import folder from "../../../../assets/open-folder.png";
import setting from "../../../../assets/settings.png";
import bell from "../../../../assets/bell.png";
import task from "../../../../assets/task-list.png";
import alter from "../../../../assets/alter.png";
import printer from "../../../../assets/printer.png";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { Checkbox, DatePicker, Select } from "antd";
import { InputsTemplate } from "../../../../templates/input";
import { FaBinoculars } from "react-icons/fa";

export const CaseManagementToolbar = () => {
  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const borderBg = useSelector((state: RootState) => state.general.cssBorderBg);
  return (
    <div className="w-[8500px] flex h-full pl-1 space-x-1 ">
      <li
        id="newRecord"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img className="pr-1 " src={newfile} />
          New{" "}
        </span>
        <Tooltip
          target="#newRecord"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className="">New Record</p>
        </Tooltip>
      </li>
      <li
        id="editRecordst"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
            src={edit}
          />
          Edit{" "}
        </span>
        <Tooltip
          target="#editRecordst"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className="">Edit Record</p>
        </Tooltip>
      </li>
      <li
        id="switchInterface"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={alter}
          />
          Switch
        </span>
        <Tooltip
          target="#switchInterface"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className="">Switch interface / table view</p>
        </Tooltip>
      </li>
      <li
        id="check"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={check}
          />
        </span>
        <Tooltip
          target="#check"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          {/* <p className="">Switch interface / table view</p> */}
        </Tooltip>
      </li>
      <li
        id="lockCase"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={locked}
          />
        </span>
        <Tooltip
          target="#lockCase"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className="">
            Lock selected case. Locking a case would prevent further changes and
            updates
          </p>
        </Tooltip>
      </li>

      <li
        id="setting"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={setting}
          />
        </span>
        <Tooltip
          target="#setting"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className=""> Case setting</p>
        </Tooltip>
      </li>
      <li
        id="reminder"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={bell}
          />
        </span>
        <Tooltip
          target="#reminder"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className=""> Set or check case reminders</p>
        </Tooltip>
      </li>
      <li
        id="task"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={task}
          />
        </span>
        <Tooltip
          target="#task"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className=""> Task Manager</p>
        </Tooltip>
      </li>
      <li
        id="folder"
        style={{
          borderWidth: "1px",
          borderColor: colorTheme,
          backgroundColor: borderBg,
        }}
        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
      >
        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
          {" "}
          <img
            className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
            src={folder}
          />
        </span>
        <Tooltip
          target="#folder"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <p className=""> View case documnets</p>
        </Tooltip>
      </li>
      <div>
        <CheckboxTemlate withBorders customDisabled={false}/>
      </div>
      <div>
        <CheckboxTemlate withBorders customDisabled={false}/>
      </div>
      <div>
        <CheckboxTemlate withBorders customDisabled={false}/>
      </div>
      <div>
        <li className="px-1 flex items-center flex-row ">
          <label
            //   htmlFor={"search-" + id}
            style={{ width: "87px" }}
            className=" text-xs"
          >
            Search Criteria
          </label>
          <Select
            //   value={criteriaDataLocal}
            dropdownMatchSelectWidth={false}
            className=" mt-0.5"
            onChange={(data: any) => {
              // const criteriaData = JSON.parse(data);
              // setCriterialDataLocal({
              //   id: criteriaData?.index,
              //   value: criteriaData?.criteria,
              // });
              // searchCriteriaOnchange &&
              //   searchCriteriaOnchange(
              //     criteriaData?.index,
              //     criteriaData?.criteria
              //   );
            }}
            //   id={"search-" + id}
            size="small"
            showSearch={true}
            style={{ width: 110, height: "25px" }}
          >
            {/* {searchCriteriaData?.map(({ id, value }, index) => ( */}
            {/* <Select.Option
                      key={id}
                      value={JSON.stringify({ index: index, criteria: value })}
                    > */}
            {/* {value} */}
            {/* </Select.Option> */}
            {/* ))} */}
          </Select>
        </li>
      </div>
      <div>
        <InputsTemplate label="Search Text" span disabledStatus={false} />
      </div>
      <div>
        <DatePicker />
      </div>
      <div>
        <DatePicker />
      </div>
      <div>
        <CheckboxTemlate withBorders customDisabled={false} />
      </div>
      <div>
        <li
          onClick={() => {
            // handleRefresh &&
            //   handleRefresh(
            //     `&searchCriteria=${searchParams}` +
            //       (searchText !== ""
            //         ? `&searchText=${searchText}`
            //         : "") +
            //       `&startDate=${startDate}&endDate=${endDate}`
            //   );
            // powerTrainRefresh && powerTrainRefresh();
            // setSearchTextValue({ temp: "", text: "" });
            // setCheckedBox(false);
            // setIndeterminate(true);
            // setCheckValue("");
            // setCriterialDataLocal({
            //   id: searchCriteriaData![0]?.id ?? 0,
            //   value: searchCriteriaData![0]?.value ?? "",
            // });
          }}
          id="refreshst"
          style={{ borderWidth: "1px", borderColor: colorTheme }}
          className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-[6px]  text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
            {" "}
            <img
              className="dark:bg-slate-900 dark:text-gray-100 duration-100"
              src={reset}
            />
          </span>
          <Tooltip
            target="#refreshst"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Refresh</p>
          </Tooltip>
        </li>
      </div>
      <div>
      <li
                  onClick={() => {
                    // findAccessPriv();
                  }}
                  id="findst"
                  style={{ borderWidth: "1px", borderColor: colorTheme }}
                  className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-[6px]   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                    <FaBinoculars size={15.5} color="#007AFF" />
                  </span>
                  <Tooltip
                    target="#findst"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Find</p>
                  </Tooltip>
                </li>
      </div>
      <div>
         <li
                //   onClick={() => printAccessPriv()}
                  id="printst"
                  style={{ borderWidth: "1px", borderColor: colorTheme }}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-[6px]   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                    {" "}
                    <img
                      className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                      src={printer}
                    />
                  </span>
                  <Tooltip
                    target="#printst"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Print/Export</p>
                  </Tooltip>
                </li>
      </div>
    </div>
  );
};
