import { datagridColumnTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";

export const elogMainColumnsPriv: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "ID",
    dataField: "pvaIDpk",
    // dataType: "",
    width: 80,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 1,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    fixed: true,
    width: 120,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "NAME",
    dataField: "empName",
    dataType: "string",
    width: 180,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 3,
    caption: "SUBSTATION",
    dataField: "stnName",
    dataType: "string",
    width: 180,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 4,
    caption: "SHIFT LDR?",
    dataField: "pvaShiftLeader",
    dataType: "boolean",
    width: 180,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 5,
    caption: "LOG?",
    dataField: "pvaLog",
    dataType: "boolean",
    width: 90,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 6,
    caption: "VIEW?",
    dataField: "pvaView",
    dataType: "boolean",
    fixed: true,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 7,
    caption: "HOME?",
    dataField: "pvaHome",
    dataType: "boolean",
    width: 90,
    fixed: true,
    allowEditing: true,
    visible: true,
  },
  {
    id: 8,
    caption: "DEF.HOME?",
    dataField: "pvaDefaultHome",
    dataType: "boolean",
    fixed: true,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE?",
    dataField: "pvaActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    allowEditing: true,
    visible: true,
  },
  {
    id: 10,
    caption: "PREVIEW LOGS",
    dataField: "pvaPreviewLogs",
    dataType: "boolean",
    width: 90,
    fixed: true,
    allowEditing: true,
    visible: true,
  },
  {
    id: 11,
    caption: "SEE ALL LOGS",
    dataField: "pvaSeeAllLogs",
    dataType: "boolean",
    width: 90,
    fixed: true,
    allowEditing: true,
    visible: true,
  },
  {
    id: 12,
    caption: "CSR?",
    dataField: "pvaCaptureStationReadings",
    dataType: "boolean",
    fixed: false,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 13,
    caption: "CAPTURE H20 LVLS?",
    dataField: "pvaCaptureWaterLevels",
    dataType: "boolean",
    fixed: false,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 14,
    caption: "VIEW H20 LVLS?",
    dataField: "pvaViewWaterLevels",
    dataType: "boolean",
    fixed: false,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 15,
    caption: "ANALYSE H20 LVLS?",
    dataField: "pvaAnalyseWaterLevels",
    dataType: "boolean",
    fixed: false,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 16,
    caption: "SEE DASHBOARD?",
    dataField: "pvaSeeDashboard",
    dataType: "boolean",
    fixed: false,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 17,
    caption: "TOS?",
    dataField: "pvaTakeOverShift",
    dataType: "boolean",
    fixed: false,
    width: 90,
    allowEditing: true,
    visible: true,
  },
  {
    id: 18,
    caption: "IRSC",
    dataField: "pvaInitiateRemoteShiftChange",
    dataType: "boolean",
    width: 90,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 19,
    caption: "FLAG?",
    dataField: "pvaFlag",
    dataType: "boolean",
    width: 90,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 20,
    caption: "SHIFT MEM?",
    dataField: "pvaShiftMember",
    dataType: "boolean",
    width: 90,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 21,
    caption: "MS?",
    dataField: "stnMasterStation",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 22,
    caption: "NCC?",
    dataField: "stnNationalControlCenter",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
    
  {
    id: 23,
    caption: "OPERATIONAL?",
    dataField: "stnOperational",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  
  {
    id: 24,
    caption: "SUP?",
    dataField: "pvaSupervisor",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 25,
    caption: "MGR?",
    dataField: "pvaManager",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 26,
    caption: "DIR?",
    dataField: "pvaDirector",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 27,
    caption: "LOG OLD DATA?",
    dataField: "pvaDirector",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 28,
    caption: "START DATE",
    dataField: "pvaStartDate",
    dataType: "datetime",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 29,
    caption: "END DATE",
    dataField: "pvaEndDate",
    dataType: "datetime",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  
  {
    id: 30,
    caption: "DATE ASSIGNED",
    dataField: "pvaCreationDate",
    dataType: "datetime",
    format:'dd MMM yyyy HH:mm',
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 31,
    caption: "DATE UNASSIGNED",
    dataField: "pvaEditedDate",
    dataType: "datetime",
    format:'dd MMM yyyy HH:mm',
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 32,
    caption: "OPERATOR LOC",
    dataField: "locShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 33,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 34,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 35,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 36,
    caption: "REMARKS",
    dataField: "pvaRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
];

export const staffsColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "empIDpk",
    datatype: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "Staff?",
    dataField: "empIsStaff",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Staff No",
    dataField: "empStaffNo",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Staff Name",
    dataField: "empName",
    datatype: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Job Title",
    dataField: "jbtShtName",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "Dept",
    dataField: "dptShtName",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Section",
    dataField: "sxnShtName",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Location",
    dataField: "locShtName",
    datatype: "string",
    width: 2000,
    fixed: false,
    visible: true,
  },
];

export const stationsColumns = [
  {
    id: 0,
    caption: "No",
    // dataField: "wsdIDpk",
    datatype: "number",
    width: 40,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Substation",
    dataField: "stnName",
    datatype: "string",
    width: 180,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Shift Ldr",
    dataField: "pvaShiftLeader",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "Log",
    dataField: "pvaLog",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "View",
    dataField: "pvaView",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },

  {
    id: 5,
    caption: "Home",
    dataField: "pvaHome",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Default Home?",
    dataField: "pvaDefaultHome",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Preview?",
    dataField: "pvaPreviewLogs",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "Export Table?",
    dataField: "pvaExport",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "See All Logs?",
    dataField: "pvaSeeAllLogs",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "See Dashboard?",
    dataField: "pvaSeeDashboard",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "Shift Member?",
    dataField: "pvaShiftMember",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "Capture H2O Lvls?",
    dataField: "pvaCaptureWaterLevels",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "View H2O Lvls?",
    dataField: "pvaViewWaterLevels",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "Analyze H2O Lvls?",
    dataField: "pvaAnalyseWaterLevels",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "CSR?",
    dataField: "pvaCaptureStationReadings",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "Flag?",
    dataField: "pvaFlag",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "IRSC?",
    dataField: "pvaInitiateRemoteShiftChange",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },

  {
    id: 18,
    caption: "TOS?",
    dataField: "pvaTakeOverShift",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "Sup?",
    dataField: "pvaSupervisor",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "Mgr?",
    dataField: "pvaManager",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "Dir?",
    dataField: "pvaDirector",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "Log Old Data?",
    dataField: "pvaCaptureBacklogData",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "StartDate",
    dataField: "pvaStartDate",
    datatype: "dateTime",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "EndDate",
    dataField: "pvaEndDate",
    datatype: "dateTime",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "Remarks",
    dataField: "pvaRmks",
    datatype: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
];

export const AllUserGroupsColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "ugpIDpk",
    width: 90,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "GROUP",
    dataField: "ugpName",
    datatype: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "ACTIVE?",
    dataField: "ugpActive",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "REMARKS",
    dataField: "ugpRmks",
    datatype: "string",
    width: 300,
    fixed: false,
    visible: true,
  },
]
export const AllStaffListColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "empIDpk",
    width: 40,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "STAFF No",
    dataField: "empStaffNo",
    datatype: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "STAFF NAME",
    dataField: "empName",
    datatype: "string",
    width: 300,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE ?",
    dataField: "empIsStaff",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    datatype: "string",
    width: 900,
    fixed: false,
    visible: true,
  },
]
export const AllStationGroupColoumn = [
  {
    id: 0,
    caption: "ID",
    dataField: "sgpIDpk",
    width: 40,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "GROUP",
    dataField: "sgpName",
    datatype: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "ACTIVE?",
    dataField: "sgpActive",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "REMARKS",
    dataField: "sgpRmks",
    datatype: "string",
    width: 300,
    fixed: false,
    visible: true,
  },
]
export const AllStationListColoumn = [
  {
    id: 0,
    caption: "ID",
    dataField: "stnIDpk",
    width: 40,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "NOMEN.",
    dataField: "stnCode",
    datatype: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "STATION",
    dataField: "stnName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "stnActive",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "CONTACT",
    dataField: "stnContact",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "LOCATION",
    dataField: "locName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "AREA",
    dataField: "areName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "TOWN",
    dataField: "locName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "INTERCOM",
    dataField: "stnPlc",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
]
export const AllGroupListColoumn = [
  {
    id: 0,
    caption: "ID",
    dataField: "sgmIDpk",
    width: 40,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "NOMEN.",
    dataField: "stnCode",
    datatype: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "STATION",
    dataField: "stnName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "stnActive",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "CONTACT",
    dataField: "stnContact",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "LOCATION",
    dataField: "locName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "AREA",
    dataField: "areName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "TOWN",
    dataField: "locName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "INTERCOM",
    dataField: "stnPlc",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
]