import { Form } from "antd";
import React from "react";
import InputSelect_template from "../../../../templates/InputSelect";
import { DateTemplate } from "../../../../templates/date";
import { InputsTemplate } from "../../../../templates/input";
import { TextareaTemplate } from "../../../../templates/textarea";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { NewButton, SaveButton } from "../../../accessories/buttons";

export const Case_details = () => {
  return (
    <div className="w-full h-full ">
      <p className="text-md font-medium border-b-1 bg-slate-200  py-1 pl-2">
        {"Case Details"}
      </p>
      <aside>
        <Form
          className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 "
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 10 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="flex  w-full ">
            <div className="w-[40%] ">
              <DateTemplate label="Recieved Date" />

              <DateTemplate label="EoA Due Date" />

              <DateTemplate label="EoA File Date" />

              <DateTemplate label="SoD File Date" />
            </div>
            <div className="w-[40%] ">
              <InputSelect_template label="Class" />
              <InputSelect_template label="Category" />
              <InputSelect_template label="Priority" />
              <InputSelect_template label="Status" />
            </div>
          </div>
        </Form>
        <Form
          className=" dark:bg-slate-900 dark:text-darkModeSkin-base   "
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="w-full ">
            <InputSelect_template
              label="Case Name "
              selectStyle={{ width: "calc(100%" }}
            />
          </div>
          <div className="w-full  ">
            <InputSelect_template
              label="Short Name "
              selectStyle={{ width: "calc(100%" }}
            />
          </div>
          <div className="w-full ">
            <InputsTemplate
              label="Suit Number "
              inputStyle={{ width: "calc(100%" }}
            />
          </div>
          <div className="w-full ">
            <TextareaTemplate
              label="Description "
              height={50}
              width="100%"
              labelCol={4}
            />
          </div>
          <div className="w-full ">
            <InputSelect_template
              label="Parties "
              selectStyle={{ width: "calc(100%" }}
            />
          </div>
          <div className="w-full  ">
            <InputSelect_template
              label="Court "
              selectStyle={{ width: "calc(100%" }}
            />
          </div>
          <div className="w-full ">
            <InputSelect_template
              label="Counsel "
              selectStyle={{ width: "calc(100%" }}
            />
          </div>
          <div className="w-full  ">
            <TextareaTemplate
              label="Remarks "
              height={50}
              width="100%"
              labelCol={4}
            />
          </div>
          <div className="w-full flex">
            <div className="w-full ">
              <CheckboxTemlate label={"Active?"} withBorders labelCol={8} />
            </div>
            <div className="flex w-full justify-end ">
              <SaveButton />
              <NewButton />
            </div>
          </div>
        </Form>

      </aside>
    </div>
  );
};
