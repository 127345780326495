import axios from "axios";
import { userDataCrypt } from "../userDataEncrypt";
import { useState } from "react";
import {
  currentDate,
  startingDate,
} from "../../components/accessories/component_infos";
interface props {
  route?: string;
  setState?: (e: any) => void;
}
type messageModalType = {
  icon: "warning" | "success" | "question";
  title: string;
  message: string;
  openModal: boolean;
  hideCancel: boolean;
  okText: string;
  cancelText: string;
  okHandler: () => void;
  cancelHandler: () => void;
};
type toolBarParamType = {
  switchBtn?: boolean;
  searchCriteria?: { initialValue: number; value: number };
  startDate?: { initialValue: string; value: string };
  endDate?: { initialValue: string; value: string };
  searchText?: { initialValue: string; value: string };
  checkValue?: { initialValue: string; value: string };
  refresh?: boolean;
  status?: "Fetch" | "Reset" | "Initial";
};
type PaginationType = {
  pageNumber: number;
  pageSize: number;
  selectedRecord: {};
};
export const useMessageModal = (): [
  messageModalType,
  React.Dispatch<React.SetStateAction<messageModalType>>
] => {
  const [messageModal, setMessageModal] = useState<messageModalType>({
    icon: "warning",
    title: "",
    message: "",
    openModal: false,
    hideCancel: false,
    okText: "Okay",
    cancelText: "",
    okHandler: () => {},
    cancelHandler: () => {},
  });
  return [messageModal, setMessageModal];
};

export const useToolBarParams = (): [
  toolBarParamType,
  React.Dispatch<React.SetStateAction<toolBarParamType>>
] => {
  const [toolBarOps, setToolBarOps] = useState<toolBarParamType>({
    switchBtn: false,
    searchCriteria: { initialValue: 0, value: 0 },
    startDate: { initialValue: startingDate, value: startingDate },
    endDate: { initialValue: currentDate, value: currentDate },
    searchText: { initialValue: "", value: "" },
    checkValue: { initialValue: "", value: "" },
    refresh: false,
    status: "Initial",
  });
  return [toolBarOps, setToolBarOps];
};

export const usePaginationAndSelectedRecord = (): [
  PaginationType,
  React.Dispatch<React.SetStateAction<PaginationType>>
] => {
  const [paginationAndSelectedRecord, setPaginationAndSelectedRecord] =
    useState({
      pageNumber: 1,
      pageSize: 10,
      selectedRecord: {},
    });
  return [paginationAndSelectedRecord, setPaginationAndSelectedRecord];
};

export const validateBeforeSaveORUpdate = (
  method: "save" | "update",
  conditions: any[],
  operationSave: () => void,
  operationUpdate: () => void,
  handleMessageModal: (e: any) => void
) => {
  let isError = conditions.find(({ check }) => check);
  if (isError) {
    handleMessageModal((prev: any) => ({
      ...prev,
      icon: "warning",
      title: isError?.title,
      message: isError?.message,
      openModal: true,
      hideCancel: true,
      okText: "Okay",
      cancelText: "",
      okHandler: () => {
        handleMessageModal((prev: any) => ({
          ...prev,
          openModal: false,
        }));
      },
    }));
  } else {
    handleMessageModal((prev: any) => ({
      ...prev,
      icon: "question",
      title: `${method == "save" ? "Save" : "Update"} record ?`,
      message: `Are you sure you want to  ${
        method == "save" ? "save" : "update"
      } this record ?`,
      openModal: true,
      hideCancel: true,
      okText: "Okay",
      cancelText: "",
      okHandler: () => {
        handleMessageModal((prev: any) => ({
          ...prev,
          openModal: false,
        }));
        method === "save" ? operationSave() : operationUpdate();
      },
    }));
  }
};
export const handleSearchText = (
  e: any,
  stateName: toolBarParamType,
  stateAction: (e: any) => void
) => {
  stateAction((prev: toolBarParamType) => ({
    ...prev,
    searchText: { ...stateName.searchText, initialValue: e },
  }));
};
export const handleStartAndEndDate = (
  e: any,
  StartOrEnd: "startDate" | "endDate",
  stateName: toolBarParamType,
  stateAction: (e: any) => void
) => {
  if (StartOrEnd == "startDate") {
    stateAction((prev: toolBarParamType) => ({
      ...prev,
      startDate: { ...stateName.startDate, initialValue: e },
    }));
    return;
  }
  if (StartOrEnd == "endDate") {
    stateAction((prev: any) => ({
      ...prev,
      endDate: { ...stateName.endDate, initialValue: e },
    }));
  }
};
export const handleSearchCriteria = (
  criteraiIndex: any,
  // criteria: string,
  stateName: toolBarParamType,
  stateAction: (e: any) => void
) => {
  stateAction((prev: toolBarParamType) => ({
    ...prev,
    searchCriteria: {
      ...stateName.searchCriteria,
      initialValue: criteraiIndex,
      // value: criteria,
    },
  }));
};
export const handleRefresh = (
  stateName: toolBarParamType,
  stateAction: (e: any) => void
) => {
  stateAction((prev: toolBarParamType) => ({
    ...prev,
    searchCriteria: {
      ...stateName.searchCriteria,
      initialValue: 0,
      value: 0,
    },
    startDate: {
      ...stateName.startDate,
      initialValue: startingDate,
      value: startingDate,
    },
    endDate: {
      ...stateName.endDate,
      initialValue: currentDate,
      value: currentDate,
    },
    searchText: { ...stateName.searchText, initialValue: "", value: "" },
    checkValue: { ...stateName.checkValue, initialValue: "", value: "" },
    status: "Reset",
    refresh: !stateName.refresh,
  }));
};
export const handleFind = (
  stateName: toolBarParamType,
  stateAction: (e: any) => void
) => {
  stateAction((prev: toolBarParamType) => ({
    ...prev,
    searchCriteria: {
      ...stateName.searchCriteria,
      value: stateName.searchCriteria.initialValue,
    },
    startDate: {
      ...stateName.startDate,
      value: stateName.startDate.initialValue,
    },
    endDate: {
      ...stateName.endDate,
      value: stateName.endDate.initialValue,
    },
    searchText: {
      ...stateName.searchText,
      value: stateName.searchText.initialValue,
    },
    checkValue: {
      ...stateName.checkValue,
      value: stateName.checkValue.initialValue,
    },
    status: "Fetch",
    refresh: !stateName.refresh,
  }));
};
export const handleCheckbox = (
  e: any,
  stateName: toolBarParamType,
  stateAction: (e: any) => void
) => {
  stateAction((prev: toolBarParamType) => ({
    ...prev,
    checkValue: {
      ...stateName.checkValue,
      initialValue: e,
    },
  }));
};
export const Fetch_Data = async ({ route }: props) => {
  let item = sessionStorage.getItem("client");
  let encItem = userDataCrypt("decrypt", item);
  let parsedItem = JSON.parse(encItem);
  try {
    const res = await axios.get(route, {
      headers: {
        "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
        "ngrok-skip-browser-warning": "true",
        Authorization: `Bearer ${parsedItem?.token}`,
      },
    });
    // setState((prev: any) => ({
    //   ...prev,
    //   successStatusData: res?.data,
    //   errorStatusData: "",
    //   isSuccess: true,
    //   isError: false,
    // }));
    return { isError: false, data: res.data };
  } catch (error) {
    console.log(error);
    console.log(error?.response?.data);

    // setState && setState(error?.response?.data?.message)
    // setState((prev: any) => ({
    //   ...prev,
    //   successStatusData: "",
    //   errorStatusData: error?.response?.data,
    //   isSuccess: false,
    //   isError: true,
    // }));
    return { isError: true, data: error?.response?.data };
  }
};
