import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DataGrid } from "devextreme-react";
import {
  SearchPanel,
  GroupPanel,
  Pager,
  Column,
  Scrolling,
} from "devextreme-react/data-grid";
import reset from "../../../../../../assets/reset.png";
import { question_table_data, questions_TH } from "../data/questions-data";
// import {
//   EditButton,
//   NewButton,
//   SaveButton,
// } from "../../../../../accessories/buttons";
// import {
//   smallAttendanceData,
//   smallAttendaceTable_TH,
//   mediumlAttendaceTable_TH,
// } from "../../../../../Safety/Entries/SafetyMeetingAttendance/data/meeting-attendance-data";
import { useState, useEffect } from "react";
// import {
//   api_url,
//   navbar_height,
//   saveModalData,
//   toolbar_height,
//   updateModalData,
// } from "../../../../../accessories/component_infos";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import axios from "axios";
import { useSelector } from "react-redux";
// import { RootState } from "../../../../../../app/store";
// import { settask_footer_table_refresh } from "../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import {
  api_url,
  navbar_height,
  saveModalData,
  toolbar_height,
  updateModalData,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { setGeneralValue, settask_footer_table_refresh } from "../../../../features/generalSlice";
import { ModalTemplate } from "../../../../templates/modal";
import { InputsTemplate } from "../../../../templates/input";
import { useCrudFunc } from "../../../../functions/crud";
import dayjs from "dayjs";
import { UpdateButton } from "../../../accessories/buttons";
// import { ModalTemplate } from "../../../../../../templates/modal";

interface props {
  toggled?: boolean;
  statesx?: any;
  setRefresh?: (e: boolean) => void;
  refresh?: boolean;
}

interface statesType {
  sres: number;
  srect: number;
  sus: number;
  rba: number;
  rtba: number;
  susba: number;
  zsres: number;
  zrect: number;
  zsus: number;
  zsrs: number;
  zsrx: number;
  zssx: number;
  ter: number;
  imp: number;
  refresh?: boolean;
}

const SecondLineDetailsTab = ({ toggled, statesx, refresh, setRefresh }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm";
  const dispatch = useDispatch();

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );

  const [states, setStates] = useState<statesType>({
    sres: 0,
    srect: 0,
    sus: 0,
    rba: 0,
    rtba: 0,
    susba: 0,
    zsres: 0,
    zrect: 0,
    zsus: 0,
    zsrs: 0,
    zsrx: 0,
    zssx: 0,
    ter: 0,
    imp: 0,
    refresh: false,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  console.log(`secondLine`, states);

  // Modal dialig states
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [refreshstate, setRefreshstate] = useState(false);
  // const [refresh, setRefresh] = useState(false);

  const validationForms = () => {
    setIcon("warning");

    if (states.sres === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for +ve Seq. Res./km is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.srect === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for +ve Seq. React./km is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.sus === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Susceptance is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.rba === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Resis. on 100MVA Base is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.rtba === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for React on 100MVA Base is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.susba === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Susc. on 100MVA Base is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.zsres === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Zero Seq. Resistance is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.zrect === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Zero Seq. Reactance is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.zsus === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Zero Seq. Susceptance is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.zsrs === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for ZSRs on 100MVA Base is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.zsrx === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for ZSRx on 100MVA Base is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.zssx === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for ZSSx on 100MVA Base is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.ter === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Thermal Limit (MVA) is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.imp === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data entry for Surge Imp. Loading (MVA) is required",
        title: "Invalid Entry!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const populateFields = (clear?: boolean) => {
    console.log(`sele`,statesx.ldtPositiveSeqResistancePerKm);
    console.log(`sele popo`,clear);
    const gridState = [
      {
        key: "sres",
        value: clear ? 0 : statesx.ldtPositiveSeqResistancePerKm,
      },
      {
        key: "srect",
        value: clear ? 0 : statesx.ldtPositiveSeqReactancePerKm,
      },
      { key: "sus", value: clear ? 0 : statesx.ldtSusceptancePerKm },
      {
        key: "rba",
        value: clear ? 0 : statesx.ldtResistanceOn100MvaBase,
      },
      {
        key: "rtba",
        value: clear ? 0 : statesx.ldtReactanceOn100MvaBase,
      },
      {
        key: "susba",
        value: clear ? 0 : statesx.ldtSusceptanceOn100MvaBase,
      },
      {
        key: "zsres",
        value: clear ? 0 : statesx.ldtZeroSeqResistance,
      },
      {
        key: "zrect",
        value: clear ? 0 : statesx.ldtZeroSeqReactance,
      },
      {
        key: "zsus",
        value: clear ? 0 : statesx.ldtZeroSeqSusceptance,
      },
      {
        key: "zsrs",
        value: clear
          ? 0
          : statesx.ldtZeroSeqResistanceOn100MvaBase,
      },
      {
        key: "zsrx",
        value: clear ? 0 : statesx?.ldtZeroSeqReactanceOn100MvaBase,
      },
      {
        key: "zssx",
        value: clear ? 0 : statesx.ldtZeroSeqSusceptanceOn100MvaBase,
      },
      { key: "ter", value: clear ? 0 : statesx?.ldtThermalLimit },
      {
        key: "imp",
        value: clear ? 0 : statesx.ldtSurgeImpedanceLoading,
      },
    ];

    for (let instance of gridState) {
      console.log(
        `Setting state for ${instance.key} with value ${instance.value}`
      );
      setState(instance.key, instance.value);
    }
  };
  let FormCode = useSelector((state: RootState) => state.user?.formCode);
  //LineDetails/second_details
  const [Posting, Updating, Patching] = useCrudFunc("powerapp");
  const updateData = async () => {
    try {
      const res = await Updating(`LineDetails/second_details/${FormCode}/test`, {
        ldtIdpk: statesx?.ldtIDpk,
        ldtPositiveSeqResistancePerKm: states.sres,
        ldtPositiveSeqReactancePerKm: states.srect,
        ldtSusceptancePerKm: states.sus,
        ldtResistanceOn100MvaBase: states.rba,
        ldtReactanceOn100MvaBase: states.rtba,
        ldtSusceptanceOn100MvaBase: states.susba,
        ldtZeroSeqResistance: states.zsres,
        ldtZeroSeqReactance: states.zrect,
        ldtZeroSeqSusceptance: states.zsus,
        ldtZeroSeqResistanceOn100MvaBase: states.zsrs,
        ldtZeroSeqReactanceOn100MvaBase: states.zsrx,
        ldtZeroSeqSusceptanceOn100MvaBase: states.zssx,
        ldtThermalLimit: states.ter,
        ldtSurgeImpedanceLoading: states.imp,
        ldtEditedBy: 1,
        ldtEditedDate: dayjs(),
      }, "form"
    );
      setIcon("success");
      setOpenModal(true);
      console.log(res);

      // dispatch(settask_footer_table_refresh(setRefresh(!refresh)))
      // setRefreshValue(!refreshValue);
      setRefresh && setRefresh(!refresh);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record Updated successfully",
        title: "Record Updated",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (statesx !== undefined && Object.keys(statesx).length > 0) {
    //   populateFields(false);
    // } else {
    //   populateFields(false);
    // }
    populateFields(false);
    console.log(`inside ${toggled}`);
  }, [statesx]);


  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Update Record" ? updateData() : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel ={icon === "success" || icon === "warning" ? true : false}
      />

      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              name="complex-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              size={"small"}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"+ve Seq. Res./km"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      defaultValue={states.sres ?? 0}
                      orderOnchange={(e) => {
                        setState("sres", e);
                      }}
                      style={{ width: "100%" }}
                      span
                      // className=""
                      // placeholder={states.sres}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"+ve Seq. React./km"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.srect ?? 0}
                      orderOnchange={(e) => {
                        setState("srect", e);
                      }}
                      style={{ width: "100%" }}
                      // className=""
                      // placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Susceptance"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.sus ?? 0}
                      orderOnchange={(e) => {
                        setState("sus", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <div className="h-4"></div>

              <Form.Item
                label={<p className="text-xs">{"Resis. on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.rba ?? 0}
                      orderOnchange={(e) => {
                        setState("rba", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"React on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.rtba ?? 0}
                      orderOnchange={(e) => {
                        setState("rtba", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Susc. on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.susba ?? 0}
                      orderOnchange={(e) => {
                        setState("susba", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <div className="h-4"></div>
              <Form.Item
                label={<p className="text-xs">{"Zero Seq. Resistance"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.zsres ?? 0}
                      orderOnchange={(e) => {
                        setState("zsres", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Zero Seq. Reactance"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.zrect ?? 0}
                      orderOnchange={(e) => {
                        setState("zrect", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Zero Seq. Susceptance"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.zsus ?? 0}
                      orderOnchange={(e) => {
                        setState("zsus", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>

            <Form
              name="complex-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              size={"small"}
              className="ml-2 my-2"
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"ZSRs on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.zsrs ?? 0}
                      orderOnchange={(e) => {
                        setState("zsrs", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"ZSRx on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.zsrx ?? 0}
                      orderOnchange={(e) => {
                        setState("zsrx", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"ZSSx on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.zssx ?? 0}
                      orderOnchange={(e) => {
                        setState("zssx", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <div className="h-4"></div>

              <Form.Item
                label={<p className="text-xs">{"Thermal Limit (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.ter ?? 0}
                      orderOnchange={(e) => {
                        setState("ter", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Surge Imp. Loading (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  rules={[{ required: false }]}
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      defaultValue={states.imp ?? 0}
                      orderOnchange={(e) => {
                        setState("imp", e);
                      }}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <div className="flex  justify-end mt-40 mr-4">
                <UpdateButton
                  // className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                  handleUpdate={() => {
                    setModalData({
                      title: "Update Record",
                      message: "Are you sure you want to update this record?",
                      okText: "Yes",
                      cancelText: "No",
                    });
                    setOpenModal(true);
                    validationForms();
                  }}
                  disableButton={form_disable}
                />
                  {/* <img alt="save" className="pr-1" src={save} />
                  Update
                </UpdateB> */}
              </div>
            </Form>

            {/* <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 ml-2 "
              style={{ width: "45%" }}
            >
              <div className=" border-t-2 pb-2 ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Images
                </div>

                <div className="px-2 pb-2 flex items-end    h-64 w-full ">
                  <div className=" w-full h-60  border flex justify-center items-center">
                    No Image data
                  </div>
                </div>
                <div className="flex mx-2 mb-0.5">
                  {" "}
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "88%",
                    }}
                    className="o  py-1  overflow-y-scroll resize-none"
                    rows={1}
                  />
                  <button
                    title="save"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  mr-1 px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="save" className="pr-1" src={save} />
                  </button>
                  <button
                    title="newIcon"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="newIcon" className="pr-1" src={newIcon} />
                  </button>
                </div>
                <div className="flex">
                  <div
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "100%",
                    }}
                    className="o mx-2  border  flex "
                  >
                    <div className=" border-r p-0.5 text-xs px-2">1</div>
                    <div className=" border-r p-0.5 text-xs px-2">2</div>
                    <div className=" border-r p-0.5 text-xs px-2">3</div>
                    <div className=" border-r p-0.5 text-xs px-2">4</div>
                    <div className=" border-r p-0.5 text-xs px-2">5</div>
                    <div className=" border-r p-0.5 text-xs px-2">6</div>
                    <div className=" border-r p-0.5 text-xs px-2">7</div>
                    <div className=" border-r p-0.5 text-xs px-2">8</div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
        </div>
      }
    </div>
  );
};

export default SecondLineDetailsTab;
