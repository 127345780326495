import { useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import alter from "../../../../../../../assets/alter.png";
import correct from "../../../../../../../assets/blue-check-mark.png";
import closeGreyed from "../../../../../../../assets/closeGreyed.png";
import log from "../../../../../../../assets/log.png";
import question from "../../../../../../../assets/question.png";

import printer from "../../../../../../../assets/printer.png";
import reset from "../../../../../../../assets/refresh-small.png";
import { Checkbox, DatePicker, Input, Select } from "antd";
import { FaBinoculars } from "react-icons/fa";
import dayjs from "dayjs";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import statusLoader from "../../../../../../../assets/statusloader.gif";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { PostDataFunc  } from "../../../../../../../functions/post";
interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleNew?: (value: boolean) => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: (val: any) => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;
  switchState?: (value: boolean) => void;
  searchCriteria?: (value: number) => void;
  handleReport?: () => void; //handles return report
  handleClose?: () => {};
  handleAcknowledge?: () => {};
  selectedRecord?: any;
  getCurrentDate?: any;
  handleGridRefresh?: () => void;
}

export const VehicleConditionToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  handleReport,
  selectedRecord,
  getCurrentDate,
  handleGridRefresh,
}: props) => {
  const dateFormat = " DD MMM YYYY";
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [searchText, setSearchText] = useState<any>("");
  const [checkValue, setCheckValue] = useState<boolean | null>(null); //actual value to use
  const [startDate, setStartDate] = useState(
    dayjs().subtract(5, "years").format("YYYY-MM-DD")
  );
  const [endDate, seEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [employeeId, userId, userInfo] = useAccountInfo();

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue(null);
    }
  };
  const [searchParam, setSearchParam] = useState<any>(0);
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const [openModal, setOpenModal] = useState(false);
  const [disableCancelButton, setDisableCancelButton] = useState(false);

  const closeModal = {
    message:
      "Are you sure you want to Cancel acknowledgement of receipt of the selected Vehicle Conditions Returns submitted for the Section ",
    title: "Acknowledge?",
    okText: "OK",
    cancelText: "No",
  };

  const acknowledgeModal = {
    message: `Are you sure you want to Acknowledge receipt of Vehicle Conditions Returns submitted for the Section for the Month `,
    title: "Acknowledge",
    okText: "Ok",
    cancelText: "No",
  };
  const [modalData, setModalData] = useState<any>(closeModal);
  const [modalIcon, setModalIcon] = useState<
    "warning" | "success" | "question"
  >("question");
  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const AccessPriv_on_FindButton = () => {
    if (readPriv === true) {
      if (handleFind) {
        handleFind(
          `AssetTamVehicleConditionReturn/PopGdvSearchAssetTamVehicleConditionReturn?startDate=${startDate}&endDate=${endDate}&criteriaIndex=${searchParam}&search=${searchText}&active=${checkedBox}`
        );
      }
    } else {
      setModalIcon("warning");
      setDisableCancelButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No, Cancel",
        progress: "",
        error: { title: "", message: "" },
        success: { title: "", message: "" },
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      handlePrint && handlePrint();
    } else {
      setModalIcon("warning");
      setDisableCancelButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No, Cancel",
      });
      setOpenModal(true);
    }
  };

  const close = () => {
    setOpenModal(true);
    setModalIcon("question");
    setDisableCancelButton(false);
    setModalData(closeModal);
  };
  const acknowledge = () => {
    setOpenModal(true);
    setModalIcon("question");
    setDisableCancelButton(false);

    setModalData({
      message: `Are you sure you want to Acknowledge receipt of Vehicle Conditions Returns submitted for the Section ${
        selectedRecord.dptShtName
      }  - ${selectedRecord.sxnShtName} for the Month  ${dayjs(
        selectedRecord.vcrDate
      ).format("YYYY-MM")}-${dayjs(selectedRecord.vcrDate)
        .endOf("month")
        .date()}`,
      title: "Acknowledge",
      okText: "Ok",
      cancelText: "No",
    });
  };

  const runClose = async () => {
    try {
      await PatchDataFunc(
        `AssetTamVehicleConditionReturn/DeleteAssetTamVehicleConditionReturn`,
        {
          submitterId: selectedRecord.submitterEmpIdpk,
          datetime: `${getCurrentDate}`,
        }
      );
      setOpenModal(true);
      setModalIcon("success");
      setDisableCancelButton(true);
      setModalData({
        message: "Vehicle Condition Returns unacknowledged successfully",
        title: "Acknowledgement Successful",
        okText: "Ok",
        cancelText: "No",
      });
    } catch (e: any) {
      setOpenModal(true);
      setModalIcon("warning");
      setDisableCancelButton(true);
      setModalData({
        message: "Contact your Administrator",
        title: "Failed to unacknowledged records",
        okText: "Ok",
        cancelText: "No",
      });
    }
    setOpenModal(false);
    handleGridRefresh && handleGridRefresh();
  };

  const runAcknowledge = async () => {
    try {
      await UpdateDataFunc(
        `AssetTamVehicleConditionReturn/UpdateAcknowledgeConditionReturn`,
        {
          submitterId: selectedRecord.submitterEmpIdpk,
          datetime: `${getCurrentDate}`,
        }
      );

      await PostDataFunc (`AsmTsmEntMaintenanceSchedule/SaveSmsAlert`, {
        altFormCode: "asmTransVehCondReturns",
        altMessage: `Your Vehicle Conditions Returns for ${dayjs(
          selectedRecord.vcrDate
        ).format("YYYY-MM")} has been acknowledged by ${
          userInfo.empFirstName
        } ${userInfo.empLastName}`,
        altTakeAction: true,
      });
      setOpenModal(true);
      setModalIcon("success");
      setDisableCancelButton(true);
      setModalData({
        message: "Vehicle Condition Returns acknowledged successfully",
        title: "Acknowledgement Successful",
        okText: "Ok",
        cancelText: "No",
      });
    } catch (e: any) {
      setOpenModal(true);
      setModalIcon("warning");
      setDisableCancelButton(true);
      setModalData({
        message: "Contact your Administrator",
        title: "Failed to acknowledged records",
        okText: "Ok",
        cancelText: "No",
      });
    }
    setOpenModal(false);
    handleGridRefresh && handleGridRefresh();
  };

  return (
    <>
      <ModalTemplate
        icon={modalIcon}
        disableCancel={disableCancelButton}
        cancelText={modalData.cancelText}
        open={openModal}
        okHandler={() => {
          modalData.title === closeModal.title
            ? runClose()
            : modalData.title === acknowledgeModal.title
            ? runAcknowledge()
            : setOpenModal(false);
        }}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{
          borderWidth: "1px",
          width: "4000px",
          height: 32,
          borderColor: borderTheme,
        }}
        className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
      >
        <div className="flex justify-center ">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            <li
              onClick={() => {
                close();
              }}
              id="close"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                opacity: !userInfo?.empIsOrgAdmin ? 0.5 : 1,
                pointerEvents: !userInfo?.empIsOrgAdmin ? "none" : "auto",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  py-1  text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img alt="" src={closeGreyed} />{" "}
              </span>
              <Tooltip
                target="#close"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Close</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                acknowledge();
              }}
              id="correct"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                opacity: !userInfo?.empIsOrgAdmin ? 0.5 : 1,
                pointerEvents: !userInfo?.empIsOrgAdmin ? "none" : "auto",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  py-1  text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img alt="" src={correct} />{" "}
              </span>
              <Tooltip
                target="#correct"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Acknowledge Submission</p>
              </Tooltip>
            </li>

            <li
              id="switch"
              onClick={toggler}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "65px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch{" "}
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch interface / table view</p>
              </Tooltip>
            </li>

            <li
              id="log"
              onClick={() => {
                handleReport && handleReport();
              }}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                opacity: !userInfo?.empIsOrgAdmin ? 0.5 : 1,
                pointerEvents: !userInfo?.empIsOrgAdmin ? "none" : "auto",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <img
                alt=""
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={log}
              />
              <Tooltip
                target="#log"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Returns report</p>
              </Tooltip>
            </li>

            <li
              id="help"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "35px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target="#help"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Quick Help</p>
              </Tooltip>
            </li>

            <li className="px-1 text-xs">Search Criteria</li>
            <li className="items-right  ">
              <Select
                showSearch
                onChange={(object) => {
                  setSearchParam(object);
                }}
                value={searchParam}
                size="small"
                allowClear={false}
                style={{ width: `${sideAlert === true ? 60 : 120}` }}
                dropdownStyle={{ minWidth: "250px" }}
              >
                {[
                  { label: "Reg No" },
                  { label: "Department" },
                  { label: "Section" },
                  { label: "Submitted By" },
                  { label: "Approving officers" },
                  { label: "Driver" },
                ]?.map((option: any, index) => {
                  return (
                    <Select.Option value={index}>{option.label}</Select.Option>
                  );
                })}
              </Select>
            </li>
          </ul>
        </div>

        <div className="flex">
          <ul className="ml-4 flex items-center">
            <div className="text-xs">Search Text</div>
            <li className="px-1">
              <Input
                size="small"
                value={searchText}
                onChange={(data) => {
                  setSearchText(data.target.value);
                }}
                allowClear
                placeholder=""
                style={{ width: `${sideAlert === true ? 50 : 180}` }}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                allowClear={false}
                value={dayjs(startDate) ?? dayjs()}
                onChange={(startDate) => {
                  setStartDate(dayjs(startDate).format("YYYY-MM-DD"));
                }}
                size="small"
                placeholder="Start Date"
                style={{ width: 125 }}
                format={dateFormat}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                allowClear={false}
                value={dayjs(endDate) ?? dayjs()}
                onChange={(endDate) => {
                  seEndDate(dayjs(endDate).format("YYYY-MM-DD"));
                }}
                size="small"
                placeholder="End Date"
                style={{ width: 125 }}
                format={dateFormat}
              />
            </li>
            <li
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              id="status"
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  onChange(checkValue);
                }}
              />
              <Tooltip
                target="#status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Submission Status</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                AccessPriv_on_FindButton();
              }}
              id="findst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs  px-1 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                <FaBinoculars size={15.5} color="#007AFF" />
              </span>
              <Tooltip
                target="#findst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Search</p>
              </Tooltip>
            </li>

            <li
              onClick={() => {
                setStartDate(dayjs().subtract(5, "years").format("YYYY-MM-DD"));
                seEndDate(dayjs().format("YYYY-MM-DD"));
                setSearchParam(0);
                setIndeterminate(true);
                handleRefresh && handleRefresh();
                setSearchText("");
              }}
              id="refreshst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={reset}
                />
              </span>
              <Tooltip
                target="#refreshst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Refresh search</p>
              </Tooltip>
            </li>

            {/* <li
              onClick={() => AccessPriv_on_PrintButton()}
              id="printst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />
              </span>
              <Tooltip
                target="#printst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Print Table View</p>
              </Tooltip>
            </li> */}

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {form_disable && (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}

              {!form_disable && (
                <div className="  flex flex-row items-center">
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  {!update_state_change ? (
                    <p className=" italic text-gray-600">New Record</p>
                  ) : (
                    <p className=" italic text-gray-600">Editing</p>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const data = [
  {
    label: "Name",
  },
  {
    label: "Staff Name",
  },
  {
    label: "Job Title",
  },
  {
    label: "Department",
  },
  {
    label: "Section",
  },
  {
    label: "Unit",
  },
  {
    label: "Location",
  },
];
