import { forwardRef, useState } from "react";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import close from "../../../../assets/close.png";
import folder from "../../../../assets/icon-yellow-folder-removebg-preview.png";
import share from "../../../../assets/shareIcon-removebg-preview.png";
import { Tooltip } from "devextreme-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { HSeperator, VSeperator } from "../widgets/utils";
import Datagrid_template from "../../../../templates/Datagrid";
import { Form, Input } from "antd";
import { DateTemplate } from "../../../../templates/date";
import InputSelect_template from "../../../../templates/InputSelect";
import TextArea from "antd/es/input/TextArea";
import { BottomBtns } from "../../../../templates/butttons";
import reset from "../../../../assets/reset.png";
import TabsPanel_template from "../../../../templates/tabsPanel_template";
export const Documents_Details = forwardRef(() => {
  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [showDocument,setShowDocument]=useState(true);
  return (
    <>
      <HrSetupsCustomToolbar
        showHelp={false}
        searchCriteria
        extraWidgetsAfterSwitch={
          <div className="flex space-x-1">
            <li
              id="clear-cancel"
              style={{ borderWidth: "1px", borderColor: colorTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  style={{ paddingTop: 1, paddingBottom: 1 }}
                  className=" w-10/12 py-0.5 h-10/12"
                  src={close}
                />
              </span>
              <Tooltip
                target="#clear-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Dont Know what it is yet</p>
              </Tooltip>
            </li>
            <li
              id="folder"
              style={{ borderWidth: "1px", borderColor: colorTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  style={{ paddingTop: 1, paddingBottom: 1 }}
                  className=" w-5 py-0.5 h-5"
                  src={folder}
                />
              </span>
              <Tooltip
                target="#folder"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Dont Know what it is yet</p>
              </Tooltip>
            </li>
            <li
              id="share"
              style={{ borderWidth: "1px", borderColor: colorTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  style={{ paddingTop: 1, paddingBottom: 1 }}
                  className=" w-5 py-0.5 h-5"
                  src={share}
                />
              </span>
              <Tooltip
                target="#share"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Dont Know what it is yet</p>
              </Tooltip>
            </li>
          </div>
        }
      />
      <section className="flex">
        {showDocument&&<main
          style={{ borderColor: borderTheme }}
          className="border-[1px] w-[37%] rounded-t-md"
        >
          <header
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="border-b-[1px] font-medium text-xs mb-2 bg-slate-100 rounded-t-md py-1 px-2"
          >
            {`Document Details`}
          </header>
          <Form
            name="complex-form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            size={"small"}
            className="grid grid-cols-1 w-full  gap-[1%] py-1"
          >
            <div className="w-full pb-1 self-start">
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Doc Date:`}</p>}
              >
                <DateTemplate style={{ width: "80%" }} />
              </Form.Item>
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Case:`}</p>}
              >
                <div className="w-full">
                  <InputSelect_template
                    useCallFunc
                    style={{ width: "100%" }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Doc. Type:`}</p>}
              >
                <div className="w-full">
                  <InputSelect_template
                    useCallFunc
                    style={{ width: "100%" }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Doc. Title:`}</p>}
              >
                <div className="w-full">
                  <Input style={{ width: "100%" }} />
                </div>
              </Form.Item>
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Remarks:`}</p>}
              >
                <div className="w-full">
                  <TextArea  style={{ width: "400px", height:'400px' }} />
                </div>
              </Form.Item>
              <BottomBtns span withoutCheckbox />
            </div>
          </Form>
        </main>
        }
        <VSeperator onClick={()=>{setShowDocument(!showDocument)}} status={showDocument} height={100} />
        <main
          style={{ borderColor: borderTheme }}
          className="w-full h-full border-[1px] rounded-t-md"
        >
          <header
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="border-b-[1px] font-medium text-xs mb-2 bg-slate-100 rounded-t-md py-1 px-2"
          >
            {`Attachments`}
          </header>
          <section className="flex">
            <div className=" w-[30%]">
              <header
                id={"form_header"}
                style={{ borderColor: borderTheme }}
                className="border-b-[1px] flex justify-between font-medium text-xs mb-2 bg-slate-100 rounded-t-md py-1 px-2"
              >
                {`File Name`}
                <span
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 26,
                    marginLeft: 3,
                    borderColor: borderTheme,
                  }}
                  className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={reset}
                    alt="reset"
                  />
                </span>
              </header>
              <div className="overflow-scroll h-full p-2">

              </div>
            </div>
            <VSeperator status height={100} />
            <div className="w-full ">
              <TabsPanel_template
                tabs={[
                  { id: 0, title: "File", contents: <></> },
                  { id: 1, title: "ImageViewer", contents: <></> },
                  { id: 2, title: "RibbonPage1", contents: <></> },
                  { id: 3, title: "RibbonPage2", contents: <></> },
                ]}
              />
            </div>
          </section>
        </main>
      </section>
      <HSeperator />
      <Datagrid_template gridheight={750} columns={[]} data={[]} />
    </>
  );
});
// v seperator
// h seperator
// tabPanel
