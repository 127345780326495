/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Select, Tooltip } from "antd";
import { forwardRef, memo, useCallback, useRef, useState } from "react";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import reset from "../../../../assets/refresh-small.png";
import { debounce } from "lodash";
import { TooltipPlacement } from "antd/es/tooltip";
import { useNonInitialEffect } from "../util/util";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useWidthResizer } from "../util/iHooks";

type Props = {
    label?: string;
    labelCol?: number;
    wrapperCol?: number;
    placeholder?: string;
    options?: any[];
    optionFilterProp?: string;
    className?: string;
    minWidth?: string | number;
    width?: string | number;
    height?: string | number;
    mode?: "multiple" | "tags";
    showSearch?: boolean; //Whether select is searchable
    searchValue?: string; //The current input "search" text  
    variant?: "outlined" | "filled" | "borderless";
    defaultValue?: string;
    idExpr?: string;
    displayExpr?: string;
    disabled?: boolean;
    showRefresh?: boolean;
    toolTip?: string;
    toolTipPlacement?: TooltipPlacement;
    value?: string;
    size?: SizeType;
    onRefresh?: () => void;
    onSearch?: (param: string) => Promise<any[]>;
    onSelect?: (value: any) => void;
};

const DropDownTemp = forwardRef((
    {
        width = "100%",
        options = [],
        height = "26px",
        defaultValue,
        disabled = false,
        showSearch = false,
        value,
        showRefresh = true,
        toolTipPlacement = "leftBottom",
        size,
        onRefresh,
        onSelect = (e) => { },
        ...props
    }: Props, ref: any) => {

    const [filteredList, setFilteredList] = useState(options);
    const [selectedOrTypedRecord, setSelecedOrTypedRecord] = useState(value);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);
    const widths = useWidthResizer(containerRef);
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass);

    const handleRefresh = () => {
        setSelecedOrTypedRecord("");
        setFilteredList(options);
        onRefresh && onRefresh();
    };

    const debouncedOnSearch = debounce((e: string) => handleSearch(e), 200);

    const handleSearch = async (value: string) => {
        if (value.trim() === "") {
            setFilteredList(options);
            return;
        }

        try {
            setLoading(true);
            setSelecedOrTypedRecord(value);
            let filter = options.filter((param: any) => param[props?.displayExpr].toLowerCase().includes(value?.toLowerCase()));
            if (filter?.length === 0) {
                filter = await props?.onSearch(value) ?? [];
            }
            setFilteredList(filter);
            setLoading(false);
        } catch (e) {
            setFilteredList([]);
            setLoading(false);
        }
    };

    const handleOnSelect = useCallback((option: any) => {
        setSelecedOrTypedRecord(option[props?.displayExpr])
        onSelect(option);
    }, []);

    const handleKeyPressed = useCallback((e: any) => {
        if (e.key === "Enter") {
            handleSearch(selectedOrTypedRecord);
        }
    }, []);

    useNonInitialEffect(() => {
        setFilteredList(options);
    }, [options]);

    useNonInitialEffect(() => {
        setSelecedOrTypedRecord(value);
    }, [value]);


    return (
        <Form.Item label={props.label} labelCol={{ span: props.labelCol }} wrapperCol={{ span: props.wrapperCol }}>
            <section className="flex space-x-0.5 items-center w-full overflow-hidden">
                <aside className="w-full" ref={containerRef}>
                    <Tooltip title={props?.toolTip} placement={toolTipPlacement} color="white" overlayInnerStyle={{ color: "black" }}>
                        <Select
                            ref={ref}
                            className={props.className}
                            showSearch={showSearch}
                            style={{ width: widths, height: height, minWidth: props?.minWidth }}
                            placeholder={props?.placeholder}
                            optionFilterProp={props?.idExpr}
                            filterOption={false} //should be false to allow custom filtering
                            fieldNames={{ label: props?.displayExpr, value: props?.idExpr }}
                            mode={props?.mode}
                            options={filteredList}
                            loading={loading}
                            value={selectedOrTypedRecord}
                            onSelect={(v, opt) => handleOnSelect(opt)}
                            onSearch={debouncedOnSearch}
                            onInputKeyDown={handleKeyPressed}
                            disabled={disabled}
                            size={size}
                            onBlur={() => { setFilteredList(options) }}
                        />
                    </Tooltip>
                </aside>
                {showRefresh &&
                    (<div onClick={disabled ? () => { } : handleRefresh}
                        style={{ height: 24.5, marginBottom: 2, borderWidth: 1, width: 24, borderColor: borderTheme }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded flex-none"
                    >
                        <img className={`size-full flex p-0.5 items-center justify-center ${disabled ? " bg-gray-200" : "bg-muted hover:bg-blue-50"}`} src={reset} alt="reset" />
                    </div>)
                }
            </section>
        </Form.Item>
    )
});

export default memo(DropDownTemp);
