import React, { useState } from "react";
import Datagrid_template from "../../../../../templates/Datagrid";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { RefreshButton } from "../../../elogger/StationReadings/_widget/utils";
import bringforward from "../../../../../assets/switch-chart.png";
import lock from "../../../../../assets/lock.png";
import { IconsContainer } from "../../../elogger/StationReadings/_widget/stationReadingsToolbar";
import { DateTemplate } from "../../../../../templates/date";
import { Form, InputNumber } from "antd";
import { Slider } from "antd";
import InputSelect_template from "../../../../../templates/InputSelect";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { FormItem } from "devextreme-react/cjs/data-grid";
import { InputsTemplate } from "../../../../../templates/input";
import {
  EditButton,
  NewButton,
  SaveButton,
} from "../../../../accessories/buttons";
function Taskupdate() {
  const [toggle, setToggled] = useState(false);

  const taskupdate_columns = [
    {
      id: 1,
      caption: "LOCKED?",
      dataField: "exnName",
      dataType: "string",
      width: 100,
      fixed: false,
      visible: true,
    },
    {
      id: 2,
      caption: "DATE",
      dataField: "exnName",
      dataType: "string",
      width: 100,
      fixed: false,
      visible: true,
    },
    {
      id: 3,
      caption: "% COMPLETE",
      dataField: "exnName",
      dataType: "string",
      width: 120,
      fixed: false,
      visible: true,
    },
    {
      id: 4,
      caption: "TASK UPDATE",
      dataField: "exnName",
      dataType: "string",
      width: 130,
      fixed: false,
      visible: true,
    },
    {
      id: 5,
      caption: "UPDATED BY",
      dataField: "exnName",
      dataType: "string",
      width: 60,
      fixed: false,
      visible: true,
    },
  ];

  const handleToggle = () => {
    setToggled(!toggle);
  };

  return (
    <main>
      <div className="">
        <header className="flex w-full text-md font-medium border-b-1 bg-slate-200 rounded-t-md pl-2 items-center">
          <p className="w-full">Task Update</p>
          <section className="flex w-full ">
            <aside className="flex w-full  gap-2">
              <div className="">
                <CheckboxTemlate withBorders />
              </div>
              <div className="mt-[2px]">
                <IconsContainer url={lock} />
              </div>
            </aside>
            <aside className="w-full flex justify-end  gap-2">
              <div className="mt-1">
                <RefreshButton />
              </div>
              <div className="mt-[2px]">
                <IconsContainer url={bringforward} />
              </div>
            </aside>
          </section>
        </header>
      </div>
      <div onDoubleClick={handleToggle}>
        {toggle ? (
          <section className="h-32 w-full">
            <Form
              className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded pt-2 "
              name="basic"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
            >
              <section className="flex w-[90%]">
                <div className="w-full">
                  <DateTemplate showTime disabled={false} label="Start Date" />
                </div>
                <div className="w-full ">
                  <InputSelect_template label="Status" />
                </div>
              </section>
              <Form.Item
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 19 }}
                className="mb-1"
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"% Complete"}
                  </p>
                }
              >
                <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base">
                  <Slider
                    style={{ width: "100%" }}
                    className=""
                    min={0}
                    max={100}
                    // onChange={(e) => {
                    //   setState("complete", e);
                    // }}
                    // value={states.complete}
                  />
                  <InputNumber
                    className="h-6 w-16 flex items-center justify-center"
                    size="small"
                    min={0}
                    max={100}
                    // value={states.complete}
                    // onChange={(e) => {
                    //   setState("complete", e);
                    // }}
                  />
                </div>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 19 }}
                className="mb-1"
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Update"}
                  </p>
                }
              >
                <TextareaTemplate height={125} />
              </Form.Item>
              <Form labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
                <div>
                  <InputsTemplate labelColPan={5} label="Done By" />
                </div>
              </Form>
              <aside className="flex  mx-5 mt-3">
                <div className="w-full ml-20">
                  <EditButton />
                </div>
                <div className="w-full flex gap-2 justify-end">
                  <NewButton />
                  <SaveButton />
                </div>
              </aside>
            </Form>
          </section>
        ) : (
          <Datagrid_template
            gridheight={300}
            columns={taskupdate_columns}
            data={[]}
            disableGroupPanel
            disableSearch
            disablePaging
          />
        )}
      </div>
    </main>
  );
}

export default Taskupdate;
