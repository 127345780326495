import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { settransDetailsValue } from "../../../../features/forms/entries/safetyManager/ppeRequest";
import {
  setGeneralValue,
  settask_footer_table_refresh,
} from "../../../../features/generalSlice";
import {
  navbar_height,
  toolbar_height,
  api_url,
  saveModalData,
} from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import { ImageUpload } from "./imageUpload";
import { ModalTemplate } from "../../../../templates/modal";

interface props {
  toggled?: boolean;
  states?: any;
  updateState?: (key: string, value: any) => void;
  selectedRecord?: any;
}

const OtherDetailsTab = ({
  toggled,
  states,
  updateState,
  selectedRecord,
}: props) => {
  console.log("ODS", selectedRecord);
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";

  const dispatch = useDispatch();

  const refreshRecord = useSelector(
    (state: RootState) => state.general.refresh
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  // Onchange values

  //   const [tw, setTw] = useState<string>("");
  //   const [tcr, setTcr] = useState<string>("");
  //   const [tcs, setTcs] = useState<string>("");
  //   const [imp, setImp] = useState<string>("");
  //   const [nt, setNt] = useState<string>("");
  //   const [ro, setRo] = useState<string>("");
  //   const [rv1, setRv1] = useState<string>("");
  //   const [rv2, setRv2] = useState<string>("");
  //   const [rv3, setRv3] = useState<string>("");
  //   const [rp1, setRp1] = useState<string>("");
  //   const [rp2, setRp2] = useState<string>("");
  //   const [rp3, setRp3] = useState<string>("");
  //   const [zh, setZh] = useState<string>("");
  //   const [zx, setZx] = useState<string>("");
  //   const [zy, setZy] = useState<string>("");
  //   const [resist, setResist] = useState("");

  const validation = () => {
    const fields = [
      { value: states.tappedWinding, name: "Tapped Winding" },
      { value: states.tapChangeRange, name: "Tap Change Range" },
      { value: states.tapChangeStep, name: "Tap Change Step" },
      { value: states.deg, name: "Deg" },
      { value: states.normalTap, name: "Normal Tap" },
      { value: states.ratedOutput, name: "Rated Output" },
      { value: states.ratedVoltageI, name: "Rated Voltage I" },
      { value: states.ratedVoltageII, name: "Rated Voltage II" },
      { value: states.ratedVoltageIII, name: "Rated Voltage III" },
      { value: states.ratedPowerI, name: "Rated Power I" },
      { value: states.ratedPowerII, name: "Rated Power II" },
      { value: states.ratedPowerIII, name: "Rated Power III" },
      { value: states.zhBase, name: "ZH Base" },
      { value: states.zxBase, name: "ZX Base" },
      { value: states.zyBase, name: "ZY Base" },
      { value: states.resistanceBase, name: "Resistance Base" },
    ];

    for (const field of fields) {
      if (field.value === "") {
        setModalData({
          message: `Please input data entry for ${field.name} field!`,
          title: "Required Field",
          okText: "Ok",
        });
        setOpenModal(true);
        setIcon("warning");
        return;
      }
    }
  };

  const saveAccessPriv = () => {
    setModalData({
      message: "Are you sure you want save this record ",
      title: "Save record?",
      okText: "Save",
    });
    setOpenModal(true);
    setIcon("question");
  };
  const updateAccessPriv = () => {
    setModalData({
      message: "Are you sure you want update this record ",
      title: "Update record?",
      okText: "Update",
    });
    setOpenModal(true);
    setIcon("question");
  };
  // useEffect(() => {
  //    console.log(`inside ${toggled}`)
  // }, [toggled])

  //   const updateData = async () => {
  //     alert("updateData");

  //     const res = await axios
  //       .put(
  //         `${api_url}/api/AssetTamEntTransformerDetail/UpdateTransformerOrderDetail`,
  //         {
  //           tfdIdpk: selectedRecord[0].tfdIdpk,
  //           tfdTappedWinding: tw,
  //           tfdTapChangerRange: tcr,
  //           tfdTapChangerStep: tcs,
  //           tfdNominalTap: nt,
  //           tfdRatedOutput: ro,
  //           tfdRatedVoltageI: rv1,
  //           tfdRatedVoltageIi: rv2,
  //           tfdRatedVoltageIii: rv3,
  //           tfdRatedPowerI: rp1,
  //           tfdRatedPowerIi: rp2,
  //           tfdRatedPowerIii: rp3,
  //           tfdZhAt100MvabaseAt75Deg: zh,
  //           tfdZxAt100MvabaseAt75Deg: zx,
  //           tfdZyAt100MvabaseAt75Deg: zy,
  //           tfdResistanceAt100MvabaseAt75Deg: resist,
  //           tfdPercentImpedanceAt75Deg: imp,
  //         }
  //       )
  //       .then((response) => {
  //         console.log(response);
  //         alert("Updated successfully");
  //         dispatch(settask_footer_table_refresh(!refresh));
  //       });
  //   };

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        icon={icon}
        disableCancel={icon === "warning" ? true : false}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save record?"
            ? dispatch(
                setGeneralValue({ expr: "saveTransformer", value: true })
              ) && setOpenModal(false)
            : modalData.title === "Update record?"
            ? dispatch(
                setGeneralValue({ expr: "updateTransformer", value: true })
              ) && setOpenModal(false)
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              {...formPropsConst}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Tapped Winding/kV"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("tappedWinding", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.tappedWinding}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Tap Changer Range"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("tapChangeRange", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.tapChangeRange}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Tap Changer Steps"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("tapChangeStep", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.tapChangeStep}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"% Imp. @ 75 Deg"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("deg", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.deg}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Nominal Tap"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("normalTap", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.normalTap}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Output (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("ratedOutput", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedOutput}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Volatage I (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("ratedVoltageI", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedVoltageI}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Volatage II (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("ratedVoltageII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedVoltageII}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Volatage III (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("ratedVoltageIII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedVoltageIII}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>

            <Form
              {...formPropsConst}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              className="ml-2 my-2"
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Rated Power I (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("ratedPowerI", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedPowerI}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Power II (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState("ratedPowerII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedPowerII}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Power III (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("ratedPowerIII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.ratedPowerIII}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Zh on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("zhBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.zhBase}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Zx on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("zxBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.zxBase}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Zy on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState("zyBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.zyBase}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Resist. on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState &&
                          updateState("resistanceBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                      value={states.resistanceBase}
                      disabled={disable}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <div className="flex  justify-end mt-20 mr-4">
                {!updateMode ? (
                  <button
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                    onClick={() => {
                      validation();
                      saveAccessPriv();
                    }}
                    disabled={disable}
                  >
                    <img className="pr-1" src={save} />
                    Update
                  </button>
                ) : (
                  <button
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                    onClick={() => {
                      validation();
                      updateAccessPriv();
                    }}
                    disabled={disable}
                  >
                    <img className="pr-1" src={save} />
                    Update
                  </button>
                )}
              </div>
            </Form>
          </div>
        </div>
      }
    </div>
  );
};

export default OtherDetailsTab;
