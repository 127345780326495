/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { GetDataFunc } from "../../../../functions/get";
import { InputsTemplate } from "../../../../templates/input";
import { RefreshButton } from "../StationReadings/_widget/utils";
import { IconsContainer } from "../StationReadings/_widget/stationReadingsToolbar";
import close from "../../../../assets/close.png";
import check from "../../../../assets/check-mark.png";
import switchUser from "../../../../assets/switUser241.png";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { debounce } from "lodash";
import { MiniCheckBoxContainer } from "./mini_checkbox_container";
import CustomLoader from "../../../accessories/custom-loader";
import { Spin } from "antd";

interface Props {
    homeStationId?: number;
    upParentState?: any; // will be used to update the parent state
    selectedOperators?: any[];
    handleShiftChange: () => Promise<void>;
}

export const MiniForm = ({ homeStationId = 0, upParentState = () => { }, handleShiftChange }: Props) => {


    const [inCommingStatus, setInCommingStatus] = useState({ IncomingUsername: "", IncomingPassword: "", disabledInput: false, disabledPwd: false, status: false });
    const [outgoningStatus, setOutgoingStatus] = useState({ OutgoingUsername: "", OutgoingPassword: "", disabledInput: true, disabledPwd: true, status: false });
    const [empIDpk, uId, user] = useAccountInfo();
    const [state, setState] = useState({ lblInvalidIncoming: "Invalid", lblInvalidOutgoing: "Invalid", checkInIconStatus: "", checkOutIconStatus: "", btnShiftChange: false, clbOperators: [] });
    const [loadingOperators, setLoadingOperators] = useState(false);
    // const [selectedOperators, setSelectedOperators] = useState([]);

    const up = (key: string, value: any) => {
        setState((prev) => ({ ...prev, [key]: value }));
    }

    const checkIncommingCredentials = async (IncomingUsername: string, IncomingPassword: string) => {

        up("lblInvalidIncoming", " ");

        if (IncomingUsername !== "" && IncomingPassword !== "") {
            try {
                setLoadingOperators(true);
                const { data } = await GetDataFunc("ELogBook/CheckIncomingCredentials", {
                    IncomingUsername: IncomingUsername,
                    pswd: IncomingPassword,
                }, "powerapp");

                up("clbOperators", []); // clear the fetched operators

                if (data.data === null) {
                    up("lblInvalidIncoming", data.message ?? "Invalid Credentials");
                    upParentState("selectedOperators", []); // clear the selected operators
                    setInCommingStatus((prev) => ({ ...prev, status: false })); // this means that the validation is unsuccessful
                    setOutgoingStatus((prev) => ({ ...prev, status: false }));  // this means that the validation is unsuccessful
                    setLoadingOperators(false);
                    return;
                } else if (data.data.usaChangePassword === true) {
                    up("lblInvalidIncoming", data.message ?? "Password Expired");
                    setInCommingStatus((prev) => ({ ...prev, status: false })); // this means that the validation is unsuccessful
                    setOutgoingStatus((prev) => ({ ...prev, disabledPwd: true }));
                    setLoadingOperators(false);
                    return;
                } else if (data.data.usaActive === false) {
                    up("lblInvalidIncoming", data.message ?? "Account Inactive");
                    setInCommingStatus((prev) => ({ ...prev, status: false })); // this means that the validation is unsuccessful
                    setOutgoingStatus((prev) => ({ ...prev, disabledPwd: true }));
                    setLoadingOperators(false);
                    return;
                }

                //this two will be updating some states in the parent file to be used in the event msg
                upParentState("incomingEmpName2", data.data?.empName2);
                upParentState("incomingStaffNox", data.data.empStaffNo);

                const isShiftLeader = (await getShiftLeaderStatus(data.data.empIDpk, homeStationId));
                setOutgoingStatus((prev) => ({ ...prev, disabledInput: true })); // disable the outgoing input field
                if (isShiftLeader === true) {
                    up("checkInIconStatus", true); // show the check icon
                    //updating incoming status to true
                    setInCommingStatus((prev) => ({ ...prev, status: true })); // this means that the validation is successful

                    //populate the the operator details in the textarea
                    await popClbOperators();
                    setOutgoingStatus((prev) => ({ ...prev, disabledPwd: false })); // enable the outgoing input field
                    setOutgoingStatus((prev) => ({ ...prev, OutgoingUsername: user.usaUsername })); // enable the outgoing input field

                    up("lblInvalidIncoming", "");
                    setLoadingOperators(false);
                } else {
                    up("checkInIconStatus", false); // show the close icon
                    setInCommingStatus((prev) => ({ ...prev, status: false })); // this means that the validation is unsuccessful

                    up("lblInvalidIncoming", "Operator is not a Shift Leader");
                    setOutgoingStatus((prev) => ({ ...prev, disabledPwd: true })); // disable the outgoing input field
                    setOutgoingStatus((prev) => ({ ...prev, OutgoingUsername: user.usaUsername })); // enable the outgoing input field
                    setOutgoingStatus((prev) => ({ ...prev, disabledInput: true })); // enable the outgoing input field  
                    upParentState("selectedOperators", []); // clear the selected operators
                    setLoadingOperators(false);
                }
            } catch (error) {
                up("lblInvalidIncoming", "Invalid Credentials");
                up("checkInIconStatus", false); // show the close icon
                upParentState("selectedOperators", []); // clear the selected operators
                up("clbOperators", []);
                setLoadingOperators(false)
            }

        } else {
            up("checkInIconStatus", ""); // hide the btnCheckInCredentials button
        }

    }

    const checkOutgoingCredentials = async (OutgoingPassword: string) => {

        up("lblInvalidOutgoing", " ");

        if (outgoningStatus.OutgoingUsername !== "" && OutgoingPassword !== "") {
            try {
                const { data } = await GetDataFunc("ELogBook/CheckOutGoingCredentials", {
                    OutgoingUsername: outgoningStatus.OutgoingUsername,
                    pswd: OutgoingPassword,
                }, "powerapp");

                if (data.data === true && data.code === 200) {
                    up("checkOutIconStatus", true); // show the check icon 
                    setOutgoingStatus((prev) => ({ ...prev, status: true }));
                } else {
                    resetAfterCheckOutGoingChangeIsFalse();
                }
            } catch (error) {
                resetAfterCheckOutGoingChangeIsFalse();
            }
        } else {
            up("checkOutIconStatus", " "); // hide the btnCheckOutCredentials button
        }
    }

    const enableDisableShiftChangeButton = () => {
        if (inCommingStatus.status === true && outgoningStatus.status === true) {
            up("btnShiftChange", true); // enable the shift change button in the parent file
        } else {
            up("btnShiftChange", false); // disable the shift change button in the parent file
        }
    }

    const handleCheckIncomingInputChange = async (staffNo: any) => {
        setInCommingStatus((prev) => ({ ...prev, IncomingUsername: staffNo }));
        await checkIncommingCredentials(staffNo, inCommingStatus.IncomingPassword);
    }

    const handleCheckIncomingPwdChange = async (pswd: any) => {
        setInCommingStatus((prev) => ({ ...prev, IncomingPassword: pswd }));
        await checkIncommingCredentials(inCommingStatus.IncomingUsername, pswd);
    }

    const handleCheckOutgoingInputChange = async (username: any) => {
        setOutgoingStatus((prev) => ({ ...prev, OutgoingUsername: username }));
    }

    const handleCheckOutgoingPwdChange = async (pswd: any) => {
        setOutgoingStatus((prev) => ({ ...prev, OutgoingPassword: pswd }));
        checkOutgoingCredentials(pswd);
    }

    const debouncedHandleCheckIncomingInputChange = debounce((staffNo: string) => handleCheckIncomingInputChange(staffNo), 2000); //2s delay 

    const debouncedHandleCheckIncomingPwdChange = debounce((pswd: string) => handleCheckIncomingPwdChange(pswd), 2000); // 2s delay  

    const debouncedHandleCheckOutgoingInputChange = debounce((username: string) => handleCheckOutgoingInputChange(username), 2000);

    const debouncedHandleCheckOutgoingPwdChange = debounce((pswd) => handleCheckOutgoingPwdChange(pswd), 2000);

    const getShiftLeaderStatus = async (empIDpk: number, homeStationId: number): Promise<boolean> => {
        const { data } = await GetDataFunc("ELogBook/CheckIfshiftLeader", { empIDpk, homeStationId }, "powerapp");
        return data.data;
    }

    const popClbOperators = async () => {
        const { data } = await GetDataFunc("ELogBook/PopClbOperators", { homeStationId: homeStationId }, "powerapp");
        up("clbOperators", data.data);
    }

    const handleRefreshCblOperators = async () => {
        if (inCommingStatus.status === true) {
            setLoadingOperators(true);
            await popClbOperators();
            setLoadingOperators(false);
        }
    }

    const resetAfterCheckOutGoingChangeIsFalse = () => {
        up("checkOutIconStatus", false);
        up("btnShiftChange", false);
        upParentState("btnAddLogStatus", true); // disable the add log button if the credentials are invalid or the user is not a shift leader in the parent file
        setOutgoingStatus((prev) => ({ ...prev, status: false })); // disable the outgoing input field
        up("lblInvalidOutgoing", "Invalid Credentials");
    }

    useEffect(() => {
        enableDisableShiftChangeButton();
    }, [inCommingStatus.status, outgoningStatus.status]);

    return (

        <section className="flex space-x-2 m-2">
            <section className="w-[80%]">
                <Container
                    title="Incoming Shift Crew Login"
                    textColor={"text-green-500"}
                >
                    <section className="flex space-x-1 items-center h-full">
                        <aside className="h-36 p-3 w-[23%]">
                            <aside>
                                <p className="text-sm">Username or Staff No:</p>
                                <section className="flex space-x-1 items-center w-full">
                                    <div className="flex-1">
                                        <InputsTemplate
                                            span={true}
                                            inputStyle={{ height: "24px" }}
                                            disabledStatus={inCommingStatus.disabledInput}
                                            useCallbackFunc
                                            orderOnchange={(staffNo: string) => debouncedHandleCheckIncomingInputChange(staffNo)}
                                            placeholder={inCommingStatus.IncomingUsername}
                                        />
                                    </div>
                                    {state.checkInIconStatus !== "" && <IconsContainer url={state.checkInIconStatus ? check : close} />}
                                </section>
                            </aside>
                            <aside>
                                <p className="text-sm">Password:</p>
                                <div className={`${state.checkInIconStatus !== "" && "mr-11"}`}>
                                    <InputsTemplate
                                        span={true}
                                        disabledStatus={inCommingStatus.disabledPwd}
                                        inputStyle={{ height: "24px" }}
                                        useCallbackFunc
                                        orderOnchange={(pswd: string) => debouncedHandleCheckIncomingPwdChange(pswd)}
                                        inputType={"password"}
                                        placeholder={inCommingStatus.IncomingPassword}
                                    />
                                </div>
                            </aside>
                            <p className="text-red-500">{state.lblInvalidIncoming}</p>
                        </aside>
                        <aside className="w-[77%] h-[150px] border-2 rounded-md overflow-hidden">
                            {loadingOperators && <Spin tip="Loading operators..." size="large"><div className="rounded-md p-[80px]" /></Spin>}
                            <aside className="flex space-x-1 w-full h-full flex-row-reverse">
                                {/* <aside className="flex space-x-1 w-[77%] h-[150px] border-2 rounded-md flex-row-reverse"> */}
                                <aside className="flex justify-end p-0.5 ">
                                    <RefreshButton onClick={handleRefreshCblOperators} />
                                </aside>
                                <aside className="w-full">
                                    <MiniCheckBoxContainer options={state.clbOperators} onSelectionChange={(selectedOperators) => upParentState("selectedOperators", [...selectedOperators])} />
                                </aside>
                            </aside>
                        </aside>
                    </section>
                </Container>
            </section>
            <section className="w-[20%]">
                <Container title="Outgoining Shift Crew Out" textColor={"text-red-500"}>
                    <aside className="h-36 p-3 w-full">
                        <section className="flex space-x-1 items-center w-full">
                            <div className="flex-1 w-[10px]">
                                <InputsTemplate
                                    autoComplete="off"
                                    span={true}
                                    disabledStatus={outgoningStatus.disabledInput}
                                    inputStyle={{ height: "24px" }}
                                    useCallbackFunc
                                    orderOnchange={(username: string) => debouncedHandleCheckOutgoingInputChange(username)}
                                    placeholder={outgoningStatus.OutgoingUsername}
                                />
                            </div>
                            {state.checkOutIconStatus !== "" && <IconsContainer url={state.checkOutIconStatus ? check : close} />}
                        </section>
                        <div className={`${state.checkOutIconStatus !== "" && "mr-11"}`}>
                            <InputsTemplate
                                span={true}
                                disabledStatus={outgoningStatus.disabledPwd}
                                inputStyle={{ height: "24px" }}
                                useCallbackFunc
                                orderOnchange={(pswd) => debouncedHandleCheckOutgoingPwdChange(pswd)}
                                inputType={"password"}
                                placeholder={outgoningStatus.OutgoingPassword}
                                autoComplete="off"
                            />
                        </div>
                        <p className="text-red-500">{state.lblInvalidOutgoing}</p>
                        <section className={`flex border-2 border-neutral-300 p-2 rounded-md items-center justify-center mt-2  space-x-2 ${state.btnShiftChange ? "bg-green-300 cursor-pointer" : "bg-gray-100 cursor-not-allowed "}`} onClick={(async () => { state.btnShiftChange && await handleShiftChange(); })}>
                            <img src={switchUser} alt="switch" />
                            <p>shift change</p>
                        </section>
                    </aside>
                </Container>
            </section>
        </section>
    );
};

const Container = ({ title, children, textColor }: any) => {
    return (
        <aside className="w-full h-auto border-[1px] rounded-md relative">
            <section
                className={`text-[12px] absolute -top-2 bg-white left-2 px-1 ${textColor} font-bold`}
            >
                {title}
            </section>
            <section className="w-full h-full p-2">{children}</section>
        </aside>
    );
};
