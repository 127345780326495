import axios from "axios";
import { userDataCrypt } from "../userDataEncrypt";
import { error } from "console";
interface props {
  route?: string;
  setState?: (e: any) => void;
}

export const validateBeforeSaveORUpdate = (
  method: "save" | "update",
  conditions: any[],
  operationSave: () => void,
  operationUpdate: () => void,
  messageModal: (e: any) => void
) => {
  let isError = conditions.find(({ check }) => check);
  if (isError) {
    messageModal((prev: any) => ({
      ...prev,
      icon: "warning",
      title: isError?.title,
      message: isError?.message,
      openModal: true,
      showCancel: true,
      okText: "Okay",
      cancelText: "",
      okHandler: () => {
        messageModal((prev: any) => ({
          ...prev,
          openModal: false,
        }));
      },
    }));
  } else {
    if (method === "save") {
      operationSave();
    }
  }
};

export const Fetch_Data = async ({ route, }: props) => {
  let item = sessionStorage.getItem("client");
  let encItem = userDataCrypt("decrypt", item);
  let parsedItem = JSON.parse(encItem);
  try {
    const res = await axios.get(route, {
      headers: {
        "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
        "ngrok-skip-browser-warning": "true",
        Authorization: `Bearer ${parsedItem?.token}`,
      },
    });
    // setState((prev: any) => ({
    //   ...prev,
    //   successStatusData: res?.data,
    //   errorStatusData: "",
    //   isSuccess: true,
    //   isError: false,
    // }));
    return { isError: false, data: res.data };
  } catch (error) {
    console.log(error);
    console.log(error?.response?.data);

    // setState && setState(error?.response?.data?.message)
    // setState((prev: any) => ({
    //   ...prev,
    //   successStatusData: "",
    //   errorStatusData: error?.response?.data,
    //   isSuccess: false,
    //   isError: true,
    // }));
    return { isError: true, data: error?.response?.data };
  }
};
