
export const lineData_TH = [
  { id: 0, caption: "NOM.", dataField: "ldtNomenclature", dataType: 'string', fixed: true, width: null },
  { id: 1, caption: "FROM", dataField: "sourceStationName", dataType: 'string', fixed: true, width: null },
  { id: 2, caption: "TO", dataField: "destStationName", dataType: 'string', fixed: true, width: null },
  { id: 3, caption: "LENGTH", dataField: "ldtLength", dataType: '', fixed: true, width: null },
  { id: 4, caption: "CONST. DATE", dataField: "ldtConstructionDate", dataType: "datetime", fixed: true, width: null },
  { id: 5, caption: "OPERATING VOLTAGE", dataField: "ldtOperatingVoltage", dataType: '', fixed: true, width: null },
  { id: 6, caption: "BUNDLED?", dataField: "ldtBundled", dataType: 'boolean', fixed: true, width: 60 },
  { id: 7, caption: "ACTIVE?", dataField: "ldtActive", dataType: 'boolean', fixed: true, width: 60 },
  { id: 8, caption: "SISR?", dataField: "ldtShowInStationReadings", dataType: 'boolean', fixed: false, width: 60 },
  { id: 9, caption: "PLANT LINE?", dataField: "ldtPlantLine", dataType: 'boolean', fixed: false, width: 60 },
  { id: 10, caption: "VERIFIED?", dataField: "ldtVerified", dataType: 'boolean', fixed: false, width: 60 },
  { id: 11, caption: "CONDUT. TYPE", dataField: "cdtName",dataType: 'string', fixed: false, width: null },
  { id: 12, caption: "CONDUT. CODE NAME", dataField: "ccnName", dataType: 'string', fixed: false, width: null },
  { id: 13, caption: "CSA", dataField: "ldtConductorCrossSectionalArea", dataType: '', fixed: false, width: null },
  { id: 14, caption: "MANUFACTURER", dataField: "mftName", dataType: 'string', fixed: false, width: null },
  { id: 15, caption: "COMMISSION DATE", dataField: "ldtCommissionDate", dataType: 'datetime', fixed: false, width: null },
  { id: 16, caption: "DECOMMISSION DATE", dataField: "ldtDecommissionDate", dataType: 'datetime', fixed: false, width: null },
  { id: 17, caption: "+ve SEQ RES/km", dataField: "ldtPositiveSeqResistancePerKm", dataType: '', fixed: false, width: null },
  { id: 18, caption: "+ve SEQ REACT/km", dataField: "ldtPositiveSeqReactancePerKm", dataType: '', fixed: false, width: null },
  { id: 19, caption: "SUSCEPTANCE", dataField: "ldtSusceptancePerKm", dataType: '', fixed: false, width: null },
  { id: 20, caption: "RES. on 100 MVA BASE", dataField: "ldtResistanceOn100MvaBase", dataType: '', fixed: false, width: null },
  { id: 21, caption: "REACT. on 100 MVA BASE", dataField: "ldtReactanceOn100MvaBase", dataType: '', fixed: false, width: null },
  { id: 22, caption: "SUSCEPT. on 100 MVA BASE", dataField: "ldtSusceptanceOn100MvaBase", dataType: '', fixed: false, width: null },
  { id: 23, caption: "ZERO SEQ RESIST.", dataField: "ldtZeroSeqResistance", dataType: '', fixed: false, width: null },
  { id: 24, caption: "ZERO SEQ REACT.", dataField: "ldtZeroSeqReactance", dataType: '', fixed: false, width: null },
  { id: 25, caption: "ZERO SEQ SUSPECT", dataField: "ldtZeroSeqSusceptance", dataType: '', fixed: false, width: null },
  { id: 26, caption: "ZERO SEQ REST. on 100MVA", dataField: "ldtZeroSeqResistanceOn100MvaBase", dataType: '', fixed: false, width: null },
  { id: 27, caption: "ZERO SEQ REACT. on 100MVA", dataField: "ldtZeroSeqReactanceOn100MvaBase", dataType: '', fixed: false, width: null },
  { id: 28, caption: "ZERO SEQ SUSCEPT. on 100MVA", dataField: "ldtZeroSeqSusceptanceOn100MvaBase", dataType: '', fixed: false, width: null },
  { id: 29, caption: "THERMAL LIMIT", dataField: "ldtThermalLimit", fixed: false, dataType: '', width: null },
  { id: 30, caption: "SIL", dataField: "ldtSurgeImpedanceLoading", fixed: false, dataType: '', width: null },
  { id: 31, caption: "START LATITUDE", dataField: "ldtStartLatitude", fixed: false, dataType: '', width: null },
  { id: 32, caption: "END LATITUDE", dataField: "ldtEndLatitude", fixed: false, dataType: '', width: null },
  { id: 33, caption: "START LONGITUDE", dataField: "ldtStartLongitude", fixed: false, dataType: '', width: null },
  { id: 34, caption: "END LONGITUDE", dataField: "ldtEndLongitude", fixed: false, dataType: '', width: null },
  { id: 35, caption: "REMARKS", dataField: "ldtRmks", fixed: false, dataType: 'string', width: 4000 }

]
