import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import staffDetailsSlice from "../features/forms/entries/hr/staffDetailsSlice";
import tier1AddReducer from "../features/forms/tier1/createSlice";
import tier2Slice from "../features/forms/tier2/tier2Slice";
import tier3Slice from "../features/forms/tier3/tier3Slice";
import tier4Slice from "../features/forms/tier4/tier4Slice";
import generalSlice from "../features/generalSlice";
import Checkstate from "../features/Task&Todo/Task/Checkstate";
import TaskDetails from "../features/Task&Todo/Task/TaskDetails";
import ppeRequest from "../features/forms/entries/safetyManager/ppeRequest";
import tier2SliceCheckbox from "../features/forms/tier2checkbox/tier2SliceCheckbox";
import toolbar from "../features/forms/entries/toolbar/toolbar";
import Estate from "../features/forms/entries/RealEstate/Estate";
import user from "../features/user";
import { VehicleModuleSlicers } from "../features/VehicleRedux/TMSconbinedReducers/Vehicle/VehicleModuleSlicers";
import MaintenanceScheduleSlice from "../features/VehicleRedux/TMS-Sicers/Maintenance/MaintenanceScheduleSlice";
// import apiSlice from "../features/apiCalls/apiSlice";
import vehicleCardAssignApiSlice from "../features/apiCalls/vehicleCardAssignApiSlice";
import vehicleLogBookApiSlice from "../features/apiCalls/vehicleLogBookApiSlice";

// import apiSlice from "../features/apiCalls/VehiicleDetailsApi/apiSlice";
import requisitionDetailsSlice from '../features/apiCalls/vehicleRequestApi/RequisitionDetails';
import vehSIMcardApi from "../features/apiCalls/vehSIMcardApi";
import apiSlice from "../features/apiCalls/VehiicleDetailsApi/apiSlice";
import vehConditionsApi from "../features/apiCalls/vehConditionsApi";
import maintainanceActivitiesSlice from '../features/apiCalls/maintenanceactApi/maintenancesActivities';
import jobDetailsSlice from '../features/apiCalls/maintenanceactApi/jobActiveDetails';
import materialsSlice from '../features/apiCalls/maintenanceactApi/materials';
import jobUpdateSlice from '../features/apiCalls/maintenanceactApi/jobUpdate';
import FuelDumpDipsSlice from '../features/apiCalls/FuelDumpDips/index';
import BacklogFuelEntries from '../features/apiCalls/BacklogFuelEntries/index';
import CardDetailsSlice from '../features/apiCalls/CardDetails/index';
// import requisitionDetailsSlice from '../features/apiCalls/vehicleRequestApi/RequisitionDetails';
import requisitionApprovalSlice from "../features/apiCalls/vehicleRequestApi/RequistionApproval";

// import FuelDumpDipsSlice from '../features/apiCalls/FuelDumpDips/index';
// import BacklogFuelEntries from '../features/apiCalls/BacklogFuelEntries/index';
// import CardDetailsSlice from '../features/apiCalls/CardDetails/index';


import maintenanceScheduleApi from "../features/apiCalls/maintenanceScheduleApi";
import transDoc from "../features/apiCalls/transDoc";
import vehAssign from "../features/apiCalls/vehAssign";
import maintenanceRequestApiSlice from "../features/apiCalls/maintenanceRequestApiSlice";
import ReturnReqCard from "../features/apiCalls/FuelReturnRequestApi/ReturnReqCard";
import maintCapApi from "../features/apiCalls/maintCapApi";

import FuelTopupRequestSlice from "../features/apiCalls/FuelTopupRequest/index";
import ReturnReqDumb from "../features/apiCalls/FuelReturnRequestApi/ReturnReqDumb";
import jobUpdate from "../features/apiCalls/maintenanceactApi/jobUpdate";
import materials from "../features/apiCalls/maintenanceactApi/materials";
import elogBook  from '../features/apiCalls/elogbook' ;

export const store = configureStore({
  reducer: {
    user: user,
    checkbox: Checkstate,
    tier1Add: tier1AddReducer, //type 1 forms slice
    tier2forms: tier2Slice, //type 2 forms slice
    tier3forms: tier3Slice, //type 3 forms slice
    tier4forms: tier4Slice, //type 4 forms slice
    general: generalSlice, //general slice
    addTask: TaskDetails,
    safetyManagerDetails: ppeRequest, //safety managerSlice
    tier2Ckeckboxforms: tier2SliceCheckbox, //type 2 forms slice
    staffDetailsSlice: staffDetailsSlice,
    toolbarDetailsSlice: toolbar, //general toolbar details slice
    RealEstateDetails: Estate, // Real Estate slice
    FuelDumpDips: FuelDumpDipsSlice,
    CardDetails: CardDetailsSlice,
    BacklogFuelEntries: BacklogFuelEntries,
    apiCalls: apiSlice,
    vehicleCardAssign: vehicleCardAssignApiSlice,
    vehicleLogBook: vehicleLogBookApiSlice,
    vehAssignSlice: vehAssign,
    transDocSlice: transDoc,
    FuelTopupRequestSlice: FuelTopupRequestSlice,
    requisitionDetails: requisitionDetailsSlice,
    vehCondition: vehConditionsApi,
    vehSIM: vehSIMcardApi,
    maintSchApi: maintenanceScheduleApi,
    maintCap: maintCapApi,
    mainType: maintenanceRequestApiSlice,
    returnCard: ReturnReqCard,
    // apiCalls: apiSlice,
    // vehicleCardAssign: vehicleCardAssignApiSlice,
    // vehicleLogBook: vehicleLogBookApiSlice,
    // vehAssignSlice: vehAssign,
    // transDocSlice: transDoc,

    requisitionApproval:requisitionApprovalSlice,
    maintainanceActivities:maintainanceActivitiesSlice,
    jobDetails:jobDetailsSlice,
    materialsSlice : materials,
     jobUpdateSlice:jobUpdate,
    vehCon : vehConditionsApi,
    returnDump : ReturnReqDumb,
    // requisitionDetails: requisitionDetailsSlice,
    materials: materialsSlice,
    jobUpdate: jobUpdateSlice,
    // vehCondition: vehConditionsApi,
    // vehSIM: vehSIMcardApi,
    // maintSchApi: maintenanceScheduleApi,
    // maintCap: maintCapApi,
    // mainType: maintenanceRequestApiSlice,
    // returnCard: ReturnReqCard,

    //---------- eagleMentality🦅--------------------------------------//
    TMSModule1: VehicleModuleSlicers,
    TMSMaintenance: MaintenanceScheduleSlice,
    //-----------------------------------------------------------------//
    elogBook: elogBook,
    //---------- eagleMentality🦅--------------------------------------//
    // TMSModule1: VehicleModuleSlicers,
    // TMSMaintenance: MaintenanceScheduleSlice,
    //-----------------------------------------------------------------//
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
