/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { SaveButton } from "../../../accessories/buttons";
import { TextareaTemplate } from "../../../../templates/textarea";
import { currentDate } from "../../../accessories/component_infos";
import { Form } from "antd";
import { useEffect, useRef, useState } from "react";
import { correspondent_column, reportingStation_column } from "./subsections/Other/dropdown_datagrid_columns";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";
import dayjs from "dayjs";
import { useCrudFunc } from "../../../../functions/crud";
import { GetDataFunc } from "../../../../functions/get";
import { MiniForm } from "./MiniForm";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../templates/modal";
import { UpdateDataFunc } from "../../../../functions/update";
import { setSearchParamOnChange } from "../../../../features/apiCalls/elogbook";
import CustomLoader from "../../../accessories/custom-loader";
// import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import search from "../../../../assets/search1.png";
import { SlbLogPreviewDialog } from "./slbLogPreviewDialog";
import DateTemp from "../components/dateTemp";
import DropDownTemp from "../components/dropDownTemp";
import GridDropdownTemp from "../components/gridDropdownTemp";
import { useNonInitialEffect } from "../util/util";
interface props {
  showMiniForm: boolean;
  midNightCondition?: boolean;
  shiftchangeClick: boolean;
  updateMiniForm: (value: boolean) => void;
  selectedData: any;
  setMidnightConditionsClick?: () => void;
  setShowTemplateModal?: (e: any) => void;
  isUpdateTemplateBtnClicked?: boolean;
  setIsUpdateTemplateBtnClicked?: (e: any) => void;
  isRemoteShiftBtnClicked?: boolean;
  setIsRemoteShiftBtnClicked?: (e: any) => void;
  setShowRemoteShift?: (e: any) => void;
  isShiftTakeOverClicked?: boolean;
  isUpdateCurrentShiftMembersClicked?: boolean;
  setIsUpdateCurrentShiftMembersClicked?: (e: any) => void;
  setShowShiftMembers?: (e: any) => void;
  setIsShiftTakeOverClicked?: (e: any) => void;
  setShowShiftTakeover?: (e: any) => void;
  isEventTypeBtnClicked?: boolean;
  setIsEventTypeBtnClicked?: (e: any) => void;
  setShowEventTypes?: (e: any) => void;
  resizeTextArea?: number;
  setResizeTextArea?: (e: any) => void;
  setSwitchForm?: (e: any) => void;
  setSwitchFormDisabled?: (e: any) => void;
  spellCheck?: boolean;
  onDeleteLogClicked?: boolean;
  onRowDoubleClick?: any;
}
interface FormStateTypes {
  eventTime: { time: any; status: boolean };
  eventType: { id: number; name: string; status: boolean };
  reportingStations: { id: 0; name: string; status: boolean };
  correspondent: { id: number; name: string; status: boolean };
  homeStation: { id: number; name: string; status: boolean };
  eventLog: { value: string; status: boolean };
  selectedOperators: any[];
  incomingEmpName2: string;
  incomingStaffNox: string;
  btnAddLogStatus: boolean;
  btnSaveText: string;
  mode: string;
  eventTypeList: any[];
  changeShiftNowx: boolean;
  lblEventText: string;
  midnightShiftChangeEventTypeIDs: {
    midnightConditionEventTypeID: number;
    shiftChangeEventTypeID: number;
    informationEventTypeID: number;
  };
  correspondentsList: any[];
  reportingStationsList: any[];
  homeStationsList: any[];
  loading: { status: boolean, message: string };
  showPreviewDialog: { status: boolean, showButton: boolean };
  lblState: string;
}
interface RefTypes {
  slbOperatorActivStatus: boolean;
  dashCorrespondentIDx: number;
  homeStation: { id: number; name: string };
}

export const ElogBookMainFormV2 = ({
  showMiniForm,
  shiftchangeClick,
  updateMiniForm,
  midNightCondition,
  selectedData,
  setMidnightConditionsClick,
  setShowTemplateModal,
  isUpdateTemplateBtnClicked,
  setIsUpdateTemplateBtnClicked,
  isRemoteShiftBtnClicked,
  setIsRemoteShiftBtnClicked,
  setShowRemoteShift,
  isShiftTakeOverClicked,
  setIsShiftTakeOverClicked,
  setShowShiftTakeover,
  isUpdateCurrentShiftMembersClicked,
  setIsUpdateCurrentShiftMembersClicked,
  setShowShiftMembers,
  isEventTypeBtnClicked,
  setIsEventTypeBtnClicked,
  setShowEventTypes,
  resizeTextArea,
  setResizeTextArea,
  spellCheck,
  onDeleteLogClicked,
  onRowDoubleClick,
  setSwitchForm,
  setSwitchFormDisabled
}: props) => {
  const [empIDpk, uId, user] = useAccountInfo();
  const [error, setError] = useState({
    status: false,
    box: 0,
    OkFunc: () => { },
  });

  const [formState, setFormState] = useState<FormStateTypes>({
    eventTime: { time: dayjs().format("ddd,DD MMM YYYY 23:59:00"), status: false },
    eventType: { id: 0, name: "", status: false },
    reportingStations: { id: 0, name: "", status: false },
    correspondent: { id: 0, name: "", status: false }, //
    homeStation: { id: 0, name: "", status: false }, //
    eventLog: { value: "", status: false },
    selectedOperators: [],
    incomingEmpName2: "",
    incomingStaffNox: "",
    btnAddLogStatus: false,
    btnSaveText: "Save Log",
    mode: "i",
    eventTypeList: [],
    changeShiftNowx: false,
    lblEventText: "Event Log",
    midnightShiftChangeEventTypeIDs: {
      midnightConditionEventTypeID: 0,
      shiftChangeEventTypeID: 0,
      informationEventTypeID: 0,
    },
    correspondentsList: [],
    reportingStationsList: [],
    homeStationsList: [],
    loading: { status: true, message: "Loading form..." },
    showPreviewDialog: { status: false, showButton: false },
    lblState: "New Log..."
  });

  const [messageModal, setMessageModal] = useState<{
    icon: "warning" | "question" | "success";
    title: string;
    message: string;
    openModal: boolean;
    hideCancel: boolean;
    okText: string;
    cancelText: string;
    okHandler: () => void;
    cancelHandler: () => void;
  }>({
    icon: "warning",
    title: "",
    message: "",
    openModal: false,
    hideCancel: false,
    okText: "",
    cancelText: "",
    okHandler: () => {
      setMessageModal((p) => ({ ...p, openModal: false }));
    },
    cancelHandler: () => {
      setMessageModal((p) => ({ ...p, openModal: false }));
    },
  });

  const { current: instantChangesState } = useRef<RefTypes>({
    slbOperatorActivStatus: false,
    dashCorrespondentIDx: 0,
    homeStation: { id: 0, name: "" },
  });


  const updateState = (key: string, value: any) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };
  const dispatch = useDispatch();

  const tState = useSelector((state: any) => state.elogBook) ?? {};

  const eventLogRef = useRef<any>();
  const eventTimeRef = useRef<any>();

  const DeleteOrRestoreLog = async () => {
    updateState("loading", { status: true, message: "Loading..." });
    const res = await getCaptureBacklogStatus(selectedData?.lbkHomeStationIDfk);

    if (res?.pvaCaptureBacklogData === true) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access Denied",
        message: "You do not have the privileges to manage backlog data",
        okText: "Okay",
        hideCancel: true,
        openModal: true,
        okHandler: () => { setMessageModal((prev) => ({ ...prev, openModal: false })) },
      }));
      updateState("loading", { ...formState.loading, status: false });
      return;
    } else if (dayjs(selectedData?.lbkLogDate).format("YYYY-MM-DD") < dayjs(res?.pvaStartDate).format("YYYY-MM-DD")
      || dayjs(selectedData?.lbkLogDate).format("YYYY-MM-DD") > dayjs(res?.pvaEndDate).format("YYYY-MM-DD")) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Date Out of Range",
        message: `You are allowed to manage logs between ${dayjs(res?.pvaStartDate).format("YYYY-MM-DD")} and ${dayjs(res?.pvaEndDate).format("YYYY-MM-DD")}.`,
        okText: "Okay",
        hideCancel: true,
        openModal: true,
        okHandler: () => { setMessageModal((prev) => ({ ...prev, openModal: false })) },
      }));
      updateState("loading", { ...formState.loading, status: false });
      return;
    }

    if ((await getShiftLeaderStatus(empIDpk, selectedData?.lbkHomeStationIDfk)) === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Insufficient Privilege",
        message:
          "You do not have the priveleges to Delete records. \n Please contact your Shift Leader or Systems Administrator for assistance",
        okText: "Okay",
        hideCancel: true,
        openModal: true,
        okHandler: () => { setMessageModal((prev) => ({ ...prev, openModal: false })) },
      }));

      if (eventTimeRef.current) {
        eventTimeRef.current?.focus();
      }
      updateState("loading", { ...formState.loading, status: false });
      return;
    }
    const lbkLogDate = await compareLastShiftChangeTime(selectedData.lbkHomeStationIDfk);
    // perform checks here first
    if (dayjs(selectedData?.lbkLogDate).format("YYYY-MM-DDTHH:mm:ssZ[Z]") <= dayjs(lbkLogDate).format("YYYY-MM-DDTHH:mm:ssZ[Z]")) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Log Archived",
        message:
          "The selected Log has been archived and cannot be DELETED. \n Please contact your Systems Administrator for assistance",
        okText: "Okay",
        hideCancel: true,
        openModal: true,
        okHandler: () => { setMessageModal((prev) => ({ ...prev, openModal: false })) },
      }));
      updateState("loading", { ...formState.loading, status: false });
      return;
    }

    updateState("loading", { ...formState.loading, status: false });

    const condition = selectedData?.lbkActive === true ? "Delete" : "Restore";
    setMessageModal((prev: any) => ({
      ...prev,
      icon: "question",
      title: `${condition} Log?`,
      message: `Are you sure you want to ${condition} the selected event log?`,
      okText: "Yes",
      hideCancel: false,
      openModal: true,
      cancelText: "No, Cancel",
      okHandler: async () => {
        setMessageModal((prev) => ({ ...prev, openModal: false }))
        await handleDeleteOrRestoreLog(selectedData?.lbkIDpk, selectedData.lbkActive);
      },
      cancelHandler: () => {
        setMessageModal((prev) => ({ ...prev, openModal: false }))
      },
    }));
  };

  const [postData, updateData] = useCrudFunc("powerapp");

  const handleSaveShiftChangeLog = async (
    onDuty: string,
    outgoingMembs: string
  ) => {
    updateState("loading", { status: true, message: "Logging shift change event..." });
    const eventx = `SUMMARY: ${formState.eventLog.value} \n ON DUTY: ${onDuty} \n OFF DUTY: ${outgoingMembs}`;
    eventx.trim();

    try {
      await postData(
        "ELogBook/SaveShitChange",
        {
          lbkEventTypeIDfk: formState.midnightShiftChangeEventTypeIDs.shiftChangeEventTypeID,
          lbkReportingStationIDfk: formState.reportingStations.id,
          homeStationIDfk: formState.homeStation.id,
          lbkCorrespondentIDfk: formState.correspondent.id,
          lbkEvent: eventx,
          eventTme: dayjs(formState.eventTime.time).format("YYYY-MM-DDTHH:mm:ss"),
          onDuty: onDuty,
        },
        `Logged shift change for ${formState.homeStation.name}, ${formState.eventTime.time}, ${onDuty} on the event ${eventx}`
      );
      updateState("loading", { status: false, message: " " });
      //Logout the user by removing the token and redirecting to the login page
      localStorage.removeItem("token");
      localStorage.removeItem("client");
      window.location.href = "/";
    } catch (error) {
      updateState("loading", { status: false, message: " " });
      setMessageModal({
        ...messageModal,
        icon: "warning",
        title: "An Error Occured",
        hideCancel: true,
        message:
          "An Error Occured. Please contact your Systems Administrator for assistance",
        openModal: true,
        okHandler: () => {
          setMessageModal((p) => ({ ...p, openModal: false }));
        },
      });
    }
  };

  const handleSaveLog = async () => {
    updateState("loading", { status: true, message: "Logging event..." });
    try {
      await postData(
        "ELogBook/addLogBook",
        {
          lbkEventTypeIDfk: formState.eventType.id,
          lbkEventTypeName: formState.eventType.name,
          lbkReportingStationIDfk: formState.reportingStations.id,
          lbkHomeStationIDfk: formState.homeStation.id,
          lbkCorrespondentIDfk: formState.correspondent.id,
          event: formState.eventLog.value.trim(),
        },
        `Logged event for ${formState.homeStation.name}, ${formState.eventTime.time}, ${formState.correspondent.name} on the event ${formState.eventLog.value}`
      );

      if (formState.homeStation.name !== tState?.stationName?.value) {
        dispatch(
          setSearchParamOnChange({
            key: "stationName",
            value: {
              ...tState,
              stationName: {
                ...tState.stationName,
                initialValue: formState.homeStation.name,
              },
            },
          })
        );
      }

      if (
        formState.reportingStations.name !==
        tState?.logStationName?.initialValue &&
        tState?.logStationName?.initialValue?.trim() !== ""
      ) {
        dispatch(
          setSearchParamOnChange({
            key: "logStationName",
            value: {
              ...tState,
              logStationName: {
                ...tState.logStationName,
                initialValue: formState.reportingStations.name,
              },
            },
          })
        );
      }

      if (formState.eventType.name.toLowerCase() === "midnight conditions") {
        updateState("loading", { status: false, message: " " });
        setMessageModal({
          ...messageModal,
          title: "Update Midnight Conditions Template?",
          message:
            "Do you want to Update the Midnight Conditions Template with this log?",
          openModal: true,
          okText: "Yes",
          okHandler: async () => {
            await UpdateMidnightConditionsTemplates();
          },
        });
      }

      //refresh datagrid
      // dispatch(setSearchParamOnChange({ key: "refresh", value: { ...tState, refresh: !tState.refresh, } }));
      dispatch(setSearchParamOnChange({ key: "refresh", value: { refresh: !tState.refresh, } }));
      setResizeTextArea(90);
      updateState("loading", { status: false, message: "" });
      //show success message
      setMessageModal({
        ...messageModal,
        icon: "success",
        okText: "Okay",
        hideCancel: true,
        title: "Logged",
        message: "Record logged successfully",
        openModal: true,
        okHandler: () => { setMessageModal((p) => ({ ...p, openModal: false })); }
      });

      if (formState.eventType.name.toLowerCase() === "midnight conditions") {
        await popCmbEventTypes("");
        await popCmbCorrespondents();
        await popLueReportingStations();

        updateState("btnAddLogStatus", false);
        updateState("eventTime", { ...formState.eventTime, status: false });
        updateState("eventType", { ...formState.eventType, status: false });
        updateState("reportingStations", {
          ...formState.reportingStations,
          status: false,
        });
        updateState("correspondent", {
          ...formState.correspondent,
          status: false,
        });
        updateState("homeStation", { ...formState.homeStation, status: false });
      }

      //reset controls
      // updateState("mode", "o");
      resetControls();
    } catch (error) {
      updateState("loading", { status: false, message: " " });
      setMessageModal({
        ...messageModal,
        icon: "warning",
        hideCancel: true,
        okText: "Okay",
        title: "An Error Occured",
        message:
          "An Error Occured. Please contact your Systems Administrator for assistance",
        openModal: true,
        okHandler: () => { setMessageModal((p) => ({ ...p, openModal: false })); },
      });
    }
  };

  const handleUpdateLog = async () => {
    updateState("loading", { status: true, message: "Updating event..." });
    try {
      await updateData(
        "ELogBook/updateLogBook",
        {
          lbkIDpk: selectedData.lbkIDpk,
          lbkEventTypeIDfk: formState.eventType.id,
          lbkEventTypeName: formState.eventType.name,
          lbkReportingStationIDfk: formState.reportingStations.id,
          lbkHomeStationIDfk: formState.homeStation.id,
          lbkCorrespondentIDfk: formState.correspondent.id,
          event: formState.eventLog.value.trim(),
          eventTme: dayjs(formState.eventTime.time).format("YYYY-MM-DD HH:mm:ss"),
        },
        `Updated event for ${formState.homeStation.name}, ${formState.eventTime.time}, ${formState.correspondent.name} on the event ${formState.eventLog.value}`
      );

      //refresh event type dropdown
      popCmbEventTypes("");
      //refresh datagrid 
      dispatch(setSearchParamOnChange({ key: "refresh", value: { refresh: !tState.refresh, } }));
      updateState("loading", { status: false, message: " " });
      //show success message
      setMessageModal({
        ...messageModal,
        title: "Updated",
        message: "Event updated successfully",
        openModal: true,
      });

      resetControls();
      updateState("mode", "o");
    } catch (error) {
      updateState("loading", { status: false, message: " " });
      setMessageModal({
        ...messageModal,
        icon: "warning",
        title: "An Error Occured",
        message:
          "An Error Occured. Please contact your Systems Administrator for assistance",
        openModal: true,
      });
    }
  };

  const handleDeleteOrRestoreLog = async (lbkIDpk: number, state: boolean) => {
    updateState("loading", { status: true, message: `${state ? "Deleting" : "Restoring"} event log...` });
    try {
      await updateData(`ELogBook/DeleteOrRestoreLog`, { lbkIDpk, state }, `Updating a log event with ID: ${selectedData.lbkIDpk}`);
      updateState("mode", "o");
      dispatch(setSearchParamOnChange({ key: "refresh", value: { refresh: !tState.refresh, } })); //refreshing the main datagrid
      setMessageModal((prev) => ({
        ...messageModal,
        icon: "success",
        hideCancel: true,
        title: "Updated",
        openModal: true,
        message: `Record ${state ? "Deleted" : "Restored"} successfully`,
        okText: "Okay",
        okHandler: () => setMessageModal((prev) => ({ ...prev, openModal: false }))
      }));
      updateState("loading", { ...formState.loading, status: false });
      return;
    } catch (error) {
      setMessageModal((prev) => ({
        ...messageModal,
        icon: "warning",
        title: "An Error Occured",
        okText: "Okay",
        hideCancel: true,
        message:
          "An Error Occured. Please contact your Systems Administrator for assistance",
        openModal: true,
      }));
      updateState("loading", { ...formState.loading, status: false });
    }
  }

  const UpdateMidnightConditionsTemplates = async () => {
    updateState("loading", { status: true, message: "Updating Midnight Conditions Template..." });
    try {
      await UpdateDataFunc(
        "ELogBook/UpdateMidnightConditionsTemplates",
        {
          tmpText: formState.eventLog.value.trim(),
          tmpEventTypeIDfk: formState.eventType.id,
          tmpStationIDfk: formState.homeStation.id,
        },
        "powerapp"
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  let conditions = [
    {
      check: formState.eventType.name === "",
      title: "Event type empty",
      message: "Please select an event type",
    },
    {
      check: formState.reportingStations.name === "",
      title: "Reporting station",
      message: "Please select reporting station",
    },
    {
      check: formState.correspondent.name === "",
      title: "Correspondent",
      message: "Please select correspondent",
    },
    {
      check: formState.homeStation.name === "",
      title: "Home station",
      message: "Please select home station",
    },
    {
      check: formState.eventLog?.value === "",
      title: "Event log",
      message: formState.lblEventText !== "Summary" ? "Please enter an event log" : "Please enter a summary for the shift change",
    },
  ];

  const resetControls = () => {
    updateState("eventTime", { ...formState.eventTime, status: false, time: dayjs().format("ddd,DD MMM YYYY 23:59:00") }); //format("dd MMM yyyy 23:59:00")
    updateState("eventType", { ...formState.eventType, id: 0, name: "", status: false });
    updateState("reportingStations", { ...formState.reportingStations, id: 0, name: "", status: false });
    updateState("correspondent", { ...formState.correspondent, id: instantChangesState.dashCorrespondentIDx, name: "-", status: false });
    updateState("eventLog", { ...formState.eventLog, value: "" });
  };

  const handleShiftChange = async () => {
    await handleToolbarShiftChangeValidation();
  };

  const handleToolbarShiftChangeValidation = async () => {
    updateState("loading", { status: true, message: "Preparing to change shift..." });
    if (formState.homeStation.name === "") {
      updateState("loading", { status: false, message: "" });
      setError((p) => ({ ...p, status: true, box: 0, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
      return;
    }
    if (formState.eventType.name.toLowerCase() === "midnight conditions") {
      updateState("loading", { status: false, message: "" });
      setMessageModal({
        ...messageModal,
        title: msgBox[2].title,
        message: msgBox[2].message,
        openModal: true,
        hideCancel: false,
        okText: "Yes, Cancel",
        cancelText: "No",
        okHandler: async () => {
          await CancelMidnightConditions();
          await conitnueShiftChangeAfterCancellingMidnight();
          setMessageModal((p) => ({ ...p, openModal: false }));
        },
        cancelHandler: () => {
          setMessageModal((p) => ({ ...p, openModal: false }));
        },
      });
      return;
    }
    await conitnueShiftChangeAfterCancellingMidnight();
  };

  const conitnueShiftChangeAfterCancellingMidnight = async () => {
    if ((await getShiftLeaderStatus(empIDpk, formState.homeStation.id)) === false) {
      updateState("loading", { status: false, message: "" });
      setError((p) => ({ ...p, status: true, box: 1, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
      return;
    }
    if ((await checkIfUserIsInCurrentShift(user.empStaffno, formState.homeStation.id)) === false) {
      updateState("loading", { status: false, message: "" });
      setError((p) => ({ ...p, status: true, box: 3, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
      return;
    }
    updateState("eventTime", { ...formState.eventTime, time: dayjs().format("ddd,DD MMM YYYY 23:59:00") }); //format("dd MMM yyyy 23:59:00")

    if (showMiniForm) {
      setError((p) => ({
        ...p,
        status: true,
        box: 4,
        OkFunc: async () => await cancelShiftChangeMode(),
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }
    //put form in shift change mode and show shift change form
    await enableShiftChangeMode();
    updateMiniForm(true); // show mini form 
    updateState("loading", { status: false, message: "" });
  }

  const enableShiftChangeMode = async () => {
    updateState("mode", "o");
    updateState("eventLog", { ...formState.eventType, value: "" });
    updateState("lblEventText", "Summary");
    updateState("btnAddLogStatus", true);

    await popCmbEventTypes("sc");
    updateState("reportingStations", {
      ...formState.reportingStations,
      name: formState.homeStation.name,
      id: formState.homeStation.id,
      status: true,
    });
    updateState("homeStation", { ...formState.homeStation, status: true });
    updateState("correspondent", {
      ...formState.correspondent,
      name: "-",
      id: instantChangesState.dashCorrespondentIDx,
      status: true,
    });
    const { shiftChangeEventTypeID } =
      formState.midnightShiftChangeEventTypeIDs;
    updateState("eventType", {
      ...formState.eventType,
      id: shiftChangeEventTypeID,
      name: "Shift Change",
      status: true,
    });
    if (eventLogRef.current) {
      eventLogRef.current.focus();
    }
  };

  const cancelShiftChangeMode = async () => {
    updateMiniForm(false);
    updateState("mode", "i");
    updateState("eventLog", { ...formState.eventType, value: "" });
    updateState("lblEventText", "Event Log");
    updateState("btnAddLogStatus", false);
    updateState("changeShiftNowx", false);
    setResizeTextArea(90);
    await popCmbEventTypes("");
    popCmbEventTypes("");
    // popCmbCorrespondents();
    popLueReportingStations();

    updateState("selectedOperators", []);
    updateState("incomingEmpName2", "");
    updateState("incomingStaffNox", "");
    updateState("correspondent", {
      ...formState.correspondent,
      name: "-",
      id: instantChangesState.dashCorrespondentIDx,
      status: false,
    }); //reseting it back to the dash correspondent
    updateState("reportingStations", {
      ...formState.reportingStations,
      status: false,
    });
    updateState("homeStation", { ...formState.homeStation, status: false });
    updateState("eventType", {
      ...formState.eventType,
      name: "",
      id: 0,
      status: false,
    });
    updateState("eventTime", { ...formState.eventTime, status: false });
  };

  const getShiftLeaderStatus = async (
    empIDpk: number,
    homeStationId: number
  ): Promise<boolean> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/CheckIfshiftLeader",
      { empIDpk, homeStationId },
      "powerapp"
    );
    return !!r.data;
  };

  const checkIfUserIsInCurrentShift = async (
    staffNo: string,
    stationId: number
  ): Promise<boolean> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/CheckIfUserIsInCurrentShift",
      { staffNo, stationId },
      "powerapp"
    );
    return !!r.data;
  };

  const getCheckShiftChangeStatus = async (
    stationID: number
  ): Promise<boolean> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetCheckShiftChangeStatus",
      { stationID },
      "powerapp"
    );
    return !!r.data;
  };

  const getLastShiftChangeTime = async (stationId: number): Promise<string> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetLastShiftChangeTime",
      { stationId },
      "powerapp"
    );
    return r.data?.lbkLogDate ?? "";
  };

  const compareLastShiftChangeTime = async (homeStationId: number): Promise<string> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/CompareLastShiftChangeTime",
      { homeStationId },
      "powerapp"
    );
    return r.data.lbkLogDate;
  };

  const getCaptureBacklogStatus = async (stationId: number): Promise<any> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetCaptureBacklogStatus",
      { stationId },
      "powerapp"
    );
    return r.data;
  };

  const getShiftDuration = async (stationId: number): Promise<number> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetShiftDuration",
      { stationId },
      "powerapp"
    );
    return r.data?.sshHours;
  };

  const checkLogInBackLogMode = async (stationId: number): Promise<any> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/CheckLogInBackLogMode",
      { stationId },
      "powerapp"
    );
    return {
      inBacklogMode: r.data.lgsInBacklogMode,
      backLogStrtDatex: r.data.lgsBacklogStartDate,
      backLogEndDatex: r.data.lgsBacklogEndDate,
    };
  };

  const getOutgoingShiftMembers = async (
    stationId: number
  ): Promise<string> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetShiftMembers",
      { smbLogStationIDfk: stationId },
      "powerapp"
    );
    return r.data?.smbMembers;
  };

  const popCmbEventTypes = async (condisn: string) => {
    const { data: r } = await GetDataFunc(
      "ELogBook/PopCmbEventTypes",
      { condisn },
      "powerapp"
    );
    updateState("eventTypeList", r.data);
  };

  const [shifChangeWarning, setShiftChangeWaring] = useState<{ color: string; text: string; }>({ color: "", text: "", });

  const popCmbCorrespondents = async () => {
    const { data: r } = await GetDataFunc(
      "ELogBook/PopCmbCorrespondents",
      {},
      "powerapp"
    );
    updateState("correspondentsList", r.data);
    updateState("correspondent", {
      ...formState.correspondent,
      name: "-",
      id: instantChangesState.dashCorrespondentIDx,
    });
  };

  const popLueReportingStations = async () => {
    const { data: r } = await GetDataFunc(
      "ELogBook/PopLueReportingStations",
      {},
      "powerapp"
    );
    updateState("reportingStationsList", r.data);
  };

  const popCmbHomeStation = async () => {
    const { data: r } = await GetDataFunc(
      "ELogBook/PopCmbHomeStation",
      {},
      "powerapp"
    );
    updateState("homeStationsList", r.data);
    const v = r?.data?.find((e: any) => e?.pvaDefaultHome === true);
    if (v) {
      updateState("homeStation", {
        ...formState.homeStation,
        name: v.stnName,
        id: v.stnIDpk,
      });
      dispatch(
        setGeneralValue({
          expr: "homeStation",
          value: v.stnIDpk,
        })
      );
      instantChangesState.homeStation = { id: v.stnIDpk, name: v.stnName };
    } else {
      updateState("homeStation", {
        ...formState.homeStation,
        name: r.data[0].stnName,
        id: r.data[0].stnIDpk,
      });
      dispatch(
        setGeneralValue({
          expr: "homeStation",
          value: r.data[0].stnIDpk,
        })
      );
      instantChangesState.homeStation = {
        id: r.data[0].stnIDpk,
        name: r.data[0].stnName,
      };
    }
  };

  const PopCmbSrchUserViewStations = async () => {
    const { data: r } = await GetDataFunc("ELogBook/PopCmbSrchUserViewStations", {}, "powerapp");
    const cmbSrchViewStationsList = [];
    if (r.data.length > 0) {
      cmbSrchViewStationsList.push({ name: "All Stations" }, ...r.data);

      dispatch(setSearchParamOnChange({
        key: "cmbSrchViewStation",
        value: { cmbSrchViewStation: { ...tState.cmbSrchViewStation, initialValue: instantChangesState.homeStation.name } },
      }));
    }

    cmbSrchViewStationsList.push(
      { name: "First item for each station" },
      { name: "First two items for each station" },
      { name: "First three items for each station" },
      { name: "Last items for each station" },
      { name: "Last two items for each station" },
      { name: "Last three items for each station" }
    );

    if (instantChangesState.slbOperatorActivStatus === true) {
      dispatch(setSearchParamOnChange({
        key: "cmbSrchViewStation",
        value: { ...tState.cmbSrchViewStation, initialValue: instantChangesState.homeStation.name },
      }));
    } else {
      dispatch(setSearchParamOnChange({
        key: "cmbSrchViewStation",
        value: { ...tState.cmbSrchViewStation, initialValue: cmbSrchViewStationsList[0]?.name }
      }));
    }
    dispatch(setGeneralValue({ expr: "cmbSrchViewStationsList", value: cmbSrchViewStationsList }));
  };

  const getMidnightShiftChangeEventTypeIDs = async () => {
    const { data: r } = await GetDataFunc(
      "ELogBook/MidnightShiftChangeEventTypeID",
      {},
      "powerapp"
    );
    updateState("midnightShiftChangeEventTypeIDs", r.data);
  };

  const getOperatorActiveStatus = async (): Promise<void> => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetOperatorActiveStatus",
      {},
      "powerapp"
    );
    instantChangesState.slbOperatorActivStatus = r?.data?.pvaLog;
  };

  const getOperatorLogStatus = async (): Promise<boolean> => {
    const { data: r } = await GetDataFunc(`ELogBook/GetOperatorLogStatus?reportinStationId=${formState.reportingStations.id}`, {}, "powerapp");
    return r?.data?.pvaLog;
  };

  const getDashCorrespondentID = async () => {
    const { data: r } = await GetDataFunc(
      "ELogBook/GetDashCorrespondentID",
      {},
      "powerapp"
    );
    instantChangesState.dashCorrespondentIDx = r.data.objId;
  };

  const getStationPreviewPrivilege = async (stationId: number) => {
    const { data: r } = await GetDataFunc("ELogBook/GetStationPreviewPrivilege", { stationId }, "powerapp");
    return r.data.pvaPreviewLogs;
  };

  const shiftChangeWarningText = async (homeStationId: number) => {
    let lastShiftChangeTime: string = "";
    let shiftDuration: number = 0;
    let minutes: any;
    let nextShiftTime: any;
    await GetDataFunc(`ELogBook/GetLastShiftChangeTime?stationId=${homeStationId}`, {}, "powerapp")
      .then((res) => { lastShiftChangeTime = res?.data?.data?.lbkLogDate ?? ""; });

    //--------------------------------------------------------------------------
    if (lastShiftChangeTime !== "") {
      await GetDataFunc(`ELogBook/GetShiftDuration?stationId=${homeStationId}`, {}, "powerapp")
        .then((res) => { shiftDuration = res?.data?.data?.sshHours ?? 0; });

      nextShiftTime = dayjs(lastShiftChangeTime).add(shiftDuration, "hours");
      minutes = dayjs(nextShiftTime).diff(currentDate, "minute");

      setFormState((prev: any) => ({ ...prev, changeShiftNowx: false }));
      if (minutes > 240) {
        setShiftChangeWaring((prev) => ({ ...prev, text: "" }));
      } else if (minutes >= 0 && minutes <= 59) {
        setShiftChangeWaring((prev) => ({
          ...prev,
          color: "text-red-400",
          text: `You have ${minutes} minutes to close your logs and change shift`,
        }));
      } else if (minutes >= 60 && minutes <= 119) {
        setShiftChangeWaring((prev) => ({
          ...prev,
          color: "text-brown-300",
          text: `You have 1 hour, ${minutes - 60
            } minute(s) to close your logs and change shift`,
        }));
      } else if (minutes >= 120 && minutes <= 179) {
        setShiftChangeWaring((prev) => ({
          ...prev,
          color: "text-blue-300",
          text: `You have 2 hour, ${minutes - 120
            } minute(s) to close your logs and change shift`,
        }));
      } else if (minutes >= 180 && minutes <= 239) {
        setShiftChangeWaring((prev) => ({
          ...prev,
          color: "text-magenta-300",
          text: `You have 3 hour, ${minutes - 180
            } minute(s) to close your logs and change shift`,
        }));
      } else if (minutes < 0) {
        setShiftChangeWaring((prev) => ({
          ...prev,
          color: "text-red-300",
          text: `Change Shift to continue logging`,
        }));
        setFormState((prev: any) => ({ ...prev, changeShiftNowx: true }));
      }
    } else {
      setShiftChangeWaring((prev) => ({
        ...prev,
        color: "text-red-400",
        text: `No Shift Change detected for the selected Home Station. Contact your Sys Admin for assistance`,
      }));
    }
  };

  const msgBox = [
    {
      title: "Select Home Station",
      message: "Please select your Home Station to continue logging",
    },
    {
      title: "Insufficient Privilege",
      message: `You do not have the priveleges to Change Shift \nPlease contact your Shift Leader or Systems Administrator for assistance`,
    },
    {
      title: "Cancel Midnight Conditions Capture?",
      message:
        "Do you want to cancel the Midnight Conditions Capture in progress?",
    },
    {
      title: "Invalid Action",
      message: `You cannot initiate Shift Change because you are not part of the current shift for ${formState.homeStation.name ?? ""
        }.\n\nTry again later or contact your Systems Administrator for assistance`,
    },
    {
      title: "Cancel Shift Change?",
      message: `Do you want to cancel the Shift Change in progress?`,
    },
    {
      title: "Invalid Action",
      message: `You are not part of the current shift for  ${formState.homeStation.name ?? ""
        }. You cannot log`,
    },
    {
      title: "Change Shift",
      message: `Your shift has timed out. You cannot continue logging\n\nPlease invoke the shift change process or contact your systems administrator for assistance`,
    },
    {
      title: "Invalid Time",
      message: `Event time cannot be greater than the current time`,
    },
    {
      title: "No Shift Change",
      message: `No shift change detected for the selected home station.\n\nPlease contact your systems administrator for assistance.`,
    },
    {
      title: "Invalid Event Date",
      message: `Your Event Time is Invalid. It is earlier than your last shift change.\nPlease check the Event Date and try again or contact your systems administrator for assistance`,
    },
    {
      title: "Invalid Shift Hours",
      message: `Please contact your systems administrator to setup up Shift Hours for your substation`,
    },
    {
      title: "Invalid Event Date",
      message: `The date you entered is earlier than your last shift change.\nPlease check the Event Date and try again or contact your Systems Administrator for assistance`,
    },
  ];

  const handleLogOperations = async () => {
    updateState("loading", { status: true, message: "Preparing to log event..." });
    const er = conditions.find(({ check }) => check);
    if (er) {
      updateState("loading", { status: false, message: "" });
      setMessageModal({
        ...messageModal,
        icon: "warning",
        title: er.title,
        message: er.message,
        hideCancel: true,
        openModal: true,
        okHandler: () => {
          setMessageModal((p) => ({ ...p, openModal: false }));
        },
      });
      return;
    } else {
      if ((await checkIfUserIsInCurrentShift(user.empStaffno, formState.homeStation.id)) === false) {
        updateState("loading", { status: false, message: "" });
        setError((p) => ({ ...p, status: true, box: 5, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
        return;
      }
      if (
        formState.changeShiftNowx &&
        formState.eventType.name.toLowerCase() !== "shift change"
      ) {
        updateState("loading", { status: false, message: "" });
        setError((p) => ({ ...p, status: true, box: 6, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
        return;
      }
      if (dayjs(formState.eventTime.time).isAfter(dayjs())) {
        // check if event time is in the future
        updateState("loading", { status: false, message: "" });
        setError((p) => ({ ...p, status: true, box: 7, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
        return;
      }

      //Starting the main validation process
      const slbLastShiftChangeTimex = await getLastShiftChangeTime(
        formState.homeStation.id
      );
      if (slbLastShiftChangeTimex === "") {
        updateState("loading", { status: false, message: "" });
        setError((p) => ({ ...p, status: true, box: 8, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
        return;
      }

      const { inBacklogMode } = await checkLogInBackLogMode(
        formState.homeStation.id
      ); //check if the station is in backlog mode

      //if not in backlog mode n event time is beyond d last shift change date flag as error
      if (
        inBacklogMode === false &&
        dayjs(formState.eventTime.time) <= dayjs(slbLastShiftChangeTimex)
      ) {
        updateState("loading", { status: false, message: "" });
        setError((p) => ({ ...p, status: true, box: 9, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
        return;
      }

      const shiftDuration = await getShiftDuration(formState.homeStation.id);

      if (await getCheckShiftChangeStatus(formState.homeStation.id)) {
        if (shiftDuration === 0) {
          updateState("loading", { status: false, message: "" });
          setError((p) => ({ ...p, status: true, box: 10, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
          return;
        }

        if (
          dayjs(slbLastShiftChangeTimex).diff(dayjs(), "minutes") >=
          shiftDuration * 60 + 60 &&
          formState.eventType.name.toLowerCase() !== "shift change" &&
          !inBacklogMode
        ) {
          updateState("loading", { status: false, message: "" });
          setMessageModal({
            ...messageModal,
            title: "Invalid Shift",
            message: `Your Shift cannot run for more than ${shiftDuration} hours.\nThe last shift was changed on ${dayjs(
              slbLastShiftChangeTimex
            ).format("ddd,DD MMM YYYY HH:mm:ss")} @ ${dayjs(
              slbLastShiftChangeTimex
            ).format(
              "HH:mm A"
            )}\n\nPlease alert your shift leader to change shift to enable you to continue logging`,
            openModal: true,
            okHandler: () => {
              setMessageModal((p) => ({ ...p, openModal: false }));
            },
          });
          return;
        }
        if (dayjs(slbLastShiftChangeTimex).isAfter(dayjs()) && !inBacklogMode) {
          updateState("loading", { status: false, message: "" });
          setMessageModal({
            ...messageModal,
            title: "Invalid Time",
            message: `Your Event Time is less than the last shift change time of\n ${dayjs(
              slbLastShiftChangeTimex
            ).format(
              "ddd,DD MMM YYYY HH:mm:ss"
            )}. Please check your time and try again`,
            openModal: true,
            okHandler: () => {
              setMessageModal((p) => ({ ...p, openModal: false }));
            },
          });
          return;
        }
      }

      // when shift change is clicked and shift change is in progress
      if (showMiniForm) {
        // getting incoming shift members
        if ((await getShiftLeaderStatus(empIDpk, formState.homeStation.id)) === false) {
          updateState("loading", { status: false, message: "" });
          setError((p) => ({ ...p, status: true, box: 1, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
          return;
        }

        let onDuty = `${formState.incomingEmpName2} (${formState.incomingStaffNox})`;

        formState.selectedOperators?.forEach((e) =>
          onDuty = `${onDuty}, ${e}`
        );

        const outgoingMembs = await getOutgoingShiftMembers(
          formState.homeStation.id
        );

        updateState("loading", { status: false, message: "" });//stop loading
        setMessageModal({
          ...messageModal,
          icon: "question",
          hideCancel: false,
          okText: "Yes",
          cancelText: "No, Cancel",
          title: "Change Shift?",
          message: `Are you sure you want to change shift?`,
          openModal: true,
          okHandler: async () => {
            setMessageModal((p) => ({ ...p, openModal: false }));
            await handleSaveShiftChangeLog(onDuty, outgoingMembs);
          },
          cancelHandler: () => setMessageModal((p) => ({ ...p, openModal: false })),
        });
        return;
      } else if (formState.mode === "i") {
        const slbLastShiftChangeTimex = await getLastShiftChangeTime(
          formState.homeStation.id
        );

        if (dayjs(formState.eventTime.time) <= dayjs(slbLastShiftChangeTimex)) {
          updateState("loading", { status: false, message: "" });
          setError((p) => ({ ...p, status: true, box: 11, OkFunc: () => setError((p) => ({ ...p, status: false })) }));
          return;
        }

        updateState("loading", { status: false, message: "" }); //stop loading
        if (!!(await getOperatorLogStatus()) === false) {
          setMessageModal({
            ...messageModal,
            icon: "warning",
            hideCancel: true,
            okText: "Okay",
            title: "Insufficient Privilege",
            message:
              "You Do Not have the priveleges to Log.\n Please contact your Shift Leader Or Systems Administrator for assistance.",
            openModal: true,
            okHandler: () => {
              updateState("loading", { status: false, message: "" });
              setMessageModal((p) => ({ ...p, openModal: false }));
            },
          });
          return;
        }
        setMessageModal({
          ...messageModal,
          icon: "question",
          hideCancel: false,
          okText: "Yes",
          cancelText: "No, Cancel",
          title: "Log?",
          message: `Are you sure you want to log this event?`,
          openModal: true,
          okHandler: async () => {
            setMessageModal((p) => ({ ...p, openModal: false }));
            await handleSaveLog();
          },
          cancelHandler: () => {
            setMessageModal((p) => ({ ...p, openModal: false }));
          },
        });
      } else if (formState.mode === "m") {
        setMessageModal({
          ...messageModal,
          icon: "question",
          hideCancel: false,
          okText: "Yes",
          cancelText: "No, Cancel",
          title: "Update Log?",
          message: `Are you sure you want to update this event?`,
          openModal: true,
          okHandler: async () => {
            await handleUpdateLog();
          },
        });
      }
    }
  };

  const CancelMidnightConditions = async () => {
    await popCmbEventTypes("");
    updateState("eventType", {
      ...formState.eventType,
      id: 0,
      name: "",
      status: false,
    });
    // setResizeTextArea(200);
    setResizeTextArea(90);
    updateState("eventLog", { ...formState.eventLog, value: "" });
    updateState("eventTime", { ...formState.eventTime, status: false });
    updateState("reportingStations", {
      ...formState.reportingStations,
      id: 0,
      name: "",
      status: false,
    });
    updateState("correspondent", {
      ...formState.correspondent,
      id: instantChangesState.dashCorrespondentIDx,
      name: "-",
      status: false,
    });
    updateState("homeStation", { ...formState.homeStation, status: false });
  };

  const checkForMidnightCondition = async () => {
    updateState("loading", { status: true, message: "Loading midnight condition template..." });
    setMidnightConditionsClick && setMidnightConditionsClick();
    let tmpText = await GetDataFunc(
      `ELogBook/GetTmpText?tmpStationIDfk=${formState.homeStation.id}`,
      {},
      "powerapp"
    );
    let getShiftldrStatus = await GetDataFunc(
      `ELogBook/GetShiftStatus?stationID=${formState.homeStation.id}`,
      {},
      "powerapp"
    );
    let dtbxx = tmpText?.data?.data[0]?.tmpText ?? "";
    let shiftLeaderStatus = getShiftldrStatus?.data?.data?.data?.pvaShiftLeader;
    let isUserIncurrentShift = await checkIfUserIsInCurrentShift(
      user.empStaffno,
      formState.homeStation.id
    );
    let text: string;
    if (shiftLeaderStatus === true) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Insufficient Privilege",
        message:
          "You do not have the privelages to Capture Midnight Conditions.\n\n Please contact your shift leader or systems administrator for assistance.",
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      if (eventTimeRef.current) {
        eventTimeRef.current?.focus();
      }
      updateState("loading", { status: false, message: "" });
      return;
    }
    if (isUserIncurrentShift === false) {
      updateState("loading", { status: false, message: "" });
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: `You cannot log Midnight Conditions because you are not part of the current shift for ${formState.homeStation.name}.\nTry again later or contact your Systems Administrator for assistance.`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }
    if (
      String(formState.eventType.name).toLowerCase() === "midnight conditions"
    ) {
      updateState("loading", { status: false, message: "" });
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Cancel Midnight Conditions Capture?",
        message: `Do you want to cancel the Midnight Conditions Capture in progress?`,
        okText: "Yes, Cancel",
        cancelText: "No",
        hideCancel: false,
        okHandler: async () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          await CancelMidnightConditions();
        },
        cancelHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }
    if (String(formState.eventType.name).toLowerCase() !== "midnight conditions") {
      if (String(formState.eventType.name).toLowerCase() === "shift change") {
        updateState("loading", { status: false, message: "" });
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "question",
          title: "Cancel Shift Change?",
          message: `Do you want to cancel the Shift Change currently in progress?`,
          okText: "Yes, Cancel",
          cancelText: "No",
          hideCancel: false,
          okHandler: async () => {
            await cancelShiftChangeMode();
            await continueMidnightConditions(text, dtbxx);
            updateState("loading", { status: false, message: "" });
          },
          cancelHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
          openModal: true,
        }));
        updateState("loading", { status: false, message: "" });
        return;
      }
      await continueMidnightConditions(text, dtbxx);
    } else {
      popCmbEventTypes("mc");
      updateState("eventType", {
        ...formState.eventType,
        id: formState.midnightShiftChangeEventTypeIDs.midnightConditionEventTypeID,
        name: "Midnight Conditions",
        status: false,
      });
      updateState("eventTime", {
        ...formState.eventTime,
        time: dayjs().format("ddd,DD MMM YYYY 23:59:00"),
      });
      updateState("reportingStations", {
        ...formState.reportingStations,
        name: formState.homeStation.name,
        status: false,
      });
      updateState("correspondent", {
        ...formState.correspondent,
        id: instantChangesState.dashCorrespondentIDx,
        name: "-",
        status: false,
      });

      updateState("homeStation", { ...formState.homeStation, status: false });
      setShiftChangeWaring((prev: any) => ({
        ...prev,
        text: "",
      }));
      updateState("loading", { status: false, message: "" });
    }
  };

  const continueMidnightConditions = async (text: string, dtbxx: any) => {
    updateState("eventLog", {
      ...formState.eventLog,
      value: "",
    });

    if (dtbxx?.length > 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "question",
        title: "Load Template?",
        message: `Do you want to load and edit the template for Midnight Conditions?`,
        okText: "Yes, load",
        cancelText: "No",
        hideCancel: false,
        okHandler: async () => {
          (text = (dtbxx ?? "")?.replace("StationCodex", "stnCode"));
          (text = text
            .replace("Dayx", `${dayjs().format("dddd")}`)
            .toUpperCase());
          text = text
            .replace("Datex", `${dayjs().format("dd MMM yyyy")}`)
            .toUpperCase();

          updateState("eventLog", {
            ...formState.eventLog,
            value: text ?? "",
          });
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          await continueMidnightConditionsAfterYes();
        },
        cancelHandler: () => {
          updateState("eventLog", {
            ...formState.eventLog,
            value: "",
          });
          updateState("loading", { status: false, message: "" });
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      return;
    } else {
      updateState("eventLog", {
        ...formState.eventLog,
        value: "",
      });
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "No Template",
        message: `There is no active Midnight Conditions template saved for your Home Station ${formState.homeStation.name}.\n\n Please contact your Systems Administrator for assistance`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }

  }

  const continueMidnightConditionsAfterYes = async () => {
    await popCmbEventTypes("mc");
    await popCmbCorrespondents();
    await popLueReportingStations();
    updateState("reportingStations", {
      ...formState.reportingStations,
      name: formState.homeStation.name,
      id: formState.homeStation.id,
      status: true,
    });
    updateState("correspondent", {
      ...formState.correspondent,
      id: instantChangesState.dashCorrespondentIDx,
      name: "-",
      status: true,
    });
    updateState("homeStation", { ...formState.homeStation, status: true });
    updateState("eventType", {
      ...formState.eventType,
      id: formState.midnightShiftChangeEventTypeIDs.midnightConditionEventTypeID,
      name: "Midnight Conditions",
      status: true,
    });
    updateState("eventTime", {
      ...formState.eventTime,
      time: dayjs().format("ddd,DD MMM YYYY 23:59:00"),
    });
    setResizeTextArea(400);
    eventLogRef.current && eventLogRef.current.focus();
    updateState("loading", { status: false, message: "" });
  }

  const btnUpdateShiftMembers_Click = async () => {
    setIsUpdateCurrentShiftMembersClicked &&
      setIsUpdateCurrentShiftMembersClicked(false);
    if (formState.homeStation.id === 0) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: `Please select the home station and try again.`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      return;
    }
    if (await checkIfUserIsInCurrentShift(user.empStaffno, formState.homeStation.id) === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: `You cannot update the current shift members because you are not part of the current shift for ${formState.homeStation.name}.\nTry again later or contact your systems administrator for assistance`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      return;
    }
    else {
      setShowShiftMembers && setShowShiftMembers(true);
    }
  };

  const btnUpdateMidnightConditionsTemplate_Click = async () => {
    setIsUpdateTemplateBtnClicked && setIsUpdateTemplateBtnClicked(false);
    if (
      (await checkIfUserIsInCurrentShift(
        user.empStaffno,
        formState.homeStation.id
      )) === false
    ) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access Denied",
        message: `You Do Not have the priveleges to update Condition templates.\n Please contact your Shift Leader Or Systems Administrator for assistance.`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      return;
    } else {
      setShowTemplateModal && setShowTemplateModal(true);
    }
  };

  const btnInitiateRemoteShiftChange_Click = async () => {
    setIsRemoteShiftBtnClicked && setIsRemoteShiftBtnClicked(false);
    let rscIdx = await GetDataFunc(
      `ELogBook/CheckIfUserIsInHomeStation?homeStationId=${formState.homeStation.id}`,
      {},
      "powerapp"
    );
    let initiateRemoteScx = await GetDataFunc(
      `ELogBook/GetRemoteShiftChangePrivilege`,
      {},
      "powerapp"
    );
    if (rscIdx?.data?.data?.objId > 0) {
      if ((await checkIfUserIsInCurrentShift(user.empStaffno, formState.homeStation.id)) === false) {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: "Invalid Action",
          message: `You cannot initiate Remote Shift Change because you are not part of the current shift for ${formState.homeStation.name}\n Try again later or contact your Systems Administrator for assistance`,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
          openModal: true,
        }));
        return;
      }
    }
    dispatch(setSearchParamOnChange({ key: "homeStationID", value: instantChangesState.homeStation.id }));

    if (initiateRemoteScx?.data?.data?.pvaInitiateRemoteShiftChange === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access Denied",
        message: `You do not have the privilege to Initiate Remote Shift Change for any station.\n Please try again later or contact your Systems Administrator for assistance`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      return;
    } else {
      setShowRemoteShift && setShowRemoteShift(true);
    }
  };

  const btnTakeOverShift_Click = async () => {
    updateState("loading", { status: true, message: "Loading..." });
    setIsShiftTakeOverClicked && setIsShiftTakeOverClicked(false);
    let takeOverShift = await GetDataFunc(
      `ELogBook/TakeOverShift?stationID=${formState.homeStation.id}`,
      {},
      "powerapp"
    );
    if (!!takeOverShift?.data?.data?.pvaTakeOverShift === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        openModal: true,
        icon: "warning",
        title: "Access Denied",
        hideCancel: true,
        okText: "Okay",
        message: `You do not have the privilege to Take Over the current shift for ${formState.homeStation.name} substation.\n Please try again later or contact your systems administrator for assistance`,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }
    updateState("mode", "o");
    setShowShiftTakeover && setShowShiftTakeover(true);
    updateState("loading", { status: false, message: "" });
  };

  const onPreviewOptionSelected = async (option: string) => {
    const msg = option === "previewSelected" ? "Marking as Previewed" : "Marking as Not Previewed";
    updateState("loading", { status: true, message: `${msg}...` });
    if ((await getStationPreviewPrivilege(selectedData?.lbkHomeStationIDfk)) === true) {
      if (option === "previewSelected") {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "question",
          title: "Mark as Previewed?",
          message: "Are you sure you want to mark the selected log(s) as previewed?",
          okText: "Yes",
          hideCancel: false,
          cancelText: "No, Cancel",
          openModal: true,
          okHandler: async () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
            await updatePreviewLog(option);
          },
          cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })); },
        }));
      } else if (option === "cancelPreviewForSelected") {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "question",
          title: "Mark as Not Previewed?",
          message: "Are you sure you want to cancel the preview of the selectedd log(s)?",
          okText: "Yes",
          hideCancel: false,
          cancelText: "No, Cancel",
          openModal: true,
          okHandler: async () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
            await updatePreviewLog(option);
          },
          cancelHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })); },
        }));
      }
      updateState("loading", { ...formState.loading, status: false, });
    } else {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Access Denied",
        message: `You do not have the privileges to Preview logs for the selected station`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { ...formState.loading, status: false, });
    }
  };

  const updatePreviewLog = async (option: string) => { //Updating for just the currently selected record for now
    const res = await updateData("ELogBook/PreviewLog", { lbkId: selectedData?.lbkIDpk, optionSelected: option }, `logID=${selectedData?.lbkIDpk}`);
    if (res) {
      if (option === "previewSelected") {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "success",
          title: option === "previewSelected" ? "Preview Successful" : "Preview Cancellation Successful",
          message: option === "previewSelected" ? "Logs previewed successfully" : "Logs previews cancelled successfully",
          okText: "Okay",
          hideCancel: true,
          openModal: true,
          okHandler: () => { setMessageModal((prev: any) => ({ ...prev, openModal: false })); },
        }));
        dispatch(setSearchParamOnChange({ key: "refresh", value: { refresh: !tState.refresh, } }));
      }
    }
    updateState("loading", { ...formState.loading, status: false, });
  };

  const onMainGridDoubleClick = async (selectedData: any) => {
    updateState("loading", { status: true, message: "Loading selected log..." });
    const shiftLeaderStatus = await getShiftLeaderStatus(empIDpk, selectedData?.lbkHomeStationIDfk);
    if (shiftLeaderStatus === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Insufficient Privilege",
        message: `You do not have the privilege to view the selected log.\n Please contact your shift leader or systems administrator for assistance`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      eventTimeRef.current && eventTimeRef.current?.focus();
      updateState("loading", { status: false, message: "" });
      return;
    }

    const lastShiftChangeTime = await getLastShiftChangeTime(selectedData?.lbkHomeStationIDfk);
    if (lastShiftChangeTime === "") {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "No Shift Change Yet",
        message: `No shift change has been done for your station yet. Please contact your Systems Administrator for assistance.`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }

    if (dayjs(selectedData.lbkLogDate).format("YYYY-MM-DD HH:mm:ss") <= dayjs(lastShiftChangeTime).format("YYYY-MM-DD HH:mm:ss")) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Log Archived",
        message: `The Log you want to Edit is not in your Shift.\nThe log has been archived and cannot be edited.\n\nPlease contact your Systems Administrator for assistance.`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }

    if ((await checkIfUserIsInCurrentShift(user.empStaffno, selectedData?.lbkHomeStationIDfk)) === false) {
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: `You cannot edit this log because you are not part of the current shift for the log Station.\n\nTry again later or contact your Systems Administrator for assistance`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
      return;
    }

    if (dayjs(selectedData.lbkLogDate).add(12, 'hours').format("YYYY-MM-DD HH:mm:ss") >= dayjs(lastShiftChangeTime).format("YYYY-MM-DD HH:mm:ss")) {
      if (selectedData?.lbkLoggedByEmpIDfk === empIDpk || shiftLeaderStatus === true) {
        if (formState.mode === "m") {
          setMessageModal((prev: any) => ({
            ...prev,
            icon: "question",
            title: "Cancel edit?",
            message: `Do you want to cancel editing this record?`,
            okText: "Yes",
            cancelText: "No, Cancel",
            hideCancel: false,
            openModal: true,
            okHandler: async () => {
              setMessageModal((prev: any) => ({ ...prev, openModal: false }))
              await handleCancleEditMode();
            },
            cancelHandler: () => setMessageModal((prev) => ({ ...prev, openModal: false }))
          })); //Cancel edit mode
          updateState("loading", { status: false, message: "" });
          return;
        } else {
          setMessageModal((prev: any) => ({
            ...prev,
            icon: "question",
            title: "Edit?",
            message: `Do you want to edit the selected event?`,
            okText: "Yes",
            cancelText: "No, Cancel",
            hideCancel: false,
            openModal: true,
            okHandler: async () => {
              setMessageModal((prev: any) => ({ ...prev, openModal: false }))
              await handleInitiateEditMode(selectedData);
            },
            cancelHandler: () => setMessageModal((prev) => ({ ...prev, openModal: false }))
          }));  //Initiate edit mode
          updateState("loading", { status: false, message: "" });
          return;
        }
      } else {
        setMessageModal((prev: any) => ({
          ...prev,
          icon: "warning",
          title: "Invalid Action",
          message: `You did not dispatch this log and so cannot edit it`,
          okText: "Okay",
          hideCancel: true,
          okHandler: () => {
            setMessageModal((prev: any) => ({ ...prev, openModal: false }));
          },
          openModal: true,
        }));
        updateState("loading", { status: false, message: "" });
      }
    } else {
      updateState("eventLog", { ...formState.eventLog, value: "" });
      updateState("mode", "i");
      setMessageModal((prev: any) => ({
        ...prev,
        icon: "warning",
        title: "Invalid Action",
        message: `This message has been commited and archived. You cannot edit it`,
        okText: "Okay",
        hideCancel: true,
        okHandler: () => {
          setMessageModal((prev: any) => ({ ...prev, openModal: false }));
        },
        openModal: true,
      }));
      updateState("loading", { status: false, message: "" });
    }
  }

  const handleCancleEditMode = async () => {
    updateState("loading", { status: true, message: "Cancelling edit mode..." });
    updateState("mode", "i");
    updateState("eventType", { ...formState.eventType, id: formState.midnightShiftChangeEventTypeIDs.informationEventTypeID, name: "information" });
    updateState("eventLog", { ...formState.eventLog, value: "" });
    updateState("lblState", "New Log...");
    updateState("correspondent", { ...formState.correspondent, name: "", id: 0 });
    updateState("reportingStations", { ...formState.reportingStations, name: "", id: 0 });
    await popCmbEventTypes("");
    updateState("eventTime", { ...formState.eventTime, time: dayjs().format("dd MMM YYYY 23:59:00") });
    updateState("btnSaveText", "Save Log");
    updateState("loading", { status: false, message: "" });
  }

  const handleInitiateEditMode = async (selectedData: any) => {
    updateState("loading", { status: true, message: "Initiating edit mode..." });
    await cancelShiftChangeMode();
    await CancelMidnightConditions();
    updateState("mode", "m");
    updateState("btnSaveText", "Update");
    updateState("eventTime", { ...formState.eventTime, time: dayjs(selectedData?.lbkLogDate).format("ddd, dd MMM YYYY HH:mm:ss") });
    updateState("correspondent", { ...formState.correspondent, name: selectedData?.correspondentName2, id: selectedData?.lbkCorrespondentIDfk });
    updateState("reportingStations", { ...formState.reportingStations, name: selectedData?.reportingStationName, id: selectedData?.lbkReportingStationIDfk });
    updateState("homeStation", { ...formState.homeStation, name: selectedData?.homeStationName, id: selectedData?.lbkHomeStationIDfk });
    updateState("lblState", "Editing log...");
    updateState("eventLog", { ...formState.eventLog, value: selectedData?.lbkEvent });

    if (selectedData?.evtShtName?.toLowerCase() === "mc") {
      await popCmbEventTypes("mc");
      updateState("eventType", { ...formState.eventType, name: "Midnight Conditions", id: formState.midnightShiftChangeEventTypeIDs.midnightConditionEventTypeID });
    } else if (selectedData?.evtShtName?.toLowerCase() === "sc") {
      await popCmbEventTypes("sc");
      updateState("eventType", { ...formState.eventType, name: "Shift Change", id: formState.midnightShiftChangeEventTypeIDs.shiftChangeEventTypeID });
    } else {
      await popCmbEventTypes("");
      updateState("eventType", { ...formState.eventType, name: selectedData?.evtName, id: selectedData?.lbkEventTypeIDfk });
    }
    updateState("btnAddLogStatus", false); //Enable the save button 
    updateState("loading", { status: false, message: "" });
  }

  const initForm = async () => {
    await getOperatorActiveStatus();
    await getMidnightShiftChangeEventTypeIDs();

    if (instantChangesState.slbOperatorActivStatus) {
      await getDashCorrespondentID();
      //Get the user's home station
      await popCmbEventTypes("");
      await popCmbCorrespondents();
      await popCmbHomeStation();
      await popLueReportingStations();
      await PopCmbSrchUserViewStations();

      await shiftChangeWarningText(instantChangesState.homeStation.id);
      if (eventTimeRef.current) {
        eventTimeRef.current?.focus();
      }
    } else {
      //TODO: hide some controlls in the toolbar because the user is not an operator; check vb for that
      setSwitchForm(false);
      setSwitchFormDisabled(true);
    }
    updateState("loading", { ...formState.loading, status: false, message: "" });
  };

  useEffect(() => {
    if (isUpdateCurrentShiftMembersClicked === true) {
      btnUpdateShiftMembers_Click();
    }
  }, [isUpdateCurrentShiftMembersClicked]);

  useEffect(() => {
    if (isShiftTakeOverClicked === true) {
      btnTakeOverShift_Click();
    }
  }, [isShiftTakeOverClicked]);

  useEffect(() => {
    if (isRemoteShiftBtnClicked === true) {
      btnInitiateRemoteShiftChange_Click();
    }
  }, [isRemoteShiftBtnClicked]);

  useEffect(() => {
    if (isUpdateTemplateBtnClicked === true) {
      btnUpdateMidnightConditionsTemplate_Click();
    }
  }, [isUpdateTemplateBtnClicked]);

  useEffect(() => {
    shiftchangeClick && handleShiftChange();
  }, [shiftchangeClick]);

  useEffect(() => {
    initForm();
  }, []);

  useEffect(() => {
    //Getting warning text for shift change any time the home station changes
    if (formState.homeStation.id !== 0) {
      shiftChangeWarningText(formState.homeStation.id);
    }
  }, [formState.homeStation]);

  useEffect(() => {
    if (midNightCondition) {
      checkForMidnightCondition();
    }
  }, [midNightCondition]);

  useNonInitialEffect(() => {
    if (selectedData?.lbkIDpk) { //checking if there is an already selected records before trying to delete the record 
      DeleteOrRestoreLog();
    }
  }, [onDeleteLogClicked]);

  useEffect(() => {
    if (isEventTypeBtnClicked) {
      setIsEventTypeBtnClicked && setIsEventTypeBtnClicked(false);
      setShowEventTypes && setShowEventTypes(true);
      popCmbEventTypes("");
    }
  }, [isEventTypeBtnClicked]);

  useEffect(() => {
    const getPreviewPriv = async () => {
      const priv = await getStationPreviewPrivilege(selectedData?.lbkHomeStationIDfk);
      updateState("showPreviewDialog", { ...formState.showPreviewDialog, showButton: priv });
    }

    if (selectedData?.lbkIDpk) {
      getPreviewPriv();
    }
  }, [selectedData]);

  useEffect(() => {
    if (onRowDoubleClick) {
      onMainGridDoubleClick(onRowDoubleClick);
    }
  }, [onRowDoubleClick]);

  return (
    <section>
      <ModalTemplate
        icon={messageModal.icon}
        title={messageModal.title}
        message={messageModal.message}
        open={messageModal.openModal}
        okText={messageModal.okText}
        cancelText={messageModal.cancelText}
        disableCancel={messageModal.hideCancel}
        okHandler={messageModal.okHandler}
        cancelHandler={messageModal.cancelHandler}
      />
      <ModalTemplate
        disableCancel={error.box === 4 ? false : true}
        title={msgBox[error.box].title}
        okText={error.box === 4 ? "Yes" : "Ok"}
        message={msgBox[error.box].message}
        open={error.status}
        okHandler={() => {
          setError((p) => ({ ...p, status: false }));
          error.OkFunc();
        }}
        cancelHandler={() => {
          setError((p) => ({ ...p, status: false }));
        }}
      />
      <ModalTemplate
        disableCancel
        width={"40%"}
        customComponent={<SlbLogPreviewDialog onSelectedId={async (v: string) => {
          await onPreviewOptionSelected(v);
          updateState("showPreviewDialog", { ...formState.showPreviewDialog, status: false });
        }} />}
        title={"Select an option"}
        message={""}
        cancelHandler={() => { updateState("showPreviewDialog", { ...formState.showPreviewDialog, status: false }) }}
        open={formState.showPreviewDialog.status}
        okHandler={() => { updateState("showPreviewDialog", { ...formState.showPreviewDialog, status: false }) }}
      />

      {formState.loading.status && <CustomLoader text={`${formState.loading.message}...`} />}
      <header className="bg-neutral-200 p-1 w-full h-auto font-medium">
        eLog Book
      </header>
      <section className="w-full h-auto border-2 px-2 ">
        <Form className="flex space-x-2 w-full px-2" layout="horizontal">
          <div className="w-full ml-1">
            <DateTemp
              label="Event Time"
              height={24}
              toolTip="{`Event Date and Time. This is the date and time the event occured. Note that it is not the date and time you are saving the event to the database.`}"
              ref={eventTimeRef}
              value={dayjs(formState.eventTime.time).format()}
              disabled={formState.eventTime.status}
              onValueChanged={(date: any) => {
                updateState("eventTime", {
                  ...formState.eventTime,
                  time: dayjs(date).format("YYYY-MM-DD HH:mm:ss"),
                });
              }}
            />
          </div>
          <div className="w-full">
            <DropDownTemp
              label="Event Type"
              options={formState.eventTypeList}
              minWidth={120}
              showSearch
              idExpr={"evtidpk"}
              displayExpr={"evtName"}
              value={formState.eventType.name}
              toolTip={`Event Type/This a way of classifying the event into groups of activities.`}
              disabled={formState.eventType.status}
              onSelect={(e: any) => {
                updateState("eventType", {
                  ...formState.eventType,
                  id: e.evtidpk ?? 0,
                  name: e.evtName ?? "",
                });
              }}
              onRefresh={async () => await popCmbEventTypes("")}
            />
          </div>
          <div className="w-full">
            <GridDropdownTemp
              toolTip={`Reporting Station/Ths is the station the log is being written about. It is the station where the event occured.`}
              keyExpr="stnIDpk"
              displayExpr="stnName"
              acceptCustomValue
              label={"Reporting Station"}
              dataSource={formState.reportingStationsList}
              columns={reportingStation_column}
              defaultValue={formState.reportingStations.name}
              disabled={formState.reportingStations.status}
              onSelect={(e) => {
                updateState("reportingStations", {
                  ...formState.reportingStations,
                  id: e.stnIDpk ?? 0,
                  name: e.stnName ?? "",
                });
              }}
              onRefresh={async () => {
                !formState.reportingStations.status &&
                  updateState("reportingStations", {
                    ...formState.reportingStations,
                    name: "",
                    id: 0,
                  });
                await popLueReportingStations();
              }} />

          </div>


          <div className="w-full">
            <GridDropdownTemp
              toolTip={`Correspondent/The person who relayed the information to you. You can select dash (-) if it is your own information.`}
              keyExpr="crdIDpk"
              displayExpr="empName1"
              columns={correspondent_column}
              acceptCustomValue
              label={"Correspondent"}
              dataSource={formState.correspondentsList}
              defaultValue={formState.correspondent.name}
              disabled={formState.correspondent.status}
              onSelect={(e) => {
                updateState("correspondent", {
                  ...formState.correspondent,
                  id: e.crdIDpk ?? 0,
                  name: e.empName1 ?? "",
                });
              }}
              onRefresh={async () => {
                !formState.correspondent.status &&
                  updateState("correspondent", {
                    ...formState.correspondent,
                    name: "",
                    id: 0,
                  });
                await popCmbCorrespondents();
              }}
            />
          </div>
          <div className="w-full">
            <DropDownTemp
              label="Home Station"
              options={formState.homeStationsList}
              idExpr={"stnIDpk"}
              displayExpr={"stnName"}
              showSearch
              toolTip={`Home Station/The log book you are writting in. It usually takes on the name of the substation you are located.`}
              disabled={formState.homeStation.status}
              value={formState.homeStation.name}
              onSelect={(e: any) => {
                updateState("homeStation", {
                  ...formState.homeStation,
                  id: e.stnIDpk ?? 0,
                  name: e.stnName ?? "",
                });
                instantChangesState.homeStation.id = e.stnIDpk ?? 0;
                dispatch(
                  setGeneralValue({
                    expr: "homeStation",
                    value: e.stnIDpk ?? 0,
                  })
                );
              }}
              onRefresh={async () => await popCmbHomeStation()}
            />
          </div>
        </Form>

        <section className="flex items-start space-x-2 w-full px-2">
          <label className="text-[12px] text-right ml-5">
            {formState.lblEventText}
          </label>
          <aside className="flex-1">
            <TextareaTemplate
              ref={eventLogRef}
              disabled={formState.eventLog?.status}
              height={showMiniForm ? 90 : resizeTextArea}
              MarginLeft={4}
              defaultValue={formState.eventLog?.value}
              setContent={(e: string) =>
                updateState("eventLog", { ...formState.eventLog, value: e })
              }
            />
          </aside>
        </section>

        <section className="flex justify-between items-center px-1 ml-[14px] mb-2">
          <em className="font-bold text-neutral-500 ">
            {formState.lblState}
            <span className={`${shifChangeWarning.color}`}>
              {shifChangeWarning.text}
            </span>
          </em>
          {
            formState.showPreviewDialog.showButton && (
              <section className="h-auto px-2 py-1 flex space-x-2 rounded-md border-[1px] cursor-pointer" onClick={() =>
                updateState("showPreviewDialog", { ...formState.showPreviewDialog, status: true })}
              >
                <img src={search} alt="preview btn" sizes="12" />
                <span>Save preview</span>
              </section>
            )
          }
          <SaveButton
            disableButton={formState.btnAddLogStatus}
            disabled_status={false}
            title={formState.btnSaveText}
            handleSave={handleLogOperations}
          />
        </section>
        {showMiniForm && (
          <MiniForm
            homeStationId={formState.homeStation.id}
            selectedOperators={formState.selectedOperators}
            upParentState={updateState}
            handleShiftChange={handleLogOperations}
          />
        )}
      </section>
    </section >
  );
};
