import React from "react";

export default function Attach() {
  return (
    <main className="flex flex-col h-[69vh] w-full ">
      <section className=" flex-1 border-[1px] h-full w-full">
      </section>
     
    </main>
  );
}
