import { useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Modal, Select } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { SelectsTemplate } from '../../../../templates/select';
import { UpdateButton } from '../../../accessories/buttons';
import { TextareaTemplate } from '../../../../templates/textarea';
import { RootState } from '../../../../app/store';
import { setGeneralValue } from '../../../../features/generalSlice';
import { PatchDataFunc } from '../../../../functions/patch';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import useFetch from '../../../../hooks/useFetch';
import { updateModalData } from '../../../accessories/component_infos';
import { modalPropTypes, selectObjectTypes, colorsType, checkboxTypes, selectsTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { ModalTemplate } from '../../../../templates/modal';
import { useCrudFunc } from '../../../../functions/crud';
import dayjs from 'dayjs';


interface props {
  open: boolean
  handleCancel: () => void
}

interface stateTypes {
  taskManagerOnStartup: boolean
  newsflashOnStartup: boolean
  newsflashBarOnStartup: boolean
  rightNavigatorOnStartup: boolean
  birthdayInNewsFlash: boolean
  workAnniversaryInNewsFlash: boolean
  tipOfTheDayOnStartup: boolean
  alertsWindowOnStartup: boolean
  ApplysettingsToAllUsers: boolean
  userChangeAppColours: boolean

  defaultApplication: { id: number, value: string, key: string }
  formHeaderBgColor: { id: string, key: number, value: string }
  footerBgColor: { id: string, key: number, value: string }
  mainNavigatorBgColor: { id: string, key: number, value: string }
  mainNavigatorTextColor: { id: string, key: number, value: string }
  rightNavigatorTextColor: { id: string, key: number, value: string }
  rightNavigatorBgColor: { id: string, key: number, value: string }
  topNavigatorBgColor: { id: string, key: number, value: string }
  topNavigatorTextColor: { id: string, key: number, value: string }
  newsflashBgColor: { id: string, key: number, value: string }
  newsFlashTextColor: { id: string, key: number, value: string }
  applyColorsToAllUsers: boolean
}

const MidnightConditions = ({ open, handleCancel }: props) => {

  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({
    eventId: 0,
    stationId: 0,
    remarks: "",
  });

  const updateState = (stateName: string, value: any) => {
    setFormData((currentState: any) => ({
      ...currentState,
      [stateName]: value,
    }));
  };
  const [modalAlert, setModalAlert] = useState<modalPropTypes>({
    state: false,
    title: '',
    message: ''
  })
  const [passwordRules, setPasswordRules] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(updateModalData);
  const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
  const [events, events_error, events_loading] =
    useFetch(`MidnightCondition/eventTypes`, true, 'id', false, "powerapp");
  const [stations, stations_error, stations_loading] =
    useFetch(`MidnightCondition/station`, true, 'id', false, "powerapp");
  const [template, template_error, template_loading] =
    useFetch(`MidnightCondition/grid?stnIDpk=${formData.stationId}&evtIDpk=${formData.eventId}`, true, 'id', false, "powerapp");

  useEffect(() => {
    if (template && template.length > 0) {
      updateState("remarks", template[0].tmpText || "");
    }
  }, [template]);

  const [posting, updating] = useCrudFunc("powerapp");

  const updateData = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => { },
    });

    await posting(
      `MidnightCondition/template`,

      {
        tmpText: formData.remarks,
        tmpEventTypeIDfk: parseInt(formData.eventId),
        tmpStationIDfk: parseInt(formData.stationId),
        // tmpActive: true,
        // tmpRmks:formData.remarks,
        // tmpCreatedBy: 0,
        // tmpCreationDate: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      }
      ,
      `Midnight Condition template added successfully`
    )
      .then((response) => {
        setIcon('success')
        setModalData(current => ({
          ...current,
          state: true,
          icon: "success",
          title: "Success",
          message: "Midnight Conditions Updated Successfuly",
          okText: "Ok",
          disableCancel: true,
          okHandler: () => {
            setOpenModal(false);
          },
          cancelHandler: () => {
            setModalData(current => ({
              ...current,
              state: false,
            })
            )
          },
        }))

      })
      .catch((err) => {
        setIcon('warning')
        setModalData(current => ({
          ...current,
          state: true,
          icon: "warning",
          title: "Failed",
          message: "Midnight Conditions failed to update. Contact your Administrator",
          okText: "Ok",
          cancelText: "Cancel",
          disableCancel: true,
          okHandler: () => {
            setOpenModal(false);

          },
          cancelHandler: () => {
            setOpenModal(false);
            setModalData(current => ({
              ...current,
              state: false,
            })
            )
          },
        }))
      });
  };


  return (
    <>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={modalData.disableCancel}
        // disableCancel={icon === "warning" ? true : false}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          icon === 'question' ? updateData() :
            setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <Modal
        maskClosable={false}
        width={790}
        footer=''
        centered
        style={{ border: '1px solid rgb(59 130 246)', borderRadius: 9, paddingBottom: 0 }}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}

            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            eLog Book - Update Midnight Conditions Template
          </div>
        }
        open={open}
        onOk={() => {
          updateData()
        }}
        transitionName=''
        onCancel={() => {
          handleCancel();
        }}
      >
        <>
          <p className=' mb-2 bg-slate-200 font-semibold px-2 py-1 rounded-sm'>
            Template Update Details
          </p>
          <main className="">
            {/* right form */}
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              className=' '
              size='small'
            >
              <div className=" mb-0.5 w-1/2 ml-4">
                <div className=" mb-0.5">
                  <SelectsTemplate
                    useCusWidth
                    cusWidth={50}
                    disabled={false}
                    options={events}
                    useCallFunc
                    idexpr={"evtIDpk"}
                    dataexp={"evtName"}
                    label='Event Type'
                    selectedValue={(e: any) => {
                      // updateState("serviceProvider", {
                      //   name: e.spdName,
                      //   id: e.spdIdpk,
                      // }); 
                      updateState("eventId", e.evtIDpk);



                    }}
                  />
                </div>

                <SelectsTemplate
                  label='Station'
                  disabled={false}
                  options={stations}
                  useCallFunc
                  idexpr={"stnIDpk"}
                  dataexp={"stnName"}
                  selectedValue={(e: any) => {
                    // updateState("serviceProvider", {
                    //   name: e.spdName,
                    //   id: e.spdIdpk,
                    // }); 
                    updateState("stationId", e.stnIDpk);


                  }}
                />
              </div>

              <div className="">
                <TextareaTemplate
                  labelCol={3}
                  disabled={false}
                  label='Template'
                  height={200}
                  useCallbackFunc={true}
                  defaultValue={formData.remarks}
                  setCallbackValue={(val) => {
                    updateState("remarks", val);
                  }}
                />
              </div>

              <div className=" mt-1 flex flex-row justify-end">
                <UpdateButton
                  disableButton={false}
                  disabled_status={false}
                  handleUpdate={() => {
                    setOpenModal(true); setModalData(updateModalData); setIcon('question');
                  }}
                />
              </div>
            </Form>
          </main>
        </>
      </Modal>
    </>
  );
};

export default MidnightConditions;