/* eslint-disable react/jsx-pascal-case */
// import type { SelectProps } from 'antd';
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { navbar_height } from "../../../../../accessories/component_infos";
import { Document_Details } from "./widgets/Document_form";
import { Right_grid } from "./widgets/Right_grid";
import { useState, useRef, useEffect, useCallback } from "react";
import { Transport_Documents_Comment } from "./widgets/Right pane widgets/comments";
import { Transport_Documents_Share } from "./widgets/Right pane widgets/share";
import useFetch from "../../../../../../hooks/useFetch";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { TransDoc_toolbar } from "../../../../../accessories/custom-toolbars/transportDoc_toolbar";
import { RootState } from "../../../../../../app/store";
import dayjs from "dayjs";
import {
  formModalStates,
  searchTextTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { GetDataFunc } from "../../../../../../functions/get";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { AutoShare } from "./widgets/AutoShare";
import { FormModal } from "../../../../../accessories/formModal";
import { AllDocShare } from "./widgets/AllDocShare";
import CustomImageRenderer from "../../../../../../templates/CustomImageRenderer";
import { useCrudFunc } from "../../../../../../functions/crud";

interface props {
  state_change: boolean;
}

interface statesTypes {
  lowerSelected?: any;
  upperSelected?: any;
  staffDC?: boolean | string;
  DPHC?: boolean | string;
  indeterminate?: any;
  indeterminate2?: any;
  openFormModal: boolean;
  setFormModal: any;
  setFormModalTitle: string;
  title: string;
  docType: any;
}

export const Fuel_Documents = ({ state_change }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [formheight, set_form_height] = useState<number>(0);
  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const defaultDoc = {
    uri: require("../../../../../../assets/InnorikLogo.jpg"),
  };
  const [docs, setDocs] = useState([
    defaultDoc, // Local File
  ]);
  const [activeDoc, setActiveDoc] = useState(docs[0]);
  const [icon, setIcon] = useState<"warning" | "success" | "question">(
    "warning"
  );
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [modalData, setModalData] = useState<any>({
    message: "No Access Privilege",
    title: "Access Denied!",
    okText: "Ok",
    cancelText: "No",
  });
  const [showCancel, setShowCancel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [AllComments, setAllComments] = useState<any>([]);

  const [initHeight, FullHeight] = useResizeMode(true);

  const formRef = useRef<any>(null);

  var pageHeight = initHeight - (navbar_height + news_flash_height + 10);

  const dispatch = useDispatch();
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [states, setStates] = useState<statesTypes>({
    upperSelected: {},
    lowerSelected: {},
    staffDC: "",
    DPHC: "",
    indeterminate: true,
    indeterminate2: true,
    openFormModal: false,
    setFormModal: "",
    setFormModalTitle: "",
    docType: { id: 0, name: "" },
    title: ""
  });

  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [showAllGrids, setShowAllGrids] = useState<boolean>(true);

  const dateFormat = " DD MMM YYYY";
  const today = dayjs(new Date()).format(dateFormat);
  const initialDate = dayjs()
    .subtract(0, "year")
    .startOf("year")
    .format(dateFormat);

  //data filter states
  const searchText = useRef("");
  const [criteria, setCriteria] = useState<number | string>(1);
  const [active, setActive] = useState<boolean | string>("");
  const [startDate, setStartDate] = useState<searchTextTypes>({
    temp: initialDate,
    text: initialDate,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    temp: today,
    text: today,
  });
  const [lowerGridData, setLowerGridData] = useState([]);
  const [sharedEmps, setSharedEmps] = useState([]);

  // -----------------------------------------------------------
  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomText = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomText += characters[randomIndex];
    }

    return randomText;
  }

  const confirmDeleteDocument = () => {
    if (Object.keys(states.lowerSelected).length === 0) {
      setIcon("warning");
      setShowCancel(false);
      setModalData({
        message: "Please select document to delete",
        title: "Validation Error!",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setShowCancel(true);
      setModalData({
        message:
          "This DELETE action is irreversible. Are you sure you want to proceed and DELETE the selected document?",
        title: "Confirm Delete",
      });
      setOpenModal(true);
    }
  };
  const deleteDocument = async () => {
    if (Object.keys(states.lowerSelected).length !== 0) {
      try {
        const res = await UpdateDataFunc(
          `AmsTmsEntTransportDocuments/DeleteDocument?eddIdpk=${states.lowerSelected.eddIDpk}`,
          {},
          `Deleted the Asset Mgr-TMS-Transport Doc. Details record with ID ${states.lowerSelected.eddIDpk} for Staff ${states.upperSelected.empName} with Emp ID ${states.lowerSelected.empIdpk}. The document type is-${states.docType.name} and the documents title is ${states.title}`
        );
        if (res.status === 200) {
          setIcon("success");
          setShowCancel(false);
          setModalData({
            message: "Document has been deleted successfully",
            title: "Delete Success",
          });
          setOpenModal(true);
          fetchLowerGridData(states.upperSelected.empIDpk);
        } else {
          throw Error("Something went wrong");
        }
      } catch (e: any) {
        setIcon("warning");
        setShowCancel(false);
        setModalData({ message: e.message, title: "Unexpected Error!" });
        setOpenModal(true);
      }
    } else {
      setIcon("warning");
      setShowCancel(false);
      setModalData({
        message: "Please select a document to delete",
        title: "Validation Error",
      });
      setOpenModal(true);
    }
  };
  const checkBoxOnChange = (e: any) => {
    if (states.indeterminate === true) {
      updateState("staffDC", false);
      updateState("indeterminate", null);
    } else if (states.indeterminate === null) {
      updateState("staffDC", true);
      updateState("indeterminate", false);
    } else {
      updateState("staffDC", false);
      updateState("indeterminate", true);
    }
  };

  const checkBoxOnChange2 = (e: any) => {
    if (states.indeterminate2 === true) {
      updateState("DPHC", false);
      updateState("indeterminate2", null);
    } else if (states.indeterminate2 === null) {
      updateState("DPHC", true);
      updateState("indeterminate2", false);
    } else {
      updateState("DPHC", false);
      updateState("indeterminate2", true);
    }
  };

  const dataGridAccessPriv = (e: any) => {
    setPrivType("update");
    if (updatePriv === true) {
      updateState("lowerSelected", e);
      //enable forms
      dispatch(setGeneralValue({ expr: "TransformDisabled", value: false }));
      //enable Update Mode
      dispatch(settask_footer_update(true));
      dispatch(setGeneralValue({ expr: "TransupdateMode", value: true }));
    } else {
      setIcon("warning");
      setOpenModal(true);
      setShowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  const fetchSharedEmps = async (eddId: number) => {
    const upperData = await GetDataFunc(
      `AmsTmsEntTransportDocuments/GetShareWith?eddIpk=${eddId}`
    );
    const newUpperData = upperData.data.map((item: any) => ({
      ...item,
      id: generateRandomId(),
    }));
    setSharedEmps(newUpperData);
  };

  const fetchComments = async (eddId: number) => {
    const comments = await GetDataFunc(
      `AmsTmsEntTransportDocuments/GetComments?eddIpk=${eddId}`
    );
    setAllComments(comments.data);
  };

  const lowerSelectRecord = async (e: any) => {
    if (e !== undefined) {
      updateState("lowerSelected", e);

      if (states.lowerSelected) {
        previewDocument(e.eddIDpk);
        await fetchSharedEmps(e.eddIDpk);
        await fetchComments(e.eddIDpk);
      }
    } else {
      setDocs([defaultDoc]);
    }
  };

  const fetchLowerGridData = async (empId: number) => {
    const lowerData = await GetDataFunc(
      `AmsTmsEntTransportDocuments/SearchAllTransportDocument?eddEmpId=${empId}&search=${searchText.current}&criteria=${criteria}&active=${active}`
    );
    const newLowerData = lowerData.data.map((item: any) => ({
      ...item,
      id: generateRandomId(),
    }));
    setLowerGridData(newLowerData);
    if (newLowerData.length === 0) {
      setSharedEmps([]);
      setDocs([defaultDoc]);
    }
  };

  const upperSelectRecord = async (e: any) => {
    setBusyLoader("Loading document...");
    updateState("upperSelected", e);
    if (states.upperSelected) {
      await fetchLowerGridData(e.empIDpk);
      setBusyLoader("");
    }
  };

  // ------------------------------------------------------------

  // Endpoints Routes
  const getStaffNamesForSysAdmin =
    "AmsTmsEntTransportDocuments/GetStaffNamesForSysAdmin"; // when both checkboxes are null
  const getStaffNamesForSysAdminX = `AmsTmsEntTransportDocuments/GetStaffNamesForSysAdmin?staffDetail=${states.staffDC}&permitHolder=${states.DPHC}`;

  const [upperGridData, upperGridError, upperGridLoading] = useFetch(
    `${
      states.DPHC === null && states.staffDC === null
        ? getStaffNamesForSysAdmin
        : getStaffNamesForSysAdminX
    }`
  );
  const [url, setUrl] = useState("");
  const previewDocument = async (eddId: number) => {
    const response = await GetDataFunc(
      `AmsTmsEntTransportDocuments/GetDocument?eddIdpk=${eddId}`
    );
    const documentData = response.data[0];
    const documentType = documentData.atdType;
    const documentContent = documentData.atdDoc;
    switch (documentType) {
      case "application/pdf":
        // For PDF documents, create a Blob and set the URL
        const pdfBlob = new Blob(
          [Uint8Array.from(atob(documentContent), (c) => c.charCodeAt(0))],
          { type: "application/pdf" }
        );
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setUrl(pdfUrl);
        setDocs([{ uri: pdfUrl }]);
        break;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        // For Word documents, create a Blob and set the URL
        const wordBlob = new Blob(
          [Uint8Array.from(atob(documentContent), (c) => c.charCodeAt(0))],
          { type: "application/msword" }
        );
        const wordUrl = URL.createObjectURL(wordBlob);
        setUrl(wordUrl);
        setDocs([{ uri: wordUrl }]);
        break;
      case "text/plain":
        // For text documents, create a Blob and set the URL
        const textBlob = new Blob([documentContent], { type: "text/plain" });
        const textUrl = URL.createObjectURL(textBlob);
        setUrl(textUrl);
        setDocs([{ uri: textUrl }]);
        break;

      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
        // For Excel documents, create a Blob and set the URL
        const excelBlob = new Blob(
          [Uint8Array.from(atob(documentContent), (c) => c.charCodeAt(0))],
          { type: documentType }
        );
        const excelUrl = URL.createObjectURL(excelBlob);
        setUrl(excelUrl);
        setDocs([{ uri: excelUrl }]);
        break;
      case "image/jpeg":
      case "image/png":
      case "image/gif":
      case "image/bmp":
        // For image formats, create a Blob and set the URL
        const imageBlob = new Blob(
          [Uint8Array.from(atob(documentContent), (c) => c.charCodeAt(0))],
          { type: documentType }
        );
        const imageUrl = URL.createObjectURL(imageBlob);
        setUrl(imageUrl);
        setDocs([{ uri: imageUrl }]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 45);
  }, []);
  const formModals: formModalStates[] = [
    { id: 0, label: "Gen-Doc Auto Share", module: <AutoShare /> },
    { id: 1, label: "Fin-All Docs Share", module: <AllDocShare /> },
  ];

  const handleAutoShare = useCallback(() => {
    updateState("openFormModal", !states.openFormModal);
    updateState("setFormModal", formModals[0].module);
    updateState("setFormModalTitle", formModals[0].label);
  }, []);
  const handleAllShare = useCallback(() => {
    updateState("openFormModal", !states.openFormModal);
    updateState("setFormModal", formModals[1].module);
    updateState("setFormModalTitle", formModals[1].label);
  }, []);

  return (
    //handle error if grid has no data
    <>
      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
        {/* toggles between the taskForm and the footer grid table vertically */}

        <FormModal
          openState={states.openFormModal}
          child={states.setFormModal}
          height={600}
          width={"50%"}
          title={states.setFormModalTitle}
          onCancel={() => {
            updateState("openFormModal", false);
          }}
        />

        <div>
          <TransDoc_toolbar
            deleteDocument={confirmDeleteDocument}
            handleAutoShare={handleAutoShare}
            handleAllShare={handleAllShare}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate.text}
            endDate={endDate.text}
            handleActive={(checked) => {
              setActive(checked);
            }}
            searchCriteria={criteria}
            setSearchCriteria={setCriteria}
            handleSearchChange={(val) => {
              searchText.current = val;
            }}
            handleFind={() => {
              fetchLowerGridData(states.upperSelected.empIDpk);
            }}
            handleRefresh={() => {
              searchText.current = "";
              fetchLowerGridData(states.upperSelected.empIDpk);
            }}
            setShowAllGrids={setShowAllGrids}
          />
        </div>

        <div className="flex flex-row w-[100%]">
          {/* Right pane  */}

          <div
            style={{ minWidth: "calc(100% - 78%)", height: pageHeight }} // pageHeight + 240
            className="h-full px-2 w-4/12"
          >
            <Right_grid
              upperGridData={upperGridData}
              lowerGridData={lowerGridData}
              singleClick={lowerSelectRecord}
              singleClick2={upperSelectRecord}
              checkBoxOnChange={checkBoxOnChange}
              checkBoxOnChange2={checkBoxOnChange2}
              states={states}
              dataGridUpdateFunc={dataGridAccessPriv}
              showAllGrids={showAllGrids}
            />
          </div>

          <ModalTemplate
            icon={icon}
            cancelText={modalData.cancelText}
            disableCancel={!showCancel}
            cancelHandler={() => {
              setOpenModal(false);
            }}
            open={openModal}
            okHandler={() => {
              if (modalData.title === "Confirm Delete") deleteDocument();
              setOpenModal(false);
            }}
            message={modalData.message}
            okText={modalData.okText}
            title={modalData.title}
          />

          {/* Center pane           width: "740px"  */}
          <div className="flex-initial w-5/12">
            <div ref={formRef} className="px-2 ">
              <p
                id={"form_header"}
                style={{ borderColor: borderTheme }}
                className="pt-1 border-[1px] pl-4 w-full bg-slate-100 rounded-tl"
              >
                Attachments
              </p>
              <div
                style={{ height: "240px" }}
                className="w-full border-b-1 mb-1 pb-1"
              >
                <div
                  style={{ width: "100%", height: "240px" }}
                  className="mb-1"
                >
                  <Document_Details
                    parentstates={states}
                    parentSetstate={updateState}
                    setDocs={setDocs}
                    setActiveDoc={setActiveDoc}
                    docs={docs}
                    sharedEmps={sharedEmps}
                    setSharedEmps={setSharedEmps}
                    defaultDoc={defaultDoc}
                    fetchLowerGridData={fetchLowerGridData}
                  />
                </div>
              </div>
            </div>

            {/* Footer gridtable  */}
            <div
              style={{ height: "" }}
              className=" px-2 overflow-hidden dark:bg-slate-900 dark:text-darkModeSkin-base"
            >
              <div
                style={{
                  height: initHeight - 480, //- 450

                  border: "2px solid #ccc",
                  borderColor: borderTheme,
                }}
                className="h-full items-center "
              >
                <DocViewer
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                  }}
                  documents={docs}
                  activeDocument={activeDoc}
                  onDocumentChange={(e: any) => {
                    setActiveDoc(e);
                  }}
                  pluginRenderers={[...DocViewerRenderers, CustomImageRenderer]}
                  className="text-wrap"
                />
              </div>
            </div>
          </div>

          <div className="w-3/12">
            {/* Right side */}
            <div>
              <div className="w-full pr-1">
                <TabPanel
                  style={{ height: initHeight - 210 }}
                  className="w-full "
                >
                  <Item title={"Share"}>
                    <Transport_Documents_Share
                      setSharedEmps={setSharedEmps}
                      sharedEmps={sharedEmps}
                    />
                  </Item>

                  <Item title={"Comments"}>
                    <Transport_Documents_Comment
                      fetchComments={fetchComments}
                      AllComments={AllComments}
                      lowerSelected={states.lowerSelected}
                    />
                  </Item>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
