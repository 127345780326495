/* eslint-disable react/jsx-pascal-case */
import { Switcher } from "../../_widget/switcher";
import { RefreshButton } from "../../_widget/utils";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { SaveButton, UpdateButton } from "../../../../../accessories/buttons";
import { DateTemplate } from "../../../../../../templates/date";
import MiniToolbar from "../../_widget/minitollbar";
import { InputsTemplate } from "../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { ReadingsChart } from "../../_widget/readingschart";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import {
  lineColumns,
  lineData,
  stationEntries,
  stationEntriesColumns,
} from "../BusVoltage/_data/stationentriescolumns";
import useFetch from "../../../../../../hooks/useFetch";
import { useEffect, useRef, useState } from "react";
import { line_cols } from "./data/datagridCols";
import dayjs, { Dayjs } from "dayjs";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useCrudFunc } from "../../../../../../functions/crud";
import { ModalTemplate } from "../../../../../../templates/modal";
import { saveModalData } from "../../../../../accessories/component_infos";
import { title } from "process";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import LineReadingsToolbar from "./lineReadingToolbar";
import { UpdateStates } from "../../../../../../functions/updateStates";

interface LineReadingsTypes {
  voltage: any;
  current: any;
  activeInput: any;
  reactiveInput: any;
  refresh: boolean;
  selectedDate?: Dayjs;
  active: boolean;
  remarks: string;
  stationSelected: any;
  lineSelected: any;
  selectedRecord: any;
  stRefresh: boolean;
}

export default function LineReadings() {
  const [states, setStates] = useState<LineReadingsTypes>({
    refresh: false,
    selectedDate: dayjs(),
    voltage: 0,
    current: 0,
    activeInput: 0,
    active: false,
    reactiveInput: 0,
    remarks: "",
    stationSelected: {},
    lineSelected: {},
    selectedRecord: {},
    stRefresh: false,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  console.log(states, "states");

  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  console.log(searchText, "searchText");
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(1, "month")
    .startOf("year")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  const [refresh, setRefresh] = useState<boolean>(false);

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const [searchValue, setSearchValue] = useState("");

  const { status } = useGraphSwitcher();
  const { status: switchStatus, setWitch, setWitchFalse } = useSwitcher();
  const dispatch = useDispatch();
  const recordSwitch = useRef<boolean>();

  useEffect(() => {
    recordSwitch.current = switchStatus;
  }, [switchStatus]);

  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 20,
  });

  //LineReadings/grid?StartDate=2020-01-01&EndDate=2024-01-01&EmpId=1&SearchTerm=a&CheckState=true&PageNumber=1&PageSize=20

  const [lineData] = useFetch(
    `LineReadings/grid?StartDate=${startDate.text}&EndDate=${
      endDate.text
    }&EmpId=${1}&SearchTerm=${searchText.text}&CheckState=${
      activeStatus.status
    }&PageNumber=${1}&PageSize=${paging.pageSize}`,
    refresh,
    undefined,
    undefined,
    "powerapp"
  );

  const [station_data] = useFetch(
    `LineReadings/line-stations`,
    states.stRefresh,
    undefined,
    undefined,
    "powerapp"
  );

  //LineReadings/line-details?stnIDfk=1
  const [lineDetails] = useFetch(
    `LineReadings/line-details?stnIDfk=${states.stationSelected.stnIDpk}`,
    states.refresh,
    undefined,
    undefined,
    "powerapp"
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const clearFields = () => {
    setStates({
      refresh: false,
      selectedDate: dayjs(),
      voltage: 0,
      current: 0,
      activeInput: 0,
      active: false,
      reactiveInput: 0,
      remarks: "",
      stationSelected: {},
      lineSelected: {},
      selectedRecord: {},
      stRefresh: false,
    });
  };

  const populateFields = (data: any) => {
    setStates((prevState) => ({
      ...prevState,
      selectedDate: data?.lrdTime ? dayjs(data.lrdTime) : dayjs(),
      voltage: data?.lrdVoltage ?? 0,
      current: data?.lrdCurrent ?? 0,
      activeInput: data?.lrdActivePower ?? 0,
      reactiveInput: data?.lrdReactivePower ?? 0,
      remarks: data?.lrdRmks ?? "",
      selectedRecord: data ?? {},
    }));
  };

  const validation = () => {
    if (
      states.stationSelected.stnIDpk === undefined ||
      states.stationSelected.stnIDpk === null ||
      states.stationSelected.stnIDpk === 0
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please select a station",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    if (
      states.lineSelected.ldtIDpk === undefined ||
      states.lineSelected.ldtIDpk === null ||
      states.lineSelected.ldtIDpk === 0
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please select a line",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    if (
      states.selectedDate === undefined ||
      states.selectedDate === null
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please select a date",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    if (
      states.voltage === undefined ||
      states.voltage === null ||
      states.voltage === ""
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter a voltage",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    if (
      states.current === undefined ||
      states.current === null ||
      states.current === ""
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter a current",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    if (
      states.activeInput === undefined ||
      states.activeInput === null ||
      states.activeInput === ""
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter active power",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    if (
      states.reactiveInput === undefined ||
      states.reactiveInput === null ||
      states.reactiveInput === ""
    ) {
      setIcon("warning");
      setModalData({
        title: "Validation Error",
        message: "Please enter reactive power",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    } 
    
    return true;
  };

  const saveAccespriv = () => {
    if (validation()) {
      setIcon("question");
      setModalData({
        title: "Save Record",
        message: "Are you sure you want to save this record?",
        okText: "Ok",
        cancelText: "Cancel",
      });
      setOpenModal(true);
    }
  };
  
  const updateAccespriv = () => {
    if (validation()) {
      setIcon("question");
      setModalData({
        title: "Update Record",
        message: "Are you sure you want to update this record?",
        okText: "Ok",
        cancelText: "Cancel",
      });
      setOpenModal(true);
    }
  };

  const toggleActiveStatus = () => {
    const newActiveStatus = !states.selectedRecord?.lrdActive;
    validation();
    setIcon("question");
    setModalData({
      title: newActiveStatus ? "Activate Record" : "Deactivate Record",
      message: `Are you sure you want to ${
        newActiveStatus ? "activate" : "deactivate"
      } this record?`,
      okText: "Ok",
      cancelText: "Cancel",
    });
    setOpenModal(true);
  };

  const [Posting, Updating, Patching] = useCrudFunc("powerapp");

  const postData = async () => {
    try {
      const res = await Posting(`LineReadings`, {
        lrdStationIDfk: states.stationSelected.stnIDpk,
        lrdLineIDfk: states.lineSelected.ldtIDpk,
        lrdTime: states.selectedDate,
        lrdActivePower: states.activeInput,
        lrdReactivePower: states.reactiveInput,
        lrdCurrent: states.current,
        lrdVoltage: states.voltage,
        lrdRmks: states.remarks,
      });
      setIcon("success");
      setModalData({
        title: "Record Saved",
        message: "Record saved successfully",
        okText: "Ok",
      });
      setOpenModal(true);
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (error) {
      setIcon("warning");
      setModalData({
        title: "Unexpected Error!",
        message: "Record not saved",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const updateData = async () => {
    try {
      const res = await Updating(
        `LineReadings/${states.selectedRecord.lrdIDpk}`,
        {
          // lrdIDpk: states.selectedRecord.lrdIDpk,
          lrdStationIdfk: states.stationSelected.stnIDpk,
          lrdLineIdfk: states.lineSelected.ldtIDpk,
          lrdTime: states.selectedDate,
          lrdActivePower: states.activeInput,
          lrdReactivePower: states.reactiveInput,
          lrdCurrent: states.current,
          lrdVoltage: states.voltage,
          lrdRmks: states.remarks,
        }
      );
      setIcon("success");
      setModalData({
        title: "Record Updated",
        message: "Record updated successfully",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setOpenModal(true);
      setRefresh(!refresh);
    } catch (error) {
      setIcon("warning");
      setModalData({
        title: "Unexpected Error!",
        message: "Record failed to update",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const toggleData = async () => {
    const newActiveStatus = !states.selectedRecord?.lrdActive;
    try {
      const res = await Patching(
        `LineReadings/UpdateActiveStatusLineReadings/${states.selectedRecord?.lrdIDpk}?lrdActive=${newActiveStatus}`,
        {}
      );

      setIcon("success");
      setModalData({
        title: newActiveStatus ? "Record Activated" : "Record Deactivated",
        message: `Record ${
          newActiveStatus ? "activated" : "deactivated"
        } successfully`,
        okText: "Ok",
      });
      setRefresh(!refresh);
    } catch (error) {
      setIcon("warning");
      setModalData({
        title: "Unexpected Error!",
        message: `Record failed to ${
          newActiveStatus ? "activate" : "deactivate"
        }`,
        okText: "Ok",
      });
    }
    setOpenModal(true);
  };

  useEffect(() => {
    // if (Object.keys(states.selectedRecord).length > 0) {
    populateFields(states.selectedRecord);
    // }
  }, [states.selectedRecord]);

  return (
    <section className="w-full h-full">
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          switch (modalData.title) {
            case "Save Record":
              postData();
              break;
            case "Update Record":
              updateData();
              break;
            case "Activate Record":
            case "Deactivate Record":
              toggleData();
              break;
            default:
              setOpenModal(false);
          }
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "success" || icon === "warning"}
      />
      <Switcher alternate={""}>
        {/* top section */}
        <section className="flex space-x-3 w-full border-2 p-2">
          {/* left section */}
          <aside className="w-[22%]">
            <aside className="flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400">
              <p className="text-sm font-medium">STATION</p>
              <RefreshButton
                onClick={() => setState("stRefresh", !states.stRefresh)}
              />
            </aside>
            <aside>
              <Datagrid_template
                gridheight={240}
                showColumnHeaders={false}
                columns={[
                  {
                    id: 1,
                    caption: "STATION",
                    dataField: "stnName",
                  },
                ]}
                data={station_data}
                onRowClick={(e) => {
                  setState("stationSelected", e);
                }}
                disablePaging
                disableSearch
                disableGroupPanel
              />
            </aside>
          </aside>
          {/* right section */}
          <aside className="w-[78%] items-right overflow-hidden">
            <section className="flex items-start ml-[174px]">
              <p className="text-[12px] font-medium mr-2">Line:</p>
              <Datagrid_template
                gridheight={100}
                showColumnHeaders={true}
                columns={line_cols}
                data={lineDetails}
                onRowClick={(e) => {
                  setState("lineSelected", e);
                }}
                disablePaging
                disableSearch
                disableGroupPanel
              />
            </section>
            <section className="w-full h-full mt-1">
              <section className="flex items-start justify-between">
                <aside className="flex items-start w-full ml-[160px] mb-0.5">
                  <p className="mr-[9px] mt-[2px]">Time : </p>
                  <DateTemplate
                    disabled={form_disable}
                    // label="Time"
                    style={{ width: 230, height: 32 }}
                    showTime
                    // format=" MMM DD, YYYY HH:mm"
                    selectedDate={dayjs(states.selectedDate) ?? dayjs()}
                    changeDate={(e: Dayjs) => {
                      setState("selectedDate", dayjs(e).format());
                    }}
                    className="w-full"
                  />
                </aside>
                <aside>
                  <RefreshButton
                    onClick={() => {
                      clearFields();
                      // setState("selectedRecord", {});
                    }}
                  />
                </aside>
              </section>
              <section className="flex space-x-2 items-center mb-1">
                <aside className="space-y-0.5 ">
                  <section className="flex space-x-1">
                    <aside className="ml-14">
                      <InputsTemplate
                        disabledStatus={form_disable}
                        span
                        useCallbackFunc
                        inputStyle={{ width: 114 }}
                        label="Voltage (kv) & Current (A)"
                        labelColPan={3}
                        defaultValue={states.voltage ?? 0}
                        orderOnchange={(e) => {
                          setState("voltage", e);
                        }}
                      />
                    </aside>
                    <InputsTemplate
                      disabledStatus={form_disable}
                      useCallbackFunc
                      span
                      inputStyle={{ width: 114 }}
                      defaultValue={states.current ?? 0}
                      orderOnchange={(e) => {
                        setState("current", e);
                      }}
                    />
                  </section>
                  <section className="flex space-x-1 ml-0.5">
                    <InputsTemplate
                      disabledStatus={form_disable}
                      span
                      useCallbackFunc
                      inputStyle={{ width: 114 }}
                      label="Active (kW) & Reactive Power (kVAr)"
                      labelColPan={12}
                      defaultValue={states.activeInput ?? 0}
                      orderOnchange={(e) => {
                        setState("activeInput", e);
                      }}
                    />
                    <InputsTemplate
                      disabledStatus={form_disable}
                      span
                      inputStyle={{ width: 114 }}
                      useCallbackFunc
                      defaultValue={states.reactiveInput ?? 0}
                      orderOnchange={(e) => {
                        setState("reactiveInput", e);
                      }}
                    />

                    {!update_state_change ? (
                      <SaveButton
                        disableButton={form_disable}
                        handleSave={() => {
                          saveAccespriv();
                        }}
                      />
                    ) : (
                      <UpdateButton
                        disableButton={form_disable}
                        handleUpdate={updateAccespriv}
                      />
                    )}
                    {/* <SaveButton disableButton={false} handleSave={() => {}} />
                    <UpdateButton disableButton={false} handleUpdate={() => {}} /> */}
                  </section>
                </aside>
              </section>
              {/* <section className="h-full mt-0.5  "> */}
              {/* <p className="mr-[6px]">Remarks:</p> */}
              <Form.Item
                className="mt-0.5  ml-[148px]"
                label={<p className="text-[12px]">Remarks</p>}
              >
                <div className="w-full pr-2">
                  <TextareaTemplate
                    height={75}
                    useCallbackFunc
                    defaultValue={states.remarks ?? ""}
                    disabled={form_disable}
                    readonly={form_disable}
                    setCallbackValue={(e) => {
                      setState("remarks", e);
                    }}
                  />
                </div>
              </Form.Item>

              {/* <TextareaTemplate
                  height={75}
                  //   labelCol={7}
                  label={"Remarks"}
                  useCallbackFunc
                  defaultValue={states.remarks ?? ""}
                  disabled={false}
                  setCallbackValue={(e) => {
                    setState("remarks", e);
                  }}
                /> */}
              {/* </section> */}
            </section>
          </aside>
        </section>
        <div className="h-1 w-full bg-sky-200" />
      </Switcher>
      {/* botom section */}
      <section className="flex flex-col">
        <LineReadingsToolbar
          startDateChanged={(date) => {
            setStartDate({
              temp: dayjs(date).format(genFormat),
              text: startDate.text,
            });
          }}
          endDateChanged={(date) => {
            setEndDate({
              temp: dayjs(date).format(genFormat),
              text: endDate.text,
            });
          }}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, text: searchText.temp });
            setActiveStatus({
              temp: activeStatus.temp,
              status: activeStatus.temp,
            });
            setStartDate({ text: startDate.temp, temp: startDate.temp });
            setEndDate({ text: endDate.temp, temp: endDate.temp });
          }}
          checkOnChange={(check) => {
            setActiveStatus({ temp: check, status: activeStatus.status });
          }}
          searchTextOnchange={(searchValue) => {
            setSearchText({ temp: searchValue, text: searchText.text });
          }}
          runNew={() => {
            clearFields();
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            setWitchFalse();
          }}
          runUpdate={() => {
            if (Object.keys(states.selectedRecord).length === 0) {
              setIcon("warning");
              setModalData({
                title: "Select Record",
                message: "Please select a record to edit",
                okText: "Ok",
              });
              setOpenModal(true);
              return;
            }
            populateFields(states.selectedRecord);
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            dispatch(setGeneralValue({ expr: "updateMode", value: true }));
            setWitchFalse();
          }}
          handleClose={() => {
            {
              states.selectedRecord?.lrdIDpk && toggleActiveStatus();
            }
          }}
          handleSwitcher={setWitch}
          handleRefresh={() => {
            setRefresh(!refresh);
            setStartDate({ text: startDate.temp, temp: startDate.temp });
            setEndDate({ text: endDate.temp, temp: endDate.temp });
            setSearchText({ temp: "", text: "" });
            setActiveStatus({ temp: "", status: "" });
            setSearchValue("");
          }}
          searchValue={searchText.temp}
          stationData={station_data}
        />
        <section className="flex-grow">
          <section className="w-full h-full">
            {status ? (
              <ReadingsChart />
            ) : (
              <Datagrid_template
                gridheight={!switchStatus ? 380 : 700}
                showColumnHeaders={true}
                columns={lineColumns}
                onRowClick={(e) => {
                  setState("selectedRecord", e);
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: false })
                  );
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );
                }}
                rowDoubleClicked={(e) => {
                  setState("selectedRecord", e);
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: true })
                  );
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: false })
                  );
                  setWitchFalse();
                }}
                handlePageNumber={(e: any) => {
                  UpdateStates("pageNumber", e, setPaging);
                }}
                handlePageSize={(e: any) => {
                  UpdateStates("pageSize", e, setPaging);
                }}
                pageSize={paging.pageSize}
                pageNumber={paging.pageNumber}
                data={lineData}
                // disablePaging
                // disableGroupPanel
              />
            )}
          </section>
        </section>
      </section>
    </section>
  );
}
