import { useEffect, useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import CustomLoader from "../../../../../../accessories/custom-loader";
import { FormModal } from "../../../../../../accessories/formModal";
import VehicleLogBook from "../../Vehicle-Log-Book/widgets/vehicleLogBook";
import VehicleRequestsSecurity from "../../Vehicle-Requests-Security/widgets/vehicle-request-security";
import { SendSMSAlertDetails } from "./sendSMSAlertDetails";
import VehicleRequestsDetails from "./vehicleRequests-details";
import { VehicleRequestsToolbar } from "./vehicleRequests-toolbar";
import { VehicleKeyAndInspectionForm } from "./widgets/vehicleKeyAndInspectionForm";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import {
  formModalStates,
  searchCriteriaTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import {
  currentDate,
  saveModalData,
  startingDate,
} from "../../../../../../accessories/component_infos";
import { Popup_Tips } from "./widgets/popUpTips";
import {
  SaveButton,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { Form } from "antd";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  setRefreshx?: () => void;
  AllState?: any;
  setAllState?: (value: any) => void;
}

interface ModalData {
  state: boolean;
  icon: "warning" | "success" | "question";
  title: string;
  okText: string;
  message: string;
  okHandler: () => void;
  cancelHandler: () => void;
  disableCancel: boolean;
}

interface stateTypes {
  details: string;
  reason: string;
}

const VehicleRequests = ({ setRefreshx, AllState, setAllState }: props) => {
  const [sendSMSAlertModal, setSendSMSAlertModal] = useState<boolean>(false);
  const [vehicleLogBookModal, setVehicleLogBookModal] =
    useState<boolean>(false);
  const [vehicleKeyModal, setVehicleKeyModal] = useState<boolean>(false);
  const [securityGateModal, setSecurityGateModal] = useState<boolean>(false);
  const [helpCenter, setHelpCenter] = useState<boolean>(false);
  const [empId, userId, userInfo] = useAccountInfo();

  const [searchText, setSearchText] = useState<{ temp: string; text: string }>({
    temp: "",
    text: "",
  });
  const [startDate, setStartDate] = useState<{ temp: string; index: string }>({
    temp: startingDate,
    index: startingDate,
  });
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: currentDate,
    index: currentDate,
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const [refreshState, setRefreshState] = useState(false);
  const [ModalData, setModaldata] = useState<ModalData>({
    state: false,
    icon: "warning",
    title: "",
    message: "",
    okText: "",
    okHandler: () => {},
    cancelHandler: () => {},
    disableCancel: false,
  });

  const [approvedStatus, setApprovedStatus] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({ temp: "", status: "" });

  const [cancelStatus, setCancelStatus] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({ temp: "", status: "" });

  const initialStates = {
    requestArea: { id: 0, name: "" },
    requestBy: { id: 0, name: "" },
    requestType: { id: 0, name: "" },
    recommendedBy: { id: 0, name: "" },
    selectedFromCountry: { id: 0, name: "" },
    selectedToCountry: { id: 0, name: "" },
    selectedFromRegion: { id: 0, name: "" },
    selectedToRegion: { id: 0, name: "" },
    selectedFromLocation: { id: "", name: "" },
    selectedToLocation: { id: "", name: "" },
    deactivateFields: true,
    regionsFrom: [],
    regionsTo: [],
    locationsFrom: [],
    locationsTo: [],
    departureTime: dayjs(),
    returnTime: dayjs(),
    isDriver: false,
    isVehicle: false,
    route: "",
    purpose: "",
    remark: "",
    active: true,
    numOfEmployees: 1,
    refresh: false,
    modalState: {
      state: false,
      title: "",
      message: "",
    },
    successModal: {
      state: false,
      title: "",
      message: "",
    },
    confirmState: {
      state: false,
      title: "",
      okText: "",
      message: "",
      event: () => {},
    },
    showUpdateButton: false,
    showCancelButton: false,
    selectedItemId: 0,
    selectedRequestClass: { id: 0, name: "" },
    selectedVehicleno1: { id: 0, name: "" },
    selectedVehicleno2: { id: 0, name: "" },
    selectedDriverNo1: { id: 0, name: "" },
    selectedDriverNo2: { id: 0, name: "" },
    selectedRequestType: { id: 0, name: "" },
    debitAmount: 0,
    reminderDate: dayjs(),
    debitNotes: "",
    remarks2: "",
    assignRemarks: "",
    deactivateFields2: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdown3: false,
    isOpenDropdown4: false,
    isOpenDropdown5: false,
    isOpenDropdown6: false,
    isOpenDropdown7: false,
    isOpenDropdown8: false,
    isOpenDropdown9: false,
    isOpenDropdown10: false,
    isOpenDropdown11: false,
    isOpenDropdown12: false,
    isOpenDropdown13: false,
    isOpenDropdown14: false,
    isOpenDropdown15: false,
    isOpenDropdown16: false,
    isLoading: false,
  };
  const [states, setStates] = useState<any>(initialStates);
  const [ammendStates, setAmmendStates] = useState<stateTypes>({
    details: "",
    reason: "",
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [refresh, setRefresh] = useState<boolean>(false);

  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const setAmmendState = (key: string, value: any) => {
    setAmmendStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [posting, updating] = useCrudFunc();

  const checkConnection = () => {
    if (!navigator.onLine) {
      setModaldata({
        ...ModalData,
        icon: "warning",
        title: "No Internet Connection!",
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again",
        disableCancel: true,
        okText: "Ok",
        okHandler: () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
        },
      });
    }
  };

  const searchCriteriaData = [
    { id: 0, value: "Reg. No" },
    { id: 1, value: "Driver" },
    { id: 2, value: "Workshop" },
    { id: 3, value: "Job No" },
    { id: 4, value: "Defect Report" },
    { id: 5, value: "Closing Remarks" },
  ];

  const [showTable, setShowTable] = useState(true);

  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  const [mainDataGridData, mainDataGridError, mainDataGridLoading] = useFetch(
    `AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequests?startDate=${startDate.index}&endDate=${endDate.index}&searchText=${searchText.text}&searchCriteriaIndex=${searchCriteria.index}&cancelled=${cancelStatus.status}&approved=${approvedStatus.status}&PageNumber=${1}&PageSize=${20}`,
    refresh,
    "vrqIdpk"
  );


  const sendAmendmentSms = async (message) => {
    try {
      checkConnection();
      await posting(
        `SMSAlert/SaveSMS`,
        {
          imsMessage: message,
          imsRecipientEmpIdfk: empId,
          imsSender: userInfo.empName,
          imsRecordIdfk: selectedRecord.vrqIdpk,
          imsFormCode: "asmTmsVehicleRequest",
          imsMessageType: "string",
          imsMessageClass: "string",
          imsInitiatorEmpIdfk: empId,
          imsCreatedBy: userId,
        },
        `Amendment SMS for Vehicle Request with ID ${selectedRecord.vrqIdpk} has been sent successfully!`
      );
      setModaldata({
        ...ModalData,
        state: true,
        icon: "success",
        title: "Success!",
        message: "Amendment submitted successfully!",
        okText: "Ok",
        okHandler: () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
        },
        disableCancel: true,
      });
    } catch (e) {
      setModaldata({
        ...ModalData,
        icon: "warning",
        title: "SMS not sent!",
        message: "Please contact your systems Administrator",
        disableCancel: true,
        okText: "Ok",
        okHandler: () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
        },
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await updating(
        `AsmTmsVehicleRequest/AmmendAsmTmsVehicleRequest?id=${states.requestBy.id}`,
        {
          vrqAmendmentDetails: states.details,
          vrqAmendmentReasons: states.reason,
        },
        `Successfully submitted an amendment for the Asset Manager-Trans-Vehicle Request with ID ${states.requestBy.id}!`
      );
      await sendAmendmentSms(`Hi, an amendment has been submitted for the vehicle request with ID ${selectedRecord?.vrqIdpk}.
      Details: ${states.details}
      Reason: ${states.reason}
      Regards, InnoX`);
      setRefreshState(!refreshState);
    } catch (err: any) {
      setModaldata({
        ...ModalData,
        icon: "warning",
        title: "Error Submitting!",
        message: "Error submitting amendment",
        disableCancel: true,
        okText: "Ok",
        okHandler: () => {
          setModaldata({
            ...ModalData,
            state: false,
          });
        },
      });
    }
  };

  const [amendment_btn, setamendment_btn] = useState<boolean>(false);

  const SaveForm = () => (
    <Form
      className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
      name="basic"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 10 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      size="small"
      layout="horizontal"
    >
      <section className="w-full h-full">
        <div className="w-full h-full">
          <p className="w-full py-0.5">Enter details(s) of amendment</p>
          <TextareaTemplate
            height={100}
            defaultValue={ammendStates.details}
            setCallbackValue={(e) => {
              setAmmendState("details", e);
            }}
            useCallbackFunc
            disabled={false}
          />
        </div>

        <div className="w-full h-full">
          <p className="w-full py-0.5">Enter the reason(s) for the amendment</p>
          <TextareaTemplate
            height={100}
            defaultValue={ammendStates.reason}
            setCallbackValue={(e) => {
              setAmmendState("reason", e);
            }}
            useCallbackFunc
            disabled={false}
          />
        </div>
        <div className="w-full flex justify-end">
          <SaveButton
            useCallbackFunc
            disableButton={false}
            title="Submit"
            handleSave={handleSubmit}
          />
        </div>
      </section>
    </Form>
  );

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "TMS - Vehicle Request Amendments",
      module: <SaveForm />,
    },
  ];

  return (
    <>
      <ModalTemplate
        icon={ModalData.icon}
        disableCancel={ModalData.disableCancel}
        title={ModalData.title}
        okText={ModalData.okText}
        message={ModalData.message}
        open={ModalData.state}
        okHandler={ModalData.okHandler}
        cancelHandler={ModalData.cancelHandler}
      />
      <FormModal
        height={1000}
        width={"30%"}
        openState={amendment_btn}
        onCancel={() => {
          setamendment_btn(false);
        }}
        child={formModal[0].module}
        title={formModal[0].label}
      />

      <div className="mx-2">
        <VehicleRequestsToolbar
          switchState={setShowTable}
          toggler={switch_toggle}
          handleAmmendments={() => {
            setamendment_btn(true);
          }}
          launchSendSMSAlertModal={() =>
            setSendSMSAlertModal(!sendSMSAlertModal)
          }
          launchVehicleLogBookModal={() =>
            setVehicleLogBookModal(!vehicleLogBookModal)
          }
          launchVehicleKeyModal={() => setVehicleKeyModal(!vehicleKeyModal)}
          launchSecurityModal={() => setSecurityGateModal(!securityGateModal)}
          Help_Center={() => {
            setHelpCenter(!helpCenter);
          }}
          searchTextOnchange={(searchValue) => {
            setSearchText({ temp: searchValue, text: searchText.text });
          }}
          onAprroveStatus={(check) =>
            setApprovedStatus({ temp: check, status: approvedStatus.status })
          }
          onCancelStatus={(check2) =>
            setCancelStatus({ temp: check2, status: cancelStatus.status })
          }
          startDateChanged={(dates) => {
            setStartDate({ temp: dates, index: startDate.index });
          }}
          endDateChanged={(dates) => {
            setEndDate({ temp: dates, index: endDate.index });
          }}
          handleRefresh={() => {
            setRefresh(!refresh);
            setSearchText({ temp: " ", text: " " });
            setApprovedStatus({
              temp: " ",
              status: " ",
            });
            setCancelStatus({
              temp: " ",
              status: " ",
            });
            setSearchCriteria({
              temp: 0,
              index: 0,
            });
            setStartDate({
              temp: startingDate,
              index: startingDate,
            });
            setEndDate({
              temp: currentDate,
              index: currentDate,
            });
          }}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, text: searchText.temp });
            setApprovedStatus({
              temp: approvedStatus.temp,
              status: approvedStatus.temp,
            });
            setCancelStatus({
              temp: cancelStatus.temp,
              status: cancelStatus.temp,
            });
            setSearchCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
            setStartDate({ temp: startDate.temp, index: startDate.temp });
            setEndDate({ temp: endDate.temp, index: endDate.temp });
          }}
          searchCriteriaOnchange={(newIndex) => {
            setSearchCriteria({ temp: newIndex, index: searchCriteria.index });
          }}
          searchCriteriaData={searchCriteriaData}
          startDate={startDate.temp}
          endDate={endDate.temp}
          setVisible={() => {
            setShowTable(true);
          }}
          AllState={states}
          setAllState={setStates}
          outerSelectedRecord={selectedRecord}
          setOuterSelectedRecord={(e: any) => {
            setSelectedRecord(e);
          }}
        />
      </div>

      <div className="px-2 mb-0.5  ">
        <VehicleRequestsDetails
          toggled={showTable}
          Datax={mainDataGridData}
          setRefreshx={() => {
            setRefresh(!refresh);
          }}
          AllState={states}
          setAllState={setStates}
          setOuterSelectedRecord={(e: any) => {
            setSelectedRecord(e);
          }}
          setVisible={() => {
            setShowTable(true);
          }}
        />
      </div>

      {
        <FormModal
          title={"Send SMS"}
          child={
            <SendSMSAlertDetails
              chooseFromDropDown={true}
              selectedRecordFromDatagrid={selectedRecord}
            />
          }
          openState={sendSMSAlertModal}
          onCancel={() => {
            setSendSMSAlertModal(false);
          }}
        />
      }
      {
        <FormModal
          title={"Transport - Vehicle Log Book"}
          child={<VehicleLogBook />}
          openState={vehicleLogBookModal}
          onCancel={() => {
            setVehicleLogBookModal(!vehicleLogBookModal);
          }}
        />
      }
      {
        <FormModal
          title={"Vehicle Key & Inspection Manager"}
          child={<VehicleKeyAndInspectionForm />}
          openState={vehicleKeyModal}
          onCancel={() => {
            setVehicleKeyModal(!vehicleKeyModal);
          }}
        />
      }
      {
        <FormModal
          title={"Transport - Vehicle Requests"}
          child={<VehicleRequestsSecurity />}
          openState={securityGateModal}
          onCancel={() => {
            setSecurityGateModal(!securityGateModal);
          }}
        />
      }
      {
        <FormModal
          title={"Popup Tips"}
          child={<Popup_Tips />}
          width={"500px"}
          openState={helpCenter}
          onCancel={() => {
            setHelpCenter(!helpCenter);
          }}
        />
      }
    </>
  );
};

export default VehicleRequests;
