import { forwardRef, Profiler, useCallback, useEffect, useState } from "react";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { Button, Form, Input, Select } from "antd";
import Datagrid_template from "../../../../templates/Datagrid";
import TextArea from "antd/es/input/TextArea";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { NewButton, SaveButton } from "../../../accessories/buttons";
import { assets_supplierColumns } from "../../../Assets-Manager/Transport/setups/vehicles/suppliers/data/suppliersData";
import InputSelect_template from "../../../../templates/InputSelect";
import { Outage_EquipmentColumns } from "./outageEquipCols";
import { TextareaTemplate } from "../../../../templates/textarea";
import { feeder } from "./tabContents/BusVoltage/_data/stationentriescolumns";
import { BottomBtns } from "../../../../templates/butttons";
import { setGeneralValue } from "../../../../features/generalSlice";
import React from "react";
import { ModalTemplate } from "../../../../templates/modal";
import { set } from "lodash";
import axios from "axios";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { PostDataFunc } from "../../../../functions/post";
import { UpdateDataFunc } from "../../../../functions/update";
import CustomLoader from "../../../accessories/custom-loader";
import useFetch from "../../../../hooks/useFetch";
import { useCrudFunc } from "../../../../functions/crud";
import { number } from "zod";

export const OutageEquipmentType = forwardRef(() => {
  const dispatch = useDispatch();
  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode: boolean = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const formCode = useSelector((state: RootState) => state.user?.formCode);
  const [BusyLoader, setBusyLoader] = useBusyLoader();
  const [switchForm, setSwitchForm] = useState<boolean>(true);
  const [OutageEquiptTypeState, setOutageEquiptTypeState] = useState<{
    Name: { id: number; name: string };
    ShortName: { id: number; name: string };
    Transformer: boolean;
    Feeder: boolean;
    Line: boolean;
    CapacitorBank: boolean;
    SVC: boolean;
    Active: boolean;
    Remark: string;
  }>({
    Name: { id: 0, name: "" },
    ShortName: { id: 0, name: "" },
    Transformer: false,
    Feeder: false,
    Line: false,
    CapacitorBank: false,
    SVC: false,
    Active: false,
    Remark: "",
  });
  const [modalContent, setModalContent] = useState<{
    icon: "warning" | "success" | "question";
    title: string;
    message: string;
    okText: string;
    cancelText: string;
    open: boolean;
    disableCancel: boolean;
    okHandler: () => void;
    cancelHandler: () => void;
  }>({
    icon: "warning",
    title: "",
    message: "",
    okText: "",
    cancelText: "",
    open: false,
    disableCancel: false,
    okHandler: () => {},
    cancelHandler: () => {},
  });
  const {
    icon,
    title,
    message,
    okText,
    cancelText,
    open,
    disableCancel,
    okHandler,
    cancelHandler,
  } = modalContent;
  const updateOutageEquipTypeState = useCallback(
    (key: string, value: string | boolean | { id: number; name: string }) => {
      setOutageEquiptTypeState((prevState) => ({ ...prevState, [key]: value }));
    },
    []
  );
  const validateFormBeforeSavingOrEditing = (method: "save" | "update") => {
    const { Transformer, Feeder, Line, CapacitorBank, SVC } =
      OutageEquiptTypeState;
    let isNameEmpty = false;
    let isShortNameEmpty = false;
    let isOutageEquipemnt = false;
    if (OutageEquiptTypeState?.Name?.name === "") {
      isNameEmpty = true;
    }
    if (OutageEquiptTypeState?.ShortName?.name === "") {
      isShortNameEmpty = true;
    }
    if (
      Transformer === false &&
      Feeder === false &&
      Line === false &&
      CapacitorBank === false &&
      SVC === false
    ) {
      isOutageEquipemnt = true;
    }
    if (isNameEmpty || isShortNameEmpty || isOutageEquipemnt) {
      setModalContent((prev) => ({
        ...prev,
        title: isNameEmpty
          ? "Name!"
          : isShortNameEmpty
          ? "Short name!"
          : "Outage Equipment!",
        message: isNameEmpty
          ? "Please enter the name"
          : isShortNameEmpty
          ? "Please enter the short name"
          : "Please select any of the outage equipments",
        okText: "Okay",
        open: true,
        disableCancel: true,
        okHandler: () => {
          setModalContent((prev) => ({ ...prev, open: false }));
        },
        cancelHandler: () => {},
      }));
    } else {
      setModalContent((prev) => ({
        ...prev,
        icon: "question",
        title: `${
          method === "save" ? "Save" : "Update"
        } outage equipment record?`,
        message: `Are you sure you want to ${
          method === "save" ? "save" : "update"
        }  this record?`,
        okText: "Yes",
        cancelText: "No, cancel",
        open: true,
        disableCancel: false,
        okHandler: () =>
          method === "save"
            ? SaveOutageEquipmentType()
            : UpdateOutageEquipmentType(),
        cancelHandler: () => {
          setModalContent((prev) => ({ ...prev, open: false }));
        },
      }));
    }
  };

  const [searchText, setSearchText] = useState<{
    initialText: string;
    text: string;
  }>({ initialText: "", text: "" });
  const [active, setActive] = useState<{
    prevActiveState: boolean | string;
    activeState: boolean | string;
  }>({ prevActiveState: "", activeState: "" });
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 50,
  });
  // const [outageEquipmentData, setOutageEquipmentData] = useState([]);
  // const [NameAndShortNameData, setNameAndShortNameData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [
    OutageEquipmentTypesData,
    OutTageEquipTypeloading,
    OutTageEquipTypeError,
  ] = useFetch(
    `OutageEquipmentType/GetOutageEquipmentTypes?SearchTerm=${searchText.text}&CheckState=${active.activeState}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`,
    refresh,
    "",
    false,
    "powerapp"
  );
  const [NameAndShortNameData, NameAndShortLoading, NameAndShortError] =
    useFetch(
      `OutageEquipmentType/RetrieveOutageEquipmentTypeNameAndShortName/name`,
      refresh,
      "",
      false,
      "powerapp"
    );
  const [postData, updateData] = useCrudFunc("powerapp");
  async function SaveOutageEquipmentType() {
    setBusyLoader("Saving record ...");
    await postData("OutageEquipmentType/AddOutageEquipmentTypes", {
      oetName: OutageEquiptTypeState.Name.name,
      oetShtName: OutageEquiptTypeState.ShortName.name,
      oetTransformer: OutageEquiptTypeState.Transformer,
      oetLine: OutageEquiptTypeState.Line,
      oetFeeder: OutageEquiptTypeState.Feeder,
      oetCapacitorBank: OutageEquiptTypeState.CapacitorBank,
      oetStaticVarCompensator: OutageEquiptTypeState.SVC,
      oetActive: OutageEquiptTypeState.Active,
      oetRmks: OutageEquiptTypeState.Remark,
      formAction: `outage equipment types was saved with
 name:${OutageEquiptTypeState.Name.name},shortName:${OutageEquiptTypeState.ShortName.name},
 transformer:${OutageEquiptTypeState.Transformer},line:${OutageEquiptTypeState.Line},feeder:${OutageEquiptTypeState.Feeder},
 capacitorBank:${OutageEquiptTypeState.CapacitorBank},SVC:${OutageEquiptTypeState.SVC},active:${OutageEquiptTypeState.Active},
 and remarks:${OutageEquiptTypeState.Remark}`,
    })
      .then((response) => {
        setModalContent((prev) => ({ ...prev, open: false }));
        setBusyLoader("");
        setRefresh(!refresh);
        setModalContent((prev) => ({
          ...prev,
          icon: "success",
          title: "Success",
          // message: "Record saved successfully!!",
          message: response?.data?.message,
          okText: "Okay",
          open: true,
          disableCancel: true,
          okHandler: () => {
            setModalContent((prev) => ({ ...prev, open: false }));
          },
          cancelHandler: () => {},
        }));
      })
      .catch((error) => {
        setBusyLoader("");
        setModalContent((prev) => ({ ...prev, open: false }));
        setModalContent((prev) => ({
          ...prev,
          icon: "warning",
          title: "Record not saved",
          // message: "Please contact the systems administrator for assistance",
          message: error?.response?.data?.message,
          okText: "Okay",
          open: true,
          disableCancel: true,
          okHandler: () => {
            setModalContent((prev) => ({ ...prev, open: false }));
          },
          cancelHandler: () => {},
        }));
      });
  }
  async function UpdateOutageEquipmentType() {
    setBusyLoader("Updating record ...");
    await updateData("OutageEquipmentType/UpdateOutageEquipmentType", {
      oetIdpk: selectedRecord?.oetIdpk,
      oetName: OutageEquiptTypeState.Name.name,
      oetShtName: OutageEquiptTypeState.ShortName.name,
      oetTransformer: OutageEquiptTypeState.Transformer,
      oetLine: OutageEquiptTypeState.Line,
      oetFeeder: OutageEquiptTypeState.Feeder,
      oetCapacitorBank: OutageEquiptTypeState.CapacitorBank,
      oetStaticVarCompensator: OutageEquiptTypeState.SVC,
      oetActive: OutageEquiptTypeState.Active,
      oetRmks: OutageEquiptTypeState.Remark,
      formAction: `outage equipment types was updated with
 name:${OutageEquiptTypeState.Name.name},shortName:${OutageEquiptTypeState.ShortName.name},
 transformer:${OutageEquiptTypeState.Transformer},line:${OutageEquiptTypeState.Line},feeder:${OutageEquiptTypeState.Feeder},
 capacitorBank:${OutageEquiptTypeState.CapacitorBank},SVC:${OutageEquiptTypeState.SVC},active:${OutageEquiptTypeState.Active},
 and remarks:${OutageEquiptTypeState.Remark}`,
    })
      .then(() => {
        setModalContent((prev) => ({ ...prev, open: false }));
        setRefresh(!refresh);
        setBusyLoader("");
        setModalContent((prev) => ({
          ...prev,
          icon: "success",
          title: "Success",
          message: "Record update successfully!!",
          okText: "Okay",
          open: true,
          disableCancel: true,
          okHandler: () => {
            setModalContent((prev) => ({ ...prev, open: false }));
          },
          cancelHandler: () => {},
        }));
      })
      .catch((error) => {
        setBusyLoader("");
        setModalContent((prev) => ({ ...prev, open: false }));
        setModalContent((prev) => ({
          ...prev,
          icon: "warning",
          title: "Record not updated",
          // message: "Please contact the systems administrator for assistance",
          message: error.response.data.message,
          okText: "Okay",
          open: true,
          disableCancel: true,
          okHandler: () => {
            setModalContent((prev) => ({ ...prev, open: false }));
          },
          cancelHandler: () => {},
        }));
      });
  }
  function UpdateStateOnDoubleClick(item: any) {
    setSelectedRecord(item);
    updateOutageEquipTypeState("Name", {
      id: item?.oetIdpk,
      name: item?.oetName,
    });
    updateOutageEquipTypeState("ShortName", {
      id: item?.oetIdpk,
      name: item?.oetShtName,
    });
    updateOutageEquipTypeState("Transformer", item?.oetTransformer);
    updateOutageEquipTypeState("Feeder", item?.oetFeeder);
    updateOutageEquipTypeState("Line", item?.oetLine);
    updateOutageEquipTypeState("CapacitorBank", item?.oetCapacitorBank);
    updateOutageEquipTypeState("SVC", item?.oetStaticVarCompensator);
    updateOutageEquipTypeState("Active", item?.oetActive);
    updateOutageEquipTypeState("Remark", item?.oetRmks);
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    setSwitchForm(true);
  }
  function UpdateStateOnRowClick(item: any) {
    setSelectedRecord(item);
    updateOutageEquipTypeState("Name", {
      id: item?.oetIdpk,
      name: item?.oetName,
    });
    updateOutageEquipTypeState("ShortName", {
      id: item?.oetIdpk,
      name: item?.oetShtName,
    });
    updateOutageEquipTypeState("Transformer", item?.oetTransformer);
    updateOutageEquipTypeState("Feeder", item?.oetFeeder);
    updateOutageEquipTypeState("Line", item?.oetLine);
    updateOutageEquipTypeState("CapacitorBank", item?.oetCapacitorBank);
    updateOutageEquipTypeState("SVC", item?.oetStaticVarCompensator);
    updateOutageEquipTypeState("Active", item?.oetActive);
    updateOutageEquipTypeState("Remark", item?.oetRmks);
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  }
  // useEffect(() => {
  //   // GetOutageEquipmentTypes();
  //   // GetNameAndShortNameForOutageEquipmentTypes();
  // }, [refresh]);
  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const resetFields = () => {
    setSelectedRecord([]);
    updateOutageEquipTypeState("Name", {
      id: 0,
      name: "",
    });
    updateOutageEquipTypeState("ShortName", {
      id: 0,
      name: "",
    });
    updateOutageEquipTypeState("Transformer", false);
    updateOutageEquipTypeState("Feeder", false);
    updateOutageEquipTypeState("Line", false);
    updateOutageEquipTypeState("CapacitorBank", false);
    updateOutageEquipTypeState("SVC", false);
    updateOutageEquipTypeState("Active", false);
    updateOutageEquipTypeState("Remark", "");
  };
  console.log(OutageEquiptTypeState);
  console.log(selectedRecord);
  return (
    <>
      {/* {outageEquipmentData.length === 0 && (
        <CustomLoader text="Fetching records..." />
      )} */}
      <ModalTemplate
        message={message}
        okHandler={okHandler}
        title={title}
        icon={icon}
        open={open}
        cancelHandler={cancelHandler}
        cancelText={cancelText}
        okText={okText}
        disableCancel={disableCancel}
      />
      <HrSetupsCustomToolbar
        outerSelected={selectedRecord}
        setOuterSelected={setSelectedRecord}
        withDates={false}
        Switch={switchForm}
        toggler={(value) => {
          setSwitchForm(value)
        }}
        searchTextOnchange={(value) => {
          setSearchText({ initialText: value, text: "" });
        }}
        checkOnChange={(value) => {
          setActive({ prevActiveState: value, activeState: "" });
        }}
        handleFind={() => {
          setSearchText({
            initialText: searchText.initialText,
            text: searchText.initialText,
          });
          setActive({
            prevActiveState: active.prevActiveState,
            activeState: active.prevActiveState,
          });
          setRefresh(!refresh);
        }}
        handleRefresh={() => {
          setSearchText({ initialText: "", text: "" });
          setActive({ prevActiveState: "", activeState: "" });
          setRefresh(!refresh);
        }}
        handleNew={() => {
          resetFields();
          setSwitchForm(true);
        }}
        handleUpdate={() => {
          setSwitchForm(true);
          // if (selectedRecord=== null|| selectedRecord===undefined||Object.keys(selectedRecord).length < 1) {
          //   dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          //   dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          //   setModalContent((prev) => ({
          //     ...prev,
          //     icon: "warning",
          //     title: "Select record!",
          //     message: "Please select record to edit",
          //     okText: "Okay",
          //     open: true,
          //     disableCancel: true,
          //     okHandler: () => {
          //       setModalContent((prev) => ({ ...prev, open: false }));
          //     },
          //     cancelHandler: () => {},
          //   }));
          // }
        }}
      />
      {switchForm && (
        <main
          style={{ borderColor: borderTheme }}
          className="w-full border-[1px] rounded-t-md  "
        >
          <header
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="border-b-[1px] font-medium text-xs mb-2 bg-slate-100 rounded-t-md py-1 px-2"
          >
            {`Outage Equipment Type Details`}
          </header>
          <Form
            name="complex-form"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            size={"small"}
            className="flex flex-row w-full  gap-[1%] py-1 pl-6"
          >
            <aside className="grid grid-cols-1 w-[458px] self-start">
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Name:`}</p>}
              >
                <div className="w-full">
                  <InputSelect_template
                    placeHolder={OutageEquiptTypeState?.Name?.name}
                    selectedValue={(e) => {
                      updateOutageEquipTypeState("Name", {
                        id: e?.oetIdpk,
                        name: e?.oetName,
                      });
                      updateOutageEquipTypeState("ShortName", {
                        id: e?.oetIdpk,
                        name: e?.oetShtName,
                      });
                    }}
                    handleSearch={(e: any) => {
                      updateOutageEquipTypeState("Name", {
                        ...OutageEquiptTypeState.Name,
                        name: e,
                      });
                      updateOutageEquipTypeState("ShortName", {
                        ...OutageEquiptTypeState.ShortName,
                        name: e,
                      });
                    }}
                    useCallFunc
                    idexpr="oetIdpk"
                    dataexp="oetName"
                    options={NameAndShortNameData}
                    style={{ width: "270px" }}
                    handleRefresh={() => {
                      updateOutageEquipTypeState("Name", {
                        ...OutageEquiptTypeState.Name,
                        name: '',
                      });
                      updateOutageEquipTypeState("ShortName", {
                        ...OutageEquiptTypeState.ShortName,
                        name: '',
                      });
                    }}
                    // disabled={formdisabled}
                  />
                  {/**width: "332px"**/}
                </div>
              </Form.Item>
              <Form.Item
                className="w-full"
                label={<p className="text-xs">{`Short Name:`}</p>}
              >
                <div className="w-full">
                  <InputSelect_template
                    placeHolder={OutageEquiptTypeState?.ShortName?.name}
                    selectedValue={(e) => {
                      updateOutageEquipTypeState("ShortName", {
                        id: e?.oetIdpk,
                        name: e?.oetShtName,
                      });
                    }}
                    useCallFunc
                    span
                    idexpr="oetIdpk"
                    dataexp="oetShtName"
                    options={NameAndShortNameData}
                    style={{ width: "299.5px" }}
                    handleSearch={(e: any) => {
                      updateOutageEquipTypeState("ShortName", {
                        ...OutageEquiptTypeState?.ShortName,
                        name: e,
                      });
                    }}
                    // style={{width: "360px"}}
                    // disabled={formdisabled}
                  />
                  {/**width: "360px"**/}
                </div>
              </Form.Item>
              {/* TODO:// REVERT BACK IF FAILED*/}
              <Form.Item label={<p>{"Transformer?"}</p>} className="w-full">
                <div className="flex space-x-[62px] w-full mb-1 mt-1">
                  <CheckboxTemlate
                    defaultValue={OutageEquiptTypeState?.Transformer}
                    handleCheck={(value) => {
                      updateOutageEquipTypeState("Transformer", value);
                    }}
                    withBorders
                  />
                  <CheckboxTemlate
                    label={"Feeder?"}
                    defaultValue={OutageEquiptTypeState?.Feeder}
                    handleCheck={(value) => {
                      updateOutageEquipTypeState("Feeder", value);
                    }}
                    withBorders
                    style={{ marginTop: "-1px" }}
                  />
                  <CheckboxTemlate
                    label={"Line?"}
                    defaultValue={OutageEquiptTypeState?.Line}
                    handleCheck={(value) => {
                      updateOutageEquipTypeState("Line", value);
                    }}
                    withBorders
                  />
                </div>
              </Form.Item>
              <Form.Item label={<p>{`Capacitor Bank?`}</p>}>
                <div className="flex space-x-[76px]  w-full my-1">
                  <CheckboxTemlate
                    defaultValue={OutageEquiptTypeState?.CapacitorBank}
                    handleCheck={(value) => {
                      updateOutageEquipTypeState("CapacitorBank", value);
                    }}
                    withBorders
                  />
                  <CheckboxTemlate
                    label={"SVC?"}
                    defaultValue={OutageEquiptTypeState?.SVC}
                    handleCheck={(value) => {
                      updateOutageEquipTypeState("SVC", value);
                    }}
                    withBorders
                    style={{ marginTop: "-1px" }}
                  />
                </div>
              </Form.Item>
              <Form.Item label={<p>{"Active?"}</p>}>
                <div className="flex justify-between w-[269px] my-1">
                  <CheckboxTemlate
                    defaultValue={OutageEquiptTypeState?.Active}
                    handleCheck={(value) => {
                      updateOutageEquipTypeState("Active", value);
                    }}
                    withBorders
                  />
                  {/* <div className="flex space-x-1"> */}
                  <BottomBtns
                    outerDisabled={formdisabled}
                    outerUpdateMode={updateMode}
                    useCallbackFunc={false}
                    onNewClick={() => {
                      resetFields();
                    }}
                    onCancelClick={() => {}}
                    updateHandler={() => {
                      validateFormBeforeSavingOrEditing("update");
                    }}
                    saveHandler={() => {
                      validateFormBeforeSavingOrEditing("save");
                    }}
                    span
                    withoutCheckbox
                  />

                  {/* </div> */}
                </div>
              </Form.Item>
            </aside>
            <aside className="w-full">
              <div className="w-full pr-2">
                <TextareaTemplate
                  label={"Remarks"}
                  placeholder={OutageEquiptTypeState?.Remark}
                  defaultValue={OutageEquiptTypeState?.Remark}
                  setContent={(value) => {
                    updateOutageEquipTypeState("Remark", value);
                  }}
                  height={150}
                  labelCol={2}
                  MarginLeft={40}
                />
              </div>
            </aside>
          </Form>
        </main>
      )}

      <Datagrid_template
        keyExpr={"oetIdpk"}
        columns={Outage_EquipmentColumns}
        onRowClick={(value) => {
          UpdateStateOnRowClick(value);
        }}
        rowDoubleClicked={(value) => {
          UpdateStateOnDoubleClick(value);
        }}
        data={OutageEquipmentTypesData}
        gridheight={600}
      />
    </>
  );
});
