/* eslint-disable react/jsx-pascal-case */

import { CheckboxTemlate } from "../../../../../templates/checkbox";
import chart from "../../../../../assets/bar-chart.png";
import reset from "../../../../../assets/refresh-small.png";
import { InputsTemplate } from "../../../../../templates/input";
import { DateTemplate } from "../../../../../templates/date";
import { SelectsTemplate } from "../../../../../templates/select";
import { RefreshButton } from "./utils";
import Datagrid_template from "../../../../../templates/Datagrid";
import { Chart } from "devextreme-react";
import { useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { useCrudFunc } from "../../../../../functions/crud";
import { UpdateStates } from "../../../../../functions/updateStates";
import dayjs, { Dayjs } from "dayjs";
import {
  currentDate,
  startingDate,
} from "../../../../accessories/component_infos";
import { CustomizableButton } from "../../../../accessories/buttons";
import { Chart_template } from "../../../../../templates/Chart";
import { chartLabels } from "../tabContents/BusVoltage/_data/stationentriescolumns";
import { RefreshButtonTemplate } from "../../../../../templates/RefreshButtonTemplate";
import { IconsContainer } from "./stationReadingsToolbar";

interface stateTypes {
  selectedStation: any;
  startDate: string;
  endDate: string;
  chartData: any[];
  plotConstants: boolean;
  chartType: {
    id: number;
    name:
      | "area"
      | "bar"
      | "bubble"
      | "candlestick"
      | "fullstackedarea"
      | "fullstackedbar"
      | "fullstackedline"
      | "fullstackedspline"
      | "fullstackedsplinearea"
      | "line"
      | "rangearea"
      | "rangebar"
      | "scatter"
      | "spline"
      | "splinearea"
      | "stackedarea"
      | "stackedbar"
      | "stackedline"
      | "stackedspline"
      | "stackedsplinearea"
      | "steparea"
      | "stepline"
      | "stock";
  };
  chartHeader: string;
  chartFooter: string;
  refresh: boolean;
}

export const ReadingsChart = () => {
  const [posting] = useCrudFunc("powerapp");
  const [states, setStates] = useState<stateTypes>({
    selectedStation: {},
    startDate: startingDate,
    endDate: currentDate,
    chartData: [],
    plotConstants: false,
    chartType: {
      id: selectOptions[0].id,
      name: selectOptions[0].value.toLowerCase() as
        | "area"
        | "bar"
        | "bubble"
        | "candlestick"
        | "fullstackedarea"
        | "fullstackedbar"
        | "fullstackedline"
        | "fullstackedspline"
        | "fullstackedsplinearea"
        | "line"
        | "rangearea"
        | "rangebar"
        | "scatter"
        | "spline"
        | "splinearea"
        | "stackedarea"
        | "stackedbar"
        | "stackedline"
        | "stackedspline"
        | "stackedsplinearea"
        | "steparea"
        | "stepline"
        | "stock",
    },
    chartFooter: "",
    chartHeader: "",
    refresh: false,
  });

  const {
    selectedStation,
    startDate,
    endDate,
    chartData,
    plotConstants,
    chartType,
    chartFooter,
    chartHeader,
    refresh,
  } = states;
  const [toggleFooter, setToggleFooter] = useState(true);
  const [toggleHeader, setToggleHeader] = useState(true);
  const [chartStation] = useFetch(
    `BusVoltages/pop_dgv_chart_station`,
    refresh,
    "",
    false,
    "powerapp"
  );

  const populateChart = async () => {
    const res = await posting(
      `BusVoltages/chart/stackedline?BusIds=${selectedStation}&StartDate=${startDate}&EndDate=${endDate}&ShowMinMaxConstants=${true}&chartType=${
        chartType.id
      }&plotMinMaxConstant=${plotConstants}`,
      {},
      `Generate ${chartType.name} chart for ${selectedStation.join(",")}`
    );
    console.log("Chart generated", res.data?.data?.chartData);
    UpdateStates("chartData", res.data?.data?.chartData, setStates);
  };

  console.log("selectedStation: ", chartData);
  return (
    <section className="w-full h-full flex space-x-3">
      {/* left section */}
      <section className="h-full w-80 px-1">
        <section className="flex space-x-2 justify-center">
          <aside>
            <div className="flex items-center space-x-1">
              <DateTemplate
                showTime
                disabled={false}
                changeDate={(date: Dayjs) => {
                  UpdateStates(
                    "startDate",
                    dayjs(date).format("YYYY-MM-DD"),
                    setStates
                  );
                }}
                selectedDate={dayjs(startDate)}
              />
              <CheckboxTemlate
                withBorders
                defaultValue={toggleHeader}
                customDisabled={false}
                setCheckboxOnchange={(toggle) => {
                  setToggleHeader(toggle);
                }}
              />
            </div>
            <div className="flex items-center space-x-1">
              <DateTemplate
                showTime
                disabled={false}
                changeDate={(date: Dayjs) => {
                  UpdateStates(
                    "endDate",
                    dayjs(date).format("YYYY-MM-DD"),
                    setStates
                  );
                }}
                selectedDate={dayjs(endDate)}
              />
              <CheckboxTemlate
                withBorders
                defaultValue={toggleFooter}
                customDisabled={false}
                setCheckboxOnchange={(toggle) => {
                  setToggleFooter(toggle);
                }}
              />
            </div>
          </aside>
          <aside className="my-0.5">
            {/* generate button */}
            <CustomizableButton
              handleCustomizableClick={() => {
                populateChart();
              }}
              classname={
                "h-full flex flex-col rounded-md cursor-pointer justify-center items-center p-2 "
              }
              customizableBtnImage={chart}
              isText
              text="Generate"
            />
            {/* <section className='h-full border-[1px] content-center px-2 rounded-md cursor-pointer'>
                            <div className='flex justify-center'><img src={chart} alt='graph' /></div>
                            <p className='text-[12px]'>Generate</p>
                        </section> */}
          </aside>
        </section>
        <SelectsTemplate
          useCallFunc
          extraWidget={false}
          idexpr={"id"}
          dataexp={"value"}
          selectStyle={{ height: "27px" }}
          disabled={false}
          options={selectOptions}
          span={true}
          useCusWidth
          cusWidth={"210px"}
          disabledCustomWidth={true}
          placeHolder={chartType?.name}
          selectedValue={(e) => {
            UpdateStates(
              "chartType",
              { id: e["id"], name: e["value"] },
              setStates
            );
          }}
        />
        <section className="flex justify-between items-center">
          <aside className="flex space-x-2 items-center border-[1px] rounded-md px-1 w-[210px]">
            <CheckboxTemlate
              withBorders
              defaultValue={plotConstants}
              customDisabled={false}
              useCallbackFunc
              setCheckboxOnchange={(e) => {
                UpdateStates("plotConstants", e, setStates);
              }}
            />
            <label className="text-[12px] text-neutral-500">
              Plot Min & Max Constants
            </label>
          </aside>
          <aside className="flex items-center space-x-1">
            <IconsContainer
              onClick={() => {
                UpdateStates("refresh", !refresh, setStates);
              }}
              url={reset}
            //   name="Midnight Conditions"
              id={"refes"}
              tooltip={"Refresh buses"}
            />
           
            {/* <CheckboxTemlate
                            withBorders
                            defaultValue={false}
                            customDisabled={false}
                        /> */}
          </aside>
        </section>
        <section>
          <Datagrid_template
            gridheight={280}
            showColumnHeaders={true}
            selectionMode="multiple"
            showSelectionCheckBox="always"
            columns={[
              {
                id: 1,
                caption: "Station",
                dataField: "stnName",
                fixed: false,
                width: 100,
              },
              {
                id: 2,
                caption: "Bus",
                dataField: "bdtName",
                fixed: false,
                width: 100,
              },
              {
                id: 2,
                dataField: "active",
                fixed: false,
                dataType: "boolean",
                width: 100,
              },
            ]}
            data={chartStation}
            disablePaging
            disableSearch
            disableGroupPanel
            selectedItemsChange={(e) => {
              const bdtIDpks = e.map((item) => item.bdtIDpk);
              console.log("selectedStation bdtIDpks", bdtIDpks);
              UpdateStates("selectedStation", [bdtIDpks], setStates);
            }}
          />
        </section>
      </section>
      <section className="h-full w-full flex-1">
        {toggleHeader && (
          <aside className="flex flex-1 space-x-1 items-center">
            <section className="flex-1">
              <InputsTemplate
                label="Chart Header"
                useCallbackFunc
                orderOnchange={(e) => {
                  UpdateStates("chartHeader", e, setStates);
                }}
                inputStyle={{ height: "25px" }}
                span={true}
                disabledStatus={false}
              />
            </section>
          </aside>
        )}
        {toggleFooter && (
          <aside className="flex flex-1 space-x-1 items-center">
            <section className="flex-1">
              <InputsTemplate
                label="Chart footer"
                useCallbackFunc
                orderOnchange={(e) => {
                  UpdateStates("chartFooter", e, setStates);
                }}
                inputStyle={{ height: "25px" }}
                span={true}
                disabledStatus={false}
              />
            </section>
          </aside>
        )}

        {/* graph area */}
        <section className="w-full h-96 border-[1px] border-sm">
          <p className="w-full flex items-center justify-center font-bold">
            {chartHeader}
          </p>
          <div className="w-full ">
            {" "}
            <Chart_template
              paletteColor={"green"}
              columns={chartLabels}
              data={chartData}
              XAxis_cols={chartLabels[0].dataField}
              YAxis_label={chartLabels[1].dataField}
              chartType={chartType.name}
              chartheight={"20rem"}
            />
          </div>
          <p className="w-full flex items-center justify-center font-bold">
            {chartFooter}
          </p>
        </section>
      </section>
    </section>
  );
};

const selectOptions = [
  { id: 0, value: "Spline" },
  { id: 1, value: "Line" },
  { id: 2, value: "Bar" },
];
