import { ReactNode, useCallback, useEffect, useRef, useState, } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import NavigationDrawer from "../components/navigaton_drawer/drawer";
import Newsticker from "../components/Newsticker/Newsticker";
import { Page404 } from "../pages/Page404/Page404";
import Sidenav from "../components/Sidenav/Sidenav";

import { pages } from "./pages";
import Login from "../pages/Login/login";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../features/generalSlice";
import SafetyScreen from "../components/Safety/safety";
import { ModalTemplate } from "../templates/modal";
import { AppModal } from "../components/abstracts/modal";

const MyRoutes = () => {
  const dispatch = useDispatch();
  const [isClicked, setClicked] = useState(true);
  const childRef = useRef<any>();
  const sideRef = useRef<any>();

  const login: boolean = useSelector((state: RootState) => state.general.loginMode);
  const modal = useSelector((state: RootState) => state.general.modalAlert);
  const userSettingShowNewsBar: boolean = useSelector(
    (state: RootState) => state.general.newsflashBarOnStartup
  );

  // UserSettings

  // this is used in the Safety.tsx file
  const [tabs, setTabs] = useState<any>([]);

  const element = (child_element: ReactNode) => {
    window.onresize = (event) => {
      childRef.current?.test();
      sideRef.current?.resizeSide();
    };

    return (
      <>
        {/* drawer with page content */}
        <NavigationDrawer drawer_component={child_element} />
      </>
    );
  };

  // *************************************************************************************************

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "SideAlert", value: !isClicked }));
  }, [isClicked]);

  dispatch(setGeneralValue({ expr: "newsflashHeight", value: 60 }));

  const toggleRightNav = useCallback(() => {
    setClicked((prevClicked) => !prevClicked);
  }, []);


  // **************************************************************************************************************

  return (
    <>
      <ModalTemplate
        disableCancel
        title={modal.title}
        message={modal.message}
        open={modal.state}
        cancelHandler={() => {
          dispatch(
            setGeneralValue({
              expr: "modalAlert",
              value: { state: false, title: "", message: "" },
            })
          );
        }}
        okHandler={() => {
          dispatch(
            setGeneralValue({
              expr: "modalAlert",
              value: { state: false, title: "", message: "" },
            })
          );
        }}
      />

      <BrowserRouter>
        {/* NAVBAR */}
        {login === true && (
          <Navbar
            tabs={tabs}
            setTabs={setTabs}
            toggleRightNav={toggleRightNav}
            setClicked={setClicked}
          />
        )}

        {/* <AppModal /> */}

        {login === true && (
          <Sidenav
            isOpened={isClicked}
            setIsOpened={setClicked}
            ref={sideRef}
            alertClicked={sideRef.current?.resizeSide()}
          />
        )}
        <Routes>
        {/* login screen route */}
          <Route element={<Login />} path={"/"} /> 
          {pages.map(({ component: Component, path, selected }) => {
            return (
              <>
                <Route
                  key={path}
                  element={element(
                    <SafetyScreen
                      selected={`${selected}`}
                      tabs={tabs}
                      setTabs={setTabs}
                      UserPage={Component}
                    />
                  )}
                  path={path}
                />
              </>
            );
          })}
          {/* </Route> */}
          <Route element={<Page404 />} />
        </Routes>
        {login === true && userSettingShowNewsBar === true && <Newsticker />}
      </BrowserRouter>
    </>
  );
};

export default MyRoutes;
