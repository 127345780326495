import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";

interface apiStates {
    departData: any | null;
    sectionData: any | null;
    unitData: any | null;
    transData: any | null;
    locationData: any | null;
    
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {
    departData: [],
    sectionData: [],
    unitData: [],
    transData: [],
    locationData: [],
    
    isLoading: false,
    isPending: false,
    isError: false,
};

// Thunks for api Calls
export const fetchDept = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "dept/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleAssignment/GetDepartment?pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchSection = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "section/fetch",
    async (search: string, params:any) => {
        const res = await GetDataFunc(`AssetTamVehicleAssignment/GetAllSection?DptId=${params?.department.id}&dptActive=${params?.active}&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchUnit = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "unit/fetch",
    async (search: string, params:any) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopUnitsAssetTamVehicleDetails?dptname=${params?.department.name}&sxnname=${params?.section.name}&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchTransport = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "transport/fetch",
    async (search: string, params:any) => {
        const res = await GetDataFunc(`AssetTamVehicleAssignment/GetAllTransportUnit?dptId=${params?.department.id}&sxnId=${params?.section.id}&untId=${params?.unit.id}&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchLocation = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "location/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleAssignment/GetLocation?pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);




const vehAssignSlice = createSlice({
    name: 'vehAssign',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchDept
            .addCase(fetchDept.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDept.fulfilled, (state, action) => {
                state.isLoading = false;
                state.departData = action.payload;
            })
            .addCase(fetchDept.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchSection
            .addCase(fetchSection.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSection.fulfilled, (state, action) => {
                state.isLoading = false;
                state.unitData = action.payload;
            })
            .addCase(fetchSection.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchUnit
            .addCase(fetchUnit.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUnit.fulfilled, (state, action) => {
                state.isLoading = false;
                state.transData = action.payload;
            })
            .addCase(fetchUnit.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchTransport
            .addCase(fetchTransport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTransport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.transData = action.payload;
            })
            .addCase(fetchTransport.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchLocation
            .addCase(fetchLocation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchLocation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.transData = action.payload;
            })
            .addCase(fetchLocation.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            
            
    },
});

export default vehAssignSlice.reducer;
