import React from "react";
import TabsPanel_template from "../../../../../../templates/tabsPanel_template";
import Share from "../../../../LegalMgmt/TaskManagement/widget/Otherside/Share";
import Attach from "../../../../LegalMgmt/TaskManagement/widget/Otherside/Attach";
import Comments from "../../../../LegalMgmt/TaskManagement/widget/Otherside/Comments";

export function Others() {
  const tabs: { id: number, title: string, contents: any }[] = [
    { id: 0, title: "Share With", contents: <Share/> },
    { id: 1, title: "Comment", contents: <Comments /> },
    { id: 2, title: "Attach", contents: <Attach/> },

  ]
  return (
    <div>
      <TabsPanel_template tabs={tabs} />
    </div>
  );
}