/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DateTemplate } from "../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../templates/select";
import {
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../accessories/buttons";
import { InputsTemplate } from "../../../../../../templates/input";
import Datagrid_template from "../../../../../../templates/Datagrid";
import MiniToolbar from "../../_widget/minitollbar";
import { Switcher } from "../../_widget/switcher";
import {
  equipCols,
  equipData,
} from "../EquipmentOutage/data/equipmentOutageGvData";
import { useGraphSwitcher, useSwitcher } from "../../db/useSwitcher";
import { ReadingsChart } from "../../_widget/readingschart";
import useFetch from "../../../../../../hooks/useFetch";
import {
  modalPropTypes,
  modalTypes,
  searchTextTypes,
  statusTypes,
  validateInstanceTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useCrudFunc } from "../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import {
  currentDate,
  dbDateFormat,
  saveModalData,
  useModalData,
} from "../../../../../accessories/component_infos";
import { update } from "lodash";
import { ModalTemplate } from "../../../../../../templates/modal";
import dayjs from "dayjs";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { useAxiosService } from "../../../../../../general/requestConfig";
import EquipmentToolbar from "../EquipmentOutage/equipment-toolbar";
import EquimentDataTable from "../EquipmentOutage/equipment-data-table";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { AxiosError } from "axios";
import { parseLower } from "../../../widgets/utils";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { DropdownGrid_template } from "../../../../../../templates/DropdownGrid";
import { RootState } from "../../../../../../app/store";
import reset from "../../../../../../assets/refresh-small.png";
import { backgroundColor } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";
import { energyReadingCol } from "./data/energyReadingData";
import EnergyMeterToolbar from "./energymeterToolbar";
import { stringify } from "querystring";
import { UpdateStates } from "../../../../../../functions/updateStates";
interface stateTypes {
  date: string;
  station: { id: any; name: string };
  meter: { id: any; name: string };
  currentActiveEnergy: number;
  previousActiveEnergy: number;
  activeEnergyLossOfRegAdjust: number;
  currentReactiveEnergy: number;
  previousReactiveEnergy: number;
  reactiveEnergyLossOfRegAdjust: number;
  maximumDemand: number;
  active: boolean;
  remarks: string;
  selectedRecordId: number;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
  selectedRecord: any;
  endOfPeriodReading: boolean;
  searchText: searchTextTypes;
}

export const reportingStation_column = [
  {
    id: 0,
    caption: "Station",
    dataField: "stnName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Location",
    dataField: "locName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "Area",
    dataField: "areName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: null,
  },
];

export const reportingMeter_column = [
  {
    id: 0,
    caption: "Meter No",
    dataField: "mtrMeterNo",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Serial No",
    dataField: "mtrSerialNo",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "Feeder",
    dataField: "fdrName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "Customer",
    dataField: "custName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "Multiplier",
    dataField: "emaKwMultiplier",
    alignment: "left",
    fixed: true,
    width: null,
  },
];

export const EnergyMeterReading = () => {
  const startingDate = dayjs()
    .subtract(1, "years")
    .startOf("month")
    .format(dbDateFormat);
  const EndDate = dayjs().endOf("month").format(dbDateFormat);

  const { status } = useGraphSwitcher();
  const { status: switchStatus, setWitch, setWitchFalse } = useSwitcher();
  const recordSwitch = useRef<boolean>();

  useEffect(() => {
    // when switch changes, store the current switch status inside `recordSwitch` ref, this is to prevent state loss when there are any re-renders
    recordSwitch.current = switchStatus;
  }, [switchStatus]);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const formDisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const validateEditBtn = useSelector(
    (state: RootState) => state.general.validateEditBtn
  );
  const reduxText = useSelector((state: RootState) => state.general.reduxText);
  const query = useAxiosService("powerapp");
  const [_, userId] = useAccountInfo();
  const [modal, setModal] = useModalData();
  const [refresh, setrefresh] = useState<boolean>(false);
  // const [searchText, setSearchText] = useState<{ temp: string; index: string }>(
  //   { temp: "", index: "" }
  // );
  // console.log("searchText", searchText);
  const [states, setStates] = useState<stateTypes>({
    date: `${dayjs().format()}`,
    station: { name: "", id: 0 },
    meter: { name: "", id: 0 },

    currentActiveEnergy: 0,
    previousActiveEnergy: 0,
    activeEnergyLossOfRegAdjust: 0,
    currentReactiveEnergy: 0,
    previousReactiveEnergy: 0,
    reactiveEnergyLossOfRegAdjust: 0,
    maximumDemand: 0,
    active: false,
    endOfPeriodReading: true,
    remarks: "",
    selectedRecordId: undefined,
    confirmModal: {
      state: false,
      title: "",
      message: "",
      action: "post",
      okText: "",
    },
    searchText: { temp: "", text: "" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
    selectedRecord: [],
  });
  console.log("states", states);
  // const[searchStates,setSearchStates]=useState({
  //   searchTerm: '',
  //   checkState: null,
  //   searchStartDate:'',
  //   searchEndDate:'',
  // })

  const toolbarRef = useRef<any>({
    searchText: "",
    startDate: startingDate,
    endDate: dayjs().format(dbDateFormat),
    activeStatus: "",
  });

  const populateFields = (clear?: boolean) => {
    clear
      ? dispatch(setGeneralValue({ expr: "validateEditBtn", value: true }))
      : "";
    const gridState = [
      {
        key: "date",
        value: clear
          ? `${dayjs().format()}`
          : states.selectedRecord?.smrDate ?? `${dayjs().format()}`,
      },
      {
        key: "station",
        value: clear
          ? { name: "", id: 0 }
          : {
              name: states.selectedRecord?.stnName ?? "",
              id: states.selectedRecord?.stnIDpk ?? 0,
            },
      },
      {
        key: "meter",
        value: clear
          ? { name: "", id: 0 }
          : {
              name: states.selectedRecord?.mtrMeterNo ?? "",
              id: states.selectedRecord?.emaIDpk ?? 0,
            },
      },
      {
        key: "currentActiveEnergy",
        value: clear ? 0 : states.selectedRecord?.smrCurrentActiveEnergy ?? 0,
      },
      {
        key: "previousActiveEnergy",
        value: clear ? 0 : states.selectedRecord?.smrPreviousActiveEnergy ?? 0,
      },
      {
        key: "activeEnergyLossOfRegAdjust",
        value: clear
          ? 0
          : states.selectedRecord?.smrActiveEnergyLossOfRegistration ?? 0,
      },
      {
        key: "currentReactiveEnergy",
        value: clear ? 0 : states.selectedRecord?.smrCurrentReactiveEnergy ?? 0,
      },
      {
        key: "previousReactiveEnergy",
        value: clear
          ? 0
          : states.selectedRecord?.smrPreviousReactiveEnergy ?? 0,
      },
      {
        key: "reactiveEnergyLossOfRegAdjust",
        value: clear
          ? 0
          : states.selectedRecord?.smrReactiveEnergyLossOfRegistration ?? 0,
      },
      {
        key: "maximumDemand",
        value: clear ? 0 : states.selectedRecord?.smrMaximumDemand ?? 0,
      },
      {
        key: "endOfPeriodReading",
        value: clear ? true : states.selectedRecord?.smrForBilling ?? true,
      },
      {
        key: "remarks",
        value: clear ? "" : states.selectedRecord?.smrRmks ?? "",
      },
    ];
    for (let instance of gridState) updateState(instance.key, instance.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [states.selectedRecord]);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string>(
    dayjs(startingDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(EndDate);

  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [station, setStation] = useState<any[]>([]);
  const [meter, setMeter] = useState<any[]>([]);

  const [refreshView, setRefreshView] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    number: 1,
    size: 20,
  });

  const [energyReadingData] = useFetch(
    `EnergyMeterReadings/PopGdcMeterReadings?SearchText=${toolbarRef.current.searchText}&StartDate=${toolbarRef.current.startDate}&EndDate=${toolbarRef.current.endDate}&Active=${toolbarRef.current.activeStatus}&PageNumber=${pagination.number}&PageSize=${pagination.size}&SearchCriteria=0`,
    // `EnergyMeterReadings/PopGdcMeterReadings?SearchText=${toolbarRef.current.searchText}&StartDate=${startDate}&EndDate=${endDate}&Active=${activeStatus.status}&PageNumber=${pagination.number}&PageSize=${pagination.size}&SearchCriteria=0`,
    refreshView,
    undefined,
    undefined,
    "powerapp"
  );
  const [activePowerEnergyConsumed, setActivePowerEnergyConsumed] =
    useState<any>();
  const [reactivePowerEnergyConsumed, setReactivePowerEnergyConsumed] =
    useState<any>();

  console.log("energyReadingData", energyReadingData);
  console.log("activePowerEnergyConsumed", activePowerEnergyConsumed);
  console.log("reactivePowerEnergyConsumed", reactivePowerEnergyConsumed);
  const [rowData, setRowData] = useState<Record<string, any>>();
  console.log("reduxText", reduxText);
  // useEffect(() => {
  //   query({
  //     url: `EnergyMeterReadings/PopGdcMeterReadings`,
  //     params: {
  //       SearchText:  states.searchText.text,
  //       StartDate: startDate,
  //       EndDate: endDate,
  //       Active: activeStatus.status,
  //       PageNumber: pagination.number,
  //       PageSize: pagination.size,
  //       SearchCriteria: 0,
  //     },
  //   }).then((response) => {
  //     setEnergyReadingData(response.data);
  //   });
  // }, [startDate, endDate, activeStatus, pagination, refreshView]);

  useEffect(() => {
    query({
      url: `EnergyMeterReadings/PopLueMrdStations`,
    }).then((response) => {
      setStation(response.data);
    });
  }, []);

  useEffect(() => {
    query({
      url: `EnergyMeterReadings/PopLueMrdMeters`,
      params: {
        dtpMrdDate: states.date,
        stationIDfk: states?.station?.id,
      },
    }).then((response) => {
      setMeter(response.data);
    });
  }, [states?.station?.id, states?.date]);

  useEffect(() => {
    query({
      url: `EnergyMeterReadings/CalculateMrdActivePowerEnergyConsumed`,
      params: {
        MeterId: states.meter.id,
        MrdDate: states.date,
      },
    }).then((response) => {
      setActivePowerEnergyConsumed(response.data);
    });
  }, [states.meter.id, states.date, states.selectedRecord]);

  useEffect(() => {
    query({
      url: `EnergyMeterReadings/CalculateMrdReactivePowerEnergyConsumed`,
      params: {
        MrdMeterID: states.meter.id,
        MrdDate: states.date,
      },
    }).then((response) => {
      setReactivePowerEnergyConsumed(response.data);
    });
  }, [states.meter.id, states.date, states.selectedRecord]);

  useEffect(() => {}, [switchStatus]);

  // async function DeleteEquipmentOutage() {
  //   if (!states.selectedRecordId) {
  //     setModal({
  //       state: true,
  //       title: "Delete Energy Meter Reading",
  //       message: `Please select a record to delete`,
  //       icon: "warning",
  //       okText: "Ok",
  //       cancelText: "No, Cancel",
  //       disableCancel: true,
  //       okHandler: () => {
  //         setModal((prev) => ({
  //           ...prev,
  //           state: false,
  //         }));
  //       },
  //       cancelHandler: () => {},
  //     });
  //     return;
  //   }
  //   const canDelete = await hasEditPriviledge();
  //   if (!canDelete) return;
  //   setModal({
  //     state: true,
  //     title: !!rowData?.eotActive
  //       ? "Delete Energy Meter Reading"
  //       : "Reactivate Energy Meter Reading",
  //     message: !!rowData?.eotActive
  //       ? `Are you sure you want to delete the selected record?`
  //       : `Are you sure you want to REACTIVATE the selected Energy Meter Reading record?`,
  //     icon: "question",
  //     okText: "Yes",
  //     cancelText: "No",
  //     disableCancel: false,
  //     okHandler: async () => {
  //       query({
  //         url: `EquipmentOutage/DeleteEquipmentOutage`,
  //         method: `PUT`,
  //         data: {
  //           id: states.selectedRecordId,
  //           active: !!rowData?.eotActive ? false : true,
  //           usaIDpk: userId,
  //           formAction: !!rowData?.eotActive
  //             ? `Delete Energy Meter Reading with ID: ${states.selectedRecordId}, by ${userId} on ${currentDate}`
  //             : `Reactivate Energy Meter Reading with ID: ${states.selectedRecordId}, by ${userId} on ${currentDate}`,
  //         },
  //       })
  //         .then((res) => {
  //           if (!!res?.data) {
  //             setModal({
  //               state: true,
  //               title: !!rowData?.eotActive
  //                 ? "Delete Energy Meter Reading"
  //                 : "Reactivate Energy Meter Reading",
  //               message: `Record updated successfully`,
  //               icon: "success",
  //               okText: "Ok",
  //               cancelText: "No, Cancel",
  //               disableCancel: true,
  //               okHandler: () => {
  //                 setModal((prev) => ({
  //                   ...prev,
  //                   state: false,
  //                 }));
  //               },
  //               cancelHandler: () => {},
  //             });
  //             setRefreshView((prev) => !prev);
  //           } else {
  //             setModal({
  //               state: true,
  //               title: "Delete Energy Meter Reading",
  //               message: `An error occured while deleting the record`,
  //               icon: "warning",
  //               okText: "Ok",
  //               cancelText: "No, Cancel",
  //               disableCancel: true,
  //               okHandler: () => {
  //                 setModal((prev) => ({
  //                   ...prev,
  //                   state: false,
  //                 }));
  //               },
  //               cancelHandler: () => {},
  //             });
  //           }
  //         })
  //         .catch((error) => {
  //           setModal({
  //             state: true,
  //             title: "Delete Energy Meter Reading",
  //             message: `An error occured while deleting the record`,
  //             icon: "warning",
  //             okText: "Ok",
  //             cancelText: "No, Cancel",
  //             disableCancel: true,
  //             okHandler: () => {
  //               setModal((prev) => ({
  //                 ...prev,
  //                 state: false,
  //               }));
  //             },
  //             cancelHandler: () => {},
  //           });
  //         });
  //     },
  //     cancelHandler: () => {
  //       setModal((prev) => ({
  //         ...prev,
  //         state: false,
  //       }));
  //     },
  //   });
  // }

  //validate fields
  const validateFields = (): boolean => {
    const validationInstances: { 
      state: any; 
      baseValue: any; 
      modalProps: { state: boolean; title: string; message: string }; 
      isNumeric?: boolean 
    }[] = [
      {
        state: states.date,
        baseValue: "",
        modalProps: {
          state: true,
          title: "Enter Date",
          message: "Please select date",
        },
      },
      {
        state: states.station?.id || states.station?.name,
        baseValue: 0 || "",
        modalProps: {
          state: true,
          title: "Enter Station",
          message: "Please select a station",
        },
      },
      {
        state: states.meter?.id || states.meter?.name,
        baseValue: 0 || "",
        modalProps: {
          state: true,
          title: "Enter Meter",
          message: "Please select a meter",
        },
      },
      {
        state: states.currentActiveEnergy,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Current Active Energy (kWh)",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
      {
        state: states.previousActiveEnergy,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Previous Active Energy (kWh)",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
      {
        state: states.activeEnergyLossOfRegAdjust,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Active Energy Loss of Reg./Adjust",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
      {
        state: states.currentReactiveEnergy,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Current Reactive Energy (kVArh)",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
      {
        state: states.previousReactiveEnergy,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Previous Reactive Energy (kVArh)",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
      {
        state: states.reactiveEnergyLossOfRegAdjust,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Reactive Energy Loss of Reg./Adjust",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
      {
        state: states.maximumDemand,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter Maximum Demand (kW)",
          message: "Please fill in the details",
        },
        isNumeric: true,
      },
    ];
  
    for (let instance of validationInstances) {
      // Check for empty state
      if (
        instance.state === instance.baseValue ||
        instance.state === undefined
      ) {
        const { modalProps } = instance;
        updateState("warningModal", {
          state: modalProps.state,
          title: modalProps.title,
          message: modalProps.message,
        });
        return false;
      }
      // Check for numeric value if isNumeric flag is true
      if (
        instance.isNumeric &&
        (isNaN(Number(instance.state?.toString())) )
      ) {
        updateState("warningModal", {
          state: true,
          title: "Invalid Input",
          message: "Input should be a number.",
        });
        return false;
      }
    }
  
    return true;
  };

  const dataFields = [
    {
      id: 0,
      label: "Date & Time",
      optionsData: [],
      stateName: "date",
      idExpr: "",
      dataExpr: "",
      defaultValue: states?.date,
    },
    {
      id: 1,
      label: "Station",
      optionsData: station ?? [],
      columns: [],
      stateName: "station",
      idExpr: "stnIDpk",
      dataExpr: "stnName",
      defaultValue: states?.station?.name,
    },
    {
      id: 2,
      label: "Meter",
      optionsData: meter ?? [],
      stateName: "meter",
      idExpr: "emaIDpk",
      dataExpr: "mtrMeterNo",
      defaultValue: states?.meter?.name,
    },
    {
      id: 3,
      label: "Current Active Energy (kWh)",
      optionsData: [],
      stateName: "currentActiveEnergy",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.currentActiveEnergy?.toString(),
    },
    {
      id: 4,
      label: "Previous Active Energy (kWh)",
      optionsData: [],
      stateName: "previousActiveEnergy",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.previousActiveEnergy?.toString(),
    },
    {
      id: 5,
      label: "Active Energy Loss of Reg./Adjust",
      optionsData: [],
      stateName: "activeEnergyLossOfRegAdjust",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.activeEnergyLossOfRegAdjust?.toString(),
    },
    {
      id: 6,
      label: "Current Reactive Energy (kVArh)",
      optionsData: [],
      stateName: "currentReactiveEnergy",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.currentReactiveEnergy?.toString(),
    },
    {
      id: 7,
      label: "Previous Reactive Energy (kVArh)",
      optionsData: [],
      stateName: "previousReactiveEnergy",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.previousReactiveEnergy?.toString(),
    },
    {
      id: 8,
      label: "Reactive Energy Loss of Reg./Adjust",
      optionsData: [],
      stateName: "reactiveEnergyLossOfRegAdjust",
      idExpr: "objId",
      dataExpr: "name",
      defaultValue: states?.reactiveEnergyLossOfRegAdjust?.toString(),
    },
    {
      id: 9,
      label: "Maximum Demand (kW)",
      stateName: "maximumDemand",
      idExpr: "objId",
      defaultValue: states?.maximumDemand?.toString(),
    },
    {
      id: 10,
      label: "Remarks",
      stateName: "remarks",
      defaultValue: states?.remarks,
    },
  ];

  const disableConfirmModal = () => {
    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
      action: "post",
    });
  };

  const enableSuccessModal = (
    titleActionText: string,
    messageActionText: string
  ) => {
    updateState("successModal", {
      state: true,
      title: `${titleActionText} Successful`,
      message: `Record ${messageActionText} successfully`,
    });
  };
  const [activePowerEnergyConsumedx, setActivePowerEnergyConsumedx] =
    useState<number>();
  const [reactivePowerEnergyConsumedx, setReactivePowerEnergyConsumedx] =
    useState<number>();
  console.log("activePowerEnergyConsumedx", activePowerEnergyConsumedx);
  console.log("reactivePowerEnergyConsumedx", reactivePowerEnergyConsumedx);
  ///////Active//////
  useEffect(() => {
    if (activePowerEnergyConsumed && activePowerEnergyConsumed.length > 0) {
      const currentActiveEnergy = states.currentActiveEnergy;
      const previousActiveEnergy = states.previousActiveEnergy;
      const energyLossAdjust = states.activeEnergyLossOfRegAdjust;
      const billPercent = activePowerEnergyConsumed[0].emaBillPercent ?? 0;
      const kwMultiplier = activePowerEnergyConsumed[0].emaKwMultiplier ?? 0;

      // Debugging logs
      console.log("Current Active Energy:", currentActiveEnergy);
      console.log("Previous Active Energy:", previousActiveEnergy);
      console.log("Energy Loss Adjustment:", energyLossAdjust);
      console.log("Bill Percent:", billPercent);
      console.log("KW Multiplier:", kwMultiplier);

      const initialDifference = currentActiveEnergy - previousActiveEnergy;
      const percentAdjustment = (initialDifference * billPercent) / 100;
      const multipliedAdjustment = percentAdjustment * kwMultiplier;
      const finalDifference = multipliedAdjustment + energyLossAdjust;

      console.log("Initial Difference:", initialDifference);
      console.log("Percent Adjustment:", percentAdjustment);
      console.log("Multiplied Adjustment:", multipliedAdjustment);
      console.log("Final Difference:", finalDifference);

      setActivePowerEnergyConsumedx(finalDifference);
    } else {
      setActivePowerEnergyConsumedx(0);
    }
  }, [
    states.currentActiveEnergy,
    states.previousActiveEnergy,
    states.activeEnergyLossOfRegAdjust,
    activePowerEnergyConsumed,
  ]);

  //////Reactive//////
  useEffect(() => {
    if (reactivePowerEnergyConsumed && reactivePowerEnergyConsumed.length > 0) {
      const currentreactiveEnergy = states.currentReactiveEnergy;
      const previousreactiveEnergy = states.previousReactiveEnergy;
      const energyLossAdjust = states.reactiveEnergyLossOfRegAdjust;
      const billPercent = reactivePowerEnergyConsumed[0].emaBillPercent ?? 0;
      const kwMultiplier = reactivePowerEnergyConsumed[0].emaKwMultiplier ?? 0;

      // Debugging logs
      console.log("currentreactiveEnergy:", currentreactiveEnergy);
      console.log("previousreactiveEnergy:", previousreactiveEnergy);
      console.log("Energy Loss Adjustment:", energyLossAdjust);
      console.log("Bill Percent:", billPercent);
      console.log("KW Multiplier:", kwMultiplier);

      const initialDifference = currentreactiveEnergy - previousreactiveEnergy;
      const percentAdjustment = (initialDifference * billPercent) / 100;
      const multipliedAdjustment = percentAdjustment * kwMultiplier;
      const finalDifference = multipliedAdjustment + energyLossAdjust;

      console.log("Initial Difference:", initialDifference);
      console.log("Percent Adjustment:", percentAdjustment);
      console.log("Multiplied Adjustment:", multipliedAdjustment);
      console.log("Final Difference:", finalDifference);

      setReactivePowerEnergyConsumedx(finalDifference);
    } else {
      setReactivePowerEnergyConsumedx(0);
    }
  }, [
    states.currentReactiveEnergy,
    states.previousReactiveEnergy,
    states.reactiveEnergyLossOfRegAdjust,
    reactivePowerEnergyConsumed,
  ]);

  //post data
  const [posting, updating] = useCrudFunc("powerapp");

  const [busyLoader, setBusyLoader] = useBusyLoader();

  // useEffect(() => {
  //   stationDataLoading ? setBusyLoader("") : setBusyLoader("");
  // }, [stationDataLoading]);

  //create
  const postData = async () => {
    try {
      const postResponse = await posting(
        `EnergyMeterReadings/AddEnergyMeterReading`,
        {
          smrDate: states.date,
          smrMeterAssignmentIDfk: states.meter.id,
          smrCurrentActiveEnergy: states.currentActiveEnergy,
          smrPreviousActiveEnergy: states.previousActiveEnergy,
          smrActiveEnergyLossOfRegistration: states.activeEnergyLossOfRegAdjust,
          smrCurrentReactiveEnergy: states.currentReactiveEnergy,
          smrPreviousReactiveEnergy: states.previousReactiveEnergy,
          smrReactiveEnergyLossOfRegistration:
            states.reactiveEnergyLossOfRegAdjust,
          smrReactiveEnergyConsumed: reactivePowerEnergyConsumedx,
          smrMaximumDemand: states.maximumDemand,
          smrActiveEnergyConsumed: activePowerEnergyConsumedx,
          smrForBilling: states.endOfPeriodReading,
          formCode: "blsStationMeterReadings",
          smrRmks: states.remarks,
        },
        `Created Energy Meter Detail`
      );
      setRefreshView((prev) => !prev);
      setrefresh(!refresh);
      // setRefresh(!refresh);
      // setsuccesModalState({
      //   state: true,
      //   message: "Record saved successfully",
      //   title: "Save Successful",
      // });
      // setBusyLoader("");
      // populateFromGrid(false);
      setrefresh(!refresh);
      updateState("confirmModal", {
        state: false,
        title: "string",
        message: "string",
      });
      updateState("successModal", {
        state: true,
        title: `Save Successful`,
        message: `Record saved successfully`,
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      //  populateStatesFromGrid(false);
    } catch (error) {
      updateState("confirmModal", {
        state: false,
        title: "string",
        message: "string",
      });
      updateState("warningModal", {
        state: true,
        title: "Unexpected error",
        message: "Failed to save record",
      });
      console.log("error", error);
    }
  };

  const updateData = async (action: "validate" | "update") => {
    try {
      const postResponse = await updating(
        `EnergyMeterReadings/UpdateEnergyMeterReading`,
        {
          smrIDpk: states.selectedRecord?.smrIDpk,
          smrDate: states.date,
          smrMeterAssignmentIDfk: states.meter.id,
          smrCurrentActiveEnergy: states.currentActiveEnergy,
          smrPreviousActiveEnergy: states.previousActiveEnergy,
          smrActiveEnergyLossOfRegistration: states.activeEnergyLossOfRegAdjust,
          smrCurrentReactiveEnergy: states.currentReactiveEnergy,
          smrPreviousReactiveEnergy: states.previousReactiveEnergy,
          smrReactiveEnergyLossOfRegistration:
            states.reactiveEnergyLossOfRegAdjust,
          smrReactiveEnergyConsumed: reactivePowerEnergyConsumedx,
          smrMaximumDemand: states.maximumDemand,
          smrActiveEnergyConsumed: activePowerEnergyConsumedx,
          smrForBilling: states.endOfPeriodReading,
          smrRmks: states.remarks,
          formCode: "blsStationMeterReadings",
        },
        `Updated Energy Meter Detail`
      );
      setrefresh(!refresh);
      setRefreshView((prev) => !prev);
      setAllowUpdate(false);
      updateState("confirmModal", {
        state: false,
        title: "string",
        message: "string",
      });
      updateState("successModal", {
        state: true,
        title: `Update Successful`,
        message: `Record updated successfully`,
      });
    } catch (error) {
      console.log(error);
      updateState("confirmModal", {
        state: false,
        title: "string",
        message: "string",
      });
      updateState("warningModal", {
        state: true,
        title: "Unexpected error",
        message: "Failed to updated record",
      });
    } finally {
      setBusyLoader("");
    }
    return;
  };
  const validateEdit = () => {
    if (validateEditBtn || states.selectedRecord?.length === 0) {
      updateState("warningModal", {
        state: true,
        title: "No record selected",
        message: "Please select a record to update",
      });
      setWitchFalse();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      setWitchFalse();
    }
  };
  const DeleteMeterReading = () => {
    if (states.selectedRecord?.smrActive === true) {
      updateState("confirmModal", {
        state: true,
        title: "Deactive record?",
        message: "Are you sure you want to deactive this record?",
        okText: "Delete",
        action: "delete",
      });
    } else {
      updateState("confirmModal", {
        state: true,
        title: "Reactivate record?",
        message: "Are you sure you want to reactivate this record?",
        okText: "Reactivate",
        action: "delete",
      });
    }
  };

  const saveMeterReading = () => {
    validateFields() &&
      updateState("confirmModal", {
        state: true,
        title: "Save record?",
        message: "Are you sure you want to save this record?",
        okText: "Save",
        action: "post",
      });
  };
  const updateMeterReading = () => {
    validateFields() &&
      updateState("confirmModal", {
        state: true,
        title: "Update Record",
        message: "Are you sure you want to update this record?",
        action: "update",
      });
  };
  const deleteMeterReading = async () => {
    try {
      await updating(
        `EnergyMeterReadings/MrdDeleteOrReActivate?smrIDpk=${states.selectedRecord?.smrIDpk}&activeState=${states.selectedRecord?.smrActive}&FormCode=blsStationMeterReadings&FormAction= Deleted energy meter reading`,
        {}
      );
      setRefreshView((prev) => !prev);
      updateState("confirmModal", {
        state: false,
        title: "string",
        message: "string",
      });

      if (states.selectedRecord?.smrActive === true) {
        updateState("successModal", {
          state: true,
          title: "Record deleted!",
          message: "Record deleted successfully",
        });
      } else {
        updateState("successModal", {
          state: true,
          title: "Record reactivated!",
          message: "Record reactivated successfully",
        });
      }
    } catch (error: any) {
      updateState("warningModal", {
        state: true,
        title: "Unexpected error",
        message: "Failed to deactive meter data",
      });
    }

    return;
  };
  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: states.warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.warningModal.message,
      okText: "Ok",
      title: states.warningModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: states.confirmModal.state,
      okHandler:
        states.confirmModal.action === "update"
          ? () => updateData("update")
          : states.confirmModal.action === "post"
          ? () => postData()
          : states.confirmModal.title === "Deactive record?" ||
            "Reactivate record?"
          ? () => deleteMeterReading()
          : null,
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText:
        states.confirmModal.action === "update"
          ? "Yes, Update"
          : states.confirmModal.title === "Deactive record?"
          ? "Delete"
          : states.confirmModal.title === "Reactivate record?"
          ? "Reactivate"
          : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    //success
    {
      disableCancel: true,
      icon: "success",
      open: states.successModal.state,
      okHandler: () =>
        updateState("successModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.successModal.message,
      okText: "Ok",
      title: states.successModal.title,
      cancelText: "No, cancel",
    },
  ];

  const dispatch = useDispatch();

  return (
    <>
      <ModalTemplate
        width={400}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}
      <div className="w-full h-full p-2">
        <Switcher alternate={""}>
          <div className="flex w-full">
            {/* leftSide */}
            <aside className="z-[1]">
              <Form
                className="h-full dark:bg-slate-900 dark:text-darkModeSkin-base rounded"
                name="basic"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
                layout="horizontal"
              >
                {dataFields
                  .filter((f) => [0, 1, 2, 3, 4, 5].includes(f.id))
                  .map(
                    ({
                      id,
                      label,
                      defaultValue,
                      optionsData,
                      idExpr,
                      dataExpr,
                      stateName,
                    }) =>
                      id === 0 ? (
                        <div>
                          <DateTemplate
                            showTime
                            key={id}
                            label={label}
                            selectedDate={
                              defaultValue === "" ? "" : dayjs(defaultValue)
                            }
                            changeDate={(e) => {
                              updateState(
                                stateName!,
                                dayjs(e).format("YYYY-MM-DDTHH:mm")
                              );
                            }}
                          />
                        </div>
                      ) : id === 1 || id === 2 ? (
                        <Form.Item key={id} label={label} name={label}>
                          <DropdownGrid_template
                            keyExpr={id === 1 ? "stnIDpk" : "mtrSerialNo"}
                            label={null}
                            labelCol={0}
                            gridData={id === 1 ? station : meter}
                            dropdownBtnStyles={{
                              width: 285,
                              overflow: "hidden",
                              height: 25,
                            }}
                            isHide={true}
                            className=""
                            cusBtnStyle={true}
                            columns={
                              id === 1
                                ? reportingStation_column
                                : reportingMeter_column
                            }
                            defaultValue={states[stateName]?.name ?? ""}
                            disabled={formDisabled}
                            onRowClick={(e) => {
                              if (id == 1) {
                                updateState(stateName, {
                                  id: e.stnIDpk ?? 0,
                                  name: e.stnName ?? "",
                                });
                              } else {
                                updateState(stateName, {
                                  id: e.emaIDpk ?? 0,
                                  name: e.mtrMeterNo ?? "",
                                });
                              }
                              console.log("Gridevent", e);
                            }}
                            handleRefresh={() => {
                              updateState(stateName, {
                                id: 0,
                                name: "",
                              });
                            }}
                            extraWidget={
                              <span
                                style={{
                                  height: 22.5,
                                  marginBottom: 2,
                                  borderWidth: 1,
                                  borderColor: borderTheme,
                                  width: 24.9,
                                  cursor: "pointer",
                                }}
                                className=" flex justify-center cursor-pointer border-gray-300 rounded"
                                onClick={(e: any) => {
                                  updateState(stateName, {
                                    id: 0,
                                    name: "",
                                  });
                                }}
                              >
                                <img
                                  className="w-full h-full cursor-pointer p-0.5 flex items-center justify-center"
                                  src={reset}
                                  alt="reset"
                                />
                              </span>
                            }
                          />
                        </Form.Item>
                      ) : (
                        <InputsTemplate
                          key={id}
                          label={label}
                          span
                          inputStyle={{ width: 300 }}
                          defaultValue={defaultValue}
                          orderOnchange={(e) => {
                            updateState(stateName!, e);
                          }}
                          useCallbackFunc
                          disabledStatus={formDisabled}
                        />
                      )
                  )}
                <div className="flex justify-between pt-[1px] ">
                  <div className="pl-[14.2%] ">
                    <CheckboxTemlate
                      label={"End of Period Reading?"}
                      labelStyle="mr-[10rem]"
                      withBorders={true}
                      defaultValue={states.endOfPeriodReading}
                      setCheckboxOnchange={(e: any) => {
                        updateState("endOfPeriodReading", e);
                      }}
                    />
                  </div>
                  <div className="">
                    <SaveButton
                    className="flex h-8 hover:border-blue-200 flex-row items-center justify-center px-2 py-2 border-2 'border-slate-200' rounded dark:bg-slate-900 dark:text-darkModeSkin-base pr-4"
                      disableButton={formDisabled}
                      useCallbackFunc={true}
                      title={updateMode ? "Update" : "Save"}
                      handleSave={() => {
                        !updateMode ? saveMeterReading() : updateMeterReading();
                      }}
                    />
                  </div>
                </div>
              </Form>
            </aside>
            {/* rightSide */}
            <aside className="z-[0]">
              <Form
                className="flex-1 h-full dark:bg-slate-900 dark:text-darkModeSkin-base rounded"
                name="basic"
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
                layout="horizontal"
              >
                {dataFields
                  .filter((f) => [6, 7, 8, 9, 10].includes(f.id))
                  .map(
                    ({
                      id,
                      label,
                      defaultValue,
                      optionsData,
                      idExpr,
                      dataExpr,
                      stateName,
                    }) =>
                      id === 10 ? (
                        <TextareaTemplate
                          label={label}
                          labelCol={14}
                          height={90}
                          width="450px"
                          defaultValue={defaultValue}
                          useCallbackFunc
                          setCallbackValue={(e) => {
                            updateState(stateName, e);
                          }}
                          disabled={formDisabled}
                        />
                      ) : (
                        <InputsTemplate
                          key={id}
                          label={label}
                          span
                          inputStyle={{ width: 225 }}
                          defaultValue={defaultValue}
                          orderOnchange={(e) => {
                            updateState(stateName, e);
                          }}
                          useCallbackFunc
                          disabledStatus={formDisabled}
                        />
                      )
                  )}
              </Form>
            </aside>
          </div>
          {/* dataGrid section */}
          {/* <div className='h-1 w-full bg-sky-200' /> */}
        </Switcher>
        <section className="flex flex-col">
          <EnergyMeterToolbar
            key={Math.random() * 3600}
            runNew={() => {
              setWitchFalse();
              populateFields(true);
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            }}
            runUpdate={() => {
              validateEdit();
            }}
            runCancel={() => {
              DeleteMeterReading();
            }}
            searchTextOnchange={(e) => {
              // setSearchText({ temp: e , index: searchText.index });
              toolbarRef.current.searchText = e;
              console.log(e);
            }}
            checkOnChange={(check) => {
              toolbarRef.current.activeStatus = check;
              // setActiveStatus({ temp: check, status: activeStatus.status });
            }}
            startDateChanged={(startDateV) => {
              // toolbarRef.current.startDate = startDateV;
              setStartDate(startDateV);
              // setStartDate({
              //   temp: dayjs(startDateV).format("YYYY-MM-DD"),
              //   index: startDate.index,
              // });
            }}
            endDateChanged={(endDateV) => {
              // toolbarRef.current.endDate = endDateV;
              setEndDate(endDateV);
              // setEndDate({
              //   temp: dayjs(endDateV).format("YYYY-MM-DD"),
              //   index: endDate.index,
              // });
            }}
            handleFind={() => {
              // setActiveStatus({
              //   temp: activeStatus.temp,
              //   status: activeStatus.temp,
              // }); //active status
              setRefreshView((prev) => !prev);
            }}
            handleRefresh={() => {
              toolbarRef.current = {
                ...toolbarRef.current,
                searchText: "",
                activeStatus: "",
                startDate: startingDate,
                endDate: dayjs().format(dbDateFormat),
              };
              // setStartDate(startingDate);
              // setEndDate(dayjs().format(dbDateFormat));
              // setSearchValue(undefined);
              // setPagination({ number: 1, size: 20 });
              setRefreshView((prev) => !prev);
            }}
            endDate={endDate}
            startDate={startDate}
          />
          <section className="flex-grow">
            <section className="w-full h-full">
              {status ? (
                <ReadingsChart />
              ) : (
                <Datagrid_template
                  dataId="smrIDpk"
                  handlePageNumber={(page) => {
                    setPagination((state) => ({ ...state, number: page }));
                  }}
                  handlePageSize={(size) => {
                    setPagination((state) => ({ ...state, size }));
                  }}
                  pageNumber={pagination.number}
                  pageSize={pagination.size}
                  gridheight={!switchStatus ? 515 : 700}
                  showColumnHeaders={true}
                  columns={energyReadingCol}
                  data={energyReadingData}
                  disablePaging={false}
                  disableGroupPanel
                  rowDoubleClicked={() => {
                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: false })
                    );
                    dispatch(
                      setGeneralValue({ expr: "updateMode", value: true })
                    );
                    dispatch(
                      setGeneralValue({ expr: "validateEditBtn", value: false })
                    );
                    setWitchFalse();
                  }}
                  onRowClick={(e) => {
                    // switchStatus && setWitch();
                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: true })
                    );
                    dispatch(
                      setGeneralValue({ expr: "updateMode", value: false })
                    );
                    dispatch(
                      setGeneralValue({ expr: "validateEditBtn", value: false })
                    );
                    console.log(e);
                    // setDisableButton(true);
                    // setAllowUpdate(false);
                    setRowData(e);
                    updateState("selectedRecord", e);
                  }}
                />
              )}
            </section>
          </section>
        </section>
      </div>
    </>
  );
};
