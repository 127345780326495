import React, { useState } from "react";
import { Image, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import image_upload from "../../../../assets/image_upload.png";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import TextArea from "antd/es/input/TextArea";
import { useCrudFunc } from "../../../../functions/crud";

interface props {
  statesx?: any;
}

export const ImageUpload = ({ statesx }: props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1); // Track the active tab
  const [imageCon, setImageCon] = useState<{ [key: number]: string }>({});
  const [fileName, setFileName] = useState<string>("");

  // Image Upload
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const browseImage = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url: string) => {
        setLoading(false);
        setImageCon((prev) => ({
          ...prev,
          [activeTab]: url, // Store image for the active tab
        }));
        setFileName(info.file.name);
      });
    }

    // Work even without the "done" status
    getBase64(info.file.originFileObj as RcFile, (url: string) => {
      setLoading(false);
      setImageCon((prev) => ({
        ...prev,
        [activeTab]: url, // Store image for the active tab
      }));
      setFileName(info.file.name);
    });
  };

  const [Posting] = useCrudFunc("powerapp");
  // const saveImage = async () => {
  //   try {
  //     const res = await Posting(`LineDetails/SaveImage`, {
  //       file: imageCon[activeTab], // Save the image corresponding to the active tab
  //       LdtIDpk: statesx?.ldtIdpk,
  //       imageIndex: activeTab,
  //     });
  //     // Handle response if needed
  //   } catch (error) {
  //     // Handle error
  //   }
  // };

  return (
    <div>
      <div className="border-t-2 pb-2 w-full">
        <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
          Images
        </div>

        <div className="px-2 pb-2 flex items-end h-full w-full">
          <div
            style={{
              backgroundImage:
                imageCon !== ""
                  ? `url(${imageCon[activeTab]})`
                  : `url(${image_upload})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            className="w-full h-60 border flex justify-center items-center"
          >
            <Upload
              name="File uploaded"
              listType="picture"
              className="avatar-uploader justify-center items-center"
              showUploadList={false}
              onChange={(e) => {
                browseImage(e);
              }}
            />
          </div>
          {/* <Image
            style={{
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            className="w-full h-60 border flex justify-center items-center"
            src={imageCon[activeTab] || imageCon[activeTab]}
            alt={`Tab ${activeTab} Image`}
          /> */}
        </div>

        <div className="flex mx-2 mb-0.5">
          <TextArea
            style={{
              resize: "none",
              maxHeight: "12%",
              minHeight: "10%",
              width: "88%",
            }}
            className="py-1 overflow-y-scroll resize-none"
            rows={1}
          />
          <li className="h-full">
            <button
              // onClick={saveImage}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1 h-full mr-1 px-2 border-2 border-slate-200 rounded"
            >
              <img className="pr-1" src={save} alt="Save" />
            </button>
          </li>
          <li>
            <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center px-2 border-2 border-slate-200 rounded">
              <Upload showUploadList={false} onChange={(e) => browseImage(e)}>
                <img className="pr-1" src={newIcon} alt="New" />
              </Upload>
            </button>
          </li>
        </div>

        <div className="flex">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((tab) => (
            <div
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`border-r p-0.5 text-xs px-2 cursor-pointer ${
                activeTab === tab ? "bg-gray-300" : ""
              }`}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};