import React, { useState } from "react";
import ProtectionGuaranteeForm from "./form";
import { HSeperator } from "../Safety/elogger/widgets/utils";
import EquipmentDataTable from "../Safety/elogger/StationReadings/tabContents/EquipmentOutage/equipment-data-table";
import { Toolbar } from "./toolbar";
import useFetch from "../../hooks/useFetch";
import { PC1_cols } from "./data/datagrid_cols";
import { setGeneralValue } from "../../features/generalSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  searchTextTypes,
  searchCriteriaTypes,
  statusTypes,
} from "../human-resource/setups/data/types/selectsTypes";
import { startingDate } from "../accessories/component_infos";

const ProtectionGuarantee1 = () => {
  const dispatch = useDispatch();
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);
  const [toggler, setToggler] = useState<boolean>(true);
  // const startingDate = dayjs().startOf("month").format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: startingDate,
    temp: startingDate,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [searchCriteria, setsearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [selectedReord, setSelectedRecord] = useState<any>([]);
  const [refershState, setFreshState] = useState<boolean>();
  const [switchGrid, setSwitchGrid] = useState(true);
  const [mainGrid] = useFetch(
    `PGApplicationPC1/GetAllPGApplicationPC1s?startDate=${
      startDate.text
    }&endDate=${endDate.text}&search=${searchText.text}&criteria=${
      searchCriteria.index
    }&pagesize=${20}&pagenumber=${1}`,
    refershState,
    undefined,
    undefined,
    "powerapp"
  );

  return (
    <main className="flex flex-col h-full w-full">
      <header className="p-1">
        <Toolbar
          toggler={() => setToggler(!toggler)}
          searchCriteria
          searchCriteriaData={[
            { id: 0, value: "Station" },
            { id: 1, value: "Applicant" },
          ]}
          searchTextOnchange={(searchValue) => {
            setSearchText({ temp: searchValue, text: searchText.text });
          }}
          checkOnChange={(check) => {
            setActiveStatus({ temp: check, status: activeStatus.status });
          }}
          handleFind={() => {
            setStartDate({ text: startDate.temp, temp: startDate.temp });
            setEndDate({ text: endDate.temp, temp: endDate.temp });
            setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
            setActiveStatus({
              temp: activeStatus.temp,
              status: activeStatus.temp,
            }); //active status
            setsearchCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
          }}
          searchCriteriaOnchange={(newIndex, criteria) => {
            setsearchCriteria({
              temp: newIndex,
              index: searchCriteria.index,
            });
          }}
          handleRefresh={() => {
            setSearchText({ temp: searchText.temp, text: "" });
            setActiveStatus({
              temp: "",
              status: "",
            });
            setFreshState(!refershState);
          }}
          startDate={startDate.temp}
          endDate={endDate.temp}
          startDateChanged={(date) =>
            setStartDate({
              temp: dayjs(date).format(genFormat),
              text: startDate.text,
            })
          }
          endDateChanged={(date) =>
            setEndDate({
              temp: dayjs(date).format(genFormat),
              text: endDate.text,
            })
          }
        />
      </header>
      {toggler && (
        <header className="flex flex-col flex-1">
          <span className="w-full p-1 bg-stone-200">
            <p className="text-sm font-bold">Protection Guarantee Details</p>
          </span>
          <div className="w-full">
            <ProtectionGuaranteeForm
              selectedRecord={selectedReord}
              setSelectedRecord={setSelectedRecord}
              refreshState={() => {
                setFreshState(!refershState);
              }}
            />
          </div>
        </header>
      )}
      <footer className="flex flex-col flex-1 w-full">
        <HSeperator
          onClick={() => setToggler(!toggler)}
          status={toggler}
        />
        <EquipmentDataTable
          columns={PC1_cols}
          data={mainGrid}
          gridheight={toggler ? 275 : 790}
          onRowClick={(e: any) => {
            setSelectedRecord(e);
            dispatch(setGeneralValue({ expr: "clearPCT", value: false }));
            dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
            dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          }}
          rowDoubleClicked={() => {
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            dispatch(setGeneralValue({ expr: "updateMode", value: true }));
          }}
        />
      </footer>
    </main>
  );
};

export default ProtectionGuarantee1;
