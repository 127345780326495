import { Form, Input, InputNumber } from "antd";
import { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setValue } from "../features/forms/tier1/createSlice";
import { setTier2Value } from "../features/forms/tier2/tier2Slice";
import { setTier2CheckboxValue } from "../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setTier3Value } from "../features/forms/tier3/tier3Slice";
import { setTier4Value } from "../features/forms/tier4/tier4Slice";
import { RefreshButtonTemplate } from "./RefreshButtonTemplate";

interface props {
  keyValues?: any;
  index?: string | number;
  label?: string;
  widths?: any; //total : 93.5
  label2?: string;
  label3?: string;
  style?: any;
  span?: boolean;
  inputType?: "text" | "number" | "password";
  expr?: string;
  inlinePlaceholder?: any;
  placeholder?: any;
  defaultValue?: string | number | null;
  nameExpr?: string;
  disabledStatus?: any;
  id?: string;
  insertedValue?: any;
  inlineInsertedValue?: any;
  useCallbackFunc?: boolean;
  inputStyle?: any;
  cusWidth?: any;
  widthChange?: boolean;
  parentClassName?: string;
  beforeIcon ?: any
  numberOnly?: boolean;
  readOnly?: boolean;
  labelColPan?: any;
  wrapperCol?: number;

  additionalWidget?: any;
  inlineInputsOnchange?: (value: string, stateName: string | number) => void;
  orderOnchange?: (value: string | number, stateName?: string | number) => void;
  handleRefresh ?: ()=>void

  inputRef?: any;
  autoComplete?: string;
}
export const InputsTemplate = ({
  inlineInputsOnchange,
  inputType = "text",
  defaultValue,
  inlinePlaceholder,
  index,
  label,
  keyValues,
  inputStyle,
  style,
  label3,
  useCallbackFunc,
  orderOnchange,
  label2,
  widths,
  span,
  expr,
  placeholder,
  nameExpr,
  disabledStatus,
  insertedValue,
  additionalWidget,
  id,
  parentClassName,
  numberOnly = false,
  readOnly = false,
  labelColPan,
  wrapperCol,
  handleRefresh,
  beforeIcon,
  inputRef,
  autoComplete
}: props) => {
  const dispatch = useDispatch();
  const idx = useId();

  //Populating field on update
  const selectedFieldData = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );
  const [data, setData] = useState<string | number | undefined>(
    inputType === "number" ? (label === "Order" ? 0 : undefined) : ""
  );
  const [inlineData, setInlineData] = useState<any>(["", ""]);

  useEffect(() => {
    if (useCallbackFunc) {
      label2
        ? setInlineData([inlinePlaceholder[0], inlinePlaceholder[1]])
        : setData(defaultValue!);
    } else {
      // null check and correction from db
      if (
        (selectedFieldData[nameExpr!] === null ||
          selectedFieldData[nameExpr!] === undefined) &&
        label === "Order"
      ) {
        setData("");
        setInputValue("");

        return;
      }

      setData(selectedFieldData[nameExpr!]);
      setInputValue(selectedFieldData[nameExpr!]);
    }
  }, [selectedFieldData, defaultValue, inlinePlaceholder]);

  // get form tier
  const tier = useSelector((state: RootState) => state.general.tier);
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled state on general redux slice

  const setInputValue = (value: string | number) => {
    // form type check to dispatch select value
    tier === "1"
      ? dispatch(setValue({ expr: expr, value: value })) //dispatch to tier1 slice
      : tier === "2"
        ? dispatch(setTier2Value({ expr: expr, value: value })) // dispactch to tier2 slice
        : tier === "3"
          ? dispatch(setTier3Value({ expr: expr, value: value })) // dispactch to tier3 slice
          : tier === "4"
            ? dispatch(setTier4Value({ expr: expr, value: value })) // dispactch to tier4 slice
            : dispatch(setTier2CheckboxValue({ expr: expr, value: value })); // dispactch to tier4 slice
  };

  //Code to fix error on clicking order fields
  const inputField = document.getElementById(id ?? "orderId");

  inputField?.addEventListener("mouseenter", function () {
    this.focus();
  });

  //Disable focus on mouse out. 🎉
  inputField?.addEventListener("mouseout", function () {
    this.blur();
  });

  if (numberOnly) {
    return (
      <>
        <Form.Item
          labelCol={{ span: labelColPan }}
          wrapperCol={{ span: wrapperCol }}
          label={label && <p className="text-xs">{label}</p>}
          className={parentClassName}
          style={style}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <div className="w-full flex flex-row items-center py-[1px]">
            <InputNumber
              type="number"
              readOnly={readOnly}
              id={`${id ?? "orderId-" + idx}`}
              style={
                !span
                  ? { ...inputStyle, width: "calc(100% - 27px)" }
                  : { ...inputStyle }
              }
              placeholder={placeholder}
              // changed type from 'React.ChangeEvent<HTMLInputElement>' to 'any' to solve error for value 0
              onChange={(boxValue: any) => {
                setData(boxValue);
                useCallbackFunc
                  ? orderOnchange && orderOnchange(boxValue, index)
                  : setInputValue(boxValue);
              }}
              value={data ?? 0}
              disabled={
                disabledStatus !== undefined ? disabledStatus : disabled
              }
            />
            {additionalWidget && <div>{additionalWidget}</div>}

            {!span && (
              <span
                style={{ height: 22.5, marginBottom: 0.6, width: 24 }}
                className=" flex justify-center hover:cursor-pointer"
              ></span>
            )}
          </div>
        </Form.Item>
      </>
    );
  } else {
    return (
      <>
        {label2 && !label3 ? (
          <Form.Item
            label={<p className="text-xs">{label}</p>}
            style={{ marginBottom: 0 }}
            labelCol={labelColPan && { span: labelColPan }}
          >
            {/* two forms inline */}
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              style={
                !widths
                  ? { display: "inline-block", width: "calc(31.5% )" }
                  : { display: "inline-block", width: widths.form1 }
              }
            >
              {numberOnly ? (
                <InputNumber
                  style={inputStyle}
                  id={id ?? "input-" + idx}
                  onChange={(e) =>
                    inlineInputsOnchange!(keyValues![0], e.target.value)
                  }
                  value={inlineData[0]}
                  disabled={
                    disabledStatus !== undefined ? disabledStatus : disabled
                  }
                />
              ) : (
                <Input
                  style={inputStyle}
                  id={id ?? "input-" + idx}
                  onChange={(e) =>
                    inlineInputsOnchange!(keyValues![0], e.target.value)
                  }
                  value={inlineData[0]}
                  disabled={
                    disabledStatus !== undefined ? disabledStatus : disabled
                  }
                  autoComplete={autoComplete}
                />
              )}
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              labelCol={{ span: 10 }}
              label={<p className="text-xs">{label2}</p>}
              style={
                !widths
                  ? {
                    display: "inline-block",
                    width: "calc(62% )",
                    marginLeft: "2%",
                  }
                  : {
                    display: "inline-block",
                    width: widths.form2,
                    marginLeft: "2%",
                  }
              }
            >
              {numberOnly ? (
                <InputNumber
                  id={id ?? "inps-" + idx}
                  style={inputStyle}
                  onChange={(e) =>
                    inlineInputsOnchange!(keyValues![1], e.target.value)
                  }
                  disabled={
                    disabledStatus !== undefined ? disabledStatus : disabled
                  }
                  value={inlineData[1]}
                />
              ) : (
                <Input
                  id={id ?? "inps-" + idx}
                  style={inputStyle}
                  onChange={(e) =>
                    inlineInputsOnchange!(keyValues![1], e.target.value)
                  }
                  disabled={
                    disabledStatus !== undefined ? disabledStatus : disabled
                  }
                  value={inlineData[1]} 
                  autoComplete={autoComplete}
                />
              )}
            </Form.Item>
          </Form.Item>
        ) : // three forms inline
          label2 && label3 ? (
            <Form.Item
              label={<p className="text-xs">{label}</p>}
              style={{ marginBottom: 0 }}
            >
              {/* form 1 */}
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                style={
                  !widths
                    ? { display: "inline-block", width: "calc(20% )" }
                    : { display: "inline-block", width: widths.form1 }
                }
              >
                <Input
                  id={"idaa-" + idx}
                  onChange={insertedValue}
                  value={placeholder}
                  key={placeholder} 
                  autoComplete={autoComplete}
                />
              </Form.Item>

              {/* form 2 */}
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                style={
                  !widths
                    ? {
                      display: "inline-block",
                      width: "calc(37% )",
                      marginLeft: "2%",
                    }
                    : {
                      display: "inline-block",
                      marginLeft: "2%",
                      width: widths.form1,
                    }
                }
                label={<p className="text-xs">{label2}</p>}
              >
                <Input
                  id={"idd-" + idx}
                  onChange={insertedValue}
                  value={placeholder}
                  key={placeholder}
                  autoComplete={autoComplete}
                />
              </Form.Item>

              {/* form3 */}
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label={<p className="text-xs">{label3}</p>}
                style={
                  !widths
                    ? {
                      display: "inline-block",
                      width: "calc(38.5% )",
                      marginLeft: "2%",
                    }
                    : {
                      display: "inline-block",
                      width: widths.form2,
                      marginLeft: "2%",
                    }
                }
              >
                <Input
                  ref={inputRef}
                  id={"iddf-" + idx}
                  onChange={(e: any) =>
                    useCallbackFunc
                      ? orderOnchange!(e.target.value)
                      : insertedValue
                  }
                  disabled={
                    disabledStatus !== undefined ? disabledStatus : disabled
                  }
                  value={placeholder}
                  key={placeholder}
                  autoComplete={autoComplete}
                />
              </Form.Item>
            </Form.Item>
          ) : (
            <Form.Item
              label={label && <p className="text-xs">{label}</p>}
              style={style}
              className={parentClassName}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <div className="flex flex-row items-center gap-1">
                <Input
                  id={inputType === "number" ? id ?? "orderId-" + idx : ""}
                  readOnly={readOnly}
                  type={inputType}
                  prefix={beforeIcon}
                  style={
                    !span
                      ? { ...inputStyle, width: "calc(100% - 27px)" }
                      : { ...inputStyle }
                  }
                  placeholder={placeholder}
                  onChange={(e: any) => {
                    const inputValue: string | number = e.target.value;
                    setData(inputValue);
                    useCallbackFunc
                      ? orderOnchange && orderOnchange(inputValue, index)
                      : setInputValue(inputValue);
                  }}
                  value={inputType == "number" ? Number(data).toFixed(2) : data}
                  disabled={
                    disabledStatus !== undefined ? disabledStatus : disabled
                  }
                  autoComplete={autoComplete}
                />
                {additionalWidget && <div>{additionalWidget}</div>}

                {!span && 
                  <RefreshButtonTemplate handleRefresh={()=>{handleRefresh && handleRefresh()}} />
                  // <span
                  //   style={{ height: 22.5, marginBottom: 0.6, width: 24 }}
                  //   className=" ml-1 flex justify-center hover:cursor-pointer"
                  // ></span>
                }
              </div>
            </Form.Item>
          )}
      </>
    );
  }
};
