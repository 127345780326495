/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";

import {
  pm_schedules_columns,
  pm_veh_Ass_columns,
  pm_veh_details_columns,
} from "../data/mini_datagrid_cols";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  Cancel,
  Schedule_Alert_Button,
  OpenButton,
} from "../../../../../accessories/buttons";
import { FaBinoculars } from "react-icons/fa";
import { InputsTemplate } from "../../../../../../templates/input";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { Select } from "antd";
import { Tooltip } from "devextreme-react";
import { searchTextTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { currentDate } from "../../../../../accessories/component_infos";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  setState?: (key: string, value: any) => void;
  opened?: () => void;
  openScheduleAlert?: () => void;
  selectedTask?: any;
  footerGridData?: any;
}

export const ActivitiesCapture_Details_Form = ({
  toggler,
  openScheduleAlert,
  datgridCol,
  customHeightHook,
  setState,
  opened,
  selectedTask,
  footerGridData,
}: props) => {
  // From redux

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchTextTypes>({
    temp: "0",
    text: "0",
  });
  //endPoints
  const [Alldata, Allerror, Allloading] = useFetch(
    `PmActivitiesCaptures/GetAllPmSchedules?SeacrchText=${searchText.text}&SelectedIndex=${searchCriteria.text}`
  );
  const [alertws_data, alertws_error, alertws_loading] = useFetch(
    "AsmTmsAlertRequester/GetLueWorkshops"
  );
  const [veh_details_data, veh_details_error, veh_details_loading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsVehicleDetail`
  );
  const [veh_assignment_data, veh_assignment_error, veh_assignment_loading] =
    useFetch(`PmActivitiesCaptures/GetAllVwAsmTmsVehicleAssignment`);

  if (alertws_data) {
  } else {
  }

  /**************************************************************/

  const cancel = async () => {
    try {
      setBusyLoader("Cancelling Schedule...");
      await UpdateDataFunc(
        `AssetTamVehicleDetail/CancelScheduleArlert?mrqIDpk=${selectedTask.mrqIDpk}`,
        {
          vdtScheduledPmDate: selectedTask?.vdtScheduledPmDate,
          vdtPmScheduled: selectedTask?.vdtPmScheduled,
          vdtScheduledPmWorkshopIDfk: selectedTask?.vdtScheduledPmWorkshopIDfk,
          vdtIDpk: selectedTask?.vdtIDpk,
        }
      );
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Schedule cancelled successfully!",
        title: "Schedule Cancelled!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to cancel schedule..contact adminstrator!",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  /**************************************************************** */
  //      Response MODAL
  /**************************************************************** */
  const AlertSentModalData = {
    message: "Transport Alert Sent",
    title: "SMS ALERT SENT",
    okText: "Ok",
  };

  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(AlertSentModalData);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  //Access Priviledge
  const [privType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  /**************************************************************** */
  const cancelSchedule = {
    icon: "warning",
    message:
      "This action is IRREVERSIBLE, it will cancel the PM schedule as well as any request associated with it.. Are you sure you want to proceed and cancel this schedule?",
    title: "Cancel Schedule?",
    okText: "Yes",
    cancelText: "No",
  };
  //open button
  const openAccessPriv = () => {
    if (readPriv === true) {
      setState && setState("selectedRecord", "");
      opened?.(); 
      setState && setState("arrivalTime", currentDate);
      setState && setState("departureTime", currentDate);

    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //schedule & alert button
  const scheduleAlertPriv = () => {
    if (readPriv === true) {
      openScheduleAlert?.();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //cancel button
  const validateCancel = () => {
    if (selectedTask === undefined) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a record",
        title: "Grid Selection Error!",
      });
    }else if(selectedTask?.vdtPmScheduled=== false){
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Vehicle hasnt been scheduled yet! ",
        title: "Unable to cancel schedule!",
      });
    }
    
    else {
      setModalData(cancelSchedule);
      setOpenModal(true);
    }
  };
  const cancelPriv = () => {
    if (readPriv === true) {
      validateCancel();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  const [currentColumns, setCurrentColumns] = useState(pm_schedules_columns);
  const [currentData, setCurrentData] = useState(Alldata);
  const options = [
    { label: "PM Requests", value: "0" },
    { label: "Veh. Assignments", value: "1" },
    { label: "Veh. Details", value: "2" },
  ];

  useEffect(() => {
    if (Alldata) {
      setCurrentData(Alldata);
    }
  }, [Alldata]);
  const handleSelectChange = (val: string) => {
    setSearchCriteria({
      temp: val,
      text: searchCriteria.text,
    });

    switch (val) {
      case "2":
        setCurrentColumns(pm_veh_details_columns);
        setCurrentData(veh_details_data);
        break;
      case "1":
        setCurrentColumns(pm_veh_Ass_columns);
        setCurrentData(veh_assignment_data);
        break;
      default:
        setCurrentColumns(pm_schedules_columns);
        setCurrentData(Alldata);
        break;
    }
  };

  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Cancel Schedule?"
            ? cancel()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "warning" ? true : false}
      />

      {/* Datagrid table */}
      <div className="p-2">
        <div className="flex flex-row justify-end items-center">
          <div className="mx-2 py-2">
            <Select
              defaultValue={"PM Requests"}
              style={{ width: 150 }}
              onChange={handleSelectChange}
              options={options}
              size="small"
              placeholder=""
              dropdownStyle={{ minWidth: "250px" }}
            />
          </div>
          
          <InputsTemplate
            useCallbackFunc
            orderOnchange={(value) => {
              setSearchText({ temp: value.toString(), text: searchText.text });
            }}
            inputStyle={{ background: "#fffbeb", height: "23px" }}
            disabledStatus={false}
            span
          />
          <span
            onClick={() => {
              setSearchText({ text: searchText.temp, temp: searchText.temp });
              setSearchCriteria({
                temp: searchCriteria.temp,
                text: searchCriteria.temp,
              });
            }}
            style={{ borderColor: borderTheme }}
            className=" h-full ml-1 flex items-center  justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
          >
            <FaBinoculars color="#007AFF" />
          </span>
        </div>
        <Datagrid_template
          columns={currentColumns}
          data={currentData}
          gridheight={359}
          onRowClick={(val) => {
            setState!("selectedTask_update", [val]);
            setState!("workShop", {
              id: val?.wsdIDpk,
              name: val?.pmWorkshopName,
            });
          }}
          disableSearch={true}
          disableGroupPanel={true}
          disablePaging={true}
        />
        <div className="w-full flex  items-center justify-between bg-slate-100 py-0.5">
          <ul className="w-full flex items-center justify-evenly">
            <li
              id="cancelPm"
              className="flex justify-start  w-1/4"
              onClick={cancelPriv}
            >
              <Cancel />
              <Tooltip
                target="#cancelPm"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Cancel PM Schedule</p>
              </Tooltip>
            </li>
            <li id="scheduleAlert" className="flex justify-center w-2/4">
              <Schedule_Alert_Button handleAlerts={scheduleAlertPriv} />
              <Tooltip
                target="#scheduleAlert"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Cancel PM Schedule</p>
              </Tooltip>
            </li>
            <li id="openButton" className="flex justify-end w-1/4">
              <OpenButton handleOpen={openAccessPriv} />
              <Tooltip
                target="#openButton"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Open PM for selected Schedule</p>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
