import { createContext, PropsWithChildren, useContext, useState } from "react";
import { createStore, StoreApi, useStore } from "zustand";

type TroubleReportRepo = {
  setSelectedReport: (record: ReportDataType) => void;
  selectedReport: ReportDataType | undefined;
  selectedRepair: TaskUpdatesType | undefined;
  setSelectedRepair: (record: TaskUpdatesType) => void;
  reportData: ReportDataType[];
};
type ProviderProps = {
  initialData?: any;
};
type RepoContextProviderProps = PropsWithChildren & ProviderProps;
const RepoContext = createContext<StoreApi<TroubleReportRepo> | undefined>(
  undefined
);
export const TroubleReportContextProvider = ({
  children,
  initialData,
}: RepoContextProviderProps) => {
  const [repository] = useState(() =>
    createStore<TroubleReportRepo>((set) => ({
      reportData: initialData,
      selectedRepair: undefined,
      selectedReport: undefined,
      setSelectedReport: (record: ReportDataType) =>
        set((state) => ({ ...state, selectedReport: record })),
      setSelectedRepair: (record: TaskUpdatesType) =>
        set((state) => ({ ...state, selectedRepair: record })),
    }))
  );
  return (
    <RepoContext.Provider value={repository}>{children}</RepoContext.Provider>
  );
};

export function useTroubleReportRepo<T>(
  selector: (state: TroubleReportRepo) => T
) {
  const context = useContext(RepoContext);
  if (!context) {
    throw new Error("Trouble Report Provider is missing");
  }
  return useStore(context, selector);
}
export type ReportDataType = {
  trpIDpk: number;
  trpCreatedBy: number;
  tstIDpk: number;
  tctIDpk: number;
  tptIDpk: number;
  timIDpk: number;
  ttpIDpk: number;
  tscIDpk: number;
  tlcIDpk: number;
  tclIDpk: number;
  tatIDpk: number;
  reportedByEmpID: number;
  trpDueDate: string; // ISO 8601 date format
  trpReport: string;
  trpResolutionActivity: string;
  ttpName: string;
  tclName: string;
  tscName: string;
  timName: string;
  tctName: string;
  tptName: string;
  tlcName: string;
  trpLocationIDfk: number;
  trpPercentComplete: number;
  trpFixed: boolean;
  trpReportDatex: string; // ISO 8601 date format
  trpReportDate: string; // ISO 8601 date format
  troubleTime: string; // Format "HH:mm"
  trpCompletionDate: string; // ISO 8601 date format
  reportedByName: string;
  trpUpdatedBy: string;
  tstName: string;
  requestSxnName: string;
  requestSxnShtName: string;
  trpEquipment: string;
  tatName: string;
  requestDeptName: string;
  requestDeptShtName: string;
  trpUpdatedByDept: string;
  trpUpdatedBySection: string;
  trpRating: number;
  trpTechnicalDescription: string;
  trpClassificationRmks: string;
  trpRmks: string;
};
export type TaskUpdatesType = {
  truIDpk: number;
  timIDpk: number;
  tstIDpk: number;
  truDate: string; // ISO 8601 date string
  truUpdate: string;
  truPercentComplete: number; // Assuming this is a percentage represented as a decimal
  tstName: string;
  timName: string;
  truCompletionDate: string; // ISO 8601 date string
  truCreationDate: string; // ISO 8601 date string
  createdByStaffNo: string;
  createdByEmpName: string;
  createdByPlc: string;
  createdByJbtShtName: string;
  createdByDeptShtName: string;
  createdByMobNox: string;
  truUpdatedBy: string; // List of names with IDs
  createdByMobNo: string;
};

const name = "samuel";
const numbr = 4;
